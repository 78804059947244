import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { addDays, isPast } from "date-fns";
import { sortBy } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { FormActions } from "../../../../components/ui/Form/FormActions";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../../components/ui/Form/Inputs/DatePicker";
import { Input } from "../../../../components/ui/Form/Inputs/Input";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  SubplanApprovalDatePage_Organization$data,
  SubplanApprovalDatePage_Organization$key,
} from "./__generated__/SubplanApprovalDatePage_Organization.graphql";
import { SubplanApprovalDatePage_Query } from "./__generated__/SubplanApprovalDatePage_Query.graphql";
import { SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation } from "./__generated__/SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SubplanApprovalDatePage_Organization on Organization
  @relay(plural: true) {
    id
    name
    terminated
    organizationGeographies {
      country {
        code
      }
      isTaxFavoredEquityTypeUnlocked
      taxFavoredEquityTypeSubplanName
      taxFavoredEquityTypeSubplanApprovalDate
      isTaxFavoredSubplanExpired
      taxFavoredSubplanExpirationDate
    }
  }
`;

const UPDATE_ORGANIZATION_GEOGRAPHY_MUTATION = graphql`
  mutation SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation(
    $organizationId: OrganizationId!
    $countryCode: String!
    $attributes: UpdateOrganizationGeographyTaxFavoredSubplanAttributes!
  ) {
    updateOrganizationGeographyTaxFavoredSubplan(
      organizationId: $organizationId
      countryCode: $countryCode
      attributes: $attributes
    ) {
      taxFavoredEquityTypeSubplanName
      taxFavoredEquityTypeSubplanApprovalDate
      isTaxFavoredSubplanExpired
      taxFavoredSubplanExpirationDate
    }
  }
`;

type Organization = SubplanApprovalDatePage_Organization$data[number];

const schema = z.object({
  subplanApprovalDate: z.string(),
  subplanName: z.string(),
});

type FieldValues = z.infer<typeof schema>;

const getOrganizationBSPCESubplan = (organization: Organization) => {
  const BSPCE = organization.organizationGeographies.find(
    ({ country, isTaxFavoredEquityTypeUnlocked }) =>
      isTaxFavoredEquityTypeUnlocked && country.code === "FR",
  );

  return {
    approvalDate: BSPCE?.taxFavoredEquityTypeSubplanApprovalDate ?? null,
    expirationDate: BSPCE?.taxFavoredSubplanExpirationDate ?? null,
    isExpired: BSPCE?.isTaxFavoredSubplanExpired ?? false,
    name: BSPCE?.taxFavoredEquityTypeSubplanName ?? null,
  };
};

const UpdateSubplanApprovalDateSlideOver: React.FC<{
  onClose: () => void;
  organization: null | Organization;
  show: boolean;
}> = ({ onClose, organization, show }) => {
  const [updateOrganizationGeographyTaxFavoredSubplan, updateIsInFlight] =
    useSafeMutation<SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation>(
      UPDATE_ORGANIZATION_GEOGRAPHY_MUTATION,
    );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      subplanApprovalDate: null as null | string,
      subplanName: null as null | string,
    },
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    if (organization) {
      const { approvalDate: subplanApprovalDate, name: subplanName } =
        getOrganizationBSPCESubplan(organization);

      setValue("subplanName", subplanName);
      setValue("subplanApprovalDate", subplanApprovalDate);
    }
  }, [organization, setValue]);

  const onSubmit = handleSubmit(async (_fieldValues) => {
    if (!organization) {
      return;
    }
    const { subplanApprovalDate, subplanName } = _fieldValues as FieldValues;

    await updateOrganizationGeographyTaxFavoredSubplan({
      variables: {
        attributes: {
          subplanApprovalDate,
          subplanName,
        },
        countryCode: "FR",
        organizationId: organization.id,
      },
    });

    onClose();
  });

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose}>Update Subplan</SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <form className="space-y-4 p-6" onSubmit={onSubmit}>
        <FormRow error={errors.subplanName?.message} label="Subplan name">
          <Input
            invalid={!!errors.subplanName}
            placeholder="BSPCE Plan"
            {...control.register("subplanName")}
          />
        </FormRow>
        <Controller
          control={control}
          name="subplanApprovalDate"
          render={({ field, fieldState }) => (
            <FormRow
              error={fieldState.error?.message}
              label="Subplan approval date"
            >
              <DatePicker
                onChange={field.onChange}
                panelPosition="bottom"
                placeholder="Select a date"
                value={field.value}
              />
            </FormRow>
          )}
        />
        <FormActions onCancel={onClose}>
          <Button loading={updateIsInFlight} size="small" type="submit">
            Save
          </Button>
        </FormActions>
      </form>
    </SlideOver>
  );
};

const SubplanApprovalDatePage_: React.FC<{
  organizationsFragment: SubplanApprovalDatePage_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const organizationsWithBspceUnlock = useMemo(
    () =>
      sortBy(
        organizations.filter(
          (organization) =>
            !organization.terminated &&
            organization.organizationGeographies.some(
              ({ country, isTaxFavoredEquityTypeUnlocked }) =>
                isTaxFavoredEquityTypeUnlocked && country.code === "FR",
            ),
        ),
        "name",
      ),
    [organizations],
  );

  const {
    setFalse: closeSlider,
    setTrue: _showSlider,
    value: sliderShown,
  } = useBoolean(false);

  const [selectedOrganization, selectOrganization] =
    useState<null | Organization>(null);

  const showSlider = useCallback(
    (organization: Organization) => {
      selectOrganization(organization);
      _showSlider();
    },
    [_showSlider],
  );

  return (
    <>
      <UpdateSubplanApprovalDateSlideOver
        onClose={closeSlider}
        organization={selectedOrganization}
        show={sliderShown}
      />
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Subplan approval date</BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <Typography variant="Medium/Default">
              Update subplan approval date
            </Typography>
          </div>
          <Table className="w-full">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Organization</Table.HeaderCell>
                <Table.HeaderCell>Subplan Name</Table.HeaderCell>
                <Table.HeaderCell>Subplan approval date</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {organizationsWithBspceUnlock.map((organization) => {
                const {
                  approvalDate: subplanApprovalDateAsString,
                  expirationDate,
                  isExpired,
                  name: subplanName,
                } = getOrganizationBSPCESubplan(organization);

                const subplanApprovalDate = subplanApprovalDateAsString
                  ? new Date(subplanApprovalDateAsString)
                  : null;

                const isBlocked = !subplanApprovalDate || isExpired;
                const isWarned =
                  !isBlocked &&
                  expirationDate &&
                  isPast(addDays(new Date(expirationDate), -60));

                return (
                  <Table.Row
                    className={classNames({
                      "text-orange": isWarned,
                      "text-red": isBlocked,
                    })}
                    key={organization.id}
                  >
                    <Table.Cell>
                      <div className="flex gap-2">
                        {isBlocked ? (
                          <span className="font-emoji">⛔️</span>
                        ) : isWarned ? (
                          <span className="font-emoji">⚠️</span>
                        ) : (
                          <span className="font-emoji">✅</span>
                        )}
                        <span>
                          <strong>{organization.name}</strong>
                        </span>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{subplanName || "-"}</Table.Cell>
                    <Table.Cell>
                      {subplanApprovalDate ? (
                        <ShortDate value={subplanApprovalDate} />
                      ) : (
                        "None"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => showSlider(organization)}
                        size="extra small"
                        variant="Secondary Full"
                      >
                        Edit
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </RoundedBox>
      </OneColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query SubplanApprovalDatePage_Query {
    organizations {
      ...SubplanApprovalDatePage_Organization
    }
  }
`;

const SubplanApprovalDatePage: React.FC = () => {
  const { organizations } = useLazyLoadQuery<SubplanApprovalDatePage_Query>(
    QUERY,
    {},
  );
  return (
    <Page
      analyticsName="Super Admin - Subplan Approval Date"
      title={`Super admin | subplan approval date`}
    >
      <SubplanApprovalDatePage_ organizationsFragment={organizations} />
    </Page>
  );
};

export default SubplanApprovalDatePage;
