import { useMemo } from "react";
import { useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { AnchorButton, Button } from "../ui/Button";
import {
  ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$data,
  ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$key,
} from "./__generated__/ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import { Section } from "./Section";

const AMENDMENT_REQUESTS_FRAGMENT = graphql`
  fragment ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest
  @relay(plural: true) {
    grant {
      grantee {
        name
      }
      grantDate
      quantityIssued
    }
    postTerminationExercisePeriod {
      displayName
    }
    amendedLastDayToExercise
    numberOfSharesUnvestedAtGranteeTerminationPriorToAmendment
    numberOfSharesAccelerating
  }
`;

export const CSV_SEPARATOR = ",";

export const GRANTS_CSV_HEADER = [
  "Optionee",
  "Date of grant",
  "Shares granted",
  "New PTEP",
  "New PTEP term",
  "Number of shares unvested",
  "Number of shares accelerating",
].join(CSV_SEPARATOR);

export function escapeCSV(field: string): string {
  // Check if the field contains commas, double quotes, or line breaks
  if (/["\r\n,]/.test(field)) {
    // Double up any double quotes in the field
    return `"${field.replace(/"/g, '""')}"`;
  }
  return field;
}

export function ExportGranteeTerminationGrantsAmendmentsSection({
  grantAmendmentRequestsFragment,
  onPreviewBoardConsentClick,
  previewBoardConsentIsInFlight,
}: {
  grantAmendmentRequestsFragment: ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$key;
  onPreviewBoardConsentClick: () => void;
  previewBoardConsentIsInFlight: boolean;
}) {
  const grantAmendmentRequests = useFragment(
    AMENDMENT_REQUESTS_FRAGMENT,
    grantAmendmentRequestsFragment,
  );
  const csvDownloadLink = useGrantsAmendmentsCSVDownloadLink({
    grantAmendmentRequests,
  });
  const applicationName = useApplicationName();

  return (
    <Section
      index={1}
      subtitle={
        <>
          You may need the list of grant amendments to append to your own
          written board consent, or to the minutes of the board meeting during
          which the grants are approved (if you approve the grant amendments
          during a real board meeting). You can also download a preview of the
          board consent that would be generated by {applicationName}.
        </>
      }
      title="Export your list of grant amendments"
    >
      <div className="flex justify-between gap-4">
        <AnchorButton
          download="grants-amendments.csv"
          href={csvDownloadLink}
          size="small"
          variant="Secondary Full"
        >
          Export grant amendments
        </AnchorButton>
        <Button
          loading={previewBoardConsentIsInFlight}
          onClick={onPreviewBoardConsentClick}
          size="small"
          type="button"
          variant="Secondary Outline"
        >
          Preview board consent
        </Button>
      </div>
    </Section>
  );
}

export function useGrantsAmendmentsCSVDownloadLink({
  grantAmendmentRequests,
}: {
  grantAmendmentRequests: ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$data;
}) {
  const intl = useIntl();

  const rows = grantAmendmentRequests.map((grantAmendmentRequest) => {
    const row = [
      grantAmendmentRequest.grant.grantee.name,
      intl.formatDate(new Date(grantAmendmentRequest.grant.grantDate), {
        day: "2-digit",
        month: "short",
        timeZone: "UTC",
        year: "numeric",
      }),
      intl.formatNumber(grantAmendmentRequest.grant.quantityIssued),
      grantAmendmentRequest.postTerminationExercisePeriod?.displayName || "",
      grantAmendmentRequest.amendedLastDayToExercise
        ? intl.formatDate(
            new Date(grantAmendmentRequest.amendedLastDayToExercise),
            {
              day: "2-digit",
              month: "short",
              timeZone: "UTC",
              year: "numeric",
            },
          )
        : "",
      grantAmendmentRequest.numberOfSharesUnvestedAtGranteeTerminationPriorToAmendment !==
      null
        ? intl.formatNumber(
            grantAmendmentRequest.numberOfSharesUnvestedAtGranteeTerminationPriorToAmendment,
          )
        : "",
      grantAmendmentRequest.numberOfSharesAccelerating !== null
        ? intl.formatNumber(grantAmendmentRequest.numberOfSharesAccelerating)
        : "",
    ];

    return row.map(escapeCSV).join(CSV_SEPARATOR);
  });

  return useMemo(() => {
    const csv = [GRANTS_CSV_HEADER, ...rows].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    return URL.createObjectURL(blob);
  }, [rows]);
}
