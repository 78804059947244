/**
 * @generated SignedSource<<e48d011a726a40a53a95d6e284e68577>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL" | "VALUATION_PENDING_BOARD_APPROVAL" | "VALUATION_UNDER_REVIEW";
export type BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation$variables = {
  boardConsentSignatureDate: string;
  fairMarketValueId: string;
  signedBoardConsentDocumentIds: ReadonlyArray<string>;
};
export type BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation$data = {
  readonly importSignedBoardConsentApprovingFairMarketValue: {
    readonly valuationWarnings: ReadonlyArray<{
      readonly reason: ValuationWarningReason;
      readonly valuationType: ValuationType;
    }>;
  };
};
export type BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation = {
  response: BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation$data;
  variables: BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentSignatureDate"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fairMarketValueId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signedBoardConsentDocumentIds"
},
v3 = [
  {
    "kind": "Variable",
    "name": "boardConsentSignatureDate",
    "variableName": "boardConsentSignatureDate"
  },
  {
    "kind": "Variable",
    "name": "fairMarketValueId",
    "variableName": "fairMarketValueId"
  },
  {
    "kind": "Variable",
    "name": "signedBoardConsentDocumentIds",
    "variableName": "signedBoardConsentDocumentIds"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationValuationWarning",
  "kind": "LinkedField",
  "name": "valuationWarnings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "importSignedBoardConsentApprovingFairMarketValue",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "importSignedBoardConsentApprovingFairMarketValue",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "532011100e31709d43e8fd9f9f99d03b",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardApproval_FairMarketValues_ImportSignedBoardConsentApprovingFairMarketValue_Mutation(\n  $fairMarketValueId: ID!\n  $signedBoardConsentDocumentIds: [String!]!\n  $boardConsentSignatureDate: Date!\n) {\n  importSignedBoardConsentApprovingFairMarketValue(fairMarketValueId: $fairMarketValueId, signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds, boardConsentSignatureDate: $boardConsentSignatureDate) {\n    valuationWarnings {\n      reason\n      valuationType\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b133e90b3789b6226eb9dc94be9f715";

export default node;
