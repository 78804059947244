import { useCallback, useRef, useState } from "react";
import { fetchQuery, useRelayEnvironment } from "react-relay";
import { GraphQLTaggedNode, OperationType, Subscription } from "relay-runtime";

export const useManualQuery = <T extends OperationType>(
  query: GraphQLTaggedNode,
  {
    fetchPolicy = "network-only",
  }: {
    fetchPolicy?: "network-only" | "store-or-network";
  } = {},
) => {
  const [queryIsInFlight, setQueryIsInFlight] = useState(false);
  const relayEnvironment = useRelayEnvironment();
  const subscriptionRef = useRef<Subscription>();

  const triggerQuery = useCallback(
    ({
      onError = (error: unknown) => {
        throw error;
      },
      onResponse,
      variables,
    }: {
      onError?: (error: unknown) => void;
      onResponse: (response: T["response"]) => void;
      variables: T["variables"];
    }) => {
      if (subscriptionRef.current && !subscriptionRef.current.closed) {
        subscriptionRef.current.unsubscribe();
      }

      const observableQuery = fetchQuery<T>(
        relayEnvironment,
        query,
        variables,
        { fetchPolicy },
      );

      subscriptionRef.current = observableQuery.subscribe({
        error: (error: unknown) => {
          setQueryIsInFlight(false);
          onError(error);
        },
        next: (response) => {
          setQueryIsInFlight(false);
          onResponse(response);
        },
        start: () => {
          setQueryIsInFlight(true);
        },
      });

      return () => {
        if (subscriptionRef.current && !subscriptionRef.current.closed) {
          subscriptionRef.current.unsubscribe();
        }
      };
    },
    [query, relayEnvironment, fetchPolicy],
  );

  return [triggerQuery, queryIsInFlight] as const;
};
