import { FormattedDate } from "react-intl";

export const ShortDate = ({ value }: { value: Date | number | string }) => {
  return (
    <FormattedDate
      day="2-digit"
      month="short"
      timeZone="UTC"
      value={value}
      year="numeric"
    />
  );
};
