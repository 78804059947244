/**
 * @generated SignedSource<<2eb9a0a14dd5b7eb61b89d6dc3b406ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PlanningEntrySuggestionType = "LEVELING_GRANT" | "TENURE_GRANT";
import { FragmentRefs } from "relay-runtime";
export type PlanningEntrySuggestionsTable_PlanningEntrySuggestion$data = ReadonlyArray<{
  readonly grantee: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
  };
  readonly id: string;
  readonly shares: number | null;
  readonly type: PlanningEntrySuggestionType;
  readonly " $fragmentType": "PlanningEntrySuggestionsTable_PlanningEntrySuggestion";
}>;
export type PlanningEntrySuggestionsTable_PlanningEntrySuggestion$key = ReadonlyArray<{
  readonly " $data"?: PlanningEntrySuggestionsTable_PlanningEntrySuggestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsTable_PlanningEntrySuggestion">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlanningEntrySuggestionsTable_PlanningEntrySuggestion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeNameWithCountryFlag_Grantee"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PlanningEntrySuggestion",
  "abstractKey": null
};

(node as any).hash = "5f61be3ca2c025196f894037632c0dec";

export default node;
