/**
 * @generated SignedSource<<66bab7ca221e249ff67850b9dc022b29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation$variables = {
  postTerminationExercisePeriodId: any;
};
export type PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation$data = {
  readonly deleteOrganizationPostTerminationExercisePeriod: boolean;
};
export type PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation = {
  response: PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation$data;
  variables: PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "postTerminationExercisePeriodId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "postTerminationExercisePeriodId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteOrganizationPostTerminationExercisePeriod",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f6d546afbf69cb6a15c9285e368eab9",
    "id": null,
    "metadata": {},
    "name": "PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation",
    "operationKind": "mutation",
    "text": "mutation PostTermination_DeleteOrganizationPostTerminationExercisePeriodMutation(\n  $postTerminationExercisePeriodId: OrganizationPostTerminationExercisePeriodId!\n) {\n  deleteOrganizationPostTerminationExercisePeriod(id: $postTerminationExercisePeriodId)\n}\n"
  }
};
})();

(node as any).hash = "76b1cd4def0582fd9b809f056024e1f4";

export default node;
