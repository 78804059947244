/**
 * @generated SignedSource<<d72e67c4e22fa6f64bbf07669fc49cae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeFormSlide_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_Grantee">;
  readonly " $fragmentType": "GranteeFormSlide_Grantee";
};
export type GranteeFormSlide_Grantee$key = {
  readonly " $data"?: GranteeFormSlide_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeFormSlide_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeForm_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "a1aeb89f2786f7220527cbabe87ad32d";

export default node;
