/**
 * @generated SignedSource<<59feb1d7f72b0a19108105195fc29ef4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteLikeGranteePortalLayout_Query$variables = {
  granteeId: string;
};
export type RemoteLikeGranteePortalLayout_Query$data = {
  readonly grantee: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Organization">;
    };
  } | null;
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  };
};
export type RemoteLikeGranteePortalLayout_Query = {
  response: RemoteLikeGranteePortalLayout_Query$data;
  variables: RemoteLikeGranteePortalLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoteLikeGranteePortalLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApplicationSideBar_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ApplicationSideBar_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoteLikeGranteePortalLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizationsAsAdmin",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8644ce3ad2b1fdfaeec4899957f8519a",
    "id": null,
    "metadata": {},
    "name": "RemoteLikeGranteePortalLayout_Query",
    "operationKind": "query",
    "text": "query RemoteLikeGranteePortalLayout_Query(\n  $granteeId: GranteeId!\n) {\n  grantee(id: $granteeId) {\n    organization {\n      ...ApplicationSideBar_Organization\n      id\n    }\n    id\n  }\n  me {\n    ...ApplicationSideBar_Viewer\n    id\n  }\n}\n\nfragment ApplicationSideBarContent_Organization on Organization {\n  ...OrganizationRoleSwitcher_Organization\n}\n\nfragment ApplicationSideBarContent_Viewer on Account {\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment ApplicationSideBar_Organization on Organization {\n  ...ApplicationSideBarContent_Organization\n  ...OrganizationRoleSwitcher_Organization\n}\n\nfragment ApplicationSideBar_Viewer on Account {\n  ...ApplicationSideBarContent_Viewer\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment OrganizationRoleSwitcher_Account on Account {\n  isSuperAdmin\n  ...useAccountRoles_Account\n}\n\nfragment OrganizationRoleSwitcher_Organization on Organization {\n  name\n  ...useAccountRoles_Organization\n}\n\nfragment useAccountRoles_Account on Account {\n  organizationsAsAdmin {\n    id\n  }\n  boardMembers {\n    id\n    organization {\n      id\n    }\n  }\n}\n\nfragment useAccountRoles_Organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "59c2443dee2c16af2559df0c4ff1dcae";

export default node;
