import classNames from "classnames";
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EsopChartGrantedStacked } from "../../../../components/EsopChartGrantedStacked";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { ApprovalSlideOverChartCard_GrantBoardConsent$key } from "./__generated__/ApprovalSlideOverChartCard_GrantBoardConsent.graphql";

const BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverChartCard_GrantBoardConsent on GrantBoardConsent {
    __typename
    totalGrantedShares
    organization {
      ...useOrganizationSharesUtil_Organization
      ...EsopChartGrantedStacked_Organization
      poolAvailableShares
      poolVestedExercisedSharesBreakdown {
        total
      }
      poolVestedToExerciseSharesBreakdown {
        total
      }
      poolGrantedToVestSharesBreakdown {
        total
      }
      poolSettlingSharesBreakdown {
        total
      }
      poolPendingSharesBreakdown {
        total
      }
      esopPoolShares
    }
  }
`;

export const ApprovalSlideOverChartCard: React.FC<
  React.ComponentProps<"div"> & {
    grantBoardConsentFragment: ApprovalSlideOverChartCard_GrantBoardConsent$key;
  }
> = ({ className, grantBoardConsentFragment, ...props }) => {
  const boardConsent = useFragment(
    BOARD_CONSENT_FRAGMENT,
    grantBoardConsentFragment,
  );

  const { sharesToFullyDilutedRatio } = useOrganizationSharesUtil({
    organizationFragment: boardConsent.organization,
  });

  const boardConsentGrantedShares = boardConsent.totalGrantedShares;

  const preBoardConsentPoolPendingShares =
    boardConsent.organization.poolPendingSharesBreakdown.total -
    boardConsentGrantedShares;

  const preGrantAvailableShares =
    boardConsent.organization.poolAvailableShares +
    boardConsent.organization.poolPendingSharesBreakdown.total;

  const postGrantAvailableShares =
    preGrantAvailableShares - boardConsentGrantedShares;

  const esopPoolShares = boardConsent.organization.esopPoolShares;
  const esopPoolFullyDilutedRatio =
    esopPoolShares !== null ? sharesToFullyDilutedRatio(esopPoolShares) : null;

  return (
    <div className={classNames("pb-8", className)} {...props}>
      {esopPoolShares !== null && (
        <div className="flex justify-between">
          {esopPoolFullyDilutedRatio !== null ? (
            <div className="flex flex-col gap-1">
              <Typography className="text-black-07" variant="Medium/Default">
                <FormattedPercentage value={esopPoolFullyDilutedRatio} />
              </Typography>
              <Typography
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Fully diluted
              </Typography>
            </div>
          ) : (
            <div />
          )}

          <div className="flex flex-col gap-1">
            <Typography
              className="text-right text-black-07"
              variant="Medium/Default"
            >
              <FormattedNumber value={esopPoolShares} />
            </Typography>
            <Typography
              className="text-right text-black-05"
              variant="Regular/Extra Small"
            >
              Shares
            </Typography>
          </div>
        </div>
      )}

      <div className="text-center text-black-05">
        <EsopChartGrantedStacked
          availablePending={preBoardConsentPoolPendingShares}
          availableWithoutPending={
            postGrantAvailableShares - preBoardConsentPoolPendingShares
          }
          currentApproval={boardConsent.totalGrantedShares}
          grantedSettling={
            boardConsent.organization.poolSettlingSharesBreakdown.total
          }
          grantedToVest={
            boardConsent.organization.poolGrantedToVestSharesBreakdown.total
          }
          grantedVestedExercised={
            boardConsent.organization.poolVestedExercisedSharesBreakdown.total
          }
          grantedVestedToExercise={
            boardConsent.organization.poolVestedToExerciseSharesBreakdown.total
          }
          height={240}
          organizationFragment={boardConsent.organization}
        />
      </div>
    </div>
  );
};
