/**
 * @generated SignedSource<<b5dc9df1361147573ca01c3418828989>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query$variables = {
  date: string;
  granteeId: string;
};
export type TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query$data = {
  readonly willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate: boolean;
};
export type TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query = {
  response: TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query$data;
  variables: TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      },
      {
        "kind": "Variable",
        "name": "granteeId",
        "variableName": "granteeId"
      }
    ],
    "kind": "ScalarField",
    "name": "willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3e9388ec18d6cbdbd4e5967fca834b54",
    "id": null,
    "metadata": {},
    "name": "TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query",
    "operationKind": "query",
    "text": "query TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query(\n  $granteeId: GranteeId!\n  $date: Date!\n) {\n  willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate(granteeId: $granteeId, date: $date)\n}\n"
  }
};
})();

(node as any).hash = "64bc6e73764966c9c15fbb000dc68d96";

export default node;
