import { zodResolver } from "@hookform/resolvers/zod";
import _, { debounce, isEmpty, isNil } from "lodash";
import { useCallback, useMemo, useState, useTransition } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../components/ui/Button";
import { SelectAutocomplete } from "../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { SearchBar } from "../../components/ui/SearchBar";
import { Switch } from "../../components/ui/Switch";
import { GrantsFilters_Organization$key } from "./__generated__/GrantsFilters_Organization.graphql";
import { OrganizationCTMSGrantsFilters } from "./Grants/__generated__/Grants_OrganizationCtmsGrantsPagination_Query.graphql";

const filterFormSchema = z.object({
  equityType: z.string().optional(),
  granteeId: z.string().optional(),
  vestingStatus: z.enum(["ALL", "VESTING", "FULLY_VESTED"]),
});

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantsFilters_Organization on Organization {
    allctmsGrants: ctmsGrants {
      edges {
        node {
          grantee {
            name
            id
          }
          equityTypeAwardName
        }
      }
    }
  }
`;

export type FilterFormSchema = z.infer<typeof filterFormSchema>;

const VESTING_STATUSES = [
  { label: "All", value: "ALL" },
  { label: "Vesting", value: "VESTING" },
  { label: "Fully vested", value: "FULLY_VESTED" },
] as const;

export const GrantsFilters: React.FC<{
  filters: OrganizationCTMSGrantsFilters;
  onFiltersChange: (filters: OrganizationCTMSGrantsFilters) => void;
  organizationFragment: GrantsFilters_Organization$key;
}> = ({ filters, onFiltersChange, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grants = useMemo(
    () => organization.allctmsGrants.edges.map(({ node }) => node),
    [organization],
  );

  const grantees = useMemo(
    () =>
      _(grants)
        .map((grant) => ({
          label: grant.grantee.name,
          value: grant.grantee.id,
        }))
        .uniqBy((option) => option.value)
        .sortBy((option) => option.label)
        .value(),
    [grants],
  );

  const equityTypes = useMemo(
    () =>
      _(grants)
        .map((grant) => grant.equityTypeAwardName)
        .compact()
        .uniq()
        .sortBy()
        .map((equityType) => ({ value: equityType }))
        .value(),
    [grants],
  );

  const filterForm = useForm<z.infer<typeof filterFormSchema>>({
    resolver: zodResolver(filterFormSchema),
    values: {
      equityType: filters.equityTypeIn?.[0],
      granteeId: filters.granteeId ?? undefined,
      vestingStatus:
        !filters.vestingStatus || filters.vestingStatus === "CLIFF_NOT_PASSED"
          ? "ALL"
          : filters.vestingStatus,
    },
  });

  const someFiltersAreActive = useMemo(
    () => !isNil(filters.granteeId) || !isEmpty(filters.equityTypeIn),
    [filters.equityTypeIn, filters.granteeId],
  );

  const selectedGrantee = useMemo(() => {
    if (!filters.granteeId) {
      return null;
    }
    return (
      grantees.find((option) => option.value === filters.granteeId) ?? null
    );
  }, [filters.granteeId, grantees]);

  const [filtersTransitionInProgress, startFiltersTransition] = useTransition();
  const handleSubmit = filterForm.handleSubmit((submittedFilters) => {
    startFiltersTransition(() => {
      onFiltersChange({
        ...filters,
        equityTypeIn: submittedFilters.equityType
          ? [submittedFilters.equityType]
          : undefined,
        granteeId: submittedFilters.granteeId,
        vestingStatus:
          submittedFilters.vestingStatus === "ALL"
            ? null
            : submittedFilters.vestingStatus,
      });
    });
  });

  const [searchTransitionInProgress, startSearchTransition] = useTransition();
  const onFullTextFilterChange = useCallback(
    (search: string) => {
      startSearchTransition(() => {
        onFiltersChange({ ...filters, search });
      });
    },
    [filters, onFiltersChange],
  );

  const debouncedOnFullTextFilterChange = useMemo(
    () => debounce(onFullTextFilterChange, 500),
    [onFullTextFilterChange],
  );

  const [realTimeFullTextFilter, setRealTimeFullTextFilter] = useState(
    filters.search ?? "",
  );

  return (
    <div className="space-y-4">
      <div className="relative flex items-center justify-between gap-4">
        <div className="relative flex items-center gap-4">
          {filters.statusIn?.includes("Active") && (
            <Controller
              control={filterForm.control}
              name="vestingStatus"
              render={({ field }) => (
                <Switch
                  getOptionValue={(option) => option.label}
                  name="vesting-status"
                  onChange={(option) => {
                    startFiltersTransition(() => {
                      field.onChange(option.value);
                      onFiltersChange({
                        ...filters,
                        vestingStatus:
                          option.value === "ALL" ? null : option.value,
                      });
                    });
                  }}
                  options={VESTING_STATUSES}
                  selectedOption={
                    VESTING_STATUSES.find(
                      (option) => option.value === field.value,
                    )!
                  }
                />
              )}
            />
          )}
          <SearchBar.FiltersBox
            popoverButton={
              <SearchBar.FiltersBoxPopoverButton
                loading={filtersTransitionInProgress}
              />
            }
            renderApplyButton={() => <Button />}
            renderClearButton={(close) => (
              <Button
                onClick={() => {
                  filterForm.reset();
                  void handleSubmit();
                  close();
                }}
              />
            )}
            renderForm={(close) => (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  void handleSubmit();
                  close();
                }}
              />
            )}
          >
            <SearchBar.FiltersBoxItem label="Grantee">
              <Controller
                control={filterForm.control}
                name="granteeId"
                render={({ field, fieldState }) => (
                  <SelectAutocomplete
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    invalid={!!fieldState.error}
                    name={field.name}
                    onChange={(option) => {
                      field.onChange(option?.value);
                    }}
                    options={grantees}
                    placeholder="Filter..."
                    usePortal
                    value={grantees.find(
                      (option) => option.value === field.value,
                    )}
                  />
                )}
              />
            </SearchBar.FiltersBoxItem>
            <SearchBar.FiltersBoxItem label="Equity type">
              <Controller
                control={filterForm.control}
                name="equityType"
                render={({ field, fieldState }) => (
                  <SelectAutocomplete
                    getOptionLabel={(option) => option.value}
                    getOptionValue={(option) => option.value}
                    invalid={!!fieldState.error}
                    name={field.name}
                    onChange={(option) => {
                      field.onChange(option?.value);
                    }}
                    options={equityTypes}
                    placeholder="Filter..."
                    usePortal
                    value={equityTypes.find(
                      (option) => option.value === field.value,
                    )}
                  />
                )}
              />
            </SearchBar.FiltersBoxItem>
          </SearchBar.FiltersBox>
        </div>

        <SearchBar
          className="w-full max-w-[300px]"
          loading={searchTransitionInProgress}
          onChange={(value) => {
            setRealTimeFullTextFilter(value);
            debouncedOnFullTextFilterChange(value);
          }}
          placeholder="Search grants"
          value={realTimeFullTextFilter}
        />
      </div>
      {someFiltersAreActive && (
        <SearchBar.FilterChipsContainer
          onClearAllFiltersButtonClick={() => {
            filterForm.reset();
            void handleSubmit();
          }}
        >
          {selectedGrantee && (
            <SearchBar.FilterChip
              label="Grantee"
              onRemoveButtonClick={() => {
                filterForm.setValue("granteeId", undefined);
                void handleSubmit();
              }}
            >
              {selectedGrantee.label}
            </SearchBar.FilterChip>
          )}
          {filters.equityTypeIn?.[0] && (
            <SearchBar.FilterChip
              label="Equity type"
              onRemoveButtonClick={() => {
                filterForm.setValue("equityType", undefined);
                void handleSubmit();
              }}
            >
              {filters.equityTypeIn[0]}
            </SearchBar.FilterChip>
          )}
        </SearchBar.FilterChipsContainer>
      )}
    </div>
  );
};
