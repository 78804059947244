import classNames from "classnames";
import { compact, isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import {
  GranteesListSlideOver,
  useGranteesListSlideOverState,
} from "../../../../components/GranteesListSlideOver";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { Typography } from "../../../../components/ui/Typography";
import {
  EquityGridLevelsGraph_EquityGridLevel$data,
  EquityGridLevelsGraph_EquityGridLevel$key,
} from "./__generated__/EquityGridLevelsGraph_EquityGridLevel.graphql";
import {
  EquityGridLevelsGraph_Organization$data,
  EquityGridLevelsGraph_Organization$key,
} from "./__generated__/EquityGridLevelsGraph_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityGridLevelsGraph_Organization on Organization {
    grantees {
      edges {
        node {
          id
          status
          equityGridLevel {
            id
          }
          offGrid
          missingSharesToEquityGridLevel
        }
      }
    }
    ...GranteesListSlideOver_Organization
  }
`;

const EQUITY_GRID_LEVEL_FRAGMENT = graphql`
  fragment EquityGridLevelsGraph_EquityGridLevel on EquityGridLevel
  @relay(plural: true) {
    id
    name
  }
`;

const Tooltip: React.FC<{
  barClassName: string;
  barKey:
    | "above-level"
    | "below-level"
    | "off-grid"
    | "on-level"
    | "without-level";
  filteredEquityGridLevels: EquityGridLevelsGraph_EquityGridLevel$data;
  granteesCount: number;
}> = ({ barClassName, barKey, filteredEquityGridLevels, granteesCount }) => {
  const title = useMemo(() => {
    switch (barKey) {
      case "above-level":
        return "Above equity grid level";
      case "below-level":
        return "Below equity grid level";
      case "off-grid":
        return "Marked as off grid";
      case "on-level":
        return "Right on equity grid level";
      case "without-level":
        return "No equity grid level";
    }
  }, [barKey]);
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grantees"
      topContent={
        <div className="space-y-4">
          <div>{title}</div>
          {!isEmpty(filteredEquityGridLevels) && (
            <div>
              <div>Filter on levels:</div>
              <ul className="list-inside list-disc">
                {filteredEquityGridLevels.map((level) => (
                  <li key={level.id}>{level.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      }
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{granteesCount, plural,
                =0 {# grantee}
                =1 {# grantee}
                other {# grantees}
              }`}
              values={{ granteesCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

type Grantee =
  EquityGridLevelsGraph_Organization$data["grantees"]["edges"][number]["node"];

export const EquityGridLevelsGraph: React.FC<{
  filteredEquityGridLevelsFragment: EquityGridLevelsGraph_EquityGridLevel$key;
  organizationFragment: EquityGridLevelsGraph_Organization$key;
}> = ({ filteredEquityGridLevelsFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const filteredEquityGridLevels = useFragment(
    EQUITY_GRID_LEVEL_FRAGMENT,
    filteredEquityGridLevelsFragment,
  );

  const {
    granteesAboveLevel,
    granteesBelowLevel,
    granteesOffGrid,
    granteesOnLevel,
    granteesWithoutLevel,
  } = useMemo(() => {
    const activeGrantees = organization.grantees.edges
      .map((edge) => edge.node)
      .filter((grantee) => {
        switch (grantee.status) {
          case "Active":
            return true;
          case "Terminated":
            return false;
        }
      });

    return activeGrantees.reduce<{
      granteesAboveLevel: Grantee[];
      granteesBelowLevel: Grantee[];
      granteesOffGrid: Grantee[];
      granteesOnLevel: Grantee[];
      granteesWithoutLevel: Grantee[];
    }>(
      (acc, grantee) => {
        if (
          !isEmpty(filteredEquityGridLevels) &&
          !filteredEquityGridLevels.find(
            (level) => level.id === grantee.equityGridLevel?.id,
          )
        ) {
          return acc;
        }

        if (!grantee.equityGridLevel) {
          acc.granteesWithoutLevel.push(grantee);
        } else {
          if (grantee.offGrid) {
            acc.granteesOffGrid.push(grantee);
          } else if (grantee.missingSharesToEquityGridLevel === null) {
            return acc;
          } else if (grantee.missingSharesToEquityGridLevel < 0) {
            acc.granteesAboveLevel.push(grantee);
          } else if (grantee.missingSharesToEquityGridLevel > 0) {
            acc.granteesBelowLevel.push(grantee);
          } else {
            acc.granteesOnLevel.push(grantee);
          }
        }
        return acc;
      },
      {
        granteesAboveLevel: [],
        granteesBelowLevel: [],
        granteesOffGrid: [],
        granteesOnLevel: [],
        granteesWithoutLevel: [],
      },
    );
  }, [organization.grantees, filteredEquityGridLevels]);

  const stacks = useMemo(
    () =>
      compact([
        {
          className: classNames("bg-purple-05 fill-purple-05"),
          grantees: granteesAboveLevel,
          key: "above-level",
          label: "Above level",
          value: granteesAboveLevel.length,
        },
        {
          className: classNames("bg-green-05 fill-green-05"),
          grantees: granteesOnLevel,
          key: "on-level",
          label: "On level",
          value: granteesOnLevel.length,
        },
        {
          className: classNames("bg-orange-05 fill-orange-05"),
          grantees: granteesBelowLevel,
          key: "below-level",
          label: "Below level",
          value: granteesBelowLevel.length,
        },
        {
          className: classNames("bg-primary-05 fill-primary-05"),
          grantees: granteesOffGrid,
          key: "off-grid",
          label: "Off grid",
          value: granteesOffGrid.length,
        },
        {
          className: classNames("bg-gray-05 fill-gray-05"),
          grantees: granteesWithoutLevel,
          key: "without-level",
          label: "No level",
          value: granteesWithoutLevel.length,
        },
      ] as const).map((stack) => ({
        ...stack,
        elements: [
          {
            className: stack.className,
            key: stack.key,
            value: stack.value,
          },
        ],
      })),
    [
      granteesAboveLevel,
      granteesOnLevel,
      granteesBelowLevel,
      granteesOffGrid,
      granteesWithoutLevel,
    ],
  );

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useGranteesListSlideOverState();

  const getGranteePositionLabelForSlideOver = useCallback(
    (barKey: (typeof stacks)[number]["key"]) => {
      switch (barKey) {
        case "above-level":
          return "above equity grid level";
        case "below-level":
          return "below equity grid level";
        case "off-grid":
          return "marked as off grid";
        case "on-level":
          return "right on equity grid level";
        case "without-level":
          return "with no equity grid level";
      }
    },
    [],
  );

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) =>
      openSlideOver({
        granteesIds: stack.grantees.map((grantee) => grantee.id),
        title: (
          <FormattedMessage
            defaultMessage={`There {granteesCount, plural, 
            =0 {is # grantee} 
            =1 {is # grantee} 
            other {are # grantees}} {granteePosition}`}
            values={{
              granteePosition: getGranteePositionLabelForSlideOver(stack.key),
              granteesCount: stack.grantees.length,
            }}
          />
        ),
      }),
    [openSlideOver, getGranteePositionLabelForSlideOver],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        barKey={stack.key}
        filteredEquityGridLevels={filteredEquityGridLevels}
        granteesCount={stack.grantees.length}
      />
    ),
    [filteredEquityGridLevels],
  );

  return (
    <>
      <GranteesListSlideOver
        onClose={closeSlideOver}
        organizationFragment={organization}
        state={slideOverState}
      />
      <BarGraph
        barGap={100}
        barSize={40}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={stacks}
        yLabel="# of grantees"
        yTickFormatter={(v) => v.toString()}
      />
    </>
  );
};
