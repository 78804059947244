// Component adapted to React and inspired by https://tailwindcomponents.com/component/datepicker-with-tailwindcss-and-alpinejs
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import {
  addMonths,
  addYears,
  max as dateFnsMaxDate,
  min as dateFnsMinDate,
  eachDayOfInterval,
  eachYearOfInterval,
  endOfMonth,
  endOfWeek,
  formatISO,
  getDay,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
} from "date-fns";
import { compact, isDate, range } from "lodash";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { FormattedDate } from "react-intl";

import { ShortDate } from "../../../ShortDate";
import { MenuButtonPlacement } from "../../MenuButton";
import { Typography } from "../../Typography";
import { getInputWrapperClassName } from "./Input";
import { Select } from "./Select/Select";

const now = new Date();

const week = eachDayOfInterval({
  end: endOfWeek(now),
  start: startOfWeek(now),
});

interface Props {
  className?: string;
  disabled?: boolean;
  invalid?: boolean;
  maxDate?: Date | null | string;
  minDate?: Date | null | string;
  onChange: (value: string) => void;
  panelPosition?: MenuButtonPlacement;
  placeholder?: string;
  popoverButtonClassName?: string;
  prefixDate?: string;
  value?: null | string;
}

const parseOptionalDateString = (date?: Date | null | string) => {
  if (!date) {
    return null;
  }

  if (isDate(date)) {
    return date;
  }

  return parseISO(date);
};

const DatePicker_ = forwardRef<HTMLInputElement, Props>(function DatePicker_(
  {
    className,
    disabled = false,
    invalid = false,
    maxDate: _maxDate,
    minDate: _minDate,
    onChange,
    panelPosition = "top",
    placeholder,
    popoverButtonClassName,
    prefixDate,
    value,
  },
  ref,
) {
  const minDate = parseOptionalDateString(_minDate);
  const maxDate = parseOptionalDateString(_maxDate);

  const defaultDate = useMemo(() => {
    if (value) {
      return parseISO(value);
    }

    const now = new Date();

    if (minDate && isBefore(now, minDate)) return minDate;
    if (maxDate && isAfter(now, maxDate)) return maxDate;

    return now;
  }, [value, minDate, maxDate]);

  const [_activeDate, setActiveDate] = useState<Date | null>(null);

  const activeDate = useMemo(
    () => _activeDate ?? defaultDate,
    [_activeDate, defaultDate],
  );

  const firstDayOfMonthOffset = useMemo(
    () => range(0, getDay(startOfMonth(activeDate))),
    [activeDate],
  );

  const daysInActiveMonth = useMemo(
    () =>
      eachDayOfInterval({
        end: endOfMonth(activeDate),
        start: startOfMonth(activeDate),
      }),
    [activeDate],
  );

  const availableYearsRange = useMemo(
    () =>
      eachYearOfInterval({
        end: dateFnsMinDate(compact([maxDate, addYears(activeDate, 30)])),
        start: dateFnsMaxDate(compact([minDate, new Date("1970")])),
      }),
    [activeDate, maxDate, minDate],
  );

  const handlePreviousMonthButtonClick = useCallback(() => {
    setActiveDate(subMonths(activeDate, 1));
  }, [activeDate]);

  const handleNextMonthButtonClick = useCallback(() => {
    setActiveDate(addMonths(activeDate, 1));
  }, [activeDate]);

  const isDaySelected = (day: Date) => {
    if (!value) {
      return false;
    }

    return isSameDay(day, parseISO(value));
  };

  function isDayDisabled(day: Date) {
    if (minDate && isBefore(day, minDate) && !isSameDay(day, minDate)) {
      return true;
    }

    if (maxDate && isAfter(day, maxDate) && !isSameDay(day, maxDate)) {
      return true;
    }

    return false;
  }

  function handleDayClick(day: Date) {
    const date = formatISO(day, { representation: "date" });

    setActiveDate(day);
    onChange(date);
  }

  return (
    <Popover className={classNames("relative", className)}>
      <input name="date" ref={ref} type="hidden" value={value ?? ""} />

      <PopoverButton
        className={classNames(
          "date-picker relative block w-full cursor-default py-4 pl-3 pr-10 text-left",
          getInputWrapperClassName({ disabled, invalid }),
          popoverButtonClassName,
        )}
        disabled={disabled}
      >
        {value ? (
          <Typography className="block truncate" variant="Regular/Extra Small">
            {prefixDate}
            <ShortDate value={value} />
          </Typography>
        ) : placeholder ? (
          <Typography className="text-gray-08" variant="Regular/Extra Small">
            {placeholder}
          </Typography>
        ) : null}

        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CalendarIcon aria-hidden="true" className="h-5 w-5 text-gray-06" />
        </span>
      </PopoverButton>
      <PopoverPanel
        anchor={{ gap: 8, to: panelPosition }}
        className={classNames("z-10 w-72 rounded-lg bg-white p-4 shadow")}
      >
        {({ close }) => (
          <>
            <div
              className="mb-2 flex items-center justify-between"
              data-cy="date-picker-modal"
            >
              <div className="flex items-center gap-2">
                <span className="text-lg font-bold">
                  <FormattedDate month="long" value={activeDate} />
                </span>
                <span className="ml-1 text-lg font-normal text-gray-08">
                  <Select
                    getOptionLabel={(date) => date.getFullYear().toString()}
                    getOptionValue={(date) => date.getFullYear().toString()}
                    onChange={(value) => {
                      if (value) {
                        setActiveDate(setYear(activeDate, parseInt(value)));
                      }
                    }}
                    options={availableYearsRange}
                    value={activeDate.getFullYear().toString()}
                  />
                </span>
              </div>
              <div className="flex shrink-0">
                <button
                  className="inline-flex shrink-0 cursor-pointer rounded-full p-1 text-gray-07 transition duration-100 ease-in-out hover:text-black"
                  data-cy="date-picker-previous-month"
                  onClick={() => {
                    handlePreviousMonthButtonClick();
                  }}
                  type="button"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
                <button
                  className="inline-flex cursor-pointer rounded-full p-1 text-gray-07 transition duration-100 ease-in-out hover:text-black"
                  data-cy="date-picker-next-month"
                  onClick={() => {
                    handleNextMonthButtonClick();
                  }}
                  type="button"
                >
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="grid select-none grid-cols-7 py-2">
              {week.map((day) => (
                <div
                  className="text-center text-xs font-medium text-black-06"
                  key={day.toISOString()}
                >
                  <FormattedDate value={day} weekday="short" />
                </div>
              ))}
            </div>

            <div className="grid grid-cols-7 grid-rows-6 gap-2">
              {firstDayOfMonthOffset.map((day) => (
                <div
                  className="border border-transparent p-1 text-center text-sm"
                  key={day}
                />
              ))}

              {daysInActiveMonth.map((day) => {
                const dayIsSelected = isDaySelected(day);
                const dayIsDisabled = isDayDisabled(day);

                return (
                  <button
                    className={classNames(
                      "select-none rounded-full px-1 text-center text-sm leading-loose transition duration-100 ease-in-out disabled:pointer-events-none disabled:opacity-40",
                      {
                        "bg-primary-05 text-white": dayIsSelected,
                        "hover:bg-primary-02": !dayIsSelected,
                      },
                    )}
                    disabled={dayIsDisabled}
                    key={day.toISOString()}
                    onClick={() => {
                      handleDayClick(day);
                      close();
                    }}
                  >
                    <FormattedDate day="numeric" value={day} />
                  </button>
                );
              })}
            </div>
          </>
        )}
      </PopoverPanel>
    </Popover>
  );
});

const FormDatePicker = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: Omit<React.ComponentProps<typeof DatePicker_>, "onChange" | "value"> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <DatePicker_ {...field} {...props} />}
    />
  );
};

export const DatePicker = Object.assign(DatePicker_, {
  Form: FormDatePicker,
});
