import _, { partition } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GranteesListSlideOver,
  useGranteesListSlideOverState,
} from "../../../../components/GranteesListSlideOver";
import { Page } from "../../../../components/Page";
import { Switch } from "../../../../components/ui/Switch";
import { Typography } from "../../../../components/ui/Typography";
import { useManualQuery } from "../../../../hooks/useManualQuery";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import {
  TeamIncentivization_Organization$data,
  TeamIncentivization_Organization$key,
} from "./__generated__/TeamIncentivization_Organization.graphql";
import { TeamIncentivization_Query } from "./__generated__/TeamIncentivization_Query.graphql";
import {
  TeamIncentivization_TeamIncentivizationDataPoint$data,
  TeamIncentivization_TeamIncentivizationDataPoint$key,
} from "./__generated__/TeamIncentivization_TeamIncentivizationDataPoint.graphql";
import { TeamIncentivization_UpdateDataPoints_Query } from "./__generated__/TeamIncentivization_UpdateDataPoints_Query.graphql";
import {
  TeamIncentivizationGraph,
  TeamIncentivizationGraphMode,
  TeamIncentivizationGraphModes,
} from "./TeamIncentivizationGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TeamIncentivization_Organization on Organization {
    id
    grantees {
      edges {
        node {
          status
          grantedSharesLatestValuation
        }
      }
    }
    ...TeamIncentivizationGraph_Organization
    ...GranteesListSlideOver_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment TeamIncentivization_TeamIncentivizationDataPoint on TeamIncentivizationDataPoint
  @relay(plural: true) {
    valueInUSD
    grantees {
      id
    }
    ...TeamIncentivizationGraph_TeamIncentivizationDataPoint
  }
`;

const UPDATE_DATA_POINTS_QUERY = graphql`
  query TeamIncentivization_UpdateDataPoints_Query(
    $organizationId: OrganizationId!
    $vestingMode: TeamIncentivizationVestingMode!
  ) {
    teamIncentivizationDataPoints(
      organizationId: $organizationId
      vestingMode: $vestingMode
    ) {
      ...TeamIncentivization_TeamIncentivizationDataPoint
    }
  }
`;

const ExcludedGrantees: React.FC<{
  grantees: TeamIncentivization_TeamIncentivizationDataPoint$data[number]["grantees"];
  mode: TeamIncentivizationGraphMode;
  organization: TeamIncentivization_Organization$data;
}> = ({ grantees, mode, organization }) => {
  const {
    close: closeGranteesListSlideOver,
    open: openGranteesListSlideOver,
    state: granteesListSlideOverState,
  } = useGranteesListSlideOverState();

  const granteesButton = useCallback(
    (chunks: React.ReactNode[]) => {
      return (
        <button
          className="font-semibold underline"
          onClick={() => {
            openGranteesListSlideOver({
              granteesIds: _(grantees)
                .map((grantee) => grantee.id)
                .value(),
            });
          }}
        >
          {chunks}
        </button>
      );
    },
    [grantees, openGranteesListSlideOver],
  );

  const message = useMemo(() => {
    switch (mode) {
      case "TO_VEST":
        return (
          <FormattedMessage
            defaultMessage="Have vested all their shares: <granteesButton>{granteeCount, plural, one {# grantee} other {# grantees}}</granteesButton>"
            values={{ granteeCount: grantees.length, granteesButton }}
          />
        );
      case "TOTAL_GRANTED":
        return (
          <FormattedMessage
            defaultMessage="Haven’t received any shares yet: <granteesButton>{granteeCount, plural, one {# grantee} other {# grantees}}</granteesButton>"
            values={{ granteeCount: grantees.length, granteesButton }}
          />
        );
      case "VESTED":
        return (
          <FormattedMessage
            defaultMessage="<granteesButton>{granteeCount, plural, one {# grantee} other {# grantees}}</granteesButton> that have not vested any shares yet."
            values={{ granteeCount: grantees.length, granteesButton }}
          />
        );
    }
  }, [grantees.length, granteesButton, mode]);

  if (!grantees.length) {
    return null;
  }

  return (
    <>
      <GranteesListSlideOver
        onClose={closeGranteesListSlideOver}
        organizationFragment={organization}
        state={granteesListSlideOverState}
      />
      <Typography
        as="div"
        className="rounded-lg bg-orange-01 px-4 py-2 text-orange-09"
        variant="Regular/Extra Small"
      >
        {message}
      </Typography>
    </>
  );
};

const INITIAL_MODE = "TO_VEST";

const AdminTeamIncentivizationPage_: React.FC<{
  dataPointsFragment: TeamIncentivization_TeamIncentivizationDataPoint$key;
  organizationFragment: TeamIncentivization_Organization$key;
  selectedMode: TeamIncentivizationGraphMode;
  setSelectedMode: (mode: TeamIncentivizationGraphMode) => void;
}> = ({
  dataPointsFragment: _dataPointsFragment,
  organizationFragment,
  selectedMode,
  setSelectedMode,
}) => {
  const [dataPointsFragment, setDataPointsFragment] =
    useState<TeamIncentivization_TeamIncentivizationDataPoint$key>(
      _dataPointsFragment,
    );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  const [_updateDataPoints] =
    useManualQuery<TeamIncentivization_UpdateDataPoints_Query>(
      UPDATE_DATA_POINTS_QUERY,
      { fetchPolicy: "store-or-network" },
    );

  const updateDataPoints = useCallback(
    (mode: TeamIncentivizationGraphMode) => {
      _updateDataPoints({
        onResponse: (response) => {
          setDataPointsFragment(response.teamIncentivizationDataPoints);
        },
        variables: {
          organizationId: organization.id,
          vestingMode: mode,
        },
      });
    },
    [_updateDataPoints, organization.id, setDataPointsFragment],
  );

  const mostIncentivizedActiveGranteeUSDValue = useMemo(() => {
    const mostIncentivizedActiveGrantee = _(organization.grantees.edges)
      .map((edge) => edge.node)
      .filter((grantee) => grantee.status === "Active")
      .maxBy((grantee) => {
        return grantee.grantedSharesLatestValuation;
      });
    if (!mostIncentivizedActiveGrantee) {
      return null;
    }
    return mostIncentivizedActiveGrantee.grantedSharesLatestValuation;
  }, [organization.grantees]);

  const [zeroValueDataPoints, nonZeroValueDataPoints] = useMemo(() => {
    return partition(dataPoints, (dataPoint) => dataPoint.valueInUSD === 0);
  }, [dataPoints]);

  const excludedGrantees = useMemo(() => {
    return _(zeroValueDataPoints)
      .flatMap((dataPoint) => dataPoint.grantees)
      .uniqBy((grantee) => grantee.id)
      .value();
  }, [zeroValueDataPoints]);

  return (
    <InsightPage
      subtitle="Aggregated $-value of grants vs. number of grants received per grantee"
      title="⭐️ Team incentivization"
      yellowBanner={
        mostIncentivizedActiveGranteeUSDValue ? (
          <>
            Most incentivized grantee:{" "}
            <Typography variant="Medium/Extra Small">
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={0}
                style="currency"
                value={mostIncentivizedActiveGranteeUSDValue}
              />
            </Typography>
          </>
        ) : null
      }
    >
      <Switch
        getOptionLabel={(option, selected) => {
          switch (option) {
            case "TO_VEST":
              return <Switch.Option label="To vest" selected={selected} />;
            case "TOTAL_GRANTED":
              return (
                <Switch.Option label="Total granted" selected={selected} />
              );
            case "VESTED":
              return <Switch.Option label="Vested" selected={selected} />;
          }
        }}
        getOptionValue={(option) => option}
        name="team-incentivization-mode"
        onChange={(value) => {
          setSelectedMode(value);
          updateDataPoints(value);
        }}
        options={TeamIncentivizationGraphModes}
        selectedOption={selectedMode}
      />
      <ExcludedGrantees
        grantees={excludedGrantees}
        mode={selectedMode}
        organization={organization}
      />
      <TeamIncentivizationGraph
        dataPointsFragment={nonZeroValueDataPoints}
        mode={selectedMode}
        organizationFragment={organization}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query TeamIncentivization_Query(
    $organizationId: OrganizationId!
    $vestingMode: TeamIncentivizationVestingMode!
  ) {
    organization(id: $organizationId) {
      name
      id
      ...TeamIncentivization_Organization
    }
    teamIncentivizationDataPoints(
      organizationId: $organizationId
      vestingMode: $vestingMode
    ) {
      ...TeamIncentivization_TeamIncentivizationDataPoint
    }
  }
`;

const AdminTeamIncentivizationPage: React.FC = () => {
  const [selectedMode, setSelectedMode] =
    useState<TeamIncentivizationGraphMode>(INITIAL_MODE);
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<TeamIncentivization_Query>(QUERY, {
    organizationId,
    vestingMode: INITIAL_MODE,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Team Incentivization"
      analyticsName="Admin - Insights - Team Incentivization"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} insights | team incentivization`}
    >
      <AdminTeamIncentivizationPage_
        dataPointsFragment={query.teamIncentivizationDataPoints}
        organizationFragment={query.organization}
        selectedMode={selectedMode}
        setSelectedMode={setSelectedMode}
      />
    </Page>
  );
};

export default AdminTeamIncentivizationPage;
