import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { RangeInput } from "../../../../../../components/RangeInput";
import { Button } from "../../../../../../components/ui/Button";
import { FormRow } from "../../../../../../components/ui/Form/FormRow";
import { Modal } from "../../../../../../components/ui/Modal";
import { NoticeMessage } from "../../../../../../components/ui/NoticeMessage";
import { useSafeMutation } from "../../../../../../hooks/useSafeMutation";
import { SetVestingEligibilityThresholdModal_Organization$key } from "./__generated__/SetVestingEligibilityThresholdModal_Organization.graphql";
import { SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation } from "./__generated__/SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SetVestingEligibilityThresholdModal_Organization on Organization {
    id
    tenureGrantsEligibilityVestedRatioThreshold
  }
`;

const SET_ORGANIZATION_TENURE_GRANTS_ELIGIBILITY_VESTED_RATIO_THRESHOLD_MUTATION = graphql`
  mutation SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation(
    $tenureGrantsEligibilityVestedRatioThreshold: Float
    $organizationId: OrganizationId!
  ) {
    updateOrganizationTenureGrantsEligibilityVestedRatioThreshold(
      tenureGrantsEligibilityVestedRatioThreshold: $tenureGrantsEligibilityVestedRatioThreshold
      organizationId: $organizationId
    ) {
      ...SetVestingEligibilityThresholdModal_Organization
    }
  }
`;

const FORM_SCHEMA = z.object({
  tenureGrantsEligibilityVestedPercentageThreshold: z.number().min(0).max(100),
});

type FormValues = z.infer<typeof FORM_SCHEMA>;

export function SetVestingEligibilityThresholdModal({
  onClose,
  organizationFragment,
  show,
}: {
  onClose: () => void;
  organizationFragment: SetVestingEligibilityThresholdModal_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const form = useForm<FormValues>({
    resolver: zodResolver(FORM_SCHEMA),
    values: {
      tenureGrantsEligibilityVestedPercentageThreshold:
        (organization.tenureGrantsEligibilityVestedRatioThreshold ?? 0.5) * 100,
    },
  });

  const [triggerSetOrganizationTenureGrantsEligibilityVestedRatioThreshold] =
    useSafeMutation<SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation>(
      SET_ORGANIZATION_TENURE_GRANTS_ELIGIBILITY_VESTED_RATIO_THRESHOLD_MUTATION,
    );

  const handleSubmit = form.handleSubmit(async (values) => {
    await triggerSetOrganizationTenureGrantsEligibilityVestedRatioThreshold({
      variables: {
        organizationId: organization.id,
        tenureGrantsEligibilityVestedRatioThreshold:
          values.tenureGrantsEligibilityVestedPercentageThreshold / 100,
      },
    });

    onClose();
  });

  return (
    <Modal
      onExited={() => {
        form.reset();
      }}
      show={show}
    >
      <Modal.Content
        actionsInHeader={
          <div className="flex items-center gap-2">
            <Button
              disabled={form.formState.isSubmitting}
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button
              form="set-vesting-eligibility-threshold"
              loading={form.formState.isSubmitting}
              size="small"
              type="submit"
            >
              Set threshold
            </Button>
          </div>
        }
        onClose={onClose}
        title="Set a vesting eligibility threshold"
      >
        <form
          className="block space-y-4"
          id="set-vesting-eligibility-threshold"
          onSubmit={handleSubmit}
        >
          <FormRow.Form
            control={form.control}
            label="Vesting threshold"
            name="tenureGrantsEligibilityVestedPercentageThreshold"
          >
            <RangeInput.Form
              control={form.control}
              max={90}
              min={10}
              name="tenureGrantsEligibilityVestedPercentageThreshold"
              renderStepLabel={({ step }) => `${step}%`}
              step={10}
            />
          </FormRow.Form>
          <NoticeMessage hasColor={false} size="Large" variant="Info">
            This threshold is used in the equity planning and will help you plan
            equity for employees eligible for a tenure grant.
          </NoticeMessage>
        </form>
      </Modal.Content>
    </Modal>
  );
}
