import { BriefcaseIcon } from "@heroicons/react/24/outline";
import { compact } from "lodash";
import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DeleteOrganizationButton } from "../../../components/DeleteOrganizationButton";
import { MarkOrganizationAsTerminatedButton } from "../../../components/MarkOrganizationAsTerminatedButton";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { LinkButton } from "../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import CONFIGURATION from "../../../services/configuration";
import NotFoundPage from "../../NotFound/NotFound";
import { OrganizationSettings_Account$key } from "./__generated__/OrganizationSettings_Account.graphql";
import { OrganizationSettings_Organization$key } from "./__generated__/OrganizationSettings_Organization.graphql";
import { OrganizationSettings_Query } from "./__generated__/OrganizationSettings_Query.graphql";
import { SettingsContainer } from "./SettingsContainer";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettings_Organization on Organization {
    id
    name
    companies {
      id
      completeName
    }
    stockOptionPlanName
    organizationGeographies {
      geography {
        country {
          code
        }
      }
      isTaxFavoredEquityTypeUnlocked
      nsoEquityTypeSubplanName
      taxFavoredEquityTypeSubplanName
    }
    ...DeleteOrganizationButton_Organization
    ...MarkOrganizationAsTerminatedButton_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettings_Account on Account {
    isSuperAdmin
  }
`;

const OrganizationSettingsPage_: React.FC<{
  organizationFragment: OrganizationSettings_Organization$key;
  viewerFragment: OrganizationSettings_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const subPlans = useMemo(
    () =>
      compact(
        organization.organizationGeographies.flatMap(
          (organizationGeography) => [
            organizationGeography.nsoEquityTypeSubplanName
              ? {
                  key: `${organizationGeography.geography.country.code}NSO`,
                  name: organizationGeography.nsoEquityTypeSubplanName,
                }
              : null,
            organizationGeography.taxFavoredEquityTypeSubplanName &&
            organizationGeography.isTaxFavoredEquityTypeUnlocked
              ? {
                  key: `${organizationGeography.geography.country.code}TaxFavored`,
                  name: organizationGeography.taxFavoredEquityTypeSubplanName,
                }
              : null,
          ],
        ),
      ),
    [organization.organizationGeographies],
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const applicationName = useApplicationName();

  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] information on organization`,
  );

  const environmentIsProduction = CONFIGURATION.ENVIRONMENT === "production";

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Organization</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <SettingsContainer title="Organization">
        <SettingsContainer.Item
          subTitle="This name is displayed as your workspace name"
          title="Organization name"
        >
          <SettingsContainer.Item.SingleValue>
            {organization.name}
          </SettingsContainer.Item.SingleValue>
        </SettingsContainer.Item>
        <SettingsContainer.Item
          subTitle="Your legal entities"
          title="Business entities"
        >
          <SettingsContainer.Item.MultipleValues>
            {organization.companies.map((company) => (
              <SettingsContainer.Item.MultipleValues.Item
                className="flex gap-2"
                key={company.id}
              >
                <BriefcaseIcon className="h-5 w-5 text-gray-08" />
                <Typography variant="Regular/Extra Small">
                  {company.completeName}
                </Typography>
              </SettingsContainer.Item.MultipleValues.Item>
            ))}
          </SettingsContainer.Item.MultipleValues>
        </SettingsContainer.Item>
        <SettingsContainer.Item title="Stock option plan name">
          <SettingsContainer.Item.SingleValue>
            {organization.stockOptionPlanName}
          </SettingsContainer.Item.SingleValue>
        </SettingsContainer.Item>
        <SettingsContainer.Item title="Sub plan name">
          {subPlans.length > 0 ? (
            <SettingsContainer.Item.MultipleValues>
              {subPlans.map((subPlan) => (
                <SettingsContainer.Item.MultipleValues.Item key={subPlan.key}>
                  <Typography variant="Regular/Extra Small">
                    {subPlan.name}
                  </Typography>
                </SettingsContainer.Item.MultipleValues.Item>
              ))}
            </SettingsContainer.Item.MultipleValues>
          ) : (
            <NoticeMessage size="Small">
              You have no sub plan yet.
            </NoticeMessage>
          )}
        </SettingsContainer.Item>

        <SettingsContainer.Item
          subTitle={`You see anything that seems weird or want to cancel your ${applicationName} subscription?`}
        >
          <LinkButton
            className="!mt-4"
            size="small"
            to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            variant="Primary Outline"
          >
            Contact us
          </LinkButton>
        </SettingsContainer.Item>
        {viewer.isSuperAdmin && (
          <SettingsContainer.Item>
            <div className="flex items-center justify-between">
              <MarkOrganizationAsTerminatedButton
                organizationFragment={organization}
              />
              {!environmentIsProduction && (
                <DeleteOrganizationButton organizationFragment={organization} />
              )}
            </div>
          </SettingsContainer.Item>
        )}
      </SettingsContainer>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettings_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettings_Organization
    }
    me {
      ...OrganizationSettings_Account
    }
  }
`;

const OrganizationSettingsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettings_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Organization Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} organization settings`}
    >
      <OrganizationSettingsPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default OrganizationSettingsPage;
