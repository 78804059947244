/**
 * @generated SignedSource<<9a87cc8f6525728691d34fa0b824b351>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeVestingSchedule_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGranteeGrantIndex_Grantee">;
  readonly " $fragmentType": "GranteeVestingSchedule_Grantee";
};
export type GranteeVestingSchedule_Grantee$key = {
  readonly " $data"?: GranteeVestingSchedule_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeVestingSchedule_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeVestingSchedule_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteeGrantIndex_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "19843fa4a9584778eaec881dfc735887";

export default node;
