import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import { Button } from "../../../components/ui/Button";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useStripeCustomerPortal } from "../../../hooks/useStripeCustomerPortal";
import { useOrganizationIdParam } from "../../../paths";

export const EnterBillingInformationBox: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { openStripeCustomerPortal, openStripeCustomerPortalIsLoading } =
    useStripeCustomerPortal({ organizationId });
  return (
    <RoundedBox
      className="flex flex-col items-start p-10"
      withBorder
      withShadow
    >
      <Typography variant="Medium/Default">
        Update your billing information
      </Typography>
      <Typography className="mt-2" variant="Regular/Small">
        In order for us to set up your workspace, we need you to fill in your
        billing details.
      </Typography>
      <Button
        className="mt-10"
        loading={openStripeCustomerPortalIsLoading}
        onClick={openStripeCustomerPortal}
        rightIcon={<ArrowTopRightOnSquareIcon />}
        size="small"
      >
        Update your billing information
      </Button>
      <Typography className="mt-4 text-black-05" variant="Regular/Caption">
        Define your billing details, your preferred credit card, platform access
        fee recurrence and geographies access fee recurrence.
      </Typography>
    </RoundedBox>
  );
};
