import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES, useOrganizationIdParam } from "../paths";
import { getEquityTypeWorkRelationship } from "../services/workRelationship";
import { LinkToEquityType_Instrument$key } from "./__generated__/LinkToEquityType_Instrument.graphql";
import { Typography } from "./ui/Typography";

const INSTRUMENT_FRAGMENT = graphql`
  fragment LinkToEquityType_Instrument on Instrument {
    workRelationship
    equityType @required(action: THROW) {
      id
      name
      taxResidenceCountry {
        emoji
      }
    }
  }
`;

export const LinkToEquityType: React.FC<{
  className?: string;
  instrumentFragment: LinkToEquityType_Instrument$key;
  variant: React.ComponentProps<typeof Typography>["variant"];
}> = ({ className, instrumentFragment, variant }) => {
  const organizationId = useOrganizationIdParam();
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);

  const equityTypeWorkRelationship = getEquityTypeWorkRelationship(
    instrument.workRelationship,
  );
  return (
    <Link
      className={className}
      target="_blank"
      to={generatePath(
        APPLICATION_ROUTES["organizationEquityTypeWorkRelationship"],
        {
          equityTypeId: instrument.equityType.id,
          equityTypeWorkRelationshipCategory: equityTypeWorkRelationship.slug,
          organizationId,
        },
      )}
    >
      <Typography
        as="div"
        className="flex items-center gap-1 text-primary"
        variant={variant}
      >
        <span>
          {instrument.equityType.taxResidenceCountry.emoji}{" "}
          {instrument.equityType.name} - {equityTypeWorkRelationship.label}
        </span>
        <ArrowTopRightOnSquareIcon className="h-3 w-3" />
      </Typography>
    </Link>
  );
};
