import { Typography } from "../../../components/ui/Typography";
import poweredByCartaLogo from "./powered-by-carta.png";

export const PoweredByCartaPill: React.FC = () => (
  <Typography
    as="div"
    className="flex items-center gap-2 rounded-full bg-purple-01 px-4 py-2"
    variant="Regular/Extra Small"
  >
    We&apos;re officially
    <img
      alt="Powered by Carta"
      className="w-full max-w-[126px]"
      src={poweredByCartaLogo}
    />
  </Typography>
);
