/**
 * @generated SignedSource<<6e8e3885ca3a80c67120c3ebf0759f6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BoardConsentSignatureLinkModal_Query$variables = {
  signatureRequestId: string;
};
export type BoardConsentSignatureLinkModal_Query$data = {
  readonly signatureRequest: {
    readonly boardMember: {
      readonly account: {
        readonly fullName: string;
      };
    };
    readonly pandadocSignatureSessionURL: {
      readonly url?: string;
    };
  };
};
export type BoardConsentSignatureLinkModal_Query = {
  response: BoardConsentSignatureLinkModal_Query$data;
  variables: BoardConsentSignatureLinkModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "signatureRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "signatureRequestId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "urlLifetimeInSeconds",
    "value": 604800
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "PandadocSignatureSessionURLSuccessOutput",
  "abstractKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardConsentSignatureLinkModal_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequest",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": null,
                "args": null,
                "concreteType": "BoardMember",
                "kind": "LinkedField",
                "name": "boardMember",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              "action": "THROW"
            },
            {
              "alias": null,
              "args": (v3/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "pandadocSignatureSessionURL",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": "pandadocSignatureSessionURL(urlLifetimeInSeconds:604800)"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardConsentSignatureLinkModal_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignatureRequest",
        "kind": "LinkedField",
        "name": "signatureRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMember",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pandadocSignatureSessionURL",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": "pandadocSignatureSessionURL(urlLifetimeInSeconds:604800)"
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6d51ed484db6689f53a80ae0566332b",
    "id": null,
    "metadata": {},
    "name": "BoardConsentSignatureLinkModal_Query",
    "operationKind": "query",
    "text": "query BoardConsentSignatureLinkModal_Query(\n  $signatureRequestId: UUID!\n) {\n  signatureRequest(id: $signatureRequestId) {\n    boardMember {\n      account {\n        fullName\n        id\n      }\n      id\n    }\n    pandadocSignatureSessionURL(urlLifetimeInSeconds: 604800) {\n      __typename\n      ... on PandadocSignatureSessionURLSuccessOutput {\n        url\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6c7ca63ec21e5d334516fb73b46f9e3c";

export default node;
