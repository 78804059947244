const CONFIGURATION = {
  CARTA_APP_LOGIN_URL: import.meta.env.VITE_CARTA_APP_LOGIN_URL,
  CONFIG_CAT_SDK_KEY: import.meta.env.VITE_CONFIG_CAT_SDK_KEY,
  ENVIRONMENT: import.meta.env.VITE_ENVIRONMENT,
  POSTHOG_API_KEY: import.meta.env.VITE_POSTHOG_API_KEY,
  POSTHOG_HOST: import.meta.env.VITE_POSTHOG_HOST,
  REMOTE_DASHBOARD_EQUITY_URL: import.meta.env.VITE_REMOTE_DASHBOARD_EQUITY_URL,
  SEGMENT_WRITE_KEY: import.meta.env.VITE_SEGMENT_WRITE_KEY,
  SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
  TINY_EDITOR_API_KEY: import.meta.env.VITE_TINY_EDITOR_API_KEY,
  VERSION: import.meta.env.VITE_VERSION,
  VITE_ZAPIER_USER_STARTED_SAFE_SIMULATOR_URL: import.meta.env
    .VITE_ZAPIER_USER_STARTED_SAFE_SIMULATOR_URL,
};

export default CONFIGURATION;
