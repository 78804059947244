/**
 * @generated SignedSource<<e780d067ab7d6e6c28171fd99f09663d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectHRISProviderMessage_Organization$data = {
  readonly isConnectedToDeel: boolean;
  readonly isConnectedToRemote: boolean;
  readonly isConnectedToRemoteEquity: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectToDeelButton_Organization" | "ConnectToRemoteButton_Organization">;
  readonly " $fragmentType": "ConnectHRISProviderMessage_Organization";
};
export type ConnectHRISProviderMessage_Organization$key = {
  readonly " $data"?: ConnectHRISProviderMessage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectHRISProviderMessage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectHRISProviderMessage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToDeel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToRemote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToRemoteEquity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectToDeelButton_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectToRemoteButton_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d7e278b22186c1033076218fe6a47565";

export default node;
