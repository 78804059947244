/**
 * @generated SignedSource<<127510f2c2fec5164a2de9a8052c0247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type JobQueue = "RefreshOrganizationCTMSExerciseRequests";
export type JobState = "Active" | "Done" | "Failed";
export type usePollJobState_JobState_Query$variables = {
  jobId: string;
  jobQueue: JobQueue;
};
export type usePollJobState_JobState_Query$data = {
  readonly job: {
    readonly state: JobState;
  };
};
export type usePollJobState_JobState_Query = {
  response: usePollJobState_JobState_Query$data;
  variables: usePollJobState_JobState_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobQueue"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "jobId",
    "variableName": "jobId"
  },
  {
    "kind": "Variable",
    "name": "jobQueue",
    "variableName": "jobQueue"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePollJobState_JobState_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Job",
          "kind": "LinkedField",
          "name": "job",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePollJobState_JobState_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5642d998249b7212ab32ea3a5f88d368",
    "id": null,
    "metadata": {},
    "name": "usePollJobState_JobState_Query",
    "operationKind": "query",
    "text": "query usePollJobState_JobState_Query(\n  $jobId: ID!\n  $jobQueue: JobQueue!\n) {\n  job(jobId: $jobId, jobQueue: $jobQueue) {\n    state\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "99514a35c57c300cf2b812f5169a0981";

export default node;
