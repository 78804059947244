import {
  BookOpenIcon,
  CloudArrowDownIcon,
  GiftIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../../components/FreemiumBlockerCard";
import { Page } from "../../../../components/Page";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { useQuery } from "../../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { EquityOffersFreemium_Organization$key } from "./__generated__/EquityOffersFreemium_Organization.graphql";
import { EquityOffersFreemium_Query } from "./__generated__/EquityOffersFreemium_Query.graphql";
import EquityOffersFreemiumIllustration from "./equity-offers-freemium-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityOffersFreemium_Organization on Organization {
    id
    name
    ...FreemiumBlockerCard_Organization
  }
`;

const EquityOffersFreemiumPage_: React.FC<{
  organizationFragment: EquityOffersFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <CenteredColumnLayout showFooter>
      <FreemiumBlockerCard
        detailsList={
          <>
            <FreemiumBlockerCard.LabelWithIcon
              icon={<GiftIcon />}
              label="Create as many offers as you want"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<BookOpenIcon />}
              label="Be ready to address technical questions with candidates and talk terms"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<StarIcon />}
              label="Show the candidate that you consider their hiring to be a shrewd business move"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<CloudArrowDownIcon />}
              label="Download offers as pdf or share them via a unique link"
            />
          </>
        }
        mailtoSubject="About equity offers"
        organizationFragment={organization}
        rightSideIllustration={
          <div
            className={classNames(
              "bg-gradient-sea-aquarium",
              "relative w-[453px] max-w-[50%] rounded-2xl",
            )}
          >
            <div className="absolute -right-2 bottom-0 left-5 top-0 flex items-center">
              <img
                alt="Equity offers freemium illustration"
                src={EquityOffersFreemiumIllustration}
              />
            </div>
          </div>
        }
        subTitle="Build an innate sense of ownership, and help candidates envision success at the company level, as well as in their own personal career. Lay out clearly where the company sits in the market and competitive landscape and make them likelier to sign on."
        title="Share Equity Offers to Candidates!"
      />
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query EquityOffersFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...EquityOffersFreemium_Organization
    }
  }
`;

const EquityOffersFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<EquityOffersFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Equity offers freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} equity offers freemium`}
    >
      <EquityOffersFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default EquityOffersFreemiumPage;
