/**
 * @generated SignedSource<<a57b62bfa058a7e9777096e301bad7dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalProjectionScenarioKey = "CURRENT_VALUE" | "FIRST_ROUND" | "SECOND_ROUND" | "THIRD_ROUND";
export type UpdateGranteePortalProjectionScenarioAttributes = {
  additionalInformation?: string | null;
  multiple: number;
  name: string;
};
export type GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation$variables = {
  attributes: UpdateGranteePortalProjectionScenarioAttributes;
  key: GranteePortalProjectionScenarioKey;
  organizationId: string;
};
export type GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation$data = {
  readonly updateGranteePortalProjectionScenario: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario">;
  };
};
export type GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation = {
  response: GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation$data;
  variables: GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GranteePortalProjectionScenario",
        "kind": "LinkedField",
        "name": "updateGranteePortalProjectionScenario",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GranteePortalProjectionScenario",
        "kind": "LinkedField",
        "name": "updateGranteePortalProjectionScenario",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multiple",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalInformation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d50ffe91d4b7bd8f4a1d7cf46fe8c4d8",
    "id": null,
    "metadata": {},
    "name": "GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteePortalSettingsProjectionSection_UpdateGranteePortalProjectionScenario_Mutation(\n  $organizationId: OrganizationId!\n  $key: GranteePortalProjectionScenarioKey!\n  $attributes: UpdateGranteePortalProjectionScenarioAttributes!\n) {\n  updateGranteePortalProjectionScenario(organizationId: $organizationId, key: $key, attributes: $attributes) {\n    ...GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario\n    id\n  }\n}\n\nfragment GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario on GranteePortalProjectionScenario {\n  key\n  name\n  multiple\n  displayed\n  additionalInformation\n}\n"
  }
};
})();

(node as any).hash = "bc0853ea6944f75db97e8d6cf962e167";

export default node;
