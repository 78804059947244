/**
 * @generated SignedSource<<af3c3641d9d8ee94c89fab8bb72ce997>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantsTableRow_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization" | "TableCellSharesOverview_Organization">;
  readonly " $fragmentType": "CtmsGrantsTableRow_Organization";
};
export type CtmsGrantsTableRow_Organization$key = {
  readonly " $data"?: CtmsGrantsTableRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantsTableRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantsTableRow_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TableCellSharesOverview_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b1956ff46c81d1c5c47b9ac61177ddf7";

export default node;
