import classNames from "classnames";
import _, { chain, isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import {
  CTMSGrantsListSlideOver,
  useCTMSGrantsListSlideOverState,
} from "../../../../components/CTMSGrantsListSlideOver";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { InsightNotAvailableBox } from "../../../../components/InsightNotAvailableBox";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { filterByPropertiesNotNull } from "../../../../helpers/ts-utlity";
import {
  VestingSchedulesGraph_Organization$data,
  VestingSchedulesGraph_Organization$key,
} from "./__generated__/VestingSchedulesGraph_Organization.graphql";
import { VestingSchedulesGraph_Viewer$key } from "./__generated__/VestingSchedulesGraph_Viewer.graphql";

const MAXIMUM_NUMBER_OF_BARS = 8;

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingSchedulesGraph_Organization on Organization {
    ctmsGrants {
      edges {
        node {
          vestingScheduleName
          ...CTMSGrantsListSlideOver_CTMSGrants
        }
      }
    }
    ...CTMSGrantsListSlideOver_Organization
    ...InsightNotAvailableBox_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment VestingSchedulesGraph_Viewer on Account {
    ...InsightNotAvailableBox_Viewer
  }
`;

const Tooltip: React.FC<{
  barClassName: string;
  grantsCount: number;
  title: string;
}> = ({ barClassName, grantsCount, title }) => {
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grants"
      topContent={title}
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{grantsCount, plural,
                =0 {# grant}
                =1 {# grant}
                other {# grants}
              }`}
              values={{ grantsCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

export const VestingSchedulesGraph: React.FC<{
  organizationFragment: VestingSchedulesGraph_Organization$key;
  viewerFragment: VestingSchedulesGraph_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const ctmsGrants = useMemo(
    () => organization.ctmsGrants.edges.map(({ node }) => node),
    [organization.ctmsGrants.edges],
  );

  const ctmsGrantsWithVestingScheduleName = useMemo(
    () => filterByPropertiesNotNull(ctmsGrants, "vestingScheduleName"),
    [ctmsGrants],
  );

  const ctmsGrantsWithoutVestingScheduleName = useMemo(
    () => ctmsGrants.filter((grant) => !grant.vestingScheduleName),
    [ctmsGrants],
  );

  const [
    nonUniqueVestingScheduleGrantsGroupedByVestingSchedule,
    uniqueVestingScheduleGrants,
  ] = useMemo(() => {
    const [
      nonUniqueVestingScheduleGrantsGroupedByVestingSchedule,
      uniqueVestingScheduleGrantsGroupedByVestingSchedule,
    ] = _(ctmsGrantsWithVestingScheduleName)
      .groupBy(({ vestingScheduleName }) => vestingScheduleName)
      .partition((grants) => grants.length > 1)
      .value();

    return [
      nonUniqueVestingScheduleGrantsGroupedByVestingSchedule,
      uniqueVestingScheduleGrantsGroupedByVestingSchedule.flat(),
    ];
  }, [ctmsGrantsWithVestingScheduleName]);

  const stacks = useMemo(() => {
    const stacks = _(nonUniqueVestingScheduleGrantsGroupedByVestingSchedule)
      .sortBy((grants) => grants.length)
      .map((grants) => {
        const firstGrant = grants[0];

        if (!firstGrant) {
          throw new Error("unexpected");
        }

        return {
          className: classNames("bg-green-05 fill-green-05"),
          grants,
          key: firstGrant.vestingScheduleName,
          label: firstGrant.vestingScheduleName,
          value: grants.length,
        };
      })
      .value();

    if (!isEmpty(uniqueVestingScheduleGrants)) {
      stacks.push({
        className: classNames("bg-gray-05 fill-gray-05"),
        grants: uniqueVestingScheduleGrants,
        key: "unique",
        label: "Unique",
        value: uniqueVestingScheduleGrants.length,
      });
    }

    return stacks.map((stack) => ({
      ...stack,
      elements: [
        {
          className: stack.className,
          key: stack.key,
          value: stack.value,
        },
      ],
    }));
  }, [
    nonUniqueVestingScheduleGrantsGroupedByVestingSchedule,
    uniqueVestingScheduleGrants,
  ]);

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useCTMSGrantsListSlideOverState();

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) =>
      openSlideOver({
        ctmsGrantsFragment: stack.grants,
        title: (
          <FormattedMessage
            defaultMessage={`There {grantsCount, plural,
            =0 {is # grant}
            =1 {is # grant}
            other {are # grants}} with {vestingSchedule} vesting schedule`}
            values={{
              grantsCount: stack.grants.length,
              vestingSchedule: stack.label,
            }}
          />
        ),
      }),
    [openSlideOver],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        grantsCount={stack.grants.length}
        title={`${stack.label} vesting schedule`}
      />
    ),
    [],
  );

  const slicedStacks = useMemo(
    () => chain(stacks).takeRight(MAXIMUM_NUMBER_OF_BARS).value(),
    [stacks],
  );

  const grantsButton = useCallback(
    (chunks: React.ReactNode[]) => {
      return (
        <button
          className="font-semibold underline"
          onClick={() => {
            openSlideOver({
              ctmsGrantsFragment: ctmsGrantsWithoutVestingScheduleName,
              title: (
                <FormattedMessage
                  defaultMessage="We couldn’t retrieve the vesting schedule of {grantCount, plural, one {# grant} other {# grants}}"
                  values={{
                    grantCount: ctmsGrantsWithoutVestingScheduleName.length,
                  }}
                />
              ),
            });
          }}
        >
          {chunks}
        </button>
      );
    },
    [ctmsGrantsWithoutVestingScheduleName, openSlideOver],
  );

  const hasDataToDisplay = useMemo(
    () => !isEmpty(ctmsGrantsWithVestingScheduleName),
    [ctmsGrantsWithVestingScheduleName],
  );

  if (!hasDataToDisplay) {
    return (
      <InsightNotAvailableBox
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    );
  }

  return (
    <>
      <CTMSGrantsListSlideOver
        onClose={closeSlideOver}
        organizationFragment={organization}
        state={slideOverState}
      />
      {!isEmpty(ctmsGrantsWithoutVestingScheduleName) && (
        <Typography
          as="div"
          className="rounded-lg bg-orange-01 px-4 py-2 text-orange-09"
          variant="Regular/Extra Small"
        >
          <FormattedMessage
            defaultMessage="We couldn’t retrieve the vesting schedule of <grantsButton>{grantCount, plural, one {# grant} other {# grants}}</grantsButton>"
            values={{
              grantCount: ctmsGrantsWithoutVestingScheduleName.length,
              grantsButton,
            }}
          />
        </Typography>
      )}
      <BarGraph
        barGap={100}
        barSize={40}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={slicedStacks}
        yLabel="# of grants"
        yTickFormatter={(v) => v.toString()}
      />
      {stacks.length > MAXIMUM_NUMBER_OF_BARS && (
        <VestingSchedulesRepartitionTable
          onRowClick={(row) => onStackClick(row)}
          rows={stacks}
        />
      )}
    </>
  );
};

type TableRow = {
  grants: VestingSchedulesGraph_Organization$data["ctmsGrants"]["edges"][number]["node"][];
  key: string;
  label: string;
  value: number;
};

function VestingSchedulesRepartitionTable<TRow extends TableRow>({
  onRowClick,
  rows,
}: {
  onRowClick: (row: TRow) => void;
  rows: Array<TRow>;
}) {
  const orderedRows = useMemo(
    () =>
      chain(rows)
        .sortBy(({ value }) => value)
        .reverse()
        .value(),
    [rows],
  );

  return (
    <RoundedBox className="w-full overflow-hidden" withBorder>
      <Table className="w-full">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Vesting schedule name</Table.HeaderCell>
            <Table.HeaderCell>Number of grants</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {orderedRows.map((row) => (
            <Table.Row
              className="cursor-pointer hover:bg-primary-01"
              key={row.key}
              onClick={() => onRowClick(row)}
            >
              <Table.Cell className="text-primary" variant="Medium/Extra Small">
                {row.label}
              </Table.Cell>
              <Table.Cell className="text-primary" variant="Medium/Extra Small">
                {row.value}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </RoundedBox>
  );
}
