/**
 * @generated SignedSource<<d3fee3836b5e433d79ddb9bb47be414e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Review_Instrument$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GrantDetailsRow_Instrument" | "InstrumentNotAvailableForGranteeAlert_Instrument">;
  readonly " $fragmentType": "Review_Instrument";
};
export type Review_Instrument$key = {
  readonly " $data"?: Review_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"Review_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Review_Instrument",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantDetailsRow_Instrument"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InstrumentNotAvailableForGranteeAlert_Instrument"
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "106ab8267782a53aa0116e499b060e2c";

export default node;
