/**
 * @generated SignedSource<<8c2c9e040cd5c0e0c874560b1fe87442>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteCompanyAdminsList_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly remoteCompanyAdmins: ReadonlyArray<{
    readonly allowedAsAdmin: boolean;
    readonly email: string;
    readonly id: string;
    readonly name: string;
    readonly userSlug: string;
  }>;
  readonly " $fragmentType": "RemoteCompanyAdminsList_Organization";
};
export type RemoteCompanyAdminsList_Organization$key = {
  readonly " $data"?: RemoteCompanyAdminsList_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteCompanyAdminsList_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteCompanyAdminsList_Organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "orderBy": {
              "direction": "ASC",
              "field": "name"
            }
          }
        }
      ],
      "concreteType": "RemoteCompanyAdmin",
      "kind": "LinkedField",
      "name": "remoteCompanyAdmins",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "userSlug",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "allowedAsAdmin",
          "storageKey": null
        }
      ],
      "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "010f56aca04c33c182cc7de5d975c669";

export default node;
