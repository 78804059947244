import React, { ComponentProps } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ptepDurationToDays } from "../helpers/ptep-utils";
import {
  GrantWatchouts_CliffWaivedWatchout_EquityType$data,
  GrantWatchouts_CliffWaivedWatchout_EquityType$key,
} from "./__generated__/GrantWatchouts_CliffWaivedWatchout_EquityType.graphql";
import {
  GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$data,
  GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$key,
} from "./__generated__/GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType.graphql";
import {
  DurationUnit,
  GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod$key,
} from "./__generated__/GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod.graphql";
import {
  GrantWatchouts_Rule83BWatchout_EquityType$data,
  GrantWatchouts_Rule83BWatchout_EquityType$key,
} from "./__generated__/GrantWatchouts_Rule83BWatchout_EquityType.graphql";
import {
  GrantWatchouts_Rule100KWatchout_EquityType$data,
  GrantWatchouts_Rule100KWatchout_EquityType$key,
} from "./__generated__/GrantWatchouts_Rule100KWatchout_EquityType.graphql";
import { NoticeMessage } from "./ui/NoticeMessage";

const EQUITY_TYPE_PTEP_GREATER_THAN90_DAYS_WATCHOUT_FRAGMENT = graphql`
  fragment GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType on EquityType {
    name
  }
`;

const PTEP_PTEP_GREATER_THAN90_DAYS_WATCHOUT_FRAGMENT = graphql`
  fragment GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod on OrganizationPostTerminationExercisePeriod {
    duration
    durationUnit
  }
`;

const watchoutIfPtepGreaterThan90DayShouldBeDisplayed = ({
  equityType,
  ptepDuration,
  ptepDurationUnit,
}: {
  equityType: GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$data;
  ptepDuration: number;
  ptepDurationUnit: DurationUnit;
}) => {
  const daysToExercise = ptepDurationToDays({
    duration: ptepDuration,
    unit: ptepDurationUnit,
  });

  if (daysToExercise < 90) {
    return false;
  }

  switch (equityType.name) {
    case "EMI":
    case "ISO":
      return true;
    default:
      return false;
  }
};

const PtepGreaterThan90DaysWatchout_: React.FC<{
  equityTypeFragment: GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$key;
  hasColor?: ComponentProps<typeof NoticeMessage>["hasColor"];
  ptepDuration: number;
  ptepDurationUnit: DurationUnit;
  size?: ComponentProps<typeof NoticeMessage>["size"];
}> = ({
  equityTypeFragment,
  hasColor,
  ptepDuration,
  ptepDurationUnit,
  size = "Small",
}) => {
  const equityType = useFragment(
    EQUITY_TYPE_PTEP_GREATER_THAN90_DAYS_WATCHOUT_FRAGMENT,
    equityTypeFragment,
  );

  const displayWatchoutIfPtepGreaterThan90Days =
    watchoutIfPtepGreaterThan90DayShouldBeDisplayed({
      equityType,
      ptepDuration: ptepDuration,
      ptepDurationUnit: ptepDurationUnit,
    });

  if (!displayWatchoutIfPtepGreaterThan90Days) {
    return null;
  }

  return (
    <NoticeMessage hasColor={hasColor} size={size} variant="Warning">
      Grantees have 90 days to exercise their stock options to benefit from{" "}
      {equityType.name} favorable taxation. You can extend the post termination
      exercise period beyond 90 days, but grantees should exercise within 90
      days if they want to fully benefit from {equityType.name} taxation regime.
    </NoticeMessage>
  );
};

const PtepGreaterThan90DaysWatchoutUsingPtepFragment: React.FC<{
  equityTypeFragment: GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$key;
  hasColor?: ComponentProps<typeof NoticeMessage>["hasColor"];
  postTerminationExercisePeriodFragment: GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod$key;
  size?: ComponentProps<typeof NoticeMessage>["size"];
}> = ({
  equityTypeFragment,
  hasColor,
  postTerminationExercisePeriodFragment,
  size = "Small",
}) => {
  const postTerminationExercisePeriod = useFragment(
    PTEP_PTEP_GREATER_THAN90_DAYS_WATCHOUT_FRAGMENT,
    postTerminationExercisePeriodFragment,
  );

  return (
    <PtepGreaterThan90DaysWatchout
      equityTypeFragment={equityTypeFragment}
      hasColor={hasColor}
      ptepDuration={postTerminationExercisePeriod.duration}
      ptepDurationUnit={postTerminationExercisePeriod.durationUnit}
      size={size}
    />
  );
};

const PtepGreaterThan90DaysWatchout = Object.assign(
  PtepGreaterThan90DaysWatchout_,
  {
    UsingPtepFragment: PtepGreaterThan90DaysWatchoutUsingPtepFragment,
  },
);

const EQUITY_TYPE_CLIFF_WAIVED_WATCHOUT_FRAGMENT = graphql`
  fragment GrantWatchouts_CliffWaivedWatchout_EquityType on EquityType {
    name
  }
`;

const useShouldDisplayWatchoutIfCliffWaived = ({
  cliffWaived,
  equityType,
}: {
  cliffWaived: boolean;
  equityType: GrantWatchouts_CliffWaivedWatchout_EquityType$data;
}) => {
  if (!cliffWaived) {
    return false;
  }

  switch (equityType.name) {
    case "EMI":
      return true;
    default:
      return false;
  }
};

const CliffWaivedWatchout: React.FC<{
  cliffWaived: boolean;
  equityTypeFragment: GrantWatchouts_CliffWaivedWatchout_EquityType$key;
}> = ({ cliffWaived, equityTypeFragment }) => {
  const equityType = useFragment(
    EQUITY_TYPE_CLIFF_WAIVED_WATCHOUT_FRAGMENT,
    equityTypeFragment,
  );
  const shouldDisplayWatchoutIfCliffWaived =
    useShouldDisplayWatchoutIfCliffWaived({
      cliffWaived,
      equityType,
    });

  if (!shouldDisplayWatchoutIfCliffWaived) {
    return null;
  }

  return (
    <NoticeMessage size="Small" variant="Warning">
      By waiving the cliff, options could lose their {equityType.name} status
      and turn into unapproved options, which are taxed at exercise
    </NoticeMessage>
  );
};

const EQUITY_TYPE_RULE100K_WATCHOUT_FRAGMENT = graphql`
  fragment GrantWatchouts_Rule100KWatchout_EquityType on EquityType {
    name
  }
`;

const useShouldDisplayRule100KWatchout = ({
  equityType,
}: {
  equityType: GrantWatchouts_Rule100KWatchout_EquityType$data;
}) => {
  switch (equityType.name) {
    case "ISO":
      return true;
    default:
      return false;
  }
};

const Rule100KWatchout: React.FC<{
  action?: React.ReactNode;
  equityTypeFragment: GrantWatchouts_Rule100KWatchout_EquityType$key;
  hasColor?: ComponentProps<typeof NoticeMessage>["hasColor"];
  size?: ComponentProps<typeof NoticeMessage>["size"];
}> = ({ action, equityTypeFragment, hasColor, size = "Small" }) => {
  const equityType = useFragment(
    EQUITY_TYPE_RULE100K_WATCHOUT_FRAGMENT,
    equityTypeFragment,
  );
  const shouldDisplayRule100KWatchout = useShouldDisplayRule100KWatchout({
    equityType,
  });

  if (!shouldDisplayRule100KWatchout) {
    return null;
  }

  return (
    <NoticeMessage hasColor={hasColor} size={size} variant="Warning">
      If the grantee receives more than $100k in exercisable {equityType.name}s
      in one calendar year, any {equityType.name}s that exceed this 100k limit
      will be considered as NSOs for tax purposes. {action}
    </NoticeMessage>
  );
};

const EQUITY_TYPE_RULE83B_WATCHOUT_FRAGMENT = graphql`
  fragment GrantWatchouts_Rule83BWatchout_EquityType on EquityType {
    name
    taxResidenceCountry {
      code
    }
  }
`;

const useShouldDisplayRule83BWatchout = ({
  earlyExerciseAllowed,
  equityType,
}: {
  earlyExerciseAllowed: boolean;
  equityType: GrantWatchouts_Rule83BWatchout_EquityType$data;
}) => {
  if (!earlyExerciseAllowed) {
    return false;
  }

  switch (equityType.name) {
    case "ISO":
      return true;
    case "NSO":
      return equityType.taxResidenceCountry.code === "US";
    default:
      return false;
  }
};

const Rule83BWatchout: React.FC<{
  earlyExerciseAllowed: boolean;
  equityTypeFragment: GrantWatchouts_Rule83BWatchout_EquityType$key;
  hasColor?: ComponentProps<typeof NoticeMessage>["hasColor"];
  size?: ComponentProps<typeof NoticeMessage>["size"];
}> = ({
  earlyExerciseAllowed,
  equityTypeFragment,
  hasColor,
  size = "Small",
}) => {
  const equityType = useFragment(
    EQUITY_TYPE_RULE83B_WATCHOUT_FRAGMENT,
    equityTypeFragment,
  );
  const shouldDisplayRule83BWatchout = useShouldDisplayRule83BWatchout({
    earlyExerciseAllowed,
    equityType,
  });

  if (!shouldDisplayRule83BWatchout) {
    return null;
  }

  return (
    <NoticeMessage hasColor={hasColor} size={size} variant="Warning">
      If those options are early exercised, it is usually recommended to file an
      83(b) election.
    </NoticeMessage>
  );
};

export const GrantWatchouts = {
  CliffWaivedWatchout,
  PtepGreaterThan90DaysWatchout,
  Rule83BWatchout,
  Rule100KWatchout,
};
