import React from "react";

import { Typography } from "../../../components/ui/Typography";

export const AssistedGrantPageTitle: React.FC<
  React.PropsWithChildren<{
    subtitle?: React.ReactNode;
  }>
> = ({ children, subtitle }) => (
  <div className="space-y-4">
    <Typography as="div" variant="Medium/Large">
      {children}
    </Typography>
    {subtitle && (
      <Typography as="div" className="text-black-05" variant="Regular/Small">
        {subtitle}
      </Typography>
    )}
  </div>
);

export const AssistedGrantPageGridLayout: React.FC<
  React.PropsWithChildren<{
    aside?: React.ReactNode;
    footer?: React.ReactNode;
    header: React.ReactNode;
  }>
> = ({ aside, children, footer, header }) => (
  <div className="grid grid-cols-[minmax(auto,540px)_minmax(auto,504px)] gap-4">
    <div>{header}</div>
    <div className="col-start-1">{children}</div>
    <div>{aside}</div>
    <div>{footer}</div>
  </div>
);
