import { ArrowRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { range } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { LinkButton } from "../../../components/ui/Button";
import { Picture } from "../../../components/ui/Picture";
import { FadeAndScaleTransition } from "../../../components/ui/Transition";
import { Typography } from "../../../components/ui/Typography";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../../../paths";
import { OverviewHero_Organization$key } from "./__generated__/OverviewHero_Organization.graphql";
import { OverviewHero_SlideEquityPhilosophy_Organization$key } from "./__generated__/OverviewHero_SlideEquityPhilosophy_Organization.graphql";
import { OverviewHero_SlideNewIntegrations_Organization$key } from "./__generated__/OverviewHero_SlideNewIntegrations_Organization.graphql";
import equityPhilosophyBannerIllustrationImage from "./equity-philosophy-banner-illustration.png";
import heroBackgroundIntegrationsImage from "./hero-background-integrations.png";

const SlideControlsDot: React.FC<{
  active?: boolean;
  onClick: () => void;
}> = ({ active = false, onClick }) => (
  <button
    className={classNames("h-3 w-3 rounded-full transition-all", {
      "bg-primary": active,
      "bg-white": !active,
    })}
    onClick={onClick}
  />
);

const SlideControls: React.FC<{
  activeIndex: number;
  itemCount: number;
  onIndexChange: (index: number) => void;
}> = ({ activeIndex, itemCount, onIndexChange }) => {
  const handleDotClick = useCallback(
    (index: number) => () => {
      onIndexChange(index);
    },
    [onIndexChange],
  );

  const dots = useMemo(() => range(itemCount), [itemCount]);

  return (
    <div className="pointer-events-auto flex items-center gap-2 rounded-full bg-white/50 p-2 backdrop-blur-md">
      {dots.map((_, index) => (
        <SlideControlsDot
          active={activeIndex === index}
          key={index}
          onClick={handleDotClick(index)}
        />
      ))}
    </div>
  );
};

const Slide: React.FC<
  React.PropsWithChildren<{
    backgroundPicture?: React.ComponentProps<typeof Picture>["image"];
    className?: string;
    footer?: React.ReactNode;
    hint: React.ReactNode;
    pictureClassName?: string;
    title: React.ReactNode;
  }>
> = ({
  backgroundPicture,
  children,
  className,
  footer,
  hint,
  pictureClassName,
  title,
}) => (
  <div className={classNames("relative h-full p-10", className)}>
    {backgroundPicture && (
      <div className="pointer-events-none absolute inset-0 flex items-center justify-end">
        <Picture
          className={classNames("h-full object-cover", pictureClassName)}
          image={backgroundPicture}
        />
      </div>
    )}

    <div className="relative z-10 flex h-full flex-col justify-between">
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Small">
          {hint}
        </Typography>
        <Typography as="div" variant="Medium/Large">
          {title}
        </Typography>
        <Typography as="div" variant="Regular/Extra Small">
          {children}
        </Typography>
      </div>
      <div className="flex gap-4">{footer}</div>
    </div>
  </div>
);

const HERO_SLIDE_EQUITY_PHILOSOPHY_ORGANIZATION_FRAGMENT = graphql`
  fragment OverviewHero_SlideEquityPhilosophy_Organization on Organization {
    id
    name
  }
`;

const SlideEquityPhilosophy: React.FC<{
  organizationFragment: OverviewHero_SlideEquityPhilosophy_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(
    HERO_SLIDE_EQUITY_PHILOSOPHY_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] About equity philosophy`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Slide
      backgroundPicture={equityPhilosophyBannerIllustrationImage}
      className="bg-purple-06 text-white-01"
      footer={
        <>
          <LinkButton
            rightIcon={<ArrowRightIcon />}
            size="small"
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigure"],
              {
                organizationId: organization.id,
              },
            )}
            variant="Tertiary Link"
          >
            Define your equity philosophy
          </LinkButton>
          <LinkButton
            size="small"
            to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            variant="Tertiary Transparent"
          >
            Contact us
          </LinkButton>
        </>
      }
      hint="New Release"
      pictureClassName="w-[168px] !h-auto mr-[90px]"
      title="Make Better Equity Decisions With Less Effort"
    >
      <div className="mr-[289px]">
        Use our new equity philosophy center to define hiring and retention
        policies and make data-driven decisions about how and when to
        incentivize your team
      </div>
    </Slide>
  );
};

const HERO_SLIDE_NEW_INTEGRATION_ORGANIZATION_FRAGMENT = graphql`
  fragment OverviewHero_SlideNewIntegrations_Organization on Organization {
    id
    name
  }
`;

const SlideNewIntegrations: React.FC<{
  organizationFragment: OverviewHero_SlideNewIntegrations_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(
    HERO_SLIDE_NEW_INTEGRATION_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] About new integrations`,
  );
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Slide
      backgroundPicture={heroBackgroundIntegrationsImage}
      className="bg-black-08"
      footer={
        <>
          <LinkButton
            rightIcon={<ArrowRightIcon />}
            size="small"
            to={generatePath(
              APPLICATION_ROUTES["organizationSettingsIntegrations"],
              {
                organizationId: organization.id,
              },
            )}
            variant="Primary Full"
          >
            Discover our integrations
          </LinkButton>
          <LinkButton
            size="small"
            to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            variant="Tertiary Transparent"
          >
            Contact us
          </LinkButton>
        </>
      }
      hint={<span className="text-gray-05">New integrations</span>}
      pictureClassName="w-3/4"
      title={
        <span className="text-white">We now integrate with Remote & Deel</span>
      }
    >
      <span className="text-purple-01">
        Seamlessly synchronize and import your contracts in {applicationName}!
      </span>
    </Slide>
  );
};

const SlideContainer: React.FC<
  React.PropsWithChildren<{
    activeIndex: number;
    index: number;
  }>
> = ({ activeIndex, children, index }) => {
  return (
    <div
      className={classNames(
        "absolute inset-0 transition-all duration-700 ease-in-out",
      )}
      style={{
        transform: `translateX(${index * 100 - activeIndex * 100}%)`,
      }}
    >
      {children}
    </div>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment OverviewHero_Organization on Organization {
    isOriginatingFromRemoteEquity
    ...OverviewHero_SlideEquityPhilosophy_Organization
    ...OverviewHero_SlideNewIntegrations_Organization
  }
`;

export const OverviewHero: React.FC<{
  organizationFragment: OverviewHero_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [adminOverviewCardIsHidden, setAdminOverviewCardIsHidden] =
    useLocalStorage("adminOverviewCardIsHidden_v2", false);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndexChange = useCallback((index: number) => {
    setActiveIndex(index);
  }, []);

  const slides = useMemo(
    () =>
      [
        <SlideEquityPhilosophy
          key="equity-philosophy"
          organizationFragment={organization}
        />,
        !organization.isOriginatingFromRemoteEquity && (
          <SlideNewIntegrations
            key="new-integrations"
            organizationFragment={organization}
          />
        ),
      ]
        .filter(Boolean)
        .map((slide, index) => (
          <SlideContainer
            activeIndex={activeIndex}
            index={index}
            key={`slide-${index}`}
          >
            {slide}
          </SlideContainer>
        )),
    [activeIndex, organization],
  );

  return (
    <FadeAndScaleTransition
      appear={false}
      className="relative h-[247px] overflow-hidden rounded-lg shadow-100"
      show={!adminOverviewCardIsHidden}
    >
      {...slides}
      {slides.length > 1 && (
        <div className="pointer-events-none absolute inset-0 flex items-end justify-center p-2">
          <SlideControls
            activeIndex={activeIndex}
            itemCount={slides.length}
            onIndexChange={handleIndexChange}
          />
        </div>
      )}
      <div className="pointer-events-none absolute inset-0 flex justify-end p-4">
        <button
          className="pointer-events-auto flex h-9 w-9 items-center justify-center rounded-full bg-white/20 backdrop-blur-md"
          onClick={() => {
            setAdminOverviewCardIsHidden(true);
          }}
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
      </div>
    </FadeAndScaleTransition>
  );
};
