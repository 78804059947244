/**
 * @generated SignedSource<<69c9adf93827f403d12307a0474b0e0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsVestingSchedule_CTMSGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly isActive: boolean;
  readonly label: string;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
  readonly vestingScheduleName: string | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActiveOrTerminatedTag_CtmsGrant" | "CtmsGrantVestingTag_CtmsGrant" | "VestingOverview_CTMSGrant">;
  readonly " $fragmentType": "CtmsGrantDetailsVestingSchedule_CTMSGrant";
};
export type CtmsGrantDetailsVestingSchedule_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetailsVestingSchedule_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsVestingSchedule_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsVestingSchedule_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingScheduleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantVestingTag_CtmsGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantActiveOrTerminatedTag_CtmsGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingOverview_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "16477a3c0dcc06047a258d989aa693fb";

export default node;
