import { LoadingSpinner } from "./ui/LoadingSpinner";
import { NoticeMessage } from "./ui/NoticeMessage";

export type RefreshingExercisesRequestJobState = "Active" | "Done" | "Failed";

export const RefreshingExercisesRequestNoticeMessage: React.FC<{
  refreshingExercisesRequestJobState: RefreshingExercisesRequestJobState;
}> = ({ refreshingExercisesRequestJobState }) => {
  if (refreshingExercisesRequestJobState === "Done") {
    return (
      <NoticeMessage size="Large" variant="Positive">
        The exercises data has been successfully refreshed
      </NoticeMessage>
    );
  }

  if (refreshingExercisesRequestJobState === "Failed") {
    return (
      <NoticeMessage size="Large" variant="Danger">
        An error occured while refreshing the exercises data. Please try again
      </NoticeMessage>
    );
  }

  return (
    <NoticeMessage emoji={<LoadingSpinner />} size="Large">
      We’re getting the latest exercises data from your cap table management
      solution
    </NoticeMessage>
  );
};
