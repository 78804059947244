/**
 * @generated SignedSource<<1fc6bf772c4fc6dc4ad2c4df9ca3343b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePage_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalGrantAndValueDetailsSection_Grantee" | "GranteePortalVestingSection_Grantee" | "GranteePortalWelcomeSection_Grantee" | "GranteePortalWhatDoYouHaveSection_Grantee">;
  readonly " $fragmentType": "GranteePage_Grantee";
};
export type GranteePage_Grantee$key = {
  readonly " $data"?: GranteePage_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePage_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePage_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalWelcomeSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalWhatDoYouHaveSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalVestingSection_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalGrantAndValueDetailsSection_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "8ad171292924150ee6d099c87d4e6fda";

export default node;
