import { graphql, useFragment } from "react-relay";

import { BoardMembersEquityGridLevelsTable } from "../../../components/EquityGrid/BoardMembersEquityGridLevelsTable";
import { Page } from "../../../components/Page";
import { Divider } from "../../../components/ui/Divider";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { useBoardMemberIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { BoardMemberEquityGrid_Organization$key } from "./__generated__/BoardMemberEquityGrid_Organization.graphql";
import { BoardMemberEquityGrid_Query } from "./__generated__/BoardMemberEquityGrid_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardMemberEquityGrid_Organization on Organization {
    equityGrid {
      name
    }
    ...BoardMembersEquityGridLevelsTable_Organization
  }
`;

const BoardMemberEquityGridPage_: React.FC<{
  organizationFragment: BoardMemberEquityGrid_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <OneColumnLayout>
      <RoundedBox withBorder withShadow>
        <div className="p-6">
          <Typography variant="Medium/Default">
            {organization.equityGrid.name} - Equity grid
          </Typography>
        </div>
        <Divider />
        <div className="p-6">
          <BoardMembersEquityGridLevelsTable
            organizationFragment={organization}
          />
        </div>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query BoardMemberEquityGrid_Query($boardMemberId: UUID!) {
    boardMember(id: $boardMemberId) {
      organization {
        id
        name
        ...BoardMemberEquityGrid_Organization
      }
    }
  }
`;

const BoardMemberEquityGridPage: React.FC = () => {
  const boardMemberId = useBoardMemberIdParam();
  const { query } = useQuery<BoardMemberEquityGrid_Query>(QUERY, {
    boardMemberId,
  });

  if (!query.boardMember) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Board - Equity Grid"
      organizationId={query.boardMember.organization.id}
      title={`Board Member | ${query.boardMember.organization.name} equity grid`}
    >
      <BoardMemberEquityGridPage_
        organizationFragment={query.boardMember.organization}
      />
    </Page>
  );
};

export default BoardMemberEquityGridPage;
