import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isNil } from "lodash";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { Typography } from "./Typography";

export type StepState = "active" | "completed" | "upcoming";

const StepDotWrapper: React.FC<React.ComponentProps<"div">> = ({
  className,
  ...props
}) => (
  <div
    className={classNames(
      "flex h-6 w-6 items-center justify-center rounded-full",
      className,
    )}
    {...props}
  />
);

const StepDot: React.FC<{
  number?: number;
  state: StepState;
}> = ({ number, state }) => {
  const isMainStep = !isNil(number);

  if (!isMainStep) {
    if (state === "completed") {
      return (
        <StepDotWrapper className="relative text-primary">
          <CheckCircleIcon className="box-content h-4 w-4 rounded-full border-2 border-gray-01 bg-gray-01" />
        </StepDotWrapper>
      );
    }

    return (
      <StepDotWrapper className="relative">
        <div
          className={classNames(
            "box-content h-2 w-2 rounded-full border-2 border-gray-01",
            {
              "bg-gray-09": state === "upcoming",
              "bg-primary": state === "active",
            },
          )}
        />
      </StepDotWrapper>
    );
  }

  if (state === "completed") {
    return (
      <StepDotWrapper className="bg-primary text-gray-01">
        <CheckIcon className="h-4 w-4" />
      </StepDotWrapper>
    );
  }

  return (
    <StepDotWrapper
      className={classNames("text-gray-01", {
        "bg-gray-07": state === "upcoming",
        "bg-primary": state === "active",
      })}
    >
      <Typography as="div" variant="Semibold/Caption">
        {number}
      </Typography>
    </StepDotWrapper>
  );
};

const StepContent: React.FC<{
  children: React.ReactNode;
  isActive?: boolean;
  number?: number;
  state: StepState;
}> = ({ children, isActive, number, state }) => (
  <>
    <StepDot number={number} state={state} />
    <Typography
      as="div"
      className={state === "completed" ? "text-gray-09" : "text-black-07"}
      variant={isActive ? "Medium/Extra Small" : "Regular/Extra Small"}
    >
      {children}
    </Typography>
  </>
);

export const Step: React.FC<{
  children: React.ReactNode;
  className?: string;
  dataCy?: string;
  forceOrder?: boolean;
  linkTo: React.ComponentProps<typeof Link>["to"];
  number?: number;
  state: StepState;
  subSteps?: React.ReactNode;
}> = ({
  children,
  className,
  dataCy,
  forceOrder,
  linkTo,
  number,
  state,
  subSteps,
}) => {
  const isMainStep = !isNil(number);
  const hasSubSteps = !!subSteps;

  const liClassName = classNames(
    {
      [/* tailwind */ `my-2`]: !isMainStep,
    },
    className,
  );

  const contentClassName = /* tailwind */ `flex w-full items-center gap-2 py-1 px-2`;

  if (state === "upcoming" && forceOrder) {
    return (
      <li
        className={classNames(liClassName, { "mb-4": isMainStep })}
        data-cy={dataCy}
      >
        <button
          className={classNames(
            contentClassName,
            "cursor-not-allowed text-left",
          )}
        >
          <StepContent number={number} state={state}>
            {children}
          </StepContent>
        </button>
      </li>
    );
  }

  return (
    <li
      className={classNames(liClassName, {
        "mb-2": hasSubSteps,
        "mb-4": isMainStep && !hasSubSteps,
      })}
      data-cy={dataCy}
    >
      <NavLink className={contentClassName} to={linkTo}>
        {({ isActive }) => (
          <StepContent isActive={isActive} number={number} state={state}>
            {children}
          </StepContent>
        )}
      </NavLink>
      {subSteps && (
        <ul className="before:content relative block before:absolute before:bottom-3 before:left-[19px] before:top-3 before:w-0.5 before:bg-gray-03">
          {subSteps}
        </ul>
      )}
    </li>
  );
};
