/**
 * @generated SignedSource<<18080cbe5433e543dc306200feca347e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafeCalculatorEmptyPlaceholder_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "SafeCalculatorEmptyPlaceholder_Organization";
};
export type SafeCalculatorEmptyPlaceholder_Organization$key = {
  readonly " $data"?: SafeCalculatorEmptyPlaceholder_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SafeCalculatorEmptyPlaceholder_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SafeCalculatorEmptyPlaceholder_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "17bd2ce7965015a17b697177ca08ed0d";

export default node;
