/**
 * @generated SignedSource<<a05053995f37130351e185e6bf4a3185>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewHero_Organization$data = {
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewHero_SlideEquityPhilosophy_Organization" | "OverviewHero_SlideNewIntegrations_Organization">;
  readonly " $fragmentType": "OverviewHero_Organization";
};
export type OverviewHero_Organization$key = {
  readonly " $data"?: OverviewHero_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewHero_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewHero_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewHero_SlideEquityPhilosophy_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OverviewHero_SlideNewIntegrations_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "34d676fb7a70f81b3140b6b42546bbe7";

export default node;
