/**
 * @generated SignedSource<<c5e1f8c8d2c561b99b00eae7a0d275ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminationPlanningEntryDetails_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharesValue_Organization">;
  readonly " $fragmentType": "TerminationPlanningEntryDetails_Organization";
};
export type TerminationPlanningEntryDetails_Organization$key = {
  readonly " $data"?: TerminationPlanningEntryDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationPlanningEntryDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminationPlanningEntryDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "9e5658d8ba5acc7561b8c25ab86a0249";

export default node;
