import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";

import { Typography } from "../ui/Typography";
import dashedGrid from "./dashed_grid.png";

export type TasksSectionTask = {
  action: React.ReactElement<React.ComponentProps<"div">>;
  key: string;
  label: React.ReactNode;
};

export const TasksSection: React.FC<{
  actionCount: number;
  tasks: Array<TasksSectionTask>;
}> = ({ actionCount, tasks }) => (
  <div className="relative flex flex-col gap-2 rounded-lg bg-primary p-6 text-white">
    <img
      alt="Application logo"
      className="absolute right-[-48px] top-0 z-0 block aspect-[2/1] h-full"
      src={dashedGrid}
    />
    <Typography variant="Medium/Extra Large">{actionCount}</Typography>
    <Typography variant="Medium/Small">
      <FormattedMessage
        defaultMessage={`{actionCount, plural, 
            one {Action}
            other {Actions}
          } can be done`}
        values={{
          actionCount,
        }}
      />
    </Typography>
    <Typography as="div" variant="Regular/Small">
      <ul className="list-disc space-y-2 pl-4 text-primary-02">
        {tasks.map((task) => (
          <li key={task.key}>{task.label}</li>
        ))}
      </ul>
    </Typography>
    <div className="flex gap-4 pt-2">
      {tasks.map((task) =>
        React.cloneElement(task.action, {
          className: classNames("z-10", task.action.props.className),
          key: task.key,
        }),
      )}
    </div>
  </div>
);
