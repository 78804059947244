import { isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "../../../../components/ui/Modal";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../../paths";
import { countries } from "../../../../services/Country";
import {
  WORK_RELATIONSHIP_TO_LABEL_HELPER,
  WORK_RELATIONSHIP_TYPES,
} from "../../../../services/workRelationship";
import { PreviewGranteePortalFreemiumModal_Mutation } from "./__generated__/PreviewGranteePortalFreemiumModal_Mutation.graphql";
import { PreviewGranteePortalFreemiumModal_Organization$key } from "./__generated__/PreviewGranteePortalFreemiumModal_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PreviewGranteePortalFreemiumModal_Organization on Organization {
    id
    grantees {
      edges {
        node {
          id
          name
          workRelationship
          taxResidenceCountry {
            code
          }
          activeCTMSGrants: ctmsGrants(grantStatusIn: [Active]) {
            __typename
          }
        }
      }
    }
  }
`;

const MUTATION = graphql`
  mutation PreviewGranteePortalFreemiumModal_Mutation(
    $granteeId: GranteeId!
    $taxResidenceCountryCode: String!
    $workRelationship: WorkRelationship!
  ) {
    updateGranteeAndSetAsOrganizationFreemiumPortalPreviewGrantee(
      granteeId: $granteeId
      taxResidenceCountryCode: $taxResidenceCountryCode
      workRelationship: $workRelationship
    ) {
      freemiumPortalPreviewGrantee {
        id
      }
    }
  }
`;

export const PreviewGranteePortalFreemiumModal: React.FC<{
  onClose: () => void;
  organizationFragment: PreviewGranteePortalFreemiumModal_Organization$key;
  show: boolean;
}> = ({ onClose, organizationFragment, show }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantees = useMemo(
    () =>
      organization.grantees.edges
        .map((edge) => edge.node)
        .filter((grantee) => !isEmpty(grantee.activeCTMSGrants)),
    [organization.grantees],
  );
  const [selectedGrantee, setSelectedGrantee] = useState<
    (typeof grantees)[number] | null
  >(null);

  const [selectedCountry, setSelectedCountry] = useState<
    (typeof countries)[number] | null
  >(null);

  const workRelationshipOptions = useMemo(
    () =>
      WORK_RELATIONSHIP_TYPES.map((workRelationship) => ({
        label:
          WORK_RELATIONSHIP_TO_LABEL_HELPER[workRelationship].singularLabel,
        value: workRelationship,
      })),
    [],
  );

  const [selectedWorkRelationship, setSelectedWorkRelationship] = useState<
    (typeof workRelationshipOptions)[number] | null
  >(null);

  useEffect(() => {
    if (selectedGrantee) {
      const taxResidenceCountry = selectedGrantee.taxResidenceCountry;
      const country = taxResidenceCountry
        ? countries.find((country) => country.code === taxResidenceCountry.code)
        : null;
      setSelectedCountry(country ?? null);

      const workRelationship =
        workRelationshipOptions.find(
          (workRelationshipOption) =>
            workRelationshipOption.value === selectedGrantee.workRelationship,
        ) ?? null;
      setSelectedWorkRelationship(workRelationship ?? null);
    }
  }, [selectedGrantee, workRelationshipOptions]);

  const [updateGranteeCountryAndWorkRelationship, updateIsInFlight] =
    useSafeMutation<PreviewGranteePortalFreemiumModal_Mutation>(MUTATION);

  const goToGranteePortalPreview = useCallback(
    (grantee: (typeof grantees)[number]) => {
      const url = generatePath(APPLICATION_ROUTES["grantee"], {
        granteeId: grantee.id,
      });
      window.open(url, "_blank");
    },
    [],
  );

  const onPreviewClick = useCallback(async () => {
    if (!(selectedGrantee && selectedCountry && selectedWorkRelationship)) {
      return;
    }
    await updateGranteeCountryAndWorkRelationship({
      variables: {
        granteeId: selectedGrantee.id,
        taxResidenceCountryCode: selectedCountry.code,
        workRelationship: selectedWorkRelationship.value,
      },
    });
    onClose();
    goToGranteePortalPreview(selectedGrantee);
  }, [
    selectedGrantee,
    selectedCountry,
    selectedWorkRelationship,
    updateGranteeCountryAndWorkRelationship,
    goToGranteePortalPreview,
    onClose,
  ]);

  return (
    <Modal onClose={onClose} show={show} suspense>
      <Modal.Content
        actionsInHeader={
          <Modal.ActionButton
            disabled={
              !(selectedGrantee && selectedCountry && selectedWorkRelationship)
            }
            loading={updateIsInFlight}
            onClick={() => onPreviewClick()}
            type="button"
          >
            Preview
          </Modal.ActionButton>
        }
        onClose={onClose}
        subTitle="Choose a grantee from your team and preview their portal"
        title="Preview grantee portal"
      >
        <FormRow label="Select a grantee">
          <SelectAutocomplete
            getOptionLabel={(grantee) => grantee.name}
            getOptionValue={(grantee) => grantee.id}
            id="grantee"
            onChange={setSelectedGrantee}
            options={grantees}
            usePortal
            value={selectedGrantee}
          />
        </FormRow>
        <FormRow label="Country of Residence">
          <SelectAutocomplete
            disabled={!selectedGrantee}
            getOptionLabel={(country) => `${country.emoji} ${country.name}`}
            getOptionValue={(country) => country.code}
            id="country"
            onChange={setSelectedCountry}
            options={countries}
            usePortal
            value={selectedCountry}
          />
        </FormRow>
        <FormRow label="Work Relationship">
          <SelectAutocomplete
            disabled={!selectedCountry}
            getOptionLabel={(workRelationship) => workRelationship.label}
            getOptionValue={(workRelationship) => workRelationship.value}
            id="work-relationship"
            onChange={setSelectedWorkRelationship}
            options={workRelationshipOptions}
            usePortal
            value={selectedWorkRelationship}
          />
        </FormRow>
      </Modal.Content>
    </Modal>
  );
};
