import classNames from "classnames";
import { isNil } from "lodash";
import React from "react";
import { NavLink } from "react-router-dom";

import { Pill } from "./Pill";
import { Typography } from "./Typography";

function _TabNavigation({ children }: React.PropsWithChildren) {
  return (
    <div className="flex w-full items-end gap-6 border-b-[0.5px] border-gray-04">
      {children}
    </div>
  );
}

function TabNavigationLink({
  children,
  count,
  end,
  leftIcon,
  to,
}: React.PropsWithChildren<{
  count?: number;
  end?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  to: string;
}>) {
  return (
    <NavLink
      className={({ isActive, isPending, isTransitioning }) =>
        classNames("border-b-2 py-2 transition-all", {
          "animate-pulse border-gray-04": isTransitioning || isPending,
          "border-primary": isActive,
          "border-transparent hover:border-primary": !isActive,
        })
      }
      end={end}
      to={to}
    >
      {({ isActive }) => (
        <div className="flex items-center gap-2">
          {leftIcon &&
            React.cloneElement(leftIcon, {
              "aria-hidden": true,
              className: classNames(
                "w-5",
                {
                  "text-gray-09": !isActive,
                  "text-primary": isActive,
                },
                leftIcon.props.className,
              ),
            })}
          <Typography
            as="div"
            className={classNames("py-0.5 transition-all", {
              "text-black-07": isActive,
              "text-gray-09 hover:text-black-07": !isActive,
            })}
            variant="Medium/Extra Small"
          >
            {children}
          </Typography>
          {!isNil(count) && <Pill>{count}</Pill>}
        </div>
      )}
    </NavLink>
  );
}

export const TabNavigation = Object.assign(_TabNavigation, {
  Link: TabNavigationLink,
});
