import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { StripePaymentMethodLabel_StripePaymentMethod$key } from "./__generated__/StripePaymentMethodLabel_StripePaymentMethod.graphql";

const STRIPE_PAYMENT_METHOD_FRAGMENT = graphql`
  fragment StripePaymentMethodLabel_StripePaymentMethod on StripePaymentMethod {
    cardLast4Digits
  }
`;

export const StripePaymentMethodLabel: React.FC<{
  stripePaymentMethodFragment: StripePaymentMethodLabel_StripePaymentMethod$key;
}> = ({ stripePaymentMethodFragment }) => {
  const { cardLast4Digits } = useFragment(
    STRIPE_PAYMENT_METHOD_FRAGMENT,
    stripePaymentMethodFragment,
  );

  if (!cardLast4Digits) {
    return null;
  }

  return <>Ends with {cardLast4Digits}</>;
};
