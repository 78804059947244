/**
 * @generated SignedSource<<bbca344103ef84aa6c571d828e145bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL" | "VALUATION_PENDING_BOARD_APPROVAL" | "VALUATION_UNDER_REVIEW";
export type BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation$variables = {
  fairMarketValueId: string;
};
export type BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation$data = {
  readonly sendFairMarketValueToBoardForConsent: {
    readonly valuationWarnings: ReadonlyArray<{
      readonly reason: ValuationWarningReason;
      readonly valuationType: ValuationType;
    }>;
  };
};
export type BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation = {
  response: BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation$data;
  variables: BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValueId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fairMarketValueId",
    "variableName": "fairMarketValueId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationValuationWarning",
  "kind": "LinkedField",
  "name": "valuationWarnings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueToBoardForConsent",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueToBoardForConsent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c4071489e08d14d601624098792fd4d",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardApproval_FairMarketValues_SendFairMarketValueToBoardForConsent_Mutation(\n  $fairMarketValueId: ID!\n) {\n  sendFairMarketValueToBoardForConsent(fairMarketValueId: $fairMarketValueId) {\n    valuationWarnings {\n      reason\n      valuationType\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1434326d44c95d9b4c45fed407c1c36c";

export default node;
