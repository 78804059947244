/**
 * @generated SignedSource<<9a92aba630a4b8426716867ce7cc0b70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SimulateExerciseModal_Query$variables = {
  granteeId: string;
};
export type SimulateExerciseModal_Query$data = {
  readonly grantee: {
    readonly __typename: "Grantee";
    readonly ctmsGrants: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly exercisePrice: number | null;
      readonly id: string;
      readonly label: string;
      readonly quantityIssued: number;
      readonly vestingStartDate: string | null;
    }>;
    readonly organization: {
      readonly id: string;
    };
  };
};
export type SimulateExerciseModal_Query = {
  response: SimulateExerciseModal_Query$data;
  variables: SimulateExerciseModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v3/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "CTMSGrant",
  "kind": "LinkedField",
  "name": "ctmsGrants",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SimulateExerciseModal_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SimulateExerciseModal_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45595acd0c1916e3d463864244a9d80f",
    "id": null,
    "metadata": {},
    "name": "SimulateExerciseModal_Query",
    "operationKind": "query",
    "text": "query SimulateExerciseModal_Query(\n  $granteeId: GranteeId!\n) {\n  grantee(id: $granteeId) {\n    __typename\n    organization {\n      id\n    }\n    ctmsGrants {\n      id\n      label\n      exercisePrice\n      vestingStartDate\n      quantityIssued\n      cumulativeVested\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "21dafe095aa9423a0a8dba947c1375cd";

export default node;
