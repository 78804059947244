/**
 * @generated SignedSource<<f1132cf19d033fc7e4a957706f4a4400>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantEquityFreemium_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"FreemiumBlockerCard_Organization">;
  readonly " $fragmentType": "GrantEquityFreemium_Organization";
};
export type GrantEquityFreemium_Organization$key = {
  readonly " $data"?: GrantEquityFreemium_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantEquityFreemium_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantEquityFreemium_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreemiumBlockerCard_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e08193c0ecc4d8cf8e28386d84df257f";

export default node;
