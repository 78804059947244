import { Avatar } from "@remote-com/norma";
import React, { useMemo, useRef } from "react";
import { graphql, useLazyLoadQuery } from "react-relay";
import { Outlet } from "react-router-dom";
import { useScroll } from "react-use";

import NotFoundPage from "../../../../pages/NotFound/NotFound";
import { useGranteeIdParam } from "../../../../paths";
import { useAuthenticatedAccount } from "../../../../services/AuthenticatedGuard";
import { ApplicationSideBar } from "../../ApplicationSideBar/ApplicationSideBar";
import { Logo } from "../../Logo/Logo";
import { RemoteLikeGranteePortalLayout_Query } from "./__generated__/RemoteLikeGranteePortalLayout_Query.graphql";
import { SectionLabel, SECTIONS } from "./SECTIONS";
import { RemoteLikeGranteePortalLayoutOutletContext } from "./useRemoteLikeGranteePortalLayoutOutletContext";

const TopBar: React.FC = () => {
  const authenticatedAccount = useAuthenticatedAccount();
  const fullName = `${authenticatedAccount.firstName} ${authenticatedAccount.lastName}`;

  return (
    <div className="border-b border-remote-grey-200 bg-white">
      <div className="mx-auto flex items-center justify-between gap-4 px-8 py-5.5">
        <Logo className="block h-5.5" variant="black" />
        <div className="flex items-center gap-2">
          <Avatar name={fullName} size="sm" />
          <div>
            <div className="text-remote-XS/Medium text-remote-grey-900">
              {fullName}
            </div>
            <div className="text-remote-XS text-remote-grey-600">
              {authenticatedAccount.email}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ORGANIZATION_QUERY = graphql`
  query RemoteLikeGranteePortalLayout_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      organization {
        ...ApplicationSideBar_Organization
      }
    }
    me {
      ...ApplicationSideBar_Viewer
    }
  }
`;

export const RemoteLikeGranteePortalLayout: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const containerScrollRef = useRef<HTMLDivElement>(null);
  const { grantee, me: viewer } =
    useLazyLoadQuery<RemoteLikeGranteePortalLayout_Query>(ORGANIZATION_QUERY, {
      granteeId,
    });
  const [activeSection, setActiveSection] = React.useState<SectionLabel>(
    SECTIONS[0].label,
  );
  const containerScroll = useScroll(containerScrollRef);

  const outletContext: RemoteLikeGranteePortalLayoutOutletContext = useMemo(
    () => ({
      containerHeight: containerScrollRef.current?.clientHeight ?? 0,
      containerScrollY: containerScroll.y,
      setActiveSection,
    }),
    [containerScroll.y],
  );

  if (!grantee) {
    return <NotFoundPage />;
  }

  return (
    <div className="grid min-h-full grid-rows-[auto_1fr] font-remote-body">
      <div className="sticky top-0 z-10">
        <TopBar />
      </div>
      <div className="fixed flex h-screen w-full flex-col bg-white pt-20 md:flex-row">
        <div className="hidden flex-shrink-0 md:block">
          <ApplicationSideBar
            activeRole="Grantee"
            className="flex-shrink-0"
            organizationFragment={grantee.organization}
            showSideBarHeader={false}
            viewerFragment={viewer}
          >
            <div className="flex flex-col gap-[1px]">
              {SECTIONS.map(({ generateHref, icon, label }) => (
                <ApplicationSideBar.NavAnchorLink
                  active={activeSection === label}
                  href={generateHref(granteeId)}
                  icon={icon}
                  key={label}
                  layoutId="grantee-portal-sidebar"
                >
                  {label}
                </ApplicationSideBar.NavAnchorLink>
              ))}
            </div>
          </ApplicationSideBar>
        </div>
        <div
          className="h-full flex-auto scroll-py-6 overflow-auto overflow-x-hidden scroll-smooth bg-white md:overflow-x-auto md:px-6 md:py-6"
          ref={containerScrollRef}
        >
          <Outlet context={outletContext} />
        </div>
      </div>
    </div>
  );
};
