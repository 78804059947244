import { useEffect } from "react";
import { useController, useWatch } from "react-hook-form";

import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "../GrantedSharesInput";
import { FormRow } from "../ui/Form/FormRow";
import { RadioGroup } from "../ui/Form/RadioGroup";
import { Modal } from "../ui/Modal";
import { FormValues } from "./FORM_SCHEMA";
import { useSimulateAGrantForm } from "./useSimulateAGrantForm";

const ModalContent: React.FC<{
  existingSimilatedGrant?: FormValues;
  maximumShares?: null | number;
  onClose: () => void;
  onSubmit: (data: FormValues) => void;
  postConversionCurrentPricePerShare: null | number;
  postConversionFullyDilutedShares: number;
  preConversionFullyDilutedShares: number;
  submitButtonLabel?: React.ReactNode;
}> = ({
  existingSimilatedGrant,
  maximumShares,
  onClose,
  onSubmit: _onSubmit,
  postConversionCurrentPricePerShare,
  postConversionFullyDilutedShares,
  preConversionFullyDilutedShares,
  submitButtonLabel = "Add",
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = useSimulateAGrantForm({
    defaultValues: existingSimilatedGrant ?? undefined,
  });

  const onSubmit = handleSubmit((data) => {
    _onSubmit(data);
  });

  const [type] = useWatch({
    control,
    name: ["type"],
  });

  const sharesInputController = useController({
    control,
    name: "shares",
  });

  const modeInputController = useController({
    control,
    name: "grantedSharesInputMode",
  });

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares:
      type === "PRE_MONEY"
        ? preConversionFullyDilutedShares
        : postConversionFullyDilutedShares,
    initialState: {
      mode: modeInputController.field.value,
      shares: sharesInputController.field.value,
    },
    latestPricePerShare:
      type === "PRE_MONEY" ? null : postConversionCurrentPricePerShare,
    maximumShares,
  });

  useEffect(() => {
    sharesInputController.field.onChange(grantedSharesController.shares);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantedSharesController.shares]);

  useEffect(() => {
    modeInputController.field.onChange(grantedSharesController.mode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grantedSharesController.mode]);

  useEffect(() => {
    const subscription = watch((values, changed) => {
      if (changed.name === "type") {
        grantedSharesController.setShares(0);
        grantedSharesController.setMode("SHARES");
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [grantedSharesController, watch]);

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.ActionButton
          form="grant-simulation"
          loading={isSubmitting}
          type="submit"
        >
          {submitButtonLabel}
        </Modal.ActionButton>
      }
      onClose={onClose}
      subTitle="Enter the ownership of the grant"
      title="Simulate a grant"
    >
      <Modal.Form id="grant-simulation" onSubmit={onSubmit}>
        <div className="space-y-6">
          <FormRow.Form control={control} label="Based on" name="type">
            <RadioGroup.Form control={control} name="type">
              <div className="grid grid-cols-2 gap-4">
                <RadioGroup.Card
                  description="Will calculate the post conversion based on the pre conversion input"
                  value="PRE_MONEY"
                >
                  Pre SAFE conversion
                </RadioGroup.Card>
                <RadioGroup.Card
                  description="Will calculate the pre conversion based on the post conversion input"
                  value="POST_MONEY"
                >
                  Post SAFE conversion
                </RadioGroup.Card>
              </div>
            </RadioGroup.Form>
          </FormRow.Form>
          <FormRow.Form
            control={control}
            label="I want to offer the following ownership"
            name="shares"
          >
            <GrantedSharesInput {...grantedSharesController.inputController} />
          </FormRow.Form>
        </div>
      </Modal.Form>
    </Modal.Content>
  );
};

export const SimulateAGrantFormModal: React.FC<
  React.ComponentProps<typeof ModalContent> & {
    show: boolean;
  }
> = ({ onClose, show, ...props }) => {
  return (
    <Modal onClose={onClose} show={show}>
      <ModalContent onClose={onClose} {...props} />
    </Modal>
  );
};
