/**
 * @generated SignedSource<<465a02edbd89bed14f0de0d46294e25c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Projection_Grantee_CTMSGrant$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"GranteeOutcome_CTMSGrant">;
  readonly " $fragmentType": "Projection_Grantee_CTMSGrant";
}>;
export type Projection_Grantee_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: Projection_Grantee_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"Projection_Grantee_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Projection_Grantee_CTMSGrant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeOutcome_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "2c1851ab15749478b98bc01ed6729c57";

export default node;
