/**
 * @generated SignedSource<<cc2dd96efc06693cea41a31f52c653df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTasksCard_BoardMember$data = {
  readonly id: string;
  readonly pendingSignatureRequestsCount: number;
  readonly " $fragmentType": "AdminTasksCard_BoardMember";
};
export type AdminTasksCard_BoardMember$key = {
  readonly " $data"?: AdminTasksCard_BoardMember$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminTasksCard_BoardMember">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminTasksCard_BoardMember",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pendingSignatureRequestsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BoardMember",
  "abstractKey": null
};

(node as any).hash = "ddd1e16fc6d89e139443aff579dfc16c";

export default node;
