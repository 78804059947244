import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { LinkButton } from "../../../components/ui/Button";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { HRISProviderLogo } from "../../../hooks/useHRISProvider";
import {
  ExerciseRequestNotifySection_CTMSGrant$data,
  ExerciseRequestNotifySection_CTMSGrant$key,
  HRISProvider,
} from "./__generated__/ExerciseRequestNotifySection_CTMSGrant.graphql";
import {
  ExerciseRequestNotifySection_FairMarketValueAtExercise$data,
  ExerciseRequestNotifySection_FairMarketValueAtExercise$key,
} from "./__generated__/ExerciseRequestNotifySection_FairMarketValueAtExercise.graphql";
import {
  ExerciseRequestNotifySection_Organization$data,
  ExerciseRequestNotifySection_Organization$key,
} from "./__generated__/ExerciseRequestNotifySection_Organization.graphql";
import {
  ExerciseRequestNotifySection_Viewer$data,
  ExerciseRequestNotifySection_Viewer$key,
} from "./__generated__/ExerciseRequestNotifySection_Viewer.graphql";
import { ShareTaxInformation } from "./ShareTaxInformation";
import { TaxDetailsContent } from "./TaxDetailsContent";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseRequestNotifySection_CTMSGrant on CTMSGrant {
    grantee {
      name
      workRelationship
      employerOfRecord {
        hRISProvider
        name
      }
    }
    ...TaxDetailsContent_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestNotifySection_Organization on Organization {
    name
    ...TaxDetailsContent_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestNotifySection_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT = graphql`
  fragment ExerciseRequestNotifySection_FairMarketValueAtExercise on FairMarketValue {
    ...TaxDetailsContent_FairMarketValueAtExercise
  }
`;

const DirectEmployeeSection: React.FC<{
  ctmsGrant: ExerciseRequestNotifySection_CTMSGrant$data;
  currentStep: number;
  exercisePrice: number;
  exerciseRequestCTMSId?: null | string;
  fairMarketValueAtExercise: ExerciseRequestNotifySection_FairMarketValueAtExercise$data;
  organization: ExerciseRequestNotifySection_Organization$data;
  quantityExercised: number;
  spread: number;
  taxResidenceCountryIsKnown: boolean;
  totalExercisePrice: number;
  totalSteps: number;
  viewer: ExerciseRequestNotifySection_Viewer$data;
}> = ({
  ctmsGrant,
  currentStep,
  exercisePrice,
  exerciseRequestCTMSId,
  fairMarketValueAtExercise,
  organization,
  quantityExercised,
  spread,
  taxResidenceCountryIsKnown,
  totalExercisePrice,
  totalSteps,
  viewer,
}) => {
  const { grantee } = ctmsGrant;
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] About taxes`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <ExerciseRequestTimelineSection
      chipContent={
        <Typography className="text-black-07" variant="Regular/Extra Small">
          {currentStep}
        </Typography>
      }
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Default">
            Notify your payroll system or accountant
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Small"
          >
            Since {grantee.name} is a direct employee, you should communicate
            the information regarding the exercise to your payroll system or
            account so they can handle their tax reporting and withholding
            obligations on your behalf.
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Want to learn more about your withholding & reporting obligations?{" "}
            <a href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}>
              <Typography className="text-primary" variant="Medium/Extra Small">
                Contact us
              </Typography>
            </a>
            .
          </Typography>
        </div>
        <RoundedBox
          className="space-y-6 !rounded-[20px] px-10 py-6"
          withBorder
          withShadow
        >
          <div className="flex w-full flex-col items-center gap-2">
            <Typography variant="Medium/Default">
              Tax information for your payroll system or accountant
            </Typography>
          </div>
          <TaxDetailsContent
            ctmsGrantFragment={ctmsGrant}
            exercisePrice={exercisePrice}
            fairMarketValueAtExerciseFragment={fairMarketValueAtExercise}
            organizationFragment={organization}
            quantityExercised={quantityExercised}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalExercisePrice={totalExercisePrice}
          />
          <div className="flex items-center gap-6">
            <Typography
              as="div"
              className="flex-grow text-right text-black-05"
              variant="Regular/Extra Small"
            >
              Step {currentStep} of {totalSteps}
            </Typography>
            {viewer.isAllowedToManageOrganization && exerciseRequestCTMSId && (
              <ShareTaxInformation
                exerciseRequestCTMSId={exerciseRequestCTMSId}
              />
            )}
          </div>
        </RoundedBox>
      </div>
    </ExerciseRequestTimelineSection>
  );
};

const LoginToHRISProviderButton: React.FC<{
  hRISProvider: HRISProvider;
}> = ({ hRISProvider }) => {
  switch (hRISProvider) {
    case "DEEL":
      return (
        <LinkButton
          size="small"
          target="_blank"
          to="https://app.deel.com/login"
          variant="Primary Outline"
        >
          Login to deel.com
        </LinkButton>
      );
    case "REMOTE":
      return (
        <LinkButton
          size="small"
          target="_blank"
          to="https://employ.remote.com/sign-in"
          variant="Primary Outline"
        >
          Login to remote.com
        </LinkButton>
      );
  }
};

const EoRSection: React.FC<{
  ctmsGrant: ExerciseRequestNotifySection_CTMSGrant$data;
  currentStep: number;
  exercisePrice: number;
  exerciseRequestCTMSId?: null | string;
  fairMarketValueAtExercise: ExerciseRequestNotifySection_FairMarketValueAtExercise$data;
  organization: ExerciseRequestNotifySection_Organization$data;
  quantityExercised: number;
  spread: number;
  taxResidenceCountryIsKnown: boolean;
  totalExercisePrice: number;
  totalSteps: number;
  viewer: ExerciseRequestNotifySection_Viewer$data;
}> = ({
  ctmsGrant,
  currentStep,
  exercisePrice,
  exerciseRequestCTMSId,
  fairMarketValueAtExercise,
  organization,
  quantityExercised,
  spread,
  taxResidenceCountryIsKnown,
  totalExercisePrice,
  totalSteps,
  viewer,
}) => {
  const { grantee } = ctmsGrant;
  const { employerOfRecord } = grantee;

  return (
    <ExerciseRequestTimelineSection
      chipContent={
        <Typography className="text-black-07" variant="Regular/Extra Small">
          {currentStep}
        </Typography>
      }
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Default">
            Notify your EoR about the exercise of {grantee.name}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Small"
          >
            Since {grantee.name} is an EoR employee, you should communicate the
            information regarding the exercise to the EoR so they can handle
            their tax reporting and withholding obligations.
          </Typography>
        </div>
        <RoundedBox
          className="space-y-6 !rounded-[20px] px-10 py-6"
          withBorder
          withShadow
        >
          <div className="flex w-full flex-col items-center gap-2">
            <div className="flex items-center gap-2">
              <Typography variant="Medium/Default">
                Tax information for
              </Typography>
              {employerOfRecord?.hRISProvider ? (
                <HRISProviderLogo
                  className="h-6"
                  hRISProvider={employerOfRecord.hRISProvider}
                />
              ) : (
                <Typography variant="Medium/Default">
                  {employerOfRecord?.name || "your Employer of Record"}
                </Typography>
              )}
            </div>
          </div>

          <TaxDetailsContent
            ctmsGrantFragment={ctmsGrant}
            exercisePrice={exercisePrice}
            fairMarketValueAtExerciseFragment={fairMarketValueAtExercise}
            organizationFragment={organization}
            quantityExercised={quantityExercised}
            spread={spread}
            taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
            totalExercisePrice={totalExercisePrice}
          />
          <div className="flex items-center gap-6">
            <Typography
              as="div"
              className="flex-grow text-right text-black-05"
              variant="Regular/Extra Small"
            >
              Step {currentStep} of {totalSteps}
            </Typography>
            <div className="flex gap-2">
              {employerOfRecord && employerOfRecord.hRISProvider && (
                <LoginToHRISProviderButton
                  hRISProvider={employerOfRecord.hRISProvider}
                />
              )}
              {viewer.isAllowedToManageOrganization &&
                exerciseRequestCTMSId && (
                  <ShareTaxInformation
                    exerciseRequestCTMSId={exerciseRequestCTMSId}
                  >
                    Share information with{" "}
                    {employerOfRecord?.name || "your EoR"}
                  </ShareTaxInformation>
                )}
            </div>
          </div>
        </RoundedBox>
      </div>
    </ExerciseRequestTimelineSection>
  );
};

export const ExerciseRequestNotifySection: React.FC<{
  ctmsGrantFragment: ExerciseRequestNotifySection_CTMSGrant$key;
  currentStep: number;
  exercisePrice: number;
  exerciseRequestCTMSId?: null | string;
  fairMarketValueAtExerciseFragment: ExerciseRequestNotifySection_FairMarketValueAtExercise$key;
  organizationFragment: ExerciseRequestNotifySection_Organization$key;
  quantityExercised: number;
  spread: number;
  taxResidenceCountryIsKnown: boolean;
  totalExercisePrice: number;
  totalSteps: number;
  viewerFragment: ExerciseRequestNotifySection_Viewer$key;
}> = ({
  ctmsGrantFragment,
  currentStep,
  exercisePrice,
  exerciseRequestCTMSId,
  fairMarketValueAtExerciseFragment,
  organizationFragment,
  quantityExercised,
  spread,
  taxResidenceCountryIsKnown,
  totalExercisePrice,
  totalSteps,
  viewerFragment,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const fairMarketValueAtExercise = useFragment(
    FAIR_MARKET_VALUE_AT_EXERCISE_FRAGMENT,
    fairMarketValueAtExerciseFragment,
  );

  const { grantee } = ctmsGrant;

  if (!grantee.workRelationship) return null;

  switch (grantee.workRelationship) {
    case "Advisor":
    case "ContractorManagementCompany":
    case "ContractorNaturalPerson":
      return null;
    case "DirectEmployee":
      return (
        <DirectEmployeeSection
          ctmsGrant={ctmsGrant}
          currentStep={currentStep}
          exercisePrice={exercisePrice}
          exerciseRequestCTMSId={exerciseRequestCTMSId}
          fairMarketValueAtExercise={fairMarketValueAtExercise}
          organization={organization}
          quantityExercised={quantityExercised}
          spread={spread}
          taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
          totalExercisePrice={totalExercisePrice}
          totalSteps={totalSteps}
          viewer={viewer}
        />
      );
    case "EoREmployee":
      return (
        <EoRSection
          ctmsGrant={ctmsGrant}
          currentStep={currentStep}
          exercisePrice={exercisePrice}
          exerciseRequestCTMSId={exerciseRequestCTMSId}
          fairMarketValueAtExercise={fairMarketValueAtExercise}
          organization={organization}
          quantityExercised={quantityExercised}
          spread={spread}
          taxResidenceCountryIsKnown={taxResidenceCountryIsKnown}
          totalExercisePrice={totalExercisePrice}
          totalSteps={totalSteps}
          viewer={viewer}
        />
      );
  }
};
