/**
 * @generated SignedSource<<65c49ba48b414988c02b609c5589d433>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantForm_Organization$data = {
  readonly allowAcceleration: boolean;
  readonly allowEarlyExercise: boolean;
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly fullyDilutedShares: number | null;
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GrantForm_Grantees">;
      };
    }>;
  };
  readonly hasCooleyAsOutsideCounsel: boolean;
  readonly id: string;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialNoticeMessage_Organization" | "GranteeFormSlide_Organization" | "GranteeNotGrantableAlert_Organization" | "GranteeSelect_Organization" | "PostTerminationExercisePeriodSelectionBlock_Organization" | "SelectVestingScheduleInput_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GrantForm_Organization";
};
export type GrantForm_Organization$key = {
  readonly " $data"?: GrantForm_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantForm_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantForm_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCooleyAsOutsideCounsel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "organizationId",
                      "variableName": "organizationId"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "GrantForm_Grantees"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAcceleration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowEarlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeSelect_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNotGrantableAlert_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostTerminationExercisePeriodSelectionBlock_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectVestingScheduleInput_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EarlyExerciseBeneficialNoticeMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1b71c063ecd14514ecf8da668452bd79";

export default node;
