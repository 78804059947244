/**
 * @generated SignedSource<<5c8d05555f445443473f6eecb4298778>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation$variables = {
  organizationId: string;
  returnURL: string;
};
export type OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation$data = {
  readonly generateOrganizationRemoteAuthorizationFlowURL: string;
};
export type OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation = {
  response: OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation$data;
  variables: OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnURL"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "returnURL",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "generateOrganizationRemoteAuthorizationFlowURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8e3cce2581d12a02c1c93f3fecf3f29",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation(\n  $organizationId: OrganizationId!\n  $returnURL: String!\n) {\n  generateOrganizationRemoteAuthorizationFlowURL(organizationId: $organizationId, returnURL: $returnURL)\n}\n"
  }
};
})();

(node as any).hash = "55a5b524421cfda495eecd9c935c2f78";

export default node;
