import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DocumentRow } from "../../../../components/DocumentRow";
import { TextButton } from "../../../../components/ui/TextButton";
import { Typography } from "../../../../components/ui/Typography";
import { useDownloadInstrumentDocumentsPreview } from "../../../../hooks/useDownloadInstrumentDocumentsPreview";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import { DocumentTemplateType } from "../../Documents/useGrantDocuments";
import { EquityTypeWorkRelationshipDocumentSet_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipDocumentSet_EquityType.graphql";
import { EquityTypeWorkRelationshipDocumentSet_Instrument$key } from "./__generated__/EquityTypeWorkRelationshipDocumentSet_Instrument.graphql";
import { EquityTypeWorkRelationshipDocumentSet_Organization$key } from "./__generated__/EquityTypeWorkRelationshipDocumentSet_Organization.graphql";

const EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_ORGANIZATION_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDocumentSet_Organization on Organization {
    ...useDownloadInstrumentDocumentsPreview_Organization
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDocumentSet_EquityType on EquityType {
    taxResidenceCountry {
      name
    }
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_INSTRUMENT_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDocumentSet_Instrument on Instrument {
    id
    workRelationship
    taxResidenceCountry {
      name
    }
    grantNoticeDocumentTemplate {
      id
      name
    }
    formOfExerciseAgreementDocumentTemplate {
      id
      name
    }
    countrySpecificAddendumDocumentTemplate {
      id
      name
    }
    boardConsentDocumentTemplate {
      id
      name
    }
    awardAgreementDocumentTemplate {
      id
      name
    }
    formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate {
      id
      name
    }
    ...useDownloadInstrumentDocumentsPreview_Instrument
  }
`;

const EquityTypeWorkRelationshipDocumentSet: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipDocumentSet_EquityType$key;
  instrumentFragment: EquityTypeWorkRelationshipDocumentSet_Instrument$key;
  organizationFragment: EquityTypeWorkRelationshipDocumentSet_Organization$key;
}> = ({ equityTypeFragment, instrumentFragment, organizationFragment }) => {
  const organization = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const instrument = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_INSTRUMENT_FRAGMENT,
    instrumentFragment,
  );

  const documentations: Array<{
    id: string;
    name: string;
    type: DocumentTemplateType;
  }> = useMemo(() => {
    const _documentations: Array<{
      document: (typeof instrument)["awardAgreementDocumentTemplate"];
      documentType: DocumentTemplateType;
    }> = [
      {
        document: instrument.awardAgreementDocumentTemplate,
        documentType: "AWARD_AGREEMENT",
      },
      {
        document: instrument.boardConsentDocumentTemplate,
        documentType: "BOARD_CONSENT",
      },
      {
        document: instrument.countrySpecificAddendumDocumentTemplate,
        documentType: "COUNTRY_SPECIFIC_ADDENDUM",
      },
      {
        document:
          instrument.formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate,
        documentType: "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
      },
      {
        document: instrument.formOfExerciseAgreementDocumentTemplate,
        documentType: "FORM_OF_EXERCISE_AGREEMENT",
      },
      {
        document: instrument.grantNoticeDocumentTemplate,
        documentType: "GRANT_NOTICE",
      },
    ];
    return _documentations.reduce(
      (documents, { document, documentType }) => {
        if (document) {
          documents.push({ ...document, type: documentType });
        }
        return documents;
      },
      [] as typeof documentations,
    );
  }, [instrument]);

  const { download, downloadIsInFlight, isDownloadAllowed } =
    useDownloadInstrumentDocumentsPreview({
      instrumentFragment: instrument,
      organizationFragment: organization,
    });

  const workRelationship =
    WORK_RELATIONSHIP_TO_LABEL_HELPER[instrument.workRelationship];

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-start justify-between pt-6">
        <Typography variant="Medium/Small">
          {workRelationship.pluralLabel} in{" "}
          {equityType.taxResidenceCountry.name}
        </Typography>

        {isDownloadAllowed && (
          <TextButton
            className="ml-20 shrink-0"
            disabled={downloadIsInFlight}
            onClick={download}
            variant="Medium/Small"
          >
            {downloadIsInFlight ? "Download in progress" : "Download all"}
          </TextButton>
        )}
      </div>

      {documentations.map((documentation) => (
        <DocumentRow
          key={documentation.id}
          label={`${instrument.taxResidenceCountry.name} - ${workRelationship.singularLabel} - ${documentation.name}.pdf`}
        />
      ))}
    </div>
  );
};

export default EquityTypeWorkRelationshipDocumentSet;
