import { Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import { LoadingSpinner } from "./LoadingSpinner";

const sizes = ({ iconOnly }: { iconOnly: boolean }) => ({
  "extra small": {
    button: classNames("min-h-6.5 gap-1 px-3"),
    icon: classNames("h-4 w-4"),
  },
  large: {
    button: classNames("gap-2", {
      "min-h-12 px-6": !iconOnly,
      "p-4": iconOnly,
    }),
    icon: classNames(`h-6 w-6`),
  },
  medium: {
    button: classNames("gap-2", {
      "min-h-10 px-6": !iconOnly,
      "p-3": iconOnly,
    }),
    icon: classNames(`h-6 w-6`),
  },
  small: {
    button: classNames("gap-2", {
      "min-h-8.5 px-5": !iconOnly,
      "p-2": iconOnly,
    }),
    icon: classNames("h-5 w-5"),
  },
});

const spinnerSizes = ({ iconOnly }: { iconOnly: boolean }) => ({
  "extra small": classNames(`h-4 w-4`, {
    "mr-0.5": !iconOnly,
  }),
  large: classNames(`h-6 w-6`, {
    "mr-3": !iconOnly,
  }),
  medium: classNames(`h-6 w-6`, {
    "mr-2": !iconOnly,
  }),
  small: classNames(`h-5 w-5`, {
    "mr-1": !iconOnly,
  }),
});

const variants = () => ({
  "Approve Light": classNames(
    `bg-green-01 text-green-08 active:bg-green-03 disabled:bg-green-01 disabled:text-green-03`,
  ),
  "Danger Full": classNames(
    `bg-red text-white hover:bg-red-06 active:bg-red-07 disabled:bg-red-02 disabled:text-red-03`,
  ),
  "Danger Link": classNames(
    `bg-white-01 text-red hover:bg-red-01 hover:text-red-04 active:bg-red-02 active:text-red disabled:bg-white-01 disabled:text-red-03`,
  ),
  "Danger Outline": classNames(
    `border border-red text-red hover:border-red-06 hover:text-red-06 active:border-red-07 active:text-red-07 disabled:border-none disabled:bg-red-02 disabled:text-red-03`,
  ),
  "Primary Full": classNames(
    `bg-primary text-white hover:bg-primary-06 active:bg-primary-07 disabled:bg-gray-03 disabled:text-gray-07`,
  ),
  "Primary Outline": classNames(
    `border border-primary text-primary hover:border-primary-06 hover:text-primary-06 active:border-primary-07 active:text-primary-07 disabled:border-none disabled:bg-primary-02 disabled:text-primary-03`,
  ),
  "Secondary Full": classNames(
    `bg-gray-02 text-black-07 hover:bg-gray-03 active:bg-gray-04 disabled:bg-gray-03 disabled:text-gray-07`,
  ),
  "Secondary Outline": classNames(
    `border border-gray-06 text-black-07 hover:border-gray-07 hover:text-black-08 active:border-gray-07 active:text-black-09 disabled:border-none disabled:bg-gray-03 disabled:text-gray-07`,
  ),
  "Tertiary Link": classNames(
    `bg-white-01 text-primary hover:bg-primary-01 hover:text-primary-04 active:bg-primary-02 active:text-primary disabled:bg-white-01 disabled:text-gray-07`,
  ),
  "Tertiary Transparent": classNames(
    `bg-white-01/20 text-white-01 hover:bg-white-01/40 active:bg-white-01/60 disabled:bg-white-01 disabled:text-gray-07`,
  ),
});

type Size = keyof ReturnType<typeof sizes>;
type Variant = keyof ReturnType<typeof variants>;

const getClassName = ({
  className,
  fullWidth,
  iconOnly,
  loading,
  size,
  variant,
}: {
  className?: string;
  fullWidth: boolean;
  iconOnly: boolean;
  loading: boolean;
  size: Size;
  success: boolean;
  variant: Variant;
}) =>
  classNames(
    /* tailwind */ "inline-flex cursor-pointer select-none items-center rounded-full text-center font-sans text-sm font-medium leading-normal transition-all disabled:pointer-events-none",
    variants()[variant],
    sizes({ iconOnly })[size].button,
    {
      /* tailwind */ "pointer-events-none": loading,
      /* tailwind */ "w-full justify-center": fullWidth,
    },
    className,
  );

export interface ButtonProps extends React.ComponentPropsWithRef<"button"> {
  fullWidth?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  loading?: boolean;
  loadingText?: string;
  remoteLike?: boolean;
  rightIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  size?: Size;
  success?: boolean;
  variant?: Variant;
}

const Button_: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  ButtonProps
> = (
  {
    children,
    className,
    fullWidth = false,
    leftIcon,
    loading = false,
    loadingText,
    onClick,
    rightIcon,
    size = "medium",
    success = false,
    variant = "Primary Full",
    ...props
  },
  ref,
) => {
  const iconOnly =
    !children && ((!leftIcon && !!rightIcon) || (!rightIcon && !!leftIcon));
  const [onClickHandlerIsLoading, setOnClickHandlerIsLoading] = useState(false);

  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setOnClickHandlerIsLoading(true);
      await Promise.resolve(onClick?.(event)).finally(() => {
        setOnClickHandlerIsLoading(false);
      });
    },
    [onClick],
  );

  const showLoading = onClickHandlerIsLoading || loading;

  return (
    <button
      className={getClassName({
        className,
        fullWidth,
        iconOnly,
        loading,
        size,
        success,
        variant,
      })}
      onClick={handleClick}
      {...props}
      ref={ref}
    >
      <Transition
        as="div"
        className="flex items-center justify-center"
        enter="transition-all duration-150"
        enterFrom="opacity-0 -ml-6"
        enterTo="opacity-100 ml-0"
        leave="transition-all duration-75"
        leaveFrom="opacity-100 ml-0"
        leaveTo="opacity-0 -ml-6"
        show={showLoading}
      >
        <LoadingSpinner
          className={classNames(spinnerSizes({ iconOnly })[size])}
        />
      </Transition>

      {!showLoading &&
        leftIcon &&
        React.cloneElement(leftIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            leftIcon.props.className,
          ),
        })}
      {showLoading ? loadingText || null : children}
      {!showLoading &&
        rightIcon &&
        React.cloneElement(rightIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            rightIcon.props.className,
          ),
        })}
    </button>
  );
};

export const Button = React.forwardRef(Button_);

interface LinkButtonProps extends React.ComponentProps<typeof Link> {
  fullWidth?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  remoteLike?: boolean;
  rightIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  size?: Size;
  variant?: Variant;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  children,
  className,
  fullWidth = false,
  leftIcon,
  rightIcon,
  size = "medium",
  variant = "Primary Full",
  ...props
}) => {
  const iconOnly =
    !children && ((!leftIcon && !!rightIcon) || (!rightIcon && !!leftIcon));

  return (
    <Link
      className={getClassName({
        className,
        fullWidth,
        iconOnly,
        loading: false,
        size,
        success: false,
        variant,
      })}
      {...props}
    >
      {leftIcon &&
        React.cloneElement(leftIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            leftIcon.props.className,
          ),
        })}
      {children}
      {rightIcon &&
        React.cloneElement(rightIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            rightIcon.props.className,
          ),
        })}
    </Link>
  );
};

interface AnchorButtonProps extends React.ComponentProps<"a"> {
  fullWidth?: boolean;
  leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  remoteLike?: boolean;
  rightIcon?: React.ReactElement<React.ComponentProps<"svg">>;
  size?: Size;
  variant?: Variant;
}

export const AnchorButton: React.FC<AnchorButtonProps> = ({
  children,
  className,
  fullWidth = false,
  leftIcon,
  rightIcon,
  size = "medium",
  variant = "Primary Full",
  ...props
}) => {
  const iconOnly =
    !children && ((!leftIcon && !!rightIcon) || (!rightIcon && !!leftIcon));

  return (
    <a
      className={getClassName({
        className,
        fullWidth,
        iconOnly: false,
        loading: false,
        size,
        success: false,
        variant,
      })}
      {...props}
    >
      {leftIcon &&
        React.cloneElement(leftIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            leftIcon.props.className,
          ),
        })}
      {children}
      {rightIcon &&
        React.cloneElement(rightIcon, {
          "aria-hidden": true,
          className: classNames(
            sizes({ iconOnly })[size].icon,
            rightIcon.props.className,
          ),
        })}
    </a>
  );
};
