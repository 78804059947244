/**
 * @generated SignedSource<<8ac93a962ff209c8dacb7dbc545e1849>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestNotifySection_CTMSGrant$data = {
  readonly grantee: {
    readonly employerOfRecord: {
      readonly hRISProvider: HRISProvider | null;
      readonly name: string;
    } | null;
    readonly name: string;
    readonly workRelationship: WorkRelationship | null;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_CTMSGrant">;
  readonly " $fragmentType": "ExerciseRequestNotifySection_CTMSGrant";
};
export type ExerciseRequestNotifySection_CTMSGrant$key = {
  readonly " $data"?: ExerciseRequestNotifySection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestNotifySection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmployerOfRecord",
          "kind": "LinkedField",
          "name": "employerOfRecord",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hRISProvider",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "9295004b7af2813f136c96ac6332f3ab";

export default node;
