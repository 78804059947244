/**
 * @generated SignedSource<<7d1ca1deda426fef257ae8b58f9a553c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillingDetails_OrganizationStripeAccount$data = {
  readonly email: string | null;
  readonly invoicesDefaultPaymentMethod: {
    readonly " $fragmentSpreads": FragmentRefs<"StripePaymentMethodExpiration_StripePaymentMethod" | "StripePaymentMethodLabel_StripePaymentMethod" | "StripePaymentMethodLogo_StripePaymentMethod">;
  } | null;
  readonly " $fragmentType": "BillingDetails_OrganizationStripeAccount";
};
export type BillingDetails_OrganizationStripeAccount$key = {
  readonly " $data"?: BillingDetails_OrganizationStripeAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetails_OrganizationStripeAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetails_OrganizationStripeAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePaymentMethod",
      "kind": "LinkedField",
      "name": "invoicesDefaultPaymentMethod",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StripePaymentMethodExpiration_StripePaymentMethod"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StripePaymentMethodLabel_StripePaymentMethod"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StripePaymentMethodLogo_StripePaymentMethod"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StripeCustomer",
  "abstractKey": null
};

(node as any).hash = "7616345be0276989b5e642e4c5f4cceb";

export default node;
