import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { FormattedPercentage } from "../../../components/Formatted/FormattedPercentage";
import { Card } from "../../../components/ui/Card";
import { Divider } from "../../../components/ui/Divider";
import { LabelValue } from "../../../components/ui/LabelValue";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  AwardSuperType,
  Incentive_EquityOffer$key,
} from "./__generated__/Incentive_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Incentive_EquityOffer on IEquityOffer {
    shares
    dollarValue
    ownershipPercentage
    displayEquityAsDollarValue
    displayEquityAsPercentage
    salary
    displayBenefits
    benefits
    displaySalary
    salaryCurrencyCode
    instrument {
      workRelationship
      awardSuperType
      isVirtual
    }
  }
`;

const HUMAN_READABLE_AWARD_SUPER_TYPE_MAP: Record<AwardSuperType, string> = {
  ISO: "Stock Options",
  NSO: "Stock Options",
  RSA: "Restricted Stock Award",
  RSU: "Restricted Stock Unit",
  SAR: "Stock Appreciation Rights",
};

export const EquityOfferIncentive: React.FC<{
  equityOfferFragment: Incentive_EquityOffer$key;
  hideNoticeMessages?: boolean;
}> = ({ equityOfferFragment, hideNoticeMessages = false }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  const hasBenefitsToDisplay =
    equityOffer.displayBenefits &&
    equityOffer.benefits.filter((benefit) => benefit).length > 0;

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <Typography as="div" variant="Medium/Large">
          Your equity incentive
        </Typography>
        <Typography as="div" variant="Medium/Default">
          We are so happy to offer you the following equity incentives:
        </Typography>
      </div>

      <Card size={4} title="🎁 Equity">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-6 sm:flex-row sm:gap-6">
            {equityOffer.displayEquityAsDollarValue && (
              <LabelValue
                className="sm:flex-1"
                isPrimary
                label="Potential value"
              >
                {equityOffer.dollarValue ? (
                  <FormattedNumber
                    compactDisplay="short"
                    currency="USD"
                    maximumFractionDigits={2}
                    minimumFractionDigits={0}
                    notation="compact"
                    style="currency"
                    value={equityOffer.dollarValue}
                  />
                ) : (
                  "-"
                )}
              </LabelValue>
            )}
            <LabelValue
              className="sm:flex-1"
              isPrimary
              label={equityOffer.instrument?.isVirtual ? "SARs" : "Shares"}
            >
              {equityOffer.shares ? (
                <FormattedNumber value={equityOffer.shares} />
              ) : (
                "-"
              )}
            </LabelValue>
            {equityOffer.displayEquityAsPercentage && (
              <LabelValue
                className="sm:flex-1"
                isPrimary
                label={
                  equityOffer.instrument?.isVirtual
                    ? "Virtual ownership"
                    : "Ownership"
                }
              >
                {equityOffer.ownershipPercentage ? (
                  <FormattedPercentage
                    value={equityOffer.ownershipPercentage / 100}
                  />
                ) : (
                  "-"
                )}
              </LabelValue>
            )}
          </div>
          {!hideNoticeMessages &&
            (equityOffer.instrument?.isVirtual ? (
              <NoticeMessage size="Large" variant="Idea">
                Each SAR corresponds to one share of Common Stock, so each SAR
                value will increase together with the value of one share of
                Common Stock, but you never become owner of any stock or stock
                options. So your ownership is virtual. Instead you receive a
                “cash equivalent” bonus corresponding to the increase in value
                of the shares of Common Stock.
              </NoticeMessage>
            ) : (
              <>
                <NoticeMessage size="Large" variant="Idea">
                  A share is a unit of ownership of a company: the capital of a
                  company is divided into a limited number of shares, and
                  members that own shares of a company are referred to as
                  shareholders.
                </NoticeMessage>
                <NoticeMessage size="Large" variant="Idea">
                  You should pay attention to the fact that the value of your
                  option grant is still theoretical at this stage. Your
                  company&apos;s valuation may increase or decrease over time,
                  according to the company’s future success.
                </NoticeMessage>
              </>
            ))}
        </div>
      </Card>

      {equityOffer.displaySalary && equityOffer.salary && (
        <Card size={4} title="💪 Salary">
          <div className="grid grid-cols-3 gap-6">
            <LabelValue label="Annual salary">
              {equityOffer.salary ? (
                <FormattedNumber
                  currency={equityOffer.salaryCurrencyCode}
                  maximumFractionDigits={0}
                  style="currency"
                  value={equityOffer.salary}
                />
              ) : (
                "-"
              )}
            </LabelValue>
          </div>
        </Card>
      )}

      {hasBenefitsToDisplay && (
        <Card size={4} title="🚀 Benefits">
          <div className="grid grid-cols-2 gap-2">
            {equityOffer.benefits.map((benefit, index) => (
              <LabelValue key={index} label="Benefit">
                {benefit}
              </LabelValue>
            ))}
          </div>
        </Card>
      )}

      <Divider />

      <Typography as="div" variant="Medium/Large">
        Equity type
      </Typography>

      <Card size={4}>
        <LabelValue label="Equity type">
          {equityOffer.instrument
            ? `${
                HUMAN_READABLE_AWARD_SUPER_TYPE_MAP[
                  equityOffer.instrument.awardSuperType
                ]
              } - ${
                WORK_RELATIONSHIP_TO_LABEL_HELPER[
                  equityOffer.instrument.workRelationship
                ].singularLabel
              }`
            : "-"}
        </LabelValue>
      </Card>
    </div>
  );
};
