import { addDays, addMonths, addYears } from "date-fns";

type DurationUnit = "day" | "month" | "year";

export const addPeriodToDate = (
  date: Date,
  period: number,
  periodUnit: DurationUnit,
) => {
  if (periodUnit === "day") {
    return addDays(date, period);
  }
  if (periodUnit === "month") {
    return addMonths(date, period);
  }
  if (periodUnit === "year") {
    return addYears(date, period);
  }

  throw new Error(`Unknown period unit`);
};
