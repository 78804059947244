/**
 * @generated SignedSource<<0eb98dac56a624e16fc27a4d75b131c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesTaxParametersEditionPage_Query$variables = {
  equityTypeId: string;
};
export type ExercisesTaxParametersEditionPage_Query$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"ExercisesTaxParametersEditionPage_EquityType">;
  } | null;
};
export type ExercisesTaxParametersEditionPage_Query = {
  response: ExercisesTaxParametersEditionPage_Query$data;
  variables: ExercisesTaxParametersEditionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseTaxRange",
    "kind": "LinkedField",
    "name": "taxRanges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseBenefit",
    "kind": "LinkedField",
    "name": "benefits",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "watchout",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ctmsTaxRateInPercent",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExercisesTaxParametersEditionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExercisesTaxParametersEditionPage_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExercisesTaxParametersEditionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeExerciseParameters",
            "kind": "LinkedField",
            "name": "exerciseParameters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "directEmployee",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "eoREmployee",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "contractor",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef36a2478c6e8928241a3f5287b8b6c7",
    "id": null,
    "metadata": {},
    "name": "ExercisesTaxParametersEditionPage_Query",
    "operationKind": "query",
    "text": "query ExercisesTaxParametersEditionPage_Query(\n  $equityTypeId: UUID!\n) {\n  equityType(id: $equityTypeId) {\n    ...ExercisesTaxParametersEditionPage_EquityType\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment ExercisesParametersPerWorkRelationshipEditionBlock_EquityType on EquityType {\n  id\n  exerciseParameters {\n    directEmployee {\n      taxRanges {\n        description\n        percentage\n      }\n      benefits {\n        content\n      }\n      watchout\n      ctmsTaxRateInPercent\n    }\n    eoREmployee {\n      taxRanges {\n        description\n        percentage\n      }\n      benefits {\n        content\n      }\n      watchout\n      ctmsTaxRateInPercent\n    }\n    contractor {\n      taxRanges {\n        description\n        percentage\n      }\n      benefits {\n        content\n      }\n      watchout\n      ctmsTaxRateInPercent\n    }\n  }\n}\n\nfragment ExercisesTaxParametersEditionPage_EquityType on EquityType {\n  name\n  taxResidenceCountry {\n    name\n    ...CountryFlag_Country\n  }\n  ...ExercisesParametersPerWorkRelationshipEditionBlock_EquityType\n}\n"
  }
};
})();

(node as any).hash = "380fadb0d893f4786f2ff021d14b03db";

export default node;
