import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { NoticeMessage } from "../../components/ui/NoticeMessage";
import { Typography } from "../../components/ui/Typography";
import { VestingGraph } from "../../components/VestingGraph";
import { useApplicationName } from "../../hooks/useApplicationTheme";
import { useGranteeGrantIndex } from "../../hooks/useGranteeGrantIndex";
import { GranteeVestingSchedule_CTMSGrant$key } from "./__generated__/GranteeVestingSchedule_CTMSGrant.graphql";
import { GranteeVestingSchedule_Grantee$key } from "./__generated__/GranteeVestingSchedule_Grantee.graphql";
import { GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant$key } from "./__generated__/GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant.graphql";

const CTMS_GRANTS_FRAGMENT = graphql`
  fragment GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant on CTMSGrant
  @relay(plural: true) {
    label
  }
`;

const MissingGrantsVestingSchedule: React.FC<{
  ctmsGrantFragments: GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant$key;
}> = ({ ctmsGrantFragments }) => {
  const ctmsGrants = useFragment(CTMS_GRANTS_FRAGMENT, ctmsGrantFragments);
  const applicationName = useApplicationName();

  const ctmsGrantsLabel = ctmsGrants
    .map((ctmsGrant) => ctmsGrant.label)
    .join(", ");

  return (
    <NoticeMessage size="Small">
      We’re missing the vesting schedule information regarding the{" "}
      <FormattedMessage
        defaultMessage={`{ctmsGrantsLabel, plural, 
              one {grant}
              other {grants}
            } {ctmsGrantsLabel}`}
        values={{ ctmsGrantsLabel }}
      />{" "}
      since your company wasn’t using {applicationName} at the time you were
      granted
    </NoticeMessage>
  );
};

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeVestingSchedule_Grantee on Grantee {
    ...useGranteeGrantIndex_Grantee
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GranteeVestingSchedule_CTMSGrant on CTMSGrant @relay(plural: true) {
    id
    label
    vestingDataPoints {
      cumulativeVested
      date
    }
    ...GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant
  }
`;

export const GranteeVestingSchedule: React.FC<{
  ctmsGrantsFragment: GranteeVestingSchedule_CTMSGrant$key;
  granteeFragment: GranteeVestingSchedule_Grantee$key;
}> = ({ ctmsGrantsFragment, granteeFragment }) => {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const getGrantIndex = useGranteeGrantIndex({
    granteeFragment: grantee,
  });

  const ctmsGrantWithVestingEvents = useMemo(
    () =>
      ctmsGrants.filter((ctmsGrant) =>
        ctmsGrant.vestingDataPoints.some(
          (vestingDataPoint) => vestingDataPoint.cumulativeVested > 0,
        ),
      ),
    [ctmsGrants],
  );
  const ctmsGrantWithoutVestingEvents = useMemo(
    () =>
      ctmsGrants.filter(
        (ctmsGrant) =>
          !ctmsGrant.vestingDataPoints.some(
            (vestingDataPoint) => vestingDataPoint.cumulativeVested > 0,
          ),
      ),
    [ctmsGrants],
  );

  return (
    <div className="flex flex-grow flex-col gap-2">
      <Typography variant="Medium/Default">Vesting Schedule</Typography>
      {ctmsGrantWithVestingEvents.length > 0 ? (
        <VestingGraph
          className="pt-4"
          getGrantIndex={getGrantIndex}
          vestingsDataPoints={ctmsGrantWithVestingEvents.map((ctmsGrant) => ({
            dataPoints: ctmsGrant.vestingDataPoints.map((dataPoint) => ({
              cumulativeVested: dataPoint.cumulativeVested,
              date: new Date(dataPoint.date),
            })),
            key: ctmsGrant.id,
            label: ctmsGrant.label,
          }))}
        />
      ) : (
        <div></div>
      )}
      {ctmsGrantWithoutVestingEvents.length > 0 && (
        <MissingGrantsVestingSchedule
          ctmsGrantFragments={ctmsGrantWithoutVestingEvents}
        />
      )}
    </div>
  );
};
