/**
 * @generated SignedSource<<4d2a4ac8f972a9f0565d76570d42a6d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningEntriesTable_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"NewHirePlanningEntryModal_Organization" | "PlanningEntryDetailsSlideOver_Organization" | "RefresherGrantPlanningEntryModal_Organization" | "TerminationPlanningEntryModal_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "PlanningEntriesTable_Organization";
};
export type PlanningEntriesTable_Organization$key = {
  readonly " $data"?: PlanningEntriesTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntriesTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanningEntriesTable_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlanningEntryDetailsSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewHirePlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TerminationPlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefresherGrantPlanningEntryModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5d8b097e3860c30ba8d9dacc7f28be9b";

export default node;
