/**
 * @generated SignedSource<<ae2724cbcbf3a36d9b446da663715339>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetails_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly granteePortalInvitationIsAllowed: boolean;
  readonly id: string;
  readonly name: string;
  readonly planIsFreemium: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantButton_Organization" | "GranteeDetailsBox_Organization" | "GranteeExerciseBox_Organization" | "GranteeFormSlide_Organization" | "GranteeGrantDetails_Organization" | "GranteeTotalOwnershipBox_Organization" | "InviteGranteesModal_Organization" | "MissingInformationTag_MissingCTMSInformationTag_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "GranteeDetails_Organization";
};
export type GranteeDetails_Organization$key = {
  readonly " $data"?: GranteeDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planIsFreemium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteePortalInvitationIsAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTotalOwnershipBox_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeGrantDetails_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantButton_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeExerciseBox_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ff08bb6340265de30560dbeeb2ce764a";

export default node;
