/**
 * @generated SignedSource<<8e83cbcd6a95186ae513732e7da74e17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueSendToRewiewSlider_Query$variables = {
  organizationId: string;
};
export type FairMarketValueSendToRewiewSlider_Query$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueSendToRewiewSlider_Organization">;
  };
};
export type FairMarketValueSendToRewiewSlider_Query = {
  response: FairMarketValueSendToRewiewSlider_Query$data;
  variables: FairMarketValueSendToRewiewSlider_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValueSendToRewiewSlider_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FairMarketValueSendToRewiewSlider_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValueSendToRewiewSlider_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "date",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valuationFirm",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "762693701f76c86170edbb4df3381915",
    "id": null,
    "metadata": {},
    "name": "FairMarketValueSendToRewiewSlider_Query",
    "operationKind": "query",
    "text": "query FairMarketValueSendToRewiewSlider_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    ...FairMarketValueSendToRewiewSlider_Organization\n    id\n  }\n}\n\nfragment FairMarketValueSendToRewiewSlider_Organization on Organization {\n  id\n  latestFairMarketValue {\n    id\n    date\n    valuationFirm\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ba025b42a5e4f99c3f1c41b9afc718e";

export default node;
