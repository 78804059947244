/**
 * @generated SignedSource<<541ef7a16b4faf8e4d7f39d25c595eed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnlockCountry_UnlockGeography_Mutation$variables = {
  countryCode: string;
  organizationId: string;
  withTaxFavoredEquityType: boolean;
};
export type UnlockCountry_UnlockGeography_Mutation$data = {
  readonly unlockGeography: {
    readonly __typename: "Organization";
  };
};
export type UnlockCountry_UnlockGeography_Mutation = {
  response: UnlockCountry_UnlockGeography_Mutation$data;
  variables: UnlockCountry_UnlockGeography_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withTaxFavoredEquityType"
},
v3 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "withTaxFavoredEquityType",
    "variableName": "withTaxFavoredEquityType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlockCountry_UnlockGeography_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "unlockGeography",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnlockCountry_UnlockGeography_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "unlockGeography",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d04251125566920dbd712a5c81474621",
    "id": null,
    "metadata": {},
    "name": "UnlockCountry_UnlockGeography_Mutation",
    "operationKind": "mutation",
    "text": "mutation UnlockCountry_UnlockGeography_Mutation(\n  $countryCode: String!\n  $withTaxFavoredEquityType: Boolean!\n  $organizationId: OrganizationId!\n) {\n  unlockGeography(countryCode: $countryCode, organizationId: $organizationId, withTaxFavoredEquityType: $withTaxFavoredEquityType) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e012a6acb1dd74caf3f8bbe0580a2996";

export default node;
