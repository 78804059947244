/**
 * @generated SignedSource<<cf377b8a718596d4b72cb69c8dd93c68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantGranteeDetailsRow_Grantee$data = {
  readonly contractStartDate: string;
  readonly managementCompanyName: string | null;
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  };
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "GrantGranteeDetailsRow_Grantee";
};
export type GrantGranteeDetailsRow_Grantee$key = {
  readonly " $data"?: GrantGranteeDetailsRow_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantGranteeDetailsRow_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantGranteeDetailsRow_Grantee",
  "selections": [
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workRelationship",
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCompanyName",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contractStartDate",
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "taxResidenceCountry",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emoji",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "f3144534c5800ca29a2c0d74305986f7";

export default node;
