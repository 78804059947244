/**
 * @generated SignedSource<<79607f81c7d0acf2dd77e4d314d7e50c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FreemiumBlockerCard_Organization$data = {
  readonly name: string;
  readonly " $fragmentType": "FreemiumBlockerCard_Organization";
};
export type FreemiumBlockerCard_Organization$key = {
  readonly " $data"?: FreemiumBlockerCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FreemiumBlockerCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreemiumBlockerCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cca5d9815a7765a04da07f11c7d77f88";

export default node;
