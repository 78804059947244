import { zodResolver } from "@hookform/resolvers/zod";
import { startTransition, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { z } from "zod";

import { EarlyExerciseBeneficialNoticeMessage } from "../../../../components/EarlyExerciseBeneficialNoticeMessage";
import { GrantWatchouts } from "../../../../components/GrantWatchouts";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { CreateSlideOverRemote } from "../../Grants/Configure/PostTermination/CreateSlideOver";
import { NewEarlyExerciseAllowedSlider_CTMSGrant$key } from "./__generated__/NewEarlyExerciseAllowedSlider_CTMSGrant.graphql";
import { NewEarlyExerciseAllowedSlider_Organization$key } from "./__generated__/NewEarlyExerciseAllowedSlider_Organization.graphql";
import { NewEarlyExerciseAllowedSlider_Unfavorable_Query } from "./__generated__/NewEarlyExerciseAllowedSlider_Unfavorable_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewEarlyExerciseAllowedSlider_CTMSGrant on CTMSGrant {
    id
    earlyExercise
    matchingInstrument {
      isEarlyExerciseBeneficial
      equityType {
        ...GrantWatchouts_Rule83BWatchout_EquityType
        ...GrantWatchouts_Rule100KWatchout_EquityType
      }
      ...EarlyExerciseBeneficialNoticeMessage_Instrument
    }
  }
`;

const UNFAVORABLE_QUERY = graphql`
  query NewEarlyExerciseAllowedSlider_Unfavorable_Query(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    areCTMSGrantAmendmentRequestParametersUnfavorable(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    )
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewEarlyExerciseAllowedSlider_Organization on Organization {
    hasCooleyAsOutsideCounsel
    ...EarlyExerciseBeneficialNoticeMessage_Organization
  }
`;

enum Option {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED",
}

export const NewEarlyExerciseAllowedOption = Option;

const formSchema = z.object({
  earlyExercise: z.enum([Option.ALLOWED, Option.NOT_ALLOWED]),
});

export type NewEarlyExerciseAllowedSliderFormFieldValues = FieldValues;
type FieldValues = z.infer<typeof formSchema>;

const DEFAULT_VALUES: FieldValues = {
  earlyExercise: Option.NOT_ALLOWED,
};

function NewEarlyExerciseAllowedSlider({
  ctmsGrantFragment,
  newEarlyExerciseAllowedValue,
  onClose,
  onExited,
  onNewEarlyExerciseAllowedValueChanges,
  organizationFragment,
  show,
}: {
  ctmsGrantFragment: NewEarlyExerciseAllowedSlider_CTMSGrant$key;
  newEarlyExerciseAllowedValue: FieldValues | null;
  onClose: () => void;
  onExited?: () => void;
  onNewEarlyExerciseAllowedValueChanges: (value: FieldValues) => void;
  organizationFragment: NewEarlyExerciseAllowedSlider_Organization$key;
  show: boolean;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const defaultValues = useMemo(() => {
    if (newEarlyExerciseAllowedValue) return newEarlyExerciseAllowedValue;

    if (ctmsGrant.earlyExercise) {
      return {
        earlyExercise: Option.ALLOWED,
      };
    }

    return DEFAULT_VALUES;
  }, [newEarlyExerciseAllowedValue, ctmsGrant.earlyExercise]);

  const { control, formState, handleSubmit, watch } = useForm<FieldValues>({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onNewEarlyExerciseAllowedValueChanges(formSchema.parse(data));
    onClose();
  });

  const [selectedEarlyExerciseOption, setSelectedEarlyExerciseOption] =
    useState<null | Option>(defaultValues.earlyExercise);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "earlyExercise": {
          startTransition(() => {
            setSelectedEarlyExerciseOption(value.earlyExercise ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setSelectedEarlyExerciseOption, defaultValues]);

  const {
    areCTMSGrantAmendmentRequestParametersUnfavorable:
      isNewEarlyExerciseUnfavorable,
  } = useLazyLoadQuery<NewEarlyExerciseAllowedSlider_Unfavorable_Query>(
    UNFAVORABLE_QUERY,
    {
      attributes: {
        accelerationClauseIsModified: false,
        earlyExercise: selectedEarlyExerciseOption === Option.ALLOWED,
        earlyExerciseIsModified: true,
        postTerminationExercisePeriodIsModified: false,
      },
      ctmsGrantId: ctmsGrant.id,
    },
  );

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Early exercise
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <CreateSlideOverRemote.Provider>
        <form className="space-y-10" onSubmit={onSubmit}>
          <div className="space-y-6 p-6">
            {organization.hasCooleyAsOutsideCounsel && (
              <NoticeMessage hasColor={false} size="Large">
                Early exercise is an important decision that indirectly impacts
                your investors. If you opt for early exercise, discuss it with
                your investors and lawyers first.
              </NoticeMessage>
            )}
            <FormRow label="Allow early exercise">
              <Controller
                control={control}
                name="earlyExercise"
                render={({ field }) => (
                  <Toggle
                    enabled={field.value === Option.ALLOWED}
                    onChange={(allowed) =>
                      field.onChange(
                        allowed ? Option.ALLOWED : Option.NOT_ALLOWED,
                      )
                    }
                  />
                )}
              />
            </FormRow>
            {ctmsGrant.matchingInstrument?.equityType && (
              <>
                <GrantWatchouts.Rule83BWatchout
                  earlyExerciseAllowed={
                    selectedEarlyExerciseOption === Option.ALLOWED
                  }
                  equityTypeFragment={ctmsGrant.matchingInstrument.equityType}
                  hasColor={false}
                  size="Large"
                />
                <GrantWatchouts.Rule100KWatchout
                  equityTypeFragment={ctmsGrant.matchingInstrument.equityType}
                  hasColor={false}
                  size="Large"
                />
              </>
            )}
            {ctmsGrant.matchingInstrument?.isEarlyExerciseBeneficial && (
              <EarlyExerciseBeneficialNoticeMessage
                hasColor={false}
                instrumentFragment={ctmsGrant.matchingInstrument}
                organizationFragment={organization}
              />
            )}
            {isNewEarlyExerciseUnfavorable && (
              <NoticeMessage hasColor={false} size="Large" variant="Warning">
                Deleting the early exercise option is usually considered as not
                grantee friendly. The grantee&apos;s acceptation will be
                required.
              </NoticeMessage>
            )}
          </div>
          <Divider />
          <div className="space-y-4 px-6 py-10">
            <div className="flex items-center justify-end gap-2">
              <Button
                disabled={formState.isSubmitting}
                onClick={onClose}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              <Button
                loading={formState.isSubmitting}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </div>
            <Typography
              as="div"
              className="text-right text-black-05"
              variant="Regular/Caption"
            >
              Amending a grant will always require the approval from the board.
            </Typography>
          </div>
        </form>
      </CreateSlideOverRemote.Provider>
    </SlideOver>
  );
}

export const NewEarlyExerciseAllowedSliderRemote = makeRemoteController<{
  ctmsGrantFragment: NewEarlyExerciseAllowedSlider_CTMSGrant$key;
  newEarlyExerciseAllowedValue: FieldValues | null;
  onNewEarlyExerciseAllowedValueChanges: (value: FieldValues) => void;
  organizationFragment: NewEarlyExerciseAllowedSlider_Organization$key;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <NewEarlyExerciseAllowedSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        newEarlyExerciseAllowedValue={state.data.newEarlyExerciseAllowedValue}
        onClose={close}
        onExited={reset}
        onNewEarlyExerciseAllowedValueChanges={
          state.data.onNewEarlyExerciseAllowedValueChanges
        }
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
