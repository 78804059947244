/**
 * @generated SignedSource<<eb528e59135ed1fa3efebb392ea44d65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CartaIssuerCannotBeSelectedReason = "INSUFFICIENT_ACCESS_RIGHTS" | "ISSUER_ALREADY_CONNECTED";
export type CartaIssuerSelectionPageQuery$variables = {
  workspaceSetupStateToken: string;
};
export type CartaIssuerSelectionPageQuery$data = {
  readonly issuers: ReadonlyArray<{
    readonly cannotBeSelectedReason: CartaIssuerCannotBeSelectedReason | null;
    readonly cartaIssuer: {
      readonly id: string;
      readonly name: string;
    };
    readonly greatestPricePerShareValue: number | null;
  }>;
};
export type CartaIssuerSelectionPageQuery = {
  response: CartaIssuerSelectionPageQuery$data;
  variables: CartaIssuerSelectionPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSetupStateToken"
  }
],
v1 = [
  {
    "alias": "issuers",
    "args": [
      {
        "kind": "Variable",
        "name": "workspaceSetupStateToken",
        "variableName": "workspaceSetupStateToken"
      }
    ],
    "concreteType": "CartaIssuerWithSetupIssuerSelectionProperties",
    "kind": "LinkedField",
    "name": "getCartaIssuersWithSetupIssuerSelectionPropertiesUsingWorkspaceSetupStateToken",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CartaIssuer",
        "kind": "LinkedField",
        "name": "cartaIssuer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "greatestPricePerShareValue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cannotBeSelectedReason",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CartaIssuerSelectionPageQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CartaIssuerSelectionPageQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "173bf5d52b1041e2a77562bbf584680d",
    "id": null,
    "metadata": {},
    "name": "CartaIssuerSelectionPageQuery",
    "operationKind": "query",
    "text": "query CartaIssuerSelectionPageQuery(\n  $workspaceSetupStateToken: String!\n) {\n  issuers: getCartaIssuersWithSetupIssuerSelectionPropertiesUsingWorkspaceSetupStateToken(workspaceSetupStateToken: $workspaceSetupStateToken) {\n    cartaIssuer {\n      id\n      name\n    }\n    greatestPricePerShareValue\n    cannotBeSelectedReason\n  }\n}\n"
  }
};
})();

(node as any).hash = "7f7ffceb83c2ef438dc5418d7eaa1509";

export default node;
