import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DraftGrantVestingPreview } from "../../../../components/DraftGrantVestingPreview";
import { VestingGraphSkeleton } from "../../../../components/VestingGraph";
import { GrantVestingOverviewRow_Grantee$key } from "./__generated__/GrantVestingOverviewRow_Grantee.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const GRANTEE_FRAGMENT = graphql`
  fragment GrantVestingOverviewRow_Grantee on Grantee {
    ...DraftGrantVestingPreview_Grantee
  }
`;

export const GrantVestingOverviewRow: React.FC<{
  granteeFragment: GrantVestingOverviewRow_Grantee$key;
  label: string;
  quantityGranted: number;
  vestingScheduleId: string;
  vestingStartDate: string;
}> = ({
  granteeFragment,
  label,
  quantityGranted,
  vestingScheduleId,
  vestingStartDate,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <GrantReviewRow title="Vesting overview">
      <Suspense fallback={<VestingGraphSkeleton />}>
        <DraftGrantVestingPreview
          draftGrant={{
            label,
            quantityGranted,
            vestingScheduleId,
            vestingStartDate,
          }}
          granteeFragment={grantee}
        />
      </Suspense>
    </GrantReviewRow>
  );
};
