import { zodResolver } from "@hookform/resolvers/zod";
import { formatISO } from "date-fns";
import { startTransition, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../../components/ui/Form/Inputs/DatePicker";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { NewVestingStartDateSlider_CTMSGrant$key } from "./__generated__/NewVestingStartDateSlider_CTMSGrant.graphql";
import { NewVestingStartDateSlider_Unfavorable_Query } from "./__generated__/NewVestingStartDateSlider_Unfavorable_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewVestingStartDateSlider_CTMSGrant on CTMSGrant {
    id
    vestingStartDate
  }
`;

const UNFAVORABLE_QUERY = graphql`
  query NewVestingStartDateSlider_Unfavorable_Query(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    areCTMSGrantAmendmentRequestParametersUnfavorable(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    )
  }
`;

const formSchema = z.object({
  vestingStartDate: z.string(),
});

function NewVestingStartDateSlider({
  ctmsGrantFragment,
  newVestingStartDate,
  onClose,
  onExited,
  onNewVestingStartDateChange,
  show,
}: {
  ctmsGrantFragment: NewVestingStartDateSlider_CTMSGrant$key;
  newVestingStartDate: null | string;
  onClose: () => void;
  onExited?: () => void;
  onNewVestingStartDateChange: (props: { vestingStartDate: string }) => void;
  show: boolean;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const defaultValues = useMemo(() => {
    if (newVestingStartDate) return { vestingStartDate: newVestingStartDate };

    if (ctmsGrant.vestingStartDate) {
      return {
        vestingStartDate: ctmsGrant.vestingStartDate,
      };
    }

    return { vestingStartDate: undefined };
  }, [newVestingStartDate, ctmsGrant.vestingStartDate]);

  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onNewVestingStartDateChange(formSchema.parse(data));
    onClose();
  });

  const [selectedVestingStartDate, setSelectedVestingStartDate] = useState<
    null | string
  >(defaultValues.vestingStartDate ?? null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "vestingStartDate": {
          const { vestingStartDate } = value;
          startTransition(() => {
            setSelectedVestingStartDate(vestingStartDate ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setSelectedVestingStartDate]);

  const {
    areCTMSGrantAmendmentRequestParametersUnfavorable:
      isNewVestingStartDateUnfavorable,
  } = useLazyLoadQuery<NewVestingStartDateSlider_Unfavorable_Query>(
    UNFAVORABLE_QUERY,
    {
      attributes: {
        accelerationClauseIsModified: false,
        earlyExerciseIsModified: false,
        postTerminationExercisePeriodIsModified: false,
        vestingStartDate: selectedVestingStartDate
          ? formatISO(selectedVestingStartDate, { representation: "date" })
          : null,
      },
      ctmsGrantId: ctmsGrant.id,
    },
  );

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Vesting start date
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <form onSubmit={onSubmit}>
        <div className="space-y-6 p-6">
          <FormRow.Form
            control={control}
            label="Vesting start date"
            name="vestingStartDate"
          >
            <DatePicker.Form control={control} name="vestingStartDate" />
          </FormRow.Form>
          {isNewVestingStartDateUnfavorable && (
            <NoticeMessage hasColor={false} size="Large" variant="Warning">
              Delaying the vesting start date is usually considered as not
              grantee friendly. The grantee&apos;s acceptation will be required.
            </NoticeMessage>
          )}
        </div>
        <Divider />
        <div className="space-y-4 px-6 py-10">
          <div className="flex items-center justify-end gap-2">
            <Button
              disabled={formState.isSubmitting}
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button loading={formState.isSubmitting} size="small" type="submit">
              Save
            </Button>
          </div>
          <Typography
            as="div"
            className="text-right text-black-05"
            variant="Regular/Caption"
          >
            Amending a grant will always require the approval from the board.
          </Typography>
        </div>
      </form>
    </SlideOver>
  );
}

export const NewVestingStartDateSliderRemote = makeRemoteController<{
  ctmsGrantFragment: NewVestingStartDateSlider_CTMSGrant$key;
  newVestingStartDate: null | string;
  onNewVestingStartDateChange: (props: { vestingStartDate: string }) => void;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <NewVestingStartDateSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        newVestingStartDate={state.data.newVestingStartDate}
        onClose={close}
        onExited={reset}
        onNewVestingStartDateChange={state.data.onNewVestingStartDateChange}
        show={state.show}
      />
    );
  },
});
