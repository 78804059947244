/**
 * @generated SignedSource<<c40042bd55c359a5a30d0540bd4c66bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminTasksCard_Organization_RefetchQuery$variables = {
  id: string;
};
export type AdminTasksCard_Organization_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminTasksCard_Organization">;
  } | null;
};
export type AdminTasksCard_Organization_RefetchQuery = {
  response: AdminTasksCard_Organization_RefetchQuery$data;
  variables: AdminTasksCard_Organization_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "READY_FOR_CONSENT"
    ]
  }
],
v6 = [
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminTasksCard_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminTasksCard_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminTasksCard_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GrantBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalGrantBoardConsents",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GrantAmendmentBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalGrantAmendmentBoardConsents",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeTerminationGrantAmendmentBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalGranteeTerminationGrantAmendmentBoardConsents",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValueBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalFairMarketValueBoardConsents",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": "reviewedDrafts",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "statusIn": [
                        "Reviewed"
                      ]
                    }
                  }
                ],
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "notReadyForBoardConsentReasons",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": "easopGrants(filters:{\"statusIn\":[\"Reviewed\"]})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteesConnection",
                "kind": "LinkedField",
                "name": "grantees",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GranteeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cannotBeInvitedReason",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "readyForConsentGranteeTerminationCTMSGrantAmendmentRequests",
                "args": (v5/*: any*/),
                "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "granteeTerminationCTMSGrantAmendmentRequests",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
              },
              {
                "alias": "readyForConsentCTMSGrantAmendmentRequests",
                "args": (v5/*: any*/),
                "concreteType": "CTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "ctmsGrantAmendmentRequests",
                "plural": true,
                "selections": (v6/*: any*/),
                "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValue",
                "kind": "LinkedField",
                "name": "latestFairMarketValue",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2b61413d62eee39ecdff0032dd71003",
    "id": null,
    "metadata": {},
    "name": "AdminTasksCard_Organization_RefetchQuery",
    "operationKind": "query",
    "text": "query AdminTasksCard_Organization_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...AdminTasksCard_Organization\n    id\n  }\n}\n\nfragment AdminTasksCard_Organization on Organization {\n  id\n  pendingApprovalGrantBoardConsents {\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    id\n  }\n  pendingApprovalGrantAmendmentBoardConsents {\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    id\n  }\n  pendingApprovalGranteeTerminationGrantAmendmentBoardConsents {\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    id\n  }\n  pendingApprovalFairMarketValueBoardConsents {\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    id\n  }\n  reviewedDrafts: easopGrants(filters: {statusIn: [Reviewed]}) {\n    notReadyForBoardConsentReasons\n    id\n  }\n  grantees {\n    edges {\n      node {\n        cannotBeInvitedReason\n        id\n      }\n    }\n  }\n  readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(statusIn: [READY_FOR_CONSENT]) {\n    __typename\n    id\n  }\n  readyForConsentCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [READY_FOR_CONSENT]) {\n    __typename\n    id\n  }\n  latestFairMarketValue {\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e41e09016917aec57bc4947e2ca67eed";

export default node;
