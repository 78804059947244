import { graphql, useFragment } from "react-relay";

import ExerciseBox from "../../../components/ExerciseBox";
import { GranteePortalExerciseBeginnerInformation } from "../../../components/GranteePortalExerciseBeginnerInformation";
import { Page } from "../../../components/Page";
import { LinkButton } from "../../../components/ui/Button";
import { NegativeDivider } from "../../../components/ui/Divider";
import { Typography } from "../../../components/ui/Typography";
import { useGranteePortalGrantType } from "../../../hooks/useGranteePortal";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../hooks/useQuery";
import { useGranteeLayoutContext } from "../../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useGranteeIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Exercise_Grantee_CTMSGrant$key } from "./__generated__/Exercise_Grantee_CTMSGrant.graphql";
import { Exercise_Grantee_Organization$key } from "./__generated__/Exercise_Grantee_Organization.graphql";
import { Exercise_Grantee_Query } from "./__generated__/Exercise_Grantee_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment Exercise_Grantee_CTMSGrant on CTMSGrant @relay(plural: true) {
    id
    earlyExercise
    ...ExerciseBox_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Exercise_Grantee_Organization on Organization {
    ...useOrganizationCTMS_Organization
  }
`;

const GranteeExercisePage_: React.FC<{
  ctmsGrantsFragment: Exercise_Grantee_CTMSGrant$key;
  organizationFragment: Exercise_Grantee_Organization$key;
}> = ({ ctmsGrantsFragment, organizationFragment }) => {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const { isExpertMode } = useGranteeLayoutContext();

  return (
    <div className="space-y-6">
      {!isExpertMode && (
        <>
          <GranteePortalExerciseBeginnerInformation
            hasGrantsWithEarlyExerciseOption={ctmsGrants.some(
              (ctmsGrant) => ctmsGrant.earlyExercise,
            )}
          />
          {ctmsGrants.length > 0 && <NegativeDivider />}
        </>
      )}
      {ctmsGrants.length > 0 && (
        <div className="space-y-10">
          <Typography as="div" variant="Medium/Large">
            Overview of possible exercises by grant
          </Typography>
          {ctmsGrants.map((ctmsGrant) => (
            <ExerciseBox ctmsGrantfragment={ctmsGrant} key={ctmsGrant.id} />
          ))}
          <div className="space-y-5">
            <Typography as="div" variant="Medium/Large">
              Ready to exercise your vested stock options?
            </Typography>

            {organizationCTMS ? (
              <>
                <Typography as="div" variant="Regular/Small">
                  Just head to {organizationCTMS.name}. You can exercise your
                  options over there in a few clicks.
                </Typography>
                <div className="flex items-center justify-center">
                  <LinkButton to={organizationCTMS.loginUrl}>
                    Exercise your options
                  </LinkButton>
                </div>
              </>
            ) : (
              <Typography as="div" variant="Regular/Small">
                Get in touch with your human resources lead to talk about next
                steps for exercising your equity.
              </Typography>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const QUERY = graphql`
  query Exercise_Grantee_Query($granteeId: GranteeId!, $grantType: GrantType!) {
    grantee(id: $granteeId) {
      organization {
        id
        name
        ...Exercise_Grantee_Organization
      }
      ctmsGrants(grantType: $grantType) {
        ...Exercise_Grantee_CTMSGrant
      }
    }
  }
`;

const GranteeExercisePage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const grantType = useGranteePortalGrantType();
  const { query } = useQuery<Exercise_Grantee_Query>(QUERY, {
    granteeId,
    grantType,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - Exercise"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} exercise`}
    >
      <GranteeExercisePage_
        ctmsGrantsFragment={query.grantee.ctmsGrants}
        organizationFragment={query.grantee.organization}
      />
    </Page>
  );
};

export default GranteeExercisePage;
