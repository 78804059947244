import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { SharesValue, SharesValueMode } from "../SharesValue";
import { Divider } from "../ui/Divider";
import { LabeledValueRoundedList } from "../ui/LabeledValueRoundedList";
import { NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry$key } from "./__generated__/NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry.graphql";
import { NewHireGrantPlanningEntryDetails_Organization$key } from "./__generated__/NewHireGrantPlanningEntryDetails_Organization.graphql";

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry on NewHireGrantPlanningEntry {
    newHirerEntrySharesGranted: sharesGranted
    position
    equityGridLevel {
      name
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewHireGrantPlanningEntryDetails_Organization on Organization {
    ...SharesValue_Organization
  }
`;

export const NewHireGrantPlanningEntryDetails: React.FC<{
  equityMode: SharesValueMode;
  organizationFragment: NewHireGrantPlanningEntryDetails_Organization$key;
  planningEntryFragment: NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry$key;
}> = ({ equityMode, organizationFragment, planningEntryFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntry = useFragment(
    PLANNING_ENTRY_FRAGMENT,
    planningEntryFragment,
  );

  return (
    <LabeledValueRoundedList>
      <LabeledValueRoundedList.LabeledValue label="Level">
        {planningEntry.equityGridLevel?.name ?? "-"}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Position">
        {planningEntry.position}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Compensation">
        {planningEntry.newHirerEntrySharesGranted !== null ? (
          <SharesValue
            appendSharesLabel
            mode={equityMode}
            organizationFragment={organization}
            shares={planningEntry.newHirerEntrySharesGranted}
            variant="Medium/Extra Small"
          />
        ) : (
          "Missing information"
        )}
      </LabeledValueRoundedList.LabeledValue>
    </LabeledValueRoundedList>
  );
};
