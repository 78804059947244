import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

import { Typography } from "./ui/Typography";

export const QuestionCard: React.FC<
  React.PropsWithChildren<{
    className?: string;
    onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
    title?: React.ReactNode;
  }>
> = ({ children, className, onCloseButtonClick, title }) => (
  <div
    className={classNames("flex gap-2 rounded-lg bg-primary-01 p-6", className)}
  >
    <div className="flex-1 space-y-6">
      {title && (
        <Typography as="div" className="text-primary-07" variant="Medium/Small">
          {title}
        </Typography>
      )}
      <Typography as="div" variant="Regular/Small">
        {children}
      </Typography>
    </div>
    {onCloseButtonClick && (
      <div>
        <button onClick={onCloseButtonClick} type="button">
          <XMarkIcon className="h-6 w-6" />
        </button>
      </div>
    )}
  </div>
);
