import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { PreviewLayout } from "../../../components/ui/Layout/PreviewLayout";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { EquityOfferPreviewLayout_EquityOffer$key } from "./__generated__/EquityOfferPreviewLayout_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquityOfferPreviewLayout_EquityOffer on EquityOffer {
    candidateAccessSecretKey
  }
`;

export const EquityOfferPreviewLayout: React.FC<
  Omit<React.ComponentProps<typeof PreviewLayout>, "previewLink"> & {
    equityOfferFragment: EquityOfferPreviewLayout_EquityOffer$key;
  }
> = ({ equityOfferFragment, ...props }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  return (
    <PreviewLayout
      previewLink={
        <Link
          target="_blank"
          to={generatePath(APPLICATION_ROUTES["candidateOffer"], {
            equityOfferCandidateAccessSecretKey:
              equityOffer.candidateAccessSecretKey,
          })}
        >
          <Typography
            as="div"
            className="cursor-pointer text-primary"
            variant="Medium/Caption"
          >
            Preview the full offer
          </Typography>
        </Link>
      }
      {...props}
    />
  );
};
