/**
 * @generated SignedSource<<f047c6da2946c2399737d34e3920fd19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL" | "VALUATION_PENDING_BOARD_APPROVAL" | "VALUATION_UNDER_REVIEW";
export type FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation$variables = {
  fairMarketValueId: string;
  valuationFirm: string;
  valuationReportDocumentId: string;
};
export type FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation$data = {
  readonly sendFairMarketValueForReview: {
    readonly valuationWarnings: ReadonlyArray<{
      readonly reason: ValuationWarningReason;
      readonly valuationType: ValuationType;
    }>;
  };
};
export type FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation = {
  response: FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation$data;
  variables: FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValueId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valuationFirm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valuationReportDocumentId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "fairMarketValueId",
    "variableName": "fairMarketValueId"
  },
  {
    "kind": "Variable",
    "name": "valuationFirm",
    "variableName": "valuationFirm"
  },
  {
    "kind": "Variable",
    "name": "valuationReportDocumentId",
    "variableName": "valuationReportDocumentId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationValuationWarning",
  "kind": "LinkedField",
  "name": "valuationWarnings",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationType",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueForReview",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "sendFairMarketValueForReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d4435352b529013abf9c50133d8cf22",
    "id": null,
    "metadata": {},
    "name": "FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation",
    "operationKind": "mutation",
    "text": "mutation FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation(\n  $fairMarketValueId: ID!\n  $valuationFirm: String!\n  $valuationReportDocumentId: String!\n) {\n  sendFairMarketValueForReview(fairMarketValueId: $fairMarketValueId, valuationFirm: $valuationFirm, valuationReportDocumentId: $valuationReportDocumentId) {\n    valuationWarnings {\n      reason\n      valuationType\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "54402936d422687ed7f2b25875e5f730";

export default node;
