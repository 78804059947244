/**
 * @generated SignedSource<<4975428317023f35c790fc7065e9d93e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type EditEasopGrantResultFailureError = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "INSTRUMENT_NOT_AVAILABLE_FOR_GRANTEE" | "LABEL_ALREADY_EXISTS" | "PTEP_NOT_PROVIDED" | "PTEP_PROVIDED_FOR_FIXED_PTEP_INSTRUMENT" | "WORK_RELATIONSHIP_NOT_COVERED";
export type EasopGrantAttributes = {
  accelerationClause?: AccelerationClause | null;
  earlyExercise: boolean;
  exercisePrice: number;
  exercisePriceBelowFMVSetOn?: string | null;
  granteeId: string;
  id: string;
  instrumentId: string;
  label: string;
  organizationId: string;
  postTerminationExercisePeriodId: any;
  quantityGranted: number;
  vestingScheduleId: any;
  vestingStartDate: string;
};
export type Review_CreateGrant_Mutation$variables = {
  attributes: EasopGrantAttributes;
  equityOfferSourceId?: string | null;
  planningEntrySourceId?: any | null;
};
export type Review_CreateGrant_Mutation$data = {
  readonly createEasopGrant: {
    readonly __typename: "EditEasopGrantResultFailure";
    readonly error: EditEasopGrantResultFailureError;
  } | {
    readonly __typename: "EditEasopGrantResultSucess";
    readonly easopGrant: {
      readonly equityOfferSource: {
        readonly __typename: "EquityOffer";
      } | null;
      readonly grantee: {
        readonly name: string;
      };
      readonly planningEntrySource: {
        readonly __typename: string;
      } | null;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type Review_CreateGrant_Mutation = {
  response: Review_CreateGrant_Mutation$data;
  variables: Review_CreateGrant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityOfferSourceId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planningEntrySourceId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "equityOfferSourceId",
    "variableName": "equityOfferSourceId"
  },
  {
    "kind": "Variable",
    "name": "planningEntrySourceId",
    "variableName": "planningEntrySourceId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "EditEasopGrantResultFailure",
  "abstractKey": null
},
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Review_CreateGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createEasopGrant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "planningEntrySource",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquityOffer",
                    "kind": "LinkedField",
                    "name": "equityOfferSource",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "EditEasopGrantResultSucess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Review_CreateGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createEasopGrant",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "planningEntrySource",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquityOffer",
                    "kind": "LinkedField",
                    "name": "equityOfferSource",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "EditEasopGrantResultSucess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "acc8aaa107b4a0023256c800a35ccfc5",
    "id": null,
    "metadata": {},
    "name": "Review_CreateGrant_Mutation",
    "operationKind": "mutation",
    "text": "mutation Review_CreateGrant_Mutation(\n  $attributes: EasopGrantAttributes!\n  $planningEntrySourceId: PlanningEntryId\n  $equityOfferSourceId: UUID\n) {\n  createEasopGrant(attributes: $attributes, planningEntrySourceId: $planningEntrySourceId, equityOfferSourceId: $equityOfferSourceId) {\n    __typename\n    ... on EditEasopGrantResultFailure {\n      error\n    }\n    ... on EditEasopGrantResultSucess {\n      easopGrant {\n        planningEntrySource {\n          __typename\n          id\n        }\n        equityOfferSource {\n          __typename\n          id\n        }\n        grantee {\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "642c16d41daafc185cb41c013a4024f2";

export default node;
