import { useCallback, useMemo } from "react";
import { DefaultValues } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { EditSlideOver_BackloadedVestingSchedules_Mutation } from "./__generated__/EditSlideOver_BackloadedVestingSchedules_Mutation.graphql";
import { EditSlideOver_LinearVestingSchedules_Mutation } from "./__generated__/EditSlideOver_LinearVestingSchedules_Mutation.graphql";
import {
  EditSlideOver_VestingSchedule_Organization$data,
  EditSlideOver_VestingSchedule_Organization$key,
} from "./__generated__/EditSlideOver_VestingSchedule_Organization.graphql";
import {
  EditSlideOver_VestingSchedule_Query,
  EditSlideOver_VestingSchedule_Query$data,
} from "./__generated__/EditSlideOver_VestingSchedule_Query.graphql";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
} from "./FORM_SCHEMA";
import {
  useBackloadedVestingScheduleForm,
  useLinearVestingScheduleForm,
} from "./useVestingScheduleForm";
import { VestingScheduleForm } from "./VestingScheduleForm";
import { VestingSchedulePreview } from "./VestingSchedulePreview";

const QUERY = graphql`
  query EditSlideOver_VestingSchedule_Query($vestingScheduleId: ID!) {
    vestingSchedule: node(id: $vestingScheduleId) @required(action: THROW) {
      ... on LinearVestingSchedule {
        __typename
        id
        name
        durationInMonths
        cliffDurationInMonths
        vestedAtCliffPercentage
        vestingOccurrence
      }
      ... on BackloadedVestingSchedule {
        __typename
        id
        name
        durationInMonths
        cliffDurationInMonths
        vestedAtCliffPercentage
        vestingOccurrence
        cliffActivatedOnFirstPeriod
        periods {
          percentageVested
          durationInMonths
        }
      }
    }
  }
`;

const UPDATE_LINEAR_VESTING_SCHEDULE_MUTATION = graphql`
  mutation EditSlideOver_LinearVestingSchedules_Mutation(
    $attributes: LinearVestingScheduleAttributes!
    $id: VestingScheduleId!
  ) {
    updateLinearVestingSchedule(attributes: $attributes, id: $id) {
      __typename
    }
  }
`;

const UPDATE_BACKLOADED_VESTING_SCHEDULE_MUTATION = graphql`
  mutation EditSlideOver_BackloadedVestingSchedules_Mutation(
    $attributes: BackloadedVestingScheduleAttributes!
    $id: VestingScheduleId!
  ) {
    updateBackloadedVestingSchedule(attributes: $attributes, id: $id) {
      __typename
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EditSlideOver_VestingSchedule_Organization on Organization {
    ...useVestingScheduleForm_Organization
  }
`;

type NonNullableVestingSchedule = NonNullable<
  EditSlideOver_VestingSchedule_Query$data["vestingSchedule"]
>;
type VestingSchedule = NonNullableVestingSchedule & {
  __typename: Exclude<NonNullableVestingSchedule["__typename"], "%other">;
};

const SlideOverContent: React.FC<{
  handleBackloadedVestingScheduleFormSubmit: (
    data: BackloadedVestingScheduleFormValues,
  ) => void;
  handleLinearVestingScheduleFormSubmit: (
    data: LinearVestingScheduleFormValues,
  ) => void;
  organization: EditSlideOver_VestingSchedule_Organization$data;
  vestingSchedule: VestingSchedule;
}> = ({
  handleBackloadedVestingScheduleFormSubmit,
  handleLinearVestingScheduleFormSubmit,
  organization,
  vestingSchedule,
}) => {
  const lineardVestingScheduleDefaultValue =
    useMemo((): DefaultValues<LinearVestingScheduleFormValues> | null => {
      if (vestingSchedule.__typename !== "LinearVestingSchedule") return null;

      return {
        cliffDurationInMonths: vestingSchedule.cliffDurationInMonths,
        durationInMonths: vestingSchedule.durationInMonths,
        hasCliff: vestingSchedule.cliffDurationInMonths
          ? vestingSchedule.cliffDurationInMonths > 0
          : false,
        name: vestingSchedule.name,
        vestedAtCliffPercentage: vestingSchedule.vestedAtCliffPercentage,
        vestingOccurrence: vestingSchedule.vestingOccurrence,
      };
    }, [vestingSchedule]);

  const linearVestingScheduleForm = useLinearVestingScheduleForm({
    currentVestingScheduleId: vestingSchedule.id,
    defaultValues: lineardVestingScheduleDefaultValue,
    organizationFragment: organization,
  });

  const backloadedVestingScheduleDefaultValue =
    useMemo((): DefaultValues<BackloadedVestingScheduleFormValues> | null => {
      if (vestingSchedule.__typename !== "BackloadedVestingSchedule")
        return null;

      return {
        cliffActivatedOnFirstPeriod:
          vestingSchedule.cliffActivatedOnFirstPeriod,
        name: vestingSchedule.name,
        periods: vestingSchedule.periods.map(
          ({ durationInMonths, percentageVested }) => ({
            durationInMonths,
            percentageVested,
          }),
        ),
        vestingOccurrence: vestingSchedule.vestingOccurrence as
          | "Every3Months"
          | "EveryMonth",
      };
    }, [vestingSchedule]);

  const backloadedVestingScheduleForm = useBackloadedVestingScheduleForm({
    currentVestingScheduleId: vestingSchedule.id,
    defaultValues: backloadedVestingScheduleDefaultValue,
    organizationFragment: organization,
  });

  const vestingScheduleType = useMemo(() => {
    switch (vestingSchedule.__typename) {
      case "BackloadedVestingSchedule":
        return "BACKLOADED";
      case "LinearVestingSchedule":
        return "LINEAR";
    }
  }, [vestingSchedule]);

  const validatedLiveValues = useMemo(() => {
    switch (vestingScheduleType) {
      case "BACKLOADED":
        return backloadedVestingScheduleForm.validatedLiveValues;
      case "LINEAR":
        return linearVestingScheduleForm.validatedLiveValues;
    }
  }, [
    vestingScheduleType,
    backloadedVestingScheduleForm.validatedLiveValues,
    linearVestingScheduleForm.validatedLiveValues,
  ]);

  return (
    <div className="space-y-10 px-10 py-16">
      <div>
        <VestingScheduleForm
          backloadedVestingScheduleForm={backloadedVestingScheduleForm}
          id="edit-vesting-schedule-form"
          linearVestingScheduleForm={linearVestingScheduleForm}
          onBackloadedVestingScheduleFormSubmit={
            handleBackloadedVestingScheduleFormSubmit
          }
          onLinearVestingScheduleFormSubmit={
            handleLinearVestingScheduleFormSubmit
          }
          onTypeChange={null}
          type={vestingScheduleType}
        />
      </div>
      {validatedLiveValues && (
        <VestingSchedulePreview
          type={vestingScheduleType}
          values={validatedLiveValues}
        />
      )}
    </div>
  );
};

function EditSlideOver({
  onClose,
  onExited,
  organizationFragment,
  show,
  vestingScheduleId,
}: {
  onClose: () => void;
  onExited?: () => void;
  organizationFragment: EditSlideOver_VestingSchedule_Organization$key;
  show: boolean;
  vestingScheduleId: string;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    query: { vestingSchedule },
  } = useQuery<EditSlideOver_VestingSchedule_Query>(QUERY, {
    vestingScheduleId,
  });

  if (vestingSchedule?.__typename === "%other") {
    throw new Error("Invalid typename");
  }

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const [
    triggerUpdateLinearVestingScheduleMutation,
    linearVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<EditSlideOver_LinearVestingSchedules_Mutation>(
    UPDATE_LINEAR_VESTING_SCHEDULE_MUTATION,
  );

  const [
    triggerUpdateBackloadedVestingScheduleMutation,
    backloadedVestingScheduleMutationIsInFlight,
  ] = useSafeMutation<EditSlideOver_BackloadedVestingSchedules_Mutation>(
    UPDATE_BACKLOADED_VESTING_SCHEDULE_MUTATION,
  );

  const handleLinearVestingScheduleFormSubmit = async (
    values: LinearVestingScheduleFormValues,
  ) => {
    await triggerUpdateLinearVestingScheduleMutation({
      variables: {
        attributes: {
          cliffDurationInMonths: values.hasCliff
            ? (values.cliffDurationInMonths ?? 0)
            : 0,
          durationInMonths: values.durationInMonths,
          name: values.name,
          vestedAtCliffPercentage: values.hasCliff
            ? (values.vestedAtCliffPercentage ?? 0)
            : 0,
          vestingOccurrence: values.vestingOccurrence,
        },
        id: vestingSchedule.id,
      },
    });

    return handleClose();
  };

  const handleBackloadedVestingScheduleFormSubmit = async (
    values: BackloadedVestingScheduleFormValues,
  ) => {
    await triggerUpdateBackloadedVestingScheduleMutation({
      variables: {
        attributes: {
          cliffActivatedOnFirstPeriod: values.cliffActivatedOnFirstPeriod,
          name: values.name,
          periods: values.periods,
          vestingOccurrence: values.vestingOccurrence,
        },
        id: vestingSchedule.id,
      },
    });

    return handleClose();
  };

  return (
    <SlideOver
      floating
      footer={
        <div className="flex items-center justify-end gap-2 p-6">
          <Button
            onClick={handleClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="edit-vesting-schedule-form"
            loading={
              linearVestingScheduleMutationIsInFlight ||
              backloadedVestingScheduleMutationIsInFlight
            }
            size="small"
            type="submit"
          >
            Update
          </Button>
        </div>
      }
      header={
        <SlideOver.Header
          onClose={onClose}
          padding={10}
          subtitle="Define the vesting parameters below"
        >
          Edit vesting schedule
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="4xl"
    >
      <SlideOverContent
        handleBackloadedVestingScheduleFormSubmit={
          handleBackloadedVestingScheduleFormSubmit
        }
        handleLinearVestingScheduleFormSubmit={
          handleLinearVestingScheduleFormSubmit
        }
        organization={organization}
        vestingSchedule={vestingSchedule}
      />
    </SlideOver>
  );
}

export const EditSlideOverRemote = makeRemoteController<{
  organizationFragment: EditSlideOver_VestingSchedule_Organization$key;
  vestingScheduleId: string;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <EditSlideOver
        onClose={close}
        onExited={reset}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
        vestingScheduleId={state.data.vestingScheduleId}
      />
    );
  },
});
