/**
 * @generated SignedSource<<d2e014241f36cd5194501da52043d4d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteCompanyAdminsList_Account$data = {
  readonly remoteUserSlug: string | null;
  readonly " $fragmentType": "RemoteCompanyAdminsList_Account";
};
export type RemoteCompanyAdminsList_Account$key = {
  readonly " $data"?: RemoteCompanyAdminsList_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteCompanyAdminsList_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteCompanyAdminsList_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteUserSlug",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f30d8c73b7ff1afad1bc217fe14b3254";

export default node;
