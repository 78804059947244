import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, useForm } from "react-hook-form";

import { FormValues, simulatedGrantSchema } from "./FORM_SCHEMA";

export function useSimulateAGrantForm({
  defaultValues = {
    grantedSharesInputMode: "SHARES",
    shares: 0,
    type: "PRE_MONEY",
  },
}: {
  defaultValues?: DefaultValues<FormValues>;
} = {}) {
  return useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(simulatedGrantSchema),
  });
}
