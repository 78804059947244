import { Link } from "react-router-dom";

import {
  GRANTEE_PORTAL_EQUITY_TYPES_GROUPS,
  useEquityTypesGroupParam,
} from "../paths";
import { About } from "./ui/About";
import { Typography } from "./ui/Typography";

const Options: React.FC<{
  hasGrantsWithEarlyExerciseOption: boolean;
}> = ({ hasGrantsWithEarlyExerciseOption }) => {
  return (
    <>
      <Typography as="div" variant="Medium/Large">
        What happens when you vest some of these options?
      </Typography>

      <Typography as="div" variant="Regular/Small">
        Congratulations 🎉, your long term commitment in the company is
        rewarded: you now have the right to buy a slice of the company at a
        pre-defined price (the “exercise price”). We call this stage the
        “exercise”.
      </Typography>

      <About title="📘 DEFINITION">
        “Exercising” means that you turn the unlocked (vested) options (i.e. the
        right to get a slice of your company) into an actual slice of the
        company by paying what we call an “exercise price” or “strike price”, a
        price that was pre-defined when the company granted you the options.
      </About>

      <Typography as="div" variant="Regular/Small">
        In principle, you can do it as soon as you get new options unlocked
        (vested), or later (for instance, before the company is about to be
        acquired)! Once you&apos;ve exercised your options, you become an owner
        of your company: you are what we call a shareholder 👨‍💼.
      </Typography>

      {hasGrantsWithEarlyExerciseOption && (
        <Typography as="div" variant="Regular/Small">
          What is presented above is the &quot;classic&quot; case, but your
          situation is a bit different because you&apos;ve been given the
          possibility to “early exercise”. It means that you can exercise stock
          options that have not vested yet. So, you could potentially exercise
          100% of your options as from day one and become an effective
          shareholder 👨‍💼 . But here&apos;s the trick: if you leave, the company
          can buy back your shares that haven&apos;t vested yet. Why would you
          early exercise? Mainly for tax reasons. If you want to see the pros
          and cons of early exercise to help you make a decision,{" "}
          <Link
            className="text-primary"
            to="https://www.easop.com/blog/should-you-allow-your-international-employees-to-early-exercise-their-stock-options"
          >
            check out this article
          </Link>{" "}
          and the &quot;reporting and general taxation&quot; sections of your
          portal.
        </Typography>
      )}
    </>
  );
};

const SARs: React.FC = () => {
  return (
    <>
      <Typography as="div" variant="Medium/Large">
        What happens when you vest some of these virtual stock options?
      </Typography>

      <Typography as="div" variant="Regular/Small">
        Congratulations 🎉, You now have the right, if a liquidity event
        happens, to get a certain amount of money. This amount will be
        calculated by taking the price at which a potential buyer would buy the
        number of shares of vested common stock underlying your grant, and
        reducing it by the virtual exercise price attached to your vested
        virtual stock options.
      </Typography>
    </>
  );
};

export const GranteePortalExerciseBeginnerInformation: React.FC<{
  hasGrantsWithEarlyExerciseOption: boolean;
}> = ({ hasGrantsWithEarlyExerciseOption }) => {
  const equityTypesGroup = useEquityTypesGroupParam();
  switch (equityTypesGroup) {
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
      return (
        <Options
          hasGrantsWithEarlyExerciseOption={hasGrantsWithEarlyExerciseOption}
        />
      );
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
      return <SARs />;
  }
};
