/**
 * @generated SignedSource<<7b6993b7fbc91947970c36169b632be6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EarlyExerciseBeneficialNoticeMessage_Instrument$data = {
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "EarlyExerciseBeneficialNoticeMessage_Instrument";
};
export type EarlyExerciseBeneficialNoticeMessage_Instrument$key = {
  readonly " $data"?: EarlyExerciseBeneficialNoticeMessage_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialNoticeMessage_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EarlyExerciseBeneficialNoticeMessage_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "a77e0edad6b4af163a08fa009a6e12ae";

export default node;
