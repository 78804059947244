/**
 * @generated SignedSource<<797cb5da12bfe3671b0ad486e3cdde69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberEquityGrid_Organization$data = {
  readonly equityGrid: {
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardMembersEquityGridLevelsTable_Organization">;
  readonly " $fragmentType": "BoardMemberEquityGrid_Organization";
};
export type BoardMemberEquityGrid_Organization$key = {
  readonly " $data"?: BoardMemberEquityGrid_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberEquityGrid_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardMemberEquityGrid_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMembersEquityGridLevelsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0ac9444a9ddafae25eb76be661973fea";

export default node;
