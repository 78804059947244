import { generatePath } from "react-router-dom";

import { Typography } from "../../../../../components/ui/Typography";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { BlueLink } from "../BlueLink";
import { ConfigureGrantsLayout } from "../ConfigureGrantsLayout";

export default function GrantDefaultsLayout({
  children,
  navigation,
  title,
}: React.PropsWithChildren<{
  navigation?: React.ReactNode;
  title: React.ReactNode;
}>) {
  const organizationId = useOrganizationIdParam();

  return (
    <ConfigureGrantsLayout navigation={navigation} title={title}>
      <div className="space-y-10">
        <div>{children}</div>
        <Typography as="div" className="text-gray-09" variant="Regular/Caption">
          Note that{" "}
          <BlueLink
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigureVestingSchedules"],
              {
                organizationId,
              },
            )}
          >
            Vesting schedule
          </BlueLink>
          ,{" "}
          <BlueLink
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigurePostTermination"],
              {
                organizationId,
              },
            )}
          >
            Post-termination
          </BlueLink>
          ,{" "}
          <BlueLink
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigureAcceleration"],
              {
                organizationId,
              },
            )}
          >
            Acceleration
          </BlueLink>{" "}
          and{" "}
          <BlueLink
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigureEarlyExercise"],
              {
                organizationId,
              },
            )}
          >
            Early exercise
          </BlueLink>{" "}
          can be added or modified in their specific configure page at any time.
        </Typography>
      </div>
    </ConfigureGrantsLayout>
  );
}
