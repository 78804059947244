import { trim } from "lodash";
import { useState } from "react";
import { useInterval } from "react-use";

import CONFIGURATION from "../services/configuration";

const fetchLatestVersion = async () => {
  const result = await fetch("/version.txt");

  return trim(await result.text());
};

const environment = CONFIGURATION.ENVIRONMENT;
if (typeof environment !== "string") {
  throw new Error("ENVIRONMENT is required");
}

const activeVersion = CONFIGURATION.VERSION;
if (typeof activeVersion !== "string") {
  throw new Error("VERSION is required");
}

const useClientHealth = () => {
  const [state, setState] = useState<
    | { state: "failed" }
    | {
        state: "loaded";
        value: {
          activeVersion: string;
          environment: string;
          latestVersion: string;
          updatedAt: Date;
        };
      }
    | { state: "loading" }
  >({ state: "loading" });
  const [refreshInterval, setRefreshInterval] = useState(0);

  useInterval(async () => {
    setRefreshInterval(30 * 1000);
    try {
      const latestVersion = await fetchLatestVersion();
      setState({
        state: "loaded",
        value: {
          activeVersion,
          environment,
          latestVersion,
          updatedAt: new Date(),
        },
      });
    } catch {
      setState({
        state: "failed",
      });
    }
  }, refreshInterval);

  return state;
};

export default useClientHealth;
