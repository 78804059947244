/**
 * @generated SignedSource<<ce7d2adaadc7e1c4c4bd9ee658b99295>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantNotReadyToBeSentToBoardConsentReason = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "NOT_REVIEWED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL" | "VALUATION_PENDING_BOARD_APPROVAL" | "VALUATION_UNDER_REVIEW";
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly readyForConsentEasopGrants: ReadonlyArray<{
    readonly id: string;
    readonly instrumentValuationType: ValuationType;
    readonly notReadyForBoardConsentReasons: ReadonlyArray<GrantNotReadyToBeSentToBoardConsentReason>;
    readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_EasopGrants">;
  }>;
  readonly searchedReadyForConsentEasopGrants: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_EasopGrant">;
  }>;
  readonly valuationWarnings: ReadonlyArray<{
    readonly reason: ValuationWarningReason;
    readonly valuationType: ValuationType;
    readonly " $fragmentSpreads": FragmentRefs<"ValuationWarningMessage_ValuationWarning">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_Organization" | "GrantsTable_Organization" | "LegalWorkflowLayout_Organization" | "ValuationWarningMessage_Organization" | "useMismatchBetweenCtmsAndEasopCapTableGuard_Organization" | "useOrganizationCTMS_Organization" | "useTaxFavoredSubplanExpiredGuard_Organization">;
  readonly " $fragmentType": "BoardApproval_Organization";
};
export type BoardApproval_Organization$key = {
  readonly " $data"?: BoardApproval_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_Organization">;
};

import BoardApproval_Organization_RefetchQuery_graphql from './BoardApproval_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  "Reviewed"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "easopGrantsSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": BoardApproval_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BoardApproval_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "searchedReadyForConsentEasopGrants",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "easopGrantsSearch"
            },
            {
              "kind": "Literal",
              "name": "statusIn",
              "value": (v1/*: any*/)
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "GrantsTable_EasopGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "readyForConsentEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": (v1/*: any*/)
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notReadyForBoardConsentReasons",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "instrumentValuationType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmSubmitBoardConsentSlide_EasopGrants"
        }
      ],
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Reviewed\"]})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationValuationWarning",
      "kind": "LinkedField",
      "name": "valuationWarnings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "valuationType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ValuationWarningMessage_ValuationWarning"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ValuationWarningMessage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMismatchBetweenCtmsAndEasopCapTableGuard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useTaxFavoredSubplanExpiredGuard_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "ea78681fe1c96b1dfceaed7b87fa3591";

export default node;
