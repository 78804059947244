/**
 * @generated SignedSource<<d499369221d2af999ca097e94dd0d20c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargestGainGraph_LargestGainDataPoint$data = ReadonlyArray<{
  readonly gainInUSD: number;
  readonly grantees: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly valueGrantedInUSD: number;
  readonly " $fragmentType": "LargestGainGraph_LargestGainDataPoint";
}>;
export type LargestGainGraph_LargestGainDataPoint$key = ReadonlyArray<{
  readonly " $data"?: LargestGainGraph_LargestGainDataPoint$data;
  readonly " $fragmentSpreads": FragmentRefs<"LargestGainGraph_LargestGainDataPoint">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LargestGainGraph_LargestGainDataPoint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gainInUSD",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueGrantedInUSD",
      "storageKey": null
    }
  ],
  "type": "LargestGainDataPoint",
  "abstractKey": null
};

(node as any).hash = "52fdfaec8156749b2f20ceca8413bb58";

export default node;
