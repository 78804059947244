/**
 * @generated SignedSource<<92450b6b9d0031d1928e92a741bf6247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption = "CUSTOM_DATE" | "LAST_DAY_AT_THE_COMPANY" | "TERMINATION_DATE";
export type GranteeTerminationCTMSGrantAmendmentRequestAttributes = {
  postTerminationExercisePeriodId?: any | null;
  vestingEndCustomDate?: string | null;
  vestingEndOption?: GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption | null;
  waiveCliff?: boolean | null;
};
export type GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation$variables = {
  attributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes;
  granteeTerminationCTMSGrantAmendmentRequestId: string;
};
export type GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation$data = {
  readonly updateGranteeTerminationCTMSGrantAmendmentRequest: {
    readonly id: string;
  };
};
export type GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation = {
  response: GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation$data;
  variables: GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeTerminationCTMSGrantAmendmentRequestId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "granteeTerminationCTMSGrantAmendmentRequestId",
        "variableName": "granteeTerminationCTMSGrantAmendmentRequestId"
      }
    ],
    "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
    "kind": "LinkedField",
    "name": "updateGranteeTerminationCTMSGrantAmendmentRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "563e2d46a7a758e3434fcb26c4936e57",
    "id": null,
    "metadata": {},
    "name": "GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation(\n  $granteeTerminationCTMSGrantAmendmentRequestId: UUID!\n  $attributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes!\n) {\n  updateGranteeTerminationCTMSGrantAmendmentRequest(granteeTerminationCTMSGrantAmendmentRequestId: $granteeTerminationCTMSGrantAmendmentRequestId, attributes: $attributes) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "aab8dc54d06d62285063a9c3d6df6c0a";

export default node;
