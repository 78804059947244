import { Carousel } from "nuka-carousel";

import { LoadingLogo } from "../../../components/LoadingLogo";
import { Card } from "../../../components/ui/Card";
import { Typography } from "../../../components/ui/Typography";
import angela from "./angela.png";
import atomicjar from "./atomicjar.png";
import catherine from "./catherine.png";
import chris from "./chris.png";
import eli from "./eli.png";
import maze from "./maze.png";
import slite from "./slite.png";
import wefunder from "./wefunder.png";

export function SetupLoadingSplash() {
  return (
    <div className="grid h-full w-full grid-cols-1 grid-rows-[1fr_auto_1fr] justify-center bg-gradient-to-b from-white to-primary-01 p-10">
      <div />
      <div className="flex items-center justify-center">
        <Card className="!p-10">
          <div className="flex flex-col items-center gap-4 text-center">
            <div className="p-9">
              <LoadingLogo className="w-full max-w-[48px]" />
            </div>
            <Typography as="div" variant="Medium/Small">
              Hold on, we’re setting up your workspace...
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              This process can take up to 30 seconds
            </Typography>
          </div>
        </Card>
      </div>
      <div className="flex items-center justify-center">
        <TestimonialsSlider />
      </div>
    </div>
  );
}

function Testimonial({
  children,
  jobTitle,
  organizationLogo,
  userFullName,
  userPicture,
}: React.PropsWithChildren<{
  jobTitle: string;
  organizationLogo: string;
  userFullName: string;
  userPicture: string;
}>) {
  return (
    <div className="flex h-full min-w-full flex-col justify-between gap-2 px-6">
      <Typography as="div" variant="Regular/Extra Small">
        “{children}”
      </Typography>
      <div className="flex items-center gap-4">
        <img className="w-10" src={userPicture} />
        <div className="flex flex-1 items-center gap-2">
          <Typography variant="Medium/Caption">{userFullName}</Typography>
          <Typography className="text-black-05" variant="Regular/Caption">
            {jobTitle}
          </Typography>
        </div>
        <img className="h-6" src={organizationLogo} />
      </div>
    </div>
  );
}

function TestimonialsSlider() {
  return (
    <div className="w-full max-w-[800px] rounded-2xl border-[0.5px] border-gray-04 bg-gradient-light-aubergine py-4">
      <Carousel autoplay autoplayInterval={8000} wrapMode="wrap">
        <Testimonial
          jobTitle="Total Reward and Payroll Lead"
          organizationLogo={maze}
          userFullName="Catherine Wilkinson"
          userPicture={catherine}
        >
          Easop’s global compliance solution has given us peace of mind knowing
          that we are meeting all of our regulatory obligations. Their team is
          always available to answer our questions and help us to navigate the
          ever-changing landscape of equity compensation.
        </Testimonial>
        <Testimonial
          jobTitle="Co-founder"
          organizationLogo={atomicjar}
          userFullName="Eli Aleyner"
          userPicture={eli}
        >
          AtomicJar has employees in 10 different countries and we would be
          lost, trying to make sense of equity compensation internationally
          without Easop insights & support.
        </Testimonial>
        <Testimonial
          jobTitle="Finance & Admin Specialist"
          organizationLogo={slite}
          userFullName="Angela Legras"
          userPicture={angela}
        >
          Easop is the best company I’ve ever dealt with when it comes to
          international employee education for equity compensation. They are
          always up-to-date on the latest regulations, and they make the process
          easy for us.
        </Testimonial>
        <Testimonial
          jobTitle="General Counsel"
          organizationLogo={wefunder}
          userFullName="Chris Vail"
          userPicture={chris}
        >
          I would highly recommend Easop’s legal operations product to any
          company that is looking to streamline their equity compensation
          processes and reduce their risk.
        </Testimonial>
      </Carousel>
    </div>
  );
}
