/**
 * @generated SignedSource<<b8a34a3e1377d5da12381a3e47587c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationGrantAmendmentsList_Grantee$data = {
  readonly activeCTMSGrants: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBox_CTMSGrant">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "GranteeTerminationGrantAmendmentsList_Grantee";
};
export type GranteeTerminationGrantAmendmentsList_Grantee$key = {
  readonly " $data"?: GranteeTerminationGrantAmendmentsList_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationGrantAmendmentsList_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationGrantAmendmentsList_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "activeCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active"
          ]
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantAmendmentRequestBox_CTMSGrant"
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "8ccba9867d9292c5e75d2e52fe6b95fc";

export default node;
