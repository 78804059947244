import { graphql, useFragment } from "react-relay";

import { GranteeGrantDetails } from "../../components/GranteeGrantDetails";
import { GranteePortalOverviewBeginnerInformation } from "../../components/GranteePortalOverviewBeginnerInformation";
import { GranteePortalOverviewHeader } from "../../components/GranteePortalOverviewHeader";
import { Page } from "../../components/Page";
import { NegativeDivider } from "../../components/ui/Divider";
import { useGranteeGrantIndex } from "../../hooks/useGranteeGrantIndex";
import { useGranteePortalGrantType } from "../../hooks/useGranteePortal";
import { useQuery } from "../../hooks/useQuery";
import { useGranteeLayoutContext } from "../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useGranteeIdParam } from "../../paths";
import NotFoundPage from "../NotFound/NotFound";
import { Overview_Grantee_CTMSGrant$key } from "./__generated__/Overview_Grantee_CTMSGrant.graphql";
import { Overview_Grantee_Grantee$key } from "./__generated__/Overview_Grantee_Grantee.graphql";
import { Overview_Grantee_Query } from "./__generated__/Overview_Grantee_Query.graphql";
import { GranteeEquity } from "./GranteeEquity";
import { GranteeOwnership } from "./GranteeOwnership";
import { GranteeVestingSchedule } from "./GranteeVestingSchedule";

const GRANTEE_FRAGMENT = graphql`
  fragment Overview_Grantee_Grantee on Grantee {
    name
    organization {
      ...GranteeGrantDetails_Organization
    }
    ...GranteeEquity_Grantee
    ...GranteeOwnership_Grantee
    ...GranteePortalOverviewHeader_Grantee
    ...GranteePortalOverviewBeginnerInformation_Grantee
    ...useGranteeGrantIndex_Grantee
    ...GranteeVestingSchedule_Grantee
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment Overview_Grantee_CTMSGrant on CTMSGrant @relay(plural: true) {
    id
    ...GranteeGrantDetails_CTMSGrant
    ...GranteeVestingSchedule_CTMSGrant
  }
`;

const GranteeOverviewPage_: React.FC<{
  ctmsGrantsFragment: Overview_Grantee_CTMSGrant$key;
  granteeFragment: Overview_Grantee_Grantee$key;
}> = ({ ctmsGrantsFragment, granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);

  const { isExpertMode } = useGranteeLayoutContext();

  const getGrantIndex = useGranteeGrantIndex({
    granteeFragment: grantee,
  });

  return (
    <>
      <GranteePortalOverviewHeader granteeFragment={grantee} />

      <NegativeDivider />

      <div className="space-y-6">
        {!isExpertMode ? (
          <GranteePortalOverviewBeginnerInformation granteeFragment={grantee} />
        ) : (
          <>
            <GranteeOwnership granteeFragment={grantee} />

            <GranteeEquity granteeFragment={grantee} />
          </>
        )}

        <GranteeVestingSchedule
          ctmsGrantsFragment={ctmsGrants}
          granteeFragment={grantee}
        />

        {isExpertMode ? (
          <>
            {ctmsGrants.map((grant) => (
              <div key={grant.id}>
                <NegativeDivider />

                <GranteeGrantDetails
                  ctmsGrantFragment={grant}
                  grantIndex={getGrantIndex(grant.id)}
                  organizationFragment={grantee.organization}
                />
              </div>
            ))}
          </>
        ) : null}
      </div>
    </>
  );
};

const QUERY = graphql`
  query Overview_Grantee_Query($granteeId: GranteeId!, $grantType: GrantType!) {
    grantee(id: $granteeId) {
      ...Overview_Grantee_Grantee
      organization {
        id
        name
      }
      ctmsGrants(grantType: $grantType) {
        ...Overview_Grantee_CTMSGrant
      }
    }
  }
`;

const GranteeOverviewPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const grantType = useGranteePortalGrantType();
  const { query } = useQuery<Overview_Grantee_Query>(QUERY, {
    granteeId,
    grantType,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - Overview"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} overview`}
    >
      <GranteeOverviewPage_
        ctmsGrantsFragment={query.grantee.ctmsGrants}
        granteeFragment={query.grantee}
      />
    </Page>
  );
};

export default GranteeOverviewPage;
