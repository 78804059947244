import classNames from "classnames";

import { Picture } from "./Picture";
import remoteLogo from "./remote-logo.png";

export const RemoteLogo: React.FC<{
  className?: string;
}> = ({ className }) => (
  <Picture
    className={classNames("object-contain", className)}
    image={remoteLogo}
  />
);
