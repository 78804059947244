import React, { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CleanupGranteesItem } from "../../../../components/CleanupGranteesItem";
import { MergeEmployeeModal } from "../../../../components/MergeEmployeeModal";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Toast } from "../../../../components/ui/Toast";
import useIgnoreCleanupSuggestionMutation from "../useIgnoreCleanupSuggestionMutation";
import { UpdateInformationItem_HRISProviderEmployee$key } from "./__generated__/UpdateInformationItem_HRISProviderEmployee.graphql";
import { UpdateInformationItem_Organization$key } from "./__generated__/UpdateInformationItem_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UpdateInformationItem_Organization on Organization {
    id
  }
`;

const HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment UpdateInformationItem_HRISProviderEmployee on HRISProviderEmployee {
    id
    hRISProvider
    hRISProviderEmployeeId
    changesSinceLastAcknowledgement
    ...CleanupGranteesItem_UpdateContent_HRISProviderEmployee
    ...MergeEmployeeModal_HRISProviderEmployee
    grantee @required(action: THROW) {
      ...CleanupGranteesItem_UpdateContent_Grantee
      ...MergeEmployeeModal_Grantee
    }
  }
`;

export const UpdateInformationItem: React.FC<{
  hideIgnoreButton?: boolean;
  hrisProviderEmployeeFragment: UpdateInformationItem_HRISProviderEmployee$key;
  listIndex: number;
  listLength: number;
  onDataMutation: () => void;
  organizationFragment: UpdateInformationItem_Organization$key;
}> = ({
  hideIgnoreButton = false,
  hrisProviderEmployeeFragment,
  listIndex,
  listLength,
  onDataMutation,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const hrisProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    hrisProviderEmployeeFragment,
  );

  const [mergeEmployeeModalIsShown, setMergeEmployeeModalIsShown] =
    useState(false);

  const [ignoreCleanupSuggestion, ignoreCleanupSuggestionMutationIsInFlight] =
    useIgnoreCleanupSuggestionMutation();
  const handleIgnoreButtonClick = useCallback(async () => {
    await ignoreCleanupSuggestion({
      variables: {
        HRISProvider: hrisProviderEmployee.hRISProvider,
        HRISProviderEmployeeId: hrisProviderEmployee.hRISProviderEmployeeId,
        organizationId: organization.id,
      },
    });

    onDataMutation();
  }, [
    hrisProviderEmployee,
    ignoreCleanupSuggestion,
    onDataMutation,
    organization.id,
  ]);

  const somethingIsLoading = ignoreCleanupSuggestionMutationIsInFlight;

  const toaster = useToaster();

  return (
    <div>
      <MergeEmployeeModal
        employeeFragment={hrisProviderEmployee}
        fieldsToDisplay={[
          ...hrisProviderEmployee.changesSinceLastAcknowledgement,
        ]}
        granteeFragment={hrisProviderEmployee.grantee}
        onClose={() => {
          setMergeEmployeeModalIsShown(false);
        }}
        onMerge={() => {
          toaster.push(
            <Toast title="Smashing!" variant="congrats">
              Grantee updated successfully!
            </Toast>,
          );

          setMergeEmployeeModalIsShown(false);

          onDataMutation();
        }}
        show={mergeEmployeeModalIsShown}
      />
      <CleanupGranteesItem
        actions={
          <>
            {!hideIgnoreButton && (
              <Button
                loading={somethingIsLoading}
                onClick={handleIgnoreButtonClick}
                size="small"
                type="button"
                variant="Tertiary Link"
              >
                Ignore
              </Button>
            )}
            <Button
              loading={somethingIsLoading}
              onClick={() => {
                setMergeEmployeeModalIsShown(true);
              }}
              size="small"
              type="button"
            >
              Merge
            </Button>
          </>
        }
        key={hrisProviderEmployee.id}
        listIndex={listIndex}
        listLength={listLength}
      >
        <CleanupGranteesItem.UpdateContent
          granteeFragment={hrisProviderEmployee.grantee}
          hRISProviderEmployeeFragment={hrisProviderEmployee}
        />
      </CleanupGranteesItem>
    </div>
  );
};
