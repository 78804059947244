/**
 * @generated SignedSource<<76b0203098caf1c576132b486cd064e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantPostTerminationRow_Organization$data = {
  readonly hasCooleyAsOutsideCounsel: boolean;
  readonly " $fragmentType": "GrantPostTerminationRow_Organization";
};
export type GrantPostTerminationRow_Organization$key = {
  readonly " $data"?: GrantPostTerminationRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantPostTerminationRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantPostTerminationRow_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCooleyAsOutsideCounsel",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a5a41fad3e71e332848a4df07b0921f6";

export default node;
