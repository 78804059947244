/**
 * @generated SignedSource<<3c97885e6acbedfa51518b4f43deea03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_CTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly pendingApprovalGrantAmendmentBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
    readonly ctmsGrantAmendmentRequests: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest">;
    }>;
    readonly id: string;
    readonly isPandaDocDocumentSent: boolean;
    readonly signatureRequests: ReadonlyArray<{
      readonly completedAt: string | null;
      readonly id: string;
      readonly signatoryFirstName: string;
      readonly signatoryLastName: string;
    }>;
    readonly voidedReason: BoardConsentVoidedReason | null;
    readonly " $fragmentSpreads": FragmentRefs<"SendReminderButton_BoardConsent">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_Organization" | "LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "PendingApproval_CTMSGrantAmendmentRequests_Organization";
};
export type PendingApproval_CTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: PendingApproval_CTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_CTMSGrantAmendmentRequests_Organization">;
};

import PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GrantAmendmentBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGrantAmendmentBoardConsents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voidedReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPandaDocDocumentSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryLastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CTMSGrantAmendmentRequest",
          "kind": "LinkedField",
          "name": "ctmsGrantAmendmentRequests",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SendReminderButton_BoardConsent"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CTMSGrantAmendmentRequestsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "1f8b16a5e19d066d3c096fd03da9903a";

export default node;
