/**
 * @generated SignedSource<<aeae7c5f2a98ff5fe3ae9ee8026661bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MergeEmployeeModal_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeTable_Grantee" | "useMergeGrantee_Grantee">;
  readonly " $fragmentType": "MergeEmployeeModal_Grantee";
};
export type MergeEmployeeModal_Grantee$key = {
  readonly " $data"?: MergeEmployeeModal_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeModal_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MergeEmployeeModal_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergeGrantee_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeTable_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "34ab420f5a2351d4c0e72ae7ac7bbf81";

export default node;
