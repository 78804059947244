import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ConnectHRISProviderMessage_Organization$key } from "./__generated__/ConnectHRISProviderMessage_Organization.graphql";
import { HRISProvider } from "./__generated__/GranteeForm_CreateGrantee_Mutation.graphql";
import { ConnectToDeelButton } from "./ConnectToDeelButton";
import { ConnectToRemoteButton } from "./ConnectToRemoteButton";
import { ActionableMessage } from "./ui/ActionableMessage";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ConnectHRISProviderMessage_Organization on Organization {
    isConnectedToDeel
    isConnectedToRemote
    isConnectedToRemoteEquity
    ...ConnectToDeelButton_Organization
    ...ConnectToRemoteButton_Organization
  }
`;

const _ConnectHRISProviderMessage: React.FC<{
  children: React.ReactNode;
  hRISProviderName: string;
}> = ({ children, hRISProviderName }) => {
  return (
    <ActionableMessage
      border
      description={
        <>
          Easily connect with {hRISProviderName} and synchronize all your
          contracts
        </>
      }
      isNew
      title={<>{hRISProviderName} integration</>}
    >
      {children}
    </ActionableMessage>
  );
};

export const ConnectHRISProviderMessage: React.FC<{
  hRISProvider: HRISProvider;
  organizationFragment: ConnectHRISProviderMessage_Organization$key;
}> = ({ hRISProvider, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (hRISProvider === "DEEL" && organization.isConnectedToDeel) {
    return null;
  }
  if (
    hRISProvider === "REMOTE" &&
    (organization.isConnectedToRemote || organization.isConnectedToRemoteEquity)
  ) {
    return null;
  }

  switch (hRISProvider) {
    case "DEEL":
      return (
        <_ConnectHRISProviderMessage hRISProviderName="Deel">
          <ConnectToDeelButton
            className="shrink-0"
            organizationFragment={organization}
            size="extra small"
          >
            Connect with Deel
          </ConnectToDeelButton>
        </_ConnectHRISProviderMessage>
      );
    case "REMOTE":
      return (
        <_ConnectHRISProviderMessage hRISProviderName="Remote">
          <ConnectToRemoteButton
            className="shrink-0"
            organizationFragment={organization}
            size="extra small"
          >
            Connect with Remote
          </ConnectToRemoteButton>
        </_ConnectHRISProviderMessage>
      );
  }
};
