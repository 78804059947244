/**
 * @generated SignedSource<<37d55c9484f69e04099b9601768c3ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationAddedEasopAsCartaLegalAdminModal_Query$variables = {
  organizationId: string;
};
export type OrganizationAddedEasopAsCartaLegalAdminModal_Query$data = {
  readonly isRemoteEquityOrganizationCartaLawFirm: {
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmFailure";
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmFailure";
  } | {
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmSuccess";
    readonly remoteEquityIsOrganizationCartaLawFirm: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type OrganizationAddedEasopAsCartaLegalAdminModal_Query = {
  response: OrganizationAddedEasopAsCartaLegalAdminModal_Query$data;
  variables: OrganizationAddedEasopAsCartaLegalAdminModal_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "isRemoteEquityOrganizationCartaLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityIsOrganizationCartaLawFirm",
            "storageKey": null
          }
        ],
        "type": "IsRemoteEquityOrganizationCartaLawFirmSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationAddedEasopAsCartaLegalAdminModal_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationAddedEasopAsCartaLegalAdminModal_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e527f4ed638ce3b96e8524ae2ef840df",
    "id": null,
    "metadata": {},
    "name": "OrganizationAddedEasopAsCartaLegalAdminModal_Query",
    "operationKind": "query",
    "text": "query OrganizationAddedEasopAsCartaLegalAdminModal_Query(\n  $organizationId: OrganizationId!\n) {\n  isRemoteEquityOrganizationCartaLawFirm(organizationId: $organizationId) {\n    __typename\n    ... on IsRemoteEquityOrganizationCartaLawFirmFailure {\n      __typename\n    }\n    ... on IsRemoteEquityOrganizationCartaLawFirmSuccess {\n      remoteEquityIsOrganizationCartaLawFirm\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcf0b175d092cfc4195c56a12cfbca4e";

export default node;
