/**
 * @generated SignedSource<<e55447ac6f2bf4eb0360011add69ce45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompanySettings_EquityOffer$data = {
  readonly displayCompanyInformation: boolean;
  readonly exercisePrice: number | null;
  readonly id: string;
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly latestFairMarketValue: {
      readonly value: number;
    } | null;
    readonly latestPricePerShare: {
      readonly value: number;
    } | null;
    readonly latestValuation: number | null;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Company_EquityOffer" | "EquityOfferPreviewLayout_EquityOffer">;
  readonly " $fragmentType": "CompanySettings_EquityOffer";
};
export type CompanySettings_EquityOffer$key = {
  readonly " $data"?: CompanySettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompanySettings_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CompanySettings_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "latestValuation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PricePerShare",
          "kind": "LinkedField",
          "name": "latestPricePerShare",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FairMarketValue",
          "kind": "LinkedField",
          "name": "latestFairMarketValue",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCompanyInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Company_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "719bdb84cf77afda47cbe8ea301ea0a1";

export default node;
