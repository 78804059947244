/**
 * @generated SignedSource<<9f20c492f1576ad5b62e520d41253d19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EarlyExerciseBeneficialPage_Query$variables = Record<PropertyKey, never>;
export type EarlyExerciseBeneficialPage_Query$data = {
  readonly instruments: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialPage_Instrument">;
  }>;
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialPage_Organization">;
  }>;
};
export type EarlyExerciseBeneficialPage_Query = {
  response: EarlyExerciseBeneficialPage_Query$data;
  variables: EarlyExerciseBeneficialPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "orderBy": {
        "direction": "ASC",
        "field": "name"
      },
      "status": "Active"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EarlyExerciseBeneficialPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Instrument",
        "kind": "LinkedField",
        "name": "instruments",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EarlyExerciseBeneficialPage_Instrument"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EarlyExerciseBeneficialPage_Organization"
          }
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"status\":\"Active\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EarlyExerciseBeneficialPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Instrument",
        "kind": "LinkedField",
        "name": "instruments",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isEarlyExerciseBeneficial",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"status\":\"Active\"})"
      }
    ]
  },
  "params": {
    "cacheID": "edf4bdc75c2de5937b678adc82b4f42a",
    "id": null,
    "metadata": {},
    "name": "EarlyExerciseBeneficialPage_Query",
    "operationKind": "query",
    "text": "query EarlyExerciseBeneficialPage_Query {\n  instruments {\n    ...EarlyExerciseBeneficialPage_Instrument\n    id\n  }\n  organizations(filters: {status: Active, orderBy: {field: name, direction: ASC}}) {\n    ...EarlyExerciseBeneficialPage_Organization\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment EarlyExerciseBeneficialPage_Instrument on Instrument {\n  id\n  description\n  isEarlyExerciseBeneficial\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment EarlyExerciseBeneficialPage_Organization on Organization {\n  id\n  name\n  ...SelectOrganizationModal_Organization\n}\n\nfragment SelectOrganizationModal_Organization on Organization {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "d102554b6ff8dada156ccc79251a813c";

export default node;
