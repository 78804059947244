/**
 * @generated SignedSource<<989041b7e991426bea44e5f702379999>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EmployerOfRecordAttributes = {
  name: string;
};
export type GranteeForm_CreateEmployerOfRecord_Mutation$variables = {
  attributes: EmployerOfRecordAttributes;
  organizationId: string;
};
export type GranteeForm_CreateEmployerOfRecord_Mutation$data = {
  readonly createEmployerOfRecord: {
    readonly employersOfRecord: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
};
export type GranteeForm_CreateEmployerOfRecord_Mutation = {
  response: GranteeForm_CreateEmployerOfRecord_Mutation$data;
  variables: GranteeForm_CreateEmployerOfRecord_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EmployerOfRecord",
  "kind": "LinkedField",
  "name": "employersOfRecord",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeForm_CreateEmployerOfRecord_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createEmployerOfRecord",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteeForm_CreateEmployerOfRecord_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createEmployerOfRecord",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cd7e044daccb9b58cdda8774d1e2684",
    "id": null,
    "metadata": {},
    "name": "GranteeForm_CreateEmployerOfRecord_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteeForm_CreateEmployerOfRecord_Mutation(\n  $attributes: EmployerOfRecordAttributes!\n  $organizationId: OrganizationId!\n) {\n  createEmployerOfRecord(attributes: $attributes, organizationId: $organizationId) {\n    employersOfRecord {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a55c37a1e6cf647fa03cd6055ab1373c";

export default node;
