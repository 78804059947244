import classNames from "classnames";
import { useState } from "react";
import { FormattedNumber } from "react-intl";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from "recharts";
import { z } from "zod";

import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { RoundedBarShape, RoundedBarShapeProps } from "./ui/RoundedBarShape";
import { Typography } from "./ui/Typography";

const onMouseEnterEventSchema = z.object({
  tooltipPosition: z.object({
    x: z.number(),
    y: z.number(),
  }),
});

interface BarData {
  className: string;
  key: ShareCategory;
  label: string;
  percent: number;
  realShares: number;
  tooltip: string;
  virtualShares: number;
}

interface EsopChartValue {
  percent: number;
  realShares: number;
  shares: number;
  virtualShares: number;
}

type ShareCategory =
  | "available"
  | "drafted"
  | "granted"
  | "grantedSettling"
  | "grantedToVest"
  | "grantedVestedExercised"
  | "grantedVestedToExercise"
  | "pending";

export const EsopChart: React.FC<{
  available: EsopChartValue;
  barGap?: number;
  barSize?: number;
  drafted: EsopChartValue;
  grantedSettling: EsopChartValue;
  grantedToVest: EsopChartValue;
  grantedVestedExercised: EsopChartValue;
  grantedVestedToExercise: EsopChartValue;
  height?: number;
  pending: EsopChartValue;
}> = ({
  available,
  barGap = 24,
  barSize = 64,
  drafted,
  grantedSettling,
  grantedToVest,
  grantedVestedExercised,
  grantedVestedToExercise,
  height = 217,
  pending,
}) => {
  const [hoveredBar, setHoveredBar] = useState<null | ShareCategory>(null);
  const [tooltipXPosition, setTooltipXPosition] = useState<number>(0);

  const barsData: BarData[] = [
    {
      className: classNames("bg-green-05 fill-green-05"),
      key: "available",
      label: "Avail.",
      tooltip: "Available",
      ...available,
    },
    {
      className: classNames("bg-yellow-05 fill-yellow-05"),
      key: "drafted",
      label: "Draft",
      tooltip: "Drafted",
      ...drafted,
    },
    {
      className: classNames("bg-orange-05 fill-orange-05"),
      key: "pending",
      label: "Pend.",
      tooltip: "Pending",
      ...pending,
    },
    {
      className: classNames("bg-purple-05 fill-purple-05"),
      key: "grantedToVest",
      label: "Vesti.",
      tooltip: "Granted (To vest)",
      ...grantedToVest,
    },
    {
      className: classNames("bg-purple-04 fill-purple-04"),
      key: "grantedVestedToExercise",
      label: "Veste.",
      tooltip: "Granted (Vested to exercise)",
      ...grantedVestedToExercise,
    },
    {
      className: classNames("bg-purple-03 fill-purple-03"),
      key: "grantedSettling",
      label: "Settl.",
      tooltip: "Granted (Settling)",
      ...grantedSettling,
    },
    {
      className: classNames("bg-purple-02 fill-purple-02"),
      key: "grantedVestedExercised",
      label: "Exerc.",
      tooltip: "Granted (Vested and exercised)",
      ...grantedVestedExercised,
    },
  ];

  const barDataNoZero = barsData.filter((barData) => barData.percent > 0);

  return (
    <div className="flex flex-col gap-6">
      <ResponsiveContainer height={height} width="100%">
        <BarChart
          barGap={barGap}
          barSize={barSize}
          data={[
            {
              available: available.percent,
              drafted: drafted.percent,
              grantedSettling: grantedSettling.percent,
              grantedToVest: grantedToVest.percent,
              grantedVestedExercised: grantedVestedExercised.percent,
              grantedVestedToExercise: grantedVestedToExercise.percent,
              pending: pending.percent,
            },
          ]}
          margin={{ bottom: 20, top: 24 }}
        >
          <CartesianGrid className="stroke-gray-04" vertical={false} />
          <YAxis
            axisLine={false}
            className="stroke-gray-08"
            style={{
              fontFamily: "IBM Plex Sans",
              fontSize: 12,
            }}
            tickLine={false}
            tickMargin={28}
            unit="%"
          />
          <Tooltip
            content={({ active }) => {
              if (!active || !hoveredBar) return null;

              const item = barDataNoZero.find(
                (barData) => barData.key === hoveredBar,
              );

              if (!item) return;

              return (
                <div className="flex flex-col gap-2 rounded bg-black-07 p-4 text-white">
                  <Typography as="div" variant="Regular/Extra Small">
                    {item.tooltip}
                  </Typography>

                  <Typography
                    as="div"
                    className="flex flex-col"
                    variant="Medium/Extra Small"
                  >
                    <Typography variant="Regular/Caption">
                      % Fully diluted:
                    </Typography>
                    <FormattedPercentage value={item.percent / 100} />
                  </Typography>

                  {item.realShares ? (
                    <Typography
                      as="div"
                      className="flex flex-col"
                      variant="Medium/Extra Small"
                    >
                      <Typography variant="Regular/Caption">
                        # shares:
                      </Typography>
                      <FormattedNumber value={item.realShares} />
                    </Typography>
                  ) : null}

                  {item.virtualShares ? (
                    <Typography
                      as="div"
                      className="flex flex-col"
                      variant="Medium/Extra Small"
                    >
                      <Typography variant="Regular/Caption">
                        # virtual shares:
                      </Typography>
                      <FormattedNumber value={item.virtualShares} />
                    </Typography>
                  ) : null}
                </div>
              );
            }}
            cursor={false}
            position={{ x: tooltipXPosition }}
          />

          {barDataNoZero.map((barData) => (
            <Bar
              activeBar={false}
              className={barData.className}
              dataKey={barData.key}
              key={barData.key}
              name={barData.label}
              onMouseEnter={(event) => {
                const parsedEvent = onMouseEnterEventSchema.safeParse(event);

                if (parsedEvent.success) {
                  setTooltipXPosition(parsedEvent.data.tooltipPosition.x);
                }
                setHoveredBar(barData.key);
              }}
              onMouseLeave={() => {
                setHoveredBar(null);
              }}
              shape={(props: unknown) => (
                <RoundedBarShape {...(props as RoundedBarShapeProps)} />
              )}
              unit="%"
            >
              <LabelList
                className="fill-gray-08"
                position="bottom"
                style={{
                  fontFamily: "IBM Plex Sans",
                  fontSize: 12,
                }}
                valueAccessor={() => barData.label}
              />
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
