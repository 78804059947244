import {
  ArrowsRightLeftIcon,
  ClipboardIcon,
  EnvelopeIcon,
  PlusIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/outline";
import {
  Activity,
  Clipboard,
  CopySuccess,
  Document,
  Edit,
  Flash,
  GlobalEdit,
  Graph,
  House,
  Profile2User,
  Setting2,
  TaskSquare,
  Teacher,
} from "iconsax-react";
import { sumBy } from "lodash";
import { startTransition, useEffect, useMemo, useRef, useState } from "react";
import {
  useFragment,
  useRefetchableFragment,
  useSubscribeToInvalidationState,
} from "react-relay";
import {
  generatePath,
  Navigate,
  Outlet,
  useMatch,
  useOutletContext,
} from "react-router-dom";
import { useScroll } from "react-use";
import { graphql } from "relay-runtime";

import { BookADemoModal } from "../../components/BookADemo/BookADemoModal";
import { FairMarketValueSendToRewiewSliderRemote } from "../../components/FairMarketValueSendToRewiewSlider";
import { InviteAdminSlideOver } from "../../components/InviteAdminSlideOver";
import {
  NewEquitySelectionModal,
  useNewEquitySelectionModalState,
} from "../../components/NewEquitySelectionModal/NewEquitySelectionModal";
import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { PoweredByCarta } from "../../components/ui/PoweredByCarta/PoweredByCarta";
import { NonEmptyArray } from "../../helpers/ts-utlity";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { useBoolean } from "../../hooks/useBoolean";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import { useScrollToTopOnNavigation } from "../../hooks/useScrollToTopOnNavigation";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import {
  AdminLayout_Organization$data,
  AdminLayout_Organization$key,
} from "./__generated__/AdminLayout_Organization.graphql";
import { AdminLayout_Organization_Query } from "./__generated__/AdminLayout_Organization_Query.graphql";
import { AdminLayout_Viewer$key } from "./__generated__/AdminLayout_Viewer.graphql";
import { FreemiumPlanStickyBanner } from "./FreemiumPlanStickyBanner";
import { FreeTrialPlanStickyBanner } from "./FreeTrialPlanStickyBanner";
import { GRANT_STATUS_LABEL_MAP } from "./GRANT_STATUS_LABEL_MAP";

const VIEWER_FRAGMENT = graphql`
  fragment AdminLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    ...ApplicationSideBar_Viewer
    ...BookADemoModal_Viewer
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminLayout_Organization on Organization
  @refetchable(queryName: "AdminLayout_Organization_RefetchableQuery") {
    id
    name
    granteeCleanupSuggestionsCount
    plan
    planIsFreemium
    shouldShowExerciseHandler
    currentRemoteOnboardingStep
    adminInvitationIsAllowed
    pendingApprovalFairMarketValueBoardConsents {
      __typename
    }
    fairMarketValueHistory {
      status
    }
    isConnectedToCarta
    isOriginatingFromRemoteEquity

    draftEasopGrants: easopGrants(filters: { statusIn: [Draft] }) {
      __typename
    }
    pendingReviewEasopGrants: easopGrants(
      filters: { statusIn: [PendingReview] }
    ) {
      __typename
    }
    reviewedEasopGrants: easopGrants(filters: { statusIn: [Reviewed] }) {
      __typename
    }
    pendingBoardConsentEasopGrants: easopGrants(
      filters: { statusIn: [PendingBoardConsent] }
    ) {
      __typename
    }
    pendingCtmsImplementationEasopGrants: easopGrants(
      filters: { statusIn: [PendingCtmsImplementation] }
    ) {
      __typename
    }

    underReviewGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
    ) {
      __typename
    }
    readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [PENDING_APPROVAL]
    ) {
      __typename
    }
    implementationGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      __typename
    }

    underReviewCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [UNDER_REVIEW]
    ) {
      __typename
    }
    readyForConsentCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    pendingApprovalCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [PENDING_APPROVAL]
    ) {
      __typename
    }
    implementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      __typename
    }
    ...ApplicationSideBar_Organization
    ...FreeTrialPlanStickyBanner_Organization
    ...BookADemoModal_Organization
  }
`;

export interface OutletContext {
  mainContentScroll: {
    x: number;
    y: number;
  };
}

interface GrantCategory {
  color: string;
  counter: number;
  label: string;
  route:
    | "organizationCTMSGrantAmendmentRequestsBoardApproval"
    | "organizationCTMSGrantAmendmentRequestsImplementation"
    | "organizationCTMSGrantAmendmentRequestsPendingApproval"
    | "organizationCTMSGrantAmendmentRequestsUnderReview"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval"
    | "organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview"
    | "organizationPrepareYourGrantsBoardApproval"
    | "organizationPrepareYourGrantsDrafts"
    | "organizationPrepareYourGrantsPendingApproval"
    | "organizationPrepareYourGrantsPendingImplementation"
    | "organizationPrepareYourGrantsUnderReview";
}

export const useAdminLayoutContext = () => {
  return useOutletContext<OutletContext>();
};

const AdminLayout_: React.FC<{
  organizationFragment: AdminLayout_Organization$key;
  viewerFragment: AdminLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const mainContentRef = useRef<HTMLDivElement | null>(null);
  useScrollToTopOnNavigation(mainContentRef);
  const mainContentScroll = useScroll(mainContentRef);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  useSubscribeToInvalidationState([organization.id], () => {
    startTransition(() => {
      refetchOrganization({});
    });
  });

  const {
    hideNewEquitySelectionModal,
    newEquitySelectionModalState,
    showNewEquitySelectionModal,
  } = useNewEquitySelectionModalState();

  const grantCategories: NonEmptyArray<GrantCategory> = useMemo(() => {
    return [
      {
        color: "bg-green",
        counter: organization.draftEasopGrants.length,
        label: "Draft",
        route: "organizationPrepareYourGrantsDrafts",
      },
      {
        color: "bg-primary",
        counter: organization.pendingReviewEasopGrants.length,
        label: "Under review",
        route: "organizationPrepareYourGrantsUnderReview",
      },
      {
        color: "bg-purple",
        counter: organization.reviewedEasopGrants.length,
        label: "Board approval",
        route: "organizationPrepareYourGrantsBoardApproval",
      },
      {
        color: "bg-orange",
        counter: organization.pendingBoardConsentEasopGrants.length,
        label: "Pending",
        route: "organizationPrepareYourGrantsPendingApproval",
      },
      {
        color: "bg-yellow",
        counter: organization.pendingCtmsImplementationEasopGrants.length,
        label: "Implementation",
        route: "organizationPrepareYourGrantsPendingImplementation",
      },
    ];
  }, [
    organization.draftEasopGrants.length,
    organization.pendingBoardConsentEasopGrants.length,
    organization.pendingCtmsImplementationEasopGrants.length,
    organization.pendingReviewEasopGrants.length,
    organization.reviewedEasopGrants.length,
  ]);

  const grantCount = useMemo(
    () => sumBy(grantCategories, (grantCategory) => grantCategory.counter),
    [grantCategories],
  );

  const granteeTerminationGrantAmendmentCategories: NonEmptyArray<GrantCategory> =
    useMemo(() => {
      return [
        {
          color: "bg-primary",
          counter:
            organization.underReviewGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingReview,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview",
        },
        {
          color: "bg-purple",
          counter:
            organization
              .readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.Reviewed,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval",
        },
        {
          color: "bg-orange",
          counter:
            organization
              .pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingBoardConsent,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval",
        },
        {
          color: "bg-yellow",
          counter:
            organization
              .implementationGranteeTerminationCTMSGrantAmendmentRequests
              .length,
          label: GRANT_STATUS_LABEL_MAP.PendingCtmsImplementation,
          route:
            "organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation",
        },
      ];
    }, [
      organization.underReviewGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.implementationGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.pendingApprovalGranteeTerminationCTMSGrantAmendmentRequests
        .length,
      organization.readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
        .length,
    ]);

  const granteeTerminationGrantAmendmentCount = useMemo(
    () =>
      sumBy(
        granteeTerminationGrantAmendmentCategories,
        (category) => category.counter,
      ),
    [granteeTerminationGrantAmendmentCategories],
  );

  const grantAmendmentCategories: NonEmptyArray<GrantCategory> = useMemo(() => {
    return [
      {
        color: "bg-primary",
        counter: organization.underReviewCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingReview,
        route: "organizationCTMSGrantAmendmentRequestsUnderReview",
      },
      {
        color: "bg-purple",
        counter: organization.readyForConsentCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.Reviewed,
        route: "organizationCTMSGrantAmendmentRequestsBoardApproval",
      },
      {
        color: "bg-orange",
        counter: organization.pendingApprovalCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingBoardConsent,
        route: "organizationCTMSGrantAmendmentRequestsPendingApproval",
      },
      {
        color: "bg-yellow",
        counter: organization.implementationCTMSGrantAmendmentRequests.length,
        label: GRANT_STATUS_LABEL_MAP.PendingCtmsImplementation,
        route: "organizationCTMSGrantAmendmentRequestsImplementation",
      },
    ];
  }, [
    organization.underReviewCTMSGrantAmendmentRequests.length,
    organization.implementationCTMSGrantAmendmentRequests.length,
    organization.pendingApprovalCTMSGrantAmendmentRequests.length,
    organization.readyForConsentCTMSGrantAmendmentRequests.length,
  ]);

  const showCleanupGranteesLink =
    viewer.isAllowedToManageOrganization &&
    organization.granteeCleanupSuggestionsCount > 0;

  const grantAmendmentCount = useMemo(
    () => sumBy(grantAmendmentCategories, (category) => category.counter),
    [grantAmendmentCategories],
  );

  const fairMarketValuesPendingReviewCount =
    organization.fairMarketValueHistory.filter(
      (fairMarketValue) => fairMarketValue.status === "PendingReview",
    ).length;

  const fairMarketValuesReviewedCount =
    organization.fairMarketValueHistory.filter(
      (fairMarketValue) => fairMarketValue.status === "Reviewed",
    ).length;

  const fairMarketValueApprovalCount =
    organization.pendingApprovalFairMarketValueBoardConsents.length;

  const fairMarketValuesBeingApprovedCount =
    fairMarketValuesPendingReviewCount +
    fairMarketValuesReviewedCount +
    fairMarketValueApprovalCount;

  const isOnDocumentsSection = !!useMatch(
    `/organizations/:organizationId/documents/*`,
  );

  const isOnSettingsSection = !!useMatch(
    `/organizations/:organizationId/settings/*`,
  );

  const isOnGrantEquitySection = !!useMatch(
    `/organizations/:organizationId/prepare-your-grants/*`,
  );

  const isOnGranteeTerminationAmendEquitySection = !!useMatch(
    `/organizations/:organizationId/grantee-termination-grant-amendment-requests/*`,
  );

  const isOnAmendEquitySection = !!useMatch(
    `/organizations/:organizationId/grant-amendment-requests/*`,
  );

  const isOnFairMarketValueApprovalSection = !!useMatch(
    `/organizations/:organizationId/fair-market-value-approvals/*`,
  );

  const isOnEquity = !!useMatch(`/organizations/:organizationId/equity/*`);

  const [unfoldedSection, setUnfoldedSection] = useState<
    | "amend-equity"
    | "documents"
    | "equity"
    | "fair_market_value_approval"
    | "grant-equity"
    | "grantee-termination-amend-equity"
    | "settings"
    | null
  >(null);

  useEffect(() => {
    if (isOnDocumentsSection) {
      setUnfoldedSection("documents");
      return;
    }
    if (isOnSettingsSection) {
      setUnfoldedSection("settings");
      return;
    }
    if (isOnGrantEquitySection) {
      setUnfoldedSection("grant-equity");
      return;
    }
    if (isOnGranteeTerminationAmendEquitySection) {
      setUnfoldedSection("grantee-termination-amend-equity");
      return;
    }
    if (isOnAmendEquitySection) {
      setUnfoldedSection("amend-equity");
      return;
    }
    if (isOnEquity) {
      setUnfoldedSection("equity");
      return;
    }
    if (isOnFairMarketValueApprovalSection) {
      setUnfoldedSection("fair_market_value_approval");
      return;
    }
    setUnfoldedSection(null);
  }, [
    isOnDocumentsSection,
    isOnSettingsSection,
    isOnGrantEquitySection,
    isOnGranteeTerminationAmendEquitySection,
    isOnAmendEquitySection,
    isOnEquity,
    isOnFairMarketValueApprovalSection,
  ]);

  const {
    setFalse: hideInviteAdminSlideOver,
    setTrue: showInviteAdminSlideOver,
    value: inviteAdminSlideOverIsShown,
  } = useBoolean(false);

  if (organization.currentRemoteOnboardingStep && !viewer.isSuperAdmin) {
    return (
      <Navigate
        replace
        to={generatePath(APPLICATION_ROUTES.organizationFinishOnboarding, {
          organizationId: organization.id,
        })}
      />
    );
  }

  return (
    <>
      <BookADemoModal
        organizationFragment={organization}
        viewerFragment={viewer}
      />
      <InviteAdminSlideOver
        onClose={hideInviteAdminSlideOver}
        organizationId={organization.id}
        show={inviteAdminSlideOverIsShown}
      />
      <NewEquitySelectionModal
        onClose={hideNewEquitySelectionModal}
        organizationId={organization.id}
        showEquityOfferCard
        state={newEquitySelectionModalState}
      />
      <ApplicationSideBar.Layout>
        <ApplicationSideBar
          activeRole="Admin"
          bottomFixedContent={
            organization.isConnectedToCarta && (
              <PoweredByCarta className="m-auto h-full" />
            )
          }
          homePath={generatePath(APPLICATION_ROUTES.organizationHome, {
            organizationId: organization.id,
          })}
          organizationFragment={organization}
          viewerFragment={viewer}
        >
          <div className="flex flex-col gap-[1px]">
            <ApplicationSideBar.NavLink
              end
              icon={<House variant="Bulk" />}
              to={generatePath(APPLICATION_ROUTES.organizationHome, {
                organizationId: organization.id,
              })}
            >
              Overview
            </ApplicationSideBar.NavLink>
            {showCleanupGranteesLink && (
              <ApplicationSideBar.SquaredIconNavLink
                count={organization.granteeCleanupSuggestionsCount}
                icon={<ArrowsRightLeftIcon />}
                to={generatePath(
                  APPLICATION_ROUTES.organizationSettingsCleanupGrantees,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                <CleanUpGranteesLabel organization={organization} />
              </ApplicationSideBar.SquaredIconNavLink>
            )}
            {organization.adminInvitationIsAllowed && (
              <ApplicationSideBar.Button
                icon={<EnvelopeIcon />}
                onClick={() => {
                  showInviteAdminSlideOver();
                }}
              >
                Invite admin
              </ApplicationSideBar.Button>
            )}
            {viewer.isAllowedToManageOrganization &&
              !organization.planIsFreemium && (
                <ApplicationSideBar.Button
                  icon={<PlusIcon />}
                  onClick={() => {
                    showNewEquitySelectionModal({ granteeFragment: null });
                  }}
                >
                  Create new
                </ApplicationSideBar.Button>
              )}
          </div>

          <ApplicationSideBar.Section title="Organization">
            <ApplicationSideBar.NavLink
              icon={<Activity variant="Bulk" />}
              to={generatePath(APPLICATION_ROUTES.organizationInsights, {
                organizationId: organization.id,
              })}
            >
              Insights
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              icon={<Flash variant="Bulk" />}
              to={generatePath(APPLICATION_ROUTES.organizationPlanning, {
                organizationId: organization.id,
              })}
            >
              Planning
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.NavLink
              icon={<Profile2User variant="Bulk" />}
              to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
                organizationId: organization.id,
              })}
            >
              Grantees
            </ApplicationSideBar.NavLink>
            <ApplicationSideBar.FoldableItem
              icon={<Graph variant="Bulk" />}
              isOpen={unfoldedSection === "equity"}
              label="Equity"
              onToggle={() => {
                setUnfoldedSection(
                  unfoldedSection !== "equity" ? "equity" : null,
                );
              }}
            >
              <ApplicationSideBar.NavLink
                isSubLink
                to={generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
                  organizationId: organization.id,
                })}
              >
                Grants
              </ApplicationSideBar.NavLink>
              <ApplicationSideBar.NavLink
                isSubLink
                to={generatePath(
                  APPLICATION_ROUTES.organizationEquityConfigure,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                Configure
              </ApplicationSideBar.NavLink>
            </ApplicationSideBar.FoldableItem>
            {!organization.planIsFreemium && (
              <ApplicationSideBar.NavLink
                icon={<Document variant="Bulk" />}
                to={generatePath(APPLICATION_ROUTES.organizationDocuments, {
                  organizationId: organization.id,
                })}
              >
                Documents
              </ApplicationSideBar.NavLink>
            )}
            {viewer.isAllowedToManageOrganization && (
              <ApplicationSideBar.FoldableItem
                icon={<Setting2 variant="Bulk" />}
                isOpen={unfoldedSection === "settings"}
                label="Settings"
                onToggle={() => {
                  setUnfoldedSection(
                    unfoldedSection !== "settings" ? "settings" : null,
                  );
                }}
              >
                <ApplicationSideBar.NavLink
                  end
                  isSubLink
                  to={generatePath(APPLICATION_ROUTES.organizationSettings, {
                    organizationId: organization.id,
                  })}
                >
                  Organization
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsAdmins,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Admins
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsEquityViewers,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Equity viewers
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsBoardMembers,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Board members
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsSafeCalculator,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  SAFE converter
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsIntegrations,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Integrations
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={generatePath(
                    APPLICATION_ROUTES.organizationSettingsBilling,
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  Plan & Billing
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  isSubLink
                  to={{
                    pathname: generatePath(
                      APPLICATION_ROUTES.organizationSettingsCleanupGrantees,
                      {
                        organizationId: organization.id,
                      },
                    ),
                    search: "ignored=true",
                  }}
                >
                  <CleanUpGranteesLabel organization={organization} />
                </ApplicationSideBar.NavLink>
              </ApplicationSideBar.FoldableItem>
            )}
          </ApplicationSideBar.Section>

          <ApplicationSideBar.Section title="Legal workflows">
            {!organization.planIsFreemium ? (
              <>
                <ApplicationSideBar.FoldableItem
                  count={grantCount}
                  icon={<CopySuccess variant="Bulk" />}
                  isOpen={unfoldedSection === "grant-equity"}
                  label="New grants"
                  onToggle={() => {
                    setUnfoldedSection(
                      unfoldedSection !== "grant-equity"
                        ? "grant-equity"
                        : null,
                    );
                  }}
                >
                  {grantCategories.map((grantCategory) => (
                    <ApplicationSideBar.NavLink
                      count={grantCategory.counter}
                      isSubLink
                      key={grantCategory.label}
                      squareClassName={grantCategory.color}
                      to={generatePath(
                        APPLICATION_ROUTES[grantCategory.route],
                        {
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {grantCategory.label}
                    </ApplicationSideBar.NavLink>
                  ))}
                </ApplicationSideBar.FoldableItem>
                {granteeTerminationGrantAmendmentCount > 0 && (
                  <ApplicationSideBar.FoldableItem
                    count={granteeTerminationGrantAmendmentCount}
                    icon={<ClipboardIcon />}
                    isOpen={
                      unfoldedSection === "grantee-termination-amend-equity"
                    }
                    label="Terminations"
                    onToggle={() => {
                      setUnfoldedSection(
                        unfoldedSection !== "grantee-termination-amend-equity"
                          ? "grantee-termination-amend-equity"
                          : null,
                      );
                    }}
                  >
                    {granteeTerminationGrantAmendmentCategories.map(
                      (category) => (
                        <ApplicationSideBar.NavLink
                          count={category.counter}
                          isSubLink
                          key={category.label}
                          squareClassName={category.color}
                          to={generatePath(APPLICATION_ROUTES[category.route], {
                            organizationId: organization.id,
                          })}
                        >
                          {category.label}
                        </ApplicationSideBar.NavLink>
                      ),
                    )}
                  </ApplicationSideBar.FoldableItem>
                )}
                {grantAmendmentCount > 0 && (
                  <ApplicationSideBar.FoldableItem
                    count={grantAmendmentCount}
                    icon={<Edit variant="Bulk" />}
                    isOpen={unfoldedSection === "amend-equity"}
                    label="Amendments"
                    onToggle={() => {
                      setUnfoldedSection(
                        unfoldedSection !== "amend-equity"
                          ? "amend-equity"
                          : null,
                      );
                    }}
                  >
                    {grantAmendmentCategories.map((category) => (
                      <ApplicationSideBar.NavLink
                        count={category.counter}
                        isSubLink
                        key={category.label}
                        squareClassName={category.color}
                        to={generatePath(APPLICATION_ROUTES[category.route], {
                          organizationId: organization.id,
                        })}
                      >
                        {category.label}
                      </ApplicationSideBar.NavLink>
                    ))}
                  </ApplicationSideBar.FoldableItem>
                )}
                {fairMarketValuesBeingApprovedCount > 0 && (
                  <ApplicationSideBar.FoldableItem
                    count={fairMarketValuesBeingApprovedCount}
                    icon={<CopySuccess variant="Bulk" />}
                    isOpen={unfoldedSection === "fair_market_value_approval"}
                    label="409A approval"
                    onToggle={() => {
                      setUnfoldedSection(
                        unfoldedSection !== "fair_market_value_approval"
                          ? "fair_market_value_approval"
                          : null,
                      );
                    }}
                  >
                    <ApplicationSideBar.NavLink
                      count={fairMarketValuesPendingReviewCount}
                      isSubLink
                      squareClassName="bg-primary"
                      to={generatePath(
                        APPLICATION_ROUTES.organizationFairMarketValueApprovalsUnderReview,
                        {
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {GRANT_STATUS_LABEL_MAP.PendingReview}
                    </ApplicationSideBar.NavLink>
                    <ApplicationSideBar.NavLink
                      count={fairMarketValuesReviewedCount}
                      isSubLink
                      squareClassName="bg-purple"
                      to={generatePath(
                        APPLICATION_ROUTES.organizationFairMarketValueApprovalsBoardApproval,
                        {
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {GRANT_STATUS_LABEL_MAP.Reviewed}
                    </ApplicationSideBar.NavLink>
                    <ApplicationSideBar.NavLink
                      count={fairMarketValueApprovalCount}
                      isSubLink
                      squareClassName="bg-orange"
                      to={generatePath(
                        APPLICATION_ROUTES.organizationFairMarketValueApprovalsPendingApproval,
                        {
                          organizationId: organization.id,
                        },
                      )}
                    >
                      {GRANT_STATUS_LABEL_MAP.PendingBoardConsent}
                    </ApplicationSideBar.NavLink>
                  </ApplicationSideBar.FoldableItem>
                )}
              </>
            ) : (
              <>
                <ApplicationSideBar.NavLink
                  icon={<RectangleStackIcon />}
                  paid
                  to={generatePath(
                    APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts,
                    { organizationId: organization.id },
                  )}
                >
                  New grants
                </ApplicationSideBar.NavLink>
                <ApplicationSideBar.NavLink
                  icon={<ClipboardIcon />}
                  paid
                  to={generatePath(
                    APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview,
                    { organizationId: organization.id },
                  )}
                >
                  Terminations
                </ApplicationSideBar.NavLink>
              </>
            )}
          </ApplicationSideBar.Section>

          <ApplicationSideBar.Section title="Global compliance">
            {viewer.isAllowedToManageOrganization && (
              <ApplicationSideBar.NavLink
                icon={<GlobalEdit variant="Bulk" />}
                to={generatePath(APPLICATION_ROUTES.organizationCountries, {
                  organizationId: organization.id,
                })}
              >
                Countries
              </ApplicationSideBar.NavLink>
            )}

            {organization.shouldShowExerciseHandler && (
              <ApplicationSideBar.NavLink
                icon={<TaskSquare variant="Bulk" />}
                paid={organization.planIsFreemium}
                to={generatePath(APPLICATION_ROUTES.organizationExercises, {
                  organizationId: organization.id,
                })}
              >
                Exercise handler
              </ApplicationSideBar.NavLink>
            )}
          </ApplicationSideBar.Section>

          {viewer.isAllowedToManageOrganization && (
            <ApplicationSideBar.Section title="Employee education">
              <ApplicationSideBar.NavLink
                icon={<Teacher variant="Bulk" />}
                paid={organization.planIsFreemium}
                to={generatePath(
                  APPLICATION_ROUTES.organizationToolsGranteePortalSettings,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                Grantee portal
              </ApplicationSideBar.NavLink>
              <ApplicationSideBar.NavLink
                icon={<Clipboard variant="Bulk" />}
                paid={organization.planIsFreemium}
                to={generatePath(
                  APPLICATION_ROUTES.organizationToolsEquityOffersSettings,
                  {
                    organizationId: organization.id,
                  },
                )}
              >
                Equity offers
              </ApplicationSideBar.NavLink>
            </ApplicationSideBar.Section>
          )}
        </ApplicationSideBar>
        <div
          className="relative h-full flex-auto overflow-auto"
          ref={mainContentRef}
        >
          {organization.plan === "freemiumDemoBooked" && (
            <FreemiumPlanStickyBanner />
          )}
          {organization.plan === "freeTrial" && (
            <FreeTrialPlanStickyBanner organizationFragment={organization} />
          )}
          <Outlet context={{ mainContentScroll } satisfies OutletContext} />
        </div>
      </ApplicationSideBar.Layout>
    </>
  );
};

const QUERY = graphql`
  query AdminLayout_Organization_Query($organizationId: OrganizationId!) {
    me {
      ...AdminLayout_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      name
      ...AdminLayout_Organization
    }
  }
`;

const AdminLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<AdminLayout_Organization_Query>(QUERY, {
    organizationId,
  });
  const { account } = useAuthentication();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider
      account={account}
      organizationName={query.organization.name}
    >
      <AnalyticsContextProvider organizationId={organizationId}>
        <FairMarketValueSendToRewiewSliderRemote.Provider>
          <AdminLayout_
            organizationFragment={query.organization}
            viewerFragment={query.me}
          />
        </FairMarketValueSendToRewiewSliderRemote.Provider>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

const CleanUpGranteesLabel: React.FC<{
  organization: AdminLayout_Organization$data;
}> = ({ organization }) => {
  if (organization.isOriginatingFromRemoteEquity) {
    return <>Match accounts</>;
  }

  return <>Clean up grantees</>;
};

export default AdminLayout;
