import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  RefreshingExercisesRequestJobState,
  RefreshingExercisesRequestNoticeMessage,
} from "../../../components/RefreshingExercisesRequestNoticeMessage";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tab } from "../../../components/ui/Tab";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { ExercisesView_Organization$key } from "./__generated__/ExercisesView_Organization.graphql";
import ExercisesFilters from "./ExercisesFilters";
import { ExercisesTable } from "./ExercisesTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesView_Organization on Organization {
    id
    name
    ...ExercisesTable_Organization
    ctmsExerciseRequests {
      ctmsId
      status
      isInProgress
      ...ExercisesFilters_Exercises
      ...ExercisesTable_Exercises
    }
  }
`;

export const ExercisesView: React.FC<{
  organizationFragment: ExercisesView_Organization$key;
  refreshingExercisesRequest: boolean;
  refreshingExercisesRequestJobState: null | RefreshingExercisesRequestJobState;
}> = ({
  organizationFragment,
  refreshingExercisesRequest,
  refreshingExercisesRequestJobState,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const inProgressExerciseRequests = organization.ctmsExerciseRequests.filter(
    (exerciseRequest) => exerciseRequest.isInProgress,
  );
  const cancelledExerciseRequests = organization.ctmsExerciseRequests.filter(
    (exerciseRequest) => exerciseRequest.status === "Cancelled",
  );
  const doneExerciseRequests = organization.ctmsExerciseRequests.filter(
    (exerciseRequest) => exerciseRequest.status === "Done",
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [filteredExercisesIds, setFilteredExercisesIds] = useState<string[]>(
    organization.ctmsExerciseRequests.map((exercise) => exercise.ctmsId),
  );

  const activeStatus = useMemo(() => {
    switch (selectedTabIndex) {
      case 0:
        return "InProgress";
      case 1:
        return "Done";
      default:
        return "Cancelled";
    }
  }, [selectedTabIndex]);

  const filteredExercises = organization.ctmsExerciseRequests.filter(
    (exercise) => filteredExercisesIds.includes(exercise.ctmsId),
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Exercises</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <RoundedBox className="space-y-2 p-6" withShadow>
        <Typography as="div" variant="Medium/Default">
          Exercises
        </Typography>

        {refreshingExercisesRequest || refreshingExercisesRequestJobState ? (
          <RefreshingExercisesRequestNoticeMessage
            refreshingExercisesRequestJobState={
              refreshingExercisesRequestJobState ?? "Active"
            }
          />
        ) : null}

        <Tab.Group
          onChange={setSelectedTabIndex}
          selectedIndex={selectedTabIndex}
        >
          <Tab.List>
            <Tab.WithCount count={inProgressExerciseRequests.length}>
              In progress
            </Tab.WithCount>
            <Tab.WithCount count={doneExerciseRequests.length}>
              Done
            </Tab.WithCount>
            <Tab.WithCount count={cancelledExerciseRequests.length}>
              Canceled
            </Tab.WithCount>
          </Tab.List>
          <Tab.Panels className="space-y-4">
            <ExercisesFilters
              exercisesFragment={organization.ctmsExerciseRequests}
              setFilteredExercisesIds={setFilteredExercisesIds}
            />
            <ExercisesTable
              exercisesFragment={filteredExercises}
              exerciseStatus={activeStatus}
              organizationFragment={organization}
            />
          </Tab.Panels>
        </Tab.Group>
      </RoundedBox>
    </OneColumnLayout>
  );
};
