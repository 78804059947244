import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { EquityOfferPostTerminationExercisePeriodSelectionBlock } from "../../../components/EquityOfferPostTerminationExercisePeriodSelectionBlock";
import { Page } from "../../../components/Page";
import { QuestionCard } from "../../../components/QuestionCard";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { SelectVestingScheduleInput } from "../../../components/ui/Form/SelectVestingScheduleInput";
import { CreateSlideOverRemote as CreatePTEPSlideOverRemote } from "../Grants/Configure/PostTermination/CreateSlideOver";
import { CreateSlideOverRemote as CreateVestingScheduleSlideOverRemote } from "../Grants/Configure/VestingSchedules/CreateSlideOver";
import { VestingSettings_EquityOffer$key } from "./__generated__/VestingSettings_EquityOffer.graphql";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import { EquityOfferVesting } from "./Vesting";

const schema = z.object({
  postTerminationExercisePeriodId: z.string().nullable(),
  vestingScheduleId: z.string().nullable(),
});

type EquityOfferVestingSettingsFormInputs = z.infer<typeof schema>;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment VestingSettings_EquityOffer on EquityOffer {
    id
    vestingSchedule {
      id
    }
    postTerminationExercisePeriod {
      id
    }
    instrument {
      isVirtual
    }

    workRelationship
    organization {
      employeesAndContractorsNewHiresGrantDefaults {
        postTerminationExercisePeriod {
          id
        }
      }
      advisorsNewHiresGrantDefaults {
        postTerminationExercisePeriod {
          id
        }
      }
      id
      name
      ...EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization
      ...SelectVestingScheduleInput_Organization
    }
    ...Vesting_EquityOffer
    ...EquityOfferPreviewLayout_EquityOffer
  }
`;

export const useEquityOfferVestingSettingsForm = ({
  defaultValues,
}: {
  defaultValues: Partial<EquityOfferVestingSettingsFormInputs>;
}) => {
  return useForm({
    resolver: zodResolver(schema),
    values: defaultValues,
  });
};

const EquityOfferVestingSettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<VestingSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );

  const organizationGrantDefaultsForWorkRelationship = useMemo(() => {
    switch (equityOffer.workRelationship) {
      case "Advisor":
        return equityOffer.organization.advisorsNewHiresGrantDefaults
          .postTerminationExercisePeriod?.id;
      case "ContractorManagementCompany":
      case "ContractorNaturalPerson":
      case "DirectEmployee":
      case "EoREmployee":
        return equityOffer.organization
          .employeesAndContractorsNewHiresGrantDefaults
          .postTerminationExercisePeriod?.id;
      case null:
        return null;
    }
  }, [
    equityOffer.organization.advisorsNewHiresGrantDefaults,
    equityOffer.organization.employeesAndContractorsNewHiresGrantDefaults,
    equityOffer.workRelationship,
  ]);

  const { control, formState, handleSubmit, watch } =
    useEquityOfferVestingSettingsForm({
      defaultValues: {
        postTerminationExercisePeriodId:
          equityOffer.postTerminationExercisePeriod?.id ??
          organizationGrantDefaultsForWorkRelationship ??
          null,
        vestingScheduleId: equityOffer.vestingSchedule?.id ?? null,
      },
    });

  const onSubmit = handleSubmit(async (_formInputs) => {
    const formInputs = _formInputs as EquityOfferVestingSettingsFormInputs;
    await equityOfferContext.updatePartialEquityOffer({
      postTerminationExercisePeriodId:
        formInputs.postTerminationExercisePeriodId,
      vestingScheduleId: formInputs.vestingScheduleId,
    });
  });

  watch(() => void onSubmit());

  useEffect(() => {
    equityOfferContext.onStepEntered("VESTING");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      return () => {
        equityOfferContext.trackStepCompleted("VESTING");
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <CreatePTEPSlideOverRemote.Provider>
      <CreateVestingScheduleSlideOverRemote.Provider>
        <EquityOfferPreviewLayout
          continueButtonDataCy="vestingAndPtepNextStepButton"
          description="Provide an incentive for candidates to remain at your company."
          equityOfferFragment={equityOffer}
          onBackClick={equityOfferContext.goToPreviousStep}
          onContinueClick={equityOfferContext.goToNextStep}
          Preview={
            <EquityOfferVesting
              equityOfferFragment={equityOffer}
              hideNoticeMessages
            />
          }
          title={
            equityOffer.instrument?.isVirtual
              ? "Vesting & PTSP"
              : "Vesting & PTEP"
          }
        >
          <div className="space-y-6">
            <FormRow
              error={formState.errors.vestingScheduleId?.message}
              label="Vesting schedule"
            >
              <SelectVestingScheduleInput.Form
                control={control}
                name="vestingScheduleId"
                organizationFragment={equityOffer.organization}
              />
            </FormRow>

            <EquityOfferPostTerminationExercisePeriodSelectionBlock
              control={control}
              isVirtual={equityOffer.instrument?.isVirtual}
              organizationFragment={equityOffer.organization}
            />

            {equityOffer.instrument?.isVirtual && (
              <QuestionCard title="Learn more on PTSP?">
                <p>
                  There’s no standard post termination settlement period for
                  SARs. You could have (ranked by employee friendliness,
                  starting withthe least employee friendly):
                </p>

                <ul className="my-4 list-disc space-y-4 pl-4">
                  <li>
                    <strong>
                      no post termination settlement period →{" "}
                      <u>least employee friendly</u>
                    </strong>
                    , but you can then freely choose if and when the grantee
                    leaves if you want to allow a post termination settlement
                    period
                  </li>
                  <li>
                    <strong>
                      a short post termination settlement period (e.g. of only a
                      few days or months), like the usual 90 days PTEP you would
                      have for employees receiving stock options →{" "}
                      <u>not employee friendly</u>,
                    </strong>{" "}
                    but you can also choose to extend it once grantee leaves
                  </li>
                  <li>
                    <strong>
                      a post termination settlement period that fits a
                      reasonable exit horizon → <u>employee friendly</u>
                    </strong>
                    . Think about your company maturity stage (does an exit seem
                    possible in 2, 5 or 10 years?) and set your post termination
                    settlement period accordingly.
                  </li>
                </ul>

                <p>
                  Whatever option you choose, you’ll always be able to pay the
                  bonus at the time the grantee leaves the company (the bonus
                  amount would then be calculated as the difference between FMV
                  of the shares of Common Stock at the time the person leaves,
                  and the FMV of the shares of Common Stock at the time of
                  grant), but it will be completely up to you! The grantee will
                  never be able to force you to pay anything before an exit
                  event.
                </p>
              </QuestionCard>
            )}
          </div>
        </EquityOfferPreviewLayout>
      </CreateVestingScheduleSlideOverRemote.Provider>
    </CreatePTEPSlideOverRemote.Provider>
  );
};

const EquityOfferVestingSettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<VestingSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Vesting"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer projection scenarios configuration`}
    >
      <EquityOfferVestingSettingsPage_ />
    </Page>
  );
};

export default EquityOfferVestingSettingsPage;
