import React, { startTransition, useCallback, useEffect } from "react";
import { graphql, useRefetchableFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { Page } from "../../../components/Page";
import { RedirectHandler } from "../../../components/RedirectHandler";
import {
  usePollJobState,
  usePollJobStateHookState,
} from "../../../hooks/usePollJobState";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Exercises_Organization$key } from "./__generated__/Exercises_Organization.graphql";
import { Exercises_Query } from "./__generated__/Exercises_Query.graphql";
import { Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation } from "./__generated__/Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation.graphql";
import { ExercisesEmptyState } from "./ExercisesEmptyState";
import { ExercisesView } from "./ExercisesView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Exercises_Organization on Organization
  @refetchable(queryName: "Exercises_Organization_RefetchQuery") {
    id
    ctmsExerciseRequestsAreOutdated
    ctmsExerciseRequests {
      __typename
    }
    ...ExercisesEmptyState_Organization
    ...ExercisesView_Organization
  }
`;

const REFRESH_EXERCISES_REQUESTS_MUTATION = graphql`
  mutation Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation(
    $organizationId: OrganizationId!
  ) {
    startRefreshOrganizationCTMSExerciseRequests(
      organizationId: $organizationId
    ) {
      id
      queue
      state
    }
  }
`;

const AdminExercisesPage_: React.FC<{
  organizationFragment: Exercises_Organization$key;
}> = ({ organizationFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const [
    _refreshOrganizationCTMSExerciseRequests,
    refreshOrganizationCTMSExerciseRequestsMutationIsInFlight,
  ] =
    useSafeMutation<Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation>(
      REFRESH_EXERCISES_REQUESTS_MUTATION,
    );

  const { hookState, startPolling } = usePollJobStateHookState();

  const { jobState } = usePollJobState({
    hookState,
    onJobDone: () => {
      startTransition(() => {
        refetchOrganization({});
      });
    },
  });

  const refreshOrganizationCTMSExerciseRequests = useCallback(async () => {
    const { startRefreshOrganizationCTMSExerciseRequests: job } =
      await _refreshOrganizationCTMSExerciseRequests({
        variables: { organizationId: organization.id },
      });

    startPolling({
      jobId: job.id,
      jobQueue: job.queue,
    });
  }, [organization.id, _refreshOrganizationCTMSExerciseRequests, startPolling]);

  useEffect(() => {
    if (organization.ctmsExerciseRequestsAreOutdated) {
      void refreshOrganizationCTMSExerciseRequests();
    }
  }, [
    refreshOrganizationCTMSExerciseRequests,
    organization.ctmsExerciseRequestsAreOutdated,
  ]);

  if (organization.ctmsExerciseRequests.length === 0) {
    return (
      <ExercisesEmptyState
        organizationFragment={organization}
        refreshingExercisesRequest={
          hookState.polling ||
          refreshOrganizationCTMSExerciseRequestsMutationIsInFlight
        }
        refreshingExercisesRequestJobState={jobState}
      />
    );
  }

  return (
    <ExercisesView
      organizationFragment={organization}
      refreshingExercisesRequest={
        hookState.polling ||
        refreshOrganizationCTMSExerciseRequestsMutationIsInFlight
      }
      refreshingExercisesRequestJobState={jobState}
    />
  );
};

const QUERY = graphql`
  query Exercises_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      shouldShowExerciseHandler
      ...Exercises_Organization
    }
  }
`;

const AdminExercisesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<Exercises_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  if (!organization.shouldShowExerciseHandler) {
    return (
      <RedirectHandler
        to={generatePath(APPLICATION_ROUTES["organizationHome"], {
          organizationId: organization.id,
        })}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Exercises"
      analyticsName="Admin - Exercises"
      organizationId={organization.id}
      title={`Admin | ${organization.name} exercises`}
    >
      <AdminExercisesPage_ organizationFragment={organization} />
    </Page>
  );
};

export default AdminExercisesPage;
