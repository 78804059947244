import { graphql, useFragment } from "react-relay";

import { CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod$key } from "./__generated__/CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod.graphql";
import { Duration } from "./Duration";

const ACTIVE_PTEP_FRAGMENT = graphql`
  fragment CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod on ActivePostTerminationExercisePeriod {
    value {
      duration
      unit
    }
    isExtended
  }
`;

export const CtmsGrantActivePTEPValue: React.FC<{
  activePostTerminationExercisePeriodFragment: CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod$key;
}> = ({ activePostTerminationExercisePeriodFragment }) => {
  const activePostTerminationExercisePeriod = useFragment(
    ACTIVE_PTEP_FRAGMENT,
    activePostTerminationExercisePeriodFragment,
  );
  return (
    <>
      <Duration
        duration={activePostTerminationExercisePeriod.value.duration}
        durationUnit={activePostTerminationExercisePeriod.value.unit}
      />
      {activePostTerminationExercisePeriod.isExtended && <> (extended)</>}
    </>
  );
};
