/**
 * @generated SignedSource<<6cc81c263dddb63c077b071d09bae923>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeForm_FindGranteeByEmail_Query$variables = {
  email: string;
  organizationId: string;
};
export type GranteeForm_FindGranteeByEmail_Query$data = {
  readonly granteeByOrganizationAndEmail: {
    readonly id: string;
  } | null;
};
export type GranteeForm_FindGranteeByEmail_Query = {
  response: GranteeForm_FindGranteeByEmail_Query$data;
  variables: GranteeForm_FindGranteeByEmail_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "Grantee",
    "kind": "LinkedField",
    "name": "granteeByOrganizationAndEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeForm_FindGranteeByEmail_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GranteeForm_FindGranteeByEmail_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "45a9adb36f010d90b217a7ba26379e2b",
    "id": null,
    "metadata": {},
    "name": "GranteeForm_FindGranteeByEmail_Query",
    "operationKind": "query",
    "text": "query GranteeForm_FindGranteeByEmail_Query(\n  $organizationId: OrganizationId!\n  $email: String!\n) {\n  granteeByOrganizationAndEmail(organizationId: $organizationId, email: $email) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "16bb611b262a6380fe1935e1d0dd0f55";

export default node;
