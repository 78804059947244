/**
 * @generated SignedSource<<68fad1e53771ac97a3edf5455570fc61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditSafeSimulatorInitialSharesModal_Organization$data = {
  readonly ctmsFullyDilutedShares: number | null;
  readonly esopPoolShares: number | null;
  readonly " $fragmentType": "EditSafeSimulatorInitialSharesModal_Organization";
};
export type EditSafeSimulatorInitialSharesModal_Organization$key = {
  readonly " $data"?: EditSafeSimulatorInitialSharesModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditSafeSimulatorInitialSharesModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditSafeSimulatorInitialSharesModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsFullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7c17efe550b4d5a0bc0457d550e7bf2b";

export default node;
