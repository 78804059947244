/**
 * @generated SignedSource<<583689dbd8d23d794d2d36089ea91854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoPage_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Account">;
  readonly " $fragmentType": "BookADemoPage_Viewer";
};
export type BookADemoPage_Viewer$key = {
  readonly " $data"?: BookADemoPage_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoPage_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoPage_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoView_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "0d734b6c9f9487e8805ae3cc44e6b3f0";

export default node;
