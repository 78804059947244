/**
 * @generated SignedSource<<d9f3975ec1c5cb6d45641f2df28fff5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SendReminderButton_BoardConsent$data = {
  readonly id: string;
  readonly " $fragmentType": "SendReminderButton_BoardConsent";
};
export type SendReminderButton_BoardConsent$key = {
  readonly " $data"?: SendReminderButton_BoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"SendReminderButton_BoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SendReminderButton_BoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "BoardConsent",
  "abstractKey": "__isBoardConsent"
};

(node as any).hash = "49b5809aea4f46d6c9fc4b76515841fe";

export default node;
