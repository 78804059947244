import { graphql } from "react-relay";
import { Outlet } from "react-router-dom";

import { useQuery } from "../../../hooks/useQuery";
import { useCTMSGrantAmendmentRequestIdParam } from "../../../paths";
import { ModifyCTMSGrantContextProvider } from "../CtmsGrant/Modify/Context";
import { DetailsLayout_CTMSGrantAmendmentRequest_Query } from "./__generated__/DetailsLayout_CTMSGrantAmendmentRequest_Query.graphql";

const QUERY = graphql`
  query DetailsLayout_CTMSGrantAmendmentRequest_Query(
    $ctmsGrantAmendmentRequestId: UUID!
  ) {
    ctmsGrantAmendmentRequest(id: $ctmsGrantAmendmentRequestId)
      @required(action: THROW) {
      ...Context_CTMSGrantAmendmentRequest
    }
  }
`;

const Layout: React.FC = () => {
  const ctmsGrantAmendmentRequestId = useCTMSGrantAmendmentRequestIdParam();

  const {
    query: { ctmsGrantAmendmentRequest },
  } = useQuery<DetailsLayout_CTMSGrantAmendmentRequest_Query>(QUERY, {
    ctmsGrantAmendmentRequestId,
  });

  return (
    <ModifyCTMSGrantContextProvider
      ctmsGrantAmendmentRequestFragment={ctmsGrantAmendmentRequest}
    >
      <Outlet />
    </ModifyCTMSGrantContextProvider>
  );
};

export default Layout;
