import classNames from "classnames";

import deelLogo from "./deel-logo.png";
import { Picture } from "./Picture";

export const DeelLogo: React.FC<{
  className?: string;
}> = ({ className }) => (
  <Picture
    className={classNames("object-contain", className)}
    image={deelLogo}
  />
);
