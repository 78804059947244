import { Editor as TinyEditor } from "@tinymce/tinymce-react";
import classNames from "classnames";
import { isEmpty, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Editor as TinyMCEEditor } from "tinymce";

import { useBoolean } from "../../hooks/useBoolean";
import CONFIGURATION from "../../services/configuration";
import { Button } from "../ui/Button";
import { escapeHTML, SafeHTML } from "./SafeHTML";
import { EDITOR_SECTIONS, EditorSectionClassAttr } from "./sections";
import { noticeWithTitleTemplate, shadowTemplates } from "./templates";

type NodeWrapper = {
  classlist: string;
  containsTextOnly: boolean;
  onlyIsolated: boolean;
  tagName: string;
};

interface PastePostprocessData {
  node: Node;
}
export function formatTableAsRoundedBox(node: Node) {
  if (node.nodeName === "TABLE") {
    replaceNode({
      node,
      nodeWrappers: [
        {
          classlist: "wysiwygShadowBox",
          containsTextOnly: false,
          onlyIsolated: true,
          tagName: "section",
        },
      ],
    });
  } else {
    node.childNodes.forEach((childNode) => {
      formatTableAsRoundedBox(childNode);
    });
  }
}
function getCssContent() {
  let css = `
    body.mce-content-body {
      padding: 30px;
    }
  `;

  try {
    Array.from(document.styleSheets).forEach((stylesheet) => {
      Array.from(stylesheet.cssRules).forEach(
        (cssRule) => (css += cssRule.cssText),
      );
    });
  } catch {
    return "";
  }

  return css;
}

function isLonelyTag(node: Node, tagName: string) {
  return (
    node &&
    node.nodeName === tagName.toUpperCase() &&
    node.parentElement?.childNodes.length === 1
  );
}

function prependAvatar(node: Node, letter: string) {
  const tag = document.createElement("div");
  tag.innerHTML = letter;
  tag.className = "wysiwygCirlcleLetter";
  node.insertBefore(tag, node.firstChild);
  replaceNode({
    node,
    nodeWrappers: [
      {
        classlist: "wysiwygCirlcleLetterContainer",
        containsTextOnly: false,
        onlyIsolated: false,
        tagName: "li",
      },
    ],
  });
}

function replaceNode({
  node,
  nodeWrappers,
}: {
  node: Node;
  nodeWrappers: NodeWrapper[];
}) {
  const hasSiblings = (node.parentElement?.childNodes?.length || 0) > 1;

  // onlyIsolated lets you target isolated elements (no siblings)
  // We need this to avoid transforming bold text inside a paragraph into a title
  const filteredNodeWrappers = nodeWrappers.filter(
    ({ onlyIsolated }) => !onlyIsolated || !hasSiblings,
  );

  if (filteredNodeWrappers.length === 0) return;

  const content: HTMLElement = document.createElement("text");
  if (node instanceof HTMLElement) {
    content.innerHTML = node.innerHTML;
  } else {
    content.textContent = node.textContent;
  }

  // nodeWrappers work as "poupée russe"
  const nodeWrapped: HTMLElement = filteredNodeWrappers.reduce(
    (_nodeWrapped, nodeWrapper) => {
      const tag = document.createElement(nodeWrapper.tagName);
      if (nodeWrapper.classlist) {
        tag.classList.add(nodeWrapper.classlist);
      }
      if (nodeWrapper.containsTextOnly) {
        tag.textContent = _nodeWrapped.textContent;
      } else {
        tag.appendChild(_nodeWrapped);
      }
      return tag;
    },
    content,
  );

  const parentElement = node.parentElement;
  parentElement?.replaceChild(nodeWrapped, node);

  // If node is in a p tag
  // Then we unwrap all of the p tag children
  // Maybe this should happen after all children have been formatted
  if (parentElement?.nodeName === "P") {
    for (const child of [...parentElement.children]) {
      parentElement.parentElement?.insertBefore(child, parentElement);
    }
    parentElement.parentElement?.removeChild(parentElement);
  }
}

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
export function formatAvatars(node: Node, index: number) {
  const nodeAsHTMLElement = node.firstChild?.parentElement;
  if (
    node.nodeName === "LI" &&
    nodeAsHTMLElement?.style.getPropertyValue("list-style-type")
  ) {
    const listStyleType =
      nodeAsHTMLElement.style.getPropertyValue("list-style-type");
    const startIndex =
      node.parentElement?.attributes?.getNamedItem("start")?.value || "1";
    const letter = listStyleType.includes("alpha")
      ? alphabet[Number(startIndex) - 1 + index] || "A"
      : (Number(startIndex) + index).toString();
    prependAvatar(node, letter);
  } else {
    node.childNodes.forEach((childNode, index) => {
      formatAvatars(childNode, index);
    });
  }
}

export function formatBlocks(node: Node) {
  node.childNodes.forEach((childNode) => {
    if (childNode.childNodes.length > 0) {
      formatBlocks(childNode);
    }

    const parentNode = childNode.parentElement;
    if (!parentNode) return;

    // Style debugger
    // console.log(
    //   Array.from(parentNode.style)
    //     .filter((style) => style !== "")
    //     .map((style) => `${style}: ${parentNode.style[style]}`)
    // );

    const nodeWrappers: NodeWrapper[] = [];

    EDITOR_SECTIONS.forEach((section) => {
      if (!section.gDocStyles) return;

      const matchesGDocStyles = [...section.gDocStyles].every((style) => {
        return (
          // Can't use getPropertyValue because it ignores fontWeight for some reason
          parentNode.style[style.prop] === style.value
        );
      });

      if (matchesGDocStyles) {
        nodeWrappers.push({
          classlist: section.classAttr ?? "",
          containsTextOnly: section.containsTextOnly,
          onlyIsolated: section.onlyReplaceWhenIsolated,
          tagName: section.tagName,
        });
      }
    });

    replaceNode({ node: parentNode, nodeWrappers });
  });
}

export function formatVariables(node: Node) {
  if (node instanceof HTMLElement) {
    node.childNodes.forEach((childNode) => {
      formatVariables(childNode);
    });
  } else if (
    node.textContent?.includes("{{") &&
    node.parentElement &&
    // We don't want to format variables in headers
    !["H1", "H2", "H3", "H4", "H5"].includes(node.parentElement.nodeName)
  ) {
    node.parentElement.innerHTML = node.textContent.replace(
      new RegExp(`{{(?!else})([^#/]+?)}}`, "gm"),
      `<span class="wysiwygTag">$&</span>`,
    );
  }
}

export function moveUpBackgroundColor(node: Node) {
  if (node instanceof HTMLElement) {
    const bgColor = node.style.getPropertyValue("background-color");
    if (!bgColor) return;

    const display = node.style.getPropertyValue("display");
    if (display === "none") return;

    const parent = node.parentElement;
    if (!parent) return;

    const siblingsAndSelf = Array.from(parent.childNodes).filter(
      (node) => node instanceof HTMLElement,
    );

    // If has no siblings, we can move up the background color
    if (siblingsAndSelf.length === 1) {
      parent.style.setProperty("background-color", bgColor);
      node.style.removeProperty("background-color");

      if (node.attributes.getNamedItem("style")?.value === "") {
        node.removeAttribute("style");
      }

      moveUpBackgroundColor(parent);
    }

    if (siblingsAndSelf.length > 1) {
      // If all siblings have the same background-color, we can move up the background color
      const siblingsHaveSameBgColor = Array.from(siblingsAndSelf).every(
        (sibling) => {
          if (sibling instanceof HTMLElement) {
            return (
              sibling.style.getPropertyValue("background-color") === bgColor
            );
          }
          return false;
        },
      );

      if (siblingsHaveSameBgColor) {
        siblingsAndSelf.forEach((sibling) => {
          if (sibling instanceof HTMLElement) {
            sibling.style.removeProperty("background-color");

            if (sibling.attributes.getNamedItem("style")?.value === "") {
              sibling.removeAttribute("style");
            }
          }
        });

        parent.style.setProperty("background-color", bgColor);
        moveUpBackgroundColor(parent);
      }
    }
  }
}

// We don't want isolated children with background color, so let's move them up until an ancestor has siblings
export function moveUpBackgroundColors(node: Node) {
  if (node instanceof HTMLElement) {
    if (node.style.getPropertyValue("background-color")) {
      moveUpBackgroundColor(node);
    }

    node.childNodes.forEach((childNode) => {
      moveUpBackgroundColors(childNode);
    });
  }
}

export function removeCascadingEmptyTags(node: Node) {
  const isLonelyBr = isLonelyTag(node, "br");
  const isEmpty = !node.textContent || node.textContent.trim() === "";

  if (isLonelyBr || isEmpty) {
    const parentNode = node.parentNode;
    parentNode?.removeChild(node);

    if (parentNode) {
      removeCascadingEmptyTags(parentNode);
    }
  } else {
    node.childNodes.forEach((childNode) => {
      removeCascadingEmptyTags(childNode);
    });
  }
}

// It's useless and causes problems with the callout nested clear
export function removeTransparentBackgroundColor(node: Node) {
  if (node instanceof HTMLElement) {
    node.childNodes.forEach((childNode) => {
      removeTransparentBackgroundColor(childNode);
    });

    if (node.style.getPropertyValue("background-color") === "transparent") {
      node.style.removeProperty("background-color");
    }

    if (node.attributes.getNamedItem("style")?.value === "") {
      node.removeAttribute("style");
    }
  }
}

function replaceFontWeightBoldWithStrongBlock(node: Node) {
  node.childNodes.forEach((childNode) => {
    if (childNode.childNodes.length > 0) {
      replaceFontWeightBoldWithStrongBlock(childNode);
    }

    if (node instanceof HTMLElement) {
      if (
        node.tagName === "SPAN" &&
        node.style.getPropertyValue("font-weight") === "bold"
      ) {
        node.style.removeProperty("font-weight");
        const content: HTMLElement = document.createElement("strong");
        content.innerHTML = node.outerHTML;

        node.parentElement?.replaceChild(content, node);
      }
    }
  });
}

const GroupedClasses: ReadonlyArray<EditorSectionClassAttr> = [
  "wysiwygInfo",
  "wysiwygNotice",
  "wysiwygCalloutDanger",
  "wysiwygCalloutSuccess",
  "wysiwygCalloutWarning",
  "wysiwygCalloutExample",
] as const;

function groupBlocks(node: Node) {
  node.childNodes.forEach(groupBlocks);

  let previousChild: HTMLElement | null = null;
  const childrenToRemove: HTMLElement[] = [];
  node.childNodes.forEach((child) => {
    if (child instanceof HTMLElement) {
      if (previousChild) {
        const childClasses = child.getAttribute("class")?.split(" ").sort();
        const previousChildClasses = previousChild
          .getAttribute("class")
          ?.split(" ")
          .sort();
        if (
          !isEmpty(childClasses) &&
          isEqual(childClasses, previousChildClasses) &&
          childClasses?.every((className) =>
            GroupedClasses.includes(className as EditorSectionClassAttr),
          )
        ) {
          // We need to append each subchild individually to respect flex-col gap
          child.childNodes.forEach((subChild) => {
            // Need to wrap content in a div to prevent text from being concatenated
            const wrappedSubChild: HTMLElement = document.createElement("div");
            wrappedSubChild.appendChild(subChild);
            previousChild?.appendChild(wrappedSubChild);
          });
          childrenToRemove.push(child);
          return;
        }
      }
      previousChild = child;
    } else {
      previousChild = null;
    }
  });
  childrenToRemove.forEach((child) => node.removeChild(child));
}

const config = {
  body_class: classNames("mce-content-body bg-gray-01"),
  content_css_cors: true,
  content_style: getCssContent(),
  contextmenu: "visualblocks bold italic underline hr link styles template",
  // Needed this otherwise empty span got removed by TinyMCE internal logic
  extended_valid_elements: "span[*]",
  inline: true,
  link_default_target: "_blank",
  menubar: false,
  paste_postprocess: function (_api: unknown, data: PastePostprocessData) {
    // Clean up
    removeCascadingEmptyTags(data.node);
    removeTransparentBackgroundColor(data.node);

    moveUpBackgroundColors(data.node);
    formatBlocks(data.node);
    replaceFontWeightBoldWithStrongBlock(data.node);
    formatTableAsRoundedBox(data.node);
    formatAvatars(data.node, 0);
    formatVariables(data.node);
    groupBlocks(data.node);
  },
  paste_webkit_styles:
    "font-weight text-decoration font-style font-size background-color list-style-type",
  plugins: [
    "autolink",
    "link",
    "lists",
    "visualblocks",
    "quickbars",
    "template",
  ],
  quickbars_insert_toolbar: "hr link styles template",
  quickbars_selection_toolbar: "bold italic underline | styles | quicklink",
  style_formats: [
    {
      format: "p",
      title: "Normal text",
    },
    {
      items: EDITOR_SECTIONS.map(({ classAttr, tagName, title }) => ({
        block: tagName,
        classes: classAttr,
        title,
      })),
      title: "Sections",
    },
    {
      items: EDITOR_SECTIONS.map(({ classAttr, tagName, title }) => ({
        classes: classAttr,
        inline: tagName,
        title: `Inline ${title}`,
      })),
      title: "Inline sections",
    },
  ],
  // template_replace_values: {
  // },
  // template_preview_replace_values: {
  // },
  templates: [...shadowTemplates, noticeWithTitleTemplate],
  toolbar: false,
  visualblocks_default_state: false,
};

const Editor = ({
  content,
  isLoading,
  onSubmit,
}: {
  content: string;
  isLoading?: boolean;
  onSubmit: (content: string) => void;
}) => {
  const editorRef = useRef<null | TinyMCEEditor>(null);

  const [tempContent, setTempContent] = useState<string>(content);

  useEffect(() => {
    setTempContent(content);
  }, [content]);

  const {
    setFalse: editContent,
    setTrue: previewContent,
    value: isPreviewing,
  } = useBoolean(true);

  return (
    <>
      <div className="sticky -top-6 z-10 mb-10 flex justify-end gap-4 bg-white p-3">
        {isPreviewing ? (
          <Button onClick={editContent} variant="Primary Full">
            Edit content
          </Button>
        ) : (
          <>
            <Button
              onClick={() => {
                if (!editorRef.current) return;
                setTempContent("");
                editorRef.current.focus();
              }}
              variant="Danger Outline"
            >
              Reset content
            </Button>

            <Button
              onClick={() => {
                if (!editorRef.current) return;
                setTempContent(editorRef.current.getContent());
                previewContent();
              }}
              variant="Primary Outline"
            >
              Preview
            </Button>

            <Button
              loading={isLoading}
              onClick={() => {
                if (!editorRef.current) return;

                const escapedHtml = escapeHTML(editorRef.current.getContent());
                onSubmit(escapedHtml);
                setTempContent(escapedHtml);
                previewContent();
              }}
              variant="Primary Full"
            >
              Save content
            </Button>
          </>
        )}
      </div>

      {isPreviewing ? (
        <SafeHTML>{tempContent}</SafeHTML>
      ) : (
        <TinyEditor
          apiKey={CONFIGURATION.TINY_EDITOR_API_KEY}
          init={config}
          initialValue={tempContent}
          onInit={(_evt, editor) => (editorRef.current = editor)}
        />
      )}
    </>
  );
};

export default Editor;
