/**
 * @generated SignedSource<<1f291b41ca62f2be943b3b5199436b1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment$data = ReadonlyArray<{
  readonly equityType: {
    readonly id: string;
    readonly name: EquityTypeName;
  } | null;
  readonly id: string;
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly code: string;
    readonly emoji: string;
  };
  readonly " $fragmentType": "AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment";
}>;
export type AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment$key = ReadonlyArray<{
  readonly " $data"?: AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityType",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};
})();

(node as any).hash = "0ceb34c45dfb2e95c2aa457fe7a786f2";

export default node;
