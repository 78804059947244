import { HRIS_PROVIDER_MAP } from "../hooks/useHRISProvider";
import { Tag } from "./ui/Tag";

export const MissingInformationFromHRISProviderTag: React.FC<{
  hRISProvider: "DEEL" | "REMOTE";
}> = ({ hRISProvider }) => {
  return (
    <Tag color="orange">
      <span className="font-emoji">⚠️</span> Could not retrieve data from{" "}
      {HRIS_PROVIDER_MAP[hRISProvider]}.
    </Tag>
  );
};
