/**
 * @generated SignedSource<<6ce7434679101005c379783abbea5755>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTotalOwnershipBox_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly fullyDilutedShares: number | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsDraftsList_Organization" | "GranteeDetailsEquityGridLevelTag_Organization" | "GranteeDetailsGrantsList_Organization" | "SharesValue_Organization">;
  readonly " $fragmentType": "GranteeTotalOwnershipBox_Organization";
};
export type GranteeTotalOwnershipBox_Organization$key = {
  readonly " $data"?: GranteeTotalOwnershipBox_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTotalOwnershipBox_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTotalOwnershipBox_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsEquityGridLevelTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsGrantsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsDraftsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e2e84eb0a1e631b7153bd1574cab7341";

export default node;
