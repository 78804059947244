/**
 * @generated SignedSource<<74913f35cb7c452be87dfc895dbb1189>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$data = {
  readonly displayedName: string;
  readonly document: {
    readonly id: string;
  };
  readonly organization: {
    readonly id: string;
  };
  readonly " $fragmentType": "MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument";
};
export type MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$key = {
  readonly " $data"?: MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$data;
  readonly " $fragmentSpreads": FragmentRefs<"MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "document",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "OrganizationMiscellaneousLegalDocument",
  "abstractKey": null
};
})();

(node as any).hash = "79139e5003832b980ba2ec25ead74205";

export default node;
