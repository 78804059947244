import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Dot,
  Label,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from "recharts";

import { isNonEmptyArray } from "../helpers/ts-utlity";

export function TimelineLinearGraph<TDataPoint extends { x: Date; y: number }>({
  allowDecimalsOnYAxis = false,
  dataPoints: _dataPoints,
  height = 400,
  onCellClick,
  renderTooltip,
  yLabel,
  yTickFormatter: _yTickFormatter,
}: {
  allowDecimalsOnYAxis?: boolean;
  dataPoints: TDataPoint[];
  height?: number;
  onCellClick?: (d: TDataPoint) => void;
  renderTooltip?: (d: TDataPoint) => React.ReactNode;
  yLabel?: React.ReactNode;
  yTickFormatter?: (value: number) => string;
}) {
  const intl = useIntl();
  const dataPoints = useMemo(
    () => _dataPoints.map((d) => ({ ...d, x: d.x.getTime() })),
    [_dataPoints],
  );
  const yTickFormatter = useMemo(
    () =>
      _yTickFormatter ??
      ((value: number) =>
        intl.formatNumber(value, {
          compactDisplay: "short",
          notation: "compact",
        })),
    [_yTickFormatter, intl],
  );

  return (
    <ResponsiveContainer height={height} width="100%">
      <AreaChart
        data={dataPoints}
        margin={{
          top: 40,
        }}
      >
        <CartesianGrid strokeDasharray="2 4" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="x"
          domain={["dataMin", "dataMax"]}
          padding={{ left: 50, right: 50 }}
          scale="time"
          tick={(props: { payload: { value: number } }) => {
            return (
              <Text
                {...props}
                className="font-captionRegular translate-y-2 transform fill-black-05"
              >
                {intl.formatDate(props.payload.value, {
                  month: "short",
                  timeZone: "UTC",
                  year: "2-digit",
                })}
              </Text>
            );
          }}
          tickLine={false}
          type="number"
        />

        <YAxis
          allowDecimals={allowDecimalsOnYAxis}
          axisLine={false}
          mirror={true}
          tick={(props: { payload: { value: number } }) => (
            <Text
              {...props}
              className="font-captionRegular -translate-x-2 -translate-y-4 transform fill-black-05"
            >
              {yTickFormatter(props.payload.value)}
            </Text>
          )}
          tickLine={false}
          type="number"
        >
          {yLabel && (
            <Label
              className="font-mediumExtraSmall -translate-x-1 -translate-y-1/2 transform fill-black-07"
              position="insideLeft"
            >
              {yLabel}
            </Label>
          )}
        </YAxis>
        <RechartsTooltip
          content={({ active, payload }) => {
            if (!active || !payload || !isNonEmptyArray(payload)) return null;

            const hoveredData = dataPoints.find(
              (d) => d === payload[0].payload,
            );
            if (!hoveredData) return null;

            return renderTooltip?.(hoveredData);
          }}
          cursor={false}
          trigger="hover"
        />
        <defs>
          <linearGradient
            className="text-purple-05"
            id="gradientPurple"
            x1="0"
            x2="0"
            y1="0"
            y2="1"
          >
            <stop offset="5%" stopColor="currentColor" stopOpacity={1} />
            <stop offset="95%" stopColor="currentColor" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          activeDot={(props: { payload: unknown }) => {
            const dataPoint = dataPoints.find((d) => d === props.payload);
            return (
              <Dot
                {...props}
                className="cursor-pointer fill-white-01 stroke-purple-05"
                onClick={() => dataPoint && onCellClick?.(dataPoint)}
                r={4}
                strokeWidth={2}
              />
            );
          }}
          className="stroke-purple-05"
          connectNulls
          dataKey="y"
          dot={false}
          fill="url(#gradientPurple)"
          fillOpacity={1}
          strokeWidth={2}
          type="monotone"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
