/**
 * @generated SignedSource<<1f6523f776ff67a70042c1e8af5c2e95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption = "CUSTOM_DATE" | "LAST_DAY_AT_THE_COMPANY" | "TERMINATION_DATE";
export type GranteeTerminationCTMSGrantAmendmentRequestAttributes = {
  postTerminationExercisePeriodId?: any | null;
  vestingEndCustomDate?: string | null;
  vestingEndOption?: GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption | null;
  waiveCliff?: boolean | null;
};
export type GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query$variables = {
  ctmsGrantId: string;
  grantAmendmentAttributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes;
  terminationDate: string;
  terminationLastDayAtTheCompany: string;
};
export type GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query$data = {
  readonly getQuantityVestedAtEndOfVestingForGrantAmendment: {
    readonly __typename: "GetQuantityVestedAtEndOfVestingForGrantAmendmentFailure";
    readonly __typename: "GetQuantityVestedAtEndOfVestingForGrantAmendmentFailure";
  } | {
    readonly __typename: "GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess";
    readonly value: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query = {
  response: GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query$data;
  variables: GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsGrantId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "grantAmendmentAttributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminationDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "terminationLastDayAtTheCompany"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ctmsGrantId",
        "variableName": "ctmsGrantId"
      },
      {
        "kind": "Variable",
        "name": "grantAmendmentAttributes",
        "variableName": "grantAmendmentAttributes"
      },
      {
        "kind": "Variable",
        "name": "terminationDate",
        "variableName": "terminationDate"
      },
      {
        "kind": "Variable",
        "name": "terminationLastDayAtTheCompany",
        "variableName": "terminationLastDayAtTheCompany"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "getQuantityVestedAtEndOfVestingForGrantAmendment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "type": "GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f144063a0bca3b139b2603fcda830d33",
    "id": null,
    "metadata": {},
    "name": "GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query",
    "operationKind": "query",
    "text": "query GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query(\n  $ctmsGrantId: CtmsGrantId!\n  $grantAmendmentAttributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes!\n  $terminationDate: Date!\n  $terminationLastDayAtTheCompany: Date!\n) {\n  getQuantityVestedAtEndOfVestingForGrantAmendment(ctmsGrantId: $ctmsGrantId, grantAmendmentAttributes: $grantAmendmentAttributes, terminationDate: $terminationDate, terminationLastDayAtTheCompany: $terminationLastDayAtTheCompany) {\n    __typename\n    ... on GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess {\n      value\n    }\n    ... on GetQuantityVestedAtEndOfVestingForGrantAmendmentFailure {\n      __typename\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29ff571e0b1f51db2fa10bddeac1186e";

export default node;
