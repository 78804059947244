export const DeelGlyph: React.FC<React.ComponentProps<"svg">> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#C4B1F9" height="47.9998" rx="23.9999" width="48" />
      <path
        d="M20.71 35C19.2354 35 17.9188 34.6278 16.7602 33.8833C15.6016 33.1388 14.6853 32.1207 14.0111 30.8288C13.3371 29.537 13 28.0699 13 26.4277C13 24.7855 13.3371 23.3294 14.0111 22.0595C14.6853 20.7676 15.6016 19.7604 16.7602 19.0378C17.9188 18.2933 19.2354 17.9211 20.71 17.9211C21.8897 17.9211 22.9219 18.151 23.8067 18.6108C24.5159 18.9794 25.1168 19.4675 25.6094 20.0753C25.7211 20.2131 25.9554 20.1351 25.9554 19.9554L25.9554 11.9758C25.9554 11.8893 26.0142 11.8147 26.0961 11.7971L29.7872 11.0039C29.8969 10.9803 30 11.0668 30 11.1825L30 34.4234C30 34.5242 29.9208 34.6059 29.8233 34.6059H26.5432C26.4588 34.6059 26.386 34.5441 26.3698 34.4585L26.0337 32.6866C26.0039 32.5299 25.8047 32.4834 25.706 32.6063C25.235 33.1928 24.6441 33.717 23.933 34.1789C23.1115 34.7263 22.0372 35 20.71 35ZM21.5632 31.3215C22.8692 31.3215 23.933 30.8726 24.7546 29.9749C25.5972 29.0553 26.0185 27.8838 26.0185 26.4605C26.0185 25.0373 25.5972 23.8769 24.7546 22.9791C23.933 22.0595 22.8692 21.5996 21.5632 21.5996C20.2782 21.5996 19.2144 22.0485 18.3717 22.9462C17.5291 23.844 17.1078 25.0044 17.1078 26.4277C17.1078 27.8509 17.5291 29.0224 18.3717 29.942C19.2144 30.8616 20.2782 31.3215 21.5632 31.3215Z"
        fill="#15357A"
      />
      <path
        d="M33.5 35C34.8807 35 36 33.8807 36 32.5C36 31.1193 34.8807 30 33.5 30C32.1193 30 31 31.1193 31 32.5C31 33.8807 32.1193 35 33.5 35Z"
        fill="#15357A"
      />
    </svg>
  );
};
