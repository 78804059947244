import React from "react";

import { Button, LinkButton } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../../hooks/useBoolean";

export function WarningNoticeMessageWithSlideOverExplanation({
  children,
  helpEmailSubject,
  whatDoesItMean,
  whatShouldIDo,
}: React.PropsWithChildren<{
  helpEmailSubject: string;
  whatDoesItMean: React.ReactNode;
  whatShouldIDo?: React.ReactNode;
}>) {
  const {
    setFalse: closeSlideOver,
    setTrue: openSlideOver,
    value: slideOverIsOpen,
  } = useBoolean(false);
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <>
      <SlideOver floating onClose={closeSlideOver} show={slideOverIsOpen}>
        <SlideOver.Header borderless onClose={closeSlideOver} />
        <div className="space-y-6 px-6">
          <NoticeMessage size="Large" variant="Danger">
            {children}
          </NoticeMessage>
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Small">
              What does it mean?
            </Typography>
            <Typography as="div" variant="Regular/Extra Small">
              {whatDoesItMean}
            </Typography>
          </div>
          {whatShouldIDo && (
            <div className="space-y-2">
              <Typography as="div" variant="Medium/Small">
                What should I do?
              </Typography>
              <Typography as="div" variant="Regular/Extra Small">
                {whatShouldIDo}
              </Typography>
            </div>
          )}
          <Divider />
          <div className="flex justify-end gap-4">
            <LinkButton
              size="small"
              to={`mailto:${supportEmailAddress}?subject=${helpEmailSubject}`}
              variant="Secondary Full"
            >
              Contact us
            </LinkButton>
            <Button onClick={closeSlideOver} size="small" type="button">
              Got it
            </Button>
          </div>
        </div>
      </SlideOver>
      <NoticeMessage size="Large" variant="Danger">
        {children}{" "}
        <button className="cursor-pointer underline" onClick={openSlideOver}>
          What does it mean?
        </button>
      </NoticeMessage>
    </>
  );
}
