/**
 * @generated SignedSource<<429b074d464e9d274d24d7d1716ef856>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation$variables = {
  boardMemberId: string;
};
export type OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation$data = {
  readonly removeBoardMember: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Organization">;
  };
};
export type OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation = {
  response: OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation$data;
  variables: OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardMemberId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "boardMemberId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeBoardMember",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsBoardMembers_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeBoardMember",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isRemovable",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d2c2f0407d2b37c5cb3dc4f78b960a0c",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation(\n  $boardMemberId: UUID!\n) {\n  removeBoardMember(id: $boardMemberId) {\n    ...OrganizationSettingsBoardMembers_Organization\n    id\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Item_BoardMember on BoardMember {\n  id\n  isRemovable\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Organization on Organization {\n  id\n  name\n  boardMembers {\n    id\n    account {\n      firstName\n      lastName\n      email\n      id\n    }\n    ...OrganizationSettingsBoardMembers_Item_BoardMember\n  }\n}\n"
  }
};
})();

(node as any).hash = "0667d3118b659c9ee6fb38f670f5f633";

export default node;
