import _ from "lodash";
import { useCallback, useMemo } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  ExerciseRequestsListSlideOver,
  useExerciseRequestsListSlideOverState,
} from "../../../../components/ExerciseRequestsListSlideOver";
import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { TimelineLinearGraph } from "../../../../components/TimelineLinearGraph";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { ExercisesActivityGraph_ExercisesActivityDataPoints$key } from "./__generated__/ExercisesActivityGraph_ExercisesActivityDataPoints.graphql";
import {
  ExercisesActivityGraph_Organization$data,
  ExercisesActivityGraph_Organization$key,
} from "./__generated__/ExercisesActivityGraph_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesActivityGraph_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    ...ExerciseRequestsListSlideOver_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment ExercisesActivityGraph_ExercisesActivityDataPoints on ExercisesActivityDataPoint
  @relay(plural: true) {
    sharesExercised
    cashReceivedFromExercisesInUSD
    date
    exerciseRequests {
      ctmsGrant {
        grantee {
          id
        }
      }
      ...ExerciseRequestsListSlideOver_ExerciseRequests
    }
  }
`;

export const ExercisesActivityGraphModes = ["SHARES", "CASH"] as const;
export type ExercisesActivityGraphMode =
  (typeof ExercisesActivityGraphModes)[number];

const Tooltip: React.FC<{
  date: Date;
  granteesCount: number;
  organization: ExercisesActivityGraph_Organization$data;
  sharesExercised: number;
}> = ({ date, granteesCount, organization, sharesExercised }) => {
  const { sharesToFullyDilutedRatio } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });
  const sharesExercisedOwnership = sharesToFullyDilutedRatio(sharesExercised);
  return (
    <GraphTooltip
      bottomContent={
        <FormattedMessage
          defaultMessage="Click on the data point to see the {count, plural, one {grantee} other {list of grantees}}"
          values={{ count: granteesCount }}
        />
      }
      topContent={
        <FormattedDate
          month="long"
          timeZone="UTC"
          value={date}
          year="numeric"
        />
      }
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className="h-2 w-2 rounded-full bg-purple-05" />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage="{count, plural, one {# grantee} other {# grantees}}"
              values={{ count: granteesCount }}
            />
          </Typography>
          <Typography as="div" variant="Regular/Extra Small">
            Number of options exercised
          </Typography>
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedNumber value={sharesExercised} />
          </Typography>
          {sharesExercisedOwnership !== null && (
            <Typography as="div" variant="Medium/Extra Small">
              <FormattedMessage
                defaultMessage="{fullyDilutedPercent} fully diluted"
                values={{
                  fullyDilutedPercent: (
                    <FormattedPercentage value={sharesExercisedOwnership} />
                  ),
                }}
              />
            </Typography>
          )}
        </div>
      </div>
    </GraphTooltip>
  );
};

export const ExercisesActivityGraph: React.FC<{
  dataPointsFragment: ExercisesActivityGraph_ExercisesActivityDataPoints$key;
  mode: ExercisesActivityGraphMode;
  organizationFragment: ExercisesActivityGraph_Organization$key;
}> = ({ dataPointsFragment, mode, organizationFragment }) => {
  const intl = useIntl();

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  const getYAxisValue = useCallback(
    (dataPoint: (typeof dataPoints)[number]) => {
      switch (mode) {
        case "CASH":
          return dataPoint.cashReceivedFromExercisesInUSD;
        case "SHARES":
          return dataPoint.sharesExercised;
      }
    },
    [mode],
  );

  const data = useMemo(
    () =>
      dataPoints.map((dataPoint) => {
        const uniqueGrantees = _(dataPoint.exerciseRequests)
          .flatMap((exerciseRequest) => exerciseRequest.ctmsGrant)
          .flatMap((grant) => grant.grantee)
          .uniqBy((grantee) => grantee.id)
          .value();

        return {
          dataPoint,
          sharesExercised: dataPoint.sharesExercised,
          uniqueGrantees,
          x: new Date(dataPoint.date),
          y: getYAxisValue(dataPoint),
        };
      }),
    [dataPoints, getYAxisValue],
  );

  const getYAxisLabel = useMemo(() => {
    switch (mode) {
      case "CASH":
        return "Cash received";
      case "SHARES":
        return "Options exercised";
    }
  }, [mode]);
  const getYAxisTickFormatter = useCallback(
    (value: number) => {
      switch (mode) {
        case "CASH":
          return intl.formatNumber(value, {
            currency: "USD",
            maximumFractionDigits: 0,
            style: "currency",
          });
        case "SHARES":
          return intl.formatNumber(value);
      }
    },
    [mode, intl],
  );

  const {
    close: closeGranteesListSlideOver,
    open: openGranteesListSlideOver,
    state: grantsListSlideOverState,
  } = useExerciseRequestsListSlideOverState();

  return (
    <>
      <ExerciseRequestsListSlideOver
        onClose={closeGranteesListSlideOver}
        organizationFragment={organization}
        state={grantsListSlideOverState}
      />
      <TimelineLinearGraph
        dataPoints={data}
        onCellClick={({ dataPoint, uniqueGrantees, x: date }) => {
          return openGranteesListSlideOver({
            exerciseRequestsFragment: dataPoint.exerciseRequests,
            title: (
              <FormattedMessage
                defaultMessage={`There {granteesCount, plural, one {is # grantee} other {are # grantees}} who exercised in {date}`}
                values={{
                  date: (
                    <FormattedDate
                      month="long"
                      timeZone="UTC"
                      value={date}
                      year="numeric"
                    />
                  ),
                  granteesCount: uniqueGrantees.length,
                }}
              />
            ),
          });
        }}
        renderTooltip={({ sharesExercised, uniqueGrantees, x: date }) => (
          <Tooltip
            date={date}
            granteesCount={uniqueGrantees.length}
            organization={organization}
            sharesExercised={sharesExercised}
          />
        )}
        yLabel={getYAxisLabel}
        yTickFormatter={getYAxisTickFormatter}
      />
    </>
  );
};
