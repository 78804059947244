/**
 * @generated SignedSource<<f5733f24214e8704fdb22ee3b59f2857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DraftWatchoutKey = "CUSTOM_BOARD_CONSENT_EXHIBIT_CONTENT" | "EARLY_EXERCISE_IS_ALLOWED" | "EXERCISE_PRICE_SHOULD_BE_EQUAL_TO_INSTRUMENT_VALUATION" | "GRANTED_TO_CORPORATION" | "HAS_ACCELERATION_CLAUSE" | "MISSING_CARTA_UPLOAD_FILE_SCOPE" | "MISSING_EQUITY_PLAN_DOCUMENT" | "MISSING_EQUITY_SUB_PLAN_DOCUMENT" | "MISSING_INSTRUMENT_VALUATION" | "MISSING_VESTING_SCHEDULE_IN_CTMS" | "NO_SUBSIDIARY_FOR_GRANTEE_COUNTRY" | "PTEP_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_PTEP" | "PULLEY_FIRST_GRANT" | "VESTING_SCHEDULE_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_VESTING_SCHEDULE" | "VESTING_START_DATE_SHOULD_BE_EQUAL_TO_CONTRACT_START_DATE_FOR_INITIAL_GRANTS" | "VESTING_START_DATE_SHOULD_DIFFER_FROM_CONTRACT_START_DATE_FOR_REFRESHER_GRANTS";
export type DraftWatchoutType = "INFORMATION" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant$data = {
  readonly watchouts: ReadonlyArray<{
    readonly key: DraftWatchoutKey;
    readonly type: DraftWatchoutType;
  }>;
  readonly " $fragmentType": "DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant";
};
export type DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant$key = {
  readonly " $data"?: DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DraftWatchout",
      "kind": "LinkedField",
      "name": "watchouts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "4d4259b034c33e77f5657af3f6b70d36";

export default node;
