/**
 * @generated SignedSource<<b43203a9bd7ec2fef1cb5e171e584f04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Debug_Error_Mutation$variables = Record<PropertyKey, never>;
export type Debug_Error_Mutation$data = {
  readonly throwError: boolean;
};
export type Debug_Error_Mutation = {
  response: Debug_Error_Mutation$data;
  variables: Debug_Error_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "throwError",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Debug_Error_Mutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Debug_Error_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "002db8214886b8b8382fe1172e3bcd39",
    "id": null,
    "metadata": {},
    "name": "Debug_Error_Mutation",
    "operationKind": "mutation",
    "text": "mutation Debug_Error_Mutation {\n  throwError\n}\n"
  }
};
})();

(node as any).hash = "5b20f6b48fb2e86666431700d91e81c4";

export default node;
