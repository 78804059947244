/**
 * @generated SignedSource<<d79621c066c2646ca0896585ce84dba1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation$variables = {
  documentId: string;
  organizationId: string;
};
export type MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation$data = {
  readonly deleteOrganizationMiscellaneousLegalDocument: boolean;
};
export type MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation = {
  response: MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation$data;
  variables: MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteOrganizationMiscellaneousLegalDocument",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d9fa1f4d00fb52c626610caf676a070a",
    "id": null,
    "metadata": {},
    "name": "MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation(\n  $organizationId: OrganizationId!\n  $documentId: UUID!\n) {\n  deleteOrganizationMiscellaneousLegalDocument(organizationId: $organizationId, documentId: $documentId)\n}\n"
  }
};
})();

(node as any).hash = "f77cdf6beb47493762c61d20bd7c20dc";

export default node;
