/**
 * @generated SignedSource<<3a44d43c17d7f58d3fd24975eb479e0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$variables = {
  ctmsExerciseRequestCTMSId: string;
  taxResidenceCountryIsKnown: boolean;
};
export type ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$data = {
  readonly setExerciseRequestTaxResidenceCountryIsKnown: {
    readonly taxResidenceCountryIsKnown: boolean;
  };
};
export type ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$rawResponse = {
  readonly setExerciseRequestTaxResidenceCountryIsKnown: {
    readonly id: string;
    readonly taxResidenceCountryIsKnown: boolean;
  };
};
export type ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation = {
  rawResponse: ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$rawResponse;
  response: ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$data;
  variables: ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsExerciseRequestCTMSId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taxResidenceCountryIsKnown"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ctmsExerciseRequestCTMSId",
    "variableName": "ctmsExerciseRequestCTMSId"
  },
  {
    "kind": "Variable",
    "name": "taxResidenceCountryIsKnown",
    "variableName": "taxResidenceCountryIsKnown"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxResidenceCountryIsKnown",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSExerciseRequest",
        "kind": "LinkedField",
        "name": "setExerciseRequestTaxResidenceCountryIsKnown",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSExerciseRequest",
        "kind": "LinkedField",
        "name": "setExerciseRequestTaxResidenceCountryIsKnown",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "49af506861e932294d583d6691725e6c",
    "id": null,
    "metadata": {},
    "name": "ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExerciseRequestDetails_SetExerciseRequestTaxResidenceCountryIsKnown_Mutation(\n  $ctmsExerciseRequestCTMSId: ID!\n  $taxResidenceCountryIsKnown: Boolean!\n) {\n  setExerciseRequestTaxResidenceCountryIsKnown(ctmsExerciseRequestCTMSId: $ctmsExerciseRequestCTMSId, taxResidenceCountryIsKnown: $taxResidenceCountryIsKnown) {\n    taxResidenceCountryIsKnown\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9bf9345cc32bbf7bb474feaab0ef00ce";

export default node;
