/**
 * @generated SignedSource<<f707a74b2fa2491eee3542dd11beace3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminAttributes = {
  accountId: string;
  organizationId: string;
};
export type InviteAdminSlideOver_AddAdmin_Mutation$variables = {
  attributes: AdminAttributes;
};
export type InviteAdminSlideOver_AddAdmin_Mutation$data = {
  readonly addAdmin: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Organization">;
    };
  };
};
export type InviteAdminSlideOver_AddAdmin_Mutation = {
  response: InviteAdminSlideOver_AddAdmin_Mutation$data;
  variables: InviteAdminSlideOver_AddAdmin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAdminSlideOver_AddAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAdminResult",
        "kind": "LinkedField",
        "name": "addAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsAdmins_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAdminSlideOver_AddAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddAdminResult",
        "kind": "LinkedField",
        "name": "addAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminInvitationIsAllowed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Admin",
                "kind": "LinkedField",
                "name": "admins",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "orderBy": {
                        "direction": "ASC",
                        "field": "name"
                      }
                    }
                  }
                ],
                "concreteType": "RemoteCompanyAdmin",
                "kind": "LinkedField",
                "name": "remoteCompanyAdmins",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userSlug",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowedAsAdmin",
                    "storageKey": null
                  }
                ],
                "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea409f7b095066ecc886ddfd2b12428f",
    "id": null,
    "metadata": {},
    "name": "InviteAdminSlideOver_AddAdmin_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteAdminSlideOver_AddAdmin_Mutation(\n  $attributes: AdminAttributes!\n) {\n  addAdmin(attributes: $attributes, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsAdmins_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsAdmins_Item_Admin on Admin {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsAdmins_Organization on Organization {\n  id\n  name\n  adminInvitationIsAllowed\n  admins {\n    id\n    ...OrganizationSettingsAdmins_Item_Admin\n  }\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    __typename\n    id\n  }\n  ...RemoteCompanyAdminsList_Organization\n}\n\nfragment RemoteCompanyAdminsList_Organization on Organization {\n  id\n  name\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    id\n    userSlug\n    name\n    email\n    allowedAsAdmin\n  }\n}\n"
  }
};
})();

(node as any).hash = "95854427ec8872ca3bec49a701201818";

export default node;
