import classNames from "classnames";
import React, { ReactNode } from "react";

import { Button } from "../Button";

interface Props {
  beforeCancel?: ReactNode;
  onCancel?: () => void;
}

export const FormActions: React.FC<
  Props & React.ComponentPropsWithoutRef<"div">
> = ({ beforeCancel, children, className, onCancel, ...props }) => {
  return (
    <div
      className={classNames(className, "flex flex-shrink-0 justify-end gap-4")}
      {...props}
    >
      {beforeCancel ? <div className="mr-auto">{beforeCancel}</div> : null}

      <Button
        onClick={onCancel}
        size="small"
        type="button"
        variant="Secondary Full"
      >
        Cancel
      </Button>

      {children}
    </div>
  );
};
