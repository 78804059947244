import React from "react";

import { ArrowLine } from "./ui/ArrowLine";
import { CenteredColumnLayout } from "./ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const EmptyStateView: React.FC<{
  breadcrumbs?: React.ReactNode;
  bullets?: React.ReactNode[];
  callToAction?: React.ReactNode;
  description: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
}> = ({ breadcrumbs, bullets, callToAction, description, subtitle, title }) => {
  return (
    <CenteredColumnLayout breadcrumbs={breadcrumbs} fullHeight showFooter>
      <div className="flex h-full flex-col items-center justify-center">
        <RoundedBox className="w-[480px] space-y-10 p-10" withBorder>
          <Typography as="div" className="text-center" variant="Medium/Large">
            {title}
          </Typography>
          <div className="space-y-2">
            {subtitle && (
              <Typography as="div" variant="Medium/Small">
                {subtitle}
              </Typography>
            )}
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              {description}
            </Typography>
            {React.Children.map(bullets, (bullet) => (
              <ArrowLine>{bullet}</ArrowLine>
            ))}
          </div>
          {callToAction}
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};
