/**
 * @generated SignedSource<<2d38cdd29df52637deb9e9cf4b4e4eec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants$data = ReadonlyArray<{
  readonly usesAnExpiredTaxFavoredSubplan: boolean;
  readonly " $fragmentType": "useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants";
}>;
export type useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants$key = ReadonlyArray<{
  readonly " $data"?: useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usesAnExpiredTaxFavoredSubplan",
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "31296cbdb2f1828af759c58b8ba9df20";

export default node;
