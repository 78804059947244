/**
 * @generated SignedSource<<974ca1248a47a2619d1d0cbd9724fe50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type useMergeGrantee_HRISProviderEmployee$data = {
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly " $fragmentType": "useMergeGrantee_HRISProviderEmployee";
};
export type useMergeGrantee_HRISProviderEmployee$key = {
  readonly " $data"?: useMergeGrantee_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMergeGrantee_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMergeGrantee_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "6c086bb363a43465c86697bad23d5c24";

export default node;
