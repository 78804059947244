import { isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";

import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useDebounced } from "../../../hooks/useDebounced";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Implementation_CTMSGrantAmendmentRequests_Account$key } from "./__generated__/Implementation_CTMSGrantAmendmentRequests_Account.graphql";
import { Implementation_CTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation } from "./__generated__/Implementation_CTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation.graphql";
import { Implementation_CTMSGrantAmendmentRequests_Organization$key } from "./__generated__/Implementation_CTMSGrantAmendmentRequests_Organization.graphql";
import { Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery } from "./__generated__/Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql";
import { Implementation_CTMSGrantAmendmentRequests_Query } from "./__generated__/Implementation_CTMSGrantAmendmentRequests_Query.graphql";
import { CTMSGrantAmendmentRequestsTable } from "./CTMSGrantAmendmentRequestsTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Implementation_CTMSGrantAmendmentRequests_Organization on Organization
  @refetchable(
    queryName: "Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery"
  )
  @argumentDefinitions(
    grantAmendmentRequestsSearch: { type: "String", defaultValue: "" }
  ) {
    id
    name
    implementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
    ) {
      id
    }
    searchedImplementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [IMPLEMENTATION]
      search: $grantAmendmentRequestsSearch
    ) {
      ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest
    }
    ...LegalWorkflowLayout_Organization
    ...CTMSGrantAmendmentRequestsTable_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment Implementation_CTMSGrantAmendmentRequests_Account on Account {
    isSuperAdmin
  }
`;

const MARK_AMENDMENT_REQUEST_AS_IMPLEMENTED_MUTATION = graphql`
  mutation Implementation_CTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation(
    $ctmsGrantAmendmentRequestIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    markCTMSGrantAmendmentRequestsAsImplemented(
      ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds
      organizationId: $organizationId
    ) {
      __typename
    }
  }
`;

const AdminAmendmentRequestsImplementationPage_: React.FC<{
  organizationFragment: Implementation_CTMSGrantAmendmentRequests_Organization$key;
  viewerFragment: Implementation_CTMSGrantAmendmentRequests_Account$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment<
    Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery,
    Implementation_CTMSGrantAmendmentRequests_Organization$key
  >(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(ACCOUNT_FRAGMENT, viewerFragment);
  const [
    selectedCTMSGrantAmendmentRequestIds,
    setSelectedCTMSGrantAmendmentRequestIds,
  ] = useState<Set<string>>(new Set());
  const selectedCTMSGrantAmendmentRequests = useMemo(
    () =>
      organization.implementationCTMSGrantAmendmentRequests.filter(
        (ctmsGrantAmendmentRequest) =>
          selectedCTMSGrantAmendmentRequestIds.has(
            ctmsGrantAmendmentRequest.id,
          ),
      ),
    [
      selectedCTMSGrantAmendmentRequestIds,
      organization.implementationCTMSGrantAmendmentRequests,
    ],
  );

  const [
    markGrantAmendmentRequestsAsImplemented,
    markGrantAmendmentRequestsAsImplementedMutationIsInFlight,
  ] =
    useSafeMutation<Implementation_CTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation>(
      MARK_AMENDMENT_REQUEST_AS_IMPLEMENTED_MUTATION,
    );

  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            grantAmendmentRequestsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        grantAmendmentRequestsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleReviewAmendmentRequestsButtonClick = useCallback(async () => {
    const ctmsGrantAmendmentRequestIds = [
      ...selectedCTMSGrantAmendmentRequestIds,
    ];

    await markGrantAmendmentRequestsAsImplemented({
      variables: {
        ctmsGrantAmendmentRequestIds,
        organizationId: organization.id,
      },
    });

    handleDataUpdated();

    setSelectedCTMSGrantAmendmentRequestIds(new Set());

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{count, plural,
            one {# amendment request}
            other {# amendment requests}
          } successfully marked as implemented!`}
          values={{
            count: ctmsGrantAmendmentRequestIds.length,
          }}
        />
      </Toast>,
    );
  }, [
    handleDataUpdated,
    selectedCTMSGrantAmendmentRequestIds,
    markGrantAmendmentRequestsAsImplemented,
    toaster,
    organization.id,
  ]);
  const applicationName = useApplicationName();

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchIsDebouncing || searchTransitionIsInProgress}
      searchPlaceholder="Search amendments..."
      searchValue={searchInputValue}
      subtitle={`Grant amendments the ${applicationName} team is currently implementing in your Cap Table Management Solution`}
      title="Pending implementation"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <Button
                disabled={isEmpty(selectedCTMSGrantAmendmentRequests)}
                loading={
                  markGrantAmendmentRequestsAsImplementedMutationIsInFlight
                }
                onClick={handleReviewAmendmentRequestsButtonClick}
                size="small"
              >
                Proceed
              </Button>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select amendments"
            itemCount={selectedCTMSGrantAmendmentRequests.length}
            loading={markGrantAmendmentRequestsAsImplementedMutationIsInFlight}
            pluralLabel="amendments selected"
            singularLabel="amendment selected"
          />
        );
      }}
    >
      {isEmpty(organization.implementationCTMSGrantAmendmentRequests) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle={`Grant amendments the ${applicationName} team is currently implementing in your Cap Table Management Solution`}
          title="Pending implementation"
        />
      ) : (
        <CTMSGrantAmendmentRequestsTable
          ctmsGrantAmendmentRequestsFragment={
            organization.searchedImplementationCTMSGrantAmendmentRequests
          }
          disableRowSelection={!viewer.isSuperAdmin}
          emptyListPlaceholder="No amendments found"
          onSelectionChange={setSelectedCTMSGrantAmendmentRequestIds}
          organizationFragment={organization}
          selection={selectedCTMSGrantAmendmentRequestIds}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query Implementation_CTMSGrantAmendmentRequests_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...Implementation_CTMSGrantAmendmentRequests_Organization
    }
    me {
      ...Implementation_CTMSGrantAmendmentRequests_Account
    }
  }
`;

const AdminAmendmentRequestsImplementationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<Implementation_CTMSGrantAmendmentRequests_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Amendment Requests"
      analyticsName="Admin - Implementation Amendment Requests"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} implementation amendment requests`}
    >
      <AdminAmendmentRequestsImplementationPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminAmendmentRequestsImplementationPage;
