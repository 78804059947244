import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../../components/BackButton";
import { CountryFlag } from "../../../../../components/CountryFlag";
import { Page } from "../../../../../components/Page";
import { BreadCrumb } from "../../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../../components/ui/Layout/OneColumnLayout";
import { Typography } from "../../../../../components/ui/Typography";
import { useEquityTypeIdParam } from "../../../../../paths";
import { LegalWatchOutsEditionPage_EquityType$key } from "./__generated__/LegalWatchOutsEditionPage_EquityType.graphql";
import { LegalWatchOutsEditionPage_Query } from "./__generated__/LegalWatchOutsEditionPage_Query.graphql";
import { LegalWatchOutsEditionBlock } from "./LegalWatchOutsEditionBlock";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment LegalWatchOutsEditionPage_EquityType on EquityType {
    name
    taxResidenceCountry {
      name
      ...CountryFlag_Country
    }
    ...LegalWatchOutsEditionBlock_EquityType
  }
`;

const LegalWatchOutsEditionPage_: React.FC<{
  equityTypeFragment: LegalWatchOutsEditionPage_EquityType$key;
}> = ({ equityTypeFragment }) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);
  const equityTypeName = `${equityType.taxResidenceCountry.name} - ${equityType.name}`;

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="../..">Legal content</BreadCrumb.Link>
          <BreadCrumb.Link to="..">Legal Watch Outs</BreadCrumb.Link>
          <BreadCrumb.Link to=".">{equityTypeName}</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex items-center gap-2">
            <Typography variant="Medium/Large">
              <CountryFlag countryFragment={equityType.taxResidenceCountry} />
            </Typography>
            <Typography variant="Medium/Default">{equityTypeName}</Typography>
          </div>
        </div>
        <LegalWatchOutsEditionBlock
          equityTypeFragment={equityType}
          equityTypeWorkRelationshipCategory="DirectEmployee"
        />
        <LegalWatchOutsEditionBlock
          equityTypeFragment={equityType}
          equityTypeWorkRelationshipCategory="EoREmployee"
        />
        <LegalWatchOutsEditionBlock
          equityTypeFragment={equityType}
          equityTypeWorkRelationshipCategory="Contractor"
        />
      </div>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query LegalWatchOutsEditionPage_Query($equityTypeId: UUID!) {
    equityType(id: $equityTypeId) {
      ...LegalWatchOutsEditionPage_EquityType
    }
  }
`;

const LegalWatchOutsEditionPage: React.FC = () => {
  const equityTypeId = useEquityTypeIdParam();
  const { equityType } = useLazyLoadQuery<LegalWatchOutsEditionPage_Query>(
    QUERY,
    {
      equityTypeId,
    },
  );

  if (!equityType) throw new Error("Equity type not found");

  return (
    <Page
      analyticsName="Super Admin - Legal Watch Outs Edition"
      title={`Super admin | legal watch outs edition`}
    >
      <LegalWatchOutsEditionPage_ equityTypeFragment={equityType} />
    </Page>
  );
};

export default LegalWatchOutsEditionPage;
