/**
 * @generated SignedSource<<4b762392146b4e433a93ff6ce4c9b0b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery$variables = {
  grantAmendmentRequestsSearch?: string | null;
  id: string;
};
export type Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"Implementation_CTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery = {
  response: Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery$data;
  variables: Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "grantAmendmentRequestsSearch"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "IMPLEMENTATION"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "grantAmendmentRequestsSearch",
                "variableName": "grantAmendmentRequestsSearch"
              }
            ],
            "kind": "FragmentSpread",
            "name": "Implementation_CTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": "implementationCTMSGrantAmendmentRequests",
                "args": [
                  (v4/*: any*/)
                ],
                "concreteType": "CTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "ctmsGrantAmendmentRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
              },
              {
                "alias": "searchedImplementationCTMSGrantAmendmentRequests",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "search",
                    "variableName": "grantAmendmentRequestsSearch"
                  },
                  (v4/*: any*/)
                ],
                "concreteType": "CTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "ctmsGrantAmendmentRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUnfavorable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeletable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "ctmsGrant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workRelationship",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "taxResidenceCountry",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToCarta",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a835617b4eeb1c2f2d57aabf9106fd2b",
    "id": null,
    "metadata": {},
    "name": "Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "operationKind": "query",
    "text": "query Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery(\n  $grantAmendmentRequestsSearch: String = \"\"\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...Implementation_CTMSGrantAmendmentRequests_Organization_2kcTi6\n    id\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  id\n  status\n  isUnfavorable\n  isDeletable\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment Implementation_CTMSGrantAmendmentRequests_Organization_2kcTi6 on Organization {\n  id\n  name\n  implementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [IMPLEMENTATION]) {\n    id\n  }\n  searchedImplementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [IMPLEMENTATION], search: $grantAmendmentRequestsSearch) {\n    ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n    id\n  }\n  ...LegalWorkflowLayout_Organization\n  ...CTMSGrantAmendmentRequestsTable_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n"
  }
};
})();

(node as any).hash = "b53a912b50c508e6d6624e4c3d8fc346";

export default node;
