import { RoundedBox } from "../../../components/ui/RoundedBox";
import { SlideOver } from "../../../components/ui/SlideOver";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";

export const SetupWorkspacePricePerShareSlideOver: React.FC<{
  onClose: () => void;
  show: boolean;
}> = ({ onClose, show }) => {
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject = encodeURI(
    `How convertibles impact my price per share?`,
  );
  return (
    <SlideOver
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={show}
      width="sm"
    >
      <div className="space-y-6 px-6 pb-6">
        <div className="space-y-2">
          <Typography as="div" variant="Medium/Small">
            💡 Why do we need this price and how to calculate it?
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            We might have found at least one convertible note or SAFE in Carta
            that has not been converted yet. It might be impossible for us to
            put a $ value on your equity grants for that reason.
            <br />
            <br />
            A way to estimate your Price Per Share until your convertible
            note(s) and/or SAFE convert is to simply apply the following
            formula:
            <br />
            <br />
            PPS proxy = Post-money Valuation Cap divided by by Fully Diluted
            number of shares.
            <br />
            <br />
            Keep in mind that this will be an overestimated amount because:
            <ul className="list-inside list-disc">
              <li>
                the calculation takes into account the company&apos;s current
                (fully-diluted) number of shares, and not the one
                post-conversion of the convertibles/SAFE
              </li>
              <li>
                the calculation is based on the highest valuation cap, which is
                the highest valuation at which the convertibles/SAFE can convert
                (but they could also well convert at a lower company valuation)
              </li>
            </ul>
          </Typography>
        </div>
        <RoundedBox className="w-full !bg-primary-01 p-4 text-gray-09">
          <Typography as="div" variant="Regular/Extra Small">
            💡 If you want to simulate the conversion of these convertible notes
            and get to a priced round, feel free to{" "}
            <strong>
              <a
                className="text-primary"
                href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
              >
                contact us
              </a>
            </strong>
          </Typography>
        </RoundedBox>
      </div>
    </SlideOver>
  );
};
