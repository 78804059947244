/**
 * @generated SignedSource<<51d4b6e301e0e90984c2c8dc5c1cc0c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation$variables = {
  equityGridId: string;
};
export type EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation$data = {
  readonly toggleEquityGridActivated: {
    readonly activated: boolean;
  };
};
export type EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation = {
  response: EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation$data;
  variables: EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityGridId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityGridId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityGrid",
        "kind": "LinkedField",
        "name": "toggleEquityGridActivated",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityGrid",
        "kind": "LinkedField",
        "name": "toggleEquityGridActivated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb9f387db49738f05ef2665137c2eb9f",
    "id": null,
    "metadata": {},
    "name": "EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityGrid_ConfigureGrants_ToggleEquityGridActivated_Mutation(\n  $equityGridId: UUID!\n) {\n  toggleEquityGridActivated(id: $equityGridId) {\n    activated\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b2b4dc036609a50bec7db948735bd33";

export default node;
