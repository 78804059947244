/**
 * @generated SignedSource<<a7c3c2a5de1f61f3b5a3e21606a34a65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubplanApprovalDatePage_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly country: {
      readonly code: string;
    };
    readonly isTaxFavoredEquityTypeUnlocked: boolean;
    readonly isTaxFavoredSubplanExpired: boolean;
    readonly taxFavoredEquityTypeSubplanApprovalDate: string | null;
    readonly taxFavoredEquityTypeSubplanName: string | null;
    readonly taxFavoredSubplanExpirationDate: string | null;
  }>;
  readonly terminated: boolean;
  readonly " $fragmentType": "SubplanApprovalDatePage_Organization";
}>;
export type SubplanApprovalDatePage_Organization$key = ReadonlyArray<{
  readonly " $data"?: SubplanApprovalDatePage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubplanApprovalDatePage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SubplanApprovalDatePage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTaxFavoredEquityTypeUnlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredEquityTypeSubplanName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredEquityTypeSubplanApprovalDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTaxFavoredSubplanExpired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredSubplanExpirationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2e520d9ddad440008fffaa6ba60a0f4f";

export default node;
