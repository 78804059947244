/**
 * @generated SignedSource<<416d5aec67a75a77e9c75b7af14b53a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useStripeCustomerPortal_Mutation$variables = {
  organizationId: string;
  returnURL: string;
};
export type useStripeCustomerPortal_Mutation$data = {
  readonly generateOrganizationStripeCustomerPortalSessionURL: string;
};
export type useStripeCustomerPortal_Mutation = {
  response: useStripeCustomerPortal_Mutation$data;
  variables: useStripeCustomerPortal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnURL"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "returnUrl",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "generateOrganizationStripeCustomerPortalSessionURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useStripeCustomerPortal_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useStripeCustomerPortal_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c314f70ea3fe8c272129bceca8a6919a",
    "id": null,
    "metadata": {},
    "name": "useStripeCustomerPortal_Mutation",
    "operationKind": "mutation",
    "text": "mutation useStripeCustomerPortal_Mutation(\n  $organizationId: OrganizationId!\n  $returnURL: String!\n) {\n  generateOrganizationStripeCustomerPortalSessionURL(organizationId: $organizationId, returnUrl: $returnURL)\n}\n"
  }
};
})();

(node as any).hash = "2a79314aef4b50752fa874053ecdfbe3";

export default node;
