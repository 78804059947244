import classNames from "classnames";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { RemoteEquityPlanCountryThresholdProgressBar_Organization$key } from "./__generated__/RemoteEquityPlanCountryThresholdProgressBar_Organization.graphql";
import { Progress } from "./ui/Progress";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteEquityPlanCountryThresholdProgressBar_Organization on Organization {
    billableOrganizationGeographies {
      __typename
    }
    remoteEquityPlanCountryThreshold
  }
`;

export const RemoteEquityPlanCountryThresholdProgressBar: React.FC<{
  organizationFragment: RemoteEquityPlanCountryThresholdProgressBar_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationOverflowedCountriesThreshold = useMemo(
    () =>
      Boolean(
        organization.remoteEquityPlanCountryThreshold &&
          organization.billableOrganizationGeographies.length >
            organization.remoteEquityPlanCountryThreshold,
      ),
    [
      organization.billableOrganizationGeographies.length,
      organization.remoteEquityPlanCountryThreshold,
    ],
  );

  if (organization.remoteEquityPlanCountryThreshold === null) return null;

  return (
    <Progress
      className="h-2 bg-grey-100"
      max={organization.remoteEquityPlanCountryThreshold}
    >
      <Progress.Value
        className={classNames({
          "bg-orange-600": organizationOverflowedCountriesThreshold,
          "bg-primary": !organizationOverflowedCountriesThreshold,
        })}
        value={organization.billableOrganizationGeographies.length}
      />
    </Progress>
  );
};
