/**
 * @generated SignedSource<<1f13ac14945ba8aea8e0dc7c7036d7ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation$variables = {
  organizationId: string;
  returnURL: string;
};
export type ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation$data = {
  readonly generateOrganizationRemoteAuthorizationFlowURL: string;
};
export type ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation = {
  response: ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation$data;
  variables: ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnURL"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "returnURL",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "generateOrganizationRemoteAuthorizationFlowURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1666b47aaa2ef30dd2e071afe0279742",
    "id": null,
    "metadata": {},
    "name": "ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation",
    "operationKind": "mutation",
    "text": "mutation ConnectToRemoteButton_GenerateOrganizationRemoteAuthorizationFlowURLMutation(\n  $organizationId: OrganizationId!\n  $returnURL: String!\n) {\n  generateOrganizationRemoteAuthorizationFlowURL(organizationId: $organizationId, returnURL: $returnURL)\n}\n"
  }
};
})();

(node as any).hash = "0909d630ba253c9d79050bb77a306924";

export default node;
