/**
 * @generated SignedSource<<d0cdad7fa432892528637eeb21c55b18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useApplicationTheme_Organization$data = {
  readonly useRemoteEquityTheme: boolean;
  readonly " $fragmentType": "useApplicationTheme_Organization";
};
export type useApplicationTheme_Organization$key = {
  readonly " $data"?: useApplicationTheme_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useApplicationTheme_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useApplicationTheme_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "useRemoteEquityTheme",
      "storageKey": null
    }
  ],
  "type": "IOrganization",
  "abstractKey": "__isIOrganization"
};

(node as any).hash = "28f9f22bcb122dbe0a690d6a869d177a";

export default node;
