/**
 * @generated SignedSource<<41646127d58d5868650b2fd0622a8315>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewCTMSGrantModificationPageContent_CTMSGrant$data = {
  readonly id: string;
  readonly label: string;
  readonly " $fragmentType": "ReviewCTMSGrantModificationPageContent_CTMSGrant";
};
export type ReviewCTMSGrantModificationPageContent_CTMSGrant$key = {
  readonly " $data"?: ReviewCTMSGrantModificationPageContent_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewCTMSGrantModificationPageContent_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewCTMSGrantModificationPageContent_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "a55d9186c33e31b13fbd7f02b97da086";

export default node;
