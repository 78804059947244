import classNames from "classnames";
import { isEmpty } from "lodash";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  GranteeDetailsDraftsList_Grantee$data,
  GranteeDetailsDraftsList_Grantee$key,
} from "./__generated__/GranteeDetailsDraftsList_Grantee.graphql";
import {
  GranteeDetailsDraftsList_Organization$data,
  GranteeDetailsDraftsList_Organization$key,
} from "./__generated__/GranteeDetailsDraftsList_Organization.graphql";
import { SharesValue, SharesValueMode } from "./SharesValue";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeDetailsDraftsList_Grantee on Grantee {
    drafts: easopGrantsWithoutCtmsGrant {
      id
      label
      quantityGranted
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeDetailsDraftsList_Organization on Organization {
    id
    ...SharesValue_Organization
  }
`;

const GAP = {
  2: "gap-2",
  6: "gap-6",
};

const LinkToDraftDetails: React.FC<{
  draft: GranteeDetailsDraftsList_Grantee$data["drafts"][number];
  equityMode: SharesValueMode;
  openInNewTab?: boolean;
  organization: GranteeDetailsDraftsList_Organization$data;
  positionInList: number;
  spaceBetween: 2 | 6;
}> = ({
  draft,
  equityMode,
  openInNewTab,
  organization,
  positionInList,
  spaceBetween,
}) => {
  return (
    <Link
      className="space-y-4 py-[19px] hover:bg-gray-01"
      target={openInNewTab ? "_blank" : undefined}
      to={generatePath(
        APPLICATION_ROUTES["organizationPrepareYourGrantsDetails"],
        {
          easopGrantId: draft.id,
          organizationId: organization.id,
        },
      )}
    >
      <div className={classNames("flex items-center", GAP[spaceBetween])}>
        <Typography
          className="flex h-6 w-6 shrink-0 items-center justify-center rounded bg-gray-02"
          variant="Regular/Extra Small"
        >
          {positionInList}
        </Typography>
        <Typography
          className="min-w-[104px] shrink-0 flex-grow whitespace-nowrap text-primary"
          variant="Medium/Small"
        >
          {draft.label}
        </Typography>
        <SharesValue
          appendSharesLabel
          className="whitespace-nowrap"
          mode={equityMode}
          organizationFragment={organization}
          shares={draft.quantityGranted}
          variant="Medium/Small"
        />
      </div>
    </Link>
  );
};

export const GranteeDetailsDraftsList: React.FC<{
  equityMode: SharesValueMode;
  granteeFragment: GranteeDetailsDraftsList_Grantee$key;
  openInNewTab?: boolean;
  organizationFragment: GranteeDetailsDraftsList_Organization$key;
  spaceBetween?: 2 | 6;
  startAtPosition?: number;
}> = ({
  equityMode,
  granteeFragment,
  openInNewTab,
  organizationFragment,
  spaceBetween = 2,
  startAtPosition = 1,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (isEmpty(grantee.drafts)) {
    return null;
  }

  return (
    <div className="flex flex-col divide-y-[0.5px] divide-gray-04">
      {grantee.drafts.map((draft, index) => (
        <LinkToDraftDetails
          draft={draft}
          equityMode={equityMode}
          key={draft.id}
          openInNewTab={openInNewTab}
          organization={organization}
          positionInList={index + startAtPosition}
          spaceBetween={spaceBetween}
        />
      ))}
    </div>
  );
};
