/**
 * @generated SignedSource<<cba6cfa24abab959c3798c0a13b9c21c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminEntitiesOverview_Organization$data = {
  readonly activeCTMSGrantCount: number;
  readonly activeGeographyCount: number;
  readonly draftEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly granteeCountGroupedByStatus: {
    readonly Active: number;
  };
  readonly id: string;
  readonly pendingBoardConsentEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly pendingCtmsImplementationEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly pendingReviewEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly reviewedEasopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly " $fragmentType": "AdminEntitiesOverview_Organization";
};
export type AdminEntitiesOverview_Organization$key = {
  readonly " $data"?: AdminEntitiesOverview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminEntitiesOverview_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminEntitiesOverview_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "activeCTMSGrantCount",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatus",
          "value": [
            "Active"
          ]
        }
      ],
      "kind": "ScalarField",
      "name": "ctmsGrantCount",
      "storageKey": "ctmsGrantCount(grantStatus:[\"Active\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeCountGroupedByStatusResult",
      "kind": "LinkedField",
      "name": "granteeCountGroupedByStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "Active",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "draftEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Draft"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Draft\"]})"
    },
    {
      "alias": "pendingReviewEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingReview"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingReview\"]})"
    },
    {
      "alias": "reviewedEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Reviewed"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Reviewed\"]})"
    },
    {
      "alias": "pendingBoardConsentEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingBoardConsent"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingBoardConsent\"]})"
    },
    {
      "alias": "pendingCtmsImplementationEasopGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingCtmsImplementation"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingCtmsImplementation\"]})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activeGeographyCount",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "e876236e76e40a0bca111fb8c08313d8";

export default node;
