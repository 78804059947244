import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { graphql } from "relay-runtime";

import { Button } from "../../../../../components/ui/Button";
import { SlideOver } from "../../../../../components/ui/SlideOver";
import { makeRemoteController } from "../../../../../helpers/makeRemoteController";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import {
  CreateSlideOver_Mutation,
  CreateSlideOver_Mutation$data,
} from "./__generated__/CreateSlideOver_Mutation.graphql";
import { CreateSlideOver_Query } from "./__generated__/CreateSlideOver_Query.graphql";
import { FormValues, useFormSchema } from "./FORM_SCHEMA";
import { PostTerminationForm } from "./PostTerminationForm";

const QUERY = graphql`
  query CreateSlideOver_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      hasCooleyAsOutsideCounsel
      termOfOptionsInMonths
      ...PostTerminationForm_Organization
    }
  }
`;

const CREATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION = graphql`
  mutation CreateSlideOver_Mutation(
    $input: CreateOrganizationPostTerminationExercisePeriodInput!
  ) {
    createOrganizationPostTerminationExercisePeriod(input: $input) {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
        displayName
        duration
        durationUnit
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
        displayName
        duration
        durationUnit
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
  }
`;

export type CreateSlideOverOrganizationFixedPostTerminationExercisePeriod =
  OrganizationFixedPostTerminationExercisePeriod;
type OrganizationFixedPostTerminationExercisePeriod =
  CreateSlideOver_Mutation$data["createOrganizationPostTerminationExercisePeriod"] & {
    __typename:
      | "OrganizationFixedPostTerminationExercisePeriod"
      | "OrganizationVariablePostTerminationExercisePeriod";
  };

function CreateSlideOver({
  doNotAllowVariablePTEP,
  onClose,
  organizationId,
  show,
}: {
  doNotAllowVariablePTEP?: boolean;
  onClose: (props: {
    createdOrganizationPostTerminationExercisePeriod?: OrganizationFixedPostTerminationExercisePeriod;
  }) => void;
  organizationId: string;
  show: boolean;
}) {
  const { query } = useQuery<CreateSlideOver_Query>(QUERY, {
    organizationId,
  });

  const [createOrganizationPostTerminationExercisePeriod] =
    useSafeMutation<CreateSlideOver_Mutation>(
      CREATE_ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_MUTATION,
    );

  const form = useForm<FormValues>({
    defaultValues: {
      type: "FIXED",
    },
    resolver: zodResolver(
      useFormSchema({
        organizationHasCooleyAsOutsideCounsel:
          query.organization.hasCooleyAsOutsideCounsel,
        organizationTermOfOptionsInMonths:
          query.organization.termOfOptionsInMonths,
      }),
    ),
  });

  const handleSubmit = form.handleSubmit(async (values) => {
    const {
      createOrganizationPostTerminationExercisePeriod:
        organizationPostTerminationExercisePeriod,
    } = await createOrganizationPostTerminationExercisePeriod({
      variables: {
        input: {
          organizationId,
          ...values,
        },
      },
    });

    if (organizationPostTerminationExercisePeriod.__typename === "%other") {
      throw new Error("Invalid post termination exercise period");
    }

    onClose({
      createdOrganizationPostTerminationExercisePeriod:
        organizationPostTerminationExercisePeriod,
    });
  });

  return (
    <SlideOver
      floating
      footer={
        <div className="flex items-center justify-end gap-2 p-6">
          <Button
            disabled={form.formState.isSubmitting}
            onClick={() => onClose({})}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="create-post-termination-exercise-period-form"
            loading={form.formState.isSubmitting}
            size="small"
            type="submit"
          >
            Create
          </Button>
        </div>
      }
      header={
        <SlideOver.Header
          onClose={() => onClose({})}
          padding={10}
          subtitle="Define the post-termination exercise period parameters below"
        >
          Create Post-Termination Exercise Period
        </SlideOver.Header>
      }
      onClose={() => onClose({})}
      show={show}
      width="4xl"
    >
      <div className="px-10 py-16">
        <PostTerminationForm
          control={form.control}
          doNotAllowVariablePTEP={doNotAllowVariablePTEP}
          id="create-post-termination-exercise-period-form"
          onSubmit={handleSubmit}
          organizationFragment={query.organization}
        />
      </div>
    </SlideOver>
  );
}

export const CreateSlideOverRemote = makeRemoteController<
  {
    doNotAllowVariablePTEP?: boolean;
    organizationId: string;
  },
  {
    createdOrganizationPostTerminationExercisePeriod?: OrganizationFixedPostTerminationExercisePeriod;
  }
>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <CreateSlideOver
        doNotAllowVariablePTEP={state.data.doNotAllowVariablePTEP}
        onClose={close}
        organizationId={state.data.organizationId}
        show={state.show}
      />
    );
  },
});
