/**
 * @generated SignedSource<<3b01242b9aa6dbdba65979497098e2f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoSlideOver_Viewer$data = {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentType": "BookADemoSlideOver_Viewer";
};
export type BookADemoSlideOver_Viewer$key = {
  readonly " $data"?: BookADemoSlideOver_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoSlideOver_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoSlideOver_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "873cb013093db1e6df79db16b06fe981";

export default node;
