import { zodResolver } from "@hookform/resolvers/zod";
import { sortBy } from "lodash";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { zodExtra } from "../helpers/zod-extra";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../services/workRelationship";
import {
  CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation,
  EquityTypeWorkRelationshipCategory,
} from "./__generated__/CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation.graphql";
import { CreateOngoingObligationSlideOver_EquityType$key } from "./__generated__/CreateOngoingObligationSlideOver_EquityType.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { MultiSelectButtonGroup } from "./ui/Form/ButtonGroup";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { Input } from "./ui/Form/Inputs/Input";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Toggle } from "./ui/Form/Toggle";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";

const MUTATION = graphql`
  mutation CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation(
    $date: Date!
    $equityTypeId: UUID!
    $equityTypeWorkRelationshipCategories: [EquityTypeWorkRelationshipCategory!]!
    $label: String!
    $requiresLastYearGrantActivity: Boolean!
  ) {
    createOngoingObligation(
      date: $date
      equityTypeId: $equityTypeId
      equityTypeWorkRelationshipCategories: $equityTypeWorkRelationshipCategories
      label: $label
      requiresLastYearGrantActivity: $requiresLastYearGrantActivity
    ) {
      ...OngoingObligationsPage_OngoingObligation
    }
  }
`;

const EQUITY_TYPES_FRAGMENT = graphql`
  fragment CreateOngoingObligationSlideOver_EquityType on EquityType
  @relay(plural: true) {
    id
    name
    taxResidenceCountry {
      emoji
      name
    }
  }
`;

const SCHEMA = z.object({
  date: z.string(),
  equityTypeId: z.string(),
  equityTypeWorkRelationshipCategories: z
    .array(zodExtra.equityTypeWorkRelationshipCategory())
    .min(1),
  label: z.string().trim().min(1),
  sendReminderEveryYear: z.boolean(),
});

export type FormInputs = z.infer<typeof SCHEMA>;

export const CreateOngoingObligationSlideOver: React.FC<{
  equityTypesFragment: CreateOngoingObligationSlideOver_EquityType$key;
  onClose: () => void;
  onOngoingObligationCreated: () => void;
  show: boolean;
}> = ({ equityTypesFragment, onClose, onOngoingObligationCreated, show }) => {
  const equityTypes = useFragment(EQUITY_TYPES_FRAGMENT, equityTypesFragment);

  const [createOngoingObligation, mutationIsInFlight] =
    useSafeMutation<CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation>(
      MUTATION,
    );

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      date: null,
      equityTypeId: null,
      equityTypeWorkRelationshipCategories:
        [] as EquityTypeWorkRelationshipCategory[],
      label: null,
      sendReminderEveryYear: false,
    },
    resolver: zodResolver(SCHEMA),
  });

  const [equityTypeWorkRelationshipCategories] = watch([
    "equityTypeWorkRelationshipCategories",
  ]);

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (_formInputs: unknown) => {
    const { sendReminderEveryYear, ...formInputs } = _formInputs as FormInputs;
    await createOngoingObligation({
      variables: {
        ...formInputs,
        requiresLastYearGrantActivity: !sendReminderEveryYear,
      },
    });
    toaster.push(
      <Toast title="Nice!">Ongoing obligation successfully created!</Toast>,
    );
    onOngoingObligationCreated();
    onClose();
    reset();
  });

  const sortedEquityTypes = useMemo(
    () =>
      sortBy(equityTypes, (equityType) => equityType.taxResidenceCountry.name),
    [equityTypes],
  );

  const equityTypeWorkRelationshipCategoriesOptions = useMemo(
    () =>
      [
        {
          label: EQUITY_TYPE_WORK_RELATIONSHIP_MAP["directEmployee"].label,
          value: "DirectEmployee",
        },
        {
          label: EQUITY_TYPE_WORK_RELATIONSHIP_MAP["eoREmployee"].label,
          value: "EoREmployee",
        },
        {
          label: EQUITY_TYPE_WORK_RELATIONSHIP_MAP["contractor"].label,
          value: "Contractor",
        },
      ] as const,
    [],
  );

  const intl = useIntl();

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose}>
          Create a new ongoing obligation
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <form className="space-y-4 px-6 py-4" onSubmit={onSubmit}>
        <Controller
          control={control}
          name="equityTypeId"
          render={({ field, fieldState }) => (
            <FormRow error={fieldState.error?.message} label="Equity type">
              <SelectAutocomplete
                getOptionLabel={(equityType) =>
                  `${equityType.taxResidenceCountry.emoji} ${equityType.taxResidenceCountry.name} - ${equityType.name}`
                }
                getOptionValue={(equityType) => equityType.id}
                onChange={(equityType) => {
                  field.onChange(equityType?.id);
                }}
                options={sortedEquityTypes}
                value={equityTypes.find(
                  (equityType) => equityType.id === field.value,
                )}
              />
            </FormRow>
          )}
        />
        <FormRow
          error={errors.equityTypeWorkRelationshipCategories?.message}
          label="Work relationships"
        >
          <Controller
            control={control}
            name="equityTypeWorkRelationshipCategories"
            render={({ field }) => (
              <MultiSelectButtonGroup
                onChange={(value) => {
                  field.onChange(value);
                }}
                options={equityTypeWorkRelationshipCategoriesOptions}
                value={equityTypeWorkRelationshipCategories}
              />
            )}
          />
        </FormRow>
        <FormRow error={errors.label?.message} label="Label">
          <Input
            {...control.register("label")}
            placeholder="Enter a description for this obligation..."
            type="text"
          />
        </FormRow>
        <Controller
          control={control}
          name="date"
          render={({ field, fieldState }) => (
            <FormRow error={fieldState.error?.message} label="Date">
              <DatePicker
                className="w-full"
                onChange={field.onChange}
                panelPosition="bottom"
                placeholder={`E.g. ${intl.formatDate(new Date(), {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })}`}
                value={field.value}
              />
            </FormRow>
          )}
        />
        <FormRow.Form
          control={control}
          label="Send reminder every year, even if no equity granted"
          name="sendReminderEveryYear"
        >
          <Controller
            control={control}
            name="sendReminderEveryYear"
            render={({ field }) => (
              <Toggle enabled={field.value} onChange={field.onChange} />
            )}
          />
        </FormRow.Form>
        <div className="flex flex-row-reverse gap-4">
          <Button loading={mutationIsInFlight} size="small" type="submit">
            Create
          </Button>
          <Button
            onClick={onClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
        </div>
      </form>
    </SlideOver>
  );
};
