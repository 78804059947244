import { isEmpty } from "lodash";
import React, {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";

import { useAlerter } from "../../../components/Alerter";
import { ConfirmSubmitBoardConsentSlide } from "../../../components/ConfirmSubmitBoardConsentSlide/ConfirmSubmitBoardConsentSlide";
import {
  CTMSGrantAmendmentRequestDeletionConfirmationModal,
  useCTMSGrantAmendmentRequestDeletionConfirmationModalState,
} from "../../../components/CTMSGrantAmendmentRequestDeletionConfirmationModal";
import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SelectedItemsActionCard } from "../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../components/Toaster";
import { Alert } from "../../../components/ui/Alert";
import { Button } from "../../../components/ui/Button";
import { Toast } from "../../../components/ui/Toast";
import { useBoolean } from "../../../hooks/useBoolean";
import { useDebounced } from "../../../hooks/useDebounced";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { BoardApproval_CTMSGrantAmendmentRequests_Organization$key } from "./__generated__/BoardApproval_CTMSGrantAmendmentRequests_Organization.graphql";
import { BoardApproval_CTMSGrantAmendmentRequests_Query } from "./__generated__/BoardApproval_CTMSGrantAmendmentRequests_Query.graphql";
import { BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation } from "./__generated__/BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation.graphql";
import { BoardApproval_CTMSGrantAmendmentRequests_Viewer$key } from "./__generated__/BoardApproval_CTMSGrantAmendmentRequests_Viewer.graphql";
import { CTMSGrantAmendmentRequestsTable } from "./CTMSGrantAmendmentRequestsTable";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardApproval_CTMSGrantAmendmentRequests_Organization on Organization
  @argumentDefinitions(
    grantAmendmentRequestsSearch: { type: "String", defaultValue: "" }
  )
  @refetchable(
    queryName: "BoardApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery"
  ) {
    id
    name
    searchedReadyForConsentAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
      search: $grantAmendmentRequestsSearch
    ) {
      id
      ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest
    }
    readyForConsentAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      id
      isUnfavorable
      ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest
    }
    ...ConfirmSubmitBoardConsentSlide_Organization
    ...LegalWorkflowLayout_Organization
    ...CTMSGrantAmendmentRequestsTable_Organization
    ...useOrganizationCTMS_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment BoardApproval_CTMSGrantAmendmentRequests_Viewer on Account {
    isSuperAdmin
  }
`;

const SEND_GRANT_AMENDMENT_REQUESTS_TO_BOARD_FOR_CONSENT_REQUESTS_MUTATION = graphql`
  mutation BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation(
    $ctmsGrantAmendmentRequestIds: [UUID!]!
    $boardConsentHandledOutsideEasop: Boolean!
    $boardConsentSignatureDate: Date
    $signedBoardConsentDocumentIds: [String!]
  ) {
    sendCTMSGrantAmendmentRequestsToBoardForConsent(
      ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds
      boardConsentHandledOutsideEasop: $boardConsentHandledOutsideEasop
      boardConsentSignatureDate: $boardConsentSignatureDate
      signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds
    ) {
      __typename
    }
  }
`;

const AdminAmendmentRequestsBoardApprovalPage_: React.FC<{
  organizationFragment: BoardApproval_CTMSGrantAmendmentRequests_Organization$key;
  viewerFragment: BoardApproval_CTMSGrantAmendmentRequests_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const [
    selectedCTMSGrantAmendmentRequestIds,
    setSelectedCTMSGrantAmendmentRequestIds,
  ] = useState<Set<string>>(new Set());

  const someSelectedCTMSGrantAmendmentRequestsAreUnfavorable = useMemo(() => {
    const selectedCTMSGrantAmendmentRequests =
      organization.readyForConsentAmendmentRequests.filter(
        (ctmsGrantAmendmentRequest) =>
          selectedCTMSGrantAmendmentRequestIds.has(
            ctmsGrantAmendmentRequest.id,
          ),
      );
    return selectedCTMSGrantAmendmentRequests.some(
      (ctmsGrantAmendmentRequest) => ctmsGrantAmendmentRequest.isUnfavorable,
    );
  }, [
    selectedCTMSGrantAmendmentRequestIds,
    organization.readyForConsentAmendmentRequests,
  ]);

  const [
    sendGrantAmendmentRequestsToBoardForConsent,
    sendGrantAmendmentRequestsToBoardForConsentMutationIsInFLight,
  ] =
    useSafeMutation<BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation>(
      SEND_GRANT_AMENDMENT_REQUESTS_TO_BOARD_FOR_CONSENT_REQUESTS_MUTATION,
    );

  const toaster = useToaster();

  const {
    setFalse: closeConfirmSubmitBoardConsentSlide,
    setTrue: _openConfirmSubmitBoardConsentSlide,
    value: isConfirmSubmitBoardConsentSlideOpen,
  } = useBoolean(false);

  const alerter = useAlerter();

  const mailtoSubjectUnfavorableAmendment = encodeURI(
    `[${organization.name}] How can I submit unfavorable amendments?`,
  );

  const openConfirmSubmitBoardConsentSlide = useCallback(() => {
    if (someSelectedCTMSGrantAmendmentRequestsAreUnfavorable) {
      alerter.push(
        <Alert
          buttonLabel={
            viewer.isSuperAdmin ? "Proceed as super admin" : undefined
          }
          onCloseButtonClick={() => {
            if (viewer.isSuperAdmin) _openConfirmSubmitBoardConsentSlide();
          }}
          title={<>Grant amendments appear to be unfavorable to the grantee.</>}
        >
          Unfavorable amendments follow a specific process requiring the grantee
          to approve the amendment to their grant. Please{" "}
          <a
            className="font-medium text-primary"
            href={`mailto:support@easop.com?subject=${mailtoSubjectUnfavorableAmendment}`}
          >
            contact us
          </a>{" "}
          to start that process.
        </Alert>,
      );
      return;
    }

    _openConfirmSubmitBoardConsentSlide();
  }, [
    _openConfirmSubmitBoardConsentSlide,
    alerter,
    someSelectedCTMSGrantAmendmentRequestsAreUnfavorable,
    mailtoSubjectUnfavorableAmendment,
    viewer,
  ]);

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            grantAmendmentRequestsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        grantAmendmentRequestsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleConfirmSubmitBoardConsentSlideSubmit = useCallback(
    async ({
      boardConsentHandledOutsideEasop,
      boardConsentSignatureDate,
      signedBoardConsentDocumentIds,
    }: {
      boardConsentHandledOutsideEasop: boolean;
      boardConsentSignatureDate?: null | string;
      signedBoardConsentDocumentIds?: null | string[];
    }) => {
      await sendGrantAmendmentRequestsToBoardForConsent({
        variables: {
          boardConsentHandledOutsideEasop,
          boardConsentSignatureDate: boardConsentHandledOutsideEasop
            ? boardConsentSignatureDate
            : null,
          ctmsGrantAmendmentRequestIds: [
            ...selectedCTMSGrantAmendmentRequestIds,
          ],
          signedBoardConsentDocumentIds: boardConsentHandledOutsideEasop
            ? signedBoardConsentDocumentIds
            : null,
        },
      });

      closeConfirmSubmitBoardConsentSlide();

      handleDataUpdated();

      setSelectedCTMSGrantAmendmentRequestIds(new Set());

      if (boardConsentHandledOutsideEasop) {
        toaster.push(
          <Toast title="Wonderful!">
            <FormattedMessage
              defaultMessage={`{grantCount, plural,
                one {# grant amendment}
                other {# grant amendments}
              } have been successfully sent for implementation in {ctmsName}`}
              values={{
                ctmsName: organizationCTMS?.name ?? "CTMS",
                grantCount: selectedCTMSGrantAmendmentRequestIds.size,
              }}
            />
          </Toast>,
        );
      } else {
        toaster.push(
          <Toast title="Wonderful!">
            <FormattedMessage
              defaultMessage={`{grantCount, plural,
                one {# amendment request}
                other {# amendment requests}
              } successfully sent to board for consent!`}
              values={{
                grantCount: selectedCTMSGrantAmendmentRequestIds.size,
              }}
            />
          </Toast>,
        );
      }
    },
    [
      sendGrantAmendmentRequestsToBoardForConsent,
      selectedCTMSGrantAmendmentRequestIds,
      closeConfirmSubmitBoardConsentSlide,
      handleDataUpdated,
      toaster,
      organizationCTMS,
    ],
  );

  const {
    closeModal: closeDeleteConfirmationModal,
    showModal: openDeleteConfirmationModal,
    state: deleteConfirmationModalState,
  } = useCTMSGrantAmendmentRequestDeletionConfirmationModalState();

  const onDeletionCompleted = () => {
    setSelectedCTMSGrantAmendmentRequestIds(new Set());
    startTransition(() => {
      refetchOrganization({});
    });
  };

  return (
    <LegalWorkflowLayout
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search amendments..."
      searchValue={searchInputValue}
      subtitle="Grant amendments ready to be sent to your board"
      title="Board approval"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        return (
          <SelectedItemsActionCard
            actions={
              <>
                <Button
                  className="whitespace-nowrap"
                  disabled={selectedCTMSGrantAmendmentRequestIds.size === 0}
                  fullWidth
                  onClick={() =>
                    openDeleteConfirmationModal([
                      ...selectedCTMSGrantAmendmentRequestIds,
                    ])
                  }
                  size="small"
                  variant="Danger Outline"
                >
                  Delete
                </Button>
                <Button
                  disabled={selectedCTMSGrantAmendmentRequestIds.size === 0}
                  fullWidth
                  loading={
                    sendGrantAmendmentRequestsToBoardForConsentMutationIsInFLight
                  }
                  onClick={openConfirmSubmitBoardConsentSlide}
                  size="small"
                  variant="Approve Light"
                >
                  Proceed
                </Button>
              </>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select amendments"
            itemCount={selectedCTMSGrantAmendmentRequestIds.size}
            loading={
              sendGrantAmendmentRequestsToBoardForConsentMutationIsInFLight
            }
            pluralLabel="amendments selected"
            singularLabel="amendment selected"
          />
        );
      }}
    >
      <CTMSGrantAmendmentRequestDeletionConfirmationModal
        onClose={closeDeleteConfirmationModal}
        onDeletionCompleted={onDeletionCompleted}
        state={deleteConfirmationModalState}
      />
      <ConfirmSubmitBoardConsentSlide
        ctmsGrantAmendmentRequestsIds={[
          ...selectedCTMSGrantAmendmentRequestIds,
        ]}
        onClose={closeConfirmSubmitBoardConsentSlide}
        onSubmit={handleConfirmSubmitBoardConsentSlideSubmit}
        open={isConfirmSubmitBoardConsentSlideOpen}
        organizationFragment={organization}
        selectedItemsCount={selectedCTMSGrantAmendmentRequestIds.size}
        submissionInProgress={
          sendGrantAmendmentRequestsToBoardForConsentMutationIsInFLight
        }
        type="GRANT_AMENDMENT_REQUEST"
      />

      {isEmpty(organization.readyForConsentAmendmentRequests) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle="You will see items here if you initiate grant amendments"
          title="No grant amendment request ready for consent"
        />
      ) : (
        <CTMSGrantAmendmentRequestsTable
          ctmsGrantAmendmentRequestsFragment={
            organization.searchedReadyForConsentAmendmentRequests
          }
          emptyListPlaceholder="No amendments found"
          onDeleteGrantAmendmentRequestClick={(grantAmendmentRequestId) =>
            openDeleteConfirmationModal([grantAmendmentRequestId])
          }
          onSelectionChange={setSelectedCTMSGrantAmendmentRequestIds}
          organizationFragment={organization}
          selection={selectedCTMSGrantAmendmentRequestIds}
          showActionsColumn
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query BoardApproval_CTMSGrantAmendmentRequests_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...BoardApproval_CTMSGrantAmendmentRequests_Organization
    }
    me {
      ...BoardApproval_CTMSGrantAmendmentRequests_Viewer
    }
  }
`;

const AdminAmendmentRequestsBoardApprovalPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<BoardApproval_CTMSGrantAmendmentRequests_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Amendment Requests"
      analyticsName="Admin - Board Approval Amendment Requests"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} board approval amendment requests`}
    >
      <AdminAmendmentRequestsBoardApprovalPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default AdminAmendmentRequestsBoardApprovalPage;
