import classNames from "classnames";
import { ReactNode } from "react";

import { Typography } from "./Typography";

const STYLE = {
  orange: "bg-orange-01",
  red: "bg-red-01",
} as const;

export const About: React.FC<{
  children: ReactNode;
  title: string;
  variant?: keyof typeof STYLE;
}> = ({ children, title, variant = "orange" }) => {
  return (
    <div className={classNames("space-y-2 p-6", STYLE[variant])}>
      <Typography
        as="div"
        className="text-gray-09"
        variant="Semibold/Extra Small"
      >
        {title}
      </Typography>
      <Typography as="div" className="text-black-05" variant="Regular/Small">
        {children}
      </Typography>
    </div>
  );
};
