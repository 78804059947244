import { useCallback, useMemo } from "react";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LoadMoreButton } from "../../../../components/ui/LoadMoreButton";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { OrderBy, Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import {
  OrganizationGranteesFilters,
  OrganizationGranteesFiltersOrderByField,
} from "../__generated__/Grantees_GranteesPagination_Query.graphql";
import GranteesFilters from "../GranteesFilters";
import { OrganizationGranteesTable_Grantees$key } from "./__generated__/OrganizationGranteesTable_Grantees.graphql";
import {
  OrganizationGranteesTable_Organization$data,
  OrganizationGranteesTable_Organization$key,
} from "./__generated__/OrganizationGranteesTable_Organization.graphql";
import { OrganizationGranteesTable_Viewer$key } from "./__generated__/OrganizationGranteesTable_Viewer.graphql";
import { GranteesTableRow } from "./GranteesTableRow";

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationGranteesTable_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GranteesTableRow_Viewer
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationGranteesTable_Organization on Organization {
    equityGrid {
      activated
    }
    ...GranteesTableRow_Organization
    ...GranteesFilters_Organization
  }
`;

const GRANTEES_FRAGMENT = graphql`
  fragment OrganizationGranteesTable_Grantees on Grantee
  @argumentDefinitions(organizationId: { type: "OrganizationId!" })
  @relay(plural: true) {
    id
    ...GranteesTableRow_Grantee @arguments(organizationId: $organizationId)
  }
`;

export type GranteesTableColumn =
  | "Action"
  | "Avatar"
  | "Equity Grid Level"
  | "Exercised"
  | "Grants"
  | "Name"
  | "Ownership"
  | "Portal Access"
  | "Position"
  | "Status"
  | "Tenure"
  | "Vested";

const ACTIVE_COLUMNS: GranteesTableColumn[] = [
  "Avatar",
  "Name",
  "Ownership",
  "Vested",
  "Grants",
  "Tenure",
  "Equity Grid Level",
  "Position",
  "Portal Access",
  "Action",
];

const TERMINATED_COLUMNS: GranteesTableColumn[] = [
  "Avatar",
  "Name",
  "Status",
  "Exercised",
  "Ownership",
  "Tenure",
  "Equity Grid Level",
  "Position",
  "Portal Access",
  "Action",
];

const COLUMNS_WIDTH: Record<GranteesTableColumn, string> = {
  Action: "auto",
  Avatar: "auto",
  "Equity Grid Level": "auto",
  Exercised: "184px",
  Grants: "auto",
  Name: "152px",
  Ownership: "152px",
  "Portal Access": "minmax(112px,auto)",
  Position: "minmax(128px,1fr)",
  Status: "128px",
  Tenure: "auto",
  Vested: "184px",
};

const HeaderColumn: React.FC<{
  column: GranteesTableColumn;
  filters: OrganizationGranteesFilters;
  handleHeaderCellOrderByChange: (
    orderBy: null | OrderBy<OrganizationGranteesFiltersOrderByField>,
  ) => void;
}> = ({ column, filters, handleHeaderCellOrderByChange }) => {
  switch (column) {
    case "Action":
      return <Table.HeaderCell />;
    case "Avatar":
      return <Table.HeaderCell></Table.HeaderCell>;
    case "Equity Grid Level":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="equityGridLevel"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Level
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Exercised":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          alignRight
          field="quantityExercisedRatio"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Exercised
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Grants":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="grantsCount"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Grants
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Name":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="name"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Name
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Ownership":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          alignRight
          field="quantityGranted"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Total ownership
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Portal Access":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          alignRight
          className="whitespace-nowrap"
          field="portalAccess"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Portal status
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Position":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="jobTitle"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Position
        </Table.SortableHeaderCellForPaginatedTable>
      );

    case "Status":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="settled"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Grantee status
        </Table.SortableHeaderCellForPaginatedTable>
      );
    case "Tenure":
      return <Table.HeaderCell>Tenure</Table.HeaderCell>;
    case "Vested":
      return (
        <Table.SortableHeaderCellForPaginatedTable
          field="quantityVestedRatio"
          onOrderByChange={handleHeaderCellOrderByChange}
          orderBy={filters.orderBy}
        >
          Vested
        </Table.SortableHeaderCellForPaginatedTable>
      );
  }
};

export const OrganizationGranteesTable_: React.FC<{
  filters: OrganizationGranteesFilters;
  granteesFragment: OrganizationGranteesTable_Grantees$key;
  granteesRelayConnectionIds: string[];
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
  onFiltersChange: (filters: OrganizationGranteesFilters) => void;
  organization: OrganizationGranteesTable_Organization$data;
  totalCount: number;
  viewerFragment: OrganizationGranteesTable_Viewer$key;
}> = ({
  filters,
  granteesFragment,
  granteesRelayConnectionIds,
  hasNext,
  isLoadingNext,
  loadNext,
  onFiltersChange,
  organization,
  totalCount,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const grantees = useFragment(GRANTEES_FRAGMENT, granteesFragment);

  const columns = useMemo(() => {
    const columns =
      filters.status === "Terminated" ? TERMINATED_COLUMNS : ACTIVE_COLUMNS;
    return columns.filter((column) => {
      switch (column) {
        case "Action":
          return viewer.isAllowedToManageOrganization;
        case "Equity Grid Level":
          return organization.equityGrid.activated;
        default:
          return true;
      }
    });
  }, [
    filters.status,
    organization.equityGrid.activated,
    viewer.isAllowedToManageOrganization,
  ]);

  const gridTemplateColumns = useMemo(() => {
    return columns.map((column) => COLUMNS_WIDTH[column]).join(" ");
  }, [columns]);

  const handleHeaderCellOrderByChange = useCallback(
    (orderBy: null | OrderBy<OrganizationGranteesFiltersOrderByField>) => {
      onFiltersChange({
        ...filters,
        orderBy,
      });
    },
    [filters, onFiltersChange],
  );

  if (totalCount === 0) {
    return (
      <NoticeMessage size="Small">
        No grantees match your current filters.
      </NoticeMessage>
    );
  }

  return (
    <>
      <Table.Containerized
        className="grid gap-x-4"
        display="grid"
        noXPaddingInCells
        style={{
          gridTemplateColumns,
        }}
      >
        <Table.Header className="border-b-[0.5px] border-gray-04">
          <Table.Row>
            {columns.map((column) => (
              <HeaderColumn
                column={column}
                filters={filters}
                handleHeaderCellOrderByChange={handleHeaderCellOrderByChange}
                key={column}
              />
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {grantees.map((grantee) => {
            return (
              <GranteesTableRow
                columns={columns}
                granteeFragment={grantee}
                granteesRelayConnectionIds={granteesRelayConnectionIds}
                key={grantee.id}
                organizationFragment={organization}
                viewerFragment={viewer}
              />
            );
          })}
        </Table.Body>
      </Table.Containerized>
      {hasNext && (
        <div className="flex justify-center">
          <LoadMoreButton
            loading={isLoadingNext}
            onLoadMoreRequest={loadNext}
          />
        </div>
      )}
    </>
  );
};

export const OrganizationGranteesTable: React.FC<
  Omit<
    React.ComponentProps<typeof OrganizationGranteesTable_>,
    "organization"
  > & {
    organizationFragment: OrganizationGranteesTable_Organization$key;
  }
> = ({ filters, onFiltersChange, organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="space-y-10">
      <div className="space-y-4">
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Overview of all your active grantees and their ownership
        </Typography>
        <GranteesFilters
          filters={filters}
          onFiltersChange={onFiltersChange}
          organizationFragment={organization}
        />
      </div>
      <OrganizationGranteesTable_
        filters={filters}
        onFiltersChange={onFiltersChange}
        organization={organization}
        {...props}
      />
    </div>
  );
};
