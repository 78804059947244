/**
 * @generated SignedSource<<bd3a05697687e1f7bd6184e6e3defaa6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation$variables = {
  documentTemplateId: string;
  needsBoardApproval: boolean;
};
export type DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation$data = {
  readonly setDocumentTemplateNeedsBoardApproval: {
    readonly " $fragmentSpreads": FragmentRefs<"DocumentsApprovalPage_DocumentTemplate">;
  };
};
export type DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation = {
  response: DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation$data;
  variables: DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "documentTemplateId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "needsBoardApproval"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "documentTemplateId",
    "variableName": "documentTemplateId"
  },
  {
    "kind": "Variable",
    "name": "needsBoardApproval",
    "variableName": "needsBoardApproval"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentTemplate",
        "kind": "LinkedField",
        "name": "setDocumentTemplateNeedsBoardApproval",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentsApprovalPage_DocumentTemplate"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DocumentTemplate",
        "kind": "LinkedField",
        "name": "setDocumentTemplateNeedsBoardApproval",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "needsBoardApproval",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contentUsedForApproval",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e22692ffaea8c793cf985344b7a9a47",
    "id": null,
    "metadata": {},
    "name": "DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation",
    "operationKind": "mutation",
    "text": "mutation DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation(\n  $documentTemplateId: UUID!\n  $needsBoardApproval: Boolean!\n) {\n  setDocumentTemplateNeedsBoardApproval(documentTemplateId: $documentTemplateId, needsBoardApproval: $needsBoardApproval) {\n    ...DocumentsApprovalPage_DocumentTemplate\n    id\n  }\n}\n\nfragment DocumentsApprovalPage_DocumentTemplate on DocumentTemplate {\n  id\n  name\n  type\n  needsBoardApproval\n  content\n  contentUsedForApproval\n}\n"
  }
};
})();

(node as any).hash = "3b01ae3facff8c876697a8e1bd953c32";

export default node;
