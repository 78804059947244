import { zodResolver } from "@hookform/resolvers/zod";
import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../components/Page";
import { Button, LinkButton } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { PasswordInput } from "../../components/ui/PasswordInput";
import { PoweredByCarta } from "../../components/ui/PoweredByCarta/PoweredByCarta";
import { useApplicationSupportEmailAddress } from "../../hooks/useApplicationTheme";
import { useBoolean } from "../../hooks/useBoolean";
import { useQuery } from "../../hooks/useQuery";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { useStateToken } from "../../layouts/SetupWorkspaceLayout/useSetupWorkspaceContext";
import { APPLICATION_ROUTES } from "../../paths";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";
import { useAuthentication } from "../../services/AuthenticationProvider";
import {
  PasswordStrengthInfoCard,
  passwordValidationSchema,
  validatePasswordStrength,
} from "../Auth/PasswordStrength";
import { AccountCreationPage_Query } from "./__generated__/AccountCreationPage_Query.graphql";

const QUERY = graphql`
  query AccountCreationPage_Query($workspaceSetupStateToken: String!) {
    user: getCartaUserUsingWorkspaceSetupStateToken(
      workspaceSetupStateToken: $workspaceSetupStateToken
    ) {
      firstName
      lastName
      email
    }
  }
`;

const formSchema = z
  .object({
    email: z.string().trim().email(),
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    password: passwordValidationSchema(),
    passwordConfirmation: z.string(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (passwordConfirmation !== password) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "The passwords did not match",
        path: ["passwordConfirmation"],
      });
    }
  });

type FormInputs = z.infer<typeof formSchema>;

const AccountCreationPage_: React.FC = () => {
  const stateToken = useStateToken();
  const [searchParams] = useSearchParams();
  const { query } = useQuery<AccountCreationPage_Query>(QUERY, {
    workspaceSetupStateToken: stateToken,
  });
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      email: query.user.email ?? "",
      firstName: query.user.firstName ?? "",
      lastName: query.user.lastName ?? "",
      password: "",
      passwordConfirmation: "",
    },
    resolver: zodResolver(formSchema),
  });

  const [firstName, lastName] = form.watch(["firstName", "lastName"]);

  const password = form.watch("password");

  const validatePasswordStrengthResult = useMemo(
    () => validatePasswordStrength(password),
    [password],
  );

  const [failure, setFailure] = useState<
    "EMAIL_ALREADY_USED" | "PASSWORD_TOO_WEAK" | null
  >(null);

  const onSignUpSuccess = () => {
    void navigate({
      pathname: generatePath(
        APPLICATION_ROUTES.setupWorkspaceCartaIssuerSelection,
        {},
      ),
      search: searchParams.toString(),
    });
  };

  const authenticationContext = useAuthentication();

  const onSubmit = form.handleSubmit(async (_formData) => {
    const formData = _formData as FormInputs;
    setFailure(null);

    const signUpResult = await AuthenticationAPIClient.signUp({
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      longLivedSession: true,
      origin: "SELF_SERVED_FLOW",
      password: formData.password,
    });

    if (signUpResult.outcome === "FAILURE") {
      setFailure(signUpResult.reason);
    } else {
      authenticationContext.setAccount(signUpResult.account);

      onSignUpSuccess();
    }
  });

  const { toggle: togglePasswordIsRevealed, value: passwordIsRevealed } =
    useBoolean(false);

  if (failure) {
    switch (failure) {
      case "EMAIL_ALREADY_USED":
        return <EmailAlreadyUsedCard />;
      default:
        return <SignUpErrorCard />;
    }
  }

  return (
    <AuthLayout.Card
      emoji="👋"
      logo={null}
      subtitle="Confirm your information and set up your account"
      title={`Hello ${firstName} ${lastName}!`}
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <FormRow
          error={form.formState.errors.firstName?.message}
          id="first-name"
          label="First name"
        >
          <Input
            autoComplete="given-name"
            placeholder="Enter your first name"
            type="text"
            {...form.register("firstName")}
          />
        </FormRow>

        <FormRow
          error={form.formState.errors.lastName?.message}
          id="last-name"
          label="Last name"
        >
          <Input
            autoComplete="family-name"
            placeholder="Enter your last name"
            type="text"
            {...form.register("lastName")}
          />
        </FormRow>

        <FormRow
          error={form.formState.errors.email?.message}
          id="email"
          label="Email address"
        >
          <Input
            autoComplete="email"
            placeholder="Enter your email"
            type="email"
            {...form.register("email")}
          />
        </FormRow>

        <FormRow
          error={form.formState.errors.password?.message}
          label="Password"
        >
          <PasswordInput
            autoComplete="new-password"
            id="password"
            passwordIsRevealed={passwordIsRevealed}
            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            togglePasswordIsRevealed={togglePasswordIsRevealed}
            {...form.register("password")}
          />
        </FormRow>

        <FormRow
          error={form.formState.errors.passwordConfirmation?.message}
          label="Confirm your password"
        >
          <PasswordInput
            autoComplete="new-password"
            id="password-confirmation"
            passwordIsRevealed={passwordIsRevealed}
            placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
            togglePasswordIsRevealed={togglePasswordIsRevealed}
            {...form.register("passwordConfirmation")}
          />
        </FormRow>

        {form.formState.errors.password &&
          !validatePasswordStrengthResult.passwordIsStrongEnough && (
            <PasswordStrengthInfoCard password={password} />
          )}

        <Button fullWidth size="small" type="submit" variant="Primary Full">
          Next
        </Button>
        <PoweredByCarta className="m-auto h-[22px]" />
      </form>
    </AuthLayout.Card>
  );
};

const EmailAlreadyUsedCard: React.FC = () => {
  const mailtoSubject = encodeURI(`How can I retrieve my account credentials?`);
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <AuthLayout.Card
      emoji="✋"
      logo={null}
      subtitle={
        <div className="space-y-4">
          <div>Looks like an account with the same email is already in use</div>
          <div>
            Please contact our{" "}
            <strong>
              <a
                className="text-primary"
                href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
              >
                support team
              </a>
            </strong>{" "}
            for more details.
          </div>
        </div>
      }
      title="This account already exists"
    >
      <LinkButton
        fullWidth
        size="medium"
        to={generatePath(APPLICATION_ROUTES.roleSelection, {})}
        variant="Primary Full"
      >
        Log in
      </LinkButton>
    </AuthLayout.Card>
  );
};

const SignUpErrorCard: React.FC = () => {
  return (
    <AuthLayout.Card emoji="⛔️" logo={null} title="Something went wrong...">
      <LinkButton
        fullWidth
        size="medium"
        to={generatePath(APPLICATION_ROUTES.signUp, {})}
        variant="Primary Full"
      >
        Try again
      </LinkButton>
    </AuthLayout.Card>
  );
};

const AccountCreationPage: React.FC = () => {
  return (
    <Page
      analyticsCategory="Sign Up"
      analyticsName="Sign Up - Account creation"
      title="Sign Up - Account creation"
    >
      <AccountCreationPage_ />
    </Page>
  );
};

export default AccountCreationPage;
