import { useMemo, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { isNonEmptyArray } from "../helpers/ts-utlity";
import { GranteePortalHydratedContentTab_Grantee$key } from "./__generated__/GranteePortalHydratedContentTab_Grantee.graphql";
import {
  GranteePortalHydratedContentTab_Reporting_CTMSGrant$data,
  GranteePortalHydratedContentTab_Reporting_CTMSGrant$key,
} from "./__generated__/GranteePortalHydratedContentTab_Reporting_CTMSGrant.graphql";
import {
  GranteePortalHydratedContentTab_Taxation_CTMSGrant$data,
  GranteePortalHydratedContentTab_Taxation_CTMSGrant$key,
} from "./__generated__/GranteePortalHydratedContentTab_Taxation_CTMSGrant.graphql";
import { GranteePortalMissingGranteeInformationMessage } from "./GranteePortalMissingGranteeInformationMessage";
import { GranteePortalNoGrantMessage } from "./GranteePortalNoGrantMessage";
import { GrantRelatedHydratedContent } from "./GrantRelatedHydratedContent";
import { Button } from "./ui/Button";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalHydratedContentTab_Grantee on Grantee {
    workRelationship
    taxResidenceCountry {
      __typename
    }
  }
`;

const CTMS_GRANT_REPORTING_FRAGMENT = graphql`
  fragment GranteePortalHydratedContentTab_Reporting_CTMSGrant on CTMSGrant
  @relay(plural: true) {
    id
    label
    ...GrantRelatedHydratedContent_CTMSGrant
    hydratedPortalContent: reportingGranteePortalContent {
      ...GrantRelatedHydratedContent_AdminPortalContent
    }
  }
`;
const CTMS_GRANT_TAXATION_FRAGMENT = graphql`
  fragment GranteePortalHydratedContentTab_Taxation_CTMSGrant on CTMSGrant
  @relay(plural: true) {
    id
    label
    ...GrantRelatedHydratedContent_CTMSGrant
    hydratedPortalContent: taxationGranteePortalContent {
      ...GrantRelatedHydratedContent_AdminPortalContent
    }
  }
`;

type Grant = (
  | GranteePortalHydratedContentTab_Reporting_CTMSGrant$data
  | GranteePortalHydratedContentTab_Taxation_CTMSGrant$data
)[number];

const GranteePortalHydratedContentTab_: React.FC<{
  ctmsGrants: Grant[];
  firstGrant: Grant;
}> = ({ ctmsGrants, firstGrant }) => {
  const [selectedGrant, setSelectedGrant] = useState<Grant>(firstGrant);

  return (
    <div className="flex flex-col gap-4">
      {ctmsGrants.length > 1 && (
        <>
          <Typography variant="Regular/Extra Small">
            Select your grant to view the corresponding documentation
          </Typography>
          <div className="flex items-center gap-2">
            {ctmsGrants.map((grant) => (
              <Button
                key={grant.id}
                onClick={() => setSelectedGrant(grant)}
                size="small"
                variant={
                  selectedGrant.id === grant.id
                    ? "Primary Outline"
                    : "Tertiary Link"
                }
              >
                {grant.label}
              </Button>
            ))}
          </div>
        </>
      )}
      <GrantRelatedHydratedContent
        adminPortalContentFragment={selectedGrant.hydratedPortalContent}
        ctmsGrantFragment={selectedGrant}
      />
    </div>
  );
};

export const GranteePortalHydratedContentTab: React.FC<
  | {
      ctmsGrantsFragment: GranteePortalHydratedContentTab_Reporting_CTMSGrant$key;
      granteeFragment: GranteePortalHydratedContentTab_Grantee$key;
      section: "reporting";
    }
  | {
      ctmsGrantsFragment: GranteePortalHydratedContentTab_Taxation_CTMSGrant$key;
      granteeFragment: GranteePortalHydratedContentTab_Grantee$key;
      section: "taxation";
    }
> = ({ ctmsGrantsFragment, granteeFragment, section }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const _ctmsGrants = useFragment(
    section === "reporting"
      ? CTMS_GRANT_REPORTING_FRAGMENT
      : CTMS_GRANT_TAXATION_FRAGMENT,
    ctmsGrantsFragment,
  );

  const ctmsGrants = useMemo(() => [..._ctmsGrants], [_ctmsGrants]);

  if (!grantee.taxResidenceCountry || !grantee.workRelationship) {
    return <GranteePortalMissingGranteeInformationMessage />;
  }
  if (!isNonEmptyArray(ctmsGrants)) {
    return <GranteePortalNoGrantMessage />;
  }

  return (
    <GranteePortalHydratedContentTab_
      ctmsGrants={ctmsGrants}
      firstGrant={ctmsGrants[0]}
    />
  );
};
