/**
 * @generated SignedSource<<2419676c6440ce1d0c136bf53a6c57e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_CTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly readyForConsentAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly isUnfavorable: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest">;
  }>;
  readonly searchedReadyForConsentAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_Organization" | "ConfirmSubmitBoardConsentSlide_Organization" | "LegalWorkflowLayout_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "BoardApproval_CTMSGrantAmendmentRequests_Organization";
};
export type BoardApproval_CTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: BoardApproval_CTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_CTMSGrantAmendmentRequests_Organization">;
};

import BoardApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './BoardApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "READY_FOR_CONSENT"
  ]
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "grantAmendmentRequestsSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": BoardApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BoardApproval_CTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "searchedReadyForConsentAmendmentRequests",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "grantAmendmentRequestsSearch"
        },
        (v1/*: any*/)
      ],
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "readyForConsentAmendmentRequests",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnfavorable",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CTMSGrantAmendmentRequestsTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "82fe05dfae78b089d1da30e679f1500e";

export default node;
