/**
 * @generated SignedSource<<3a2ff57776c1b59be7be4f8a183955d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
import { FragmentRefs } from "relay-runtime";
export type RefresherTag_EasopGrant$data = {
  readonly isRefresher: boolean;
  readonly planningEntrySource: {
    readonly refresherType?: RefresherGrantPlanningEntryType;
  } | null;
  readonly " $fragmentType": "RefresherTag_EasopGrant";
};
export type RefresherTag_EasopGrant$key = {
  readonly " $data"?: RefresherTag_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefresherTag_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefresherTag_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRefresher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "planningEntrySource",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "refresherType",
              "storageKey": null
            }
          ],
          "type": "RefresherGrantPlanningEntry",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "522adab6b491f8987d0169603340a497";

export default node;
