import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { VestingOverview_CTMSGrant$key } from "./__generated__/VestingOverview_CTMSGrant.graphql";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { SingleValueProgress } from "./ui/SingleValueProgress";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment VestingOverview_CTMSGrant on CTMSGrant {
    cumulativeVested
    quantityIssued
    isActive
  }
`;
export const VestingOverview: React.FC<{
  ctmsGrantFragment: VestingOverview_CTMSGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const intl = useIntl();
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <div className="flex flex-col items-end">
      <Typography as="div" variant="Medium/Caption">
        <FormattedPercentage
          value={ctmsGrant.cumulativeVested / ctmsGrant.quantityIssued}
        />
      </Typography>

      <Typography variant="Regular/Caption">
        {`${intl.formatNumber(
          ctmsGrant.cumulativeVested,
        )} of the ${intl.formatNumber(
          ctmsGrant.quantityIssued,
        )} shares have vested`}
      </Typography>

      <SingleValueProgress
        className="relative mt-2 h-2 w-[140px] sm:w-[280px]"
        current={ctmsGrant.cumulativeVested}
        max={ctmsGrant.quantityIssued}
        variant={!ctmsGrant.isActive ? "light-red" : "primary"}
      />
    </div>
  );
};
