import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../components/CountryFlag";
import { CtmsGrantActiveOrTerminatedTag } from "../../../../components/CtmsGrantActiveOrTerminatedTag";
import DetailsHeader from "../../../../components/DetailsHeader";
import {
  MissingCTMSInformationTag,
  MissingInformationTag,
} from "../../../../components/MissingInformationTag";
import { RepricedFromCtmsGrantTag } from "../../../../components/RepricedFromCtmsGrantTag";
import { RepricedToCtmsGrantTag } from "../../../../components/RepricedToCtmsGrantTag";
import { ShortDate } from "../../../../components/ShortDate";
import { Tag } from "../../../../components/ui/Tag";
import { useOrganizationCTMS } from "../../../../hooks/useOrganizationCTMS";
import { APPLICATION_ROUTES } from "../../../../paths";
import { CtmsGrantDetailsHeader_CTMSGrant$key } from "./__generated__/CtmsGrantDetailsHeader_CTMSGrant.graphql";
import { CtmsGrantDetailsHeader_Organization$key } from "./__generated__/CtmsGrantDetailsHeader_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsHeader_CTMSGrant on CTMSGrant {
    label
    vestingStartDate
    stakeholderSignatureDate
    equityTypeAwardName
    grantStatus
    terminationDate
    boardApprovalDate
    repricedFromCTMSGrant {
      ...RepricedFromCtmsGrantTag_CtmsGrant
    }
    repricedToCTMSGrant {
      ...RepricedToCtmsGrantTag_CtmsGrant
    }
    grantee {
      id
      name
      taxResidenceCountry {
        ...CountryFlag_Country
      }
    }
    matchingInstrument {
      taxResidenceCountry {
        ...CountryFlag_Country
      }
    }
    easopGrant {
      formattedGranteeName
    }
    ...CtmsGrantActiveOrTerminatedTag_CtmsGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsHeader_Organization on Organization {
    id
    isConnectedToCarta
    isConnectedToPulley
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
    ...useOrganizationCTMS_Organization
  }
`;

export const CtmsGrantDetailsHeader: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsHeader_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsHeader_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  const equityTypeValue = useMemo(() => {
    if (!ctmsGrant.equityTypeAwardName) {
      return <MissingCTMSInformationTag organizationFragment={organization} />;
    }

    return (
      <FormattedMessage
        defaultMessage={`{equityType} {countryEmoji}`}
        values={{
          countryEmoji: (
            <CountryFlag
              countryFragment={
                ctmsGrant.matchingInstrument?.taxResidenceCountry
              }
            />
          ),
          equityType: ctmsGrant.equityTypeAwardName,
        }}
      />
    );
  }, [
    ctmsGrant.equityTypeAwardName,
    ctmsGrant.matchingInstrument?.taxResidenceCountry,
    organization,
  ]);

  return (
    <DetailsHeader
      subtitle={
        <div className="flex items-center gap-2">
          {ctmsGrant.repricedToCTMSGrant && (
            <RepricedToCtmsGrantTag
              ctmsGrantFragment={ctmsGrant.repricedToCTMSGrant}
            />
          )}
          {ctmsGrant.repricedFromCTMSGrant && (
            <RepricedFromCtmsGrantTag
              ctmsGrantFragment={ctmsGrant.repricedFromCTMSGrant}
            />
          )}
          <CtmsGrantActiveOrTerminatedTag
            ctmsGrantFragment={ctmsGrant}
            length="long"
          />
        </div>
      }
      title={ctmsGrant.label}
    >
      <DetailsHeader.Item
        label="Grantee"
        tooltipContent="Team member receiving equity"
      >
        <Link
          to={generatePath(APPLICATION_ROUTES.organizationGrantee, {
            granteeId: ctmsGrant.grantee.id,
            organizationId: organization.id,
          })}
        >
          <CountryFlag
            countryFragment={ctmsGrant.grantee.taxResidenceCountry}
          />{" "}
          {ctmsGrant.easopGrant?.formattedGranteeName ?? ctmsGrant.grantee.name}
        </Link>
      </DetailsHeader.Item>
      <DetailsHeader.Item
        label="Equity type"
        tooltipContent="Example: ISO, NSO, SAR,..."
      >
        {equityTypeValue}
      </DetailsHeader.Item>
      <DetailsHeader.Item
        label="Vesting start date"
        tooltipContent="When the grant started vesting"
      >
        {ctmsGrant.vestingStartDate ? (
          <ShortDate value={ctmsGrant.vestingStartDate} />
        ) : (
          <MissingCTMSInformationTag organizationFragment={organization} />
        )}
      </DetailsHeader.Item>
      {(organization.isConnectedToCarta ||
        organization.isConnectedToPulley) && (
        <DetailsHeader.Item
          label="Grantee signature date"
          tooltipContent={
            organizationCTMS
              ? `When the grantee approved the grant in ${organizationCTMS.name}`
              : `When the grantee approved the grant`
          }
        >
          {ctmsGrant.stakeholderSignatureDate ? (
            <ShortDate value={ctmsGrant.stakeholderSignatureDate} />
          ) : (
            <Tag color="orange">Pending signature</Tag>
          )}
        </DetailsHeader.Item>
      )}
      {ctmsGrant.grantStatus === "Active" ? (
        <DetailsHeader.Item
          label="Board approval date"
          tooltipContent="Date of approval of the grant by the last board member (= grant date)"
        >
          {ctmsGrant.boardApprovalDate ? (
            <ShortDate value={ctmsGrant.boardApprovalDate} />
          ) : (
            <MissingInformationTag />
          )}
        </DetailsHeader.Item>
      ) : (
        <DetailsHeader.Item
          label="Termination date"
          tooltipContent="When the grant was terminated"
        >
          {ctmsGrant.terminationDate ? (
            <ShortDate value={ctmsGrant.terminationDate} />
          ) : (
            <MissingInformationTag />
          )}
        </DetailsHeader.Item>
      )}
    </DetailsHeader>
  );
};
