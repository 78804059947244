import { isEmpty } from "lodash";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { graphql } from "react-relay";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { SendGrantsBackToDraftButton_Mutation } from "./__generated__/SendGrantsBackToDraftButton_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const SEND_BACK_TO_DRAFTS_MUTATION = graphql`
  mutation SendGrantsBackToDraftButton_Mutation($easopGrantsIds: [UUID!]!) {
    sendEasopGrantsBackToDraft(easopGrantsIds: $easopGrantsIds) {
      grantStatus
    }
  }
`;

export const SendGrantsBackToDraftButton: React.FC<{
  onCompleted: () => void;
  selectedGrants: Array<{ id: string }>;
}> = ({ onCompleted, selectedGrants }) => {
  const [sendBackToDrafts, sendBackToDraftsIsInFlight] =
    useSafeMutation<SendGrantsBackToDraftButton_Mutation>(
      SEND_BACK_TO_DRAFTS_MUTATION,
    );
  const toaster = useToaster();

  const {
    setFalse: hideConfirmationModal,
    setTrue: showConfirmationModal,
    value: confirmationModalIsShown,
  } = useBoolean(false);

  const handleSendBackToDraftsConfirmed = useCallback(async () => {
    const easopGrantsIds = selectedGrants.map((grant) => grant.id);
    await sendBackToDrafts({
      variables: {
        easopGrantsIds,
      },
    });

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
              one {# draft}
              other {# drafts}
            } successfully sent back to drafts!`}
          values={{
            grantCount: easopGrantsIds.length,
          }}
        />
      </Toast>,
    );

    onCompleted();

    hideConfirmationModal();
  }, [
    selectedGrants,
    sendBackToDrafts,
    toaster,
    onCompleted,
    hideConfirmationModal,
  ]);
  return (
    <>
      <ConfirmationModal
        confirmationLabel="Back to drafts"
        loading={sendBackToDraftsIsInFlight}
        onClose={hideConfirmationModal}
        onConfirmed={handleSendBackToDraftsConfirmed}
        show={confirmationModalIsShown}
        title="Send grant back to drafts?"
      >
        Grants will have to go through the review process again.
      </ConfirmationModal>
      <Button
        className="whitespace-nowrap"
        disabled={isEmpty(selectedGrants)}
        fullWidth
        onClick={showConfirmationModal}
        size="small"
        variant="Secondary Full"
      >
        Back to draft
      </Button>
    </>
  );
};
