import { DeelLogo } from "../components/ui/DeelLogo";
import { RemoteLogo } from "../components/ui/RemoteLogo";

export const HRIS_PROVIDER_MAP = {
  DEEL: "Deel",
  REMOTE: "Remote",
};

export const HRISProviderLogo: React.FC<{
  className?: string;
  hRISProvider: "DEEL" | "REMOTE";
}> = ({ className, hRISProvider }) => {
  switch (hRISProvider) {
    case "DEEL":
      return <DeelLogo className={className} />;
    case "REMOTE":
      return <RemoteLogo className={className} />;
  }
};
