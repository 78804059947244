/**
 * @generated SignedSource<<c2b1727c55deed3dc6b67b347cd9aa6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSExerciseRequestStatus = "Cancelled" | "Done" | "PendingApproval" | "WaitingForCertificateSignature" | "WaitingForFunds";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestCompletedSection_CTMSExerciseRequest$data = {
  readonly ctmsGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"WantToKnowMoreBox_CTMSGrant">;
  };
  readonly status: CTMSExerciseRequestStatus;
  readonly " $fragmentType": "ExerciseRequestCompletedSection_CTMSExerciseRequest";
};
export type ExerciseRequestCompletedSection_CTMSExerciseRequest$key = {
  readonly " $data"?: ExerciseRequestCompletedSection_CTMSExerciseRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestCompletedSection_CTMSExerciseRequest">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestCompletedSection_CTMSExerciseRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WantToKnowMoreBox_CTMSGrant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "f20da343407b279c4d0cbb6e966f0b74";

export default node;
