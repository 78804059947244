import {
  CheckIcon,
  ClockIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { generatePath, Outlet, useSearchParams } from "react-router-dom";

import { BookADemoSlideOver } from "../../components/BookADemo/BookADemoSlideOver";
import { DjamoLogo } from "../../components/CustomerLogos/DjamoLogo";
import { FolkLogo } from "../../components/CustomerLogos/FolkLogo";
import { KaratLogo } from "../../components/CustomerLogos/KaratLogo";
import { LevityLogo } from "../../components/CustomerLogos/LevityLogo";
import { MazeLogo } from "../../components/CustomerLogos/MazeLogo";
import { MedusaLogo } from "../../components/CustomerLogos/MedusaLogo";
import { PayflowLogo } from "../../components/CustomerLogos/PayflowLogo";
import { SliteLogo } from "../../components/CustomerLogos/SliteLogo";
import { SafeSimulatorGraphSection } from "../../components/SafeSimulatorGraphSection";
import { Button, LinkButton } from "../../components/ui/Button";
import { CenteredColumnLayout } from "../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { Typography } from "../../components/ui/Typography";
import { SafeSimulatorContextProvider } from "../../pages/SafeSimulator/Context";
import { useSafeSimulatorContext } from "../../pages/SafeSimulator/IContext";
import { APPLICATION_ROUTES } from "../../paths";
import { ZapierService } from "../../services/ZapierService";

const SafeSimulatorLayout_: React.FC = () => {
  const {
    computedValues: {
      currentShareholdersShares,
      postConversionCurrentPricePerShare,
      postConversionFullyDilutedShares,
      postConversionSafeShares,
    },
    state: { optionPoolShares },
  } = useSafeSimulatorContext();
  return (
    <div className="h-full w-full bg-gradient-9-am">
      <CenteredColumnLayout
        backgroundColor="none"
        fullHeight
        maxWidth="1304"
        showFooter
      >
        <div className="flex flex-col items-center gap-16">
          <RoundedBox withBorder withShadow>
            <div className="grid grid-cols-[1fr_400px] divide-x-[0.5px] divide-gray-04">
              <div>
                <Outlet />
              </div>
              <SafeSimulatorGraphSection
                className="px-6 py-10"
                currentShareholdersShares={currentShareholdersShares}
                optionPoolShares={optionPoolShares}
                postConversionCurrentPricePerShare={
                  postConversionCurrentPricePerShare
                }
                postConversionLatestValuation={
                  postConversionCurrentPricePerShare !== null &&
                  postConversionFullyDilutedShares !== null
                    ? postConversionCurrentPricePerShare *
                      postConversionFullyDilutedShares
                    : null
                }
                postConversionSafeShares={postConversionSafeShares}
              />
            </div>
          </RoundedBox>
          <div className="flex flex-col items-center gap-6">
            <Typography className="text-black-05" variant="Regular/Default">
              We take the complexity out of equity for:
            </Typography>
            <div className="flex h-6 items-center gap-12 text-black">
              <MazeLogo />
              <PayflowLogo />
              <KaratLogo />
              <LevityLogo />
              <DjamoLogo />
              <FolkLogo />
              <SliteLogo />
              <MedusaLogo />
            </div>
          </div>
          <RoundedBox className="w-[984px] space-y-6 rounded-3xl border-[1px] border-black-07 px-20 py-16 text-center">
            <div className="space-y-4">
              <Typography as="div" variant="Medium/Large">
                Willing to do more with your equity?
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Small"
              >
                Automatically import your team’s equity directly from Carta and
                discover a new world of equity.
              </Typography>
            </div>
            <Typography
              as="div"
              className="flex items-center justify-center gap-6 py-4"
              variant="Medium/Caption"
            >
              <div className="flex items-center gap-1">
                <CheckIcon className="w-4 text-primary" />
                <span>No credit card required.</span>
              </div>
              <div className="flex items-center gap-1">
                <ShieldCheckIcon className="w-4 text-primary" />
                <span>Secure connection.</span>
              </div>
              <div className="flex items-center gap-1">
                <ClockIcon className="w-4 text-primary" />
                <span>Setup in 30 seconds.</span>
              </div>
            </Typography>
            <div className="flex gap-10">
              <LinkButton
                className="flex-1"
                fullWidth
                size="medium"
                to={generatePath(APPLICATION_ROUTES["signUp"])}
                variant="Primary Full"
              >
                Sync your Carta account
              </LinkButton>
              <BookADemoSlideOver
                renderButton={({ openSlideOver }) => (
                  <Button
                    className="flex-1"
                    fullWidth
                    onClick={openSlideOver}
                    size="medium"
                    type="button"
                    variant="Secondary Outline"
                  >
                    Book a demo
                  </Button>
                )}
              />
            </div>
          </RoundedBox>
        </div>
      </CenteredColumnLayout>
    </div>
  );
};

const SafeSimulatorLayout: React.FC = () => {
  const [searchParams] = useSearchParams();
  const userEmail = searchParams.get("email");
  useEffect(() => {
    if (!userEmail) return;
    void ZapierService.triggerUserStartedSafeSimulator({
      email: userEmail,
    });
  }, [userEmail]);

  return (
    <SafeSimulatorContextProvider>
      <SafeSimulatorLayout_ />
    </SafeSimulatorContextProvider>
  );
};

export default SafeSimulatorLayout;
