import { RadioGroup as HeadlessUIRadioGroup } from "@headlessui/react";
import classNames from "classnames";
import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { Tag } from "../Tag";
import { Typography } from "../Typography";
import { Radio } from "./Radio";

const Label: React.FC<React.PropsWithChildren> = ({ children }) => (
  <HeadlessUIRadioGroup.Label>
    <Typography as="div" variant="Medium/Extra Small">
      {children}
    </Typography>
  </HeadlessUIRadioGroup.Label>
);

const Option = <Value,>({
  children,
  className,
  value,
}: React.PropsWithChildren<{
  className?: string;
  value: Value;
}>) => (
  <HeadlessUIRadioGroup.Option value={value}>
    {({ checked }) => (
      <div
        className={classNames(
          "flex cursor-pointer items-center gap-2 py-2",
          className,
        )}
      >
        <Radio checked={checked} />
        <Typography
          className="text-gray-08 ui-checked:text-primary"
          variant="Medium/Extra Small"
        >
          {children}
        </Typography>
      </div>
    )}
  </HeadlessUIRadioGroup.Option>
);

const CardOption: React.FC<
  Omit<
    React.ComponentProps<typeof HeadlessUIRadioGroup.Option>,
    "children" | "className"
  > &
    React.PropsWithChildren<{
      className?: string;
      description?: React.ReactNode;
      loading?: boolean;
      tag?: React.ReactNode;
    }>
> = ({ children, className, description, loading, tag, ...props }) => (
  <HeadlessUIRadioGroup.Option {...props}>
    {({ checked }) => (
      <div
        className={classNames(
          "flex items-center gap-5 rounded-lg px-6 py-4 transition-all",
          {
            "animate-pulse": loading,
            "border-[0.5px] border-primary": checked,
            "cursor-pointer border-[0.5px] border-gray-07": !checked,
          },
          className,
        )}
      >
        <Radio checked={checked} className="shrink-0" />
        <div className="space-y-1">
          <div className="flex items-center gap-2">
            <Typography variant="Medium/Extra Small">{children}</Typography>
            {tag && (
              <Tag color="purple" size="Small">
                {tag}
              </Tag>
            )}
          </div>
          <Typography className="text-black-05" variant="Regular/Extra Small">
            {description}
          </Typography>
        </div>
      </div>
    )}
  </HeadlessUIRadioGroup.Option>
);

const _RadioGroup = <Value,>({
  children,
  className,
  disabled,
  onChange,
  value,
}: React.PropsWithChildren<{
  className?: string;
  disabled?: boolean;
  onChange: (value: Value) => void;
  value?: Value;
}>) => (
  <HeadlessUIRadioGroup
    className={className}
    disabled={disabled}
    onChange={onChange}
    value={value}
  >
    {children}
  </HeadlessUIRadioGroup>
);

const RadioGroupForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: Omit<React.ComponentProps<typeof _RadioGroup>, "onChange" | "value"> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <_RadioGroup onChange={field.onChange} value={field.value} {...props} />
      )}
    />
  );
};

export const RadioGroup = Object.assign(_RadioGroup, {
  Card: CardOption,
  Form: RadioGroupForm,
  Label,
  Option,
});
