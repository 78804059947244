/**
 * @generated SignedSource<<2f56e1fce0d7681b23abdb0d2b2ecfaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "NEW_HIRE_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT" | "TERMINATION";
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
import { FragmentRefs } from "relay-runtime";
export type PlanningEntriesTable_PlanningEntries$data = ReadonlyArray<{
  readonly __typename: "NewHireGrantPlanningEntry";
  readonly canBeConvertedToDraft: boolean;
  readonly createdFromSuggestion: boolean;
  readonly duplicable: boolean;
  readonly easopGrant: {
    readonly id: string;
  } | null;
  readonly editable: boolean;
  readonly equityGridLevel: {
    readonly name: string;
  } | null;
  readonly id: string;
  readonly newHirerEntrySharesGranted: number | null;
  readonly position: string;
  readonly type: PlanningEntryType;
  readonly " $fragmentSpreads": FragmentRefs<"NewHirePlanningEntryModal_NewHireGrantPlanningEntry" | "PlanningEntryDetailsSlideOver_PlanningEntry">;
  readonly " $fragmentType": "PlanningEntriesTable_PlanningEntries";
} | {
  readonly __typename: "RefresherGrantPlanningEntry";
  readonly canBeConvertedToDraft: boolean;
  readonly createdFromSuggestion: boolean;
  readonly duplicable: boolean;
  readonly easopGrant: {
    readonly id: string;
  } | null;
  readonly editable: boolean;
  readonly grantee: {
    readonly equityGridLevel: {
      readonly name: string;
    } | null;
    readonly jobTitle: string | null;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
  };
  readonly id: string;
  readonly refresherType: RefresherGrantPlanningEntryType;
  readonly sharesGranted: number;
  readonly type: PlanningEntryType;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntryDetailsSlideOver_PlanningEntry" | "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry">;
  readonly " $fragmentType": "PlanningEntriesTable_PlanningEntries";
} | {
  readonly __typename: "TerminationPlanningEntry";
  readonly canBeConvertedToDraft: boolean;
  readonly createdFromSuggestion: boolean;
  readonly duplicable: boolean;
  readonly easopGrant: {
    readonly id: string;
  } | null;
  readonly editable: boolean;
  readonly grantee: {
    readonly equityGridLevel: {
      readonly name: string;
    } | null;
    readonly jobTitle: string | null;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
  };
  readonly id: string;
  readonly sharesReturning: number;
  readonly type: PlanningEntryType;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntryDetailsSlideOver_PlanningEntry" | "TerminationPlanningEntryModal_TerminationPlanningEntry">;
  readonly " $fragmentType": "PlanningEntriesTable_PlanningEntries";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "PlanningEntriesTable_PlanningEntries";
}>;
export type PlanningEntriesTable_PlanningEntries$key = ReadonlyArray<{
  readonly " $data"?: PlanningEntriesTable_PlanningEntries$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntriesTable_PlanningEntries">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duplicable",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "editable",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeConvertedToDraft",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdFromSuggestion",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "EasopGrant",
  "kind": "LinkedField",
  "name": "easopGrant",
  "plural": false,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "EquityGridLevel",
  "kind": "LinkedField",
  "name": "equityGridLevel",
  "plural": false,
  "selections": [
    (v7/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "PlanningEntryDetailsSlideOver_PlanningEntry"
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Grantee",
  "kind": "LinkedField",
  "name": "grantee",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNameWithCountryFlag_Grantee"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlanningEntriesTable_PlanningEntries",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "position",
          "storageKey": null
        },
        {
          "alias": "newHirerEntrySharesGranted",
          "args": null,
          "kind": "ScalarField",
          "name": "sharesGranted",
          "storageKey": null
        },
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewHirePlanningEntryModal_NewHireGrantPlanningEntry"
        }
      ],
      "type": "NewHireGrantPlanningEntry",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refresherType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sharesGranted",
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v10/*: any*/),
        (v9/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry"
        }
      ],
      "type": "RefresherGrantPlanningEntry",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sharesReturning",
          "storageKey": null
        },
        (v5/*: any*/),
        (v6/*: any*/),
        (v10/*: any*/),
        (v9/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TerminationPlanningEntryModal_TerminationPlanningEntry"
        }
      ],
      "type": "TerminationPlanningEntry",
      "abstractKey": null
    }
  ],
  "type": "PlanningEntry",
  "abstractKey": "__isPlanningEntry"
};
})();

(node as any).hash = "a1f7f1e32d3d32a3ecd97b6f2d8c5555";

export default node;
