/**
 * @generated SignedSource<<cab6e464646089e813eb4facf6cd9785>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsGrant_EasopGrant$data = {
  readonly grantDate: string;
  readonly grantStatus: EasopGrantStatus;
  readonly instrument: {
    readonly equityType: {
      readonly id: string;
      readonly name: EquityTypeName;
      readonly taxResidenceCountry: {
        readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
      };
    } | null;
    readonly needsAdditionalFormalities: boolean;
    readonly workRelationship: WorkRelationship;
    readonly " $fragmentSpreads": FragmentRefs<"NeedsAdditionalFormalitiesWarning_Instrument">;
  };
  readonly isVirtual: boolean;
  readonly quantityGranted: number;
  readonly " $fragmentType": "DraftGrantDetailsGrant_EasopGrant";
};
export type DraftGrantDetailsGrant_EasopGrant$key = {
  readonly " $data"?: DraftGrantDetailsGrant_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsGrant_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsGrant_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "needsAdditionalFormalities",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "taxResidenceCountry",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CountryFlag_Country"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NeedsAdditionalFormalitiesWarning_Instrument"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "a1254e4b14374ca84626a12ef810fd1b";

export default node;
