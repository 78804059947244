import { Typography } from "./Typography";

function _DetailsTableLarge({
  children,
  footer,
  title,
}: React.PropsWithChildren<{
  footer?: React.ReactNode;
  title: React.ReactNode;
}>) {
  return (
    <div className="rounded-lg border-[0.5px] border-gray-04 p-10">
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Default">
          {title}
        </Typography>
        <div>{children}</div>
        <div>{footer}</div>
      </div>
    </div>
  );
}

function DetailsTableRow({
  label,
  value,
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="grid grid-cols-2 items-center gap-6 border-b-[0.5px] border-gray-04 py-4 last-of-type:border-b-0">
      <Typography as="div" variant="Medium/Extra Small">
        {label}
      </Typography>
      <Typography as="div" variant="Regular/Extra Small">
        {value}
      </Typography>
    </div>
  );
}

export const DetailsTable = Object.assign(
  {},
  {
    Large: _DetailsTableLarge,
    Row: DetailsTableRow,
  },
);
