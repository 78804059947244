import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../../components/Page";
import { Toggle } from "../../../components/ui/Form/Toggle";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import { EquiuniversitySettings_EquityOffer$key } from "./__generated__/EquiuniversitySettings_EquityOffer.graphql";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import { EquityOfferEquiuniversity } from "./Equiuniversity";

const schema = z.object({
  displayFAQ: z.boolean(),
});
type EquityOfferEquiuniversityFormInputs = z.infer<typeof schema>;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquiuniversitySettings_EquityOffer on EquityOffer {
    displayFAQ
    organization {
      id
      name
    }
    instrument {
      isVirtual
    }
    ...EquityOfferPreviewLayout_EquityOffer
  }
`;

const EquityOfferEquiuniversitySettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<EquiuniversitySettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      displayFAQ: equityOffer.displayFAQ,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(
    (formInputs: EquityOfferEquiuniversityFormInputs) =>
      equityOfferContext.updatePartialEquityOffer({
        displayFAQ: formInputs.displayFAQ,
      }),
  );

  watch(() => {
    void onSubmit();
  });

  useEffect(() => {
    equityOfferContext.onStepEntered("EQUIUNIVERSITY");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      return () => {
        equityOfferContext.trackStepCompleted("EQUIUNIVERSITY");
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <EquityOfferPreviewLayout
      continueButtonDataCy="equiUniversiTyNextStepButton"
      equityOfferFragment={equityOffer}
      onBackClick={equityOfferContext.goToPreviousStep}
      onContinueClick={equityOfferContext.goToNextStep}
      Preview={
        <EquityOfferEquiuniversity
          isVirtual={equityOffer.instrument?.isVirtual}
        />
      }
      previewClassnames={classNames({
        ["pointer-events-none opacity-50"]: !equityOffer.displayFAQ,
      })}
      title={
        <span>
          {equityOffer.instrument?.isVirtual ? (
            <>
              FAQ <span className="text-primary">regarding</span> SAR
            </>
          ) : (
            <>
              Equi-<span className="text-primary">universi</span>-ty
            </>
          )}
        </span>
      }
    >
      <form id="equity-offer-equiuniversity-form">
        <NoticeMessage size="Large" variant="Idea">
          <div className="flex flex-col gap-2">
            <span>
              Allow the candidate to view general information and educative
              content to understand the compensation.
            </span>
            <div className="flex items-center gap-2">
              <Typography
                className="text-black-07"
                variant="Regular/Extra Small"
              >
                Educative content visible
              </Typography>
              <Controller
                control={control}
                name="displayFAQ"
                render={({ field }) => (
                  <Toggle enabled={field.value} onChange={field.onChange} />
                )}
              />
            </div>
          </div>
        </NoticeMessage>
      </form>
    </EquityOfferPreviewLayout>
  );
};

const EquityOfferEquiuniversitySettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<EquiuniversitySettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Equi-university"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer equi-university configuration`}
    >
      <EquityOfferEquiuniversitySettingsPage_ />
    </Page>
  );
};

export default EquityOfferEquiuniversitySettingsPage;
