/**
 * @generated SignedSource<<c27427dde740bca89d212165568fdfdb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AdminPortalContentError = "MISSING_INFORMATION_FOR_HYDRATED_CONTENT";
import { FragmentRefs } from "relay-runtime";
export type GrantRelatedHydratedContent_AdminPortalContent$data = {
  readonly content: string;
  readonly error: AdminPortalContentError | null;
  readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  readonly " $fragmentType": "GrantRelatedHydratedContent_AdminPortalContent";
};
export type GrantRelatedHydratedContent_AdminPortalContent$key = {
  readonly " $data"?: GrantRelatedHydratedContent_AdminPortalContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_AdminPortalContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantRelatedHydratedContent_AdminPortalContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminPortalContent_AdminPortalContent"
    }
  ],
  "type": "AdminPortalContent",
  "abstractKey": null
};

(node as any).hash = "2973781c3fa903dec865ffae2ddf8d42";

export default node;
