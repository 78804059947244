import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../../components/Page";
import { Toggle } from "../../../components/ui/Form/Toggle";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { ProjectionSettings_EquityOffer$key } from "./__generated__/ProjectionSettings_EquityOffer.graphql";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import { EquityOfferProjection } from "./Projection";

const schema = z.object({
  displayProjectionScenarios: z.boolean(),
});

type EquityOfferProjectionSettingsFormInputs = z.infer<typeof schema>;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment ProjectionSettings_EquityOffer on EquityOffer {
    organization {
      id
      name
    }
    displayProjectionScenarios
    ...EquityOfferPreviewLayout_EquityOffer
    ...Projection_EquityOffer
  }
`;

const EquityOfferProjectionSettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<ProjectionSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      displayProjectionScenarios: equityOffer.displayProjectionScenarios,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(
    (formInputs: EquityOfferProjectionSettingsFormInputs) =>
      equityOfferContext.updatePartialEquityOffer({
        displayProjectionScenarios: formInputs.displayProjectionScenarios,
      }),
  );

  watch(() => void onSubmit());

  useEffect(() => {
    equityOfferContext.onStepEntered("PROJECTION");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      equityOfferContext.trackStepCompleted("PROJECTION");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <EquityOfferPreviewLayout
      continueButtonDataCy="projectionScenariosNextStepButton"
      description="Add milestones to help candidates know how much the company could grow over time"
      equityOfferFragment={equityOffer}
      onBackClick={equityOfferContext.goToPreviousStep}
      onContinueClick={equityOfferContext.goToNextStep}
      Preview={
        <EquityOfferProjection
          equityOfferFragment={equityOffer}
          forceProjectionChartView
          forceTabletView
        />
      }
      previewClassnames={classNames({
        ["pointer-events-none opacity-50"]:
          !equityOffer.displayProjectionScenarios,
      })}
      title="Projection scenarios"
    >
      <div className="space-y-4">
        <form id="equity-offer-candidate-form">
          <NoticeMessage size="Large" variant="Idea">
            <div className="flex flex-col gap-2">
              <span>
                Allow the candidate to view the potential value of the equity
                based on the company&apos;s possible future valuation
              </span>
              <div className="flex items-center gap-2 text-black-07">
                <span>Visible to candidate</span>
                <Controller
                  control={control}
                  name="displayProjectionScenarios"
                  render={({ field }) => (
                    <Toggle enabled={field.value} onChange={field.onChange} />
                  )}
                />
              </div>
            </div>
          </NoticeMessage>
        </form>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Your projection scenarios are the same as those displayed to your
          grantees.{" "}
          <Link
            target="_blank"
            to={generatePath(
              APPLICATION_ROUTES["organizationToolsGranteePortalSettings"],
              {
                organizationId: equityOfferContext.equityOffer.organization.id,
              },
            )}
          >
            <Typography
              className="cursor-pointer text-primary"
              variant="Medium/Extra Small"
            >
              Edit those scenarios.
            </Typography>
          </Link>
        </Typography>
      </div>
    </EquityOfferPreviewLayout>
  );
};

const EquityOfferProjectionSettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<ProjectionSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Projection scenarios"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer projection scenarios configuration`}
    >
      <EquityOfferProjectionSettingsPage_ />
    </Page>
  );
};

export default EquityOfferProjectionSettingsPage;
