import classNames from "classnames";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { To } from "react-router-dom";

import { BackButton } from "../../BackButton";
import { Footer } from "../../Footer";
import { Typography } from "../Typography";

const MAX_WIDTHS = {
  "690": /* tailwind */ `max-w-[690px]`,
  "800": /* tailwind */ `max-w-[800px]`,
  "984": /* tailwind */ `max-w-[984px]`,
  "1304": /* tailwind */ `max-w-[1304px]`,
} as const;

const BACKGROUND_COLORS = {
  "gray-01": classNames("bg-gray-01"),
  none: classNames("!bg-transparent"),
  white: classNames("bg-white"),
} as const;

export const CenteredColumnLayout: React.FC<{
  backButton?: {
    label: string;
    linkTo?: To;
  };
  backgroundColor?: keyof typeof BACKGROUND_COLORS;
  breadcrumbs?: ReactNode;
  children: ReactNode;
  fullHeight?: boolean;
  maxWidth?: keyof typeof MAX_WIDTHS;
  showFooter?: boolean;
}> = ({
  backButton,
  backgroundColor = "white",
  breadcrumbs,
  children,
  fullHeight = false,
  maxWidth = "984",
  showFooter = false,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-10 bg-white p-6",
        {
          ["h-full"]: fullHeight,
          "pt-20": isNil(breadcrumbs),
        },
        BACKGROUND_COLORS[backgroundColor],
      )}
    >
      {breadcrumbs}
      <div
        className={classNames(
          "mx-auto flex w-full max-w-[1600px] flex-col space-y-6",
          {
            ["flex-grow"]: fullHeight,
          },
        )}
      >
        <main
          className={classNames(
            "m-auto w-full space-y-6",
            {
              ["flex-grow"]: fullHeight,
            },
            MAX_WIDTHS[maxWidth],
          )}
        >
          {backButton && (
            <BackButton linkTo={backButton.linkTo}>
              <Typography
                className="text-black-05"
                variant="Medium/Extra Small"
              >
                {backButton.label}
              </Typography>
            </BackButton>
          )}
          <div>{children}</div>
        </main>
        {showFooter ? <Footer className="pb-4 pt-12" /> : null}
      </div>
    </div>
  );
};
