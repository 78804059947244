/**
 * @generated SignedSource<<0116fa6b19cac9db6ac10cc3392ebb71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSExerciseRequestStatus = "Cancelled" | "Done" | "PendingApproval" | "WaitingForCertificateSignature" | "WaitingForFunds";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestApprovedSection_CTMSExerciseRequest$data = {
  readonly status: CTMSExerciseRequestStatus;
  readonly " $fragmentType": "ExerciseRequestApprovedSection_CTMSExerciseRequest";
};
export type ExerciseRequestApprovedSection_CTMSExerciseRequest$key = {
  readonly " $data"?: ExerciseRequestApprovedSection_CTMSExerciseRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovedSection_CTMSExerciseRequest">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestApprovedSection_CTMSExerciseRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "dc8e208a3beb99675986abf7f155a229";

export default node;
