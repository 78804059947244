/**
 * @generated SignedSource<<3baad57ee22adaa7220c6e47bc61f036>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription$variables = {
  boardConsentsIds: ReadonlyArray<string>;
};
export type PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription$data = {
  readonly boardConsentsUpdates: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent">;
  };
};
export type PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription = {
  response: PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription$data;
  variables: PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardConsentsIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "boardConsentsIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "boardConsentsUpdates",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "boardConsentsUpdates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidedReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPandaDocDocumentSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatureRequest",
                "kind": "LinkedField",
                "name": "signatureRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryLastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValue",
                "kind": "LinkedField",
                "name": "fairMarketValue",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "valuationFirm",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "valuationReportDocument",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isBoardConsent"
              }
            ],
            "type": "FairMarketValueBoardConsent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c7a41967b572ea0da6da979c411db0c",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription",
    "operationKind": "subscription",
    "text": "subscription PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription(\n  $boardConsentsIds: [UUID!]!\n) {\n  boardConsentsUpdates(ids: $boardConsentsIds) {\n    __typename\n    ...PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {\n  id\n  date\n  value\n  valuationFirm\n  status\n  valuationReportDocument {\n    downloadUrl\n    id\n  }\n}\n\nfragment PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent on FairMarketValueBoardConsent {\n  id\n  voidedReason\n  isPandaDocDocumentSent\n  canBoardMemberSignatureRequestReminderEmailsBeSent\n  signatureRequests {\n    id\n    completedAt\n    signatoryFirstName\n    signatoryLastName\n  }\n  fairMarketValue {\n    ...FairMarketValueApprovalTable_FairMarketValue\n    id\n  }\n  ...SendReminderButton_BoardConsent\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "a1790c33ffc44bc929690c9640b0018c";

export default node;
