import { useMemo } from "react";
import { Control, FieldPath, FieldValues, useWatch } from "react-hook-form";

import { DeepPartial } from "../../helpers/ts-utlity";
import {
  DurationUnit,
  DurationUnitSelect,
} from "../GrantForm/DurationUnitSelect";
import { Input } from "./Form/Inputs/Input";

type Duration = { duration: number; unit: DurationUnit };

export const isDuration = (
  duration: DeepPartial<Duration> | null | undefined,
): duration is Duration => {
  return (
    typeof duration?.duration === "number" && typeof duration?.unit === "string"
  );
};

export const useDurationInputErrorMessage = ({
  error,
}: {
  error:
    | undefined
    | {
        duration?: { message?: string };
        message?: string;
        unit?: { message?: string };
      };
}) => {
  return useMemo(() => {
    if (!error) return null;

    if (error.message) return error.message;
    if (error?.duration?.message) return error.duration.message;
    if (error?.unit?.message) return error.unit.message;
  }, [error]);
};

const DEFAULT_DURATION_UNIT: DurationUnit = "month";
const DEFAULT_DURATION = 0;

const _DurationInput: React.FC<{
  disabled?: boolean;
  isDurationInvalid: boolean;
  name?: string;
  onChange: (value: Duration) => void;
  unitOptions?: DurationUnit[];
  usePortal?: boolean;
  value: Duration | null;
}> = ({
  disabled,
  isDurationInvalid,
  name,
  onChange,
  unitOptions,
  usePortal,
  value,
}) => {
  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <Input
          before="#"
          disabled={disabled}
          invalid={isDurationInvalid}
          min={1}
          name={name ? `${name}-duration` : undefined}
          onChange={(event) =>
            onChange({
              duration: Number(event.currentTarget.value),
              unit: value?.unit ?? DEFAULT_DURATION_UNIT,
            })
          }
          step={1}
          type="number"
          value={value?.duration || ""}
        />
      </div>
      <div className="flex-1">
        <DurationUnitSelect
          disabled={disabled}
          invalid={isDurationInvalid}
          name={name ? `${name}-unit` : undefined}
          onChange={(unit) =>
            unit &&
            onChange({ duration: value?.duration ?? DEFAULT_DURATION, unit })
          }
          options={unitOptions}
          unitCount={value?.duration ?? DEFAULT_DURATION}
          usePortal={usePortal}
          value={value?.unit ?? DEFAULT_DURATION_UNIT}
        />
      </div>
    </div>
  );
};

const FormDurationInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  durationName,
  unitName,
  unitOptions,
}: {
  control: Control<TFieldValues>;
  durationName: TName;
  unitName: TName;
  unitOptions?: DurationUnit[];
}) => {
  const unitCount = useWatch({
    control,
    name: durationName,
  });

  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <Input.Form
          before="#"
          control={control}
          min={1}
          name={durationName}
          step={1}
          type="number"
        />
      </div>
      <div className="flex-1">
        <DurationUnitSelect.Form
          control={control}
          name={unitName}
          options={unitOptions}
          unitCount={unitCount ?? DEFAULT_DURATION}
          usePortal
        />
      </div>
    </div>
  );
};

export const DurationInput = Object.assign(_DurationInput, {
  Form: FormDurationInput,
});
