/**
 * @generated SignedSource<<5296b0ffffdc37c514b66cf840144dec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PoolDetails_Organization$data = {
  readonly poolAvailableShares: number;
  readonly poolGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly poolPendingSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "PoolDetails_Organization";
};
export type PoolDetails_Organization$key = {
  readonly " $data"?: PoolDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PoolDetails_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PoolDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "poolAvailableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolPendingSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "3fc36ef20c5f455189c02d03530d0821";

export default node;
