/**
 * @generated SignedSource<<7deb7c102e64c833230123e7aa8fa46d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PricePerShareHistoryChart_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ValuationHistoryChart_Organization">;
  readonly " $fragmentType": "PricePerShareHistoryChart_Organization";
};
export type PricePerShareHistoryChart_Organization$key = {
  readonly " $data"?: PricePerShareHistoryChart_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PricePerShareHistoryChart_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PricePerShareHistoryChart_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ValuationHistoryChart_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2eb5a6907bfdfe158387c52ff8b2b998";

export default node;
