import { CheckIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Typography } from "../../../components/ui/Typography";
import { useOrganizationCTMS } from "../../../hooks/useOrganizationCTMS";
import { ExerciseRequestApprovedSection_CTMSExerciseRequest$key } from "./__generated__/ExerciseRequestApprovedSection_CTMSExerciseRequest.graphql";
import { ExerciseRequestApprovedSection_Organization$key } from "./__generated__/ExerciseRequestApprovedSection_Organization.graphql";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment ExerciseRequestApprovedSection_CTMSExerciseRequest on CTMSExerciseRequest {
    status
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestApprovedSection_Organization on Organization {
    ...useOrganizationCTMS_Organization
  }
`;

export const ExerciseRequestApprovedSection: React.FC<{
  ctmsExerciseRequestFragment: ExerciseRequestApprovedSection_CTMSExerciseRequest$key;
  organizationFragment: ExerciseRequestApprovedSection_Organization$key;
}> = ({ ctmsExerciseRequestFragment, organizationFragment }) => {
  const ctmsExerciseRequest = useFragment(
    EXERCISE_REQUEST_FRAGMENT,
    ctmsExerciseRequestFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  const isPendingApproval = ctmsExerciseRequest.status === "PendingApproval";

  return (
    <ExerciseRequestTimelineSection
      active={!isPendingApproval}
      chipContent={
        isPendingApproval ? (
          <ClockIcon className="h-4 w-4" />
        ) : (
          <CheckIcon className="h-4 w-4" />
        )
      }
      chipRounded
    >
      <Typography as="div" variant="Medium/Default">
        Exercise is approved in {organizationCTMS?.name ?? "CTMS"}
      </Typography>
    </ExerciseRequestTimelineSection>
  );
};
