import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key } from "./__generated__/InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts.graphql";
import { InstrumentLegalWatchOuts_Instrument$key } from "./__generated__/InstrumentLegalWatchOuts_Instrument.graphql";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const INSTRUMENT_FRAGMENT = graphql`
  fragment InstrumentLegalWatchOuts_Instrument on Instrument {
    name
  }
`;

const LEGAL_WATCH_OUTS_FRAGMENT = graphql`
  fragment InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts on EquityTypeWorkRelationshipCategoryLegalWatchOuts {
    noWatchOut
    watchOuts {
      id
      title
      content
    }
  }
`;

export const InstrumentLegalWatchOuts: React.FC<{
  instrumentFragment: InstrumentLegalWatchOuts_Instrument$key;
  legalWatchOutsFragment: InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key;
}> = ({ instrumentFragment, legalWatchOutsFragment }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const legalWatchOuts = useFragment(
    LEGAL_WATCH_OUTS_FRAGMENT,
    legalWatchOutsFragment,
  );

  return (
    <RoundedBox className="space-y-2 !bg-primary-01 p-6">
      <Typography as="div" variant="Medium/Small">
        What should I do for {instrument.name} at the time of grant?
      </Typography>
      {legalWatchOuts.noWatchOut ? (
        <Typography as="div" variant="Medium/Extra Small">
          ✅ All good, nothing to worry about
        </Typography>
      ) : (
        legalWatchOuts.watchOuts.map((legalWatchOut) => (
          <div className="space-y-2" key={legalWatchOut.id}>
            <Typography as="div" variant="Medium/Extra Small">
              {legalWatchOut.title}
            </Typography>
            <Typography
              as="div"
              className="whitespace-pre-line text-black-05"
              variant="Regular/Extra Small"
            >
              {legalWatchOut.content}
            </Typography>
          </div>
        ))
      )}
    </RoundedBox>
  );
};
