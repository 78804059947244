import { isEmpty } from "lodash";
import {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";

import { EmptyListPlaceholder } from "../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { GenerateCTMSXlsxButton } from "../../../../components/GenerateCTMSXlsxButton";
import { Page } from "../../../../components/Page";
import { SelectedItemsActionCard } from "../../../../components/SelectedItemsActionCard";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { ConfirmationModal } from "../../../../components/ui/ConfirmationModal";
import { Toast } from "../../../../components/ui/Toast";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useDebounced } from "../../../../hooks/useDebounced";
import { useOrganizationCTMS } from "../../../../hooks/useOrganizationCTMS";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { LegalWorkflowLayout } from "../../../../layouts/LegalWorkflowLayout";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { PendingImplementation_MarkPendingCTMSImplementationGrantsAsImplemented_Mutation } from "./__generated__/PendingImplementation_MarkPendingCTMSImplementationGrantsAsImplemented_Mutation.graphql";
import { PendingImplementation_Organization$key } from "./__generated__/PendingImplementation_Organization.graphql";
import { PendingImplementation_Query } from "./__generated__/PendingImplementation_Query.graphql";
import { PendingImplementation_Viewer$key } from "./__generated__/PendingImplementation_Viewer.graphql";
import { GrantsTable } from "./GrantsTable";

const ACCOUNT_FRAGMENT = graphql`
  fragment PendingImplementation_Viewer on Account {
    isSuperAdmin
    ...GrantsTable_Account
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PendingImplementation_Organization on Organization
  @refetchable(queryName: "PendingImplementation_Organization_RefetchQuery")
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    easopGrantsSearch: { type: "String", defaultValue: "" }
  ) {
    id
    name
    ...useOrganizationCTMS_Organization
    ...GenerateCTMSXlsxButton_Organization
    searchedEasopGrantsPendingCTMSImplementation: easopGrants(
      filters: {
        statusIn: [PendingCtmsImplementation]
        search: $easopGrantsSearch
      }
    ) {
      ...GrantsTable_EasopGrant @arguments(organizationId: $organizationId)
    }
    easopGrantsPendingCTMSImplementation: easopGrants(
      filters: { statusIn: [PendingCtmsImplementation] }
    ) {
      __typename
    }
    ...useOrganizationCTMS_Organization
    ...GrantsTable_Organization
    easopGrants {
      id
    }
    ...LegalWorkflowLayout_Organization
  }
`;

const MARK_PENDING_CTMS_IMPLEMENTATION_GRANTS_AS_IMPLEMENTED_MUTATION = graphql`
  mutation PendingImplementation_MarkPendingCTMSImplementationGrantsAsImplemented_Mutation(
    $easopGrantsIds: [UUID!]!
    $organizationId: OrganizationId!
  ) {
    markPendingCTMSImplementationGrantsAsImplemented(
      easopGrantsIds: $easopGrantsIds
      organizationId: $organizationId
    ) {
      grants {
        easopGrant {
          grantStatus
        }
      }
    }
  }
`;

const PendingImplementationPage_: React.FC<{
  organizationFragment: PendingImplementation_Organization$key;
  viewerFragment: PendingImplementation_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(ACCOUNT_FRAGMENT, viewerFragment);

  const [grantSelection, setGrantSelection] = useState<Set<string>>(new Set());

  const selectedGrants = useMemo(
    () =>
      organization.easopGrants.filter((grant) => grantSelection.has(grant.id)),
    [grantSelection, organization.easopGrants],
  );

  const organizationCTMSName =
    useOrganizationCTMS({ organizationFragment: organization })?.name ??
    "your Cap Table Management Solution";

  const [
    markPendingCTMSImplementationGrantsAsImplemented,
    markPendingCTMSImplementationGrantsAsImplementedIsInFlight,
  ] =
    useSafeMutation<PendingImplementation_MarkPendingCTMSImplementationGrantsAsImplemented_Mutation>(
      MARK_PENDING_CTMS_IMPLEMENTATION_GRANTS_AS_IMPLEMENTED_MUTATION,
    );

  const {
    setFalse: closeMarkGrantsAsImplementedConfirmationModal,
    setTrue: showMarkGrantsAsImplementedConfirmationModal,
    value: markGrantsAsImplementedConfirmationModalIsShown,
  } = useBoolean(false);

  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const {
    isDebouncing: searchIsDebouncing,
    liveState: searchInputValue,
    setState: setSearchInputValue,
  } = useDebounced<string>({
    delay: 1000,
    initialState: "",
    onDebounce: useCallback(
      (search: string) => {
        startSearchTransition(() => {
          refetchOrganization({
            easopGrantsSearch: search,
          });
        });
      },
      [refetchOrganization],
    ),
  });

  const handleDataUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization({
        easopGrantsSearch: searchInputValue,
      });
    });
  }, [refetchOrganization, searchInputValue]);

  const handleMarkPendingCTMSImplementationGrantsAsImplemented =
    useCallback(async () => {
      const easopGrantsIds = selectedGrants.map((grant) => grant.id);

      await markPendingCTMSImplementationGrantsAsImplemented({
        variables: {
          easopGrantsIds,
          organizationId: organization.id,
        },
      });

      closeMarkGrantsAsImplementedConfirmationModal();

      handleDataUpdated();

      setGrantSelection(new Set());

      toaster.push(
        <Toast title="Toasty!">
          <FormattedMessage
            defaultMessage={`{grantCount, plural,
            one {# draft}
            other {# drafts}
          } successfully marked as implemented!`}
            values={{
              grantCount: easopGrantsIds.length,
            }}
          />
        </Toast>,
      );
    }, [
      selectedGrants,
      markPendingCTMSImplementationGrantsAsImplemented,
      closeMarkGrantsAsImplementedConfirmationModal,
      handleDataUpdated,
      toaster,
      organization.id,
    ]);

  const applicationName = useApplicationName();

  return (
    <LegalWorkflowLayout
      actionButton={
        viewer.isSuperAdmin && (
          <GenerateCTMSXlsxButton organizationFragment={organization} />
        )
      }
      onSearchChange={setSearchInputValue}
      organizationFragment={organization}
      searchIsLoading={searchTransitionIsInProgress || searchIsDebouncing}
      searchPlaceholder="Search grants..."
      searchValue={searchInputValue}
      subtitle={`Grants the ${applicationName} team is currently implementing in ${organizationCTMSName}`}
      title="Pending implementation"
      topBarActionsRender={({ mainContentIsScrolled }) => {
        if (!viewer.isSuperAdmin) return null;

        return (
          <SelectedItemsActionCard
            actions={
              <Button
                disabled={selectedGrants.length === 0}
                loading={
                  markPendingCTMSImplementationGrantsAsImplementedIsInFlight
                }
                onClick={showMarkGrantsAsImplementedConfirmationModal}
                size="small"
              >
                Mark as implemented
              </Button>
            }
            compact={mainContentIsScrolled}
            emptyListLabel="Select drafts"
            itemCount={selectedGrants.length}
            loading={markPendingCTMSImplementationGrantsAsImplementedIsInFlight}
            pluralLabel="drafts selected"
            singularLabel="draft selected"
          />
        );
      }}
    >
      <ConfirmationModal
        confirmationLabel="Confirm"
        loading={markPendingCTMSImplementationGrantsAsImplementedIsInFlight}
        onClose={closeMarkGrantsAsImplementedConfirmationModal}
        onConfirmed={handleMarkPendingCTMSImplementationGrantsAsImplemented}
        show={markGrantsAsImplementedConfirmationModalIsShown}
        title="Mark grants as implemented"
      >
        You won&apos;t be able to generate exportable files for these grants
      </ConfirmationModal>

      {isEmpty(organization.easopGrantsPendingCTMSImplementation) ? (
        <EmptyListPlaceholder
          hideImage
          subtitle="Feel free to create new grants or plan equity from your planning"
          title="No grants currently pending implementation"
        />
      ) : (
        <GrantsTable
          columns={[
            "Draft",
            "Employee",
            "Ownership",
            "Details",
            "Strike price",
            "Grant type",
            "Status",
            "Actions",
          ]}
          easopGrantsFragment={
            organization.searchedEasopGrantsPendingCTMSImplementation
          }
          emptyListPlaceholder="No grants found"
          grantSelection={grantSelection}
          onGrantSelectionChange={setGrantSelection}
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      )}
    </LegalWorkflowLayout>
  );
};

const QUERY = graphql`
  query PendingImplementation_Query($organizationId: OrganizationId!) {
    me {
      ...PendingImplementation_Viewer
    }
    organization(id: $organizationId) {
      id
      name
      ...PendingImplementation_Organization
        @arguments(organizationId: $organizationId)
    }
  }
`;

const PendingImplementationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me, organization },
  } = useQuery<PendingImplementation_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - New Equity Implementation"
      organizationId={organization.id}
      title={`Admin | ${organization.name} pending implementation grants`}
    >
      <PendingImplementationPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default PendingImplementationPage;
