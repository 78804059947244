/**
 * @generated SignedSource<<7c13362729df077af4d0fb56f0496bf5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdminPage = "GEOGRAPHIES" | "GRANTEE_PORTAL_SETTINGS" | "INSIGHTS";
export type useSetAdminPageVisited_Mutation$variables = {
  organizationId: string;
  page: AdminPage;
};
export type useSetAdminPageVisited_Mutation$data = {
  readonly setOrganizationAdminPageVisited: {
    readonly pageHasBeenVisited: boolean;
  } | null;
};
export type useSetAdminPageVisited_Mutation = {
  response: useSetAdminPageVisited_Mutation$data;
  variables: useSetAdminPageVisited_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  }
],
v1 = {
  "kind": "Variable",
  "name": "page",
  "variableName": "page"
},
v2 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": [
    (v1/*: any*/)
  ],
  "kind": "ScalarField",
  "name": "pageHasBeenVisited",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useSetAdminPageVisited_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "setOrganizationAdminPageVisited",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useSetAdminPageVisited_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Admin",
        "kind": "LinkedField",
        "name": "setOrganizationAdminPageVisited",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79c4a3547f412947b86a06b385037b03",
    "id": null,
    "metadata": {},
    "name": "useSetAdminPageVisited_Mutation",
    "operationKind": "mutation",
    "text": "mutation useSetAdminPageVisited_Mutation(\n  $organizationId: OrganizationId!\n  $page: AdminPage!\n) {\n  setOrganizationAdminPageVisited(organizationId: $organizationId, page: $page) {\n    pageHasBeenVisited(page: $page)\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4dc4daa1955e34d76233008f0aafb465";

export default node;
