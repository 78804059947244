/**
 * @generated SignedSource<<5536ca398b27d71af8b7dd954782ef19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsApprovalPage_Query$variables = Record<PropertyKey, never>;
export type DocumentsApprovalPage_Query$data = {
  readonly documentsTemplates: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DocumentsApprovalPage_DocumentTemplate">;
  }>;
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DocumentsApprovalPage_Organization">;
  }>;
};
export type DocumentsApprovalPage_Query = {
  response: DocumentsApprovalPage_Query$data;
  variables: DocumentsApprovalPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsApprovalPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentTemplate",
        "kind": "LinkedField",
        "name": "documentsTemplates",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentsApprovalPage_DocumentTemplate"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DocumentsApprovalPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DocumentsApprovalPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentTemplate",
        "kind": "LinkedField",
        "name": "documentsTemplates",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "needsBoardApproval",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contentUsedForApproval",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requiresDocumentApproval",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ffd85c1a0b4f9b353ad553773267de6",
    "id": null,
    "metadata": {},
    "name": "DocumentsApprovalPage_Query",
    "operationKind": "query",
    "text": "query DocumentsApprovalPage_Query {\n  documentsTemplates {\n    ...DocumentsApprovalPage_DocumentTemplate\n    id\n  }\n  organizations {\n    ...DocumentsApprovalPage_Organization\n    id\n  }\n}\n\nfragment DocumentsApprovalPage_DocumentTemplate on DocumentTemplate {\n  id\n  name\n  type\n  needsBoardApproval\n  content\n  contentUsedForApproval\n}\n\nfragment DocumentsApprovalPage_Organization on Organization {\n  id\n  name\n  requiresDocumentApproval\n}\n"
  }
};
})();

(node as any).hash = "5b15a25c838e774a8e6131e523a142b0";

export default node;
