/**
 * @generated SignedSource<<27f7700cdbe385badeac9e9afbb21cc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberApprovalsTables_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "BoardMemberApprovalsTables_Organization";
};
export type BoardMemberApprovalsTables_Organization$key = {
  readonly " $data"?: BoardMemberApprovalsTables_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberApprovalsTables_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardMemberApprovalsTables_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1a16c7c429b117c1cdbaa6746e3c1074";

export default node;
