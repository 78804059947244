import { isEmpty } from "lodash";
import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { graphql } from "react-relay";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { RequestChangesOnEasopGrantsButton_Mutation } from "./__generated__/RequestChangesOnEasopGrantsButton_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { Toast } from "./ui/Toast";

const SEND_BACK_TO_DRAFTS_MUTATION = graphql`
  mutation RequestChangesOnEasopGrantsButton_Mutation(
    $easopGrantsIds: [UUID!]!
  ) {
    requestChangesOnEasopGrants(easopGrantsIds: $easopGrantsIds) {
      grantStatus
    }
  }
`;

export const RequestChangesOnEasopGrantsButton: React.FC<{
  onCompleted: () => void;
  selectedGrants: Array<{ id: string }>;
}> = ({ onCompleted, selectedGrants }) => {
  const [
    requestChangesOnEasopGrants,
    requestChangesOnEasopGrantsMutationIsInFlight,
  ] = useSafeMutation<RequestChangesOnEasopGrantsButton_Mutation>(
    SEND_BACK_TO_DRAFTS_MUTATION,
  );
  const toaster = useToaster();

  const handleRequestChangesButtonClick = useCallback(async () => {
    const easopGrantsIds = selectedGrants.map((grant) => grant.id);
    await requestChangesOnEasopGrants({
      variables: {
        easopGrantsIds,
      },
    });

    toaster.push(
      <Toast title="Wonderful!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
              one {# draft}
              other {# drafts}
            } successfully sent back to drafts!`}
          values={{
            grantCount: easopGrantsIds.length,
          }}
        />
      </Toast>,
    );

    onCompleted();
  }, [selectedGrants, requestChangesOnEasopGrants, toaster, onCompleted]);

  return (
    <Button
      className="whitespace-nowrap"
      disabled={isEmpty(selectedGrants)}
      fullWidth
      loading={requestChangesOnEasopGrantsMutationIsInFlight}
      onClick={handleRequestChangesButtonClick}
      size="small"
      variant="Danger Outline"
    >
      Reject
    </Button>
  );
};
