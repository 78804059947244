/**
 * @generated SignedSource<<0f4873dc2131fc3dee8343c0bd024181>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_Admin_StripeCustomer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OnboardingSlider_StripeCustomer">;
  readonly " $fragmentType": "Overview_Admin_StripeCustomer";
};
export type Overview_Admin_StripeCustomer$key = {
  readonly " $data"?: Overview_Admin_StripeCustomer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_Admin_StripeCustomer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Overview_Admin_StripeCustomer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OnboardingSlider_StripeCustomer"
    }
  ],
  "type": "StripeCustomer",
  "abstractKey": null
};

(node as any).hash = "c73872d00ad98d52c2dd552dc2482546";

export default node;
