import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Book, Flash, IconProps, TimerStart } from "iconsax-react";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CountriesTableFooterContent } from "../../../components/CountriesTableFooterContent";
import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { LinkButton } from "../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { SearchBar } from "../../../components/ui/SearchBar";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { useSetAdminPageVisited } from "../useSetAdminPageVisited";
import {
  Countries_Organization$data,
  Countries_Organization$key,
} from "./__generated__/Countries_Organization.graphql";
import { Countries_Query } from "./__generated__/Countries_Query.graphql";
import {
  Countries_Viewer$data,
  Countries_Viewer$key,
} from "./__generated__/Countries_Viewer.graphql";
import { CountriesTableFooter } from "./CountriesTableFooter";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Countries_Organization on Organization {
    id
    name
    suggestedCountries {
      country {
        code
        name
        emoji
      }
      organizationGeography {
        unlockedAt
      }
      geography {
        __typename
      }
      employeeCount
      grantCount
      grantDraftCount
    }
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment Countries_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)
  }
`;

type SuggestedCountry =
  Countries_Organization$data["suggestedCountries"][number];

const columnHelper = createColumnHelper<SuggestedCountry>();

function ComplianceCard({
  children,
  icon: Icon,
  title,
}: React.PropsWithChildren<{
  icon: React.ComponentType<IconProps>;
  title: React.ReactNode;
}>) {
  return (
    <div className="space-y-4 rounded-2xl bg-gray-01 p-6">
      <div>
        <Icon className="text-purple-05" size={24} variant="Bulk" />
      </div>
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Small">
          {title}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {children}
        </Typography>
      </div>
    </div>
  );
}

function getItemState({
  item,
  viewer,
}: {
  item: SuggestedCountry;
  viewer: Countries_Viewer$data;
}) {
  if (item.organizationGeography?.unlockedAt) {
    return "UNLOCKED";
  }

  if (item.geography && viewer.isAllowedToUnlockCountryForOrganization) {
    return "AVAILABLE";
  }

  return "NOT_AVAILABLE";
}

function getRowAction({
  item,
  organizationId,
  organizationName,
  supportEmailAddress,
  viewer,
}: {
  item: SuggestedCountry;
  organizationId: string;
  organizationName: string;
  supportEmailAddress: string;
  viewer: Countries_Viewer$data;
}) {
  const state = getItemState({ item, viewer });
  const linkTarget = getRowLinkTarget({
    item,
    organizationId,
    organizationName,
    supportEmailAddress,
    viewer,
  });

  switch (state) {
    case "AVAILABLE":
      return (
        <LinkButton size="extra small" to={linkTarget} variant="Primary Full">
          Unlock country
        </LinkButton>
      );
    case "NOT_AVAILABLE":
      return (
        <LinkButton
          size="extra small"
          to={linkTarget}
          variant="Secondary Outline"
        >
          Contact us
        </LinkButton>
      );
    case "UNLOCKED":
      return (
        <LinkButton
          rightIcon={<ArrowTopRightOnSquareIcon />}
          size="extra small"
          to={linkTarget}
          variant="Secondary Outline"
        >
          View guide
        </LinkButton>
      );
  }
}

function getRowLinkTarget({
  item,
  organizationId,
  organizationName,
  supportEmailAddress,
  viewer,
}: {
  item: SuggestedCountry;
  organizationId: string;
  organizationName: string;
  supportEmailAddress: string;
  viewer: Countries_Viewer$data;
}) {
  const state = getItemState({ item, viewer });

  switch (state) {
    case "AVAILABLE":
      return generatePath(APPLICATION_ROUTES["organizationUnlockCountry"], {
        countryCode: item.country.code,
        organizationId,
      });
    case "NOT_AVAILABLE":
      return `mailto:${supportEmailAddress}?subject=${encodeURIComponent(
        `[${organizationName}] granting employee in ${item.country.name}`,
      )}`;
    case "UNLOCKED":
      return generatePath(APPLICATION_ROUTES["organizationCountry"], {
        countryCode: item.country.code,
        organizationId,
      });
  }
}

const CountriesPage_: React.FC<{
  organizationFragment: Countries_Organization$key;
  viewerFragment: Countries_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  useSetAdminPageVisited({
    organizationId: organization.id,
    page: "GEOGRAPHIES",
  });
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.country.name, {
        cell: (context) => {
          const item = context.row.original;

          return (
            <div className="flex items-center gap-4">
              <div className="font-emoji text-2xl">{item.country.emoji}</div>
              <div className="space-y-1">
                <Typography as="div" variant="Medium/Extra Small">
                  {item.country.name}
                </Typography>
                {item.organizationGeography?.unlockedAt && (
                  <Typography
                    as="div"
                    className="text-gray-09"
                    variant="Regular/Extra Small"
                  >
                    Unlocked{" "}
                    <ShortDate value={item.organizationGeography.unlockedAt} />
                  </Typography>
                )}
                {!item.organizationGeography?.unlockedAt && item.geography && (
                  <Typography
                    as="div"
                    className="text-gray-09"
                    variant="Medium/Extra Small"
                  >
                    Locked
                  </Typography>
                )}
                {!item.geography && (
                  <Typography
                    as="div"
                    className="text-red-05"
                    variant="Regular/Caption"
                  >
                    Not available
                  </Typography>
                )}
              </div>
            </div>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Name",
        id: "country",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.employeeCount, {
        cell: (context) => context.getValue() || "-",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Employees",
        id: "employeeCount",
      }),
      columnHelper.accessor((row) => row.grantCount, {
        cell: (context) => context.getValue() || "-",
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Grants",
        id: "grantCount",
      }),
      columnHelper.accessor((row) => row.grantDraftCount, {
        cell: (context) => {
          const value = context.getValue();

          if (!value) {
            return "-";
          }

          return (
            <Tag color="purple">
              <FormattedMessage
                defaultMessage="{count, plural, =1 {# draft}  other {# drafts}}"
                values={{
                  count: value,
                }}
              />
            </Tag>
          );
        },
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Drafts",
        id: "grantDraftCount",
      }),
      columnHelper.display({
        cell: (context) => {
          const item = context.row.original;

          return getRowAction({
            item,
            organizationId: organization.id,
            organizationName: organization.name,
            supportEmailAddress,
            viewer,
          });
        },
        id: "action",
        meta: {
          alignRight: true,
        },
      }),
    ],
    [organization.id, organization.name, supportEmailAddress, viewer],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const handleSearch = useCallback(
    (value: string) => {
      searchParams.set("search", value);
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams],
  );

  const searchValue = searchParams.get("search");

  const table = useReactTable({
    columns,
    data: useMemo(
      () => [...organization.suggestedCountries],
      [organization.suggestedCountries],
    ),
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    onGlobalFilterChange,
    state: {
      globalFilter: searchValue,
    },
  });

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      showFooter
      subtitle="Global documentation and guides per country"
      title="Countries"
    >
      <div className="space-y-8">
        <div className="space-y-6">
          <div className="flex gap-4">
            {viewer.isAllowedToUnlockCountryForOrganization && (
              <LinkButton
                size="small"
                to={generatePath(
                  APPLICATION_ROUTES["organizationUnlockCountries"],
                  { organizationId: organization.id },
                )}
              >
                Unlock new countries
              </LinkButton>
            )}
            <SearchBar
              className="w-full max-w-[260px]"
              onChange={handleSearch}
              placeholder="Search countries..."
              value={searchValue ?? ""}
            />
          </div>
          <div>
            <Table.Smart
              footer={
                <CountriesTableFooter>
                  <Table.Row>
                    <Table.Cell className="col-span-full">
                      <CountriesTableFooterContent
                        organizationId={organization.id}
                      />
                    </Table.Cell>
                  </Table.Row>
                </CountriesTableFooter>
              }
              rowRenderer={({ cells, rowData }) => {
                return (
                  <Table.LinkRow
                    className="cursor-pointer"
                    to={getRowLinkTarget({
                      item: rowData.original,
                      organizationId: organization.id,
                      organizationName: organization.name,
                      supportEmailAddress,
                      viewer,
                    })}
                  >
                    {cells}
                  </Table.LinkRow>
                );
              }}
              table={table}
              tableClassName="grid-cols-[1fr_1fr_1fr_1fr_auto]"
              tableDisplay="grid"
            />
          </div>
        </div>
        <div className="space-y-2">
          <Typography
            className="uppercase text-black-05"
            variant="Medium/Caption"
          >
            Equity compensation, compliantly
          </Typography>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <ComplianceCard icon={Book} title="Stay Fully Compliant">
              Let go of the stress around proper legal filings. Your grant
              documentation has already been reviewed by local attorneys across
              70+ countries.
            </ComplianceCard>
            <ComplianceCard icon={Flash} title="Build Equity Faster">
              Forget managing attorneys across the world to build out your
              employee equity program. We’ve already got it covered for you.
            </ComplianceCard>
            <ComplianceCard icon={TimerStart} title="Become A Master Of Time">
              We help companies understand what’s coming their way. From the
              future of your equity program to your 409A valuation - never miss
              an opportunity again.
            </ComplianceCard>
          </div>
        </div>
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query Countries_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...Countries_Organization
    }
    me {
      ...Countries_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const CountriesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<Countries_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Countries"
      analyticsName="Admin - Countries"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} countries freemium`}
    >
      <CountriesPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default CountriesPage;
