/**
 * @generated SignedSource<<3e3f31832a69cd9d052149e1a8fa0c5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodSelectionBlock_Organization$data = {
  readonly id: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationPostTerminationExercisePeriod_Organization">;
  readonly " $fragmentType": "PostTerminationExercisePeriodSelectionBlock_Organization";
};
export type PostTerminationExercisePeriodSelectionBlock_Organization$key = {
  readonly " $data"?: PostTerminationExercisePeriodSelectionBlock_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodSelectionBlock_Organization">;
};

import PostTerminationExercisePeriodSelectionBlock_Organization_RefetchQuery_graphql from './PostTerminationExercisePeriodSelectionBlock_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PostTerminationExercisePeriodSelectionBlock_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PostTerminationExercisePeriodSelectionBlock_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationPostTerminationExercisePeriod_Organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/)
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "92b3228337911b7fbf0cf186fb033543";

export default node;
