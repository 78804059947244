/**
 * @generated SignedSource<<c24f67bef5817aa9cc8e4cc1ee99399c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantableStatus = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTABLE" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "WORK_RELATIONSHIP_NOT_COVERED";
import { FragmentRefs } from "relay-runtime";
export type GrantFormSlide_Grantees$data = ReadonlyArray<{
  readonly grantableStatus: GrantableStatus;
  readonly id: string;
  readonly instruments: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"InstrumentNotAvailableForGranteeAlert_Instrument">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"InstrumentNotAvailableForGranteeAlert_Grantee">;
  readonly " $fragmentType": "GrantFormSlide_Grantees";
}>;
export type GrantFormSlide_Grantees$key = ReadonlyArray<{
  readonly " $data"?: GrantFormSlide_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_Grantees">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GrantFormSlide_Grantees",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantableStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": "TaxFavored"
        },
        {
          "kind": "Literal",
          "name": "sortDirection",
          "value": "DESC"
        }
      ],
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InstrumentNotAvailableForGranteeAlert_Instrument"
        }
      ],
      "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InstrumentNotAvailableForGranteeAlert_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "439f1743b3f58d28dc0173dd7df5d3ec";

export default node;
