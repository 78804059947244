import {
  ArrowLeftIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, To } from "react-router-dom";
import { graphql } from "relay-runtime";

import { ShortDate } from "../../../../components/ShortDate";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import {
  APPLICATION_ROUTES,
  MAP_AMENDMENT_STATUS_TO_PATH,
} from "../../../../paths";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../services/AccelerationClause";
import { ReviewCTMSGrantModificationPageContent_CTMSGrant$key } from "./__generated__/ReviewCTMSGrantModificationPageContent_CTMSGrant.graphql";
import {
  ReviewCTMSGrantModificationPageContent_Organization$data,
  ReviewCTMSGrantModificationPageContent_Organization$key,
} from "./__generated__/ReviewCTMSGrantModificationPageContent_Organization.graphql";
import { CancelFlowButton } from "./CancelFlowButton";
import { useModifyCTMSGrantContext } from "./Context";
import { NewAccelerationAllowedOption } from "./NewAccelerationAllowedSlider";
import { NewEarlyExerciseAllowedOption } from "./NewEarlyExerciseAllowedSlider";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ReviewCTMSGrantModificationPageContent_Organization on Organization {
    id
    postTerminationExercisePeriods {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
        displayName
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
        displayName
      }
    }
    vestingSchedules {
      id
      name
    }
    ...LargeCenteredDetailsLayout_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ReviewCTMSGrantModificationPageContent_CTMSGrant on CTMSGrant {
    id
    label
  }
`;

const WhastNextItem: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="flex items-center gap-2">
    <div className="h-8 w-8 rounded-full bg-primary-01 p-1.5 text-primary">
      <ArrowRightCircleIcon />
    </div>
    <span>{children}</span>
  </div>
);

const WhatsNext: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <Typography
      as="div"
      className="flex flex-col gap-2"
      variant="Regular/Extra Small"
    >
      <Typography variant="Medium/Default">What’s next?</Typography>
      <span>After you confirm the modification:</span>
      <WhastNextItem>
        {applicationName} will review the modification details to make sure no
        mistakes were made.
      </WhastNextItem>
      <WhastNextItem>
        Once reviewed, they’ll be ready for you in the board approval page
      </WhastNextItem>
      <WhastNextItem>
        Send the amendments to your board for final approval
      </WhastNextItem>
      <WhastNextItem>
        {applicationName} will implement the changes in your Cap Table
        Management Solution
      </WhastNextItem>
    </Typography>
  );
};

const ReviewTable: React.FC<{
  organization: ReviewCTMSGrantModificationPageContent_Organization$data;
}> = ({ organization }) => {
  const context = useModifyCTMSGrantContext();

  const {
    newAccelerationAllowedValue,
    newEarlyExerciseAllowedValue,
    newOrganizationPostTerminationExercisePeriodId,
    newVestingScheduleId,
    newVestingStartDate,
    totalAccelerationDate,
    waiveCliff,
  } = context.state;

  const organizationPostTerminationExercisePeriods = useMemo(
    () =>
      organization.postTerminationExercisePeriods.map((ptep) => {
        if (ptep.__typename === "%other") throw new Error("Invalid typename");
        return ptep;
      }),
    [organization.postTerminationExercisePeriods],
  );

  const newOrganizationPostTerminationExercisePeriod = useMemo(
    () =>
      organizationPostTerminationExercisePeriods.find(
        ({ id }) => newOrganizationPostTerminationExercisePeriodId === id,
      ),
    [
      organizationPostTerminationExercisePeriods,
      newOrganizationPostTerminationExercisePeriodId,
    ],
  );

  const newVestingSchedule = useMemo(
    () =>
      organization.vestingSchedules.find(
        ({ id }) => newVestingScheduleId === id,
      ),
    [organization.vestingSchedules, newVestingScheduleId],
  );

  return (
    <RoundedBox className="overflow-hidden" withBorder>
      <Table className="w-full">
        <Table.Header>
          <Table.Row>
            {newVestingStartDate && (
              <Table.HeaderCell>Vesting start date</Table.HeaderCell>
            )}
            {newVestingSchedule && (
              <Table.HeaderCell>Vesting schedule</Table.HeaderCell>
            )}
            {totalAccelerationDate && (
              <Table.HeaderCell>Vesting acceleration</Table.HeaderCell>
            )}
            {waiveCliff && <Table.HeaderCell>Cliff</Table.HeaderCell>}
            {newOrganizationPostTerminationExercisePeriod && (
              <Table.HeaderCell>PTEP</Table.HeaderCell>
            )}
            {newAccelerationAllowedValue && (
              <Table.HeaderCell>Acceleration</Table.HeaderCell>
            )}
            {newEarlyExerciseAllowedValue && (
              <Table.HeaderCell>Early exercise</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            {newVestingStartDate && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Starts on</Typography>
                <br />
                <span>
                  <ShortDate value={newVestingStartDate} />
                </span>
              </Table.Cell>
            )}
            {newVestingSchedule && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Name</Typography>
                <br />
                <span>{newVestingSchedule.name}</span>
              </Table.Cell>
            )}
            {totalAccelerationDate && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">End date</Typography>
                <br />
                <span>
                  <ShortDate value={totalAccelerationDate} />
                </span>
              </Table.Cell>
            )}
            {waiveCliff && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Waive</Typography>
                <br />
                <span>Yes</span>
              </Table.Cell>
            )}
            {newOrganizationPostTerminationExercisePeriod && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Duration</Typography>
                <br />
                <span>
                  {newOrganizationPostTerminationExercisePeriod.displayName}
                </span>
              </Table.Cell>
            )}
            {newAccelerationAllowedValue && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Terms</Typography>
                <br />
                <span>
                  {newAccelerationAllowedValue.acceleration ===
                  NewAccelerationAllowedOption.ALLOWED
                    ? ACCELERATION_CLAUSE_LABELS[
                        newAccelerationAllowedValue.accelerationClause
                      ]
                    : "Not allowed"}
                </span>
              </Table.Cell>
            )}
            {newEarlyExerciseAllowedValue && (
              <Table.Cell variant="Medium/Extra Small">
                <Typography variant="Regular/Extra Small">Terms</Typography>
                <br />
                <span>
                  {newEarlyExerciseAllowedValue.earlyExercise ===
                  NewEarlyExerciseAllowedOption.ALLOWED
                    ? "Allowed"
                    : "Not allowed"}
                </span>
              </Table.Cell>
            )}
          </Table.Row>
        </Table.Body>
      </Table>
    </RoundedBox>
  );
};

export const ReviewCTMSGrantModificationPageContent: React.FC<{
  backLink: To;
  ctmsGrantFragment: ReviewCTMSGrantModificationPageContent_CTMSGrant$key;
  onConfirmModificationClicked: () => void;
  organizationFragment: ReviewCTMSGrantModificationPageContent_Organization$key;
}> = ({
  backLink,
  ctmsGrantFragment,
  onConfirmModificationClicked,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const context = useModifyCTMSGrantContext();

  const { parentLink, parentTitle } = useMemo(() => {
    if (context.existingCTMSGrantAmendmentRequest) {
      switch (context.existingCTMSGrantAmendmentRequest.status) {
        case "IMPLEMENTED":
          break;
        default:
          return {
            parentLink: generatePath(
              APPLICATION_ROUTES[
                MAP_AMENDMENT_STATUS_TO_PATH[
                  context.existingCTMSGrantAmendmentRequest.status
                ]
              ],
              {
                organizationId: organization.id,
              },
            ),
            parentTitle: "Amendments",
          };
      }
    }
    return {
      parentLink: generatePath(APPLICATION_ROUTES["organizationEquityGrants"], {
        organizationId: organization.id,
      }),
      parentTitle: "Grants",
    };
  }, [context.existingCTMSGrantAmendmentRequest, organization.id]);

  return (
    <LargeCenteredDetailsLayout
      organizationFragment={organization}
      parents={[
        {
          link: parentLink,
          title: parentTitle,
        },
      ]}
      subtitle={
        <>Please confirm the new terms of the grant that you just modified.</>
      }
      title={<>Review modification details {ctmsGrant.label}</>}
    >
      <div className="space-y-10">
        <ReviewTable organization={organization} />
        <div className="flex gap-4">
          <Button
            onClick={onConfirmModificationClicked}
            size="small"
            variant="Primary Full"
          >
            Confirm modification
          </Button>
          <LinkButton
            leftIcon={<ArrowLeftIcon />}
            size="small"
            to={backLink}
            variant="Secondary Full"
          >
            Back
          </LinkButton>
          <CancelFlowButton organizationId={organization.id} />
        </div>
        <WhatsNext />
      </div>
    </LargeCenteredDetailsLayout>
  );
};
