import classNames from "classnames";
import React from "react";

const BACKGROUNDS = {
  base: classNames("bg-background-base"),
  "gradient-light-aubergine": classNames("bg-gradient-light-aubergine"),
  gray: classNames("bg-gray-01"),
  green: classNames("bg-green-01"),
  orange: classNames("bg-orange-01"),
  primary: classNames("bg-primary-01"),
  "remote-grey-100": classNames("bg-remote-grey-100"),
  subtle: classNames("bg-background-subtle"),
  white: classNames("bg-white-01"),
};

const RADIUSES = {
  "2xl": classNames("rounded-2xl"),
  "3xl": classNames("rounded-3xl"),
  // 😭
  "4px": classNames("rounded"),
  full: classNames("rounded-full"),
  lg: classNames("rounded-lg"),
  md: classNames("rounded-md"),
  sm: classNames("rounded-sm"),
  xl: classNames("rounded-xl"),
};

const BORDERS = {
  gray: classNames("border-[0.5px] border-gray-04"),
  purple: classNames("border-[0.5px] border-purple-05"),
};

const _RoundedBox: React.ForwardRefRenderFunction<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    background?: keyof typeof BACKGROUNDS;
    loading?: boolean;
    rounded?: keyof typeof RADIUSES;
    withBorder?: boolean | keyof typeof BORDERS;
    withShadow?: boolean;
  }
> = (
  {
    background = "white",
    className,
    loading,
    rounded = "lg",
    withBorder = false,
    withShadow = false,
    ...props
  },
  ref,
) => {
  const border =
    withBorder === true
      ? BORDERS.gray
      : withBorder === false
        ? undefined
        : BORDERS[withBorder];

  return (
    <div
      className={classNames(
        "transition-all",
        border,
        {
          "animate-pulse": loading,
          "shadow-100": withShadow,
        },
        RADIUSES[rounded],
        BACKGROUNDS[background],
        className,
      )}
      ref={ref}
      {...props}
    />
  );
};

export const RoundedBox = React.forwardRef(_RoundedBox);
