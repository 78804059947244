/**
 * @generated SignedSource<<6b2a17d362352ebbebdb30c0efe93348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery$variables = {
  id: string;
};
export type PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_CTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery = {
  response: PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery$data;
  variables: PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "GrantAmendmentBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalGrantAmendmentBoardConsents",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voidedReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPandaDocDocumentSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SignatureRequest",
                    "kind": "LinkedField",
                    "name": "signatureRequests",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signatoryFirstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signatoryLastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrantAmendmentRequest",
                    "kind": "LinkedField",
                    "name": "ctmsGrantAmendmentRequests",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isUnfavorable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeletable",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CTMSGrant",
                        "kind": "LinkedField",
                        "name": "ctmsGrant",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Grantee",
                            "kind": "LinkedField",
                            "name": "grantee",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "workRelationship",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Country",
                                "kind": "LinkedField",
                                "name": "taxResidenceCountry",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "emoji",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isBoardConsent"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToCarta",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e01bd3b557b49711a28a94be3a7d4652",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery",
    "operationKind": "query",
    "text": "query PendingApproval_CTMSGrantAmendmentRequests_Organization_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PendingApproval_CTMSGrantAmendmentRequests_Organization\n    id\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  id\n  status\n  isUnfavorable\n  isDeletable\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment PendingApproval_CTMSGrantAmendmentRequests_Organization on Organization {\n  id\n  name\n  pendingApprovalGrantAmendmentBoardConsents {\n    id\n    voidedReason\n    isPandaDocDocumentSent\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    signatureRequests {\n      id\n      completedAt\n      signatoryFirstName\n      signatoryLastName\n    }\n    ctmsGrantAmendmentRequests {\n      ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n      id\n    }\n    ...SendReminderButton_BoardConsent\n  }\n  ...LargeOneColumnLayout_Organization\n  ...CTMSGrantAmendmentRequestsTable_Organization\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "1f8b16a5e19d066d3c096fd03da9903a";

export default node;
