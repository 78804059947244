/**
 * @generated SignedSource<<2cab72846bcecc1a940a30112f67e979>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestDetails_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_Organization" | "ExerciseRequestApprovedSection_Organization" | "ExerciseRequestCompletedSection_Organization" | "ExerciseRequestNotifySection_Organization" | "ExerciseRequestTaxTreatmentSection_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "ExerciseRequestDetails_Organization";
};
export type ExerciseRequestDetails_Organization$key = {
  readonly " $data"?: ExerciseRequestDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestTaxTreatmentSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovalSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestNotifySection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovedSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestCompletedSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "dc33b76520cac9d121048a3ecffb8932";

export default node;
