import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { compact } from "lodash";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Duration } from "../../../../components/Duration";
import { VESTING_OPTION_MAP } from "../../../../components/GrantAmendmentRequest/GrantAmendmentRequestBoxVestingEndSlide";
import { ShortDate } from "../../../../components/ShortDate";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import {
  getAmendedVestingEndingOn,
  getAmendmentVestingEndOption,
  getOriginalLastDayToExercise,
  GrantAmendmentRequest,
  useAmendedLastDayToExercise,
} from "../../../../services/grantAmendmentRequest";
import {
  GranteeTerminationReview_Grantee$data,
  GranteeTerminationReview_Grantee$key,
} from "./__generated__/GranteeTerminationReview_Grantee.graphql";
import {
  GranteeTerminationReview_Organization$data,
  GranteeTerminationReview_Organization$key,
} from "./__generated__/GranteeTerminationReview_Organization.graphql";
import { useGranteeTerminationContext } from "./GranteeTerminationContext";
import { TERMINATION_TYPE_LABEL } from "./GranteeTerminationInformation";
import { GranteeTerminationLayout } from "./GranteeTerminationLayout";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTerminationReview_Organization on Organization {
    id
    name
    granteePortalSettings {
      displayFullyDilutedValues
    }
    postTerminationExercisePeriods {
      id
      displayName
    }
    ...grantAmendmentRequest_useAmendedLastDayToExercise_Organization
  }
`;
const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTerminationReview_Grantee on Grantee {
    id
    name
    ctmsGrants(grantStatusIn: [Active]) {
      id
      label
      activePostTerminationExercisePeriod {
        value {
          duration
          unit
        }
      }
      equityTypeAwardName
    }
  }
`;

const PTEPCell = ({
  grant,
  grantAmendment,
  organization,
}: {
  grant: GranteeTerminationReview_Grantee$data["ctmsGrants"][number];
  grantAmendment: GrantAmendmentRequest | null;
  organization: GranteeTerminationReview_Organization$data;
}) => {
  const granteeTerminationContext = useGranteeTerminationContext();
  const { terminationDate, terminationType } =
    granteeTerminationContext.getCompletedState();

  // PTEP

  const originalPostTerminationExercisePeriod =
    grant.activePostTerminationExercisePeriod?.value ?? null;

  // LAST DAY TO EXERCISE

  const granteeTerminationDate = new Date(terminationDate);

  const originalLastDayToExercise = getOriginalLastDayToExercise({
    granteeTerminationDate,
    originalPostTerminationExercisePeriod,
  });

  const { amendedLastDayToExercise } = useAmendedLastDayToExercise({
    grantAmendment,
    granteeTerminationDate,
    organizationFragment: organization,
  });

  const amendedPtep = useMemo(() => {
    if (!grantAmendment?.postTerminationExercisePeriodId) return null;

    const selectedPtep = organization.postTerminationExercisePeriods.find(
      (ptep) => ptep.id === grantAmendment.postTerminationExercisePeriodId,
    );

    if (!selectedPtep) {
      throw new Error("Can't find selected PTEP");
    }

    return selectedPtep;
  }, [
    grantAmendment?.postTerminationExercisePeriodId,
    organization.postTerminationExercisePeriods,
  ]);

  const displayedLastDayToExercise =
    amendedLastDayToExercise ?? originalLastDayToExercise;

  if (terminationType === "WITH_CAUSE") {
    return (
      <Table.Cell variant="Medium/Extra Small">
        <NoticeMessage
          className="w-full max-w-[187.5px]"
          size="Small"
          variant="Danger"
        >
          Grantee is prohibited from exercising their stock options due to the
          termination type
        </NoticeMessage>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell variant="Medium/Extra Small">
      <div className="flex flex-col items-start">
        {originalPostTerminationExercisePeriod ? (
          <>
            <Typography variant="Regular/Extra Small">
              {amendedPtep?.displayName ?? (
                <Duration
                  duration={originalPostTerminationExercisePeriod.duration}
                  durationUnit={originalPostTerminationExercisePeriod.unit}
                />
              )}
            </Typography>
            <span>
              {displayedLastDayToExercise ? (
                <ShortDate value={displayedLastDayToExercise} />
              ) : null}
            </span>
          </>
        ) : (
          <Tag color="gray">Not applicable for {grant.equityTypeAwardName}</Tag>
        )}
      </div>
    </Table.Cell>
  );
};

export const GranteeTerminationReview: React.FC<{
  granteeFragment: GranteeTerminationReview_Grantee$key;
  onBackClick: () => void;
  onSubmit: () => void;
  organizationFragment: GranteeTerminationReview_Organization$key;
  submitting?: boolean;
}> = ({
  granteeFragment,
  onBackClick,
  onSubmit,
  organizationFragment,
  submitting,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const granteeTerminationContext = useGranteeTerminationContext();

  const {
    grantAmendments,
    terminationDate,
    terminationLastDayAtTheCompany,
    terminationNewRelationship,
    terminationPersonalEmail,
    terminationType,
  } = granteeTerminationContext.getCompletedState();

  if (!terminationDate || !terminationLastDayAtTheCompany) {
    throw new Error("State is missing mandatory information");
  }

  const { handleSubmit } = useForm();

  const applicationName = useApplicationName();

  const whatsNext = useMemo(
    () =>
      compact([
        grantAmendments && grantAmendments.length > 0 && (
          <div className="flex items-center gap-2" key="Easop review">
            <div className="h-8 w-8 rounded-full bg-primary-01 p-1.5 text-primary">
              <ArrowRightCircleIcon />
            </div>
            <span>
              {applicationName} will review the terminations to make sure no
              mistakes were made. Once reviewed, they’ll be sent to the board
              for approval.
            </span>
          </div>
        ),
        terminationType !== "WITH_CAUSE" && (
          <div className="flex items-center gap-2" key="PTEP Vesting">
            <div className="h-8 w-8 rounded-full bg-primary-01 p-1.5 text-primary">
              <ArrowRightCircleIcon />
            </div>
            <span>
              Stakeholders have until the end of their post termination exercise
              period to exercise their vested options, if any.
            </span>
          </div>
        ),
      ]),
    [applicationName, grantAmendments, terminationType],
  );

  return (
    <GranteeTerminationLayout
      grantee={{ id: grantee.id, name: grantee.name }}
      onBackClick={onBackClick}
      onSubmit={handleSubmit(onSubmit)}
      organization={{ id: organization.id, name: organization.name }}
      submitting={submitting}
    >
      <div className="px-10">
        <div className="py-6">
          <Typography variant="Medium/Small">
            👀 Review termination details
          </Typography>
        </div>
        <div className="flex flex-col gap-4.5 py-6">
          <RoundedBox className="overflow-hidden" withBorder>
            <Table className="w-full">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grantee</Table.HeaderCell>
                  <Table.HeaderCell>Termination date</Table.HeaderCell>
                  <Table.HeaderCell>Last day at the company</Table.HeaderCell>
                  <Table.HeaderCell>Termination type</Table.HeaderCell>
                  <Table.HeaderCell>New relationship</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell variant="Medium/Extra Small">
                    <div className="flex flex-col gap-1">
                      <span>{grantee.name}</span>
                      <Typography variant="Regular/Caption">
                        {terminationPersonalEmail}
                      </Typography>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <ShortDate value={terminationDate} />
                  </Table.Cell>
                  <Table.Cell>
                    <ShortDate value={terminationLastDayAtTheCompany} />
                  </Table.Cell>
                  <Table.Cell>
                    {TERMINATION_TYPE_LABEL[terminationType]}
                  </Table.Cell>
                  <Table.Cell>{terminationNewRelationship || "-"}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </RoundedBox>
          <RoundedBox className="overflow-hidden" withBorder>
            <Table className="w-full">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grant</Table.HeaderCell>
                  <Table.HeaderCell>PTEP</Table.HeaderCell>
                  <Table.HeaderCell>Vestings ends on</Table.HeaderCell>
                  <Table.HeaderCell>Cliff</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {grantee.ctmsGrants.map((grant) => {
                  const grantAmendment =
                    (grantAmendments || []).find(
                      (grantAmendment) =>
                        grantAmendment.ctmsGrantId === grant.id,
                    ) || null;

                  // VESTING ENDING ON
                  const amendedVestingEndingOn = getAmendedVestingEndingOn({
                    grantAmendment,
                    terminationDate,
                    terminationLastDayAtTheCompany,
                  });

                  const vestingEndOption = getAmendmentVestingEndOption({
                    grantAmendment,
                  });

                  return (
                    <Table.Row key={grant.label}>
                      <Table.Cell variant="Medium/Extra Small">
                        {grant.label}
                      </Table.Cell>
                      <PTEPCell
                        grant={grant}
                        grantAmendment={grantAmendment}
                        organization={organization}
                      />
                      <Table.Cell variant="Medium/Extra Small">
                        <div className="flex flex-col">
                          <Typography variant="Regular/Extra Small">
                            {VESTING_OPTION_MAP[vestingEndOption]}
                          </Typography>
                          <span>
                            <ShortDate value={amendedVestingEndingOn} />
                          </span>
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        {grantAmendment?.waiveCliff ? "Waived" : "-"}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </RoundedBox>
          {whatsNext.length > 0 && (
            <Typography
              as="div"
              className="flex flex-col gap-2 py-6"
              variant="Regular/Extra Small"
            >
              <Typography variant="Medium/Default">What’s next?</Typography>
              <span>After you confirm the terminations:</span>
              {whatsNext}
            </Typography>
          )}
        </div>
        {organization.granteePortalSettings.displayFullyDilutedValues &&
          terminationType !== "WITH_CAUSE" && (
            <NoticeMessage size="Small" variant="Idea">
              Grantees will still be able to see the value of their grant (based
              on latest company valuation) while they are in their post
              termination exercise period
            </NoticeMessage>
          )}
      </div>
    </GranteeTerminationLayout>
  );
};
