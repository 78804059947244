/**
 * @generated SignedSource<<057e22ca2fae8a7c404642f5a889cab7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Countries_Query$variables = {
  organizationId: string;
};
export type Countries_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Countries_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Countries_Organization">;
  } | null;
};
export type Countries_Query = {
  response: Countries_Query$data;
  variables: Countries_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Countries_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Countries_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": (v4/*: any*/),
            "kind": "FragmentSpread",
            "name": "Countries_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Countries_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSuggestedCountry",
            "kind": "LinkedField",
            "name": "suggestedCountries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationGeography",
                "kind": "LinkedField",
                "name": "organizationGeography",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unlockedAt",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Geography",
                "kind": "LinkedField",
                "name": "geography",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employeeCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantDraftCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "kind": "ScalarField",
            "name": "isAllowedToUnlockCountryForOrganization",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5cd906d96a732ed6e42ce7b503eace2f",
    "id": null,
    "metadata": {},
    "name": "Countries_Query",
    "operationKind": "query",
    "text": "query Countries_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Countries_Organization\n  }\n  me {\n    ...Countries_Viewer_4xMPKw\n    id\n  }\n}\n\nfragment Countries_Organization on Organization {\n  id\n  name\n  suggestedCountries {\n    country {\n      code\n      name\n      emoji\n    }\n    organizationGeography {\n      unlockedAt\n      id\n    }\n    geography {\n      __typename\n    }\n    employeeCount\n    grantCount\n    grantDraftCount\n  }\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment Countries_Viewer_4xMPKw on Account {\n  isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "8ff6a2637c98f629fa4e4dd575cf2a7e";

export default node;
