import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import { InstrumentLegalWatchOuts } from "../../../components/InstrumentLegalWatchOuts";
import { LinkToEquityType } from "../../../components/LinkToEquityType";
import { Page } from "../../../components/Page";
import { Button, LinkButton } from "../../../components/ui/Button";
import { Divider } from "../../../components/ui/Divider";
import { Radio } from "../../../components/ui/Form/Radio";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { isNonEmptyArray } from "../../../helpers/ts-utlity";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { US_COUNTRY_CODE } from "../../../services/Country";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import NotFoundPage from "../../NotFound/NotFound";
import { Instrument_Grantee$key } from "./__generated__/Instrument_Grantee.graphql";
import { Instrument_InstrumentButton_Instrument$key } from "./__generated__/Instrument_InstrumentButton_Instrument.graphql";
import { Instrument_Organization$key } from "./__generated__/Instrument_Organization.graphql";
import { Instrument_Query } from "./__generated__/Instrument_Query.graphql";
import { useAssistedGrantContext } from "./Context";
import { AssistedGrantPageGridLayout, AssistedGrantPageTitle } from "./Shared";

const INSTRUMENT_BUTTON_INSTRUMENT_FRAGMENT = graphql`
  fragment Instrument_InstrumentButton_Instrument on Instrument {
    name
    needsAdditionalFormalities
    equityType {
      fullname
    }
    ...LinkToEquityType_Instrument
  }
`;

const InstrumentButton: React.FC<
  Omit<React.ComponentProps<"button">, "children"> & {
    instrumentFragment: Instrument_InstrumentButton_Instrument$key;
    selected: boolean;
  }
> = ({ className, instrumentFragment, selected, ...props }) => {
  const instrument = useFragment(
    INSTRUMENT_BUTTON_INSTRUMENT_FRAGMENT,
    instrumentFragment,
  );

  return (
    <div
      className={classNames({ "border-[1.5px] border-transparent": !selected })}
    >
      <div
        className={classNames(
          "flex flex-col overflow-hidden rounded-lg",
          className,
          selected
            ? "border-2 border-primary"
            : "border-[0.5px] border-gray-04",
        )}
      >
        <button
          className="flex items-center gap-6 p-6 text-left disabled:cursor-not-allowed"
          role="listitem"
          {...props}
        >
          <Radio checked={selected} />
          <div className="flex flex-col">
            <Typography variant="Medium/Small">{instrument.name}</Typography>
            {instrument.equityType?.fullname && (
              <Typography className="text-black-05" variant="Regular/Small">
                {`(${instrument.equityType.fullname})`}
              </Typography>
            )}
          </div>
        </button>
        {instrument.equityType && (
          <>
            <Divider />
            {instrument.needsAdditionalFormalities ? (
              <Typography
                className="flex bg-gray-01 px-6 py-4 text-gray-09"
                variant="Regular/Caption"
              >
                <div>
                  <span className="font-emoji">⚠️</span>&nbsp;When granting that
                  type of equity incentive,&nbsp;
                  <Typography variant="Medium/Caption">
                    you will have additional formalities at the time of grant or
                    just after.
                  </Typography>
                  <br />
                  Please head over to our geography portal for complete
                  information:&nbsp;
                  <LinkToEquityType
                    className="inline-block"
                    instrumentFragment={instrument}
                    variant="Medium/Caption"
                  />
                </div>
              </Typography>
            ) : (
              <Typography
                className="flex bg-gray-01 px-6 py-4 text-gray-09"
                variant="Regular/Caption"
              >
                Discover all things related to&nbsp;
                <LinkToEquityType
                  instrumentFragment={instrument}
                  variant="Medium/Caption"
                />
                &nbsp;in our geography portal
              </Typography>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment Instrument_Organization on Organization {
    id
    name
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment Instrument_Grantee on Grantee {
    name
    taxResidenceCountry @required(action: THROW) {
      emoji
      name
      code
    }
    isUSCitizen
    workRelationship @required(action: THROW)
    instruments(sortBy: TaxFavored, sortDirection: DESC) {
      id
      awardSuperType
      name
      taxResidenceCountry {
        emoji
        name
      }
      legalWatchOuts {
        messageShouldBeDisplayedOnGrantFlow
        ...InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts
      }
      ...InstrumentLegalWatchOuts_Instrument
      ...Instrument_InstrumentButton_Instrument
    }
  }
`;

const AdminAssistedGrantInstrumentPage_: React.FC<{
  granteeFragment: Instrument_Grantee$key;
  organizationFragment: Instrument_Organization$key;
}> = ({ granteeFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const context = useAssistedGrantContext();
  const navigate = useNavigate();

  const defaultInstrumentId = useMemo(() => {
    if (!grantee) return null;
    if (!isNonEmptyArray(grantee.instruments)) return null;
    if (
      context.state.instrumentId &&
      grantee.instruments.some(
        (instrument) => instrument.id === context.state.instrumentId,
      )
    )
      return context.state.instrumentId;
    if (grantee.instruments.length === 1) {
      return grantee.instruments[0].id;
    }
    return null;
  }, [grantee, context.state.instrumentId]);

  const [selectedInstrumentId, setSelectedInstrumentId] = useState<
    null | string
  >(defaultInstrumentId);

  const selectedInstrument = useMemo(
    () =>
      grantee.instruments.find(
        (instrument) => instrument.id === selectedInstrumentId,
      ) ?? null,
    [grantee, selectedInstrumentId],
  );

  const onNextClick = useCallback(() => {
    if (!selectedInstrument) return;
    context.dispatchAction({
      awardSuperType: selectedInstrument.awardSuperType,
      instrumentId: selectedInstrument.id,
      instrumentName: selectedInstrument.name || "",
      type: "SELECT_INSTRUMENT",
    });
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationAssistedGrantShares, {
        organizationId: organization.id,
      }),
    );
  }, [context, navigate, organization.id, selectedInstrument]);

  const mailtoSubject = encodeURI(
    `[${organization.name}] ISO to US tax citizen`,
  );

  const supportEmailAddress = useApplicationSupportEmailAddress();

  if (!grantee) {
    return <NotFoundPage />;
  }

  return (
    <AssistedGrantPageGridLayout
      aside={
        <>
          {grantee.taxResidenceCountry.code !== US_COUNTRY_CODE &&
            grantee.isUSCitizen && (
              <RoundedBox background="primary" className="space-y-2 p-6">
                <Typography as="div" variant="Medium/Extra Small">
                  It looks like the grantee is a US tax citizen, which may make
                  them eligible to receive ISO instead of NSO.
                </Typography>
                <Typography
                  as="div"
                  className="whitespace-pre-line text-black-05"
                  variant="Regular/Extra Small"
                >
                  If the grantee expects to be taxed in the US at the time they
                  will exercise their options,{" "}
                  <a
                    className="font-semibold text-primary"
                    href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
                  >
                    contact us
                  </a>{" "}
                  (as it could then make sense for them to receive ISO instead
                  of the standard NSO applicable to their country of tax
                  residency).
                </Typography>
              </RoundedBox>
            )}
          {selectedInstrument?.legalWatchOuts
            ?.messageShouldBeDisplayedOnGrantFlow ? (
            <InstrumentLegalWatchOuts
              instrumentFragment={selectedInstrument}
              legalWatchOutsFragment={selectedInstrument.legalWatchOuts}
            />
          ) : null}
        </>
      }
      header={
        <AssistedGrantPageTitle>
          What type of equity incentive do you want to grant to {grantee.name}?
        </AssistedGrantPageTitle>
      }
    >
      <div className="space-y-4">
        <Typography
          as="div"
          className="rounded bg-gray-01 px-4 py-3 !leading-7"
          variant="Regular/Extra Small"
        >
          Considering&nbsp;
          <span className="text-primary">{grantee.name}</span>
          &nbsp;has their tax residence in&nbsp;
          <Tag color="gray">
            {grantee.taxResidenceCountry.emoji}{" "}
            {grantee.taxResidenceCountry.name}
          </Tag>
          &nbsp;and is working as a&nbsp;
          <Tag color="primary">
            {
              WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
                .singularLabel
            }
          </Tag>
          , the following equity types are possible:
        </Typography>
        {grantee.instruments.map((instrument) => (
          <InstrumentButton
            className="w-full"
            disabled={context.isParameterImmutable("instrumentId")}
            instrumentFragment={instrument}
            key={instrument.id}
            onClick={() => {
              setSelectedInstrumentId(instrument.id);
            }}
            role="listitem"
            selected={instrument.id === selectedInstrumentId}
          />
        ))}
        <div className="flex justify-between">
          <LinkButton
            leftIcon={<ArrowLeftIcon />}
            to={generatePath(
              APPLICATION_ROUTES.organizationAssistedGrantGrantee,
              {
                organizationId: organization.id,
              },
            )}
            variant="Tertiary Link"
          >
            Back
          </LinkButton>
          <Button
            data-cy="instrumentNextStepButton"
            disabled={!selectedInstrument}
            onClick={onNextClick}
            rightIcon={<ArrowRightIcon />}
            type="button"
          >
            Next
          </Button>
        </div>
      </div>
    </AssistedGrantPageGridLayout>
  );
};

const QUERY = graphql`
  query Instrument_Query(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...Instrument_Organization
    }
    grantee(id: $granteeId) @required(action: THROW) {
      ...Instrument_Grantee
    }
  }
`;

const AdminAssistedGrantInstrumentPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const context = useAssistedGrantContext();

  if (!context.state.granteeId) {
    throw new Error("grantee id not found in context");
  }

  const { grantee, organization } = useLazyLoadQuery<Instrument_Query>(QUERY, {
    granteeId: context.state.granteeId,
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Instrument"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - instrument`}
    >
      <AdminAssistedGrantInstrumentPage_
        granteeFragment={grantee}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminAssistedGrantInstrumentPage;
