import { FormattedRelativeTime } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { WarningNoticeMessageWithSlideOverExplanation } from "../pages/Admin/Equity/PrepareYourGrants/WarningNoticeMessageWithSlideOverExplanation";
import { APPLICATION_ROUTES } from "../paths";
import {
  ValuationWarningMessage_Organization$data,
  ValuationWarningMessage_Organization$key,
} from "./__generated__/ValuationWarningMessage_Organization.graphql";
import {
  ValuationType,
  ValuationWarningMessage_ValuationWarning$key,
  ValuationWarningReason,
} from "./__generated__/ValuationWarningMessage_ValuationWarning.graphql";
import { FairMarketValueSendToRewiewSliderRemote } from "./FairMarketValueSendToRewiewSlider";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ValuationWarningMessage_Organization on Organization {
    id
    latestFairMarketValue {
      remainingDaysBeforeExpiration
    }
    lastestUKValuation {
      remainingDaysBeforeExpiration
    }
  }
`;

const VALUATION_WARNING_FRAGMENT = graphql`
  fragment ValuationWarningMessage_ValuationWarning on OrganizationValuationWarning {
    reason
    valuationType
  }
`;

type ValuationWarningComponent = React.FC<{
  onValuationSentForReview: () => void;
  organization: ValuationWarningMessage_Organization$data;
}>;

const NoValuation409AWarning: ValuationWarningComponent = () => {
  const applicationName = useApplicationName();
  return (
    <WarningNoticeMessageWithSlideOverExplanation
      helpEmailSubject="I have no records of a 409A valuation"
      whatDoesItMean={
        <>
          Since you signed up in {applicationName} application, there is no
          record of a 409A certificate. A 409A certificate is crucial and
          mandatory to issue option grants.
        </>
      }
      whatShouldIDo={
        <>
          Get a 409A certificate using a valuation firm like Carta valuations
          LLC or Prolific Labs Inc. dba Pulley. Once the certificate is
          generated, you&apos;ll be asked to upload it in the platform and get
          board approved through our board approval workflow. The earlier the
          409A certificate is emitted, the quicker the process will be.
        </>
      }
    >
      There are no records of a 409A valuation.
    </WarningNoticeMessageWithSlideOverExplanation>
  );
};

const NoValuationUKValuationWarning: ValuationWarningComponent = () => (
  <WarningNoticeMessageWithSlideOverExplanation
    helpEmailSubject="My EMI has not been uploaded"
    whatDoesItMean="Drafts cannot be sent to your board members for approval until you’ve uploaded your EMI valuation."
  >
    Your EMI valuation has not been uploaded.
  </WarningNoticeMessageWithSlideOverExplanation>
);

const ValuationExpired409AWarning: ValuationWarningComponent = () => (
  <WarningNoticeMessageWithSlideOverExplanation
    helpEmailSubject="My 409A has expired"
    whatDoesItMean={
      <>
        You are no longer allowed to issue new option grants. You may still
        draft option grants but you won&apos;t be able to create the board
        consent and approve option grants. This is a safety measure preventing
        you from breaching Section 409A of the (US) Internal Revenue Code.
      </>
    }
    whatShouldIDo={
      <>
        Before the expiration date of your 409A valuation, you should request
        and obtain a new 409A valuation. You can request one via your cap table
        management solution. Once you&apos;ve obtained your 409A valuation, you
        can have it approved by your board in our app.
      </>
    }
  >
    Your 409A valuation has expired.
  </WarningNoticeMessageWithSlideOverExplanation>
);

const ValuationExpiredUKValuationWarning: ValuationWarningComponent = () => (
  <WarningNoticeMessageWithSlideOverExplanation
    helpEmailSubject="My EMI has expired"
    whatDoesItMean={`Drafts cannot be sent to your board members for approval until you’ve received a new EMI valuation.`}
  >
    Your EMI valuation has expired.
  </WarningNoticeMessageWithSlideOverExplanation>
);

const ValuationExpiringSoon409AWarning: ValuationWarningComponent = ({
  organization,
}) => (
  <WarningNoticeMessageWithSlideOverExplanation
    helpEmailSubject="My 409A will expire soon"
    whatDoesItMean={
      <span className="first-letter:uppercase">
        <FormattedRelativeTime
          unit="day"
          value={
            organization.latestFairMarketValue?.remainingDaysBeforeExpiration
          }
        />
        , you won&apos;t be allowed to issue new options grants. You&apos;ll
        still be able to prepare drafts but not to send them to the board for
        approval.
      </span>
    }
    whatShouldIDo={
      <>
        Before the expiration date of your 409A valuation, you should request
        and obtain a new 409A valuation. You can request one via your cap table
        management solution. Once you&apos;ve obtained your 409A valuation, you
        can have it approved by your board in our app.
      </>
    }
  >
    Your 409A certificate will expire{" "}
    <FormattedRelativeTime
      unit="day"
      value={organization.latestFairMarketValue?.remainingDaysBeforeExpiration}
    />
    .
  </WarningNoticeMessageWithSlideOverExplanation>
);

const ValuationExpiringSoonUKValuationWarning: ValuationWarningComponent = ({
  organization,
}) => (
  <WarningNoticeMessageWithSlideOverExplanation
    helpEmailSubject="My EMI will expire soon"
    whatDoesItMean={
      <span className="first-letter:uppercase">
        <FormattedRelativeTime
          unit="day"
          value={organization.lastestUKValuation?.remainingDaysBeforeExpiration}
        />
        , you won’t be allowed to make new grants. Grants pending approval will
        be voided.
      </span>
    }
  >
    Your EMI valuation will expire{" "}
    <FormattedRelativeTime
      unit="day"
      value={organization.lastestUKValuation?.remainingDaysBeforeExpiration}
    />
    .
  </WarningNoticeMessageWithSlideOverExplanation>
);

const ValuationManuallyInvalidated409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      helpEmailSubject="My 409A was invalidated"
      whatDoesItMean={
        <>
          We have detected elements (e.g., changes in your cap table) that
          indicate that material events having an impact on the validity of your
          409A valuation could have taken place. As a consequence, we have
          temporarily prevented you from issuing new equity grants until your
          situation is clarified.
        </>
      }
      whatShouldIDo={
        <>
          Contact our support team so they can check with you if the events
          detected should be considered as impacting the validity of your 409A
          valuation or not.
        </>
      }
    >
      Your 409A valuation has been marked as non valid anymore.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ValuationNotBoardApproved409AWarning: ValuationWarningComponent = ({
  onValuationSentForReview,
  organization,
}) => {
  return (
    <FairMarketValueSendToRewiewSliderRemote.Provider>
      <NoticeMessage
        size="Large"
        title="Your new 409A requires board approval."
        variant="Danger"
      >
        <FairMarketValueSendToRewiewSliderRemote.Provider>
          <FairMarketValueSendToRewiewSliderRemote.Controller
            render={({ open }) => (
              <button
                onClick={() => {
                  open({
                    data: {
                      onValuationSentForReview,
                      organizationId: organization.id,
                    },
                  });
                }}
              >
                <Typography
                  className="text-primary"
                  variant="Medium/Extra Small"
                >
                  Validate your new 409A
                </Typography>
              </button>
            )}
          />
        </FairMarketValueSendToRewiewSliderRemote.Provider>
      </NoticeMessage>
    </FairMarketValueSendToRewiewSliderRemote.Provider>
  );
};

const ValuationPendingBoardApproval409AWarning: ValuationWarningComponent =
  () => (
    <WarningNoticeMessageWithSlideOverExplanation
      helpEmailSubject="My 409A is pending board approval"
      whatDoesItMean={
        <>
          You have a 409A valuation in place, but it still needs to be approved
          by your board before you&apos;re allowed to issue new equity grants.
        </>
      }
      whatShouldIDo={
        <>
          Technically nothing is required on your side anymore but your board
          still needs to approve the new 409A valuation. You can send your board
          reminders if there is a task to do so in the overview.
        </>
      }
    >
      Your new 409A valuation is pending board approval.
    </WarningNoticeMessageWithSlideOverExplanation>
  );

const ValuationNotSentToBoardForApproval409AWarning: ValuationWarningComponent =
  ({ organization }) => (
    <NoticeMessage
      size="Large"
      title="Your new 409A requires a board approval."
      variant="Danger"
    >
      <Link
        to={generatePath(
          APPLICATION_ROUTES.organizationFairMarketValueApprovalsBoardApproval,
          {
            organizationId: organization.id,
          },
        )}
      >
        <Typography className="text-primary" variant="Medium/Extra Small">
          Generate board consent
        </Typography>
      </Link>
    </NoticeMessage>
  );

const ValuationUnderReview409AWarning: ValuationWarningComponent = () => {
  const applicationName = useApplicationName();
  return (
    <WarningNoticeMessageWithSlideOverExplanation
      helpEmailSubject="My 409A is under review"
      whatDoesItMean={
        <>
          Your new 409A valuation has been submitted to the {applicationName}{" "}
          team for review.
        </>
      }
      whatShouldIDo={
        <>
          Once reviewed, you&apos;ll be prompted to send it to your board for
          approval through our board approval workflow.
        </>
      }
    >
      Your 409A valuation is currently under review.
    </WarningNoticeMessageWithSlideOverExplanation>
  );
};

const VALUATION_WARNING_COMPONENTS_MAP: Record<
  ValuationWarningReason,
  Record<ValuationType, null | ValuationWarningComponent>
> = {
  NO_VALUATION: {
    FMV_409A: NoValuation409AWarning,
    UK_VALUATION: NoValuationUKValuationWarning,
  },
  VALUATION_EXPIRED: {
    FMV_409A: ValuationExpired409AWarning,
    UK_VALUATION: ValuationExpiredUKValuationWarning,
  },
  VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS: {
    FMV_409A: ValuationExpiringSoon409AWarning,
    UK_VALUATION: ValuationExpiringSoonUKValuationWarning,
  },
  VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS: {
    FMV_409A: ValuationExpiringSoon409AWarning,
    UK_VALUATION: ValuationExpiringSoonUKValuationWarning,
  },
  VALUATION_MANUALLY_INVALIDATED: {
    FMV_409A: ValuationManuallyInvalidated409AWarning,
    UK_VALUATION: null,
  },
  VALUATION_NOT_BOARD_APPROVED: {
    FMV_409A: ValuationNotBoardApproved409AWarning,
    UK_VALUATION: null,
  },
  VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL: {
    FMV_409A: ValuationNotSentToBoardForApproval409AWarning,
    UK_VALUATION: null,
  },
  VALUATION_PENDING_BOARD_APPROVAL: {
    FMV_409A: ValuationPendingBoardApproval409AWarning,
    UK_VALUATION: null,
  },
  VALUATION_UNDER_REVIEW: {
    FMV_409A: ValuationUnderReview409AWarning,
    UK_VALUATION: null,
  },
};

export const ValuationWarningMessage: React.FC<{
  onValuationSentForReview: () => void;
  organizationFragment: ValuationWarningMessage_Organization$key;

  valuationWarningFragment: ValuationWarningMessage_ValuationWarning$key;
}> = ({
  onValuationSentForReview,
  organizationFragment,
  valuationWarningFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const valuationWarning = useFragment(
    VALUATION_WARNING_FRAGMENT,
    valuationWarningFragment,
  );

  const Component =
    VALUATION_WARNING_COMPONENTS_MAP[valuationWarning.reason][
      valuationWarning.valuationType
    ];

  if (!Component) return null;

  return (
    <Component
      onValuationSentForReview={onValuationSentForReview}
      organization={organization}
    />
  );
};
