/**
 * @generated SignedSource<<3e50972fb86a6ad305d244a51fbd32a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Countries_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly suggestedCountries: ReadonlyArray<{
    readonly country: {
      readonly code: string;
      readonly emoji: string;
      readonly name: string;
    };
    readonly employeeCount: number;
    readonly geography: {
      readonly __typename: "Geography";
    } | null;
    readonly grantCount: number;
    readonly grantDraftCount: number;
    readonly organizationGeography: {
      readonly unlockedAt: string;
    } | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "Countries_Organization";
};
export type Countries_Organization$key = {
  readonly " $data"?: Countries_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Countries_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Countries_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSuggestedCountry",
      "kind": "LinkedField",
      "name": "suggestedCountries",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationGeography",
          "kind": "LinkedField",
          "name": "organizationGeography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unlockedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Geography",
          "kind": "LinkedField",
          "name": "geography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "employeeCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantDraftCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "257cf76da228c1df46ff5e56c8631f47";

export default node;
