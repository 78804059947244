/**
 * @generated SignedSource<<05e7d78d1af5dbbead535468d609da3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateInformationItem_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "UpdateInformationItem_Organization";
};
export type UpdateInformationItem_Organization$key = {
  readonly " $data"?: UpdateInformationItem_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateInformationItem_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateInformationItem_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "faaaaa5056f3177c6903b479127407c1";

export default node;
