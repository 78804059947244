/**
 * @generated SignedSource<<a42c51856d03283831cb80d9661b8a8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsEditionPage_EquityType$data = {
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionBlock_EquityType">;
  readonly " $fragmentType": "LegalWatchOutsEditionPage_EquityType";
};
export type LegalWatchOutsEditionPage_EquityType$key = {
  readonly " $data"?: LegalWatchOutsEditionPage_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionPage_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LegalWatchOutsEditionPage_EquityType",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWatchOutsEditionBlock_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "584981158c2462f648d821765af45f3e";

export default node;
