/**
 * @generated SignedSource<<f4a3b764116e5a082b9919d81e4c3a64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeFormSlide_Organization$data = {
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectHRISProvidersMessage_Organization" | "GranteeForm_Organization">;
  readonly " $fragmentType": "GranteeFormSlide_Organization";
};
export type GranteeFormSlide_Organization$key = {
  readonly " $data"?: GranteeFormSlide_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeFormSlide_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeForm_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectHRISProvidersMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7d24c8100f2b42f67a9b652c2bb6c022";

export default node;
