import { durationToDays } from "./duration-utils";

export type PostTerminationExercisePeriod = {
  duration: number;
  unit: DurationUnit;
};

export type PtepDurationUnit = DurationUnit;
type DurationUnit = "day" | "month" | "year";

export const arePtepEquals = (
  ptep1: PostTerminationExercisePeriod,
  ptep2: PostTerminationExercisePeriod,
) => ptep1.duration === ptep2.duration && ptep1.unit === ptep2.unit;

export const isPtepNotNull = (
  ptep?:
    | null
    | Partial<PostTerminationExercisePeriod>
    | Readonly<PostTerminationExercisePeriod>,
): ptep is PostTerminationExercisePeriod => {
  return typeof ptep?.duration === "number" && ptep?.unit ? true : false;
};

export const ptepDurationToDays = (
  ptep: PostTerminationExercisePeriod,
): number =>
  durationToDays({
    duration: ptep.duration,
    unit: ptep.unit,
  });

export type OrganizationPostTerminationExercisePeriod =
  | {
      readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
      readonly duration: number;
      readonly durationUnit: DurationUnit;
    }
  | {
      readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
      readonly duration: number;
      readonly durationUnit: DurationUnit;
      readonly extensionDuration: number;
      readonly extensionDurationUnit: DurationUnit;
      readonly thresholdForExtensionInYears: number;
    };

export const areOrganizationPostTerminationExercisePeriodsEqual = (
  organizationPostTerminationExercisePeriodA: OrganizationPostTerminationExercisePeriod,
  organizationPostTerminationExercisePeriodB: OrganizationPostTerminationExercisePeriod,
) => {
  switch (organizationPostTerminationExercisePeriodA.__typename) {
    case "OrganizationFixedPostTerminationExercisePeriod": {
      if (
        organizationPostTerminationExercisePeriodB.__typename !==
        organizationPostTerminationExercisePeriodA.__typename
      )
        return false;
      return (
        organizationPostTerminationExercisePeriodA.duration ===
          organizationPostTerminationExercisePeriodB.duration &&
        organizationPostTerminationExercisePeriodA.durationUnit ===
          organizationPostTerminationExercisePeriodB.durationUnit
      );
    }
    case "OrganizationVariablePostTerminationExercisePeriod": {
      if (
        organizationPostTerminationExercisePeriodB.__typename !==
        organizationPostTerminationExercisePeriodA.__typename
      )
        return false;
      return (
        organizationPostTerminationExercisePeriodA.duration ===
          organizationPostTerminationExercisePeriodB.duration &&
        organizationPostTerminationExercisePeriodA.durationUnit ===
          organizationPostTerminationExercisePeriodB.durationUnit &&
        organizationPostTerminationExercisePeriodA.extensionDuration ===
          organizationPostTerminationExercisePeriodB.extensionDuration &&
        organizationPostTerminationExercisePeriodA.extensionDurationUnit ===
          organizationPostTerminationExercisePeriodB.extensionDurationUnit &&
        organizationPostTerminationExercisePeriodA.thresholdForExtensionInYears ===
          organizationPostTerminationExercisePeriodB.thresholdForExtensionInYears
      );
    }
  }
};
