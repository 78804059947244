import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  CTMSGrantsListSlideOver_CTMSGrants$data,
  CTMSGrantsListSlideOver_CTMSGrants$key,
} from "./__generated__/CTMSGrantsListSlideOver_CTMSGrants.graphql";
import {
  CTMSGrantsListSlideOver_Organization$data,
  CTMSGrantsListSlideOver_Organization$key,
} from "./__generated__/CTMSGrantsListSlideOver_Organization.graphql";
import { NoticeMessage } from "./ui/NoticeMessage";
import { SearchBar } from "./ui/SearchBar";
import { SlideOver } from "./ui/SlideOver";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CTMSGrantsListSlideOver_Organization on Organization {
    id
  }
`;

const CTMS_GRANTS_FRAGMENT = graphql`
  fragment CTMSGrantsListSlideOver_CTMSGrants on CTMSGrant
  @relay(plural: true) {
    id
    label
    grantee {
      name
      email
    }
  }
`;

type State =
  | {
      ctmsGrantsFragment: CTMSGrantsListSlideOver_CTMSGrants$key;
      show: true;
      title: React.ReactNode;
    }
  | {
      ctmsGrantsFragment: CTMSGrantsListSlideOver_CTMSGrants$key | null;
      show: false;
      title: null | React.ReactNode;
    };

export const useCTMSGrantsListSlideOverState = () => {
  const [state, setState] = useState<State>({
    ctmsGrantsFragment: null,
    show: false,
    title: null,
  });

  const open = ({
    ctmsGrantsFragment,
    title,
  }: {
    ctmsGrantsFragment: CTMSGrantsListSlideOver_CTMSGrants$key;
    title: React.ReactNode;
  }) => {
    setState({
      ctmsGrantsFragment,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
};

const GrantsList: React.FC<{
  ctmsGrants: CTMSGrantsListSlideOver_CTMSGrants$data;
  organization: CTMSGrantsListSlideOver_Organization$data;
}> = ({ ctmsGrants, organization }) => {
  if (isEmpty(ctmsGrants)) {
    return (
      <NoticeMessage size="Small">
        No grant matches your current filter.
      </NoticeMessage>
    );
  }

  return (
    <div className="space-y-6">
      <div>
        {ctmsGrants.map((ctmsGrant) => (
          <Link
            key={ctmsGrant.label}
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityCtmsGrant"],
              {
                ctmsGrantId: ctmsGrant.id,
                organizationId: organization.id,
              },
            )}
          >
            <div className="group flex cursor-pointer items-center rounded border-[0.5px] border-transparent p-2 transition-all hover:border-gray-04">
              <Tag color="gray">{ctmsGrant.label}</Tag>
              <div className="flex-grow space-y-1 px-4 py-2">
                <Typography as="div" variant="Regular/Extra Small">
                  {ctmsGrant.grantee.name}
                </Typography>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  {ctmsGrant.grantee.email}
                </Typography>
              </div>
              <div className="px-4">
                <ArrowTopRightOnSquareIcon className="h-5 w-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const SlideOverContent: React.FC<{
  ctmsGrantsFragment: CTMSGrantsListSlideOver_CTMSGrants$key;
  organization: CTMSGrantsListSlideOver_Organization$data;
}> = ({ ctmsGrantsFragment, organization }) => {
  const ctmsGrants = useFragment(CTMS_GRANTS_FRAGMENT, ctmsGrantsFragment);

  const [fullTextFilter, setFullTextFilter] = useState<string>("");
  const filteredCTMSGrants = useMemo(() => {
    const fuse = new Fuse(ctmsGrants, {
      keys: ["label", "grantee.name", "grantee.email"],
    });

    if (isEmpty(fullTextFilter)) {
      return ctmsGrants;
    }

    return fuse.search(fullTextFilter).map((result) => result.item);
  }, [ctmsGrants, fullTextFilter]);

  return (
    <>
      <SearchBar
        onChange={setFullTextFilter}
        placeholder="Search grants..."
        value={fullTextFilter}
      />
      <GrantsList ctmsGrants={filteredCTMSGrants} organization={organization} />
    </>
  );
};

export const CTMSGrantsListSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: CTMSGrantsListSlideOver_Organization$key;
  state: State;
}> = ({ onClose, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <SlideOver
      floating
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={state.show}
      width="2xl"
    >
      <div className="space-y-6 p-6">
        <Typography
          as="div"
          className="w-full text-center"
          variant="Medium/Small"
        >
          {state.title}
        </Typography>
        {state.show && (
          <SlideOverContent
            ctmsGrantsFragment={state.ctmsGrantsFragment}
            organization={organization}
          />
        )}
      </div>
    </SlideOver>
  );
};
