/**
 * @generated SignedSource<<3888344ad5baa9099c3e563b691e1bff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type PortalAccessTag_Grantee$data = {
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly firstSeenAt: string | null;
  readonly hasPortalAccess: boolean;
  readonly hasVisitedTheirPortal: boolean;
  readonly lastSeenAt: string | null;
  readonly " $fragmentType": "PortalAccessTag_Grantee";
};
export type PortalAccessTag_Grantee$key = {
  readonly " $data"?: PortalAccessTag_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortalAccessTag_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortalAccessTag_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasVisitedTheirPortal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastSeenAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstSeenAt",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "ddfcf8d6f0db0efeb2ba0d0d2fd46bae";

export default node;
