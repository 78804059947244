/**
 * @generated SignedSource<<dbd615a8c30de80386702cf00b812c72>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type GrantWarning = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsHeader_EasopGrant$data = {
  readonly createdAt: string;
  readonly formattedGranteeName: string;
  readonly grantStatus: EasopGrantStatus;
  readonly grantee: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsEquityGridLevelTag_Grantee">;
  };
  readonly instrumentValuationType: ValuationType;
  readonly label: string;
  readonly quantityGranted: number;
  readonly warnings: ReadonlyArray<GrantWarning>;
  readonly " $fragmentType": "DraftGrantDetailsHeader_EasopGrant";
};
export type DraftGrantDetailsHeader_EasopGrant$key = {
  readonly " $data"?: DraftGrantDetailsHeader_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsHeader_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsHeader_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedGranteeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instrumentValuationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeDetailsEquityGridLevelTag_Grantee"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "aa20cdbef2a5170189eabee10694c670";

export default node;
