/**
 * @generated SignedSource<<469d4ad6820f4107c3a0eec6bbc9de67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EarlyExerciseBeneficialPage_Instrument$data = ReadonlyArray<{
  readonly description: string;
  readonly id: string;
  readonly isEarlyExerciseBeneficial: boolean;
  readonly taxResidenceCountry: {
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly " $fragmentType": "EarlyExerciseBeneficialPage_Instrument";
}>;
export type EarlyExerciseBeneficialPage_Instrument$key = ReadonlyArray<{
  readonly " $data"?: EarlyExerciseBeneficialPage_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialPage_Instrument">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EarlyExerciseBeneficialPage_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEarlyExerciseBeneficial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "722d6dfa92620ff57f0cc9f5448f12f9";

export default node;
