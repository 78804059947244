/**
 * @generated SignedSource<<2d0d87249eb2f2f8f2d49df2102cacf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CleanupGrantees_Query$variables = {
  organizationId: string;
};
export type CleanupGrantees_Query$data = {
  readonly organization: {
    readonly granteeCleanupSuggestionsCount: number;
    readonly granteeCleanupSuggestionsWithIgnoredCount: number;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CleanupGrantees_Organization">;
  } | null;
};
export type CleanupGrantees_Query = {
  response: CleanupGrantees_Query$data;
  variables: CleanupGrantees_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "granteeCleanupSuggestionsCount",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "ignored",
    "value": true
  }
],
v6 = {
  "alias": "granteeCleanupSuggestionsWithIgnoredCount",
  "args": (v5/*: any*/),
  "kind": "ScalarField",
  "name": "granteeCleanupSuggestionsCount",
  "storageKey": "granteeCleanupSuggestionsCount(ignored:true)"
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CleanupGrantees_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CleanupGrantees_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CleanupGrantees_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": "ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
            "args": (v5/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(ignored:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": "ignoredHRISProviderEmployeesSuggestedAsNewGrantee",
            "args": (v5/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": "HRISProviderEmployeesSuggestedAsNewGrantee(ignored:true)"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          {
            "alias": "ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
            "args": (v5/*: any*/),
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
            "plural": true,
            "selections": (v7/*: any*/),
            "storageKey": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(ignored:true)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9688cef4eb18a3a4c622c0f9eb3eb171",
    "id": null,
    "metadata": {},
    "name": "CleanupGrantees_Query",
    "operationKind": "query",
    "text": "query CleanupGrantees_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    granteeCleanupSuggestionsCount\n    granteeCleanupSuggestionsWithIgnoredCount: granteeCleanupSuggestionsCount(ignored: true)\n    ...CleanupGrantees_Organization\n  }\n}\n\nfragment CleanupGrantees_Organization on Organization {\n  id\n  name\n  HRISProviderEmployeesSuggestedToUpdateLinkedGrantee {\n    __typename\n    id\n  }\n  ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee: HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(ignored: true) {\n    __typename\n    id\n  }\n  HRISProviderEmployeesSuggestedAsNewGrantee {\n    __typename\n    id\n  }\n  ignoredHRISProviderEmployeesSuggestedAsNewGrantee: HRISProviderEmployeesSuggestedAsNewGrantee(ignored: true) {\n    __typename\n    id\n  }\n  HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee {\n    __typename\n    id\n  }\n  ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(ignored: true) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "13389b404da8485bd964488aa7555938";

export default node;
