import React from "react";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { RedirectHandler } from "../../../../components/RedirectHandler";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation } from "./__generated__/OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation.graphql";
import { OrganizationSettingsIntegrationRemote_Query } from "./__generated__/OrganizationSettingsIntegrationRemote_Query.graphql";
import OrganizationSettingsIntegrationView from "./OrganizationSettingsIntegration";
import remoteLargeLogoSrc from "./remote-large-logo.png";

const QUERY = graphql`
  query OrganizationSettingsIntegrationRemote_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      isConnectedToRemote
      isOriginatingFromRemoteEquity
      ...OrganizationSettingsIntegration_Organization
    }
  }
`;

const GENERATE_AUTHORIZATION_FLOW_URL_MUTATION = graphql`
  mutation OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation(
    $organizationId: OrganizationId!
    $returnURL: String!
  ) {
    generateOrganizationRemoteAuthorizationFlowURL(
      organizationId: $organizationId
      returnURL: $returnURL
    )
  }
`;

const OrganizationSettingsIntegrationRemote: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsIntegrationRemote_Query>(
    QUERY,
    { organizationId },
  );

  const [triggerMutation] =
    useSafeMutation<OrganizationSettingsIntegrationRemote_GenerateOrganizationFlowURLMutation>(
      GENERATE_AUTHORIZATION_FLOW_URL_MUTATION,
    );

  const generateAuthorizationUrl = async () => {
    const result = await triggerMutation({
      variables: { organizationId, returnURL: window.location.href },
    });

    return result.generateOrganizationRemoteAuthorizationFlowURL;
  };

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (query.organization.isOriginatingFromRemoteEquity) {
    return (
      <RedirectHandler
        to={generatePath(
          APPLICATION_ROUTES["organizationSettingsIntegrations"],
          {
            organizationId: query.organization.id,
          },
        )}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Remote Integration Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} Remote integration settings`}
    >
      <OrganizationSettingsIntegrationView
        capabilities={[
          {
            description:
              "When adding a new grantee in Easop, the integration will automatically populate the grantee’s personal details such as name, email address, contract start date, country of residence, and work relationship. In addition to preventing clerical errors, this saves time and effort, as you don’t have to manually enter this information.",
            key: "auto-fill-data",
            title: "Auto-fill grantees’ information at creation",
          },
        ]}
        description="With the integration in place, you can easily monitor and manage equity grants for your international hires. This includes tracking vesting schedules, issuing equity documents, and managing tax compliance."
        generateAuthorizationUrl={generateAuthorizationUrl}
        integrationColor="#5F4EDB"
        integrationLogoSrc={remoteLargeLogoSrc}
        integrationName="Remote"
        integrationRequirements={[
          {
            content: "Admin access to your Easop account",
            key: "easop_access",
          },
          {
            content: "An active Remote subscription",
            key: "remote_subscription",
          },
        ]}
        integrationWebsiteName="remote.com"
        integrationWebsiteUrl="https://remote.com"
        isConnected={query.organization.isConnectedToRemote}
        organizationFragment={query.organization}
        provider="REMOTE"
        title="Monitor and manage equity grants"
      />
    </Page>
  );
};

export default OrganizationSettingsIntegrationRemote;
