/**
 * @generated SignedSource<<0d453332f6a5f4d99041713886c17357>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsBoardMembers_Organization$data = {
  readonly boardMembers: ReadonlyArray<{
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Item_BoardMember">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "OrganizationSettingsBoardMembers_Organization";
};
export type OrganizationSettingsBoardMembers_Organization$key = {
  readonly " $data"?: OrganizationSettingsBoardMembers_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsBoardMembers_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationSettingsBoardMembers_Item_BoardMember"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "adcf54616add7773e7df086de1341099";

export default node;
