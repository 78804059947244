/**
 * @generated SignedSource<<6ce84f5acccf400b50bf91a499287e0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationRoleSwitcher_ResetDatabase_Mutation$variables = Record<PropertyKey, never>;
export type OrganizationRoleSwitcher_ResetDatabase_Mutation$data = {
  readonly resetDatabase: boolean;
};
export type OrganizationRoleSwitcher_ResetDatabase_Mutation = {
  response: OrganizationRoleSwitcher_ResetDatabase_Mutation$data;
  variables: OrganizationRoleSwitcher_ResetDatabase_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resetDatabase",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationRoleSwitcher_ResetDatabase_Mutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationRoleSwitcher_ResetDatabase_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "8e367f3ab6326906690d8d5602e968c3",
    "id": null,
    "metadata": {},
    "name": "OrganizationRoleSwitcher_ResetDatabase_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationRoleSwitcher_ResetDatabase_Mutation {\n  resetDatabase\n}\n"
  }
};
})();

(node as any).hash = "c2a435ab8dd757b461ab1876ca3c8d23";

export default node;
