/**
 * @generated SignedSource<<9aff3c6e57fc3324d30959c4535a326c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VestingSchedules_DeleteVestingScheduleMutation$variables = {
  vestingScheduleId: any;
};
export type VestingSchedules_DeleteVestingScheduleMutation$data = {
  readonly deleteVestingSchedule: {
    readonly __typename: "Organization";
  };
};
export type VestingSchedules_DeleteVestingScheduleMutation = {
  response: VestingSchedules_DeleteVestingScheduleMutation$data;
  variables: VestingSchedules_DeleteVestingScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vestingScheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vestingScheduleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedules_DeleteVestingScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VestingSchedules_DeleteVestingScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23350b95dca07a21951f59fb50cf3af3",
    "id": null,
    "metadata": {},
    "name": "VestingSchedules_DeleteVestingScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation VestingSchedules_DeleteVestingScheduleMutation(\n  $vestingScheduleId: VestingScheduleId!\n) {\n  deleteVestingSchedule(id: $vestingScheduleId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "870e56955d473d843084e5c0332fdee9";

export default node;
