/**
 * @generated SignedSource<<5a525ab80eac0503ced697ea7ad2498c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsPage_Organization$data = {
  readonly boardConsents: ReadonlyArray<{
    readonly signedDocument: {
      readonly __typename: "Document";
    } | null;
  }>;
  readonly easopGrants: ReadonlyArray<{
    readonly signedBoardConsentDocument: {
      readonly __typename: "Document";
    } | null;
  }>;
  readonly id: string;
  readonly miscellaneousLegalDocuments: ReadonlyArray<{
    readonly __typename: "OrganizationMiscellaneousLegalDocument";
  }>;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly __typename: "OrganizationGeography";
  }>;
  readonly " $fragmentType": "DocumentsPage_Organization";
};
export type DocumentsPage_Organization$key = {
  readonly " $data"?: DocumentsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsPage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentsPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "signedDocument",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "signedBoardConsentDocument",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationMiscellaneousLegalDocument",
      "kind": "LinkedField",
      "name": "miscellaneousLegalDocuments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "e643353abcfdf423676be7ef17a3dba3";

export default node;
