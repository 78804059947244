/**
 * @generated SignedSource<<19810db9e437d1b9d6df6a1a55270d5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsApprovalPage_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly requiresDocumentApproval: boolean;
  readonly " $fragmentType": "DocumentsApprovalPage_Organization";
}>;
export type DocumentsApprovalPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: DocumentsApprovalPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsApprovalPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DocumentsApprovalPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiresDocumentApproval",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "60c79af0327c85e3018b0fc6b33940b0";

export default node;
