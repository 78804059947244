import classNames from "classnames";
import React from "react";

export const TITLE_VARIANTS = {
  "Title 1": {
    as: "h1",
    style: "font-title1",
  },
  "Title 2": {
    as: "h2",
    style: "font-title2",
  },
  "Title 3": {
    as: "h3",
    style: "font-title3",
  },
  "Title 4": {
    as: "h4",
    style: "font-title4",
  },
  "Title 5": {
    as: "h5",
    style: "font-title5",
  },
  "Title 6": {
    as: "h6",
    style: "font-title6",
  },
} as const;

export type TitleVariant = keyof typeof TITLE_VARIANTS;

export const TITLE_VARIANT_TYPES = Object.keys(
  TITLE_VARIANTS,
) as TitleVariant[];

export const TYPOGRAPHY_VARIANTS = {
  "Medium/Caption": {
    as: "span",
    style: "font-captionMedium",
  },
  "Medium/Default": {
    as: "span",
    style: "font-mediumDefault",
  },
  "Medium/Extra Large": {
    as: "h3",
    style: "font-mediumExtraLarge",
  },
  "Medium/Extra Small": {
    as: "span",
    style: "font-mediumExtraSmall",
  },
  "Medium/Large": {
    as: "h4",
    style: "font-mediumLarge",
  },
  "Medium/Small": {
    as: "span",
    style: "font-mediumSmall",
  },
  "Medium/XS-Caption": {
    as: "span",
    style: "font-captionXsMedium",
  },
  "Regular/Caption": {
    as: "span",
    style: "font-captionRegular",
  },
  "Regular/Default": {
    as: "span",
    style: "font-regularDefault",
  },
  "Regular/Extra Large": {
    as: "h3",
    style: "font-regularExtraLarge",
  },
  "Regular/Extra Small": {
    as: "span",
    style: "font-regularExtraSmall",
  },
  "Regular/Large": {
    as: "h4",
    style: "font-regularLarge",
  },
  "Regular/Small": {
    as: "span",
    style: "font-regularSmall",
  },
  "Regular/XS-Caption": {
    as: "span",
    style: "font-captionXsRegular",
  },
  "Semibold/Caption": {
    as: "span",
    style: "font-captionSemibold",
  },
  "Semibold/Default": {
    as: "span",
    style: "font-semiboldDefault",
  },
  "Semibold/Extra Large": {
    as: "span",
    style: "font-semiboldExtraLarge",
  },
  "Semibold/Extra Small": {
    as: "span",
    style: "font-semiboldExtraSmall",
  },
  "Semibold/Large": {
    as: "span",
    style: "font-semiboldLarge",
  },
  "Semibold/Small": {
    as: "span",
    style: "font-semiboldSmall",
  },
} as const;

export type TypographyVariant = keyof typeof TYPOGRAPHY_VARIANTS;

export const TYPOGRAPHY_VARIANT_TYPES = Object.keys(
  TYPOGRAPHY_VARIANTS,
) as TypographyVariant[];

export const ALL_VARIANTS = {
  ...TITLE_VARIANTS,
  ...TYPOGRAPHY_VARIANTS,
} as const;

type As = "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span";

type Variant = TitleVariant | TypographyVariant;

const _Typography: React.ForwardRefRenderFunction<
  HTMLElement,
  React.ComponentProps<As> & { as?: As; skeleton?: boolean; variant?: Variant }
> = ({ as, className, skeleton, variant = "Regular/Default", ...props }, ref) =>
  React.createElement(as ?? ALL_VARIANTS[variant].as, {
    className: classNames(
      ALL_VARIANTS[variant].style,
      {
        "animate-pulse select-none rounded-lg bg-gray-04 leading-normal text-gray-04":
          skeleton,
      },
      className,
    ),
    ...props,
    ref,
  });

export const Typography = React.forwardRef(_Typography);
