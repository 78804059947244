import { useWatch } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GrantWatchouts } from "../GrantWatchouts";
import { FormRow } from "../ui/Form/FormRow";
import { PostTerminationExercisePeriodSelectionBlock_Instrument$key } from "./__generated__/PostTerminationExercisePeriodSelectionBlock_Instrument.graphql";
import { PostTerminationExercisePeriodSelectionBlock_Organization$key } from "./__generated__/PostTerminationExercisePeriodSelectionBlock_Organization.graphql";
import { UseGrantForm } from "./GrantForm";
import { SelectOrganizationPostTerminationExercisePeriod } from "./SelectOrganizationPostTerminationExercisePeriod";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriodSelectionBlock_Organization on Organization
  @refetchable(
    queryName: "PostTerminationExercisePeriodSelectionBlock_Organization_RefetchQuery"
  ) {
    ...SelectOrganizationPostTerminationExercisePeriod_Organization
    postTerminationExercisePeriods {
      id
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod
    }
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriodSelectionBlock_Instrument on Instrument {
    equityType {
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType
    }
    isVirtual
  }
`;

const PostTerminationExercisePeriodSelectionBlock_: React.FC<{
  control: UseGrantForm["control"];
  instrumentFragment: null | PostTerminationExercisePeriodSelectionBlock_Instrument$key;
  organizationFragment: PostTerminationExercisePeriodSelectionBlock_Organization$key;
}> = ({ control, instrumentFragment, organizationFragment }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const postTerminationExercisePeriodId = useWatch({
    control,
    name: "postTerminationExercisePeriodId",
  });
  const postTerminationExercisePeriod =
    organization.postTerminationExercisePeriods.find(
      (ptep) => ptep.id === postTerminationExercisePeriodId,
    );

  return (
    <>
      <FormRow.Form
        control={control}
        label={
          instrument?.isVirtual
            ? "Post-termination settlement period (PTSP)"
            : "Post-termination exercise period (PTEP)"
        }
        name="postTerminationExercisePeriodId"
      >
        <SelectOrganizationPostTerminationExercisePeriod
          control={control}
          isVirtual={instrument?.isVirtual ?? false}
          name="postTerminationExercisePeriodId"
          organizationFragment={organization}
        />
      </FormRow.Form>

      {postTerminationExercisePeriod && instrument?.equityType && (
        <GrantWatchouts.PtepGreaterThan90DaysWatchout.UsingPtepFragment
          equityTypeFragment={instrument.equityType}
          postTerminationExercisePeriodFragment={postTerminationExercisePeriod}
        />
      )}
    </>
  );
};

export const PostTerminationExercisePeriodSelectionBlock: React.FC<{
  control: UseGrantForm["control"];
  instrumentFragment: null | PostTerminationExercisePeriodSelectionBlock_Instrument$key;
  organizationFragment: PostTerminationExercisePeriodSelectionBlock_Organization$key;
}> = ({ control, instrumentFragment, organizationFragment }) => {
  return (
    <PostTerminationExercisePeriodSelectionBlock_
      control={control}
      instrumentFragment={instrumentFragment}
      organizationFragment={organizationFragment}
    />
  );
};
