import classNames from "classnames";

import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const DetailsBox: React.FC<
  React.PropsWithChildren<{
    headerRightAddon?: React.ReactNode;
    Icon: React.ComponentType<{ className?: string }>;
    padded?: boolean;
    title: React.ReactNode;
  }>
> = ({ children, headerRightAddon, Icon, padded = true, title }) => {
  return (
    <RoundedBox className="space-y-6" withBorder withShadow>
      <div className="flex items-center justify-between gap-2 px-6 pt-6">
        <div className="flex items-center gap-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary-01 p-1.5">
            <Icon className="w-full text-primary" />
          </div>
          <Typography as="div" variant="Medium/Small">
            {title}
          </Typography>
        </div>
        <div className="flex items-center">{headerRightAddon}</div>
      </div>
      <div
        className={classNames({
          "px-6 pb-6": padded,
        })}
      >
        {children}
      </div>
    </RoundedBox>
  );
};
