import { useSearchParams } from "react-router-dom";
import { z } from "zod";

export const useStateToken = () => {
  const [searchParams] = useSearchParams();

  const stateToken = searchParams.get("state");

  if (!stateToken) {
    throw new Error("Missing state token");
  }

  return stateToken;
};

export const useCartaIssuerId = () => {
  const [searchParams] = useSearchParams();

  return searchParams.get("cartaIssuerId");
};

const ROLE_SCHEMA = z.enum([
  "FOUNDER_OR_CEO",
  "LEGAL_ADMINISTRATOR",
  "HR_ADMINISTRATOR",
  "CFO",
  "LAWYER",
  "OTHER",
]);

export const useRole = () => {
  const [searchParams] = useSearchParams();

  return ROLE_SCHEMA.parse(searchParams.get("role"));
};
