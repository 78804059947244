/**
 * @generated SignedSource<<575444f0e9a56e2ee2ca6c4be8fd10cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeTable_HRISProviderEmployee" | "useMergeGrantee_HRISProviderEmployee">;
  readonly " $fragmentType": "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee";
};
export type MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key = {
  readonly " $data"?: MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergeGrantee_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeTable_HRISProviderEmployee"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "796a4aab2dd997ff96645a232559b7fa";

export default node;
