import classNames from "classnames";

const TONE_CLASSNAMES = {
  error: classNames("bg-red-500 [&>.inner]:bg-red-700"),
  success: classNames("bg-greenLight-500 [&>.inner]:bg-greenLight-700"),
  warning: classNames("bg-yellow-500 [&>.inner]:bg-yellow-700"),
};

export function StatusLight({ tone }: { tone: keyof typeof TONE_CLASSNAMES }) {
  return (
    <div
      className={classNames(
        "relative size-3 overflow-hidden rounded-full",
        TONE_CLASSNAMES[tone],
      )}
    >
      <div className="inner absolute inset-y-0 left-0 w-1/2" />
    </div>
  );
}
