/**
 * @generated SignedSource<<1321ecfc9e8a3e320805a5fb2c1b9a27>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestDetails_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_Viewer" | "ExerciseRequestCompletedSection_Viewer" | "ExerciseRequestNotifySection_Viewer" | "ExerciseRequestTaxTreatmentSection_Viewer">;
  readonly " $fragmentType": "ExerciseRequestDetails_Viewer";
};
export type ExerciseRequestDetails_Viewer$key = {
  readonly " $data"?: ExerciseRequestDetails_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestDetails_Viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestDetails_Viewer",
  "selections": [
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ExerciseRequestTaxTreatmentSection_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ExerciseRequestCompletedSection_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovalSection_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "ExerciseRequestNotifySection_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "1c6164e6b9e9dade15f9efa883ca5cf7";

export default node;
