import { sumBy } from "lodash";
import { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { SharesInDraftMessage_Organization$key } from "./__generated__/SharesInDraftMessage_Organization.graphql";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SharesInDraftMessage_Organization on Organization {
    easopGrants {
      boardConsent {
        __typename
      }
      quantityGranted
    }
  }
`;

export const SharesInDraftMessage: React.FC<
  Omit<React.ComponentProps<typeof NoticeMessage>, "children"> & {
    organizationFragment: SharesInDraftMessage_Organization$key;
  }
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { grantsCount: grantsWithoutBoardConsentCount, shares } =
    useMemo(() => {
      const easopGrantsWithoutBoardConsent = organization.easopGrants.filter(
        (easopGrant) => easopGrant.boardConsent === null,
      );
      return {
        grantsCount: easopGrantsWithoutBoardConsent.length,
        shares: sumBy(
          easopGrantsWithoutBoardConsent,
          ({ quantityGranted }) => quantityGranted,
        ),
      };
    }, [organization.easopGrants]);

  if (!grantsWithoutBoardConsentCount) return null;

  return (
    <NoticeMessage {...props}>
      <FormattedMessage
        defaultMessage="<strong>{shares, number} shares</strong> over {grantsCount, plural, one {# grant} other {# grants}} currently in draft"
        values={{
          grantsCount: grantsWithoutBoardConsentCount,
          shares,
          strong: (chunks) => (
            <Typography variant="Medium/Extra Small">{chunks}</Typography>
          ),
        }}
      />
    </NoticeMessage>
  );
};
