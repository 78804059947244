/**
 * @generated SignedSource<<664c27107dc6c473d20a45addeea7682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityOfferSettingsStep = "CANDIDATE" | "COMPANY" | "EQUIUNIVERSITY" | "FINAL_THOUGHTS" | "INCENTIVE" | "PROJECTION" | "VESTING";
import { FragmentRefs } from "relay-runtime";
export type EquityOfferAside_EquityOffer$data = {
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly visitedSteps: ReadonlyArray<EquityOfferSettingsStep>;
  readonly " $fragmentType": "EquityOfferAside_EquityOffer";
};
export type EquityOfferAside_EquityOffer$key = {
  readonly " $data"?: EquityOfferAside_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferAside_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferAside_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visitedSteps",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "ac63ef11a9baafa170dd9447afcb98d7";

export default node;
