/**
 * @generated SignedSource<<27857a05b1c39f6b0583f9219559dcdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent$data = {
  readonly __typename: "GranteeTerminationGrantAmendmentBoardConsent";
  readonly granteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly grant: {
      readonly label: string;
    };
  }>;
  readonly " $fragmentType": "ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent";
};
export type ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent$key = {
  readonly " $data"?: ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CTMSGrant",
          "kind": "LinkedField",
          "name": "grant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GranteeTerminationGrantAmendmentBoardConsent",
  "abstractKey": null
};

(node as any).hash = "756db0f44658415c9f5f0446d4950087";

export default node;
