/**
 * @generated SignedSource<<15269efdbc2bc71464b26a73e2d1c1d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivity_Query$variables = {
  organizationId: string;
};
export type ExercisesActivity_Query$data = {
  readonly exercisesActivityDataPoints: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivity_ExercisesActivityDataPoint">;
  }>;
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivity_Organization">;
  } | null;
};
export type ExercisesActivity_Query = {
  response: ExercisesActivity_Query$data;
  variables: ExercisesActivity_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityExercised",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExercisesActivity_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExercisesActivity_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExercisesActivityDataPoint",
        "kind": "LinkedField",
        "name": "exercisesActivityDataPoints",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExercisesActivity_ExercisesActivityDataPoint"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExercisesActivity_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PricePerShare",
            "kind": "LinkedField",
            "name": "latestPricePerShare",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "statusIn": [
                    "Active",
                    "Terminated"
                  ]
                }
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grantStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equityTypeAwardName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "earlyExercise",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exercisableNonExercisedShares",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cumulativeVested",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantityIssued",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\"]})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExercisesActivityDataPoint",
        "kind": "LinkedField",
        "name": "exercisesActivityDataPoints",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sharesExercised",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashReceivedFromExercisesInUSD",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CTMSExerciseRequest",
            "kind": "LinkedField",
            "name": "exerciseRequests",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CTMSGrant",
                "kind": "LinkedField",
                "name": "ctmsGrant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46f3dcf5c99fde8dc86307ba805eea51",
    "id": null,
    "metadata": {},
    "name": "ExercisesActivity_Query",
    "operationKind": "query",
    "text": "query ExercisesActivity_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...ExercisesActivity_Organization\n  }\n  exercisesActivityDataPoints(organizationId: $organizationId) {\n    ...ExercisesActivity_ExercisesActivityDataPoint\n  }\n}\n\nfragment ExerciseRequestsListSlideOver_ExerciseRequests on CTMSExerciseRequest {\n  quantityExercised\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      email\n    }\n  }\n}\n\nfragment ExerciseRequestsListSlideOver_Organization on Organization {\n  id\n}\n\nfragment ExercisesActivityExercisableSharesBoxes_Organization on Organization {\n  ctmsGrants(filters: {statusIn: [Active, Terminated]}) {\n    edges {\n      node {\n        grantStatus\n        equityTypeAwardName\n        earlyExercise\n        quantityExercised\n        exercisableNonExercisedShares\n        cumulativeVested\n        quantityIssued\n        id\n      }\n    }\n  }\n}\n\nfragment ExercisesActivityGraph_ExercisesActivityDataPoints on ExercisesActivityDataPoint {\n  sharesExercised\n  cashReceivedFromExercisesInUSD\n  date\n  exerciseRequests {\n    ctmsGrant {\n      grantee {\n        id\n      }\n      id\n    }\n    ...ExerciseRequestsListSlideOver_ExerciseRequests\n    id\n  }\n}\n\nfragment ExercisesActivityGraph_Organization on Organization {\n  ...useOrganizationSharesUtil_Organization\n  ...ExerciseRequestsListSlideOver_Organization\n}\n\nfragment ExercisesActivity_ExercisesActivityDataPoint on ExercisesActivityDataPoint {\n  ...ExercisesActivityGraph_ExercisesActivityDataPoints\n}\n\nfragment ExercisesActivity_Organization on Organization {\n  id\n  ...ExercisesActivityGraph_Organization\n  ...ExercisesActivityExercisableSharesBoxes_Organization\n}\n\nfragment useOrganizationSharesUtil_Organization on Organization {\n  latestPricePerShare {\n    value\n    id\n  }\n  fullyDilutedShares\n}\n"
  }
};
})();

(node as any).hash = "366e7b1ef54b391e0fa42e0f861ea99e";

export default node;
