/**
 * @generated SignedSource<<570d236e82523d3b6a0912681a0fed89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
export type OrganizationPostTerminationExercisePeriodType = "FIXED" | "VARIABLE";
export type CreateOrganizationPostTerminationExercisePeriodInput = {
  duration: number;
  durationUnit: DurationUnit;
  extensionDuration?: number | null;
  extensionDurationUnit?: DurationUnit | null;
  organizationId: string;
  thresholdForExtensionInYears?: number | null;
  type: OrganizationPostTerminationExercisePeriodType;
};
export type AddNew_Mutation$variables = {
  input: CreateOrganizationPostTerminationExercisePeriodInput;
};
export type AddNew_Mutation$data = {
  readonly createOrganizationPostTerminationExercisePeriod: {
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly id: string;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type AddNew_Mutation = {
  response: AddNew_Mutation$data;
  variables: AddNew_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNew_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "OrganizationFixedPostTerminationExercisePeriod",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v4/*: any*/),
            "type": "OrganizationVariablePostTerminationExercisePeriod",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNew_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "059034455b68e44a6e063cfdf85858ca",
    "id": null,
    "metadata": {},
    "name": "AddNew_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddNew_Mutation(\n  $input: CreateOrganizationPostTerminationExercisePeriodInput!\n) {\n  createOrganizationPostTerminationExercisePeriod(input: $input) {\n    __typename\n    ... on OrganizationFixedPostTerminationExercisePeriod {\n      id\n    }\n    ... on OrganizationVariablePostTerminationExercisePeriod {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2207282a42db96258ad638d42344eae0";

export default node;
