/**
 * @generated SignedSource<<e685d2610c7d2e71f1015501edbc0775>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewHireGrantPlanningEntryDetails_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SharesValue_Organization">;
  readonly " $fragmentType": "NewHireGrantPlanningEntryDetails_Organization";
};
export type NewHireGrantPlanningEntryDetails_Organization$key = {
  readonly " $data"?: NewHireGrantPlanningEntryDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewHireGrantPlanningEntryDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewHireGrantPlanningEntryDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "dce31c1774ba7a9afb9b42bd4795f20d";

export default node;
