/**
 * @generated SignedSource<<573db5074f7a6869d267a5def9d940ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectOrganizationPostTerminationExercisePeriod_Organization$data = {
  readonly id: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly displayName: string;
    readonly id: string;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly displayName: string;
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentType": "SelectOrganizationPostTerminationExercisePeriod_Organization";
};
export type SelectOrganizationPostTerminationExercisePeriod_Organization$key = {
  readonly " $data"?: SelectOrganizationPostTerminationExercisePeriod_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationPostTerminationExercisePeriod_Organization">;
};

import SelectOrganizationPostTerminationExercisePeriod_Organization_RefetchQuery_graphql from './SelectOrganizationPostTerminationExercisePeriod_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": SelectOrganizationPostTerminationExercisePeriod_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "SelectOrganizationPostTerminationExercisePeriod_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "OrganizationFixedPostTerminationExercisePeriod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "OrganizationVariablePostTerminationExercisePeriod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "96f21d6796fa2399c417af75c026974e";

export default node;
