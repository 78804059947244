/**
 * @generated SignedSource<<50b74490dd75462a1f2d96bc1f3c918c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CleanupGranteesItem_UpdateContent_Grantee$data = {
  readonly name: string;
  readonly " $fragmentType": "CleanupGranteesItem_UpdateContent_Grantee";
};
export type CleanupGranteesItem_UpdateContent_Grantee$key = {
  readonly " $data"?: CleanupGranteesItem_UpdateContent_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_UpdateContent_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CleanupGranteesItem_UpdateContent_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "044dc0e40b8769c1919b49ad5f9a084a";

export default node;
