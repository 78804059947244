/**
 * @generated SignedSource<<3850639c8db366adf03669966d6af09d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IntegrationProvider = "CARTA" | "DEEL" | "REMOTE";
export type DisconnectIntegrationButton_Mutation$variables = {
  organizationId: string;
  provider: IntegrationProvider;
};
export type DisconnectIntegrationButton_Mutation$data = {
  readonly disconnectOrganizationIntegration: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsIntegrations_Organization">;
  };
};
export type DisconnectIntegrationButton_Mutation = {
  response: DisconnectIntegrationButton_Mutation$data;
  variables: DisconnectIntegrationButton_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "provider",
    "variableName": "provider"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DisconnectIntegrationButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "disconnectOrganizationIntegration",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsIntegrations_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DisconnectIntegrationButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "disconnectOrganizationIntegration",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToDeel",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToRemote",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOriginatingFromRemoteEquity",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e12839ab8c2dfe2e0af89aa005af95dc",
    "id": null,
    "metadata": {},
    "name": "DisconnectIntegrationButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation DisconnectIntegrationButton_Mutation(\n  $organizationId: OrganizationId!\n  $provider: IntegrationProvider!\n) {\n  disconnectOrganizationIntegration(organizationId: $organizationId, provider: $provider) {\n    ...OrganizationSettingsIntegrations_Organization\n    id\n  }\n}\n\nfragment OrganizationSettingsIntegrations_Organization on Organization {\n  id\n  name\n  isConnectedToDeel\n  isConnectedToRemote\n  isConnectedToCarta\n  isOriginatingFromRemoteEquity\n}\n"
  }
};
})();

(node as any).hash = "4edf26413f7cc117591ee73e45c3a80a";

export default node;
