import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { ConfirmationModal } from "../../../../../components/ui/ConfirmationModal";
import { Table } from "../../../../../components/ui/Table";
import { Typography } from "../../../../../components/ui/Typography";
import { useBoolean } from "../../../../../hooks/useBoolean";
import { useQuery } from "../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { BlueLink } from "../BlueLink";
import { ConfigureGrantsLayout } from "../ConfigureGrantsLayout";
import { ToggleCard } from "../ToggleCard";
import { Acceleration_Query } from "./__generated__/Acceleration_Query.graphql";
import { Acceleration_SetAccelerationAllowedForOrganization_Mutation } from "./__generated__/Acceleration_SetAccelerationAllowedForOrganization_Mutation.graphql";

function AccelerationTermsTable() {
  const organizationId = useOrganizationIdParam();

  return (
    <Table.Containerized>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Terms</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES[
              "organizationEquityConfigureSingleTriggerAccelerationDetails"
            ],
            {
              organizationId,
            },
          )}
        >
          <Table.Cell>
            <Typography
              className="whitespace-nowrap"
              variant="Medium/Extra Small"
            >
              Single Trigger
            </Typography>
          </Table.Cell>
          <Table.Cell>
            Options become 100％ vested upon occurrence of a change of control.
          </Table.Cell>
        </Table.LinkRow>
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES[
              "organizationEquityConfigureDoubleTriggerAccelerationDetails"
            ],
            {
              organizationId,
            },
          )}
        >
          <Table.Cell>
            <Typography
              className="whitespace-nowrap"
              variant="Medium/Extra Small"
            >
              Double Trigger
            </Typography>
          </Table.Cell>
          <Table.Cell>
            Options become 100% vested upon occurrence of a change of control
            and grantee&apos;s terminated (or resigns for &ldquo;good
            reason&rdquo;) 3 months before, or 12 months after, the change of
            control.
          </Table.Cell>
        </Table.LinkRow>
      </Table.Body>
    </Table.Containerized>
  );
}

const QUERY = graphql`
  query Acceleration_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      allowAcceleration
      hasNotImplementedEasopGrantsWithAccelerationClause
    }
  }
`;

const SET_ACCELERATION_ALLOWED_MUTATION = graphql`
  mutation Acceleration_SetAccelerationAllowedForOrganization_Mutation(
    $organizationId: OrganizationId!
    $allowAcceleration: Boolean!
  ) {
    setAccelerationAllowedForOrganization(
      organizationId: $organizationId
      allowAcceleration: $allowAcceleration
    ) {
      allowAcceleration
    }
  }
`;

export default function Acceleration() {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<Acceleration_Query>(QUERY, {
    organizationId,
  });
  const [mutate, mutationIsInFlight] =
    useSafeMutation<Acceleration_SetAccelerationAllowedForOrganization_Mutation>(
      SET_ACCELERATION_ALLOWED_MUTATION,
    );
  const turnAccelerationOn = () => {
    void mutate({
      variables: {
        allowAcceleration: true,
        organizationId,
      },
    });
  };
  const turnAccelerationOff = () => {
    void mutate({
      variables: {
        allowAcceleration: false,
        organizationId,
      },
    });
  };

  const handleToggle = (allowAcceleration: boolean) => {
    if (allowAcceleration) {
      turnAccelerationOn();
    } else {
      if (
        query.organization.hasNotImplementedEasopGrantsWithAccelerationClause
      ) {
        showConfirmationModal();
      } else {
        turnAccelerationOff();
      }
    }
  };

  const {
    setFalse: closeConfirmationModal,
    setTrue: showConfirmationModal,
    value: confirmationModalIsShown,
  } = useBoolean(false);

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - Acceleration"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity acceleration settings`}
    >
      <ConfirmationModal
        confirmationLabel="Turn off"
        onClose={closeConfirmationModal}
        onConfirmed={() => {
          turnAccelerationOff();
          closeConfirmationModal();
        }}
        show={confirmationModalIsShown}
        title="Acceleration is active on current drafts."
      >
        We noticed that you currently have drafts that allow employees to
        benefit from an acceleration clause. If you want those employees to
        still benefit from an acceleration clause, you should turn off this
        setting but only after the grants have been implemented.
      </ConfirmationModal>
      <ConfigureGrantsLayout title="Acceleration">
        <div className="space-y-10">
          <ToggleCard
            description={
              <>
                Acceleration would allow your team member to vest all their
                options under specific conditions. It can be set up more
                granularly in the{" "}
                <BlueLink
                  to={generatePath(
                    APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                    { organizationId },
                  )}
                >
                  New Hires
                </BlueLink>{" "}
                and{" "}
                <BlueLink
                  to={generatePath(
                    APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                    { organizationId },
                  )}
                >
                  Retention
                </BlueLink>{" "}
                policies
              </>
            }
            loading={mutationIsInFlight}
            onToggle={handleToggle}
            title="Allow acceleration at company level"
            toggled={query.organization?.allowAcceleration ?? false}
          />
          <AccelerationTermsTable />
        </div>
      </ConfigureGrantsLayout>
    </Page>
  );
}
