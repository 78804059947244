import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { graphql } from "react-relay";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation } from "./__generated__/GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation.graphql";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const DELETE_GRANT_AMENDMENT_REQUESTS_MUTATION = graphql`
  mutation GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation(
    $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!
  ) {
    deleteGranteeTerminationCTMSGrantAmendmentRequests(
      granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds
    ) {
      __typename
    }
  }
`;

type State = {
  granteeTerminationCTMSGrantAmendmentRequestIds: string[];
  shown: boolean;
};

export const useGranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModalState =
  () => {
    const [state, setState] = useState<State>({
      granteeTerminationCTMSGrantAmendmentRequestIds: [],
      shown: false,
    });

    const showModal = useCallback(
      (granteeTerminationCTMSGrantAmendmentRequestIds: string[]) => {
        setState({
          granteeTerminationCTMSGrantAmendmentRequestIds,
          shown: true,
        });
      },
      [],
    );

    const closeModal = useCallback(() => {
      setState((previousState) => ({
        ...previousState,
        shown: false,
      }));
    }, []);

    return {
      closeModal,
      showModal,
      state,
    };
  };

export const GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal: React.FC<{
  onClose: () => void;
  onDeletionCompleted?: () => void;
  state: State;
}> = ({
  onClose,
  onDeletionCompleted,
  state: { granteeTerminationCTMSGrantAmendmentRequestIds, shown },
}) => {
  const [deleteGrantAmendmentRequests, deleteGrantAmendmentRequestsIsInFlight] =
    useSafeMutation<GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation>(
      DELETE_GRANT_AMENDMENT_REQUESTS_MUTATION,
    );
  const toaster = useToaster();
  const onConfirmed = async () => {
    await deleteGrantAmendmentRequests({
      variables: {
        granteeTerminationCTMSGrantAmendmentRequestIds,
      },
    });
    toaster.push(
      <Toast title="Alright!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# amendment request}
            other {# amendment requests}
          } successfully deleted!`}
          values={{
            grantCount: granteeTerminationCTMSGrantAmendmentRequestIds.length,
          }}
        />
      </Toast>,
    );
    onClose();
    onDeletionCompleted?.();
  };

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      loading={deleteGrantAmendmentRequestsIsInFlight}
      onClose={onClose}
      onConfirmed={onConfirmed}
      show={shown}
      title={
        <FormattedMessage
          defaultMessage="Delete {selectedGrantCount, plural, one {amendment request} other {amendment requests}}"
          values={{
            selectedGrantCount:
              granteeTerminationCTMSGrantAmendmentRequestIds.length,
          }}
        />
      }
    >
      <FormattedMessage
        defaultMessage="Are you sure you want to delete {selectedGrantCount, plural, one {this amendment request} other {these amendment requests}}?"
        values={{
          selectedGrantCount:
            granteeTerminationCTMSGrantAmendmentRequestIds.length,
        }}
      />
    </ConfirmationModal>
  );
};
