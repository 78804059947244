/**
 * @generated SignedSource<<cdfef1a341777cff749144b391eaa3fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantStatusTag_CtmsGrant$data = {
  readonly fullyVested: boolean;
  readonly grantStatus: CTMSGrantStatus;
  readonly isActive: boolean;
  readonly settled: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantVestingTag_CtmsGrant">;
  readonly " $fragmentType": "CtmsGrantStatusTag_CtmsGrant";
};
export type CtmsGrantStatusTag_CtmsGrant$key = {
  readonly " $data"?: CtmsGrantStatusTag_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantStatusTag_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantVestingTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "6e19a85e86b79511a2d3778704d5d174";

export default node;
