/**
 * @generated SignedSource<<947121bf88a044b77449755059cc183a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsPostTerminationExercisePeriod_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "CtmsGrantDetailsPostTerminationExercisePeriod_Organization";
};
export type CtmsGrantDetailsPostTerminationExercisePeriod_Organization$key = {
  readonly " $data"?: CtmsGrantDetailsPostTerminationExercisePeriod_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsPostTerminationExercisePeriod_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsPostTerminationExercisePeriod_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "301931387eb9bb8b9405b298d601bba5";

export default node;
