import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import { ApprovalDetails_GrantAmendmentBoardConsent$key } from "./__generated__/ApprovalDetails_GrantAmendmentBoardConsent.graphql";
import { ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent$key } from "./__generated__/ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent.graphql";
import { ApprovalDetails_Organization$key } from "./__generated__/ApprovalDetails_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApprovalDetails_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
  }
`;

const ApprovalDetailsValue: React.FC<{
  children: React.ReactNode;
  label: string;
}> = ({ children, label }) => {
  return (
    <div className="flex flex-auto flex-col gap-2">
      <Typography className="text-black-05" variant="Regular/Extra Small">
        {label}
      </Typography>
      <Typography
        className="text-primary-05 sm:hidden"
        variant="Medium/Default"
      >
        {children}
      </Typography>
      <Typography
        className="hidden text-primary-05 sm:inline"
        variant="Medium/Large"
      >
        {children}
      </Typography>
    </div>
  );
};

export const GrantsBoardConsentApprovalDetails: React.FC<{
  grantedShares: number;
  organizationFragment: ApprovalDetails_Organization$key;
}> = ({ grantedShares, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const grantedSharesOwnership = sharesToFullyDilutedRatio(grantedShares);
  const grantedSharesValue = sharesToValue(grantedShares);

  return (
    <div className="flex">
      {grantedSharesOwnership !== null && (
        <ApprovalDetailsValue label="% total fully diluted">
          <FormattedPercentage value={grantedSharesOwnership} />
        </ApprovalDetailsValue>
      )}
      {grantedSharesValue && (
        <ApprovalDetailsValue label="$ total value">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={grantedSharesValue}
          />
        </ApprovalDetailsValue>
      )}
      <ApprovalDetailsValue label="# total shares">
        <FormattedNumber value={grantedShares} />
      </ApprovalDetailsValue>
    </div>
  );
};

const GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent on GranteeTerminationGrantAmendmentBoardConsent {
    __typename
    granteeTerminationCTMSGrantAmendmentRequests {
      grant {
        label
      }
    }
  }
`;

export const GranteeTerminationGrantAmendmentsBoardConsentApprovalDetails: React.FC<{
  grantAmendmentBoardConsentFragment: ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent$key;
}> = ({ grantAmendmentBoardConsentFragment }) => {
  const grantAmendmentBoardConsent = useFragment(
    GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_FRAGMENT,
    grantAmendmentBoardConsentFragment,
  );

  return (
    <div className="flex">
      <ApprovalDetailsValue label="Amendment on grant">
        <div className="flex flex-wrap gap-8">
          {grantAmendmentBoardConsent.granteeTerminationCTMSGrantAmendmentRequests.map(
            (grantAmendmentRequest) => (
              <div key={grantAmendmentRequest.grant.label}>
                {grantAmendmentRequest.grant.label}
              </div>
            ),
          )}
        </div>
      </ApprovalDetailsValue>
    </div>
  );
};

const GRANT_AMENDMENT_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalDetails_GrantAmendmentBoardConsent on GrantAmendmentBoardConsent {
    __typename
    ctmsGrantAmendmentRequests {
      ctmsGrant {
        label
      }
    }
  }
`;

export const GrantAmendmentsBoardConsentApprovalDetails: React.FC<{
  grantAmendmentBoardConsentFragment: ApprovalDetails_GrantAmendmentBoardConsent$key;
}> = ({ grantAmendmentBoardConsentFragment }) => {
  const grantAmendmentBoardConsent = useFragment(
    GRANT_AMENDMENT_BOARD_CONSENT_FRAGMENT,
    grantAmendmentBoardConsentFragment,
  );

  return (
    <div className="flex">
      <ApprovalDetailsValue label="Amendment on grant">
        <div className="flex flex-wrap gap-8">
          {grantAmendmentBoardConsent.ctmsGrantAmendmentRequests.map(
            (grantAmendmentRequest) => (
              <div key={grantAmendmentRequest.ctmsGrant.label}>
                {grantAmendmentRequest.ctmsGrant.label}
              </div>
            ),
          )}
        </div>
      </ApprovalDetailsValue>
    </div>
  );
};
