/**
 * @generated SignedSource<<d715305f5354df14432987e10e53c1f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeTerminationCTMSGrantAmendmentRequestStatus = "IMPLEMENTATION" | "IMPLEMENTED" | "PENDING_APPROVAL" | "READY_FOR_CONSENT" | "UNDER_REVIEW";
export type GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption = "CUSTOM_DATE" | "LAST_DAY_AT_THE_COMPANY" | "TERMINATION_DATE";
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest$data = {
  readonly grant: {
    readonly grantee: {
      readonly terminationInformations: {
        readonly terminationDate: string;
        readonly terminationLastDayAtTheCompany: string;
      } | null;
    };
    readonly id: string;
    readonly label: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBox_CTMSGrant">;
  };
  readonly id: string;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly status: GranteeTerminationCTMSGrantAmendmentRequestStatus;
  readonly vestingEndCustomDate: string | null;
  readonly vestingEndOption: GranteeTerminationCTMSGrantAmendmentRequestVestingEndOption | null;
  readonly waiveCliff: boolean | null;
  readonly " $fragmentType": "GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest";
};
export type GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest$key = {
  readonly " $data"?: GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waiveCliff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingEndCustomDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingEndOption",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "grant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantAmendmentRequestBox_CTMSGrant"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GranteeTerminationInformations",
              "kind": "LinkedField",
              "name": "terminationInformations",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminationDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "terminationLastDayAtTheCompany",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GranteeTerminationCTMSGrantAmendmentRequest",
  "abstractKey": null
};
})();

(node as any).hash = "38d72add88ebe7928c5814c999c5af93";

export default node;
