/**
 * @generated SignedSource<<7096cb7b0dfff3e272761024385313df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsTable_PlanningEntrySuggestion">;
  readonly " $fragmentType": "PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion";
}>;
export type PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion$key = ReadonlyArray<{
  readonly " $data"?: PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlanningEntrySuggestionsTable_PlanningEntrySuggestion"
    }
  ],
  "type": "PlanningEntrySuggestion",
  "abstractKey": null
};

(node as any).hash = "e7ac9b07d0d137f92925da15f8149de0";

export default node;
