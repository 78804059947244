/**
 * @generated SignedSource<<97a152b891fedc7d88a37f4b5a3cce10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MatchGranteeTable_HRISProviderEmployee$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTableRow_HRISProviderEmployee">;
  readonly " $fragmentType": "MatchGranteeTable_HRISProviderEmployee";
}>;
export type MatchGranteeTable_HRISProviderEmployee$key = ReadonlyArray<{
  readonly " $data"?: MatchGranteeTable_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTable_HRISProviderEmployee">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MatchGranteeTable_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatchGranteeTableRow_HRISProviderEmployee"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "6cb4599cb204c9810546487d818ca18f";

export default node;
