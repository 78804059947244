/**
 * @generated SignedSource<<1790970032415b448dab9e4ac14f2005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceEvolutionGraphForVSOP_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly easopGrant: {
    readonly exercisePrice: number;
  } | null;
  readonly exercisePrice: number | null;
  readonly pricePerShareAtGrant: number | null;
  readonly quantityIssued: number;
  readonly " $fragmentType": "PriceEvolutionGraphForVSOP_CTMSGrant";
};
export type PriceEvolutionGraphForVSOP_CTMSGrant$key = {
  readonly " $data"?: PriceEvolutionGraphForVSOP_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceEvolutionGraphForVSOP_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exercisePrice",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceEvolutionGraphForVSOP_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricePerShareAtGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "e7e489917fbab7a0ecfec3eaa90d7c35";

export default node;
