import { useMemo } from "react";
import { z } from "zod";

import {
  compareDurations,
  durationToYears,
} from "../../../../../helpers/duration-utils";

const DURATION_UNIT_SCHEMA = z.enum(["day", "month", "year"]);

export const useFormSchema = ({
  organizationHasCooleyAsOutsideCounsel,
  organizationTermOfOptionsInMonths,
}: {
  organizationHasCooleyAsOutsideCounsel: boolean;
  organizationTermOfOptionsInMonths: number;
}) => {
  const organizationTermOfOptionsInYears = useMemo(
    () =>
      durationToYears({
        duration: organizationTermOfOptionsInMonths,
        unit: "month",
      }),
    [organizationTermOfOptionsInMonths],
  );

  return useMemo(
    () =>
      z
        .discriminatedUnion("type", [
          z.object({
            duration: z.coerce.number().positive().int(),
            durationUnit: DURATION_UNIT_SCHEMA,
            type: z.literal("FIXED"),
          }),
          z.object({
            duration: z.coerce.number().positive().int(),
            durationUnit: DURATION_UNIT_SCHEMA,
            extensionDuration: z.coerce.number().positive().int(),
            extensionDurationUnit: DURATION_UNIT_SCHEMA,
            thresholdForExtensionInYears: z.coerce.number().positive().int(),
            type: z.literal("VARIABLE"),
          }),
        ])
        .refine(
          ({ duration, durationUnit }) =>
            compareDurations(
              {
                duration,
                unit: durationUnit,
              },
              {
                duration: organizationTermOfOptionsInMonths,
                unit: "month",
              },
            ) <= 0,
          {
            message: `Your stock option plan states that stock options expire after ${organizationTermOfOptionsInYears} years. Therefore, stock options cannot be exercised after ${organizationTermOfOptionsInYears} years, and the PTEP cannot be more than ${organizationTermOfOptionsInYears} years.`,
            path: ["duration"],
          },
        )
        .refine(
          (attributes) => {
            if (attributes.type === "FIXED") {
              return true;
            }

            return (
              compareDurations(
                {
                  duration: attributes.extensionDuration,
                  unit: attributes.extensionDurationUnit,
                },
                {
                  duration: attributes.duration,
                  unit: attributes.durationUnit,
                },
              ) > 0
            );
          },
          {
            message: "extension duration must be greater than base duration",
            path: ["extensionDuration"],
          },
        )
        .refine(
          (attributes) => {
            if (attributes.type === "FIXED") {
              return true;
            }

            return (
              compareDurations(
                {
                  duration: attributes.extensionDuration,
                  unit: attributes.extensionDurationUnit,
                },
                {
                  duration: organizationTermOfOptionsInMonths,
                  unit: "month",
                },
              ) <= 0
            );
          },
          {
            message: `Your stock option plan states that stock options expire after ${organizationTermOfOptionsInYears} years. Therefore, stock options cannot be exercised after ${organizationTermOfOptionsInYears} years, and the extended PTEP cannot be more than ${organizationTermOfOptionsInYears} years.`,
            path: ["extensionDuration"],
          },
        )
        .refine(
          ({ type }) => {
            return type === "FIXED" || !organizationHasCooleyAsOutsideCounsel;
          },
          {
            message:
              "organization with Cooley as outside counsel cannot have a variable PTEP",
            path: ["type"],
          },
        ),
    [
      organizationHasCooleyAsOutsideCounsel,
      organizationTermOfOptionsInMonths,
      organizationTermOfOptionsInYears,
    ],
  );
};

export type FormValues = z.infer<ReturnType<typeof useFormSchema>>;
