/**
 * @generated SignedSource<<b2568fc7806b43029fc44aca49c7ff33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GeographiesDocumentsTable_Organization$data = {
  readonly organizationGeographies: ReadonlyArray<{
    readonly country: {
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
    };
    readonly unlockedEquityTypes: ReadonlyArray<{
      readonly id: string;
      readonly instruments: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_DownloadDocumentsItem_Instrument">;
      }>;
      readonly name: EquityTypeName;
      readonly taxFavored: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_DownloadDocumentsItem_EquityType">;
    }>;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_DownloadDocumentsItem_Organization">;
  readonly " $fragmentType": "GeographiesDocumentsTable_Organization";
};
export type GeographiesDocumentsTable_Organization$key = {
  readonly " $data"?: GeographiesDocumentsTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeographiesDocumentsTable_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CountryFlag_Country"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "unlockedEquityTypes",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxFavored",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GeographiesDocumentsTable_DownloadDocumentsItem_EquityType"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Instrument",
              "kind": "LinkedField",
              "name": "instruments",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GeographiesDocumentsTable_DownloadDocumentsItem_Instrument"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GeographiesDocumentsTable_DownloadDocumentsItem_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "4720b38998486d5af94604b3b45730bd";

export default node;
