import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { useSafeSimulatorContext } from "../pages/SafeSimulator/IContext";
import {
  EditSafeSimulatorInitialSharesModal_Organization$data,
  EditSafeSimulatorInitialSharesModal_Organization$key,
} from "./__generated__/EditSafeSimulatorInitialSharesModal_Organization.graphql";
import { FormRow } from "./ui/Form/FormRow";
import { MaskedInput } from "./ui/Form/Inputs/MaskedInput";
import { Modal } from "./ui/Modal";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EditSafeSimulatorInitialSharesModal_Organization on Organization {
    ctmsFullyDilutedShares
    esopPoolShares
  }
`;

const schema = z
  .object({
    optionPoolShares: z.coerce.number().nonnegative().int().optional(),
    preConversionFullyDilutedShares: z.coerce.number().positive().int(),
  })
  .refine(
    ({ optionPoolShares, preConversionFullyDilutedShares }) =>
      typeof optionPoolShares !== "number" ||
      optionPoolShares <= preConversionFullyDilutedShares,
    {
      message: "Option pool shares must be less than fully diluted shares",
      path: ["optionPoolShares"],
    },
  );

type FormValues = z.infer<typeof schema>;

const ModalContent: React.FC<{
  onClose: () => void;
  organization: EditSafeSimulatorInitialSharesModal_Organization$data | null;
}> = ({ onClose, organization }) => {
  const { onInitialSharesUpdated, state } = useSafeSimulatorContext();
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      optionPoolShares:
        state.optionPoolShares ?? organization?.esopPoolShares ?? undefined,
      preConversionFullyDilutedShares:
        state.preConversionFullyDilutedShares ??
        organization?.ctmsFullyDilutedShares ??
        undefined,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    const { optionPoolShares, preConversionFullyDilutedShares } =
      data as FormValues;
    await onInitialSharesUpdated({
      optionPoolShares,
      preConversionFullyDilutedShares,
    });
    onClose();
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.SubmitButton
          disabled={!isDirty}
          form="edit-safe-converter-initial-shares-form"
          loading={isSubmitting}
        >
          Save
        </Modal.SubmitButton>
      }
      onClose={onClose}
      subTitle="Add or Edit Shareholders and option pool"
      title="Edit Initial Shares Count"
    >
      <Modal.Form
        id="edit-safe-converter-initial-shares-form"
        onSubmit={onSubmit}
      >
        <div className="space-y-6">
          <Controller
            control={control}
            name="preConversionFullyDilutedShares"
            render={({ field, fieldState }) => (
              <FormRow
                error={fieldState.error?.message}
                label="Fully Diluted Shares"
                subLabel="This is the initial count of all shares (the amount of all your shares, i.e. common shares, preferred shares and option pool. Do not add your SAFE notes here.)"
              >
                <MaskedInput.Number
                  onChange={(event) => field.onChange(event.target.value)}
                  placeholder="eg. 10,000,000"
                  value={field.value}
                />
              </FormRow>
            )}
          />
          <Controller
            control={control}
            name="optionPoolShares"
            render={({ field, fieldState }) => (
              <FormRow
                error={fieldState.error?.message}
                label="Option pool"
                subLabel="If you want to visualize your option pool in the calculation, please add the amount represented by your option pool only."
              >
                <MaskedInput.Number
                  onChange={(event) => field.onChange(event.target.value)}
                  placeholder="eg. 1,000,000"
                  value={field.value}
                />
              </FormRow>
            )}
          />
        </div>
      </Modal.Form>
    </Modal.Content>
  );
};

export const EditSafeSimulatorInitialSharesModal: React.FC<{
  onClose: () => void;
  organizationFragment: EditSafeSimulatorInitialSharesModal_Organization$key | null;
  show: boolean;
}> = ({ onClose, organizationFragment, show }) => {
  const organization =
    useFragment(ORGANIZATION_FRAGMENT, organizationFragment) ?? null;

  return (
    <Modal onClose={onClose} show={show}>
      <ModalContent onClose={onClose} organization={organization} />
    </Modal>
  );
};
