/**
 * @generated SignedSource<<3e0b36791feb0c2066a1e26b278b61a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsAdmins_Query$variables = {
  organizationId: string;
};
export type OrganizationSettingsAdmins_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Organization">;
  } | null;
};
export type OrganizationSettingsAdmins_Query = {
  response: OrganizationSettingsAdmins_Query$data;
  variables: OrganizationSettingsAdmins_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsAdmins_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsAdmins_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsAdmins_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsAdmins_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminInvitationIsAllowed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admins",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "orderBy": {
                    "direction": "ASC",
                    "field": "name"
                  }
                }
              }
            ],
            "concreteType": "RemoteCompanyAdmin",
            "kind": "LinkedField",
            "name": "remoteCompanyAdmins",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userSlug",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowedAsAdmin",
                "storageKey": null
              }
            ],
            "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteUserSlug",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "45f2b70b07f99c2521bcc4a1b14da8ab",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsAdmins_Query",
    "operationKind": "query",
    "text": "query OrganizationSettingsAdmins_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...OrganizationSettingsAdmins_Organization\n  }\n  me {\n    ...OrganizationSettingsAdmins_Account\n    id\n  }\n}\n\nfragment OrganizationSettingsAdmins_Account on Account {\n  ...RemoteCompanyAdminsList_Account\n  ...OrganizationSettingsAdmins_Item_Account\n}\n\nfragment OrganizationSettingsAdmins_Item_Account on Account {\n  id\n}\n\nfragment OrganizationSettingsAdmins_Item_Admin on Admin {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsAdmins_Organization on Organization {\n  id\n  name\n  adminInvitationIsAllowed\n  admins {\n    id\n    ...OrganizationSettingsAdmins_Item_Admin\n  }\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    __typename\n    id\n  }\n  ...RemoteCompanyAdminsList_Organization\n}\n\nfragment RemoteCompanyAdminsList_Account on Account {\n  remoteUserSlug\n}\n\nfragment RemoteCompanyAdminsList_Organization on Organization {\n  id\n  name\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    id\n    userSlug\n    name\n    email\n    allowedAsAdmin\n  }\n}\n"
  }
};
})();

(node as any).hash = "bec456d97e4f670cb07a3a527d8808b2";

export default node;
