/**
 * @generated SignedSource<<07586b157998218bd94a56b88d311639>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Home_AdminInsight_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "Home_AdminInsight_Organization";
};
export type Home_AdminInsight_Organization$key = {
  readonly " $data"?: Home_AdminInsight_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Home_AdminInsight_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Home_AdminInsight_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "507d8a15f03f5b8c194e477f0e6e71d6";

export default node;
