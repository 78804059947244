/**
 * @generated SignedSource<<fc0c1f3930af74a68fb92b260cbb1e19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeOwnership_Grantee$data = {
  readonly organization: {
    readonly latestPricePerShare: {
      readonly value: number;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useGranteePortalGranteeShares_Grantee">;
  readonly " $fragmentType": "GranteeOwnership_Grantee";
};
export type GranteeOwnership_Grantee$key = {
  readonly " $data"?: GranteeOwnership_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeOwnership_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeOwnership_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PricePerShare",
          "kind": "LinkedField",
          "name": "latestPricePerShare",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationSharesUtil_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteePortalGranteeShares_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "d11e6dc2ed79445720a3e8111468a83c";

export default node;
