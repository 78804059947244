/**
 * @generated SignedSource<<f373d59bc32db9f8e376235817e850e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type InviteGranteesModal_Grantees$data = ReadonlyArray<{
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee" | "InviteGranteeEmailPreviewModal_Grantee" | "InviteGranteesModal_MissingInformationGranteesList_Grantees">;
  readonly " $fragmentType": "InviteGranteesModal_Grantees";
}>;
export type InviteGranteesModal_Grantees$key = ReadonlyArray<{
  readonly " $data"?: InviteGranteesModal_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Grantees">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "InviteGranteesModal_Grantees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteeEmailPreviewModal_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_MissingInformationGranteesList_Grantees"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "92b9992263908520e4a89aa2ccb8463d";

export default node;
