/**
 * @generated SignedSource<<5a71acfe95df4532765398980f069c75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipGranteePortal_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalReportingContent_Viewer" | "GranteePortalTaxationContent_Viewer">;
  readonly " $fragmentType": "EquityTypeWorkRelationshipGranteePortal_Viewer";
};
export type EquityTypeWorkRelationshipGranteePortal_Viewer$key = {
  readonly " $data"?: EquityTypeWorkRelationshipGranteePortal_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipGranteePortal_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipGranteePortal_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalReportingContent_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalTaxationContent_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e720f3b39414c889b0ab38671cd6d14c";

export default node;
