/**
 * @generated SignedSource<<6c120528555642fa516d0af6379cc907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivityGraph_ExercisesActivityDataPoints$data = ReadonlyArray<{
  readonly cashReceivedFromExercisesInUSD: number;
  readonly date: string;
  readonly exerciseRequests: ReadonlyArray<{
    readonly ctmsGrant: {
      readonly grantee: {
        readonly id: string;
      };
    };
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestsListSlideOver_ExerciseRequests">;
  }>;
  readonly sharesExercised: number;
  readonly " $fragmentType": "ExercisesActivityGraph_ExercisesActivityDataPoints";
}>;
export type ExercisesActivityGraph_ExercisesActivityDataPoints$key = ReadonlyArray<{
  readonly " $data"?: ExercisesActivityGraph_ExercisesActivityDataPoints$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivityGraph_ExercisesActivityDataPoints">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExercisesActivityGraph_ExercisesActivityDataPoints",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharesExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashReceivedFromExercisesInUSD",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSExerciseRequest",
      "kind": "LinkedField",
      "name": "exerciseRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CTMSGrant",
          "kind": "LinkedField",
          "name": "ctmsGrant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "grantee",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestsListSlideOver_ExerciseRequests"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExercisesActivityDataPoint",
  "abstractKey": null
};

(node as any).hash = "ebd357b1e74c6c3714e721c55c4f064f";

export default node;
