/**
 * @generated SignedSource<<cd3f5be11506ebe843162538377c0f42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriod_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodGraph_Organization">;
  readonly " $fragmentType": "PostTerminationExercisePeriod_Organization";
};
export type PostTerminationExercisePeriod_Organization$key = {
  readonly " $data"?: PostTerminationExercisePeriod_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriod_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationExercisePeriod_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostTerminationExercisePeriodGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "9d5e25c4cc51ff9bc48aaf15d53e8c85";

export default node;
