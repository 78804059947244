import { Disclosure, Transition } from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { isEqual, isNil } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { zodExtra } from "../helpers/zod-extra";
import { useSafeMutation } from "../hooks/useSafeMutation";
import {
  EQUITY_TYPE_WORK_RELATIONSHIP_MAP,
  EquityTypeWorkRelationshipCategory,
} from "../services/workRelationship";
import { ExercisesParametersPerWorkRelationshipEditionBlock_EquityType$key } from "./__generated__/ExercisesParametersPerWorkRelationshipEditionBlock_EquityType.graphql";
import { ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation } from "./__generated__/ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { Divider } from "./ui/Divider";
import { FormRow } from "./ui/Form/FormRow";
import { Input } from "./ui/Form/Inputs/Input";
import { TextArea } from "./ui/Form/Inputs/TextArea";
import { RoundedBox } from "./ui/RoundedBox";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment ExercisesParametersPerWorkRelationshipEditionBlock_EquityType on EquityType {
    id
    exerciseParameters {
      # eslint-disable-next-line relay/unused-fields
      directEmployee {
        taxRanges {
          description
          percentage
        }
        benefits {
          content
        }
        watchout
        ctmsTaxRateInPercent
      }
      # eslint-disable-next-line relay/unused-fields
      eoREmployee {
        taxRanges {
          description
          percentage
        }
        benefits {
          content
        }
        watchout
        ctmsTaxRateInPercent
      }
      # eslint-disable-next-line relay/unused-fields
      contractor {
        taxRanges {
          description
          percentage
        }
        benefits {
          content
        }
        watchout
        ctmsTaxRateInPercent
      }
    }
  }
`;

const UPDATE_EQUITY_TYPE_EXERCISE_PARAMETERS_MUTATION = graphql`
  mutation ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation(
    $equityTypeId: UUID!
    $workRelationship: EquityTypeWorkRelationshipCategory!
    $attributes: EquityTypeExerciseParametersForWorkRelationshipAttributes!
  ) {
    setEquityTypeExerciseParametersForWorkRelationship(
      equityTypeId: $equityTypeId
      workRelationship: $workRelationship
      attributes: $attributes
    ) {
      exerciseParameters {
        directEmployee {
          taxRanges {
            description
            percentage
          }
          benefits {
            content
          }
          watchout
          ctmsTaxRateInPercent
        }
        eoREmployee {
          taxRanges {
            description
            percentage
          }
          benefits {
            content
          }
          watchout
          ctmsTaxRateInPercent
        }
        contractor {
          taxRanges {
            description
            percentage
          }
          benefits {
            content
          }
          watchout
          ctmsTaxRateInPercent
        }
      }
    }
  }
`;

const TaxRangeInputBloc: React.FC<{
  control: UseExercisesParametersForm["control"];
  formState: UseExercisesParametersForm["formState"];
  index: number;
  onDeleteClick: () => void;
}> = ({ control, formState, index, onDeleteClick }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="Medium/Extra Small">Range {index + 1}</Typography>
        <Button
          leftIcon={<XMarkIcon />}
          onClick={onDeleteClick}
          size="extra small"
          variant="Secondary Full"
        >
          DELETE RANGE
        </Button>
      </div>
      <FormRow
        error={formState.errors.taxRanges?.[index]?.description?.message}
      >
        <Input
          placeholder="Range (e.g. Between 50K & 250K)"
          {...control.register(`taxRanges.${index}.description`)}
        />
      </FormRow>
      <FormRow error={formState.errors.taxRanges?.[index]?.percentage?.message}>
        <Input
          before="%"
          placeholder="Percentage (e.g. 5)"
          step={0.01}
          type="number"
          {...control.register(`taxRanges.${index}.percentage`, {
            valueAsNumber: true,
          })}
          required
        />
      </FormRow>
    </div>
  );
};

const BenefitInputBloc: React.FC<{
  control: UseExercisesParametersForm["control"];
  formState: UseExercisesParametersForm["formState"];
  index: number;
  onDeleteClick: () => void;
}> = ({ control, formState, index, onDeleteClick }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="Medium/Extra Small">
          Benefit {index + 1}
        </Typography>
        <Button
          leftIcon={<XMarkIcon />}
          onClick={onDeleteClick}
          size="extra small"
          variant="Secondary Full"
        >
          DELETE BENEFIT
        </Button>
      </div>
      <FormRow error={formState.errors.benefits?.[index]?.content?.message}>
        <TextArea
          className="h-[96px] resize-none"
          placeholder="Content"
          required
          {...control.register(`benefits.${index}.content`)}
        />
      </FormRow>
    </div>
  );
};

const WatchoutInputBloc: React.FC<{
  control: UseExercisesParametersForm["control"];
  formState: UseExercisesParametersForm["formState"];
  onDeleteClick: () => void;
}> = ({ control, formState, onDeleteClick }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="Medium/Extra Small">Watchout</Typography>
        <Button
          leftIcon={<XMarkIcon />}
          onClick={onDeleteClick}
          size="extra small"
          variant="Secondary Full"
        >
          DELETE WATCHOUT
        </Button>
      </div>
      <FormRow error={formState.errors.watchout?.message}>
        <TextArea
          className="h-[96px] resize-none"
          placeholder="Watchout..."
          required
          {...control.register("watchout")}
        />
      </FormRow>
    </div>
  );
};

const CtmsTaxRateInputBloc: React.FC<{
  control: UseExercisesParametersForm["control"];
  formState: UseExercisesParametersForm["formState"];
  onDeleteClick: () => void;
}> = ({ control, formState, onDeleteClick }) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <Typography variant="Medium/Extra Small">CTMS rate</Typography>
        <Button
          leftIcon={<XMarkIcon />}
          onClick={onDeleteClick}
          size="extra small"
          variant="Secondary Full"
        >
          DELETE CTMS RATE
        </Button>
      </div>
      <FormRow error={formState.errors.ctmsTaxRateInPercent?.message}>
        <Input
          before="%"
          placeholder="CTMS rate..."
          required
          step={0.01}
          type="number"
          {...control.register("ctmsTaxRateInPercent", {
            valueAsNumber: true,
          })}
        />
      </FormRow>
    </div>
  );
};

const exerciseParametersFormSchema = z.object({
  benefits: z.array(
    z.object({
      content: z.string().trim().min(1),
    }),
  ),
  ctmsTaxRateInPercent: z.number().nonnegative().nullable(),
  taxRanges: z.array(
    z.object({
      description: zodExtra.nonEmptyNullableString(),
      percentage: z.number().nonnegative(),
    }),
  ),
  watchout: zodExtra.nonEmptyNullableString(),
});

type ExerciseParametersFormInputs = z.infer<
  typeof exerciseParametersFormSchema
>;

const useExercisesParametersForm = ({
  defaultBenefits,
  defaultCtmsRate,
  defaultTaxRanges,
  defaultWatchout,
}: {
  defaultBenefits: { content: null | string }[];
  defaultCtmsRate: null | number;
  defaultTaxRanges: { description: null | string; percentage: null | number }[];
  defaultWatchout: null | string;
}) => {
  return useForm({
    defaultValues: {
      benefits: defaultBenefits,
      ctmsTaxRateInPercent: defaultCtmsRate,
      taxRanges: defaultTaxRanges,
      watchout: defaultWatchout,
    },
    resolver: zodResolver(exerciseParametersFormSchema),
  });
};

type UseExercisesParametersForm = ReturnType<typeof useExercisesParametersForm>;

export const ExercisesParametersPerWorkRelationshipEditionBlock: React.FC<{
  equityTypeFragment: ExercisesParametersPerWorkRelationshipEditionBlock_EquityType$key;
  workRelationship: EquityTypeWorkRelationshipCategory;
}> = ({ equityTypeFragment, workRelationship }) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);

  const defaultTaxRanges: ExerciseParametersFormInputs["taxRanges"] = useMemo(
    () => [
      ...(equityType.exerciseParameters[workRelationship]?.taxRanges ?? []),
    ],
    [equityType.exerciseParameters, workRelationship],
  );
  const defaultBenefits: ExerciseParametersFormInputs["benefits"] = useMemo(
    () => [
      ...(equityType.exerciseParameters[workRelationship]?.benefits ?? []),
    ],
    [equityType.exerciseParameters, workRelationship],
  );
  const defaultWatchout: ExerciseParametersFormInputs["watchout"] =
    equityType.exerciseParameters[workRelationship]?.watchout ?? null;
  const defaultCtmsRate: ExerciseParametersFormInputs["ctmsTaxRateInPercent"] =
    equityType.exerciseParameters[workRelationship]?.ctmsTaxRateInPercent ??
    null;

  const { control, formState, handleSubmit, reset, setValue, watch } =
    useExercisesParametersForm({
      defaultBenefits,
      defaultCtmsRate,
      defaultTaxRanges,
      defaultWatchout,
    });

  const [
    updateEquityTypeExerciseParameters,
    updateEquityTypeExerciseParametersMutationIsInFlight,
  ] =
    useSafeMutation<ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation>(
      UPDATE_EQUITY_TYPE_EXERCISE_PARAMETERS_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (_formInputs) => {
    const formInputs = _formInputs as ExerciseParametersFormInputs;
    const {
      setEquityTypeExerciseParametersForWorkRelationship: updatedEquityType,
    } = await updateEquityTypeExerciseParameters({
      variables: {
        attributes: formInputs,
        equityTypeId: equityType.id,
        workRelationship:
          workRelationship === "directEmployee"
            ? "DirectEmployee"
            : workRelationship === "eoREmployee"
              ? "EoREmployee"
              : "Contractor",
      },
    });
    reset({
      benefits: [
        ...(updatedEquityType.exerciseParameters[workRelationship]?.benefits ??
          []),
      ],
      ctmsTaxRateInPercent:
        updatedEquityType.exerciseParameters[workRelationship]
          ?.ctmsTaxRateInPercent ?? null,
      taxRanges: [
        ...(updatedEquityType.exerciseParameters[workRelationship]?.taxRanges ??
          []),
      ],
      watchout:
        updatedEquityType.exerciseParameters[workRelationship]?.watchout ??
        null,
    });
    toaster.push(
      <Toast title="Well done!">
        Exercise parameters saved for{" "}
        {EQUITY_TYPE_WORK_RELATIONSHIP_MAP[workRelationship].label}
      </Toast>,
    );
  });

  const [taxRanges, benefits, ctmsTaxRateInPercent, watchout] = watch([
    "taxRanges",
    "benefits",
    "ctmsTaxRateInPercent",
    "watchout",
  ]);

  const dataHasBeenModified = useMemo(
    () =>
      !isEqual(
        { benefits, ctmsTaxRateInPercent, taxRanges, watchout },
        {
          benefits: defaultBenefits,
          ctmsTaxRateInPercent: defaultCtmsRate,
          taxRanges: defaultTaxRanges,
          watchout: defaultWatchout,
        },
      ),
    [
      taxRanges,
      benefits,
      ctmsTaxRateInPercent,
      watchout,
      defaultTaxRanges,
      defaultBenefits,
      defaultCtmsRate,
      defaultWatchout,
    ],
  );

  const addTaxRange = useCallback(
    () =>
      setValue("taxRanges", [
        ...taxRanges,
        { description: null, percentage: null },
      ]),
    [taxRanges, setValue],
  );
  const deleteTaxRange = useCallback(
    (index: number) =>
      setValue(
        "taxRanges",
        taxRanges.filter((_, i) => i !== index),
      ),
    [taxRanges, setValue],
  );

  const addBenefit = useCallback(
    () => setValue("benefits", [...benefits, { content: null }]),
    [benefits, setValue],
  );
  const deleteBenefit = useCallback(
    (index: number) =>
      setValue(
        "benefits",
        benefits.filter((_, i) => i !== index),
      ),
    [benefits, setValue],
  );

  const [displayWatchoutBox, setDisplayWatchoutBox] = useState(
    Boolean(defaultWatchout),
  );

  const addWatchout = useCallback(
    () => setDisplayWatchoutBox(true),
    [setDisplayWatchoutBox],
  );
  const deleteWatchout = useCallback(() => {
    setValue("watchout", null);
    setDisplayWatchoutBox(false);
  }, [setDisplayWatchoutBox, setValue]);

  const [displayCtmsRateBox, setDisplayCtmsRateBox] = useState(
    !isNil(defaultCtmsRate),
  );

  const addCtmsRate = useCallback(
    () => setDisplayCtmsRateBox(true),
    [setDisplayCtmsRateBox],
  );
  const deleteCtmsRate = useCallback(() => {
    setValue("ctmsTaxRateInPercent", null);
    setDisplayCtmsRateBox(false);
  }, [setDisplayCtmsRateBox, setValue]);

  return (
    <RoundedBox className="overflow-hidden" withBorder>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between bg-gray-02 px-6 py-4 text-left">
              <Typography variant="Medium/Extra Small">
                {EQUITY_TYPE_WORK_RELATIONSHIP_MAP[workRelationship].label}
              </Typography>
              <ChevronDownIcon
                className={classNames(
                  {
                    "rotate-180": open,
                  },
                  "h-4",
                )}
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <form className="space-y-6 p-10" onSubmit={onSubmit}>
                  <div className="flex gap-10">
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography variant="Medium/Extra Small">
                        Estimated taxes due on spread
                      </Typography>
                      <ul className="list-inside list-disc text-black-05">
                        <li>
                          Leave empty if you don’t want to display anything
                        </li>
                        <li>
                          Please input only a % percentage, we’ll automatically
                          compute the $values
                        </li>
                      </ul>
                    </div>
                    <div className="w-[551px] shrink-0 space-y-6">
                      {taxRanges.map((_, index) => (
                        <TaxRangeInputBloc
                          control={control}
                          formState={formState}
                          index={index}
                          key={`taxRange-${index}`}
                          onDeleteClick={() => deleteTaxRange(index)}
                        />
                      ))}
                      <Button
                        leftIcon={<PlusIcon />}
                        onClick={addTaxRange}
                        size="extra small"
                        type="button"
                        variant="Primary Outline"
                      >
                        Add tax range
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <div className="flex gap-10">
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography variant="Medium/Extra Small">
                        Potential tax benefits
                      </Typography>
                      <ul className="list-inside list-disc text-black-05">
                        <li>
                          Add benefits here. Add a title and content per benefit
                        </li>
                      </ul>
                    </div>
                    <div className="w-[551px] shrink-0 space-y-6">
                      {benefits.map((_, index) => (
                        <BenefitInputBloc
                          control={control}
                          formState={formState}
                          index={index}
                          key={`benefit-${index}`}
                          onDeleteClick={() => deleteBenefit(index)}
                        />
                      ))}
                      <Button
                        leftIcon={<PlusIcon />}
                        onClick={addBenefit}
                        size="extra small"
                        type="button"
                        variant="Primary Outline"
                      >
                        Add a benefit
                      </Button>
                    </div>
                  </div>
                  <Divider />
                  <div className="flex gap-10">
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography variant="Medium/Extra Small">
                        Potential watchout
                      </Typography>
                      <ul className="list-inside list-disc text-black-05">
                        <li>Add a watch-out in the box when relevant</li>
                      </ul>
                    </div>
                    <div className="w-[551px] shrink-0 space-y-6">
                      {displayWatchoutBox ? (
                        <WatchoutInputBloc
                          control={control}
                          formState={formState}
                          onDeleteClick={deleteWatchout}
                        />
                      ) : (
                        <Button
                          leftIcon={<PlusIcon />}
                          onClick={addWatchout}
                          size="extra small"
                          type="button"
                          variant="Primary Outline"
                        >
                          Add a watchout
                        </Button>
                      )}
                    </div>
                  </div>
                  <Divider />
                  {workRelationship === "directEmployee" && (
                    <>
                      <div className="flex gap-10">
                        <div className="flex flex-1 flex-col gap-2">
                          <Typography variant="Medium/Extra Small">
                            CTMS rate
                          </Typography>
                          <ul className="list-inside list-disc text-black-05">
                            <li>
                              Add the marginal rate % to allow calculation for
                              CTMS
                            </li>
                          </ul>
                        </div>
                        <div className="w-[551px] shrink-0 space-y-6">
                          {displayCtmsRateBox ? (
                            <CtmsTaxRateInputBloc
                              control={control}
                              formState={formState}
                              onDeleteClick={deleteCtmsRate}
                            />
                          ) : (
                            <Button
                              leftIcon={<PlusIcon />}
                              onClick={addCtmsRate}
                              size="extra small"
                              type="button"
                              variant="Primary Outline"
                            >
                              Add a rate
                            </Button>
                          )}
                        </div>
                      </div>
                      <Divider />
                    </>
                  )}
                  <div className="flex justify-end gap-2">
                    <Button
                      disabled={
                        !dataHasBeenModified ||
                        updateEquityTypeExerciseParametersMutationIsInFlight
                      }
                      onClick={() => reset()}
                      size="extra small"
                      type="button"
                      variant="Secondary Full"
                    >
                      Discard
                    </Button>
                    <Button
                      disabled={
                        !dataHasBeenModified ||
                        updateEquityTypeExerciseParametersMutationIsInFlight
                      }
                      loading={
                        updateEquityTypeExerciseParametersMutationIsInFlight
                      }
                      size="extra small"
                      type="submit"
                      variant="Primary Full"
                    >
                      Save changes
                    </Button>
                  </div>
                </form>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </RoundedBox>
  );
};
