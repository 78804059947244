/**
 * @generated SignedSource<<d1d4a56242422aa67aab8ca54e6c3955>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationRoleSwitcher_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"useAccountRoles_Account">;
  readonly " $fragmentType": "OrganizationRoleSwitcher_Account";
};
export type OrganizationRoleSwitcher_Account$key = {
  readonly " $data"?: OrganizationRoleSwitcher_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRoleSwitcher_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationRoleSwitcher_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useAccountRoles_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "8fac068b031a396071939d42c672cae2";

export default node;
