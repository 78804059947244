/**
 * @generated SignedSource<<f636ade566faff3543bc51a9c4963f3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Country_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"LargeCenteredDetailsLayout_Organization">;
  readonly " $fragmentType": "Country_Organization";
};
export type Country_Organization$key = {
  readonly " $data"?: Country_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Country_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Country_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "dca7c7a086c2130b12e36bf8909e36e6";

export default node;
