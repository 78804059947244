import { CheckIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Typography } from "../../../components/ui/Typography";
import { ExerciseRequestCompletedSection_CTMSExerciseRequest$key } from "./__generated__/ExerciseRequestCompletedSection_CTMSExerciseRequest.graphql";
import { ExerciseRequestCompletedSection_Organization$key } from "./__generated__/ExerciseRequestCompletedSection_Organization.graphql";
import { ExerciseRequestCompletedSection_Viewer$key } from "./__generated__/ExerciseRequestCompletedSection_Viewer.graphql";
import { WantToKnowMoreBox } from "./WantToKnowMoreBox";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment ExerciseRequestCompletedSection_CTMSExerciseRequest on CTMSExerciseRequest {
    status
    ctmsGrant {
      ...WantToKnowMoreBox_CTMSGrant
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExerciseRequestCompletedSection_Organization on Organization {
    ...WantToKnowMoreBox_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment ExerciseRequestCompletedSection_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

export const ExerciseRequestCompletedSection: React.FC<{
  ctmsExerciseRequestFragment: ExerciseRequestCompletedSection_CTMSExerciseRequest$key;
  organizationFragment: ExerciseRequestCompletedSection_Organization$key;
  viewerFragment: ExerciseRequestCompletedSection_Viewer$key;
}> = ({
  ctmsExerciseRequestFragment,
  organizationFragment,
  viewerFragment,
}) => {
  const ctmsExerciseRequest = useFragment(
    EXERCISE_REQUEST_FRAGMENT,
    ctmsExerciseRequestFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const isCompleted = ctmsExerciseRequest.status === "Done";

  return (
    <ExerciseRequestTimelineSection
      active={isCompleted}
      chipContent={
        !isCompleted ? (
          <ClockIcon className="h-4 w-4" />
        ) : (
          <CheckIcon className="h-4 w-4" />
        )
      }
      chipRounded
    >
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Default">
          Exercise is complete
        </Typography>
        {viewer.isAllowedToManageOrganization && (
          <WantToKnowMoreBox
            ctmsGrantFragment={ctmsExerciseRequest.ctmsGrant}
            organizationFragment={organization}
          />
        )}
      </div>
    </ExerciseRequestTimelineSection>
  );
};
