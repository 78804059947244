/**
 * @generated SignedSource<<35604cf8aa1ff8ab67653ffd06e56649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingSchedules_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedulesGraph_Viewer">;
  readonly " $fragmentType": "VestingSchedules_Viewer";
};
export type VestingSchedules_Viewer$key = {
  readonly " $data"?: VestingSchedules_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedules_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingSchedules_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingSchedulesGraph_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "b210825fec7d46a00247a85d83fb14ee";

export default node;
