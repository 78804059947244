import { graphql } from "relay-runtime";

import type { useIgnoreCleanupSuggestionMutation } from "./__generated__/useIgnoreCleanupSuggestionMutation.graphql";

import { useSafeMutation } from "../../../hooks/useSafeMutation";

const MUTATION = graphql`
  mutation useIgnoreCleanupSuggestionMutation(
    $HRISProvider: HRISProvider!
    $HRISProviderEmployeeId: String!
    $organizationId: OrganizationId!
  ) {
    ignoreHRISProviderEmployee(
      hRISProvider: $HRISProvider
      hRISProviderEmployeeId: $HRISProviderEmployeeId
      organizationId: $organizationId
    ) {
      id
      ignored
    }
  }
`;

export default function useIgnoreCleanupSuggestionMutation() {
  return useSafeMutation<useIgnoreCleanupSuggestionMutation>(MUTATION);
}
