/**
 * @generated SignedSource<<dd169b72cea243c8900d1381bb21055d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsEquityViewers_Organization$data = {
  readonly equityViewers: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Item_EquityViewer">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "OrganizationSettingsEquityViewers_Organization";
};
export type OrganizationSettingsEquityViewers_Organization$key = {
  readonly " $data"?: OrganizationSettingsEquityViewers_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsEquityViewers_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityViewer",
      "kind": "LinkedField",
      "name": "equityViewers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationSettingsEquityViewers_Item_EquityViewer"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "c29e22c2f954c49c9fd0bb5762dc892f";

export default node;
