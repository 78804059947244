/**
 * @generated SignedSource<<feee6d6f05aa849f09cf6cf04464fa26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamIncentivizationVestingMode = "TOTAL_GRANTED" | "TO_VEST" | "VESTED";
export type TeamIncentivization_UpdateDataPoints_Query$variables = {
  organizationId: string;
  vestingMode: TeamIncentivizationVestingMode;
};
export type TeamIncentivization_UpdateDataPoints_Query$data = {
  readonly teamIncentivizationDataPoints: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivization_TeamIncentivizationDataPoint">;
  }>;
};
export type TeamIncentivization_UpdateDataPoints_Query = {
  response: TeamIncentivization_UpdateDataPoints_Query$data;
  variables: TeamIncentivization_UpdateDataPoints_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vestingMode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "vestingMode",
    "variableName": "vestingMode"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeamIncentivization_UpdateDataPoints_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamIncentivizationDataPoint",
        "kind": "LinkedField",
        "name": "teamIncentivizationDataPoints",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TeamIncentivization_TeamIncentivizationDataPoint"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeamIncentivization_UpdateDataPoints_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TeamIncentivizationDataPoint",
        "kind": "LinkedField",
        "name": "teamIncentivizationDataPoints",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valueInUSD",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grantsCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0ba34bed0f381761215421a3132dbff",
    "id": null,
    "metadata": {},
    "name": "TeamIncentivization_UpdateDataPoints_Query",
    "operationKind": "query",
    "text": "query TeamIncentivization_UpdateDataPoints_Query(\n  $organizationId: OrganizationId!\n  $vestingMode: TeamIncentivizationVestingMode!\n) {\n  teamIncentivizationDataPoints(organizationId: $organizationId, vestingMode: $vestingMode) {\n    ...TeamIncentivization_TeamIncentivizationDataPoint\n  }\n}\n\nfragment TeamIncentivizationGraph_TeamIncentivizationDataPoint on TeamIncentivizationDataPoint {\n  grantees {\n    id\n    name\n  }\n  grantsCount\n  valueInUSD\n}\n\nfragment TeamIncentivization_TeamIncentivizationDataPoint on TeamIncentivizationDataPoint {\n  valueInUSD\n  grantees {\n    id\n  }\n  ...TeamIncentivizationGraph_TeamIncentivizationDataPoint\n}\n"
  }
};
})();

(node as any).hash = "dcb09aa4753600aba640312eec40eeaa";

export default node;
