/**
 * @generated SignedSource<<4dd61fa7e42e4945bed3ccb4574a552a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeVestingSchedule_CTMSGrant$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant">;
  readonly " $fragmentType": "GranteeVestingSchedule_CTMSGrant";
}>;
export type GranteeVestingSchedule_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: GranteeVestingSchedule_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeVestingSchedule_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeVestingSchedule_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "bdc81517292df282ff5d1f20790d5a1d";

export default node;
