/**
 * @generated SignedSource<<d0d4f3e27522ef964eacb001f9ddb42e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OngoingObligationsPage_EquityType$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"CreateOngoingObligationSlideOver_EquityType">;
  readonly " $fragmentType": "OngoingObligationsPage_EquityType";
}>;
export type OngoingObligationsPage_EquityType$key = ReadonlyArray<{
  readonly " $data"?: OngoingObligationsPage_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsPage_EquityType">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OngoingObligationsPage_EquityType",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateOngoingObligationSlideOver_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "243296684774363287099aab91018df8";

export default node;
