/**
 * @generated SignedSource<<b134881e03982b4b967f3da3f62f1593>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation$variables = {
  ceoNote: string;
  organizationId: string;
};
export type BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation$data = {
  readonly updateOrganizationPendingFairMarketValueCeoNote: {
    readonly pendingFairMarketValueCeoNote: string | null;
  };
};
export type BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation = {
  response: BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation$data;
  variables: BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ceoNote"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ceoNote",
    "variableName": "ceoNote"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pendingFairMarketValueCeoNote",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingFairMarketValueCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingFairMarketValueCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d0ab81973b4845c610ce85224f52ac8",
    "id": null,
    "metadata": {},
    "name": "BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation(\n  $organizationId: OrganizationId!\n  $ceoNote: String!\n) {\n  updateOrganizationPendingFairMarketValueCeoNote(organizationId: $organizationId, ceoNote: $ceoNote) {\n    pendingFairMarketValueCeoNote\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1409b91de3c0ebaaf8c8bfba96b25743";

export default node;
