import { useApplicationName } from "../hooks/useApplicationTheme";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

export const GranteePortalMissingInformationMessage: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <NoticeMessage size="Small" variant="Warning">
      <div className="flex flex-col gap-2">
        <Typography className="text-orange" variant="Medium/Extra Small">
          As this grant wasn’t made via {applicationName}, we couldn’t use
          personal data in the example below.
        </Typography>
        <Typography variant="Regular/Extra Small">
          The data use to illustrate the reporting and taxation explanation are
          mocked.
        </Typography>
      </div>
    </NoticeMessage>
  );
};
