import { FormattedMessage, FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ShortDate } from "../../../../components/ShortDate";
import { LinkButton } from "../../../../components/ui/Button";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { BillableGeographiesSlideOver_Organization$key } from "./__generated__/BillableGeographiesSlideOver_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BillableGeographiesSlideOver_Organization on Organization {
    name
    organizationGeographies {
      geography {
        country {
          emoji
          name
        }
      }
      price {
        discount
        priceAfterDiscount
        priceBeforeDiscount
      }
      unlockedAt
      billableGranteesCount
    }
  }
`;

export const BillableGeographiesSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: BillableGeographiesSlideOver_Organization$key;
  show: boolean;
}> = ({ onClose, organizationFragment, show }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const organizationGeographies = organization.organizationGeographies;
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] About billed geographies`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={10}>
          Active geographies
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <div className="px-6">
        <div className="flex flex-col divide-y-[0.5px] divide-gray-04">
          {organizationGeographies.map((organizationGeography) => (
            <div
              className="flex items-center justify-between px-4 py-3.5"
              key={organizationGeography.geography.country.name}
            >
              <div className="flex items-center">
                <Typography className="w-12" variant="Medium/Large">
                  {organizationGeography.geography.country.emoji}
                </Typography>
                <Typography variant="Medium/Extra Small">
                  {organizationGeography.geography.country.name}
                </Typography>
              </div>
              <div className="flex flex-col items-end gap-1">
                {organizationGeography.price &&
                organizationGeography.price.priceAfterDiscount > 0 ? (
                  <div className="flex gap-2">
                    {organizationGeography.price.discount > 0 && (
                      <Typography
                        className="line-through"
                        variant="Regular/Extra Small"
                      >
                        <FormattedNumber
                          currency="USD"
                          style="currency"
                          value={
                            organizationGeography.price.priceBeforeDiscount
                          }
                        />
                      </Typography>
                    )}
                    <Typography variant="Medium/Extra Small">
                      <FormattedNumber
                        currency="USD"
                        style="currency"
                        value={organizationGeography.price.priceAfterDiscount}
                      />
                    </Typography>
                  </div>
                ) : (
                  <Tag>Unlocked for free</Tag>
                )}
                <Typography className="text-gray-09" variant="Regular/Caption">
                  Unlocked on{" "}
                  <ShortDate value={organizationGeography.unlockedAt} />
                </Typography>
                <Tag color="glass-green">
                  <FormattedMessage
                    defaultMessage="{granteesCount} {granteesCount, plural, one {billed grantee} other {billed grantees}}"
                    values={{
                      granteesCount:
                        organizationGeography.billableGranteesCount,
                    }}
                  />
                </Tag>
              </div>
            </div>
          ))}
        </div>
        <div className="py-6">
          <div className="flex items-center justify-between rounded-lg bg-gray-01 px-10 py-6">
            <Typography variant="Regular/Extra Small">
              If you have any questions, please contact us.
            </Typography>
            <LinkButton
              size="small"
              to={`mailto:${supportEmailAddress}subject=${mailtoSubject}`}
              variant="Primary Outline"
            >
              Contact us
            </LinkButton>
          </div>
        </div>
      </div>
    </SlideOver>
  );
};
