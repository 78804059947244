/**
 * @generated SignedSource<<21a0d7bf06a51686fe2b776194d374ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ignored_Organization$data = {
  readonly id: string;
  readonly ignoredHRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_HRISProviderEmployee">;
  }>;
  readonly ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SynchronizeEmployeesDataItem_HRISProviderEmployee">;
  }>;
  readonly ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UpdateInformationItem_HRISProviderEmployee">;
  }>;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_Organization" | "SynchronizeEmployeesDataItem_Organization" | "UpdateInformationItem_Organization">;
  readonly " $fragmentType": "Ignored_Organization";
};
export type Ignored_Organization$key = {
  readonly " $data"?: Ignored_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Ignored_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "ignored",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Ignored_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UpdateInformationItem_HRISProviderEmployee"
        }
      ],
      "storageKey": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(ignored:true)"
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedAsNewGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateNewGranteeItem_HRISProviderEmployee"
        }
      ],
      "storageKey": "HRISProviderEmployeesSuggestedAsNewGrantee(ignored:true)"
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SynchronizeEmployeesDataItem_HRISProviderEmployee"
        }
      ],
      "storageKey": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(ignored:true)"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateInformationItem_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SynchronizeEmployeesDataItem_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateNewGranteeItem_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0f49dd2aa1376c6ef3e66bbe9d4d3f9a";

export default node;
