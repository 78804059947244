import { NoticeMessage } from "./ui/NoticeMessage";

export const GranteeContractNotStartedYetNoticeMessage: React.FC = () => {
  return (
    <NoticeMessage size="Small" variant="Warning">
      This grantee’s contract start date is set in the future. This can have
      some impact on the grant if the FMV changes before the grant is signed.
      Therefore, you are allowed to draft new grants but you won’t be able to
      send these drafts to your board for consent.
    </NoticeMessage>
  );
};
