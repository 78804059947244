import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { PoolDetails_Organization$key } from "./__generated__/PoolDetails_Organization.graphql";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PoolDetails_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
    poolAvailableShares
    poolGrantedSharesBreakdown {
      total
    }
    poolPendingSharesBreakdown {
      total
    }
  }
`;

export const PoolDetails: React.FC<{
  grantedShares: number;
  organizationFragment: PoolDetails_Organization$key;
}> = ({ grantedShares, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const preGrantAvailableShares =
    organization.poolAvailableShares +
    organization.poolPendingSharesBreakdown.total;

  const preGrantGrantedShares = organization.poolGrantedSharesBreakdown.total;

  const postGrantAvailableShares = preGrantAvailableShares - grantedShares;
  const postGrantGrantedShares = preGrantGrantedShares + grantedShares;

  const { sharesToFullyDilutedRatio } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const grantedSharesOwnership = sharesToFullyDilutedRatio(grantedShares);
  const preGrantAvailableSharesOwnership = sharesToFullyDilutedRatio(
    preGrantAvailableShares,
  );
  const preGrantGrantedSharesOwnership = sharesToFullyDilutedRatio(
    preGrantGrantedShares,
  );
  const postGrantAvailableSharesOwnership = sharesToFullyDilutedRatio(
    postGrantAvailableShares,
  );
  const postGrantGrantedSharesOwnership = sharesToFullyDilutedRatio(
    postGrantGrantedShares,
  );

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="w-[20%]"></th>
          <th className="w-[40%] bg-gray-01 p-6 pb-0 text-left">
            <Typography variant="Medium/Extra Small">Pre-approval</Typography>
          </th>
          <th className="w-[40%] p-6 pb-0 text-left">
            <Typography variant="Medium/Extra Small">
              Approval is signed
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th align="center" className="p-6">
            <Typography className="text-gray-09" variant="Regular/Caption">
              Available
            </Typography>
          </th>
          <td className="bg-gray-01 p-6">
            {preGrantAvailableSharesOwnership !== null && (
              <Typography
                className="block text-black-07"
                variant="Regular/Extra Small"
              >
                <FormattedPercentage value={preGrantAvailableSharesOwnership} />
              </Typography>
            )}
            <Typography
              className="mt-2 block text-black-05"
              variant="Regular/Extra Small"
            >
              <FormattedNumber value={preGrantAvailableShares} />
            </Typography>
          </td>
          <td className="p-6">
            {postGrantAvailableSharesOwnership !== null && (
              <Typography className="flex gap-2" variant="Regular/Extra Small">
                <FormattedPercentage
                  value={postGrantAvailableSharesOwnership}
                />
                {grantedSharesOwnership !== null && (
                  <div className="text-primary">
                    <FormattedPercentage
                      signDisplay="always"
                      value={-grantedSharesOwnership}
                    />
                  </div>
                )}
              </Typography>
            )}

            <Typography
              className="mt-1 flex gap-2 text-black-05"
              variant="Regular/Extra Small"
            >
              <FormattedNumber value={postGrantAvailableShares} />
              <div className="text-primary">
                <FormattedNumber signDisplay="always" value={-grantedShares} />
              </div>
            </Typography>
          </td>
        </tr>
        <tr className="border-t border-gray-04 p-6">
          <th align="center">
            <Typography className="text-gray-09" variant="Regular/Caption">
              Granted
            </Typography>
          </th>
          <td className="bg-gray-01 p-6">
            {preGrantGrantedSharesOwnership !== null && (
              <Typography
                className="block text-black-07"
                variant="Regular/Extra Small"
              >
                <FormattedPercentage value={preGrantGrantedSharesOwnership} />
              </Typography>
            )}
            <Typography
              className="mt-1 block text-black-05"
              variant="Regular/Extra Small"
            >
              <FormattedNumber value={preGrantGrantedShares} />
            </Typography>
          </td>
          <td className="p-6">
            {postGrantGrantedSharesOwnership !== null && (
              <Typography className="flex gap-2" variant="Regular/Extra Small">
                <FormattedPercentage value={postGrantGrantedSharesOwnership} />
                {grantedSharesOwnership !== null && (
                  <div className="text-primary">
                    <FormattedPercentage
                      signDisplay="always"
                      value={grantedSharesOwnership}
                    />
                  </div>
                )}
              </Typography>
            )}

            <Typography
              className="mt-2 flex gap-2 text-black-05"
              variant="Regular/Extra Small"
            >
              <FormattedNumber value={postGrantGrantedShares} />
              <div className="text-primary">
                <FormattedNumber signDisplay="always" value={grantedShares} />
              </div>
            </Typography>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
