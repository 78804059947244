/**
 * @generated SignedSource<<555feeeeb9052331d90c53d676a7a2c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingAccelerationSlider_CTMSGrant$data = {
  readonly vestingSchedule: {
    readonly durationInMonths: number;
  } | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentType": "VestingAccelerationSlider_CTMSGrant";
};
export type VestingAccelerationSlider_CTMSGrant$key = {
  readonly " $data"?: VestingAccelerationSlider_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingAccelerationSlider_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingAccelerationSlider_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationInMonths",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "4c6d2e564e62a2aca7a6ea453a8e6acf";

export default node;
