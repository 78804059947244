import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant$key } from "./__generated__/CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant.graphql";
import { Typography } from "./ui/Typography";

type Variant = "Caption" | "Extra Small";
const VARIANTS = {
  ["Caption"]: {
    medium: "Medium/Caption",
    regular: "Regular/Caption",
  },
  ["Extra Small"]: {
    medium: "Medium/Extra Small",
    regular: "Regular/Extra Small",
  },
} as const;

const GRANT_FRAGMENT = graphql`
  fragment CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant on CTMSGrant {
    remainingDaysToExercise
    exercisableNonExercisedAmount
    isVirtual
  }
`;

export const CtmsGrantRemainingDaysToExerciseMessage: React.FC<{
  ctmsGrantFragment: CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant$key;
  variant?: Variant;
}> = ({ ctmsGrantFragment, variant = "Extra Small" }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  if (
    ctmsGrant.exercisableNonExercisedAmount === 0 ||
    ctmsGrant.remainingDaysToExercise === null
  ) {
    return null;
  }

  return (
    <Typography variant={VARIANTS[variant].regular}>
      <Typography variant={VARIANTS[variant].medium}>
        <FormattedNumber
          style="unit"
          unit="day"
          value={ctmsGrant.remainingDaysToExercise}
        />
      </Typography>{" "}
      remaining to {ctmsGrant.isVirtual ? "settle" : "exercise"}
    </Typography>
  );
};
