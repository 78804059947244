import { zodResolver } from "@hookform/resolvers/zod";
import { isNil } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../../components/ui/Button";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { SearchBar } from "../../../components/ui/SearchBar";
import { zodExtra } from "../../../helpers/zod-extra";
import {
  ExercisesFilters_Exercises$data,
  ExercisesFilters_Exercises$key,
} from "./__generated__/ExercisesFilters_Exercises.graphql";

const filterFormSchema = z.object({
  maximumTotalExercisePrice: zodExtra.preprocessNaNToUndefined(
    z.number().optional().nullable(),
  ),
  minimumTotalExercisePrice: zodExtra.preprocessNaNToUndefined(
    z.number().min(0).optional().nullable(),
  ),
});

const EXERCISES_FRAGMENT = graphql`
  fragment ExercisesFilters_Exercises on CTMSExerciseRequest
  @relay(plural: true) {
    ctmsId
    ctmsGrantLabel
    totalExercisePrice
    ctmsGrant {
      grantee {
        name
        email
      }
    }
  }
`;

const filterExercises = ({
  activeFilters,
  exercises,
  fullTextFilter,
}: {
  activeFilters: FilterFormSchema;
  exercises: ExercisesFilters_Exercises$data;
  fullTextFilter: string;
}) => {
  const fullTextFilterLowerCase = fullTextFilter.toLocaleLowerCase();

  return exercises
    .filter(
      (exercise) =>
        exercise.ctmsGrantLabel
          .toLowerCase()
          .includes(fullTextFilterLowerCase) ||
        exercise.ctmsGrant.grantee.name
          .toLowerCase()
          .includes(fullTextFilterLowerCase) ||
        exercise.ctmsGrant.grantee.email.includes(fullTextFilterLowerCase),
    )
    .filter(
      (exercise) =>
        isNil(activeFilters.minimumTotalExercisePrice) ||
        (typeof exercise.totalExercisePrice === "number" &&
          exercise.totalExercisePrice >
            activeFilters.minimumTotalExercisePrice),
    )
    .filter(
      (exercise) =>
        isNil(activeFilters.maximumTotalExercisePrice) ||
        (typeof exercise.totalExercisePrice === "number" &&
          exercise.totalExercisePrice <
            activeFilters.maximumTotalExercisePrice),
    );
};

export type FilterFormSchema = z.infer<typeof filterFormSchema>;

const ExercisesFilters: React.FC<{
  exercisesFragment: ExercisesFilters_Exercises$key;
  setFilteredExercisesIds: (exercisesIds: string[]) => void;
}> = ({ exercisesFragment, setFilteredExercisesIds }) => {
  const exercises = useFragment(EXERCISES_FRAGMENT, exercisesFragment);

  const [fullTextFilter, setFullTextFilter] = useState("");
  const [activeFilters, setActiveFilters] = useState<FilterFormSchema>({});

  useEffect(() => {
    const filteredExercises = filterExercises({
      activeFilters,
      exercises,
      fullTextFilter,
    });

    setFilteredExercisesIds(
      filteredExercises.map((exercise) => exercise.ctmsId),
    );
  }, [activeFilters, fullTextFilter, exercises, setFilteredExercisesIds]);

  const filterForm = useForm<z.output<typeof filterFormSchema>>({
    resolver: zodResolver(filterFormSchema),
  });

  const someFiltersAreActive = useMemo(
    () =>
      !isNil(activeFilters.minimumTotalExercisePrice) ||
      !isNil(activeFilters.maximumTotalExercisePrice),
    [activeFilters],
  );

  return (
    <div className="space-y-4">
      <div className="relative flex items-center gap-4">
        <SearchBar
          onChange={setFullTextFilter}
          placeholder="Search..."
          value={fullTextFilter}
        />
        <SearchBar.FiltersBox
          renderApplyButton={() => <Button />}
          renderClearButton={(close) => (
            <Button
              onClick={() => {
                filterForm.reset();
                setActiveFilters({});
                close();
              }}
            />
          )}
          renderForm={(close) => (
            <form
              onSubmit={filterForm.handleSubmit((submittedFilters) => {
                setActiveFilters(submittedFilters);
                close();
              })}
            />
          )}
        >
          <SearchBar.FiltersBoxItem label="$ Total exercise price">
            <div className="flex gap-2 [&>div]:flex-1">
              <Input
                placeholder="Minimum"
                type="number"
                {...filterForm.register("minimumTotalExercisePrice", {
                  valueAsNumber: true,
                })}
              />
              <Input
                placeholder="Maximum"
                type="number"
                {...filterForm.register("maximumTotalExercisePrice", {
                  valueAsNumber: true,
                })}
              />
            </div>
          </SearchBar.FiltersBoxItem>
        </SearchBar.FiltersBox>
      </div>
      {someFiltersAreActive && (
        <SearchBar.FilterChipsContainer
          onClearAllFiltersButtonClick={() => {
            filterForm.reset();
            setActiveFilters({});
          }}
        >
          {(!isNil(activeFilters.maximumTotalExercisePrice) ||
            !isNil(activeFilters.minimumTotalExercisePrice)) && (
            <SearchBar.FilterChip
              label="Exercise price"
              onRemoveButtonClick={() => {
                filterForm.setValue("minimumTotalExercisePrice", undefined);
                filterForm.setValue("maximumTotalExercisePrice", undefined);
                setActiveFilters((prevActiveFilters) => ({
                  ...prevActiveFilters,
                  maximumTotalExercisePrice: undefined,
                  minimumTotalExercisePrice: undefined,
                }));
              }}
            >
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={0}
                style="currency"
                value={activeFilters.minimumTotalExercisePrice ?? 0}
              />{" "}
              -{" "}
              {!isNil(activeFilters.maximumTotalExercisePrice) ? (
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={activeFilters.maximumTotalExercisePrice}
                />
              ) : (
                "∞"
              )}
            </SearchBar.FilterChip>
          )}
        </SearchBar.FilterChipsContainer>
      )}
    </div>
  );
};

export default ExercisesFilters;
