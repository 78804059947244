import { Disclosure, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CtmsGrantDetailsExercise } from "../../../../components/CtmsGrantDetailsExercise";
import { DetailsBox } from "../../../../components/DetailsBox";
import { ExerciseStatusTag } from "../../../../components/ExerciseStatusTag";
import { Divider } from "../../../../components/ui/Divider";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { GranteeExerciseBox_Grantee$key } from "./__generated__/GranteeExerciseBox_Grantee.graphql";
import { GranteeExerciseBox_Organization$key } from "./__generated__/GranteeExerciseBox_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeExerciseBox_Organization on Organization {
    id
    ...CtmsGrantDetailsExercise_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeExerciseBox_Grantee on Grantee {
    ctmsGrants {
      id
      label
      exerciseStatus
      settled
      ...CtmsGrantDetailsExercise_CTMSGrant
    }
  }
`;

const GranteeExerciseBox: React.FC<{
  granteeFragment: GranteeExerciseBox_Grantee$key;
  organizationFragment: GranteeExerciseBox_Organization$key;
}> = ({ granteeFragment, organizationFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <DetailsBox Icon={CurrencyDollarIcon} padded={false} title="Exercise">
      <div className="flex flex-col">
        {grantee.ctmsGrants.map((ctmsGrant) => (
          <>
            <Divider key={`${ctmsGrant.id}-divider`} />
            <Disclosure key={ctmsGrant.id}>
              {({ open }) => (
                <div className="p-6">
                  <Disclosure.Button className="flex w-full items-center gap-2">
                    <Typography variant="Medium/Extra Small">
                      {ctmsGrant.label}
                    </Typography>
                    <ExerciseStatusTag
                      exerciseStatus={ctmsGrant.exerciseStatus}
                    />
                    {ctmsGrant.settled && <Tag color="red">Settled</Tag>}
                    <div className="flex-grow">
                      <ChevronDownIcon
                        className={classNames(
                          {
                            "rotate-180": open,
                          },
                          "ml-auto h-4",
                        )}
                      />
                    </div>
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      <CtmsGrantDetailsExercise.Content
                        ctmsGrantFragment={ctmsGrant}
                        displayLastDayToExercise={true}
                        organizationFragment={organization}
                      />
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          </>
        ))}
      </div>
    </DetailsBox>
  );
};

export default GranteeExerciseBox;
