/**
 * @generated SignedSource<<4c073c2389046e82321c0539f098b005>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsComponent_Account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsGrant_Viewer" | "DraftGrantDetailsVestingSchedule_Account" | "GranteeDetailsBox_Viewer">;
  readonly " $fragmentType": "DraftGrantDetailsComponent_Account";
};
export type DraftGrantDetailsComponent_Account$key = {
  readonly " $data"?: DraftGrantDetailsComponent_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Account">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsComponent_Account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsVestingSchedule_Account"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Viewer"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsGrant_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "2f950d733c7280b57d8793147561a1cb";

export default node;
