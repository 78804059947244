/**
 * @generated SignedSource<<d0270db61a98c65fa2172081854f6035>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinalThoughtsSettings_EquityOffer$data = {
  readonly finalThoughts: string | null;
  readonly organization: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPreviewLayout_EquityOffer" | "FinalThoughts_EquityOffer" | "useEquityOfferShareUtils_EquityOffer" | "useEquityOfferTrackEvent_EquityOffer">;
  readonly " $fragmentType": "FinalThoughtsSettings_EquityOffer";
};
export type FinalThoughtsSettings_EquityOffer$key = {
  readonly " $data"?: FinalThoughtsSettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinalThoughtsSettings_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinalThoughtsSettings_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalThoughts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEquityOfferShareUtils_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEquityOfferTrackEvent_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalThoughts_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "87327720ecf69c14b3839d6c2ad37181";

export default node;
