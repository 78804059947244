import { useMemo } from "react";
import { FormattedNumber } from "react-intl";

import { last } from "../helpers/array-utility";
import { NonEmptyArray } from "../helpers/ts-utlity";
import { PreviewBox } from "./ui/Layout/PreviewLayout";
import { Typography } from "./ui/Typography";
import { DataPoint, VestingGraph } from "./VestingGraph";

export const VestingSchedulePreviewBox: React.FC<{
  vestingDataPoints: NonEmptyArray<DataPoint>;
}> = ({ vestingDataPoints }) => {
  const sharesReceived = useMemo(
    () => last(vestingDataPoints).cumulativeVested,
    [vestingDataPoints],
  );

  return (
    <PreviewBox previewClassnames="!p-6">
      <div className="space-y-8 rounded-lg border-[0.5px] border-primary pt-6">
        <div className="space-y-2 px-6">
          <Typography as="div" variant="Medium/Small">
            Potential value projection
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            e.g. Grantee receives <FormattedNumber value={sharesReceived} />{" "}
            shares
          </Typography>
        </div>

        <VestingGraph
          dateDisplayMode="relative"
          displayTodayLabel={false}
          showLegend={false}
          vestingsDataPoints={[
            {
              dataPoints: vestingDataPoints.map(
                ({ cumulativeVested, date }) => ({
                  cumulativeVested,
                  date: new Date(date),
                }),
              ),
              key: "vesting_schedule_overview",
              label: "Vesting schedule preview",
            },
          ]}
          vestingStartDate={vestingDataPoints[0].date.toISOString()}
        />
      </div>
    </PreviewBox>
  );
};
