import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { GraphQLTaggedNode, MutationParameters } from "relay-runtime";

import { useSafeMutation } from "./useSafeMutation";

export function useDebouncedSafeMutation<TMutation extends MutationParameters>(
  gqlQuery: GraphQLTaggedNode,
  delayInMs = 500,
) {
  const [safeMutation, mutationIsInFlight] =
    useSafeMutation<TMutation>(gqlQuery);

  const debouncedSafeMutation = useMemo(
    () => debounce(safeMutation, delayInMs),
    [safeMutation, delayInMs],
  );

  const flushDebouncedUpdates = useCallback(async () => {
    await debouncedSafeMutation.flush();
  }, [debouncedSafeMutation]);

  useEffect(() => {
    return () => {
      void flushDebouncedUpdates();
    };
  }, [flushDebouncedUpdates]);

  return [debouncedSafeMutation, mutationIsInFlight] as const;
}
