import { zodResolver } from "@hookform/resolvers/zod";
import { noop } from "lodash";
import React, { Suspense, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { makeRemoteController } from "../helpers/makeRemoteController";
import { useQuery } from "../hooks/useQuery";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { FairMarketValueSendToRewiewSlider_Organization$key } from "./__generated__/FairMarketValueSendToRewiewSlider_Organization.graphql";
import { FairMarketValueSendToRewiewSlider_Query } from "./__generated__/FairMarketValueSendToRewiewSlider_Query.graphql";
import { FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation } from "./__generated__/FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation.graphql";
import { Section } from "./ConfirmSubmitBoardConsentSlide/Section";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { CartaLogo } from "./ui/CartaLogo";
import { Divider } from "./ui/Divider";
import {
  FileInput,
  useFileInputFormUploadController,
} from "./ui/Form/FileInput";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { Input } from "./ui/Form/Inputs/Input";
import { NoticeMessage } from "./ui/NoticeMessage";
import { PulleyLogo } from "./ui/PulleyLogo";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueSendToRewiewSlider_Organization on Organization {
    id
    latestFairMarketValue @required(action: THROW) {
      id
      date
      valuationFirm
    }
  }
`;

const SEND_FAIR_MARKET_VALUE_FOR_REVIEW_MUTATION = graphql`
  mutation FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation(
    $fairMarketValueId: ID!
    $valuationFirm: String!
    $valuationReportDocumentId: String!
  ) {
    sendFairMarketValueForReview(
      fairMarketValueId: $fairMarketValueId
      valuationFirm: $valuationFirm
      valuationReportDocumentId: $valuationReportDocumentId
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const SCHEMA = z.object({
  valuationFirm: z
    .string({
      required_error: "Please enter here the name of the valuation firm",
    })
    .trim()
    .min(1, { message: "Please enter here the name of the valuation firm" }),
  valuationReportDocumentId: z.string({
    message: "You must import the 409A valuation report",
  }),
});

function ValuationFirmRadioButton({
  checked,
  children: Children,
  onChange,
}: {
  checked: boolean;
  children: React.ComponentType<{ className?: string }> | string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <label className="flex h-12 cursor-pointer items-center rounded-remote-sm border-[0.5px] border-grey-300 px-6 text-SM/Medium transition-all has-[:checked]:cursor-auto has-[:checked]:border-transparent has-[:checked]:shadow-100 has-[:checked]:ring-2 has-[:checked]:ring-primary">
      <input
        checked={checked}
        className="hidden"
        onChange={onChange}
        type="radio"
      />
      {typeof Children === "string" ? Children : <Children className="h-4" />}
    </label>
  );
}

const CARTA_VALUATION_FIRM_NAME = "Carta Valuations LLC";
const PULLEY_VALUATION_FIRM_NAME = "Prolific Labs Inc. dba Pulley";

const FairMarketValueSendToRewiewSliderContent_: React.FC<{
  onClose: () => void;
  onValuationSentForReview: () => void;
  organizationFragment: FairMarketValueSendToRewiewSlider_Organization$key;
}> = ({ onClose, onValuationSentForReview, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const fairMarketValue = organization.latestFairMarketValue;

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      valuationFirm: fairMarketValue.valuationFirm ?? undefined,
      valuationReportDocumentId: undefined,
    },
    resolver: zodResolver(SCHEMA),
  });

  const [valuationFirmOrigin, setValueFirmOrigin] = useState<
    "Carta" | "Custom" | "Pulley" | null
  >(() => {
    if (!fairMarketValue.valuationFirm) {
      return null;
    }
    switch (fairMarketValue.valuationFirm) {
      case CARTA_VALUATION_FIRM_NAME:
        return "Carta";
      case PULLEY_VALUATION_FIRM_NAME:
        return "Pulley";
      default:
        return "Custom";
    }
  });

  const valuationReportFileInputUploadController =
    useFileInputFormUploadController({
      accept: "application/pdf",
      acceptedExtensions: [".pdf"],
      control,
      multiple: false,
      name: "valuationReportDocumentId",
    });

  const [sendFairMarketValueForReview, mutationIsInFlight] =
    useSafeMutation<FairMarketValueSendToRewiewSlider_SendFairMarketValueForReview_Mutation>(
      SEND_FAIR_MARKET_VALUE_FOR_REVIEW_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (_formData) => {
    const formData = SCHEMA.parse(_formData);
    await sendFairMarketValueForReview({
      variables: {
        fairMarketValueId: fairMarketValue.id,
        valuationFirm: formData.valuationFirm,
        valuationReportDocumentId: formData.valuationReportDocumentId,
      },
    });
    toaster.push(
      <Toast title="Wonderful!">
        Your 409A was successfully sent for review!
      </Toast>,
    );

    onClose();
    onValuationSentForReview();
  });

  const handleValuationFirmRadioButtonChange = useCallback(
    (newValuationFirmOrigin: "Carta" | "Custom" | "Pulley") => () => {
      if (newValuationFirmOrigin === valuationFirmOrigin) return;

      setValueFirmOrigin(newValuationFirmOrigin);
      switch (newValuationFirmOrigin) {
        case "Carta":
          setValue("valuationFirm", CARTA_VALUATION_FIRM_NAME);
          break;
        case "Custom":
          setValue("valuationFirm", "");
          break;
        case "Pulley":
          setValue("valuationFirm", PULLEY_VALUATION_FIRM_NAME);
          break;
      }
    },
    [setValue, setValueFirmOrigin, valuationFirmOrigin],
  );

  return (
    <form className="space-y-10 p-6" onSubmit={onSubmit}>
      <Section
        subtitle="Please fill in the following inputs in order to validate your new 409A"
        title="New 409A valuation details"
      >
        <div className="space-y-4">
          <FormRow.Form
            control={control}
            label="Valuation Firm"
            name="valuationFirm"
          >
            <>
              <div className="flex items-center gap-4">
                <ValuationFirmRadioButton
                  checked={valuationFirmOrigin === "Carta"}
                  onChange={handleValuationFirmRadioButtonChange("Carta")}
                >
                  {CartaLogo}
                </ValuationFirmRadioButton>
                <ValuationFirmRadioButton
                  checked={valuationFirmOrigin === "Pulley"}
                  onChange={handleValuationFirmRadioButtonChange("Pulley")}
                >
                  {PulleyLogo}
                </ValuationFirmRadioButton>
                <ValuationFirmRadioButton
                  checked={valuationFirmOrigin === "Custom"}
                  onChange={handleValuationFirmRadioButtonChange("Custom")}
                >
                  Other valuation firm
                </ValuationFirmRadioButton>
              </div>
              {valuationFirmOrigin && (
                <>
                  <Input.Form
                    control={control}
                    disabled={valuationFirmOrigin !== "Custom"}
                    name="valuationFirm"
                    placeholder="Enter valuation firm name"
                    type="text"
                  />
                  {valuationFirmOrigin === "Custom" && (
                    <NoticeMessage size="Small" variant="Warning">
                      The name of the valuation firm can be found in your 409A
                      valuation. Please include the correct name as this will be
                      displayed in the board consent your board members will
                      sign.
                    </NoticeMessage>
                  )}
                </>
              )}
            </>
          </FormRow.Form>
          <FormRow
            label="409A Valuation Date"
            subLabel="The effective date of the new 409A valuation"
          >
            <DatePicker
              disabled
              onChange={noop}
              value={organization.latestFairMarketValue.date}
            />
          </FormRow>
          <FormRow.Form
            control={control}
            label="Upload your new 409A valuation"
            name="valuationReportDocumentId"
          >
            <FileInput
              {...valuationReportFileInputUploadController.inputProps}
            />
          </FormRow.Form>
        </div>
      </Section>

      <Divider />

      <div className="space-y-4">
        <div className="flex justify-end gap-4">
          <Button
            onClick={onClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            disabled={
              valuationReportFileInputUploadController.someItemsAreNotReady
            }
            loading={mutationIsInFlight}
            size="small"
            type="submit"
          >
            Send for review
          </Button>
        </div>
        <Typography
          as="div"
          className="text-right text-gray-09"
          variant="Regular/Caption"
        >
          All documents will be uploaded and stored in your organization’s
          documents folder
        </Typography>
      </div>
    </form>
  );
};

const QUERY = graphql`
  query FairMarketValueSendToRewiewSlider_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      ...FairMarketValueSendToRewiewSlider_Organization
    }
  }
`;

const FairMarketValueSendToRewiewSliderContent: React.FC<
  Omit<
    React.ComponentProps<typeof FairMarketValueSendToRewiewSliderContent_>,
    "organizationFragment"
  > & {
    organizationId: string;
  }
> = ({ organizationId, ...props }) => {
  const {
    query: { organization },
  } = useQuery<FairMarketValueSendToRewiewSlider_Query>(QUERY, {
    organizationId,
  });

  return (
    <FairMarketValueSendToRewiewSliderContent_
      organizationFragment={organization}
      {...props}
    />
  );
};

export const FairMarketValueSendToRewiewSliderRemote = makeRemoteController<{
  onValuationSentForReview: () => void;
  organizationId: string;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <SlideOver
        floating
        header={
          <SlideOver.Header onClose={close}>
            Validate new 409A valuation
          </SlideOver.Header>
        }
        onClose={close}
        show={state.show}
      >
        <Suspense fallback={<LoadingPlaceholder />}>
          <FairMarketValueSendToRewiewSliderContent
            onClose={close}
            onValuationSentForReview={state.data.onValuationSentForReview}
            organizationId={state.data.organizationId}
          />
        </Suspense>
      </SlideOver>
    );
  },
});
