import classNames from "classnames";

import { Typography } from "./ui/Typography";

export const StickyBanner: React.FC<
  React.PropsWithChildren<{
    className?: string;
  }>
> = ({ children, className }) => {
  return (
    <Typography
      as="div"
      className={classNames(
        "bg-glass-green-01 px-4 py-2 text-center text-glass-green-09",
        className,
      )}
      variant="Regular/Extra Small"
    >
      {children}
    </Typography>
  );
};
