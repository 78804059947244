/**
 * @generated SignedSource<<2f997b02b841598125873aef788ab0f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type CandidateSettings_EquityOffer$data = {
  readonly candidateName: string | null;
  readonly contractStartDate: string | null;
  readonly id: string;
  readonly organization: {
    readonly id: string;
    readonly name: string;
  };
  readonly position: string | null;
  readonly taxResidenceCountry: {
    readonly code: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"Candidate_EquityOffer" | "EquityOfferPreviewLayout_EquityOffer">;
  readonly " $fragmentType": "CandidateSettings_EquityOffer";
};
export type CandidateSettings_EquityOffer$key = {
  readonly " $data"?: CandidateSettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"CandidateSettings_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CandidateSettings_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "candidateName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Candidate_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "7c6d11ef6e2a6350b1d532c523c9194e";

export default node;
