import classNames from "classnames";
import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useMouse } from "react-use";
import useSpring from "react-use/lib/useSpring";

const useAnimatedRainbowBackgroundStyle = ({
  ref,
}: {
  ref: MutableRefObject<null>;
}) => {
  const mouse = useMouse(ref);
  const [angle, setAngle] = useState(45);
  const springedAngle = useSpring(angle, 40, 12);

  useEffect(() => {
    const calculatedAngle =
      Math.atan2(
        mouse.docY - window.innerHeight / 2,
        mouse.docX - window.innerWidth / 2,
      ) *
        (180 / Math.PI) +
      90;

    setAngle(calculatedAngle);
  }, [mouse.docX, mouse.docY]);

  return useMemo(() => {
    return {
      background: `linear-gradient(${springedAngle}deg, #FFD972, #F39D8D, #2A55E1)`,
    };
  }, [springedAngle]);
};

export const RainbowCard: React.FC<
  React.PropsWithChildren<{
    innerClassName?: string;
    outerClassName?: string;
    padded?: boolean;
  }>
> = ({ children, innerClassName, outerClassName, padded = true }) => {
  const ref = useRef(null);
  const style = useAnimatedRainbowBackgroundStyle({ ref });

  return (
    <div
      className={classNames(
        "rounded-lg p-[1px] transition-all high-pixel-density:p-[0.5px]",
        outerClassName,
      )}
      ref={ref}
      style={style}
    >
      <div
        className={classNames(
          "rounded-[7px] bg-white high-pixel-density:rounded-[7.5px]",
          {
            "overflow-hidden": !padded,
            "p-6": padded,
          },
          innerClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
