import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import { graphql } from "react-relay";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { CTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteCTMSGrantAmendmentRequests_Mutation } from "./__generated__/CTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteCTMSGrantAmendmentRequests_Mutation.graphql";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const DELETE_GRANT_AMENDMENT_REQUESTS_MUTATION = graphql`
  mutation CTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteCTMSGrantAmendmentRequests_Mutation(
    $ctmsGrantAmendmentRequestIds: [UUID!]!
  ) {
    deleteCTMSGrantAmendmentRequests(
      ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds
    ) {
      __typename
    }
  }
`;

type State = {
  ctmsGrantAmendmentRequestIds: string[];
  shown: boolean;
};

export const useCTMSGrantAmendmentRequestDeletionConfirmationModalState =
  () => {
    const [state, setState] = useState<State>({
      ctmsGrantAmendmentRequestIds: [],
      shown: false,
    });

    const showModal = useCallback((ctmsGrantAmendmentRequestIds: string[]) => {
      setState({
        ctmsGrantAmendmentRequestIds,
        shown: true,
      });
    }, []);

    const closeModal = useCallback(() => {
      setState((previousState) => ({
        ...previousState,
        shown: false,
      }));
    }, []);

    return {
      closeModal,
      showModal,
      state,
    };
  };

export const CTMSGrantAmendmentRequestDeletionConfirmationModal: React.FC<{
  onClose: () => void;
  onDeletionCompleted?: () => void;
  state: State;
}> = ({
  onClose,
  onDeletionCompleted,
  state: { ctmsGrantAmendmentRequestIds, shown },
}) => {
  const [deleteGrantAmendmentRequests, deleteGrantAmendmentRequestsIsInFlight] =
    useSafeMutation<CTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteCTMSGrantAmendmentRequests_Mutation>(
      DELETE_GRANT_AMENDMENT_REQUESTS_MUTATION,
    );
  const toaster = useToaster();
  const onConfirmed = async () => {
    await deleteGrantAmendmentRequests({
      variables: {
        ctmsGrantAmendmentRequestIds,
      },
    });
    toaster.push(
      <Toast title="Alright!">
        <FormattedMessage
          defaultMessage={`{grantCount, plural,
            one {# amendment request}
            other {# amendment requests}
          } successfully deleted!`}
          values={{
            grantCount: ctmsGrantAmendmentRequestIds.length,
          }}
        />
      </Toast>,
    );
    onClose();
    onDeletionCompleted?.();
  };

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      loading={deleteGrantAmendmentRequestsIsInFlight}
      onClose={onClose}
      onConfirmed={onConfirmed}
      show={shown}
      title={
        <FormattedMessage
          defaultMessage="Delete {selectedGrantCount, plural, one {amendment request} other {amendment requests}}"
          values={{
            selectedGrantCount: ctmsGrantAmendmentRequestIds.length,
          }}
        />
      }
    >
      <FormattedMessage
        defaultMessage="Are you sure you want to delete {selectedGrantCount, plural, one {this amendment request} other {these amendment requests}}?"
        values={{
          selectedGrantCount: ctmsGrantAmendmentRequestIds.length,
        }}
      />
    </ConfirmationModal>
  );
};
