/**
 * @generated SignedSource<<e97f452a327c873d2a445fa8556194c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$data = ReadonlyArray<{
  readonly displayedName: string;
  readonly document: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument">;
  readonly " $fragmentType": "DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument";
}>;
export type DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$key = ReadonlyArray<{
  readonly " $data"?: DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayedName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "document",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downloadUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument"
    }
  ],
  "type": "OrganizationMiscellaneousLegalDocument",
  "abstractKey": null
};

(node as any).hash = "c2b8001bff7016d56a93e580a9430820";

export default node;
