/**
 * @generated SignedSource<<1800aa48893dd1cf37c0cd6f48295e7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueBoardApprovalSlider_FairMarketValue$data = {
  readonly date: string;
  readonly id: string;
  readonly " $fragmentType": "FairMarketValueBoardApprovalSlider_FairMarketValue";
};
export type FairMarketValueBoardApprovalSlider_FairMarketValue$key = {
  readonly " $data"?: FairMarketValueBoardApprovalSlider_FairMarketValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueBoardApprovalSlider_FairMarketValue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueBoardApprovalSlider_FairMarketValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "e133b179bbee8b34e2bf714a51dcb12f";

export default node;
