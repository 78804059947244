/**
 * @generated SignedSource<<27794631864741120a676c865d0405cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Review_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantOwnershipRow_Organization" | "GrantPostTerminationRow_Organization">;
  readonly " $fragmentType": "Review_Organization";
};
export type Review_Organization$key = {
  readonly " $data"?: Review_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Review_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Review_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantOwnershipRow_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantPostTerminationRow_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "fb630661d436a807262211e7a57f8b64";

export default node;
