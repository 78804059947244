/**
 * @generated SignedSource<<5090def97c0ca172136731f382492cf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantFormSlide_Organization$data = {
  readonly allowAcceleration: boolean;
  readonly allowEarlyExercise: boolean;
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_Grantees">;
      };
    }>;
  };
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly poolAvailableShares: number;
  readonly poolDraftedSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GrantForm_Organization" | "useGrantForm_Organization">;
  readonly " $fragmentType": "GrantFormSlide_Organization";
};
export type GrantFormSlide_Organization$key = {
  readonly " $data"?: GrantFormSlide_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_Organization">;
};

import GrantFormSlide_Organization_RefetchQuery_graphql from './GrantFormSlide_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": GrantFormSlide_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "GrantFormSlide_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAcceleration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowEarlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "poolAvailableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolDraftedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GrantFormSlide_Grantees"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "GrantForm_Organization"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "useGrantForm_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "d419c49b271c37c24bf052664df5c2ab";

export default node;
