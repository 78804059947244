/**
 * @generated SignedSource<<760bd30331cb2462e417eda05dbd304f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardConsentsDocumentsTable_Organization$data = {
  readonly boardConsents: ReadonlyArray<{
    readonly __typename: string;
    readonly completedAt: string | null;
    readonly ctmsGrantAmendmentRequestsCount?: number;
    readonly fairMarketValue?: {
      readonly value: number;
    };
    readonly grantCount?: number;
    readonly granteeTerminationCTMSGrantAmendmentRequestCount?: number;
    readonly signedDocument: {
      readonly downloadUrl: string;
    } | null;
  }>;
  readonly " $fragmentType": "BoardConsentsDocumentsTable_Organization";
};
export type BoardConsentsDocumentsTable_Organization$key = {
  readonly " $data"?: BoardConsentsDocumentsTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardConsentsDocumentsTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardConsentsDocumentsTable_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grantCount",
              "storageKey": null
            }
          ],
          "type": "GrantBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "granteeTerminationCTMSGrantAmendmentRequestCount",
              "storageKey": null
            }
          ],
          "type": "GranteeTerminationGrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctmsGrantAmendmentRequestsCount",
              "storageKey": null
            }
          ],
          "type": "GrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FairMarketValue",
              "kind": "LinkedField",
              "name": "fairMarketValue",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "FairMarketValueBoardConsent",
          "abstractKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "signedDocument",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "downloadUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b6ffceb37c8b6f21d29df21ce0d9147b";

export default node;
