/**
 * @generated SignedSource<<3e15a4fb3bd8a6ea58914397cc259c5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettings_Query$variables = {
  organizationId: string;
};
export type OrganizationSettings_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettings_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettings_Organization">;
  } | null;
};
export type OrganizationSettings_Query = {
  response: OrganizationSettings_Query$data;
  variables: OrganizationSettings_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettings_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettings_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettings_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "companies",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completeName",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "stockOptionPlanName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGeography",
            "kind": "LinkedField",
            "name": "organizationGeographies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Geography",
                "kind": "LinkedField",
                "name": "geography",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "country",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "code",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTaxFavoredEquityTypeUnlocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nsoEquityTypeSubplanName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxFavoredEquityTypeSubplanName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminated",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c5a30179d0620631dfd7adaf42aae43",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettings_Query",
    "operationKind": "query",
    "text": "query OrganizationSettings_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...OrganizationSettings_Organization\n  }\n  me {\n    ...OrganizationSettings_Account\n    id\n  }\n}\n\nfragment DeleteOrganizationButton_Organization on Organization {\n  id\n  name\n}\n\nfragment MarkOrganizationAsTerminatedButton_Organization on Organization {\n  id\n  name\n  terminated\n}\n\nfragment OrganizationSettings_Account on Account {\n  isSuperAdmin\n}\n\nfragment OrganizationSettings_Organization on Organization {\n  id\n  name\n  companies {\n    id\n    completeName\n  }\n  stockOptionPlanName\n  organizationGeographies {\n    geography {\n      country {\n        code\n      }\n    }\n    isTaxFavoredEquityTypeUnlocked\n    nsoEquityTypeSubplanName\n    taxFavoredEquityTypeSubplanName\n    id\n  }\n  ...DeleteOrganizationButton_Organization\n  ...MarkOrganizationAsTerminatedButton_Organization\n}\n"
  }
};
})();

(node as any).hash = "e0c47f1c92965f7cf85d1bcb274d1365";

export default node;
