import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { differenceInDays, format } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../services/workRelationship";
import { OngoingObligationsTable_DeleteOngoingObligation_Mutation } from "./__generated__/OngoingObligationsTable_DeleteOngoingObligation_Mutation.graphql";
import {
  OngoingObligationsTable_OngoingObligation$data,
  OngoingObligationsTable_OngoingObligation$key,
} from "./__generated__/OngoingObligationsTable_OngoingObligation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Table } from "./ui/Table";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ONGOING_OBLIGATIONS_FRAGMENT = graphql`
  fragment OngoingObligationsTable_OngoingObligation on OngoingObligation
  @relay(plural: true) {
    id
    label
    date
    equityTypeWorkRelationshipCategories
    equityType {
      name
      taxResidenceCountry {
        emoji
        name
      }
    }
    affectedOrganizations {
      id
      name
    }
  }
`;

const MUTATION = graphql`
  mutation OngoingObligationsTable_DeleteOngoingObligation_Mutation(
    $ongoingObligationId: UUID!
  ) {
    deleteOngoingObligation(ongoingObligationId: $ongoingObligationId)
  }
`;

type OngoingObligation = OngoingObligationsTable_OngoingObligation$data[number];

const columnHelper = createColumnHelper<OngoingObligation>();

export const OngoingObligationsTable: React.FC<{
  ongoingObligationsFragment: OngoingObligationsTable_OngoingObligation$key;
  onOngoingObligationDeleted: () => void;
}> = ({ ongoingObligationsFragment, onOngoingObligationDeleted }) => {
  const ongoingObligations = useFragment(
    ONGOING_OBLIGATIONS_FRAGMENT,
    ongoingObligationsFragment,
  );

  const toaster = useToaster();

  const [_deleteOngoingObligation, deletionIsInFlight] =
    useSafeMutation<OngoingObligationsTable_DeleteOngoingObligation_Mutation>(
      MUTATION,
    );

  const [selectedOngoingObligation, setSelectedOngoingObligation] =
    useState<null | OngoingObligation>(null);

  const deleteOngoingObligation = useCallback(
    async (ongoingObligationId: string) => {
      await _deleteOngoingObligation({
        variables: {
          ongoingObligationId,
        },
      });
      toaster.push(
        <Toast title="Nice!">Ongoing obligation successfully created!</Toast>,
      );
      onOngoingObligationDeleted();
      setSelectedOngoingObligation(null);
    },
    [toaster, _deleteOngoingObligation, onOngoingObligationDeleted],
  );

  const getWorkRelationship = (
    workRelationShip: OngoingObligation["equityTypeWorkRelationshipCategories"][number],
  ) => {
    switch (workRelationShip) {
      case "Contractor":
        return EQUITY_TYPE_WORK_RELATIONSHIP_MAP["contractor"];
      case "DirectEmployee":
        return EQUITY_TYPE_WORK_RELATIONSHIP_MAP["directEmployee"];
      case "EoREmployee":
        return EQUITY_TYPE_WORK_RELATIONSHIP_MAP["eoREmployee"];
    }
  };

  const isOngoingObligationDateInLessThan6Weeks = useCallback(
    (ongoingObligation: OngoingObligation) =>
      differenceInDays(new Date(ongoingObligation.date), new Date()) < 6 * 7,
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) =>
          `${row.equityType.taxResidenceCountry.emoji} ${row.equityType.taxResidenceCountry.name} - ${row.equityType.name}`,
        {
          cell: (context) => {
            const equityTypeName = context.getValue();
            return (
              <Typography variant="Medium/Extra Small">
                {" "}
                {equityTypeName}
              </Typography>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Instrument",
          id: "equityType",
        },
      ),
      columnHelper.accessor((row) => row.label, {
        cell: (context) => {
          const label = context.getValue();
          return label;
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Label",
        id: "label",
      }),
      columnHelper.accessor((row) => new Date(row.date).getTime(), {
        cell: (context) => {
          const ongoingObligation = context.row.original;
          return (
            <span
              className={classNames({
                "font-medium":
                  isOngoingObligationDateInLessThan6Weeks(ongoingObligation),
              })}
            >
              {format(new Date(ongoingObligation.date), "dd-MMM")}
            </span>
          );
        },
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Due date",
        id: "date",
      }),
      columnHelper.accessor(
        (row) =>
          row.equityTypeWorkRelationshipCategories
            .map(
              (workRelationship) => getWorkRelationship(workRelationship).label,
            )
            .join(" - "),
        {
          cell: (context) => {
            const workRelationships = context.getValue();
            return workRelationships;
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Work relationships",
          id: "workRelationship",
        },
      ),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const ongoingObligation = context.row.original;

          if (isEmpty(ongoingObligation.affectedOrganizations)) {
            return "-";
          }

          return (
            <Disclosure key={ongoingObligation.id}>
              {({ open }) => (
                <div>
                  <Disclosure.Button className="flex w-full items-center gap-2">
                    See{" "}
                    <FormattedMessage
                      defaultMessage="{count, plural, one {# organization} other {# organizations}}"
                      values={{
                        count: ongoingObligation.affectedOrganizations.length,
                      }}
                    />{" "}
                    <ChevronDownIcon
                      className={classNames(
                        {
                          "rotate-180": open,
                        },
                        "h-4",
                      )}
                    />
                  </Disclosure.Button>
                  <Transition
                    enter="transition duration-100 ease-out"
                    enterFrom="transform scale-95 opacity-0"
                    enterTo="transform scale-100 opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform scale-100 opacity-100"
                    leaveTo="transform scale-95 opacity-0"
                  >
                    <Disclosure.Panel>
                      <div>
                        {ongoingObligation.affectedOrganizations.map(
                          (organization) => (
                            <Typography
                              as="div"
                              className="text-primary"
                              key={organization.id}
                              variant="Medium/Extra Small"
                            >
                              <Link
                                key={organization.id}
                                target="_blank"
                                to={generatePath(
                                  APPLICATION_ROUTES["organizationHome"],
                                  {
                                    organizationId: organization.id,
                                  },
                                )}
                              >
                                {organization.name}
                              </Link>
                            </Typography>
                          ),
                        )}
                      </div>
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Affected organizations",
        id: "organizations",
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const ongoingObligation = context.row.original;
          return (
            <Button
              leftIcon={<TrashIcon />}
              onClick={() => setSelectedOngoingObligation(ongoingObligation)}
              size="extra small"
              type="button"
              variant="Danger Full"
            />
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "",
        id: "actions",
      }),
    ],
    [setSelectedOngoingObligation, isOngoingObligationDateInLessThan6Weeks],
  );

  const data = useMemo(() => [...ongoingObligations], [ongoingObligations]);

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    initialState: {
      sorting: [{ desc: false, id: "date" }],
    },
    onGlobalFilterChange,
    state: {
      globalFilter: searchParams.get("search"),
    },

    // Hack to set width auto when size is not set
    defaultColumn: { size: 0 },
  });

  return (
    <>
      <ConfirmationModal
        confirmationLabel="Delete"
        loading={deletionIsInFlight}
        onClose={() => setSelectedOngoingObligation(null)}
        onConfirmed={() =>
          selectedOngoingObligation
            ? deleteOngoingObligation(selectedOngoingObligation.id)
            : null
        }
        show={!!selectedOngoingObligation}
        title="Delete organization"
      >
        Are you sure you want to delete{" "}
        <strong>{selectedOngoingObligation?.label}</strong>?
      </ConfirmationModal>
      <Table.Smart
        rowRenderer={({ cells, rowData }) => (
          <Table.Row
            className={classNames({
              ["text-orange"]: isOngoingObligationDateInLessThan6Weeks(
                rowData.original,
              ),
            })}
          >
            {cells}
          </Table.Row>
        )}
        searchBarPlaceholder="Search..."
        showSearchBar
        table={table}
      />
    </>
  );
};
