import { BellAlertIcon } from "@heroicons/react/24/outline";
import { Pill } from "@remote-com/norma";
import { addWeeks, format } from "date-fns";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { CreateOngoingObligationSlideOver } from "../../../../components/CreateOngoingObligationSlideOver";
import { OngoingObligationsTable } from "../../../../components/OngoingObligationsTable";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import CONFIGURATION from "../../../../services/configuration";
import { OngoingObligationsPage_EquityType$key } from "./__generated__/OngoingObligationsPage_EquityType.graphql";
import { OngoingObligationsPage_OngoingObligation$key } from "./__generated__/OngoingObligationsPage_OngoingObligation.graphql";
import { OngoingObligationsPage_Query } from "./__generated__/OngoingObligationsPage_Query.graphql";
import { OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation } from "./__generated__/OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation.graphql";

const ONGOING_OBLIGATION_FRAGMENT = graphql`
  fragment OngoingObligationsPage_OngoingObligation on OngoingObligation
  @relay(plural: true) {
    ...OngoingObligationsTable_OngoingObligation
  }
`;

const EQUITY_TYPES_FRAGMENT = graphql`
  fragment OngoingObligationsPage_EquityType on EquityType
  @relay(plural: true) {
    ...CreateOngoingObligationSlideOver_EquityType
  }
`;

const MUTATION = graphql`
  mutation OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation {
    triggerOngoingObligationsNotifications
  }
`;

const OngoingObligationsPage_: React.FC<{
  equityTypesFragment: OngoingObligationsPage_EquityType$key;
  ongoingObligationsFragment: OngoingObligationsPage_OngoingObligation$key;
  onRefreshData: () => void;
}> = ({ equityTypesFragment, ongoingObligationsFragment, onRefreshData }) => {
  const ongoingObligations = useFragment(
    ONGOING_OBLIGATION_FRAGMENT,
    ongoingObligationsFragment,
  );
  const equityTypes = useFragment(EQUITY_TYPES_FRAGMENT, equityTypesFragment);

  const {
    setFalse: closeSlider,
    setTrue: showSlider,
    value: sliderIsShown,
  } = useBoolean(false);

  const [triggerOngoingObligationsNotifications, mutationIsInFlight] =
    useSafeMutation<OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation>(
      MUTATION,
    );
  return (
    <>
      <CreateOngoingObligationSlideOver
        equityTypesFragment={equityTypes}
        onClose={closeSlider}
        onOngoingObligationCreated={onRefreshData}
        show={sliderIsShown}
      />
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link to="..">Legal content</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Ongoing obligations</BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="flex-grow space-y-2">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">
                  Ongoing Obligations
                </Typography>
                <Pill>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# ongoing obligation} other {# ongoing obligations}}"
                    values={{
                      count: ongoingObligations.length,
                    }}
                  />
                </Pill>
                <div className="flex flex-1 justify-end gap-2">
                  {CONFIGURATION.ENVIRONMENT !== "production" && (
                    <TooltipContainer
                      tooltipContent={
                        <>
                          This will trigger a notification for ongoing
                          obligations due in exactly 6 weeks (
                          {format(addWeeks(new Date(), 6), "dd-MMM")}).
                          <br />
                          Emails will only be sent once per admin even if you
                          trigger notifications multiple times.
                        </>
                      }
                    >
                      <Button
                        leftIcon={<BellAlertIcon />}
                        loading={mutationIsInFlight}
                        onClick={() =>
                          triggerOngoingObligationsNotifications({
                            variables: {},
                          })
                        }
                        size="small"
                        type="button"
                        variant="Danger Outline"
                      >
                        Trigger notifications
                      </Button>
                    </TooltipContainer>
                  )}
                  <Button onClick={showSlider} size="small" type="button">
                    Create new ongoing obligation
                  </Button>
                </div>
              </div>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Create a new ongoing obligation for a specific instrument
              </Typography>
            </div>
          </div>
          <OngoingObligationsTable
            ongoingObligationsFragment={ongoingObligations}
            onOngoingObligationDeleted={onRefreshData}
          />
        </RoundedBox>
      </OneColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query OngoingObligationsPage_Query {
    ongoingObligations {
      ...OngoingObligationsPage_OngoingObligation
    }
    equityTypes {
      ...OngoingObligationsPage_EquityType
    }
  }
`;

const OngoingObligationsPage: React.FC = () => {
  const {
    query: { equityTypes, ongoingObligations },
    refreshQueryWithoutSuspense,
  } = useQuery<OngoingObligationsPage_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Ongoing Obligations"
      title={`Super admin | ongoing obligations`}
    >
      <OngoingObligationsPage_
        equityTypesFragment={equityTypes}
        ongoingObligationsFragment={ongoingObligations}
        onRefreshData={refreshQueryWithoutSuspense}
      />
    </Page>
  );
};

export default OngoingObligationsPage;
