import { useApplicationSupportEmailAddress } from "../../hooks/useApplicationTheme";
import { LinkButton } from "./Button";
import MessageQuestionIcon from "./MessageQuestionIcon";
import { Typography } from "./Typography";

export function HelpCard({
  buttonLabel = "Talk to our experts",
  label = "Need an advice or help from our team?",
  mailtoSubject = "Talk to our experts",
}: {
  buttonLabel?: React.ReactNode;
  label?: React.ReactNode;
  mailtoSubject?: string;
}) {
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <div className="flex items-center gap-4 rounded-2xl bg-gray-02 p-6">
      <MessageQuestionIcon className="w-8 text-primary" />
      <Typography as="div" className="flex-1" variant="Medium/Extra Small">
        {label}
      </Typography>
      <LinkButton
        size="small"
        to={`mailto:${supportEmailAddress}?subject=${encodeURIComponent(mailtoSubject)}`}
        variant="Secondary Outline"
      >
        {buttonLabel}
      </LinkButton>
    </div>
  );
}
