import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { generatePath, Link, useRouteError } from "react-router-dom";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../paths";
import ErrorPage from "./Error/Error";

const CriticalErrorPage = () => {
  const error = useRouteError();
  const applicationName = useApplicationName();

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <ErrorPage
      action={
        <Link
          className="text-primary"
          to={generatePath(APPLICATION_ROUTES["roleSelection"], {})}
        >
          Back to {applicationName}
        </Link>
      }
      subtitle="Something went wrong, but our team has been advised"
      title="Whoops!"
    />
  );
};

export default CriticalErrorPage;
