/**
 * @generated SignedSource<<749b144e32dc40a1492321c74293c53f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdminAttributes = {
  accountId: string;
  organizationId: string;
};
export type InviteAdminSlideOver_Admin_Query$variables = {
  attributes: AdminAttributes;
};
export type InviteAdminSlideOver_Admin_Query$data = {
  readonly isAccountAnOrganizationAdmin: boolean;
};
export type InviteAdminSlideOver_Admin_Query = {
  response: InviteAdminSlideOver_Admin_Query$data;
  variables: InviteAdminSlideOver_Admin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      }
    ],
    "kind": "ScalarField",
    "name": "isAccountAnOrganizationAdmin",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAdminSlideOver_Admin_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAdminSlideOver_Admin_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "aa7312878027922d392bd29b5e0dd363",
    "id": null,
    "metadata": {},
    "name": "InviteAdminSlideOver_Admin_Query",
    "operationKind": "query",
    "text": "query InviteAdminSlideOver_Admin_Query(\n  $attributes: AdminAttributes!\n) {\n  isAccountAnOrganizationAdmin(attributes: $attributes)\n}\n"
  }
};
})();

(node as any).hash = "098b2317898a2a3cd7006e3c8a548983";

export default node;
