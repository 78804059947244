import { graphql } from "relay-runtime";

import { shared_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationCTMSGrantAmendmentRequest$data } from "./__generated__/shared_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationCTMSGrantAmendmentRequest.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment shared_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest {
    # eslint-disable-next-line relay/unused-fields
    status
  }
`;

export const GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP: Record<
  shared_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationCTMSGrantAmendmentRequest$data["status"],
  string
> = {
  IMPLEMENTATION: "Implementation",
  IMPLEMENTED: "Implemented",
  PENDING_APPROVAL: "Pending approval",
  READY_FOR_CONSENT: "Ready for consent",
  UNDER_REVIEW: "Under review",
};
