/**
 * @generated SignedSource<<2316e848bbeaf5239233eaa5dfcd0644>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalTaxationContent_EquityType$data = {
  readonly contractorTaxationGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly directEmployeeTaxationGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly eoREmployeeTaxationGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly taxResidenceCountry: {
    readonly name: string;
  };
  readonly " $fragmentType": "GranteePortalTaxationContent_EquityType";
};
export type GranteePortalTaxationContent_EquityType$key = {
  readonly " $data"?: GranteePortalTaxationContent_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalTaxationContent_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AdminPortalContent_AdminPortalContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalTaxationContent_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "eoREmployeeTaxationGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "directEmployeeTaxationGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "contractorTaxationGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "ffbc65bcef578ce600f5dc05c049ea31";

export default node;
