/**
 * @generated SignedSource<<a8918fd548468cb4fea17b497eaf0d84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModifyCTMSGrantPageContent_Organization$data = {
  readonly allowAcceleration: boolean;
  readonly allowEarlyExercise: boolean;
  readonly id: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly displayName: string;
    readonly id: string;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly displayName: string;
    readonly id: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly vestingSchedules: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"LargeCenteredDetailsLayout_Organization" | "MissingInformationTag_MissingCTMSInformationTag_Organization" | "NewEarlyExerciseAllowedSlider_Organization" | "NewPTEPSlider_Organization" | "NewVestingScheduleSlider_Organization">;
  readonly " $fragmentType": "ModifyCTMSGrantPageContent_Organization";
};
export type ModifyCTMSGrantPageContent_Organization$key = {
  readonly " $data"?: ModifyCTMSGrantPageContent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModifyCTMSGrantPageContent_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModifyCTMSGrantPageContent_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAcceleration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowEarlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "OrganizationFixedPostTerminationExercisePeriod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "OrganizationVariablePostTerminationExercisePeriod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewPTEPSlider_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewEarlyExerciseAllowedSlider_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewVestingScheduleSlider_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0eda948594a6f7ef73dc763532c69750";

export default node;
