/**
 * @generated SignedSource<<3b607120297e7f463c919ef97901b67f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectToDeelButton_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "ConnectToDeelButton_Organization";
};
export type ConnectToDeelButton_Organization$key = {
  readonly " $data"?: ConnectToDeelButton_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectToDeelButton_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectToDeelButton_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c83f0d342f588926ad7765ad74982cef";

export default node;
