import { Popover } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Fuse from "fuse.js";
import { isEmpty, sortBy } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../../../components/Page";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { Checkbox } from "../../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SearchBar } from "../../../../components/ui/SearchBar";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { CheckboxFormRow } from "../../EquityOffer/CheckboxFormRow";
import { InsightPage } from "../InsightPage";
import {
  EquityGridLevels_Organization$data,
  EquityGridLevels_Organization$key,
} from "./__generated__/EquityGridLevels_Organization.graphql";
import { EquityGridLevels_Query } from "./__generated__/EquityGridLevels_Query.graphql";
import { EquityGridLevelsGraph } from "./EquityGridLevelsGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityGridLevels_Organization on Organization {
    id
    name
    equityGrid {
      levels {
        name
        id
        ...EquityGridLevelsGraph_EquityGridLevel
      }
    }
    ...EquityGridLevelsGraph_Organization
  }
`;

type EquityGridLevel =
  EquityGridLevels_Organization$data["equityGrid"]["levels"][number];

const PopoverContent: React.FC<{
  levels: EquityGridLevel[];
  onClose: () => void;
  onSelectedLevelsIdsChange: (selectedLevelsIds: string[]) => void;
  selectedLevelsIds: string[];
}> = ({ levels, onClose, onSelectedLevelsIdsChange, selectedLevelsIds }) => {
  const [fullTextFilter, setFullTextFilter] = useState<string>("");

  const filteredLevels = useMemo(() => {
    const fuse = new Fuse(levels, {
      keys: ["name"],
    });

    if (isEmpty(fullTextFilter)) {
      return levels;
    }

    return fuse.search(fullTextFilter).map((result) => result.item);
  }, [levels, fullTextFilter]);

  const [internalSelectedLevelsIds, setInternalSelectedLevelsIds] =
    useState<string[]>(selectedLevelsIds);

  return (
    <>
      <div className="space-y-4 p-4">
        <SearchBar
          onChange={setFullTextFilter}
          placeholder="Search level..."
          value={fullTextFilter}
        />
        {filteredLevels.map((level) => (
          <CheckboxFormRow
            checkbox={
              <Checkbox
                checked={internalSelectedLevelsIds.includes(level.id)}
                onChange={(e) => {
                  const checked = e.currentTarget.checked;
                  if (checked) {
                    setInternalSelectedLevelsIds([
                      ...new Set([level.id, ...internalSelectedLevelsIds]),
                    ]);
                  } else {
                    setInternalSelectedLevelsIds(
                      internalSelectedLevelsIds.filter((id) => id !== level.id),
                    );
                  }
                }}
              />
            }
            key={level.id}
            label={level.name}
          />
        ))}
      </div>
      <Divider />
      <div className="flex justify-between p-4">
        <Button
          onClick={() => {
            onSelectedLevelsIdsChange([]);
            onClose();
          }}
          size="small"
          type="button"
          variant="Secondary Full"
        >
          Clear all
        </Button>
        <Button
          onClick={() => {
            onSelectedLevelsIdsChange(internalSelectedLevelsIds);
            onClose();
          }}
          size="small"
          type="button"
          variant="Primary Full"
        >
          Apply
        </Button>
      </div>
    </>
  );
};

const FilterButton: React.FC<{
  id?: string;
  levels: EquityGridLevel[];
  onSelectedLevelsIdsChange: (selectedLevelsIds: string[]) => void;
  selectedLevelsIds: string[];
}> = ({ id, levels, onSelectedLevelsIdsChange, selectedLevelsIds }) => {
  return (
    <div className="relative">
      <Popover>
        {/* eslint-disable-next-line @typescript-eslint/unbound-method */}
        {({ close }) => (
          <>
            <Popover.Button
              as={Button}
              id={id}
              rightIcon={<ChevronDownIcon />}
              size="small"
              variant="Secondary Full"
            >
              Filter level
            </Popover.Button>
            <Popover.Panel className="absolute left-0 top-full z-10 mt-0.5 w-full max-w-[350px] space-y-0 rounded border border-gray-03 bg-white-01 shadow-100">
              <PopoverContent
                levels={levels}
                onClose={close}
                onSelectedLevelsIdsChange={onSelectedLevelsIdsChange}
                selectedLevelsIds={selectedLevelsIds}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

const equityGridLevelsIdsSchema = z.array(z.string());

const AdminEquityGridLevelsPage_: React.FC<{
  organizationFragment: EquityGridLevels_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const levels = useMemo(
    () => sortBy(organization.equityGrid?.levels ?? [], ({ name }) => name),
    [organization.equityGrid],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const setEquityGridLevelsIds = useCallback(
    (equityGridLevelsIds: string[]) =>
      setSearchParams(
        {
          equityGridLevelsIds: JSON.stringify(equityGridLevelsIds),
        },
        { replace: true },
      ),
    [setSearchParams],
  );

  const equityGridLevelsIds = useMemo(() => {
    try {
      const equityGridLevelsIdsParams = searchParams.get("equityGridLevelsIds");
      if (!equityGridLevelsIdsParams) {
        return [];
      }
      return equityGridLevelsIdsSchema.parse(
        JSON.parse(equityGridLevelsIdsParams),
      );
    } catch {
      return [];
    }
  }, [searchParams]);

  const filteredEquityGridLevels = useMemo(
    () =>
      isEmpty(equityGridLevelsIds)
        ? []
        : levels.filter((level) => equityGridLevelsIds.includes(level.id)),
    [equityGridLevelsIds, levels],
  );

  return (
    <InsightPage
      aboveChildren={
        <FormRow
          label="Filter by grid level"
          subLabel="Select one or more level to filter the view"
        >
          <FilterButton
            levels={levels}
            onSelectedLevelsIdsChange={setEquityGridLevelsIds}
            selectedLevelsIds={equityGridLevelsIds}
          />
        </FormRow>
      }
      subtitle="Split of grantees by equity grid level status"
      title="🏷️ Equity grid levels"
    >
      <EquityGridLevelsGraph
        filteredEquityGridLevelsFragment={filteredEquityGridLevels}
        organizationFragment={organization}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query EquityGridLevels_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...EquityGridLevels_Organization
    }
  }
`;

const AdminEquityGridLevelsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { organization } = useLazyLoadQuery<EquityGridLevels_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Equity grid levels"
      analyticsName="Admin - Insights - Equity grid levels"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | equity grid levels`}
    >
      <AdminEquityGridLevelsPage_ organizationFragment={organization} />
    </Page>
  );
};

export default AdminEquityGridLevelsPage;
