/**
 * @generated SignedSource<<1b51005a9de4ea1a73af8de38c016181>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettings_Organization$data = {
  readonly granteePortalInvitationIsAllowed: boolean;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization" | "GranteePortalSettingsProjectionSection_Organization" | "GranteePortalSettingsUsageSection_Organization">;
  readonly " $fragmentType": "GranteePortalSettings_Organization";
};
export type GranteePortalSettings_Organization$key = {
  readonly " $data"?: GranteePortalSettings_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettings_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalSettings_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteePortalInvitationIsAllowed",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalSettingsProjectionSection_Organization"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GranteePortalSettingsUsageSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalSettingsDisplayFullyDilutedValuesSection_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3a4b8be71e2b74e83154462085955d73";

export default node;
