/**
 * @generated SignedSource<<67ebcbf7607c2868fcd5597b1f51272f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation$variables = {
  granteeTerminationCTMSGrantAmendmentRequestIds: ReadonlyArray<string>;
};
export type GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation$data = {
  readonly deleteGranteeTerminationCTMSGrantAmendmentRequests: {
    readonly __typename: "Organization";
  };
};
export type GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation = {
  response: GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation$data;
  variables: GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds",
    "variableName": "granteeTerminationCTMSGrantAmendmentRequestIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteGranteeTerminationCTMSGrantAmendmentRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteGranteeTerminationCTMSGrantAmendmentRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef3f7317a97d1f9de9ceeed5fca4f801",
    "id": null,
    "metadata": {},
    "name": "GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteeTerminationCTMSGrantAmendmentRequestDeletionConfirmationModal_DeleteGrantAmendmentRequests_Mutation(\n  $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!\n) {\n  deleteGranteeTerminationCTMSGrantAmendmentRequests(granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "09329e81cd29c1f2ae41f0875f9f87a4";

export default node;
