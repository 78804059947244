/**
 * @generated SignedSource<<67fd917e521a79173f1b7b96e7bab2ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EquityOfferContext_EquityOffer$data = {
  readonly benefits: ReadonlyArray<string>;
  readonly candidateName: string | null;
  readonly contractStartDate: string | null;
  readonly displayBenefits: boolean;
  readonly displayCompanyInformation: boolean;
  readonly displayEquityAsDollarValue: boolean;
  readonly displayEquityAsPercentage: boolean;
  readonly displayFAQ: boolean;
  readonly displayProjectionScenarios: boolean;
  readonly displaySalary: boolean;
  readonly displayTaxationExplanation: boolean;
  readonly exercisePrice: number | null;
  readonly finalThoughts: string | null;
  readonly id: string;
  readonly instrument: {
    readonly id: string;
  } | null;
  readonly organization: {
    readonly id: string;
  };
  readonly position: string | null;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly salary: number | null;
  readonly salaryCurrencyCode: string;
  readonly shares: number | null;
  readonly taxResidenceCountry: {
    readonly code: string;
  } | null;
  readonly vestingSchedule: {
    readonly id: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"CandidateSettings_EquityOffer" | "CompanySettings_EquityOffer" | "EquiuniversitySettings_EquityOffer" | "FinalThoughtsSettings_EquityOffer" | "IncentiveSettings_EquityOffer" | "ProjectionSettings_EquityOffer" | "VestingSettings_EquityOffer" | "useEquityOfferTrackEvent_EquityOffer">;
  readonly " $fragmentType": "EquityOfferContext_EquityOffer";
};
export type EquityOfferContext_EquityOffer$key = {
  readonly " $data"?: EquityOfferContext_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferContext_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferContext_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "candidateName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayBenefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCompanyInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsDollarValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayFAQ",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayProjectionScenarios",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displaySalary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayTaxationExplanation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalThoughts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salaryCurrencyCode",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CompanySettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CandidateSettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingSettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquiuniversitySettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IncentiveSettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProjectionSettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalThoughtsSettings_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEquityOfferTrackEvent_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "d59037d05b3fd71614deb2249b6b1160";

export default node;
