import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseOverview_CTMSGrant$key } from "./__generated__/ExerciseOverview_CTMSGrant.graphql";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { SingleValueProgress } from "./ui/SingleValueProgress";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseOverview_CTMSGrant on CTMSGrant {
    quantityExercised
    exercisableShares
    earlyExercise
    settled
  }
`;
export const ExerciseOverview: React.FC<{
  ctmsGrantFragment: ExerciseOverview_CTMSGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <>
      <div className="flex flex-col items-end">
        <Typography as="div" variant="Medium/Caption">
          <FormattedPercentage
            value={
              ctmsGrant.exercisableShares > 0
                ? ctmsGrant.quantityExercised / ctmsGrant.exercisableShares
                : 0
            }
          />
        </Typography>

        <Typography variant="Regular/Caption">
          {ctmsGrant.earlyExercise ? (
            <FormattedMessage
              defaultMessage={`{quantityExercised, number} of the {exercisableShares, number} exercisable shares`}
              values={{
                exercisableShares: ctmsGrant.exercisableShares,
                quantityExercised: ctmsGrant.quantityExercised,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage={`{quantityExercised, number} of the {exercisableShares, number} vested shares`}
              values={{
                exercisableShares: ctmsGrant.exercisableShares,
                quantityExercised: ctmsGrant.quantityExercised,
              }}
            />
          )}
        </Typography>
      </div>

      <SingleValueProgress
        className="relative mt-2 h-2 w-[280px]"
        current={ctmsGrant.quantityExercised}
        max={ctmsGrant.exercisableShares || 100}
        variant={ctmsGrant.settled ? "light-red" : "primary"}
      />
    </>
  );
};
