import React, { useMemo } from "react";

import { AnchorButton } from "../../../components/ui/Button";

export const ShareTaxInformation: React.FC<{
  children?: React.ReactNode;
  exerciseRequestCTMSId: string;
}> = ({ children = <>Share information</>, exerciseRequestCTMSId }) => {
  const exerciseRequestPDFPath = useMemo(() => {
    return `/api/exercise-requests/${exerciseRequestCTMSId}/tax-information.pdf`;
  }, [exerciseRequestCTMSId]);

  return (
    <AnchorButton
      href={exerciseRequestPDFPath}
      size="small"
      variant="Primary Full"
    >
      {children}
    </AnchorButton>
  );
};
