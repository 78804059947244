import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useToaster } from "../../../../components/Toaster";
import { Toast } from "../../../../components/ui/Toast";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { EquityTypeWorkRelationshipData } from "../../../../services/workRelationship";
import { EquityTypeWorkRelationshipDetailed_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipDetailed_EquityType.graphql";
import {
  EquityTypeAdminPortalContentSlot,
  EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation,
} from "./__generated__/EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation.graphql";
import { EquityTypeWorkRelationshipDetailed_Viewer$key } from "./__generated__/EquityTypeWorkRelationshipDetailed_Viewer.graphql";
import AdminPortalContent from "./AdminPortalContent";

const EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDetailed_EquityType on EquityType {
    id
    taxResidenceCountry {
      name
    }
    eoREmployeeDetailedAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    directEmployeeDetailedAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    contractorDetailedAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDetailed_Viewer on Account {
    ...AdminPortalContent_Viewer
  }
`;

const SAVE_ADMIN_PORTAL_CONTENT_MUTATION = graphql`
  mutation EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation(
    $equityTypeId: String!
    $equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot!
    $content: String!
  ) {
    saveEquityTypeAdminPortalContent(
      equityTypeId: $equityTypeId
      equityTypeAdminPortalContentSlot: $equityTypeAdminPortalContentSlot
      content: $content
    ) {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const EquityTypeWorkRelationshipDetailed: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipDetailed_EquityType$key;
  viewerFragment: EquityTypeWorkRelationshipDetailed_Viewer$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({ equityTypeFragment, viewerFragment, workRelationshipData }) => {
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const viewer = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT,
    viewerFragment,
  );

  const [saveAdminPortalContent, saveAdminPortalContentIsInFlight] =
    useSafeMutation<EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation>(
      SAVE_ADMIN_PORTAL_CONTENT_MUTATION,
    );

  const toaster = useToaster();

  const { adminPortalContent, equityTypeAdminPortalContentSlot } =
    useMemo(() => {
      switch (workRelationshipData.slug) {
        case "contractor":
          return {
            adminPortalContent: equityType.contractorDetailedAdminPortalContent,
            equityTypeAdminPortalContentSlot:
              "contractorDetailedAdminPortalContentId" as EquityTypeAdminPortalContentSlot,
          };
        case "directEmployee":
          return {
            adminPortalContent:
              equityType.directEmployeeDetailedAdminPortalContent,
            equityTypeAdminPortalContentSlot:
              "directEmployeeDetailedAdminPortalContentId" as EquityTypeAdminPortalContentSlot,
          };
        case "eoREmployee":
          return {
            adminPortalContent:
              equityType.eoREmployeeDetailedAdminPortalContent,
            equityTypeAdminPortalContentSlot:
              "eoREmployeeDetailedAdminPortalContentId" as EquityTypeAdminPortalContentSlot,
          };
        default:
          throw new Error("Unhandled work relationship");
      }
    }, [workRelationshipData, equityType]);

  return (
    <AdminPortalContent
      adminPortalContentFragment={adminPortalContent}
      countryName={equityType.taxResidenceCountry.name}
      isLoading={saveAdminPortalContentIsInFlight}
      onSubmit={async (content: string) => {
        await saveAdminPortalContent({
          variables: {
            content,
            equityTypeAdminPortalContentSlot,
            equityTypeId: equityType.id,
          },
        });

        toaster.push(
          <Toast title="Wonderful!">The documentation has been saved!</Toast>,
        );
      }}
      viewerFragment={viewer}
    />
  );
};

export default EquityTypeWorkRelationshipDetailed;
