/**
 * @generated SignedSource<<0d81b4143c621ff342318a473e7506d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ExerciseStatus = "FullyExercised" | "NotExercised" | "PartiallyExercised";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantExerciseTag_CtmsGrant$data = {
  readonly exerciseStatus: ExerciseStatus;
  readonly settled: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant">;
  readonly " $fragmentType": "CtmsGrantExerciseTag_CtmsGrant";
};
export type CtmsGrantExerciseTag_CtmsGrant$key = {
  readonly " $data"?: CtmsGrantExerciseTag_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantExerciseTag_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantExerciseTag_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exerciseStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantStatusTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "59cfa303ddc116342ddbf28c07684b11";

export default node;
