import classNames from "classnames";
import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Tab } from "../../../components/ui/Tab";
import { SuspensedTabList_Deferred_Organization$key } from "./__generated__/SuspensedTabList_Deferred_Organization.graphql";
import { SuspensedTabList_Organization$key } from "./__generated__/SuspensedTabList_Organization.graphql";

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedTabList_Deferred_Organization on Organization {
    activeGrantees: grantees(filters: { status: Active }) {
      totalCount
    }
    terminatedGrantees: grantees(filters: { status: Terminated }) {
      totalCount
    }
  }
`;

const Deferred: React.FC<{
  loading?: boolean;
  organizationFragment: SuspensedTabList_Deferred_Organization$key;
}> = ({ loading, organizationFragment }) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  return (
    <Tab.List
      className={classNames({
        "animate-pulse": loading,
      })}
    >
      <Tab.WithCount count={organization.activeGrantees.totalCount}>
        Active
      </Tab.WithCount>
      <Tab.WithCount count={organization.terminatedGrantees.totalCount}>
        Terminated
      </Tab.WithCount>
    </Tab.List>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment SuspensedTabList_Organization on Organization {
    ...SuspensedTabList_Deferred_Organization @defer
  }
`;

export const SuspensedTabList: React.FC<{
  loading?: boolean;
  organizationFragment: SuspensedTabList_Organization$key;
}> = ({ loading, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense
      fallback={
        <Tab.List
          className={classNames({
            "animate-pulse": loading,
          })}
        >
          <Tab.WithCount count={99} skeleton>
            Active
          </Tab.WithCount>
          <Tab.WithCount count={99} skeleton>
            Terminated
          </Tab.WithCount>
        </Tab.List>
      }
    >
      <Deferred loading={loading} organizationFragment={organization} />
    </Suspense>
  );
};
