import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedFMV } from "../../../components/FormattedFMV";
import { QuestionCard } from "../../../components/QuestionCard";
import { Typography } from "../../../components/ui/Typography";
import { QuestionCards_FairMarketValue_InstrumentValuation$key } from "./__generated__/QuestionCards_FairMarketValue_InstrumentValuation.graphql";
import { QuestionCards_ValueToday_Organization$key } from "./__generated__/QuestionCards_ValueToday_Organization.graphql";

const ORGANIZATION_OWNERSHIP_FRAGMENT = graphql`
  fragment QuestionCards_ValueToday_Organization on Organization {
    latestPricePerShare @required(action: THROW) {
      value
    }
    fullyDilutedShares
  }
`;

export const ValueTodayQuestionCard: React.FC<{
  className?: string;
  onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
  organizationFragment: QuestionCards_ValueToday_Organization$key;
}> = ({ className, onCloseButtonClick, organizationFragment }) => {
  const organization = useFragment(
    ORGANIZATION_OWNERSHIP_FRAGMENT,
    organizationFragment,
  );

  return (
    <QuestionCard
      className={className}
      onCloseButtonClick={onCloseButtonClick}
      title="How do we come to the potential value today?"
    >
      The <strong>potential value today</strong> is derived from the price per
      share (of the preferred stock) multiplied by the number of shares you want
      to grant.
      <br />
      Unless you’ve asked us to use another valuation, the price per share has
      itself been calculated by:
      <ul className="list-disc py-8 pl-6">
        <li>
          <strong>
            taking your company’s valuation at your latest fundraising event
          </strong>{" "}
          (if your latest fundraising event wasn’t a priced round - for example
          because you’ve raised via a convertible note or a SAFE -, we have used
          either (a) the valuation cap that you’ve negotiated with your
          investor(s) as a basis to determine your company’s valuation; or (b)
          the valuation which you’ve asked us to use).
        </li>
        <li>
          dividing it by the fully diluted number of shares{" "}
          {organization.fullyDilutedShares !== null ? (
            <>
              (
              <strong>
                <FormattedNumber value={organization.fullyDilutedShares} />
              </strong>
              )
            </>
          ) : null}
          , which includes all your company’s shares, including those shares
          that have been reserved for issuance but have not been issued yet (for
          example, the shares reserved under your ESOP pool, or reserved to an
          investor who invested in your company via a SAFE or a convertible
          note).
        </li>
      </ul>
      <br />
      Your current price per share is:{" "}
      <strong>
        <FormattedFMV value={organization.latestPricePerShare.value} />
      </strong>
    </QuestionCard>
  );
};

export const SarValueTodayQuestionCard: React.FC<{
  className?: string;
  onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
  organizationFragment: QuestionCards_ValueToday_Organization$key;
}> = ({ className, onCloseButtonClick, organizationFragment }) => {
  const organization = useFragment(
    ORGANIZATION_OWNERSHIP_FRAGMENT,
    organizationFragment,
  );

  return (
    <QuestionCard
      className={className}
      onCloseButtonClick={onCloseButtonClick}
      title="How do we come to the potential value today?"
    >
      The <strong>potential value today</strong> is derived from the price per
      share (of the preferred stock) multiplied by the number of SARs you want
      to grant.
      <br />
      Unless you’ve asked us to use another valuation, the price per share has
      itself been calculated by:
      <ul className="list-disc py-8 pl-6">
        <li>
          Taking your company’s valuation at your latest fundraising event (if
          your latest fundraising event wasn’t a priced round - for example
          because you’ve raised via a convertible note or a SAFE -, we have used
          either (a) the valuation cap that you’ve negotiated with your
          investor(s) as a basis to determine your company’s valuation; or (b)
          the valuation which you’ve asked us to use)
        </li>
        <li>
          Dividing it by the fully diluted number of shares{" "}
          {organization.fullyDilutedShares !== null ? (
            <>
              (
              <strong>
                <FormattedNumber value={organization.fullyDilutedShares} />
              </strong>
              )
            </>
          ) : null}
          , which includes all your company’s shares, including those shares
          that have been reserved for issuance but have not been issued yet (for
          example, the shares reserved under your ESOP pool, or reserved to an
          investor who invested in your company via a SAFE or a convertible
          note).
        </li>
      </ul>
      <br />
      Your current price per share is{" "}
      <strong>
        <FormattedFMV value={organization.latestPricePerShare.value} />
      </strong>
      <br />
      USD value = PPS x number of SARs granted
      <br />
      Don’t forget that you should also deduct the virtual exercise price from
      this amount
    </QuestionCard>
  );
};

const INSTRUMENT_VALUATION_FRAGMENT = graphql`
  fragment QuestionCards_FairMarketValue_InstrumentValuation on InstrumentValuation {
    type
    valueInDollars
  }
`;

export const FairMarketValueQuestionCard: React.FC<{
  className?: string;
  instrumentValuationFragment: QuestionCards_FairMarketValue_InstrumentValuation$key;
  onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
}> = ({ className, instrumentValuationFragment, onCloseButtonClick }) => {
  const instrumentValuation = useFragment(
    INSTRUMENT_VALUATION_FRAGMENT,
    instrumentValuationFragment,
  );

  switch (instrumentValuation.type) {
    case "FMV_409A":
      return (
        <QuestionCard
          className={className}
          onCloseButtonClick={onCloseButtonClick}
          title="The current Fair Market Value is set in your 409A valuation report."
        >
          {instrumentValuation.valueInDollars !== null && (
            <>
              Current Fair Market Value:
              <br />
              <Typography variant="Medium/Small">
                <FormattedFMV value={instrumentValuation.valueInDollars} />
              </Typography>
              <br />
              <br />
            </>
          )}
          The vast majority of US companies choose to set the exercise price on
          the basis of the fair market value of the shares of Common Stock as
          reflected in their latest 409A valuation.
        </QuestionCard>
      );
    case "UK_VALUATION":
      return (
        <QuestionCard
          className={className}
          onCloseButtonClick={onCloseButtonClick}
          title="For EMI options, the current Fair Market Value is set in your EMI valuation report."
        >
          {instrumentValuation.valueInDollars ? (
            <>
              Current Fair Market Value based on your EMI valuation report:
              <br />
              <Typography variant="Medium/Small">
                <FormattedFMV value={instrumentValuation.valueInDollars} />
              </Typography>
            </>
          ) : (
            <>Missing EMI valuation</>
          )}
          <br />
          <br />
          The vast majority of US companies eligible for EMI grants choose to
          set the exercise price on the basis of the fair market value of the
          shares of Common Stock as reflected in their latest EMI valuation.
        </QuestionCard>
      );
  }
};
