/**
 * @generated SignedSource<<bb640d18d32affe7a4fb83ef736dcff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Countries_Viewer$data = {
  readonly isAllowedToUnlockCountryForOrganization: boolean;
  readonly " $fragmentType": "Countries_Viewer";
};
export type Countries_Viewer$key = {
  readonly " $data"?: Countries_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Countries_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Countries_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToUnlockCountryForOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "5c68e7377fc9e1cf8325c788cbc2cbb6";

export default node;
