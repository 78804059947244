/**
 * @generated SignedSource<<3d5853cc62c2588608767c8c66a4c302>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivityExercisableSharesBoxes_Organization$data = {
  readonly ctmsGrants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cumulativeVested: number;
        readonly earlyExercise: boolean;
        readonly equityTypeAwardName: string | null;
        readonly exercisableNonExercisedShares: number;
        readonly grantStatus: CTMSGrantStatus;
        readonly quantityExercised: number;
        readonly quantityIssued: number;
      };
    }>;
  };
  readonly " $fragmentType": "ExercisesActivityExercisableSharesBoxes_Organization";
};
export type ExercisesActivityExercisableSharesBoxes_Organization$key = {
  readonly " $data"?: ExercisesActivityExercisableSharesBoxes_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivityExercisableSharesBoxes_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesActivityExercisableSharesBoxes_Organization",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Active",
              "Terminated"
            ]
          }
        }
      ],
      "concreteType": "OrganizationCTMSGrantsConnection",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationCTMSGrantsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CTMSGrant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "grantStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "equityTypeAwardName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "earlyExercise",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantityExercised",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "exercisableNonExercisedShares",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cumulativeVested",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quantityIssued",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\",\"Terminated\"]})"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "50b5bbc0c87c861a36d1847ce2e463a8";

export default node;
