/**
 * @generated SignedSource<<a67e1014db6071adc0edb1271ae29a96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type LockedGeographySlideOver_Content_GeographyWithPrice$data = {
  readonly geography: {
    readonly country: {
      readonly code: string;
      readonly emoji: string;
      readonly name: string;
    };
    readonly equityTypes: ReadonlyArray<{
      readonly description: string | null;
      readonly fullname: string | null;
      readonly id: string;
      readonly instruments: ReadonlyArray<{
        readonly workRelationship: WorkRelationship;
      }>;
      readonly name: EquityTypeName;
      readonly taxFavored: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_EquityTypeDescription_EquityType">;
    }>;
  };
  readonly standardPrice: {
    readonly priceAfterDiscount: number;
  } | null;
  readonly taxFavoredPrice: {
    readonly priceAfterDiscount: number;
  } | null;
  readonly " $fragmentType": "LockedGeographySlideOver_Content_GeographyWithPrice";
};
export type LockedGeographySlideOver_Content_GeographyWithPrice$key = {
  readonly " $data"?: LockedGeographySlideOver_Content_GeographyWithPrice$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_Content_GeographyWithPrice">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "priceAfterDiscount",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockedGeographySlideOver_Content_GeographyWithPrice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Geography",
      "kind": "LinkedField",
      "name": "geography",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityTypes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxFavored",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Instrument",
              "kind": "LinkedField",
              "name": "instruments",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workRelationship",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LockedGeographySlideOver_EquityTypeDescription_EquityType"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSpecificPrice",
      "kind": "LinkedField",
      "name": "standardPrice",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationSpecificPrice",
      "kind": "LinkedField",
      "name": "taxFavoredPrice",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "GeographyWithPrice",
  "abstractKey": null
};
})();

(node as any).hash = "73bfb322bc84d40da7cf58dbc512ba72";

export default node;
