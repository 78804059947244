/**
 * @generated SignedSource<<aeb7ec0f6025ad1d7bb64f7c142d947d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsAdmins_RemoveAdmin_Mutation$variables = {
  adminId: string;
};
export type OrganizationSettingsAdmins_RemoveAdmin_Mutation$data = {
  readonly removeAdmin: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Organization">;
  };
};
export type OrganizationSettingsAdmins_RemoveAdmin_Mutation = {
  response: OrganizationSettingsAdmins_RemoveAdmin_Mutation$data;
  variables: OrganizationSettingsAdmins_RemoveAdmin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "adminId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "adminId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsAdmins_RemoveAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeAdmin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsAdmins_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsAdmins_RemoveAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeAdmin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "adminInvitationIsAllowed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Admin",
            "kind": "LinkedField",
            "name": "admins",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "orderBy": {
                    "direction": "ASC",
                    "field": "name"
                  }
                }
              }
            ],
            "concreteType": "RemoteCompanyAdmin",
            "kind": "LinkedField",
            "name": "remoteCompanyAdmins",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userSlug",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowedAsAdmin",
                "storageKey": null
              }
            ],
            "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "608c3f3af91ddb219945892a0ada9c3d",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsAdmins_RemoveAdmin_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsAdmins_RemoveAdmin_Mutation(\n  $adminId: UUID!\n) {\n  removeAdmin(id: $adminId) {\n    ...OrganizationSettingsAdmins_Organization\n    id\n  }\n}\n\nfragment OrganizationSettingsAdmins_Item_Admin on Admin {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsAdmins_Organization on Organization {\n  id\n  name\n  adminInvitationIsAllowed\n  admins {\n    id\n    ...OrganizationSettingsAdmins_Item_Admin\n  }\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    __typename\n    id\n  }\n  ...RemoteCompanyAdminsList_Organization\n}\n\nfragment RemoteCompanyAdminsList_Organization on Organization {\n  id\n  name\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    id\n    userSlug\n    name\n    email\n    allowedAsAdmin\n  }\n}\n"
  }
};
})();

(node as any).hash = "3be05d03c4981d4d503619a809b2a70e";

export default node;
