import React from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { EmptyStateView } from "../../../components/EmptyStateView";
import {
  RefreshingExercisesRequestJobState,
  RefreshingExercisesRequestNoticeMessage,
} from "../../../components/RefreshingExercisesRequestNoticeMessage";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { APPLICATION_ROUTES } from "../../../paths";
import { ExercisesEmptyState_Organization$key } from "./__generated__/ExercisesEmptyState_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesEmptyState_Organization on Organization {
    id
    name
  }
`;

export const ExercisesEmptyState: React.FC<{
  organizationFragment: ExercisesEmptyState_Organization$key;
  refreshingExercisesRequest: boolean;
  refreshingExercisesRequestJobState: null | RefreshingExercisesRequestJobState;
}> = ({
  organizationFragment,
  refreshingExercisesRequest,
  refreshingExercisesRequestJobState,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <EmptyStateView
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Exercises</BreadCrumb.Link>
        </BreadCrumb>
      }
      bullets={[
        "Follow exercises happening in your teams closely",
        "Know exactly what you need to report",
        "Be compliant with your EoRs",
      ]}
      description="Manage your tax and reporting obligations when your team members exercise their stock options"
      title={
        refreshingExercisesRequest || refreshingExercisesRequestJobState ? (
          <div className="space-y-10">
            <div>💸 No pending exercises</div>
            <RefreshingExercisesRequestNoticeMessage
              refreshingExercisesRequestJobState={
                refreshingExercisesRequestJobState ?? "Active"
              }
            />
          </div>
        ) : (
          <>💸 No pending exercises</>
        )
      }
    />
  );
};
