import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GrantAmendmentRequestBox } from "../../../../components/GrantAmendmentRequest/GrantAmendmentRequestBox";
import { Typography } from "../../../../components/ui/Typography";
import {
  GrantAmendmentRequest,
  GrantAmendmentRequests,
  GrantAmendmentRequestsFormSchema,
} from "../../../../services/grantAmendmentRequest";
import { GranteeTerminationGrantAmendmentsList_Grantee$key } from "./__generated__/GranteeTerminationGrantAmendmentsList_Grantee.graphql";
import { GranteeTerminationGrantAmendmentsList_Organization$key } from "./__generated__/GranteeTerminationGrantAmendmentsList_Organization.graphql";
import { GranteeTerminationGrantAmendmentsList_Viewer$key } from "./__generated__/GranteeTerminationGrantAmendmentsList_Viewer.graphql";
import { useGranteeTerminationContext } from "./GranteeTerminationContext";
import { GranteeTerminationLayout } from "./GranteeTerminationLayout";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTerminationGrantAmendmentsList_Organization on Organization {
    id
    name
    ...GrantAmendmentRequestBox_Organization
  }
`;
const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTerminationGrantAmendmentsList_Grantee on Grantee {
    id
    name
    activeCTMSGrants: ctmsGrants(grantStatusIn: [Active]) {
      id
      ...GrantAmendmentRequestBox_CTMSGrant
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GranteeTerminationGrantAmendmentsList_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...GrantAmendmentRequestBox_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

export const GranteeTerminationGrantAmendmentsList: React.FC<{
  granteeFragment: GranteeTerminationGrantAmendmentsList_Grantee$key;
  onBackClick: () => void;
  onNextClick: (data: GrantAmendmentRequests) => void;
  organizationFragment: GranteeTerminationGrantAmendmentsList_Organization$key;
  viewerFragment: GranteeTerminationGrantAmendmentsList_Viewer$key;
}> = ({
  granteeFragment,
  onBackClick,
  onNextClick,
  organizationFragment,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const granteeTerminationContext = useGranteeTerminationContext();

  const { getValues, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      grantAmendments: granteeTerminationContext.state.grantAmendments || [],
    },
    resolver: zodResolver(GrantAmendmentRequestsFormSchema),
  });

  const { terminationDate, terminationLastDayAtTheCompany } = useMemo(() => {
    if (
      !granteeTerminationContext.state.terminationDate ||
      !granteeTerminationContext.state.terminationLastDayAtTheCompany
    ) {
      throw new Error("State is missing mandatory information");
    }
    return {
      terminationDate: granteeTerminationContext.state.terminationDate,
      terminationLastDayAtTheCompany:
        granteeTerminationContext.state.terminationLastDayAtTheCompany,
    };
  }, [granteeTerminationContext]);

  const onGrantAmendmentUpdate = useCallback(
    (granteeTerminationGrantAmendment: GrantAmendmentRequest) => {
      const { grantAmendments } = getValues();

      const existingGrantAmendment = grantAmendments.find(
        (grantAmendment) =>
          grantAmendment.ctmsGrantId ===
          granteeTerminationGrantAmendment.ctmsGrantId,
      );

      const updatedGrantAmendment = existingGrantAmendment
        ? { ...existingGrantAmendment, ...granteeTerminationGrantAmendment }
        : granteeTerminationGrantAmendment;

      if (existingGrantAmendment) {
        setValue(
          "grantAmendments",
          grantAmendments.map((grantAmendment) => {
            if (grantAmendment === existingGrantAmendment) {
              return updatedGrantAmendment;
            }
            return grantAmendment;
          }),
        );
      } else {
        setValue("grantAmendments", [
          ...grantAmendments,
          updatedGrantAmendment,
        ]);
      }

      return updatedGrantAmendment;
    },
    [getValues, setValue],
  );

  const grantAmendments = watch("grantAmendments");

  return (
    <GranteeTerminationLayout
      grantee={{ id: grantee.id, name: grantee.name }}
      onBackClick={onBackClick}
      onNextClick={handleSubmit(onNextClick)}
      organization={{ id: organization.id, name: organization.name }}
    >
      <div className="border-b-[0.5px] border-t-[0.5px] border-gray-04 bg-gray-01 px-10 py-6">
        <Typography as="div" className="mb-2" variant="Medium/Small">
          👀 Review grant details
        </Typography>

        <Typography
          as="div"
          className="mb-12 text-black-05"
          variant="Regular/Extra Small"
        >
          You can review and modify grant details in case of a termination. Note
          that modifying grant details will require a grant amendment and
          therefore a new board consent will be created and will need approval
          of the board.
        </Typography>

        <div className="flex flex-col gap-4">
          {grantee.activeCTMSGrants.map((ctmsGrant) => (
            <GrantAmendmentRequestBox
              ctmsGrantFragment={ctmsGrant}
              grantAmendment={
                grantAmendments.find(
                  (grantAmendment) =>
                    grantAmendment.ctmsGrantId === ctmsGrant.id,
                ) || null
              }
              key={ctmsGrant.id}
              onChange={onGrantAmendmentUpdate}
              organizationFragment={organization}
              terminationDate={terminationDate}
              terminationLastDayAtTheCompany={terminationLastDayAtTheCompany}
              viewerFragment={viewer}
            />
          ))}
        </div>
      </div>
    </GranteeTerminationLayout>
  );
};
