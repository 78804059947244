/**
 * @generated SignedSource<<abdd6452c9334fc1b1e679d6df4c94cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettingsUsageSection_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
        readonly hasPortalAccess: boolean;
        readonly hasVisitedTheirPortal: boolean;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Grantees" | "NewEquitySelectionModal_DefaultGrantee" | "PreviewGranteesPortalModal_Grantees">;
      };
    }>;
  };
  readonly granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet: ReadonlyArray<{
    readonly __typename: "Grantee";
  }>;
  readonly granteesThatDidNotVisitTheirPortalYet: ReadonlyArray<{
    readonly __typename: "Grantee";
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Organization" | "PreviewGranteesPortalModal_Organization">;
  readonly " $fragmentType": "GranteePortalSettingsUsageSection_Organization";
};
export type GranteePortalSettingsUsageSection_Organization$key = {
  readonly " $data"?: GranteePortalSettingsUsageSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsUsageSection_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalSettingsUsageSection_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "granteesThatDidNotVisitTheirPortalYet",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "granteesInvitedMoreThanAWeekAgoThatDidNotVisitTheirPortalYet",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cannotBeInvitedReason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPortalAccess",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasVisitedTheirPortal",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "InviteGranteesModal_Grantees"
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "organizationId",
                      "variableName": "organizationId"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "NewEquitySelectionModal_DefaultGrantee"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PreviewGranteesPortalModal_Grantees"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewGranteesPortalModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "c68117f3dfaf9ab367fcb7fccf5bc576";

export default node;
