import classNames from "classnames";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { mapRecordValues } from "../helpers/object-utility";
import {
  PriceEvolutionGraphForVSOP_CTMSGrant$data,
  PriceEvolutionGraphForVSOP_CTMSGrant$key,
} from "./__generated__/PriceEvolutionGraphForVSOP_CTMSGrant.graphql";
import { PriceEvolutionGraphForVSOP_Organization$key } from "./__generated__/PriceEvolutionGraphForVSOP_Organization.graphql";
import { BarGraph } from "./BarGraph";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import {
  PriceEvolutionGraphMode,
  StackTopLabelUsingFormattedCurrency,
  StackTopLabelUsingFormattedFMV,
} from "./PriceEvolutionGraph";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment PriceEvolutionGraphForVSOP_CTMSGrant on CTMSGrant {
    pricePerShareAtGrant
    easopGrant {
      exercisePrice
    }
    exercisePrice
    cumulativeVested
    quantityIssued
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PriceEvolutionGraphForVSOP_Organization on Organization {
    latestPricePerShare {
      value
    }
    latestFairMarketValue {
      value
    }
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

type ValueId =
  | "fmvToday"
  | "ppsAtGrant"
  | "ppsToday"
  | "virtualExercisePriceForFMVToday"
  | "virtualExercisePriceForPPSAtGrant"
  | "virtualExercisePriceForPPSToday";

const StackIds = [
  "virtualExercisePriceVsPPSAtGrant",
  "virtualExercisePriceVsFMVToday",
  "virtualExercisePriceVsPPSToday",
] as const;

type StackId = (typeof StackIds)[number];

const StackLabelsMap: Record<StackId, string> = {
  virtualExercisePriceVsFMVToday: "Today",
  virtualExercisePriceVsPPSAtGrant: "At grant",
  virtualExercisePriceVsPPSToday: "Today",
} as const;

const LegendMap: Record<ValueId, null | string> = {
  fmvToday: null,
  ppsAtGrant: null,
  ppsToday: null,
  virtualExercisePriceForFMVToday:
    "Virtual Exercise Price vs FMV of Common Stock ",
  virtualExercisePriceForPPSAtGrant:
    "Virtual Exercise Price vs Price Per Share",
  virtualExercisePriceForPPSToday: "Virtual Exercise Price vs Price Per Share",
};

const BarsDataKeysWithStack: Array<{
  className: string;
  key: ValueId;
  stackId: StackId;
}> = [
  {
    className: classNames("bg-purple-05 fill-purple-05"),
    key: "virtualExercisePriceForPPSAtGrant",
    stackId: "virtualExercisePriceVsPPSAtGrant",
  },
  {
    className: classNames("bg-purple-02 fill-purple-02"),
    key: "ppsAtGrant",
    stackId: "virtualExercisePriceVsPPSAtGrant",
  },
  {
    className: classNames("bg-primary-05 fill-primary-05"),
    key: "virtualExercisePriceForFMVToday",
    stackId: "virtualExercisePriceVsFMVToday",
  },
  {
    className: classNames("bg-primary-02 fill-primary-02"),
    key: "fmvToday",
    stackId: "virtualExercisePriceVsFMVToday",
  },
  {
    className: classNames("bg-yellow-05 fill-yellow-05"),
    key: "virtualExercisePriceForPPSToday",
    stackId: "virtualExercisePriceVsPPSToday",
  },
  {
    className: classNames("bg-yellow-02 fill-yellow-02"),
    key: "ppsToday",
    stackId: "virtualExercisePriceVsPPSToday",
  },
];

const PriceEvolutionGraphForVSOP_: React.FC<{
  ctmsGrant: PriceEvolutionGraphForVSOP_CTMSGrant$data;
  exercisePrice: number;
  graphMode: PriceEvolutionGraphMode;
  height: number;
  latestFairMarketValue: number;
  latestPricePerShare: number;
  pricePerShareAtGrant: number;
}> = ({
  ctmsGrant,
  exercisePrice,
  graphMode,
  height,
  latestFairMarketValue,
  latestPricePerShare,
  pricePerShareAtGrant,
}) => {
  const TopLabelMap: Record<StackId, React.ReactNode> = useMemo(() => {
    const valueRecord = {
      virtualExercisePriceVsFMVToday: latestFairMarketValue,
      virtualExercisePriceVsPPSAtGrant: pricePerShareAtGrant,
      virtualExercisePriceVsPPSToday: latestPricePerShare,
    };
    switch (graphMode) {
      case "Fully vested": {
        const labelRecord = {
          virtualExercisePriceVsFMVToday: "FMV",
          virtualExercisePriceVsPPSAtGrant: "Initial valuation",
          virtualExercisePriceVsPPSToday: "Latest valuation",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedCurrency
            label={labelRecord[key]}
            valueInUSD={value * ctmsGrant.quantityIssued}
          />
        ));
      }
      case "Per share": {
        const labelRecord = {
          virtualExercisePriceVsFMVToday: "FMV",
          virtualExercisePriceVsPPSAtGrant: "Price per share",
          virtualExercisePriceVsPPSToday: "Price per share",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedFMV
            label={labelRecord[key]}
            valueInUSD={value}
          />
        ));
      }
      case "Vested": {
        const labelRecord = {
          virtualExercisePriceVsFMVToday: "FMV",
          virtualExercisePriceVsPPSAtGrant: "Initial valuation",
          virtualExercisePriceVsPPSToday: "Latest valuation",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedCurrency
            label={labelRecord[key]}
            valueInUSD={value * ctmsGrant.cumulativeVested}
          />
        ));
      }
    }
  }, [
    pricePerShareAtGrant,
    latestFairMarketValue,
    latestPricePerShare,
    graphMode,
    ctmsGrant,
  ]);

  const barsDataStackedValues: Record<ValueId, number> = useMemo(() => {
    const record = {
      fmvToday: Math.max(0, latestFairMarketValue - exercisePrice),
      ppsAtGrant: Math.max(0, pricePerShareAtGrant - exercisePrice),
      ppsToday: Math.max(0, latestPricePerShare - exercisePrice),
      virtualExercisePriceForFMVToday: exercisePrice,
      virtualExercisePriceForPPSAtGrant: exercisePrice,
      virtualExercisePriceForPPSToday: exercisePrice,
    };

    switch (graphMode) {
      case "Fully vested":
        return mapRecordValues(
          record,
          (value) => value * ctmsGrant.quantityIssued,
        );
      case "Per share":
        return record;
      case "Vested":
        return mapRecordValues(
          record,
          (value) => value * ctmsGrant.cumulativeVested,
        );
    }
  }, [
    exercisePrice,
    pricePerShareAtGrant,
    latestFairMarketValue,
    latestPricePerShare,
    ctmsGrant,
    graphMode,
  ]);

  const stacks = useMemo(
    () =>
      StackIds.map((key) => ({
        elements: BarsDataKeysWithStack.filter(
          ({ stackId }) => stackId === key,
        ).map(({ className, key }) => ({
          className,
          key,
          legendLabel: LegendMap[key],
          value: barsDataStackedValues[key],
        })),
        key,
        label: StackLabelsMap[key],
      })),
    [barsDataStackedValues],
  );

  const intl = useIntl();

  return (
    <BarGraph
      barGap={50}
      barSize={64}
      height={height}
      renderBarTopLabel={(stack) => TopLabelMap[stack.key]}
      showLegend
      stacks={stacks}
      yTickFormatter={(value: number) =>
        intl.formatNumber(value, {
          compactDisplay: "short",
          currency: "USD",
          notation: "compact",
          style: "currency",
        })
      }
    />
  );
};

export const PriceEvolutionGraphForVSOP: React.FC<{
  ctmsGrantFragment: PriceEvolutionGraphForVSOP_CTMSGrant$key;
  graphMode: PriceEvolutionGraphMode;
  height?: number;
  organizationFragment: PriceEvolutionGraphForVSOP_Organization$key;
}> = ({ ctmsGrantFragment, graphMode, height = 190, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const exercisePrice =
    ctmsGrant.exercisePrice ?? ctmsGrant.easopGrant?.exercisePrice ?? null;
  const latestPricePerShare = organization.latestPricePerShare?.value ?? null;
  const latestFairMarketValue =
    organization.latestFairMarketValue?.value ?? null;
  const pricePerShareAtGrant = ctmsGrant.pricePerShareAtGrant;

  if (
    typeof exercisePrice !== "number" ||
    typeof latestPricePerShare !== "number" ||
    typeof latestFairMarketValue !== "number" ||
    typeof pricePerShareAtGrant !== "number"
  ) {
    return (
      <div className="w-full text-center">
        <MissingCTMSInformationTag organizationFragment={organization} />
      </div>
    );
  }

  return (
    <PriceEvolutionGraphForVSOP_
      ctmsGrant={ctmsGrant}
      exercisePrice={exercisePrice}
      graphMode={graphMode}
      height={height}
      latestFairMarketValue={latestFairMarketValue}
      latestPricePerShare={latestPricePerShare}
      pricePerShareAtGrant={pricePerShareAtGrant}
    />
  );
};
