import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { GrantAmendmentRequestBoxPTEPSlideOverRemote } from "../../../../components/GrantAmendmentRequest/GrantAmendmentRequestBoxPTEPSlide";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { ConfirmationModal } from "../../../../components/ui/ConfirmationModal";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { APPLICATION_ROUTES } from "../../../../paths";

export const GranteeTerminationLayout: React.FC<{
  children: React.ReactNode;
  grantee: { id: string; name: string };
  onBackClick?: () => void;
  onNextClick?: () => void;
  onSubmit?: () => void;
  organization: { id: string; name: string };
  submitting?: boolean;
}> = ({
  children,
  grantee,
  onBackClick,
  onNextClick,
  onSubmit,
  organization,
  submitting,
}) => {
  const navigate = useNavigate();
  const {
    setFalse: hideExitModal,
    setTrue: showExitModal,
    value: exitModalIsShown,
  } = useBoolean(false);
  const goToGranteePage = useCallback(
    () =>
      navigate(
        generatePath(APPLICATION_ROUTES["organizationGrantee"], {
          granteeId: grantee.id,
          organizationId: organization.id,
        }),
      ),
    [navigate, grantee, organization],
  );
  return (
    <>
      <ConfirmationModal
        cancelLabel="Continue termination"
        confirmationLabel="Exit termination"
        onClose={hideExitModal}
        onConfirmed={() => goToGranteePage()}
        show={exitModalIsShown}
        suspense
        title="Cancel grantee termination?"
      >
        When you exit the flow, any unsaved changes will be lost.
      </ConfirmationModal>
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link to="../../..">{organization.name}</BreadCrumb.Link>
            <BreadCrumb.Link to="../..">Grantees</BreadCrumb.Link>
            <BreadCrumb.Link to="..">{grantee.name}</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Terminate grantee</BreadCrumb.Link>
          </BreadCrumb>
        }
      >
        <GrantAmendmentRequestBoxPTEPSlideOverRemote.Provider>
          <form
            className="mx-auto max-w-[984px]"
            id="terminate-grantee-form"
            onSubmit={onSubmit ?? onNextClick}
          >
            <RoundedBox className="flex w-full flex-col" withBorder>
              <Typography className="p-10 pb-6" variant="Medium/Default">
                Terminate grantee
              </Typography>
              {children}
              <div className="flex justify-between gap-2 p-10 pt-6">
                <div className="flex gap-2">
                  {onBackClick && (
                    <Button
                      leftIcon={<ArrowLeftIcon />}
                      onClick={onBackClick}
                      size="small"
                      type="button"
                      variant="Primary Outline"
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={showExitModal}
                    size="small"
                    type="button"
                    variant="Secondary Full"
                  >
                    Cancel
                  </Button>
                </div>

                {onNextClick && (
                  <Button
                    rightIcon={<ArrowRightIcon />}
                    size="small"
                    type="submit"
                  >
                    Next
                  </Button>
                )}
                {onSubmit && (
                  <Button loading={submitting} size="small" type="submit">
                    Confirm termination
                  </Button>
                )}
              </div>
            </RoundedBox>
          </form>
        </GrantAmendmentRequestBoxPTEPSlideOverRemote.Provider>
      </OneColumnLayout>
    </>
  );
};
