import { useApplicationName } from "../hooks/useApplicationTheme";
import { LogoWithoutText } from "./ui/Logo/Logo";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const GranteePortalMissingGranteeInformationMessage: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <RoundedBox
      className="flex flex-col items-center gap-6 p-10 text-center"
      withBorder
    >
      <LogoWithoutText className="w-10" />
      <Typography variant="Medium/Large">
        We’re missing some mandatory information
      </Typography>
      <div className="flex flex-col items-center gap-4">
        <Typography variant="Medium/Default">👩🏽‍💻 ➡️ ✅</Typography>
        <Typography variant="Regular/Small">
          We don’t have enough information in order to provide you with the
          correct Reporting and Taxation content, as we need to know your tax
          residence and type of work relationship. Your admin should be in a
          position to give that information to {applicationName}, don’t hesitate
          to remind them.
        </Typography>
      </div>
    </RoundedBox>
  );
};
