import React from "react";
import { graphql, useFragment } from "react-relay";

import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { WarningTaxFavoredSubplanExpired_EasopGrants$key } from "./__generated__/WarningTaxFavoredSubplanExpired_EasopGrants.graphql";
import { WarningTaxFavoredSubplanExpired_Organization$key } from "./__generated__/WarningTaxFavoredSubplanExpired_Organization.graphql";
import { useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan } from "./useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan";

const ORGANIZATION_FRAGMENT = graphql`
  fragment WarningTaxFavoredSubplanExpired_Organization on Organization {
    name
  }
`;

const EASOP_GRANTS_FRAGMENT = graphql`
  fragment WarningTaxFavoredSubplanExpired_EasopGrants on EasopGrant
  @relay(plural: true) {
    ...useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants
  }
`;

export const WarningTaxFavoredSubplanExpired: React.FC<{
  easopGrantsFragment: WarningTaxFavoredSubplanExpired_EasopGrants$key;
  organizationFragment: WarningTaxFavoredSubplanExpired_Organization$key;
}> = ({ easopGrantsFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const easopGrants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrantsFragment);

  const someEasopGrantUsesAnExpiredTaxFavoredSubplan =
    useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan({ easopGrants });
  const supportEmailAddress = useApplicationSupportEmailAddress();

  if (!someEasopGrantUsesAnExpiredTaxFavoredSubplan) {
    return null;
  }

  const mailtoSubject = encodeURI(
    `[${organization.name}] What should I do when my board authorisation to issue BSPCE has expired?`,
  );

  return (
    <NoticeMessage hasColor={false} size="Large" variant="Warning">
      Board authorisation to issue BSPCE has expired. Please{" "}
      <a
        className="font-medium text-primary"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
      >
        contact us
      </a>{" "}
      to refresh it.
    </NoticeMessage>
  );
};
