import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { compact } from "lodash";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { GranteeNameWithCountryFlag } from "../../../components/GranteeNameWithCountryFlag";
import { StopClickPropagationWrapper } from "../../../components/StopClickPropagationWrapper";
import { Button } from "../../../components/ui/Button";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { LoadingSpinner } from "../../../components/ui/LoadingSpinner";
import { MenuButton } from "../../../components/ui/MenuButton";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$data,
  CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$key,
} from "./__generated__/CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest.graphql";
import { CTMSGrantAmendmentRequestsTable_Organization$key } from "./__generated__/CTMSGrantAmendmentRequestsTable_Organization.graphql";
import { GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP } from "./shared";

const AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest
  @relay(plural: true) {
    id
    status
    isUnfavorable
    isDeletable
    ctmsGrant {
      id
      label
      grantee {
        id
        name
        workRelationship
        ...GranteeNameWithCountryFlag_Grantee
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CTMSGrantAmendmentRequestsTable_Organization on Organization {
    id
  }
`;

const columnHelper =
  createColumnHelper<
    CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$data[number]
  >();

export const CTMSGrantAmendmentRequestsTable: React.FC<
  {
    boardConsentGenerationIsInProgress?: boolean;
    borderless?: boolean;
    ctmsGrantAmendmentRequestsFragment: CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$key;
    disableRowSelection?: boolean;
    emptyListPlaceholder?: React.ReactNode;
    onSelectionChange?: (selection: Set<string>) => void;
    organizationFragment: CTMSGrantAmendmentRequestsTable_Organization$key;
    selection?: Set<string>;
  } & (
    | {
        onDeleteGrantAmendmentRequestClick: (
          grantAmendmentRequestId: string,
        ) => void;
        showActionsColumn: boolean;
      }
    | {
        onDeleteGrantAmendmentRequestClick?: undefined;
        showActionsColumn?: undefined;
      }
  )
> = ({
  boardConsentGenerationIsInProgress,
  borderless,
  ctmsGrantAmendmentRequestsFragment,
  disableRowSelection,
  emptyListPlaceholder,
  onDeleteGrantAmendmentRequestClick,
  onSelectionChange,
  organizationFragment,
  selection,
  showActionsColumn,
}) => {
  const ctmsGrantAmendmentRequests = useFragment(
    AMENDMENT_REQUEST_FRAGMENT,
    ctmsGrantAmendmentRequestsFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const columns = useMemo(
    () =>
      compact([
        !disableRowSelection
          ? columnHelper.display({
              cell: (context) => (
                <Checkbox
                  checked={context.row.getIsSelected()}
                  onChange={context.row.getToggleSelectedHandler()}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              ),
              header: (context) => (
                <Checkbox
                  checked={context.table.getIsAllRowsSelected()}
                  onChange={context.table.getToggleAllPageRowsSelectedHandler()}
                />
              ),
              id: "select",
            })
          : null,
        columnHelper.accessor((row) => row.ctmsGrant.label, {
          cell: (context) => <Tag color="gray">{context.getValue()}</Tag>,
          enableSorting: true,
          header: () => "Grant",
          id: "grant-label",
        }),
        columnHelper.accessor((row) => row.ctmsGrant.grantee.name, {
          cell: (context) => {
            const grantee = context.row.original.ctmsGrant.grantee;

            return (
              <div className="space-y-1">
                <Typography as="div" variant="Regular/Extra Small">
                  <GranteeNameWithCountryFlag granteeFragment={grantee} />
                </Typography>
                {grantee.workRelationship && (
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    {
                      WORK_RELATIONSHIP_TO_LABEL_HELPER[
                        grantee.workRelationship
                      ].singularLabel
                    }
                  </Typography>
                )}
              </div>
            );
          },
          enableSorting: true,
          header: () => "Grantee",
          id: "grantee-name",
        }),
        columnHelper.display({
          cell: (context) => {
            const ctmsGrantAmendmentRequest = context.row.original;
            return (
              <div className="flex gap-1">
                <Tag color="gray">Amendment</Tag>
                {ctmsGrantAmendmentRequest.isUnfavorable && (
                  <Tag color="orange">Unfavorable</Tag>
                )}
              </div>
            );
          },
          header: "Type",
        }),
        columnHelper.accessor((row) => row.status, {
          cell: (context) => {
            if (boardConsentGenerationIsInProgress) {
              return (
                <Tag color="dashed">
                  <LoadingSpinner className="h-3 w-3 shrink-0" /> Generating
                  board consent
                </Tag>
              );
            }

            const status = context.getValue();

            return (
              <Tag color="dashed">
                {GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP[status]}
              </Tag>
            );
          },
          enableSorting: true,
          header: () => "Status",
          id: "status",
          meta: { alignRight: true },
        }),
        showActionsColumn &&
          columnHelper.accessor((row) => row, {
            cell: (context) => {
              const grantAmendment = context.getValue();
              return (
                <StopClickPropagationWrapper>
                  <MenuButton
                    button={
                      <Button
                        leftIcon={<EllipsisHorizontalIcon />}
                        size="extra small"
                        variant="Secondary Full"
                      />
                    }
                  >
                    {grantAmendment.isDeletable && (
                      <MenuButton.Item
                        leftIcon={<TrashIcon />}
                        onClick={() => {
                          onDeleteGrantAmendmentRequestClick(grantAmendment.id);
                        }}
                      >
                        Delete
                      </MenuButton.Item>
                    )}
                  </MenuButton>
                </StopClickPropagationWrapper>
              );
            },
            enableSorting: false,
            header: () => "Actions",
            id: "actions",
            meta: { alignRight: true },
          }),
      ]),
    [
      boardConsentGenerationIsInProgress,
      disableRowSelection,
      showActionsColumn,
      onDeleteGrantAmendmentRequestClick,
    ],
  );

  const data = useMemo(
    () => [...ctmsGrantAmendmentRequests],
    [ctmsGrantAmendmentRequests],
  );

  const rowSelection = useMemo(
    () =>
      data.reduce(
        (rowSelection: Record<string, boolean>, grant, index) => ({
          ...rowSelection,
          [index]: selection?.has(grant.id),
        }),
        {},
      ),
    [data, selection],
  );

  const table = useReactTable({
    columns,
    data,
    enableRowSelection: !disableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: (getSelection) => {
      if (typeof getSelection === "function") {
        const newRowSelection = getSelection(rowSelection);

        onSelectionChange?.(
          data.reduce((set, grant, index) => {
            if (newRowSelection[index]) {
              set.add(grant.id);
            }

            return set;
          }, new Set<string>()),
        );
      }
    },
    state: {
      rowSelection,
    },
  });

  if (ctmsGrantAmendmentRequests.length === 0) {
    return <NoticeMessage size="Large">{emptyListPlaceholder}</NoticeMessage>;
  }

  return (
    <Table.Smart
      borderlessContainer={borderless}
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequest,
            {
              ctmsGrantAmendmentRequestId: rowData.original.id,
              organizationId: organization.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      table={table}
      tableClassName={classNames("min-w-[720px]", {
        ["grid-cols-[1fr,1fr,1fr,1fr,100px]"]:
          disableRowSelection && showActionsColumn,
        ["grid-cols-[1fr,1fr,1fr,1fr]"]:
          disableRowSelection && !showActionsColumn,
        ["grid-cols-[40px,1fr,1fr,1fr,1fr,100px]"]:
          !disableRowSelection && showActionsColumn,
        ["grid-cols-[40px,1fr,1fr,1fr,1fr]"]:
          !disableRowSelection && !showActionsColumn,
      })}
      tableDisplay="grid"
    />
  );
};
