import classNames from "classnames";

const VARIANTS = {
  ["glass-green" as const]: "bg-glass-green-05",
  dark: "bg-gray-07",
  darkest: "bg-gray-09",
  default: "bg-gray-04",
  light: "bg-gray-03",
};

export const Divider: React.FC<{
  variant?: keyof typeof VARIANTS;
  withGradient?: boolean;
}> = ({ variant = "default", withGradient }) => {
  if (withGradient) {
    return (
      <hr
        className="z-10 h-[0.5px] border-none"
        style={{
          background:
            "linear-gradient(272deg, rgba(222, 225, 231, 0.00) 0%, #A6A6A6 51.56%, rgba(222, 225, 231, 0.00) 100%)",
        }}
      />
    );
  }
  return (
    <hr
      className={classNames("z-10 h-[0.5px] border-none", VARIANTS[variant])}
    />
  );
};
export const VerticalDivider: React.FC = () => (
  <div className="border-l-[0.5px] border-gray-04"></div>
);

export const NegativeDivider = () => (
  <hr className="!my-10 -mx-6 h-[0.5px] border-0 bg-gray-04 sm:-mx-10" />
);
