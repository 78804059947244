import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import MaintenanceInProgressErrorPage from "../pages/MaintenanceInProgressErrorPage";

export default function MaintenanceInProgressGuard({
  children,
}: React.PropsWithChildren) {
  const maintenanceInProgress = useIsFeatureEnabled("maintenance");

  if (!maintenanceInProgress) {
    return children;
  }

  return <MaintenanceInProgressErrorPage />;
}
