/**
 * @generated SignedSource<<589ac0be7e6193e9606d88ae2b5440e3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewVestingScheduleSlider_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SelectVestingScheduleInput_Organization">;
  readonly " $fragmentType": "NewVestingScheduleSlider_Organization";
};
export type NewVestingScheduleSlider_Organization$key = {
  readonly " $data"?: NewVestingScheduleSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewVestingScheduleSlider_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewVestingScheduleSlider_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectVestingScheduleInput_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d1a4eba122142ac8f4a3ece67ff30a98";

export default node;
