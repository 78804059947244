/**
 * @generated SignedSource<<2b4529b598c8108aaa41b528abc80f8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteGranteeEmailPreviewModal_Viewer$data = {
  readonly email: string;
  readonly fullName: string;
  readonly " $fragmentType": "InviteGranteeEmailPreviewModal_Viewer";
};
export type InviteGranteeEmailPreviewModal_Viewer$key = {
  readonly " $data"?: InviteGranteeEmailPreviewModal_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteeEmailPreviewModal_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteGranteeEmailPreviewModal_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "609e78623608370febb0854d416a810f";

export default node;
