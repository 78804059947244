import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../components/CountryFlag";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { NeedsAdditionalFormalitiesWarning } from "../../../../components/NeedsAdditionalFormalitiesWarning";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { GrantDetailsRow_Instrument$key } from "./__generated__/GrantDetailsRow_Instrument.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const INSTRUMENT_FRAGMENT = graphql`
  fragment GrantDetailsRow_Instrument on Instrument {
    needsAdditionalFormalities
    equityType @required(action: THROW) {
      name
      taxResidenceCountry {
        ...CountryFlag_Country
      }
    }
    ...NeedsAdditionalFormalitiesWarning_Instrument
  }
`;

export const GrantDetailsRow: React.FC<{
  exercisePrice: number;
  instrumentFragment: GrantDetailsRow_Instrument$key;
  quantityGranted: number;
}> = ({ exercisePrice, instrumentFragment, quantityGranted }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);

  return (
    <GrantReviewRow title="Grant details">
      <InformationRows>
        <InformationRows.Row label="Exercise price">
          <FormattedFMV value={exercisePrice} />
        </InformationRows.Row>
        <InformationRows.Row label="Total exercise price">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={exercisePrice * quantityGranted}
          />
        </InformationRows.Row>
        <InformationRows.Row label="Equity type">
          {instrument.equityType.name}{" "}
          <CountryFlag
            countryFragment={instrument.equityType.taxResidenceCountry}
          />
        </InformationRows.Row>
      </InformationRows>
      {instrument.needsAdditionalFormalities && (
        <NeedsAdditionalFormalitiesWarning instrumentFragment={instrument} />
      )}
    </GrantReviewRow>
  );
};
