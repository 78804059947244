/**
 * @generated SignedSource<<05c591a1098650b9a8897ab3bfa78c5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_Grantee_Grantee$data = {
  readonly name: string;
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteeGrantDetails_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GranteeEquity_Grantee" | "GranteeOwnership_Grantee" | "GranteePortalOverviewBeginnerInformation_Grantee" | "GranteePortalOverviewHeader_Grantee" | "GranteeVestingSchedule_Grantee" | "useGranteeGrantIndex_Grantee">;
  readonly " $fragmentType": "Overview_Grantee_Grantee";
};
export type Overview_Grantee_Grantee$key = {
  readonly " $data"?: Overview_Grantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_Grantee_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Overview_Grantee_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeGrantDetails_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeEquity_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeOwnership_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalOverviewHeader_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalOverviewBeginnerInformation_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteeGrantIndex_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeVestingSchedule_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "68b9535d7bb2c63cc6d4e0c825e38b46";

export default node;
