/**
 * @generated SignedSource<<f7d417410cebcc175218752e2d64d71e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalHydratedContentTab_Taxation_CTMSGrant$data = ReadonlyArray<{
  readonly hydratedPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_AdminPortalContent">;
  } | null;
  readonly id: string;
  readonly label: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_CTMSGrant">;
  readonly " $fragmentType": "GranteePortalHydratedContentTab_Taxation_CTMSGrant";
}>;
export type GranteePortalHydratedContentTab_Taxation_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: GranteePortalHydratedContentTab_Taxation_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalHydratedContentTab_Taxation_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteePortalHydratedContentTab_Taxation_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantRelatedHydratedContent_CTMSGrant"
    },
    {
      "alias": "hydratedPortalContent",
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "taxationGranteePortalContent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantRelatedHydratedContent_AdminPortalContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "33e6801dea00ead23bef704b84829988";

export default node;
