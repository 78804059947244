import { Typography } from "../ui/Typography";

export function Section({
  children,
  index,
  subtitle,
  title,
}: React.PropsWithChildren<{
  index?: React.ReactNode;
  subtitle: React.ReactNode;
  title: React.ReactNode;
}>) {
  return (
    <div className="space-y-6">
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          {index && (
            <div className="flex h-8 min-w-8 items-center justify-center rounded-lg bg-gray-03 p-0.5">
              <Typography as="div" variant="Medium/Default">
                {index}
              </Typography>
            </div>
          )}

          <SectionTitle subtitle={subtitle}>{title}</SectionTitle>
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
}

export function SectionTitle({
  children,
  subtitle,
}: React.PropsWithChildren<{
  subtitle?: React.ReactNode;
}>) {
  return (
    <div className="space-y-2">
      <Typography as="div" variant="Medium/Small">
        {children}
      </Typography>
      {subtitle && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {subtitle}
        </Typography>
      )}
    </div>
  );
}
