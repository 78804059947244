import { Divider } from "./ui/Divider";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const GraphTooltip: React.FC<{
  bottomContent?: React.ReactNode;
  children: React.ReactNode;
  topContent?: React.ReactNode;
}> = ({ bottomContent, children, topContent }) => {
  return (
    <RoundedBox className="space-y-6 !bg-black-07 p-6 text-white">
      {topContent ? (
        <>
          <Typography as="div" variant="Regular/Extra Small">
            {topContent}
          </Typography>
          <Divider variant="darkest" />
        </>
      ) : null}
      {children}
      {bottomContent && (
        <Typography as="div" variant="Regular/Caption">
          {bottomContent}
        </Typography>
      )}
    </RoundedBox>
  );
};
