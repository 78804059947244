/**
 * @generated SignedSource<<eddd4c3815bcf4079d9439e83eb8195a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BulkUploadDraftsXLSXResultErrorCode = "ERROR_CREATING_EASOP_GRANT" | "ERROR_PARSING_XLSX" | "ERROR_PARSING_XLSX_ROW" | "ERROR_UPDATING_GRANTEE" | "NOT_ENOUGH_SHARES_AVAILABLE";
export type BulkUploadDrafts_Mutation$variables = {
  base64XLSX: string;
  organizationId: string;
};
export type BulkUploadDrafts_Mutation$data = {
  readonly bulkUploadDraftsXLSX: {
    readonly easopGrantsCreated: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
    }>;
    readonly errors: ReadonlyArray<{
      readonly code: BulkUploadDraftsXLSXResultErrorCode;
      readonly error: string;
      readonly line: number;
    }>;
  };
};
export type BulkUploadDrafts_Mutation = {
  response: BulkUploadDrafts_Mutation$data;
  variables: BulkUploadDrafts_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "base64XLSX"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "base64XLSX",
        "variableName": "base64XLSX"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "BulkUploadDraftsXLSXResult",
    "kind": "LinkedField",
    "name": "bulkUploadDraftsXLSX",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "easopGrantsCreated",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BulkUploadDraftsXLSXResultError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "line",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BulkUploadDrafts_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BulkUploadDrafts_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9b1141abf3f95a08b39344136a7ffa79",
    "id": null,
    "metadata": {},
    "name": "BulkUploadDrafts_Mutation",
    "operationKind": "mutation",
    "text": "mutation BulkUploadDrafts_Mutation(\n  $organizationId: OrganizationId!\n  $base64XLSX: String!\n) {\n  bulkUploadDraftsXLSX(base64XLSX: $base64XLSX, organizationId: $organizationId) {\n    easopGrantsCreated {\n      id\n      label\n    }\n    errors {\n      line\n      code\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "53a7bc67af3d3467fb5f76d3b708624b";

export default node;
