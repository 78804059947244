/**
 * @generated SignedSource<<791bfc7de122b7aeaeae465ad485b788>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PreviewGranteesPortalModal_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Organization">;
  readonly " $fragmentType": "PreviewGranteesPortalModal_Organization";
};
export type PreviewGranteesPortalModal_Organization$key = {
  readonly " $data"?: PreviewGranteesPortalModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreviewGranteesPortalModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviewGranteesPortalModal_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "e802c7d548f356186e90dca4bf4f4d2a";

export default node;
