import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { usePTEPForm } from "../pages/Admin/AssistedGrant/PTEP";
import { BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument$key } from "./__generated__/BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument.graphql";
import { BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization$key } from "./__generated__/BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization.graphql";
import { SelectOrganizationPostTerminationExercisePeriod } from "./GrantForm/SelectOrganizationPostTerminationExercisePeriod";
import { FormRow } from "./ui/Form/FormRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization on Organization {
    ...SelectOrganizationPostTerminationExercisePeriod_Organization
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument on Instrument {
    isVirtual
  }
`;

export const BeginnerFlowPostTerminationExercisePeriodSelectionBlock: React.FC<{
  control: ReturnType<typeof usePTEPForm>["control"];
  disabled?: boolean;
  instrumentFragment: BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument$key;
  organizationFragment: BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization$key;
}> = ({ control, disabled, instrumentFragment, organizationFragment }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormRow.Form
      control={control}
      label="Indicate a period"
      name="postTerminationExercisePeriodId"
    >
      <SelectOrganizationPostTerminationExercisePeriod
        control={control}
        disabled={disabled}
        isVirtual={instrument?.isVirtual ?? false}
        name="postTerminationExercisePeriodId"
        organizationFragment={organization}
      />
    </FormRow.Form>
  );
};
