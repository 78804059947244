/**
 * @generated SignedSource<<188438bf021829375486fc29c016428c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsightNotAvailableBox_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoSlideOver_Viewer">;
  readonly " $fragmentType": "InsightNotAvailableBox_Viewer";
};
export type InsightNotAvailableBox_Viewer$key = {
  readonly " $data"?: InsightNotAvailableBox_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"InsightNotAvailableBox_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InsightNotAvailableBox_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoSlideOver_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "4e56ba012db9194be639932b7e343e43";

export default node;
