/**
 * @generated SignedSource<<b323c4809c0e4b02998623be2dd7445e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type HRISProviderEmployeeSuggestionReason = "SameEmail" | "SameName" | "SimilarName";
import { FragmentRefs } from "relay-runtime";
export type SynchronizeEmployeesDataItem_HRISProviderEmployee$data = {
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly suggestedGrantee: {
    readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_SynchronizeContent_Grantee" | "MergeEmployeeModal_Grantee">;
  };
  readonly suggestionReason: HRISProviderEmployeeSuggestionReason;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee" | "MergeEmployeeModal_HRISProviderEmployee" | "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee">;
  readonly " $fragmentType": "SynchronizeEmployeesDataItem_HRISProviderEmployee";
};
export type SynchronizeEmployeesDataItem_HRISProviderEmployee$key = {
  readonly " $data"?: SynchronizeEmployeesDataItem_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"SynchronizeEmployeesDataItem_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SynchronizeEmployeesDataItem_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeModal_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "suggestedGrantee",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CleanupGranteesItem_SynchronizeContent_Grantee"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MergeEmployeeModal_Grantee"
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "suggestionReason",
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "c47042d4c23b9f751b367815ebd1c423";

export default node;
