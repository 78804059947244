/**
 * @generated SignedSource<<95f27362e6ab7552e2cb6570dc683053>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeNameWithCountryFlag_Grantee$data = {
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  } | null;
  readonly " $fragmentType": "GranteeNameWithCountryFlag_Grantee";
};
export type GranteeNameWithCountryFlag_Grantee$key = {
  readonly " $data"?: GranteeNameWithCountryFlag_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeNameWithCountryFlag_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "99345b7bebb1002fa16acc952faaf1e6";

export default node;
