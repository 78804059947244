import classNames from "classnames";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useGranteePositionToEquityGridLevel } from "../../../../hooks/useGranteePositionToEquityGridLevel";
import { useOrganizationSharesUtil } from "../../../../hooks/useOrganizationSharesUtil";
import {
  GranteeDetailsEquityGridLevelTag_Grantee$data,
  GranteeDetailsEquityGridLevelTag_Grantee$key,
} from "./__generated__/GranteeDetailsEquityGridLevelTag_Grantee.graphql";
import { GranteeDetailsEquityGridLevelTag_Organization$key } from "./__generated__/GranteeDetailsEquityGridLevelTag_Organization.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeDetailsEquityGridLevelTag_Grantee on Grantee {
    equityGridLevel {
      name
    }
    ctmsGrants {
      __typename
    }
    name
    missingSharesToEquityGridLevel
    ...useGranteePositionToEquityGridLevel_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeDetailsEquityGridLevelTag_Organization on Organization {
    ...useOrganizationSharesUtil_Organization
  }
`;

const Style = {
  Color: {
    gray: "!bg-gray-02",
    green: "!bg-green-02",
    orange: "!bg-orange-02",
    primary: "!bg-primary-02",
  },
} as const;

const Tag: React.FC<
  React.ComponentProps<"div"> & {
    color: "gray" | "green" | "orange" | "primary";
  }
> = ({ children, className, color }) => {
  return (
    <RoundedBox
      className={classNames(
        "px-4 py-2 text-black-07",
        Style.Color[color],
        className,
      )}
    >
      <Typography className="flex gap-2" variant="Medium/Extra Small">
        {children}
      </Typography>
    </RoundedBox>
  );
};

export const GranteeDetailsEquityGridLevelTag: React.FC<
  {
    className?: string;
    grantedSharesInDraft: number;
    granteeFragment: GranteeDetailsEquityGridLevelTag_Grantee$key;
    organizationFragment: GranteeDetailsEquityGridLevelTag_Organization$key;
  } & (
    | {
        onAddEquityLevelClick: (
          grantee: GranteeDetailsEquityGridLevelTag_Grantee$data,
        ) => void;
        onCreateTopUpClick: () => void;
        showActionButtons: true;
      }
    | {
        onAddEquityLevelClick?: (
          grantee: GranteeDetailsEquityGridLevelTag_Grantee$data,
        ) => void;
        onCreateTopUpClick?: () => void;
        showActionButtons: false;
      }
  )
> = ({
  className,
  grantedSharesInDraft,
  granteeFragment,
  onAddEquityLevelClick,
  onCreateTopUpClick,
  organizationFragment,
  showActionButtons,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const granteeEquityGridLevel = grantee.equityGridLevel;

  const hasExistingGrant =
    grantee.ctmsGrants.length > 0 || !!grantedSharesInDraft;

  const granteePositionToEquityGridLevel = useGranteePositionToEquityGridLevel({
    grantedSharesInDraft,
    granteeFragment: grantee,
  });

  const { missingShares, missingValue } = useMemo(() => {
    if (grantee.missingSharesToEquityGridLevel === null) {
      return { missingShares: null, missingValue: null };
    }

    const missingShares = Math.abs(grantee.missingSharesToEquityGridLevel);

    return {
      missingShares,
      missingValue: sharesToValue(missingShares),
    };
  }, [grantee.missingSharesToEquityGridLevel, sharesToValue]);

  if (granteePositionToEquityGridLevel === null) return null;

  switch (granteePositionToEquityGridLevel) {
    case "ABOVE_LEVEL":
      return (
        <Tag className={className} color="green">
          <span>🚀</span>
          <span>Above equity grid level.</span>
        </Tag>
      );
    case "BELOW_LEVEL":
      return (
        <Tag className={className} color="orange">
          <span>👇</span>
          <span>
            Below equity grid level.
            {showActionButtons && missingShares !== null ? (
              <>
                &nbsp;As an {granteeEquityGridLevel?.name} equity level the
                grantee is missing&nbsp;
                <FormattedNumber value={missingShares} />
                &nbsp;shares
                {missingValue !== null && (
                  <>
                    {" "}
                    (
                    <FormattedNumber
                      currency="USD"
                      maximumFractionDigits={0}
                      style="currency"
                      value={missingValue}
                    />
                    )
                  </>
                )}
                .&nbsp;
                <button
                  className="font-medium text-primary-04"
                  onClick={onCreateTopUpClick}
                  type="button"
                >
                  {hasExistingGrant ? "Make a top-up grant" : "Make a grant"}
                </button>
                &nbsp;to {grantee.name}?
              </>
            ) : null}
          </span>
        </Tag>
      );
    case "NO_LEVEL":
      return (
        <Tag className={className} color="gray">
          <span>ℹ️</span>
          <span>
            {grantee.name} has no equity grid level.
            {showActionButtons ? (
              <>
                &nbsp;
                <button
                  className="font-medium text-primary-04"
                  onClick={() => onAddEquityLevelClick(grantee)}
                  type="button"
                >
                  Add an equity grid level
                </button>
                &nbsp;to {grantee.name}?
              </>
            ) : null}
          </span>
        </Tag>
      );
    case "OFF_GRID":
      return (
        <Tag className={className} color="primary">
          <span>📌</span>
          <span>{grantee.name} is marked as off grid.</span>
        </Tag>
      );
    case "RIGHT_ON_LEVEL":
      return (
        <Tag className={className} color="green">
          <span>👌</span>
          <span>Right on equity grid level.</span>
        </Tag>
      );
  }
};
