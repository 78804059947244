import { LinkIcon } from "@heroicons/react/24/outline";
import { useCallback, useTransition } from "react";
import { graphql } from "relay-runtime";

import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Modal } from "../../../../components/ui/Modal";
import { Toast } from "../../../../components/ui/Toast";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { BoardConsentSignatureLinkModal_Query } from "./__generated__/BoardConsentSignatureLinkModal_Query.graphql";

const QUERY = graphql`
  query BoardConsentSignatureLinkModal_Query($signatureRequestId: UUID!) {
    signatureRequest(id: $signatureRequestId) @required(action: THROW) {
      boardMember @required(action: THROW) {
        account {
          fullName
        }
      }
      pandadocSignatureSessionURL(urlLifetimeInSeconds: 604800) {
        ... on PandadocSignatureSessionURLSuccessOutput {
          url
        }
      }
    }
  }
`;

function ModalContent({
  onClose,
  signatureRequestId,
}: {
  onClose: () => void;
  signatureRequestId: string;
}) {
  const { query } = useQuery<BoardConsentSignatureLinkModal_Query>(QUERY, {
    signatureRequestId,
  });

  const toaster = useToaster();

  const handleCopyLinkToClipboardButtonClick = useCallback(
    async (url: string) => {
      await navigator.clipboard.writeText(url);

      onClose();

      toaster.push(
        <Toast title="Link copied to clipboard">
          You can now forward the link to{" "}
          {query.signatureRequest.boardMember.account.fullName} to sign the
          board consent
        </Toast>,
      );
    },
    [onClose, query.signatureRequest.boardMember.account.fullName, toaster],
  );

  const pandadocSignatureSessionURL =
    query.signatureRequest.pandadocSignatureSessionURL.url;

  if (!pandadocSignatureSessionURL) {
    return (
      <Modal.Content
        actionsInHeader={
          <Modal.ActionButton onClick={onClose} variant="Secondary Full">
            OK
          </Modal.ActionButton>
        }
        onClose={onClose}
        subTitle={
          <>
            We are really sorry, our e-signature provider is experiencing issues
            at the moment. Please try again in a moment.
          </>
        }
      />
    );
  }

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.ActionButton
          onClick={() =>
            handleCopyLinkToClipboardButtonClick(pandadocSignatureSessionURL)
          }
        >
          Copy link to clipboard
        </Modal.ActionButton>
      }
      onClose={onClose}
      subTitle={
        <>
          Your board member{" "}
          {query.signatureRequest.boardMember.account.fullName} can access their
          document to sign directly at that link
        </>
      }
      title="Share the board consent link directly"
    >
      <Typography
        className="space-y-2 py-4 text-black-05"
        variant="Regular/Extra Small"
      >
        <div className="select-all rounded border-[0.5px] border-gray-07 px-4 py-2">
          {pandadocSignatureSessionURL}
        </div>
        <div>
          Please be careful as this link is unique to{" "}
          {query.signatureRequest.boardMember.account.fullName} and only valid
          for 7 days!
        </div>
      </Typography>
    </Modal.Content>
  );
}

export const BoardConsentSignatureLinkModal = ({
  signatureRequestId,
}: {
  signatureRequestId: string;
}) => {
  const {
    setFalse: closeModal,
    setTrue: openModal,
    value: modalIsOpen,
  } = useBoolean(false);
  const [modalIsOpening, startOpenModalTransition] = useTransition();

  function handleButtonClick() {
    startOpenModalTransition(() => {
      openModal();
    });
  }

  return (
    <>
      <TooltipContainer tooltipContent="Click to copy the direct link to the board consent">
        <Button
          className="whitespace-nowrap"
          leftIcon={<LinkIcon />}
          loading={modalIsOpening}
          onClick={handleButtonClick}
          size="extra small"
          variant="Secondary Full"
        />
      </TooltipContainer>
      <Modal onClose={closeModal} show={modalIsOpen} width={768}>
        <ModalContent
          onClose={closeModal}
          signatureRequestId={signatureRequestId}
        />
      </Modal>
    </>
  );
};
