/**
 * @generated SignedSource<<d60695136024710ae38781351bb696e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodGraph_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ctmsGrants: ReadonlyArray<{
          readonly activePostTerminationExercisePeriod: {
            readonly value: {
              readonly duration: number;
              readonly unit: DurationUnit;
            };
          } | null;
        }>;
        readonly id: string;
        readonly status: GranteeStatus;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GranteesListSlideOver_Organization" | "InsightNotAvailableBox_Organization">;
  readonly " $fragmentType": "PostTerminationExercisePeriodGraph_Organization";
};
export type PostTerminationExercisePeriodGraph_Organization$key = {
  readonly " $data"?: PostTerminationExercisePeriodGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationExercisePeriodGraph_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "grantStatusIn",
                      "value": [
                        "Active"
                      ]
                    }
                  ],
                  "concreteType": "CTMSGrant",
                  "kind": "LinkedField",
                  "name": "ctmsGrants",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ActivePostTerminationExercisePeriod",
                      "kind": "LinkedField",
                      "name": "activePostTerminationExercisePeriod",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "PostTerminationExercisePeriod",
                          "kind": "LinkedField",
                          "name": "value",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "duration",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "unit",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesListSlideOver_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InsightNotAvailableBox_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b850df349ba622e29c456b5788d8abf6";

export default node;
