/**
 * @generated SignedSource<<d8d5ef7bb00758a1971ea9e0d9be5b1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderwaterGrants_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoSlideOver_Viewer">;
  readonly " $fragmentType": "UnderwaterGrants_Viewer";
};
export type UnderwaterGrants_Viewer$key = {
  readonly " $data"?: UnderwaterGrants_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrants_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderwaterGrants_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoSlideOver_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "9bcafada80b9d74cd0afb6a7aca7c439";

export default node;
