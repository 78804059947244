import { useEffect } from "react";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../hooks/useSafeMutation";
import {
  AdminPage,
  useSetAdminPageVisited_Mutation,
} from "./__generated__/useSetAdminPageVisited_Mutation.graphql";

const MUTATION = graphql`
  mutation useSetAdminPageVisited_Mutation(
    $organizationId: OrganizationId!
    $page: AdminPage!
  ) {
    setOrganizationAdminPageVisited(
      organizationId: $organizationId
      page: $page
    ) {
      pageHasBeenVisited(page: $page)
    }
  }
`;

export const useSetAdminPageVisited = ({
  organizationId,
  page,
}: {
  organizationId: string;
  page: AdminPage;
}) => {
  const [triggerSetAdminPageVisitedMutation] =
    useSafeMutation<useSetAdminPageVisited_Mutation>(MUTATION);

  useEffect(() => {
    void triggerSetAdminPageVisitedMutation({
      variables: {
        organizationId,
        page,
      },
    });
  }, [organizationId, page, triggerSetAdminPageVisitedMutation]);
};
