import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { SynchronizeEmployeesData_Organization$key } from "./__generated__/SynchronizeEmployeesData_Organization.graphql";
import { SynchronizeEmployeesData_Query } from "./__generated__/SynchronizeEmployeesData_Query.graphql";
import { SynchronizeEmployeesDataItem } from "./SynchronizeEmployeesDataItem";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SynchronizeEmployeesData_Organization on Organization {
    id
    name
    HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee {
      id
      ...SynchronizeEmployeesDataItem_HRISProviderEmployee
    }
    ...SynchronizeEmployeesDataItem_Organization
  }
`;

const SynchronizeEmployeesData_: React.FC<{
  organizationFragment: SynchronizeEmployeesData_Organization$key;
  refreshQuery: () => void;
}> = ({ organizationFragment, refreshQuery }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const applicationName = useApplicationName();

  return (
    <>
      <CenteredColumnLayout
        breadcrumbs={
          <BreadCrumb>
            <BreadCrumb.Link
              to={generatePath(APPLICATION_ROUTES["organizationHome"], {
                organizationId: organization.id,
              })}
            >
              {organization.name}
            </BreadCrumb.Link>
            <BreadCrumb.Link to="../..">Settings</BreadCrumb.Link>
            <BreadCrumb.Link to="..">Clean up grantees</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Synchronize employees data</BreadCrumb.Link>
          </BreadCrumb>
        }
        fullHeight
        showFooter
      >
        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="space-y-2">
              <Typography as="div" variant="Medium/Default">
                Match data synced with existing grantees
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Prevent duplicates by merging {applicationName} grantees data
                with employees from your other HR solutions
              </Typography>
            </div>
          </div>
          {organization.HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee.map(
            (employee, index) => {
              return (
                <SynchronizeEmployeesDataItem
                  hrisProviderEmployeeFragment={employee}
                  key={employee.id}
                  listIndex={index}
                  listLength={
                    organization
                      .HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
                      .length
                  }
                  onDataMutation={() => {
                    refreshQuery();
                  }}
                  organizationFragment={organization}
                />
              );
            },
          )}
        </div>
      </CenteredColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query SynchronizeEmployeesData_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee {
        __typename
      }
      ...SynchronizeEmployeesData_Organization
    }
  }
`;

const SynchronizeEmployeesDataPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<SynchronizeEmployeesData_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  const [searchParams] = useSearchParams();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (
    query.organization
      .HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee.length === 0
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: generatePath(
            APPLICATION_ROUTES["organizationSettingsCleanupGrantees"],
            {
              organizationId: query.organization.id,
            },
          ),
          search: searchParams.toString(),
        }}
      />
    );
  }

  return (
    <Page
      analyticsName="Admin - Clean Up - Synchronize"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} synchronize employees data`}
    >
      <SynchronizeEmployeesData_
        organizationFragment={query.organization}
        refreshQuery={refreshQuery}
      />
    </Page>
  );
};

export default SynchronizeEmployeesDataPage;
