/**
 * @generated SignedSource<<2ae352589ec2cbea29004c061d34e0d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationPostTerminationExercisePeriod_Organization">;
  readonly " $fragmentType": "EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization";
};
export type EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization$key = {
  readonly " $data"?: EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationPostTerminationExercisePeriod_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "33e75f24916192d04099be09c2a4d9b6";

export default node;
