import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { compact, sortBy } from "lodash";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useTailwindActiveBreakpoints } from "../../hooks/useTailwindActiveBreakpoints";
import { FormattedPercentage } from "../Formatted/FormattedPercentage";
import { Table } from "../ui/Table";
import { Typography } from "../ui/Typography";
import {
  BoardMembersEquityGridLevelsTable_Organization$data,
  BoardMembersEquityGridLevelsTable_Organization$key,
} from "./__generated__/BoardMembersEquityGridLevelsTable_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardMembersEquityGridLevelsTable_Organization on Organization {
    equityGrid {
      levels {
        name
        role
        yearlySalary
        equityInUsd
        equityInShares
        ownership
      }
    }
  }
`;

type EquityGridLevel =
  BoardMembersEquityGridLevelsTable_Organization$data["equityGrid"]["levels"][number];

const LevelNameLabel: React.FC<{
  equityGridLevel: EquityGridLevel;
}> = ({ equityGridLevel }) => (
  <Typography as="div" className="text-primary-06" variant="Medium/Extra Small">
    {equityGridLevel.name}
  </Typography>
);

const columnHelper = createColumnHelper<EquityGridLevel>();

export const BoardMembersEquityGridLevelsTable: React.FC<{
  organizationFragment: BoardMembersEquityGridLevelsTable_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const equityGrid = organization.equityGrid;

  const equityGridLevels = useMemo(
    () =>
      equityGrid.levels
        ? sortBy(equityGrid.levels, (level) => level.equityInUsd)
        : [],
    [equityGrid.levels],
  );

  const { isXs } = useTailwindActiveBreakpoints();

  const columns = useMemo(
    () =>
      compact([
        isXs
          ? null
          : columnHelper.accessor((row) => row.name, {
              cell: (context) => (
                <LevelNameLabel equityGridLevel={context.row.original} />
              ),
              header: () => "Level",
              id: "level",
            }),
        columnHelper.accessor((row) => row.role || "-", {
          cell: (context) =>
            isXs ? (
              <div className="flex flex-col gap-4">
                <LevelNameLabel equityGridLevel={context.row.original} />
                <span>{context.getValue()}</span>
              </div>
            ) : (
              context.getValue()
            ),
          header: () => "Role",
          id: "role",
        }),
        columnHelper.accessor((row) => row.equityInUsd, {
          cell: (context) => {
            const level = context.row.original;
            const equityInUsd = context.getValue();
            return (
              <Typography
                as="div"
                className="flex flex-col gap-1"
                variant="Medium/Extra Small"
              >
                {level.ownership !== null && (
                  <span>
                    <FormattedPercentage value={level.ownership} />
                  </span>
                )}
                <span>
                  <FormattedNumber
                    currency="USD"
                    maximumFractionDigits={0}
                    style="currency"
                    value={equityInUsd}
                  />
                </span>
                {level.equityInShares !== null && (
                  <Typography
                    className="text-black-05"
                    variant="Regular/Extra Small"
                  >
                    <FormattedNumber value={level.equityInShares} /> shares
                  </Typography>
                )}
              </Typography>
            );
          },
          header: () => "Value equity",
          id: "equity",
        }),
        columnHelper.accessor((row) => row.yearlySalary, {
          cell: (context) => {
            const yearlySalary = context.getValue();

            return yearlySalary ? (
              <FormattedNumber
                currency="USD"
                style="currency"
                value={yearlySalary}
              />
            ) : (
              "-"
            );
          },
          header: () => "Yearly Salary",
          id: "yearly_salary",
        }),
      ]),
    [isXs],
  );

  const data = useMemo(
    () => [...equityGridLevels.filter((level) => !!level)],
    [equityGridLevels],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return <Table.Smart table={table} />;
};
