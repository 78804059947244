/**
 * @generated SignedSource<<5617ad075f3e156534a44974e7beec0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_CTMSGrantAmendmentRequests_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "PendingApproval_CTMSGrantAmendmentRequests_Viewer";
};
export type PendingApproval_CTMSGrantAmendmentRequests_Viewer$key = {
  readonly " $data"?: PendingApproval_CTMSGrantAmendmentRequests_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_CTMSGrantAmendmentRequests_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PendingApproval_CTMSGrantAmendmentRequests_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "88ee4fa57ec5bc18aeee06416313678d";

export default node;
