/**
 * @generated SignedSource<<58d2ff85ba790b6b493c9aad6907dea4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoPage_Organization$data = {
  readonly remoteEquityIsOrganizationCartaLawFirm: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Organization" | "TestLawFirmCartaConnectionView_Organization">;
  readonly " $fragmentType": "BookADemoPage_Organization";
};
export type BookADemoPage_Organization$key = {
  readonly " $data"?: BookADemoPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoPage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityIsOrganizationCartaLawFirm",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestLawFirmCartaConnectionView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookADemoView_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "83008a931d97d1d6b235fc2279d23444";

export default node;
