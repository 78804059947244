import { useSubscription } from "react-relay";
import { Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { ApplicationThemeProvider } from "../../hooks/useApplicationTheme";
import { useQuery } from "../../hooks/useQuery";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { useOrganizationIdParam } from "../../paths";
import { OrganizationLayout_Query } from "./__generated__/OrganizationLayout_Query.graphql";
import { OrganizationLayout_Subscription } from "./__generated__/OrganizationLayout_Subscription.graphql";
import { SetupLoadingSplash } from "./SetupLoadingSplash/SetupLoadingSplash";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment OrganizationLayout_Organization on Organization {
    setupIsComplete
  }
`;

const QUERY = graphql`
  query OrganizationLayout_Query($id: OrganizationId!) {
    organization(id: $id) {
      ...OrganizationLayout_Organization @relay(mask: false)
      ...useApplicationTheme_Organization
    }
  }
`;

const SUBSCRIPTION = graphql`
  subscription OrganizationLayout_Subscription($id: OrganizationId!) {
    organizationUpdates(id: $id) {
      ...OrganizationLayout_Organization @relay(mask: false)
    }
  }
`;

const OrganizationLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationLayout_Query>(QUERY, {
    id: organizationId,
  });

  useSubscription<OrganizationLayout_Subscription>({
    subscription: SUBSCRIPTION,
    variables: {
      id: organizationId,
    },
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (!query.organization.setupIsComplete) {
    return (
      <ApplicationThemeProvider organizationFragment={query.organization}>
        <SetupLoadingSplash />
      </ApplicationThemeProvider>
    );
  }

  return (
    <ApplicationThemeProvider organizationFragment={query.organization}>
      <Outlet />
    </ApplicationThemeProvider>
  );
};

export default OrganizationLayout;
