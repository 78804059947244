/**
 * @generated SignedSource<<a14a11333cfc7882536d62e31c1da354>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Approvals_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_Organization" | "BoardMemberApprovalsTables_Organization">;
  readonly " $fragmentType": "Approvals_Organization";
};
export type Approvals_Organization$key = {
  readonly " $data"?: Approvals_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Approvals_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Approvals_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalSlideOverContent_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMemberApprovalsTables_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "48b5d639afb8892b38e08d51f73b8539";

export default node;
