/**
 * @generated SignedSource<<40561a2ea29e2778681b8c9061cfaf48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestDetails_CTMSExerciseRequest$data = {
  readonly ctmsGrant: {
    readonly grantee: {
      readonly workRelationship: WorkRelationship | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_CTMSGrant" | "ExerciseRequestGranteeSection_CTMSGrant" | "ExerciseRequestNotifySection_CTMSGrant" | "ExerciseRequestTaxTreatmentSection_CTMSGrant">;
  };
  readonly exercisePrice: number;
  readonly fairMarketValueAtExercise: {
    readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_FairMarketValueAtExercise" | "ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise">;
  };
  readonly fundsTransferredAt: string | null;
  readonly id: string;
  readonly quantityExercised: number;
  readonly requestSubmittedAt: string;
  readonly spread: number;
  readonly taxResidenceCountryIsKnown: boolean;
  readonly totalExercisePrice: number;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovedSection_CTMSExerciseRequest" | "ExerciseRequestCompletedSection_CTMSExerciseRequest" | "ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest">;
  readonly " $fragmentType": "ExerciseRequestDetails_CTMSExerciseRequest";
};
export type ExerciseRequestDetails_CTMSExerciseRequest$key = {
  readonly " $data"?: ExerciseRequestDetails_CTMSExerciseRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestDetails_CTMSExerciseRequest">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestDetails_CTMSExerciseRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spread",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestSubmittedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxResidenceCountryIsKnown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fundsTransferredAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestGranteeSection_CTMSGrant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestTaxTreatmentSection_CTMSGrant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestApprovalSection_CTMSGrant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestNotifySection_CTMSGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueAtExercise",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestTaxTreatmentSection_FairMarketValueAtExercise"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExerciseRequestNotifySection_FairMarketValueAtExercise"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestApprovedSection_CTMSExerciseRequest"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestCompletedSection_CTMSExerciseRequest"
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "eb045646e27681dbd6f8f3b0bc7e4147";

export default node;
