import { ExerciseStatus } from "./__generated__/CtmsGrantDetailsExercise_CTMSGrant.graphql";
import { Tag } from "./ui/Tag";

const EXERCISE_STATUS_TAGS: Record<ExerciseStatus, React.ReactNode> = {
  FullyExercised: <Tag color="green">Fully exercised</Tag>,
  NotExercised: <Tag color="gray">Not exercised</Tag>,
  PartiallyExercised: <Tag color="purple">Partially exercised</Tag>,
};

export const ExerciseStatusTag: React.FC<{
  exerciseStatus: ExerciseStatus;
}> = ({ exerciseStatus }) => EXERCISE_STATUS_TAGS[exerciseStatus];
