import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { useAccountRoles_Account$key } from "./__generated__/useAccountRoles_Account.graphql";
import { useAccountRoles_Organization$key } from "./__generated__/useAccountRoles_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useAccountRoles_Organization on Organization {
    id
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment useAccountRoles_Account on Account {
    organizationsAsAdmin {
      id
    }
    boardMembers {
      id
      organization {
        id
      }
    }
  }
`;

export const useAccountRoles = ({
  account,
  activeOrganization,
}: {
  account: useAccountRoles_Account$key;
  activeOrganization: useAccountRoles_Organization$key;
}) => {
  const activeOrganizationFragment = useFragment(
    ORGANIZATION_FRAGMENT,
    activeOrganization,
  );
  const accountFragment = useFragment(ACCOUNT_FRAGMENT, account);

  const activeOrganizationBoardMemberRole = useMemo(
    () =>
      accountFragment.boardMembers.find(
        (boardMember) =>
          boardMember.organization.id === activeOrganizationFragment.id,
      ),
    [accountFragment.boardMembers, activeOrganizationFragment.id],
  );

  const activeOrganizationAdminRole = useMemo(
    () =>
      accountFragment.organizationsAsAdmin.find(
        (organization) => organization.id === activeOrganizationFragment.id,
      ),
    [accountFragment.organizationsAsAdmin, activeOrganizationFragment.id],
  );

  const activeOrganizationBoardMemberRolePath = useMemo(
    () =>
      activeOrganizationBoardMemberRole
        ? generatePath(APPLICATION_ROUTES["boardMemberOverview"], {
            boardMemberId: activeOrganizationBoardMemberRole.id,
          })
        : null,
    [activeOrganizationBoardMemberRole],
  );

  const activeOrganizationHomePath = useMemo(
    () =>
      generatePath(APPLICATION_ROUTES["organizationHome"], {
        organizationId: activeOrganizationFragment.id,
      }),
    [activeOrganizationFragment.id],
  );

  const accountBoardMemberRoleInOtherOrganizations = useMemo(
    () =>
      accountFragment.boardMembers.filter(
        (boardMember) =>
          boardMember.organization.id !== activeOrganizationFragment.id,
      ),
    [accountFragment.boardMembers, activeOrganizationFragment.id],
  );

  const accountAdminRoleInOtherOrganizations = useMemo(
    () =>
      accountFragment.organizationsAsAdmin.filter(
        (organization) => organization.id !== activeOrganizationFragment.id,
      ),
    [accountFragment.organizationsAsAdmin, activeOrganizationFragment.id],
  );

  const accountHasRoleInOtherOrganizations = useMemo(
    () =>
      accountBoardMemberRoleInOtherOrganizations.length > 0 ||
      accountAdminRoleInOtherOrganizations.length > 0,
    [
      accountAdminRoleInOtherOrganizations.length,
      accountBoardMemberRoleInOtherOrganizations.length,
    ],
  );

  return {
    accountHasRoleInOtherOrganizations,
    activeOrganizationAdminRole,
    activeOrganizationBoardMemberRole,
    activeOrganizationBoardMemberRolePath,
    activeOrganizationHomePath,
  };
};
