import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";

const formSchema = z.object({
  waiveCliff: z.boolean(),
});

function WaiveCliffSlider({
  onClose,
  onExited,
  onWaiveCliffChange,
  show,
  waiveCliff,
}: {
  onClose: () => void;
  onExited?: () => void;
  onWaiveCliffChange: (props: { waiveCliff: boolean }) => void;
  show: boolean;
  waiveCliff: boolean | null;
}) {
  const { control, formState, handleSubmit } = useForm({
    defaultValues: { waiveCliff: waiveCliff ?? undefined },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onWaiveCliffChange(formSchema.parse(data));
    onClose();
  });

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Cliff
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <form onSubmit={onSubmit}>
        <div className="space-y-6 p-6">
          <div className="flex items-center justify-between">
            <Typography variant="Medium/Extra Small">
              Waive the cliff
            </Typography>
            <Toggle.Form control={control} name="waiveCliff" size="small" />
          </div>
        </div>
        <Divider />
        <div className="space-y-4 px-6 py-10">
          <div className="flex items-center justify-end gap-2">
            <Button
              disabled={formState.isSubmitting}
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button loading={formState.isSubmitting} size="small" type="submit">
              Save
            </Button>
          </div>
          <Typography
            as="div"
            className="text-right text-black-05"
            variant="Regular/Caption"
          >
            Amending a grant will always require the approval from the board.
          </Typography>
        </div>
      </form>
    </SlideOver>
  );
}

export const WaiveCliffSliderRemote = makeRemoteController<{
  onWaiveCliffChange: (props: { waiveCliff: boolean }) => void;
  waiveCliff: boolean | null;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <WaiveCliffSlider
        onClose={close}
        onExited={reset}
        onWaiveCliffChange={state.data.onWaiveCliffChange}
        show={state.show}
        waiveCliff={state.data.waiveCliff}
      />
    );
  },
});
