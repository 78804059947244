import { createContext, useContext } from "react";
import { graphql, useFragment } from "react-relay";

import { useApplicationTheme_Organization$key } from "./__generated__/useApplicationTheme_Organization.graphql";

type ApplicationTheme = "easop" | "remote equity";

const ApplicationThemeContext = createContext<ApplicationTheme | null>(null);

export function useApplicationTheme() {
  return useContext(ApplicationThemeContext);
}

const ORGANIZATION_FRAGMENT = graphql`
  fragment useApplicationTheme_Organization on IOrganization {
    useRemoteEquityTheme
  }
`;

export function ApplicationThemeProvider({
  children,
  organizationFragment,
}: React.PropsWithChildren<{
  organizationFragment: useApplicationTheme_Organization$key;
}>) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <ApplicationThemeContext.Provider
      value={organization.useRemoteEquityTheme ? "remote equity" : "easop"}
    >
      {children}
    </ApplicationThemeContext.Provider>
  );
}

export function useApplicationName() {
  const theme = useApplicationTheme();

  switch (theme) {
    case "easop":
    case null:
      return "Easop";
    case "remote equity":
      return "Remote Equity";
  }
}

export function useApplicationSupportEmailAddress() {
  const theme = useApplicationTheme();

  switch (theme) {
    case "easop":
    case null:
      return "support@easop.com";
    case "remote equity":
      return "equity-support@remote.com";
  }
}
