import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useDownloadInstrumentDocumentsPreview } from "../hooks/useDownloadInstrumentDocumentsPreview";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../services/workRelationship";
import { GeographiesDocumentsTable_DownloadDocumentsItem_EquityType$key } from "./__generated__/GeographiesDocumentsTable_DownloadDocumentsItem_EquityType.graphql";
import { GeographiesDocumentsTable_DownloadDocumentsItem_Instrument$key } from "./__generated__/GeographiesDocumentsTable_DownloadDocumentsItem_Instrument.graphql";
import { GeographiesDocumentsTable_DownloadDocumentsItem_Organization$key } from "./__generated__/GeographiesDocumentsTable_DownloadDocumentsItem_Organization.graphql";
import {
  GeographiesDocumentsTable_Organization$data,
  GeographiesDocumentsTable_Organization$key,
} from "./__generated__/GeographiesDocumentsTable_Organization.graphql";
import { CountryFlag } from "./CountryFlag";
import { Button } from "./ui/Button";
import { RoundedBox } from "./ui/RoundedBox";
import { Table } from "./ui/Table";
import { Typography } from "./ui/Typography";

const EQUITY_TYPE_ITEM_FRAGMENT = graphql`
  fragment GeographiesDocumentsTable_DownloadDocumentsItem_EquityType on EquityType {
    name
  }
`;

const INSTRUMENT_ITEM_FRAGMENT = graphql`
  fragment GeographiesDocumentsTable_DownloadDocumentsItem_Instrument on Instrument {
    workRelationship
    ...useDownloadInstrumentDocumentsPreview_Instrument
  }
`;

const ORGANIZATION_ITEM_FRAGMENT = graphql`
  fragment GeographiesDocumentsTable_DownloadDocumentsItem_Organization on Organization {
    ...useDownloadInstrumentDocumentsPreview_Organization
  }
`;

const DownloadDocumentsItem: React.FC<{
  className?: string;
  equityTypeFragment: GeographiesDocumentsTable_DownloadDocumentsItem_EquityType$key;
  instrumentFragment: GeographiesDocumentsTable_DownloadDocumentsItem_Instrument$key;
  organizationFragment: GeographiesDocumentsTable_DownloadDocumentsItem_Organization$key;
}> = ({
  className,
  equityTypeFragment,
  instrumentFragment,
  organizationFragment,
}) => {
  const equityType = useFragment(EQUITY_TYPE_ITEM_FRAGMENT, equityTypeFragment);
  const instrument = useFragment(INSTRUMENT_ITEM_FRAGMENT, instrumentFragment);
  const organization = useFragment(
    ORGANIZATION_ITEM_FRAGMENT,
    organizationFragment,
  );

  const { download, downloadIsInFlight, isDownloadAllowed } =
    useDownloadInstrumentDocumentsPreview({
      instrumentFragment: instrument,
      organizationFragment: organization,
    });

  return (
    <RoundedBox
      className={classNames(
        "flex items-center justify-between gap-2 !rounded p-4",
        className,
      )}
      withBorder
    >
      <div className="flex min-w-0 flex-col">
        <Typography className="truncate" variant="Medium/Extra Small">
          {
            WORK_RELATIONSHIP_TO_LABEL_HELPER[instrument.workRelationship]
              .pluralLabel
          }
        </Typography>
        <Typography className="text-black-05" variant="Medium/Caption">
          {equityType.name}
        </Typography>
      </div>
      {isDownloadAllowed && (
        <Button
          className="shrink-0"
          leftIcon={<ArrowDownTrayIcon />}
          loading={downloadIsInFlight}
          onClick={download}
          size="extra small"
          variant="Secondary Full"
        />
      )}
    </RoundedBox>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment GeographiesDocumentsTable_Organization on Organization {
    organizationGeographies {
      country {
        name
        ...CountryFlag_Country
      }
      unlockedEquityTypes {
        id
        name
        taxFavored
        ...GeographiesDocumentsTable_DownloadDocumentsItem_EquityType
        instruments {
          id
          ...GeographiesDocumentsTable_DownloadDocumentsItem_Instrument
        }
      }
    }
    ...GeographiesDocumentsTable_DownloadDocumentsItem_Organization
  }
`;

type OrganizationGeography =
  GeographiesDocumentsTable_Organization$data["organizationGeographies"][number];

const columnHelper = createColumnHelper<OrganizationGeography>();

export const GeographiesDocumentsTable: React.FC<{
  organizationFragment: GeographiesDocumentsTable_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const getUnlockedEquityTypesNames = useCallback(
    (organizationGeography: OrganizationGeography) =>
      _(organizationGeography.unlockedEquityTypes)
        .sortBy((equityType) => equityType.taxFavored)
        .reverse()
        .map((equityType) => equityType.name)
        .join(", "),
    [],
  );

  const getAllInstrumentsWithEquityTypeForOrganizationGeography = useCallback(
    (organizationGeography: OrganizationGeography) =>
      _(organizationGeography.unlockedEquityTypes)
        .sortBy((equityType) => equityType.taxFavored)
        .reverse()
        .flatMap((equityType) =>
          equityType.instruments.map((instrument) => ({
            equityType,
            instrument,
          })),
        )
        .value(),
    [],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.country.name, {
        cell: (context) => {
          const organizationGeography = context.row.original;
          return (
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Large">
                <CountryFlag countryFragment={organizationGeography.country} />
              </Typography>
              <div className="space-y-1">
                <Typography as="div" variant="Medium/Extra Small">
                  {organizationGeography.country.name}
                </Typography>
                <Typography
                  as="div"
                  className="text-black-05"
                  variant="Regular/Caption"
                >
                  {getUnlockedEquityTypesNames(organizationGeography)}
                </Typography>
              </div>
            </div>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Country",
        id: "country",
      }),
      columnHelper.accessor((row) => row, {
        cell: (context) => {
          const organizationGeography = context.getValue();
          const instrumentsWithEquityType =
            getAllInstrumentsWithEquityTypeForOrganizationGeography(
              organizationGeography,
            );
          return (
            <div className="flex gap-2">
              {instrumentsWithEquityType.map(({ equityType, instrument }) => (
                <DownloadDocumentsItem
                  className="min-w-0 flex-1"
                  equityTypeFragment={equityType}
                  instrumentFragment={instrument}
                  key={`${equityType.id}-${instrument.id}`}
                  organizationFragment={organization}
                />
              ))}
            </div>
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Documentation",
        id: "documentation",
      }),
    ],
    [
      getUnlockedEquityTypesNames,
      getAllInstrumentsWithEquityTypeForOrganizationGeography,
      organization,
    ],
  );

  const data = useMemo(
    () => [...organization.organizationGeographies],
    [organization.organizationGeographies],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    initialState: {
      sorting: [{ desc: false, id: "country" }],
    },
    onGlobalFilterChange,
    state: {
      globalFilter: searchParams.get("search"),
    },

    // Hack to set width auto when size is not set
    defaultColumn: { size: 0 },
  });

  return (
    <Table.Smart
      searchBarPlaceholder="Search countries..."
      showSearchBar
      table={table}
    />
  );
};
