/**
 * @generated SignedSource<<45ad20ab7f1dbf77d43763e359c575f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
export type FairMarketValueStatus = "Approved" | "Draft" | "PendingBoardConsent" | "PendingReview" | "Reviewed";
export type GrantNotReadyToBeSentToBoardConsentReason = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "NOT_REVIEWED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
import { FragmentRefs } from "relay-runtime";
export type AdminTasksCard_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
      };
    }>;
  };
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly status: FairMarketValueStatus;
  } | null;
  readonly pendingApprovalFairMarketValueBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
  }>;
  readonly pendingApprovalGrantAmendmentBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
  }>;
  readonly pendingApprovalGrantBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
  }>;
  readonly pendingApprovalGranteeTerminationGrantAmendmentBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
  }>;
  readonly readyForConsentCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
  readonly readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
  readonly reviewedDrafts: ReadonlyArray<{
    readonly notReadyForBoardConsentReasons: ReadonlyArray<GrantNotReadyToBeSentToBoardConsentReason>;
  }>;
  readonly " $fragmentType": "AdminTasksCard_Organization";
};
export type AdminTasksCard_Organization$key = {
  readonly " $data"?: AdminTasksCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminTasksCard_Organization">;
};

import AdminTasksCard_Organization_RefetchQuery_graphql from './AdminTasksCard_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "statusIn",
    "value": [
      "READY_FOR_CONSENT"
    ]
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": AdminTasksCard_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "AdminTasksCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GrantBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGrantBoardConsents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GrantAmendmentBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGrantAmendmentBoardConsents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationGrantAmendmentBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGranteeTerminationGrantAmendmentBoardConsents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValueBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalFairMarketValueBoardConsents",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "reviewedDrafts",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Reviewed"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "notReadyForBoardConsentReasons",
          "storageKey": null
        }
      ],
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"Reviewed\"]})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cannotBeInvitedReason",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "readyForConsentGranteeTerminationCTMSGrantAmendmentRequests",
      "args": (v1/*: any*/),
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "alias": "readyForConsentCTMSGrantAmendmentRequests",
      "args": (v1/*: any*/),
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "e41e09016917aec57bc4947e2ca67eed";

export default node;
