/**
 * @generated SignedSource<<5ba08a94537572cd425a2ad3e22854c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type EquityTypesExercisesTaxParametersTable_EquityType$data = ReadonlyArray<{
  readonly exerciseParameters: {
    readonly contractor: {
      readonly benefits: ReadonlyArray<{
        readonly __typename: "ExerciseBenefit";
      }>;
      readonly taxRanges: ReadonlyArray<{
        readonly __typename: "ExerciseTaxRange";
      }>;
      readonly watchout: string | null;
    } | null;
    readonly directEmployee: {
      readonly benefits: ReadonlyArray<{
        readonly __typename: "ExerciseBenefit";
      }>;
      readonly taxRanges: ReadonlyArray<{
        readonly __typename: "ExerciseTaxRange";
      }>;
      readonly watchout: string | null;
    } | null;
    readonly eoREmployee: {
      readonly benefits: ReadonlyArray<{
        readonly __typename: "ExerciseBenefit";
      }>;
      readonly taxRanges: ReadonlyArray<{
        readonly __typename: "ExerciseTaxRange";
      }>;
      readonly watchout: string | null;
    } | null;
    readonly lastUpdatedAt: string | null;
  };
  readonly id: string;
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly " $fragmentType": "EquityTypesExercisesTaxParametersTable_EquityType";
}>;
export type EquityTypesExercisesTaxParametersTable_EquityType$key = ReadonlyArray<{
  readonly " $data"?: EquityTypesExercisesTaxParametersTable_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypesExercisesTaxParametersTable_EquityType">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseTaxRange",
    "kind": "LinkedField",
    "name": "taxRanges",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseBenefit",
    "kind": "LinkedField",
    "name": "benefits",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "watchout",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquityTypesExercisesTaxParametersTable_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeExerciseParameters",
      "kind": "LinkedField",
      "name": "exerciseParameters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastUpdatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "directEmployee",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "eoREmployee",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "contractor",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "f748a47cedc3ffc73a498f517d3de33c";

export default node;
