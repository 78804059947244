/**
 * @generated SignedSource<<b6af1b784166afc53b5f5b442b9549ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantRelatedHydratedContent_CTMSGrant$data = {
  readonly equityTypeAwardName: string | null;
  readonly label: string;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly name: EquityTypeName;
    } | null;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    };
    readonly workRelationship: WorkRelationship;
  } | null;
  readonly " $fragmentType": "GrantRelatedHydratedContent_CTMSGrant";
};
export type GrantRelatedHydratedContent_CTMSGrant$key = {
  readonly " $data"?: GrantRelatedHydratedContent_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantRelatedHydratedContent_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeAwardName",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "512bf3d27e38026fc1471c2f18502f01";

export default node;
