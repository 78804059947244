import { CheckIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DisconnectIntegrationButton } from "../../../../components/DisconnectIntegrationButton";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { AlternativeTwoColumnsLayout } from "../../../../components/ui/Layout/AlternativeTwoColumnsLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../../../../paths";
import { OrganizationSettingsIntegration_Organization$key } from "./__generated__/OrganizationSettingsIntegration_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsIntegration_Organization on Organization {
    id
    name
    ...DisconnectIntegrationButton_Organization
  }
`;

export type IntegrationProvider = "CARTA" | "DEEL" | "REMOTE";

const NotConnectedTag: React.FC = () => <Tag color="gray">Not connected</Tag>;

const ConnectedTag: React.FC = () => (
  <Tag color="green" icon={<CheckIcon />}>
    Connected
  </Tag>
);

const OrganizationSettingsIntegrationView: React.FC<{
  capabilities: Array<{
    description: React.ReactNode;
    key: string;
    title: React.ReactNode;
  }>;
  description: React.ReactNode;
  errorMessage?: React.ReactNode;
  generateAuthorizationUrl: () => Promise<string>;
  integrationColor: string;
  integrationLogoSrc: string;
  integrationName: string;
  integrationRequirements: Array<{ content: React.ReactNode; key: string }>;
  integrationWebsiteName: string;
  integrationWebsiteUrl: string;
  isConnected: boolean;
  organizationFragment: OrganizationSettingsIntegration_Organization$key;
  provider: IntegrationProvider;
  title: React.ReactNode;
}> = ({
  capabilities,
  description,
  errorMessage,
  generateAuthorizationUrl,
  integrationColor,
  integrationLogoSrc,
  integrationName,
  integrationRequirements,
  integrationWebsiteName,
  integrationWebsiteUrl,
  isConnected,
  organizationFragment,
  provider,
  title,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoURL = `mailto:${supportEmailAddress}?subject=${encodeURIComponent(
    `[${organization.name}] neeed help with ${integrationName} integration`,
  )}`;
  const handleConnectButtonClick = async () => {
    const url = await generateAuthorizationUrl();

    window.location.href = url;
  };
  const applicationName = useApplicationName();

  return (
    <AlternativeTwoColumnsLayout
      breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Integrations</BreadCrumb.Link>
          <BreadCrumb.Link to=".">{integrationName}</BreadCrumb.Link>
        </BreadCrumb>
      }
      leftColumn={
        <RoundedBox withBorder withShadow>
          <div className="space-y-6 px-10 py-6">
            <div className="space-y-2">
              <Typography as="div" variant="Medium/Small">
                {title}
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Small"
              >
                {description}
              </Typography>
            </div>
            {!isConnected && (
              <div className="space-y-6 rounded-t border-[0.5px] border-gray-04 bg-primary-01 px-10 py-6">
                <div className="space-y-2">
                  <Typography as="div" variant="Medium/Small">
                    Connect the integration
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Small"
                  >
                    To integrate with {integrationName}, you’ll need:
                    <ul className="list-disc pl-6">
                      {integrationRequirements.map((requirement) => (
                        <li key={requirement.key}>{requirement.content}</li>
                      ))}
                    </ul>
                  </Typography>
                </div>
                {errorMessage}
                <Button
                  fullWidth
                  onClick={handleConnectButtonClick}
                  size="small"
                  type="button"
                >
                  Connect {integrationName}
                </Button>
              </div>
            )}
            <div className="space-y-6">
              {capabilities.map((capability) => (
                <div className="space-y-2" key={capability.key}>
                  <Typography as="div" variant="Medium/Small">
                    {capability.title}
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Small"
                  >
                    {capability.description}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </RoundedBox>
      }
      rightColumn={
        <RoundedBox withBorder withShadow>
          <div className="space-y-4 p-6">
            <div
              className="rounded-lg px-6 py-4"
              style={{
                backgroundColor: integrationColor,
              }}
            >
              <img
                alt={`${integrationName} logo`}
                className="mx-auto h-12 object-fill"
                src={integrationLogoSrc}
              />
            </div>
            <div className="flex flex-col items-center space-y-2">
              <Typography as="div" variant="Medium/Extra Small">
                Status
              </Typography>
              {isConnected ? <ConnectedTag /> : <NotConnectedTag />}
            </div>
            {isConnected && (
              <div className="text-center">
                <DisconnectIntegrationButton
                  organizationFragment={organization}
                  provider={provider}
                />
              </div>
            )}
            <Divider />
            <div className="text-center">
              <Link className="text-primary" to={integrationWebsiteUrl}>
                <Typography as="div" variant="Medium/Caption">
                  Visit {integrationWebsiteName}
                </Typography>
              </Link>
            </div>
            <Divider />
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              If you have any questions or need assistance with the{" "}
              {applicationName}-{integrationName} integration, please don&apos;t
              hesitate to contact our support team via email at{" "}
              <Link className="font-medium text-primary" to={mailtoURL}>
                {supportEmailAddress}
              </Link>
              . Our team is here to help you get the most out of this
              integration and ensure a smooth and seamless experience.
            </Typography>
          </div>
        </RoundedBox>
      }
    />
  );
};

export default OrganizationSettingsIntegrationView;
