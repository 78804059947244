/**
 * @generated SignedSource<<a6f5401371303c47ce52fb50227f80b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationSideBar_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBarContent_Organization" | "OrganizationRoleSwitcher_Organization">;
  readonly " $fragmentType": "ApplicationSideBar_Organization";
};
export type ApplicationSideBar_Organization$key = {
  readonly " $data"?: ApplicationSideBar_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationSideBar_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBarContent_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c50f8dc2f3ed6dec5576e30a4c5f7dad";

export default node;
