/**
 * @generated SignedSource<<8e70942d4e8d700eee90e98a9eab9481>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationForm_Organization$data = {
  readonly hasCooleyAsOutsideCounsel: boolean;
  readonly " $fragmentType": "PostTerminationForm_Organization";
};
export type PostTerminationForm_Organization$key = {
  readonly " $data"?: PostTerminationForm_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationForm_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationForm_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCooleyAsOutsideCounsel",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "615f5913e812d6e00c2de45b37a9e3e3";

export default node;
