/**
 * @generated SignedSource<<8e6173e811f073af4e9956f811114d9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsEquityGridLevelTag_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly __typename: "CTMSGrant";
  }>;
  readonly equityGridLevel: {
    readonly name: string;
  } | null;
  readonly missingSharesToEquityGridLevel: number | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"useGranteePositionToEquityGridLevel_Grantee">;
  readonly " $fragmentType": "GranteeDetailsEquityGridLevelTag_Grantee";
};
export type GranteeDetailsEquityGridLevelTag_Grantee$key = {
  readonly " $data"?: GranteeDetailsEquityGridLevelTag_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsEquityGridLevelTag_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsEquityGridLevelTag_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingSharesToEquityGridLevel",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteePositionToEquityGridLevel_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "987cb65bf8025767eebb82f2083b17bf";

export default node;
