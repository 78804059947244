/**
 * @generated SignedSource<<6d873d27b285dd34c68b38d63e9cd816>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type GrantFormSlide_EasopGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly earlyExercise: boolean;
  readonly exercisePrice: number;
  readonly exercisePriceBelowFMVSetOn: string | null;
  readonly grantee: {
    readonly id: string;
  };
  readonly id: string;
  readonly instrument: {
    readonly id: string;
  };
  readonly label: string;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  };
  readonly quantityGranted: number;
  readonly vestingSchedule: {
    readonly id: string;
  };
  readonly vestingStartDate: string;
  readonly " $fragmentType": "GrantFormSlide_EasopGrant";
};
export type GrantFormSlide_EasopGrant$key = {
  readonly " $data"?: GrantFormSlide_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_EasopGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantFormSlide_EasopGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePriceBelowFMVSetOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "c435efdae1479e8f563a948123e8d314";

export default node;
