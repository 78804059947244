/**
 * @generated SignedSource<<085bbca8c56b674bc8d439a699700e03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query$variables = {
  organizationId: string;
};
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query = {
  response: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query$data;
  variables: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "IMPLEMENTATION"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "implementationAmendmentRequests",
            "args": [
              (v4/*: any*/)
            ],
            "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "granteeTerminationCTMSGrantAmendmentRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
          },
          {
            "alias": "searchedImplementationAmendmentRequests",
            "args": [
              {
                "kind": "Literal",
                "name": "search",
                "value": ""
              },
              (v4/*: any*/)
            ],
            "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "granteeTerminationCTMSGrantAmendmentRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeletable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CTMSGrant",
                "kind": "LinkedField",
                "name": "grant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "taxResidenceCountry",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emoji",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(search:\"\",statusIn:[\"IMPLEMENTATION\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a51d3c1233ec3d02c684b7456c29363",
    "id": null,
    "metadata": {},
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query",
    "operationKind": "query",
    "text": "query Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization\n  }\n  me {\n    ...Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest {\n  id\n  status\n  isDeletable\n  grant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment GranteeTerminationAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account on Account {\n  isSuperAdmin\n}\n\nfragment Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Organization on Organization {\n  id\n  name\n  implementationAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(statusIn: [IMPLEMENTATION]) {\n    id\n  }\n  searchedImplementationAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(statusIn: [IMPLEMENTATION], search: \"\") {\n    ...GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest\n    id\n  }\n  ...LegalWorkflowLayout_Organization\n  ...GranteeTerminationAmendmentRequestsTable_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n"
  }
};
})();

(node as any).hash = "9353c98d046dc44e3c7b5872fc2a7aae";

export default node;
