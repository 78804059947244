/**
 * @generated SignedSource<<07f7148df567738652ec12e448d4b1b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuspensedTabList_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedTabList_Deferred_Organization">;
  readonly " $fragmentType": "SuspensedTabList_Organization";
};
export type SuspensedTabList_Organization$key = {
  readonly " $data"?: SuspensedTabList_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedTabList_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuspensedTabList_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SuspensedTabList_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b6fd760b75504e8fcd227d0f1e945361";

export default node;
