/**
 * @generated SignedSource<<3fa6f3d7cf4ce050d2a741eccddae95f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type GrantAmendmentRequestBoxPTEP_CtmsGrant$data = {
  readonly activePostTerminationExercisePeriod: {
    readonly value: {
      readonly duration: number;
      readonly unit: DurationUnit;
    };
  } | null;
  readonly id: string;
  readonly isVirtual: boolean;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBoxPTEPSlide_CtmsGrant">;
  readonly " $fragmentType": "GrantAmendmentRequestBoxPTEP_CtmsGrant";
};
export type GrantAmendmentRequestBoxPTEP_CtmsGrant$key = {
  readonly " $data"?: GrantAmendmentRequestBoxPTEP_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBoxPTEP_CtmsGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantAmendmentRequestBoxPTEP_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivePostTerminationExercisePeriod",
      "kind": "LinkedField",
      "name": "activePostTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PostTerminationExercisePeriod",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "unit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantAmendmentRequestBoxPTEPSlide_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "01177efb734cfdf76d7c9227823deb83";

export default node;
