import { generatePath, Outlet } from "react-router-dom";

import { TabNavigation } from "../../../../../../components/ui/TabNavigation";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../../paths";
import GrantDefaultsLayout from "../GrantDefaultsLayout";

export default function NewHiresLayout() {
  const organizationId = useOrganizationIdParam();

  return (
    <GrantDefaultsLayout
      navigation={
        <TabNavigation>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES[
                "organizationEquityConfigureNewHiresEmployeesAndContractors"
              ],
              {
                organizationId,
              },
            )}
          >
            Employees and Contractors
          </TabNavigation.Link>
          <TabNavigation.Link
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityConfigureNewHiresAdvisors"],
              {
                organizationId,
              },
            )}
          >
            Advisors
          </TabNavigation.Link>
        </TabNavigation>
      }
      title="New hire grants"
    >
      <Outlet />
    </GrantDefaultsLayout>
  );
}
