/**
 * @generated SignedSource<<5806fdc8c2fb482908502dc9c4153103>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceEvolutionGraph_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly easopGrant: {
    readonly exercisePrice: number;
  } | null;
  readonly exercisePrice: number | null;
  readonly pricePerShareAtGrant: number | null;
  readonly quantityIssued: number;
  readonly " $fragmentType": "PriceEvolutionGraph_CTMSGrant";
};
export type PriceEvolutionGraph_CTMSGrant$key = {
  readonly " $data"?: PriceEvolutionGraph_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceEvolutionGraph_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exercisePrice",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceEvolutionGraph_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricePerShareAtGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "796ea208f058e6d8f89d0c17b9680a40";

export default node;
