/**
 * @generated SignedSource<<8377e51f156747297ff023de9c920db6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Grantees_Organization$data = {
  readonly id: string;
  readonly isConnectedToHRISProvider: boolean;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateGranteeButton_Organization" | "Grantees_Refetchable_Organization" | "LargeOneColumnLayout_Organization" | "OrganizationGranteesTable_Organization" | "SuspensedInviteGranteesModal_Organization" | "SuspensedTabList_Organization">;
  readonly " $fragmentType": "Grantees_Organization";
};
export type Grantees_Organization$key = {
  readonly " $data"?: Grantees_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantees_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Grantees_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToHRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationGranteesTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateGranteeButton_Organization"
    },
    {
      "args": [
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "SuspensedInviteGranteesModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SuspensedTabList_Organization"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        (v0/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "Grantees_Refetchable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0f769c2bb4ad26803e1c987c6b2a959c";

export default node;
