/**
 * @generated SignedSource<<9257ccf810bec300c7672dcd9823dec3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriod_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodGraph_Viewer">;
  readonly " $fragmentType": "PostTerminationExercisePeriod_Viewer";
};
export type PostTerminationExercisePeriod_Viewer$key = {
  readonly " $data"?: PostTerminationExercisePeriod_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriod_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationExercisePeriod_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PostTerminationExercisePeriodGraph_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "99c4352aaebb564f1fc558609fc8357c";

export default node;
