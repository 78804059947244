/**
 * @generated SignedSource<<d12172368dac6811916a787f16be05f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly pendingApprovalGranteeTerminationGrantAmendmentBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
    readonly granteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
    }>;
    readonly id: string;
    readonly isPandaDocDocumentSent: boolean;
    readonly signatureRequests: ReadonlyArray<{
      readonly completedAt: string | null;
      readonly id: string;
      readonly signatoryFirstName: string;
      readonly signatoryLastName: string;
    }>;
    readonly voidedReason: BoardConsentVoidedReason | null;
    readonly " $fragmentSpreads": FragmentRefs<"SendReminderButton_BoardConsent">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_Organization" | "LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization";
};
export type PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization">;
};

import PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationGrantAmendmentBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGranteeTerminationGrantAmendmentBoardConsents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voidedReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPandaDocDocumentSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryLastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
          "kind": "LinkedField",
          "name": "granteeTerminationCTMSGrantAmendmentRequests",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SendReminderButton_BoardConsent"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationAmendmentRequestsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "d3044b7f9e51aee8a2aa63da7364bbb1";

export default node;
