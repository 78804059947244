import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useMergeGrantee_Grantee$key } from "./__generated__/useMergeGrantee_Grantee.graphql";
import { useMergeGrantee_HRISProviderEmployee$key } from "./__generated__/useMergeGrantee_HRISProviderEmployee.graphql";
import {
  HRISProviderEmployeeField,
  useMergeGrantee_MergeGranteeMutation,
} from "./__generated__/useMergeGrantee_MergeGranteeMutation.graphql";
import { useSafeMutation } from "./useSafeMutation";

const GRANTEE_FRAGMENT = graphql`
  fragment useMergeGrantee_Grantee on Grantee {
    id
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment useMergeGrantee_HRISProviderEmployee on HRISProviderEmployee {
    hRISProvider
    hRISProviderEmployeeId
  }
`;

const MERGE_GRANTEE_MUTATION = graphql`
  mutation useMergeGrantee_MergeGranteeMutation(
    $granteeId: GranteeId!
    $hRISProvider: HRISProvider!
    $hRISProviderEmployeeId: String!
    $selectedHRISProviderEmployeeFields: [HRISProviderEmployeeField!]!
  ) {
    mergeHRISProviderEmployeeWithGrantee(
      granteeId: $granteeId
      hRISProvider: $hRISProvider
      hRISProviderEmployeeId: $hRISProviderEmployeeId
      selectedHRISProviderEmployeeFields: $selectedHRISProviderEmployeeFields
    ) {
      HRISProviderEmployee {
        ...useMergeGrantee_HRISProviderEmployee
      }
      grantee {
        ...MergeEmployeeModal_Grantee
        ...MergeEmployeeWithAnotherGranteeModal_Grantee
      }
    }
  }
`;

export const useMergeGrantee = ({
  employeeFragment,
  granteeFragment,
}: {
  employeeFragment: useMergeGrantee_HRISProviderEmployee$key;
  granteeFragment: useMergeGrantee_Grantee$key;
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const [_mergeGrantee, mergeGranteeIsInFlight] =
    useSafeMutation<useMergeGrantee_MergeGranteeMutation>(
      MERGE_GRANTEE_MUTATION,
    );

  const mergeGrantee = useCallback(
    (selectedHRISProviderEmployeeFields: HRISProviderEmployeeField[]) =>
      _mergeGrantee({
        variables: {
          granteeId: grantee.id,
          hRISProvider: employee.hRISProvider,
          hRISProviderEmployeeId: employee.hRISProviderEmployeeId,
          selectedHRISProviderEmployeeFields:
            selectedHRISProviderEmployeeFields,
        },
      }),
    [_mergeGrantee, grantee, employee],
  );

  return [mergeGrantee, mergeGranteeIsInFlight] as const;
};
