import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { ReactNode } from "react";

import { Button } from "../Button";
import { RoundedBox } from "../RoundedBox";
import { Typography } from "../Typography";

export const PreviewBox: React.FC<{
  children: ReactNode;
  className?: string;
  previewClassnames?: string;
  previewLink?: React.ReactNode;
}> = ({ children, className, previewClassnames, previewLink }) => (
  <RoundedBox
    background="primary"
    className={classNames("flex flex-col gap-2 p-4", className)}
  >
    <div className="flex justify-between">
      <Typography as="div" className="flex gap-2" variant="Regular/Caption">
        <div>👀</div> Preview
      </Typography>

      {previewLink}
    </div>

    <RoundedBox
      className={classNames(previewClassnames, "p-10")}
      data-cy="preview"
      withBorder
    >
      {children}
    </RoundedBox>
  </RoundedBox>
);

export const PreviewLayout: React.FC<{
  children: ReactNode;
  className?: string;
  continueButtonDataCy?: string;
  description?: string;
  onBackClick?: () => void;
  onContinueClick?: () => void;
  Preview: ReactNode;
  previewClassnames?: string;
  previewLink: React.ReactNode;
  title: React.ReactNode;
}> = ({
  children,
  className,
  continueButtonDataCy,
  description,
  onBackClick,
  onContinueClick,
  Preview,
  previewClassnames,
  previewLink,
  title,
}) => {
  return (
    <div
      className={classNames(className, "flex items-start justify-center gap-6")}
    >
      <RoundedBox className="w-[480px] p-10" withBorder withShadow>
        <div className="mb-4 flex flex-col gap-2">
          <Typography as="div" variant="Medium/Large">
            {title}
          </Typography>

          {description ? (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              {description}
            </Typography>
          ) : null}
        </div>

        {children}

        {(onContinueClick || onBackClick) && (
          <div className="mt-6 flex">
            {onBackClick ? (
              <Button
                leftIcon={<ArrowLeftIcon />}
                onClick={onBackClick}
                size="small"
                variant="Secondary Full"
              >
                Back
              </Button>
            ) : null}
            {onContinueClick && (
              <Button
                className="ml-auto"
                data-cy={continueButtonDataCy}
                onClick={onContinueClick}
                size="small"
                variant="Primary Full"
              >
                Continue
              </Button>
            )}
          </div>
        )}
      </RoundedBox>
      <PreviewBox
        className="w-[480px]"
        previewClassnames={previewClassnames}
        previewLink={previewLink}
      >
        {Preview}
      </PreviewBox>
    </div>
  );
};
