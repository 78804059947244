import React, { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CleanupGranteesItem } from "../../../../components/CleanupGranteesItem";
import { MergeEmployeeModal } from "../../../../components/MergeEmployeeModal";
import { MergeEmployeeWithAnotherGranteeModal } from "../../../../components/MergeEmployeeWithAnotherGranteeModal";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Toast } from "../../../../components/ui/Toast";
import useIgnoreCleanupSuggestionMutation from "../useIgnoreCleanupSuggestionMutation";
import { SynchronizeEmployeesDataItem_HRISProviderEmployee$key } from "./__generated__/SynchronizeEmployeesDataItem_HRISProviderEmployee.graphql";
import { SynchronizeEmployeesDataItem_Organization$key } from "./__generated__/SynchronizeEmployeesDataItem_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SynchronizeEmployeesDataItem_Organization on Organization {
    id
    ...MergeEmployeeWithAnotherGranteeModal_Organization
  }
`;

const HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment SynchronizeEmployeesDataItem_HRISProviderEmployee on HRISProviderEmployee {
    hRISProvider
    hRISProviderEmployeeId
    ...CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee
    ...MergeEmployeeModal_HRISProviderEmployee
    ...MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee
    suggestedGrantee @required(action: THROW) {
      ...CleanupGranteesItem_SynchronizeContent_Grantee
      ...MergeEmployeeModal_Grantee
    }
    suggestionReason @required(action: THROW)
  }
`;

export const SynchronizeEmployeesDataItem: React.FC<{
  hideIgnoreButton?: boolean;
  hrisProviderEmployeeFragment: SynchronizeEmployeesDataItem_HRISProviderEmployee$key;
  listIndex: number;
  listLength: number;
  onDataMutation: () => void;
  organizationFragment: SynchronizeEmployeesDataItem_Organization$key;
}> = ({
  hideIgnoreButton = false,
  hrisProviderEmployeeFragment,
  listIndex,
  listLength,
  onDataMutation,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const hrisProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    hrisProviderEmployeeFragment,
  );

  const [mergeEmployeeModalIsShown, setMergeEmployeeModalIsShown] =
    useState(false);

  const [
    mergeEmployeeWithAnotherGranteeModalIsShown,
    setMergeEmployeeWithAnotherGranteeModalIsShown,
  ] = useState(false);

  const [ignoreCleanupSuggestion, ignoreCleanupSuggestionMutationIsInFlight] =
    useIgnoreCleanupSuggestionMutation();
  const handleIgnoreButtonClick = useCallback(async () => {
    await ignoreCleanupSuggestion({
      variables: {
        HRISProvider: hrisProviderEmployee.hRISProvider,
        HRISProviderEmployeeId: hrisProviderEmployee.hRISProviderEmployeeId,
        organizationId: organization.id,
      },
    });

    onDataMutation();
  }, [
    hrisProviderEmployee.hRISProvider,
    hrisProviderEmployee.hRISProviderEmployeeId,
    ignoreCleanupSuggestion,
    onDataMutation,
    organization.id,
  ]);

  const somethingIsLoading = ignoreCleanupSuggestionMutationIsInFlight;

  const toaster = useToaster();

  return (
    <div>
      <MergeEmployeeModal
        employeeFragment={hrisProviderEmployee}
        granteeFragment={hrisProviderEmployee.suggestedGrantee}
        onClose={() => setMergeEmployeeModalIsShown(false)}
        onMerge={() => {
          toaster.push(
            <Toast title="Bazinga!" variant="congrats">
              Grantee updated successfully!
            </Toast>,
          );

          setMergeEmployeeModalIsShown(false);
          onDataMutation();
        }}
        show={mergeEmployeeModalIsShown}
      />

      <MergeEmployeeWithAnotherGranteeModal
        employeeFragment={hrisProviderEmployee}
        onClose={() => setMergeEmployeeWithAnotherGranteeModalIsShown(false)}
        onMerge={() => {
          toaster.push(
            <Toast title="Kaboom!" variant="congrats">
              Grantee updated successfully!
            </Toast>,
          );

          setMergeEmployeeWithAnotherGranteeModalIsShown(false);
          onDataMutation();
        }}
        organizationFragment={organization}
        show={mergeEmployeeWithAnotherGranteeModalIsShown}
      />

      <CleanupGranteesItem
        actions={
          <>
            {!hideIgnoreButton && (
              <Button
                loading={somethingIsLoading}
                onClick={handleIgnoreButtonClick}
                size="small"
                type="button"
                variant="Tertiary Link"
              >
                Ignore
              </Button>
            )}
            <Button
              loading={somethingIsLoading}
              onClick={() => {
                setMergeEmployeeWithAnotherGranteeModalIsShown(true);
              }}
              size="small"
              variant="Secondary Full"
            >
              Link to another grantee
            </Button>
            <Button
              loading={somethingIsLoading}
              onClick={() => {
                setMergeEmployeeModalIsShown(true);
              }}
              size="small"
            >
              Merge grantee
            </Button>
          </>
        }
        listIndex={listIndex}
        listLength={listLength}
      >
        <CleanupGranteesItem.SynchronizeContent
          granteeFragment={hrisProviderEmployee.suggestedGrantee}
          hRISProviderEmployeeFragment={hrisProviderEmployee}
          reason={hrisProviderEmployee.suggestionReason}
        />
      </CleanupGranteesItem>
    </div>
  );
};
