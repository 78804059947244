import { Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { ApplicationThemeProvider } from "../../hooks/useApplicationTheme";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import NotFoundPage from "../../pages/NotFound/NotFound";
import { useGranteeIdParam } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { GranteeLayout_Grantee_Query } from "./__generated__/GranteeLayout_Grantee_Query.graphql";

const QUERY = graphql`
  query GranteeLayout_Grantee_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      organization {
        id
        name
        ...useApplicationTheme_Organization
      }
    }
  }
`;

const GranteeLayout: React.FC = () => {
  const granteeId = useGranteeIdParam();

  const { query } = useQuery<GranteeLayout_Grantee_Query>(QUERY, {
    granteeId,
  });
  const { account } = useAuthentication();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <ApplicationThemeProvider organizationFragment={query.grantee.organization}>
      <FeatureFlagProvider
        account={account}
        organizationName={query.grantee.organization.name}
      >
        <AnalyticsContextProvider
          organizationId={query.grantee.organization.id}
        >
          <Outlet />
        </AnalyticsContextProvider>
      </FeatureFlagProvider>
    </ApplicationThemeProvider>
  );
};

export default GranteeLayout;
