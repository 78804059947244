/**
 * @generated SignedSource<<899916eadc4ab4da2ec437b2315fa8c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
export type BookADemoView_TriggerDemoBooked_Mutation$variables = {
  organizationId: string;
};
export type BookADemoView_TriggerDemoBooked_Mutation$data = {
  readonly triggerDemoBooked: {
    readonly plan: OrganizationPlan;
  };
};
export type BookADemoView_TriggerDemoBooked_Mutation = {
  response: BookADemoView_TriggerDemoBooked_Mutation$data;
  variables: BookADemoView_TriggerDemoBooked_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookADemoView_TriggerDemoBooked_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "triggerDemoBooked",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookADemoView_TriggerDemoBooked_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "triggerDemoBooked",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a44952e7c9774c0fa0c5bcae65560446",
    "id": null,
    "metadata": {},
    "name": "BookADemoView_TriggerDemoBooked_Mutation",
    "operationKind": "mutation",
    "text": "mutation BookADemoView_TriggerDemoBooked_Mutation(\n  $organizationId: OrganizationId!\n) {\n  triggerDemoBooked(organizationId: $organizationId) {\n    plan\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "808eca0af8ef5072f1d664df5f08cef5";

export default node;
