/**
 * @generated SignedSource<<da03227c16d96eb78a864b30a191412b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberAttributes = {
  accountId: string;
  organizationId: string;
};
export type InviteBoardMemberSlideOver_AddBoardMember_Mutation$variables = {
  attributes: BoardMemberAttributes;
};
export type InviteBoardMemberSlideOver_AddBoardMember_Mutation$data = {
  readonly addBoardMember: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Organization">;
    };
  };
};
export type InviteBoardMemberSlideOver_AddBoardMember_Mutation = {
  response: InviteBoardMemberSlideOver_AddBoardMember_Mutation$data;
  variables: InviteBoardMemberSlideOver_AddBoardMember_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteBoardMemberSlideOver_AddBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBoardMemberResult",
        "kind": "LinkedField",
        "name": "addBoardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsBoardMembers_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteBoardMemberSlideOver_AddBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AddBoardMemberResult",
        "kind": "LinkedField",
        "name": "addBoardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardMember",
                "kind": "LinkedField",
                "name": "boardMembers",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRemovable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "702a0c8e4be120c7d65265de457f5427",
    "id": null,
    "metadata": {},
    "name": "InviteBoardMemberSlideOver_AddBoardMember_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteBoardMemberSlideOver_AddBoardMember_Mutation(\n  $attributes: BoardMemberAttributes!\n) {\n  addBoardMember(attributes: $attributes, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsBoardMembers_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Item_BoardMember on BoardMember {\n  id\n  isRemovable\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Organization on Organization {\n  id\n  name\n  boardMembers {\n    id\n    account {\n      firstName\n      lastName\n      email\n      id\n    }\n    ...OrganizationSettingsBoardMembers_Item_BoardMember\n  }\n}\n"
  }
};
})();

(node as any).hash = "12fc148b90e15b26a4c8ef4121811e2b";

export default node;
