import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { CreateNewGrantees_Organization$key } from "./__generated__/CreateNewGrantees_Organization.graphql";
import { CreateNewGrantees_Query } from "./__generated__/CreateNewGrantees_Query.graphql";
import { CreateNewGranteeItem } from "./CreateNewGranteeItem";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CreateNewGrantees_Organization on Organization {
    id
    name
    HRISProviderEmployeesSuggestedAsNewGrantee {
      id
      name
      ...CreateNewGranteeItem_HRISProviderEmployee
    }
    ...CreateNewGranteeItem_Organization
  }
`;

const CreateNewGrantees_: React.FC<{
  organizationFragment: CreateNewGrantees_Organization$key;
  refreshQuery: () => void;
}> = ({ organizationFragment, refreshQuery }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <>
      <CenteredColumnLayout
        breadcrumbs={
          <BreadCrumb>
            <BreadCrumb.Link
              to={generatePath(APPLICATION_ROUTES["organizationHome"], {
                organizationId: organization.id,
              })}
            >
              {organization.name}
            </BreadCrumb.Link>
            <BreadCrumb.Link to="../..">Settings</BreadCrumb.Link>
            <BreadCrumb.Link to="..">Clean up grantees</BreadCrumb.Link>
            <BreadCrumb.Link to=".">Create new grantees</BreadCrumb.Link>
          </BreadCrumb>
        }
        fullHeight
        showFooter
      >
        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="space-y-2">
              <Typography as="div" variant="Medium/Default">
                Create new grantees directly from your HR solutions employee
                data
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Import employees data from your other HR solutions
              </Typography>
            </div>
          </div>
          {organization.HRISProviderEmployeesSuggestedAsNewGrantee.map(
            (hRISProviderEmployee, index) => {
              return (
                <CreateNewGranteeItem
                  hrisProviderEmployeeFragment={hRISProviderEmployee}
                  key={hRISProviderEmployee.id}
                  listIndex={index}
                  listLength={
                    organization.HRISProviderEmployeesSuggestedAsNewGrantee
                      .length
                  }
                  onDataMutation={() => {
                    refreshQuery();
                  }}
                  organizationFragment={organization}
                />
              );
            },
          )}
        </div>
      </CenteredColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query CreateNewGrantees_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      HRISProviderEmployeesSuggestedAsNewGrantee {
        __typename
      }
      ...CreateNewGrantees_Organization
    }
  }
`;

const CreateNewGranteesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<CreateNewGrantees_Query>(QUERY, {
    organizationId,
  });

  const [searchParams] = useSearchParams();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (
    query.organization.HRISProviderEmployeesSuggestedAsNewGrantee.length === 0
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: generatePath(
            APPLICATION_ROUTES["organizationSettingsCleanupGrantees"],
            {
              organizationId: query.organization.id,
            },
          ),
          search: searchParams.toString(),
        }}
      />
    );
  }

  return (
    <Page
      analyticsName="Admin - Clean Up - Create"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} create new grantees`}
    >
      <CreateNewGrantees_
        organizationFragment={query.organization}
        refreshQuery={refreshQuery}
      />
    </Page>
  );
};

export default CreateNewGranteesPage;
