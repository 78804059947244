import { Button } from "../Button";

export function ButtonGroup<T extends { label: string; value: string }>({
  onChange,
  options,
  value,
}: {
  onChange: (value: string) => void;
  options: ReadonlyArray<T>;
  value: null | string | undefined;
}) {
  return (
    <div className="flex gap-2">
      {options.map((option) => {
        return (
          <Button
            key={option.value}
            onClick={() => onChange(option.value)}
            size="extra small"
            type="button"
            variant={value === option.value ? "Primary Full" : "Secondary Full"}
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
}

export function MultiSelectButtonGroup<
  T extends { label: string; value: string },
>({
  onChange,
  options,
  value,
}: {
  onChange: (value: T["value"][]) => void;
  options: ReadonlyArray<T>;
  value: null | T["value"][] | undefined;
}) {
  return (
    <div className="flex gap-2">
      {options.map((option) => {
        return (
          <Button
            key={option.value}
            onClick={() =>
              value
                ? value.includes(option.value)
                  ? onChange(value.filter((v) => v !== option.value))
                  : onChange([...value, option.value])
                : onChange([option.value])
            }
            size="extra small"
            type="button"
            variant={
              value?.includes(option.value) ? "Primary Full" : "Secondary Full"
            }
          >
            {option.label}
          </Button>
        );
      })}
    </div>
  );
}
