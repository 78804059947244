/**
 * @generated SignedSource<<ad27432161c219b0acbcd00cacb7dbd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly accelerationClauseIsModified: boolean;
  readonly ctmsGrant: {
    readonly grantee: {
      readonly name: string;
    };
    readonly label: string;
  };
  readonly earlyExercise: boolean | null;
  readonly earlyExerciseIsModified: boolean;
  readonly id: string;
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  } | null;
  readonly postTerminationExercisePeriodIsModified: boolean;
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest";
};
export type ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest$key = {
  readonly " $data"?: ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postTerminationExercisePeriodIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClauseIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExerciseIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrantAmendmentRequest",
  "abstractKey": null
};

(node as any).hash = "6d20a2cad183fd5b557e26fde4a40283";

export default node;
