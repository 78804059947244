import classNames from "classnames";

import { Toggle } from "../../../../components/ui/Form/Toggle";
import { Typography } from "../../../../components/ui/Typography";

export function ToggleCard({
  description,
  loading,
  onToggle,
  title,
  toggled,
}: {
  description: React.ReactNode;
  loading: boolean;
  onToggle: (allowAcceleration: boolean) => void;
  title: React.ReactNode;
  toggled: boolean;
}) {
  return (
    <div
      className={classNames(
        "space-x-6 space-y-4 rounded-lg border-[0.5px] px-6 py-4",
        {
          "animate-pulse": loading,
          "border-gray-07": !toggled,
          "border-primary": toggled,
        },
      )}
    >
      <div className="flex items-center gap-10">
        <div className="space-y-1">
          <Typography as="div" variant="Medium/Small">
            {title}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            {description}
          </Typography>
        </div>
        <Toggle disabled={loading} enabled={toggled} onChange={onToggle} />
      </div>
    </div>
  );
}
