/**
 * @generated SignedSource<<e32e6eadd33f7b03c400e9caffb751ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant$data = {
  readonly exercisableNonExercisedAmount: number | null;
  readonly isVirtual: boolean;
  readonly remainingDaysToExercise: number | null;
  readonly " $fragmentType": "CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant";
};
export type CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant$key = {
  readonly " $data"?: CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingDaysToExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableNonExercisedAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "98e8a6722b73046fabc5861d84a0166b";

export default node;
