/**
 * @generated SignedSource<<44cc1bea8e17540e5933e89ec13f6b9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWorkflowLayout_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "LegalWorkflowLayout_Organization";
};
export type LegalWorkflowLayout_Organization$key = {
  readonly " $data"?: LegalWorkflowLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWorkflowLayout_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LegalWorkflowLayout_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ff54c85d96db87533ae050602902f1d3";

export default node;
