/**
 * @generated SignedSource<<acb51fe3e9fab0298fd6a080820d8cee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipDetailed_EquityType$data = {
  readonly contractorDetailedAdminPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly directEmployeeDetailedAdminPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly eoREmployeeDetailedAdminPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly id: string;
  readonly taxResidenceCountry: {
    readonly name: string;
  };
  readonly " $fragmentType": "EquityTypeWorkRelationshipDetailed_EquityType";
};
export type EquityTypeWorkRelationshipDetailed_EquityType$key = {
  readonly " $data"?: EquityTypeWorkRelationshipDetailed_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipDetailed_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AdminPortalContent_AdminPortalContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipDetailed_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "eoREmployeeDetailedAdminPortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "directEmployeeDetailedAdminPortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "contractorDetailedAdminPortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "c99f4c5095582aca55710b4b6d64eaea";

export default node;
