import classNames from "classnames";

import { Tag } from "./Tag";
import { Typography } from "./Typography";

export const ActionableMessage: React.FC<{
  border?: boolean;
  children: React.ReactNode | React.ReactNode[];
  description: React.ReactNode;
  isNew?: boolean;
  title: React.ReactNode;
}> = ({ border, children, description, isNew, title }) => {
  return (
    <div
      className={classNames("flex items-center rounded bg-primary-01 p-4", {
        "border-[0.5px] border-primary": border,
      })}
    >
      <div className="flex-grow space-y-2">
        <div className="flex items-center gap-2">
          {isNew && (
            <Tag color="primary" size="Extra Small">
              NEW
            </Tag>
          )}
          <Typography variant="Medium/Extra Small">{title}</Typography>
        </div>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {description}
        </Typography>
      </div>
      <div className="flex shrink-0 gap-2">{children}</div>
    </div>
  );
};
