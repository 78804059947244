import { useCallback, useState } from "react";

import { Safe } from "../services/SafeCalculatorService";
import { ConfirmationModal } from "./ui/ConfirmationModal";

type State =
  | {
      safe: Safe;
      shown: true;
    }
  | {
      safe?: null | Safe;
      shown: false;
    };

export const useDeleteSafeConfirmationModalState = () => {
  const [deleteSafeConfirmationModalState, setState] = useState<State>({
    shown: false,
  });

  const showDeleteSafeConfirmationModal = useCallback((safe: Safe) => {
    setState({
      safe,
      shown: true,
    });
  }, []);

  const closeDeleteSafeConfirmationModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    closeDeleteSafeConfirmationModal,
    deleteSafeConfirmationModalState,
    showDeleteSafeConfirmationModal,
  };
};

export const DeleteSafeConfirmationModal: React.FC<{
  onClose: () => void;
  onDeleteSafe: (safe: Safe) => void;
  state: State;
}> = ({ onClose, onDeleteSafe, state }) => {
  const handleDeleteConfirmation = useCallback(() => {
    if (state.shown) {
      onDeleteSafe(state.safe);
      onClose();
    }
  }, [state, onClose, onDeleteSafe]);

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      onClose={onClose}
      onConfirmed={handleDeleteConfirmation}
      show={state.shown}
      title="Confirm delete"
    >
      Are you sure you want to delete this SAFE?
    </ConfirmationModal>
  );
};
