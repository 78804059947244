import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

const DocumentTable_: React.FC<React.ComponentProps<"table">> = ({
  className,
  ...props
}) => <table className={classNames("table-fixed", className)} {...props} />;

const TableBody: React.FC<React.ComponentProps<"tbody">> = (props) => (
  <tbody {...props} />
);

const TableHeader: React.FC<React.ComponentProps<"thead">> = (props) => (
  <thead {...props} />
);

const Row: React.FC<React.ComponentProps<"tr">> = ({ className, ...props }) => (
  <tr
    className={classNames("transition-all hover:bg-gray-01", className)}
    {...props}
  />
);

const HeaderRow: React.FC<React.ComponentProps<"tr">> = (props) => (
  <tr {...props} />
);

const TableHead: React.FC<
  React.ComponentProps<"th"> & {
    onSortButtonClick?: React.ComponentProps<"button">["onClick"];
  }
> = ({ children, className, onSortButtonClick, ...props }) => (
  <th
    className={classNames("pb-4 pl-4 pr-6 font-medium", className)}
    {...props}
  >
    <div className="flex items-center gap-1">
      <div className="truncate text-xs">{children}</div>
      {onSortButtonClick && (
        <button className="block shrink-0" onClick={onSortButtonClick}>
          <ChevronUpDownIcon className="h-4 w-4 text-gray-09" />
        </button>
      )}
    </div>
  </th>
);

const TableData: React.FC<React.ComponentProps<"td">> = ({
  className,
  ...props
}) => (
  <td
    className={classNames(
      "truncate border-t border-gray-02 py-6 pl-4 pr-6 text-sm text-black-05",
      className,
    )}
    {...props}
  />
);

export const DocumentTable = Object.assign(DocumentTable_, {
  HeaderRow,
  Row,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
});
