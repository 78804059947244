/**
 * @generated SignedSource<<76d81d6e91ebaa7474d765465cc55f0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type GrantAmendmentRequestBoxPTEPSlide_Organization$data = {
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationPostTerminationExercisePeriod_Organization">;
  readonly " $fragmentType": "GrantAmendmentRequestBoxPTEPSlide_Organization";
};
export type GrantAmendmentRequestBoxPTEPSlide_Organization$key = {
  readonly " $data"?: GrantAmendmentRequestBoxPTEPSlide_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBoxPTEPSlide_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantAmendmentRequestBoxPTEPSlide_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationUnit",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationPostTerminationExercisePeriod_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a9bf5c2c07b923d201e041c8016875be";

export default node;
