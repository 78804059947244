/**
 * @generated SignedSource<<a7064d1d0ee2b368c436b93de386c74d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipCategory = "Contractor" | "DirectEmployee" | "EoREmployee";
export type CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation$variables = {
  date: string;
  equityTypeId: string;
  equityTypeWorkRelationshipCategories: ReadonlyArray<EquityTypeWorkRelationshipCategory>;
  label: string;
  requiresLastYearGrantActivity: boolean;
};
export type CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation$data = {
  readonly createOngoingObligation: {
    readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsPage_OngoingObligation">;
  };
};
export type CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation = {
  response: CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation$data;
  variables: CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeWorkRelationshipCategories"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "label"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requiresLastYearGrantActivity"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "date",
    "variableName": "date"
  },
  {
    "kind": "Variable",
    "name": "equityTypeId",
    "variableName": "equityTypeId"
  },
  {
    "kind": "Variable",
    "name": "equityTypeWorkRelationshipCategories",
    "variableName": "equityTypeWorkRelationshipCategories"
  },
  {
    "kind": "Variable",
    "name": "label",
    "variableName": "label"
  },
  {
    "kind": "Variable",
    "name": "requiresLastYearGrantActivity",
    "variableName": "requiresLastYearGrantActivity"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OngoingObligation",
        "kind": "LinkedField",
        "name": "createOngoingObligation",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OngoingObligationsPage_OngoingObligation"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OngoingObligation",
        "kind": "LinkedField",
        "name": "createOngoingObligation",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "equityTypeWorkRelationshipCategories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityType",
            "kind": "LinkedField",
            "name": "equityType",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "affectedOrganizations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e5047151d526bf78a1fdaa864b739c1d",
    "id": null,
    "metadata": {},
    "name": "CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateOngoingObligationSlideOver_CreateOngoingObligation_Mutation(\n  $date: Date!\n  $equityTypeId: UUID!\n  $equityTypeWorkRelationshipCategories: [EquityTypeWorkRelationshipCategory!]!\n  $label: String!\n  $requiresLastYearGrantActivity: Boolean!\n) {\n  createOngoingObligation(date: $date, equityTypeId: $equityTypeId, equityTypeWorkRelationshipCategories: $equityTypeWorkRelationshipCategories, label: $label, requiresLastYearGrantActivity: $requiresLastYearGrantActivity) {\n    ...OngoingObligationsPage_OngoingObligation\n    id\n  }\n}\n\nfragment OngoingObligationsPage_OngoingObligation on OngoingObligation {\n  ...OngoingObligationsTable_OngoingObligation\n}\n\nfragment OngoingObligationsTable_OngoingObligation on OngoingObligation {\n  id\n  label\n  date\n  equityTypeWorkRelationshipCategories\n  equityType {\n    name\n    taxResidenceCountry {\n      emoji\n      name\n    }\n    id\n  }\n  affectedOrganizations {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "95a30d7610ad93afdecbb68abf7ad376";

export default node;
