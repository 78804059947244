/**
 * @generated SignedSource<<213d892053f862d1763b6825cd49f787>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OngoingObligationsPage_OngoingObligation$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsTable_OngoingObligation">;
  readonly " $fragmentType": "OngoingObligationsPage_OngoingObligation";
}>;
export type OngoingObligationsPage_OngoingObligation$key = ReadonlyArray<{
  readonly " $data"?: OngoingObligationsPage_OngoingObligation$data;
  readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsPage_OngoingObligation">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OngoingObligationsPage_OngoingObligation",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OngoingObligationsTable_OngoingObligation"
    }
  ],
  "type": "OngoingObligation",
  "abstractKey": null
};

(node as any).hash = "e851b5665b953d53de979c63ac53ef0f";

export default node;
