import React from "react";

import { AlertElementProps } from "../Alerter";
import { Alert } from "../ui/Alert";

export const PTEPProvidedForFixedPTEPInstrumentAlert: React.FC<
  Omit<AlertElementProps, "children" | "title">
> = ({ ...props }) => {
  return (
    <Alert title="" {...props}>
      Post termination exercise period should not be provided for this
      instrument
    </Alert>
  );
};
