import classNames from "classnames";
import React, { useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { mapRecordValues } from "../helpers/object-utility";
import {
  PriceEvolutionGraph_CTMSGrant$data,
  PriceEvolutionGraph_CTMSGrant$key,
} from "./__generated__/PriceEvolutionGraph_CTMSGrant.graphql";
import {
  PriceEvolutionGraph_Organization$data,
  PriceEvolutionGraph_Organization$key,
} from "./__generated__/PriceEvolutionGraph_Organization.graphql";
import { BarGraph } from "./BarGraph";
import { FormattedFMV } from "./FormattedFMV";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment PriceEvolutionGraph_CTMSGrant on CTMSGrant {
    pricePerShareAtGrant
    easopGrant {
      exercisePrice
    }
    exercisePrice
    quantityIssued
    cumulativeVested
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PriceEvolutionGraph_Organization on Organization {
    name
    latestPricePerShare {
      value
    }
    latestFairMarketValue {
      value
    }
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

export const PriceEvolutionGraphModes = [
  "Per share",
  "Vested",
  "Fully vested",
] as const;
export type PriceEvolutionGraphMode = (typeof PriceEvolutionGraphModes)[number];

type ValueId =
  | "discountAtGrant"
  | "exercisePriceAtGrant"
  | "exercisePriceToday"
  | "taxableBaseAtExercise"
  | "taxableBaseAtSale";

const StackIds = ["at_grant", "today"] as const;
type StackId = (typeof StackIds)[number];

const StackLabelsMap: Record<StackId, string> = {
  at_grant: "At grant",
  today: "Today",
} as const;

const TooltipLabels: Record<ValueId, string> = {
  discountAtGrant: "Discount at grant",
  exercisePriceAtGrant: "Exercise price at grant",
  exercisePriceToday: "Exercise price",
  taxableBaseAtExercise: "Potential taxable base at exercise",
  taxableBaseAtSale: "Potential taxable base at sale",
} as const;

const LegendLabels: Record<ValueId, null | string> = {
  ...TooltipLabels,
  exercisePriceToday: null,
} as const;

const BarsDataKeysWithStack = [
  {
    className: classNames("bg-purple-07 fill-purple-07"),
    key: "exercisePriceAtGrant",
    stackId: "at_grant",
  },
  {
    className: classNames("bg-purple-03 fill-purple-03"),
    key: "discountAtGrant",
    stackId: "at_grant",
  },
  {
    className: classNames("bg-black-07 fill-black-07"),
    key: "exercisePriceToday",
    stackId: "today",
  },
  {
    className: classNames("bg-primary-05 fill-primary-05"),
    key: "taxableBaseAtExercise",
    stackId: "today",
  },
  {
    className: classNames("bg-primary-03 fill-primary-03"),
    key: "taxableBaseAtSale",
    stackId: "today",
  },
] as const;

const PriceEvolutionGraph_: React.FC<{
  ctmsGrant: PriceEvolutionGraph_CTMSGrant$data;
  exercisePrice: number;
  graphMode: PriceEvolutionGraphMode;
  height: number;
  latestFairMarketValue: number;
  latestPricePerShare: number;
  organization: PriceEvolutionGraph_Organization$data;
  pricePerShareAtGrant: number;
}> = ({
  ctmsGrant,
  exercisePrice,
  graphMode,
  height,
  latestFairMarketValue,
  latestPricePerShare,
  organization,
  pricePerShareAtGrant,
}) => {
  const TopLabelMap: Record<StackId, React.ReactNode> = useMemo(() => {
    const valueRecord = {
      at_grant: pricePerShareAtGrant,
      today: latestPricePerShare,
    };
    switch (graphMode) {
      case "Fully vested": {
        const labelRecord = {
          at_grant: "Initial valuation",
          today: "Latest valuation",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedCurrency
            label={labelRecord[key]}
            valueInUSD={value * ctmsGrant.quantityIssued}
          />
        ));
      }
      case "Per share": {
        const labelRecord = {
          at_grant: "Price per share",
          today: "Price per share",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedFMV
            label={labelRecord[key]}
            valueInUSD={value}
          />
        ));
      }
      case "Vested": {
        const labelRecord = {
          at_grant: "Initial valuation",
          today: "Latest valuation",
        };
        return mapRecordValues(valueRecord, (value, key) => (
          <StackTopLabelUsingFormattedCurrency
            label={labelRecord[key]}
            valueInUSD={value * ctmsGrant.cumulativeVested}
          />
        ));
      }
    }
  }, [pricePerShareAtGrant, latestPricePerShare, graphMode, ctmsGrant]);

  const barsDataValues: Record<ValueId, number> = useMemo(() => {
    const discountAtGrantDiff = Math.max(
      pricePerShareAtGrant - exercisePrice,
      0,
    );
    const taxableBaseAtExerciseDiff = Math.max(
      latestFairMarketValue - exercisePrice,
      0,
    );
    const taxableBaseAtSaleDiff = Math.max(
      latestPricePerShare - taxableBaseAtExerciseDiff - exercisePrice,
      0,
    );

    const record = {
      discountAtGrant: discountAtGrantDiff,
      exercisePriceAtGrant: exercisePrice,
      exercisePriceToday: exercisePrice,
      taxableBaseAtExercise: taxableBaseAtExerciseDiff,
      taxableBaseAtSale: taxableBaseAtSaleDiff,
    };

    switch (graphMode) {
      case "Fully vested":
        return mapRecordValues(
          record,
          (value) => value * ctmsGrant.quantityIssued,
        );
      case "Per share":
        return record;
      case "Vested":
        return mapRecordValues(
          record,
          (value) => value * ctmsGrant.cumulativeVested,
        );
    }
  }, [
    graphMode,
    ctmsGrant.cumulativeVested,
    ctmsGrant.quantityIssued,
    exercisePrice,
    latestFairMarketValue,
    latestPricePerShare,
    pricePerShareAtGrant,
  ]);

  const stacks = useMemo(
    () =>
      StackIds.map((key) => ({
        elements: BarsDataKeysWithStack.filter(
          ({ stackId }) => stackId === key,
        ).map(
          ({ className, key }) =>
            ({
              className,
              key,
              legendLabel: LegendLabels[key],
              value: barsDataValues[key],
            }) as const,
        ),
        key,
        label: StackLabelsMap[key],
      })),
    [barsDataValues],
  );

  const intl = useIntl();

  return (
    <BarGraph
      barGap={50}
      barSize={64}
      height={height}
      renderBarTopLabel={(stack) => TopLabelMap[stack.key]}
      renderTooltip={({ stackElement }) => (
        <TooltipContent
          exercisePrice={exercisePrice}
          graphMode={graphMode}
          label={TooltipLabels[stackElement.key]}
          latestFairMarketValue={latestFairMarketValue}
          latestPricePerShare={latestPricePerShare}
          organizationName={organization.name}
          pricePerShareAtGrant={pricePerShareAtGrant}
          stackElementKey={stackElement.key}
          value={stackElement.value}
        />
      )}
      showLegend
      stacks={stacks}
      yTickFormatter={(value: number) =>
        intl.formatNumber(value, {
          compactDisplay: "short",
          currency: "USD",
          notation: "compact",
          style: "currency",
        })
      }
    />
  );
};

export const PriceEvolutionGraph: React.FC<{
  ctmsGrantFragment: PriceEvolutionGraph_CTMSGrant$key;
  graphMode: PriceEvolutionGraphMode;
  height?: number;
  organizationFragment: PriceEvolutionGraph_Organization$key;
}> = ({ ctmsGrantFragment, graphMode, height = 190, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const exercisePrice =
    ctmsGrant.exercisePrice ?? ctmsGrant.easopGrant?.exercisePrice ?? null;
  const latestPricePerShare = organization.latestPricePerShare?.value ?? null;
  const latestFairMarketValue =
    organization.latestFairMarketValue?.value ?? null;
  const pricePerShareAtGrant = ctmsGrant.pricePerShareAtGrant;

  if (
    typeof exercisePrice !== "number" ||
    typeof latestPricePerShare !== "number" ||
    typeof latestFairMarketValue !== "number" ||
    typeof pricePerShareAtGrant !== "number"
  ) {
    return (
      <div className="w-full text-center">
        <MissingCTMSInformationTag organizationFragment={organization} />
      </div>
    );
  }

  return (
    <PriceEvolutionGraph_
      ctmsGrant={ctmsGrant}
      exercisePrice={exercisePrice}
      graphMode={graphMode}
      height={height}
      latestFairMarketValue={latestFairMarketValue}
      latestPricePerShare={latestPricePerShare}
      organization={organization}
      pricePerShareAtGrant={pricePerShareAtGrant}
    />
  );
};

const StackTopLabel: React.FC<{
  children: React.ReactNode;
  label: string;
}> = ({ children, label }) => {
  return (
    <div className="flex flex-col text-center">
      <Typography variant="Regular/Caption">{label}</Typography>
      <Typography variant="Medium/Caption">{children}</Typography>
    </div>
  );
};

export const StackTopLabelUsingFormattedFMV: React.FC<{
  label: string;
  valueInUSD: number;
}> = ({ label, valueInUSD }) => {
  return (
    <StackTopLabel label={label}>
      <FormattedFMV value={valueInUSD} />
    </StackTopLabel>
  );
};

export const StackTopLabelUsingFormattedCurrency: React.FC<{
  label: string;
  valueInUSD: number;
}> = ({ label, valueInUSD }) => {
  return (
    <StackTopLabel label={label}>
      <FormattedNumber
        currency="USD"
        maximumFractionDigits={0}
        style="currency"
        value={valueInUSD}
      />
    </StackTopLabel>
  );
};

const TooltipExplanationContent: React.FC<{
  label: string;
  labelValueLeft: string;
  labelValueRight: string;
  showTaxDisclaimer?: boolean;
  valueLeft: number;
  valueRight: number;
}> = ({
  label,
  labelValueLeft,
  labelValueRight,
  showTaxDisclaimer,
  valueLeft,
  valueRight,
}) => (
  <div className="flex flex-col gap-2 text-gray-07">
    <Typography variant="Regular/Caption">{label}</Typography>
    <div className="flex justify-between gap-6">
      <div className="flex flex-col">
        <Typography variant="Regular/Caption">{labelValueLeft}</Typography>
        <Typography className="text-white" variant="Medium/Extra Small">
          <FormattedFMV value={valueLeft} />
        </Typography>
      </div>
      <div className="flex flex-col">
        <Typography variant="Regular/Caption">{labelValueRight}</Typography>
        <Typography className="text-white" variant="Medium/Extra Small">
          <FormattedFMV value={valueRight} />
        </Typography>
      </div>
    </div>
    {showTaxDisclaimer ? (
      <Typography className="w-[300px] text-orange-02" variant="Medium/Caption">
        Subject to local rules and tax authorities’ guidelines and enforcement
        practices. Please consult{" "}
        <strong className="text-orange">our geographies portal</strong> for more
        details on potential taxation.
      </Typography>
    ) : null}
  </div>
);

const TooltipExplanation: React.FC<{
  exercisePrice: number;
  latestFairMarketValue: number;
  latestPricePerShare: number;
  pricePerShareAtGrant: number;
  stackElementKey: ValueId;
}> = ({
  exercisePrice,
  latestFairMarketValue,
  latestPricePerShare,
  pricePerShareAtGrant,
  stackElementKey,
}) => {
  switch (stackElementKey) {
    case "discountAtGrant":
      return (
        <TooltipExplanationContent
          label="Discount is the difference between:"
          labelValueLeft="Price Per Share at grant"
          labelValueRight="Exercise Price"
          valueLeft={pricePerShareAtGrant}
          valueRight={exercisePrice}
        />
      );
    case "taxableBaseAtExercise":
      return (
        <TooltipExplanationContent
          label="The potential taxable basis at exercise is the difference between:"
          labelValueLeft="Fair Market Value at exercise"
          labelValueRight="Exercise Price"
          showTaxDisclaimer
          valueLeft={latestFairMarketValue}
          valueRight={exercisePrice}
        />
      );
    case "taxableBaseAtSale":
      return (
        <TooltipExplanationContent
          label="The potential taxable basis at sale is the difference between:"
          labelValueLeft="Price Per Share at sale"
          labelValueRight="Fair Market Value at exercise"
          showTaxDisclaimer
          valueLeft={latestPricePerShare}
          valueRight={latestFairMarketValue}
        />
      );
    default:
      return null;
  }
};

const TooltipContent: React.FC<
  React.ComponentProps<typeof TooltipExplanation> & {
    graphMode: PriceEvolutionGraphMode;
    label: string;
    organizationName: string;
    value: number;
  }
> = ({ graphMode, label, organizationName, value, ...props }) => {
  const valueLabel = useMemo(() => {
    switch (graphMode) {
      case "Fully vested":
        return (
          <FormattedNumber
            currency="USD"
            maximumFractionDigits={0}
            style="currency"
            value={value}
          />
        );
      case "Per share":
        return (
          <>
            <FormattedFMV value={value} /> per share
          </>
        );
      case "Vested":
        return (
          <FormattedNumber
            currency="USD"
            maximumFractionDigits={0}
            style="currency"
            value={value}
          />
        );
    }
  }, [graphMode, value]);
  return (
    <div className="flex flex-col gap-2 rounded bg-black-07 p-4 text-left text-white">
      <Typography variant="Regular/Extra Small">
        {organizationName} <br /> {label}
      </Typography>
      <Typography variant="Medium/Small">{valueLabel}</Typography>
      <TooltipExplanation {...props} />
    </div>
  );
};
