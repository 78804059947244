/**
 * @generated SignedSource<<d1f0b630ab442a8a3c8651cd3f21e200>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useTrackRemoteEquityOnboardingFlowEvent_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly remoteCompanyId: string | null;
  readonly " $fragmentType": "useTrackRemoteEquityOnboardingFlowEvent_Organization";
};
export type useTrackRemoteEquityOnboardingFlowEvent_Organization$key = {
  readonly " $data"?: useTrackRemoteEquityOnboardingFlowEvent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useTrackRemoteEquityOnboardingFlowEvent_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useTrackRemoteEquityOnboardingFlowEvent_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteCompanyId",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b09ba5603b91d8880ee916049eff2191";

export default node;
