import { ChartBarIcon, TableCellsIcon } from "@heroicons/react/24/outline";
import { compact, isNil, round } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GranteePortalProjectionScenarioKey,
  ProjectionCard_GranteePortalProjectionScenarios$key,
} from "./__generated__/ProjectionCard_GranteePortalProjectionScenarios.graphql";
import { ProjectionGraph } from "./ProjectionGraph";
import { ProjectionTable } from "./ProjectionTable";
import { RoundedBox } from "./ui/RoundedBox";
import { Switch } from "./ui/Switch";
import { Typography } from "./ui/Typography";

const GRANTEE_PORTAL_PROJECTION_SCENARIOS_FRAGMENT = graphql`
  fragment ProjectionCard_GranteePortalProjectionScenarios on GranteePortalProjectionScenario
  @relay(plural: true) {
    key
    name
    multiple
    displayed
    additionalInformation
    ...ProjectionTable_GranteePortalProjectionScenarios
  }
`;

export type ProjectionMode = "CHART" | "TABLE";

export const ProjectionCardSwitch: React.FC<{
  selectedMode: ProjectionMode;
  setSelectedMode: (mode: ProjectionMode) => void;
}> = ({ selectedMode, setSelectedMode }) => {
  return (
    <Switch
      getOptionLabel={(option, selected) => {
        switch (option) {
          case "CHART":
            return (
              <Switch.Option
                icon={<ChartBarIcon />}
                label="Chart view"
                selected={selected}
              />
            );
          case "TABLE":
            return (
              <Switch.Option
                icon={<TableCellsIcon />}
                label="Table view"
                selected={selected}
              />
            );
        }
      }}
      getOptionValue={(option) => option}
      name="projection-card-mode"
      onChange={setSelectedMode}
      options={["CHART", "TABLE"] as const}
      selectedOption={selectedMode}
    />
  );
};

export const ProjectionCard: React.FC<{
  chartViewOnly?: boolean;
  latestPricePerShareValue: number;
  projectionScenariosFragment: ProjectionCard_GranteePortalProjectionScenarios$key;
  shares: number;
  valueAtGrant: null | number;
}> = ({
  chartViewOnly = false,
  latestPricePerShareValue,
  projectionScenariosFragment,
  shares,
  valueAtGrant,
}) => {
  const projectionScenarios = useFragment(
    GRANTEE_PORTAL_PROJECTION_SCENARIOS_FRAGMENT,
    projectionScenariosFragment,
  );

  const sharesToValue = useCallback(
    (shares: number) => round(shares * latestPricePerShareValue, 4),
    [latestPricePerShareValue],
  );

  const findProjectionScenario = useCallback(
    (key: GranteePortalProjectionScenarioKey) => {
      const projectionScenario = projectionScenarios.find(
        (projectionScenario) => projectionScenario.key === key,
      );
      if (!projectionScenario)
        throw new Error(`Missing projection scenario - ${key}`);
      return { ...projectionScenario };
    },
    [projectionScenarios],
  );

  const projectionGraphBars = useMemo(() => {
    const currentValueProjectionScenario =
      findProjectionScenario("CURRENT_VALUE");

    const projectionGraphBars: React.ComponentProps<
      typeof ProjectionGraph
    >["bars"] = compact([
      isNil(valueAtGrant)
        ? null
        : {
            key: "VALUE_AT_GRANT",
            label: "Value at grant",
            tooltip: (
              <ProjectionGraph.Tooltip
                barKey="VALUE_AT_GRANT"
                label="Value at grant"
                valueUSD={valueAtGrant}
              />
            ),
            value: valueAtGrant,
          },
      {
        key: currentValueProjectionScenario.key,
        label: currentValueProjectionScenario.name,
        tooltip: (
          <ProjectionGraph.Tooltip
            barKey={currentValueProjectionScenario.key}
            label={currentValueProjectionScenario.name}
            potentialValueUSD={sharesToValue(shares)}
          >
            {currentValueProjectionScenario.additionalInformation}
          </ProjectionGraph.Tooltip>
        ),
        value: sharesToValue(shares),
      },
      ...(["FIRST_ROUND", "SECOND_ROUND", "THIRD_ROUND"] as const).map(
        (key) => {
          const projectionScenario = findProjectionScenario(key);
          if (!projectionScenario.displayed) return null;
          const value = sharesToValue(shares * projectionScenario.multiple);
          return {
            key: projectionScenario.key,
            label: projectionScenario.name,
            tooltip: (
              <ProjectionGraph.Tooltip
                barKey={projectionScenario.key}
                label={projectionScenario.name}
                multiplier={projectionScenario.multiple}
                potentialValueUSD={value}
              >
                {projectionScenario.additionalInformation}
              </ProjectionGraph.Tooltip>
            ),
            value,
          };
        },
      ),
    ]);
    return projectionGraphBars;
  }, [findProjectionScenario, sharesToValue, valueAtGrant, shares]);

  const [selectedProjectionMode, setSelectedProjectionMode] =
    useState<ProjectionMode>("CHART");

  return (
    <div className="flex flex-col items-center gap-6">
      {!chartViewOnly && (
        <ProjectionCardSwitch
          selectedMode={selectedProjectionMode}
          setSelectedMode={setSelectedProjectionMode}
        />
      )}
      <RoundedBox className="w-full p-6" withBorder withShadow>
        <Typography variant="Medium/Small">
          Potential value projection
        </Typography>

        {chartViewOnly || selectedProjectionMode === "CHART" ? (
          <ProjectionGraph bars={projectionGraphBars} />
        ) : (
          <ProjectionTable
            className="mt-4 w-full"
            latestPricePerShareValue={latestPricePerShareValue}
            projectionScenariosFragment={projectionScenarios}
            shares={shares}
            valueAtGrant={valueAtGrant}
          />
        )}
      </RoundedBox>
    </div>
  );
};
