import React from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql } from "relay-runtime";

import { useToaster } from "../../../../components/Toaster";
import { Toast } from "../../../../components/ui/Toast";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { EquityTypeWorkRelationshipData } from "../../../../services/workRelationship";
import { AdminPortalContent_AdminPortalContent$key } from "./__generated__/AdminPortalContent_AdminPortalContent.graphql";
import { EquityTypeWorkRelationshipOverview_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipOverview_EquityType.graphql";
import {
  EquityTypeAdminPortalContentSlot,
  EquityTypeWorkRelationshipOverview_SaveAdminPortalContent_Mutation,
} from "./__generated__/EquityTypeWorkRelationshipOverview_SaveAdminPortalContent_Mutation.graphql";
import { EquityTypeWorkRelationshipOverview_Viewer$key } from "./__generated__/EquityTypeWorkRelationshipOverview_Viewer.graphql";
import AdminPortalContent from "./AdminPortalContent";

const EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipOverview_EquityType on EquityType {
    id
    taxResidenceCountry {
      name
    }
    eoREmployeeOverviewAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    directEmployeeOverviewAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    contractorOverviewAdminPortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipOverview_Viewer on Account {
    ...AdminPortalContent_Viewer
  }
`;

const SAVE_ADMIN_PORTAL_CONTENT_MUTATION = graphql`
  mutation EquityTypeWorkRelationshipOverview_SaveAdminPortalContent_Mutation(
    $equityTypeId: String!
    $equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot!
    $content: String!
  ) {
    saveEquityTypeAdminPortalContent(
      equityTypeId: $equityTypeId
      equityTypeAdminPortalContentSlot: $equityTypeAdminPortalContentSlot
      content: $content
    ) {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const REFRESH_QUERY = graphql`
  query EquityTypeWorkRelationshipOverview_Refresh_Query($equityTypeId: UUID!) {
    equityType(id: $equityTypeId) {
      ...EquityTypeWorkRelationshipOverview_EquityType
    }
  }
`;

const EquityTypeWorkRelationshipOverview: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipOverview_EquityType$key;
  viewerFragment: EquityTypeWorkRelationshipOverview_Viewer$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({ equityTypeFragment, viewerFragment, workRelationshipData }) => {
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const viewer = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT,
    viewerFragment,
  );

  const [saveAdminPortalContent, saveAdminPortalContentIsInFlight] =
    useSafeMutation<EquityTypeWorkRelationshipOverview_SaveAdminPortalContent_Mutation>(
      SAVE_ADMIN_PORTAL_CONTENT_MUTATION,
    );

  const toaster = useToaster();
  const relayEnvironment = useRelayEnvironment();
  const refreshEquityType = () => {
    // @ts-expect-error - relay-runtime types are not up-to-date
    fetchQuery(relayEnvironment, REFRESH_QUERY, {
      equityTypeId: equityType.id,
    }).subscribe({});
  };

  let adminPortalContent: AdminPortalContent_AdminPortalContent$key | null;
  let equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot;

  switch (workRelationshipData.slug) {
    case "contractor":
      adminPortalContent = equityType.contractorOverviewAdminPortalContent;
      equityTypeAdminPortalContentSlot =
        "contractorOverviewAdminPortalContentId";
      break;
    case "directEmployee":
      adminPortalContent = equityType.directEmployeeOverviewAdminPortalContent;
      equityTypeAdminPortalContentSlot =
        "directEmployeeOverviewAdminPortalContentId";
      break;
    case "eoREmployee":
      adminPortalContent = equityType.eoREmployeeOverviewAdminPortalContent;
      equityTypeAdminPortalContentSlot =
        "eoREmployeeOverviewAdminPortalContentId";
      break;
  }

  const handleAdminPortalContentSubmit = async (content: string) => {
    await saveAdminPortalContent({
      variables: {
        content,
        equityTypeAdminPortalContentSlot,
        equityTypeId: equityType.id,
      },
    });

    toaster.push(
      <Toast title="Wonderful!">The documentation has been saved!</Toast>,
    );

    refreshEquityType();
  };

  return (
    <AdminPortalContent
      adminPortalContentFragment={adminPortalContent}
      countryName={equityType.taxResidenceCountry.name}
      isLoading={saveAdminPortalContentIsInFlight}
      onSubmit={handleAdminPortalContentSubmit}
      viewerFragment={viewer}
    />
  );
};

export default EquityTypeWorkRelationshipOverview;
