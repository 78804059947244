import { GiftIcon } from "@heroicons/react/24/outline";
import { sumBy } from "lodash";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DetailsBox } from "../../../../components/DetailsBox";
import { FormattedFMV } from "../../../../components/FormattedFMV";
import { GranteeDetailsDraftsList } from "../../../../components/GranteeDetailsDraftsList";
import { GranteeDetailsGrantsList } from "../../../../components/GranteeDetailsGrantsList";
import {
  SharesValue,
  SharesValueMode,
} from "../../../../components/SharesValue";
import {
  SharesValueSwitcher,
  useSharesValueSwitcherMode,
} from "../../../../components/SharesValueSwitcher";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import {
  GranteeTotalOwnershipBox_Grantee$data,
  GranteeTotalOwnershipBox_Grantee$key,
} from "./__generated__/GranteeTotalOwnershipBox_Grantee.graphql";
import {
  GranteeTotalOwnershipBox_Organization$data,
  GranteeTotalOwnershipBox_Organization$key,
} from "./__generated__/GranteeTotalOwnershipBox_Organization.graphql";
import { GranteeTotalOwnershipBox_Viewer$key } from "./__generated__/GranteeTotalOwnershipBox_Viewer.graphql";
import { GranteeDetailsEquityGridLevelTag } from "./GranteeDetailsEquityGridLevelTag";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTotalOwnershipBox_Organization on Organization {
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    equityGrid {
      activated
    }
    ...GranteeDetailsEquityGridLevelTag_Organization
    ...GranteeDetailsGrantsList_Organization
    ...GranteeDetailsDraftsList_Organization
    ...SharesValue_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTotalOwnershipBox_Grantee on Grantee {
    totalVestedSharesBreakdown {
      real
      virtual
      total
    }
    totalGrantedSharesBreakdown {
      real
      virtual
      total
    }
    easopGrantsWithoutCtmsGrant {
      quantityGranted
    }
    ctmsGrantsCount
    ...GranteeDetailsGrantsList_Grantee
    ...GranteeDetailsDraftsList_Grantee
    ...GranteeDetailsEquityGridLevelTag_Grantee
  }
`;

const PotentialValueVested: React.FC<{
  equityMode: SharesValueMode;
  grantee: GranteeTotalOwnershipBox_Grantee$data;
  organization: GranteeTotalOwnershipBox_Organization$data;
}> = ({ equityMode, grantee, organization }) => {
  const title = useMemo(() => {
    switch (equityMode) {
      case "FULLY_DILUTED":
        return "% fully diluted vested";
      case "SHARES":
        return "# shares vested";
      case "USD_VALUE":
        return "Potential $ value vested";
    }
  }, [equityMode]);

  return (
    <div className="flex flex-col items-center gap-1">
      <Typography className="text-black-05" variant="Regular/Extra Small">
        {title}
      </Typography>
      {equityMode === "USD_VALUE" && organization.latestPricePerShare && (
        <Tag color="purple">
          Current PPS:{" "}
          <FormattedFMV value={organization.latestPricePerShare.value} />
        </Tag>
      )}
      <SharesValue
        mode={equityMode}
        organizationFragment={organization}
        shares={grantee.totalVestedSharesBreakdown.total}
        variant="Medium/Extra Large"
      />
      {grantee.totalGrantedSharesBreakdown.real > 0 &&
        grantee.totalGrantedSharesBreakdown.virtual > 0 && (
          <Typography className="flex gap-4" variant="Medium/Small">
            <span>
              <SharesValue
                mode={equityMode}
                organizationFragment={organization}
                shares={grantee.totalVestedSharesBreakdown.real}
                variant="Medium/Small"
              />{" "}
              ESOP
            </span>
            <span>
              <SharesValue
                mode={equityMode}
                organizationFragment={organization}
                shares={grantee.totalVestedSharesBreakdown.virtual}
                variant="Medium/Small"
              />{" "}
              VSOP
            </span>
          </Typography>
        )}
      <Typography className="text-black-05" variant="Regular/Small">
        /
        <SharesValue
          mode={equityMode}
          organizationFragment={organization}
          shares={grantee.totalGrantedSharesBreakdown.total}
          variant="Regular/Small"
        />
      </Typography>
    </div>
  );
};

const VIEWER_FRAGMENT = graphql`
  fragment GranteeTotalOwnershipBox_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

export const GranteeTotalOwnershipBox: React.FC<{
  granteeFragment: GranteeTotalOwnershipBox_Grantee$key;
  onAddEquityLevelClick: (
    grantee: GranteeTotalOwnershipBox_Grantee$data,
  ) => void;
  onCreateTopUpClick: () => void;
  organizationFragment: GranteeTotalOwnershipBox_Organization$key;
  viewerFragment: GranteeTotalOwnershipBox_Viewer$key;
}> = ({
  granteeFragment,
  onAddEquityLevelClick,
  onCreateTopUpClick,
  organizationFragment,
  viewerFragment,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const [selectedEquityMode, setSelectedEquityMode] =
    useSharesValueSwitcherMode({
      fullyDilutedShares: organization.fullyDilutedShares,
      initialMode: "USD_VALUE",
      latestPricePerShare: organization.latestPricePerShare?.value ?? null,
    });

  const showEquityGridFeature = organization.equityGrid.activated;

  const grantedSharesInDraft = useMemo(
    () =>
      sumBy(
        grantee.easopGrantsWithoutCtmsGrant,
        (grant) => grant.quantityGranted,
      ),
    [grantee.easopGrantsWithoutCtmsGrant],
  );

  return (
    <DetailsBox Icon={GiftIcon} title="Total ownership">
      <div className="space-y-6">
        <div className="flex justify-center">
          <SharesValueSwitcher
            fullyDilutedShares={organization.fullyDilutedShares}
            latestPricePerShare={
              organization.latestPricePerShare?.value ?? null
            }
            onChange={setSelectedEquityMode}
            value={selectedEquityMode}
          />
        </div>
        <PotentialValueVested
          equityMode={selectedEquityMode}
          grantee={grantee}
          organization={organization}
        />
        {showEquityGridFeature && (
          <GranteeDetailsEquityGridLevelTag
            className="w-full"
            grantedSharesInDraft={grantedSharesInDraft}
            granteeFragment={grantee}
            onAddEquityLevelClick={() => {
              onAddEquityLevelClick(grantee);
            }}
            onCreateTopUpClick={onCreateTopUpClick}
            organizationFragment={organization}
            showActionButtons={viewer.isAllowedToManageOrganization}
          />
        )}
        <div className="divide-y-[0.5px] divide-gray-04">
          <GranteeDetailsGrantsList
            equityMode={selectedEquityMode}
            granteeFragment={grantee}
            organizationFragment={organization}
            spaceBetween={6}
          />
          <GranteeDetailsDraftsList
            equityMode={selectedEquityMode}
            granteeFragment={grantee}
            organizationFragment={organization}
            spaceBetween={6}
            startAtPosition={grantee.ctmsGrantsCount + 1}
          />
          <div className="flex items-center justify-between gap-6 py-[19px]">
            <Typography variant="Medium/Small">
              Total projected ownership
            </Typography>
            <SharesValue
              appendSharesLabel
              className="text-primary"
              mode={selectedEquityMode}
              organizationFragment={organization}
              shares={
                grantedSharesInDraft + grantee.totalGrantedSharesBreakdown.total
              }
              variant="Medium/Small"
            />
          </div>
        </div>
      </div>
    </DetailsBox>
  );
};
