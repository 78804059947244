/**
 * @generated SignedSource<<ae7953f09e3ce2bcd03673a31b055426>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOfferShareModal_EquityOffer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useEquityOfferShareUtils_EquityOffer" | "useEquityOfferTrackEvent_EquityOffer">;
  readonly " $fragmentType": "EquityOfferShareModal_EquityOffer";
};
export type EquityOfferShareModal_EquityOffer$key = {
  readonly " $data"?: EquityOfferShareModal_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferShareModal_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferShareModal_EquityOffer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEquityOfferShareUtils_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useEquityOfferTrackEvent_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "15205c6e1d7dbf5e3f96423d2b3ca1f0";

export default node;
