/**
 * @generated SignedSource<<e9b57f7801df6d14e086cec94dc56750>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
export type OrganizationPostTerminationExercisePeriodType = "FIXED" | "VARIABLE";
export type UpdateOrganizationPostTerminationExercisePeriodInput = {
  duration: number;
  durationUnit: DurationUnit;
  extensionDuration?: number | null;
  extensionDurationUnit?: DurationUnit | null;
  id: any;
  thresholdForExtensionInYears?: number | null;
  type: OrganizationPostTerminationExercisePeriodType;
};
export type EditSlideOver_Mutation$variables = {
  input: UpdateOrganizationPostTerminationExercisePeriodInput;
};
export type EditSlideOver_Mutation$data = {
  readonly updateOrganizationPostTerminationExercisePeriod: {
    readonly __typename: string;
  };
};
export type EditSlideOver_Mutation = {
  response: EditSlideOver_Mutation$data;
  variables: EditSlideOver_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSlideOver_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSlideOver_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9ad24cc5b74e1752c26f2719139396a7",
    "id": null,
    "metadata": {},
    "name": "EditSlideOver_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditSlideOver_Mutation(\n  $input: UpdateOrganizationPostTerminationExercisePeriodInput!\n) {\n  updateOrganizationPostTerminationExercisePeriod(input: $input) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "605b5454c1fdaa8ad1df02cf8ff559bb";

export default node;
