export const MazeLogo = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 132 24"
    width="132"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_66_7594)">
      <path
        clipRule="evenodd"
        d="M2.43879 21.0941C-0.562969 18.1178 -0.562921 13.2923 2.43888 10.316L10.5919 2.23224C13.1295 -0.283773 16.9999 -0.672891 19.9497 1.06489C20.8622 1.6024 20.8542 2.83544 20.1037 3.57952C19.3533 4.3236 18.1282 4.25956 17.1096 3.96416C15.8052 3.5859 14.3383 3.90678 13.3096 4.92679L5.15653 13.0105C3.65563 14.4987 3.65561 16.9114 5.15649 18.3996C6.6574 19.8878 9.0909 19.8878 10.5918 18.3996L26.8977 2.23234C29.8996 -0.743955 34.7664 -0.743968 37.7683 2.23231L45.9214 10.316C48.9232 13.2922 48.9232 18.1178 45.9214 21.0941C42.9196 24.0704 38.0527 24.0704 35.0508 21.0941L32.3332 18.3996L28.9361 21.7678C26.3985 24.2838 22.5281 24.6729 19.5782 22.9351C18.6658 22.3976 18.6738 21.1646 19.4243 20.4205C20.1747 19.6764 21.3997 19.7404 22.4184 20.0358C23.7228 20.4141 25.1897 20.0932 26.2184 19.0732L29.7126 15.6088C31.1599 14.1738 33.5064 14.1738 34.9538 15.6088L37.7685 18.3996C39.2694 19.8877 41.7028 19.8877 43.2037 18.3996C44.7046 16.9114 44.7046 14.4987 43.2037 13.0105L35.0506 4.92688C33.5497 3.43874 31.1163 3.43875 29.6154 4.9269L13.3095 21.0942C10.3076 24.0705 5.44061 24.0705 2.43879 21.0941Z"
        fill="#485864"
        fillRule="evenodd"
      />
      <path
        d="M78.875 4.32031C76.9862 4.32031 75.5552 4.99667 74.6108 6.3494C73.7809 4.99667 72.4644 4.32031 70.7187 4.32031C68.9157 4.32031 67.5992 4.94031 66.7406 6.20849V4.71486H63.0488V18.8057H66.7406V10.8866C66.7406 8.8294 67.7995 7.70213 69.4308 7.70213C70.9476 7.70213 71.8348 8.68849 71.8348 10.3794V18.8057H75.5266V10.8866C75.5266 8.8294 76.4997 7.70213 78.1882 7.70213C79.705 7.70213 80.5921 8.68849 80.5921 10.3794V18.8057H84.284V10.1257C84.284 6.60304 82.1662 4.32031 78.875 4.32031Z"
        fill="#485864"
      />
      <path
        d="M98.0089 6.37758C96.8928 4.99667 95.3473 4.32031 93.344 4.32031C91.4265 4.32031 89.7954 5.05304 88.4217 6.49031C87.0766 7.92758 86.3896 9.67486 86.3896 11.7603C86.3896 13.8457 87.0766 15.6212 88.4217 17.0585C89.7954 18.4957 91.4265 19.2003 93.344 19.2003C95.3473 19.2003 96.8928 18.5239 98.0089 17.143V18.8057H101.701V4.71486H98.0089V6.37758ZM91.1976 14.6348C90.4535 13.8739 90.0815 12.9157 90.0815 11.7603C90.0815 10.6048 90.4535 9.64667 91.1976 8.91395C91.9417 8.15304 92.8862 7.78667 94.0309 7.78667C95.1757 7.78667 96.1201 8.15304 96.8642 8.91395C97.6369 9.64667 98.0089 10.6048 98.0089 11.7603C98.0089 12.9157 97.6369 13.8739 96.8642 14.6348C96.1201 15.3676 95.1757 15.7339 94.0309 15.7339C92.8862 15.7339 91.9417 15.3676 91.1976 14.6348Z"
        fill="#485864"
      />
      <path
        d="M115.323 7.13847V4.71484H104.591V8.12484H110.343L104.304 16.3821V18.8057H115.609V15.3957H109.255L115.323 7.13847Z"
        fill="#485864"
      />
      <path
        d="M131.045 13.2539C131.131 12.7185 131.188 12.2394 131.188 11.7603C131.188 9.64667 130.502 7.8994 129.128 6.46213C127.783 5.02485 126.037 4.32031 123.948 4.32031C121.744 4.32031 119.913 5.02485 118.51 6.46213C117.108 7.87122 116.392 9.64667 116.392 11.7603C116.392 13.9021 117.108 15.6776 118.539 17.0866C119.97 18.4957 121.859 19.2003 124.234 19.2003C126.981 19.2003 129.071 18.1857 130.473 16.1848L127.497 14.4939C126.781 15.4239 125.694 15.903 124.291 15.903C122.088 15.903 120.743 15.0294 120.256 13.2539H131.045ZM120.199 10.4357C120.628 8.63213 121.973 7.5894 123.948 7.5894C125.636 7.5894 127.067 8.5194 127.497 10.4357H120.199Z"
        fill="#485864"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_7594">
        <rect
          fill="white"
          height="24"
          transform="translate(0.1875)"
          width="131"
        />
      </clipPath>
    </defs>
  </svg>
);
