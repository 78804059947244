/**
 * @generated SignedSource<<dee78b514c95f04b6c82438b05c9d779>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type NewAccelerationAllowedSlider_CTMSGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly id: string;
  readonly " $fragmentType": "NewAccelerationAllowedSlider_CTMSGrant";
};
export type NewAccelerationAllowedSlider_CTMSGrant$key = {
  readonly " $data"?: NewAccelerationAllowedSlider_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewAccelerationAllowedSlider_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewAccelerationAllowedSlider_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "9df0a6ababfa900ee75fabf8a7a5ca1a";

export default node;
