import classNames from "classnames";
import { range } from "lodash";
import { forwardRef, useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { Typography } from "./ui/Typography";

interface Props {
  id?: string;
  max: number;
  min: number;
  onChange: (value: number) => void;
  renderStepLabel: (props: {
    active: boolean;
    step: number;
  }) => React.ReactNode;
  step: number;
  tone?: Tone;
  value: number;
}

type Tone = "primary" | "purple";

const TONE_STYLE: Record<Tone, string> = {
  primary: "bg-primary",
  purple: "bg-purple-600",
};

const _RangeInput = forwardRef<HTMLInputElement, Props>(function RangeInput(
  { id, max, min, onChange, renderStepLabel, step, tone = "primary", value },
  ref,
) {
  const steps = useMemo(() => range(min, max + step, step), [min, max, step]);
  const stepsCount = steps.length;
  const widthAsPercentage = useMemo(() => {
    const minWidth = 50 / stepsCount;
    const maxWidth = 100 - 50 / stepsCount;
    const width =
      ((value - min) / (max - min)) * (maxWidth - minWidth) + minWidth;
    return width;
  }, [stepsCount, max, min, value]);
  return (
    <div className="relative space-y-2">
      <div className="absolute left-0 top-3 h-[6px] w-full rounded bg-gray-04">
        <div
          className={classNames("relative h-full rounded-l", TONE_STYLE[tone])}
          style={{
            width: `${widthAsPercentage}%`,
          }}
        >
          <div
            className={classNames(
              "absolute -right-2 bottom-0 top-0 my-auto h-5 w-5 rounded-full p-1 shadow-100",
              TONE_STYLE[tone],
            )}
          >
            <div className="h-full w-full rounded-full bg-white" />
          </div>
        </div>
      </div>
      <input
        className="h-2 w-full cursor-pointer opacity-0"
        id={id}
        max={max}
        min={min}
        onChange={(e) => {
          onChange(e.target.valueAsNumber);
        }}
        ref={ref}
        step={step}
        type="range"
        value={value}
      />
      <div className="grid grid-flow-col text-black-05">
        {steps.map((step) => {
          const active = step === value;

          return (
            <div className="flex justify-center" key={step}>
              <Typography
                className={classNames(
                  "cursor-pointer select-none rounded-lg px-[9px] py-[3px] text-center transition-all",

                  {
                    "bg-grey-900 text-white": active,
                  },
                )}
                onClick={() => {
                  onChange(step);
                }}
                variant="Regular/Caption"
              >
                {renderStepLabel({
                  active,
                  step,
                })}
              </Typography>
            </div>
          );
        })}
      </div>
    </div>
  );
});

const FormRangeInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: Omit<React.ComponentProps<typeof _RangeInput>, "onChange" | "value"> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <_RangeInput {...field} {...props} />}
    />
  );
};

export const RangeInput = Object.assign(_RangeInput, {
  Form: FormRangeInput,
});
