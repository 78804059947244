import {
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  EllipsisHorizontalIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { RefreshSquare } from "iconsax-react";
import { compact } from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../paths";
import {
  PlanningEntriesTable_Organization$data,
  PlanningEntriesTable_Organization$key,
} from "./__generated__/PlanningEntriesTable_Organization.graphql";
import {
  PlanningEntriesTable_PlanningEntries$data,
  PlanningEntriesTable_PlanningEntries$key,
} from "./__generated__/PlanningEntriesTable_PlanningEntries.graphql";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { GranteeNameWithCountryFlag } from "./GranteeNameWithCountryFlag";
import {
  NewHirePlanningEntryModal,
  useNewHirePlanningEntryModalState,
} from "./NewHirePlanningEntryModal";
import {
  PlanningEntryDeletionConfirmationModal,
  usePlanningEntryDeletionConfirmationModalState,
} from "./PlanningEntryDeletionConfirmationModal";
import {
  PlanningEntryDetailsSlideOver,
  usePlanningEntryDetailsSlideOverState,
} from "./PlanningEntryDetailsSlideOver/PlanningEntryDetailsSlideOver";
import { PlanningEntryTypeTag } from "./PlanningEntryTypeTag";
import {
  RefresherGrantPlanningEntryModal,
  useRefresherGrantPlanningEntryModalState,
} from "./RefresherGrantPlanningEntryModal";
import { StopClickPropagationWrapper } from "./StopClickPropagationWrapper";
import {
  TerminationPlanningEntryModal,
  useTerminationPlanningEntryModalState,
} from "./TerminationPlanningEntryModal";
import { Button, LinkButton } from "./ui/Button";
import { MenuButton } from "./ui/MenuButton";
import { Table } from "./ui/Table";
import { Tag } from "./ui/Tag";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningEntriesTable_Organization on Organization {
    id
    equityGrid {
      activated
    }
    ...PlanningEntryDetailsSlideOver_Organization
    ...useOrganizationSharesUtil_Organization
    ...NewHirePlanningEntryModal_Organization
    ...TerminationPlanningEntryModal_Organization
    ...RefresherGrantPlanningEntryModal_Organization
  }
`;

const PLANNING_ENTRIES_FRAGMENT = graphql`
  fragment PlanningEntriesTable_PlanningEntries on PlanningEntry
  @relay(plural: true) {
    __typename
    ... on NewHireGrantPlanningEntry {
      id
      position
      newHirerEntrySharesGranted: sharesGranted
      duplicable
      editable
      canBeConvertedToDraft
      type
      createdFromSuggestion
      easopGrant {
        id
      }
      equityGridLevel {
        name
      }
      ...PlanningEntryDetailsSlideOver_PlanningEntry
      ...NewHirePlanningEntryModal_NewHireGrantPlanningEntry
    }
    ... on RefresherGrantPlanningEntry {
      id
      duplicable
      editable
      canBeConvertedToDraft
      type
      refresherType
      sharesGranted
      createdFromSuggestion
      easopGrant {
        id
      }
      grantee {
        name
        jobTitle
        equityGridLevel {
          name
        }
        ...GranteeNameWithCountryFlag_Grantee
      }
      ...PlanningEntryDetailsSlideOver_PlanningEntry
      ...RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry
    }
    ... on TerminationPlanningEntry {
      id
      duplicable
      editable
      canBeConvertedToDraft
      type
      sharesReturning
      createdFromSuggestion
      easopGrant {
        id
      }
      grantee {
        name
        jobTitle
        equityGridLevel {
          name
        }
        ...GranteeNameWithCountryFlag_Grantee
      }
      ...PlanningEntryDetailsSlideOver_PlanningEntry
      ...TerminationPlanningEntryModal_TerminationPlanningEntry
    }
  }
`;

type PlanningEntry = PlanningEntriesTable_PlanningEntries$data[number] & {
  __typename: PlanningEntryTypename;
};
type PlanningEntryTypename = Exclude<
  PlanningEntriesTable_PlanningEntries$data[number]["__typename"],
  "%other"
>;

const columnHelper = createColumnHelper<PlanningEntry>();

const getEntryName = (entry: PlanningEntry) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
      return "New hire";
    case "RefresherGrantPlanningEntry":
    case "TerminationPlanningEntry":
      return entry.grantee.name;
  }
};
const FormattedEntryName: React.FC<{ entry: PlanningEntry }> = ({ entry }) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
      return "New hire";
    case "RefresherGrantPlanningEntry":
    case "TerminationPlanningEntry":
      return <GranteeNameWithCountryFlag granteeFragment={entry.grantee} />;
  }
};
const getEntryPosition = (entry: PlanningEntry) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
      return entry.position;
    case "RefresherGrantPlanningEntry":
    case "TerminationPlanningEntry":
      return entry.grantee.jobTitle;
  }
};
const getEntryEquityGridLevel = (entry: PlanningEntry) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
      return entry.equityGridLevel?.name;
    case "RefresherGrantPlanningEntry":
    case "TerminationPlanningEntry":
      return entry.grantee.equityGridLevel?.name;
  }
};

const getEntryRerservedShares = (entry: PlanningEntry) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
      return entry.newHirerEntrySharesGranted;
    case "RefresherGrantPlanningEntry":
      return entry.sharesGranted;
    case "TerminationPlanningEntry":
      return null;
  }
};
const getEntryReturningShares = (entry: PlanningEntry) => {
  switch (entry.__typename) {
    case "NewHireGrantPlanningEntry":
    case "RefresherGrantPlanningEntry":
      return null;
    case "TerminationPlanningEntry":
      return entry.sharesReturning;
  }
};

const Equity = ({
  organization,
  shares,
}: {
  organization: PlanningEntriesTable_Organization$data;
  shares: number;
}) => {
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const ownership = sharesToFullyDilutedRatio(shares);
  const usdValue = sharesToValue(shares);

  return (
    <div className="space-y-1 text-right">
      {usdValue !== null && (
        <Typography as="div" variant="Medium/Caption">
          <FormattedNumber
            currency="USD"
            maximumFractionDigits={0}
            style="currency"
            value={usdValue}
          />
        </Typography>
      )}
      <Typography as="div" variant="Regular/Caption">
        <FormattedNumber value={shares} /> shares
      </Typography>
      {ownership !== null && (
        <Typography as="div" variant="Regular/Caption">
          <FormattedPercentage value={ownership} />
        </Typography>
      )}
    </div>
  );
};

export const PlanningEntriesTable: React.FC<{
  onDuplicatePlanningEntryClick: (planningEntryId: string) => void;
  organizationFragment: PlanningEntriesTable_Organization$key;
  planningEntriesFragment: PlanningEntriesTable_PlanningEntries$key;
}> = ({
  onDuplicatePlanningEntryClick,
  organizationFragment,
  planningEntriesFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntries = useFragment(
    PLANNING_ENTRIES_FRAGMENT,
    planningEntriesFragment,
  );

  const {
    closePlanningEntryDeletionConfirmationModal,
    planningEntryDeletionConfirmationModalState,
    showPlanningEntryDeletionConfirmationModal,
  } = usePlanningEntryDeletionConfirmationModalState();

  const {
    closePlanningEntryDetailsSlideOver,
    openPlanningEntryDetailsSlideOver,
    planningEntryDetailsSlideOverState,
  } = usePlanningEntryDetailsSlideOverState();

  const {
    closeNewHirePlanningEntryModal,
    newHirePlanningEntryModalState,
    openNewHirePlanningEntryModalInEditMode,
  } = useNewHirePlanningEntryModalState();
  const {
    closeTerminationPlanningEntryModal,
    openTerminationPlanningEntryModalInEditMode,
    terminationPlanningEntryModalState,
  } = useTerminationPlanningEntryModalState();

  const {
    closeRefresherGrantPlanningEntryModal,
    openRefresherGrantPlanningEntryModalInEditMode,
    refresherGrantPlanningEntryModalState,
  } = useRefresherGrantPlanningEntryModalState();

  const handleEditClick = useCallback(
    (planningEntry: PlanningEntry) => {
      switch (planningEntry.__typename) {
        case "NewHireGrantPlanningEntry":
          openNewHirePlanningEntryModalInEditMode(planningEntry);
          break;
        case "RefresherGrantPlanningEntry":
          openRefresherGrantPlanningEntryModalInEditMode({
            editedPlanningEntryFragment: planningEntry,
            refresherGrantPlanningEntryType: planningEntry.refresherType,
          });
          break;
        case "TerminationPlanningEntry":
          openTerminationPlanningEntryModalInEditMode(planningEntry);
          break;
      }
    },
    [
      openNewHirePlanningEntryModalInEditMode,
      openRefresherGrantPlanningEntryModalInEditMode,
      openTerminationPlanningEntryModalInEditMode,
    ],
  );

  const columns = useMemo(
    () =>
      compact([
        columnHelper.accessor((row) => getEntryName(row), {
          cell: (context) => {
            const planningEntry = context.row.original;
            return (
              <div className="flex items-center gap-1">
                {planningEntry.createdFromSuggestion && (
                  <div className="rounded bg-gradient-light-aubergine p-1">
                    <RefreshSquare
                      className="h-4 w-4 text-purple-04"
                      variant="Bulk"
                    />
                  </div>
                )}
                <div className="whitespace-nowrap">
                  <FormattedEntryName entry={planningEntry} />
                </div>
              </div>
            );
          },
          enableSorting: true,
          header: () => "Name",
          id: "name",
        }),
        columnHelper.accessor((row) => getEntryRerservedShares(row), {
          cell: (context) => {
            const shares = context.getValue();

            return shares !== null ? (
              <Equity organization={organization} shares={shares} />
            ) : (
              "-"
            );
          },
          enableSorting: true,
          header: () => "Reserved",
          id: "reservedShares",
          meta: { alignRight: true },
        }),
        columnHelper.accessor((row) => getEntryReturningShares(row), {
          cell: (context) => {
            const shares = context.getValue();

            return shares !== null ? (
              <Equity organization={organization} shares={shares} />
            ) : (
              "-"
            );
          },
          enableSorting: true,
          header: () => "Returning",
          id: "returningShares",
          meta: { alignRight: true },
        }),
        columnHelper.accessor((row) => getEntryPosition(row), {
          cell: (context) => {
            const position = context.getValue();
            return <span className="text-black-05">{position}</span>;
          },
          enableSorting: true,
          header: () => "Position",
          id: "position",
        }),
        organization.equityGrid.activated
          ? columnHelper.accessor((row) => getEntryEquityGridLevel(row), {
              cell: (context) => {
                const equityGridLevel = context.getValue();
                return equityGridLevel ? (
                  <Tag className="whitespace-nowrap" color="gray">
                    {equityGridLevel}
                  </Tag>
                ) : (
                  "-"
                );
              },
              enableSorting: true,
              header: () => "Level",
              id: "level",
            })
          : null,
        columnHelper.accessor((row) => row.type, {
          cell: (context) => {
            const type = context.getValue();

            return <PlanningEntryTypeTag type={type} />;
          },
          enableSorting: true,
          header: () => "Grant type",
          id: "type",
        }),
        columnHelper.accessor(() => null, {
          cell: (context) => {
            const planningEntry = context.row.original;
            return (
              <StopClickPropagationWrapper>
                <div className="flex items-center justify-end gap-2">
                  {planningEntry.easopGrant ? (
                    <>
                      <TooltipContainer
                        tooltipContent={
                          <>
                            Drafted means the grant has been turned into a draft
                            and is now in the process of being sent for approval
                            and implemented. If it gets implemented, it will
                            disappear from the planning. If it gets removed from
                            the draft workflow, it will come back as a planned
                            grant.
                          </>
                        }
                      >
                        <Tag color="green">Drafted</Tag>
                      </TooltipContainer>
                      <LinkButton
                        rightIcon={<ArrowTopRightOnSquareIcon />}
                        size="extra small"
                        to={generatePath(
                          APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
                          {
                            easopGrantId: planningEntry.easopGrant.id,
                            organizationId: organization.id,
                          },
                        )}
                        variant="Secondary Full"
                      />
                    </>
                  ) : (
                    <>
                      <MenuButton
                        button={
                          <Button
                            leftIcon={<EllipsisHorizontalIcon />}
                            size="extra small"
                            variant="Secondary Full"
                          />
                        }
                      >
                        {planningEntry.editable && (
                          <MenuButton.Item
                            leftIcon={<PencilIcon />}
                            onClick={() => {
                              handleEditClick(planningEntry);
                            }}
                          >
                            Edit
                          </MenuButton.Item>
                        )}
                        {planningEntry.duplicable && (
                          <MenuButton.Item
                            leftIcon={<DocumentDuplicateIcon />}
                            onClick={() => {
                              onDuplicatePlanningEntryClick(planningEntry.id);
                            }}
                          >
                            Duplicate
                          </MenuButton.Item>
                        )}
                        <MenuButton.Item
                          leftIcon={<TrashIcon />}
                          onClick={() => {
                            showPlanningEntryDeletionConfirmationModal(
                              planningEntry.id,
                            );
                          }}
                        >
                          Delete
                        </MenuButton.Item>
                      </MenuButton>
                      {planningEntry.canBeConvertedToDraft && (
                        <LinkButton
                          size="extra small"
                          to={{
                            pathname: generatePath(
                              APPLICATION_ROUTES.organizationAssistedGrantGranteeGrantee,
                              { organizationId: organization.id },
                            ),
                            search: new URLSearchParams({
                              planningEntryId: planningEntry.id,
                            }).toString(),
                          }}
                          type="button"
                        >
                          Draft
                        </LinkButton>
                      )}
                    </>
                  )}
                </div>
              </StopClickPropagationWrapper>
            );
          },
          enableSorting: false,
          header: () => "Actions",
          id: "actions",
          meta: { alignRight: true },
        }),
      ]),
    [
      organization,
      handleEditClick,
      onDuplicatePlanningEntryClick,
      showPlanningEntryDeletionConfirmationModal,
    ],
  );

  const data = useMemo(
    () =>
      planningEntries.map((entry) => {
        if (entry.__typename === "%other") {
          throw new Error("Unexpected typename");
        }
        return entry;
      }),
    [planningEntries],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const organizationId = useOrganizationIdParam();

  return (
    <>
      <NewHirePlanningEntryModal
        onClose={closeNewHirePlanningEntryModal}
        organizationFragment={organization}
        state={newHirePlanningEntryModalState}
      />
      <TerminationPlanningEntryModal
        onClose={closeTerminationPlanningEntryModal}
        organizationFragment={organization}
        state={terminationPlanningEntryModalState}
      />
      <RefresherGrantPlanningEntryModal
        onClose={closeRefresherGrantPlanningEntryModal}
        organizationFragment={organization}
        state={refresherGrantPlanningEntryModalState}
      />
      <PlanningEntryDetailsSlideOver
        onClose={closePlanningEntryDetailsSlideOver}
        organizationFragment={organization}
        state={planningEntryDetailsSlideOverState}
      />
      <PlanningEntryDeletionConfirmationModal
        onClose={closePlanningEntryDeletionConfirmationModal}
        state={planningEntryDeletionConfirmationModalState}
      />
      <Table.Smart
        dataCy="planning-entries-table"
        rowRenderer={({ cells, rowData }) => {
          const planningEntry = rowData.original;

          if (planningEntry.easopGrant) {
            return (
              <Table.LinkRow
                to={generatePath(
                  APPLICATION_ROUTES.organizationPrepareYourGrantsDetails,
                  { easopGrantId: planningEntry.easopGrant.id, organizationId },
                )}
              >
                {cells}
              </Table.LinkRow>
            );
          }

          return (
            <Table.Row
              onClick={() => {
                openPlanningEntryDetailsSlideOver({
                  planningEntryFragment: planningEntry,
                });
              }}
            >
              {cells}
            </Table.Row>
          );
        }}
        table={table}
      />
    </>
  );
};
