import classNames from "classnames";

import { Typography } from "./Typography";

export const VerticallyLabeledValue: React.FC<
  React.ComponentProps<"div"> & { label: React.ReactNode }
> = ({ children, className, label }) => {
  return (
    <div className={classNames("space-y-1", className)}>
      <Typography
        as="div"
        className="text-black-05"
        variant="Regular/Extra Small"
      >
        {label}
      </Typography>
      <Typography as="div" variant="Medium/Extra Small">
        {children}
      </Typography>
    </div>
  );
};
