import { isAfter } from "date-fns";
import { reverse, uniq } from "lodash";
import { graphql, useFragment } from "react-relay";

import { useQuery } from "../hooks/useQuery";
import {
  DraftGrantVestingPreview_Grantee$data,
  DraftGrantVestingPreview_Grantee$key,
} from "./__generated__/DraftGrantVestingPreview_Grantee.graphql";
import { DraftGrantVestingPreview_VestingDataPoints_Query } from "./__generated__/DraftGrantVestingPreview_VestingDataPoints_Query.graphql";
import { VestingGraph } from "./VestingGraph";

const GRANTEE_FRAGMENT = graphql`
  fragment DraftGrantVestingPreview_Grantee on Grantee {
    activeCTMSGrants: ctmsGrants(grantStatusIn: [Active]) {
      id
      label
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
    easopGrantsWithoutCtmsGrant {
      id
      label
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
  }
`;

const VESTING_DATA_POINTS_QUERY = graphql`
  query DraftGrantVestingPreview_VestingDataPoints_Query(
    $vestingScheduleId: VestingScheduleId!
    $vestingStartDate: Date!
    $quantityGranted: Int!
  ) {
    vestingDataPoints(
      vestingStartDate: $vestingStartDate
      vestingScheduleId: $vestingScheduleId
      quantityGranted: $quantityGranted
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

const getCumulativeVestedAtDate = ({
  date,
  grant,
}: {
  date: Date;
  grant: DraftGrantVestingPreview_Grantee$data["activeCTMSGrants"][number];
}) => {
  const reversedVestingDataPoints = reverse([...grant.vestingDataPoints]);
  return (
    reversedVestingDataPoints.find(
      (dataPoint) => !isAfter(new Date(dataPoint.date), date),
    )?.cumulativeVested || 0
  );
};

export const DraftGrantVestingPreview: React.FC<{
  draftGrant: {
    id?: string;
    label: string;
    quantityGranted: number;
    vestingScheduleId: string;
    vestingStartDate: string;
  };
  granteeFragment: DraftGrantVestingPreview_Grantee$key;
}> = ({ draftGrant, granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const {
    query: { vestingDataPoints: draftGrantVestingDataPoints },
  } = useQuery<DraftGrantVestingPreview_VestingDataPoints_Query>(
    VESTING_DATA_POINTS_QUERY,
    {
      quantityGranted: draftGrant.quantityGranted,
      vestingScheduleId: draftGrant.vestingScheduleId,
      vestingStartDate: draftGrant.vestingStartDate,
    },
  );

  const otherGrants = [
    ...grantee.activeCTMSGrants,
    ...grantee.easopGrantsWithoutCtmsGrant,
  ]
    .filter(({ id }) => id !== draftGrant.id)
    .filter(({ vestingDataPoints }) =>
      vestingDataPoints.some(
        (vestingDataPoint) => vestingDataPoint.cumulativeVested > 0,
      ),
    );

  const existingGrantsVestingDataPointsMerged = otherGrants.flatMap(
    (grant) => grant.vestingDataPoints,
  );

  const allDates = uniq(
    existingGrantsVestingDataPointsMerged.map(({ date }) => date),
  ).sort();

  const vestingsDataPoints = [
    ...otherGrants.map((grant) => ({
      dataPoints: allDates.map((date) => ({
        cumulativeVested: getCumulativeVestedAtDate({
          date: new Date(date),
          grant,
        }),
        date: new Date(date),
      })),
      key: grant.id,
      label: grant.label,
    })),
    {
      dataPoints: draftGrantVestingDataPoints.map(
        ({ cumulativeVested, date }) => ({
          cumulativeVested,
          date: new Date(date),
        }),
      ),
      key: "__draftGrantId",
      label: draftGrant.label,
    },
  ];

  return (
    <VestingGraph
      getGrantIndex={(key) =>
        vestingsDataPoints
          .map(({ key: _key }) => _key)
          .reverse()
          .indexOf(key)
      }
      vestingsDataPoints={vestingsDataPoints}
      vestingStartDate={draftGrantVestingDataPoints[0]?.date}
    />
  );
};
