/**
 * @generated SignedSource<<037ab3edfd72d00e978049be795601d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsEquityViewers_Item_EquityViewer$data = {
  readonly account: {
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  };
  readonly id: string;
  readonly " $fragmentType": "OrganizationSettingsEquityViewers_Item_EquityViewer";
};
export type OrganizationSettingsEquityViewers_Item_EquityViewer$key = {
  readonly " $data"?: OrganizationSettingsEquityViewers_Item_EquityViewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Item_EquityViewer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsEquityViewers_Item_EquityViewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityViewer",
  "abstractKey": null
};
})();

(node as any).hash = "25d3d28fe94141a439747ef3ecac5256";

export default node;
