/**
 * @generated SignedSource<<3c9e57af8d4899a9ddbc24ff1e6472f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityPlanThresholdsExceededPage_Organization$data = ReadonlyArray<{
  readonly billableGrantees: ReadonlyArray<{
    readonly __typename: "Grantee";
  }>;
  readonly billableOrganizationGeographies: ReadonlyArray<{
    readonly __typename: "OrganizationGeography";
  }>;
  readonly id: string;
  readonly name: string;
  readonly remoteEquityPlanCountryThreshold: number | null;
  readonly remoteEquityPlanEmployeeThreshold: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityPlanCountryThresholdProgressBar_Organization" | "RemoteEquityPlanEmployeeThresholdProgressBar_Organization">;
  readonly " $fragmentType": "RemoteEquityPlanThresholdsExceededPage_Organization";
}>;
export type RemoteEquityPlanThresholdsExceededPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: RemoteEquityPlanThresholdsExceededPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityPlanThresholdsExceededPage_Organization">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RemoteEquityPlanThresholdsExceededPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "billableGrantees",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "billableOrganizationGeographies",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanEmployeeThreshold",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanCountryThreshold",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityPlanEmployeeThresholdProgressBar_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteEquityPlanCountryThresholdProgressBar_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "83a4070e9f73e184101c58967fdec036";

export default node;
