import { InputSelect as _RemoteInputSelect } from "@remote-com/norma";
import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

const FormInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof _RemoteInputSelect> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const selectedOption = props.options.find(
          (option) => "value" in option && option.value === field.value,
        );
        return (
          <_RemoteInputSelect
            {...field}
            {...props}
            errorText={fieldState.error?.message}
            onChange={(option) => {
              field.onChange(option.value);
            }}
            value={
              selectedOption && "value" in selectedOption
                ? selectedOption
                : undefined
            }
          />
        );
      }}
    />
  );
};

export const RemoteInputSelect = Object.assign(_RemoteInputSelect, {
  Form: FormInput,
});
