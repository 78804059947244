export const CartaGlyph: React.FC<React.ComponentProps<"svg">> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        fill="white"
        height="30.9999"
        rx="15.4999"
        width="31"
        x="0.5"
        y="1"
      />
      <rect
        height="30.9999"
        rx="15.4999"
        stroke="#CDD6DF"
        width="31"
        x="0.5"
        y="1"
      />
      <path
        clipRule="evenodd"
        d="M6.40009 12.5L25.6001 12.5L25.6001 20.6454L6.40009 20.6454L6.40009 12.5ZM6.97687 13.0572L6.97687 20.0882L25.0233 20.0882L25.0233 13.0572L6.97687 13.0572Z"
        fill="#0F1419"
        fillRule="evenodd"
      />
      <path
        d="M10.6769 18.215C10.283 18.2164 9.90492 18.0647 9.62812 17.7939C9.35051 17.523 9.19695 17.1559 9.20163 16.7747C9.20163 15.8606 9.99461 15.3238 10.7052 15.3238C11.2135 15.3238 11.6888 15.5085 11.943 15.9394L11.4677 16.2076C11.3009 15.9634 11.0176 15.8172 10.7148 15.8194C10.2968 15.8194 9.79916 16.1371 9.79916 16.7667C9.79916 17.3962 10.2745 17.7187 10.7524 17.7187C11.0788 17.7153 11.3768 17.5383 11.5269 17.2583L12.0129 17.4776C11.7392 17.9582 11.248 18.215 10.6773 18.215H10.6769ZM13.7697 17.6943C14.3117 17.6943 14.6845 17.3005 14.6845 16.771C14.6845 16.2414 14.3115 15.8477 13.7697 15.8477C13.2279 15.8477 12.8419 16.2367 12.8419 16.7621C12.8419 17.2876 13.2155 17.6941 13.7697 17.6941V17.6943ZM13.7124 18.215C12.8539 18.215 12.2428 17.5853 12.2428 16.7692C12.2428 15.9532 12.8484 15.3238 13.7124 15.3238C14.109 15.3238 14.4907 15.4857 14.6737 15.7343L14.6737 15.3953L15.2607 15.3953L15.2607 18.1382L14.6737 18.1382L14.6737 17.7983C14.4814 18.0452 14.1082 18.2152 13.7124 18.2152V18.215ZM18.2268 18.137L18.2268 15.9251L17.7749 15.9251L17.7749 15.3771L18.2325 15.3771V14.5665L18.8373 14.5665V15.3772L19.4643 15.3772L19.4643 15.9251L18.8373 15.9251L18.8373 18.1366L18.2266 18.1366V18.137H18.2268ZM21.1957 17.6943C21.7375 17.6943 22.1111 17.3005 22.1111 16.771C22.1111 16.2414 21.7375 15.8477 21.1955 15.8477C20.6535 15.8477 20.2687 16.2367 20.2687 16.7621C20.2687 17.2876 20.6415 17.6941 21.1955 17.6941L21.1957 17.6943ZM21.1384 18.215C20.2793 18.215 19.6693 17.5853 19.6693 16.7692C19.6693 15.9532 20.2742 15.3238 21.1384 15.3238C21.5349 15.3238 21.9167 15.4857 22.0997 15.7343L22.0997 15.3953L22.6873 15.3953L22.6873 18.1382L22.0997 18.1382L22.0997 17.7983C21.9073 18.0452 21.5341 18.2152 21.1384 18.2152V18.215ZM15.8655 18.1337L15.8655 15.3908L16.4248 15.3908L16.4248 15.8673C16.5404 15.5594 16.785 15.3472 17.1092 15.3472C17.1796 15.3458 17.2497 15.3535 17.3183 15.3694L17.3183 15.8997C16.8619 15.8486 16.4763 16.1577 16.4763 16.8637L16.4763 18.1335L15.8655 18.1335V18.1337Z"
        fill="#0F1419"
      />
    </svg>
  );
};
