export const StopClickPropagationWrapper: React.FC<
  React.ComponentProps<"div">
> = ({ children, ...props }) => (
  <div
    {...props}
    onClick={(event) => {
      event.stopPropagation();
    }}
  >
    {children}
  </div>
);
