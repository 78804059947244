import { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { RangeInput } from "../../../../components/RangeInput";
import { VerticalDivider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { Typography } from "../../../../components/ui/Typography";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { CliffPeriods_Organization$key } from "./__generated__/CliffPeriods_Organization.graphql";
import { CliffPeriods_Query } from "./__generated__/CliffPeriods_Query.graphql";
import { CliffPeriodsGraph } from "./CliffPeriodsGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CliffPeriods_Organization on Organization {
    id
    name
    ...CliffPeriodsGraph_Organization
  }
`;

const AdminCliffPeriodsPage_: React.FC<{
  organizationFragment: CliffPeriods_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const [searchParams, setSearchParams] = useSearchParams();

  const threshold = useMemo(
    () => Number(searchParams.get("threshold") ?? String(2)),
    [searchParams],
  );

  const excludeRefreshers = useMemo(
    () => (searchParams.get("excludeRefreshers") ?? String(false)) === "true",
    [searchParams],
  );

  const setThreshold = useCallback(
    (threshold: number) =>
      setSearchParams(
        {
          excludeRefreshers: String(excludeRefreshers),
          threshold: String(threshold),
        },
        {
          replace: true,
        },
      ),
    [setSearchParams, excludeRefreshers],
  );

  const setExcludeRefreshers = useCallback(
    (excludeRefreshers: boolean) =>
      setSearchParams(
        {
          excludeRefreshers: String(excludeRefreshers),
          threshold: String(threshold),
        },
        {
          replace: true,
        },
      ),
    [setSearchParams, threshold],
  );

  const [
    granteesWhoHaventPassedCliffCount,
    setGranteesWhoHaventPassedCliffCount,
  ] = useState<null | number>(null);

  return (
    <InsightPage
      aboveChildren={
        <div className="flex gap-6">
          <FormRow
            className="flex-grow"
            label="Month-to-cliff threshold"
            subLabel="Define month-to-cliff threshold to get desired split"
          >
            <RangeInput
              id="threshold-range"
              max={12}
              min={1}
              onChange={setThreshold}
              renderStepLabel={({ step }) => step}
              step={1}
              value={threshold}
            />
          </FormRow>
          <VerticalDivider />
          <FormRow
            className="flex-grow"
            label="Exclude refresher grants"
            subLabel="This will not be relevant to see first time shareholders"
          >
            <Toggle
              className="mt-2"
              enabled={excludeRefreshers}
              onChange={setExcludeRefreshers}
              size="small"
            />
          </FormRow>
        </div>
      }
      subtitle="Split of grantees based on duration until passing of cliff"
      title="👩‍🎓 Cliff periods"
      yellowBanner={
        granteesWhoHaventPassedCliffCount ? (
          <>
            # of grantees who haven’t passed their cliff:{" "}
            <Typography variant="Medium/Extra Small">
              <FormattedMessage
                defaultMessage={`{granteesCount, plural,
                =0 {# grantee}
                =1 {# grantee}
                other {# grantees}
              }`}
                values={{ granteesCount: granteesWhoHaventPassedCliffCount }}
              />
            </Typography>
          </>
        ) : null
      }
    >
      <CliffPeriodsGraph
        excludeRefreshers={excludeRefreshers}
        onGranteesWhoHaventPassedCliffCountChange={
          setGranteesWhoHaventPassedCliffCount
        }
        organizationFragment={organization}
        thresholdInMonths={threshold}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query CliffPeriods_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...CliffPeriods_Organization
    }
  }
`;

const AdminCliffPeriodsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { organization } = useLazyLoadQuery<CliffPeriods_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Cliff Periods"
      analyticsName="Admin - Insights - Cliff Periods"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | cliff periods`}
    >
      <AdminCliffPeriodsPage_ organizationFragment={organization} />
    </Page>
  );
};

export default AdminCliffPeriodsPage;
