/**
 * @generated SignedSource<<6b965c3f889a085450bee0d4b9fe6d76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation$variables = {
  granteeId: string;
  hRISProvider: HRISProvider;
  hRISProviderEmployeeId: string;
};
export type MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation$data = {
  readonly matchHRISProviderEmployeeAndGrantee: {
    readonly __typename: "HRISProviderEmployee";
  };
};
export type MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation = {
  response: MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation$data;
  variables: MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hRISProvider"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hRISProviderEmployeeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeId",
    "variableName": "granteeId"
  },
  {
    "kind": "Variable",
    "name": "hRISProvider",
    "variableName": "hRISProvider"
  },
  {
    "kind": "Variable",
    "name": "hRISProviderEmployeeId",
    "variableName": "hRISProviderEmployeeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HRISProviderEmployee",
        "kind": "LinkedField",
        "name": "matchHRISProviderEmployeeAndGrantee",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HRISProviderEmployee",
        "kind": "LinkedField",
        "name": "matchHRISProviderEmployeeAndGrantee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e700af242d84dd580262a7a64879ce5",
    "id": null,
    "metadata": {},
    "name": "MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation",
    "operationKind": "mutation",
    "text": "mutation MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation(\n  $granteeId: GranteeId!\n  $hRISProvider: HRISProvider!\n  $hRISProviderEmployeeId: String!\n) {\n  matchHRISProviderEmployeeAndGrantee(granteeId: $granteeId, hRISProvider: $hRISProvider, hRISProviderEmployeeId: $hRISProviderEmployeeId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4df94b46f77d055970de4cfcb05131d8";

export default node;
