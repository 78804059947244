import { format } from "date-fns";
import { useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useCSVDownloadLink } from "../helpers/csv-utility";
import {
  DownloadUnderwaterGrantsCSV_CTMSGrant$data,
  DownloadUnderwaterGrantsCSV_CTMSGrant$key,
} from "./__generated__/DownloadUnderwaterGrantsCSV_CTMSGrant.graphql";
import { AnchorButton } from "./ui/Button";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment DownloadUnderwaterGrantsCSV_CTMSGrant on CTMSGrant
  @relay(plural: true) {
    label
    exercisePrice
    formattedGranteeName
    grantDate
    quantityIssued
    equityTypeAwardName
  }
`;

const GRANTS_CSV_HEADER = [
  "Grant label",
  "Name of the optionee",
  "Grant date",
  "Number of underlying shares",
  "Exercise price",
  "Equity type",
];

function useGrantsCSVDownloadLink({
  ctmsGrants,
}: {
  ctmsGrants: DownloadUnderwaterGrantsCSV_CTMSGrant$data;
}) {
  const intl = useIntl();

  const rows = ctmsGrants.map((grant) => {
    const row = [
      grant.label,
      grant.formattedGranteeName,
      format(new Date(grant.grantDate), "yyyy-MM-dd"),
      intl.formatNumber(grant.quantityIssued),
      typeof grant.exercisePrice === "number"
        ? intl.formatNumber(grant.exercisePrice, {
            currency: "USD",
            maximumFractionDigits: 21,
            style: "currency",
          })
        : "None",
      grant.equityTypeAwardName ?? "",
    ];

    return row;
  });

  return useCSVDownloadLink({
    header: GRANTS_CSV_HEADER,
    rows,
  });
}

export const DownloadUnderwaterGrantsCSV: React.FC<{
  ctmsGrantsFragment: DownloadUnderwaterGrantsCSV_CTMSGrant$key;
}> = ({ ctmsGrantsFragment }) => {
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);
  const csvDownloadLink = useGrantsCSVDownloadLink({
    ctmsGrants,
  });

  return (
    <AnchorButton
      download="underwater_grants.csv"
      href={csvDownloadLink}
      size="small"
      variant="Secondary Full"
    >
      Download Underwater grants list
    </AnchorButton>
  );
};
