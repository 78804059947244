import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { PostTerminationExercisePeriod_Organization$key } from "./__generated__/PostTerminationExercisePeriod_Organization.graphql";
import { PostTerminationExercisePeriod_Query } from "./__generated__/PostTerminationExercisePeriod_Query.graphql";
import { PostTerminationExercisePeriod_Viewer$key } from "./__generated__/PostTerminationExercisePeriod_Viewer.graphql";
import { PostTerminationExercisePeriodGraph } from "./PostTerminationExercisePeriodGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriod_Organization on Organization {
    id
    name
    ...PostTerminationExercisePeriodGraph_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriod_Viewer on Account {
    ...PostTerminationExercisePeriodGraph_Viewer
  }
`;

const AdminPostTerminationExercisePeriodPage_: React.FC<{
  organizationFragment: PostTerminationExercisePeriod_Organization$key;
  viewerFragment: PostTerminationExercisePeriod_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <InsightPage
      subtitle="Split of different PTEPs used and grantees associated"
      title="⌛️ Post-termination exercise periods"
    >
      <PostTerminationExercisePeriodGraph
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query PostTerminationExercisePeriod_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...PostTerminationExercisePeriod_Organization
    }
    me {
      ...PostTerminationExercisePeriod_Viewer
    }
  }
`;

const AdminPostTerminationExercisePeriodPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { me, organization } =
    useLazyLoadQuery<PostTerminationExercisePeriod_Query>(QUERY, {
      organizationId,
    });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Post-termination exercise periods"
      analyticsName="Admin - Insights - Post-termination exercise periods"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | post-termination exercise periods`}
    >
      <AdminPostTerminationExercisePeriodPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default AdminPostTerminationExercisePeriodPage;
