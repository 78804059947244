import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { InviteBoardMemberSlideOver } from "../../../components/InviteBoardMemberSlideOver";
import { Page } from "../../../components/Page";
import { useToaster } from "../../../components/Toaster";
import { Avatar } from "../../../components/ui/Avatar";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button, LinkButton } from "../../../components/ui/Button";
import { InfoCard } from "../../../components/ui/InfoCard";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Toast } from "../../../components/ui/Toast";
import { Typography } from "../../../components/ui/Typography";
import { WarningButton } from "../../../components/ui/WarningButton";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../hooks/useBoolean";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { OrganizationSettingsBoardMembers_Item_BoardMember$key } from "./__generated__/OrganizationSettingsBoardMembers_Item_BoardMember.graphql";
import {
  OrganizationSettingsBoardMembers_Organization$data,
  OrganizationSettingsBoardMembers_Organization$key,
} from "./__generated__/OrganizationSettingsBoardMembers_Organization.graphql";
import { OrganizationSettingsBoardMembers_Query } from "./__generated__/OrganizationSettingsBoardMembers_Query.graphql";
import { OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation } from "./__generated__/OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation.graphql";
import { OrganizationSettingsBoardMembers_Viewer$key } from "./__generated__/OrganizationSettingsBoardMembers_Viewer.graphql";
import { SettingsContainer } from "./SettingsContainer";

const REMOVE_BOARD_MEMBER_MUTATION = graphql`
  mutation OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation(
    $boardMemberId: UUID!
  ) {
    removeBoardMember(id: $boardMemberId) {
      ...OrganizationSettingsBoardMembers_Organization
    }
  }
`;

const ITEM_BOARD_MEMBER_FRAGMENT = graphql`
  fragment OrganizationSettingsBoardMembers_Item_BoardMember on BoardMember {
    id
    isRemovable
    account {
      id
      firstName
      lastName
      email
    }
  }
`;

const Item: React.FC<{
  boardMemberFragment: OrganizationSettingsBoardMembers_Item_BoardMember$key;
  onNonRemovableRemoveButtonClick: () => void;
}> = ({ boardMemberFragment, onNonRemovableRemoveButtonClick }) => {
  const boardMember = useFragment(
    ITEM_BOARD_MEMBER_FRAGMENT,
    boardMemberFragment,
  );
  const toaster = useToaster();
  const [removeBoardMember, removeBoardMemberMutationIsInFlight] =
    useSafeMutation<OrganizationSettingsBoardMembers_RemoveBoardMember_Mutation>(
      REMOVE_BOARD_MEMBER_MUTATION,
    );
  const handleConfirmBoardMemberRemovalButtonClick = useCallback(async () => {
    await removeBoardMember({
      variables: { boardMemberId: boardMember.id },
    });

    toaster.push(
      <Toast title="Wonderful!">Board member successfully removed!</Toast>,
    );
  }, [boardMember.id, removeBoardMember, toaster]);

  return (
    <SettingsContainer.Item.MultipleValues.Item className="group flex items-center gap-2 transition-all hover:bg-gray-01">
      <Avatar>{boardMember.account.firstName}</Avatar>
      <div className="flex-1 space-y-1">
        <Typography as="div" variant="Regular/Extra Small">
          {boardMember.account.firstName} {boardMember.account.lastName}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {boardMember.account.email}
        </Typography>
      </div>
      <WarningButton
        className="opacity-0 group-hover:opacity-100"
        loading={removeBoardMemberMutationIsInFlight}
        onConfirm={handleConfirmBoardMemberRemovalButtonClick}
        onPrevent={onNonRemovableRemoveButtonClick}
        shouldPrevent={!boardMember.isRemovable}
        size="small"
        variant="Danger Outline"
        warningContent={`This is going to remove ${boardMember.account.firstName}
          ${boardMember.account.lastName} from your board members. Please
          confirm this action`}
        warningTitle="Remove board member"
      >
        Remove
      </WarningButton>
    </SettingsContainer.Item.MultipleValues.Item>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationSettingsBoardMembers_Organization on Organization {
    id
    name
    boardMembers {
      id
      account {
        firstName
        lastName
        email
      }
      ...OrganizationSettingsBoardMembers_Item_BoardMember
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment OrganizationSettingsBoardMembers_Viewer on Account {
    firstName
  }
`;

const OrganizationSettingsBoardMembersPage_: React.FC<{
  organizationFragment: OrganizationSettingsBoardMembers_Organization$key;
  viewerFragment: OrganizationSettingsBoardMembers_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const {
    setFalse: hideInviteBoardMemberSlideOver,
    setTrue: showInviteBoardMemberSlideOver,
    value: inviteBoardMemberSlideOverIsShown,
  } = useBoolean(false);
  const [selectedNonRemovableBoardMember, setSelectedNonRemovableBoardMember] =
    useState<
      | null
      | OrganizationSettingsBoardMembers_Organization$data["boardMembers"][number]
    >(null);
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const handleNonRemovableRemoveButtonClick = useCallback(
    (
      boardMember: OrganizationSettingsBoardMembers_Organization$data["boardMembers"][number],
    ) =>
      () => {
        setSelectedNonRemovableBoardMember(boardMember);
      },
    [],
  );

  const buildHelpMailtoHref = useCallback(
    (
      boardMember: OrganizationSettingsBoardMembers_Organization$data["boardMembers"][number],
    ) => {
      const mailtoSubject = encodeURIComponent(
        `[${organization.name}] Removing a board member`,
      );

      const mailtoBody = encodeURIComponent(
        `I'm ${viewer.firstName}, admin at ${organization.name} and I would like to remove ${boardMember.account.firstName} ${boardMember.account.lastName} (${boardMember.account.email}) from our board members.`,
      );

      return `mailto:${supportEmailAddress}?subject=${mailtoSubject}&body=${mailtoBody}`;
    },
    [organization.name, supportEmailAddress, viewer.firstName],
  );

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Board members</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <InviteBoardMemberSlideOver
        onClose={hideInviteBoardMemberSlideOver}
        organizationId={organization.id}
        show={inviteBoardMemberSlideOverIsShown}
      />

      <SettingsContainer
        action={
          <Button onClick={showInviteBoardMemberSlideOver} size="small">
            + Add new
          </Button>
        }
        subTitle={`Invite your board members and observers here. Once invited, they will be able to sign board consents via ${applicationName}.`}
        title="Board members"
      >
        <SettingsContainer.Item>
          <div className="space-y-6">
            {selectedNonRemovableBoardMember && (
              <InfoCard
                icon={<ExclamationTriangleIcon />}
                title="This board member cannot be removed."
                variant="error"
              >
                <div className="space-y-2">
                  <div>
                    This board member is currently mandatory in the approval of
                    a grant.
                    <br />
                    <br />
                    Need to remove this board member?
                  </div>
                  <LinkButton
                    size="small"
                    to={buildHelpMailtoHref(selectedNonRemovableBoardMember)}
                    variant="Danger Outline"
                  >
                    Contact us
                  </LinkButton>
                </div>
              </InfoCard>
            )}
            {organization.boardMembers.length > 0 ? (
              <SettingsContainer.Item.MultipleValues>
                {organization.boardMembers.map((boardMember) => (
                  <Item
                    boardMemberFragment={boardMember}
                    key={boardMember.id}
                    onNonRemovableRemoveButtonClick={handleNonRemovableRemoveButtonClick(
                      boardMember,
                    )}
                  />
                ))}
              </SettingsContainer.Item.MultipleValues>
            ) : (
              <NoticeMessage size="Small">
                You have no board members.
              </NoticeMessage>
            )}
          </div>
        </SettingsContainer.Item>
      </SettingsContainer>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query OrganizationSettingsBoardMembers_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...OrganizationSettingsBoardMembers_Organization
    }
    me {
      ...OrganizationSettingsBoardMembers_Viewer
    }
  }
`;

const OrganizationSettingsBoardMembersPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsBoardMembers_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Board Members"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} organization board members`}
    >
      <OrganizationSettingsBoardMembersPage_
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default OrganizationSettingsBoardMembersPage;
