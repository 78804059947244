/**
 * @generated SignedSource<<9e266e2132f460f8a6c0828e38f4d5ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOffersFreemium_Query$variables = {
  organizationId: string;
};
export type EquityOffersFreemium_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"EquityOffersFreemium_Organization">;
  } | null;
};
export type EquityOffersFreemium_Query = {
  response: EquityOffersFreemium_Query$data;
  variables: EquityOffersFreemium_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityOffersFreemium_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityOffersFreemium_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityOffersFreemium_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2d08bbd2e37ead45e6a8b89f7f46aa9e",
    "id": null,
    "metadata": {},
    "name": "EquityOffersFreemium_Query",
    "operationKind": "query",
    "text": "query EquityOffersFreemium_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...EquityOffersFreemium_Organization\n  }\n}\n\nfragment EquityOffersFreemium_Organization on Organization {\n  id\n  name\n  ...FreemiumBlockerCard_Organization\n}\n\nfragment FreemiumBlockerCard_Organization on Organization {\n  name\n}\n"
  }
};
})();

(node as any).hash = "457de4bb116e0494bc99d4263442f2bd";

export default node;
