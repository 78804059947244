import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import { SpreadExplanationSlideOver_Organization$key } from "./__generated__/SpreadExplanationSlideOver_Organization.graphql";
import { RoundedBox } from "./ui/RoundedBox";
import { SlideOver } from "./ui/SlideOver";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SpreadExplanationSlideOver_Organization on Organization {
    id
  }
`;

export const SpreadExplanationSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: SpreadExplanationSlideOver_Organization$key;
  show: boolean;
}> = ({ onClose, organizationFragment, show }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={10}>
          How is the spread calculated?
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <Typography
        as="div"
        className="space-y-6 px-10 pb-6 text-black-05"
        variant="Regular/Extra Small"
      >
        <Typography
          as="div"
          className="text-black-07"
          variant="Medium/Extra Small"
        >
          The spread is calculated as the delta between:
        </Typography>
        <ul className="list-inside list-disc space-y-6">
          <li>
            The fair market value (FMV) of all exercised shares at the time the
            grantee becomes a shareholder minus
          </li>
          <li>
            The acquisition costs of the exercised shares which, if the options
            have been granted free of charge (as is customary) equals the
            exercise price of the exercised shares.
          </li>
        </ul>
        <div>
          The acquisition costs of the exercised shares which, if the options
          have been granted free of charge (as is customary) equals the exercise
          price of the exercised shares.
        </div>
        <div>
          <strong>When the grantee is in the US 🇺🇸</strong>, you&apos;ll
          typically look at the value of the shares on the basis of the 409A
          valuation applicable at the time the grantee becomes a shareholder to
          determine the fair market value.
        </div>
        <div>
          <strong>When the grantee is not based in the US</strong>, it&apos;s
          more difficult because 409A valuations are not binding on other
          countries&apos; tax authorities.
        </div>
        <RoundedBox className="!bg-gray-02 p-2">
          <Typography variant="Regular/Caption">
            It&apos;s never entirely certain that the tax authorities of the
            grantee&apos;s country of residence will accept a spread calculation
            based on a 409A valuation. Some countries also impose other
            valuation methods or certification by local valuators.{" "}
          </Typography>
        </RoundedBox>
        <RoundedBox
          className="flex items-center justify-between !rounded-[20px] p-6"
          withBorder
          withShadow
        >
          <Typography variant="Regular/Extra Small">
            Refer to your <strong>Geographies 🌍</strong> if you want to learn
            more.
          </Typography>
          <Link
            className="shrink-0"
            target="_blank"
            to={generatePath(APPLICATION_ROUTES["organizationCountries"], {
              organizationId: organization.id,
            })}
          >
            <ArrowTopRightOnSquareIcon className="h-7 w-7 text-primary" />
          </Link>
        </RoundedBox>
      </Typography>
    </SlideOver>
  );
};
