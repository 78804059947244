/**
 * @generated SignedSource<<201e6b01a7f2731425e6a2b14f3b211c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateNewGrantees_Organization$data = {
  readonly HRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_HRISProviderEmployee">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_Organization">;
  readonly " $fragmentType": "CreateNewGrantees_Organization";
};
export type CreateNewGrantees_Organization$key = {
  readonly " $data"?: CreateNewGrantees_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateNewGrantees_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateNewGrantees_Organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CreateNewGranteeItem_HRISProviderEmployee"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateNewGranteeItem_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "ba2394bf9514c7318515072f2c851402";

export default node;
