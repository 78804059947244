import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback } from "react";
import { Control, useController, useForm, useWatch } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Button } from "../../../../components/ui/Button";
import { Checkbox } from "../../../../components/ui/Form/Checkbox";
import {
  FileInput,
  Item,
  useFileInputUploadController,
} from "../../../../components/ui/Form/FileInput";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { Input } from "../../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation } from "./__generated__/UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation.graphql";
import {
  UploadDocumentSlideOver_Organization$data,
  UploadDocumentSlideOver_Organization$key,
} from "./__generated__/UploadDocumentSlideOver_Organization.graphql";
import { FORM_SCHEMA, FormValues } from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UploadDocumentSlideOver_Organization on Organization {
    id
    unlockedEquityTypesRequiringActiveSubPlanDocument: unlockedEquityTypes(
      filters: { requiresActiveSubPlanDocument: true }
    ) {
      equityTypeId
      name
      taxResidenceCountry {
        emoji
        name
      }
    }
  }
`;

const CREATE_ORGANIZATION_MISCELLANEOUS_LEGAL_DOCUMENT_MUTATION = graphql`
  mutation UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation(
    $organizationId: OrganizationId!
    $documentId: UUID!
    $displayedName: String!
    $useDocumentAsOrganizationEquityPlan: Boolean!
    $useDocumentAsOrganizationSubPlanOnEquityTypeId: ID
  ) {
    createOrganizationMiscellaneousLegalDocument(
      organizationId: $organizationId
      documentId: $documentId
      displayedName: $displayedName
      useDocumentAsOrganizationEquityPlan: $useDocumentAsOrganizationEquityPlan
      useDocumentAsOrganizationSubPlanOnEquityTypeId: $useDocumentAsOrganizationSubPlanOnEquityTypeId
    ) {
      __typename
    }
  }
`;

const SlideOverContent: React.FC<{
  control: Control<FormValues>;
  onSubmit: () => void;
  organization: UploadDocumentSlideOver_Organization$data;
}> = ({ control, onSubmit, organization }) => {
  const controller = useController({
    control,
    name: "documentId",
  });

  const useDocumentAsOrganizationEquityTypeSubPlan = useWatch({
    control,
    name: "useDocumentAsOrganizationEquityTypeSubPlan",
  });

  const { inputProps } = useFileInputUploadController({
    accept: "application/pdf",
    acceptedExtensions: [".pdf"],
    id: controller.field.name,
    multiple: false,
    name: controller.field.name,
    onItemsChange: useCallback(
      (items: Item[]) => {
        const item = items[0];
        controller.field.onChange(item?.documentId);
      },
      [controller.field],
    ),
  });

  return (
    <form
      className="block space-y-6"
      id="upload-document-form"
      onSubmit={onSubmit}
    >
      <FormRow.Form control={control} name="documentId">
        <FileInput {...inputProps} />
      </FormRow.Form>
      <FormRow.Form
        control={control}
        label="Name displayed"
        name="displayedName"
      >
        <Input.Form control={control} name="displayedName" />
      </FormRow.Form>
      <FormRow.Form
        control={control}
        name="useDocumentAsOrganizationEquityPlan"
      >
        <div className="flex gap-2">
          <Checkbox.Form
            control={control}
            name="useDocumentAsOrganizationEquityPlan"
          />
          <Typography variant="Medium/Extra Small">
            Use document as organization equity plan
          </Typography>
        </div>
      </FormRow.Form>

      <FormRow.Form
        control={control}
        name="useDocumentAsOrganizationEquityTypeSubPlan"
      >
        <div className="flex gap-2">
          <Checkbox.Form
            control={control}
            name="useDocumentAsOrganizationEquityTypeSubPlan"
          />
          <Typography variant="Medium/Extra Small">
            Use document as organization equity sub-plan
          </Typography>
        </div>
      </FormRow.Form>

      {useDocumentAsOrganizationEquityTypeSubPlan && (
        <FormRow.Form
          control={control}
          label="Select an equity type"
          name="useDocumentAsOrganizationSubPlanOnEquityTypeId"
        >
          <SelectAutocomplete.Form
            control={control}
            getOptionLabel={(equityType) =>
              `${equityType.taxResidenceCountry.emoji} ${equityType.taxResidenceCountry.name} - ${equityType.name}`
            }
            getOptionValue={(equityType) => equityType.equityTypeId}
            name="useDocumentAsOrganizationSubPlanOnEquityTypeId"
            options={
              organization.unlockedEquityTypesRequiringActiveSubPlanDocument
            }
            usePortal
          />
        </FormRow.Form>
      )}
    </form>
  );
};

function UploadDocumentSlideOver({
  onClose: _onClose,
  organizationFragment,
  show,
}: {
  onClose: () => void;
  organizationFragment: UploadDocumentSlideOver_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [createOrganizationMiscellaneousLegalDocument] =
    useSafeMutation<UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation>(
      CREATE_ORGANIZATION_MISCELLANEOUS_LEGAL_DOCUMENT_MUTATION,
    );

  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      useDocumentAsOrganizationEquityPlan: false,
      useDocumentAsOrganizationEquityTypeSubPlan: false,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const handleClose = useCallback(() => {
    reset();
    _onClose();
  }, [_onClose, reset]);

  const onSubmit = handleSubmit(async (data) => {
    await createOrganizationMiscellaneousLegalDocument({
      variables: {
        displayedName: data.displayedName,
        documentId: data.documentId,
        organizationId: organization.id,
        useDocumentAsOrganizationEquityPlan:
          data.useDocumentAsOrganizationEquityPlan,
        useDocumentAsOrganizationSubPlanOnEquityTypeId:
          data.useDocumentAsOrganizationSubPlanOnEquityTypeId,
      },
    });
    handleClose();
  });

  return (
    <SlideOver
      footer={
        <div className="flex items-center justify-end gap-2 p-6">
          <Button
            disabled={formState.isSubmitting}
            onClick={handleClose}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Cancel
          </Button>
          <Button
            form="upload-document-form"
            loading={formState.isSubmitting}
            size="small"
            type="submit"
          >
            Import
          </Button>
        </div>
      }
      header={
        <SlideOver.Header onClose={handleClose} padding={10}>
          Upload new documents
        </SlideOver.Header>
      }
      onClose={handleClose}
      show={show}
      width="600"
    >
      <div className="px-10 py-6">
        <SlideOverContent
          control={control}
          onSubmit={onSubmit}
          organization={organization}
        />
      </div>
    </SlideOver>
  );
}

export const UploadDocumentSlideOverRemote = makeRemoteController<{
  organizationFragment: UploadDocumentSlideOver_Organization$key;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }
    return (
      <UploadDocumentSlideOver
        onClose={close}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
