/**
 * @generated SignedSource<<d712a803a8db17ee517d2befe317dee1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InviteAdminSlideOver_Account_Query$variables = {
  email: string;
};
export type InviteAdminSlideOver_Account_Query$data = {
  readonly accountFromEmail: {
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  } | null;
};
export type InviteAdminSlideOver_Account_Query = {
  response: InviteAdminSlideOver_Account_Query$data;
  variables: InviteAdminSlideOver_Account_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "Account",
    "kind": "LinkedField",
    "name": "accountFromEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAdminSlideOver_Account_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InviteAdminSlideOver_Account_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d3dd76e132df40b0859637b8cdd91aa",
    "id": null,
    "metadata": {},
    "name": "InviteAdminSlideOver_Account_Query",
    "operationKind": "query",
    "text": "query InviteAdminSlideOver_Account_Query(\n  $email: String!\n) {\n  accountFromEmail(email: $email) {\n    id\n    email\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "90d11175bb4179432c02439891475775";

export default node;
