import { EllipsisHorizontalIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { compact } from "lodash";
import { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { GranteeNameWithCountryFlag } from "../../../components/GranteeNameWithCountryFlag";
import { StopClickPropagationWrapper } from "../../../components/StopClickPropagationWrapper";
import { Button } from "../../../components/ui/Button";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { LoadingSpinner } from "../../../components/ui/LoadingSpinner";
import { MenuButton } from "../../../components/ui/MenuButton";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$data,
  GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$key,
} from "./__generated__/GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import { GranteeTerminationAmendmentRequestsTable_Organization$key } from "./__generated__/GranteeTerminationAmendmentRequestsTable_Organization.graphql";
import { GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP } from "./shared";

const AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest
  @relay(plural: true) {
    id
    status
    isDeletable
    grant {
      id
      label
      grantee {
        id
        name
        workRelationship
        ...GranteeNameWithCountryFlag_Grantee
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTerminationAmendmentRequestsTable_Organization on Organization {
    id
  }
`;

const columnHelper =
  createColumnHelper<
    GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$data[number]
  >();

export const GranteeTerminationAmendmentRequestsTable: React.FC<
  {
    amendmentRequestFragment: GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$key;
    boardConsentGenerationIsInProgress?: boolean;
    borderless?: boolean;
    disableRowSelection?: boolean;
    emptyListPlaceholder?: React.ReactNode;
    grantSelection?: Set<string>;
    onGrantSelectionChange?: (selection: Set<string>) => void;
    organizationFragment: GranteeTerminationAmendmentRequestsTable_Organization$key;
  } & (
    | {
        onDeleteGrantAmendmentRequestClick: (
          grantAmendmentRequestId: string,
        ) => void;
        showActionsColumn: boolean;
      }
    | {
        onDeleteGrantAmendmentRequestClick?: undefined;
        showActionsColumn?: undefined;
      }
  )
> = ({
  amendmentRequestFragment,
  boardConsentGenerationIsInProgress,
  borderless,
  onGrantSelectionChange,
  disableRowSelection = !onGrantSelectionChange,
  emptyListPlaceholder,
  grantSelection,
  onDeleteGrantAmendmentRequestClick,
  organizationFragment,
  showActionsColumn,
}) => {
  const amendmentRequests = useFragment(
    AMENDMENT_REQUEST_FRAGMENT,
    amendmentRequestFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const columns = useMemo(
    () =>
      compact([
        !disableRowSelection
          ? columnHelper.display({
              cell: (context) => (
                <Checkbox
                  checked={context.row.getIsSelected()}
                  onChange={context.row.getToggleSelectedHandler()}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                />
              ),
              header: (context) => (
                <Checkbox
                  checked={context.table.getIsAllRowsSelected()}
                  onChange={context.table.getToggleAllPageRowsSelectedHandler()}
                />
              ),
              id: "select",
              size: 0,
            })
          : null,
        columnHelper.accessor((row) => row.grant.label, {
          cell: (context) => <Tag color="gray">{context.getValue()}</Tag>,
          enableSorting: true,
          header: () => "Grant",
          id: "grant.label",
        }),
        columnHelper.accessor((row) => row.grant.grantee.name, {
          cell: (context) => {
            const grantee = context.row.original.grant.grantee;

            return (
              <div className="space-y-1">
                <Typography as="div" variant="Regular/Extra Small">
                  <GranteeNameWithCountryFlag granteeFragment={grantee} />
                </Typography>
                {grantee.workRelationship && (
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    {
                      WORK_RELATIONSHIP_TO_LABEL_HELPER[
                        grantee.workRelationship
                      ].singularLabel
                    }
                  </Typography>
                )}
              </div>
            );
          },
          enableSorting: true,
          header: () => "Grantee",
          id: "grantee.name",
        }),
        columnHelper.display({
          cell: () => <Tag color="gray">Amendment</Tag>,
          header: "Type",
        }),
        columnHelper.accessor((row) => row.status, {
          cell: (context) => {
            if (boardConsentGenerationIsInProgress) {
              return (
                <Tag color="dashed">
                  <LoadingSpinner className="h-3 w-3 shrink-0" /> Generating
                  board consent
                </Tag>
              );
            }

            return (
              <Tag color="dashed">
                {
                  GRANT_AMENDMENT_REQUEST_STATUS_TO_LABEL_MAP[
                    context.getValue()
                  ]
                }
              </Tag>
            );
          },
          enableSorting: true,
          header: () => "Status",
          id: "status",
          meta: { alignRight: true },
        }),
        showActionsColumn &&
          columnHelper.accessor((row) => row, {
            cell: (context) => {
              const grantAmendment = context.getValue();
              return (
                <StopClickPropagationWrapper>
                  <MenuButton
                    button={
                      <Button
                        leftIcon={<EllipsisHorizontalIcon />}
                        size="extra small"
                        variant="Secondary Full"
                      />
                    }
                  >
                    {grantAmendment.isDeletable && (
                      <MenuButton.Item
                        leftIcon={<TrashIcon />}
                        onClick={() => {
                          onDeleteGrantAmendmentRequestClick(grantAmendment.id);
                        }}
                      >
                        Delete
                      </MenuButton.Item>
                    )}
                  </MenuButton>
                </StopClickPropagationWrapper>
              );
            },
            enableSorting: false,
            header: () => "Actions",
            id: "actions",
            meta: { alignRight: true },
          }),
      ]),
    [
      boardConsentGenerationIsInProgress,
      disableRowSelection,
      showActionsColumn,
      onDeleteGrantAmendmentRequestClick,
    ],
  );

  const data = useMemo(() => [...amendmentRequests], [amendmentRequests]);

  const rowSelection = useMemo(
    () =>
      data.reduce(
        (rowSelection: Record<string, boolean>, grant, index) => ({
          ...rowSelection,
          [index]: grantSelection?.has(grant.id),
        }),
        {},
      ),
    [data, grantSelection],
  );

  const table = useReactTable({
    columns,
    data,
    // Hack to set width auto when size is not set
    defaultColumn: { size: 0 },
    enableRowSelection: !disableRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: (getSelection) => {
      if (typeof getSelection === "function") {
        const newRowSelection = getSelection(rowSelection);

        onGrantSelectionChange?.(
          data.reduce((set, grant, index) => {
            if (newRowSelection[index]) {
              set.add(grant.id);
            }

            return set;
          }, new Set<string>()),
        );
      }
    },
    state: {
      rowSelection,
    },
  });

  if (amendmentRequests.length === 0) {
    return <NoticeMessage size="Large">{emptyListPlaceholder}</NoticeMessage>;
  }

  return (
    <Table.Smart
      borderlessContainer={borderless}
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequest,
            {
              granteeTerminationCTMSGrantAmendmentRequestId:
                rowData.original.id,
              organizationId: organization.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      table={table}
      tableClassName="min-w-[720px]"
    />
  );
};
