/**
 * @generated SignedSource<<5abe0f82b94d6a66fb671c49d390abee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectedItemsActionCardWithNumbers_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "SelectedItemsActionCardWithNumbers_Organization";
};
export type SelectedItemsActionCardWithNumbers_Organization$key = {
  readonly " $data"?: SelectedItemsActionCardWithNumbers_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectedItemsActionCardWithNumbers_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectedItemsActionCardWithNumbers_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "bcfd8fcbd79db7a22b2cf159f8e539cd";

export default node;
