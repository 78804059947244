/**
 * @generated SignedSource<<cde58592f428f4115ad547fb8e91e657>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriptionBillingPeriod = "monthly" | "yearly";
import { FragmentRefs } from "relay-runtime";
export type BillingDetails_Organization$data = {
  readonly id: string;
  readonly subscriptionBillingPeriod: SubscriptionBillingPeriod | null;
  readonly " $fragmentType": "BillingDetails_Organization";
};
export type BillingDetails_Organization$key = {
  readonly " $data"?: BillingDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionBillingPeriod",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1f977eef4475d6c8933a43a78eb59e66";

export default node;
