/**
 * @generated SignedSource<<6e4bc7fd71ae488d4c52a2ff9113255b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsBoardMembers_Item_BoardMember$data = {
  readonly account: {
    readonly email: string;
    readonly firstName: string;
    readonly id: string;
    readonly lastName: string;
  };
  readonly id: string;
  readonly isRemovable: boolean;
  readonly " $fragmentType": "OrganizationSettingsBoardMembers_Item_BoardMember";
};
export type OrganizationSettingsBoardMembers_Item_BoardMember$key = {
  readonly " $data"?: OrganizationSettingsBoardMembers_Item_BoardMember$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Item_BoardMember">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsBoardMembers_Item_BoardMember",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRemovable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BoardMember",
  "abstractKey": null
};
})();

(node as any).hash = "82d097a8dae8cd42bf223ad92efd58da";

export default node;
