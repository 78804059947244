import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../components/GranteeFormSlide";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import { MissingGranteeInformationMessage_Grantee$key } from "./__generated__/MissingGranteeInformationMessage_Grantee.graphql";
import { MissingGranteeInformationMessage_Organization$key } from "./__generated__/MissingGranteeInformationMessage_Organization.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment MissingGranteeInformationMessage_Grantee on Grantee {
    ...GranteeFormSlide_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment MissingGranteeInformationMessage_Organization on Organization {
    ...GranteeFormSlide_Organization
  }
`;

const WarningMessage: React.FC<{
  missingInformation: "TaxResidenceCountry" | "WorkRelationship";
  onClick: () => void;
}> = ({ missingInformation, onClick }) => {
  switch (missingInformation) {
    case "TaxResidenceCountry":
      return (
        <NoticeMessage size="Small" variant="Warning">
          We need to know where the grantee is paying taxes. Please add the tax
          residency by updating the{" "}
          <Typography
            className="cursor-pointer text-primary"
            onClick={onClick}
            variant="Medium/Extra Small"
          >
            grantee&apos;s details
          </Typography>
        </NoticeMessage>
      );
    case "WorkRelationship":
      return (
        <NoticeMessage size="Small" variant="Warning">
          We need to know your work relationship with the grantee. Please add it
          by updating the{" "}
          <Typography
            className="cursor-pointer text-primary"
            onClick={onClick}
            variant="Medium/Extra Small"
          >
            grantee&apos;s details
          </Typography>
        </NoticeMessage>
      );
  }
};

export const MissingGranteeInformationMessage: React.FC<{
  granteeFragment: MissingGranteeInformationMessage_Grantee$key;
  missingInformation: "TaxResidenceCountry" | "WorkRelationship";
  organizationFragment: MissingGranteeInformationMessage_Organization$key;
}> = ({ granteeFragment, missingInformation, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInEditMode,
  } = useGranteeFormSlideState();

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeUpdated={closeGranteeFormSlide}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />
      <WarningMessage
        missingInformation={missingInformation}
        onClick={() => openGranteeFormSlideInEditMode(grantee)}
      />
    </>
  );
};
