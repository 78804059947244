/**
 * @generated SignedSource<<23b37ff4024988b1a6dce5ace0bf6737>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsBox_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization">;
  readonly " $fragmentType": "GranteeDetailsBox_Organization";
};
export type GranteeDetailsBox_Organization$key = {
  readonly " $data"?: GranteeDetailsBox_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsBox_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsBox_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d6da464b85119edda50fd7d3402cd165";

export default node;
