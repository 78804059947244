/**
 * @generated SignedSource<<667dacf2492e1336dfe28238db58f7ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnlockCountry_UnlockGeographyForFree_Mutation$variables = {
  countryCode: string;
  organizationId: string;
  withTaxFavoredEquityType: boolean;
};
export type UnlockCountry_UnlockGeographyForFree_Mutation$data = {
  readonly unlockGeographyForFree: {
    readonly __typename: "Organization";
  };
};
export type UnlockCountry_UnlockGeographyForFree_Mutation = {
  response: UnlockCountry_UnlockGeographyForFree_Mutation$data;
  variables: UnlockCountry_UnlockGeographyForFree_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withTaxFavoredEquityType"
},
v3 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "withTaxFavoredEquityType",
    "variableName": "withTaxFavoredEquityType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlockCountry_UnlockGeographyForFree_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "unlockGeographyForFree",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnlockCountry_UnlockGeographyForFree_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "unlockGeographyForFree",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b0dd73e7e73d2f065f05db024fd06d21",
    "id": null,
    "metadata": {},
    "name": "UnlockCountry_UnlockGeographyForFree_Mutation",
    "operationKind": "mutation",
    "text": "mutation UnlockCountry_UnlockGeographyForFree_Mutation(\n  $countryCode: String!\n  $withTaxFavoredEquityType: Boolean!\n  $organizationId: OrganizationId!\n) {\n  unlockGeographyForFree(countryCode: $countryCode, organizationId: $organizationId, withTaxFavoredEquityType: $withTaxFavoredEquityType) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b34cd966befeeda941cba47d0fbd169";

export default node;
