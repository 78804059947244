/**
 * @generated SignedSource<<65030f94c63282b094c32b7b964534f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CountriesFreemium_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly geography: {
      readonly country: {
        readonly code: string;
        readonly emoji: string;
        readonly name: string;
      };
    };
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"FreemiumBlockerCard_Organization">;
  readonly " $fragmentType": "CountriesFreemium_Organization";
};
export type CountriesFreemium_Organization$key = {
  readonly " $data"?: CountriesFreemium_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CountriesFreemium_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CountriesFreemium_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Geography",
          "kind": "LinkedField",
          "name": "geography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emoji",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FreemiumBlockerCard_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "2e4e98cd89be1159c58bb9b7e6756a51";

export default node;
