import { CheckIcon } from "@heroicons/react/24/outline";

import { Button } from "./ui/Button";
import { NoticeMessage } from "./ui/NoticeMessage";

export const ConfirmationNoticeMessage: React.FC<{
  children: React.ReactNode;
  confirmed: boolean;
  hasColor?: React.ComponentProps<typeof NoticeMessage>["hasColor"];
  onConfirmClick: () => void;
  size: React.ComponentProps<typeof NoticeMessage>["size"];
  variant: React.ComponentProps<typeof NoticeMessage>["variant"];
}> = ({ children, confirmed, hasColor, onConfirmClick, size, variant }) => {
  return (
    <NoticeMessage hasColor={hasColor} size={size} variant={variant}>
      <div className="space-y-4">
        <div>{children}</div>
        <div className="flex items-center gap-6">
          <Button
            disabled={confirmed}
            onClick={onConfirmClick}
            rightIcon={confirmed ? <CheckIcon /> : undefined}
            size="small"
            variant="Primary Full"
          >
            {confirmed ? "Confirmed" : "Confirm"}
          </Button>
        </div>
      </div>
    </NoticeMessage>
  );
};
