import { ComponentProps } from "react";
import { FormattedNumber as _FormattedNumber } from "react-intl";

import { FormattedNumberAnimated } from "./FormattedNumberAnimated";

export function FormattedNumber({
  animated,
  ...props
}: ComponentProps<typeof _FormattedNumber> & {
  animated?: boolean;
}) {
  if (animated) {
    return <FormattedNumberAnimated {...props} />;
  }

  return <_FormattedNumber {...props} />;
}
