import classNames from "classnames";

import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const RoundedDataRow: React.FC<{
  background?: React.ComponentProps<typeof RoundedBox>["background"];
  bottomContent?: React.ReactNode;
  children: React.ReactNode;
  childrenClassName?: string;
  label: React.ReactNode;
}> = ({
  background = "gray",
  bottomContent,
  children,
  childrenClassName,
  label,
}) => {
  return (
    <RoundedBox background={background} className="space-y-4 p-4">
      <div className="flex items-center justify-between gap-10">
        <Typography as="div" className="w-1/2" variant="Regular/Extra Small">
          {label}
        </Typography>
        <Typography
          as="div"
          className={classNames("w-1/2 text-right", childrenClassName)}
          variant="Medium/Extra Small"
        >
          {children}
        </Typography>
      </div>
      {bottomContent}
    </RoundedBox>
  );
};
