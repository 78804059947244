/**
 * @generated SignedSource<<a9e52059422489122904636529159af2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MissingInformationTag_MissingCTMSInformationTag_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "MissingInformationTag_MissingCTMSInformationTag_Organization";
};
export type MissingInformationTag_MissingCTMSInformationTag_Organization$key = {
  readonly " $data"?: MissingInformationTag_MissingCTMSInformationTag_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MissingInformationTag_MissingCTMSInformationTag_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5e629dd857ffe1cb2ffc0fb1b13648c7";

export default node;
