/**
 * @generated SignedSource<<a9c2f85f95e16f07fe17f04fd43e96bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  readonly " $fragmentType": "BoardMemberLayout_Viewer";
};
export type BoardMemberLayout_Viewer$key = {
  readonly " $data"?: BoardMemberLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardMemberLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "c76af573bf71a4dbbaca51a82432ab09";

export default node;
