/**
 * @generated SignedSource<<d4a8affc3e8ff1eb04383d02515812e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminPortalContent_AdminPortalContent$data = {
  readonly content: string;
  readonly lastModifiedAt: string;
  readonly " $fragmentType": "AdminPortalContent_AdminPortalContent";
};
export type AdminPortalContent_AdminPortalContent$key = {
  readonly " $data"?: AdminPortalContent_AdminPortalContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminPortalContent_AdminPortalContent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModifiedAt",
      "storageKey": null
    }
  ],
  "type": "AdminPortalContent",
  "abstractKey": null
};

(node as any).hash = "76ac4ca67e325f9fe81994b4d4bf18e6";

export default node;
