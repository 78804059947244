/**
 * @generated SignedSource<<10b03b5539ec5b6a314447df0ba4c5fe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FreeTrialPlanStickyBanner_Organization$data = {
  readonly name: string;
  readonly " $fragmentType": "FreeTrialPlanStickyBanner_Organization";
};
export type FreeTrialPlanStickyBanner_Organization$key = {
  readonly " $data"?: FreeTrialPlanStickyBanner_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FreeTrialPlanStickyBanner_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreeTrialPlanStickyBanner_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "5efa798eee483d1ed90729b01c1ff29c";

export default node;
