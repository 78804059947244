/**
 * @generated SignedSource<<67d8ddb6d08ebbd9e0f37b57e26ee64f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation$variables = {
  instrumentId: string;
  organizationId: string;
};
export type useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation$data = {
  readonly generateInstrumentDocumentsPreviewURL: string;
};
export type useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation = {
  response: useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation$data;
  variables: useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "instrumentId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "instrumentId",
        "variableName": "instrumentId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "generateInstrumentDocumentsPreviewURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "cb5fada56ea3c4f20a9502f47eed79a6",
    "id": null,
    "metadata": {},
    "name": "useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation(\n  $instrumentId: UUID!\n  $organizationId: OrganizationId!\n) {\n  generateInstrumentDocumentsPreviewURL(instrumentId: $instrumentId, organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "23559b8a480d33cb20007a82064c6e54";

export default node;
