/**
 * @generated SignedSource<<3d90f02d8048b4eed01d72fadf8f63b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_BoardConsent$data = {
  readonly __typename: string;
  readonly ceoNote: string | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_BoardConsent">;
  readonly " $fragmentType": "ApprovalSlideOverContent_BoardConsent";
};
export type ApprovalSlideOverContent_BoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverContent_BoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_BoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_BoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ceoNote",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalSlideOverGrantsCard_BoardConsent"
    }
  ],
  "type": "BoardConsent",
  "abstractKey": "__isBoardConsent"
};

(node as any).hash = "9757e0cfabf0bfe14701f9cc0691130d";

export default node;
