/**
 * @generated SignedSource<<559c76d7cbf96291024abab370266761>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WarningTaxFavoredSubplanExpired_Organization$data = {
  readonly name: string;
  readonly " $fragmentType": "WarningTaxFavoredSubplanExpired_Organization";
};
export type WarningTaxFavoredSubplanExpired_Organization$key = {
  readonly " $data"?: WarningTaxFavoredSubplanExpired_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"WarningTaxFavoredSubplanExpired_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WarningTaxFavoredSubplanExpired_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cd312095ccb13d545c1f2ade4e216e39";

export default node;
