import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import classNames from "classnames";
import { chain } from "lodash";
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { useBoolean } from "../hooks/useBoolean";
import { useQuery } from "../hooks/useQuery";
import { useSafeMutation } from "../hooks/useSafeMutation";
import {
  EditTerminationPlanningEntryResultFailureReason,
  TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation,
} from "./__generated__/TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation.graphql";
import {
  TerminationPlanningEntryModal_Organization$data,
  TerminationPlanningEntryModal_Organization$key,
} from "./__generated__/TerminationPlanningEntryModal_Organization.graphql";
import {
  TerminationPlanningEntryModal_TerminationPlanningEntry$data,
  TerminationPlanningEntryModal_TerminationPlanningEntry$key,
} from "./__generated__/TerminationPlanningEntryModal_TerminationPlanningEntry.graphql";
import { TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation } from "./__generated__/TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation.graphql";
import { TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query } from "./__generated__/TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query.graphql";
import { useToaster } from "./Toaster";
import { Divider } from "./ui/Divider";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Toggle } from "./ui/Form/Toggle";
import { Modal } from "./ui/Modal";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TerminationPlanningEntryModal_Organization on Organization {
    id
    name
    activeGrantees: grantees(filters: { status: Active }) {
      edges {
        node {
          id
          name
        }
      }
    }
    planningEntries {
      ... on TerminationPlanningEntry {
        __typename
        id
        grantee {
          id
        }
      }
    }
  }
`;

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment TerminationPlanningEntryModal_TerminationPlanningEntry on TerminationPlanningEntry {
    id
    terminationDate
    vestingEndDate
    waiveCliff
    grantee {
      id
    }
  }
`;

const WILL_GRANTEE_HAVE_AT_LEAST_ONE_ACTIVE_GRANT_WITH_CLIFF_NOT_PASSED_AT_DATE_QUERY = graphql`
  query TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query(
    $granteeId: GranteeId!
    $date: Date!
  ) {
    willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate(
      granteeId: $granteeId
      date: $date
    )
  }
`;

const CREATE_TERMINATION_PLANNING_ENTRY_MUTATION = graphql`
  mutation TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation(
    $organizationId: OrganizationId!
    $attributes: CreateTerminationPlanningEntryAttributes!
  ) {
    createTerminationPlanningEntry(
      organizationId: $organizationId
      attributes: $attributes
    ) {
      __typename
      ... on EditTerminationPlanningEntryResultFailure {
        reason
      }
    }
  }
`;

const UPDATE_TERMINATION_PLANNING_ENTRY_MUTATION = graphql`
  mutation TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation(
    $planningEntryId: PlanningEntryId!
    $attributes: CreateTerminationPlanningEntryAttributes!
  ) {
    updateTerminationPlanningEntry(
      planningEntryId: $planningEntryId
      attributes: $attributes
    ) {
      __typename
      ... on EditTerminationPlanningEntryResultFailure {
        reason
      }
    }
  }
`;

const useSchema = ({
  editedPlanningEntry,
  organization,
}: {
  editedPlanningEntry: null | TerminationPlanningEntryModal_TerminationPlanningEntry$data;
  organization: TerminationPlanningEntryModal_Organization$data;
}) => {
  const otherExistingTerminationPlanningEntries = useMemo(
    () =>
      chain(organization.planningEntries)
        .map((entry) => {
          if (entry.__typename !== "TerminationPlanningEntry") {
            return null;
          }
          if (editedPlanningEntry && entry.id === editedPlanningEntry.id) {
            return null;
          }
          return entry;
        })
        .compact()
        .value(),
    [organization.planningEntries, editedPlanningEntry],
  );
  const schema = z.object({
    granteeId: z
      .string()
      .refine(
        (granteeId) =>
          !otherExistingTerminationPlanningEntries.some(
            (entry) => entry.grantee.id === granteeId,
          ),
        {
          message:
            "This grantee is already set for termination in the planning.",
        },
      ),
    terminationDate: z.string(),
    vestingEndDate: z.string().nullable(),
    waiveCliff: z.boolean(),
  });
  return schema;
};

type FormValues = z.infer<ReturnType<typeof useSchema>>;

const WaiveCliffSection: React.FC<{
  granteeId: string;
  loading?: boolean;
  onChange: (value: boolean) => void;
  vestingEndDate: string;
  waiveCliff: boolean;
}> = ({ granteeId, loading, onChange, vestingEndDate, waiveCliff }) => {
  const {
    query: { willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate },
  } =
    useQuery<TerminationPlanningEntryModal_WillGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate_Query>(
      WILL_GRANTEE_HAVE_AT_LEAST_ONE_ACTIVE_GRANT_WITH_CLIFF_NOT_PASSED_AT_DATE_QUERY,
      {
        date: vestingEndDate,
        granteeId,
      },
    );

  if (!willGranteeHaveAtLeastOneActiveGrantWithCliffNotPassedAtDate) {
    return null;
  }

  return (
    <div
      className={classNames("space-y-4", {
        "animate-pulse": loading,
      })}
    >
      <Divider />
      <NoticeMessage hasColor={false} size="Small" variant="Warning">
        Cliff ends after the termination date
      </NoticeMessage>
      <div className="flex items-center justify-between">
        <Typography as="div" variant="Medium/Extra Small">
          Waive the cliff?
        </Typography>
        <Toggle enabled={waiveCliff} onChange={onChange} size="small" />
      </div>
    </div>
  );
};

type State = {
  editedPlanningEntryFragment: null | TerminationPlanningEntryModal_TerminationPlanningEntry$key;
  open: boolean;
};

export const useTerminationPlanningEntryModalState = () => {
  const [state, setState] = useState<State>({
    editedPlanningEntryFragment: null,
    open: false,
  });

  const openTerminationPlanningEntryModalInEditMode = useCallback(
    (
      editedPlanningEntryFragment: TerminationPlanningEntryModal_TerminationPlanningEntry$key,
    ) => {
      setState({
        editedPlanningEntryFragment,
        open: true,
      });
    },
    [],
  );

  const openTerminationPlanningEntryModalInCreateMode = useCallback(() => {
    setState({
      editedPlanningEntryFragment: null,
      open: true,
    });
  }, []);

  const closeTerminationPlanningEntryModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }));
  }, []);

  return {
    closeTerminationPlanningEntryModal,
    openTerminationPlanningEntryModalInCreateMode,
    openTerminationPlanningEntryModalInEditMode,
    terminationPlanningEntryModalState: state,
  };
};

const ModalContent: React.FC<{
  editedPlanningEntryFragment: null | TerminationPlanningEntryModal_TerminationPlanningEntry$key;
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organization: TerminationPlanningEntryModal_Organization$data;
}> = ({
  editedPlanningEntryFragment,
  onClose,
  onPlanningEntryEdited,
  organization,
}) => {
  const editedPlanningEntry =
    useFragment(PLANNING_ENTRY_FRAGMENT, editedPlanningEntryFragment) ?? null;

  const activeGrantees = useMemo(
    () => organization.activeGrantees.edges.map((edge) => edge.node),
    [organization.activeGrantees],
  );

  const schema = useSchema({ editedPlanningEntry, organization });

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    setError,
    watch,
  } = useForm({
    defaultValues: (editedPlanningEntry
      ? {
          granteeId: editedPlanningEntry.grantee.id,
          terminationDate: editedPlanningEntry.terminationDate,
          vestingEndDate: editedPlanningEntry.vestingEndDate,
          waiveCliff: editedPlanningEntry.waiveCliff,
        }
      : {
          granteeId: null,
          terminationDate: null,
          vestingEndDate: null,
          waiveCliff: false,
        }) as unknown as FormValues,
    resolver: zodResolver(schema),
  });

  const {
    toggle: toggleShowVestingEndDatePicker,
    value: showVestingEndDatePicker,
  } = useBoolean();

  const [
    createTerminationPlanningEntry,
    createTerminationPlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation>(
      CREATE_TERMINATION_PLANNING_ENTRY_MUTATION,
    );
  const [
    updateTerminationPlanningEntry,
    updateTerminationPlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation>(
      UPDATE_TERMINATION_PLANNING_ENTRY_MUTATION,
    );

  const toaster = useToaster();

  const applicationName = useApplicationName();

  function handleMutationFailure({
    reason,
  }: {
    reason: EditTerminationPlanningEntryResultFailureReason;
  }) {
    Sentry.captureException(
      new Error(
        `${organization.name} tried to plan a termination but was blocked because ${reason}`,
      ),
    );

    switch (reason) {
      case "MISSING_INFORMATION":
        setError("granteeId", {
          message: `This grant was made outside of ${applicationName}, we are unable to retrieve enough information from your CTMS to simulate the new vesting schedule`,
        });
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    const { granteeId, terminationDate, vestingEndDate, waiveCliff } =
      schema.parse(data);
    if (editedPlanningEntry) {
      const { updateTerminationPlanningEntry: output } =
        await updateTerminationPlanningEntry({
          variables: {
            attributes: {
              granteeId,
              terminationDate,
              vestingEndDate: showVestingEndDatePicker ? vestingEndDate : null,
              waiveCliff,
            },
            planningEntryId: editedPlanningEntry.id,
          },
        });

      if (output.__typename === "EditTerminationPlanningEntryResultFailure") {
        handleMutationFailure({
          reason: output.reason,
        });
        return;
      }

      toaster.push(
        <Toast title="Great!">Termination entry successfully updated!</Toast>,
      );

      onPlanningEntryEdited?.();
    } else {
      const { createTerminationPlanningEntry: output } =
        await createTerminationPlanningEntry({
          variables: {
            attributes: {
              granteeId,
              terminationDate,
              vestingEndDate: showVestingEndDatePicker ? vestingEndDate : null,
              waiveCliff,
            },
            organizationId: organization.id,
          },
        });

      if (output.__typename === "EditTerminationPlanningEntryResultFailure") {
        handleMutationFailure({
          reason: output.reason,
        });
        return;
      }

      toaster.push(
        <Toast title="Great!">Termination entry successfully created!</Toast>,
      );
    }

    onClose();
  });

  const [granteeId, terminationDate, vestingEndDate] = watch([
    "granteeId",
    "terminationDate",
    "vestingEndDate",
  ]);

  const [selectedGranteeId, setSelectedGranteeId] = useState<null | string>(
    null,
  );
  const [selectedVestingEndDate, setSelectedVestingEndDate] = useState<
    null | string
  >(null);

  const [transitionIsInProgress, startTransition] = useTransition();

  useEffect(() => {
    startTransition(() => {
      setSelectedGranteeId(granteeId);
      setSelectedVestingEndDate(vestingEndDate || terminationDate);
    });
  }, [granteeId, vestingEndDate, terminationDate]);

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.SubmitButton
          disabled={!isDirty}
          form="termination-planning-entry-form"
          loading={
            createTerminationPlanningEntryMutationIsInFlight ||
            updateTerminationPlanningEntryMutationIsInFlight
          }
        >
          {editedPlanningEntry ? "Update" : "Plan"}
        </Modal.SubmitButton>
      }
      onClose={onClose}
      subTitle="This will allow you to plan the potential amount of shares returning to your pool"
      title={
        editedPlanningEntry ? "Update termination" : "Plan a new termination"
      }
    >
      <Modal.Form id="termination-planning-entry-form" onSubmit={onSubmit}>
        <div className="space-y-6">
          <FormRow.Form control={control} label="Employee" name="granteeId">
            <SelectAutocomplete.Form
              control={control}
              dataCy="grantee"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              name="granteeId"
              options={activeGrantees}
              placeholder="Employee"
              usePortal
            />
          </FormRow.Form>
          <FormRow.Form
            control={control}
            label="Termination date"
            name="terminationDate"
          >
            <DatePicker.Form
              className="w-full"
              control={control}
              name="terminationDate"
              panelPosition="top"
              placeholder="Termination date"
              popoverButtonClassName="!py-4"
            />
          </FormRow.Form>
          <div className="space-y-4 py-2">
            <Typography
              as="div"
              className="text-black-05"
              variant="Medium/Caption"
            >
              REGARDING THE TERMS
            </Typography>
            <div className="flex items-center justify-between">
              <Typography as="div" variant="Medium/Extra Small">
                Accelerate vesting?
              </Typography>
              <Toggle
                disabled={transitionIsInProgress}
                enabled={showVestingEndDatePicker}
                loading={transitionIsInProgress}
                onChange={toggleShowVestingEndDatePicker}
                size="small"
              />
            </div>
            {showVestingEndDatePicker && (
              <FormRow.Form
                control={control}
                label="Vesting ends on"
                name="vestingEndDate"
              >
                <DatePicker.Form
                  className="w-full"
                  control={control}
                  name="vestingEndDate"
                  panelPosition="top"
                  placeholder="Vesting end date"
                  popoverButtonClassName="!py-4"
                />
              </FormRow.Form>
            )}
            {selectedGranteeId && selectedVestingEndDate && (
              <Controller
                control={control}
                name="waiveCliff"
                render={({ field }) => (
                  <WaiveCliffSection
                    granteeId={selectedGranteeId}
                    loading={transitionIsInProgress}
                    onChange={field.onChange}
                    vestingEndDate={selectedVestingEndDate}
                    waiveCliff={field.value}
                  />
                )}
              />
            )}
          </div>
        </div>
      </Modal.Form>
    </Modal.Content>
  );
};
export const TerminationPlanningEntryModal: React.FC<{
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organizationFragment: TerminationPlanningEntryModal_Organization$key;
  state: State;
}> = ({ onClose, onPlanningEntryEdited, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Modal dataCy="termination-entry-modal" onClose={onClose} show={state.open}>
      <ModalContent
        editedPlanningEntryFragment={state.editedPlanningEntryFragment}
        onClose={onClose}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organization={organization}
      />
    </Modal>
  );
};
