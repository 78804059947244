import { CheckIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { partition } from "lodash";
import { graphql, useFragment } from "react-relay";

import { Table } from "../../../../components/ui/Table";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { BlueLink } from "../../Grants/Configure/BlueLink";
import { CountriesTableFooter } from "../CountriesTableFooter";
import { EquityIncentivesAvailable_Geography$key } from "./__generated__/EquityIncentivesAvailable_Geography.graphql";

const GEOGRAPHY_FRAGMENT = graphql`
  fragment EquityIncentivesAvailable_Geography on Geography {
    equityTypes {
      id
      name
      taxFavored
      description
      instruments {
        workRelationship
      }
    }
  }
`;

export const EquityIncentivesAvailable: React.FC<{
  geographyFragment: EquityIncentivesAvailable_Geography$key;
}> = ({ geographyFragment }) => {
  const geography = useFragment(GEOGRAPHY_FRAGMENT, geographyFragment);
  const [taxFavoredEquityTypes] = partition(
    geography.equityTypes,
    (equityType) => equityType.taxFavored,
  );
  const offersEquityToDirectEmployees = geography.equityTypes.some(
    (equityType) =>
      equityType.instruments.some(
        (instrument) => instrument.workRelationship === "DirectEmployee",
      ),
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <div className="space-y-4">
      <Typography variant="Medium/Small">
        Equity incentives available per work relationship
      </Typography>
      <Table.Containerized className="grid-cols-4" display="grid">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Equity incentives</Table.HeaderCell>
            <Table.HeaderCell>Direct employees</Table.HeaderCell>
            <Table.HeaderCell>EoR employees</Table.HeaderCell>
            <Table.HeaderCell>Contractors</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {geography.equityTypes.map((equityType) => {
            const offersEquityToDirectEmployees = equityType.instruments.some(
              (instrument) => instrument.workRelationship === "DirectEmployee",
            );
            const offersEquityToEorEmployees = equityType.instruments.some(
              (instrument) => instrument.workRelationship === "EoREmployee",
            );
            const offersEquityToContractors = equityType.instruments.some(
              (instrument) =>
                instrument.workRelationship === "Advisor" ||
                instrument.workRelationship === "ContractorManagementCompany" ||
                instrument.workRelationship === "ContractorNaturalPerson",
            );

            return (
              <Table.Row key={equityType.id}>
                <Table.Cell>
                  <div className="flex items-center gap-1">
                    <Typography variant="Medium/Extra Small">
                      {equityType.name}
                    </Typography>
                    <TooltipContainer
                      disabled={!equityType.description}
                      tooltipContent={equityType.description}
                    >
                      <InformationCircleIcon className="w-4 text-primary-05" />
                    </TooltipContainer>
                  </div>
                </Table.Cell>
                <Table.Cell>
                  {offersEquityToDirectEmployees ? (
                    <CheckIcon className="w-5 text-green-05" />
                  ) : (
                    "-"
                  )}
                </Table.Cell>
                <Table.Cell>
                  {offersEquityToEorEmployees ? (
                    <CheckIcon className="w-5 text-green-05" />
                  ) : (
                    "-"
                  )}
                </Table.Cell>
                <Table.Cell>
                  {offersEquityToContractors ? (
                    <CheckIcon className="w-5 text-green-05" />
                  ) : (
                    "-"
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <CountriesTableFooter>
          <Table.Row>
            <Table.Cell className="col-span-full">
              <Typography variant="Regular/Caption">
                <ul className="list-disc pl-3">
                  {offersEquityToDirectEmployees && (
                    <li>
                      A local subsidiary is required if you wish to offer equity
                      incentives to direct employee in this country.
                    </li>
                  )}
                  {taxFavoredEquityTypes.map((equityType) => (
                    <li key={equityType.id}>
                      {equityType.name} equity incentive requires a “sub plan”
                      and does have some extra costs ($1,999 USD) in case we
                      need to set it up. If you wish to benefit from this equity
                      incentive, please{" "}
                      <BlueLink to={`mailto:${supportEmailAddress}`}>
                        contact us
                      </BlueLink>
                      .
                    </li>
                  ))}
                </ul>
              </Typography>
            </Table.Cell>
          </Table.Row>
        </CountriesTableFooter>
      </Table.Containerized>
    </div>
  );
};
