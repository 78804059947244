/**
 * @generated SignedSource<<9d28b8cd7a034744a48c3c709a962b70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Approvals_SignatureRequests$data = ReadonlyArray<{
  readonly completedAt: string | null;
  readonly createdAt: string | null;
  readonly id: string;
  readonly justSigned: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_SignatureRequest" | "BoardMemberApprovalsTables_SignatureRequests">;
  readonly " $fragmentType": "Approvals_SignatureRequests";
}>;
export type Approvals_SignatureRequests$key = ReadonlyArray<{
  readonly " $data"?: Approvals_SignatureRequests$data;
  readonly " $fragmentSpreads": FragmentRefs<"Approvals_SignatureRequests">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Approvals_SignatureRequests",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalSlideOverContent_SignatureRequest"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMemberApprovalsTables_SignatureRequests"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "justSigned",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "SignatureRequest",
  "abstractKey": null
};

(node as any).hash = "80b35b3b41918877918735aa8b3be84f";

export default node;
