/**
 * @generated SignedSource<<a3dcdbcaa36c04fd2792a7167d5658d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeOutcome_CTMSGrant$data = ReadonlyArray<{
  readonly quantityIssued: number;
  readonly valueAtGrant: number | null;
  readonly " $fragmentType": "GranteeOutcome_CTMSGrant";
}>;
export type GranteeOutcome_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: GranteeOutcome_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeOutcome_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeOutcome_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueAtGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "f29ac516a226036bbc633d440a7c2ba9";

export default node;
