import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useFragment } from "react-relay";
import { generatePath, NavLink } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../../../paths";
import { OrganizationRoleSwitcher, Role } from "../../OrganizationRoleSwitcher";
import { LinkButton } from "../Button";
import { ApplicationSideBarContent_Organization$key } from "./__generated__/ApplicationSideBarContent_Organization.graphql";
import { ApplicationSideBarContent_Viewer$key } from "./__generated__/ApplicationSideBarContent_Viewer.graphql";
import { LogoLink } from "./LogoLink";

export const ApplicationSideBarContent: React.FC<
  React.PropsWithChildren<{
    activeRole: null | Role;
    className?: string;
    homePath?: React.ComponentProps<typeof NavLink>["to"];
    organizationFragment: ApplicationSideBarContent_Organization$key | null;
    showHeader?: boolean;
    viewerFragment: ApplicationSideBarContent_Viewer$key;
  }>
> = ({
  activeRole,
  children,
  className,
  homePath,
  organizationFragment,
  showHeader = true,
  viewerFragment,
}) => {
  const viewer = useFragment(
    graphql`
      fragment ApplicationSideBarContent_Viewer on Account {
        ...OrganizationRoleSwitcher_Account
      }
    `,
    viewerFragment,
  );
  const organization = useFragment(
    graphql`
      fragment ApplicationSideBarContent_Organization on Organization {
        ...OrganizationRoleSwitcher_Organization
      }
    `,
    organizationFragment,
  );

  return (
    <div
      className={classNames(
        "z-10 flex h-full flex-col gap-6 overflow-auto py-6",
        className,
      )}
    >
      {showHeader && (
        <div className="flex items-center justify-between">
          <LogoLink homePath={homePath} />
          {organization && activeRole ? (
            <OrganizationRoleSwitcher
              accountFragment={viewer}
              activeRole={activeRole}
              organizationFragment={organization}
            />
          ) : (
            <LinkButton
              leftIcon={<ArrowLeftIcon />}
              size="small"
              to={generatePath(APPLICATION_ROUTES["roleSelection"])}
              variant="Danger Outline"
            />
          )}
        </div>
      )}

      <div className="flex flex-col gap-6 px-0">{children}</div>
    </div>
  );
};
