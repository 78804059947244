/**
 * @generated SignedSource<<aba6e298e2928d45c50eca3ac11148f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOffer_EquityOffer$data = {
  readonly displayCompanyInformation: boolean;
  readonly displayFAQ: boolean;
  readonly displayProjectionScenarios: boolean;
  readonly finalThoughts: string | null;
  readonly id: string;
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"useApplicationTheme_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"Candidate_EquityOffer" | "Company_EquityOffer" | "FinalThoughts_EquityOffer" | "Incentive_EquityOffer" | "Projection_EquityOffer" | "Vesting_EquityOffer">;
  readonly " $fragmentType": "EquityOffer_EquityOffer";
};
export type EquityOffer_EquityOffer$key = {
  readonly " $data"?: EquityOffer_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOffer_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOffer_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCompanyInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayFAQ",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayProjectionScenarios",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalThoughts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CandidateAccess_Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useApplicationTheme_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Candidate_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Company_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Incentive_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Vesting_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Projection_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalThoughts_EquityOffer"
    }
  ],
  "type": "CandidateAccess_EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "f24a5d27cfe1497660be521f5bdcbcf1";

export default node;
