import { max, min } from "lodash";
import React, { useCallback, useMemo } from "react";
import {
  CartesianGrid,
  Cell,
  Label,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Text,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";

import { isNonEmptyArray } from "../helpers/ts-utlity";

const MIN_OPACITY = 1 / 4;

export function ScatterGraph<
  TDataPoint extends { x: number; y: number; z: number },
>({
  dataPoints,
  onCellClick,
  renderTooltip,
  xLabel,
  xTickFormatter,
  yLabel,
  yTickFormatter,
}: {
  dataPoints: TDataPoint[];
  onCellClick: (d: TDataPoint) => void;
  renderTooltip: (d: TDataPoint) => React.ReactNode;
  xLabel: React.ReactNode;
  xTickFormatter: (value: number) => string;
  yLabel: React.ReactNode;
  yTickFormatter: (value: number) => string;
}) {
  const { maxZAxis, minZAxis } = useMemo(
    () => ({
      maxZAxis: max(dataPoints.map((d) => d.z)) ?? 1,
      minZAxis: min(dataPoints.map((d) => d.z)) ?? 0,
    }),
    [dataPoints],
  );
  const getCellOpacity = useCallback(
    (z: number) =>
      MIN_OPACITY +
      ((1 - MIN_OPACITY) * (z - minZAxis)) / (maxZAxis - minZAxis),
    [maxZAxis, minZAxis],
  );

  return (
    <ResponsiveContainer height={400} width="100%">
      <ScatterChart
        margin={{
          top: 40,
        }}
      >
        <CartesianGrid strokeDasharray="2 4" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="x"
          padding={{ left: 100, right: 100 }}
          tick={(props: { payload: { value: number } }) => {
            return (
              <Text
                {...props}
                className="font-captionRegular translate-y-2 transform fill-black-05"
              >
                {xTickFormatter(props.payload.value)}
              </Text>
            );
          }}
          tickLine={false}
          type="number"
        >
          <Label
            className="font-mediumExtraSmall fill-black-07"
            position="insideBottomRight"
          >
            {xLabel}
          </Label>
        </XAxis>
        <YAxis
          allowDecimals={false}
          axisLine={false}
          dataKey="y"
          mirror={true}
          tick={(props: { payload: { value: number } }) => (
            <Text
              {...props}
              className="font-captionRegular -translate-x-2 -translate-y-4 transform fill-black-05"
            >
              {yTickFormatter(props.payload.value)}
            </Text>
          )}
          tickLine={false}
          type="number"
        >
          <Label
            className="font-mediumExtraSmall -translate-x-1 -translate-y-1/2 transform fill-black-07"
            position="insideLeft"
          >
            {yLabel}
          </Label>
        </YAxis>
        <ZAxis dataKey="z" range={[0, 1000]} scale="linear" type="number" />
        <RechartsTooltip
          content={({ active, payload }) => {
            if (!active || !payload || !isNonEmptyArray(payload)) return null;

            const hoveredData = dataPoints.find(
              (d) => d === payload[0].payload,
            );
            if (!hoveredData) return null;

            return renderTooltip(hoveredData);
          }}
          cursor={false}
          trigger="hover"
        />
        <Scatter className="fill-purple-05" data={dataPoints} shape="circle">
          {dataPoints.map((dataPoint, index) => (
            <Cell
              className="cursor-pointer"
              fillOpacity={getCellOpacity(dataPoint.z)}
              key={`cell-${index}`}
              onClick={() => onCellClick(dataPoint)}
            />
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}
