/**
 * @generated SignedSource<<9d09b57763aee8cd540a6e02cacdec84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
export type OrganizationPostTerminationExercisePeriodType = "FIXED" | "VARIABLE";
export type CreateOrganizationPostTerminationExercisePeriodInput = {
  duration: number;
  durationUnit: DurationUnit;
  extensionDuration?: number | null;
  extensionDurationUnit?: DurationUnit | null;
  organizationId: string;
  thresholdForExtensionInYears?: number | null;
  type: OrganizationPostTerminationExercisePeriodType;
};
export type CreateSlideOver_Mutation$variables = {
  input: CreateOrganizationPostTerminationExercisePeriodInput;
};
export type CreateSlideOver_Mutation$data = {
  readonly createOrganizationPostTerminationExercisePeriod: {
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly displayName: string;
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly id: string;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly displayName: string;
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly extensionDuration: number;
    readonly extensionDurationUnit: DurationUnit;
    readonly id: string;
    readonly thresholdForExtensionInYears: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type CreateSlideOver_Mutation = {
  response: CreateSlideOver_Mutation$data;
  variables: CreateSlideOver_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationUnit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensionDuration",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "extensionDurationUnit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thresholdForExtensionInYears",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSlideOver_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "OrganizationFixedPostTerminationExercisePeriod",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "OrganizationVariablePostTerminationExercisePeriod",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSlideOver_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createOrganizationPostTerminationExercisePeriod",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "type": "OrganizationFixedPostTerminationExercisePeriod",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "type": "OrganizationVariablePostTerminationExercisePeriod",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "deb87e0e7dddb04dabba01b449c5fd7f",
    "id": null,
    "metadata": {},
    "name": "CreateSlideOver_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateSlideOver_Mutation(\n  $input: CreateOrganizationPostTerminationExercisePeriodInput!\n) {\n  createOrganizationPostTerminationExercisePeriod(input: $input) {\n    __typename\n    ... on OrganizationFixedPostTerminationExercisePeriod {\n      id\n      displayName\n      duration\n      durationUnit\n    }\n    ... on OrganizationVariablePostTerminationExercisePeriod {\n      id\n      displayName\n      duration\n      durationUnit\n      extensionDuration\n      extensionDurationUnit\n      thresholdForExtensionInYears\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cd0668624a001f65d6bd05f3a3798cc9";

export default node;
