/**
 * @generated SignedSource<<d6b73ea085100d8b8122a29eecb10d14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseOverview_CTMSGrant$data = {
  readonly earlyExercise: boolean;
  readonly exercisableShares: number;
  readonly quantityExercised: number;
  readonly settled: boolean;
  readonly " $fragmentType": "ExerciseOverview_CTMSGrant";
};
export type ExerciseOverview_CTMSGrant$key = {
  readonly " $data"?: ExerciseOverview_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseOverview_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseOverview_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "settled",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "09c60af5d1c1d321ba65e0e9b9fc260f";

export default node;
