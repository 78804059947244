/**
 * @generated SignedSource<<bb016fa235cf3ded50023d0096bd4346>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Exercises_Organization$data = {
  readonly ctmsExerciseRequests: ReadonlyArray<{
    readonly __typename: "CTMSExerciseRequest";
  }>;
  readonly ctmsExerciseRequestsAreOutdated: boolean;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesEmptyState_Organization" | "ExercisesView_Organization">;
  readonly " $fragmentType": "Exercises_Organization";
};
export type Exercises_Organization$key = {
  readonly " $data"?: Exercises_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Exercises_Organization">;
};

import Exercises_Organization_RefetchQuery_graphql from './Exercises_Organization_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": Exercises_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "Exercises_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsExerciseRequestsAreOutdated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSExerciseRequest",
      "kind": "LinkedField",
      "name": "ctmsExerciseRequests",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesEmptyState_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesView_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a2e01162a9e727a7b26bdfab7d9a715a";

export default node;
