import { FormattedNumberParts } from "react-intl";

import { Typography } from "./ui/Typography";

export const FormattedCurrencyParts: React.FC<{
  className?: string;
  defaultVariant?: React.ComponentProps<typeof Typography>["variant"];
  fractionVariant?: React.ComponentProps<typeof Typography>["variant"];
  signDisplay?: "always" | "auto" | "never";
  value: number;
}> = ({
  className,
  defaultVariant = "Medium/Extra Large",
  fractionVariant = "Medium/Default",
  signDisplay,
  value,
}) => (
  <FormattedNumberParts
    currency="USD"
    signDisplay={signDisplay}
    style="currency"
    value={value}
  >
    {(parts) => (
      <>
        {parts.map((part, index) => {
          switch (part.type) {
            case "decimal":
            case "fraction":
              return (
                <Typography
                  as="span"
                  className={className}
                  key={`${index}`}
                  variant={fractionVariant}
                >
                  {part.value}
                </Typography>
              );
            default:
              return (
                <Typography
                  as="span"
                  className={className}
                  key={`${index}`}
                  variant={defaultVariant}
                >
                  {part.value}
                </Typography>
              );
          }
        })}
      </>
    )}
  </FormattedNumberParts>
);
