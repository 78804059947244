/**
 * @generated SignedSource<<4caa952c4c06614de863033b51022f4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGranteePositionToEquityGridLevel_Grantee$data = {
  readonly equityGridLevel: {
    readonly __typename: "EquityGridLevel";
  } | null;
  readonly missingSharesToEquityGridLevel: number | null;
  readonly offGrid: boolean;
  readonly " $fragmentType": "useGranteePositionToEquityGridLevel_Grantee";
};
export type useGranteePositionToEquityGridLevel_Grantee$key = {
  readonly " $data"?: useGranteePositionToEquityGridLevel_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGranteePositionToEquityGridLevel_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGranteePositionToEquityGridLevel_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offGrid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "missingSharesToEquityGridLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "3aba62ce3bc5d90448b820286ba53854";

export default node;
