/**
 * @generated SignedSource<<5e46f5a932716d0bc21e0156cddacb3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewGrantee_Organization$data = {
  readonly id: string;
  readonly isOriginatingFromRemoteEquity: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectHRISProvidersMessage_Organization" | "GranteeForm_Organization">;
  readonly " $fragmentType": "NewGrantee_Organization";
};
export type NewGrantee_Organization$key = {
  readonly " $data"?: NewGrantee_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewGrantee_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewGrantee_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isOriginatingFromRemoteEquity",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeForm_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectHRISProvidersMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ebc61341e752575dfd37d8b4004eadc0";

export default node;
