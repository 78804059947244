import { graphql, useFragment } from "react-relay";

import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import { GranteeNameWithCountryFlag } from "../../GranteeNameWithCountryFlag";
import { Typography } from "../Typography";
import { TableCellGrantee_Grantee$key } from "./__generated__/TableCellGrantee_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment TableCellGrantee_Grantee on Grantee {
    workRelationship
    ...GranteeNameWithCountryFlag_Grantee
  }
`;

export function TableCellGrantee({
  granteeFragment,
}: {
  granteeFragment: TableCellGrantee_Grantee$key;
}) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <div className="space-y-1">
      <Typography variant="Regular/Extra Small">
        <GranteeNameWithCountryFlag granteeFragment={grantee} />
      </Typography>
      {grantee.workRelationship && (
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Caption"
        >
          {
            WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
              .singularLabel
          }
        </Typography>
      )}
    </div>
  );
}
