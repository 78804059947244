import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { StickyBanner } from "../../components/StickyBanner";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../hooks/useApplicationTheme";
import { FreeTrialPlanStickyBanner_Organization$key } from "./__generated__/FreeTrialPlanStickyBanner_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FreeTrialPlanStickyBanner_Organization on Organization {
    name
  }
`;

export const FreeTrialPlanStickyBanner: React.FC<{
  organizationFragment: FreeTrialPlanStickyBanner_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const mailtoSubject = encodeURI(
    `[${organization.name}] ${applicationName} paid plans.`,
  );

  return (
    <StickyBanner className="sticky top-0 z-10">
      You’re currently running a free trial plan.{" "}
      <a
        className="font-semibold text-primary"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
      >
        contact us
      </a>{" "}
      to learn more about paid plans.
    </StickyBanner>
  );
};
