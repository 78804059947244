import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../hooks/useSafeMutation";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { useToaster } from "../Toaster";
import { Modal } from "../ui/Modal";
import { Toast } from "../ui/Toast";
import { Typography } from "../ui/Typography";
import { BookADemoModal_Organization$key } from "./__generated__/BookADemoModal_Organization.graphql";
import { BookADemoModal_Viewer$key } from "./__generated__/BookADemoModal_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment BookADemoModal_Viewer on Account {
    email
    firstName
    lastName
    isSuperAdmin
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment BookADemoModal_Organization on Organization {
    id
    name
    plan
  }
`;

const MUTATION = graphql`
  mutation BookADemoModal_MarkOrganizationFreemiumDemoAsBooked_Mutation(
    $organizationId: OrganizationId!
  ) {
    markOrganizationFreemiumDemoAsBooked(id: $organizationId) {
      ...BookADemoModal_Organization
    }
  }
`;

export const BookADemoModal: React.FC<{
  organizationFragment: BookADemoModal_Organization$key;
  viewerFragment: BookADemoModal_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const [submitMarkDemoAsBookedMutation] = useSafeMutation(MUTATION);

  const toaster = useToaster();

  useCalendlyEventListener({
    onEventScheduled: async () => {
      await submitMarkDemoAsBookedMutation({
        variables: {
          organizationId: organization.id,
        },
      });

      toaster.push(
        <Toast variant="congrats">
          Your demo call has successfully been scheduled. We can’t wait to meet
          you!
        </Toast>,
      );
    },
  });

  const showModal =
    !viewer.isSuperAdmin && organization.plan === "freemiumDemoToBook";

  return (
    <Modal.Raw
      panelClassName="p-10 overflow-y-auto"
      show={showModal}
      width={510}
    >
      <div className="space-y-6">
        <div className="space-y-4">
          <Typography
            as="div"
            className="text-center"
            variant="Medium/Extra Large"
          >
            ⏰
          </Typography>
          <Typography as="div" className="text-center" variant="Medium/Large">
            You&apos;re all set!
            <br />
            Time to book your onboarding
          </Typography>
          <Typography
            as="div"
            className="text-center text-black-05"
            variant="Regular/Small"
          >
            Reserve your spot for a white glove onboarding and become an equity
            compensation wizard 🪄.
          </Typography>
        </div>
        <div className="overflow-hidden rounded-lg bg-[#F7F7F7]">
          <InlineWidget
            iframeTitle="Schedule a demo"
            LoadingSpinner={LoadingPlaceholder}
            pageSettings={{
              backgroundColor: "F7F7F7",
              hideEventTypeDetails: true,
              hideGdprBanner: true,
              hideLandingPageDetails: true,
            }}
            prefill={{
              customAnswers: {
                a1: organization.name,
              },
              email: viewer?.email ?? "",
              name: viewer ? `${viewer.firstName} ${viewer.lastName}` : "",
            }}
            styles={{
              height: "600px",
              textAlign: "center",
            }}
            url="https://calendly.com/d/z33-6ks-wby/meet-easop-demo"
          />
        </div>
      </div>
    </Modal.Raw>
  );
};
