import { isEmpty, isNil } from "lodash";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EquityOfferSettingsStep } from "../pages/Admin/EquityOffer/__generated__/EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation.graphql";
import { EQUITY_OFFER_STEPS } from "../pages/Admin/EquityOffer/EquityOfferContext";
import { useEquityOfferTrackEvent_EquityOffer$key } from "./__generated__/useEquityOfferTrackEvent_EquityOffer.graphql";
import { useTrackEvent } from "./useAnalytics";
import { useOrganizationSharesUtil } from "./useOrganizationSharesUtil";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment useEquityOfferTrackEvent_EquityOffer on EquityOffer {
    id
    candidateName
    position
    taxResidenceCountry {
      name
    }
    workRelationship
    displayCompanyInformation
    exercisePrice
    displayEquityAsDollarValue
    displayEquityAsPercentage
    shares
    salary
    benefits
    instrument {
      id
      description
    }
    vestingSchedule {
      id
      name
    }
    postTerminationExercisePeriod {
      displayName
    }
    displayFAQ
    displayProjectionScenarios
    finalThoughts
    organization {
      latestFairMarketValue {
        value
      }
      ...useOrganizationSharesUtil_Organization
    }
  }
`;

export type EquityOfferSharingMethod = "link" | "pdf";

export const useEquityOfferTrackEvent = ({
  equityOfferFragment,
}: {
  equityOfferFragment: useEquityOfferTrackEvent_EquityOffer$key;
}) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  const intl = useIntl();
  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: equityOffer.organization,
    });

  const strikePriceChanged = useMemo(
    () =>
      !isNil(equityOffer.exercisePrice) &&
      !isNil(equityOffer.organization.latestFairMarketValue) &&
      equityOffer.exercisePrice !==
        equityOffer.organization.latestFairMarketValue.value,
    [equityOffer.exercisePrice, equityOffer.organization.latestFairMarketValue],
  );

  const { ownership, shares, usdValue } = useMemo(() => {
    if (isNil(equityOffer.shares)) {
      return {
        ownership: null,
        shares: null,
        usdValue: null,
      };
    }

    const shares = equityOffer.shares;

    return {
      ownership: sharesToFullyDilutedRatio(shares),
      shares,
      usdValue: sharesToValue(shares),
    };
  }, [equityOffer.shares, sharesToFullyDilutedRatio, sharesToValue]);

  const trackEvent = useTrackEvent();
  const trackEquityOfferEvent = useCallback(
    (eventName: string, additionalProperties: Record<string, unknown> = {}) => {
      trackEvent(eventName, {
        ["benefits"]: equityOffer.benefits.join(", "),
        ["benefits_displayed"]: !isEmpty(equityOffer.benefits),
        ["display_company_information"]: equityOffer.displayCompanyInformation,
        ["dollars_displayed"]: equityOffer.displayEquityAsDollarValue,
        ["equity_package_in_dollars"]:
          usdValue !== null
            ? intl.formatNumber(usdValue, {
                currency: "USD",
                style: "currency",
              })
            : null,
        ["equity_package_in_percentage"]:
          ownership !== null
            ? intl.formatNumber(ownership, {
                maximumFractionDigits: 2,
                style: "percent",
              })
            : null,
        ["equity_package_in_shares"]:
          shares !== null ? intl.formatNumber(shares) : null,
        ["equity_university_displayed"]: equityOffer.displayFAQ,
        ["final_words_filled"]: !isEmpty(equityOffer.finalThoughts),
        ["instrument"]: equityOffer.instrument
          ? equityOffer.instrument.description
          : null,
        ["instrument_id"]: equityOffer.instrument?.id ?? null,
        ["name_filled"]: !!equityOffer.candidateName,
        ["percentage_displayed"]: equityOffer.displayEquityAsPercentage,
        position: equityOffer.position,
        ["post_termination_exercise_period"]:
          equityOffer.postTerminationExercisePeriod?.displayName ?? null,
        ["projections_displayed"]: equityOffer.displayProjectionScenarios,
        ["salary"]: !isNil(equityOffer.salary)
          ? intl.formatNumber(equityOffer.salary, {
              currency: "USD",
              style: "currency",
            })
          : null,
        ["salary_displayed"]: !isNil(equityOffer.salary),
        ["strike_price_changed"]: strikePriceChanged,
        ["tax_residence"]: equityOffer.taxResidenceCountry?.name || null,
        ["vesting_schedule_id"]: equityOffer.vestingSchedule?.id ?? null,
        ["vesting_schedule_name"]: equityOffer.vestingSchedule
          ? equityOffer.vestingSchedule.name
          : null,
        ["work_relationship"]: equityOffer.workRelationship,
        ...additionalProperties,
      });
    },
    [
      trackEvent,
      equityOffer,
      intl,
      strikePriceChanged,
      shares,
      ownership,
      usdValue,
    ],
  );

  const trackStepCompleted = useCallback(
    (stepKey: EquityOfferSettingsStep) => {
      const step = EQUITY_OFFER_STEPS.find(({ step }) => step === stepKey);
      if (!step) {
        throw new Error("can't find step");
      }
      trackEquityOfferEvent(`Equity Offer - ${step.analyticsName} Completed`);
    },
    [trackEquityOfferEvent],
  );
  const trackEquityOfferShared = useCallback(
    (sharingMethod: EquityOfferSharingMethod) => {
      trackEquityOfferEvent("Equity Offer - Shared", {
        ["sharing_method"]: sharingMethod,
      });
    },
    [trackEquityOfferEvent],
  );

  return { trackEquityOfferShared, trackStepCompleted };
};
