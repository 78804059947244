/**
 * @generated SignedSource<<c8bd7011d46a403a43c3a39feae59645>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMismatchBetweenCtmsAndEasopCapTableGuard_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "useMismatchBetweenCtmsAndEasopCapTableGuard_Organization";
};
export type useMismatchBetweenCtmsAndEasopCapTableGuard_Organization$key = {
  readonly " $data"?: useMismatchBetweenCtmsAndEasopCapTableGuard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMismatchBetweenCtmsAndEasopCapTableGuard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMismatchBetweenCtmsAndEasopCapTableGuard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "526f3f74e13527677602b665c0b9843d";

export default node;
