/**
 * @generated SignedSource<<c3fb548d840902afb637b67ae0933552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeTerminationCTMSGrantAmendmentRequestStatus = "IMPLEMENTATION" | "IMPLEMENTED" | "PENDING_APPROVAL" | "READY_FOR_CONSENT" | "UNDER_REVIEW";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$data = ReadonlyArray<{
  readonly grant: {
    readonly grantee: {
      readonly id: string;
      readonly name: string;
      readonly workRelationship: WorkRelationship | null;
      readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
    };
    readonly id: string;
    readonly label: string;
  };
  readonly id: string;
  readonly isDeletable: boolean;
  readonly status: GranteeTerminationCTMSGrantAmendmentRequestStatus;
  readonly " $fragmentType": "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest";
}>;
export type GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$key = ReadonlyArray<{
  readonly " $data"?: GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "grant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GranteeNameWithCountryFlag_Grantee"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GranteeTerminationCTMSGrantAmendmentRequest",
  "abstractKey": null
};
})();

(node as any).hash = "8116d9a6156c0038b814783b7770dd22";

export default node;
