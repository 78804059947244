/**
 * @generated SignedSource<<c6898fc76a639e4a42937f1bf481596c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsPage_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationModal_Organization">;
  readonly " $fragmentType": "LegalWatchOutsPage_Organization";
}>;
export type LegalWatchOutsPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: LegalWatchOutsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "LegalWatchOutsPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8855b6511afe8642b50e1e4d54c702b9";

export default node;
