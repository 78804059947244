/**
 * @generated SignedSource<<4441c9894db3a1d04d116477886643b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettings_Organization$data = {
  readonly companies: ReadonlyArray<{
    readonly completeName: string;
    readonly id: string;
  }>;
  readonly id: string;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly geography: {
      readonly country: {
        readonly code: string;
      };
    };
    readonly isTaxFavoredEquityTypeUnlocked: boolean;
    readonly nsoEquityTypeSubplanName: string | null;
    readonly taxFavoredEquityTypeSubplanName: string | null;
  }>;
  readonly stockOptionPlanName: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteOrganizationButton_Organization" | "MarkOrganizationAsTerminatedButton_Organization">;
  readonly " $fragmentType": "OrganizationSettings_Organization";
};
export type OrganizationSettings_Organization$key = {
  readonly " $data"?: OrganizationSettings_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettings_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettings_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companies",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completeName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stockOptionPlanName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Geography",
          "kind": "LinkedField",
          "name": "geography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTaxFavoredEquityTypeUnlocked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nsoEquityTypeSubplanName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredEquityTypeSubplanName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteOrganizationButton_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MarkOrganizationAsTerminatedButton_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "1d9f6f0a1ff81b5dc6896576be55de30";

export default node;
