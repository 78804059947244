/**
 * @generated SignedSource<<46bc34b8a63f686fa77f0e35896363d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InviteEquityViewerSlideOver_Admin_Query$variables = {
  accountId: string;
  organizationId: string;
};
export type InviteEquityViewerSlideOver_Admin_Query$data = {
  readonly isAccountAnOrganizationEquityViewer: boolean;
};
export type InviteEquityViewerSlideOver_Admin_Query = {
  response: InviteEquityViewerSlideOver_Admin_Query$data;
  variables: InviteEquityViewerSlideOver_Admin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "accountId",
        "variableName": "accountId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "isAccountAnOrganizationEquityViewer",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteEquityViewerSlideOver_Admin_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteEquityViewerSlideOver_Admin_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2d68d214a2025f48f5ebf891910e20e5",
    "id": null,
    "metadata": {},
    "name": "InviteEquityViewerSlideOver_Admin_Query",
    "operationKind": "query",
    "text": "query InviteEquityViewerSlideOver_Admin_Query(\n  $organizationId: OrganizationId!\n  $accountId: String!\n) {\n  isAccountAnOrganizationEquityViewer(accountId: $accountId, organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "dd245e5f0b90cea06a8b5d5f091bdb9e";

export default node;
