import {
  Tab as HeadlessUITab,
  TabGroup as HeadlessUITabGroup,
  TabList as HeadlessUITabList,
  TabPanel as HeadlessUITabPanel,
  TabPanels as HeadlessUITabPanels,
} from "@headlessui/react";
import classNames from "classnames";
import React, { Suspense } from "react";

import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { Pill } from "./Pill";
import { Typography } from "./Typography";

const _Tab: React.FC<React.PropsWithChildren> = ({ children }) => (
  <HeadlessUITab
    className={classNames(
      "border-b-2 border-transparent pb-2 text-gray-09 outline-none transition-all hover:border-primary-02 hover:text-black-05 ui-selected:border-primary ui-selected:text-black-07",
    )}
  >
    <Typography variant="Medium/Extra Small">{children}</Typography>
  </HeadlessUITab>
);

const List: React.FC<
  {
    children:
      | React.ReactElement<React.ComponentProps<typeof _Tab>>
      | React.ReactElement<React.ComponentProps<typeof _Tab>>[];
  } & { className?: string }
> = ({ children, className }) => (
  <HeadlessUITabList
    className={classNames(
      "flex gap-6 border-b-[0.5px] border-gray-04",
      className,
    )}
  >
    {children}
  </HeadlessUITabList>
);

const Panel: React.FC<React.PropsWithChildren> = ({ children }) => (
  <HeadlessUITabPanel>
    <Suspense fallback={<LoadingPlaceholder />}>{children}</Suspense>
  </HeadlessUITabPanel>
);

const Panels: React.FC<
  Pick<React.ComponentProps<"div">, "className"> & {
    children:
      | React.ReactElement<React.ComponentProps<typeof Panel>>
      | React.ReactElement<React.ComponentProps<typeof Panel>>[];
  }
> = ({ children, className }) => (
  <HeadlessUITabPanels className={className}>{children}</HeadlessUITabPanels>
);

const Group: React.FC<
  React.PropsWithChildren<{
    className?: string;
    onChange?: (index: number) => void;
    selectedIndex?: number;
  }>
> = ({ children, className, onChange, selectedIndex }) => (
  <HeadlessUITabGroup onChange={onChange} selectedIndex={selectedIndex}>
    <div className={classNames("space-y-4", className)}>{children}</div>
  </HeadlessUITabGroup>
);

const TabWithCount: React.FC<
  React.PropsWithChildren<{
    count: number;
    skeleton?: boolean;
  }>
> = ({ children, count, skeleton }) => {
  return (
    <Tab>
      <div className="flex items-center gap-2">
        <div>{children}</div>
        <Pill skeleton={skeleton}>{count}</Pill>
      </div>
    </Tab>
  );
};

export const Tab = Object.assign(_Tab, {
  Group,
  List,
  Panel,
  Panels,
  WithCount: TabWithCount,
});
