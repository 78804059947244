import {
  ArrowPathIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  DocumentCurrencyDollarIcon,
  DocumentDuplicateIcon,
  GlobeAmericasIcon,
  ScaleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { generatePath } from "react-router-dom";

import { Page } from "../../../components/Page";
import { SuperAdminHomeLink } from "../../../components/SuperAdminHomeLink";
import { Divider } from "../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";

const _OrganizationAdmin: React.FC = () => {
  return (
    <CenteredColumnLayout maxWidth="800" showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Organization admin
        </Typography>
        <RoundedBox className="flex flex-col" withBorder>
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="primary"
                icon={<DocumentDuplicateIcon />}
              />
            }
            subTitle="Change an easop grant instrument or override a ctms grant with an instrument"
            title="Change grant instrument"
            to={generatePath(
              APPLICATION_ROUTES.superAdminChangeGrantInstrument,
            )}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="primary"
                icon={<CurrencyDollarIcon />}
              />
            }
            subTitle="Mark a grant as repriced to another grant"
            title="Repricing"
            to={generatePath(APPLICATION_ROUTES.superAdminRepricing)}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon color="red" icon={<ArrowPathIcon />} />
            }
            subTitle="List the organizations whose cap table data differs from that of CTMS and Easop"
            title="Sync cap table data"
            to={generatePath(APPLICATION_ROUTES.superAdminEquityAlerting)}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon color="purple" icon={<CalendarIcon />} />
            }
            subTitle="Approve the date of sub plans here"
            title="Sub plan approval date"
            to={generatePath(APPLICATION_ROUTES.superAdminSubplanApprovalDate)}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="red"
                icon={<GlobeAmericasIcon />}
              />
            }
            subTitle="Check which organizations are paying for unused geographies"
            title="Unused geographies"
            to={generatePath(APPLICATION_ROUTES.superAdminUnusedGeographies)}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon color="orange" icon={<ScaleIcon />} />
            }
            subTitle="Check which organizations have added RE as their law firm in Carta"
            title="Carta org with Remote Equity as law firm"
            to={generatePath(APPLICATION_ROUTES.superAdminCartaOrganizations)}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="red"
                icon={<DocumentCurrencyDollarIcon />}
              />
            }
            subTitle="Remote Equity organizations that have reached the max amount of active grantees or countries unlocked"
            title="Plan threshold passed"
            to={generatePath(
              APPLICATION_ROUTES.superAdminRemoteEquityPlanThresholdsExceededOrganizations,
            )}
          />
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};

const OrganizationAdmin: React.FC = () => {
  return (
    <Page
      analyticsName="Super Admin - Organization admin"
      title={`Super admin | Organization admin`}
    >
      <_OrganizationAdmin />
    </Page>
  );
};

export default OrganizationAdmin;
