/**
 * @generated SignedSource<<db3df6c34c1d4934572f301f8de5586c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type ValuationWarningReason = "NO_VALUATION" | "VALUATION_EXPIRED" | "VALUATION_EXPIRING_IN_LESS_THAN_30_DAYS" | "VALUATION_EXPIRING_IN_LESS_THAN_60_DAYS" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_NOT_SENT_TO_BOARD_FOR_APPROVAL" | "VALUATION_PENDING_BOARD_APPROVAL" | "VALUATION_UNDER_REVIEW";
import { FragmentRefs } from "relay-runtime";
export type ValuationWarningMessage_ValuationWarning$data = {
  readonly reason: ValuationWarningReason;
  readonly valuationType: ValuationType;
  readonly " $fragmentType": "ValuationWarningMessage_ValuationWarning";
};
export type ValuationWarningMessage_ValuationWarning$key = {
  readonly " $data"?: ValuationWarningMessage_ValuationWarning$data;
  readonly " $fragmentSpreads": FragmentRefs<"ValuationWarningMessage_ValuationWarning">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ValuationWarningMessage_ValuationWarning",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationType",
      "storageKey": null
    }
  ],
  "type": "OrganizationValuationWarning",
  "abstractKey": null
};

(node as any).hash = "201d6146bf5e3c1293ff885c983ebe22";

export default node;
