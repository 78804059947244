/**
 * @generated SignedSource<<247c1835a668a226da52e01a51a56d86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GeographiesDocumentsTable_DownloadDocumentsItem_Instrument$data = {
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentSpreads": FragmentRefs<"useDownloadInstrumentDocumentsPreview_Instrument">;
  readonly " $fragmentType": "GeographiesDocumentsTable_DownloadDocumentsItem_Instrument";
};
export type GeographiesDocumentsTable_DownloadDocumentsItem_Instrument$key = {
  readonly " $data"?: GeographiesDocumentsTable_DownloadDocumentsItem_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_DownloadDocumentsItem_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeographiesDocumentsTable_DownloadDocumentsItem_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDownloadInstrumentDocumentsPreview_Instrument"
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "d6286740283bc3787513a5e8d4765175";

export default node;
