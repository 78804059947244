import { PlusIcon } from "@heroicons/react/24/outline";
import { forwardRef, useTransition } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CreateSlideOverRemote } from "../../../pages/Admin/Grants/Configure/VestingSchedules/CreateSlideOver";
import { Button } from "../Button";
import { SelectVestingScheduleInput_Organization$key } from "./__generated__/SelectVestingScheduleInput_Organization.graphql";
import { SelectAutocomplete } from "./Inputs/Select/SelectAutocomplete";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SelectVestingScheduleInput_Organization on Organization
  @refetchable(
    queryName: "SelectVestingScheduleInput_Organization_RefetchQuery"
  ) {
    id
    vestingSchedules {
      id
      name
    }
    ...CreateSlideOver_VestingSchedule_Organization
  }
`;

const _SelectVestingScheduleInput = forwardRef<
  HTMLDivElement,
  {
    disabled?: boolean;
    invalid?: boolean;
    onChange: (vestingScheduleId: string) => void;
    organizationFragment: SelectVestingScheduleInput_Organization$key;
    selectedVestingScheduleId?: string;
  }
>(function SelectVestingScheduleInput(
  {
    disabled,
    invalid,
    onChange,
    organizationFragment,
    selectedVestingScheduleId,
  },
  ref,
) {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const createSlideOverController = CreateSlideOverRemote.useController();
  const [transitionInProgress, startTransition] = useTransition();

  const handleNewButtonClicked = () => {
    createSlideOverController.open({
      data: {
        organizationFragment: organization,
      },
      onClose: ({ vestingScheduleId }) => {
        if (vestingScheduleId) {
          startTransition(() => {
            refetchOrganization(
              {},
              {
                fetchPolicy: "network-only",
              },
            );
            onChange(vestingScheduleId);
          });
        }
      },
    });
  };

  return (
    <div className="space-y-2" ref={ref}>
      <SelectAutocomplete
        disabled={disabled}
        getOptionLabel={(vestingSchedule) => vestingSchedule.name}
        getOptionValue={(vestingSchedule) => vestingSchedule.id}
        invalid={invalid}
        onChange={(option) => {
          if (option) {
            onChange(option.id);
          }
        }}
        options={organization.vestingSchedules}
        value={organization.vestingSchedules.find(
          (vestingSchedule) => vestingSchedule.id === selectedVestingScheduleId,
        )}
      />
      {!disabled && (
        <Button
          leftIcon={<PlusIcon />}
          loading={transitionInProgress}
          onClick={handleNewButtonClicked}
          size="extra small"
          type="button"
          variant="Tertiary Link"
        >
          Add new vesting schedule
        </Button>
      )}
    </div>
  );
});

const FormSelectVestingScheduleInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  disabled,
  name,
  organizationFragment,
}: {
  control: Control<TFieldValues>;
  disabled?: boolean;
  name: TName;
  organizationFragment: SelectVestingScheduleInput_Organization$key;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <_SelectVestingScheduleInput
          {...field}
          disabled={disabled}
          invalid={!!fieldState.error || undefined}
          onChange={(vestingScheduleId) => {
            return field.onChange(vestingScheduleId);
          }}
          organizationFragment={organizationFragment}
          selectedVestingScheduleId={field.value}
        />
      )}
    />
  );
};

export const SelectVestingScheduleInput = Object.assign(
  _SelectVestingScheduleInput,
  {
    Form: FormSelectVestingScheduleInput,
  },
);
