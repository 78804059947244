import { startTransition, useCallback, useMemo, useState } from "react";
import { useFragment, useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  NewHirePlanningEntryModal,
  useNewHirePlanningEntryModalState,
} from "../NewHirePlanningEntryModal";
import {
  PlanningEntryDeletionConfirmationModal,
  usePlanningEntryDeletionConfirmationModalState,
} from "../PlanningEntryDeletionConfirmationModal";
import {
  RefresherGrantPlanningEntryModal,
  useRefresherGrantPlanningEntryModalState,
} from "../RefresherGrantPlanningEntryModal";
import {
  SharesValueSwitcher,
  useSharesValueSwitcherMode,
} from "../SharesValueSwitcher";
import {
  TerminationPlanningEntryModal,
  useTerminationPlanningEntryModalState,
} from "../TerminationPlanningEntryModal";
import { Button } from "../ui/Button";
import { SlideOver } from "../ui/SlideOver";
import { Typography } from "../ui/Typography";
import {
  PlanningEntryDetailsSlideOver_Organization$data,
  PlanningEntryDetailsSlideOver_Organization$key,
} from "./__generated__/PlanningEntryDetailsSlideOver_Organization.graphql";
import {
  PlanningEntryDetailsSlideOver_PlanningEntry$data,
  PlanningEntryDetailsSlideOver_PlanningEntry$key,
} from "./__generated__/PlanningEntryDetailsSlideOver_PlanningEntry.graphql";
import { NewHireGrantPlanningEntryDetails } from "./NewHireGrantPlanningEntryDetails";
import { RefresherGrantPlanningEntryDetails } from "./RefresherGrantPlanningEntryDetails";
import { TerminationPlanningEntryDetails } from "./TerminationPlanningEntryDetails";

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment PlanningEntryDetailsSlideOver_PlanningEntry on PlanningEntry
  @refetchable(
    queryName: "PlanningEntryDetailsSlideOver_PlanningEntry_RefetchQuery"
  ) {
    __typename
    id
    editable
    type
    ... on NewHireGrantPlanningEntry {
      ...NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry
      ...NewHirePlanningEntryModal_NewHireGrantPlanningEntry
    }
    ... on RefresherGrantPlanningEntry {
      refresherType
      ...RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry
      ...RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry
    }
    ... on TerminationPlanningEntry {
      ...TerminationPlanningEntryDetails_TerminationPlanningEntry
      ...TerminationPlanningEntryModal_TerminationPlanningEntry
    }
  }
`;

type PlanningEntry = PlanningEntryDetailsSlideOver_PlanningEntry$data;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningEntryDetailsSlideOver_Organization on Organization {
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    ...NewHireGrantPlanningEntryDetails_Organization
    ...RefresherGrantPlanningEntryDetails_Organization
    ...TerminationPlanningEntryDetails_Organization
    ...NewHirePlanningEntryModal_Organization
    ...TerminationPlanningEntryModal_Organization
    ...RefresherGrantPlanningEntryModal_Organization
  }
`;

type State =
  | {
      planningEntryFragment: null | PlanningEntryDetailsSlideOver_PlanningEntry$key;
      show: false;
    }
  | {
      planningEntryFragment: PlanningEntryDetailsSlideOver_PlanningEntry$key;
      show: true;
    };

export function usePlanningEntryDetailsSlideOverState() {
  const [state, setState] = useState<State>({
    planningEntryFragment: null,
    show: false,
  });

  const open = ({
    planningEntryFragment,
  }: {
    planningEntryFragment: PlanningEntryDetailsSlideOver_PlanningEntry$key;
  }) => {
    setState({
      planningEntryFragment,
      show: true,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    closePlanningEntryDetailsSlideOver: close,
    openPlanningEntryDetailsSlideOver: open,
    planningEntryDetailsSlideOverState: state,
  };
}

const getSlideOverTitle = (entry: PlanningEntry) => {
  switch (entry.type) {
    case "EXCEPTIONAL_GRANT":
      return "Exceptional grant";
    case "LEVELING_GRANT":
      return "Leveling grant";
    case "NEW_HIRE_GRANT":
      return "New hire";
    case "PROMOTION_GRANT":
      return "Promotion grant";
    case "TENURE_GRANT":
      return "Tenure grant";
    case "TERMINATION":
      return "Termination";
  }
};

const PlanningEntryDetailsSlideOverContent: React.FC<{
  onClose: () => void;
  organization: PlanningEntryDetailsSlideOver_Organization$data;
  planningEntryFragment: PlanningEntryDetailsSlideOver_PlanningEntry$key;
}> = ({ onClose, organization, planningEntryFragment }) => {
  const [planningEntry, refetchPlanningEntry] = useRefetchableFragment(
    PLANNING_ENTRY_FRAGMENT,
    planningEntryFragment,
  );

  if (planningEntry.__typename === "%other") {
    throw new Error(`Unexpected entry type`);
  }

  const title = useMemo(
    () => getSlideOverTitle(planningEntry),
    [planningEntry],
  );

  const {
    closePlanningEntryDeletionConfirmationModal,
    planningEntryDeletionConfirmationModalState,
    showPlanningEntryDeletionConfirmationModal,
  } = usePlanningEntryDeletionConfirmationModalState();

  const [selectedEquityMode, setSelectedEquityMode] =
    useSharesValueSwitcherMode({
      fullyDilutedShares: organization.fullyDilutedShares,
      initialMode: "SHARES",
      latestPricePerShare: organization.latestPricePerShare?.value ?? null,
    });

  const content = useMemo(() => {
    switch (planningEntry.__typename) {
      case "NewHireGrantPlanningEntry":
        return (
          <NewHireGrantPlanningEntryDetails
            equityMode={selectedEquityMode}
            organizationFragment={organization}
            planningEntryFragment={planningEntry}
          />
        );
      case "RefresherGrantPlanningEntry":
        return (
          <RefresherGrantPlanningEntryDetails
            equityMode={selectedEquityMode}
            organizationFragment={organization}
            planningEntryFragment={planningEntry}
          />
        );
      case "TerminationPlanningEntry":
        return (
          <TerminationPlanningEntryDetails
            equityMode={selectedEquityMode}
            organizationFragment={organization}
            planningEntryFragment={planningEntry}
          />
        );
    }
  }, [planningEntry, organization, selectedEquityMode]);

  const {
    closeNewHirePlanningEntryModal,
    newHirePlanningEntryModalState,
    openNewHirePlanningEntryModalInEditMode,
  } = useNewHirePlanningEntryModalState();
  const {
    closeTerminationPlanningEntryModal,
    openTerminationPlanningEntryModalInEditMode,
    terminationPlanningEntryModalState,
  } = useTerminationPlanningEntryModalState();

  const {
    closeRefresherGrantPlanningEntryModal,
    openRefresherGrantPlanningEntryModalInEditMode,
    refresherGrantPlanningEntryModalState,
  } = useRefresherGrantPlanningEntryModalState();

  const handleEditClick = () => {
    switch (planningEntry.__typename) {
      case "NewHireGrantPlanningEntry":
        openNewHirePlanningEntryModalInEditMode(planningEntry);
        break;
      case "RefresherGrantPlanningEntry": {
        if (!planningEntry.refresherType) {
          throw new Error(`Unexpected refresher type`);
        }
        openRefresherGrantPlanningEntryModalInEditMode({
          editedPlanningEntryFragment: planningEntry,
          refresherGrantPlanningEntryType: planningEntry.refresherType,
        });
        break;
      }
      case "TerminationPlanningEntry":
        openTerminationPlanningEntryModalInEditMode(planningEntry);
        break;
    }
  };

  const onPlanningEntryEdited = useCallback(() => {
    startTransition(() => {
      refetchPlanningEntry({});
    });
  }, [refetchPlanningEntry]);

  return (
    <>
      <NewHirePlanningEntryModal
        onClose={closeNewHirePlanningEntryModal}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organizationFragment={organization}
        state={newHirePlanningEntryModalState}
      />
      <TerminationPlanningEntryModal
        onClose={closeTerminationPlanningEntryModal}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organizationFragment={organization}
        state={terminationPlanningEntryModalState}
      />
      <RefresherGrantPlanningEntryModal
        onClose={closeRefresherGrantPlanningEntryModal}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organizationFragment={organization}
        state={refresherGrantPlanningEntryModalState}
      />
      <PlanningEntryDeletionConfirmationModal
        onClose={closePlanningEntryDeletionConfirmationModal}
        onPlanningEntryDeleted={onClose}
        state={planningEntryDeletionConfirmationModalState}
      />
      <div className="space-y-6 px-6 pb-6">
        <Typography
          as="div"
          className="w-full text-center"
          variant="Medium/Small"
        >
          {title}
        </Typography>
        <div className="flex items-center justify-center gap-2">
          <Button
            onClick={() =>
              showPlanningEntryDeletionConfirmationModal(planningEntry.id)
            }
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Delete
          </Button>
          {planningEntry.editable && (
            <Button
              onClick={handleEditClick}
              size="small"
              type="button"
              variant="Primary Full"
            >
              Edit
            </Button>
          )}
        </div>
        <div className="flex justify-center">
          <SharesValueSwitcher
            fullyDilutedShares={organization.fullyDilutedShares}
            latestPricePerShare={
              organization.latestPricePerShare?.value ?? null
            }
            onChange={setSelectedEquityMode}
            value={selectedEquityMode}
          />
        </div>
        {content}
      </div>
    </>
  );
};

export const PlanningEntryDetailsSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: PlanningEntryDetailsSlideOver_Organization$key;
  state: State;
}> = ({ onClose, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <SlideOver
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={state.show}
      width="2xl"
    >
      {state.planningEntryFragment && (
        <PlanningEntryDetailsSlideOverContent
          onClose={onClose}
          organization={organization}
          planningEntryFragment={state.planningEntryFragment}
        />
      )}
    </SlideOver>
  );
};
