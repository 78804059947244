/**
 * @generated SignedSource<<4d3e740cf6d1f266c21dd87b9150de8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CartaOrganizationsPage_Query$variables = Record<PropertyKey, never>;
export type CartaOrganizationsPage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CartaOrganizationsPage_Organizations">;
  }>;
};
export type CartaOrganizationsPage_Query = {
  response: CartaOrganizationsPage_Query$data;
  variables: CartaOrganizationsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "connectedToCarta": true
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CartaOrganizationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CartaOrganizationsPage_Organizations"
          }
        ],
        "storageKey": "organizations(filters:{\"connectedToCarta\":true})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CartaOrganizationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": "organizations(filters:{\"connectedToCarta\":true})"
      }
    ]
  },
  "params": {
    "cacheID": "b60cb9f0f6beebf64dde01182c8f1a6c",
    "id": null,
    "metadata": {},
    "name": "CartaOrganizationsPage_Query",
    "operationKind": "query",
    "text": "query CartaOrganizationsPage_Query {\n  organizations(filters: {connectedToCarta: true}) {\n    ...CartaOrganizationsPage_Organizations\n    id\n  }\n}\n\nfragment CartaOrganizationsPage_Organizations on Organization {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "7e0d4d38bc490101ea80fbceb92f5374";

export default node;
