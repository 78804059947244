/**
 * @generated SignedSource<<93684b513e0bdbabba339c4c79a74690>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type UpdateOrganizationGrantDefaultsInput = {
  accelerationClause?: AccelerationClause | null;
  allowEarlyExercise: boolean;
  postTerminationExercisePeriodId?: any | null;
  vestingScheduleId?: any | null;
};
export type Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation$variables = {
  input: UpdateOrganizationGrantDefaultsInput;
  organizationId: string;
};
export type Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation$data = {
  readonly updateOrganizationAdvisorsNewHiresGrantDefaults: {
    readonly " $fragmentSpreads": FragmentRefs<"Advisors_Organization">;
  };
};
export type Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation = {
  response: Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation$data;
  variables: Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowEarlyExercise",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationAdvisorsNewHiresGrantDefaults",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Advisors_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationAdvisorsNewHiresGrantDefaults",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGrantDefaults",
            "kind": "LinkedField",
            "name": "advisorsNewHiresGrantDefaults",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accelerationClause",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "postTerminationExercisePeriod",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "vestingSchedule",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "allowAcceleration",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "postTerminationExercisePeriods",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bc59fc3919ca117df1a851873f612a3",
    "id": null,
    "metadata": {},
    "name": "Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation",
    "operationKind": "mutation",
    "text": "mutation Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation(\n  $input: UpdateOrganizationGrantDefaultsInput!\n  $organizationId: OrganizationId!\n) {\n  updateOrganizationAdvisorsNewHiresGrantDefaults(input: $input, organizationId: $organizationId) {\n    ...Advisors_Organization\n    id\n  }\n}\n\nfragment Advisors_Organization on Organization {\n  id\n  advisorsNewHiresGrantDefaults {\n    accelerationClause\n    allowEarlyExercise\n    postTerminationExercisePeriod {\n      __typename\n      id\n    }\n    vestingSchedule {\n      __typename\n      id\n    }\n  }\n  ...GrantDefaultsForm_Organization\n}\n\nfragment GrantDefaultsForm_Organization on Organization {\n  allowAcceleration\n  allowEarlyExercise\n  vestingSchedules {\n    __typename\n    id\n    name\n  }\n  postTerminationExercisePeriods {\n    __typename\n    id\n    displayName\n  }\n}\n"
  }
};
})();

(node as any).hash = "7af4e88a0fc5816cbec648900c6cfefd";

export default node;
