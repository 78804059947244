/**
 * @generated SignedSource<<6b5ad089bcd04e136bb7a6c777749cc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestApprovalSection_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "ExerciseRequestApprovalSection_Organization";
};
export type ExerciseRequestApprovalSection_Organization$key = {
  readonly " $data"?: ExerciseRequestApprovalSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestApprovalSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestApprovalSection_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ca553639dcc64e9e0c5616c150ebf239";

export default node;
