import React from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { GrantedSharesBreakdownCard_Organization$key } from "./__generated__/GrantedSharesBreakdownCard_Organization.graphql";
import { CircularProgress } from "./CircularProgress";
import { FormattedFMV } from "./FormattedFMV";
import { Card } from "./ui/Card";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantedSharesBreakdownCard_Organization on Organization {
    poolGrantedSharesBreakdown {
      real
      virtual
      total
    }
    latestPricePerShare {
      value
    }
  }
`;

const GrantedSharesBreakdownCardRow: React.FC<{
  color: string;
  label: string;
  value: number;
}> = ({ color, label, value }) => {
  return (
    <div className="flex justify-between p-4">
      <Typography as="div" variant="Medium/Default">
        <FormattedNumber value={value} />
      </Typography>

      <div className="flex items-center gap-2">
        <div className={`h-[10px] w-[10px] rounded-sm ${color}`}></div>

        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {label}
        </Typography>
      </div>
    </div>
  );
};

export const GrantedSharesBreakdownCard: React.FC<{
  className?: string;
  organizationFragment: GrantedSharesBreakdownCard_Organization$key;
}> = ({ className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (!organization.poolGrantedSharesBreakdown.virtual) return null;

  return (
    <Card className={className} title="Granted shares breakdown">
      <div className="flex flex-col-reverse items-center gap-6 md:flex-row md:items-start">
        <div className="flex flex-auto flex-col gap-2">
          <div className="rounded-lg border-[0.5px] border-gray-04">
            <GrantedSharesBreakdownCardRow
              color="bg-purple-05"
              label="ESOP"
              value={organization.poolGrantedSharesBreakdown.real}
            />
          </div>

          <div className="rounded-lg border-[0.5px] border-gray-04">
            <GrantedSharesBreakdownCardRow
              color="bg-purple-02"
              label="VSOP"
              value={organization.poolGrantedSharesBreakdown.virtual}
            />

            <div className="border-t-[0.5px] border-gray-04 bg-gray-01 p-4">
              <Typography as="div" variant="Regular/Extra Small">
                Maximum potential debt in case of sale event (VSOP)
              </Typography>

              {organization.latestPricePerShare && (
                <>
                  <Typography variant="Medium/Extra Small">
                    <FormattedNumber
                      value={organization.poolGrantedSharesBreakdown.virtual}
                    />{" "}
                    x{" "}
                    <FormattedFMV
                      value={organization.latestPricePerShare.value}
                    />{" "}
                    ={" "}
                  </Typography>

                  <Typography
                    className="text-primary-05"
                    variant="Medium/Default"
                  >
                    <FormattedNumber
                      compactDisplay="short"
                      currency="USD"
                      maximumFractionDigits={2}
                      notation="compact"
                      style="currency"
                      value={
                        organization.latestPricePerShare.value *
                        organization.poolGrantedSharesBreakdown.virtual
                      }
                    />
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
        <CircularProgress
          indicatorWidth={8}
          margin={5}
          showTrack={false}
          trackWidth={8}
          values={[
            {
              color: "#D7CEFF", // purple-05
              id: "vsop",
              progress:
                (organization.poolGrantedSharesBreakdown.virtual /
                  organization.poolGrantedSharesBreakdown.total) *
                100,
            },
            {
              color: "#583DDD", // purple-02
              id: "esop",
              progress:
                (organization.poolGrantedSharesBreakdown.real /
                  organization.poolGrantedSharesBreakdown.total) *
                100,
            },
          ]}
        />
      </div>
    </Card>
  );
};
