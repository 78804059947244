/**
 * @generated SignedSource<<f5453d44ac9e67f42204886a22f4f210>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivity_ExercisesActivityDataPoint$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivityGraph_ExercisesActivityDataPoints">;
  readonly " $fragmentType": "ExercisesActivity_ExercisesActivityDataPoint";
}>;
export type ExercisesActivity_ExercisesActivityDataPoint$key = ReadonlyArray<{
  readonly " $data"?: ExercisesActivity_ExercisesActivityDataPoint$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivity_ExercisesActivityDataPoint">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExercisesActivity_ExercisesActivityDataPoint",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesActivityGraph_ExercisesActivityDataPoints"
    }
  ],
  "type": "ExercisesActivityDataPoint",
  "abstractKey": null
};

(node as any).hash = "6f5e76cabc99706ee252ad0f4d8a0c5a";

export default node;
