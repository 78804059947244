/**
 * @generated SignedSource<<5595c3c30d841265e11decfcc8b81bb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly readyForConsentAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest" | "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
  }>;
  readonly searchedReadyForConsentAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_Organization" | "GranteeTerminationAmendmentRequestsTable_Organization" | "LegalWorkflowLayout_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization";
};
export type BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization">;
};

import BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "READY_FOR_CONSENT"
  ]
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "grantAmendmentRequestsSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "BoardApproval_GranteeTerminationCTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "searchedReadyForConsentAmendmentRequests",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "grantAmendmentRequestsSearch"
        },
        (v1/*: any*/)
      ],
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": "readyForConsentAmendmentRequests",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest"
        }
      ],
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"READY_FOR_CONSENT\"])"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationAmendmentRequestsTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "a6c4c3fe70b4f6e9580990e18c2148bf";

export default node;
