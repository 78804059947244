/**
 * @generated SignedSource<<d45b43fc52bbd8224a6f06bec6acc493>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantStatus = "Active" | "Canceled" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsHeader_CTMSGrant$data = {
  readonly boardApprovalDate: string | null;
  readonly easopGrant: {
    readonly formattedGranteeName: string;
  } | null;
  readonly equityTypeAwardName: string | null;
  readonly grantStatus: CTMSGrantStatus;
  readonly grantee: {
    readonly id: string;
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
    } | null;
  };
  readonly label: string;
  readonly matchingInstrument: {
    readonly taxResidenceCountry: {
      readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
    };
  } | null;
  readonly repricedFromCTMSGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"RepricedFromCtmsGrantTag_CtmsGrant">;
  } | null;
  readonly repricedToCTMSGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"RepricedToCtmsGrantTag_CtmsGrant">;
  } | null;
  readonly stakeholderSignatureDate: string | null;
  readonly terminationDate: string | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActiveOrTerminatedTag_CtmsGrant">;
  readonly " $fragmentType": "CtmsGrantDetailsHeader_CTMSGrant";
};
export type CtmsGrantDetailsHeader_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetailsHeader_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsHeader_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "taxResidenceCountry",
  "plural": false,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CountryFlag_Country"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsHeader_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stakeholderSignatureDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeAwardName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boardApprovalDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "repricedFromCTMSGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RepricedFromCtmsGrantTag_CtmsGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "repricedToCTMSGrant",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RepricedToCtmsGrantTag_CtmsGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "formattedGranteeName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantActiveOrTerminatedTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "5767ebc902f0678ced81843cd8d445df";

export default node;
