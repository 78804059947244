/**
 * @generated SignedSource<<f0af98108dc77142ab84b704b2f932dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DocumentsUploadOrganizationView_Root_Query$variables = {
  organizationId: string;
};
export type DocumentsUploadOrganizationView_Root_Query$data = {
  readonly organization: {
    readonly name: string;
  };
};
export type DocumentsUploadOrganizationView_Root_Query = {
  response: DocumentsUploadOrganizationView_Root_Query$data;
  variables: DocumentsUploadOrganizationView_Root_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsUploadOrganizationView_Root_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsUploadOrganizationView_Root_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9914336e52d8c8a59b0059fe9eec18d7",
    "id": null,
    "metadata": {},
    "name": "DocumentsUploadOrganizationView_Root_Query",
    "operationKind": "query",
    "text": "query DocumentsUploadOrganizationView_Root_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0a62870cfafd9891f2411988066ca7f3";

export default node;
