/**
 * @generated SignedSource<<0accdaa8745da61f7a9b5553564b3f41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantWarning = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type useInstrumentValuationGuard_Grants$data = ReadonlyArray<{
  readonly instrument: {
    readonly valuation: {
      readonly type: ValuationType;
    };
  };
  readonly warnings: ReadonlyArray<GrantWarning>;
  readonly " $fragmentType": "useInstrumentValuationGuard_Grants";
}>;
export type useInstrumentValuationGuard_Grants$key = ReadonlyArray<{
  readonly " $data"?: useInstrumentValuationGuard_Grants$data;
  readonly " $fragmentSpreads": FragmentRefs<"useInstrumentValuationGuard_Grants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "useInstrumentValuationGuard_Grants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "concreteType": "InstrumentValuation",
          "kind": "LinkedField",
          "name": "valuation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warnings",
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "001f16240475577e959475209fe07bfa";

export default node;
