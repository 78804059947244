import { graphql } from "relay-runtime";

import { useQuery } from "../hooks/useQuery";
import NotFoundPage from "../pages/NotFound/NotFound";
import { useOrganizationIdParam } from "../paths";
import { FreemiumGuard_Query } from "./__generated__/FreemiumGuard_Query.graphql";

const QUERY = graphql`
  query FreemiumGuard_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      planIsFreemium
    }
  }
`;

const FreemiumGuard: React.FC<{
  children: React.ReactNode;
  freemiumPage: React.ReactElement;
}> = ({ children, freemiumPage }) => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<FreemiumGuard_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (query.organization.planIsFreemium) {
    return freemiumPage;
  }

  return children;
};

export default FreemiumGuard;
