import { useCallback } from "react";
import { graphql } from "relay-runtime";

import { analytics } from "../services/analytics";
import { useStripeCustomerPortal_Mutation } from "./__generated__/useStripeCustomerPortal_Mutation.graphql";
import { useSafeMutation } from "./useSafeMutation";

const GENERATE_ORGANIZATION_STRIPE_CUSTOMER_PORTAL_SESSION_URL_MUTATION = graphql`
  mutation useStripeCustomerPortal_Mutation(
    $organizationId: OrganizationId!
    $returnURL: String!
  ) {
    generateOrganizationStripeCustomerPortalSessionURL(
      organizationId: $organizationId
      returnUrl: $returnURL
    )
  }
`;

export const useStripeCustomerPortal = ({
  organizationId,
  returnURL = window.location.href,
  target = "_blank",
}: {
  organizationId: string;
  returnURL?: string;
  target?: string;
}) => {
  const [
    generateOrganizationStripeCustomerPortalSessionUrl,
    generateOrganizationStripeCustomerPortalSessionUrlIsInFlight,
  ] = useSafeMutation<useStripeCustomerPortal_Mutation>(
    GENERATE_ORGANIZATION_STRIPE_CUSTOMER_PORTAL_SESSION_URL_MUTATION,
  );

  const openStripeCustomerPortal = useCallback(async () => {
    void analytics.page(
      "Admin Settings",
      "Admin - Billing",
      {},
      { context: { groupId: organizationId } },
    );

    const result = await generateOrganizationStripeCustomerPortalSessionUrl({
      variables: {
        organizationId,
        returnURL,
      },
    });

    window.open(
      result.generateOrganizationStripeCustomerPortalSessionURL,
      target,
    );
  }, [
    generateOrganizationStripeCustomerPortalSessionUrl,
    organizationId,
    returnURL,
    target,
  ]);

  return {
    openStripeCustomerPortal,
    openStripeCustomerPortalIsLoading:
      generateOrganizationStripeCustomerPortalSessionUrlIsInFlight,
  };
};
