/**
 * @generated SignedSource<<177ab7f84d2e8af9368796af9ca00edc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantAmendmentRequestStatus = "IMPLEMENTATION" | "IMPLEMENTED" | "PENDING_APPROVAL" | "READY_FOR_CONSENT" | "UNDER_REVIEW";
export type CTMSGrantAmendmentRequestAttributes = {
  accelerationClause?: AccelerationClause | null;
  accelerationClauseIsModified: boolean;
  earlyExercise?: boolean | null;
  earlyExerciseIsModified: boolean;
  postTerminationExercisePeriodId?: any | null;
  postTerminationExercisePeriodIsModified: boolean;
  totalAccelerationDate?: string | null;
  vestingScheduleId?: any | null;
  vestingStartDate?: string | null;
  waiveCliff?: boolean | null;
};
export type DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation$variables = {
  attributes: CTMSGrantAmendmentRequestAttributes;
  ctmsGrantAmendmentRequestId: string;
};
export type DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation$data = {
  readonly updateCTMSGrantAmendmentRequest: {
    readonly status: CTMSGrantAmendmentRequestStatus;
  };
};
export type DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation = {
  response: DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation$data;
  variables: DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantAmendmentRequestId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "ctmsGrantAmendmentRequestId",
    "variableName": "ctmsGrantAmendmentRequestId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "updateCTMSGrantAmendmentRequest",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "updateCTMSGrantAmendmentRequest",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec7652940dbf1078d48e592c9787eabd",
    "id": null,
    "metadata": {},
    "name": "DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation",
    "operationKind": "mutation",
    "text": "mutation DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation(\n  $ctmsGrantAmendmentRequestId: UUID!\n  $attributes: CTMSGrantAmendmentRequestAttributes!\n) {\n  updateCTMSGrantAmendmentRequest(attributes: $attributes, ctmsGrantAmendmentRequestId: $ctmsGrantAmendmentRequestId) {\n    status\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f67b1628435f9c188d274b6aa5fbd7e";

export default node;
