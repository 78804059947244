import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GranteeNameWithCountryFlag } from "../../../../components/GranteeNameWithCountryFlag";
import { GranteeStatusTag } from "../../../../components/GranteeStatusTag";
import { LinkButton } from "../../../../components/ui/Button";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { BillableGranteesSlideOver_Grantee$key } from "./__generated__/BillableGranteesSlideOver_Grantee.graphql";
import { BillableGranteesSlideOver_Organization$key } from "./__generated__/BillableGranteesSlideOver_Organization.graphql";

const GRANTEES_FRAGMENT = graphql`
  fragment BillableGranteesSlideOver_Grantee on Grantee @relay(plural: true) {
    id
    name
    email
    ...GranteeNameWithCountryFlag_Grantee
    ...GranteeStatusTag_Grantee
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment BillableGranteesSlideOver_Organization on Organization {
    name
  }
`;

export const BillableGranteesSlideOver: React.FC<{
  granteesFragment: BillableGranteesSlideOver_Grantee$key;
  onClose: () => void;
  organizationFragment: BillableGranteesSlideOver_Organization$key;
  show: boolean;
}> = ({ granteesFragment, onClose, organizationFragment, show }) => {
  const grantees = useFragment(GRANTEES_FRAGMENT, granteesFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] About billed grantees`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={10}>
          Billed grantees
        </SlideOver.Header>
      }
      onClose={onClose}
      show={show}
    >
      <div className="px-6">
        <div className="flex flex-col divide-y-[0.5px] divide-gray-04">
          {grantees.map((grantee) => (
            <div
              className="flex items-center justify-between px-4 py-3.5"
              key={grantee.id}
            >
              <div className="flex flex-col">
                <Typography variant="Regular/Extra Small">
                  <GranteeNameWithCountryFlag granteeFragment={grantee} />
                </Typography>
                <Typography className="text-gray-09" variant="Regular/Caption">
                  {grantee.email}
                </Typography>
              </div>
              <GranteeStatusTag granteeFragment={grantee} />
            </div>
          ))}
        </div>
        <div className="py-6">
          <div className="flex items-center justify-between rounded-lg bg-gray-01 px-10 py-6">
            <Typography variant="Regular/Extra Small">
              If you have any questions, please contact us.
            </Typography>
            <LinkButton
              size="small"
              to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
              variant="Primary Outline"
            >
              Contact us
            </LinkButton>
          </div>
        </div>
      </div>
    </SlideOver>
  );
};
