import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation } from "./__generated__/MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation.graphql";
import { MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$key } from "./__generated__/MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument.graphql";
import { ConfirmationModal } from "./ui/ConfirmationModal";

const DOCUMENT_FRAGMENT = graphql`
  fragment MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument on OrganizationMiscellaneousLegalDocument {
    displayedName
    document {
      id
    }
    organization {
      id
    }
  }
`;

const MUTATION = graphql`
  mutation MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation(
    $organizationId: OrganizationId!
    $documentId: UUID!
  ) {
    deleteOrganizationMiscellaneousLegalDocument(
      organizationId: $organizationId
      documentId: $documentId
    )
  }
`;

type State =
  | {
      documentFragment: MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$key;
      shown: true;
    }
  | {
      documentFragment?: MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$key | null;
      shown: false;
    };

export const useMiscellaneousLegalDocumentDeletionConfirmationModalState =
  () => {
    const [miscellaneousLegalDocumentDeletionConfirmationModalState, setState] =
      useState<State>({
        shown: false,
      });

    const showMiscellaneousLegalDocumentDeletionConfirmationModal = useCallback(
      (
        documentFragment: MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument$key,
      ) => {
        setState({
          documentFragment,
          shown: true,
        });
      },
      [],
    );

    const closeMiscellaneousLegalDocumentDeletionConfirmationModal =
      useCallback(() => {
        setState((previousState) => ({
          ...previousState,
          shown: false,
        }));
      }, []);

    return {
      closeMiscellaneousLegalDocumentDeletionConfirmationModal,
      miscellaneousLegalDocumentDeletionConfirmationModalState,
      showMiscellaneousLegalDocumentDeletionConfirmationModal,
    };
  };

export const MiscellaneousLegalDocumentDeletionConfirmationModal: React.FC<{
  onClose: () => void;
  onDeletion: () => void;
  state: State;
}> = ({ onClose, onDeletion, state }) => {
  const miscellaneousLegalDocument = useFragment(
    DOCUMENT_FRAGMENT,
    state.documentFragment,
  );

  const [deleteOrganizationMiscellaneousLegalDocument, deletionIsInFlight] =
    useSafeMutation<MiscellaneousLegalDocumentDeletionConfirmationModal_Mutation>(
      MUTATION,
    );

  const handleConfirmation = useCallback(async () => {
    if (!miscellaneousLegalDocument) {
      return;
    }
    await deleteOrganizationMiscellaneousLegalDocument({
      variables: {
        documentId: miscellaneousLegalDocument.document.id,
        organizationId: miscellaneousLegalDocument.organization.id,
      },
    });
    onClose();
    onDeletion();
  }, [
    onDeletion,
    onClose,
    miscellaneousLegalDocument,
    deleteOrganizationMiscellaneousLegalDocument,
  ]);

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      loading={deletionIsInFlight}
      onClose={onClose}
      onConfirmed={handleConfirmation}
      show={state.shown}
      title="Confirm delete"
    >
      Are you sure you want to delete{" "}
      <strong>{miscellaneousLegalDocument?.displayedName}</strong>?
    </ConfirmationModal>
  );
};
