import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Typography } from "./ui/Typography";

const COLORS = {
  green: "bg-green-01 text-green-08",
  orange: "bg-orange-01 text-orange-08",
  primary: "bg-primary-01 text-primary-08",
  purple: "bg-purple-01 text-purple-08",
  red: "bg-red-01 text-red-08",
};

const Icon: React.FC<{
  color: keyof typeof COLORS;
  icon: React.ReactElement<React.ComponentProps<"svg">>;
}> = ({ color, icon }) => {
  return (
    <div className={classNames("shring-0 h-8 w-8 rounded p-2", COLORS[color])}>
      {React.cloneElement(icon)}
    </div>
  );
};

const _SuperAdminHomeLink: React.FC<{
  icon: React.ReactNode;
  subTitle: React.ReactNode;
  title: React.ReactNode;
  to: string;
}> = ({ icon, subTitle, title, to }) => {
  return (
    <Link className="flex items-center gap-6 p-6" to={to}>
      {icon}
      <div className="shrink-0"></div>
      <div className="flex-1 space-y-2">
        <Typography as="div" variant="Medium/Small">
          {title}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          {subTitle}
        </Typography>
      </div>
      <div className="shring-0 h-9 w-9 p-2">
        <ChevronRightIcon />
      </div>
    </Link>
  );
};

export const SuperAdminHomeLink = Object.assign(_SuperAdminHomeLink, { Icon });
