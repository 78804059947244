import classNames from "classnames";
import { useCallback, useMemo, useTransition } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { Typography } from "../../../../components/ui/Typography";
import { UnderwaterGrantsGraph_CTMSGrant$key } from "./__generated__/UnderwaterGrantsGraph_CTMSGrant.graphql";
import { UnderwaterGrantsGraph_Organization$key } from "./__generated__/UnderwaterGrantsGraph_Organization.graphql";
import {
  UnderwaterGrantsSlideOver,
  useUnderwaterGrantsSlideOverState,
} from "./UnderwaterGrantsSlideOver";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnderwaterGrantsGraph_Organization on Organization {
    id
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment UnderwaterGrantsGraph_CTMSGrant on CTMSGrant @relay(plural: true) {
    id
    label
  }
`;

const Tooltip: React.FC<{
  barClassName: string;
  barKey: "in-the-money" | "on-fmv" | "underwater";
  grantsCount: number;
}> = ({ barClassName, barKey, grantsCount }) => {
  const { label, subLabel } = useMemo(() => {
    switch (barKey) {
      case "in-the-money":
        return {
          label: "In the money grants",
          subLabel: "Exercise price < FMV",
        };
      case "on-fmv":
        return { label: "On FMV grants", subLabel: "Exercise price = FMV" };
      case "underwater":
        return { label: "Underwater grants", subLabel: "Exercise price > FMV" };
    }
  }, [barKey]);
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grants"
      topContent={
        <>
          {label}
          <br />
          {subLabel}
        </>
      }
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{grantsCount, plural,
                =0 {# grant}
                =1 {# grant}
                other {# grants}
              }`}
              values={{ grantsCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

const StrikePricePositionMap = {
  "in-the-money": "in the money",
  "on-fmv": "on FMV",
  underwater: "underwater",
} as const;

export const UnderwaterGrantsGraph: React.FC<{
  inTheMoneyGrantsFragment: UnderwaterGrantsGraph_CTMSGrant$key;
  onFmvGrantsFragment: UnderwaterGrantsGraph_CTMSGrant$key;
  organizationFragment: UnderwaterGrantsGraph_Organization$key;
  underWaterGrantsFragment: UnderwaterGrantsGraph_CTMSGrant$key;
}> = ({
  inTheMoneyGrantsFragment,
  onFmvGrantsFragment,
  organizationFragment,
  underWaterGrantsFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const underWaterGrants = useFragment(
    CTMS_GRANT_FRAGMENT,
    underWaterGrantsFragment,
  );
  const onFmvGrants = useFragment(CTMS_GRANT_FRAGMENT, onFmvGrantsFragment);
  const inTheMoneyGrants = useFragment(
    CTMS_GRANT_FRAGMENT,
    inTheMoneyGrantsFragment,
  );

  const stacks = useMemo(
    () =>
      (
        [
          {
            className: classNames("bg-purple-05 fill-purple-05"),
            grants: underWaterGrants,
            key: "underwater",
            label: "Underwater",
            value: underWaterGrants.length,
          },

          {
            className: classNames("bg-primary-05 fill-primary-05"),
            grants: onFmvGrants,
            key: "on-fmv",
            label: "On FMV",
            value: onFmvGrants.length,
          },

          {
            className: classNames("bg-green-05 fill-green-05"),
            grants: inTheMoneyGrants,
            key: "in-the-money",
            label: "In the money",
            value: inTheMoneyGrants.length,
          },
        ] as const
      ).map((stack) => ({
        ...stack,
        elements: [
          {
            className: stack.className,
            key: stack.key,
            value: stack.value,
          },
        ],
      })),
    [underWaterGrants, onFmvGrants, inTheMoneyGrants],
  );

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useUnderwaterGrantsSlideOverState();

  const [slideOverIsOpening, startOpenSlideOverTransition] = useTransition();

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) => {
      startOpenSlideOverTransition(() => {
        openSlideOver({
          ctmsGrantsIds: stack.grants.map((grant) => grant.id),
          title: (
            <FormattedMessage
              defaultMessage={`There {grantsCount, plural, 
                =1 {is # grant} 
                other {are # grants}} currenty {strikePricePosition}`}
              values={{
                grantsCount: stack.grants.length,
                strikePricePosition: StrikePricePositionMap[stack.key],
              }}
            />
          ),
        });
      });
    },
    [openSlideOver],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        barKey={stack.key}
        grantsCount={stack.value}
      />
    ),
    [],
  );

  return (
    <>
      <UnderwaterGrantsSlideOver
        onClose={closeSlideOver}
        organizationId={organization.id}
        state={slideOverState}
      />
      <BarGraph
        barGap={88}
        loading={slideOverIsOpening}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={stacks}
        yLabel="# of grants"
        yTickFormatter={(v) => v.toString()}
      />
    </>
  );
};
