/**
 * @generated SignedSource<<748b1301695cac000a662ee3500ee75c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantType = "real" | "virtual";
export type Projection_Grantee_Query$variables = {
  grantType: GrantType;
  granteeId: string;
};
export type Projection_Grantee_Query$data = {
  readonly grantee: {
    readonly ctmsGrants: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"Projection_Grantee_CTMSGrant">;
    }>;
    readonly organization: {
      readonly id: string;
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"Projection_Grantee_Grantee">;
  } | null;
};
export type Projection_Grantee_Query = {
  response: Projection_Grantee_Query$data;
  variables: Projection_Grantee_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grantType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "grantType",
    "variableName": "grantType"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Projection_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Projection_Grantee_Grantee"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Projection_Grantee_CTMSGrant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Projection_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteePortalSettings",
                "kind": "LinkedField",
                "name": "granteePortalSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "showProjectionScenarios",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayFullyDilutedValues",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PricePerShare",
                "kind": "LinkedField",
                "name": "latestPricePerShare",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteePortalProjectionScenario",
                "kind": "LinkedField",
                "name": "granteePortalProjectionScenarios",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "key",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "multiple",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalInformation",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasSettlingGrant",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "valueAtGrant",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityIssued",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "501646681b3a92d6dd8767916f9457b9",
    "id": null,
    "metadata": {},
    "name": "Projection_Grantee_Query",
    "operationKind": "query",
    "text": "query Projection_Grantee_Query(\n  $granteeId: GranteeId!\n  $grantType: GrantType!\n) {\n  grantee(id: $granteeId) {\n    ...Projection_Grantee_Grantee\n    organization {\n      id\n      name\n    }\n    ctmsGrants(grantType: $grantType) {\n      ...Projection_Grantee_CTMSGrant\n      id\n    }\n    id\n  }\n}\n\nfragment GranteeOutcome_CTMSGrant on CTMSGrant {\n  valueAtGrant\n  quantityIssued\n}\n\nfragment GranteeOutcome_Grantee on Grantee {\n  organization {\n    latestPricePerShare {\n      value\n      id\n    }\n    granteePortalProjectionScenarios {\n      ...ProjectionCard_GranteePortalProjectionScenarios\n      id\n    }\n    id\n  }\n}\n\nfragment ProjectionCard_GranteePortalProjectionScenarios on GranteePortalProjectionScenario {\n  key\n  name\n  multiple\n  displayed\n  additionalInformation\n  ...ProjectionTable_GranteePortalProjectionScenarios\n}\n\nfragment ProjectionTable_GranteePortalProjectionScenarios on GranteePortalProjectionScenario {\n  key\n  name\n  multiple\n  displayed\n  additionalInformation\n}\n\nfragment Projection_Grantee_CTMSGrant on CTMSGrant {\n  ...GranteeOutcome_CTMSGrant\n}\n\nfragment Projection_Grantee_Grantee on Grantee {\n  organization {\n    granteePortalSettings {\n      showProjectionScenarios\n    }\n    id\n  }\n  ...GranteeOutcome_Grantee\n  ...useDisplayFullyDilutedValuesInGranteePortal_Grantee\n}\n\nfragment useDisplayFullyDilutedValuesInGranteePortal_Grantee on Grantee {\n  status\n  hasSettlingGrant\n  organization {\n    granteePortalSettings {\n      displayFullyDilutedValues\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a75e9a44e559bc3263cf43da1e8e55c5";

export default node;
