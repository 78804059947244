import classNames from "classnames";
import { partition } from "lodash";
import React from "react";
import { FormattedList, FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Navigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { LinkButton } from "../../../../components/ui/Button";
import { HelpCard } from "../../../../components/ui/HelpCard";
import { LargeCenteredDetailsLayout } from "../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Table } from "../../../../components/ui/Table";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCountryCodeParam,
  useOrganizationIdParam,
} from "../../../../paths";
import {
  getEquityTypeWorkRelationship,
  WORK_RELATIONSHIP_TO_LABEL_HELPER,
} from "../../../../services/workRelationship";
import NotFoundPage from "../../../NotFound/NotFound";
import { BlueLink } from "../../Grants/Configure/BlueLink";
import { useSetAdminPageVisited } from "../../useSetAdminPageVisited";
import { Country_Organization$key } from "./__generated__/Country_Organization.graphql";
import { Country_OrganizationGeography$key } from "./__generated__/Country_OrganizationGeography.graphql";
import { Country_Query } from "./__generated__/Country_Query.graphql";
import { Country_Viewer$key } from "./__generated__/Country_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Country_Organization on Organization {
    id
    name
    ...LargeCenteredDetailsLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment Country_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)
  }
`;

const GEOGRAPHY_FRAGMENT = graphql`
  fragment Country_OrganizationGeography on OrganizationGeography {
    unlockedAt
    isTaxFavoredEquityTypeUnlocked
    draftCount
    activeAndTerminatedOptionGrantsCount: optionGrantsCount(
      ctmsGrantStatusIn: [Active, Terminated]
    )
    billableGranteesCount
    geography {
      country {
        name
        emoji
      }
      equityTypes {
        instruments {
          id
          workRelationship
        }
        id
        taxFavored
        name
      }
    }
  }
`;

const KeyFact: React.FC<{ title: React.ReactNode; value: React.ReactNode }> = ({
  title,
  value,
}) => (
  <div className="space-y-2">
    <Typography as="div" className="text-gray-09" variant="Regular/Extra Small">
      {title}
    </Typography>
    <Typography as="div" variant="Medium/Default">
      {value}
    </Typography>
  </div>
);

const CountryPage_: React.FC<{
  organizationFragment: Country_Organization$key;
  organizationGeographyFragment: Country_OrganizationGeography$key;
  viewerFragment: Country_Viewer$key;
}> = ({
  organizationFragment,
  organizationGeographyFragment,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organizationGeography = useFragment(
    GEOGRAPHY_FRAGMENT,
    organizationGeographyFragment,
  );
  const { geography } = organizationGeography;
  const [taxFavoredEquityTypes, nonTaxFavoredEquityTypes] = partition(
    geography.equityTypes,
    (equityType) => equityType.taxFavored,
  );
  const availableEquityTypes =
    organizationGeography.isTaxFavoredEquityTypeUnlocked
      ? geography.equityTypes
      : nonTaxFavoredEquityTypes;

  const showUnlockTaxFavoredEquityType =
    !organizationGeography.isTaxFavoredEquityTypeUnlocked &&
    taxFavoredEquityTypes.length > 0;

  useSetAdminPageVisited({
    organizationId: organization.id,
    page: "GEOGRAPHIES",
  });
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <LargeCenteredDetailsLayout
      footerHelpCard={
        <HelpCard
          buttonLabel="Talk to our team"
          label="Anything unclear? You need more information regarding your countries or our billing methods?"
        />
      }
      navigationTitle={geography.country.name}
      organizationFragment={organization}
      parents={[
        {
          link: "..",
          title: "Countries",
        },
      ]}
      subtitle={`Discover what you (including your subsidiary) and the employee have to do to comply with local rules and how this equity incentive will be taxed in ${geography.country.name}, review template documents to be used for grants to employees.`}
      title={`Documents & templates available in ${geography.country.name} ${geography.country.emoji}`}
    >
      <div className="space-y-10">
        <div className="grid grid-cols-1 gap-10 rounded-lg border-[0.5px] border-gray-04 p-10 md:grid-cols-2 lg:grid-cols-4">
          <KeyFact
            title="Unlocked"
            value={<ShortDate value={organizationGeography.unlockedAt} />}
          />
          <KeyFact
            title="Employees"
            value={organizationGeography.billableGranteesCount || "-"}
          />
          <KeyFact
            title="Option grants"
            value={
              organizationGeography.activeAndTerminatedOptionGrantsCount || "-"
            }
          />
          <KeyFact
            title="Drafts"
            value={organizationGeography.draftCount || "-"}
          />
        </div>
        <div className="grid grid-cols-1 gap-x-6 gap-y-10 lg:grid-cols-[1fr_360px]">
          <div
            className={classNames("space-y-10", {
              "col-span-full": !showUnlockTaxFavoredEquityType,
            })}
          >
            <NoticeMessage size="Large" variant="Info">
              These pages aim at proposing a deep dive into the country’s
              regulation, tax and obligations, should you have questions, please
              feel free to{" "}
              <BlueLink to={`mailto:${supportEmailAddress}`}>
                contact us
              </BlueLink>
              , we’ll be delighted to help you
            </NoticeMessage>
            <Table.Containerized
              className="grid grid-cols-[104px_1fr_1fr_auto]"
              display="grid"
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Equity type</Table.HeaderCell>
                  <Table.HeaderCell>Work relationship</Table.HeaderCell>
                  <Table.HeaderCell alignRight>
                    Document templates
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {availableEquityTypes.map((equityType) =>
                  equityType.instruments.map((instrument) => {
                    const equityTypeWorkRelationship =
                      getEquityTypeWorkRelationship(
                        instrument.workRelationship,
                      );
                    const documentationPath = generatePath(
                      APPLICATION_ROUTES[
                        "organizationEquityTypeWorkRelationship"
                      ],
                      {
                        equityTypeId: equityType.id,
                        equityTypeWorkRelationshipCategory:
                          equityTypeWorkRelationship.slug,
                        organizationId: organization.id,
                      },
                    );

                    return (
                      <Table.LinkRow key={instrument.id} to={documentationPath}>
                        <Table.Cell>
                          <Tag color="gray">{equityType.name}</Tag>
                        </Table.Cell>
                        <Table.Cell>
                          {
                            WORK_RELATIONSHIP_TO_LABEL_HELPER[
                              instrument.workRelationship
                            ].pluralLabel
                          }
                        </Table.Cell>
                        <Table.Cell className="flex justify-end">
                          <Tag color="green">Available</Tag>
                        </Table.Cell>
                      </Table.LinkRow>
                    );
                  }),
                )}
              </Table.Body>
            </Table.Containerized>
          </div>
          {showUnlockTaxFavoredEquityType && (
            <div className="space-y-10">
              {taxFavoredEquityTypes.map((equityType) => {
                const requestSubPlanLinkTarget = `mailto:${supportEmailAddress}?subject=${encodeURIComponent(`[${organization.name}] Request sub plan for ${geography.country.name} ${equityType.name}`)}`;

                return (
                  <div
                    className="space-y-4 rounded-lg border-[0.5px] border-gray-04 p-6"
                    key={equityType.id}
                  >
                    <Typography variant="Medium/Small">
                      Tax favored equity type available for your employees in
                      this country
                    </Typography>
                    <div className="flex justify-between gap-4 rounded-lg bg-gray-01 p-4">
                      <div className="flex gap-4">
                        <Typography variant="Medium/Large">
                          {organizationGeography.geography.country.emoji}
                        </Typography>
                        <div className="space-y-1">
                          <Typography as="div" variant="Medium/Small">
                            {equityType.name}
                          </Typography>
                          <Typography as="div" variant="Regular/Extra Small">
                            for{" "}
                            <FormattedList
                              type="conjunction"
                              value={equityType.instruments.map(
                                (instrument) =>
                                  WORK_RELATIONSHIP_TO_LABEL_HELPER[
                                    instrument.workRelationship
                                  ].pluralLabel,
                              )}
                            />
                          </Typography>
                        </div>
                      </div>
                      <div>
                        <Tag color="green">Available</Tag>
                      </div>
                    </div>
                    {viewer.isAllowedToUnlockCountryForOrganization && (
                      <div className="flex items-center justify-between py-4">
                        <Typography
                          as="div"
                          className="text-black-05"
                          variant="Regular/Extra Small"
                        >
                          Total price
                        </Typography>
                        <div className="space-y-1 text-right">
                          <Typography as="div" variant="Medium/Extra Small">
                            <FormattedNumber
                              currency="USD"
                              style="currency"
                              value={1999}
                            />
                            <br />
                            USD
                          </Typography>
                          <Typography as="div" variant="Regular/Caption">
                            One time payment
                          </Typography>
                        </div>
                      </div>
                    )}
                    <div>
                      <LinkButton
                        fullWidth
                        size="small"
                        to={requestSubPlanLinkTarget}
                        variant="Primary Full"
                      >
                        Request the sub plan
                      </LinkButton>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query Country_Query($organizationId: OrganizationId!, $countryCode: String!) {
    organization(id: $organizationId) {
      id
      name
      ...Country_Organization
    }
    organizationGeography(
      countryCode: $countryCode
      organizationId: $organizationId
    ) {
      ...Country_OrganizationGeography
    }
    me {
      ...Country_Viewer @arguments(organizationId: $organizationId)
    }
    geography(countryCode: $countryCode) {
      country {
        name
        emoji
      }
    }
  }
`;

const CountryPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const countryCode = useCountryCodeParam();
  const { query } = useQuery<Country_Query>(QUERY, {
    countryCode,
    organizationId,
  });

  if (!query.organization || !query.geography) {
    return <NotFoundPage />;
  }

  if (!query.organizationGeography) {
    return (
      <Navigate
        replace
        to={generatePath(APPLICATION_ROUTES["organizationUnlockCountry"], {
          countryCode,
          organizationId,
        })}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Countries"
      analyticsName="Admin - Country"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} country | ${query.geography.country.name} ${query.geography.country.emoji}`}
    >
      <CountryPage_
        organizationFragment={query.organization}
        organizationGeographyFragment={query.organizationGeography}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default CountryPage;
