import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  EllipsisHorizontalIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { startTransition, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import {
  graphql,
  useFragment,
  useRefetchableFragment,
  useSubscription,
} from "react-relay";

import {
  BoardConsentVoidedReasonTag,
  getInstrumentValuationTypeFromBoardConsentVoidedReason,
} from "../../../components/BoardConsentVoidedReasonTag";
import { CancelBoardConsentButton } from "../../../components/CancelBoardConsentButton";
import { EmptyListPlaceholder } from "../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import { Page } from "../../../components/Page";
import { SendReminderButton } from "../../../components/SendReminderButton";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { LoadingSpinner } from "../../../components/ui/LoadingSpinner";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { FadeAndScaleTransition } from "../../../components/ui/Transition";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { BoardConsentSignatureLinkModal } from "../Equity/PrepareYourGrants/BoardConsentSignatureLinkModal";
import { PendingApproval_FairMarketValueApprovals_deleteBoardConsent_Mutation } from "./__generated__/PendingApproval_FairMarketValueApprovals_deleteBoardConsent_Mutation.graphql";
import { PendingApproval_FairMarketValueApprovals_Organization$key } from "./__generated__/PendingApproval_FairMarketValueApprovals_Organization.graphql";
import { PendingApproval_FairMarketValueApprovals_Query } from "./__generated__/PendingApproval_FairMarketValueApprovals_Query.graphql";
import { PendingApproval_FairMarketValueApprovals_Viewer$key } from "./__generated__/PendingApproval_FairMarketValueApprovals_Viewer.graphql";
import { FairMarketValueApprovalTable } from "./FairMarketValueApprovalTable";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent on FairMarketValueBoardConsent {
    id
    voidedReason
    isPandaDocDocumentSent
    canBoardMemberSignatureRequestReminderEmailsBeSent
    signatureRequests {
      id
      completedAt
      signatoryFirstName
      signatoryLastName
    }
    fairMarketValue {
      ...FairMarketValueApprovalTable_FairMarketValue
    }
    ...SendReminderButton_BoardConsent
  }
`;

const DELETE_BOARD_CONSENT_MUTATION = graphql`
  mutation PendingApproval_FairMarketValueApprovals_deleteBoardConsent_Mutation(
    $boardConsentId: UUID!
  ) {
    deleteBoardConsentAndSendLinkedEasopGrantsToDraft(id: $boardConsentId)
  }
`;

const BOARD_CONSENTS_UPDATES_SUBSCRIPTION = graphql`
  subscription PendingApproval_FairMarketValueApprovals_BoardConsentsUpdatesSubscription(
    $boardConsentsIds: [UUID!]!
  ) {
    boardConsentsUpdates(ids: $boardConsentsIds) {
      ...PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment PendingApproval_FairMarketValueApprovals_Organization on Organization
  @refetchable(
    queryName: "PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery"
  ) {
    id
    name
    pendingApprovalFairMarketValueBoardConsents {
      ...PendingApproval_FairMarketValueApprovals_FairMarketValueBoardConsent
        @relay(mask: false)
    }
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment PendingApproval_FairMarketValueApprovals_Viewer on Account {
    isSuperAdmin
  }
`;

const AdminFairMarketValueApprovalsPendingApprovalPage_: React.FC<{
  organizationFragment: PendingApproval_FairMarketValueApprovals_Organization$key;
  viewerFragment: PendingApproval_FairMarketValueApprovals_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const boardConsentsUpdatesSubscriptionConfig = useMemo(
    () => ({
      subscription: BOARD_CONSENTS_UPDATES_SUBSCRIPTION,
      variables: {
        boardConsentsIds:
          organization.pendingApprovalFairMarketValueBoardConsents.map(
            (boardConsent) => boardConsent.id,
          ),
      },
    }),
    [organization.pendingApprovalFairMarketValueBoardConsents],
  );
  useSubscription(boardConsentsUpdatesSubscriptionConfig);

  const onBoardConsentUpdated = useCallback(() => {
    startTransition(() => {
      refetchOrganization(
        {},
        {
          fetchPolicy: "network-only",
        },
      );
    });
  }, [refetchOrganization]);

  const [deleteBoardConsent, deleteBoardConsentMutationIsInFlight] =
    useSafeMutation<PendingApproval_FairMarketValueApprovals_deleteBoardConsent_Mutation>(
      DELETE_BOARD_CONSENT_MUTATION,
    );

  const handleDeleteBoardConsentButtonClick = useCallback(
    async (boardConsentId: string) => {
      await deleteBoardConsent({
        variables: { boardConsentId },
      });

      onBoardConsentUpdated();
    },
    [deleteBoardConsent, onBoardConsentUpdated],
  );

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      subtitle="409A valuation is pending approval from the board"
      title="Pending approval"
    >
      <div className="flex flex-col gap-4">
        {organization.pendingApprovalFairMarketValueBoardConsents.length > 0 ? (
          organization.pendingApprovalFairMarketValueBoardConsents.map(
            (boardConsent) => (
              <RoundedBox
                className={classNames(
                  "overflow-hidden",
                  boardConsent.voidedReason
                    ? "border-red-05"
                    : "border-gray-04",
                )}
                key={boardConsent.id}
                withBorder
                withShadow
              >
                {boardConsent.voidedReason && (
                  <Typography
                    as="div"
                    className="flex w-full items-center gap-6 rounded-t-lg bg-red-01 px-4 py-2 text-red-09"
                    variant="Medium/Caption"
                  >
                    <button
                      className="ml-auto h-5 w-5 shrink-0"
                      disabled={deleteBoardConsentMutationIsInFlight}
                      onClick={() =>
                        handleDeleteBoardConsentButtonClick(boardConsent.id)
                      }
                    >
                      {deleteBoardConsentMutationIsInFlight ? (
                        <LoadingSpinner />
                      ) : (
                        <XMarkIcon />
                      )}
                    </button>
                  </Typography>
                )}

                <div className="overflow-y-hidden">
                  <FairMarketValueApprovalTable
                    boardConsentGenerationIsInProgress={
                      !boardConsent.isPandaDocDocumentSent
                    }
                    fairMarketValuesFragment={[boardConsent.fairMarketValue]}
                  />
                </div>
                {boardConsent.voidedReason ? (
                  <div className="flex w-full justify-center border-t-[0.5px] border-gray-03 p-4">
                    <BoardConsentVoidedReasonTag
                      instrumentValuationType={getInstrumentValuationTypeFromBoardConsentVoidedReason(
                        boardConsent.voidedReason,
                      )}
                    />
                  </div>
                ) : !isEmpty(boardConsent.signatureRequests) ? (
                  <div className="flex items-center border-t-[0.5px] border-gray-03 p-4">
                    <Popover className="relative">
                      <PopoverButton className="focus:outline-none">
                        <Typography
                          as="div"
                          className="flex cursor-pointer items-center text-black-05"
                          variant="Medium/Caption"
                        >
                          <div className="flex h-6 w-6 items-center justify-center rounded-full bg-glass-green-02 text-black-09">
                            <div>
                              {
                                boardConsent.signatureRequests.filter(
                                  (signatureRequest) =>
                                    signatureRequest.completedAt,
                                ).length
                              }
                            </div>
                          </div>
                          &nbsp; out of {boardConsent.signatureRequests.length}{" "}
                          board members have signed &nbsp;
                          <ChevronDownIcon className="h-4 w-4 text-black-07 transition-all ui-open:rotate-180 ui-open:transform" />
                        </Typography>
                      </PopoverButton>
                      <PopoverPanel
                        anchor={{ gap: 8, to: "bottom start" }}
                        className="absolute bottom-6 right-0 z-10 w-[289px]"
                        portal
                      >
                        <RoundedBox
                          className="flex flex-col gap-4 p-4"
                          withBorder
                          withShadow
                        >
                          <Typography
                            className="text-black-05"
                            variant="Medium/Extra Small"
                          >
                            List of board members:
                          </Typography>
                          <div className="space-y-1">
                            {boardConsent.signatureRequests.map(
                              (signatureRequest) => (
                                <Typography
                                  as="div"
                                  className={classNames(
                                    "flex items-center justify-between gap-2 px-2 py-1 text-gray-09",
                                    {
                                      "text-gray-09":
                                        !signatureRequest.completedAt,
                                    },
                                  )}
                                  key={signatureRequest.id}
                                  variant="Regular/Extra Small"
                                >
                                  <div className="flex items-center gap-2">
                                    {signatureRequest.completedAt ? (
                                      <CheckIcon className="h-4 w-4 shrink-0 text-green-05" />
                                    ) : (
                                      <EllipsisHorizontalIcon className="h-4 w-4 shrink-0 text-gray-09" />
                                    )}
                                    <div>
                                      {signatureRequest.signatoryFirstName}
                                      &nbsp;
                                      {signatureRequest.signatoryLastName}
                                    </div>
                                  </div>
                                  {!signatureRequest.completedAt && (
                                    <BoardConsentSignatureLinkModal
                                      signatureRequestId={signatureRequest.id}
                                    />
                                  )}
                                </Typography>
                              ),
                            )}
                          </div>
                          <Typography
                            as="div"
                            className="border-t-[0.5px] border-gray-03 pt-4"
                            variant="Medium/Extra Small"
                          >
                            <FormattedMessage
                              defaultMessage={`{unsignedSignatureRequestsCount, plural,
                              one {# board member hasn’t signed yet.}
                              other {# board members haven’t signed yet.}
                            }`}
                              values={{
                                unsignedSignatureRequestsCount:
                                  boardConsent.signatureRequests.filter(
                                    (signatureRequest) =>
                                      !signatureRequest.completedAt,
                                  ).length,
                              }}
                            />
                          </Typography>
                          <FadeAndScaleTransition
                            appear={false}
                            show={
                              boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent
                            }
                          >
                            <SendReminderButton
                              boardConsentFragment={boardConsent}
                              fullWidth
                            />
                          </FadeAndScaleTransition>
                        </RoundedBox>
                      </PopoverPanel>
                    </Popover>

                    <div className="flex flex-1 items-center justify-end gap-8">
                      {boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent && (
                        <div className="ml-auto flex items-center gap-6">
                          <Typography
                            className="text-black-05"
                            variant="Regular/Caption"
                          >
                            Send a reminder to the missing board members
                          </Typography>
                          <SendReminderButton
                            boardConsentFragment={boardConsent}
                          />
                        </div>
                      )}
                      {viewer.isSuperAdmin && (
                        <CancelBoardConsentButton
                          boardConsentId={boardConsent.id}
                          onBoardConsentVoided={onBoardConsentUpdated}
                        />
                      )}
                    </div>
                  </div>
                ) : null}
              </RoundedBox>
            ),
          )
        ) : (
          <EmptyListPlaceholder
            hideImage
            subtitle="You will see items here if you initiate 409A approval process"
            title="No 409A currently pending approval"
          />
        )}
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query PendingApproval_FairMarketValueApprovals_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...PendingApproval_FairMarketValueApprovals_Organization
    }
    me {
      ...PendingApproval_FairMarketValueApprovals_Viewer
    }
  }
`;

const AdminFairMarketValueApprovalsPendingApprovalPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { me, organization },
  } = useQuery<PendingApproval_FairMarketValueApprovals_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Fair Market Value Approvals"
      analyticsName="Admin - Fair Market Value Pending Approval"
      organizationId={organization.id}
      title={`Admin | ${organization.name} fair market value pending approval`}
    >
      <AdminFairMarketValueApprovalsPendingApprovalPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default AdminFairMarketValueApprovalsPendingApprovalPage;
