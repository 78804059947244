import { Link, NavLink } from "react-router-dom";

import { Logo } from "../Logo/Logo";

export const LogoLink: React.FC<{
  homePath?: React.ComponentProps<typeof NavLink>["to"];
}> = ({ homePath }) => {
  const logo = (
    <Logo className="block h-full max-h-6 w-full max-w-36" variant="black" />
  );

  return (
    <div className="flex justify-start">
      {homePath ? <Link to={homePath}>{logo}</Link> : logo}
    </div>
  );
};
