import { FormattedNumber } from "react-intl";

export const FormattedFMV: React.FC<{ value: number }> = ({ value }) => (
  <FormattedNumber
    currency="USD"
    maximumFractionDigits={value < 0.1 ? 4 : 2}
    style="currency"
    value={value}
  />
);
