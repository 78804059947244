import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useMemo, useState } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import {
  LoadMoreListSlideOver,
  useLoadMoreSlideOverProperties,
} from "../../../../components/LoadMoreListSlideOver";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { Progress } from "../../../../components/ui/Progress";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  QuantityVestedSlideOver_Organization$data,
  QuantityVestedSlideOver_Organization$key,
} from "./__generated__/QuantityVestedSlideOver_Organization.graphql";
import {
  QuantityVestedSlideOver_Query,
  QuantityVestedSlideOver_Query$data,
} from "./__generated__/QuantityVestedSlideOver_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment QuantityVestedSlideOver_Organization on Organization {
    id
  }
`;

const QUERY = graphql`
  query QuantityVestedSlideOver_Query(
    $organizationId: OrganizationId!
    $granteesIds: [GranteeId!]!
    $search: String
    $take: Int!
    $skip: Int!
  ) {
    result: granteesForOrganizationFilterById(
      organizationId: $organizationId
      granteesIds: $granteesIds
      search: $search
      take: $take
      skip: $skip
    ) {
      # eslint-disable-next-line relay/unused-fields
      items: grantees {
        id
        name
        totalGrantedSharesBreakdown {
          total
        }
        totalVestedSharesBreakdown {
          total
        }
      }
      # eslint-disable-next-line relay/unused-fields
      total
    }
  }
`;

type State =
  | {
      barClassName: null | string;
      granteesIds: null | string[];
      show: false;
      title: null | React.ReactNode;
    }
  | {
      barClassName: string;
      granteesIds: string[];
      show: true;
      title: React.ReactNode;
    };

export function useQuantityVestedSlideOverState() {
  const [state, setState] = useState<State>({
    barClassName: null,
    granteesIds: [],
    show: false,
    title: null,
  });

  const open = ({
    barClassName,
    granteesIds,
    title,
  }: {
    barClassName: string;
    granteesIds: string[];
    title: React.ReactNode;
  }) => {
    setState({
      barClassName,
      granteesIds,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
}

const Row: React.FC<{
  barClassName: string;
  grantee: QuantityVestedSlideOver_Query$data["result"]["items"][number];
  index: number;
  organization: QuantityVestedSlideOver_Organization$data;
}> = ({ barClassName, grantee, index, organization }) => {
  return (
    <Link
      to={generatePath(APPLICATION_ROUTES["organizationGrantee"], {
        granteeId: grantee.id,
        organizationId: organization.id,
      })}
    >
      <div className="group flex cursor-pointer items-center gap-2 rounded border-[0.5px] border-transparent p-2 transition-all hover:border-gray-04">
        <Tag color="gray" size="Small">
          {String(index).padStart(2, "0")}
        </Tag>

        <div className="flex-grow space-y-1 px-4">
          <Typography as="div" variant="Regular/Extra Small">
            {grantee.name}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            <FormattedNumber value={grantee.totalVestedSharesBreakdown.total} />{" "}
            of the{" "}
            <FormattedNumber
              value={grantee.totalGrantedSharesBreakdown.total}
            />{" "}
            shares
            <br />
            have vested
          </Typography>
        </div>

        <Progress
          className="h-3 w-[200px] bg-gray-03"
          max={grantee.totalGrantedSharesBreakdown.total}
        >
          <Progress.Value
            className={barClassName}
            value={grantee.totalVestedSharesBreakdown.total}
          />
        </Progress>

        <Tag color="gray" size="Small">
          <FormattedPercentage
            maximumFractionDigits={0}
            value={
              grantee.totalVestedSharesBreakdown.total /
              grantee.totalGrantedSharesBreakdown.total
            }
          />
        </Tag>

        <div className="shrink-0 px-4">
          <ArrowTopRightOnSquareIcon className="h-5 w-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
        </div>
      </div>
    </Link>
  );
};

export const QuantityVestedSlideOver: React.FC<{
  onClose: () => void;
  organizationFragment: QuantityVestedSlideOver_Organization$key;
  state: State;
}> = ({ onClose, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const variables = useMemo(
    () =>
      state.granteesIds
        ? { granteesIds: state.granteesIds, organizationId: organization.id }
        : null,
    [state.granteesIds, organization.id],
  );

  const loadMoreSlideOverProps =
    useLoadMoreSlideOverProperties<QuantityVestedSlideOver_Query>({
      QUERY,
      variables,
    });

  return (
    <LoadMoreListSlideOver
      onClose={onClose}
      state={state}
      {...loadMoreSlideOverProps}
      noItemsMessage={
        <NoticeMessage size="Small">
          No grantees match your current filters.
        </NoticeMessage>
      }
      renderItems={(grantees) => (
        <div className="w-full space-y-0">
          {grantees.map((grantee, index) => (
            <Row
              barClassName={state.barClassName ?? ""}
              grantee={grantee}
              index={index + 1}
              key={grantee.id}
              organization={organization}
            />
          ))}
        </div>
      )}
      searchBarPlaceholder="Search grantees..."
    />
  );
};
