/**
 * @generated SignedSource<<d2aca7347a546da4f040b0ca48a4e7ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
import { FragmentRefs } from "relay-runtime";
export type RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry$data = {
  readonly grantee: {
    readonly ctmsGrantsCount: number;
    readonly jobTitle: string | null;
    readonly name: string;
    readonly totalDraftedSharesBreakdown: {
      readonly total: number;
    };
    readonly totalGrantedSharesBreakdown: {
      readonly total: number;
    };
    readonly totalPlannedSharesBreakdown: {
      readonly total: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsDraftsList_Grantee" | "GranteeDetailsGrantsList_Grantee">;
  };
  readonly granteeOtherRefresherGrantPlanningEntries: ReadonlyArray<{
    readonly id: string;
    readonly refresherType: RefresherGrantPlanningEntryType;
    readonly sharesGranted: number;
  }>;
  readonly refresherType: RefresherGrantPlanningEntryType;
  readonly sharesGranted: number;
  readonly " $fragmentType": "RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry";
};
export type RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry$key = {
  readonly " $data"?: RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sharesGranted",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refresherType",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RefresherGrantPlanningEntry",
      "kind": "LinkedField",
      "name": "granteeOtherRefresherGrantPlanningEntries",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "totalPlannedSharesBreakdown",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "totalDraftedSharesBreakdown",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "totalGrantedSharesBreakdown",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ctmsGrantsCount",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeDetailsGrantsList_Grantee"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeDetailsDraftsList_Grantee"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RefresherGrantPlanningEntry",
  "abstractKey": null
};
})();

(node as any).hash = "7e92a0ff5453df54697b7c6220d7b3a7";

export default node;
