/**
 * @generated SignedSource<<7cceee1cdf04a9e4921817c4369c33f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipDetailed_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_Viewer">;
  readonly " $fragmentType": "EquityTypeWorkRelationshipDetailed_Viewer";
};
export type EquityTypeWorkRelationshipDetailed_Viewer$key = {
  readonly " $data"?: EquityTypeWorkRelationshipDetailed_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipDetailed_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipDetailed_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminPortalContent_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "3d9763c5a80847eff15194a233a3f014";

export default node;
