/**
 * @generated SignedSource<<47c0cfb966ca6cd1f23109e7937d69a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsEditionBlock_EquityType$data = {
  readonly id: string;
  readonly legalWatchOutsForContractor: {
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts">;
  };
  readonly legalWatchOutsForDirectEmployee: {
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts">;
  };
  readonly legalWatchOutsForEoREmployee: {
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts">;
  };
  readonly " $fragmentType": "LegalWatchOutsEditionBlock_EquityType";
};
export type LegalWatchOutsEditionBlock_EquityType$key = {
  readonly " $data"?: LegalWatchOutsEditionBlock_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionBlock_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LegalWatchOutsEditionBlock_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForDirectEmployee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForEoREmployee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForContractor",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "a4128b07c20e6a1492308953f3ebee93";

export default node;
