/**
 * @generated SignedSource<<e402fea13de52a105115079d473d8dd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$data = ReadonlyArray<{
  readonly accelerationClause: AccelerationClause | null;
  readonly awardSuperType: AwardSuperType;
  readonly earlyExercise: boolean;
  readonly equityTypeName: string | null;
  readonly exercisePrice: number;
  readonly grantee: {
    readonly USStateOfResidence: {
      readonly name: string;
    } | null;
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly name: string;
    } | null;
  };
  readonly postTerminationExercisePeriod: {
    readonly __typename: "OrganizationFixedPostTerminationExercisePeriod";
    readonly duration: number;
    readonly durationUnit: DurationUnit;
  } | {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly extensionDuration: number;
    readonly extensionDurationUnit: DurationUnit;
    readonly thresholdForExtensionInYears: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
  readonly quantityGranted: number;
  readonly vestingSchedule: {
    readonly name: string;
  };
  readonly vestingStartDate: string;
  readonly " $fragmentType": "ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants";
}>;
export type ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$key = ReadonlyArray<{
  readonly " $data"?: ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationUnit",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "USState",
          "kind": "LinkedField",
          "name": "USStateOfResidence",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "awardSuperType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "type": "OrganizationFixedPostTerminationExercisePeriod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDuration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDurationUnit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thresholdForExtensionInYears",
              "storageKey": null
            }
          ],
          "type": "OrganizationVariablePostTerminationExercisePeriod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "b07e52eb6d457552c8c5b07503f5690d";

export default node;
