/**
 * @generated SignedSource<<33e18ca7ff802001a5e31cdf08fbdb62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsExercise_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "CtmsGrantDetailsExercise_Organization";
};
export type CtmsGrantDetailsExercise_Organization$key = {
  readonly " $data"?: CtmsGrantDetailsExercise_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsExercise_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsExercise_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "de7f3e7a57c206e3a6c48ab82d4efb79";

export default node;
