/**
 * @generated SignedSource<<831a99998430f6b2c2657c9b22e1772b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type JobQueue = "RefreshOrganizationCTMSExerciseRequests";
export type JobState = "Active" | "Done" | "Failed";
export type Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation$variables = {
  organizationId: string;
};
export type Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation$data = {
  readonly startRefreshOrganizationCTMSExerciseRequests: {
    readonly id: string;
    readonly queue: JobQueue;
    readonly state: JobState;
  };
};
export type Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation = {
  response: Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation$data;
  variables: Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "Job",
    "kind": "LinkedField",
    "name": "startRefreshOrganizationCTMSExerciseRequests",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "queue",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "551d6b40570073917a8a8100a8800054",
    "id": null,
    "metadata": {},
    "name": "Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation",
    "operationKind": "mutation",
    "text": "mutation Exercises_RefreshOrganizationCTMSExerciseRequests_Mutation(\n  $organizationId: OrganizationId!\n) {\n  startRefreshOrganizationCTMSExerciseRequests(organizationId: $organizationId) {\n    id\n    queue\n    state\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a3dd498acf0f84d250288fa28b6aa84";

export default node;
