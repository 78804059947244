/**
 * @generated SignedSource<<387c7c48ec3780a875b19cd2968431ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquiuniversitySettings_EquityOffer$data = {
  readonly displayFAQ: boolean;
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPreviewLayout_EquityOffer">;
  readonly " $fragmentType": "EquiuniversitySettings_EquityOffer";
};
export type EquiuniversitySettings_EquityOffer$key = {
  readonly " $data"?: EquiuniversitySettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquiuniversitySettings_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquiuniversitySettings_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayFAQ",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "2ba873a35c27de48e947ae85bb4a08d0";

export default node;
