/**
 * @generated SignedSource<<59b64c50999c4cb98cf631869abfb1ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationLayout_Query$variables = {
  id: string;
};
export type OrganizationLayout_Query$data = {
  readonly organization: {
    readonly setupIsComplete: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"useApplicationTheme_Organization">;
  } | null;
};
export type OrganizationLayout_Query = {
  response: OrganizationLayout_Query$data;
  variables: OrganizationLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupIsComplete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useApplicationTheme_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "useRemoteEquityTheme",
                "storageKey": null
              }
            ],
            "type": "IOrganization",
            "abstractKey": "__isIOrganization"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7b70e3cb21839f606a42a2b429efeb2",
    "id": null,
    "metadata": {},
    "name": "OrganizationLayout_Query",
    "operationKind": "query",
    "text": "query OrganizationLayout_Query(\n  $id: OrganizationId!\n) {\n  organization(id: $id) {\n    setupIsComplete\n    ...useApplicationTheme_Organization\n    id\n  }\n}\n\nfragment useApplicationTheme_Organization on IOrganization {\n  __isIOrganization: __typename\n  useRemoteEquityTheme\n}\n"
  }
};
})();

(node as any).hash = "e8e0eb49d760bfc7f4190a74170cb707";

export default node;
