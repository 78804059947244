import { FormattedNumber } from "./FormattedNumber";

export const FormattedCurrency: React.FC<{
  animated?: boolean;
  currency?: string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
  value: number;
}> = ({ currency = "USD", value, ...props }) => (
  <FormattedNumber
    currency={currency}
    style="currency"
    value={value}
    {...props}
  />
);
