/**
 * @generated SignedSource<<0af927b27c03b38364e0c6c9ffd2eb6f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CancelBoardConsentButton_cancelBoardConsent_Mutation$variables = {
  boardConsentId: string;
};
export type CancelBoardConsentButton_cancelBoardConsent_Mutation$data = {
  readonly cancelBoardConsent: boolean;
};
export type CancelBoardConsentButton_cancelBoardConsent_Mutation = {
  response: CancelBoardConsentButton_cancelBoardConsent_Mutation$data;
  variables: CancelBoardConsentButton_cancelBoardConsent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardConsentId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "boardConsentId"
      }
    ],
    "kind": "ScalarField",
    "name": "cancelBoardConsent",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelBoardConsentButton_cancelBoardConsent_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelBoardConsentButton_cancelBoardConsent_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f5c2de862261e1e4a860402ca63dba6",
    "id": null,
    "metadata": {},
    "name": "CancelBoardConsentButton_cancelBoardConsent_Mutation",
    "operationKind": "mutation",
    "text": "mutation CancelBoardConsentButton_cancelBoardConsent_Mutation(\n  $boardConsentId: UUID!\n) {\n  cancelBoardConsent(id: $boardConsentId)\n}\n"
  }
};
})();

(node as any).hash = "7a16590ff600f52067a0996637b1a1a1";

export default node;
