import React, { useCallback, useContext, useMemo, useState } from "react";
import { RecordSource, Store } from "relay-runtime";
import RelayModernStore from "relay-runtime/lib/store/RelayModernStore";

const createRelayStore = () => new Store(new RecordSource());

const RelayStoreContext = React.createContext<null | {
  resetStore: () => void;
  store: RelayModernStore;
}>(null);

export const RelayStoreContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [store, setStore] = useState(createRelayStore());
  const resetStore = useCallback(() => {
    setStore(createRelayStore());
  }, []);

  const context = useMemo(() => ({ resetStore, store }), [resetStore, store]);

  return (
    <RelayStoreContext.Provider value={context}>
      {children}
    </RelayStoreContext.Provider>
  );
};

export const useRelayStoreContext = () => {
  const context = useContext(RelayStoreContext);

  if (!context) {
    throw new Error("cannot get relay store context");
  }

  return context;
};
