import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ShortDate } from "../../../components/ShortDate";
import { LabelValue } from "../../../components/ui/LabelValue";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";
import { WORK_RELATIONSHIPS } from "../../../services/workRelationship";
import { Candidate_EquityOffer$key } from "./__generated__/Candidate_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Candidate_EquityOffer on IEquityOffer {
    organization {
      name
    }
    candidateName
    taxResidenceCountry {
      emoji
      name
    }
    workRelationship
    position
    contractStartDate
  }
`;

export const EquityOfferCandidate: React.FC<{
  equityOfferFragment: Candidate_EquityOffer$key;
  forceMobile?: boolean;
  hideNoticeMessages?: boolean;
}> = ({
  equityOfferFragment,
  forceMobile = false,
  hideNoticeMessages = false,
}) => {
  const equityOffer = useFragment<Candidate_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferFragment,
  );

  return (
    <div className="flex flex-col gap-6">
      <Typography as="div" className="sm:!text-4.5xl" variant="Medium/Large">
        Congratulations {equityOffer.candidateName}!
      </Typography>

      <Typography as="div" variant="Medium/Default">
        We are so happy to offer you an equity package at{" "}
        {equityOffer.organization.name}! 🔥 🙏 🚀 🎸 🤘
      </Typography>

      {equityOffer.position ||
      equityOffer.taxResidenceCountry ||
      equityOffer.workRelationship ||
      equityOffer.contractStartDate ? (
        <div
          className={classNames("flex flex-col gap-6 md:flex-row md:gap-0", {
            "md:flex-col md:gap-6": forceMobile,
          })}
        >
          {equityOffer.position ? (
            <LabelValue className="flex-1" isPrimary label="Job title">
              {equityOffer.position}
            </LabelValue>
          ) : null}

          {equityOffer.taxResidenceCountry && (
            <LabelValue className="flex-1" isPrimary label="Tax residence">
              {equityOffer.taxResidenceCountry?.emoji}{" "}
              {equityOffer.taxResidenceCountry?.name}
            </LabelValue>
          )}

          {equityOffer.workRelationship && (
            <LabelValue className="flex-1" isPrimary label="Work relationship">
              {
                WORK_RELATIONSHIPS.find(
                  ({ id }) => id === equityOffer.workRelationship,
                )?.singularLabel
              }
            </LabelValue>
          )}

          {equityOffer.contractStartDate && (
            <LabelValue
              className="flex-1"
              isPrimary
              label="Contract start date"
            >
              <ShortDate value={equityOffer.contractStartDate} />
            </LabelValue>
          )}
        </div>
      ) : null}

      {!hideNoticeMessages && (
        <NoticeMessage size="Large" title="Before we start" variant="Warning">
          This offer is still subject to board approval.
          <br />
          Certain terms of the offer (in particular strike price and company
          valuation) may change between the time you accept this offer and the
          time you receive your equity package.
        </NoticeMessage>
      )}
    </div>
  );
};
