import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isNil } from "lodash";
import { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import {
  BoardConsentsDocumentsTable_Organization$data,
  BoardConsentsDocumentsTable_Organization$key,
} from "./__generated__/BoardConsentsDocumentsTable_Organization.graphql";
import { GreenCheckTag } from "./GreenCheckTag";
import { LongDate } from "./LongDate";
import { RedCrossTag } from "./RedCrossTag";
import { LinkButton } from "./ui/Button";
import { Table } from "./ui/Table";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardConsentsDocumentsTable_Organization on Organization {
    boardConsents {
      __typename
      ... on GrantBoardConsent {
        grantCount
      }
      ... on GranteeTerminationGrantAmendmentBoardConsent {
        granteeTerminationCTMSGrantAmendmentRequestCount
      }
      ... on GrantAmendmentBoardConsent {
        ctmsGrantAmendmentRequestsCount
      }
      ... on FairMarketValueBoardConsent {
        fairMarketValue {
          value
        }
      }
      completedAt
      signedDocument {
        downloadUrl
      }
    }
  }
`;

type BoardConsent =
  BoardConsentsDocumentsTable_Organization$data["boardConsents"][number];

const columnHelper = createColumnHelper<BoardConsent>();

const GrantCountMessage: React.FC<{ boardConsent: BoardConsent }> = ({
  boardConsent,
}) => {
  switch (boardConsent.__typename) {
    case "FairMarketValueBoardConsent":
      return (
        <>
          409A valuation (
          {!isNil(boardConsent.fairMarketValue?.value) ? (
            <FormattedNumber
              currency="USD"
              style="currency"
              value={boardConsent.fairMarketValue?.value}
            />
          ) : (
            "-"
          )}
          )
        </>
      );
    case "GrantAmendmentBoardConsent":
      return (
        <FormattedMessage
          defaultMessage="{count, plural, one {# grant amendment request} other {# grant amendment requests}}"
          values={{
            count: boardConsent.ctmsGrantAmendmentRequestsCount,
          }}
        />
      );
    case "GrantBoardConsent":
      return (
        <FormattedMessage
          defaultMessage="{count, plural, one {# grant} other {# grants}}"
          values={{
            count: boardConsent.grantCount,
          }}
        />
      );
    case "GranteeTerminationGrantAmendmentBoardConsent":
      return (
        <FormattedMessage
          defaultMessage="{count, plural, one {# grant amendment request} other {# grant amendment requests}}"
          values={{
            count:
              boardConsent.granteeTerminationCTMSGrantAmendmentRequestCount,
          }}
        />
      );
  }
};

export const BoardConsentsDocumentsTable: React.FC<{
  onDownloadBoardConsentButtonClick: () => void;
  organizationFragment: BoardConsentsDocumentsTable_Organization$key;
}> = ({ onDownloadBoardConsentButtonClick, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const columns = useMemo(
    () => [
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const boardConsent = context.row.original;
          return (
            <div className="space-y-1">
              <Typography as="div" variant="Medium/Extra Small">
                Board consent -{" "}
                <GrantCountMessage boardConsent={boardConsent} />
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Caption"
              >
                {boardConsent.completedAt ? "Approved" : "Not yet approved"}
              </Typography>
            </div>
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Document name",
        id: "document_name",
      }),
      columnHelper.accessor((row) => row.completedAt, {
        cell: (context) => {
          const boardApprovalDate = context.getValue();

          return (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Caption"
            >
              {boardApprovalDate ? <LongDate value={boardApprovalDate} /> : "-"}
            </Typography>
          );
        },
        enableGlobalFilter: false,
        enableSorting: true,
        header: () => "Board approval date",
        id: "board_approval_date",
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const boardConsent = context.row.original;

          return boardConsent.completedAt ? <GreenCheckTag /> : <RedCrossTag />;
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Availability",
        id: "availability",
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const boardConsent = context.row.original;

          const completionDate = boardConsent.completedAt;
          const documentUrl = boardConsent.signedDocument?.downloadUrl;

          if (!completionDate || !documentUrl) {
            return null;
          }

          return (
            <LinkButton
              leftIcon={<ArrowDownTrayIcon />}
              onClick={() => onDownloadBoardConsentButtonClick()}
              size="extra small"
              to={documentUrl}
              variant="Secondary Full"
            />
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "",
        id: "download",
      }),
    ],
    [onDownloadBoardConsentButtonClick],
  );

  const data = useMemo(
    () => [...organization.boardConsents],
    [organization.boardConsents],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "board_approval_date" }],
    },
  });

  return <Table.Smart table={table} />;
};
