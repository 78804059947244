import React from "react";
import { graphql } from "relay-runtime";

import { OAuthErrorNotice } from "../../../../components/OAuthErrorNotice";
import { Page } from "../../../../components/Page";
import { SelectCartaIssuerBlock } from "../../../../components/SelectCartaIssuerBlock";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import { useGenerateOrganizationCartaAuthorizationFlowURL } from "../../../../hooks/useGenerateOrganizationCartaAuthorizationFlowURL";
import { useQuery } from "../../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { OrganizationSettingsIntegrationCarta_Query } from "./__generated__/OrganizationSettingsIntegrationCarta_Query.graphql";
import cartaLargeLogoSrc from "./carta-large-logo.png";
import OrganizationSettingsIntegrationView from "./OrganizationSettingsIntegration";

const QUERY = graphql`
  query OrganizationSettingsIntegrationCarta_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      isConnectedToCarta
      selectedCartaIssuerId
      ...OrganizationSettingsIntegration_Organization
      ...SelectCartaIssuerBlock_Organization
    }
  }
`;

const OrganizationSettingsIntegrationCarta: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<OrganizationSettingsIntegrationCarta_Query>(QUERY, {
    organizationId,
  });

  const { generateOrganizationCartaAuthorizationFlowURL } =
    useGenerateOrganizationCartaAuthorizationFlowURL({
      doNotConnectIfMultipleIssuersSelected: false,
      organizationId,
    });

  const applicationName = useApplicationName();

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Carta Integration Settings"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Carta integration settings`}
    >
      <OrganizationSettingsIntegrationView
        capabilities={[
          {
            description: (
              <div className="space-y-2">
                <div>
                  {applicationName} synchronizes with Carta in such a way that{" "}
                  {applicationName} users can manage their entire team equity
                  compensation workflows in
                  {applicationName} while making sure that the cap table is
                  updated accordingly.
                </div>
                <div>
                  Once the connection is established, grants and grantees will
                  automatically synchronize with Carta.
                </div>
              </div>
            ),
            key: "import_data_from_carta",
            title: "Import existing grants & grantees from Carta",
          },
          {
            description: (
              <div className="space-y-2">
                <div>
                  Here is the read only data from Carta that will be
                  synchronised with {applicationName} on a daily basis:
                </div>
                <ul className="list-inside list-disc text-black-05">
                  <li>Information about you and the company&apos;s account</li>
                  <li>
                    Aggregated summary information of your capitalization table
                  </li>
                  <li>Securities in your capitalization table</li>
                  <li>Share class information</li>
                  <li>Stakeholder capitalization table</li>
                  <li>
                    Information about a shareholder that has received equity
                  </li>
                  <li>Valuation information</li>
                </ul>
              </div>
            ),
            key: "scope_of_imported_data",
            title: "Scope of data imported",
          },
        ]}
        description={
          <div className="space-y-6">
            <div>
              The integration between {applicationName} and Carta makes it
              easier for businesses to grant equity to international hires and
              manage their equity more effectively. By automating the regulatory
              and tax complexities that come with incentivizing team members
              from different countries, {applicationName} enables businesses to
              grant equity to their team faster and more cost-effectively.
            </div>
            {organization.isConnectedToCarta &&
              !organization.selectedCartaIssuerId && (
                <SelectCartaIssuerBlock organizationFragment={organization} />
              )}
          </div>
        }
        errorMessage={<OAuthErrorNotice />}
        generateAuthorizationUrl={generateOrganizationCartaAuthorizationFlowURL}
        integrationColor="#000"
        integrationLogoSrc={cartaLargeLogoSrc}
        integrationName="Carta"
        integrationRequirements={[
          {
            content: `Admin access to your ${applicationName} account`,
            key: "easop_access",
          },
          {
            content: "An active Carta subscription",
            key: "carta_subscription",
          },
          {
            content: (
              <>
                Company editor, company viewer, HR admin or legal admin access
                in Carta (more information on permissions & roles available{" "}
                <a
                  className="text-primary underline"
                  href="https://support.carta.com/s/article/corporate-permissions#permissions"
                  rel="noreferrer"
                  target="_blank"
                >
                  here
                </a>
                )
              </>
            ),
            key: "carta_role",
          },
        ]}
        integrationWebsiteName="carta.com"
        integrationWebsiteUrl="https://carta.com"
        isConnected={organization.isConnectedToCarta}
        organizationFragment={organization}
        provider="CARTA"
        title="Automatically synchronize your equity and grantees"
      />
    </Page>
  );
};

export default OrganizationSettingsIntegrationCarta;
