import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../../hooks/useBoolean";
import { LoadingPlaceholder } from "../LoadingPlaceholder";
import { useToaster } from "../Toaster";
import { SlideOver } from "../ui/SlideOver";
import { Toast } from "../ui/Toast";
import { Typography } from "../ui/Typography";
import { BookADemoSlideOver_Viewer$key } from "./__generated__/BookADemoSlideOver_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment BookADemoSlideOver_Viewer on Account {
    email
    firstName
    lastName
  }
`;

export const BookADemoSlideOver: React.FC<{
  header?: React.ReactNode;
  introduction?: string;
  name?: string;
  organizationName?: string;
  renderButton: (options: { openSlideOver: () => void }) => React.ReactNode;
  viewerFragment?: BookADemoSlideOver_Viewer$key | null;
}> = ({
  header,
  introduction,
  organizationName,
  renderButton,
  viewerFragment = null,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const {
    setFalse: closeSlideOver,
    setTrue: openSlideOver,
    value: slideOverIsOpen,
  } = useBoolean(false);

  const toaster = useToaster();

  useCalendlyEventListener({
    onEventScheduled: () => {
      toaster.push(
        <Toast variant="congrats">
          Your demo call has successfully been scheduled. We can’t wait to meet
          you!
        </Toast>,
      );
      return closeSlideOver();
    },
  });

  return (
    <>
      {renderButton({ openSlideOver })}
      <SlideOver onClose={closeSlideOver} show={slideOverIsOpen} width="sm">
        <SlideOver.Header onClose={closeSlideOver} />
        <div className="space-y-6 px-6">
          <div className="space-y-2 text-center">
            <Typography as="div" variant="Medium/Small">
              Schedule a demo
            </Typography>
            {header && (
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                {header}
              </Typography>
            )}
          </div>
          <div className="overflow-hidden rounded-lg border-[0.5px] border-gray-04">
            <InlineWidget
              LoadingSpinner={LoadingPlaceholder}
              prefill={{
                customAnswers: {
                  a1: organizationName ?? "",
                  a2: introduction ?? "",
                },
                email: viewer?.email ?? "",
                name: viewer ? `${viewer.firstName} ${viewer.lastName}` : "",
              }}
              url="https://calendly.com/d/z33-6ks-wby/meet-easop-demo"
            />
          </div>
        </div>
      </SlideOver>
    </>
  );
};
