import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { InviteGranteeEmailPreviewModal_Grantee$key } from "./__generated__/InviteGranteeEmailPreviewModal_Grantee.graphql";
import { InviteGranteeEmailPreviewModal_Viewer$key } from "./__generated__/InviteGranteeEmailPreviewModal_Viewer.graphql";
import { Modal } from "./ui/Modal";

const GRANTEE_FRAGMENT = graphql`
  fragment InviteGranteeEmailPreviewModal_Grantee on Grantee {
    name
    organization {
      id
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment InviteGranteeEmailPreviewModal_Viewer on Account {
    fullName
    email
  }
`;

type State =
  | {
      granteeFragment: InviteGranteeEmailPreviewModal_Grantee$key;
      shown: true;
    }
  | {
      granteeFragment?: InviteGranteeEmailPreviewModal_Grantee$key | null;
      shown: false;
    };

export const useInviteGranteeEmailPreviewModalState = () => {
  const [inviteGranteeEmailPreviewModalState, setState] = useState<State>({
    shown: false,
  });

  const openInviteGranteeEmailPreviewModal = useCallback(
    ({
      granteeFragment,
    }: {
      granteeFragment: InviteGranteeEmailPreviewModal_Grantee$key;
    }) => setState({ granteeFragment, shown: true }),
    [],
  );

  const closeInviteGranteeEmailPreviewModal = useCallback(
    () => setState((previousState) => ({ ...previousState, shown: false })),
    [],
  );

  return {
    closeInviteGranteeEmailPreviewModal,
    inviteGranteeEmailPreviewModalState,
    openInviteGranteeEmailPreviewModal,
  };
};

export const InviteGranteeEmailPreviewModal: React.FC<{
  onClose: () => void;
  state: State;
  viewerFragment: InviteGranteeEmailPreviewModal_Viewer$key;
}> = ({ onClose, state, viewerFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, state.granteeFragment ?? null);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const iframeSrc = useMemo(() => {
    if (!grantee) return null;

    const currentURL = new URL(window.location.href);

    currentURL.pathname = "/api/email-preview/invite.html";

    currentURL.searchParams.set("role", "grantee");
    currentURL.searchParams.set("inviteURL", "https://app.easop.com");
    currentURL.searchParams.set("recipientName", grantee.name);
    currentURL.searchParams.set("inviterName", viewer.fullName);
    currentURL.searchParams.set("inviterEmail", viewer.email);
    currentURL.searchParams.set("organizationId", grantee.organization.id);

    return currentURL.toString();
  }, [grantee, viewer]);

  return (
    <Modal.Raw
      onClose={onClose}
      panelClassName="h-screen"
      show={state.shown}
      suspense
      width={680}
    >
      <div className="h-full">
        {iframeSrc && (
          <iframe
            className="rounded-lg"
            height="100%"
            src={iframeSrc}
            width="100%"
          />
        )}
      </div>
    </Modal.Raw>
  );
};
