import classNames from "classnames";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CARD_BRAND_TO_LOGO_MAP } from "../pages/Admin/Countries/cardLogos";
import { StripeCardPaymentMethod_StripePaymentMethod$key } from "./__generated__/StripeCardPaymentMethod_StripePaymentMethod.graphql";

const STRIPE_PAYMENT_METHOD_FRAGMENT = graphql`
  fragment StripeCardPaymentMethod_StripePaymentMethod on StripePaymentMethod {
    cardBrand
    cardExpirationMonth
    cardExpirationYear
    cardLast4Digits
  }
`;

export const StripeCardPaymentMethod: React.FC<{
  className?: string;
  stripePaymentMethodFragment: StripeCardPaymentMethod_StripePaymentMethod$key;
}> = ({ className, stripePaymentMethodFragment }) => {
  const {
    cardBrand,
    cardExpirationMonth,
    cardExpirationYear,
    cardLast4Digits,
  } = useFragment(STRIPE_PAYMENT_METHOD_FRAGMENT, stripePaymentMethodFragment);

  if (
    !cardBrand ||
    typeof cardExpirationMonth !== "number" ||
    typeof cardExpirationYear !== "number" ||
    !cardLast4Digits
  ) {
    return null;
  }

  const cardLogo = (cardBrand && CARD_BRAND_TO_LOGO_MAP[cardBrand]) ?? null;

  return (
    <div className={classNames("flex items-center gap-2", className)}>
      {cardLogo && <img className="w-6" src={cardLogo} />}
      <div className="space-y-1">
        <div className="text-SM/Medium">
          <span className="capitalize">{cardBrand}</span>{" "}
          <span className="text-SM/Normal">ending with</span> {cardLast4Digits}
        </div>
        <div className="text-XS/Normal text-black-05">
          Expires {cardExpirationMonth.toString().padStart(2, "0")}/
          {cardExpirationYear}
        </div>
      </div>
    </div>
  );
};
