import amexLogo from "./amex.svg";
import dinersLogo from "./diners.svg";
import discoverLogo from "./discover.svg";
import jcbLogo from "./jcb.svg";
import mastercardLogo from "./mastercard.svg";
import unionpayLogo from "./unionpay.svg";
import visaLogo from "./visa.svg";

export const CARD_BRAND_TO_LOGO_MAP: Record<string, string | undefined> = {
  amex: amexLogo,
  diners: dinersLogo,
  discover: discoverLogo,
  jcb: jcbLogo,
  mastercard: mastercardLogo,
  unionpay: unionpayLogo,
  visa: visaLogo,
};
