import { useCallback, useMemo, useState } from "react";
import { graphql, useFragment, usePaginationFragment } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";

import { OrganizationCTMSGrantsTable } from "../../../components/OrganizationCtmsGrantsTable";
import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import {
  useGrantsTableSearchParams,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { OrganizationCTMSGrantsFilters } from "../Insights/UnderwaterGrants/__generated__/UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query.graphql";
import { Grants_Organization$key } from "./__generated__/Grants_Organization.graphql";
import {
  CTMSGrantStatus,
  Grants_OrganizationCtmsGrantsPagination_Query,
} from "./__generated__/Grants_OrganizationCtmsGrantsPagination_Query.graphql";
import { Grants_Query } from "./__generated__/Grants_Query.graphql";
import { Grants_Refetchable_Organization$key } from "./__generated__/Grants_Refetchable_Organization.graphql";

const ORGANIZATION_REFETCHABLE_FRAGMENT = graphql`
  fragment Grants_Refetchable_Organization on Organization
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    filters: { type: "OrganizationCTMSGrantsFilters!" }
  )
  @refetchable(queryName: "Grants_OrganizationCtmsGrantsPagination_Query") {
    ctmsGrants(first: $count, after: $cursor, filters: $filters)
      @connection(key: "CtmsGrants_Organization_ctmsGrants") {
      totalCount
      edges {
        node {
          ...OrganizationCtmsGrantsTable_CTMSGrants
        }
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Grants_Organization on Organization
  @argumentDefinitions(filters: { type: "OrganizationCTMSGrantsFilters!" }) {
    id
    name
    ...OrganizationCtmsGrantsTable_Organization
    ...Grants_Refetchable_Organization @arguments(filters: $filters)
  }
`;

const AdminGrantsPage_: React.FC<{
  filters: OrganizationCTMSGrantsFilters;
  grantStatus: CTMSGrantStatus;
  organizationFragment: Grants_Organization$key;
  setFilters: (filters: OrganizationCTMSGrantsFilters) => void;
}> = ({ filters, grantStatus, organizationFragment, setFilters }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    data: { ctmsGrants },
    hasNext,
    isLoadingNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    Grants_OrganizationCtmsGrantsPagination_Query,
    Grants_Refetchable_Organization$key
  >(ORGANIZATION_REFETCHABLE_FRAGMENT, organization);

  const handleFiltersChange = (filters: OrganizationCTMSGrantsFilters) => {
    setFilters(filters);
    refetch({ filters });
  };

  const pageAttributes = useMemo(() => {
    switch (grantStatus) {
      case "Active":
        return {
          analyticsName: "Admin - Active Grants",
          title: `Admin | ${organization.name} active grants`,
        };
      case "Canceled":
        return {
          analyticsName: "Admin - Canceled Grants",
          title: `Admin | ${organization.name} canceled grants`,
        };
      case "Terminated":
        return {
          analyticsName: "Admin - Terminated Grants",
          title: `Admin | ${organization.name} terminated grants`,
        };
    }
  }, [organization.name, grantStatus]);

  const handleLoadNext = () => {
    loadNext(20);
  };

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName={pageAttributes.analyticsName}
      organizationId={organization.id}
      title={pageAttributes.title}
    >
      <OrganizationCTMSGrantsTable
        ctmsGrantsFragment={ctmsGrants.edges.map((edge) => edge.node)}
        filters={filters}
        hasNext={hasNext}
        isLoadingNext={isLoadingNext}
        loadNext={handleLoadNext}
        onFiltersChange={handleFiltersChange}
        organizationFragment={organization}
        totalCount={ctmsGrants.totalCount}
      />
    </Page>
  );
};

const QUERY = graphql`
  query Grants_Query(
    $organizationId: OrganizationId!
    $filters: OrganizationCTMSGrantsFilters!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      ...Grants_Organization @arguments(filters: $filters)
    }
  }
`;

const filtersSchema = z.object({
  equityTypeIn: z.array(z.string()).nullish(),
  granteeId: z.string().nullish(),
  idIn: z.array(z.string()).nullish(),
  orderBy: z
    .object({
      direction: z.enum(["ASC", "DESC"]),
      field: z.enum([
        "cumulativeVested",
        "equityType",
        "exercisePrice",
        "grantee",
        "label",
        "quantityExercised",
        "quantityIssued",
        "remainingDaysToExercise",
        "settled",
      ]),
    })
    .nullish(),
  search: z.string().nullish(),
  statusIn: z.array(z.enum(["Active", "Canceled", "Terminated"])).nullish(),
  vestingStatus: z
    .enum(["CLIFF_NOT_PASSED", "FULLY_VESTED", "VESTING"])
    .nullish(),
});

const AdminGrantsPage: React.FC<{ grantStatus: CTMSGrantStatus }> = ({
  grantStatus,
}) => {
  const organizationId = useOrganizationIdParam();
  const { params } = useGrantsTableSearchParams();
  const defaultFilters: OrganizationCTMSGrantsFilters = {
    orderBy: params,
  };
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, _setFilters] = useState<OrganizationCTMSGrantsFilters>(() => {
    const filters = searchParams.get("filters");

    if (filters) {
      const parsingResult = filtersSchema.safeParse(
        JSON.parse(decodeURI(atob(filters))),
      );
      if (parsingResult.success) {
        return parsingResult.data;
      }
    }

    return defaultFilters;
  });

  const setFilters = useCallback(
    (filters: OrganizationCTMSGrantsFilters) => {
      setSearchParams((params) => ({
        ...params,
        filters: btoa(encodeURI(JSON.stringify(filters))),
      }));
      _setFilters(filters);
    },
    [setSearchParams],
  );

  const filtersWithStatus = useMemo(
    () => ({
      ...filters,
      statusIn: [grantStatus],
    }),
    [filters, grantStatus],
  );

  const { query } = useQuery<Grants_Query>(QUERY, {
    filters: filtersWithStatus,
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGrantsPage_
      filters={filtersWithStatus}
      grantStatus={grantStatus}
      key={grantStatus}
      organizationFragment={query.organization}
      setFilters={setFilters}
    />
  );
};

export default AdminGrantsPage;
