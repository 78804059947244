/**
 * @generated SignedSource<<92c4de62d2614a15ed3a2b5ac07b05cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantVestingPreview_Grantee$data = {
  readonly activeCTMSGrants: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly vestingDataPoints: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly date: string;
    }>;
  }>;
  readonly easopGrantsWithoutCtmsGrant: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly vestingDataPoints: ReadonlyArray<{
      readonly cumulativeVested: number;
      readonly date: string;
    }>;
  }>;
  readonly " $fragmentType": "DraftGrantVestingPreview_Grantee";
};
export type DraftGrantVestingPreview_Grantee$key = {
  readonly " $data"?: DraftGrantVestingPreview_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingPreview_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "VestingDataPoint",
    "kind": "LinkedField",
    "name": "vestingDataPoints",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cumulativeVested",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantVestingPreview_Grantee",
  "selections": [
    {
      "alias": "activeCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active"
          ]
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "1f263e45085de283cee39a15e6f6f4bd";

export default node;
