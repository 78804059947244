/**
 * @generated SignedSource<<3a51733c4a1bb62b9119e82e0d0890a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation$variables = {
  equityViewerId: string;
};
export type OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation$data = {
  readonly removeEquityViewer: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Organization">;
  };
};
export type OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation = {
  response: OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation$data;
  variables: OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityViewerId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityViewerId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeEquityViewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsEquityViewers_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "removeEquityViewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityViewer",
            "kind": "LinkedField",
            "name": "equityViewers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "77f0d0e58009006bf05d4bbe193cd9ac",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsEquityViewers_RemoveEquityViewer_Mutation(\n  $equityViewerId: UUID!\n) {\n  removeEquityViewer(id: $equityViewerId) {\n    ...OrganizationSettingsEquityViewers_Organization\n    id\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Item_EquityViewer on EquityViewer {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Organization on Organization {\n  id\n  name\n  equityViewers {\n    id\n    ...OrganizationSettingsEquityViewers_Item_EquityViewer\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad384a740bfab6dd3487bc30e5399d8b";

export default node;
