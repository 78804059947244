import saveAs from "file-saver";
import { compact } from "lodash";
import { useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useDownloadGrantDocumentationForImplementation_EasopGrant$key } from "./__generated__/useDownloadGrantDocumentationForImplementation_EasopGrant.graphql";
import { useDownloadGrantDocumentationForImplementation_Organization$key } from "./__generated__/useDownloadGrantDocumentationForImplementation_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useDownloadGrantDocumentationForImplementation_Organization on Organization {
    name
    activeEquityPlanDocument {
      downloadUrl
    }
  }
`;

const EASOP_GRANT_FRAGMENT = graphql`
  fragment useDownloadGrantDocumentationForImplementation_EasopGrant on EasopGrant
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    label
    grantee {
      name
    }
    grantNoticeDocument {
      downloadUrl
    }
    awardAgreementDocument {
      downloadUrl
    }
    countrySpecificAddendumDocument {
      downloadUrl
    }
    formOfExerciseAgreementDocument {
      downloadUrl
    }
    formOfEarlyExerciseStockPurchaseAgreementDocument {
      downloadUrl
    }
    instrument {
      equityType {
        organizationActiveSubPlanDocument(organizationId: $organizationId) {
          downloadUrl
        }
      }
    }
  }
`;

type DocumentTemplateType =
  | "AWARD_AGREEMENT"
  | "COUNTRY_SPECIFIC_ADDENDUM"
  | "EQUITY_PLAN"
  | "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT"
  | "FORM_OF_EXERCISE_AGREEMENT"
  | "GRANT_NOTICE"
  | "SUB_PLAN";

export const useDownloadGrantDocumentationForImplementation = ({
  easopGrantFragment,
  organizationFragment,
}: {
  easopGrantFragment: useDownloadGrantDocumentationForImplementation_EasopGrant$key;
  organizationFragment: useDownloadGrantDocumentationForImplementation_Organization$key;
}) => {
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const buildItem = useMemo(
    () =>
      ({
        document,
        label,
        type,
      }: {
        document?: null | { downloadUrl: string; updatedAt?: null | string };
        label: string;
        type: DocumentTemplateType;
      }) =>
        document
          ? {
              downloadUrl: document.downloadUrl,
              fileName: `${easopGrant.grantee.name} - ${easopGrant.label} - ${label}.pdf`,
              label,
              type,
            }
          : null,
    [easopGrant.grantee.name, easopGrant.label],
  );

  const items = useMemo(
    () =>
      compact([
        buildItem({
          document: organization.activeEquityPlanDocument,
          label: "Equity plan",
          type: "EQUITY_PLAN",
        }),
        buildItem({
          document:
            easopGrant.instrument.equityType?.organizationActiveSubPlanDocument,
          label: "Sub plan",
          type: "SUB_PLAN",
        }),
        buildItem({
          document: easopGrant.awardAgreementDocument,
          label: "Award agreement",
          type: "AWARD_AGREEMENT",
        }),
        buildItem({
          document: easopGrant.countrySpecificAddendumDocument,
          label: "Country specific addendum",
          type: "COUNTRY_SPECIFIC_ADDENDUM",
        }),
        buildItem({
          document: easopGrant.formOfExerciseAgreementDocument,
          label: "Form of exercise agreement",
          type: "FORM_OF_EXERCISE_AGREEMENT",
        }),
        buildItem({
          document:
            easopGrant.formOfEarlyExerciseStockPurchaseAgreementDocument,
          label: "Form of early exercise stock purchase agreement",
          type: "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
        }),
        buildItem({
          document: easopGrant.grantNoticeDocument,
          label: "Grant notice",
          type: "GRANT_NOTICE",
        }),
      ]),
    [buildItem, easopGrant, organization],
  );

  return useCallback(() => {
    items.forEach((item) => {
      saveAs(item.downloadUrl, item.fileName);
    });
  }, [items]);
};
