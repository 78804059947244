/**
 * @generated SignedSource<<f9ae8763efb61a81d509897571b35385>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type RemoteOnboardingStep = "CLEAN_UP_GRANTEE_DATA" | "CONNECT_CTMS" | "SET_PRICE_PER_SHARE" | "TEST_LAW_FIRM_CARTA_CONNECTION";
import { FragmentRefs } from "relay-runtime";
export type FinishRemoteEquityOnboardingPage_Organization$data = {
  readonly currentRemoteOnboardingStep: RemoteOnboardingStep | null;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectCTMSView_Organization" | "MatchGranteeView_Organization" | "SubmitPricePerShareView_Organization" | "TestLawFirmCartaConnectionView_Organization">;
  readonly " $fragmentType": "FinishRemoteEquityOnboardingPage_Organization";
};
export type FinishRemoteEquityOnboardingPage_Organization$key = {
  readonly " $data"?: FinishRemoteEquityOnboardingPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishRemoteEquityOnboardingPage_Organization">;
};

import FinishRemoteEquityOnboardingPage_Organization_RefetchQuery_graphql from './FinishRemoteEquityOnboardingPage_Organization_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": FinishRemoteEquityOnboardingPage_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "FinishRemoteEquityOnboardingPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRemoteOnboardingStep",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectCTMSView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmitPricePerShareView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MatchGranteeView_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TestLawFirmCartaConnectionView_Organization"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cbb8cb9a9252650496acd30171afb409";

export default node;
