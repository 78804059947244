import {
  ArrowRightIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { HRISProviderEmployeeField } from "../hooks/__generated__/useMergeGrantee_MergeGranteeMutation.graphql";
import { useMergeGrantee } from "../hooks/useMergeGrantee";
import { MergeEmployeeWithAnotherGranteeModal_Grantee$key } from "./__generated__/MergeEmployeeWithAnotherGranteeModal_Grantee.graphql";
import { MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key } from "./__generated__/MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee.graphql";
import {
  MergeEmployeeWithAnotherGranteeModal_Organization$data,
  MergeEmployeeWithAnotherGranteeModal_Organization$key,
} from "./__generated__/MergeEmployeeWithAnotherGranteeModal_Organization.graphql";
import {
  MergeEmployeeTable,
  useMergeEmployeeTableState,
} from "./MergeEmployeeTable";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "./ui/Modal";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MergeEmployeeWithAnotherGranteeModal_Organization on Organization {
    grantees {
      edges {
        node {
          id
          name
          ...MergeEmployeeWithAnotherGranteeModal_Grantee
        }
      }
    }
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment MergeEmployeeWithAnotherGranteeModal_Grantee on Grantee {
    ...useMergeGrantee_Grantee
    ...MergeEmployeeTable_Grantee
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee on HRISProviderEmployee {
    name
    ...useMergeGrantee_HRISProviderEmployee
    ...MergeEmployeeTable_HRISProviderEmployee
  }
`;

const MergeTableContent: React.FC<{
  employeeFragment: MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key;
  granteeFragment: MergeEmployeeWithAnotherGranteeModal_Grantee$key;
  onSelectedFieldsListChange: (
    selectedFieldsList: HRISProviderEmployeeField[],
  ) => void;
}> = ({ employeeFragment, granteeFragment, onSelectedFieldsListChange }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const {
    resetState: resetMergeEmployeeTableState,
    selectedFieldsList: mergeEmployeeTableSelectedFieldsList,
    setState: setMergeEmployeeTableState,
    state: mergeEmployeeTableState,
  } = useMergeEmployeeTableState({
    granteeFragment: grantee,
  });

  useEffect(() => {
    onSelectedFieldsListChange(mergeEmployeeTableSelectedFieldsList);
  }, [mergeEmployeeTableSelectedFieldsList, onSelectedFieldsListChange]);

  useEffect(() => {
    resetMergeEmployeeTableState();
  }, [resetMergeEmployeeTableState, grantee]);

  return (
    <RoundedBox className="overflow-hidden" withBorder withShadow>
      <div className="bg-gray-01 px-4 py-2">
        <Typography className="text-black-05" variant="Medium/Extra Small">
          2. Reconcile data
        </Typography>
      </div>
      <div className="p-6">
        <MergeEmployeeTable
          employeeFragment={employee}
          granteeFragment={grantee}
          onChange={setMergeEmployeeTableState}
          state={mergeEmployeeTableState}
        />
      </div>
    </RoundedBox>
  );
};

const MergeGranteeButton: React.FC<{
  employeeFragment: MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key;
  granteeFragment: MergeEmployeeWithAnotherGranteeModal_Grantee$key;
  onMerge: () => void;
  selectedFieldsList: HRISProviderEmployeeField[];
}> = ({ employeeFragment, granteeFragment, onMerge, selectedFieldsList }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);
  const [_mergeGrantee, mergeGranteeIsInFlight] = useMergeGrantee({
    employeeFragment: employee,
    granteeFragment: grantee,
  });

  const mergeGrantee = async () => {
    await _mergeGrantee(selectedFieldsList);
    onMerge();
  };

  return (
    <Modal.ActionButton loading={mergeGranteeIsInFlight} onClick={mergeGrantee}>
      Merge grantee
    </Modal.ActionButton>
  );
};

const MergeEmployeeWithAnotherGranteeModalContent: React.FC<{
  employeeFragment: MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key;
  onClose: () => void;
  onMerge: () => void;
  organizationFragment: MergeEmployeeWithAnotherGranteeModal_Organization$key;
}> = ({ employeeFragment, onClose, onMerge, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const [selectedGrantee, setSelectedGrantee] = useState<
    | MergeEmployeeWithAnotherGranteeModal_Organization$data["grantees"]["edges"][number]["node"]
    | null
  >(null);

  const [selectedFieldsList, setSelectedFieldsList] = useState<
    HRISProviderEmployeeField[]
  >([]);

  return (
    <Modal.Content
      actionsInHeader={
        <>
          <Modal.ActionButton onClick={onClose} variant="Secondary Full">
            Cancel
          </Modal.ActionButton>
          {selectedGrantee ? (
            <MergeGranteeButton
              employeeFragment={employeeFragment}
              granteeFragment={selectedGrantee}
              onMerge={onMerge}
              selectedFieldsList={selectedFieldsList}
            />
          ) : (
            <Modal.ActionButton disabled={true}>
              Merge grantee
            </Modal.ActionButton>
          )}
        </>
      }
      onClose={onClose}
      subTitle="Select the most relevant data to reconcile"
      title="Merge grantee"
    >
      <RoundedBox className="overflow-hidden" withBorder withShadow>
        <div className="bg-gray-01 px-4 py-2">
          <Typography className="text-black-05" variant="Medium/Extra Small">
            1. Select grantee
          </Typography>
        </div>
        <div className="flex items-center gap-6 p-6">
          <div className="flex-1">
            <RoundedBox
              className="flex w-full items-center gap-2 px-3 py-2.5 text-black-05"
              withBorder
            >
              <ClipboardDocumentIcon className="h-5 w-5" />
              <Typography variant="Medium/Extra Small">
                {employee.name}
              </Typography>
            </RoundedBox>
          </div>
          <div className="h-8 w-8 shrink-0 rounded border-[0.5px] border-primary bg-primary-01 text-primary">
            <ArrowRightIcon className="m-auto h-full w-3.5" />
          </div>
          <div className="flex-1">
            <SelectAutocomplete
              className="w-full"
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={setSelectedGrantee}
              options={organization.grantees.edges.map((edge) => edge.node)}
              usePortal
              value={selectedGrantee}
            />
          </div>
        </div>
      </RoundedBox>
      {selectedGrantee && (
        <MergeTableContent
          employeeFragment={employeeFragment}
          granteeFragment={selectedGrantee}
          onSelectedFieldsListChange={setSelectedFieldsList}
        />
      )}
    </Modal.Content>
  );
};

export const MergeEmployeeWithAnotherGranteeModal: React.FC<{
  employeeFragment: MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee$key;
  onClose: () => void;
  onMerge: () => void;
  organizationFragment: MergeEmployeeWithAnotherGranteeModal_Organization$key;
  show: boolean;
}> = ({ employeeFragment, onClose, onMerge, organizationFragment, show }) => {
  return (
    <Modal onClose={onClose} show={show} suspense width={1048}>
      <MergeEmployeeWithAnotherGranteeModalContent
        employeeFragment={employeeFragment}
        onClose={onClose}
        onMerge={onMerge}
        organizationFragment={organizationFragment}
      />
    </Modal>
  );
};
