import { useApplicationTheme } from "../hooks/useApplicationTheme";
import { LogoWithoutText } from "./ui/Logo/Logo";
import { WhiteLabelSpinner } from "./WhiteLabelSpinner";

export const LoadingLogo: React.FC<{ className?: string }> = ({
  className,
}) => {
  const applicationTheme = useApplicationTheme();

  switch (applicationTheme) {
    case "easop":
      return <LogoWithoutText animate className={className} />;
    case null:
    case "remote equity":
      return <WhiteLabelSpinner className={className} />;
  }
};
