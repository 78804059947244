import classNames from "classnames";
import { FormattedDate } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Footer_Organization$key } from "./__generated__/Footer_Organization.graphql";
import { PoweredByCarta } from "./ui/PoweredByCarta/PoweredByCarta";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Footer_Organization on Organization {
    isConnectedToCarta
  }
`;

export const Footer: React.FC<{
  className?: string;
  organizationFragment?: Footer_Organization$key | null;
}> = ({ className, organizationFragment = null }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const now = new Date();

  return (
    <div
      className={classNames(
        "grid border-t-[0.5px] border-gray-04 py-10",
        {
          "grid-cols-[auto_1fr_auto]": organization?.isConnectedToCarta,
          "grid-cols-[auto_auto]": !organization?.isConnectedToCarta,
        },
        className,
      )}
    >
      <Typography
        as="div"
        className={classNames({
          "text-center": organization?.isConnectedToCarta,
          "text-left": !organization?.isConnectedToCarta,
        })}
        variant="Regular/Extra Small"
      >
        Copyright © <FormattedDate value={now} year="numeric" /> Remote Eagles
        Inc., dba Easop
      </Typography>
      <Typography
        as="div"
        className={classNames(
          "flex flex-wrap items-center gap-2 text-center text-black-05 sm:gap-10",
          {
            "justify-center": organization?.isConnectedToCarta,
            "justify-end": !organization?.isConnectedToCarta,
          },
        )}
        variant="Medium/Caption"
      >
        <a href="https://easop.crew.work/jobs" rel="noreferrer" target="_blank">
          Careers
        </a>
        <a href="https://www.easop.com/privacy/privacy-policy">Privacy</a>
        <a href="https://www.easop.com/privacy/terms-of-use">
          Terms & Conditions
        </a>
      </Typography>
      {organization?.isConnectedToCarta && <PoweredByCarta />}
    </div>
  );
};
