/**
 * @generated SignedSource<<ef5252c404836f79bb748bb179c0f641>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripePaymentMethodExpiration_StripePaymentMethod$data = {
  readonly cardExpirationMonth: number | null;
  readonly cardExpirationYear: number | null;
  readonly " $fragmentType": "StripePaymentMethodExpiration_StripePaymentMethod";
};
export type StripePaymentMethodExpiration_StripePaymentMethod$key = {
  readonly " $data"?: StripePaymentMethodExpiration_StripePaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"StripePaymentMethodExpiration_StripePaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StripePaymentMethodExpiration_StripePaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardExpirationMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardExpirationYear",
      "storageKey": null
    }
  ],
  "type": "StripePaymentMethod",
  "abstractKey": null
};

(node as any).hash = "1e6280aaf39bbe6c60feedda47042902";

export default node;
