/**
 * @generated SignedSource<<2e8a422a53ee12f0fdbbd9d7122a84f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantVestingTag_CtmsGrant$data = {
  readonly fullyVested: boolean;
  readonly " $fragmentType": "CtmsGrantVestingTag_CtmsGrant";
};
export type CtmsGrantVestingTag_CtmsGrant$key = {
  readonly " $data"?: CtmsGrantVestingTag_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantVestingTag_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantVestingTag_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyVested",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "0b61341fa106a0fc73fc3c33a5b1b65c";

export default node;
