/**
 * @generated SignedSource<<2818714dc185a0cd4ac538f8f48f96d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_CTMSGrantAmendmentRequests_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "UnderReview_CTMSGrantAmendmentRequests_Account";
};
export type UnderReview_CTMSGrantAmendmentRequests_Account$key = {
  readonly " $data"?: UnderReview_CTMSGrantAmendmentRequests_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderReview_CTMSGrantAmendmentRequests_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderReview_CTMSGrantAmendmentRequests_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "3f09688a213fdd2fc27802fece84ec03";

export default node;
