/**
 * @generated SignedSource<<095d0628291d7494a65fa48b8fc92580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsHeader_Organization$data = {
  readonly id: string;
  readonly isConnectedToCarta: boolean;
  readonly isConnectedToPulley: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "CtmsGrantDetailsHeader_Organization";
};
export type CtmsGrantDetailsHeader_Organization$key = {
  readonly " $data"?: CtmsGrantDetailsHeader_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsHeader_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsHeader_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToPulley",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "28a84018a92029953563b7da32fc4d49";

export default node;
