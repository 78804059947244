/**
 * @generated SignedSource<<54f8ce7994a6742a76f49c14df7c058d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation$variables = {
  organizationId: string;
};
export type EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation$data = {
  readonly generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl: string;
};
export type EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation = {
  response: EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation$data;
  variables: EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7ce74ca65eb4378ce4c69ffd7acf3797",
    "id": null,
    "metadata": {},
    "name": "EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation",
    "operationKind": "mutation",
    "text": "mutation EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation(\n  $organizationId: OrganizationId!\n) {\n  generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl(organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "df8276eb1088e8c68bd68453e7d8ff29";

export default node;
