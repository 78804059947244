/**
 * @generated SignedSource<<81be0d6466e22f30596fdca27aa3d3c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type DraftWatchoutKey = "CUSTOM_BOARD_CONSENT_EXHIBIT_CONTENT" | "EARLY_EXERCISE_IS_ALLOWED" | "EXERCISE_PRICE_SHOULD_BE_EQUAL_TO_INSTRUMENT_VALUATION" | "GRANTED_TO_CORPORATION" | "HAS_ACCELERATION_CLAUSE" | "MISSING_CARTA_UPLOAD_FILE_SCOPE" | "MISSING_EQUITY_PLAN_DOCUMENT" | "MISSING_EQUITY_SUB_PLAN_DOCUMENT" | "MISSING_INSTRUMENT_VALUATION" | "MISSING_VESTING_SCHEDULE_IN_CTMS" | "NO_SUBSIDIARY_FOR_GRANTEE_COUNTRY" | "PTEP_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_PTEP" | "PULLEY_FIRST_GRANT" | "VESTING_SCHEDULE_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_VESTING_SCHEDULE" | "VESTING_START_DATE_SHOULD_BE_EQUAL_TO_CONTRACT_START_DATE_FOR_INITIAL_GRANTS" | "VESTING_START_DATE_SHOULD_DIFFER_FROM_CONTRACT_START_DATE_FOR_REFRESHER_GRANTS";
export type DraftWatchoutType = "INFORMATION" | "WARNING";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type DraftWatchoutTag_Deferred_EasopGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly equityTypeName: string | null;
  readonly grantee: {
    readonly contractStartDate: string;
    readonly defaultPostTerminationExercisePeriod: {
      readonly displayName: string;
    } | null;
    readonly defaultVestingSchedule: {
      readonly name: string;
    } | null;
    readonly equityGridLevel: {
      readonly equityInUsd: number;
      readonly name: string;
    } | null;
    readonly jobTitle: string | null;
    readonly taxResidenceCountry: {
      readonly name: string;
    } | null;
    readonly totalGrantedSharesBreakdown: {
      readonly total: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useGranteePositionToEquityGridLevel_Grantee">;
  };
  readonly instrument: {
    readonly valuation: {
      readonly type: ValuationType;
    };
  };
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  };
  readonly quantityGranted: number;
  readonly vestingSchedule: {
    readonly name: string;
  };
  readonly vestingStartDate: string;
  readonly watchouts: ReadonlyArray<{
    readonly key: DraftWatchoutKey;
    readonly type: DraftWatchoutType;
  }>;
  readonly " $fragmentType": "DraftWatchoutTag_Deferred_EasopGrant";
};
export type DraftWatchoutTag_Deferred_EasopGrant$key = {
  readonly " $data"?: DraftWatchoutTag_Deferred_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftWatchoutTag_Deferred_EasopGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftWatchoutTag_Deferred_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DraftWatchout",
      "kind": "LinkedField",
      "name": "watchouts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useGranteePositionToEquityGridLevel_Grantee"
        },
        {
          "kind": "RequiredField",
          "field": {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contractStartDate",
            "storageKey": null
          },
          "action": "THROW"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "totalGrantedSharesBreakdown",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "total",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityGridLevel",
          "kind": "LinkedField",
          "name": "equityGridLevel",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "equityInUsd",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "defaultVestingSchedule",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "defaultPostTerminationExercisePeriod",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "concreteType": "InstrumentValuation",
          "kind": "LinkedField",
          "name": "valuation",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "c1bf3af5903cfcebbafa60dcaa8d8b5b";

export default node;
