import { Outlet } from "react-router-dom";

import { ModifyCTMSGrantContextProvider } from "./Context";

const Layout: React.FC = () => {
  return (
    <ModifyCTMSGrantContextProvider ctmsGrantAmendmentRequestFragment={null}>
      <Outlet />
    </ModifyCTMSGrantContextProvider>
  );
};

export default Layout;
