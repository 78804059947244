import { Portal } from "@headlessui/react";
import { nanoid } from "nanoid";
import React, { useCallback, useContext, useMemo, useState } from "react";

export type AlertElement = React.ReactElement<AlertElementProps>;

export type AlertElementProps = {
  buttonLabel?: React.ReactNode;
  isShown?: boolean;
  onCloseButtonClick?: () => void;
  title: React.ReactNode;
};

interface Alert {
  element: AlertElement;
  id: string;
  isShown: boolean;
}

type AlertPusher = (render: Alert["element"]) => void;

const AlerterContext = React.createContext<null | {
  push: AlertPusher;
}>(null);

export const AlerterProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<Alert[]>([]);

  const flush = useCallback(
    (id: string) => () => {
      setState((previousState) =>
        previousState.filter((alert) => alert.id !== id),
      );
    },
    [],
  );

  const hide = useCallback(
    (id: string) => () => {
      setState((previousState) => {
        setTimeout(flush(id), 300);

        return previousState.map((alert) =>
          alert.id === id ? { ...alert, isShown: false } : alert,
        );
      });
    },
    [flush],
  );

  const push: AlertPusher = useCallback((element) => {
    setState((previousState) => {
      const id = nanoid();

      return [...previousState, { element, id, isShown: true }];
    });
  }, []);

  const value = useMemo(() => ({ push }), [push]);

  const renderedAlerts = useMemo(
    () =>
      state.reduce((alerts: React.ReactElement[], alert) => {
        const element = React.cloneElement(alert.element, {
          ...alert.element.props,
          isShown: alert.isShown,
          onCloseButtonClick: () => {
            alert.element.props.onCloseButtonClick?.();
            return hide(alert.id)();
          },
        });

        return [...alerts, element];
      }, []),
    [hide, state],
  );

  return (
    <>
      <Portal>{renderedAlerts[0]}</Portal>
      <AlerterContext.Provider value={value}>
        {children}
      </AlerterContext.Provider>
    </>
  );
};

export const useAlerter = () => {
  const context = useContext(AlerterContext);

  if (!context) {
    throw new Error("can't get alerter context");
  }

  return context;
};
