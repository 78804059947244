/**
 * @generated SignedSource<<fdb785a3cc08bf81ce36f689beeb6335>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingOverview_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly isActive: boolean;
  readonly quantityIssued: number;
  readonly " $fragmentType": "VestingOverview_CTMSGrant";
};
export type VestingOverview_CTMSGrant$key = {
  readonly " $data"?: VestingOverview_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingOverview_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingOverview_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isActive",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "eb41e8a375efdcb875bdb44a4dbeb1f9";

export default node;
