import { graphql, useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { RepricedFromCtmsGrantTag_CtmsGrant$key } from "./__generated__/RepricedFromCtmsGrantTag_CtmsGrant.graphql";
import { Tag } from "./ui/Tag";

const GRANT_FRAGMENT = graphql`
  fragment RepricedFromCtmsGrantTag_CtmsGrant on CTMSGrant {
    id
    label
    organizationId
  }
`;

export const RepricedFromCtmsGrantTag: React.FC<{
  ctmsGrantFragment: RepricedFromCtmsGrantTag_CtmsGrant$key;
}> = ({ ctmsGrantFragment }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <Tag color="orange">
      <span>
        This grant was repriced from{" "}
        <Link
          className="font-medium text-primary"
          to={generatePath(APPLICATION_ROUTES["organizationEquityCtmsGrant"], {
            ctmsGrantId: ctmsGrant.id,
            organizationId: ctmsGrant.organizationId,
          })}
        >
          {ctmsGrant.label}
        </Link>
      </span>
    </Tag>
  );
};
