export const PulleyLogo: React.FC<React.ComponentProps<"svg">> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 41 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.173 18.425C34.8857 18.425 34.6148 18.4022 34.3601 18.3564C34.1055 18.314 33.8867 18.2569 33.7039 18.185L34.2328 16.4466C34.4678 16.5249 34.6801 16.5706 34.8694 16.5837C35.062 16.5967 35.2269 16.5657 35.364 16.4906C35.5044 16.4188 35.6121 16.2899 35.6872 16.1038L35.7803 15.8785L33.1064 8.08228H35.6138L36.9997 13.4495H37.078L38.4835 8.08228H41.0055L38.1701 16.3241C38.033 16.7355 37.8387 17.0979 37.5873 17.4113C37.3392 17.728 37.0176 17.9761 36.6226 18.1557C36.2308 18.3352 35.7476 18.425 35.173 18.425Z"
        fill="#346346"
      />
      <path
        d="M29.1166 15.746C28.3298 15.746 27.6507 15.591 27.0794 15.2808C26.5113 14.9674 26.0738 14.5218 25.767 13.9439C25.4633 13.3628 25.3115 12.6723 25.3115 11.8724C25.3115 11.0954 25.465 10.4163 25.7719 9.83521C26.0787 9.25082 26.5113 8.79702 27.0696 8.47381C27.6279 8.14734 28.2857 7.9841 29.0431 7.9841C29.5786 7.9841 30.0683 8.06735 30.5123 8.23385C30.9563 8.40036 31.3399 8.64684 31.6631 8.97332C31.9863 9.29979 32.2377 9.70299 32.4173 10.1829C32.5968 10.6596 32.6866 11.2064 32.6866 11.8234V12.4209H26.1489V11.0301H30.4584C30.4551 10.7755 30.3947 10.5486 30.2772 10.3494C30.1597 10.1503 29.9981 9.99519 29.7924 9.88418C29.59 9.76992 29.3565 9.71278 29.0921 9.71278C28.8244 9.71278 28.5844 9.77318 28.3722 9.89398C28.16 10.0115 27.9919 10.1731 27.8678 10.3788C27.7438 10.5812 27.6785 10.8114 27.6719 11.0693V12.4846C27.6719 12.7914 27.7323 13.0608 27.8531 13.2926C27.9739 13.5211 28.1453 13.699 28.3673 13.8264C28.5893 13.9537 28.8538 14.0174 29.1607 14.0174C29.3729 14.0174 29.5655 13.988 29.7385 13.9292C29.9116 13.8704 30.0601 13.7839 30.1842 13.6697C30.3082 13.5554 30.4013 13.415 30.4633 13.2485L32.6621 13.3122C32.5707 13.8051 32.3699 14.2345 32.0598 14.6001C31.7529 14.9625 31.3497 15.2449 30.8502 15.4473C30.3507 15.6465 29.7728 15.746 29.1166 15.746Z"
        fill="#346346"
      />
      <path
        d="M24.0763 5.57498V15.6043H21.6816V5.57498H24.0763Z"
        fill="#346346"
      />
      <path
        d="M20.1579 5.57498V15.6043H17.7632V5.57498H20.1579Z"
        fill="#346346"
      />
      <path
        d="M13.8482 12.3575V8.08228H16.238V15.6043H13.9559V14.2037H13.8775C13.711 14.664 13.427 15.0297 13.0255 15.3006C12.6272 15.5683 12.1456 15.7022 11.5808 15.7022C11.0682 15.7022 10.6177 15.5847 10.2292 15.3496C9.84069 15.1145 9.5387 14.7864 9.32322 14.3653C9.10775 13.9409 8.99838 13.4446 8.99512 12.8766V8.08228H11.3898V12.4064C11.3931 12.8145 11.5008 13.1361 11.713 13.3712C11.9252 13.6062 12.2142 13.7238 12.5798 13.7238C12.8181 13.7238 13.032 13.6715 13.2213 13.5671C13.414 13.4593 13.5658 13.3042 13.6768 13.1018C13.791 12.8961 13.8482 12.648 13.8482 12.3575Z"
        fill="#346346"
      />
      <path
        d="M0 15.6043V5.57498H4.14297C4.89386 5.57498 5.54191 5.7219 6.08713 6.01572C6.6356 6.30629 7.05839 6.71275 7.35548 7.23511C7.65257 7.7542 7.80112 8.35818 7.80112 9.04704C7.80112 9.73917 7.64931 10.3448 7.34569 10.8639C7.04533 11.3797 6.61601 11.7796 6.05774 12.0637C5.49947 12.3477 4.83673 12.4897 4.06951 12.4897H1.51321V10.5798H3.61898C3.98463 10.5798 4.28988 10.5162 4.53474 10.3889C4.78286 10.2615 4.97058 10.0836 5.09791 9.85507C5.22523 9.62327 5.28889 9.35393 5.28889 9.04704C5.28889 8.73689 5.22523 8.46918 5.09791 8.24391C4.97058 8.01538 4.78286 7.83909 4.53474 7.71502C4.28662 7.59096 3.98136 7.52893 3.61898 7.52893H2.42408V15.6043H0Z"
        fill="#346346"
      />
    </svg>
  );
};
