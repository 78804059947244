import { sumBy } from "lodash";
import React, { useMemo } from "react";

export interface CircularProgressValue {
  color: string;
  id: string;
  progress: number;
}

interface CircularProgressType {
  indicatorCap?: "butt" | "inherit" | "round" | "square";
  indicatorWidth?: number;
  margin?: number;
  showTrack?: boolean;
  size?: number;
  trackColor?: string;
  trackWidth?: number;
  values: CircularProgressValue[];
}

export const CircularProgress: React.FC<CircularProgressType> = ({
  indicatorCap = "round",
  indicatorWidth = 10,
  margin = 0,
  showTrack = true,
  size = 150,
  trackColor = `#ddd`,
  trackWidth = 10,
  values: _values = [],
}) => {
  const valuesWithoutNullProgress = useMemo(
    () => _values.filter((value) => value.progress),
    [_values],
  );
  const values = valuesWithoutNullProgress;

  const center = size / 2;
  const radius =
    center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth);
  const cirlclePerimeter = 2 * Math.PI * radius;

  // Rounded ends are not properly aligned. They exceeds the tips
  const capOffset =
    indicatorCap === "square" || indicatorCap === "round" ? indicatorWidth : 0;

  const capOffsetAngle = (capOffset / cirlclePerimeter) * 360;
  const marginAngle = (margin / cirlclePerimeter) * 360;

  const circlePerimeterWithoutGaps =
    cirlclePerimeter - (capOffset + margin) * values.length;
  const totalAngleWithoutGaps =
    360 - (capOffsetAngle + marginAngle) * values.length;

  return (
    <div style={{ height: size, width: size }}>
      <svg className="-rotate-90" style={{ height: size, width: size }}>
        {showTrack ? (
          <circle
            cx={center}
            cy={center}
            fill="transparent"
            r={radius}
            stroke={trackColor}
            strokeWidth={trackWidth}
          />
        ) : null}

        {values.map((value, i) => {
          const prevValues = values.slice(0, i);
          const prevAccumulatedProgress = sumBy(
            prevValues,
            (value) => value.progress,
          );

          const rotate =
            totalAngleWithoutGaps * (prevAccumulatedProgress / 100) +
            i * (capOffsetAngle + marginAngle);

          const arcLength = (value.progress / 100) * circlePerimeterWithoutGaps;

          return (
            <circle
              className="origin-center"
              cx={center}
              cy={center}
              fill="transparent"
              key={value.id}
              r={radius}
              stroke={value.color}
              strokeDasharray={cirlclePerimeter}
              strokeDashoffset={cirlclePerimeter - arcLength}
              strokeLinecap={indicatorCap}
              strokeWidth={indicatorWidth}
              style={{ transform: `rotate(${rotate}deg)` }}
            />
          );
        })}
      </svg>
    </div>
  );
};
