/**
 * @generated SignedSource<<6eb6a557588851512bb1e3c3ee3a82e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type HRISProviderEmployeeField = "Email" | "JobTitle" | "Name" | "StartDate" | "TaxResidenceCountryCode" | "USStateOfResidenceCode" | "WorkRelationship";
import { FragmentRefs } from "relay-runtime";
export type UpdateInformationItem_HRISProviderEmployee$data = {
  readonly changesSinceLastAcknowledgement: ReadonlyArray<HRISProviderEmployeeField>;
  readonly grantee: {
    readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_UpdateContent_Grantee" | "MergeEmployeeModal_Grantee">;
  };
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_UpdateContent_HRISProviderEmployee" | "MergeEmployeeModal_HRISProviderEmployee">;
  readonly " $fragmentType": "UpdateInformationItem_HRISProviderEmployee";
};
export type UpdateInformationItem_HRISProviderEmployee$key = {
  readonly " $data"?: UpdateInformationItem_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateInformationItem_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateInformationItem_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changesSinceLastAcknowledgement",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CleanupGranteesItem_UpdateContent_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeModal_HRISProviderEmployee"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CleanupGranteesItem_UpdateContent_Grantee"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MergeEmployeeModal_Grantee"
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "fb4e42d98dc836bc231b286aeef5db16";

export default node;
