/**
 * @generated SignedSource<<56df9f8ec95cfc669f03012cddae8e4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectCartaIssuerBlock_Organization$data = {
  readonly id: string;
  readonly selectedCartaIssuerId: string | null;
  readonly " $fragmentType": "SelectCartaIssuerBlock_Organization";
};
export type SelectCartaIssuerBlock_Organization$key = {
  readonly " $data"?: SelectCartaIssuerBlock_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectCartaIssuerBlock_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectCartaIssuerBlock_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selectedCartaIssuerId",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "8611fd6c52910cb27d3a7491efa4e9a9";

export default node;
