/**
 * @generated SignedSource<<be652e46be9d9bc3bc9c829b7e5f6b73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteePortalProjectionScenarioKey = "CURRENT_VALUE" | "FIRST_ROUND" | "SECOND_ROUND" | "THIRD_ROUND";
import { FragmentRefs } from "relay-runtime";
export type ProjectionTable_GranteePortalProjectionScenarios$data = ReadonlyArray<{
  readonly additionalInformation: string | null;
  readonly displayed: boolean;
  readonly key: GranteePortalProjectionScenarioKey;
  readonly multiple: number;
  readonly name: string;
  readonly " $fragmentType": "ProjectionTable_GranteePortalProjectionScenarios";
}>;
export type ProjectionTable_GranteePortalProjectionScenarios$key = ReadonlyArray<{
  readonly " $data"?: ProjectionTable_GranteePortalProjectionScenarios$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProjectionTable_GranteePortalProjectionScenarios">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ProjectionTable_GranteePortalProjectionScenarios",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multiple",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformation",
      "storageKey": null
    }
  ],
  "type": "GranteePortalProjectionScenario",
  "abstractKey": null
};

(node as any).hash = "48230966ace461212a451bb763ca87c4";

export default node;
