/**
 * @generated SignedSource<<66936c0f5cefa8c96d61c9cdc84faf9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation$variables = Record<PropertyKey, never>;
export type OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation$data = {
  readonly triggerOngoingObligationsNotifications: any | null;
};
export type OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation = {
  response: OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation$data;
  variables: OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "triggerOngoingObligationsNotifications",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "a1f1caeb250dfe067650502916fd24c8",
    "id": null,
    "metadata": {},
    "name": "OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation",
    "operationKind": "mutation",
    "text": "mutation OngoingObligationsPage_TriggerOngoingObligationsNotifications_Mutation {\n  triggerOngoingObligationsNotifications\n}\n"
  }
};
})();

(node as any).hash = "8dabb554b52c5e46a1ce53ada6dee4bb";

export default node;
