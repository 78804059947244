/**
 * @generated SignedSource<<5fb63b6d15c8e340468fb7538ac32c0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly pendingApprovalGrantBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
    readonly grantCount: number;
    readonly grants: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_EasopGrant">;
    }>;
    readonly id: string;
    readonly signatureRequests: ReadonlyArray<{
      readonly completedAt: string | null;
      readonly id: string;
      readonly signatoryFirstName: string;
      readonly signatoryLastName: string;
    }>;
    readonly totalGrantedShares: number;
    readonly voidedReason: BoardConsentVoidedReason | null;
    readonly " $fragmentSpreads": FragmentRefs<"SendReminderButton_BoardConsent">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_Organization" | "LargeOneColumnLayout_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "PendingApproval_Organization";
};
export type PendingApproval_Organization$key = {
  readonly " $data"?: PendingApproval_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "PendingApproval_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GrantBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalGrantBoardConsents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EasopGrant",
          "kind": "LinkedField",
          "name": "grants",
          "plural": true,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "organizationId",
                  "variableName": "organizationId"
                }
              ],
              "kind": "FragmentSpread",
              "name": "GrantsTable_EasopGrant"
            }
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalGrantedShares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryLastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voidedReason",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SendReminderButton_BoardConsent"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTable_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "56795321cbce0761b818f7523773b926";

export default node;
