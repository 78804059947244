import React from "react";

import { AlertElementProps } from "../Alerter";
import { Alert } from "../ui/Alert";

export const GranteeIsMissingRequiredInformationAlert: React.FC<
  Omit<AlertElementProps, "children" | "title">
> = ({ ...props }) => {
  return (
    <Alert title="" {...props}>
      Grantee is missing required information
    </Alert>
  );
};
