import axios from "axios";

import CONFIGURATION from "./configuration";

const triggerWebhook = async ({
  params,
  url,
}: {
  params?: unknown;
  url: string;
}) => {
  await axios({ method: "GET", params, url });
};

const triggerUserStartedSafeSimulator = async ({
  email,
}: {
  email: string;
}) => {
  if (!CONFIGURATION.VITE_ZAPIER_USER_STARTED_SAFE_SIMULATOR_URL) return;
  await triggerWebhook({
    params: {
      email,
    },
    url: CONFIGURATION.VITE_ZAPIER_USER_STARTED_SAFE_SIMULATOR_URL,
  });
};

export const ZapierService = {
  triggerUserStartedSafeSimulator,
};
