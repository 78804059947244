/**
 * @generated SignedSource<<2e8e219dd4f2ad2125e5011d164225b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestNotifySection_Organization$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_Organization">;
  readonly " $fragmentType": "ExerciseRequestNotifySection_Organization";
};
export type ExerciseRequestNotifySection_Organization$key = {
  readonly " $data"?: ExerciseRequestNotifySection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestNotifySection_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ab942eb1743eaf93c628e4d527e0453e";

export default node;
