import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useFragment } from "react-relay";
import { generatePath, NavLink } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Typography } from "../../../../components/ui/Typography";
import { APPLICATION_ROUTES, useBoardMemberIdParam } from "../../../../paths";
import { ApprovalGranteeEquityGridLevelTag_EasopGrant$key } from "./__generated__/ApprovalGranteeEquityGridLevelTag_EasopGrant.graphql";

const LinkToBoardMemberEquityGrid: React.FC = () => {
  const boardMemberId = useBoardMemberIdParam();
  return (
    <NavLink
      target="_blank"
      to={generatePath(APPLICATION_ROUTES["boardMemberEquityGrid"], {
        boardMemberId,
      })}
    >
      <Typography
        as="div"
        className="flex items-center gap-1 text-primary"
        variant="Medium/Caption"
      >
        <span>Equity grid</span>
        <ArrowTopRightOnSquareIcon className="h-3 w-3" />
      </Typography>
    </NavLink>
  );
};

const GRANT_FRAGMENT = graphql`
  fragment ApprovalGranteeEquityGridLevelTag_EasopGrant on EasopGrant {
    quantityGranted
    grantee {
      equityGridLevel {
        name
      }
      offGrid
      name
      missingSharesToEquityGridLevel
    }
  }
`;

export const ApprovalGranteeEquityGridLevelTag: React.FC<{
  className?: string;
  grantFragment: ApprovalGranteeEquityGridLevelTag_EasopGrant$key;
}> = ({ className, grantFragment }) => {
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);
  const grantee = grant.grantee;

  const granteeEquityGridLevel = grant.grantee.equityGridLevel;

  const missingSharesToEquityGridLevelAfterGrant =
    grantee.missingSharesToEquityGridLevel !== null
      ? grantee.missingSharesToEquityGridLevel - grant.quantityGranted
      : null;

  if (grantee.offGrid) {
    return (
      <Typography
        as="div"
        className={classNames(
          "flex items-center gap-2 rounded-lg bg-primary-01 px-4 py-2",
          className,
        )}
        variant="Regular/Caption"
      >
        <span>📌</span>
        <span>
          {grantee.name} is marked as off grid.&nbsp;
          <LinkToBoardMemberEquityGrid />
        </span>
      </Typography>
    );
  }

  if (!granteeEquityGridLevel) {
    return (
      <Typography
        as="div"
        className={classNames(
          "flex gap-2 rounded-lg bg-gray-02 px-4 py-2",
          className,
        )}
        variant="Regular/Caption"
      >
        <span>ℹ️</span>
        <span>
          {grantee.name} has no equity grid level.&nbsp;
          <LinkToBoardMemberEquityGrid />
        </span>
      </Typography>
    );
  }

  if (missingSharesToEquityGridLevelAfterGrant === null) {
    return (
      <Typography
        as="div"
        className={classNames(
          "flex gap-2 rounded-lg bg-green-02 px-4 py-2",
          className,
        )}
        variant="Regular/Caption"
      >
        <span>😬</span>
        <span>
          We are missing some information to determine if {grantee.name} is on
          the equity grid level.
        </span>
      </Typography>
    );
  }

  if (missingSharesToEquityGridLevelAfterGrant < 0) {
    return (
      <Typography
        as="div"
        className={classNames(
          "flex gap-2 rounded-lg bg-green-02 px-4 py-2",
          className,
        )}
        variant="Regular/Caption"
      >
        <span>🚀</span>
        <span>
          Above equity grid level..&nbsp;
          <LinkToBoardMemberEquityGrid />
        </span>
      </Typography>
    );
  }

  if (missingSharesToEquityGridLevelAfterGrant > 0) {
    return (
      <Typography
        as="div"
        className={classNames(
          "flex gap-2 rounded-lg bg-orange-02 px-4 py-2",
          className,
        )}
        variant="Regular/Caption"
      >
        <span>👇</span>
        <span>
          Below equity grid level.&nbsp;
          <LinkToBoardMemberEquityGrid />
        </span>
      </Typography>
    );
  }

  return (
    <Typography
      as="div"
      className={classNames(
        "flex gap-2 rounded-lg bg-green-02 px-4 py-2",
        className,
      )}
      variant="Regular/Caption"
    >
      <span>👌</span>
      <span>
        Right on equity grid level.&nbsp;
        <LinkToBoardMemberEquityGrid />
      </span>
    </Typography>
  );
};
