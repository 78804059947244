import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { HRISProviderEmployeeField } from "../hooks/__generated__/useMergeGrantee_MergeGranteeMutation.graphql";
import { useMergeGrantee } from "../hooks/useMergeGrantee";
import { MergeEmployeeModal_Grantee$key } from "./__generated__/MergeEmployeeModal_Grantee.graphql";
import { MergeEmployeeModal_HRISProviderEmployee$key } from "./__generated__/MergeEmployeeModal_HRISProviderEmployee.graphql";
import {
  MergeEmployeeTable,
  useMergeEmployeeTableState,
} from "./MergeEmployeeTable";
import { Modal } from "./ui/Modal";

const GRANTEE_FRAGMENT = graphql`
  fragment MergeEmployeeModal_Grantee on Grantee {
    ...useMergeGrantee_Grantee
    ...MergeEmployeeTable_Grantee
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment MergeEmployeeModal_HRISProviderEmployee on HRISProviderEmployee {
    ...useMergeGrantee_HRISProviderEmployee
    ...MergeEmployeeTable_HRISProviderEmployee
  }
`;

const MergeEmployeeModalContent: React.FC<{
  employeeFragment: MergeEmployeeModal_HRISProviderEmployee$key;
  fieldsToDisplay?: HRISProviderEmployeeField[];
  granteeFragment: MergeEmployeeModal_Grantee$key;
  onClose: () => void;
  onMerge: () => void;
}> = ({
  employeeFragment,
  fieldsToDisplay,
  granteeFragment,
  onClose,
  onMerge,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const {
    selectedFieldsList: mergeEmployeeTableStateSelectedFieldsList,
    setState: setMergeEmployeeTableState,
    state: mergeEmployeeTableState,
  } = useMergeEmployeeTableState({
    granteeFragment: grantee,
  });

  const [_mergeGrantee, mergeGranteeIsInFlight] = useMergeGrantee({
    employeeFragment: employee,
    granteeFragment: grantee,
  });

  const mergeGrantee = async () => {
    await _mergeGrantee(mergeEmployeeTableStateSelectedFieldsList);
    onMerge();
    onClose();
  };

  return (
    <Modal.Content
      actionsInHeader={
        <>
          <Modal.ActionButton onClick={onClose} variant="Secondary Full">
            Cancel
          </Modal.ActionButton>
          <Modal.ActionButton
            loading={mergeGranteeIsInFlight}
            onClick={mergeGrantee}
          >
            Merge grantee
          </Modal.ActionButton>
        </>
      }
      onClose={onClose}
      subTitle="Select the most relevant data to reconcile"
      title="Merge grantee"
    >
      <MergeEmployeeTable
        employeeFragment={employee}
        fieldsToDisplay={fieldsToDisplay}
        granteeFragment={grantee}
        onChange={setMergeEmployeeTableState}
        state={mergeEmployeeTableState}
      />
    </Modal.Content>
  );
};

export const MergeEmployeeModal: React.FC<{
  employeeFragment: MergeEmployeeModal_HRISProviderEmployee$key;
  fieldsToDisplay?: HRISProviderEmployeeField[];
  granteeFragment: MergeEmployeeModal_Grantee$key;
  onClose: () => void;
  onMerge: () => void;
  show: boolean;
}> = ({
  employeeFragment,
  fieldsToDisplay,
  granteeFragment,
  onClose,
  onMerge,
  show,
}) => {
  return (
    <Modal onClose={onClose} show={show} suspense width={984}>
      <MergeEmployeeModalContent
        employeeFragment={employeeFragment}
        fieldsToDisplay={fieldsToDisplay}
        granteeFragment={granteeFragment}
        onClose={onClose}
        onMerge={onMerge}
      />
    </Modal>
  );
};
