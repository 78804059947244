/**
 * @generated SignedSource<<55b00f2e75c844f031189e527a283575>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePotentialValue_Grantee$data = {
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"useDisplayFullyDilutedValuesInGranteePortal_Grantee" | "useGranteePortalGranteeShares_Grantee">;
  readonly " $fragmentType": "GranteePotentialValue_Grantee";
};
export type GranteePotentialValue_Grantee$key = {
  readonly " $data"?: GranteePotentialValue_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePotentialValue_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePotentialValue_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationSharesUtil_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDisplayFullyDilutedValuesInGranteePortal_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteePortalGranteeShares_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "e9922e706556d8296a980604736103d0";

export default node;
