/**
 * @generated SignedSource<<681b6ab1f6edbaa9ba8f90b636526733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGranteeGrantIndex_Grantee$data = {
  readonly drafts: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly grants: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "useGranteeGrantIndex_Grantee";
};
export type useGranteeGrantIndex_Grantee$key = {
  readonly " $data"?: useGranteeGrantIndex_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGranteeGrantIndex_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGranteeGrantIndex_Grantee",
  "selections": [
    {
      "alias": "grants",
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "drafts",
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "dcba08da8fbd3a47a4303eb8755c1c33";

export default node;
