import { Suspense, useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant$key } from "./__generated__/DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant.graphql";
import { DraftGrantVestingScheduleWarningMessage_EasopGrant$key } from "./__generated__/DraftGrantVestingScheduleWarningMessage_EasopGrant.graphql";
import {
  VestingStartDateDiffersFromContractStartDateWatchout,
  VestingStartDateEqualsContractStartDateWatchout,
} from "./DraftWatchoutTag";
import { LoadingSpinner } from "./ui/LoadingSpinner";

const DEFERRED_EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant on EasopGrant {
    watchouts {
      key
      type
    }
  }
`;

const DeferredDraftGrantVestingScheduleWarningMessage: React.FC<{
  easopGrantFragment: DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant$key;
}> = ({ easopGrantFragment }) => {
  const easopGrant = useFragment(
    DEFERRED_EASOP_GRANT_FRAGMENT,
    easopGrantFragment,
  );
  const watchoutForRefresherGrant = useMemo(
    () =>
      easopGrant.watchouts.find(
        (watchout) =>
          watchout.key ===
          "VESTING_START_DATE_SHOULD_DIFFER_FROM_CONTRACT_START_DATE_FOR_REFRESHER_GRANTS",
      ),
    [easopGrant.watchouts],
  );
  const watchoutForInitialGrant = useMemo(
    () =>
      easopGrant.watchouts.find(
        (watchout) =>
          watchout.key ===
          "VESTING_START_DATE_SHOULD_BE_EQUAL_TO_CONTRACT_START_DATE_FOR_INITIAL_GRANTS",
      ),
    [easopGrant.watchouts],
  );

  if (watchoutForRefresherGrant) {
    return (
      <VestingStartDateEqualsContractStartDateWatchout
        className="mr-auto"
        hasColor={true}
        watchOutType={watchoutForRefresherGrant.type}
      />
    );
  }
  if (watchoutForInitialGrant) {
    return (
      <VestingStartDateDiffersFromContractStartDateWatchout
        className="mr-auto"
        hasColor={true}
        watchOutType={watchoutForInitialGrant.type}
      />
    );
  }
  return null;
};

const EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantVestingScheduleWarningMessage_EasopGrant on EasopGrant {
    ...DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant @defer
  }
`;

export const DraftGrantVestingScheduleWarningMessage: React.FC<{
  easopGrantFragment: DraftGrantVestingScheduleWarningMessage_EasopGrant$key;
}> = ({ easopGrantFragment }) => {
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);
  return (
    <Suspense fallback={<LoadingSpinner className="h-4 w-4" />}>
      <DeferredDraftGrantVestingScheduleWarningMessage
        easopGrantFragment={easopGrant}
      />
    </Suspense>
  );
};
