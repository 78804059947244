/**
 * @generated SignedSource<<f57434ec2a3952fcdb5ff119268f4e38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CannotBeInvitedReason = "ALREADY_INVITED" | "MISSING_INFORMATION" | "NO_GRANTS" | "REMOTE_EMPLOYEE" | "SETTLED";
import { FragmentRefs } from "relay-runtime";
export type PreviewGranteesPortalModal_PreviewGranteeRow_Grantees$data = {
  readonly cannotBeInvitedReason: CannotBeInvitedReason | null;
  readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
  readonly hasPortalAccess: boolean;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"PortalAccessTag_Grantee">;
  readonly " $fragmentType": "PreviewGranteesPortalModal_PreviewGranteeRow_Grantees";
};
export type PreviewGranteesPortalModal_PreviewGranteeRow_Grantees$key = {
  readonly " $data"?: PreviewGranteesPortalModal_PreviewGranteeRow_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreviewGranteesPortalModal_PreviewGranteeRow_Grantees">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviewGranteesPortalModal_PreviewGranteeRow_Grantees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPortalAccess",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cannotBeInvitedReason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PortalAccessTag_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "e79b87b5f401c8ad74c26603655acee3";

export default node;
