import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  getOriginalLastDayToExercise,
  GrantAmendmentRequest,
  useAmendedLastDayToExercise,
} from "../../services/grantAmendmentRequest";
import { LongDate } from "../LongDate";
import { StateUpdateRow } from "../ui/StateUpdateRow";
import { Typography } from "../ui/Typography";
import { GrantAmendmentRequestBoxPTEP_CtmsGrant$key } from "./__generated__/GrantAmendmentRequestBoxPTEP_CtmsGrant.graphql";
import { GrantAmendmentRequestBoxPTEP_Organization$key } from "./__generated__/GrantAmendmentRequestBoxPTEP_Organization.graphql";
import { GrantAmendmentRequestBoxPTEPSlideOverRemote } from "./GrantAmendmentRequestBoxPTEPSlide";

const GRANT_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBoxPTEP_CtmsGrant on CTMSGrant {
    isVirtual
    activePostTerminationExercisePeriod {
      value {
        duration
        unit
      }
    }
    postTerminationExercisePeriod {
      id
    }
    id
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...GrantAmendmentRequestBoxPTEPSlide_CtmsGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBoxPTEP_Organization on Organization {
    id
    postTerminationExercisePeriods {
      id
      displayName
    }
    ...grantAmendmentRequest_useAmendedLastDayToExercise_Organization
    ...GrantAmendmentRequestBoxPTEPSlide_Organization
  }
`;

export const GrantAmendmentRequestBoxPTEP: React.FC<{
  ctmsGrantFragment: GrantAmendmentRequestBoxPTEP_CtmsGrant$key;
  grantAmendment: GrantAmendmentRequest | null;
  onChange: (grantAmendment: GrantAmendmentRequest) => void;
  organizationFragment: GrantAmendmentRequestBoxPTEP_Organization$key;
  readOnly?: boolean;
  terminationDate: string;
}> = ({
  ctmsGrantFragment,
  grantAmendment,
  onChange,
  organizationFragment,
  readOnly,
  terminationDate,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  const grantAmendmentRequestBoxPTEPSlideOverController =
    GrantAmendmentRequestBoxPTEPSlideOverRemote.useController();

  const granteeTerminationDate = new Date(terminationDate);

  const originalPostTerminationExercisePeriod =
    ctmsGrant.activePostTerminationExercisePeriod?.value ?? null;

  const originalLastDayToExercise = getOriginalLastDayToExercise({
    granteeTerminationDate,
    originalPostTerminationExercisePeriod,
  });

  const { amendedLastDayToExercise } = useAmendedLastDayToExercise({
    grantAmendment,
    granteeTerminationDate,
    organizationFragment: organization,
  });

  const amendedPTEP = useMemo(
    () =>
      organization.postTerminationExercisePeriods.find(
        (ptep) => ptep.id === grantAmendment?.postTerminationExercisePeriodId,
      ),
    [organization, grantAmendment?.postTerminationExercisePeriodId],
  );

  return (
    <div className="flex flex-col gap-4 border-t-[0.5px] border-gray-04 p-6">
      <div className="flex items-center justify-between">
        <Typography as="div" variant="Regular/Extra Small">
          {ctmsGrant.isVirtual
            ? "Post-termination settlement period"
            : "Post-termination exercise period"}
        </Typography>
        <Typography as="div" variant="Regular/Extra Small">
          {amendedPTEP?.displayName ??
            (originalPostTerminationExercisePeriod ? (
              <FormattedNumber
                style="unit"
                unit={originalPostTerminationExercisePeriod.unit}
                unitDisplay="long"
                value={originalPostTerminationExercisePeriod.duration}
              />
            ) : (
              "-"
            ))}
        </Typography>
      </div>

      <StateUpdateRow
        hasChanged={
          !!amendedLastDayToExercise &&
          originalLastDayToExercise?.valueOf() !==
            amendedLastDayToExercise.valueOf()
        }
        label="Last day to exercise is"
        newValue={
          amendedLastDayToExercise ? (
            <LongDate value={amendedLastDayToExercise} />
          ) : undefined
        }
        onModifyClick={() =>
          grantAmendmentRequestBoxPTEPSlideOverController.open({
            data: {
              amendedPtepId:
                grantAmendment?.postTerminationExercisePeriodId ?? null,
              grantFragment: ctmsGrant,
              onPTEPChange: (postTerminationExercisePeriodId) =>
                onChange({
                  ctmsGrantId: ctmsGrant.id,
                  postTerminationExercisePeriodId,
                }),
              organizationFragment: organization,
              originalPtepId:
                ctmsGrant.postTerminationExercisePeriod?.id ?? null,
              terminationDate: granteeTerminationDate,
            },
          })
        }
        readOnly={readOnly}
        value={
          originalLastDayToExercise ? (
            <LongDate value={originalLastDayToExercise} />
          ) : (
            "-"
          )
        }
      />
    </div>
  );
};
