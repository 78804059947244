import { isEmpty } from "lodash";
import { z } from "zod";

export const FORM_SCHEMA = z
  .object({
    displayedName: z.string().trim().min(1),
    documentId: z.string(),
    useDocumentAsOrganizationEquityPlan: z.boolean(),
    useDocumentAsOrganizationEquityTypeSubPlan: z.boolean(),
    useDocumentAsOrganizationSubPlanOnEquityTypeId: z.string().optional(),
  })
  .refine(
    ({
      useDocumentAsOrganizationEquityPlan,
      useDocumentAsOrganizationEquityTypeSubPlan,
    }) => {
      if (!useDocumentAsOrganizationEquityTypeSubPlan) return true;
      return !useDocumentAsOrganizationEquityPlan;
    },
    {
      message:
        "Cannot use document as both equity plan and equity type sub plan",
      path: ["useDocumentAsOrganizationEquityTypeSubPlan"],
    },
  )
  .refine(
    ({
      useDocumentAsOrganizationEquityTypeSubPlan,
      useDocumentAsOrganizationSubPlanOnEquityTypeId,
    }) => {
      if (!useDocumentAsOrganizationEquityTypeSubPlan) return true;
      return !isEmpty(useDocumentAsOrganizationSubPlanOnEquityTypeId);
    },
    {
      message:
        "Equity type is required when using document as equity type sub plan",
      path: ["useDocumentAsOrganizationSubPlanOnEquityTypeId"],
    },
  );

export type FormValues = z.infer<typeof FORM_SCHEMA>;
