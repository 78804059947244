/**
 * @generated SignedSource<<6c13e4fbdcaaf4c109bf381aee5505d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OngoingObligationsPage_Query$variables = Record<PropertyKey, never>;
export type OngoingObligationsPage_Query$data = {
  readonly equityTypes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsPage_EquityType">;
  }>;
  readonly ongoingObligations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsPage_OngoingObligation">;
  }>;
};
export type OngoingObligationsPage_Query = {
  response: OngoingObligationsPage_Query$data;
  variables: OngoingObligationsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "taxResidenceCountry",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OngoingObligationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OngoingObligation",
        "kind": "LinkedField",
        "name": "ongoingObligations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OngoingObligationsPage_OngoingObligation"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OngoingObligationsPage_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OngoingObligationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OngoingObligation",
        "kind": "LinkedField",
        "name": "ongoingObligations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "equityTypeWorkRelationshipCategories",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityType",
            "kind": "LinkedField",
            "name": "equityType",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "affectedOrganizations",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70d37156977382f267e454eeadb43571",
    "id": null,
    "metadata": {},
    "name": "OngoingObligationsPage_Query",
    "operationKind": "query",
    "text": "query OngoingObligationsPage_Query {\n  ongoingObligations {\n    ...OngoingObligationsPage_OngoingObligation\n    id\n  }\n  equityTypes {\n    ...OngoingObligationsPage_EquityType\n    id\n  }\n}\n\nfragment CreateOngoingObligationSlideOver_EquityType on EquityType {\n  id\n  name\n  taxResidenceCountry {\n    emoji\n    name\n  }\n}\n\nfragment OngoingObligationsPage_EquityType on EquityType {\n  ...CreateOngoingObligationSlideOver_EquityType\n}\n\nfragment OngoingObligationsPage_OngoingObligation on OngoingObligation {\n  ...OngoingObligationsTable_OngoingObligation\n}\n\nfragment OngoingObligationsTable_OngoingObligation on OngoingObligation {\n  id\n  label\n  date\n  equityTypeWorkRelationshipCategories\n  equityType {\n    name\n    taxResidenceCountry {\n      emoji\n      name\n    }\n    id\n  }\n  affectedOrganizations {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8ae1e924f65681c7992819ec7712c57";

export default node;
