import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EditableHtml_Viewer$key } from "./__generated__/EditableHtml_Viewer.graphql";
import Editor from "./Editor";
import { SafeHTML } from "./SafeHTML";

const EDITABLE_HTML_VIEWER_FRAGMENT = graphql`
  fragment EditableHtml_Viewer on Account {
    isSuperAdmin
  }
`;

const EditableHtml = ({
  accountFragment = null,
  className,
  isLoading,
  onSubmit = () => null,
  unsafeHtml,
}: {
  accountFragment?: EditableHtml_Viewer$key | null;
  className?: string;
  isLoading?: boolean;
  onSubmit?: (unsafeHtml: string) => void;
  unsafeHtml: string;
}) => {
  const viewer = useFragment(EDITABLE_HTML_VIEWER_FRAGMENT, accountFragment);

  return (
    <div className={className}>
      {viewer?.isSuperAdmin ? (
        <Editor
          content={unsafeHtml}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ) : (
        <SafeHTML>{unsafeHtml}</SafeHTML>
      )}
    </div>
  );
};

export default EditableHtml;
