/**
 * @generated SignedSource<<c713a003a560d3ecbeae71a7e8d2e9c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type Instrument_Grantee$data = {
  readonly instruments: ReadonlyArray<{
    readonly awardSuperType: AwardSuperType;
    readonly id: string;
    readonly legalWatchOuts: {
      readonly messageShouldBeDisplayedOnGrantFlow: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts">;
    } | null;
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"InstrumentLegalWatchOuts_Instrument" | "Instrument_InstrumentButton_Instrument">;
  }>;
  readonly isUSCitizen: boolean | null;
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly code: string;
    readonly emoji: string;
    readonly name: string;
  };
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "Instrument_Grantee";
};
export type Instrument_Grantee$key = {
  readonly " $data"?: Instrument_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"Instrument_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emoji",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Instrument_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "taxResidenceCountry",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUSCitizen",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "workRelationship",
        "storageKey": null
      },
      "action": "THROW"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": "TaxFavored"
        },
        {
          "kind": "Literal",
          "name": "sortDirection",
          "value": "DESC"
        }
      ],
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "awardSuperType",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
          "kind": "LinkedField",
          "name": "legalWatchOuts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "messageShouldBeDisplayedOnGrantFlow",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InstrumentLegalWatchOuts_EquityTypeWorkRelationshipCategoryLegalWatchOuts"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "InstrumentLegalWatchOuts_Instrument"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Instrument_InstrumentButton_Instrument"
        }
      ],
      "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "6eab2c74f488cb01af9ff9d750f0da0c";

export default node;
