import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { saveAs } from "file-saver";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../components/BackButton";
import { BoardConsentsDocumentsTable } from "../../../components/BoardConsentsDocumentsTable";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useTrackDocumentsDownloaded } from "../../../hooks/useAnalytics";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { BoardConsentsPage_Organization$key } from "./__generated__/BoardConsentsPage_Organization.graphql";
import { BoardConsentsPage_Query } from "./__generated__/BoardConsentsPage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardConsentsPage_Organization on Organization {
    id
    name
    boardConsents {
      completedAt
      signedDocument {
        downloadUrl
      }
    }
    ...BoardConsentsDocumentsTable_Organization
  }
`;

const AdminDocumentsBoardConsentsPage_: React.FC<{
  organizationFragment: BoardConsentsPage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const signedBoardConsentsCount = useMemo(
    () =>
      organization.boardConsents.filter(
        (boardConsent) => boardConsent.signedDocument,
      ).length,
    [organization.boardConsents],
  );

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();

  const handleDownloadBoardConsentButtonClick = useCallback(() => {
    trackDocumentsDownloaded({
      documentsTypes: ["BOARD_CONSENT"],
      downloadType: "UNIQUE",
    });
  }, [trackDocumentsDownloaded]);

  const handleDownloadAllBoardConsentsButtonClick = useCallback(() => {
    trackDocumentsDownloaded({
      documentsTypes: ["BOARD_CONSENT"],
      downloadType: "MULTIPLE",
    });
    organization.boardConsents.forEach((boardConsent) => {
      if (boardConsent.signedDocument && boardConsent.completedAt) {
        saveAs(boardConsent.signedDocument?.downloadUrl);
      }
    });
  }, [organization.boardConsents, trackDocumentsDownloaded]);

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Documents</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Board consents</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex-grow space-y-2">
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Default">Board consents</Typography>
              <Tag className="uppercase">
                <FormattedMessage
                  defaultMessage="{count, plural, one {# document} other {# documents}}"
                  values={{
                    count: signedBoardConsentsCount,
                  }}
                />
              </Tag>
            </div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              All board consent regarding past and current grants.
            </Typography>
          </div>
          <Button
            leftIcon={<ArrowDownTrayIcon />}
            onClick={handleDownloadAllBoardConsentsButtonClick}
            size="small"
            variant="Secondary Full"
          >
            Download all
          </Button>
        </div>
        <BoardConsentsDocumentsTable
          onDownloadBoardConsentButtonClick={
            handleDownloadBoardConsentButtonClick
          }
          organizationFragment={organization}
        />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query BoardConsentsPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...BoardConsentsPage_Organization
    }
  }
`;

const AdminDocumentsBoardConsentsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<BoardConsentsPage_Query>(
    QUERY,
    {
      organizationId,
    },
    { fetchPolicy: "store-and-network" },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Documents Consents"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} board consents`}
    >
      <AdminDocumentsBoardConsentsPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default AdminDocumentsBoardConsentsPage;
