/**
 * @generated SignedSource<<8bd03e4c51a76a78d33530ed2d175007>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditTerminationPlanningEntryResultFailureReason = "MISSING_INFORMATION";
export type CreateTerminationPlanningEntryAttributes = {
  granteeId: string;
  terminationDate: string;
  vestingEndDate?: string | null;
  waiveCliff: boolean;
};
export type TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation$variables = {
  attributes: CreateTerminationPlanningEntryAttributes;
  planningEntryId: any;
};
export type TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation$data = {
  readonly updateTerminationPlanningEntry: {
    readonly __typename: "EditTerminationPlanningEntryResultFailure";
    readonly reason: EditTerminationPlanningEntryResultFailureReason;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation = {
  response: TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation$data;
  variables: TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planningEntryId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "planningEntryId",
        "variableName": "planningEntryId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateTerminationPlanningEntry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          }
        ],
        "type": "EditTerminationPlanningEntryResultFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0db1ac1d375928aace956126ef6b8bbd",
    "id": null,
    "metadata": {},
    "name": "TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation TerminationPlanningEntryModal_UpdateTerminationPlanningEntry_Mutation(\n  $planningEntryId: PlanningEntryId!\n  $attributes: CreateTerminationPlanningEntryAttributes!\n) {\n  updateTerminationPlanningEntry(planningEntryId: $planningEntryId, attributes: $attributes) {\n    __typename\n    ... on EditTerminationPlanningEntryResultFailure {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "49c225a07218ab65ba2bfa4bfe1c725a";

export default node;
