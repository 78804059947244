import React from "react";
import { generatePath, Navigate, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { useToaster } from "../../../components/Toaster";
import { Toast } from "../../../components/ui/Toast";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useCTMSGrantAmendmentRequestIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { useModifyCTMSGrantContext } from "../CtmsGrant/Modify/Context";
import { NewEarlyExerciseAllowedOption } from "../CtmsGrant/Modify/NewEarlyExerciseAllowedSlider";
import { ReviewCTMSGrantModificationPageContent } from "../CtmsGrant/Modify/ReviewCTMSGrantModificationPageContent";
import { DetailsReviewModification_Query } from "./__generated__/DetailsReviewModification_Query.graphql";
import { DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation } from "./__generated__/DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation.graphql";

const QUERY = graphql`
  query DetailsReviewModification_Query(
    $organizationId: OrganizationId!
    $ctmsGrantAmendmentRequestId: UUID!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...ReviewCTMSGrantModificationPageContent_Organization
    }
    ctmsGrantAmendmentRequest(id: $ctmsGrantAmendmentRequestId) {
      id
      status
      ctmsGrant {
        ...ReviewCTMSGrantModificationPageContent_CTMSGrant
      }
    }
  }
`;

const UPDATE_AMENDMENT_MUTATION = graphql`
  mutation DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation(
    $ctmsGrantAmendmentRequestId: UUID!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    updateCTMSGrantAmendmentRequest(
      attributes: $attributes
      ctmsGrantAmendmentRequestId: $ctmsGrantAmendmentRequestId
    ) {
      status
    }
  }
`;

const REDIRECTION_ROUTE_FROM_STATUS = {
  IMPLEMENTATION: "organizationCTMSGrantAmendmentRequestsImplementation",
  IMPLEMENTED: "organizationCTMSGrantAmendmentRequestsImplementation",
  PENDING_APPROVAL: "organizationCTMSGrantAmendmentRequestsPendingApproval",
  READY_FOR_CONSENT: "organizationCTMSGrantAmendmentRequestsBoardApproval",
  UNDER_REVIEW: "organizationCTMSGrantAmendmentRequestsUnderReview",
} as const;

const DetailsReviewModification_: React.FC<{
  ctmsGrantAmendmentRequest: NonNullable<
    DetailsReviewModification_Query["response"]["ctmsGrantAmendmentRequest"]
  >;
  organization: NonNullable<
    DetailsReviewModification_Query["response"]["organization"]
  >;
}> = ({ ctmsGrantAmendmentRequest, organization }) => {
  const [updateCTMSGrantAmendmentRequest] =
    useSafeMutation<DetailsReviewModification_UpdateCTMSGrantAmendmentRequest_Mutation>(
      UPDATE_AMENDMENT_MUTATION,
    );

  const toaster = useToaster();
  const navigate = useNavigate();

  const context = useModifyCTMSGrantContext();
  const {
    newAccelerationAllowedValue,
    newEarlyExerciseAllowedValue,
    newOrganizationPostTerminationExercisePeriodId,
    newVestingScheduleId,
    newVestingStartDate,
    totalAccelerationDate,
    waiveCliff,
  } = context.state;

  const onConfirmModificationClicked = async () => {
    const {
      updateCTMSGrantAmendmentRequest: { status },
    } = await updateCTMSGrantAmendmentRequest({
      variables: {
        attributes: {
          accelerationClause: newAccelerationAllowedValue?.accelerationClause,
          accelerationClauseIsModified: Boolean(newAccelerationAllowedValue),
          earlyExercise:
            newEarlyExerciseAllowedValue?.earlyExercise ===
            NewEarlyExerciseAllowedOption.ALLOWED,
          earlyExerciseIsModified: Boolean(newEarlyExerciseAllowedValue),
          postTerminationExercisePeriodId:
            newOrganizationPostTerminationExercisePeriodId,
          postTerminationExercisePeriodIsModified: Boolean(
            newOrganizationPostTerminationExercisePeriodId,
          ),
          totalAccelerationDate,
          vestingScheduleId: newVestingScheduleId,
          vestingStartDate: newVestingStartDate,
          waiveCliff,
        },
        ctmsGrantAmendmentRequestId: ctmsGrantAmendmentRequest.id,
      },
    });

    toaster.push(
      <Toast title="Great!">
        Grant amendment request successfully updated!
      </Toast>,
    );

    void navigate(
      generatePath(APPLICATION_ROUTES[REDIRECTION_ROUTE_FROM_STATUS[status]], {
        organizationId: organization.id,
      }),
    );
  };

  return (
    <ReviewCTMSGrantModificationPageContent
      backLink={generatePath(
        APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequest,
        {
          ctmsGrantAmendmentRequestId: ctmsGrantAmendmentRequest.id,
          organizationId: organization.id,
        },
      )}
      ctmsGrantFragment={ctmsGrantAmendmentRequest.ctmsGrant}
      onConfirmModificationClicked={onConfirmModificationClicked}
      organizationFragment={organization}
    />
  );
};

const DetailsReviewModification: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantAmendmentRequestId = useCTMSGrantAmendmentRequestIdParam();

  const context = useModifyCTMSGrantContext();

  const {
    query: { ctmsGrantAmendmentRequest, organization },
  } = useQuery<DetailsReviewModification_Query>(QUERY, {
    ctmsGrantAmendmentRequestId,
    organizationId,
  });

  if (!organization || !ctmsGrantAmendmentRequest) {
    return <NotFoundPage />;
  }

  if (context.isEmpty) {
    return (
      <Navigate
        to={generatePath(
          APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequest,
          { ctmsGrantAmendmentRequestId, organizationId },
        )}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Grant amendment request modification review"
      organizationId={organization.id}
      title={`Admin | ${organization.name} review grant amendment request modification`}
    >
      <DetailsReviewModification_
        ctmsGrantAmendmentRequest={ctmsGrantAmendmentRequest}
        organization={organization}
      />
    </Page>
  );
};

export default DetailsReviewModification;
