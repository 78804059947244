import { useCallback } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import { ConnectHRISProvidersMessage } from "../../../components/ConnectHRISProvidersMessage";
import { GranteeForm, useGranteeForm } from "../../../components/GranteeForm";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { NewGrantee_Organization$key } from "./__generated__/NewGrantee_Organization.graphql";
import { NewGrantee_Query } from "./__generated__/NewGrantee_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewGrantee_Organization on Organization {
    id
    name
    isOriginatingFromRemoteEquity
    ...GranteeForm_Organization
    ...ConnectHRISProvidersMessage_Organization
  }
`;

export const NewGranteePage_: React.FC<{
  organizationFragment: NewGrantee_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const navigate = useNavigate();

  const { control, handleSubmit, isSubmitting, reset, setValue, watch } =
    useGranteeForm({
      organizationId: organization.id,
    });

  const onGranteeCreated = useCallback(
    (createdGranteeId: string) => {
      void navigate(
        generatePath(APPLICATION_ROUTES.organizationGrantee, {
          granteeId: createdGranteeId,
          organizationId: organization.id,
        }),
      );
    },
    [navigate, organization.id],
  );

  const onCancel = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationGrantees, {
        organizationId: organization.id,
      }),
    );
  }, [navigate, organization.id]);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationGrantees, {
              organizationId: organization.id,
            })}
          >
            Grantees
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">New Grantee</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-6 p-10" withBorder withShadow>
        <div className="space-y-4">
          <Typography as="div" className="" variant="Medium/Default">
            New grantee
          </Typography>
          {!organization.isOriginatingFromRemoteEquity && (
            <ConnectHRISProvidersMessage organizationFragment={organization} />
          )}
        </div>
        <GranteeForm
          control={control}
          formReset={reset}
          handleSubmit={handleSubmit}
          id="grantee-form"
          onCancel={onCancel}
          onGranteeCreated={onGranteeCreated}
          organizationFragment={organization}
          setValue={setValue}
          submissionInProgress={isSubmitting}
          watch={watch}
        />
      </RoundedBox>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query NewGrantee_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...NewGrantee_Organization
    }
  }
`;

const NewGranteePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const query = useLazyLoadQuery<NewGrantee_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - New Grantee"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} new grantee`}
    >
      <NewGranteePage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default NewGranteePage;
