import { Typography } from "../Typography";

export function FormRowContainer({
  children,
  title,
}: React.PropsWithChildren<{ title: React.ReactNode }>) {
  return (
    <div className="space-y-2">
      <Typography
        as="div"
        className="uppercase text-black-05"
        variant="Regular/Caption"
      >
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  );
}
