/**
 * @generated SignedSource<<baf1a04df408aab837f4fb596fdfa649>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CliffPeriods_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CliffPeriodsGraph_Organization">;
  readonly " $fragmentType": "CliffPeriods_Organization";
};
export type CliffPeriods_Organization$key = {
  readonly " $data"?: CliffPeriods_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CliffPeriods_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CliffPeriods_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CliffPeriodsGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "940043a3c99801a93e33bf515d0c90f1";

export default node;
