import { IconV2OutlineAlertTriangle } from "@remote-com/norma/icons/IconV2OutlineAlertTriangle";
import { IconV2OutlineTimesFull } from "@remote-com/norma/icons/IconV2OutlineTimesFull";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";

const NOTICE_CARD_VARIANT_VALUES = {
  error: {
    className: classNames("border-remote-red-400 bg-remote-red-50"),
    icon: <IconV2OutlineTimesFull className="h-5 w-5 text-remote-red-700" />,
  },
  warning: {
    className: classNames("border-remote-yellow-400 bg-remote-yellow-50"),
    icon: (
      <IconV2OutlineAlertTriangle className="h-5 w-5 text-remote-yellow-700" />
    ),
  },
};

const NoticeCard: React.FC<{
  description: React.ReactNode;
  label: React.ReactNode;
  variant: keyof typeof NOTICE_CARD_VARIANT_VALUES;
}> = ({ description, label, variant }) => {
  const { className, icon } = NOTICE_CARD_VARIANT_VALUES[variant];

  return (
    <div
      className={classNames(
        "flex gap-2 rounded-remote-md border border-remote-red-400 bg-remote-red-50 px-3 py-2",
        className,
      )}
    >
      <div className="py-0.5">{icon}</div>
      <div className="text-remote-grey-700">
        <div className="text-remote-SM/Medium">{label}</div>
        <div className="text-remote-XS/Normal">{description}</div>
      </div>
    </div>
  );
};

const ContactUsLink: React.FC<{
  mailtoSubject: string;
  organizationName: string;
}> = ({ mailtoSubject, organizationName }) => {
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject_ = encodeURI(`[${organizationName}] ${mailtoSubject}`);

  return (
    <Link
      className="text-remote-XS/Medium text-remote-brand-600"
      to={`mailto:${supportEmailAddress}?subject=${mailtoSubject_}`}
    >
      Contact us
    </Link>
  );
};

const useErrorLabel = ({
  error,
  organizationName,
}: {
  error: string;
  organizationName: string;
}) => {
  switch (error) {
    case "ACCESS_DENIED":
      return {
        description: (
          <>
            Please{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: Carta access denied"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "Carta access denied",
      };
    case "INSUFFICIENT_ACCESS_RIGHTS":
      return {
        description: (
          <>
            The Carta account you&apos;re trying to connect doesn&apos;t have
            the sufficient access rights. Please{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: Carta insufficient access rights"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "Insufficient Carta access rights",
      };
    case "ISSUER_ALREADY_CONNECTED":
      return {
        description: (
          <>
            This Carta account is already connected to another Remote Equity
            account. Please{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: Carta issuer already connected"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "Carta issuer already connected",
      };
    case "MULTIPLE_ISSUERS_SELECTED":
      return {
        description: (
          <>
            Please select only one issuer in Carta or{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: Multiple Carta issuers selected"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "Multiple Carta issuers selected",
      };
    case "NO_ISSUER_SELECTED":
      return {
        description: (
          <>
            Please try again and select an issuer in Carta or{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: No Carta issuer selected"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "No Carta issuer selected",
      };
    default:
      return {
        description: (
          <>
            Please{" "}
            <ContactUsLink
              mailtoSubject="Onboarding: Unknown Carta error"
              organizationName={organizationName}
            />{" "}
            for more details.
          </>
        ),
        title: "Unknown Carta error",
      };
  }
};

export const CartaErrorCard: React.FC<{
  error: string;
  organizationName: string;
}> = ({ error, organizationName }) => {
  const { description, title } = useErrorLabel({ error, organizationName });

  return <NoticeCard description={description} label={title} variant="error" />;
};

export const PricePerShareWarningCard: React.FC = () => {
  return (
    <NoticeCard
      description="Please enter the estimated price per share for your company."
      label="Couldn’t find price per share"
      variant="warning"
    />
  );
};
