import classNames from "classnames";
import { chain, sumBy } from "lodash";
import React, { useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  FullyRoundedBarShape,
  RoundedBarShape,
  RoundedBarShapeProps,
} from "./ui/RoundedBarShape";
import { Typography } from "./ui/Typography";

export const BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME = classNames(
  "bg-[rgba(242,243,245,0.50)] fill-[rgba(242,243,245,0.50)]",
);

export function BarGraph<
  TStackElementKey extends string,
  TStackElement extends {
    className: string;
    fullyRounded?: boolean;
    key: TStackElementKey;
    legendLabel?: null | string;
    value: number;
  },
  TStack extends {
    elements: readonly TStackElement[];
    label: string;
  },
>({
  barGap = 8,
  barRounded = true,
  barSize = 56,
  height = 400,
  hideXAxis,
  legendOptions,
  loading,
  onBackgroundClick,
  onStackElementClick,
  renderBackgroundTooltip,
  renderBarTopLabel,
  renderTooltip,
  showBackground,
  showLegend,
  stacks,
  yLabel,
  yTickFormatter,
}: {
  barGap?: number;
  barRounded?: boolean;
  barSize?: number;
  height?: number;
  hideXAxis?: boolean;
  legendOptions?: {
    fullWidth?: boolean;
  };
  loading?: boolean;
  onBackgroundClick?: (props: { stack: TStack }) => void;
  onStackElementClick?: (props: {
    stack: TStack;
    stackElement: TStack["elements"][number];
  }) => void;
  renderBackgroundTooltip?: (props: { stack: TStack }) => React.ReactNode;
  renderBarTopLabel?: (stack: TStack) => React.ReactNode;
  renderTooltip?: (props: {
    stack: TStack;
    stackElement: TStack["elements"][number];
  }) => React.ReactNode;
  showBackground?: boolean;
  showLegend?: boolean;
  stacks: readonly TStack[];
  yLabel?: React.ReactNode;
  yTickFormatter: (value: number) => string;
}) {
  const summedValues = useMemo(
    () => sumBy(stacks, ({ elements }) => sumBy(elements, (el) => el.value)),
    [stacks],
  );

  const data = useMemo(
    () =>
      chain(stacks)
        .map((stack) => ({
          background: showBackground
            ? summedValues - sumBy(stack.elements, (el) => el.value)
            : 0,
          ...chain(stack.elements)
            .keyBy((el) => el.key)
            .mapValues((el) => el.value)
            .value(),
          stack,
        }))
        .value(),
    [stacks, summedValues, showBackground],
  );

  const [chartWidth, setChartWidth] = React.useState<null | number>(null);

  const xAxisPadding = useMemo(() => {
    if (!chartWidth) return undefined;
    const padding = Math.round(
      (chartWidth - (barSize + barGap) * stacks.length + barGap) / 2,
    );
    if (padding < 0) return undefined;
    return { left: padding, right: padding };
  }, [chartWidth, barSize, barGap, stacks.length]);

  const bars = useMemo(
    () =>
      chain(stacks)
        .flatMap((stack) => {
          return stack.elements.map((stackElement, index) => {
            const isLastNonZeroElement = stack.elements
              .slice(index + 1)
              .every((el) => !el.value);
            return {
              shouldBeRounded: isLastNonZeroElement,
              stack,
              stackElement,
            };
          });
        })
        .value(),
    [stacks],
  );

  const [hoveredBar, setHoveredBar] = useState<(typeof bars)[number] | null>(
    null,
  );
  const [hoveredStack, setHoveredStack] = useState<null | TStack>(null);

  return (
    <>
      <ResponsiveContainer
        className={classNames("transition-all [&_svg]:overflow-y-visible", {
          "animate-pulse": loading,
        })}
        height={height}
        onResize={(width) => {
          setChartWidth(width);
        }}
        width="100%"
      >
        <BarChart
          barCategoryGap={barGap}
          barGap={barGap}
          barSize={barSize}
          data={data}
          margin={{ bottom: 16, top: 56 }}
        >
          <CartesianGrid strokeDasharray="2 4" vertical={false} />
          <XAxis
            axisLine={false}
            padding={xAxisPadding}
            tick={
              hideXAxis
                ? false
                : (props: { index: number }) => {
                    const stack = stacks[props.index];
                    return (
                      <Text
                        {...props}
                        className={classNames(
                          "font-captionRegular translate-y-4 transform fill-black-05",
                        )}
                        verticalAnchor="middle"
                        width={barGap + barSize - 8}
                      >
                        {stack?.label}
                      </Text>
                    );
                  }
            }
            tickLine={false}
          />
          <YAxis
            allowDecimals={false}
            axisLine={false}
            mirror={true}
            tick={(props: { payload: { value: number } }) => (
              <Text
                {...props}
                className="font-captionRegular -translate-x-2 -translate-y-4 transform fill-black-05"
              >
                {yTickFormatter(props.payload.value)}
              </Text>
            )}
            tickLine={false}
            type="number"
          >
            <Label
              className="font-mediumExtraSmall -translate-x-1 -translate-y-1/2 transform fill-black-07"
              position="insideLeft"
            >
              {yLabel}
            </Label>
          </YAxis>
          <Tooltip
            content={({ active }) => {
              if (!active) return null;

              if (hoveredBar && renderTooltip) {
                return renderTooltip({
                  stack: hoveredBar.stack,
                  stackElement: hoveredBar.stackElement,
                });
              }

              if (hoveredStack && renderBackgroundTooltip)
                return renderBackgroundTooltip({ stack: hoveredStack });

              return null;
            }}
            cursor={false}
            trigger="hover"
          />
          {bars.map((bar) => (
            <Bar
              activeBar={false}
              className={classNames(bar.stackElement.className, {
                "cursor-pointer": Boolean(onStackElementClick),
              })}
              dataKey={bar.stackElement.key}
              key={bar.stackElement.key}
              onClick={() =>
                onStackElementClick?.({
                  stack: bar.stack,
                  stackElement: bar.stackElement,
                })
              }
              onMouseEnter={() => {
                setHoveredBar(bar);
              }}
              onMouseLeave={() =>
                hoveredBar === bar ? setHoveredBar(null) : null
              }
              shape={
                bar.stackElement.fullyRounded
                  ? (props: unknown) => (
                      <FullyRoundedBarShape
                        {...(props as RoundedBarShapeProps)}
                      />
                    )
                  : barRounded && bar.shouldBeRounded
                    ? (props: unknown) => (
                        <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                      )
                    : undefined
              }
              stackId="stackId"
            />
          ))}
          <Bar
            activeBar={false}
            className={classNames(BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME, {
              "cursor-pointer": Boolean(onBackgroundClick),
            })}
            dataKey="background"
            key="background"
            label={
              renderBarTopLabel
                ? (props: { index: number }) => {
                    const stack = stacks[props.index];
                    return (
                      <foreignObject
                        {...props}
                        className="h-[1px] overflow-visible"
                      >
                        <div className="relative w-full">
                          <div
                            className="absolute bottom-0 left-[50%] w-[200px] -translate-x-1/2 text-center"
                            style={{ width: barGap + barSize }}
                          >
                            {stack ? renderBarTopLabel(stack) : null}
                          </div>
                        </div>
                      </foreignObject>
                    );
                  }
                : false
            }
            onClick={(data) => {
              const {
                payload: { stack },
              } = data as {
                payload: {
                  stack: TStack;
                };
              };
              onBackgroundClick?.({ stack });
            }}
            onMouseEnter={(data) => {
              const {
                payload: { stack },
              } = data as {
                payload: {
                  stack: TStack;
                };
              };
              setHoveredStack(stack);
            }}
            onMouseLeave={() => {
              setHoveredStack(null);
            }}
            shape={
              barRounded
                ? (props: unknown) => (
                    <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                  )
                : undefined
            }
            stackId="stackId"
          />
        </BarChart>
      </ResponsiveContainer>
      {showLegend && (
        <div className="flex flex-wrap justify-center gap-x-4 gap-y-2">
          {stacks.map((stack) =>
            stack.elements.map((el) =>
              el.legendLabel ? (
                <div
                  className={classNames("flex items-center gap-2", {
                    "flex-1": legendOptions?.fullWidth ?? true,
                  })}
                  key={el.key}
                >
                  <div
                    className={classNames(
                      el.className,
                      "h-[10px] w-[10px] shrink-0 rounded-sm",
                    )}
                  ></div>
                  <Typography
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    {el.legendLabel}
                  </Typography>
                </div>
              ) : null,
            ),
          )}
        </div>
      )}
    </>
  );
}
