import React from "react";

import { Typography } from "../../../components/ui/Typography";

export function NotificationCountBadge({ children }: React.PropsWithChildren) {
  return (
    <Typography
      as="div"
      className="rounded-full bg-red px-[5px] py-[0.5px] text-white"
      variant="Medium/Caption"
    >
      {children}
    </Typography>
  );
}
