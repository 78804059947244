import React from "react";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../paths";

const IndexPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  return (
    <Navigate
      to={{
        pathname: generatePath(
          APPLICATION_ROUTES["setupWorkspaceAccountCreation"],
          {},
        ),
        search: searchParams.toString(),
      }}
    />
  );
};

export default IndexPage;
