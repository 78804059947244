import React from "react";
import { generatePath } from "react-router-dom";

import { Step } from "../../components/ui/Step";
import { Typography } from "../../components/ui/Typography";
import { useGetAssistedGrantStepState } from "../../pages/Admin/AssistedGrant/Context";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../paths";

const Aside: React.FC<React.PropsWithChildren> = () => {
  const organizationId = useOrganizationIdParam();
  const getAssistedGrantStepState = useGetAssistedGrantStepState();

  return (
    <aside className="flex flex-col gap-6">
      <Typography as="div" variant="Medium/Default">
        Draft a new grant
      </Typography>

      <ul className="block">
        <Step
          forceOrder
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationAssistedGrantGrantee"],
            {
              organizationId,
            },
          )}
          number={1}
          state={getAssistedGrantStepState("organizationAssistedGrantGrantee")}
          subSteps={
            <>
              <Step
                forceOrder
                linkTo={generatePath(
                  APPLICATION_ROUTES["organizationAssistedGrantGranteeGrantee"],
                  {
                    organizationId,
                  },
                )}
                state={getAssistedGrantStepState(
                  "organizationAssistedGrantGranteeGrantee",
                )}
              >
                Choose a grantee
              </Step>
              <Step
                forceOrder
                linkTo={generatePath(
                  APPLICATION_ROUTES[
                    "organizationAssistedGrantGranteeInstrument"
                  ],
                  {
                    organizationId,
                  },
                )}
                state={getAssistedGrantStepState(
                  "organizationAssistedGrantGranteeInstrument",
                )}
              >
                Equity incentive
              </Step>
            </>
          }
        >
          Grantee
        </Step>
        <Step
          forceOrder
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationAssistedGrantShares"],
            {
              organizationId,
            },
          )}
          number={2}
          state={getAssistedGrantStepState("organizationAssistedGrantShares")}
        >
          Shares
        </Step>
        <Step
          forceOrder
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationAssistedGrantVesting"],
            {
              organizationId,
            },
          )}
          number={3}
          state={getAssistedGrantStepState("organizationAssistedGrantVesting")}
          subSteps={
            <>
              <Step
                forceOrder
                linkTo={generatePath(
                  APPLICATION_ROUTES[
                    "organizationAssistedGrantVestingVestingSchedule"
                  ],
                  {
                    organizationId,
                  },
                )}
                state={getAssistedGrantStepState(
                  "organizationAssistedGrantVestingVestingSchedule",
                )}
              >
                Vesting schedule
              </Step>
              <Step
                forceOrder
                linkTo={generatePath(
                  APPLICATION_ROUTES["organizationAssistedGrantVestingPTEP"],
                  {
                    organizationId,
                  },
                )}
                state={getAssistedGrantStepState(
                  "organizationAssistedGrantVestingPTEP",
                )}
              >
                Post termination
              </Step>
            </>
          }
        >
          Vesting
        </Step>
        <Step
          forceOrder
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationAssistedGrantReview"],
            {
              organizationId,
            },
          )}
          number={4}
          state={getAssistedGrantStepState("organizationAssistedGrantReview")}
        >
          Save draft
        </Step>
      </ul>
    </aside>
  );
};

export default Aside;
