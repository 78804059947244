/**
 * @generated SignedSource<<aa83678f4bbf611e68f3a141acce8021>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PTEP_Instrument$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType" | "GrantWatchouts_Rule83BWatchout_EquityType">;
  } | null;
  readonly isEarlyExerciseBeneficial: boolean;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument" | "EarlyExerciseBeneficialNoticeMessage_Instrument">;
  readonly " $fragmentType": "PTEP_Instrument";
};
export type PTEP_Instrument$key = {
  readonly " $data"?: PTEP_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"PTEP_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PTEP_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEarlyExerciseBeneficial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityType",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_Rule83BWatchout_EquityType"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EarlyExerciseBeneficialNoticeMessage_Instrument"
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "a226ef43e7414b9dbac0e3f2b1fdc9d4";

export default node;
