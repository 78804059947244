import { useCallback, useState } from "react";
import { graphql, useFragment } from "react-relay";

import { CleanupGranteesItem } from "../../../../components/CleanupGranteesItem";
import {
  GranteeFormSlide,
  useGranteeFormSlideState,
} from "../../../../components/GranteeFormSlide";
import { MergeEmployeeWithAnotherGranteeModal } from "../../../../components/MergeEmployeeWithAnotherGranteeModal";
import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Toast } from "../../../../components/ui/Toast";
import useIgnoreCleanupSuggestionMutation from "../useIgnoreCleanupSuggestionMutation";
import { CreateNewGranteeItem_HRISProviderEmployee$key } from "./__generated__/CreateNewGranteeItem_HRISProviderEmployee.graphql";
import { CreateNewGranteeItem_Organization$key } from "./__generated__/CreateNewGranteeItem_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CreateNewGranteeItem_Organization on Organization {
    id
    ...GranteeFormSlide_Organization
    ...MergeEmployeeWithAnotherGranteeModal_Organization
    ignoredHRISProviderEmployeesSuggestedAsNewGrantee: HRISProviderEmployeesSuggestedAsNewGrantee(
      ignored: true
    ) {
      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...GranteeForm_HRISProviderEmployee
    }
  }
`;

const HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment CreateNewGranteeItem_HRISProviderEmployee on HRISProviderEmployee {
    hRISProvider
    hRISProviderEmployeeId
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...GranteeForm_DefaultHRISProviderEmployee
    ...MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee
    ...CleanupGranteesItem_CreateContent_HRISProviderEmployee
  }
`;

export const CreateNewGranteeItem: React.FC<{
  hideIgnoreButton?: boolean;
  hrisProviderEmployeeFragment: CreateNewGranteeItem_HRISProviderEmployee$key;
  listIndex: number;
  listLength: number;
  onDataMutation: () => void;
  organizationFragment: CreateNewGranteeItem_Organization$key;
}> = ({
  hideIgnoreButton = false,
  hrisProviderEmployeeFragment,
  listIndex,
  listLength,
  onDataMutation,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const hrisProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    hrisProviderEmployeeFragment,
  );
  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInCreateMode,
  } = useGranteeFormSlideState();

  const handleCreateGranteeButtonClick = useCallback(() => {
    openGranteeFormSlideInCreateMode({
      defaultHRISProviderEmployeeFragment: hrisProviderEmployee,
    });
  }, [hrisProviderEmployee, openGranteeFormSlideInCreateMode]);

  const [
    mergeEmployeeWithAnotherGranteeModalIsShown,
    setMergeEmployeeWithAnotherGranteeModalIsShown,
  ] = useState(false);

  const [ignoreCleanupSuggestion, ignoreCleanupSuggestionMutationIsInFlight] =
    useIgnoreCleanupSuggestionMutation();
  const handleIgnoreButtonClick = useCallback(async () => {
    await ignoreCleanupSuggestion({
      variables: {
        HRISProvider: hrisProviderEmployee.hRISProvider,
        HRISProviderEmployeeId: hrisProviderEmployee.hRISProviderEmployeeId,
        organizationId: organization.id,
      },
    });

    onDataMutation();
  }, [
    hrisProviderEmployee,
    ignoreCleanupSuggestion,
    onDataMutation,
    organization.id,
  ]);

  const toaster = useToaster();

  const somethingIsLoading = ignoreCleanupSuggestionMutationIsInFlight;

  return (
    <div>
      <GranteeFormSlide
        ignoredHRISProviderEmployeesFragment={
          organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee
        }
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={() => {
          closeGranteeFormSlide();

          onDataMutation();
        }}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      <MergeEmployeeWithAnotherGranteeModal
        employeeFragment={hrisProviderEmployee}
        onClose={() => {
          setMergeEmployeeWithAnotherGranteeModalIsShown(false);
        }}
        onMerge={() => {
          toaster.push(
            <Toast title="Cha-ching!" variant="congrats">
              Grantee updated successfully!
            </Toast>,
          );

          setMergeEmployeeWithAnotherGranteeModalIsShown(false);

          onDataMutation();
        }}
        organizationFragment={organization}
        show={mergeEmployeeWithAnotherGranteeModalIsShown}
      />

      <CleanupGranteesItem
        actions={
          <>
            {!hideIgnoreButton && (
              <Button
                loading={somethingIsLoading}
                onClick={handleIgnoreButtonClick}
                size="small"
                type="button"
                variant="Tertiary Link"
              >
                Ignore
              </Button>
            )}
            <Button
              loading={somethingIsLoading}
              onClick={() => {
                setMergeEmployeeWithAnotherGranteeModalIsShown(true);
              }}
              size="small"
              variant="Secondary Full"
            >
              Link to existing
            </Button>
            <Button
              loading={somethingIsLoading}
              onClick={handleCreateGranteeButtonClick}
              size="small"
            >
              Create grantee
            </Button>
          </>
        }
        listIndex={listIndex}
        listLength={listLength}
      >
        <CleanupGranteesItem.CreateContent
          hRISProviderEmployeeFragment={hrisProviderEmployee}
        />
      </CleanupGranteesItem>
    </div>
  );
};
