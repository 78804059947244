/**
 * @generated SignedSource<<ae17441d0fd42ce9a9b653875f4fd75f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type EquityTypeWorkRelationshipCategory = "Contractor" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type OngoingObligationsTable_OngoingObligation$data = ReadonlyArray<{
  readonly affectedOrganizations: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly date: string;
  readonly equityType: {
    readonly name: EquityTypeName;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    };
  };
  readonly equityTypeWorkRelationshipCategories: ReadonlyArray<EquityTypeWorkRelationshipCategory>;
  readonly id: string;
  readonly label: string;
  readonly " $fragmentType": "OngoingObligationsTable_OngoingObligation";
}>;
export type OngoingObligationsTable_OngoingObligation$key = ReadonlyArray<{
  readonly " $data"?: OngoingObligationsTable_OngoingObligation$data;
  readonly " $fragmentSpreads": FragmentRefs<"OngoingObligationsTable_OngoingObligation">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OngoingObligationsTable_OngoingObligation",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeWorkRelationshipCategories",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityType",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "affectedOrganizations",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "OngoingObligation",
  "abstractKey": null
};
})();

(node as any).hash = "1d6de389e1b10e562c92a36e91efc969";

export default node;
