/**
 * @generated SignedSource<<e29949bbe6adfe7b1633933df66a9fee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ExerciseStatus = "FullyExercised" | "NotExercised" | "PartiallyExercised";
import { FragmentRefs } from "relay-runtime";
export type GranteeExerciseBox_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly exerciseStatus: ExerciseStatus;
    readonly id: string;
    readonly label: string;
    readonly settled: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsExercise_CTMSGrant">;
  }>;
  readonly " $fragmentType": "GranteeExerciseBox_Grantee";
};
export type GranteeExerciseBox_Grantee$key = {
  readonly " $data"?: GranteeExerciseBox_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeExerciseBox_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeExerciseBox_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "exerciseStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settled",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantDetailsExercise_CTMSGrant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "609d46073b089e833cd21888fca6753f";

export default node;
