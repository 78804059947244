/**
 * @generated SignedSource<<1e688c6ff80acf44df869946c224732b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodSelectionBlock_Instrument$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType">;
  } | null;
  readonly isVirtual: boolean;
  readonly " $fragmentType": "PostTerminationExercisePeriodSelectionBlock_Instrument";
};
export type PostTerminationExercisePeriodSelectionBlock_Instrument$key = {
  readonly " $data"?: PostTerminationExercisePeriodSelectionBlock_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodSelectionBlock_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationExercisePeriodSelectionBlock_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityType",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "39d3da9adca233c5e35451346a53b6fe";

export default node;
