/**
 * @generated SignedSource<<386331fbd8b7a2e7eec3bc67b8262fe9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GenerateGrantBoardConsentPreviewURLResultFailureError = "DIFFERENT_ACCELERATION_CLAUSE";
export type useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation$variables = {
  easopGrantsIds: ReadonlyArray<string>;
  organizationId: string;
};
export type useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation$data = {
  readonly generateGrantBoardConsentPreviewURL: {
    readonly __typename: "GenerateGrantBoardConsentPreviewURLResultFailure";
    readonly error: GenerateGrantBoardConsentPreviewURLResultFailureError;
  } | {
    readonly __typename: "GenerateGrantBoardConsentPreviewURLResultSuccess";
    readonly url: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation = {
  response: useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation$data;
  variables: useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "easopGrantsIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "easopGrantsIds",
        "variableName": "easopGrantsIds"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "generateGrantBoardConsentPreviewURL",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "type": "GenerateGrantBoardConsentPreviewURLResultFailure",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "type": "GenerateGrantBoardConsentPreviewURLResultSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2f09155773eb246ee9229c1dbe28d851",
    "id": null,
    "metadata": {},
    "name": "useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDownloadBoardConsentPreview_GenerateGrantBoardConsentPreviewURL_Mutation(\n  $easopGrantsIds: [UUID!]!\n  $organizationId: OrganizationId!\n) {\n  generateGrantBoardConsentPreviewURL(easopGrantsIds: $easopGrantsIds, organizationId: $organizationId) {\n    __typename\n    ... on GenerateGrantBoardConsentPreviewURLResultFailure {\n      error\n    }\n    ... on GenerateGrantBoardConsentPreviewURLResultSuccess {\n      url\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ebf644c00b5589a6ee6bdd2c9f78c0b";

export default node;
