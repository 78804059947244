/**
 * @generated SignedSource<<8e41645b1c2498b35974b09dab6eadac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type GrantNotReadyToBeSentToBoardConsentReason = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "NOT_REVIEWED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type GrantWarning = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
export type JobStatus = "ACTIVE" | "COMPLETED" | "CREATED" | "FAILED";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type GrantsTable_EasopGrant$data = ReadonlyArray<{
  readonly createCTMSDraftJobStatus: JobStatus | null;
  readonly exercisePrice: number;
  readonly grantStatus: EasopGrantStatus;
  readonly grantee: {
    readonly contractStartDate: string | null;
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"TableCellGrantee_Grantee">;
  };
  readonly id: string;
  readonly instrument: {
    readonly equityType: {
      readonly name: EquityTypeName;
    } | null;
  };
  readonly instrumentValuationType: ValuationType;
  readonly isDeletable: boolean;
  readonly isEditable: boolean;
  readonly label: string;
  readonly notReadyForBoardConsentReasons: ReadonlyArray<GrantNotReadyToBeSentToBoardConsentReason>;
  readonly quantityGranted: number;
  readonly vestingStartDate: string;
  readonly warnings: ReadonlyArray<GrantWarning>;
  readonly " $fragmentSpreads": FragmentRefs<"DraftWatchoutTag_EasopGrant" | "GrantsTableStatusTag_EasopGrant" | "RefresherTag_EasopGrant" | "useDownloadGrantDocumentationForImplementation_EasopGrant" | "useInstrumentValuationGuard_Grants">;
  readonly " $fragmentType": "GrantsTable_EasopGrant";
}>;
export type GrantsTable_EasopGrant$key = ReadonlyArray<{
  readonly " $data"?: GrantsTable_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_EasopGrant">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GrantsTable_EasopGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contractStartDate",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TableCellGrantee_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notReadyForBoardConsentReasons",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEditable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instrumentValuationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createCTMSDraftJobStatus",
      "storageKey": null
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "DraftWatchoutTag_EasopGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefresherTag_EasopGrant"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "useInstrumentValuationGuard_Grants"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTableStatusTag_EasopGrant"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "useDownloadGrantDocumentationForImplementation_EasopGrant"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "475afb9b5c9819ea837146d402a556aa";

export default node;
