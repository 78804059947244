import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedCurrencyCompact } from "../../../../components/Formatted/FormattedCurrencyCompact";
import {
  GranteesListSlideOver,
  useGranteesListSlideOverState,
} from "../../../../components/GranteesListSlideOver";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { ScatterGraph } from "../../../../components/ScatterGraph";
import { Typography } from "../../../../components/ui/Typography";
import { LargestGainGraph_LargestGainDataPoint$key } from "./__generated__/LargestGainGraph_LargestGainDataPoint.graphql";
import { LargestGainGraph_Organization$key } from "./__generated__/LargestGainGraph_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LargestGainGraph_Organization on Organization {
    ...GranteesListSlideOver_Organization
  }
`;

const DATA_POINTS_FRAGMENT = graphql`
  fragment LargestGainGraph_LargestGainDataPoint on LargestGainDataPoint
  @relay(plural: true) {
    grantees {
      id
    }
    gainInUSD
    valueGrantedInUSD
  }
`;

const Tooltip: React.FC<{
  gainInUSD: number;
  granteesCount: number;
  valueGrantedInUSD: number;
}> = ({ gainInUSD, granteesCount, valueGrantedInUSD }) => {
  return (
    <GraphTooltip
      bottomContent={
        <FormattedMessage
          defaultMessage="Click on the data point to see the {count, plural, one {grantee} other {list of grantees}}"
          values={{ count: granteesCount }}
        />
      }
      topContent={
        <FormattedMessage
          defaultMessage={`{gainInUSD} of gain realized`}
          values={{
            gainInUSD: (
              <FormattedCurrencyCompact
                minimumFractionDigits={0}
                value={gainInUSD}
              />
            ),
          }}
        />
      }
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className="h-2 w-2 rounded-full bg-purple-05" />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Regular/Extra Small">
            <FormattedMessage
              defaultMessage={`For a total value granted of {valueGrantedInUSD}`}
              values={{
                valueGrantedInUSD: (
                  <FormattedCurrencyCompact
                    minimumFractionDigits={0}
                    value={valueGrantedInUSD}
                  />
                ),
              }}
            />
          </Typography>
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage="{count, plural, one {# grantee} other {# grantees}}"
              values={{ count: granteesCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

export const LargestGainGraph: React.FC<{
  dataPointsFragment: LargestGainGraph_LargestGainDataPoint$key;
  organizationFragment: LargestGainGraph_Organization$key;
}> = ({ dataPointsFragment, organizationFragment }) => {
  const intl = useIntl();

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const dataPoints = useFragment(DATA_POINTS_FRAGMENT, dataPointsFragment);

  const data = useMemo(
    () =>
      dataPoints.map((dataPoint) => ({
        grantees: dataPoint.grantees,
        x: dataPoint.valueGrantedInUSD,
        y: dataPoint.gainInUSD,
        z: dataPoint.grantees.length,
      })),
    [dataPoints],
  );

  const {
    close: closeGranteesListSlideOver,
    open: openGranteesListSlideOver,
    state: GranteesListSlideOverState,
  } = useGranteesListSlideOverState();

  return (
    <>
      <GranteesListSlideOver
        onClose={closeGranteesListSlideOver}
        organizationFragment={organization}
        state={GranteesListSlideOverState}
      />
      <ScatterGraph
        dataPoints={data}
        onCellClick={({ grantees, y: gainInUSD, z: granteesCount }) =>
          openGranteesListSlideOver({
            granteesIds: grantees.map(({ id }) => id),
            title: (
              <FormattedMessage
                defaultMessage={`There {granteesCount, plural, one {is # grantee} other {are # grantees}} who made a gain of {gainInUSD}`}
                values={{
                  gainInUSD: (
                    <FormattedCurrencyCompact
                      minimumFractionDigits={0}
                      value={gainInUSD}
                    />
                  ),
                  granteesCount,
                }}
              />
            ),
          })
        }
        renderTooltip={({ grantees, x: valueGrantedInUSD, y: gainInUSD }) => (
          <Tooltip
            gainInUSD={gainInUSD}
            granteesCount={grantees.length}
            valueGrantedInUSD={valueGrantedInUSD}
          />
        )}
        xLabel="$ Value"
        xTickFormatter={(value) =>
          intl.formatNumber(value, {
            currency: "USD",
            maximumFractionDigits: 0,
            style: "currency",
          })
        }
        yLabel="Gain $value"
        yTickFormatter={(value) =>
          intl.formatNumber(value, {
            currency: "USD",
            maximumFractionDigits: 0,
            style: "currency",
          })
        }
      />
    </>
  );
};
