import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { useApplicationSupportEmailAddress } from "../hooks/useApplicationTheme";
import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { SafeCalculatorEmptyPlaceholder_Account$key } from "./__generated__/SafeCalculatorEmptyPlaceholder_Account.graphql";
import { SafeCalculatorEmptyPlaceholder_CreateSafeCalculatorVersion_Mutation } from "./__generated__/SafeCalculatorEmptyPlaceholder_CreateSafeCalculatorVersion_Mutation.graphql";
import { SafeCalculatorEmptyPlaceholder_Organization$key } from "./__generated__/SafeCalculatorEmptyPlaceholder_Organization.graphql";
import imageSource from "./SafeCalculatorEmptyPlaceholder.png";
import { FormValues } from "./SafeCalculatorVersionModal/FORM_SCHEMA";
import { SafeCalculatorVersionModal } from "./SafeCalculatorVersionModal/SafeCalculatorVersionModal";
import { Button, LinkButton } from "./ui/Button";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SafeCalculatorEmptyPlaceholder_Organization on Organization {
    id
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment SafeCalculatorEmptyPlaceholder_Account on Account {
    isSuperAdmin
  }
`;

const CREATE_SAFE_CALCULATOR_VERSION_MUTATION = graphql`
  mutation SafeCalculatorEmptyPlaceholder_CreateSafeCalculatorVersion_Mutation(
    $organizationId: OrganizationId!
    $name: String!
  ) {
    createSafeCalculatorVersion(name: $name, organizationId: $organizationId) {
      __typename
    }
  }
`;

export const SafeCalculatorEmptyPlaceholder: React.FC<{
  onSafeCalculatorVersionCreated: () => void;
  organizationFragment: SafeCalculatorEmptyPlaceholder_Organization$key;
  viewerFragment: SafeCalculatorEmptyPlaceholder_Account$key;
}> = ({
  onSafeCalculatorVersionCreated,
  organizationFragment,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const {
    setFalse: closeSafeCalculatorVersionModal,
    setTrue: openSafeCalculatorVersionModal,
    value: safeCalculatorVersionModalIsShown,
  } = useBoolean(false);

  const [createSafeCalculatorVersion] =
    useSafeMutation<SafeCalculatorEmptyPlaceholder_CreateSafeCalculatorVersion_Mutation>(
      CREATE_SAFE_CALCULATOR_VERSION_MUTATION,
    );

  const handleSafeCalculatorVersionFormSubmit = useCallback(
    async (formData: FormValues) => {
      await createSafeCalculatorVersion({
        variables: {
          name: formData.name,
          organizationId: organization.id,
        },
      });

      closeSafeCalculatorVersionModal();
      onSafeCalculatorVersionCreated();
    },
    [
      organization.id,
      createSafeCalculatorVersion,
      closeSafeCalculatorVersionModal,
      onSafeCalculatorVersionCreated,
    ],
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <RoundedBox withBorder>
      <SafeCalculatorVersionModal
        onClose={closeSafeCalculatorVersionModal}
        onSubmit={handleSafeCalculatorVersionFormSubmit}
        show={safeCalculatorVersionModalIsShown}
      />
      <div className="flex gap-14 p-10">
        <div className="flex-1 space-y-6">
          <Typography as="div" variant="Medium/Large">
            Calculate your SAFE notes conversion
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Default"
          >
            Preview the impact of the conversion of your SAFE notes on your
            option pool and fully diluted shares count.
          </Typography>
          {viewer.isSuperAdmin ? (
            <Button
              onClick={openSafeCalculatorVersionModal}
              size="medium"
              type="button"
              variant="Primary Full"
            >
              Start new SAFE conversion
            </Button>
          ) : (
            <div className="flex gap-2">
              <LinkButton
                rightIcon={<ArrowTopRightOnSquareIcon />}
                target="_blank"
                to={generatePath(APPLICATION_ROUTES["safeSimulator"])}
              >
                Simulate SAFE Notes
              </LinkButton>
              <LinkButton
                to={`mailto:${supportEmailAddress}?subject=Talk%20to%20our%20experts`}
                variant="Secondary Outline"
              >
                Contact us
              </LinkButton>
            </div>
          )}
        </div>
        <div className="flex-1">
          <img className="w-full" src={imageSource} />
        </div>
      </div>
    </RoundedBox>
  );
};
