import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CARD_BRAND_TO_LOGO_MAP } from "../cardLogos";
import { StripePaymentMethodLogo_StripePaymentMethod$key } from "./__generated__/StripePaymentMethodLogo_StripePaymentMethod.graphql";

const STRIPE_PAYMENT_METHOD_FRAGMENT = graphql`
  fragment StripePaymentMethodLogo_StripePaymentMethod on StripePaymentMethod {
    cardBrand
  }
`;

export const StripePaymentMethodLogo: React.FC<{
  className?: string;
  stripePaymentMethodFragment: StripePaymentMethodLogo_StripePaymentMethod$key;
}> = ({ className, stripePaymentMethodFragment }) => {
  const { cardBrand } = useFragment(
    STRIPE_PAYMENT_METHOD_FRAGMENT,
    stripePaymentMethodFragment,
  );

  if (!cardBrand) {
    return null;
  }

  const cardLogo = (cardBrand && CARD_BRAND_TO_LOGO_MAP[cardBrand]) ?? null;

  if (!cardLogo) {
    return null;
  }

  return <img className={className} src={cardLogo} />;
};
