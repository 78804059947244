/**
 * @generated SignedSource<<040ed122d699c6a1f0055642ae50583c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardConsentsPage_Organization$data = {
  readonly boardConsents: ReadonlyArray<{
    readonly completedAt: string | null;
    readonly signedDocument: {
      readonly downloadUrl: string;
    } | null;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardConsentsDocumentsTable_Organization">;
  readonly " $fragmentType": "BoardConsentsPage_Organization";
};
export type BoardConsentsPage_Organization$key = {
  readonly " $data"?: BoardConsentsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardConsentsPage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardConsentsPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsents",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "signedDocument",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "downloadUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardConsentsDocumentsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d6d338ad9a2e98a3ebcc52c24f03a6b3";

export default node;
