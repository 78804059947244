/**
 * @generated SignedSource<<932d075d63ba1a7dde1c3992057b39cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Review_VestingSchedule$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GrantVestingScheduleRow_VestingSchedule">;
  readonly " $fragmentType": "Review_VestingSchedule";
};
export type Review_VestingSchedule$key = {
  readonly " $data"?: Review_VestingSchedule$data;
  readonly " $fragmentSpreads": FragmentRefs<"Review_VestingSchedule">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Review_VestingSchedule",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantVestingScheduleRow_VestingSchedule"
    }
  ],
  "type": "VestingSchedule",
  "abstractKey": "__isVestingSchedule"
};

(node as any).hash = "b1f63b32d7fc1e3d46c54b70db77b263";

export default node;
