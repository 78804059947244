import { chain } from "lodash";
import { useMemo } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BookADemoSlideOver } from "../../../../components/BookADemo/BookADemoSlideOver";
import { DownloadUnderwaterGrantsCSV } from "../../../../components/DownloadUnderwaterGrantsCSV";
import { Page } from "../../../../components/Page";
import { Button } from "../../../../components/ui/Button";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { checkPropertiesNotNull } from "../../../../helpers/ts-utlity";
import { useApplicationName } from "../../../../hooks/useApplicationTheme";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { UnderwaterGrants_Organization$key } from "./__generated__/UnderwaterGrants_Organization.graphql";
import { UnderwaterGrants_Query } from "./__generated__/UnderwaterGrants_Query.graphql";
import { UnderwaterGrants_Viewer$key } from "./__generated__/UnderwaterGrants_Viewer.graphql";
import { UnderwaterGrantsGraph } from "./UnderwaterGrantsGraph";

const VIEWER_FRAGMENT = graphql`
  fragment UnderwaterGrants_Viewer on Account {
    isSuperAdmin
    ...BookADemoSlideOver_Viewer
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnderwaterGrants_Organization on Organization {
    id
    name
    activeCTMSGrants: ctmsGrants(filters: { statusIn: [Active] }) {
      edges {
        node {
          id
          exercisePrice
          equityTypeAwardName
          ...DownloadUnderwaterGrantsCSV_CTMSGrant
          ...UnderwaterGrantsGraph_CTMSGrant
        }
      }
    }
    latestFairMarketValue {
      value
    }
    ...UnderwaterGrantsGraph_Organization
  }
`;

const AdminUnderwaterGrantsPage_: React.FC<{
  organizationFragment: UnderwaterGrants_Organization$key;
  viewerFragment: UnderwaterGrants_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const applicationName = useApplicationName();

  const activeCTMSGrants = useMemo(
    () => organization.activeCTMSGrants.edges.map(({ node }) => node),
    [organization.activeCTMSGrants.edges],
  );
  const { inTheMoneyGrants, onFmvGrants, underWaterGrants } = useMemo(() => {
    const nsoIsoGrants = chain(activeCTMSGrants)
      .map((grant) => {
        if (checkPropertiesNotNull(grant, "equityTypeAwardName")) {
          switch (grant.equityTypeAwardName) {
            case "BSPCE":
            case "EMI":
            case "ISO":
            case "NSO":
            case "SAR":
              return grant;
            default:
              break;
          }
        }
        return null;
      })
      .compact()
      .value();

    return nsoIsoGrants.reduce<{
      inTheMoneyGrants: Array<(typeof nsoIsoGrants)[number]>;
      onFmvGrants: Array<(typeof nsoIsoGrants)[number]>;
      underWaterGrants: Array<(typeof nsoIsoGrants)[number]>;
    }>(
      (acc, grant) => {
        if (!organization.latestFairMarketValue) return acc;

        if (checkPropertiesNotNull(grant, "exercisePrice")) {
          if (grant.exercisePrice > organization.latestFairMarketValue.value) {
            acc.underWaterGrants.push(grant);
          } else if (
            grant.exercisePrice < organization.latestFairMarketValue.value
          ) {
            acc.inTheMoneyGrants.push(grant);
          } else {
            acc.onFmvGrants.push(grant);
          }
        }
        return acc;
      },
      {
        inTheMoneyGrants: [],
        onFmvGrants: [],
        underWaterGrants: [],
      },
    );
  }, [activeCTMSGrants, organization.latestFairMarketValue]);

  return (
    <InsightPage
      subtitle="Split of grants underwater, on FMV and in the money"
      title="🤿 Underwater grants"
      topRightContent={
        viewer.isSuperAdmin && (
          <DownloadUnderwaterGrantsCSV ctmsGrantsFragment={underWaterGrants} />
        )
      }
    >
      <UnderwaterGrantsGraph
        inTheMoneyGrantsFragment={inTheMoneyGrants}
        onFmvGrantsFragment={onFmvGrants}
        organizationFragment={organization}
        underWaterGrantsFragment={underWaterGrants}
      />
      <NoticeMessage className="w-full py-2" size="Large" variant="Idea">
        <div className="flex items-center gap-4">
          <div className="flex-grow">
            Did you know that, with {applicationName}, you can easily reprice
            underwater grants?
          </div>
          <BookADemoSlideOver
            header="Jump on a call with our team of experts and learn all you need about repricing underwater grants"
            introduction="I would like to learn more about repricing underwater grants"
            organizationName={organization.name}
            renderButton={({ openSlideOver }) => (
              <Button
                className="shrink-0"
                onClick={openSlideOver}
                size="extra small"
                variant="Secondary Outline"
              >
                Talk to us
              </Button>
            )}
            viewerFragment={viewer}
          />
        </div>
      </NoticeMessage>
    </InsightPage>
  );
};

const QUERY = graphql`
  query UnderwaterGrants_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...UnderwaterGrants_Organization
    }
    me {
      ...UnderwaterGrants_Viewer
    }
  }
`;

const AdminUnderwaterGrantsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { me: viewer, organization } = useLazyLoadQuery<UnderwaterGrants_Query>(
    QUERY,
    {
      organizationId,
    },
  );

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Underwater Grants"
      analyticsName="Admin - Insights - Underwater Grants"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | underwater grants`}
    >
      <AdminUnderwaterGrantsPage_
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    </Page>
  );
};

export default AdminUnderwaterGrantsPage;
