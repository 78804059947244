/**
 * @generated SignedSource<<5952284ecf22620c8ae7d7ee1ca3c14a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type Duration_PostTerminationExercisePeriod$data = {
  readonly duration: number;
  readonly unit: DurationUnit;
  readonly " $fragmentType": "Duration_PostTerminationExercisePeriod";
};
export type Duration_PostTerminationExercisePeriod$key = {
  readonly " $data"?: Duration_PostTerminationExercisePeriod$data;
  readonly " $fragmentSpreads": FragmentRefs<"Duration_PostTerminationExercisePeriod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Duration_PostTerminationExercisePeriod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unit",
      "storageKey": null
    }
  ],
  "type": "PostTerminationExercisePeriod",
  "abstractKey": null
};

(node as any).hash = "6619a92a6dbb86fdff002418b27dc6c4";

export default node;
