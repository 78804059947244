import React, { useEffect } from "react";

import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { Typography } from "../../../components/ui/Typography";
import { useTrackEvent } from "../../../hooks/useAnalytics";
import { useSafeSimulatorContext } from "../IContext";
import { SafeSimulatorCalculatorContent } from "./SafeSimulatorCalculatorContent";

const SafeSimulatorCalculatorPage_: React.FC = () => {
  const {
    reset: resetSafeSimulator,
    state: { optionPoolShares, preConversionFullyDilutedShares },
  } = useSafeSimulatorContext();

  const trackEvent = useTrackEvent();

  useEffect(() => {
    trackEvent(`SAFE converter - Not Logged - Simulation started`, {
      fully_dilluted_shares: preConversionFullyDilutedShares,
      pool_size: optionPoolShares,
    });
  }, [trackEvent, preConversionFullyDilutedShares, optionPoolShares]);

  return (
    <div className="space-y-4 p-16">
      <Typography as="div" variant="Semibold/Large">
        SAFE converter
      </Typography>
      <SafeSimulatorCalculatorContent organizationFragment={null} />
      <Button
        onClick={resetSafeSimulator}
        size="small"
        type="button"
        variant="Secondary Full"
      >
        Reset converter
      </Button>
    </div>
  );
};

const SafeSimulatorCalculatorPage: React.FC = () => {
  return (
    <Page
      analyticsCategory="SAFE converter"
      analyticsName="SAFE converter"
      title="SAFE converter"
    >
      <SafeSimulatorCalculatorPage_ />
    </Page>
  );
};

export default SafeSimulatorCalculatorPage;
