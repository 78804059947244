import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, { useCallback, useMemo, useState, useTransition } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  CartaOrganizationsPage_Organizations$data,
  CartaOrganizationsPage_Organizations$key,
} from "./__generated__/CartaOrganizationsPage_Organizations.graphql";
import { CartaOrganizationsPage_Query } from "./__generated__/CartaOrganizationsPage_Query.graphql";
import { OrganizationAddedEasopAsCartaLegalAdminModal } from "./OrganizationAddedEasopAsCartaLegalAdminModal";

const ORGANIZATIONS_FRAGMENT = graphql`
  fragment CartaOrganizationsPage_Organizations on Organization
  @relay(plural: true) {
    id
    name
  }
`;

type Organization = CartaOrganizationsPage_Organizations$data[number];

const columnHelper = createColumnHelper<Organization>();

const CartaOrganizationsPage_: React.FC<{
  organizationsFragment: CartaOrganizationsPage_Organizations$key;
}> = ({ organizationsFragment }) => {
  const instruments = useFragment(
    ORGANIZATIONS_FRAGMENT,
    organizationsFragment,
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<
    null | string
  >(null);
  const [openModalTransitionIsInProgress, startOpenModalTransition] =
    useTransition();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Organization",
        id: "name",
      }),
    ],
    [],
  );

  const data = useMemo(() => [...instruments], [instruments]);

  const handleRowClick = useCallback(
    ({ organizationId }: { organizationId: string }) =>
      () => {
        startOpenModalTransition(() => {
          setSelectedOrganizationId(organizationId);
        });
      },
    [],
  );

  const handleModalExited = useCallback(() => {
    setSelectedOrganizationId(null);
  }, []);

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    initialState: {
      sorting: [{ desc: false, id: "name" }],
    },
  });

  return (
    <>
      {selectedOrganizationId && (
        <OrganizationAddedEasopAsCartaLegalAdminModal
          onExited={handleModalExited}
          organizationId={selectedOrganizationId}
        />
      )}
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link
              to={generatePath(
                APPLICATION_ROUTES["superAdminOrganizationAdmin"],
                {},
              )}
            >
              Organization admin
            </BreadCrumb.Link>
            <BreadCrumb.Link to=".">Carta Organizations</BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="flex-grow space-y-2">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">
                  Carta Organizations
                </Typography>
              </div>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Click on an organization to check if it has RemoteEquity set as
                their law firm in Carta
              </Typography>
            </div>
          </div>
          <Table.Smart
            rowRenderer={({ cells, rowData }) => (
              <Table.Row
                className={classNames({
                  "animate-pulse": openModalTransitionIsInProgress,
                })}
                onClick={handleRowClick({
                  organizationId: rowData.original.id,
                })}
              >
                {cells}
              </Table.Row>
            )}
            table={table}
          />
        </RoundedBox>
      </OneColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query CartaOrganizationsPage_Query {
    organizations(filters: { connectedToCarta: true }) {
      ...CartaOrganizationsPage_Organizations
    }
  }
`;

const CartaOrganizationsPage: React.FC = () => {
  const { organizations } = useLazyLoadQuery<CartaOrganizationsPage_Query>(
    QUERY,
    {},
  );
  return (
    <Page
      analyticsName="Super Admin - Carta Organizations"
      title={`Super admin | carta organizations`}
    >
      <CartaOrganizationsPage_ organizationsFragment={organizations} />
    </Page>
  );
};

export default CartaOrganizationsPage;
