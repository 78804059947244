import { isBefore } from "date-fns";

import { addPeriodToDate } from "../helpers/addPeriodToDate";
import { PtepDurationUnit } from "../helpers/ptep-utils";

type PostTerminationExercisePeriod = {
  duration: number;
  durationUnit: PtepDurationUnit;
};

export const comparePtep = (
  a: PostTerminationExercisePeriod,
  b: PostTerminationExercisePeriod,
) => {
  if (a.duration === b.duration && a.durationUnit === b.durationUnit)
    return "EQUAL";
  const date = new Date();
  const aDate = addPeriodToDate(date, a.duration, a.durationUnit);
  const bDate = addPeriodToDate(date, b.duration, b.durationUnit);
  return isBefore(aDate, bDate) ? "LOWER" : "GREATER";
};
