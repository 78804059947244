import React, { ReactNode } from "react";

import { useBoolean } from "../../hooks/useBoolean";
import { Button, ButtonProps } from "./Button";
import { ConfirmationModal } from "./ConfirmationModal";

interface WarningButtonProps extends ButtonProps {
  onConfirm: () => void;
  onPrevent?: () => void;
  shouldPrevent?: boolean;
  warningContent: ReactNode;
  warningTitle: string;
}

const WarningButton_: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  WarningButtonProps
> = (
  {
    children,
    onConfirm,
    onPrevent,
    shouldPrevent,
    warningContent,
    warningTitle,
    ...props
  },
  ref,
) => {
  const {
    setFalse: hideRemoveWarningModal,
    setTrue: showRemoveWarningModal,
    value: removeWarningModalIsShown,
  } = useBoolean(false);

  return (
    <div>
      <ConfirmationModal
        onClose={hideRemoveWarningModal}
        onConfirmed={onConfirm}
        show={removeWarningModalIsShown}
        title={warningTitle}
      >
        {warningContent}
      </ConfirmationModal>
      <Button
        ref={ref}
        {...props}
        onClick={
          shouldPrevent && onPrevent ? onPrevent : showRemoveWarningModal
        }
      >
        {children}
      </Button>
    </div>
  );
};

export const WarningButton = React.forwardRef(WarningButton_);
