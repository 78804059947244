import { Pill } from "@remote-com/norma";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { EquityTypesExercisesTaxParametersTable } from "../../../../components/EquityTypesExercisesTaxParametersTable";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { ExercisesTaxParametersPage_EquityType$key } from "./__generated__/ExercisesTaxParametersPage_EquityType.graphql";
import { ExercisesTaxParametersPage_Query } from "./__generated__/ExercisesTaxParametersPage_Query.graphql";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment ExercisesTaxParametersPage_EquityType on EquityType
  @relay(plural: true) {
    ...EquityTypesExercisesTaxParametersTable_EquityType
  }
`;

const ExercisesTaxParametersPage_: React.FC<{
  equityTypesFragment: ExercisesTaxParametersPage_EquityType$key;
}> = ({ equityTypesFragment }) => {
  const equityTypes = useFragment(EQUITY_TYPE_FRAGMENT, equityTypesFragment);
  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Legal content</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Exercises Tax Parameters</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex-grow space-y-2">
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Default">
                Exercises Tax Parameters
              </Typography>
              <Pill>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# equity type} other {# equity types}}"
                  values={{
                    count: equityTypes.length,
                  }}
                />
              </Pill>
            </div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Create a new tax parameter per equity type and per work
              relationship
            </Typography>
          </div>
        </div>
        <EquityTypesExercisesTaxParametersTable
          equityTypesFragment={equityTypes}
        />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query ExercisesTaxParametersPage_Query {
    equityTypes {
      ...ExercisesTaxParametersPage_EquityType
    }
  }
`;

const ExercisesTaxParametersPage: React.FC = () => {
  const { equityTypes } = useLazyLoadQuery<ExercisesTaxParametersPage_Query>(
    QUERY,
    {},
  );
  return (
    <Page
      analyticsName="Super Admin - ExercisesTaxParameters"
      title={`Super admin | ExercisesTaxParameters`}
    >
      <ExercisesTaxParametersPage_ equityTypesFragment={equityTypes} />
    </Page>
  );
};

export default ExercisesTaxParametersPage;
