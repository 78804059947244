/**
 * @generated SignedSource<<85c3691dcd424f7a40a24d90340974a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueBoardApprovalSlider_Organization$data = {
  readonly allowBoardConsentHandledOutsideEasop: boolean;
  readonly boardMembers: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization" | "BoardNoteSection_Organization">;
  readonly " $fragmentType": "FairMarketValueBoardApprovalSlider_Organization";
};
export type FairMarketValueBoardApprovalSlider_Organization$key = {
  readonly " $data"?: FairMarketValueBoardApprovalSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueBoardApprovalSlider_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueBoardApprovalSlider_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowBoardConsentHandledOutsideEasop",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardNoteSection_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0f0f98df4bef4d9aa65e467233acb925";

export default node;
