/**
 * @generated SignedSource<<f48f56f4e51bf1f4d7008c39b1772959>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsIntegrationRemote_Query$variables = {
  organizationId: string;
};
export type OrganizationSettingsIntegrationRemote_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly isConnectedToRemote: boolean;
    readonly isOriginatingFromRemoteEquity: boolean;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsIntegration_Organization">;
  } | null;
};
export type OrganizationSettingsIntegrationRemote_Query = {
  response: OrganizationSettingsIntegrationRemote_Query$data;
  variables: OrganizationSettingsIntegrationRemote_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isConnectedToRemote",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isOriginatingFromRemoteEquity",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsIntegrationRemote_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsIntegration_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsIntegrationRemote_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5189fa3cfb947ee068ea76834fde4656",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsIntegrationRemote_Query",
    "operationKind": "query",
    "text": "query OrganizationSettingsIntegrationRemote_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    isConnectedToRemote\n    isOriginatingFromRemoteEquity\n    ...OrganizationSettingsIntegration_Organization\n  }\n}\n\nfragment DisconnectIntegrationButton_Organization on Organization {\n  id\n}\n\nfragment OrganizationSettingsIntegration_Organization on Organization {\n  id\n  name\n  ...DisconnectIntegrationButton_Organization\n}\n"
  }
};
})();

(node as any).hash = "5001cfbdddee3e3cc554c7a86d3d296a";

export default node;
