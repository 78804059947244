import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { chain, isEmpty, partition } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useAlerter } from "../../../../components/Alerter";
import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { useToaster } from "../../../../components/Toaster";
import { Alert } from "../../../../components/ui/Alert";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { TextArea } from "../../../../components/ui/Form/Inputs/TextArea";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Tab } from "../../../../components/ui/Tab";
import { Table } from "../../../../components/ui/Table";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../../../paths";
import {
  DocumentsApprovalPage_DocumentTemplate$data,
  DocumentsApprovalPage_DocumentTemplate$key,
} from "./__generated__/DocumentsApprovalPage_DocumentTemplate.graphql";
import { DocumentsApprovalPage_Organization$key } from "./__generated__/DocumentsApprovalPage_Organization.graphql";
import { DocumentsApprovalPage_Query } from "./__generated__/DocumentsApprovalPage_Query.graphql";
import { DocumentsApprovalPage_SetDocumentTemplateContentUsedForApproval_Mutation } from "./__generated__/DocumentsApprovalPage_SetDocumentTemplateContentUsedForApproval_Mutation.graphql";
import { DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation } from "./__generated__/DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation.graphql";
import { DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation } from "./__generated__/DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation.graphql";

const DOCUMENT_TEMPLATE_FRAGMENT = graphql`
  fragment DocumentsApprovalPage_DocumentTemplate on DocumentTemplate
  @relay(plural: true) {
    id
    name
    type
    needsBoardApproval
    content
    contentUsedForApproval
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DocumentsApprovalPage_Organization on Organization
  @relay(plural: true) {
    id
    name
    requiresDocumentApproval
  }
`;

const SET_DOCUMENT_TEMPLATE_NEEDS_BOARD_APPROVAL_MUTATION = graphql`
  mutation DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation(
    $documentTemplateId: UUID!
    $needsBoardApproval: Boolean!
  ) {
    setDocumentTemplateNeedsBoardApproval(
      documentTemplateId: $documentTemplateId
      needsBoardApproval: $needsBoardApproval
    ) {
      ...DocumentsApprovalPage_DocumentTemplate
    }
  }
`;

const SET_DOCUMENT_TEMPLATE_CONTENT_USED_FOR_APPROVAL_MUTATION = graphql`
  mutation DocumentsApprovalPage_SetDocumentTemplateContentUsedForApproval_Mutation(
    $documentTemplateId: UUID!
    $contentUsedForApproval: String
  ) {
    setDocumentTemplateContentUsedForApproval(
      documentTemplateId: $documentTemplateId
      contentUsedForApproval: $contentUsedForApproval
    ) {
      __typename
      ... on DocumentTemplateOperationSuccess {
        documentTemplate {
          ...DocumentsApprovalPage_DocumentTemplate
        }
      }
      ... on DocumentTemplateOperationFailure {
        error
      }
    }
  }
`;

const SET_ORGANIZATION_REQUIRES_DOCUMENT_APPROVAL_MUTATION = graphql`
  mutation DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation(
    $organizationId: OrganizationId!
    $requiresDocumentApproval: Boolean!
  ) {
    setOrganizationRequiresDocumentApproval(
      organizationId: $organizationId
      requiresDocumentApproval: $requiresDocumentApproval
    ) {
      requiresDocumentApproval
    }
  }
`;

type DocumentTemplate = DocumentsApprovalPage_DocumentTemplate$data[number];
type DocumentTemplateType = DocumentTemplate["type"];

const schema = z.object({
  contentUsedForApproval: z.string().nullable(),
});

const DocumentTemplateTypeToLabel: Record<DocumentTemplateType, string> = {
  AWARD_AGREEMENT: "Award agreement",
  BOARD_CONSENT: "Board consent",
  COUNTRY_SPECIFIC_ADDENDUM: "Country specific addendum",
  FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT:
    "Form of early exercise stock purchase agreement",
  FORM_OF_EXERCISE_AGREEMENT: "Form of exercise agreement",
  GRANT_NOTICE: "Grant notice",
};

const UpdateDocumentTemplateContentUsedForApprovalForm: React.FC<{
  documentTemplate: DocumentTemplate;
}> = ({ documentTemplate }) => {
  const [
    setDocumentTemplateContentUsedForApproval,
    setDocumentTemplateContentUsedForApprovalIsInFlight,
  ] =
    useSafeMutation<DocumentsApprovalPage_SetDocumentTemplateContentUsedForApproval_Mutation>(
      SET_DOCUMENT_TEMPLATE_CONTENT_USED_FOR_APPROVAL_MUTATION,
    );

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      contentUsedForApproval: documentTemplate.contentUsedForApproval,
    },
    resolver: zodResolver(schema),
  });

  const [newContentUsedForApproval] = watch(["contentUsedForApproval"]);

  const formHasChanged = useMemo(() => {
    if (isEmpty(newContentUsedForApproval)) {
      return !isEmpty(documentTemplate.contentUsedForApproval);
    }

    return (
      newContentUsedForApproval !== documentTemplate.contentUsedForApproval
    );
  }, [newContentUsedForApproval, documentTemplate.contentUsedForApproval]);

  const alerter = useAlerter();

  const onSubmit = handleSubmit(async (_formInputs) => {
    const formInputs = _formInputs as z.infer<typeof schema>;
    const { setDocumentTemplateContentUsedForApproval: result } =
      await setDocumentTemplateContentUsedForApproval({
        variables: {
          contentUsedForApproval: formInputs.contentUsedForApproval,
          documentTemplateId: documentTemplate.id,
        },
      });

    if (result.__typename === "DocumentTemplateOperationFailure") {
      alerter.push(
        <Alert title="Error saving the document">
          There is an error in the document you try to modify. See details:
          <br />
          {result.error}
        </Alert>,
      );
      return;
    }
  });

  return (
    <form className="space-y-4 py-4" onSubmit={onSubmit}>
      <div className="flex w-full gap-4">
        <div className="flex-1 space-y-2">
          <Typography as="div" variant="Medium/Extra Small">
            Original content
          </Typography>
          <TextArea
            className="h-[400px] resize-none"
            disabled={true}
            value={documentTemplate.content}
          />
        </div>
        <div className="flex-1 space-y-2">
          <Typography as="div" variant="Medium/Extra Small">
            Approval content
          </Typography>
          <TextArea
            className="h-[400px] resize-none"
            placeholder="Content used for document approval..."
            {...control.register("contentUsedForApproval")}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button
          onClick={() => setValue("contentUsedForApproval", "")}
          size="small"
          type="button"
          variant="Danger Outline"
        >
          Clear content
        </Button>
        <Button
          onClick={() =>
            setValue("contentUsedForApproval", documentTemplate.content)
          }
          size="small"
          type="button"
          variant="Primary Outline"
        >
          Copy original content
        </Button>
        <Button
          disabled={!formHasChanged}
          loading={setDocumentTemplateContentUsedForApprovalIsInFlight}
          size="small"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const DocumentTemplateTypesTabs = [
  "AWARD_AGREEMENT",
  "GRANT_NOTICE",
  "FORM_OF_EXERCISE_AGREEMENT",
  "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
  "BOARD_CONSENT",
] as const;

const DocumentsApprovalPage_: React.FC<{
  documentTemplatesFragment: DocumentsApprovalPage_DocumentTemplate$key;
  organizationsFragment: DocumentsApprovalPage_Organization$key;
}> = ({ documentTemplatesFragment, organizationsFragment }) => {
  const documentTemplates = useFragment(
    DOCUMENT_TEMPLATE_FRAGMENT,
    documentTemplatesFragment,
  );
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const [_setDocumentTemplateNeedsBoardApproval] =
    useSafeMutation<DocumentsApprovalPage_SetDocumentTemplateNeedsBoardApproval_Mutation>(
      SET_DOCUMENT_TEMPLATE_NEEDS_BOARD_APPROVAL_MUTATION,
    );

  const toggleDocumentTemplateNeedsBoardApproval = useCallback(
    async (documentTemplate: DocumentTemplate) => {
      await _setDocumentTemplateNeedsBoardApproval({
        variables: {
          documentTemplateId: documentTemplate.id,
          needsBoardApproval: !documentTemplate.needsBoardApproval,
        },
      });
    },
    [_setDocumentTemplateNeedsBoardApproval],
  );

  const documentTemplatesGroupedByType = useMemo(
    () =>
      chain(documentTemplates)
        .sortBy((documentTemplate) => documentTemplate.name)
        .groupBy((documentTemplate) => documentTemplate.type)
        .value(),
    [documentTemplates],
  );

  const [
    organizationsRequiringDocumentApproval,
    organizationsNotRequiringDocumentApproval,
  ] = useMemo(
    () =>
      partition(
        organizations,
        (organization) => organization.requiresDocumentApproval,
      ),
    [organizations],
  );

  const [selectedOrganization, setSelectedOrganization] = useState<
    (typeof organizations)[number] | null
  >(null);

  const [_setOrganizationRequiresDocumentApproval] =
    useSafeMutation<DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation>(
      SET_ORGANIZATION_REQUIRES_DOCUMENT_APPROVAL_MUTATION,
    );

  const toaster = useToaster();

  const requireDocumentApprovalForOrganization = useCallback(async () => {
    if (!selectedOrganization) return;
    await _setOrganizationRequiresDocumentApproval({
      variables: {
        organizationId: selectedOrganization.id,
        requiresDocumentApproval: true,
      },
    });
    toaster.push(
      <Toast title="Fine">
        {selectedOrganization.name} now requires document approval.
      </Toast>,
    );
    setSelectedOrganization(null);
  }, [_setOrganizationRequiresDocumentApproval, selectedOrganization, toaster]);

  const removeOrganizationFromOrganizationsRequiringDocumentApproval =
    useCallback(
      async (
        organization: (typeof organizationsRequiringDocumentApproval)[0],
      ) => {
        await _setOrganizationRequiresDocumentApproval({
          variables: {
            organizationId: organization.id,
            requiresDocumentApproval: false,
          },
        });
        toaster.push(
          <Toast title="Alright">
            {organization.name} no longer requires document approval.
          </Toast>,
        );
      },
      [_setOrganizationRequiresDocumentApproval, toaster],
    );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Legal content</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Documents approval</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex-grow space-y-2">
            <Typography variant="Medium/Default">
              Organizations needing documents approval
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Select organizations whose board members must approve the grant
              documentation
            </Typography>
          </div>
        </div>
        <div className="flex items-center justify-between gap-4">
          <SelectAutocomplete
            className="max-w-[300px]"
            getOptionLabel={({ name }) => name}
            getOptionValue={({ id }) => id}
            onChange={(organization) => setSelectedOrganization(organization)}
            options={organizationsNotRequiringDocumentApproval}
            placeholder="Select an organization"
            value={selectedOrganization}
          />
          <Button
            disabled={!selectedOrganization}
            onClick={() => requireDocumentApprovalForOrganization()}
            type="button"
          >
            Require documents approval for that organization
          </Button>
        </div>

        <Table className="w-full">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Organization</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {organizationsRequiringDocumentApproval.map((organization) => (
              <Table.Row key={organization.id}>
                <Table.Cell variant="Medium/Small">
                  <Link
                    to={generatePath(
                      APPLICATION_ROUTES["organizationDocumentsBoardConsents"],
                      { organizationId: organization.id },
                    )}
                  >
                    {organization.name}
                  </Link>
                </Table.Cell>
                <Table.Cell className="text-right">
                  <Button
                    onClick={() =>
                      removeOrganizationFromOrganizationsRequiringDocumentApproval(
                        organization,
                      )
                    }
                    size="small"
                    type="button"
                    variant="Secondary Full"
                  >
                    Remove from list
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </RoundedBox>
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex-grow space-y-2">
            <Typography variant="Medium/Default">
              Documents Approvals
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Configure documents that need to be approved by board members
            </Typography>
          </div>
        </div>
        <Tab.Group className="px-6 pb-6">
          <Tab.List>
            {DocumentTemplateTypesTabs.map((type) => (
              <Tab key={type}>{DocumentTemplateTypeToLabel[type]}</Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {DocumentTemplateTypesTabs.map((type) => (
              <Tab.Panel key={type}>
                <div className="space-y-2">
                  {(documentTemplatesGroupedByType[type] ?? []).map(
                    (documentTemplate, index) => (
                      <>
                        {index > 0 && <Divider />}
                        <Disclosure>
                          {({ open }) => (
                            <div>
                              <Disclosure.Button className="flex w-full items-center gap-2 text-left">
                                {type !== "BOARD_CONSENT" && (
                                  <Toggle
                                    enabled={
                                      documentTemplate.needsBoardApproval
                                    }
                                    onChange={() =>
                                      toggleDocumentTemplateNeedsBoardApproval(
                                        documentTemplate,
                                      )
                                    }
                                    size="small"
                                  />
                                )}
                                <Typography
                                  as="div"
                                  className="flex-1"
                                  variant="Medium/Small"
                                >
                                  {documentTemplate.name}
                                </Typography>

                                <ChevronDownIcon
                                  className={classNames(
                                    {
                                      "rotate-180": open,
                                    },
                                    "h-4",
                                  )}
                                />
                              </Disclosure.Button>
                              <Transition
                                enter="transition duration-100 ease-out"
                                enterFrom="transform scale-95 opacity-0"
                                enterTo="transform scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom="transform scale-100 opacity-100"
                                leaveTo="transform scale-95 opacity-0"
                              >
                                <Disclosure.Panel>
                                  <UpdateDocumentTemplateContentUsedForApprovalForm
                                    documentTemplate={documentTemplate}
                                  />
                                </Disclosure.Panel>
                              </Transition>
                            </div>
                          )}
                        </Disclosure>
                      </>
                    ),
                  )}
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query DocumentsApprovalPage_Query {
    documentsTemplates {
      ...DocumentsApprovalPage_DocumentTemplate
    }
    organizations {
      ...DocumentsApprovalPage_Organization
    }
  }
`;

const DocumentsApprovalPage: React.FC = () => {
  const {
    query: { documentsTemplates, organizations },
  } = useQuery<DocumentsApprovalPage_Query>(QUERY, {});

  return (
    <Page
      analyticsName="Super Admin - Documents Approval"
      title={`Super admin | documents approval`}
    >
      <DocumentsApprovalPage_
        documentTemplatesFragment={documentsTemplates}
        organizationsFragment={organizations}
      />
    </Page>
  );
};

export default DocumentsApprovalPage;
