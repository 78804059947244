export type EditorSection = (typeof EDITOR_SECTIONS)[number];
export type EditorSectionClassAttr = NonNullable<EditorSection["classAttr"]>;

export const EDITOR_SECTIONS = [
  {
    classAttr: null,
    containsTextOnly: true,
    gDocStyles: [
      {
        prop: "fontSize",
        value: "15pt",
      },
      {
        prop: "textDecoration",
        value: "none",
      },
    ],
    onlyReplaceWhenIsolated: true,
    tagName: "h3",
    title: "Title 3",
  },
  {
    classAttr: null,
    containsTextOnly: true,
    gDocStyles: [
      {
        prop: "fontWeight",
        value: "bold",
      },
      {
        prop: "fontSize",
        value: "11pt",
      },
    ],
    onlyReplaceWhenIsolated: true,
    tagName: "h4",
    title: "Title 4",
  },
  {
    classAttr: "wysiwygInfo",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(224, 234, 252)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "aside",
    title: "Info",
  },
  {
    classAttr: "wysiwygNotice",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(204, 204, 204)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Notice",
  },
  {
    classAttr: "wysiwygTag",
    containsTextOnly: false,
    gDocStyles: null,
    onlyReplaceWhenIsolated: false,
    tagName: "span",
    title: "Tag",
  },
  {
    classAttr: "wysiwygCalloutSuccess",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(187, 255, 187)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Callout ✅",
  },
  {
    classAttr: "wysiwygCalloutWarning",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(255, 246, 221)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Callout 💡",
  },
  {
    classAttr: "wysiwygCalloutDanger",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(255, 197, 197)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Callout 🛎️",
  },
  {
    classAttr: "wysiwygCalloutExample",
    containsTextOnly: false,
    gDocStyles: [{ prop: "backgroundColor", value: "rgb(86, 140, 227)" }],
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Callout 👉",
  },
  {
    classAttr: "wysiwygShadowBox",
    containsTextOnly: false,
    gDocStyles: null,
    onlyReplaceWhenIsolated: false,
    tagName: "section",
    title: "Shadow box",
  },
] as const;
