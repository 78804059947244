/**
 * @generated SignedSource<<6448a44e07997c09ae12a78c3e04e701>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type HRISProviderEmployeeField = "Email" | "JobTitle" | "Name" | "StartDate" | "TaxResidenceCountryCode" | "USStateOfResidenceCode" | "WorkRelationship";
import { FragmentRefs } from "relay-runtime";
export type CleanupGranteesItem_UpdateContent_HRISProviderEmployee$data = {
  readonly changesSinceLastAcknowledgement: ReadonlyArray<HRISProviderEmployeeField>;
  readonly hRISProvider: HRISProvider;
  readonly " $fragmentType": "CleanupGranteesItem_UpdateContent_HRISProviderEmployee";
};
export type CleanupGranteesItem_UpdateContent_HRISProviderEmployee$key = {
  readonly " $data"?: CleanupGranteesItem_UpdateContent_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_UpdateContent_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CleanupGranteesItem_UpdateContent_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "changesSinceLastAcknowledgement",
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "5b96b23fd1ac9bb79eb6ca5a8f4c361b";

export default node;
