export default function MessageQuestionIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      fill="currentColor"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/bulk/message-question">
        <g id="message-question">
          <path
            d="M22.6665 24.5736H17.3332L11.3998 28.5203C10.5198 29.1069 9.33317 28.4802 9.33317 27.4136V24.5736C5.33317 24.5736 2.6665 21.9069 2.6665 17.9069V9.9069C2.6665 5.9069 5.33317 3.24023 9.33317 3.24023H22.6665C26.6665 3.24023 29.3332 5.9069 29.3332 9.9069V17.9069C29.3332 21.9069 26.6665 24.5736 22.6665 24.5736Z"
            id="Vector"
            opacity="0.4"
          />
          <g id="Group">
            <path
              d="M15.9999 16.1465C15.4533 16.1465 14.9999 15.6932 14.9999 15.1465V14.8665C14.9999 13.3199 16.1332 12.5599 16.5599 12.2665C17.0532 11.9332 17.2132 11.7065 17.2132 11.3599C17.2132 10.6932 16.6666 10.1465 15.9999 10.1465C15.3333 10.1465 14.7866 10.6932 14.7866 11.3599C14.7866 11.9065 14.3333 12.3599 13.7866 12.3599C13.24 12.3599 12.7866 11.9065 12.7866 11.3599C12.7866 9.58653 14.2266 8.14648 15.9999 8.14648C17.7733 8.14648 19.2132 9.58653 19.2132 11.3599C19.2132 12.8799 18.0933 13.6398 17.6799 13.9198C17.1599 14.2665 16.9999 14.4932 16.9999 14.8665V15.1465C16.9999 15.7065 16.5466 16.1465 15.9999 16.1465Z"
              id="Vector_2"
            />
          </g>
          <g id="Group_2">
            <path
              d="M16 19.4668C15.44 19.4668 15 19.0135 15 18.4668C15 17.9201 15.4533 17.4668 16 17.4668C16.5467 17.4668 17 17.9201 17 18.4668C17 19.0135 16.56 19.4668 16 19.4668Z"
              id="Vector_3"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
