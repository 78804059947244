/**
 * @generated SignedSource<<d935dcf9433ce684f253192c6514af53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningChart_Organization$data = {
  readonly availableSharesForPlanningNow: number;
  readonly planningExceptionalGrantsTotalShares: number;
  readonly planningNewHiresTotalShares: number;
  readonly planningPromotionGrantsTotalShares: number;
  readonly planningSharesLeft: number;
  readonly planningTenureGrantsTotalShares: number;
  readonly planningTerminationsTotalShares: number;
  readonly " $fragmentSpreads": FragmentRefs<"SharesValue_Organization">;
  readonly " $fragmentType": "PlanningChart_Organization";
};
export type PlanningChart_Organization$key = {
  readonly " $data"?: PlanningChart_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningChart_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanningChart_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availableSharesForPlanningNow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningNewHiresTotalShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningTenureGrantsTotalShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningPromotionGrantsTotalShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningExceptionalGrantsTotalShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningTerminationsTotalShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "planningSharesLeft",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f624950445ca2cee9af785fafb747c5d";

export default node;
