import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link, To, useSearchParams } from "react-router-dom";

export const BackButton: React.FC<{
  children?: React.ReactNode;
  linkTo?: To;
}> = ({ children, linkTo: _linkTo }) => {
  const [searchParams] = useSearchParams();
  const linkTo = _linkTo ?? { pathname: "..", search: searchParams.toString() };

  return (
    <Link className="flex items-center gap-6" to={linkTo}>
      <div className="rounded-lg border-[0.5px] border-gray-04 p-2">
        <ArrowLeftIcon className="m-auto h-full w-4" />
      </div>
      {children}
    </Link>
  );
};
