/**
 * @generated SignedSource<<c02c16e38c1d356d60bf4df7ba131607>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$data = ReadonlyArray<{
  readonly amendedLastDayToExercise: string | null;
  readonly grant: {
    readonly grantDate: string;
    readonly grantee: {
      readonly name: string;
    };
    readonly quantityIssued: number;
  };
  readonly numberOfSharesAccelerating: number | null;
  readonly numberOfSharesUnvestedAtGranteeTerminationPriorToAmendment: number | null;
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  } | null;
  readonly " $fragmentType": "ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest";
}>;
export type ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$key = ReadonlyArray<{
  readonly " $data"?: ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "grant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityIssued",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amendedLastDayToExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfSharesUnvestedAtGranteeTerminationPriorToAmendment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfSharesAccelerating",
      "storageKey": null
    }
  ],
  "type": "GranteeTerminationCTMSGrantAmendmentRequest",
  "abstractKey": null
};

(node as any).hash = "e598e176b3b1ee706e5960658d29e0e0";

export default node;
