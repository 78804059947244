import React, { useState } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import { GrantAmendmentRequestBox } from "../../../components/GrantAmendmentRequest/GrantAmendmentRequestBox";
import { GrantAmendmentRequestBoxPTEPSlideOverRemote } from "../../../components/GrantAmendmentRequest/GrantAmendmentRequestBoxPTEPSlide";
import { Page } from "../../../components/Page";
import { useToaster } from "../../../components/Toaster";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Button } from "../../../components/ui/Button";
import { ConfirmationModal } from "../../../components/ui/ConfirmationModal";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Toast } from "../../../components/ui/Toast";
import { Typography } from "../../../components/ui/Typography";
import { useBoolean } from "../../../hooks/useBoolean";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useGranteeTerminationCTMSGrantAmendmentRequestIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import { GrantAmendmentRequest } from "../../../services/grantAmendmentRequest";
import NotFoundPage from "../../NotFound/NotFound";
import { GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation } from "./__generated__/GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation.graphql";
import {
  GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest$key,
  GranteeTerminationCTMSGrantAmendmentRequestStatus,
} from "./__generated__/GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import { GranteeTerminationAmendmentRequest_Organization$key } from "./__generated__/GranteeTerminationAmendmentRequest_Organization.graphql";
import { GranteeTerminationAmendmentRequest_Query } from "./__generated__/GranteeTerminationAmendmentRequest_Query.graphql";
import { GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation } from "./__generated__/GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation.graphql";
import { GranteeTerminationAmendmentRequest_Viewer$key } from "./__generated__/GranteeTerminationAmendmentRequest_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTerminationAmendmentRequest_Organization on Organization {
    id
    name
    ...GrantAmendmentRequestBox_Organization
  }
`;

const AMENDMENT_REQUEST_FRAGMENT = graphql`
  fragment GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest {
    id
    status
    waiveCliff
    postTerminationExercisePeriod {
      id
    }
    vestingEndCustomDate
    vestingEndOption
    grant {
      id
      label
      ...GrantAmendmentRequestBox_CTMSGrant
      grantee {
        terminationInformations {
          terminationDate
          terminationLastDayAtTheCompany
        }
      }
    }
  }
`;

const UPDATE_AMENDMENT_MUTATION = graphql`
  mutation GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation(
    $granteeTerminationCTMSGrantAmendmentRequestId: UUID!
    $attributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes!
  ) {
    updateGranteeTerminationCTMSGrantAmendmentRequest(
      granteeTerminationCTMSGrantAmendmentRequestId: $granteeTerminationCTMSGrantAmendmentRequestId
      attributes: $attributes
    ) {
      id
    }
  }
`;

const DELETE_AMENDMENT_MUTATION = graphql`
  mutation GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation(
    $id: UUID!
  ) {
    deleteGranteeTerminationCTMSGrantAmendmentRequests(
      granteeTerminationCTMSGrantAmendmentRequestIds: [$id]
    ) {
      __typename
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GranteeTerminationAmendmentRequest_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...GrantAmendmentRequestBox_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

function getAmendmentListPath(
  amendmentStatus: GranteeTerminationCTMSGrantAmendmentRequestStatus,
  organizationId: string,
) {
  switch (amendmentStatus) {
    case "IMPLEMENTATION":
    case "IMPLEMENTED":
      return generatePath(
        APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsImplementation,
        {
          organizationId,
        },
      );
    case "PENDING_APPROVAL":
      return generatePath(
        APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval,
        {
          organizationId,
        },
      );
    case "READY_FOR_CONSENT":
      return generatePath(
        APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval,
        {
          organizationId,
        },
      );
    case "UNDER_REVIEW":
      return generatePath(
        APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsUnderReview,
        {
          organizationId,
        },
      );
  }
}

const AdminAmendmentRequestPage_: React.FC<{
  amendmentRequestFragment: GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest$key;
  organizationFragment: GranteeTerminationAmendmentRequest_Organization$key;
  viewerFragment: GranteeTerminationAmendmentRequest_Viewer$key;
}> = ({ amendmentRequestFragment, organizationFragment, viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const amendmentRequest = useFragment(
    AMENDMENT_REQUEST_FRAGMENT,
    amendmentRequestFragment,
  );
  const amendmentsListPath = getAmendmentListPath(
    amendmentRequest.status,
    organization.id,
  );

  const {
    setFalse: closeDeleteConfirmationModal,
    setTrue: openDeleteConfirmationModal,
    value: showDeleteConfirmationModal,
  } = useBoolean(false);

  const toaster = useToaster();
  const navigate = useNavigate();

  const [updatedGrantAmendmentRequest, setUpdatedGrantAmendmentRequest] =
    useState<GrantAmendmentRequest | null>(null);

  const [deleteGrantAmendmentRequest, deleteGrantMutationIsInFlight] =
    useSafeMutation<GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation>(
      DELETE_AMENDMENT_MUTATION,
    );

  const handleDeleteButtonClick = async () => {
    if (!amendmentRequest) {
      return;
    }

    await deleteGrantAmendmentRequest({
      variables: { id: amendmentRequest.id },
    });

    closeDeleteConfirmationModal();
    void navigate(amendmentsListPath, { replace: true });
  };

  const [updateGrantAmendmentRequest] =
    useSafeMutation<GranteeTerminationAmendmentRequest_UpdateGrantAmendment_Mutation>(
      UPDATE_AMENDMENT_MUTATION,
    );

  if (!amendmentRequest.grant.grantee.terminationInformations) {
    throw new Error("Grantee has no termination informations");
  }

  const submit = async () => {
    if (!updatedGrantAmendmentRequest) {
      throw new Error("No updated grant amendment request");
    }

    await updateGrantAmendmentRequest({
      variables: {
        attributes: {
          postTerminationExercisePeriodId:
            updatedGrantAmendmentRequest.postTerminationExercisePeriodId,
          vestingEndCustomDate:
            updatedGrantAmendmentRequest.vestingEndCustomDate,
          vestingEndOption: updatedGrantAmendmentRequest.vestingEndOption,
          waiveCliff: updatedGrantAmendmentRequest.waiveCliff,
        },
        granteeTerminationCTMSGrantAmendmentRequestId: amendmentRequest.id,
      },
    });

    toaster.push(
      <Toast title="Mind-blowing!">
        Grant amendment successfully updated!
      </Toast>,
    );
  };

  const readOnly = amendmentRequest.status !== "UNDER_REVIEW";

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to={amendmentsListPath}>Amendment</BreadCrumb.Link>
          <BreadCrumb.Link to=".">
            Amendment on grant {amendmentRequest.grant.label}
          </BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <GrantAmendmentRequestBoxPTEPSlideOverRemote.Provider>
        <RoundedBox withBorder>
          <Typography as="div" className="p-10 pb-6" variant="Medium/Default">
            Amendment on grant {amendmentRequest.grant.label}
          </Typography>
          <div className="bg-gray-01 px-10 py-6">
            <GrantAmendmentRequestBox
              ctmsGrantFragment={amendmentRequest.grant}
              grantAmendment={
                updatedGrantAmendmentRequest ?? {
                  ctmsGrantId: amendmentRequest.grant.id,
                  postTerminationExercisePeriodId:
                    amendmentRequest.postTerminationExercisePeriod?.id,
                  vestingEndCustomDate:
                    amendmentRequest.vestingEndCustomDate ?? undefined,
                  vestingEndOption:
                    amendmentRequest.vestingEndOption ?? undefined,
                  waiveCliff: amendmentRequest.waiveCliff ?? false,
                }
              }
              onChange={(grantAmendmentRequestUpdate) => {
                setUpdatedGrantAmendmentRequest({
                  ...{
                    postTerminationExercisePeriod:
                      amendmentRequest.postTerminationExercisePeriod?.id,
                    vestingEndCustomDate:
                      amendmentRequest.vestingEndCustomDate ?? undefined,
                    vestingEndOption:
                      amendmentRequest.vestingEndOption ?? undefined,
                    waiveCliff: amendmentRequest.waiveCliff ?? false,
                  },
                  ...updatedGrantAmendmentRequest,
                  ...grantAmendmentRequestUpdate,
                });

                return grantAmendmentRequestUpdate;
              }}
              organizationFragment={organization}
              readOnly={readOnly}
              terminationDate={
                amendmentRequest.grant.grantee.terminationInformations
                  .terminationDate
              }
              terminationLastDayAtTheCompany={
                amendmentRequest.grant.grantee.terminationInformations
                  .terminationLastDayAtTheCompany
              }
              viewerFragment={viewer}
            />
          </div>
          {viewer.isAllowedToManageOrganization && (
            <div className="flex justify-between p-10 pt-6">
              <div className="flex gap-2">
                <Button
                  onClick={() =>
                    navigate(amendmentsListPath, { replace: true })
                  }
                  size="small"
                  variant="Secondary Full"
                >
                  Cancel
                </Button>
                <Button
                  disabled={deleteGrantMutationIsInFlight}
                  onClick={openDeleteConfirmationModal}
                  size="small"
                  variant="Danger Outline"
                >
                  Delete
                </Button>
              </div>
              {readOnly ? null : (
                <Button
                  disabled={!updatedGrantAmendmentRequest}
                  onClick={() => submit()}
                  size="small"
                  variant="Primary Full"
                >
                  Confirm
                </Button>
              )}
            </div>
          )}
        </RoundedBox>

        <ConfirmationModal
          confirmationLabel="Delete"
          loading={deleteGrantMutationIsInFlight}
          onClose={closeDeleteConfirmationModal}
          onConfirmed={handleDeleteButtonClick}
          show={showDeleteConfirmationModal}
          title="Delete grant amendment request?"
        >
          Are you sure you want to delete this grant amendment request?
        </ConfirmationModal>
      </GrantAmendmentRequestBoxPTEPSlideOverRemote.Provider>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query GranteeTerminationAmendmentRequest_Query(
    $organizationId: OrganizationId!
    $amendmentRequestId: UUID!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...GranteeTerminationAmendmentRequest_Organization
    }
    granteeTerminationCTMSGrantAmendmentRequest(id: $amendmentRequestId) {
      ...GranteeTerminationAmendmentRequest_GranteeTerminationCTMSGrantAmendmentRequest
    }
    me {
      ...GranteeTerminationAmendmentRequest_Viewer
        @arguments(organizationId: $organizationId)
    }
  }
`;

const GranteeTerminationCTMSGrantAmendmentRequestPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const amendmentRequestId =
    useGranteeTerminationCTMSGrantAmendmentRequestIdParam();
  const { query } = useQuery<GranteeTerminationAmendmentRequest_Query>(QUERY, {
    amendmentRequestId,
    organizationId,
  });

  if (
    !query.organization ||
    !query.granteeTerminationCTMSGrantAmendmentRequest
  ) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Amendment Requests"
      analyticsName="Admin - Amendment Request"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} amendment request`}
    >
      <AdminAmendmentRequestPage_
        amendmentRequestFragment={
          query.granteeTerminationCTMSGrantAmendmentRequest
        }
        organizationFragment={query.organization}
        viewerFragment={query.me}
      />
    </Page>
  );
};

export default GranteeTerminationCTMSGrantAmendmentRequestPage;
