import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EquityOverviewCard_Organization$key } from "./__generated__/EquityOverviewCard_Organization.graphql";
import { EsopPoolProjectionGraph } from "./EsopPoolProjectionGraph";
import { SharesInDraftMessage } from "./SharesInDraftMessage";
import { Card } from "./ui/Card";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityOverviewCard_Organization on Organization {
    esopPoolShares
    poolAvailableShares
    poolDraftedSharesBreakdown {
      total
    }
    ...SharesInDraftMessage_Organization
    ...EsopPoolProjectionGraph_Organization
  }
`;

export const EquityOverviewCard: React.FC<{
  className?: string;
  hideDraftsWatchout?: boolean;
  onCreateGrantButtonClick?: () => void;
  organizationFragment: EquityOverviewCard_Organization$key;
}> = ({
  className,
  hideDraftsWatchout = false,
  onCreateGrantButtonClick,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const availableSharesWithoutDrafted =
    organization.poolAvailableShares -
    organization.poolDraftedSharesBreakdown.total;

  if (organization.esopPoolShares === null) return null;

  return (
    <Card className={className} title="Equity overview">
      <div className="space-y-6">
        <EsopPoolProjectionGraph organizationFragment={organization} />

        <div className="space-y-2">
          {!hideDraftsWatchout && (
            <SharesInDraftMessage
              hasColor={false}
              organizationFragment={organization}
              size="Large"
              variant="Info"
            />
          )}

          {onCreateGrantButtonClick && availableSharesWithoutDrafted > 0 && (
            <NoticeMessage hasColor={false} size="Large" variant="Info">
              <FormattedMessage
                defaultMessage="<strong>{availableShares, number} shares</strong> are available for your team members in your current pool. Start granting equity incentives by {draftNewGrantButton}"
                values={{
                  availableShares: organization.poolAvailableShares,
                  draftNewGrantButton: (
                    <button
                      className="font-medium text-primary-04"
                      onClick={onCreateGrantButtonClick}
                      type="button"
                    >
                      creating a new draft
                    </button>
                  ),
                  strong: (chunks) => (
                    <Typography variant="Medium/Extra Small">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            </NoticeMessage>
          )}
        </div>
      </div>
    </Card>
  );
};
