/**
 * @generated SignedSource<<59289331e2bb4ca0417d65badf6b11da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type grantAmendmentRequest_useAmendedLastDayToExercise_Organization$data = {
  readonly id: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly duration: number;
    readonly durationUnit: DurationUnit;
    readonly id: string;
  }>;
  readonly " $fragmentType": "grantAmendmentRequest_useAmendedLastDayToExercise_Organization";
};
export type grantAmendmentRequest_useAmendedLastDayToExercise_Organization$key = {
  readonly " $data"?: grantAmendmentRequest_useAmendedLastDayToExercise_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"grantAmendmentRequest_useAmendedLastDayToExercise_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "grantAmendmentRequest_useAmendedLastDayToExercise_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationUnit",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "1ebc87a52ca3268d4160be301908263e";

export default node;
