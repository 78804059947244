/**
 * @generated SignedSource<<26205b9aaa364c65fd18d247b3e1f734>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountAttributes = {
  email: string;
  firstName: string;
  lastName: string;
};
export type InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation$variables = {
  attributes: AccountAttributes;
  organizationId: string;
};
export type InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation$data = {
  readonly createAccountAndAddItAsEquityViewer: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Organization">;
    };
  };
};
export type InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation = {
  response: InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation$data;
  variables: InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsEquityViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsEquityViewers_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsEquityViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityViewer",
                "kind": "LinkedField",
                "name": "equityViewers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eeff9644e4e0e957f96f16e73284239c",
    "id": null,
    "metadata": {},
    "name": "InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteEquityViewerSlideOver_CreateAccountAndAddItAsEquityViewer_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: AccountAttributes!\n) {\n  createAccountAndAddItAsEquityViewer(organizationId: $organizationId, attributes: $attributes, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsEquityViewers_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Item_EquityViewer on EquityViewer {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Organization on Organization {\n  id\n  name\n  equityViewers {\n    id\n    ...OrganizationSettingsEquityViewers_Item_EquityViewer\n  }\n}\n"
  }
};
})();

(node as any).hash = "5658870f65d1e5858597dd6e816596ad";

export default node;
