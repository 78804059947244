import React, { startTransition, useCallback } from "react";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { Page } from "../../../components/Page";
import { RedirectHandler } from "../../../components/RedirectHandler";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { FinishRemoteEquityOnboardingPage_Organization$key } from "./__generated__/FinishRemoteEquityOnboardingPage_Organization.graphql";
import {
  FinishRemoteEquityOnboardingPage_Query,
  RemoteOnboardingStep,
} from "./__generated__/FinishRemoteEquityOnboardingPage_Query.graphql";
import { FinishRemoteEquityOnboardingPage_Viewer$key } from "./__generated__/FinishRemoteEquityOnboardingPage_Viewer.graphql";
import { ConnectCTMSView } from "./ConnectCTMSView";
import { MatchGranteeView } from "./MatchGranteeView";
import { SubmitPricePerShareView } from "./SubmitPricePerShareView";
import { TestLawFirmCartaConnectionView } from "./TestLawFirmCartaConnectionView";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FinishRemoteEquityOnboardingPage_Organization on Organization
  @refetchable(
    queryName: "FinishRemoteEquityOnboardingPage_Organization_RefetchQuery"
  ) {
    currentRemoteOnboardingStep
    ...ConnectCTMSView_Organization
    ...SubmitPricePerShareView_Organization
    ...MatchGranteeView_Organization
    ...TestLawFirmCartaConnectionView_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment FinishRemoteEquityOnboardingPage_Viewer on Account {
    ...ConnectCTMSView_Viewer
  }
`;

const OnboardingStep: React.FC<{
  organizationFragment: FinishRemoteEquityOnboardingPage_Organization$key;
  step: RemoteOnboardingStep;
  viewerFragment: FinishRemoteEquityOnboardingPage_Viewer$key;
}> = ({ organizationFragment, step, viewerFragment }) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const onStepCompleted = useCallback(() => {
    startTransition(() => {
      refetchOrganization({});
    });
  }, [refetchOrganization]);

  switch (step) {
    case "CLEAN_UP_GRANTEE_DATA":
      return <MatchGranteeView organizationFragment={organization} />;
    case "CONNECT_CTMS":
      return (
        <ConnectCTMSView
          organizationFragment={organization}
          viewerFragment={viewer}
        />
      );
    case "SET_PRICE_PER_SHARE":
      return (
        <SubmitPricePerShareView
          onStepCompleted={onStepCompleted}
          organizationFragment={organization}
        />
      );
    case "TEST_LAW_FIRM_CARTA_CONNECTION":
      return (
        <TestLawFirmCartaConnectionView
          organizationFragment={organization}
          skippable
        />
      );
  }
};

const QUERY = graphql`
  query FinishRemoteEquityOnboardingPage_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      currentRemoteOnboardingStep
      ...FinishRemoteEquityOnboardingPage_Organization
    }
    me {
      ...FinishRemoteEquityOnboardingPage_Viewer
    }
  }
`;

const FinishRemoteEquityOnboardingPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const {
    query: { me: viewer, organization },
  } = useQuery<FinishRemoteEquityOnboardingPage_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  if (!organization.currentRemoteOnboardingStep) {
    return (
      <RedirectHandler
        to={generatePath(APPLICATION_ROUTES["organizationHome"], {
          organizationId: organization.id,
        })}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Admin Onboarding"
      analyticsName="Admin - Finish onboarding"
      organizationId={organization.id}
      title={`Admin | ${organization.name} Finish onboarding`}
    >
      <OnboardingStep
        organizationFragment={organization}
        step={organization.currentRemoteOnboardingStep}
        viewerFragment={viewer}
      />
    </Page>
  );
};

export default FinishRemoteEquityOnboardingPage;
