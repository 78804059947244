/**
 * @generated SignedSource<<5f00f10cc1f2c5dbdc051d92efa8f8a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityTypeWorkRelationshipCategory = "Contractor" | "DirectEmployee" | "EoREmployee";
export type EquityTypeExerciseParametersForWorkRelationshipAttributes = {
  benefits: ReadonlyArray<ExerciseBenefitInput>;
  ctmsTaxRateInPercent?: number | null;
  taxRanges: ReadonlyArray<ExerciseTaxRangeInput>;
  watchout?: string | null;
};
export type ExerciseBenefitInput = {
  content: string;
};
export type ExerciseTaxRangeInput = {
  description?: string | null;
  percentage: number;
};
export type ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation$variables = {
  attributes: EquityTypeExerciseParametersForWorkRelationshipAttributes;
  equityTypeId: string;
  workRelationship: EquityTypeWorkRelationshipCategory;
};
export type ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation$data = {
  readonly setEquityTypeExerciseParametersForWorkRelationship: {
    readonly exerciseParameters: {
      readonly contractor: {
        readonly benefits: ReadonlyArray<{
          readonly content: string;
        }>;
        readonly ctmsTaxRateInPercent: number | null;
        readonly taxRanges: ReadonlyArray<{
          readonly description: string | null;
          readonly percentage: number;
        }>;
        readonly watchout: string | null;
      } | null;
      readonly directEmployee: {
        readonly benefits: ReadonlyArray<{
          readonly content: string;
        }>;
        readonly ctmsTaxRateInPercent: number | null;
        readonly taxRanges: ReadonlyArray<{
          readonly description: string | null;
          readonly percentage: number;
        }>;
        readonly watchout: string | null;
      } | null;
      readonly eoREmployee: {
        readonly benefits: ReadonlyArray<{
          readonly content: string;
        }>;
        readonly ctmsTaxRateInPercent: number | null;
        readonly taxRanges: ReadonlyArray<{
          readonly description: string | null;
          readonly percentage: number;
        }>;
        readonly watchout: string | null;
      } | null;
    };
  };
};
export type ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation = {
  response: ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation$data;
  variables: ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityTypeId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workRelationship"
},
v3 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "equityTypeId",
    "variableName": "equityTypeId"
  },
  {
    "kind": "Variable",
    "name": "workRelationship",
    "variableName": "workRelationship"
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseTaxRange",
    "kind": "LinkedField",
    "name": "taxRanges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseBenefit",
    "kind": "LinkedField",
    "name": "benefits",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "watchout",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ctmsTaxRateInPercent",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "EquityTypeExerciseParameters",
  "kind": "LinkedField",
  "name": "exerciseParameters",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
      "kind": "LinkedField",
      "name": "directEmployee",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
      "kind": "LinkedField",
      "name": "eoREmployee",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
      "kind": "LinkedField",
      "name": "contractor",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "setEquityTypeExerciseParametersForWorkRelationship",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "setEquityTypeExerciseParametersForWorkRelationship",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e073e4c38b074724552c5ce549cfe665",
    "id": null,
    "metadata": {},
    "name": "ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation",
    "operationKind": "mutation",
    "text": "mutation ExercisesParametersPerWorkRelationshipEditionBlock_UpdateEquityTypeExerciseParameters_Mutation(\n  $equityTypeId: UUID!\n  $workRelationship: EquityTypeWorkRelationshipCategory!\n  $attributes: EquityTypeExerciseParametersForWorkRelationshipAttributes!\n) {\n  setEquityTypeExerciseParametersForWorkRelationship(equityTypeId: $equityTypeId, workRelationship: $workRelationship, attributes: $attributes) {\n    exerciseParameters {\n      directEmployee {\n        taxRanges {\n          description\n          percentage\n        }\n        benefits {\n          content\n        }\n        watchout\n        ctmsTaxRateInPercent\n      }\n      eoREmployee {\n        taxRanges {\n          description\n          percentage\n        }\n        benefits {\n          content\n        }\n        watchout\n        ctmsTaxRateInPercent\n      }\n      contractor {\n        taxRanges {\n          description\n          percentage\n        }\n        benefits {\n          content\n        }\n        watchout\n        ctmsTaxRateInPercent\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2f9feb6a915615a16f41ef4a6ee1e4d";

export default node;
