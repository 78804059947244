/**
 * @generated SignedSource<<ed76aaf87b3404fc02e5a74174361a87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillableGranteesSlideOver_Grantee$data = ReadonlyArray<{
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee" | "GranteeStatusTag_Grantee">;
  readonly " $fragmentType": "BillableGranteesSlideOver_Grantee";
}>;
export type BillableGranteesSlideOver_Grantee$key = ReadonlyArray<{
  readonly " $data"?: BillableGranteesSlideOver_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillableGranteesSlideOver_Grantee">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BillableGranteesSlideOver_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNameWithCountryFlag_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeStatusTag_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "37eade3b2b85201b2e863da1f39a1ae2";

export default node;
