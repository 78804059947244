/**
 * @generated SignedSource<<678c26bf8071455353bb7d4f4df90de2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantsTableStatusTag_Organization$data = {
  readonly isConnectedToCarta: boolean;
  readonly " $fragmentType": "GrantsTableStatusTag_Organization";
};
export type GrantsTableStatusTag_Organization$key = {
  readonly " $data"?: GrantsTableStatusTag_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTableStatusTag_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantsTableStatusTag_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6c6328bd6a339500c97272a00c8c2329";

export default node;
