import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

export const BoldBlueLink: React.FC<React.ComponentProps<typeof Link>> = ({
  className,
  ...props
}) => (
  <Link
    className={classNames(className, "font-medium text-primary")}
    {...props}
  />
);
