import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { RefresherTag_EasopGrant$key } from "./__generated__/RefresherTag_EasopGrant.graphql";
import { PLANNING_ENTRY_TYPE_LABELS } from "./PlanningEntryTypeTag";
import { Tag } from "./ui/Tag";

const GRANT_FRAGMENT = graphql`
  fragment RefresherTag_EasopGrant on EasopGrant {
    isRefresher
    planningEntrySource {
      ... on RefresherGrantPlanningEntry {
        refresherType
      }
    }
  }
`;

export const RefresherTag: React.FC<{
  grantFragment: RefresherTag_EasopGrant$key;
}> = ({ grantFragment }) => {
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);

  if (grant.isRefresher) {
    return (
      <Tag color="gray" size="Small">
        {grant.planningEntrySource?.refresherType
          ? PLANNING_ENTRY_TYPE_LABELS[grant.planningEntrySource.refresherType]
          : "Refresher"}
      </Tag>
    );
  }

  return (
    <Tag color="gray" size="Small">
      New hire
    </Tag>
  );
};
