import { useCallback, useState } from "react";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { PlanningEntryDeletionConfirmationModal_DeletePlanningEntry_Mutation } from "./__generated__/PlanningEntryDeletionConfirmationModal_DeletePlanningEntry_Mutation.graphql";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const DELETE_PLANNING_ENTRY_MUTATION = graphql`
  mutation PlanningEntryDeletionConfirmationModal_DeletePlanningEntry_Mutation(
    $planningEntryId: PlanningEntryId!
  ) {
    deletePlanningEntry(planningEntryId: $planningEntryId) {
      ...PlanningEditableListView_Organization
      ...Ignored_Planning_Organization
    }
  }
`;

type State =
  | {
      planningEntryId: string;
      shown: true;
    }
  | {
      planningEntryId?: null | string;
      shown: false;
    };

export const usePlanningEntryDeletionConfirmationModalState = () => {
  const [planningEntryDeletionConfirmationModalState, setState] =
    useState<State>({
      shown: false,
    });

  const showPlanningEntryDeletionConfirmationModal = useCallback(
    (planningEntryId: string) => {
      setState({
        planningEntryId,
        shown: true,
      });
    },
    [],
  );

  const closePlanningEntryDeletionConfirmationModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    closePlanningEntryDeletionConfirmationModal,
    planningEntryDeletionConfirmationModalState,
    showPlanningEntryDeletionConfirmationModal,
  };
};

export const PlanningEntryDeletionConfirmationModal: React.FC<{
  onClose: () => void;
  onPlanningEntryDeleted?: () => void;
  state: State;
}> = ({ onClose, onPlanningEntryDeleted, state }) => {
  const [deletePlanningEntry, deletionIsInflight] =
    useSafeMutation<PlanningEntryDeletionConfirmationModal_DeletePlanningEntry_Mutation>(
      DELETE_PLANNING_ENTRY_MUTATION,
    );

  const toaster = useToaster();

  const handleDeleteConfirmation = useCallback(async () => {
    const planningEntryId = state.planningEntryId;
    if (!planningEntryId) return;

    await deletePlanningEntry({
      variables: {
        planningEntryId,
      },
    });

    toaster.push(<Toast title="Alright!">Entry successfully deleted!</Toast>);

    onClose();
    onPlanningEntryDeleted?.();
  }, [
    toaster,
    onClose,
    deletePlanningEntry,
    onPlanningEntryDeleted,
    state.planningEntryId,
  ]);

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      dataCy="planning-entry-deletion-confirmation-modal"
      loading={deletionIsInflight}
      onClose={onClose}
      onConfirmed={handleDeleteConfirmation}
      show={state.shown}
      title="Confirm deletion"
    >
      Are you sure you want to delete this entry from the planning?
    </ConfirmationModal>
  );
};
