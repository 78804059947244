/**
 * @generated SignedSource<<0e8940c47482ee95e19f3617e80a5078>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountAttributes = {
  email: string;
  firstName: string;
  lastName: string;
};
export type InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation$variables = {
  attributes: AccountAttributes;
  organizationId: string;
};
export type InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation$data = {
  readonly createAccountAndAddItAsBoardMember: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsBoardMembers_Organization">;
    };
  };
};
export type InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation = {
  response: InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation$data;
  variables: InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsBoardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsBoardMembers_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsBoardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BoardMember",
                "kind": "LinkedField",
                "name": "boardMembers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isRemovable",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bae6bb0f69cc34a69bb562d24f5218b2",
    "id": null,
    "metadata": {},
    "name": "InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteBoardMemberSlideOver_CreateAccountAndAddItAsBoardMember_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: AccountAttributes!\n) {\n  createAccountAndAddItAsBoardMember(organizationId: $organizationId, attributes: $attributes, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsBoardMembers_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Item_BoardMember on BoardMember {\n  id\n  isRemovable\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsBoardMembers_Organization on Organization {\n  id\n  name\n  boardMembers {\n    id\n    account {\n      firstName\n      lastName\n      email\n      id\n    }\n    ...OrganizationSettingsBoardMembers_Item_BoardMember\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f71c51b65ac2573e0cea505a6f204a2";

export default node;
