import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { WantToKnowMoreBox_CTMSGrant$key } from "./__generated__/WantToKnowMoreBox_CTMSGrant.graphql";
import { WantToKnowMoreBox_Organization$key } from "./__generated__/WantToKnowMoreBox_Organization.graphql";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment WantToKnowMoreBox_CTMSGrant on CTMSGrant {
    grantee {
      taxResidenceCountry {
        name
        emoji
        code
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment WantToKnowMoreBox_Organization on Organization {
    id
  }
`;

export const WantToKnowMoreBox: React.FC<{
  className?: string;
  ctmsGrantFragment: WantToKnowMoreBox_CTMSGrant$key;
  organizationFragment: WantToKnowMoreBox_Organization$key;
}> = ({ className, ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(EXERCISE_REQUEST_FRAGMENT, ctmsGrantFragment);
  const { grantee } = ctmsGrant;

  if (!grantee.taxResidenceCountry) return null;

  return (
    <RoundedBox
      className={classNames(
        "flex items-center justify-between !rounded-[20px] p-6",
        className,
      )}
      withBorder
      withShadow
    >
      <div className="space-y-1">
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Want to know more about Reporting & Taxation in{" "}
          {grantee.taxResidenceCountry.emoji} {grantee.taxResidenceCountry.name}
          ?
        </Typography>
        <Typography as="div" variant="Medium/Extra Small">
          Discover our full set of information in the geographies portal
        </Typography>
      </div>
      <Link
        className="shrink-0"
        target="_blank"
        to={generatePath(APPLICATION_ROUTES["organizationCountry"], {
          countryCode: grantee.taxResidenceCountry.code,
          organizationId: organization.id,
        })}
      >
        <ArrowTopRightOnSquareIcon className="h-7 w-7 text-primary" />
      </Link>
    </RoundedBox>
  );
};
