import { AnalyticsBrowser } from "@segment/analytics-next";

import CONFIGURATION from "./configuration";

if (!CONFIGURATION.SEGMENT_WRITE_KEY) {
  throw new Error("SEGMENT_WRITE_KEY is not defined");
}

export const analytics = AnalyticsBrowser.load({
  writeKey: CONFIGURATION.SEGMENT_WRITE_KEY,
});
