import React from "react";

import { Typography, TypographyVariant } from "./Typography";

export const TextButton: React.FC<
  React.ComponentProps<"button"> & { variant?: TypographyVariant }
> = ({ children, type = "button", variant = "Medium/Caption", ...props }) => (
  <button type={type} {...props}>
    <Typography className="text-primary" variant={variant}>
      {children}
    </Typography>
  </button>
);
