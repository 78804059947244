/**
 * @generated SignedSource<<39a44090be1bd675279e00f55cdeded1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmSubmitBoardConsentSlide_EasopGrants$data = ReadonlyArray<{
  readonly id: string;
  readonly instrument: {
    readonly id: string;
    readonly requiresEligibilityAcknowledgmentBeforeGranting: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ExportSelectedItemsSection_EasopGrants">;
  readonly " $fragmentType": "ConfirmSubmitBoardConsentSlide_EasopGrants";
}>;
export type ConfirmSubmitBoardConsentSlide_EasopGrants$key = ReadonlyArray<{
  readonly " $data"?: ConfirmSubmitBoardConsentSlide_EasopGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_EasopGrants">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConfirmSubmitBoardConsentSlide_EasopGrants",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requiresEligibilityAcknowledgmentBeforeGranting",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AcknowledgementSection_ConfirmSubmitBoardConsentSlide_InstrumentsRequiringEligibilityAcknowledgment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportSelectedItemsSection_EasopGrants"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "29ea7e8bf72c78b0daa39776fca9e65f";

export default node;
