/**
 * @generated SignedSource<<747e94287e6bbf11aa92c0aa9b626b33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type DocumentsUpload_Query$variables = {
  search?: string | null;
};
export type DocumentsUpload_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly activeEquityPlanDocument: {
      readonly __typename: "Document";
    } | null;
    readonly id: string;
    readonly miscellaneousLegalDocuments: ReadonlyArray<{
      readonly document: {
        readonly updatedAt: string;
      };
    }>;
    readonly name: string;
    readonly unlockedEquityTypesRequiringActiveSubPlanDocument: ReadonlyArray<{
      readonly activeSubPlanDocument: {
        readonly __typename: "Document";
      } | null;
      readonly id: string;
      readonly name: EquityTypeName;
    }>;
  }>;
};
export type DocumentsUpload_Query = {
  response: DocumentsUpload_Query$data;
  variables: DocumentsUpload_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "requiresActiveSubPlanDocument": true
    }
  }
],
v8 = [
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsUpload_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMiscellaneousLegalDocument",
            "kind": "LinkedField",
            "name": "miscellaneousLegalDocuments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "activeEquityPlanDocument",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "unlockedEquityTypesRequiringActiveSubPlanDocument",
            "args": (v7/*: any*/),
            "concreteType": "OrganizationUnlockedEquityType",
            "kind": "LinkedField",
            "name": "unlockedEquityTypes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "activeSubPlanDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "unlockedEquityTypes(filters:{\"requiresActiveSubPlanDocument\":true})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsUpload_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMiscellaneousLegalDocument",
            "kind": "LinkedField",
            "name": "miscellaneousLegalDocuments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "document",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "activeEquityPlanDocument",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          {
            "alias": "unlockedEquityTypesRequiringActiveSubPlanDocument",
            "args": (v7/*: any*/),
            "concreteType": "OrganizationUnlockedEquityType",
            "kind": "LinkedField",
            "name": "unlockedEquityTypes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "activeSubPlanDocument",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": "unlockedEquityTypes(filters:{\"requiresActiveSubPlanDocument\":true})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8a78aa5df1388fc575bb2972aaeb47e",
    "id": null,
    "metadata": {},
    "name": "DocumentsUpload_Query",
    "operationKind": "query",
    "text": "query DocumentsUpload_Query(\n  $search: String\n) {\n  organizations(search: $search) {\n    id\n    name\n    miscellaneousLegalDocuments {\n      document {\n        updatedAt\n        id\n      }\n    }\n    activeEquityPlanDocument {\n      __typename\n      id\n    }\n    unlockedEquityTypesRequiringActiveSubPlanDocument: unlockedEquityTypes(filters: {requiresActiveSubPlanDocument: true}) {\n      id\n      name\n      activeSubPlanDocument {\n        __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e9c3d9d369951f41457e9222c1c37c6";

export default node;
