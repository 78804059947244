/**
 * @generated SignedSource<<425af0b3cc309c205b54e06f4e72a552>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripePaymentMethodLogo_StripePaymentMethod$data = {
  readonly cardBrand: string | null;
  readonly " $fragmentType": "StripePaymentMethodLogo_StripePaymentMethod";
};
export type StripePaymentMethodLogo_StripePaymentMethod$key = {
  readonly " $data"?: StripePaymentMethodLogo_StripePaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"StripePaymentMethodLogo_StripePaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StripePaymentMethodLogo_StripePaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardBrand",
      "storageKey": null
    }
  ],
  "type": "StripePaymentMethod",
  "abstractKey": null
};

(node as any).hash = "f915b87dd35568107f83936c401a160b";

export default node;
