/**
 * @generated SignedSource<<0236d37e2036633f3a0a6544bf4bc6e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type JobStatus = "ACTIVE" | "COMPLETED" | "CREATED" | "FAILED";
import { FragmentRefs } from "relay-runtime";
export type GrantsTableStatusTag_EasopGrant$data = {
  readonly boardConsent: {
    readonly isPandaDocDocumentSent: boolean;
    readonly voidedReason: BoardConsentVoidedReason | null;
  } | null;
  readonly createCTMSDraftJobStatus: JobStatus | null;
  readonly grantStatus: EasopGrantStatus;
  readonly " $fragmentType": "GrantsTableStatusTag_EasopGrant";
};
export type GrantsTableStatusTag_EasopGrant$key = {
  readonly " $data"?: GrantsTableStatusTag_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTableStatusTag_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantsTableStatusTag_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createCTMSDraftJobStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPandaDocDocumentSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voidedReason",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "96d4eaa7a97d1fbe35fe37cbc4476c9d";

export default node;
