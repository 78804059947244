import { zodResolver } from "@hookform/resolvers/zod";
import React, { Suspense, useCallback } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { makeRemoteController } from "../helpers/makeRemoteController";
import { useApplicationName } from "../hooks/useApplicationTheme";
import { useDownloadFairMarketValueBoardConsentPreview } from "../hooks/useDownloadBoardConsentPreview";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { FairMarketValueBoardApprovalSlider_FairMarketValue$key } from "./__generated__/FairMarketValueBoardApprovalSlider_FairMarketValue.graphql";
import { FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation } from "./__generated__/FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation.graphql";
import { FairMarketValueBoardApprovalSlider_Organization$key } from "./__generated__/FairMarketValueBoardApprovalSlider_Organization.graphql";
import { FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation } from "./__generated__/FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation.graphql";
import { ConfirmationNoticeMessage } from "./ConfirmationNoticeMessage";
import { BoardMembersSection } from "./ConfirmSubmitBoardConsentSlide/BoardMembersSection";
import { BoardNoteSection } from "./ConfirmSubmitBoardConsentSlide/BoardNoteSection";
import { Section } from "./ConfirmSubmitBoardConsentSlide/Section";
import { LoadingPlaceholder } from "./LoadingPlaceholder";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { Divider } from "./ui/Divider";
import {
  FileInput,
  useFileInputFormUploadController,
} from "./ui/Form/FileInput";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { RadioGroup } from "./ui/Form/RadioGroup";
import { SlideOver } from "./ui/SlideOver";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueBoardApprovalSlider_Organization on Organization {
    id
    allowBoardConsentHandledOutsideEasop
    boardMembers {
      id
    }
    ...BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization
    ...BoardNoteSection_Organization
  }
`;

const FAIR_MARKET_VALUE_FRAGMENT = graphql`
  fragment FairMarketValueBoardApprovalSlider_FairMarketValue on FairMarketValue {
    id
    date
  }
`;

const SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION = graphql`
  mutation FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation(
    $fairMarketValueId: ID!
  ) {
    sendFairMarketValueToBoardForConsent(
      fairMarketValueId: $fairMarketValueId
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION = graphql`
  mutation FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation(
    $fairMarketValueId: ID!
    $signedBoardConsentDocumentIds: [String!]!
    $boardConsentSignatureDate: Date!
  ) {
    importSignedBoardConsentApprovingFairMarketValue(
      fairMarketValueId: $fairMarketValueId
      signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds
      boardConsentSignatureDate: $boardConsentSignatureDate
    ) {
      valuationWarnings {
        reason
        valuationType
      }
    }
  }
`;

const SCHEMA = z.discriminatedUnion("flow", [
  z.object({
    flow: z.literal("Outside Easop"),

    acknowledgedEasopIsNotResponsibleForBoardConsent: z.literal(true, {
      message: "You must acknowledge the statement",
    }),
    boardConsentSignatureDate: z.string().date("You must select a date"),

    signedBoardConsentDocumentIds: z
      .array(z.string())
      .min(
        1,
        "You must import the board consent approving your 409A valuation",
      ),
  }),
  z.object({
    flow: z.literal("In app"),
  }),
]);

type FormInput = z.input<typeof SCHEMA> & { boardMembers: unknown };
type FormOutput = z.output<typeof SCHEMA>;

const FairMarketValueBoardApprovalSliderContent: React.FC<{
  fairMarketValueFragment: FairMarketValueBoardApprovalSlider_FairMarketValue$key;
  onClose: () => void;
  onValuationSentToBoard: () => void;
  organizationFragment: FairMarketValueBoardApprovalSlider_Organization$key;
}> = ({
  fairMarketValueFragment,
  onClose,
  onValuationSentToBoard,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const fairMarketValue = useFragment(
    FAIR_MARKET_VALUE_FRAGMENT,
    fairMarketValueFragment,
  );
  const applicationName = useApplicationName();

  const { control, handleSubmit, setError, setValue, watch } = useForm<
    FormInput,
    unknown,
    FormOutput
  >({
    defaultValues: {
      flow: "In app",
    },
    resolver: zodResolver(SCHEMA),
  });

  const [flow, acknowledgedEasopIsNotResponsibleForBoardConsent] = watch([
    "flow",
    "acknowledgedEasopIsNotResponsibleForBoardConsent",
  ]);

  const signedBoardConsentDocumentsFileInputUploadController =
    useFileInputFormUploadController({
      accept: "*",
      control,
      multiple: true,
      name: "signedBoardConsentDocumentIds",
    });

  const showBoardConsentHandledOutsideEasopRadioGroup =
    organization.allowBoardConsentHandledOutsideEasop;

  const [sendFairMarketValueToBoardForConsent, _mutationIsInFlight] =
    useSafeMutation<FairMarketValueBoardApprovalSlider_SendFairMarketValueToBoardForConsent_Mutation>(
      SEND_FAIR_MARKET_VALUE_TO_BOARD_FOR_CONSENT_MUTATION,
    );

  const [
    importSignedBoardConsentApprovingFairMarketValue,
    __mutationIsInFlight,
  ] =
    useSafeMutation<FairMarketValueBoardApprovalSlider_ImportSignedBoardConsentApprovingFairMarketValue_Mutation>(
      IMPORT_SIGNED_BOARD_CONSENT_APPROVING_FAIR_MARKET_VALUE_MUTATION,
    );

  const somethingIsLoading =
    _mutationIsInFlight ||
    __mutationIsInFlight ||
    signedBoardConsentDocumentsFileInputUploadController.someItemsAreNotReady;

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (formData: FormOutput) => {
    if (flow === "In app" && organization.boardMembers.length === 0) {
      setError("boardMembers", {
        message: "You haven't invited any board members",
      });

      return;
    }

    switch (formData.flow) {
      case "In app":
        await sendFairMarketValueToBoardForConsent({
          variables: {
            fairMarketValueId: fairMarketValue.id,
          },
        });
        toaster.push(
          <Toast title="Wonderful!">
            Your 409A was successfully sent to board for consent!
          </Toast>,
        );

        break;
      case "Outside Easop":
        await importSignedBoardConsentApprovingFairMarketValue({
          variables: {
            boardConsentSignatureDate: formData.boardConsentSignatureDate,
            fairMarketValueId: fairMarketValue.id,
            signedBoardConsentDocumentIds:
              formData.signedBoardConsentDocumentIds,
          },
        });

        toaster.push(
          <Toast title="Wonderful!">
            Your latest 409A is approved, you can now draft equity again!
          </Toast>,
        );

        break;
    }

    onClose();
    onValuationSentToBoard();
  });

  const {
    downloadFairMarketValueBoardConsentPreview,
    downloadIsInFlight: downloadIsInFlight,
  } = useDownloadFairMarketValueBoardConsentPreview({
    fairMarketValueId: fairMarketValue.id,
  });

  const onPreviewBoardConsentClick = useCallback(async () => {
    await downloadFairMarketValueBoardConsentPreview();
  }, [downloadFairMarketValueBoardConsentPreview]);

  return (
    <form className="space-y-10 p-6" onSubmit={onSubmit}>
      {showBoardConsentHandledOutsideEasopRadioGroup && (
        <>
          <Section
            subtitle="A new 409A valuation requires a board signature. Please select below how you want to proceed:"
            title="Approval"
          >
            <div className="space-y-6">
              <FormRow.Form
                control={control}
                label="Choose a way to approve your new 409A"
                name="flow"
              >
                <RadioGroup.Form control={control} name="flow">
                  <div className="space-y-2">
                    <RadioGroup.Card value="In app">
                      Generate and approve a board consent with Remote Equity
                    </RadioGroup.Card>
                    <RadioGroup.Card value="Outside Easop">
                      Upload your own board consent
                    </RadioGroup.Card>
                  </div>
                </RadioGroup.Form>
              </FormRow.Form>

              {flow === "Outside Easop" && (
                <>
                  <FormRow.Form
                    control={control}
                    label="Date of approval"
                    name="boardConsentSignatureDate"
                    subLabel="Input the date of approval of the 409A valuation"
                  >
                    <DatePicker.Form
                      control={control}
                      maxDate={new Date()}
                      name="boardConsentSignatureDate"
                      placeholder="Select a date"
                    />
                  </FormRow.Form>

                  <FormRow.Form
                    control={control}
                    label="Upload board consent"
                    name="signedBoardConsentDocumentIds"
                  >
                    <FileInput
                      {...signedBoardConsentDocumentsFileInputUploadController.inputProps}
                    />
                  </FormRow.Form>
                </>
              )}
            </div>
          </Section>

          <Divider />
        </>
      )}

      {flow === "In app" && (
        <>
          <FormRow.Form control={control} name="boardMembers">
            <BoardMembersSection organizationFragment={organization} />
          </FormRow.Form>
          <Divider />
          <BoardNoteSection
            organizationFragment={organization}
            type="FAIR_MARKET_VALUE"
          />
          <Divider />
        </>
      )}

      {flow === "Outside Easop" && (
        <>
          <FormRow.Form
            control={control}
            name="acknowledgedEasopIsNotResponsibleForBoardConsent"
          >
            <Section
              subtitle="Please confirm the following before proceeding"
              title="Acknowledgements"
            >
              <div className="space-y-4">
                <ConfirmationNoticeMessage
                  confirmed={acknowledgedEasopIsNotResponsibleForBoardConsent}
                  onConfirmClick={() => {
                    setValue(
                      "acknowledgedEasopIsNotResponsibleForBoardConsent",
                      true,
                    );
                  }}
                  size="Large"
                  variant="Info"
                >
                  I confirm that a valid resolution approving the 409A valuation
                  has been adopted by the board (either via an oral meeting, or
                  by way of written decision signed by all board members) on the
                  above date of approval, in accordance with the laws of
                  Delaware and the company&apos;s constitutional documents, and
                  that {applicationName} is not responsible for verifying its
                  content.
                </ConfirmationNoticeMessage>
              </div>
            </Section>
          </FormRow.Form>
          <Divider />
        </>
      )}

      <div className="space-y-4">
        <div className="flex justify-between gap-4">
          <Button
            loading={downloadIsInFlight}
            onClick={onPreviewBoardConsentClick}
            size="small"
            type="button"
            variant="Secondary Outline"
          >
            Preview board consent
          </Button>
          <div className="flex gap-4">
            <Button
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button loading={somethingIsLoading} size="small" type="submit">
              Validate your 409A
            </Button>
          </div>
        </div>
        {flow === "In app" && (
          <Typography
            as="div"
            className="text-right text-gray-09"
            variant="Regular/Caption"
          >
            Board members will be notified of the new approval via email
          </Typography>
        )}
      </div>
    </form>
  );
};

export const FairMarketValueBoardApprovalSliderRemote = makeRemoteController<{
  fairMarketValueFragment: FairMarketValueBoardApprovalSlider_FairMarketValue$key;
  onValuationSentToBoard: () => void;
  organizationFragment: FairMarketValueBoardApprovalSlider_Organization$key;
}>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <SlideOver
        floating
        header={
          <SlideOver.Header onClose={close}>
            Validate new 409A valuation
          </SlideOver.Header>
        }
        onClose={close}
        show={state.show}
      >
        <Suspense fallback={<LoadingPlaceholder />}>
          <FairMarketValueBoardApprovalSliderContent
            fairMarketValueFragment={state.data.fairMarketValueFragment}
            onClose={close}
            onValuationSentToBoard={state.data.onValuationSentToBoard}
            organizationFragment={state.data.organizationFragment}
          />
        </Suspense>
      </SlideOver>
    );
  },
});
