import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { CreateGranteeButton_Organization$key } from "./__generated__/CreateGranteeButton_Organization.graphql";
import {
  GranteeFormSlide,
  GranteeFormSlideState,
  useGranteeFormSlideState,
} from "./GranteeFormSlide";
import { Button, LinkButton } from "./ui/Button";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CreateGranteeButton_Organization on Organization {
    id
    ...GranteeFormSlide_Organization
  }
`;

export type CreateGranteeMode = "new-page" | "panel"; // | "manual";

export const CreateGranteeButton: React.FC<{
  defaultGranteeCreationValues?: GranteeFormSlideState["defaultValues"];
  mode: CreateGranteeMode;
  onGranteeCreated?: (granteeId: string) => void;
  organizationFragment: CreateGranteeButton_Organization$key;
}> = ({
  defaultGranteeCreationValues,
  mode,
  onGranteeCreated,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const {
    closeGranteeFormSlide,
    granteeFormSlideState,
    openGranteeFormSlideInCreateMode,
  } = useGranteeFormSlideState(
    defaultGranteeCreationValues
      ? { defaultValues: defaultGranteeCreationValues, open: true }
      : undefined,
  );

  function handleGranteeChange(granteeId: string) {
    if (onGranteeCreated) onGranteeCreated(granteeId);
    closeGranteeFormSlide();
  }

  return (
    <>
      <GranteeFormSlide
        onCancel={closeGranteeFormSlide}
        onGranteeCreated={handleGranteeChange}
        onGranteeUpdated={handleGranteeChange}
        organizationFragment={organization}
        state={granteeFormSlideState}
      />

      {mode === "new-page" ? (
        <LinkButton
          size="small"
          to={generatePath(APPLICATION_ROUTES["organizationNewGrantee"], {
            organizationId: organization.id,
          })}
        >
          Create a grantee
        </LinkButton>
      ) : (
        <Button
          leftIcon={<PlusIcon />}
          onClick={() => {
            openGranteeFormSlideInCreateMode();
          }}
          size="extra small"
          type="button"
          variant="Tertiary Link"
        >
          Add new grantee
        </Button>
      )}
    </>
  );
};
