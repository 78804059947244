/**
 * @generated SignedSource<<be0e7e4c97203862484ff54433a03788>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesActivityGraph_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestsListSlideOver_Organization" | "useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "ExercisesActivityGraph_Organization";
};
export type ExercisesActivityGraph_Organization$key = {
  readonly " $data"?: ExercisesActivityGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesActivityGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesActivityGraph_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseRequestsListSlideOver_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3e72013f38bd3e4f856c9b3cfbc733a3";

export default node;
