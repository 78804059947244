/**
 * @generated SignedSource<<f4a05e0893c15383d1b6ce225d46d7fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettings_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "OrganizationSettings_Account";
};
export type OrganizationSettings_Account$key = {
  readonly " $data"?: OrganizationSettings_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettings_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettings_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "d0b77e2a06dc130ecd0564fc5a2582f5";

export default node;
