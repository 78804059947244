/**
 * @generated SignedSource<<1196321573eafc9cd2c515e38a4544ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation$variables = {
  ceoNote: string;
  organizationId: string;
};
export type BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation$data = {
  readonly updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote: {
    readonly pendingGranteeTerminationGrantAmendmentCeoNote: string | null;
  };
};
export type BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation = {
  response: BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation$data;
  variables: BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ceoNote"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ceoNote",
    "variableName": "ceoNote"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pendingGranteeTerminationGrantAmendmentCeoNote",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e4818644cca02c69524f70f82d9cf1c",
    "id": null,
    "metadata": {},
    "name": "BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation(\n  $organizationId: OrganizationId!\n  $ceoNote: String!\n) {\n  updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote(id: $organizationId, ceoNote: $ceoNote) {\n    pendingGranteeTerminationGrantAmendmentCeoNote\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "9d16847b5b295b56aa61fcfe379d708c";

export default node;
