import {
  ArrowPathIcon,
  EnvelopeIcon,
  LinkIcon,
  Square2StackIcon,
} from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { HRISProviderEmployeeSuggestionReason } from "../pages/Admin/CleanupGrantees/SynchronizeEmployeesData/__generated__/SynchronizeEmployeesDataItem_HRISProviderEmployee.graphql";
import { CleanupGranteesItem_CreateContent_HRISProviderEmployee$key } from "./__generated__/CleanupGranteesItem_CreateContent_HRISProviderEmployee.graphql";
import { CleanupGranteesItem_SynchronizeContent_Grantee$key } from "./__generated__/CleanupGranteesItem_SynchronizeContent_Grantee.graphql";
import { CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee$key } from "./__generated__/CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee.graphql";
import { CleanupGranteesItem_UpdateContent_Grantee$key } from "./__generated__/CleanupGranteesItem_UpdateContent_Grantee.graphql";
import { CleanupGranteesItem_UpdateContent_HRISProviderEmployee$key } from "./__generated__/CleanupGranteesItem_UpdateContent_HRISProviderEmployee.graphql";
import { HRISProvider } from "./__generated__/GranteeForm_CreateGrantee_Mutation.graphql";
import { HRIS_PROVIDER_EMPLOYEE_FIELD_TO_LABEL_MAP } from "./MergeEmployeeTable";
import { DeelLogo } from "./ui/DeelLogo";
import { RemoteLogo } from "./ui/RemoteLogo";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const _CleanupGranteesItem: React.FC<{
  actions: React.ReactNode;
  children: React.ReactNode;
  listIndex: number;
  listLength: number;
}> = ({ actions, children, listIndex, listLength }) => {
  return (
    <RoundedBox className="overflow-hidden" withBorder withShadow>
      <div className="flex items-center gap-6 border-l-4 border-primary p-6">
        <Typography
          as="div"
          className="rounded bg-gray-02 p-2"
          variant="Medium/Extra Small"
        >
          {listIndex + 1}/{listLength}
        </Typography>
        <div className="flex-grow">{children}</div>
        <div className="flex items-center gap-4">{actions}</div>
      </div>
    </RoundedBox>
  );
};

const SuggestionReasonLabelMap: Record<
  HRISProviderEmployeeSuggestionReason,
  string
> = {
  SameEmail: "Same email address",
  SameName: "Same name",
  SimilarName: "Similar name",
};

const SuggestionReasonLine: React.FC<{
  reason: HRISProviderEmployeeSuggestionReason;
}> = ({ reason }) => (
  <Typography
    as="div"
    className="flex items-center gap-2 text-black-05"
    variant="Regular/Extra Small"
  >
    <EnvelopeIcon className="w-5" />
    <span>{SuggestionReasonLabelMap[reason]}</span>
  </Typography>
);

const HRIS_PROVIDER_EMPLOYEE_SYNCHRONIZE_CONTENT_FRAGMENT = graphql`
  fragment CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee on HRISProviderEmployee {
    name
    hRISProvider
  }
`;
const GRANTEE_SYNCHRONIZE_CONTENT_FRAGMENT = graphql`
  fragment CleanupGranteesItem_SynchronizeContent_Grantee on Grantee {
    name
  }
`;

const HRIS_PROVIDER_LOGO_MAP: Record<
  HRISProvider,
  React.ComponentType<{ className?: string }>
> = {
  DEEL: DeelLogo,
  REMOTE: RemoteLogo,
};

const SynchronizeContent: React.FC<{
  granteeFragment: CleanupGranteesItem_SynchronizeContent_Grantee$key;
  hRISProviderEmployeeFragment: CleanupGranteesItem_SynchronizeContent_HRISProviderEmployee$key;
  reason?: HRISProviderEmployeeSuggestionReason;
}> = ({ granteeFragment, hRISProviderEmployeeFragment, reason }) => {
  const hRISProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_SYNCHRONIZE_CONTENT_FRAGMENT,
    hRISProviderEmployeeFragment,
  );
  const grantee = useFragment(
    GRANTEE_SYNCHRONIZE_CONTENT_FRAGMENT,
    granteeFragment,
  );

  const HRISProviderLogo =
    HRIS_PROVIDER_LOGO_MAP[hRISProviderEmployee.hRISProvider];

  return (
    <div className="space-y-2">
      <Typography as="div" className="flex gap-4" variant="Medium/Default">
        <span>{hRISProviderEmployee.name}</span>
        <div className="rounded-lg bg-primary-02 p-1">
          <LinkIcon className="m-auto h-full w-5 text-primary" />
        </div>
        <span>{grantee.name}</span>
      </Typography>
      <Typography
        as="div"
        className="flex items-center gap-2"
        variant="Regular/Extra Small"
      >
        <span>Potential duplicate found from</span>
        <HRISProviderLogo className="h-[15px] object-contain" />
      </Typography>
      {reason && <SuggestionReasonLine reason={reason} />}
    </div>
  );
};

const HRIS_PROVIDER_EMPLOYEE_CREATE_CONTENT_FRAGMENT = graphql`
  fragment CleanupGranteesItem_CreateContent_HRISProviderEmployee on HRISProviderEmployee {
    name
    email
    hRISProvider
  }
`;

const CreateContent: React.FC<{
  hRISProviderEmployeeFragment: CleanupGranteesItem_CreateContent_HRISProviderEmployee$key;
}> = ({ hRISProviderEmployeeFragment }) => {
  const hRISProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_CREATE_CONTENT_FRAGMENT,
    hRISProviderEmployeeFragment,
  );

  const HRISProviderLogo =
    HRIS_PROVIDER_LOGO_MAP[hRISProviderEmployee.hRISProvider];

  return (
    <div className="space-y-2">
      <Typography as="div" className="flex gap-4" variant="Medium/Default">
        {hRISProviderEmployee.name}
      </Typography>
      <Typography
        as="div"
        className="flex items-center gap-2 text-black-05"
        variant="Regular/Extra Small"
      >
        <EnvelopeIcon className="w-5" />
        <span>{hRISProviderEmployee.email}</span>
      </Typography>
      <div className="flex items-center gap-2">
        <ArrowPathIcon className="w-5" />
        <HRISProviderLogo className="h-[15px] object-contain" />
      </div>
    </div>
  );
};

const HRIS_PROVIDER_EMPLOYEE_UPDATE_CONTENT_FRAGMENT = graphql`
  fragment CleanupGranteesItem_UpdateContent_HRISProviderEmployee on HRISProviderEmployee {
    hRISProvider
    changesSinceLastAcknowledgement
  }
`;

const GRANTEE_UPDATE_CONTENT_FRAGMENT = graphql`
  fragment CleanupGranteesItem_UpdateContent_Grantee on Grantee {
    name
  }
`;

const UpdateContent: React.FC<{
  granteeFragment: CleanupGranteesItem_UpdateContent_Grantee$key;
  hRISProviderEmployeeFragment: CleanupGranteesItem_UpdateContent_HRISProviderEmployee$key;
}> = ({ granteeFragment, hRISProviderEmployeeFragment }) => {
  const hRISProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_UPDATE_CONTENT_FRAGMENT,
    hRISProviderEmployeeFragment,
  );
  const grantee = useFragment(GRANTEE_UPDATE_CONTENT_FRAGMENT, granteeFragment);

  const HRISProviderLogo =
    HRIS_PROVIDER_LOGO_MAP[hRISProviderEmployee.hRISProvider];

  return (
    <div className="space-y-2">
      <Typography as="div" className="flex gap-4" variant="Medium/Default">
        {grantee.name}
      </Typography>
      {hRISProviderEmployee.changesSinceLastAcknowledgement.map((change) => (
        <Typography
          as="div"
          className="flex items-center gap-2 text-black-05"
          key={change}
          variant="Regular/Extra Small"
        >
          <Square2StackIcon className="w-5" />
          <span>
            New{" "}
            {HRIS_PROVIDER_EMPLOYEE_FIELD_TO_LABEL_MAP[change].toLowerCase()}{" "}
            detected
          </span>
        </Typography>
      ))}
      <div className="flex items-center gap-2">
        <ArrowPathIcon className="w-5" />
        <HRISProviderLogo className="h-[15px] object-contain" />
      </div>
    </div>
  );
};

export const CleanupGranteesItem = Object.assign(_CleanupGranteesItem, {
  CreateContent,
  SynchronizeContent,
  UpdateContent,
});
