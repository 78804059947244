/**
 * @generated SignedSource<<757e960f0bb5ea70d5b3a8befd3e8e73>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationAmendmentRequest_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBox_Organization">;
  readonly " $fragmentType": "GranteeTerminationAmendmentRequest_Organization";
};
export type GranteeTerminationAmendmentRequest_Organization$key = {
  readonly " $data"?: GranteeTerminationAmendmentRequest_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequest_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationAmendmentRequest_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantAmendmentRequestBox_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "d533bb1fb5627d9fcbf818e50fbc4292";

export default node;
