import { graphql, useFragment } from "react-relay";

import { Page } from "../../components/Page";
import { NegativeDivider } from "../../components/ui/Divider";
import { Scenario } from "../../components/ui/Scenario";
import { Typography } from "../../components/ui/Typography";
import { useDisplayFullyDilutedValuesInGranteePortal } from "../../hooks/useDisplayFullyDilutedValuesInGranteePortal";
import { useGranteePortalGrantType } from "../../hooks/useGranteePortal";
import { useQuery } from "../../hooks/useQuery";
import { useGranteeLayoutContext } from "../../layouts/GranteeEquityTypesGroupLayout/GranteeEquityTypesGroupLayout";
import { useGranteeIdParam } from "../../paths";
import NotFoundPage from "../NotFound/NotFound";
import { Projection_Grantee_CTMSGrant$key } from "./__generated__/Projection_Grantee_CTMSGrant.graphql";
import { Projection_Grantee_Grantee$key } from "./__generated__/Projection_Grantee_Grantee.graphql";
import { Projection_Grantee_Query } from "./__generated__/Projection_Grantee_Query.graphql";
import { GranteeOutcome } from "./GranteeOutcome";

const GRANTEE_FRAGMENT = graphql`
  fragment Projection_Grantee_Grantee on Grantee {
    organization {
      granteePortalSettings {
        showProjectionScenarios
      }
    }
    ...GranteeOutcome_Grantee
    ...useDisplayFullyDilutedValuesInGranteePortal_Grantee
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment Projection_Grantee_CTMSGrant on CTMSGrant @relay(plural: true) {
    ...GranteeOutcome_CTMSGrant
  }
`;

const GranteeProjectionPage_: React.FC<{
  ctmsGrantsFragment: Projection_Grantee_CTMSGrant$key;
  granteeFragment: Projection_Grantee_Grantee$key;
}> = ({ ctmsGrantsFragment, granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const ctmsGrants = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantsFragment);

  const { isExpertMode } = useGranteeLayoutContext();

  const { showProjectionScenarios } =
    grantee.organization.granteePortalSettings;

  const displayFullyDilutedValues = useDisplayFullyDilutedValuesInGranteePortal(
    { granteeFragment: grantee },
  );

  return (
    <>
      {showProjectionScenarios && displayFullyDilutedValues ? (
        <>
          {!isExpertMode ? (
            <>
              <Typography variant="Medium/Large">
                In the meantime, the value of your options might increase!
              </Typography>

              <Typography as="div" className="mt-6" variant="Regular/Small">
                As the company grows, it is likely that it will raise more money
                during what we call “fundraising events” (to keep growing). When
                fundraising events occur, your company&apos;s valuation will be
                re-evaluated, to assess what it is worth now. As your options
                correspond to a slice of the company, if the company is worth
                more, so will your slice!
              </Typography>

              <Typography className="mt-10" variant="Medium/Large">
                Let’s see what it could potentially be worth in the future:
              </Typography>
            </>
          ) : (
            <Typography variant="Medium/Large">Value projection</Typography>
          )}

          <div className="mt-6">
            <GranteeOutcome
              ctmsGrantsFragment={ctmsGrants}
              granteeFragment={grantee}
            />
          </div>

          <NegativeDivider />
        </>
      ) : null}

      <Typography variant="Medium/Large">
        You are probably wondering when you will eventually convert these
        options into cash 😉
      </Typography>

      <Typography as="div" className="mt-4" variant="Regular/Small">
        Now that you own a slice of the company, you will need to wait for an
        event when someone else can buy you this slice, be it:
      </Typography>

      <div className="mt-6 flex flex-col gap-4 md:flex-row md:gap-10">
        <Scenario
          className="flex-1"
          frequency="Most frequent"
          title="Acquisition (exit)"
        >
          If your company is sold to another company, meaning the other company
          will buy all the shares from all shareholders, including you
        </Scenario>
        <Scenario
          className="flex-1"
          frequency="Second most frequent"
          title="Secondary sale"
        >
          If some other companies or people are interested in buying a share of
          your company and your company has authorized you to sell your shares*
        </Scenario>
        <Scenario className="flex-1" frequency="Less frequent" title="IPO">
          If your company goes public, meaning that people can publicly buy and
          sell its shares on a stock market (e.g. NYSE, Nasdaq)
        </Scenario>
      </div>

      <Typography
        as="div"
        className="mt-6 text-black-05"
        variant="Regular/Caption"
      >
        * Your shares are subject to transfer restrictions
      </Typography>
    </>
  );
};

const QUERY = graphql`
  query Projection_Grantee_Query(
    $granteeId: GranteeId!
    $grantType: GrantType!
  ) {
    grantee(id: $granteeId) {
      ...Projection_Grantee_Grantee
      organization {
        id
        name
      }
      ctmsGrants(grantType: $grantType) {
        ...Projection_Grantee_CTMSGrant
      }
    }
  }
`;

const GranteeProjectionPage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const grantType = useGranteePortalGrantType();
  const { query } = useQuery<Projection_Grantee_Query>(QUERY, {
    granteeId,
    grantType,
  });
  const { isExpertMode } = useGranteeLayoutContext();

  if (!query.grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee view"
      analyticsName="Grantee - Projection"
      analyticsProperties={{
        expertiseMode: isExpertMode ? "expert" : "beginner",
      }}
      organizationId={query.grantee.organization.id}
      title={`Grantee | ${query.grantee.organization.name} projection`}
    >
      <GranteeProjectionPage_
        ctmsGrantsFragment={query.grantee.ctmsGrants}
        granteeFragment={query.grantee}
      />
    </Page>
  );
};

export default GranteeProjectionPage;
