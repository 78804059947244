/**
 * @generated SignedSource<<8d8cdd9b83363cdd78055a18c4f48ceb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefresherGrantPlanningEntryDetails_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsDraftsList_Organization" | "GranteeDetailsGrantsList_Organization" | "SharesValue_Organization">;
  readonly " $fragmentType": "RefresherGrantPlanningEntryDetails_Organization";
};
export type RefresherGrantPlanningEntryDetails_Organization$key = {
  readonly " $data"?: RefresherGrantPlanningEntryDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefresherGrantPlanningEntryDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefresherGrantPlanningEntryDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsGrantsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsDraftsList_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "300a7d1a3623a11cc1ff5b1bfd476ba0";

export default node;
