/**
 * @generated SignedSource<<fe4774dfaa6952f5818b9b1d2613c3be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestTaxInformationExport_CTMSExerciseRequest$data = {
  readonly ctmsGrant: {
    readonly grantee: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_CTMSGrant">;
  };
  readonly exercisePrice: number;
  readonly quantityExercised: number;
  readonly spread: number;
  readonly taxResidenceCountryIsKnown: boolean;
  readonly totalExercisePrice: number;
  readonly " $fragmentType": "ExerciseRequestTaxInformationExport_CTMSExerciseRequest";
};
export type ExerciseRequestTaxInformationExport_CTMSExerciseRequest$key = {
  readonly " $data"?: ExerciseRequestTaxInformationExport_CTMSExerciseRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestTaxInformationExport_CTMSExerciseRequest">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestTaxInformationExport_CTMSExerciseRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaxDetailsContent_CTMSGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "spread",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxResidenceCountryIsKnown",
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "bd5a86e65b22a57fe9dbc7330ac925ec";

export default node;
