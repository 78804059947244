/**
 * @generated SignedSource<<23239494b904f10e9d6ccbf7e56eef5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmSubmitBoardConsentSlide_FairMarketValue$data = {
  readonly id: string;
  readonly value: number;
  readonly " $fragmentType": "ConfirmSubmitBoardConsentSlide_FairMarketValue";
};
export type ConfirmSubmitBoardConsentSlide_FairMarketValue$key = {
  readonly " $data"?: ConfirmSubmitBoardConsentSlide_FairMarketValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_FairMarketValue">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConfirmSubmitBoardConsentSlide_FairMarketValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "dde79d22542b81fb6190584da10c8321";

export default node;
