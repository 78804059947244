import { isNil } from "lodash";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  ExerciseBox_CTMSGrant$data,
  ExerciseBox_CTMSGrant$key,
} from "./__generated__/ExerciseBox_CTMSGrant.graphql";
import { CtmsGrantActivePTEPValue } from "./CtmsGrantActivePTEPValue";
import { ExerciseStatusTag } from "./ExerciseStatusTag";
import { ShortDate } from "./ShortDate";
import { Progress } from "./ui/Progress";
import { RoundedBox } from "./ui/RoundedBox";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseBox_CTMSGrant on CTMSGrant {
    label
    grantStatus
    exerciseStatus
    quantityExercised
    cumulativeVested
    quantityIssued
    earlyExercise
    exercisePrice
    terminationDate
    exercisableUntilDate
    settled
    exercisableNonExercisedAmount
    grantDate
    isVirtual
    activePostTerminationExercisePeriod {
      ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod
    }
    matchingInstrument {
      name
    }
  }
`;

const ExercisedOverVestedPercentage: React.FC<{
  ctmsGrant: ExerciseBox_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  const ratio = ctmsGrant.quantityExercised / (ctmsGrant.cumulativeVested || 1);

  return <FormattedNumber style="percent" value={ratio} />;
};

const ExercisedOverGrantedPercentage: React.FC<{
  ctmsGrant: ExerciseBox_CTMSGrant$data;
}> = ({ ctmsGrant }) => {
  const ratio = ctmsGrant.quantityExercised / (ctmsGrant.quantityIssued || 1);

  return <FormattedNumber style="percent" value={ratio} />;
};

const GrantDetailsItem: React.FC<
  React.PropsWithChildren<{
    label: React.ReactNode;
    subLabel?: React.ReactNode;
  }>
> = ({ children, label, subLabel }) => {
  return (
    <div className="flex justify-between rounded bg-gray-01 p-4">
      <div className="space-y-1">
        <Typography as="div" variant="Regular/Extra Small">
          {label}
        </Typography>
        {subLabel && (
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Caption"
          >
            {subLabel}
          </Typography>
        )}
      </div>
      <Typography as="div" variant="Medium/Extra Small">
        {children}
      </Typography>
    </div>
  );
};

const ExerciseBox: React.FC<{
  ctmsGrantfragment: ExerciseBox_CTMSGrant$key;
}> = ({ ctmsGrantfragment }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantfragment);

  return (
    <RoundedBox withBorder withShadow>
      <div className="space-y-6 p-6">
        <div className="space-y-2">
          <div className="flex items-center gap-4">
            <Typography
              as="div"
              className="text-primary"
              variant="Medium/Default"
            >
              {ctmsGrant.label}
            </Typography>
            {ctmsGrant.grantStatus !== "Active" && (
              <Tag color="red">Terminated</Tag>
            )}
            <ExerciseStatusTag exerciseStatus={ctmsGrant.exerciseStatus} />
          </div>
          <Typography as="div" className="text-right" variant="Medium/Small">
            {ctmsGrant.earlyExercise ? (
              <ExercisedOverGrantedPercentage ctmsGrant={ctmsGrant} />
            ) : (
              <ExercisedOverVestedPercentage ctmsGrant={ctmsGrant} />
            )}
          </Typography>
          <Typography as="div" className="text-right" variant="Regular/Small">
            {ctmsGrant.earlyExercise ? (
              <FormattedMessage
                defaultMessage="<strong>{quantityExercised, number} of the {quantityGranted, number}</strong> shares exercised"
                values={{
                  quantityExercised: ctmsGrant.quantityExercised,
                  quantityGranted: ctmsGrant.quantityIssued,
                  strong: (chunks) => (
                    <Typography as="span" variant="Medium/Small">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                defaultMessage="<strong>{quantityExercised, number} of the {quantityVested, number}</strong> vested shares exercised"
                values={{
                  quantityExercised: ctmsGrant.quantityExercised,
                  quantityVested: ctmsGrant.cumulativeVested,
                  strong: (chunks) => (
                    <Typography as="span" variant="Medium/Small">
                      {chunks}
                    </Typography>
                  ),
                }}
              />
            )}
          </Typography>
          <Progress
            className="bg-gray-03"
            max={
              ctmsGrant.exerciseStatus
                ? ctmsGrant.quantityIssued
                : ctmsGrant.cumulativeVested
            }
          >
            <Progress.Value
              className="bg-primary"
              value={ctmsGrant.quantityExercised}
            />
          </Progress>
        </div>
        <div className="space-y-4">
          <GrantDetailsItem label="Early exercise">
            {ctmsGrant.earlyExercise ? (
              <Tag color="green">Allowed</Tag>
            ) : (
              <Tag color="red">Not allowed</Tag>
            )}
          </GrantDetailsItem>
          {!isNil(ctmsGrant.exercisePrice) && (
            <GrantDetailsItem label="Exercise price (one share)">
              <FormattedNumber
                currency="USD"
                style="currency"
                value={ctmsGrant.exercisePrice}
              />
            </GrantDetailsItem>
          )}
          {!isNil(ctmsGrant.exercisableNonExercisedAmount) && (
            <GrantDetailsItem label="Exercise price (all exercisable shares)">
              <FormattedNumber
                currency="USD"
                style="currency"
                value={ctmsGrant.exercisableNonExercisedAmount}
              />
            </GrantDetailsItem>
          )}
          {!isNil(ctmsGrant.exercisableNonExercisedAmount) && (
            <GrantDetailsItem
              label="Grant date"
              subLabel="Based on the date of board approval"
            >
              <ShortDate value={ctmsGrant.grantDate} />
            </GrantDetailsItem>
          )}
          {ctmsGrant.matchingInstrument && (
            <GrantDetailsItem label="Type">
              {ctmsGrant.matchingInstrument.name}
            </GrantDetailsItem>
          )}
          {ctmsGrant.terminationDate && (
            <GrantDetailsItem label="Termination date">
              <ShortDate value={ctmsGrant.terminationDate} />
            </GrantDetailsItem>
          )}
          {!ctmsGrant.settled &&
            (ctmsGrant.exercisableUntilDate ? (
              <GrantDetailsItem
                label="Last day to exercise"
                subLabel="Based on the date of termination"
              >
                <ShortDate value={ctmsGrant.exercisableUntilDate} />
              </GrantDetailsItem>
            ) : ctmsGrant.activePostTerminationExercisePeriod ? (
              <GrantDetailsItem
                label={
                  ctmsGrant.isVirtual
                    ? "Post-termination settlement period"
                    : "Post-termination exercise period"
                }
              >
                <CtmsGrantActivePTEPValue
                  activePostTerminationExercisePeriodFragment={
                    ctmsGrant.activePostTerminationExercisePeriod
                  }
                />
              </GrantDetailsItem>
            ) : null)}
        </div>
      </div>
    </RoundedBox>
  );
};

export default ExerciseBox;
