/**
 * @generated SignedSource<<c9121b0e9eb38b48ed9cc66b39607ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsAdmins_Organization$data = {
  readonly adminInvitationIsAllowed: boolean;
  readonly admins: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Item_Admin">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly remoteCompanyAdmins: ReadonlyArray<{
    readonly __typename: "RemoteCompanyAdmin";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteCompanyAdminsList_Organization">;
  readonly " $fragmentType": "OrganizationSettingsAdmins_Organization";
};
export type OrganizationSettingsAdmins_Organization$key = {
  readonly " $data"?: OrganizationSettingsAdmins_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsAdmins_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "adminInvitationIsAllowed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Admin",
      "kind": "LinkedField",
      "name": "admins",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationSettingsAdmins_Item_Admin"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "orderBy": {
              "direction": "ASC",
              "field": "name"
            }
          }
        }
      ],
      "concreteType": "RemoteCompanyAdmin",
      "kind": "LinkedField",
      "name": "remoteCompanyAdmins",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteCompanyAdminsList_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "42b15eed7e951ab786db345d94c0df91";

export default node;
