/**
 * @generated SignedSource<<5391e34da49bc0cf8e02203475f2eed0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useAccountRoles_Account$data = {
  readonly boardMembers: ReadonlyArray<{
    readonly id: string;
    readonly organization: {
      readonly id: string;
    };
  }>;
  readonly organizationsAsAdmin: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "useAccountRoles_Account";
};
export type useAccountRoles_Account$key = {
  readonly " $data"?: useAccountRoles_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"useAccountRoles_Account">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useAccountRoles_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organizationsAsAdmin",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "abcc15a2f67804c57b7b05fe40165cea";

export default node;
