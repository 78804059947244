/**
 * @generated SignedSource<<71f7194b2a9b45980995c8d0b220d301>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOfferPreviewLayout_EquityOffer$data = {
  readonly candidateAccessSecretKey: string;
  readonly " $fragmentType": "EquityOfferPreviewLayout_EquityOffer";
};
export type EquityOfferPreviewLayout_EquityOffer$key = {
  readonly " $data"?: EquityOfferPreviewLayout_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPreviewLayout_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferPreviewLayout_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "candidateAccessSecretKey",
      "storageKey": null
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "924a57cea77fb7376683cd4a6ca18f7d";

export default node;
