import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { Footer } from "../../Footer";
import { Typography } from "../Typography";

const BackToPreviousPageLink: React.FC = () => (
  <Link className="group flex items-center gap-4" to="..">
    <div className="rounded-lg border-[0.4px] border-gray-04 px-3 py-2 transition-all group-hover:shadow-100">
      <ArrowLeftIcon className="h-4 w-4" />
    </div>
    <Typography
      className="text-black-05 group-hover:text-black-07"
      variant="Regular/Extra Small"
    >
      Back to Integrations
    </Typography>
  </Link>
);

export const AlternativeTwoColumnsLayout: React.FC<{
  breadcrumb?: React.ReactNode;
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}> = ({ breadcrumb, leftColumn, rightColumn }) => (
  <div className="min-h-full space-y-9 bg-white p-6">
    {breadcrumb}
    <div className="container mx-auto max-w-[984px] space-y-6">
      <BackToPreviousPageLink />

      <div className="flex flex-wrap-reverse gap-6 md:flex-nowrap">
        <div className="flex-1">{leftColumn}</div>
        <div className="w-full md:max-w-[300px]">{rightColumn}</div>
      </div>
      <Footer />
    </div>
  </div>
);
