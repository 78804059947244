import { useMemo } from "react";

import { EquityTypeWorkRelationshipCategory } from "../../../services/workRelationship";

export const useExerciseRequestSteps = ({
  ctmsName,
  equityTypeWorkRelationshipCategory,
}: {
  ctmsName: null | string;
  equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory | null;
}) => {
  return useMemo(() => {
    switch (equityTypeWorkRelationshipCategory) {
      case "contractor":
        return {
          Approval: { current: 2 },
          Notify: null,
          TaxTreatment: {
            current: 1,
            next: `Approve the exercise in ${ctmsName ?? "your CTMS"}`,
          },
          total: 2,
        } as const;
      case "eoREmployee":
        return {
          Approval: {
            current: 2,
            next: "Send the taxation information to your EoR",
          },
          Notify: { current: 3 },
          TaxTreatment: {
            current: 1,
            next: `Approve the exercise in ${ctmsName ?? "your CTMS"}`,
          },
          total: 3,
        } as const;
      case "directEmployee":
      default:
        return {
          Approval: {
            current: 2,
            next: "Notify your payroll system or accountant",
          },
          Notify: { current: 3 },
          TaxTreatment: {
            current: 1,
            next: `Approve the exercise in ${ctmsName ?? "your CTMS"}`,
          },
          total: 3,
        } as const;
    }
  }, [equityTypeWorkRelationshipCategory, ctmsName]);
};
