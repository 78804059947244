/**
 * @generated SignedSource<<665f3c0302850b79981c75c733654e48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantAmendmentRequestBox_CTMSGrant$data = {
  readonly easopGrant: {
    readonly postTerminationExercisePeriod: {
      readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
      readonly thresholdForExtensionInYears: number;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  } | null;
  readonly grantee: {
    readonly contractStartDate: string | null;
    readonly name: string;
  };
  readonly id: string;
  readonly label: string;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_CliffWaivedWatchout_EquityType">;
    } | null;
  } | null;
  readonly quantityIssued: number;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantExerciseTag_CtmsGrant" | "CtmsGrantVestingTag_CtmsGrant" | "GrantAmendmentRequestBoxPTEP_CtmsGrant" | "GrantAmendmentRequestBoxVestingEnd_CtmsGrant">;
  readonly " $fragmentType": "GrantAmendmentRequestBox_CTMSGrant";
};
export type GrantAmendmentRequestBox_CTMSGrant$key = {
  readonly " $data"?: GrantAmendmentRequestBox_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBox_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantAmendmentRequestBox_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contractStartDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "postTerminationExercisePeriod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "thresholdForExtensionInYears",
                  "storageKey": null
                }
              ],
              "type": "OrganizationVariablePostTerminationExercisePeriod",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_CliffWaivedWatchout_EquityType"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantVestingTag_CtmsGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantExerciseTag_CtmsGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantAmendmentRequestBoxPTEP_CtmsGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantAmendmentRequestBoxVestingEnd_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "76c0ec61753f3cd9bf04604841ad3a7b";

export default node;
