import React from "react";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { RedirectHandler } from "../../../../components/RedirectHandler";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation } from "./__generated__/OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation.graphql";
import { OrganizationSettingsIntegrationDeel_Query } from "./__generated__/OrganizationSettingsIntegrationDeel_Query.graphql";
import deelLargeLogoSrc from "./deel-large-logo.png";
import OrganizationSettingsIntegrationView from "./OrganizationSettingsIntegration";

const QUERY = graphql`
  query OrganizationSettingsIntegrationDeel_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
      isConnectedToDeel
      isOriginatingFromRemoteEquity
      ...OrganizationSettingsIntegration_Organization
    }
  }
`;

const GENERATE_AUTHORIZATION_FLOW_URL_MUTATION = graphql`
  mutation OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation(
    $organizationId: OrganizationId!
    $returnURL: String!
  ) {
    generateOrganizationDeelAuthorizationFlowURL(
      organizationId: $organizationId
      returnURL: $returnURL
    )
  }
`;

const OrganizationSettingsIntegrationDeel: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsIntegrationDeel_Query>(QUERY, {
    organizationId,
  });

  const [triggerMutation] =
    useSafeMutation<OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation>(
      GENERATE_AUTHORIZATION_FLOW_URL_MUTATION,
    );

  const generateAuthorizationUrl = async () => {
    const result = await triggerMutation({
      variables: { organizationId, returnURL: window.location.href },
    });

    return result.generateOrganizationDeelAuthorizationFlowURL;
  };

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (query.organization.isOriginatingFromRemoteEquity) {
    return (
      <RedirectHandler
        to={generatePath(
          APPLICATION_ROUTES["organizationSettingsIntegrations"],
          {
            organizationId: query.organization.id,
          },
        )}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Deel Integration Settings"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} Deel integration settings`}
    >
      <OrganizationSettingsIntegrationView
        capabilities={[
          {
            description:
              "When adding a new grantee in Easop, the integration will automatically populate the grantee’s personal details such as name, email address, contract start date, country of residence, and work relationship. In addition to preventing clerical errors, this saves time and effort, as you don’t have to manually enter this information.",
            key: "auto-fill-data",
            title: "Auto-fill grantees’ information at creation",
          },
        ]}
        description="With the integration in place, you can easily monitor and manage equity grants for your international hires. This includes tracking vesting schedules, issuing equity documents, and managing tax compliance."
        generateAuthorizationUrl={generateAuthorizationUrl}
        integrationColor="#3F6FE8"
        integrationLogoSrc={deelLargeLogoSrc}
        integrationName="Deel"
        integrationRequirements={[
          {
            content: "Admin access to your Easop account",
            key: "easop_access",
          },
          { content: "An active Deel subscription", key: "deel_subscription" },
        ]}
        integrationWebsiteName="deel.com"
        integrationWebsiteUrl="https://deel.com"
        isConnected={query.organization.isConnectedToDeel}
        organizationFragment={query.organization}
        provider="DEEL"
        title="Monitor and manage equity grants"
      />
    </Page>
  );
};

export default OrganizationSettingsIntegrationDeel;
