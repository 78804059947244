/**
 * @generated SignedSource<<b6544ad80d13d1e11a1def6b1b57a96f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$data = {
  readonly activePostTerminationExercisePeriod: {
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod">;
  } | null;
  readonly exercisableUntilDate: string | null;
  readonly isVirtual: boolean;
  readonly postTerminationExercisePeriod: {
    readonly __typename: "OrganizationVariablePostTerminationExercisePeriod";
    readonly extensionDuration: number;
    readonly extensionDurationUnit: DurationUnit;
    readonly thresholdForExtensionInYears: number;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly remainingDaysToExercise: number | null;
  readonly terminationDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant">;
  readonly " $fragmentType": "CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant";
};
export type CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisableUntilDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remainingDaysToExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ActivePostTerminationExercisePeriod",
      "kind": "LinkedField",
      "name": "activePostTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDuration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extensionDurationUnit",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thresholdForExtensionInYears",
              "storageKey": null
            }
          ],
          "type": "OrganizationVariablePostTerminationExercisePeriod",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "202688baf29233a430fa9129e7ea2d7a";

export default node;
