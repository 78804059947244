/**
 * @generated SignedSource<<869d181618a3e4adf7175200660e4972>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingSchedulesTable_VestingScheduleFragment_RefetchQuery$variables = {
  id: string;
};
export type VestingSchedulesTable_VestingScheduleFragment_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"VestingSchedulesTable_VestingScheduleFragment">;
  } | null;
};
export type VestingSchedulesTable_VestingScheduleFragment_RefetchQuery = {
  response: VestingSchedulesTable_VestingScheduleFragment_RefetchQuery$data;
  variables: VestingSchedulesTable_VestingScheduleFragment_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingSchedulesTable_VestingScheduleFragment_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VestingSchedulesTable_VestingScheduleFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VestingSchedulesTable_VestingScheduleFragment_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cliffDurationInMonths",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "durationInMonths",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "easopGrantCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUsed",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "backloadedVestingOccurrence",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vestingOccurrence",
                    "storageKey": null
                  }
                ],
                "type": "BackloadedVestingSchedule",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "linearVestingOccurrence",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vestingOccurrence",
                    "storageKey": null
                  }
                ],
                "type": "LinearVestingSchedule",
                "abstractKey": null
              }
            ],
            "type": "VestingSchedule",
            "abstractKey": "__isVestingSchedule"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "73ba492e1d04f7289bda7c70438c2d5c",
    "id": null,
    "metadata": {},
    "name": "VestingSchedulesTable_VestingScheduleFragment_RefetchQuery",
    "operationKind": "query",
    "text": "query VestingSchedulesTable_VestingScheduleFragment_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...VestingSchedulesTable_VestingScheduleFragment\n    id\n  }\n}\n\nfragment VestingSchedulesTable_VestingScheduleFragment on VestingSchedule {\n  __isVestingSchedule: __typename\n  __typename\n  id\n  name\n  cliffDurationInMonths\n  durationInMonths\n  ... on BackloadedVestingSchedule {\n    backloadedVestingOccurrence: vestingOccurrence\n  }\n  ... on LinearVestingSchedule {\n    linearVestingOccurrence: vestingOccurrence\n  }\n  easopGrantCount\n  isUsed\n}\n"
  }
};
})();

(node as any).hash = "82cd252d49b4ae5746f4f19092068d5b";

export default node;
