import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { chain, sortBy } from "lodash";
import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CountryFlag } from "../../../../components/CountryFlag";
import { ShortDate } from "../../../../components/ShortDate";
import { Table } from "../../../../components/ui/Table";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../../paths";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../../../../services/workRelationship";
import {
  EquityTypesLegalWatchOutsTable_EquityType$data,
  EquityTypesLegalWatchOutsTable_EquityType$key,
} from "./__generated__/EquityTypesLegalWatchOutsTable_EquityType.graphql";

const EQUITY_TYPES_FRAGMENT = graphql`
  fragment EquityTypesLegalWatchOutsTable_EquityType on EquityType
  @relay(plural: true) {
    id
    name
    taxResidenceCountry {
      name
      ...CountryFlag_Country
    }
    legalWatchOutsForDirectEmployee {
      lastUpdatedAt
    }
    legalWatchOutsForEoREmployee {
      lastUpdatedAt
    }
    legalWatchOutsForContractor {
      lastUpdatedAt
    }
  }
`;

const CompleteTag: React.FC = () => (
  <Tag color="glass-green" icon={<CheckIcon />} size="Extra Small" />
);

const UncompleteTag: React.FC = () => (
  <Tag color="red" icon={<XMarkIcon />} size="Extra Small" />
);

type EquityType = EquityTypesLegalWatchOutsTable_EquityType$data[number];

const columnHelper = createColumnHelper<EquityType>();

export const EquityTypesLegalWatchOutsTable: React.FC<{
  equityTypesFragment: EquityTypesLegalWatchOutsTable_EquityType$key;
}> = ({ equityTypesFragment }) => {
  const equityTypes = useFragment(EQUITY_TYPES_FRAGMENT, equityTypesFragment);

  const getEquityTypeLegalWatchOutsLastEditedAt = ({
    legalWatchOutsForContractor,
    legalWatchOutsForDirectEmployee,
    legalWatchOutsForEoREmployee,
  }: EquityType) =>
    chain([
      legalWatchOutsForDirectEmployee,
      legalWatchOutsForEoREmployee,
      legalWatchOutsForContractor,
    ])
      .map(({ lastUpdatedAt }) => lastUpdatedAt)
      .compact()
      .max()
      .value();

  const getEquityTypeNameWithCountry = (equityType: EquityType) =>
    `${equityType.taxResidenceCountry.name} - ${equityType.name}`;

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => getEquityTypeNameWithCountry(row), {
        cell: (context) => {
          const equityType = context.row.original;
          return (
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Large">
                <CountryFlag countryFragment={equityType.taxResidenceCountry} />
              </Typography>
              <Typography variant="Medium/Extra Small">
                {context.getValue()}
              </Typography>
            </div>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Equity type",
        id: "equityType",
      }),
      ...(["DirectEmployee", "EoREmployee", "Contractor"] as const).map(
        (workRelationship) =>
          columnHelper.accessor(
            (row) => {
              switch (workRelationship) {
                case "Contractor":
                  return !!row.legalWatchOutsForContractor.lastUpdatedAt;
                case "DirectEmployee":
                  return !!row.legalWatchOutsForDirectEmployee.lastUpdatedAt;
                case "EoREmployee":
                  return !!row.legalWatchOutsForEoREmployee.lastUpdatedAt;
              }
            },
            {
              cell: (context) => {
                const isComplete = context.getValue();
                return isComplete ? <CompleteTag /> : <UncompleteTag />;
              },
              enableGlobalFilter: false,
              enableSorting: false,
              header: () =>
                EQUITY_TYPE_WORK_RELATIONSHIP_MAP[workRelationship].label,
              id: workRelationship,
            },
          ),
      ),
      columnHelper.accessor(
        (row) => getEquityTypeLegalWatchOutsLastEditedAt(row),
        {
          cell: (context) => {
            const lastEdited = context.getValue();
            if (!lastEdited) {
              return "-";
            }
            return <ShortDate value={lastEdited} />;
          },
          enableGlobalFilter: false,
          enableSorting: true,
          header: () => "Last edited",
          id: "lastEdited",
        },
      ),
    ],
    [],
  );

  const data = useMemo(
    () =>
      sortBy(
        [...equityTypes],
        [
          (equityType) =>
            getEquityTypeLegalWatchOutsLastEditedAt(equityType) ? 0 : 1,
          (equityType) => getEquityTypeNameWithCountry(equityType),
        ],
      ),
    [equityTypes],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    onGlobalFilterChange,
    state: {
      globalFilter: searchParams.get("search"),
    },

    // Hack to set width auto when size is not set
    defaultColumn: { size: 0 },
  });

  return (
    <Table.Smart
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES["superAdminLegalWatchOutsEdition"],
            {
              equityTypeId: rowData.original.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      searchBarPlaceholder="Search equity types..."
      showSearchBar
      table={table}
    />
  );
};
