/**
 * @generated SignedSource<<c984f98f307052f04887415c656f762f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardApproval_CTMSGrantAmendmentRequests_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "BoardApproval_CTMSGrantAmendmentRequests_Viewer";
};
export type BoardApproval_CTMSGrantAmendmentRequests_Viewer$key = {
  readonly " $data"?: BoardApproval_CTMSGrantAmendmentRequests_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardApproval_CTMSGrantAmendmentRequests_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardApproval_CTMSGrantAmendmentRequests_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "a2262dd8e5460e6cb30393b9bc79c19e";

export default node;
