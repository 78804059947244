import { useCallback, useEffect, useRef, useState } from "react";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { GranteePortalGrantAndValueDetailsSection } from "../../../components/GranteePortalGrantAndValueDetailsSection";
import { GranteePortalVestingSection } from "../../../components/GranteePortalVestingSection";
import { GranteePortalWelcomeSection } from "../../../components/GranteePortalWelcomeSection";
import { GranteePortalWhatDoYouHaveSection } from "../../../components/GranteePortalWhatDoYouHaveSection";
import { Page } from "../../../components/Page";
import { SectionLabel } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/SECTIONS";
import { useRemoteLikeGranteePortalLayoutOutletContext } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/useRemoteLikeGranteePortalLayoutOutletContext";
import { useGranteeIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { GranteePage_Grantee$key } from "./__generated__/GranteePage_Grantee.graphql";
import { GranteePage_Query } from "./__generated__/GranteePage_Query.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePage_Grantee on Grantee {
    ...GranteePortalWelcomeSection_Grantee
    ...GranteePortalWhatDoYouHaveSection_Grantee
    ...GranteePortalVestingSection_Grantee
    ...GranteePortalGrantAndValueDetailsSection_Grantee
  }
`;

const GranteePage_: React.FC<{
  granteeFragment: GranteePage_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const [valuationMultiple, setValuationMultiple] = useState(1);
  const sections = useRef<Map<SectionLabel, HTMLElement>>(new Map());
  const { containerHeight, containerScrollY, setActiveSection } =
    useRemoteLikeGranteePortalLayoutOutletContext();

  const registerSection = useCallback(
    (sectionLabel: SectionLabel) => (sectionRef: HTMLElement | null) => {
      if (!sectionRef) {
        return;
      }

      sections.current.set(sectionLabel, sectionRef);
    },
    [],
  );

  useEffect(() => {
    const containerScrollVisibleAreaTop = containerScrollY;
    const containerScrollVisibleAreaBottom = containerScrollY + containerHeight;

    function getElementVisibleRatio(element: HTMLElement) {
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;

      if (
        elementTop >= containerScrollVisibleAreaBottom ||
        elementBottom <= containerScrollVisibleAreaTop
      ) {
        return 0;
      }

      const visibleHeight =
        Math.min(containerScrollVisibleAreaBottom, elementBottom) -
        Math.max(containerScrollVisibleAreaTop, elementTop);
      const elementHeight = elementBottom - elementTop;

      return visibleHeight / elementHeight;
    }

    function getElementDistanceToEdge(element: HTMLElement) {
      const elementTop = element.offsetTop;
      const elementBottom = elementTop + element.offsetHeight;

      const distanceToTop = Math.max(
        containerScrollVisibleAreaTop - elementTop,
        0,
      );
      const distanceToBottom = Math.max(
        elementBottom - containerScrollVisibleAreaBottom,
        0,
      );

      return Math.min(distanceToTop, distanceToBottom);
    }

    const mostVisibleSection = sections.current
      .entries()
      .reduce<[SectionLabel, HTMLElement] | null>((mostVisible, section) => {
        if (!mostVisible) {
          return section;
        }

        const mostVisibleRatio = getElementVisibleRatio(mostVisible[1]);
        const sectionRatio = getElementVisibleRatio(section[1]);

        if (sectionRatio === mostVisibleRatio) {
          return getElementDistanceToEdge(section[1]) <
            getElementDistanceToEdge(mostVisible[1])
            ? section
            : mostVisible;
        }

        return sectionRatio > mostVisibleRatio ? section : mostVisible;
      }, null);

    if (mostVisibleSection) {
      setActiveSection(mostVisibleSection[0]);
    }
  }, [containerHeight, containerScrollY, setActiveSection]);

  return (
    <div className="relative mx-auto max-w-[1000px] space-y-10">
      <GranteePortalWelcomeSection
        granteeFragment={grantee}
        id="ownership"
        ref={registerSection("Ownership")}
      />
      <GranteePortalWhatDoYouHaveSection
        granteeFragment={grantee}
        id="what-do-you-have"
        onValuationMultipleChange={setValuationMultiple}
        valuationMultiple={valuationMultiple}
      />
      <GranteePortalGrantAndValueDetailsSection
        granteeFragment={grantee}
        id="grant-details"
        ref={registerSection("Grant details")}
        valuationMultiple={valuationMultiple}
      />
      <GranteePortalVestingSection
        granteeFragment={grantee}
        id="vesting-timeline"
        ref={registerSection("Vesting timeline")}
        valuationMultiple={valuationMultiple}
      />
      <GranteePortalWelcomeSection
        granteeFragment={grantee}
        id="exercise"
        ref={registerSection("Exercise")}
      />
      <GranteePortalWelcomeSection
        granteeFragment={grantee}
        id="reporting-and-general-taxation"
        ref={registerSection("Reporting & General taxation")}
      />
      <GranteePortalWelcomeSection
        granteeFragment={grantee}
        id="taxation-example"
        ref={registerSection("Taxation example")}
      />
      <GranteePortalWelcomeSection
        granteeFragment={grantee}
        id="equity-101"
        ref={registerSection("Equity 101")}
      />
    </div>
  );
};

const QUERY = graphql`
  query GranteePage_Query($granteeId: GranteeId!) {
    grantee(id: $granteeId) {
      organization {
        id
        name
      }
      ...GranteePage_Grantee
    }
  }
`;

const GranteePage: React.FC = () => {
  const granteeId = useGranteeIdParam();
  const { grantee } = useLazyLoadQuery<GranteePage_Query>(QUERY, {
    granteeId,
  });

  if (!grantee) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Grantee portal"
      analyticsName="Grantee - Portal"
      organizationId={grantee.organization.id}
      title={`Grantee | ${grantee.organization.name} grantee portal`}
    >
      <GranteePage_ granteeFragment={grantee} />
    </Page>
  );
};

export default GranteePage;
