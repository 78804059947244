import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CtmsGrantDetailsExercise } from "../../../../components/CtmsGrantDetailsExercise";
import { DetailsBox } from "../../../../components/DetailsBox";
import { DocumentsTable } from "../../../../components/DocumentsTable";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { TwoColumnsGrid } from "../../../../components/ui/TwoColumnsGrid";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCtmsGrantIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { CtmsGrantDetails_CTMSGrant$key } from "./__generated__/CtmsGrantDetails_CTMSGrant.graphql";
import { CtmsGrantDetails_Organization$key } from "./__generated__/CtmsGrantDetails_Organization.graphql";
import { CtmsGrantDetails_Query } from "./__generated__/CtmsGrantDetails_Query.graphql";
import { CtmsGrantDetails_Viewer$key } from "./__generated__/CtmsGrantDetails_Viewer.graphql";
import { CtmsGrantDetailsGrant } from "./CtmsGrantDetailsGrant";
import { CtmsGrantDetailsGrantee } from "./CtmsGrantDetailsGrantee";
import { CtmsGrantDetailsHeader } from "./CtmsGrantDetailsHeader";
import { CtmsGrantDetailsPostTerminationExercisePeriod } from "./CtmsGrantDetailsPostTerminationExercisePeriod";
import { CtmsGrantDetailsPriceEvolution } from "./CtmsGrantDetailsPriceEvolution";
import { CtmsGrantDetailsVestingSchedule } from "./CtmsGrantDetailsVestingSchedule";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetails_Organization on Organization {
    id
    name
    ...CtmsGrantDetailsHeader_Organization
    ...CtmsGrantDetailsGrant_Organization
    ...CtmsGrantDetailsVestingSchedule_Organization
    ...CtmsGrantDetailsPostTerminationExercisePeriod_Organization
    ...CtmsGrantDetailsExercise_Organization
    ...CtmsGrantDetailsGrantee_Organization
    ...CtmsGrantDetailsPriceEvolution_Organization
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment CtmsGrantDetails_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...CtmsGrantDetailsGrantee_Viewer
      @arguments(organizationId: $organizationId)
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetails_CTMSGrant on CTMSGrant {
    label
    easopGrant {
      ...DocumentsTable_Documents
    }
    grantee {
      name
      ...CtmsGrantDetailsGrantee_Grantee
    }
    ...CtmsGrantDetailsHeader_CTMSGrant
    ...CtmsGrantDetailsGrant_CTMSGrant
    ...CtmsGrantDetailsVestingSchedule_CTMSGrant
    ...CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant
    ...CtmsGrantDetailsExercise_CTMSGrant
    ...CtmsGrantDetailsPriceEvolution_CTMSGrant
  }
`;

const CtmsGrantDetails_: React.FC<{
  ctmsGrantFragment: CtmsGrantDetails_CTMSGrant$key;
  organizationFragment: CtmsGrantDetails_Organization$key;
  viewerFragment: CtmsGrantDetails_Viewer$key;
}> = ({ ctmsGrantFragment, organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>

          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationEquityGrants"], {
              organizationId: organization.id,
            })}
          >
            Grants
          </BreadCrumb.Link>

          <BreadCrumb.Link to=".">{ctmsGrant.label}</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <div className="space-y-6">
        <CtmsGrantDetailsHeader
          ctmsGrantFragment={ctmsGrant}
          organizationFragment={organization}
        />

        <TwoColumnsGrid>
          <CtmsGrantDetailsGrant
            ctmsGrantFragment={ctmsGrant}
            organizationFragment={organization}
          />

          <CtmsGrantDetailsPriceEvolution
            ctmsGrantFragment={ctmsGrant}
            organizationFragment={organization}
          />

          <CtmsGrantDetailsVestingSchedule
            ctmsGrantFragment={ctmsGrant}
            organizationFragment={organization}
          />

          <CtmsGrantDetailsExercise
            ctmsGrantFragment={ctmsGrant}
            displayLastDayToExercise={false}
            organizationFragment={organization}
          />

          <CtmsGrantDetailsGrantee
            granteeFragment={ctmsGrant.grantee}
            organizationFragment={organization}
            viewerFragment={viewer}
          />

          <CtmsGrantDetailsPostTerminationExercisePeriod
            ctmsGrantFragment={ctmsGrant}
            organizationFragment={organization}
          />

          <DetailsBox
            Icon={DocumentDuplicateIcon}
            padded={!ctmsGrant.easopGrant}
            title="Documents"
          >
            {ctmsGrant.easopGrant ? (
              <div className="[&_td:first-of-type]:pl-6 [&_th:first-of-type]:pl-6">
                <DocumentsTable easopGrantFragment={ctmsGrant.easopGrant} />
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              </div>
            )}
          </DetailsBox>
        </TwoColumnsGrid>
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query CtmsGrantDetails_Query(
    $organizationId: OrganizationId!
    $ctmsGrantId: CtmsGrantId!
  ) {
    me {
      ...CtmsGrantDetails_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      id
      name
      ...CtmsGrantDetails_Organization
    }
    ctmsGrant(id: $ctmsGrantId) {
      ...CtmsGrantDetails_CTMSGrant
    }
  }
`;

const CtmsGrantDetails: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantId = useCtmsGrantIdParam();

  const {
    query: { ctmsGrant, me, organization },
  } = useQuery<CtmsGrantDetails_Query>(QUERY, {
    ctmsGrantId,
    organizationId,
  });

  if (!organization || !ctmsGrant) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Grant details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} grant details`}
    >
      <CtmsGrantDetails_
        ctmsGrantFragment={ctmsGrant}
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default CtmsGrantDetails;
