import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/20/solid";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import classNames from "classnames";
import { compact } from "lodash";
import React, { useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { GranteeNameWithCountryFlag } from "../../../components/GranteeNameWithCountryFlag";
import { LongDate } from "../../../components/LongDate";
import { ShortDate } from "../../../components/ShortDate";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../services/workRelationship";
import {
  ExercisesTable_Exercises$data,
  ExercisesTable_Exercises$key,
} from "./__generated__/ExercisesTable_Exercises.graphql";
import { ExercisesTable_Organization$key } from "./__generated__/ExercisesTable_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesTable_Organization on Organization {
    id
  }
`;

const EASOP_GRANTEES_FRAGMENT = graphql`
  fragment ExercisesTable_Exercises on CTMSExerciseRequest
  @relay(plural: true) {
    ctmsId
    ctmsGrantLabel
    status
    isInProgress
    requestSubmittedAt
    totalExercisePrice
    quantityExercised
    spread
    fundsTransferredAt

    ctmsGrant {
      grantee {
        name
        workRelationship
        ...GranteeNameWithCountryFlag_Grantee
      }
    }
  }
`;

const GRANT_STATUS_TO_LABEL_MAP: Record<
  ExercisesTable_Exercises$data[number]["status"],
  string
> = {
  Cancelled: "Canceled",
  Done: "Done",
  PendingApproval: "Pending approval",
  WaitingForCertificateSignature: "Waiting for certificate signature",
  WaitingForFunds: "Waiting for Funds",
};

const columnHelper =
  createColumnHelper<ExercisesTable_Exercises$data[number]>();

export const ExercisesTable: React.FC<{
  exercisesFragment: ExercisesTable_Exercises$key;
  exerciseStatus: "Cancelled" | "Done" | "InProgress";
  organizationFragment: ExercisesTable_Organization$key;
}> = ({ exercisesFragment, exerciseStatus, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const exercises = useFragment(EASOP_GRANTEES_FRAGMENT, exercisesFragment);

  const data = useMemo(
    () =>
      exercises.filter(
        (exercise) =>
          (exerciseStatus === "InProgress" && exercise.isInProgress) ||
          exercise.status === exerciseStatus,
      ),
    [exercises, exerciseStatus],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.ctmsGrantLabel, {
        cell: (context) => (
          <Typography as="div" variant="Medium/Extra Small">
            {context.getValue()}
          </Typography>
        ),
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Grant",
        id: "grantLabel",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.ctmsGrant.grantee.name, {
        cell: (context) => (
          <div className="space-y-1">
            <Typography as="div" variant="Medium/Extra Small">
              <GranteeNameWithCountryFlag
                granteeFragment={context.row.original.ctmsGrant.grantee}
              />
            </Typography>
            {context.row.original.ctmsGrant.grantee.workRelationship && (
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Caption"
              >
                {
                  WORK_RELATIONSHIP_TO_LABEL_HELPER[
                    context.row.original.ctmsGrant.grantee.workRelationship
                  ].singularLabel
                }
              </Typography>
            )}
          </div>
        ),
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Grantee",
        id: "grantee",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.requestSubmittedAt, {
        cell: (context) => (
          <Typography as="div" variant="Regular/Extra Small">
            <LongDate value={context.getValue()} />
          </Typography>
        ),
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Request date",
        id: "requestDate",
        sortingFn: "datetime",
      }),
      columnHelper.accessor((row) => row.totalExercisePrice, {
        cell: (context) => {
          const value = context.getValue();
          return (
            <Typography as="div" variant="Regular/Extra Small">
              {typeof value === "number" ? (
                <FormattedNumber
                  currency="USD"
                  style="currency"
                  value={value}
                />
              ) : (
                "-"
              )}
            </Typography>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Total exercise price",
        id: "totalExercisePrice",
        sortingFn: "basic",
      }),
      columnHelper.accessor((row) => row.quantityExercised, {
        cell: (context) => (
          <Typography as="div" variant="Regular/Extra Small">
            <FormattedMessage
              defaultMessage="{shares, plural, one {# share} other {# shares}}"
              values={{ shares: context.getValue() }}
            />
          </Typography>
        ),
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Quantity exercised",
        id: "quantityExercised",
        sortingFn: "basic",
      }),
      columnHelper.accessor((row) => row.spread, {
        cell: (context) => {
          const value = context.getValue();
          return (
            <Typography
              as="div"
              className={classNames("flex items-center", {
                "text-green": value && value > 0,
                "text-red": value && value < 0,
              })}
              variant="Medium/Extra Small"
            >
              {typeof value === "number" ? (
                <>
                  {value > 0 && <ArrowUpIcon className="h-4" />}
                  {value < 0 && <ArrowDownIcon className="h-4" />}
                  <FormattedNumber
                    currency="USD"
                    style="currency"
                    value={value}
                  />
                </>
              ) : (
                "-"
              )}
            </Typography>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Spread",
        id: "spread",
        sortingFn: "basic",
      }),
      exerciseStatus === "InProgress" &&
        columnHelper.accessor((row) => row.status, {
          cell: (context) => {
            const value = context.getValue();
            return (
              <div className="text-right">
                <Tag color={value === "WaitingForFunds" ? "orange" : "gray"}>
                  {GRANT_STATUS_TO_LABEL_MAP[value]}
                </Tag>
              </div>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Status",
          id: "status",
          meta: {
            alignRight: true,
          },
          sortingFn: "basic",
        }),
      exerciseStatus === "Done" &&
        columnHelper.accessor((row) => row.fundsTransferredAt, {
          cell: (context) => {
            const value = context.getValue();
            return (
              <div className="text-right">
                {value ? <ShortDate value={value} /> : "-"}
              </div>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Funds transferred at",
          id: "fundsTransferredAt",
          meta: {
            alignRight: true,
          },
          sortingFn: "datetime",
        }),
    ],
    [exerciseStatus],
  );

  const table = useReactTable({
    columns: compact(columns),
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (data.length === 0) {
    return (
      <NoticeMessage size="Small">
        No exercise matches your current filters.
      </NoticeMessage>
    );
  }

  return (
    <Table.Smart
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES.organizationExerciseRequestDetails,
            {
              ctmsExerciseRequestCTMSId: rowData.original.ctmsId,
              organizationId: organization.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      table={table}
      tableLayout="fixed"
    />
  );
};
