import { useCallback, useState } from "react";

import { ConfirmationModal } from "./ui/ConfirmationModal";

type State = {
  shown: boolean;
};

export const useDeleteSimulatedGrantConfirmationModalState = () => {
  const [deleteSimulatedGrantConfirmationModalState, setState] =
    useState<State>({
      shown: false,
    });

  const showDeleteSimulatedGrantConfirmationModal = useCallback(() => {
    setState({
      shown: true,
    });
  }, []);

  const closeShowDeleteSimulatedGrantConfirmationModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    closeShowDeleteSimulatedGrantConfirmationModal,
    deleteSimulatedGrantConfirmationModalState,
    showDeleteSimulatedGrantConfirmationModal,
  };
};

export const DeleteSimulatedGrantConfirmationModal: React.FC<{
  onClose: () => void;
  onConfirmed: () => void;
  state: State;
}> = ({ onClose, onConfirmed, state }) => {
  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      onClose={onClose}
      onConfirmed={onConfirmed}
      show={state.shown}
      title="Confirm delete"
    >
      Are you sure you want to delete this grant?
    </ConfirmationModal>
  );
};
