import classNames from "classnames";

export const WhiteLabelSpinner: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <div
      className={classNames(
        "relative aspect-square rounded-full",
        "before:absolute before:inset-0 before:box-border before:animate-prix-clip-fix before:rounded-full before:border-[5px] before:border-remote-brand-600 before:content-['']",
        "after:absolute after:inset-[6px] after:box-border after:animate-prix-clip-fix-reverse after:rounded-full after:border-[5px] after:border-remote-grey-400 after:content-['']",
        className,
      )}
    />
  );
};
