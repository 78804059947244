/**
 * @generated SignedSource<<39e1391cfaf1c250f6685db47f54fb06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type FairMarketValueStatus = "Approved" | "Draft" | "PendingBoardConsent" | "PendingReview" | "Reviewed";
import { FragmentRefs } from "relay-runtime";
export type UnderReview_FairMarketValues_Organization$data = {
  readonly fairMarketValueHistory: ReadonlyArray<{
    readonly id: string;
    readonly status: FairMarketValueStatus;
    readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueApprovalTable_FairMarketValue">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"LegalWorkflowLayout_Organization">;
  readonly " $fragmentType": "UnderReview_FairMarketValues_Organization";
};
export type UnderReview_FairMarketValues_Organization$key = {
  readonly " $data"?: UnderReview_FairMarketValues_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderReview_FairMarketValues_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderReview_FairMarketValues_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValueHistory",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FairMarketValueApprovalTable_FairMarketValue"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "1527243706b34a55bdcbe785a65767cd";

export default node;
