/**
 * @generated SignedSource<<a883a41794088ab48bee0d40e8b62f32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CartaIssuerSelection_SetupWorkspace_Mutation$variables = {
  latestPricePerShareValue: number;
  selectedCartaIssuerId: string;
  workspaceSetupStateToken: string;
};
export type CartaIssuerSelection_SetupWorkspace_Mutation$data = {
  readonly setupWorkspaceUsingWorkspaceSetupStateToken: {
    readonly id: string;
  };
};
export type CartaIssuerSelection_SetupWorkspace_Mutation = {
  response: CartaIssuerSelection_SetupWorkspace_Mutation$data;
  variables: CartaIssuerSelection_SetupWorkspace_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "latestPricePerShareValue"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedCartaIssuerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workspaceSetupStateToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "latestPricePerShare",
        "variableName": "latestPricePerShareValue"
      },
      {
        "kind": "Variable",
        "name": "selectedCartaIssuerId",
        "variableName": "selectedCartaIssuerId"
      },
      {
        "kind": "Literal",
        "name": "source",
        "value": "Remote Equity Web App"
      },
      {
        "kind": "Variable",
        "name": "workspaceSetupStateToken",
        "variableName": "workspaceSetupStateToken"
      }
    ],
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "setupWorkspaceUsingWorkspaceSetupStateToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CartaIssuerSelection_SetupWorkspace_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CartaIssuerSelection_SetupWorkspace_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "30789577c617655786b7f6d8e7b2b487",
    "id": null,
    "metadata": {},
    "name": "CartaIssuerSelection_SetupWorkspace_Mutation",
    "operationKind": "mutation",
    "text": "mutation CartaIssuerSelection_SetupWorkspace_Mutation(\n  $latestPricePerShareValue: Float!\n  $selectedCartaIssuerId: String!\n  $workspaceSetupStateToken: String!\n) {\n  setupWorkspaceUsingWorkspaceSetupStateToken(latestPricePerShare: $latestPricePerShareValue, selectedCartaIssuerId: $selectedCartaIssuerId, workspaceSetupStateToken: $workspaceSetupStateToken, source: \"Remote Equity Web App\") {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f633d252c99d95f820bff9e456df29fb";

export default node;
