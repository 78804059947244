/**
 * @generated SignedSource<<b6261decf02150166468dc1a140305f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantFormSlide_DefaultGrantee$data = {
  readonly defaultVestingSchedule: {
    readonly id: string;
  } | null;
  readonly equityGridLevel: {
    readonly __typename: "EquityGridLevel";
  } | null;
  readonly id: string;
  readonly instruments: ReadonlyArray<{
    readonly id: string;
    readonly valuation: {
      readonly valueInDollars: number | null;
    };
  }>;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentType": "GrantFormSlide_DefaultGrantee";
};
export type GrantFormSlide_DefaultGrantee$key = {
  readonly " $data"?: GrantFormSlide_DefaultGrantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_DefaultGrantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantFormSlide_DefaultGrantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": "TaxFavored"
        },
        {
          "kind": "Literal",
          "name": "sortDirection",
          "value": "DESC"
        }
      ],
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "concreteType": "InstrumentValuation",
          "kind": "LinkedField",
          "name": "valuation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valueInDollars",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "defaultVestingSchedule",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "68df73ba68eb6bd048b7d0746d2bd5e3";

export default node;
