/**
 * @generated SignedSource<<36a4be6c00ce5c228d84433c0cb79599>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GrantDocumentationPage_GrantCard$data = {
  readonly grantee: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
  };
  readonly instrument: {
    readonly awardSuperType: AwardSuperType;
  };
  readonly label: string;
  readonly quantityGranted: number;
  readonly signedBoardConsentDocument: {
    readonly __typename: "Document";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useGrantDocuments_EasopGrant">;
  readonly " $fragmentType": "GrantDocumentationPage_GrantCard";
};
export type GrantDocumentationPage_GrantCard$key = {
  readonly " $data"?: GrantDocumentationPage_GrantCard$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationPage_GrantCard">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDocumentationPage_GrantCard",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGrantDocuments_EasopGrant"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "awardSuperType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeNameWithCountryFlag_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "signedBoardConsentDocument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "eb87f8dabfc68ef28f913790770099d7";

export default node;
