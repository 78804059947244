/**
 * @generated SignedSource<<6141533b69d99c7b79832a2fd1c259a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LockedGeographySlideOverWithQuery_Query$variables = {
  countryCode: string;
  organizationId: string;
};
export type LockedGeographySlideOverWithQuery_Query$data = {
  readonly geographyWithOrganizationSpecificPrices: {
    readonly geography: {
      readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_Header_Geography">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_Content_GeographyWithPrice">;
  };
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_Content_Organization">;
  };
};
export type LockedGeographySlideOverWithQuery_Query = {
  response: LockedGeographySlideOverWithQuery_Query$data;
  variables: LockedGeographySlideOverWithQuery_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "priceAfterDiscount",
    "storageKey": null
  },
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LockedGeographySlideOverWithQuery_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v2/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LockedGeographySlideOver_Content_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      },
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "GeographyWithPrice",
          "kind": "LinkedField",
          "name": "geographyWithOrganizationSpecificPrices",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Geography",
              "kind": "LinkedField",
              "name": "geography",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "LockedGeographySlideOver_Header_Geography"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LockedGeographySlideOver_Content_GeographyWithPrice"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LockedGeographySlideOverWithQuery_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "subscriptionBillingPeriod",
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GeographyWithPrice",
        "kind": "LinkedField",
        "name": "geographyWithOrganizationSpecificPrices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Geography",
            "kind": "LinkedField",
            "name": "geography",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityType",
                "kind": "LinkedField",
                "name": "equityTypes",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullname",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxFavored",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Instrument",
                    "kind": "LinkedField",
                    "name": "instruments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSpecificPrice",
            "kind": "LinkedField",
            "name": "standardPrice",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationSpecificPrice",
            "kind": "LinkedField",
            "name": "taxFavoredPrice",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce269413e2687e88df5333bfb3e305dc",
    "id": null,
    "metadata": {},
    "name": "LockedGeographySlideOverWithQuery_Query",
    "operationKind": "query",
    "text": "query LockedGeographySlideOverWithQuery_Query(\n  $organizationId: OrganizationId!\n  $countryCode: String!\n) {\n  organization(id: $organizationId) {\n    ...LockedGeographySlideOver_Content_Organization\n    id\n  }\n  geographyWithOrganizationSpecificPrices(countryCode: $countryCode, organizationId: $organizationId) {\n    geography {\n      ...LockedGeographySlideOver_Header_Geography\n    }\n    ...LockedGeographySlideOver_Content_GeographyWithPrice\n  }\n}\n\nfragment LockedGeographySlideOver_Content_GeographyWithPrice on GeographyWithPrice {\n  geography {\n    country {\n      code\n      name\n      emoji\n    }\n    equityTypes {\n      id\n      name\n      fullname\n      description\n      taxFavored\n      instruments {\n        workRelationship\n        id\n      }\n      ...LockedGeographySlideOver_EquityTypeDescription_EquityType\n    }\n  }\n  standardPrice {\n    priceAfterDiscount\n    id\n  }\n  taxFavoredPrice {\n    priceAfterDiscount\n    id\n  }\n}\n\nfragment LockedGeographySlideOver_Content_Organization on Organization {\n  id\n  subscriptionBillingPeriod\n  name\n}\n\nfragment LockedGeographySlideOver_EquityTypeDescription_EquityType on EquityType {\n  id\n  name\n  fullname\n  description\n  taxFavored\n  instruments {\n    workRelationship\n    id\n  }\n}\n\nfragment LockedGeographySlideOver_Header_Geography on Geography {\n  country {\n    name\n    emoji\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7f40450f5ac6dc55b25a074a415be3a";

export default node;
