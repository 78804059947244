/**
 * @generated SignedSource<<adc4c786f6d8f869399fbbfbdfd86907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
export type ChangeGrantInstrumentForm_EasopGrant_Query$variables = {
  ctmsGrantId: string;
};
export type ChangeGrantInstrumentForm_EasopGrant_Query$data = {
  readonly ctmsGrant: {
    readonly grantee: {
      readonly taxResidenceCountry: {
        readonly code: string;
      } | null;
      readonly workRelationship: WorkRelationship | null;
    };
    readonly matchingInstrument: {
      readonly id: string;
    } | null;
  };
};
export type ChangeGrantInstrumentForm_EasopGrant_Query = {
  response: ChangeGrantInstrumentForm_EasopGrant_Query$data;
  variables: ChangeGrantInstrumentForm_EasopGrant_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsGrantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ctmsGrantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "taxResidenceCountry",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workRelationship",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Instrument",
  "kind": "LinkedField",
  "name": "matchingInstrument",
  "plural": false,
  "selections": [
    (v4/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeGrantInstrumentForm_EasopGrant_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "CTMSGrant",
          "kind": "LinkedField",
          "name": "ctmsGrant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "grantee",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/)
              ],
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeGrantInstrumentForm_EasopGrant_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSGrant",
        "kind": "LinkedField",
        "name": "ctmsGrant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantee",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0b947a1fee4625743e7a112f4148c37",
    "id": null,
    "metadata": {},
    "name": "ChangeGrantInstrumentForm_EasopGrant_Query",
    "operationKind": "query",
    "text": "query ChangeGrantInstrumentForm_EasopGrant_Query(\n  $ctmsGrantId: CtmsGrantId!\n) {\n  ctmsGrant(id: $ctmsGrantId) {\n    grantee {\n      taxResidenceCountry {\n        code\n      }\n      workRelationship\n      id\n    }\n    matchingInstrument {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3bc14789c966ac23d626d7a23d890126";

export default node;
