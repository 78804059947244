import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { BoxedIcon, Button, Pill, Text } from "@remote-com/norma";
import { IconV2DuotoneSlidersVertical } from "@remote-com/norma/icons/IconV2DuotoneSlidersVertical";
import { IconV2DuotoneStar } from "@remote-com/norma/icons/IconV2DuotoneStar";
import classNames from "classnames";
import { compact, first, isNil } from "lodash";
import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";

import {
  CTMSGrantVestingStatus,
  GranteePortalGrantAndValueDetailsSection_Grantee$key,
} from "./__generated__/GranteePortalGrantAndValueDetailsSection_Grantee.graphql";
import { ColoredBoxValue } from "./ColoredBoxValue";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import { FormattedNumber } from "./Formatted/FormattedNumber";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { RemoteLikeInfoBlock } from "./RemoteLikeInfoBlock";
import { SectionHero } from "./SectionHero";
import { ShortDate } from "./ShortDate";
import { StatusLight } from "./StatusLight";
import { RoundedBox } from "./ui/RoundedBox";
import { Switch } from "./ui/Switch";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalGrantAndValueDetailsSection_Grantee on Grantee {
    organization {
      latestPricePerShare {
        value
      }
      granteePortalSettings {
        displayFullyDilutedValues
      }
    }
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      quantityIssued
      grantDate
      vestingStartDate
      cliffDate
      vestingStatus
      cumulativeVested
      exercisePrice
    }
  }
`;

const CTMS_GRANT_VESTING_STATUS_PILL: Record<
  CTMSGrantVestingStatus,
  React.ReactNode
> = {
  fully_vested: <Pill>Fully Vested</Pill>,
  terminated: <Pill tone="error">Terminated</Pill>,
  vesting: <Pill tone="success">Vesting</Pill>,
};

const CTMS_GRANT_STATUS_LIGHT: Record<CTMSGrantVestingStatus, React.ReactNode> =
  {
    fully_vested: <StatusLight tone="success" />,
    terminated: <StatusLight tone="error" />,
    vesting: <StatusLight tone="success" />,
  };

export const GranteePortalGrantAndValueDetailsSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: GranteePortalGrantAndValueDetailsSection_Grantee$key;
    id: string;
    valuationMultiple: number;
  }
>(function GranteePortalGrantAndValueDetailsSection(
  { granteeFragment, id, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const displayFullyDilutedValues =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  const firstCTMSGrant = first(grantee.ctmsGrants);

  if (!firstCTMSGrant) {
    throw new Error("Unexpected: grantee has no grants");
  }

  const [selectedGrant, setSelectedGrant] = useState(firstCTMSGrant);

  const grantDetailsInfo = compact([
    {
      label: "Vesting progress",
      value: (
        <div className="flex items-center gap-2">
          {CTMS_GRANT_STATUS_LIGHT[selectedGrant.vestingStatus]}
          <FormattedPercentage
            animated
            value={
              selectedGrant.cumulativeVested / selectedGrant.quantityIssued
            }
          />
        </div>
      ),
    },
    {
      label: "Grant date",
      value: <ShortDate value={selectedGrant.grantDate} />,
    },
    selectedGrant.vestingStartDate && {
      label: "Vesting start date",
      value: <ShortDate value={selectedGrant.vestingStartDate} />,
    },
    selectedGrant.cliffDate && {
      label: "Cliff date",
      value: <ShortDate value={selectedGrant.cliffDate} />,
    },
  ]);

  const selectedGrantExercisePrice = selectedGrant.exercisePrice;

  const organizationLatestPricePerShare =
    grantee.organization.latestPricePerShare?.value;

  const organizationProjectedPricePerShare = useMemo(() => {
    if (isNil(organizationLatestPricePerShare)) {
      return null;
    }

    return organizationLatestPricePerShare * valuationMultiple;
  }, [organizationLatestPricePerShare, valuationMultiple]);

  const selectedGrantProjectedTotalPrice = useMemo(() => {
    if (isNil(organizationProjectedPricePerShare)) {
      return null;
    }

    return selectedGrant.quantityIssued * organizationProjectedPricePerShare;
  }, [organizationProjectedPricePerShare, selectedGrant.quantityIssued]);

  const selectedGrantTotalExercisePrice = useMemo(() => {
    if (isNil(selectedGrantExercisePrice)) {
      return null;
    }

    return selectedGrant.quantityIssued * selectedGrantExercisePrice;
  }, [selectedGrant.quantityIssued, selectedGrantExercisePrice]);

  const selectedGrantProjectedNetEquityValue = useMemo(() => {
    if (
      isNil(selectedGrantProjectedTotalPrice) ||
      isNil(selectedGrantTotalExercisePrice)
    ) {
      return null;
    }

    return selectedGrantProjectedTotalPrice - selectedGrantTotalExercisePrice;
  }, [selectedGrantProjectedTotalPrice, selectedGrantTotalExercisePrice]);

  return (
    <SectionHero id={id} ref={ref} withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon
            Icon={IconV2DuotoneSlidersVertical}
            size="sm"
            tone="cyan"
          />
          <Text variant="lgMedium">Grant and value details</Text>
        </div>
        <div>
          <Switch
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.label}
            name="grant"
            onChange={setSelectedGrant}
            options={grantee.ctmsGrants}
            selectedOption={selectedGrant}
          />
        </div>
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-[minmax(300px,auto)_1fr]">
          <div>
            <ColoredBoxValue
              footer={
                <div className="flex justify-end">
                  {CTMS_GRANT_VESTING_STATUS_PILL[selectedGrant.vestingStatus]}
                </div>
              }
              secondary={
                selectedGrantProjectedNetEquityValue
                  ? {
                      unit: "Net equity value",
                      value: (
                        <FormattedCurrency
                          animated
                          value={selectedGrantProjectedNetEquityValue}
                        />
                      ),
                    }
                  : undefined
              }
              title={
                <div className="flex items-center gap-3">
                  <BoxedIcon
                    appearance="subtle"
                    Icon={IconV2DuotoneStar}
                    size="sm"
                    tone="purple"
                  />
                  <Text variant="lgMedium">{selectedGrant.label}</Text>
                </div>
              }
              tone="purple"
              unit={<span className="uppercase">shares</span>}
              value={
                <FormattedNumber
                  animated
                  value={selectedGrant.quantityIssued}
                />
              }
            />
          </div>
          <RemoteLikeInfoBlock info={grantDetailsInfo} />
        </div>
        {displayFullyDilutedValues &&
          !isNil(selectedGrantExercisePrice) &&
          !isNil(organizationProjectedPricePerShare) &&
          !isNil(selectedGrantTotalExercisePrice) &&
          !isNil(selectedGrantProjectedNetEquityValue) &&
          !isNil(selectedGrantProjectedTotalPrice) && (
            <RoundedBox background="gray" className="space-y-6 p-6">
              <Disclosure>
                {({ open }) => (
                  <>
                    <div className="flex items-center gap-2">
                      <Text className="flex-1" variant="lgMedium">
                        Details about this grant
                      </Text>
                      <DisclosureButton>
                        <Button
                          className="!bg-transparent"
                          IconAfter={open ? ChevronUpIcon : ChevronDownIcon}
                          size="sm"
                          tone="primary"
                          variant="outline"
                        >
                          {open ? <>Hide</> : <>Show</>}
                        </Button>
                      </DisclosureButton>
                    </div>

                    <Transition
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0"
                    >
                      <DisclosurePanel>
                        <ValueList>
                          <ValueListItem label="How are these values calculated">
                            <div className="space-y-4">
                              <div>
                                This is the total pre-tax net value of your
                                grant. Here&apos;s the calculation:
                              </div>
                              <div className="grid grid-cols-1 gap-x-3 gap-y-1 md:grid-cols-[1fr_auto_1fr_auto_1fr_auto_1fr]">
                                <RemoteLikeFakeInput
                                  label="Total # shares amount"
                                  value={
                                    <FormattedNumber
                                      animated
                                      value={selectedGrant.quantityIssued}
                                    />
                                  }
                                />
                                <div className="row-span-2 flex items-center">
                                  ×
                                </div>
                                <div className="row-span-2 grid-rows-subgrid space-y-1">
                                  <RemoteLikeFakeInput
                                    label="Price per share"
                                    value={
                                      <div className="flex gap-2">
                                        <FormattedCurrency
                                          animated
                                          value={
                                            organizationProjectedPricePerShare
                                          }
                                        />
                                        {valuationMultiple > 1 && (
                                          <Pill tone="purple">
                                            @{valuationMultiple}X
                                          </Pill>
                                        )}
                                      </div>
                                    }
                                  />
                                  <div className="px-4">
                                    <Text>
                                      <FormattedCurrency
                                        animated
                                        value={selectedGrantProjectedTotalPrice}
                                      />
                                    </Text>
                                  </div>
                                </div>
                                <div className="row-span-2 flex items-center">
                                  -
                                </div>
                                <div className="row-span-2 grid-rows-subgrid space-y-1">
                                  <RemoteLikeFakeInput
                                    label="Exercise price"
                                    value={
                                      <FormattedCurrency
                                        animated
                                        value={selectedGrantExercisePrice}
                                      />
                                    }
                                  />
                                  <div className="px-4">
                                    <Text>
                                      <FormattedCurrency
                                        animated
                                        value={selectedGrantTotalExercisePrice}
                                      />
                                    </Text>
                                  </div>
                                </div>
                                <div className="row-span-2 flex items-center">
                                  =
                                </div>
                                <RemoteLikeFakeInput
                                  label="Net Equity Value"
                                  tone="accent"
                                  value={
                                    <FormattedNumber
                                      animated
                                      minimumFractionDigits={0}
                                      value={
                                        selectedGrantProjectedNetEquityValue
                                      }
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </ValueListItem>
                          <ValueListItem label="Total amount of shares">
                            <FormattedMessage
                              defaultMessage="This grant includes a total of {shares, plural, one {# share} other {# shares}}."
                              values={{
                                shares: selectedGrant.quantityIssued,
                              }}
                            />
                          </ValueListItem>
                          <ValueListItem label="Price per share (Estimated)">
                            This is what you&apos;re assuming each stock unit is
                            worth when you sell. You can adjust your assumed
                            share price using the slider above to match the
                            eventuality of a potential company valuation.
                          </ValueListItem>
                          <ValueListItem label="Exercise Price (Based on the Fair Market value)">
                            The exercise price is the price you’ll have to pay
                            to acquire the options and turn them into actual
                            shares. It may vary from one grant to another
                            depending of the time it has been granted.
                          </ValueListItem>
                        </ValueList>
                      </DisclosurePanel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            </RoundedBox>
          )}
      </div>
    </SectionHero>
  );
});

function RemoteLikeFakeInput({
  label,
  tone = "muted",
  value,
}: {
  label: React.ReactNode;
  tone?: "accent" | "muted";
  value: React.ReactNode;
}) {
  return (
    <div
      className={classNames("rounded-remote-md border px-4 py-2", {
        "border-dashed border-grey-500 bg-grey-100": tone === "muted",
        "border-purple-600 bg-purple-100": tone === "accent",
      })}
    >
      <Text className="text-grey-600" variant="xs">
        {label}
      </Text>
      <Text className="text-grey-900" variant="sm">
        {value}
      </Text>
    </div>
  );
}

function ValueList({ children }: PropsWithChildren) {
  return <div className="divide-y-[0.5px] divide-grey-300">{children}</div>;
}

function ValueListItem({
  children,
  label,
}: PropsWithChildren<{
  label: ReactNode;
}>) {
  return (
    <div className="flex flex-col gap-x-10 gap-y-2 py-2">
      <Text variant="baseMedium">{label}</Text>
      <Text className="text-grey-600" variant="sm">
        {children}
      </Text>
    </div>
  );
}
