import { EnvelopeOpenIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../../components/Page";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../components/ui/Form/Inputs/DatePicker";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { SelectRow } from "../../../components/ui/Form/Inputs/Select/SelectRow";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";
import { countries } from "../../../services/Country";
import {
  WORK_RELATIONSHIP_TYPES,
  WORK_RELATIONSHIPS,
} from "../../../services/workRelationship";
import { CandidateSettings_EquityOffer$key } from "./__generated__/CandidateSettings_EquityOffer.graphql";
import { EquityOfferCandidate } from "./Candidate";
import { CheckboxFormRow } from "./CheckboxFormRow";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import handSrc from "./hand.svg";

const schema = z.object({
  candidateName: z.string().nullable(),
  contractStartDate: z.string().nullable(),
  contractStartDateIsKnown: z.boolean(),
  position: z.string().nullable(),
  taxResidenceCountryCode: z.string().nullable(),
  workRelationship: z.enum(WORK_RELATIONSHIP_TYPES).nullable(),
});

type EquityOfferCandidateSettingsFormInputs = z.infer<typeof schema>;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment CandidateSettings_EquityOffer on EquityOffer {
    id
    candidateName
    taxResidenceCountry {
      code
    }
    workRelationship
    position
    organization {
      id
      name
    }
    contractStartDate
    ...EquityOfferPreviewLayout_EquityOffer
    ...Candidate_EquityOffer
  }
`;

const EquityOfferCandidateSettingsPage_: React.FC = () => {
  const intl = useIntl();
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<CandidateSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );

  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: {
      candidateName: equityOffer.candidateName,
      contractStartDate: equityOffer.contractStartDate,
      contractStartDateIsKnown: equityOffer.contractStartDate ? true : false,
      position: equityOffer.position,
      taxResidenceCountryCode: equityOffer.taxResidenceCountry?.code || null,
      workRelationship: equityOffer.workRelationship,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(
    (formInputs: EquityOfferCandidateSettingsFormInputs) =>
      equityOfferContext.updatePartialEquityOffer({
        candidateName: formInputs.candidateName,
        contractStartDate: formInputs.contractStartDateIsKnown
          ? formInputs.contractStartDate
          : null,
        position: formInputs.position,
        taxResidenceCountryCode: formInputs.taxResidenceCountryCode,
        workRelationship: formInputs.workRelationship,
      }),
  );

  const contractStartDateIsKnown = watch("contractStartDateIsKnown");

  watch(() => void onSubmit());

  useEffect(() => {
    equityOfferContext.onStepEntered("CANDIDATE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      equityOfferContext.trackStepCompleted("CANDIDATE");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mailtoSubject_AboutEquityOffers = useMemo(
    () => encodeURI(`[${equityOffer.organization.name}] About equity offers`),
    [equityOffer.organization.name],
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col items-center gap-2">
        <img src={handSrc} />
        <Typography as="div" variant="Medium/Default">
          Make a new equity offer
        </Typography>
        <Typography as="div" className="text-black-05" variant="Regular/Small">
          Changes are saved automatically. Once you’re ready, share the link.
        </Typography>
        <a
          className="text-primary"
          href={`mailto:${supportEmailAddress}?subject=${mailtoSubject_AboutEquityOffers}`}
        >
          <Typography
            as="div"
            className="flex cursor-pointer gap-1 text-primary"
            variant="Medium/Extra Small"
          >
            <EnvelopeOpenIcon className="w-3" />
            Any questions?
          </Typography>
        </a>
      </div>

      <EquityOfferPreviewLayout
        continueButtonDataCy="aboutTheCandidateNextStepButton"
        equityOfferFragment={equityOffer}
        onContinueClick={equityOfferContext.goToNextStep}
        Preview={
          <EquityOfferCandidate
            equityOfferFragment={equityOffer}
            forceMobile
            hideNoticeMessages
          />
        }
        title="About the candidate"
      >
        <form className="mt-4 space-y-4" id="equity-offer-candidate-form">
          <FormRow
            error={formState.errors.candidateName?.message}
            label="Name *"
          >
            <Input
              invalid={!!formState.errors.candidateName}
              placeholder="John Doe"
              {...control.register("candidateName")}
            />
          </FormRow>

          <FormRow
            error={formState.errors.position?.message}
            label="Position *"
          >
            <Input
              invalid={!!formState.errors.position}
              placeholder="Product designer"
              {...control.register("position")}
            />
          </FormRow>

          <Controller
            control={control}
            name="taxResidenceCountryCode"
            render={({ field, fieldState }) => (
              <FormRow error={fieldState.error?.message} label="Tax residence">
                <SelectAutocomplete
                  dataCy="taxResidenceCountryCode"
                  getOptionLabel={(country) =>
                    `${country.emoji} ${country.name}`
                  }
                  getOptionValue={(country) => country.code}
                  onChange={(newValue) => field.onChange(newValue?.code)}
                  options={countries}
                  placeholder="E.g. United Kingdom"
                  value={
                    countries.find((country) => country.code === field.value) ??
                    null
                  }
                />
              </FormRow>
            )}
          />

          <Controller
            control={control}
            name="workRelationship"
            render={({ field, fieldState }) => {
              return (
                <FormRow
                  error={fieldState.error?.message}
                  id="workRelationship"
                  label="Your work relationship with the grantee"
                >
                  <SelectAutocomplete
                    dataCy="workRelationship"
                    formatOptionLabel={(data, { context }) => {
                      return (
                        <SelectRow
                          help={context === "menu" ? data.help : undefined}
                          label={data.singularLabel}
                        />
                      );
                    }}
                    getOptionLabel={(workRelationship) =>
                      workRelationship.singularLabel
                    }
                    getOptionValue={(workRelationship) => workRelationship.id}
                    onChange={(newValue) => field.onChange(newValue?.id)}
                    options={WORK_RELATIONSHIPS}
                    placeholder={`E.g. ${
                      WORK_RELATIONSHIPS[0]?.singularLabel || "Direct Employee"
                    }`}
                    usePortal
                    value={
                      WORK_RELATIONSHIPS.find(({ id }) => id === field.value) ??
                      null
                    }
                  />
                </FormRow>
              );
            }}
          />

          <CheckboxFormRow
            checkbox={
              <Controller
                control={control}
                name="contractStartDateIsKnown"
                render={({ field: { value, ...field } }) => (
                  <Checkbox checked={value} {...field} />
                )}
              />
            }
            className="pt-2"
            label="I know the contract start date"
          />

          {contractStartDateIsKnown && (
            <Controller
              control={control}
              name="contractStartDate"
              render={({ field, fieldState }) => (
                <FormRow
                  error={fieldState.error?.message}
                  id="contract-start-date"
                  label="Contract start date"
                >
                  <DatePicker
                    className="w-full"
                    minDate={new Date()}
                    onChange={field.onChange}
                    placeholder={`E.g. ${intl.formatDate(new Date(), {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}`}
                    value={field.value}
                  />
                </FormRow>
              )}
            />
          )}
        </form>
      </EquityOfferPreviewLayout>
    </div>
  );
};

const EquityOfferCandidateSettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<CandidateSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Candidate"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer candidate configuration`}
    >
      <EquityOfferCandidateSettingsPage_ />
    </Page>
  );
};

export default EquityOfferCandidateSettingsPage;
