/**
 * @generated SignedSource<<865d565776a44b6ccaafa1afad50d0ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminationPlanningEntryDetails_TerminationPlanningEntry$data = {
  readonly grantee: {
    readonly jobTitle: string | null;
    readonly name: string;
  };
  readonly sharesReturning: number;
  readonly terminationDate: string;
  readonly vestingEndDate: string | null;
  readonly waiveCliff: boolean;
  readonly " $fragmentType": "TerminationPlanningEntryDetails_TerminationPlanningEntry";
};
export type TerminationPlanningEntryDetails_TerminationPlanningEntry$key = {
  readonly " $data"?: TerminationPlanningEntryDetails_TerminationPlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationPlanningEntryDetails_TerminationPlanningEntry">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminationPlanningEntryDetails_TerminationPlanningEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waiveCliff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharesReturning",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TerminationPlanningEntry",
  "abstractKey": null
};

(node as any).hash = "4eb593f06b455eeceee0838bd672ec4d";

export default node;
