import { graphql } from "relay-runtime";
import { z } from "zod";

import { AccelerationClause } from "./__generated__/FORMSCHEMA_OrganizationGrantDefaults.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment FORMSCHEMA_OrganizationGrantDefaults on OrganizationGrantDefaults {
    # eslint-disable-next-line relay/unused-fields
    accelerationClause
  }
`;

export const FORM_SCHEMA = z.object({
  acceleration: z.enum([
    "NONE",
    "SINGLE_TRIGGER",
    "DOUBLE_TRIGGER_100",
    "DOUBLE_TRIGGER_50",
    "DOUBLE_TRIGGER_35",
    "DOUBLE_TRIGGER_25",
  ]),
  allowEarlyExercise: z.enum(["YES", "NO"]),
  postTerminationPeriodId: z.string().nullish(),
  vestingScheduleId: z.string().nullish(),
});

export type FormValues = z.infer<typeof FORM_SCHEMA>;

export type GrantDefaultsFormAccelerationClause = FormValues["acceleration"];

export function mapFormToGraphQLAccelerationClause(
  value: GrantDefaultsFormAccelerationClause,
): AccelerationClause | null {
  switch (value) {
    case "DOUBLE_TRIGGER_25":
      return "DoubleTrigger25";
    case "DOUBLE_TRIGGER_35":
      return "DoubleTrigger35";
    case "DOUBLE_TRIGGER_50":
      return "DoubleTrigger50";
    case "DOUBLE_TRIGGER_100":
      return "DoubleTrigger100";
    case "NONE":
      return null;
    case "SINGLE_TRIGGER":
      return "SingleTrigger";
  }
}

export function mapFormToGraphQLAllowEarlyExercise(
  value: FormValues["allowEarlyExercise"],
) {
  switch (value) {
    case "NO":
      return false;
    case "YES":
      return true;
  }
}

export function mapGraphQLToFormAccelerationClause(
  value: AccelerationClause | null | undefined,
): GrantDefaultsFormAccelerationClause {
  if (!value) return "NONE";
  switch (value) {
    case "DoubleTrigger25":
      return "DOUBLE_TRIGGER_25";
    case "DoubleTrigger35":
      return "DOUBLE_TRIGGER_35";
    case "DoubleTrigger50":
      return "DOUBLE_TRIGGER_50";
    case "DoubleTrigger100":
      return "DOUBLE_TRIGGER_100";
    case "SingleTrigger":
      return "SINGLE_TRIGGER";
  }
}

export function mapGraphQLToFormAllowEarlyExercise(
  value: boolean | null | undefined,
) {
  switch (value) {
    case false:
      return "NO";
    case true:
      return "YES";
    default:
      return undefined;
  }
}
