/**
 * @generated SignedSource<<ccdd56d9d9c4d5411005f3f48cc662f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation$variables = {
  granteeTerminationCTMSGrantAmendmentRequestIds: ReadonlyArray<string>;
  organizationId: string;
};
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation$data = {
  readonly markGranteeTerminationCTMSGrantAmendmentRequestsAsImplemented: ReadonlyArray<{
    readonly __typename: "GranteeTerminationCTMSGrantAmendmentRequest";
  }>;
};
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation = {
  response: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation$data;
  variables: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds",
    "variableName": "granteeTerminationCTMSGrantAmendmentRequestIds"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "markGranteeTerminationCTMSGrantAmendmentRequestsAsImplemented",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "markGranteeTerminationCTMSGrantAmendmentRequestsAsImplemented",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e4e2f55b3f84f137e1f7261fc76e8c9",
    "id": null,
    "metadata": {},
    "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation",
    "operationKind": "mutation",
    "text": "mutation Implementation_GranteeTerminationCTMSGrantAmendmentRequests_MarkGrantAmendmentRequestsAsImplemented_Mutation(\n  $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!\n  $organizationId: OrganizationId!\n) {\n  markGranteeTerminationCTMSGrantAmendmentRequestsAsImplemented(granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds, organizationId: $organizationId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcad44cd4d32b0d18983d7a70b8c0acf";

export default node;
