import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { LargeOneColumnLayout } from "../../../../components/ui/Layout/LargeOneColumnLayout";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import { ConfigureGrantsRootLayout_Query } from "./__generated__/ConfigureGrantsRootLayout_Query.graphql";

const QUERY = graphql`
  query ConfigureGrantsRootLayout_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      ...LargeOneColumnLayout_Organization
    }
  }
`;

const ConfigureGrantsRootLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<ConfigureGrantsRootLayout_Query>(QUERY, {
    organizationId,
  });

  return (
    <LargeOneColumnLayout
      organizationFragment={query.organization}
      showFooter={false}
      subtitle="Manage all your grant parameters, vesting schedules, post-termination exercise periods, acceleration terms and early exercise"
      title="Configure"
    >
      <LargeOneColumnLayout.SubNavigation
        links={
          <>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationEquityConfigureEquityPhilosophy"
                ],
                {
                  organizationId,
                },
              )}
            >
              Equity Philosophy
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureEquityGrid"],
                {
                  organizationId,
                },
              )}
            >
              Equity grid
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                {
                  organizationId,
                },
              )}
            >
              New hires
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureRefreshers"],
                {
                  organizationId,
                },
              )}
            >
              Refreshers
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationEquityConfigureVestingSchedules"
                ],
                {
                  organizationId,
                },
              )}
            >
              Vesting schedules
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationEquityConfigurePostTermination"
                ],
                {
                  organizationId,
                },
              )}
            >
              Post-termination
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureAcceleration"],
                {
                  organizationId,
                },
              )}
            >
              Acceleration
            </LargeOneColumnLayout.SubNavigation.LinkItem>
            <LargeOneColumnLayout.SubNavigation.LinkItem
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureEarlyExercise"],
                {
                  organizationId,
                },
              )}
            >
              Early exercise
            </LargeOneColumnLayout.SubNavigation.LinkItem>
          </>
        }
        organizationFragment={query.organization}
      >
        <Outlet />
      </LargeOneColumnLayout.SubNavigation>
    </LargeOneColumnLayout>
  );
};

export default ConfigureGrantsRootLayout;
