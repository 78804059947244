/**
 * @generated SignedSource<<83d44f60d22414451ae97550e365f6ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
import { FragmentRefs } from "relay-runtime";
export type BookADemoView_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly plan: OrganizationPlan;
  readonly " $fragmentType": "BookADemoView_Organization";
};
export type BookADemoView_Organization$key = {
  readonly " $data"?: BookADemoView_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoView_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "4ddeb219141c2917c47ee615032d550c";

export default node;
