/**
 * @generated SignedSource<<675397bf4770389baeb30ddbb14c2895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EasopOrganizationSettingsBilling_StripeCustomer$data = {
  readonly invoicesDefaultPaymentMethod: {
    readonly __typename: "StripePaymentMethod";
    readonly " $fragmentSpreads": FragmentRefs<"StripeCardPaymentMethod_StripePaymentMethod">;
  } | null;
  readonly " $fragmentType": "EasopOrganizationSettingsBilling_StripeCustomer";
};
export type EasopOrganizationSettingsBilling_StripeCustomer$key = {
  readonly " $data"?: EasopOrganizationSettingsBilling_StripeCustomer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EasopOrganizationSettingsBilling_StripeCustomer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EasopOrganizationSettingsBilling_StripeCustomer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePaymentMethod",
      "kind": "LinkedField",
      "name": "invoicesDefaultPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StripeCardPaymentMethod_StripePaymentMethod"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StripeCustomer",
  "abstractKey": null
};

(node as any).hash = "1971245d260f347b5090cec3b140acd3";

export default node;
