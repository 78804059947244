import {
  BookSaved,
  Calendar2,
  HomeTrendUp,
  MessageQuestion,
  ReceiptDiscount,
  RefreshCircle,
  StatusUp,
} from "iconsax-react";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../../../../paths";

export const SECTIONS = [
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeOwnership, {
        granteeId,
      }),
    icon: <HomeTrendUp variant="Bulk" />,
    label: "Ownership",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeGrantDetails, {
        granteeId,
      }),
    icon: <StatusUp variant="Bulk" />,
    label: "Grant details",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeVestingTimeline, {
        granteeId,
      }),
    icon: <Calendar2 variant="Bulk" />,
    label: "Vesting timeline",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeExercise, {
        granteeId,
      }),
    icon: <RefreshCircle variant="Bulk" />,
    label: "Exercise",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeReportingAndGeneralTaxation, {
        granteeId,
      }),
    icon: <ReceiptDiscount variant="Bulk" />,
    label: "Reporting & General taxation",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeTaxationExample, {
        granteeId,
      }),
    icon: <BookSaved variant="Bulk" />,
    label: "Taxation example",
  },
  {
    generateHref: (granteeId: string) =>
      generatePath(APPLICATION_ROUTES.nextGranteeEquity101, {
        granteeId,
      }),
    icon: <MessageQuestion variant="Bulk" />,
    label: "Equity 101",
  },
] as const;

export type Section = (typeof SECTIONS)[number];

export type SectionLabel = Section["label"];
