import { useFragment } from "react-relay";
import { Link, To } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { graphql } from "relay-runtime";

import { Footer } from "../../Footer";
import { HelpCard } from "../HelpCard";
import { Typography } from "../Typography";
import { LargeCenteredDetailsLayout_Organization$key } from "./__generated__/LargeCenteredDetailsLayout_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LargeCenteredDetailsLayout_Organization on Organization {
    ...Footer_Organization
  }
`;

export function LargeCenteredDetailsLayout({
  children,
  footerHelpCard = <HelpCard />,
  title,
  navigationTitle = title,
  organizationFragment,
  parents,
  subtitle,
  titleActions,
}: React.PropsWithChildren<{
  footerHelpCard?: React.ReactNode;
  navigationTitle?: React.ReactNode;
  organizationFragment: LargeCenteredDetailsLayout_Organization$key;
  parents: Array<{ link: To; title: React.ReactNode }>;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  titleActions?: React.ReactNode;
}>) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div>
      <div className="sticky top-0 z-10 flex items-center gap-2 border-b-[0.5px] border-gray-04 bg-white px-10 py-4 md:px-16">
        {parents.map((parent, index) => (
          <Fragment key={index}>
            <Link to={parent.link}>
              <Typography as="div" variant="Medium/Extra Small">
                {parent.title}
              </Typography>
            </Link>
            <Typography
              as="div"
              className="text-gray-08"
              variant="Medium/Small"
            >
              /
            </Typography>
          </Fragment>
        ))}
        <Typography as="div" variant="Medium/Extra Small">
          {navigationTitle}
        </Typography>
      </div>
      <div className="mx-auto w-full max-w-[1112px] px-10 py-16 md:px-16">
        <div className="space-y-10">
          <div className="flex justify-between">
            <div className="space-y-2">
              <Typography as="div" variant="Medium/Large">
                {title}
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Small"
              >
                {subtitle}
              </Typography>
            </div>
            {titleActions && <div>{titleActions}</div>}
          </div>
          <div>{children}</div>
          <div className="space-y-6">
            {footerHelpCard}
            <Footer organizationFragment={organization} />
          </div>
        </div>
      </div>
    </div>
  );
}
