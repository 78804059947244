/**
 * @generated SignedSource<<7c7cdb0b1ea21d835f419fa4f0d93782>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
export type PreviewGranteePortalFreemiumModal_Mutation$variables = {
  granteeId: string;
  taxResidenceCountryCode: string;
  workRelationship: WorkRelationship;
};
export type PreviewGranteePortalFreemiumModal_Mutation$data = {
  readonly updateGranteeAndSetAsOrganizationFreemiumPortalPreviewGrantee: {
    readonly freemiumPortalPreviewGrantee: {
      readonly id: string;
    } | null;
  };
};
export type PreviewGranteePortalFreemiumModal_Mutation = {
  response: PreviewGranteePortalFreemiumModal_Mutation$data;
  variables: PreviewGranteePortalFreemiumModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taxResidenceCountryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workRelationship"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeId",
    "variableName": "granteeId"
  },
  {
    "kind": "Variable",
    "name": "taxResidenceCountryCode",
    "variableName": "taxResidenceCountryCode"
  },
  {
    "kind": "Variable",
    "name": "workRelationship",
    "variableName": "workRelationship"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Grantee",
  "kind": "LinkedField",
  "name": "freemiumPortalPreviewGrantee",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PreviewGranteePortalFreemiumModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateGranteeAndSetAsOrganizationFreemiumPortalPreviewGrantee",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PreviewGranteePortalFreemiumModal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateGranteeAndSetAsOrganizationFreemiumPortalPreviewGrantee",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bac4a37a6cecc9b933161a50ece9961d",
    "id": null,
    "metadata": {},
    "name": "PreviewGranteePortalFreemiumModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation PreviewGranteePortalFreemiumModal_Mutation(\n  $granteeId: GranteeId!\n  $taxResidenceCountryCode: String!\n  $workRelationship: WorkRelationship!\n) {\n  updateGranteeAndSetAsOrganizationFreemiumPortalPreviewGrantee(granteeId: $granteeId, taxResidenceCountryCode: $taxResidenceCountryCode, workRelationship: $workRelationship) {\n    freemiumPortalPreviewGrantee {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f87938c8225e8b0d635c2f2891463c9";

export default node;
