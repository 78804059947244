import { graphql } from "react-relay";

import { useGenerateOrganizationCartaAuthorizationFlowURL_Mutation } from "./__generated__/useGenerateOrganizationCartaAuthorizationFlowURL_Mutation.graphql";
import { useSafeMutation } from "./useSafeMutation";

const GENERATE_AUTHORIZATION_FLOW_URL_MUTATION = graphql`
  mutation useGenerateOrganizationCartaAuthorizationFlowURL_Mutation(
    $organizationId: OrganizationId!
    $returnURL: String!
    $doNotConnectIfMultipleIssuersSelected: Boolean!
  ) {
    generateOrganizationCartaAuthorizationFlowURL(
      organizationId: $organizationId
      returnURL: $returnURL
      doNotConnectIfMultipleIssuersSelected: $doNotConnectIfMultipleIssuersSelected
    )
  }
`;

export const useGenerateOrganizationCartaAuthorizationFlowURL = ({
  doNotConnectIfMultipleIssuersSelected,
  organizationId,
  returnURL = window.location.href,
}: {
  doNotConnectIfMultipleIssuersSelected: boolean;
  organizationId: string;
  returnURL?: string;
}) => {
  const [triggerMutation, mutationIsInFlight] =
    useSafeMutation<useGenerateOrganizationCartaAuthorizationFlowURL_Mutation>(
      GENERATE_AUTHORIZATION_FLOW_URL_MUTATION,
    );

  const generateOrganizationCartaAuthorizationFlowURL = async () => {
    const result = await triggerMutation({
      variables: {
        doNotConnectIfMultipleIssuersSelected,
        organizationId,
        returnURL,
      },
    });

    return result.generateOrganizationCartaAuthorizationFlowURL;
  };

  return { generateOrganizationCartaAuthorizationFlowURL, mutationIsInFlight };
};
