import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useAlerter } from "../../../../components/Alerter";
import { Alert } from "../../../../components/ui/Alert";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useTaxFavoredSubplanExpiredGuard_Organization$key } from "./__generated__/useTaxFavoredSubplanExpiredGuard_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useTaxFavoredSubplanExpiredGuard_Organization on Organization {
    name
  }
`;

const WarningTaxFavoredSubplanExpiredTitle: React.FC = () => (
  <>We cannot let you move forward with those drafts 😔</>
);

export const WarningTaxFavoredSubplanExpiredMessage: React.FC<{
  organizationName: string;
}> = ({ organizationName }) => {
  const mailtoSubject = encodeURI(
    `[${organizationName}] What should I do when my board authorisation to issue BSPCE has expired?`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <>
      Board authorisation to issue BSPCE has expired. Please{" "}
      <a
        className="font-medium text-primary"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
      >
        contact us
      </a>{" "}
      to refresh it.
    </>
  );
};

export const useTaxFavoredSubplanExpiredGuard = ({
  organizationFragment,
}: {
  organizationFragment: useTaxFavoredSubplanExpiredGuard_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const alerter = useAlerter();

  const showTaxFavoredSubplanExpiredGuard = useCallback(() => {
    alerter.push(
      <Alert
        buttonLabel="Get back to drafting"
        title={<WarningTaxFavoredSubplanExpiredTitle />}
      >
        {
          <WarningTaxFavoredSubplanExpiredMessage
            organizationName={organization.name}
          />
        }
      </Alert>,
    );
  }, [alerter, organization]);

  return {
    showTaxFavoredSubplanExpiredGuard,
  };
};
