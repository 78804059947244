/**
 * @generated SignedSource<<2076d0f53da248b6fada4d57048e0079>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SelectCartaIssuerBlock_SelectCartaIssuerIdMutation$variables = {
  cartaIssuerId: string;
  organizationId: string;
};
export type SelectCartaIssuerBlock_SelectCartaIssuerIdMutation$data = {
  readonly selectCartaIssuerId: {
    readonly selectedCartaIssuerId: string | null;
  };
};
export type SelectCartaIssuerBlock_SelectCartaIssuerIdMutation = {
  response: SelectCartaIssuerBlock_SelectCartaIssuerIdMutation$data;
  variables: SelectCartaIssuerBlock_SelectCartaIssuerIdMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cartaIssuerId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "cartaIssuerId",
    "variableName": "cartaIssuerId"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "selectedCartaIssuerId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCartaIssuerBlock_SelectCartaIssuerIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "selectCartaIssuerId",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SelectCartaIssuerBlock_SelectCartaIssuerIdMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "selectCartaIssuerId",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de20a77581e800a4c675b547be6dd3f4",
    "id": null,
    "metadata": {},
    "name": "SelectCartaIssuerBlock_SelectCartaIssuerIdMutation",
    "operationKind": "mutation",
    "text": "mutation SelectCartaIssuerBlock_SelectCartaIssuerIdMutation(\n  $organizationId: OrganizationId!\n  $cartaIssuerId: String!\n) {\n  selectCartaIssuerId(organizationId: $organizationId, cartaIssuerId: $cartaIssuerId) {\n    selectedCartaIssuerId\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "321a75961444fe94d53c2ddcfc08efbb";

export default node;
