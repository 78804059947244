/**
 * @generated SignedSource<<2b61e52350c5358a80c6c80d793d5b51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PlanningEntrySuggestionType = "LEVELING_GRANT" | "TENURE_GRANT";
export type PlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "NEW_HIRE_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT" | "TERMINATION";
import { FragmentRefs } from "relay-runtime";
export type PlanningEditableListView_Organization$data = {
  readonly foundPlanningEntries: ReadonlyArray<{
    readonly __typename: string;
    readonly type?: PlanningEntryType;
    readonly " $fragmentSpreads": FragmentRefs<"PlanningEntriesTable_PlanningEntries">;
  }>;
  readonly foundPlanningEntriesSuggestions?: ReadonlyArray<{
    readonly id: string;
    readonly type: PlanningEntrySuggestionType;
    readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion" | "RefresherGrantPlanningEntryModal_PlanningEntrySuggestion">;
  }>;
  readonly id: string;
  readonly searchOnlyPlanningEntries: ReadonlyArray<{
    readonly __typename: "NewHireGrantPlanningEntry";
    readonly id: string;
    readonly type: PlanningEntryType;
  } | {
    readonly __typename: "RefresherGrantPlanningEntry";
    readonly id: string;
    readonly type: PlanningEntryType;
  } | {
    readonly __typename: "TerminationPlanningEntry";
    readonly id: string;
    readonly type: PlanningEntryType;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"NewHirePlanningEntryModal_Organization" | "PlanningEntriesTable_Organization" | "PlanningEntrySuggestionsCollapsibleSection_Organization" | "RefresherGrantPlanningEntryModal_Organization" | "TerminationPlanningEntryModal_Organization">;
  readonly " $fragmentType": "PlanningEditableListView_Organization";
};
export type PlanningEditableListView_Organization$key = {
  readonly " $data"?: PlanningEditableListView_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEditableListView_Organization">;
};

import PlanningEditableListView_Organization_Refetchable_graphql from './PlanningEditableListView_Organization_Refetchable.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planningEntrySuggestionType"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "planningEntryType"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "skipPlanningEntrySuggestions"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PlanningEditableListView_Organization_Refetchable_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PlanningEditableListView_Organization",
  "selections": [
    {
      "alias": "searchOnlyPlanningEntries",
      "args": [
        (v0/*: any*/)
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "planningEntries",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "NewHireGrantPlanningEntry",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "RefresherGrantPlanningEntry",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v4/*: any*/),
          "type": "TerminationPlanningEntry",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "foundPlanningEntries",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "planningEntryType"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "planningEntries",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "NewHireGrantPlanningEntry",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "RefresherGrantPlanningEntry",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v5/*: any*/),
          "type": "TerminationPlanningEntry",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PlanningEntriesTable_PlanningEntries"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "skipPlanningEntrySuggestions",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": "foundPlanningEntriesSuggestions",
          "args": [
            (v0/*: any*/),
            {
              "kind": "Variable",
              "name": "type",
              "variableName": "planningEntrySuggestionType"
            }
          ],
          "concreteType": "PlanningEntrySuggestion",
          "kind": "LinkedField",
          "name": "planningEntrySuggestions",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RefresherGrantPlanningEntryModal_PlanningEntrySuggestion"
            }
          ],
          "storageKey": null
        }
      ]
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewHirePlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefresherGrantPlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TerminationPlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlanningEntrySuggestionsCollapsibleSection_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PlanningEntriesTable_Organization"
    },
    (v2/*: any*/)
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "cdafc80fef89c823c83c610bbd41e8af";

export default node;
