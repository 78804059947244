/**
 * @generated SignedSource<<f2b184ef4196b92440f57d1fd1191d58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest">;
  readonly " $fragmentType": "ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest";
}>;
export type ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest$key = ReadonlyArray<{
  readonly " $data"?: ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConfirmSubmitBoardConsentSlide_GranteeTerminationCTMSGrantAmendmentRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest"
    }
  ],
  "type": "GranteeTerminationCTMSGrantAmendmentRequest",
  "abstractKey": null
};

(node as any).hash = "59dac96b2a175abec9a78a2f25b6bbda";

export default node;
