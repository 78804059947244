/**
 * @generated SignedSource<<87607ef9c42dabbd717e16d8713579fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation$variables = {
  ctmsGrantAmendmentRequestIds: ReadonlyArray<string>;
};
export type UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation$data = {
  readonly reviewCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
};
export type UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation = {
  response: UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation$data;
  variables: UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsGrantAmendmentRequestIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ctmsGrantAmendmentRequestIds",
    "variableName": "ctmsGrantAmendmentRequestIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "reviewCTMSGrantAmendmentRequests",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "reviewCTMSGrantAmendmentRequests",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e2656e84d9344de5cc725328029a0e8",
    "id": null,
    "metadata": {},
    "name": "UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation",
    "operationKind": "mutation",
    "text": "mutation UnderReview_CTMSGrantAmendmentRequests_ReviewGrantAmendmentRequests_Mutation(\n  $ctmsGrantAmendmentRequestIds: [UUID!]!\n) {\n  reviewCTMSGrantAmendmentRequests(ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fc3f72fcaa95d856b93a23b02b0be10";

export default node;
