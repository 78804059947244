import { zodResolver } from "@hookform/resolvers/zod";
import { addYears, isBefore, subDays } from "date-fns";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { addPeriodToDate } from "../../helpers/addPeriodToDate";
import { makeRemoteController } from "../../helpers/makeRemoteController";
import { CreateSlideOverRemote } from "../../pages/Admin/Grants/Configure/PostTermination/CreateSlideOver";
import { comparePtep } from "../../services/postTerminationExercisePeriod";
import { SelectOrganizationPostTerminationExercisePeriod } from "../GrantForm/SelectOrganizationPostTerminationExercisePeriod";
import { GrantWatchouts } from "../GrantWatchouts";
import { Button } from "../ui/Button";
import { FormActions } from "../ui/Form/FormActions";
import { FormRow } from "../ui/Form/FormRow";
import { NoticeMessage } from "../ui/NoticeMessage";
import { SlideOver } from "../ui/SlideOver";
import { Typography } from "../ui/Typography";
import {
  GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$data,
  GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$key,
} from "./__generated__/GrantAmendmentRequestBoxPTEPSlide_CtmsGrant.graphql";
import {
  GrantAmendmentRequestBoxPTEPSlide_Organization$data,
  GrantAmendmentRequestBoxPTEPSlide_Organization$key,
} from "./__generated__/GrantAmendmentRequestBoxPTEPSlide_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBoxPTEPSlide_Organization on Organization {
    postTerminationExercisePeriods {
      id
      duration
      durationUnit
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod
    }
    ...SelectOrganizationPostTerminationExercisePeriod_Organization
  }
`;

const GRANT_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBoxPTEPSlide_CtmsGrant on CTMSGrant {
    isVirtual
    grantDate
    matchingInstrument {
      equityType {
        ...GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType
      }
    }
  }
`;

const useSchema = ({
  grant,
  organization,
  terminationDate,
}: {
  grant: GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$data;
  organization: GrantAmendmentRequestBoxPTEPSlide_Organization$data;
  terminationDate: Date;
}) => {
  const grantDate = new Date(grant.grantDate);
  const grantExpirationDate = addYears(grantDate, 10);
  const intl = useIntl();
  return z
    .object({
      postTerminationExercisePeriodId: z.string(),
    })
    .refine(
      ({ postTerminationExercisePeriodId }) => {
        const postTerminationExercisePeriod =
          organization.postTerminationExercisePeriods.find(
            (ptep) => ptep.id === postTerminationExercisePeriodId,
          );

        if (!postTerminationExercisePeriod) {
          throw new Error("Can't find selected PTEP");
        }

        const amendedLastDayToExercise = addPeriodToDate(
          terminationDate,
          postTerminationExercisePeriod.duration,
          postTerminationExercisePeriod.durationUnit,
        );
        return isBefore(amendedLastDayToExercise, grantExpirationDate);
      },
      {
        message: `Grants expire after a 10 years period. You cannot extend the PTEP past ${intl.formatDate(
          subDays(grantExpirationDate, 1),
          {
            day: "2-digit",
            month: "short",
            year: "numeric",
          },
        )}. Please update to finish the amendment`,
        path: ["postTerminationExercisePeriod"],
      },
    );
};

type GrantAmendmentRequestPTEPFormInputs = z.output<
  ReturnType<typeof useSchema>
>;

export const LoweringPTEPWarning: React.FC = () => {
  return (
    <NoticeMessage size="Small" variant="Warning">
      You should not consider lowering the Post-termination exercise period.
    </NoticeMessage>
  );
};

const GrantAmendmentRequestBoxPTEPSlide: React.FC<{
  amendedPtepId: null | string;
  grantFragment: GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$key;
  onClose: () => void;
  onSubmit: (postTerminationExercisePeriodId: string) => void;
  open: boolean;
  organizationFragment: GrantAmendmentRequestBoxPTEPSlide_Organization$key;
  originalPtepId: null | string;
  terminationDate: Date;
}> = ({
  amendedPtepId,
  grantFragment,
  onClose,
  onSubmit,
  open,
  organizationFragment,
  originalPtepId,
  terminationDate,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);
  const schema = useSchema({ grant, organization, terminationDate });
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      postTerminationExercisePeriodId: amendedPtepId ?? originalPtepId,
    },
    resolver: zodResolver(schema),
  });

  const onSubmitForm = handleSubmit((_formInputs) => {
    const formInputs = _formInputs as GrantAmendmentRequestPTEPFormInputs;
    onSubmit(formInputs.postTerminationExercisePeriodId);

    onClose();
  });

  const newPtepId = watch("postTerminationExercisePeriodId");

  const newPtep = useMemo(
    () =>
      organization.postTerminationExercisePeriods.find(
        (ptep) => ptep.id === newPtepId,
      ),
    [newPtepId, organization.postTerminationExercisePeriods],
  );

  const originalPtep = useMemo(
    () =>
      organization.postTerminationExercisePeriods.find(
        (ptep) => ptep.id === originalPtepId,
      ),
    [originalPtepId, organization.postTerminationExercisePeriods],
  );

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={10}>
          Post-termination Exercise Period
        </SlideOver.Header>
      }
      onClose={onClose}
      show={open}
    >
      <CreateSlideOverRemote.Provider>
        <div className="space-y-4 p-10 pt-0">
          <FormRow.Form
            control={control}
            label="Post-termination exercise period"
            name="postTerminationExercisePeriodId"
          >
            <SelectOrganizationPostTerminationExercisePeriod
              control={control}
              isVirtual={grant.isVirtual}
              name="postTerminationExercisePeriodId"
              organizationFragment={organization}
            />
          </FormRow.Form>

          {newPtep && grant.matchingInstrument?.equityType && (
            <GrantWatchouts.PtepGreaterThan90DaysWatchout.UsingPtepFragment
              equityTypeFragment={grant.matchingInstrument.equityType}
              postTerminationExercisePeriodFragment={newPtep}
            />
          )}

          {originalPtep &&
          newPtep &&
          comparePtep(newPtep, originalPtep) === "LOWER" ? (
            <LoweringPTEPWarning />
          ) : null}

          <FormActions className="justify-between pt-10" onCancel={onClose}>
            <Button onClick={onSubmitForm} size="small" type="button">
              Modify
            </Button>
          </FormActions>

          <Typography
            as="div"
            className="text-right text-black-05"
            variant="Regular/Caption"
          >
            Amending a grant will always require the approval from the board.
          </Typography>
        </div>
      </CreateSlideOverRemote.Provider>
    </SlideOver>
  );
};

export const GrantAmendmentRequestBoxPTEPSlideOverRemote =
  makeRemoteController<{
    amendedPtepId: null | string;
    grantFragment: GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$key;
    onPTEPChange: (postTerminationExercisePeriodId: string) => void;
    organizationFragment: GrantAmendmentRequestBoxPTEPSlide_Organization$key;
    originalPtepId: null | string;
    terminationDate: Date;
  }>({
    render: ({ close, state }) => {
      if (!state.data) {
        return null;
      }

      return (
        <GrantAmendmentRequestBoxPTEPSlide
          amendedPtepId={state.data.amendedPtepId}
          grantFragment={state.data.grantFragment}
          onClose={close}
          onSubmit={state.data.onPTEPChange}
          open={state.show}
          organizationFragment={state.data.organizationFragment}
          originalPtepId={state.data.originalPtepId}
          terminationDate={state.data.terminationDate}
        />
      );
    },
  });
