import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { EsopPoolChartCard_Organization$key } from "./__generated__/EsopPoolChartCard_Organization.graphql";
import { EsopChart } from "./EsopChart";
import { FormattedPercentage } from "./Formatted/FormattedPercentage";
import { Card } from "./ui/Card";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EsopPoolChartCard_Organization on Organization {
    fullyDilutedShares
    esopPoolShares
    poolAvailableShares
    poolDraftedSharesBreakdown {
      real
      virtual
      total
    }
    poolVestedExercisedSharesBreakdown {
      real
      virtual
      total
    }
    poolVestedToExerciseSharesBreakdown {
      real
      virtual
      total
    }
    poolPendingSharesBreakdown {
      real
      virtual
      total
    }
    poolGrantedToVestSharesBreakdown {
      real
      virtual
      total
    }
    poolSettlingSharesBreakdown {
      real
      virtual
      total
    }
  }
`;

export const EsopPoolChartCard: React.FC<{
  className?: string;
  organizationFragment: EsopPoolChartCard_Organization$key;
}> = ({ className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const availableSharesWithoutDrafted =
    organization.poolAvailableShares -
    organization.poolDraftedSharesBreakdown.total;

  if (!organization.esopPoolShares) return null;
  if (!organization.fullyDilutedShares) return null;

  const esopPoolOverFullyDilutedSharesRatio =
    organization.esopPoolShares / organization.fullyDilutedShares;

  const draftedSharesPercent =
    (organization.poolDraftedSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;

  const availableSharesWithoutDraftedPercent =
    (availableSharesWithoutDrafted / organization.fullyDilutedShares) * 100;

  const pendingSharesPercent =
    (organization.poolPendingSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;

  const grantedToVestSharesPercent =
    (organization.poolGrantedToVestSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;
  const grantedVestedToExerciseSharesPercent =
    (organization.poolVestedToExerciseSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;
  const grantedSettlingSharesPercent =
    (organization.poolSettlingSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;
  const grantedVestedExercisedSharesPercent =
    (organization.poolVestedExercisedSharesBreakdown.total /
      organization.fullyDilutedShares) *
    100;

  return (
    <Card className={className} title="Pool Size">
      <div className="flex justify-between">
        <div>
          <Typography variant="Medium/Default">
            <FormattedPercentage value={esopPoolOverFullyDilutedSharesRatio} />
          </Typography>
          <Typography
            as="div"
            className="mb-1 text-black-05"
            variant="Regular/Extra Small"
          >
            Fully diluted
          </Typography>
        </div>

        <div className="text-right">
          <Typography variant="Medium/Default">
            <FormattedNumber value={organization.esopPoolShares} />
          </Typography>
          <Typography
            as="div"
            className="mb-1 text-black-05"
            variant="Regular/Extra Small"
          >
            Shares
          </Typography>
        </div>
      </div>

      <EsopChart
        available={{
          percent: availableSharesWithoutDraftedPercent,
          realShares: availableSharesWithoutDrafted,
          shares: availableSharesWithoutDrafted,
          virtualShares: 0,
        }}
        barGap={32}
        barSize={24}
        drafted={{
          percent: draftedSharesPercent,
          realShares: organization.poolDraftedSharesBreakdown.real,
          shares: organization.poolDraftedSharesBreakdown.total,
          virtualShares: organization.poolDraftedSharesBreakdown.virtual,
        }}
        grantedSettling={{
          percent: grantedSettlingSharesPercent,
          realShares: organization.poolSettlingSharesBreakdown.real,
          shares: organization.poolSettlingSharesBreakdown.total,
          virtualShares: organization.poolSettlingSharesBreakdown.virtual,
        }}
        grantedToVest={{
          percent: grantedToVestSharesPercent,
          realShares: organization.poolGrantedToVestSharesBreakdown.real,
          shares: organization.poolGrantedToVestSharesBreakdown.total,
          virtualShares: organization.poolGrantedToVestSharesBreakdown.virtual,
        }}
        grantedVestedExercised={{
          percent: grantedVestedExercisedSharesPercent,
          realShares: organization.poolVestedExercisedSharesBreakdown.real,
          shares: organization.poolVestedExercisedSharesBreakdown.total,
          virtualShares:
            organization.poolVestedExercisedSharesBreakdown.virtual,
        }}
        grantedVestedToExercise={{
          percent: grantedVestedToExerciseSharesPercent,
          realShares: organization.poolVestedToExerciseSharesBreakdown.real,
          shares: organization.poolVestedToExerciseSharesBreakdown.total,
          virtualShares:
            organization.poolVestedToExerciseSharesBreakdown.virtual,
        }}
        pending={{
          percent: pendingSharesPercent,
          realShares: organization.poolPendingSharesBreakdown.real,
          shares: organization.poolPendingSharesBreakdown.total,
          virtualShares: organization.poolPendingSharesBreakdown.virtual,
        }}
      />
    </Card>
  );
};
