import { Pill } from "@remote-com/norma";
import classNames from "classnames";
import {
  addMonths,
  addYears,
  differenceInMonths,
  differenceInYears,
  formatDate,
  isBefore,
  isSameMonth,
  isSameYear,
} from "date-fns";
import _, { mapValues, maxBy, minBy, sumBy } from "lodash";
import { ComponentProps, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from "recharts";

import { formatCurrency, formatNumber } from "../helpers/formatter";
import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import {
  GranteeVestingGraph_Grantee$data,
  GranteeVestingGraph_Grantee$key,
} from "./__generated__/GranteeVestingGraph_Grantee.graphql";
import { RoundedBarShape, RoundedBarShapeProps } from "./ui/RoundedBarShape";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeVestingGraph_Grantee on Grantee {
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      label
      vestingDataPoints {
        cumulativeVested
        date
      }
    }
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useOrganizationSharesUtil_Organization
    }
  }
`;

type PillTone = ComponentProps<typeof Pill>["tone"];

export const VESTING_SCHEDULE_GRANTS_STYLE: {
  fillColor: string;
  pillTone: PillTone;
  textColor: string;
}[] = [
  {
    fillColor: classNames("fill-brand-600"),
    pillTone: "blue",
    textColor: classNames("text-brand-600"),
  },
  {
    fillColor: classNames("fill-red-600"),
    pillTone: "error",
    textColor: classNames("text-red-600"),
  },
  {
    fillColor: classNames("fill-purple-600"),
    pillTone: "purple",
    textColor: classNames("text-purple-600"),
  },
  {
    fillColor: classNames("fill-green-600"),
    pillTone: "success",
    textColor: classNames("text-green-600"),
  },
  {
    fillColor: classNames("fill-pink-600"),
    pillTone: "pink",
    textColor: classNames("text-pink-600"),
  },
  {
    fillColor: classNames("fill-cyan-600"),
    pillTone: "cyan",
    textColor: classNames("text-cyan-600"),
  },
];

type GROUP_BY = "month" | "year";

const buildEmptyVestingEventsFromDates = ({
  groupBy,
  maximumVestingEventDate,
  minimumVestingEventDate,
}: {
  groupBy: GROUP_BY;
  maximumVestingEventDate: string;
  minimumVestingEventDate: string;
}): { date: string }[] => {
  switch (groupBy) {
    case "month":
      return Array.from(
        {
          length:
            differenceInMonths(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(
            addMonths(minimumVestingEventDate, index),
            "yyyy-MM",
          ),
        }),
      );
    case "year":
      return Array.from(
        {
          length:
            differenceInYears(
              maximumVestingEventDate,
              minimumVestingEventDate,
            ) + 1,
        },
        (_, index) => ({
          date: formatDate(addYears(minimumVestingEventDate, index), "yyyy"),
        }),
      );
  }
};

const getCumulativeVestedAtDate = ({
  date,
  grantee,
  groupBy,
}: {
  date: string;
  grantee: GranteeVestingGraph_Grantee$data;
  groupBy: GROUP_BY;
}) => {
  switch (groupBy) {
    case "month":
      return sumBy(grantee.ctmsGrants, (ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameMonth(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return vestingDataPoint?.cumulativeVested || 0;
      });
    case "year":
      return sumBy(grantee.ctmsGrants, (ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameYear(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return vestingDataPoint?.cumulativeVested || 0;
      });
  }
};

const getCumulativeVestedAtDatePerGrant = ({
  date,
  grantee,
  groupBy,
}: {
  date: string;
  grantee: GranteeVestingGraph_Grantee$data;
  groupBy: GROUP_BY;
}): Record<string, number> => {
  switch (groupBy) {
    case "month":
      return grantee.ctmsGrants.reduce((acc, ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameMonth(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [ctmsGrant.label]: vestingDataPoint?.cumulativeVested || 0,
        };
      }, {});
    case "year":
      return grantee.ctmsGrants.reduce((acc, ctmsGrant) => {
        const vestingDataPoint = ctmsGrant.vestingDataPoints.findLast(
          (dataPoint) =>
            isSameYear(dataPoint.date, date) || isBefore(dataPoint.date, date),
        );
        return {
          ...acc,
          [ctmsGrant.label]: vestingDataPoint?.cumulativeVested || 0,
        };
      }, {});
  }
};

export const GranteeVestingGraph: React.FC<{
  granteeFragment: GranteeVestingGraph_Grantee$key;
  groupBy: GROUP_BY;
  rounded?: boolean;
  valuationMultiple: number;
  view: "cumulative" | "split";
}> = ({ granteeFragment, groupBy, rounded, valuationMultiple, view }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const displayValuation =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const convertSharesToGraphData = useCallback(
    (shares: number) =>
      displayValuation ? sharesToValue(shares * valuationMultiple) : shares,
    [sharesToValue, valuationMultiple, displayValuation],
  );

  const vestingEvents = useMemo(() => {
    const allVestingEventsDate = _(grantee.ctmsGrants)
      .flatMap((ctmsGrant) => ctmsGrant.vestingDataPoints)
      .map((dataPoint) =>
        dataPoint.cumulativeVested > 0 ? dataPoint.date : null,
      )
      .compact()
      .value();
    const minimumVestingEventDate = minBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );
    const maximumVestingEventDate = maxBy(allVestingEventsDate, (date) =>
      new Date(date).getTime(),
    );

    if (!minimumVestingEventDate || !maximumVestingEventDate) {
      return [];
    }

    const emptyVestingEvents = buildEmptyVestingEventsFromDates({
      groupBy,
      maximumVestingEventDate,
      minimumVestingEventDate,
    });

    return emptyVestingEvents.map(({ date }) => {
      const cumulativeVestedAtDate = getCumulativeVestedAtDate({
        date,
        grantee,
        groupBy,
      });

      const cumulativeVestedAtDatePerGrants = getCumulativeVestedAtDatePerGrant(
        {
          date,
          grantee,
          groupBy,
        },
      );

      return {
        date,
        vested: convertSharesToGraphData(cumulativeVestedAtDate),
        ...mapValues(
          cumulativeVestedAtDatePerGrants,
          (cumulativeVestedAtDate) =>
            convertSharesToGraphData(cumulativeVestedAtDate),
        ),
      };
    });
  }, [convertSharesToGraphData, groupBy, grantee]);

  return (
    <ResponsiveContainer height="100%" width="100%">
      <BarChart data={vestingEvents} margin={{ bottom: 5, top: 20 }}>
        <CartesianGrid strokeDasharray="2 4" vertical={false} />
        <XAxis
          axisLine={false}
          dataKey="date"
          padding={{ left: 70, right: 20 }}
          tick={(props) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            const date: string = props.payload.value;
            return (
              <Text
                {...props}
                className="font-regularSmall translate-y-3 fill-grey-500"
              >
                {formatDate(date, "yyyy")}
              </Text>
            );
          }}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          mirror={true}
          tick={(props: { payload: { value: number } }) => (
            <Text
              {...props}
              className="font-regularSmall -translate-x-2 -translate-y-3 transform fill-grey-500"
            >
              {displayValuation
                ? formatCurrency(props.payload.value, {
                    compactDisplay: "short",
                    maximumSignificantDigits: 3,
                    notation: "compact",
                  })
                : formatNumber(props.payload.value)}
            </Text>
          )}
          tickLine={false}
        />
        {view === "cumulative" && (
          <Bar
            className="fill-purple-700"
            dataKey="vested"
            shape={
              rounded
                ? (props: unknown) => (
                    <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                  )
                : undefined
            }
          />
        )}
        {view === "split" &&
          grantee.ctmsGrants
            .toReversed()
            .map((ctmsGrant, index) => (
              <Bar
                className={
                  VESTING_SCHEDULE_GRANTS_STYLE[
                    (grantee.ctmsGrants.length - index - 1) %
                      VESTING_SCHEDULE_GRANTS_STYLE.length
                  ]?.fillColor
                }
                dataKey={ctmsGrant.label}
                key={ctmsGrant.label}
                shape={
                  rounded
                    ? (props: unknown) => (
                        <RoundedBarShape {...(props as RoundedBarShapeProps)} />
                      )
                    : undefined
                }
                stackId="__stackId"
              />
            ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
