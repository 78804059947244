/**
 * @generated SignedSource<<7de832e2e95bf8586600dffea727fcb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestTaxInformationExport_FairMarketValueAtExercise$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_FairMarketValueAtExercise">;
  readonly " $fragmentType": "ExerciseRequestTaxInformationExport_FairMarketValueAtExercise";
};
export type ExerciseRequestTaxInformationExport_FairMarketValueAtExercise$key = {
  readonly " $data"?: ExerciseRequestTaxInformationExport_FairMarketValueAtExercise$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestTaxInformationExport_FairMarketValueAtExercise">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestTaxInformationExport_FairMarketValueAtExercise",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_FairMarketValueAtExercise"
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "eca233f160714d6efd90b96593c99625";

export default node;
