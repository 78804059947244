export const MatchGranteeEmptyStateIllustration: React.FC<
  React.ComponentProps<"svg">
> = ({ className, ...props }) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 416 128"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_3284_4779)">
        <rect fill="white" height="40" rx="20" width="360" x="52" y="80.9999" />
        <rect fill="#F0FAFF" height="8" rx="4" width="32" x="68" y="96.9999" />
        <rect
          fill="#F0FAFF"
          height="8"
          rx="4"
          width="288"
          x="108"
          y="96.9999"
        />
      </g>
      <g filter="url(#filter1_d_3284_4779)">
        <rect fill="white" height="40" rx="20" width="360" x="28" y="40.9999" />
        <rect fill="#F5F3FF" height="8" rx="4" width="32" x="44" y="56.9999" />
        <rect fill="#F5F3FF" height="8" rx="4" width="288" x="84" y="56.9999" />
      </g>
      <g filter="url(#filter2_d_3284_4779)">
        <rect fill="white" height="40" rx="20" width="360" x="4" y="0.999893" />
        <rect fill="#FDF4FF" height="8" rx="4" width="32" x="20" y="16.9999" />
        <rect fill="#FDF4FF" height="8" rx="4" width="288" x="60" y="16.9999" />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="48"
          id="filter0_d_3284_4779"
          width="368"
          x="48"
          y="79.9999"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.164706 0 0 0 0 0.203922 0 0 0 0.1 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_3284_4779"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3284_4779"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="48"
          id="filter1_d_3284_4779"
          width="368"
          x="24"
          y="39.9999"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.164706 0 0 0 0 0.203922 0 0 0 0.1 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_3284_4779"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3284_4779"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="48"
          id="filter2_d_3284_4779"
          width="368"
          x="0"
          y="-0.000106812"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.101961 0 0 0 0 0.164706 0 0 0 0 0.203922 0 0 0 0.1 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_3284_4779"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_3284_4779"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
