/**
 * @generated SignedSource<<d7852d445a924428f72463ef10138f77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Country_Query$variables = {
  countryCode: string;
  organizationId: string;
};
export type Country_Query$data = {
  readonly geography: {
    readonly country: {
      readonly emoji: string;
      readonly name: string;
    };
  };
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Country_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Country_Organization">;
  } | null;
  readonly organizationGeography: {
    readonly " $fragmentSpreads": FragmentRefs<"Country_OrganizationGeography">;
  } | null;
};
export type Country_Query = {
  response: Country_Query$data;
  variables: Country_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "kind": "Variable",
  "name": "countryCode",
  "variableName": "countryCode"
},
v6 = {
  "kind": "Variable",
  "name": "organizationId",
  "variableName": "organizationId"
},
v7 = [
  (v5/*: any*/),
  (v6/*: any*/)
],
v8 = [
  (v6/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emoji",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": [
    (v5/*: any*/)
  ],
  "concreteType": "Geography",
  "kind": "LinkedField",
  "name": "geography",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Country_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Country_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Country_OrganizationGeography"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": (v8/*: any*/),
            "kind": "FragmentSpread",
            "name": "Country_Viewer"
          }
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Country_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "organizationGeography",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "unlockedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isTaxFavoredEquityTypeUnlocked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "draftCount",
            "storageKey": null
          },
          {
            "alias": "activeAndTerminatedOptionGrantsCount",
            "args": [
              {
                "kind": "Literal",
                "name": "ctmsGrantStatusIn",
                "value": [
                  "Active",
                  "Terminated"
                ]
              }
            ],
            "kind": "ScalarField",
            "name": "optionGrantsCount",
            "storageKey": "optionGrantsCount(ctmsGrantStatusIn:[\"Active\",\"Terminated\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "billableGranteesCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Geography",
            "kind": "LinkedField",
            "name": "geography",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityType",
                "kind": "LinkedField",
                "name": "equityTypes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Instrument",
                    "kind": "LinkedField",
                    "name": "instruments",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "taxFavored",
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "kind": "ScalarField",
            "name": "isAllowedToUnlockCountryForOrganization",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v10/*: any*/)
    ]
  },
  "params": {
    "cacheID": "363e61cf013998fd331d59ad0061dd90",
    "id": null,
    "metadata": {},
    "name": "Country_Query",
    "operationKind": "query",
    "text": "query Country_Query(\n  $organizationId: OrganizationId!\n  $countryCode: String!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Country_Organization\n  }\n  organizationGeography(countryCode: $countryCode, organizationId: $organizationId) {\n    ...Country_OrganizationGeography\n    id\n  }\n  me {\n    ...Country_Viewer_4xMPKw\n    id\n  }\n  geography(countryCode: $countryCode) {\n    country {\n      name\n      emoji\n    }\n  }\n}\n\nfragment Country_Organization on Organization {\n  id\n  name\n  ...LargeCenteredDetailsLayout_Organization\n}\n\nfragment Country_OrganizationGeography on OrganizationGeography {\n  unlockedAt\n  isTaxFavoredEquityTypeUnlocked\n  draftCount\n  activeAndTerminatedOptionGrantsCount: optionGrantsCount(ctmsGrantStatusIn: [Active, Terminated])\n  billableGranteesCount\n  geography {\n    country {\n      name\n      emoji\n    }\n    equityTypes {\n      instruments {\n        id\n        workRelationship\n      }\n      id\n      taxFavored\n      name\n    }\n  }\n}\n\nfragment Country_Viewer_4xMPKw on Account {\n  isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeCenteredDetailsLayout_Organization on Organization {\n  ...Footer_Organization\n}\n"
  }
};
})();

(node as any).hash = "667be8ac517a421a567169a711e4721a";

export default node;
