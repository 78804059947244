/**
 * @generated SignedSource<<4c880bac8d68d6b8bdea921a327d4572>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MissingGranteeInformationMessage_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee">;
  readonly " $fragmentType": "MissingGranteeInformationMessage_Grantee";
};
export type MissingGranteeInformationMessage_Grantee$key = {
  readonly " $data"?: MissingGranteeInformationMessage_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MissingGranteeInformationMessage_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MissingGranteeInformationMessage_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "329282ca5aa015a4bf051d6f43ad0178";

export default node;
