/**
 * @generated SignedSource<<59e4a9903f9164ef35a4ad3b5ca8405f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation$variables = {
  organizationId: string;
  requiresDocumentApproval: boolean;
};
export type DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation$data = {
  readonly setOrganizationRequiresDocumentApproval: {
    readonly requiresDocumentApproval: boolean;
  };
};
export type DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation = {
  response: DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation$data;
  variables: DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requiresDocumentApproval"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "requiresDocumentApproval",
    "variableName": "requiresDocumentApproval"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresDocumentApproval",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setOrganizationRequiresDocumentApproval",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setOrganizationRequiresDocumentApproval",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "da940fd78fcd42d51367f52b445ed612",
    "id": null,
    "metadata": {},
    "name": "DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation",
    "operationKind": "mutation",
    "text": "mutation DocumentsApprovalPage_SetOrganizationRequiresDocumentApproval_Mutation(\n  $organizationId: OrganizationId!\n  $requiresDocumentApproval: Boolean!\n) {\n  setOrganizationRequiresDocumentApproval(organizationId: $organizationId, requiresDocumentApproval: $requiresDocumentApproval) {\n    requiresDocumentApproval\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b20f5e0d84e4d41a026df221dc08e31e";

export default node;
