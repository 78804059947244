import { capitalize } from "lodash";
import React, { useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";
import { useIntl } from "react-intl";

import { SelectAutocomplete } from "../ui/Form/Inputs/Select/SelectAutocomplete";

export type DurationUnit = "day" | "month" | "year";

const DurationUnitSelect_: React.FC<{
  className?: string;
  disabled?: boolean;
  invalid: boolean;
  name?: string;
  onChange: (value: DurationUnit | null) => void;
  options?: DurationUnit[];
  unitCount: number;
  usePortal?: boolean;
  value?: DurationUnit | null;
}> = ({
  className,
  disabled,
  invalid,
  name,
  onChange,
  options = ["day", "month", "year"],
  unitCount,
  usePortal,
  value = null,
}) => {
  const intl = useIntl();

  const _options = useMemo(
    () =>
      options.map((option) => ({
        value: option,
      })),
    [options],
  );

  return (
    <SelectAutocomplete
      className={className}
      disabled={disabled}
      getOptionLabel={(option) =>
        intl
          .formatNumberToParts(unitCount, {
            style: "unit",
            unit: option.value,
            unitDisplay: "long",
          })
          .filter((part) => part.type === "unit")
          .map((part) => capitalize(part.value))
          .join(" ")
      }
      getOptionValue={(option) => option.value}
      invalid={invalid}
      name={name}
      onChange={(option) => onChange(option?.value ?? null)}
      options={_options}
      usePortal={usePortal}
      value={_options.find((option) => option.value === value)}
    />
  );
};

const DurationUnitSelectForm = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: Omit<
  React.ComponentProps<typeof DurationUnitSelect_>,
  "invalid" | "onChange"
> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <DurationUnitSelect_
          disabled={field.disabled}
          invalid={fieldState.invalid}
          name={field.name}
          onChange={field.onChange}
          value={field.value}
          {...props}
        />
      )}
    />
  );
};

export const DurationUnitSelect = Object.assign(DurationUnitSelect_, {
  Form: DurationUnitSelectForm,
});
