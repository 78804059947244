/**
 * @generated SignedSource<<d915ea6266980ccb1ae4a867ad115672>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeForm_DefaultHRISProviderEmployee$data = {
  readonly id: string;
  readonly " $fragmentType": "GranteeForm_DefaultHRISProviderEmployee";
};
export type GranteeForm_DefaultHRISProviderEmployee$key = {
  readonly " $data"?: GranteeForm_DefaultHRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_DefaultHRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeForm_DefaultHRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "c7896bd77ba0cf98d0b8bd3f7a166a92";

export default node;
