/**
 * @generated SignedSource<<e4143f421fa025c6a033a0e6f210d54f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation$variables = {
  granteeId: string;
};
export type useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation$data = {
  readonly resendInvitationEmailToGrantee: {
    readonly hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal: boolean;
    readonly hasPortalAccess: boolean;
    readonly hasVisitedTheirPortal: boolean;
  };
};
export type useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation = {
  response: useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation$data;
  variables: useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeId",
    "variableName": "granteeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPortalAccess",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasVisitedTheirPortal",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "resendInvitationEmailToGrantee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "resendInvitationEmailToGrantee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed86313cc40e5c0734ddf7f1dc698b05",
    "id": null,
    "metadata": {},
    "name": "useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation",
    "operationKind": "mutation",
    "text": "mutation useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation(\n  $granteeId: GranteeId!\n) {\n  resendInvitationEmailToGrantee(granteeId: $granteeId) {\n    hasPortalAccess\n    hasVisitedTheirPortal\n    hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a28cde58b9758179b231645e162009c5";

export default node;
