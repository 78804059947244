/**
 * @generated SignedSource<<8edf5b4b10e9124767ca6423272fa67a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_Organization$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalDetails_Organization" | "ApprovalOrganizationBlock_Organization" | "ApprovalSlideOverGrantsCard_Organization" | "PoolDetails_Organization">;
  readonly " $fragmentType": "ApprovalSlideOverContent_Organization";
};
export type ApprovalSlideOverContent_Organization$key = {
  readonly " $data"?: ApprovalSlideOverContent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalOrganizationBlock_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PoolDetails_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalSlideOverGrantsCard_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalDetails_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7670d557e2d28aac68c1189febff56b3";

export default node;
