/**
 * @generated SignedSource<<27cb10d13dcd4e1f43f1cbf1dc11e299>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
export type CreateRefresherGrantPlanningEntryAttributes = {
  granteeId: string;
  sharesGranted: number;
  type: RefresherGrantPlanningEntryType;
};
export type RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation$variables = {
  attributes: CreateRefresherGrantPlanningEntryAttributes;
  planningEntryId: any;
};
export type RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation$data = {
  readonly updateRefresherGrantPlanningEntry: {
    readonly __typename: "Organization";
  };
};
export type RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation = {
  response: RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation$data;
  variables: RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planningEntryId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "planningEntryId",
    "variableName": "planningEntryId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateRefresherGrantPlanningEntry",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateRefresherGrantPlanningEntry",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ef567df9ca52f00193716f29a44eaad",
    "id": null,
    "metadata": {},
    "name": "RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation(\n  $planningEntryId: PlanningEntryId!\n  $attributes: CreateRefresherGrantPlanningEntryAttributes!\n) {\n  updateRefresherGrantPlanningEntry(planningEntryId: $planningEntryId, attributes: $attributes) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0cf06fe074151ff9354fe45dfe41a6b1";

export default node;
