/**
 * @generated SignedSource<<22ed07dce99eb2c8352c3af12b1c9fb4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PricePerShareAttributes = {
  boardDetermined: boolean;
  date: string;
  organizationId: string;
  tag?: string | null;
  value: number;
};
export type SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation$variables = {
  pricePerShareAttributes: PricePerShareAttributes;
};
export type SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation$data = {
  readonly createPricePerShareAndSetOrganizationNameToCartaIssuerName: {
    readonly name: string;
  };
};
export type SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation = {
  response: SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation$data;
  variables: SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pricePerShareAttributes"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "pricePerShareAttributes",
    "variableName": "pricePerShareAttributes"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createPricePerShareAndSetOrganizationNameToCartaIssuerName",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createPricePerShareAndSetOrganizationNameToCartaIssuerName",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6cfcb15e4fbd5e4e656e7aef2f4ef3c6",
    "id": null,
    "metadata": {},
    "name": "SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation",
    "operationKind": "mutation",
    "text": "mutation SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation(\n  $pricePerShareAttributes: PricePerShareAttributes!\n) {\n  createPricePerShareAndSetOrganizationNameToCartaIssuerName(pricePerShareAttributes: $pricePerShareAttributes) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "038cf297e67b7ed0ff4e4b2d76392c95";

export default node;
