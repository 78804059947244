import { useOrganizationCTMS_Organization$key } from "../../hooks/__generated__/useOrganizationCTMS_Organization.graphql";
import { useOrganizationCTMS } from "../../hooks/useOrganizationCTMS";
import { CartaLogo } from "./CartaLogo";

export const CTMSLogo: React.FC<
  React.ComponentProps<"svg"> & {
    organizationFragment: useOrganizationCTMS_Organization$key;
  }
> = ({ organizationFragment, ...props }) => {
  const ctmsName = useOrganizationCTMS({ organizationFragment });
  switch (ctmsName?.name) {
    case "Carta":
      return <CartaLogo {...props} />;
    case "Pulley":
      return <span className="!font-black">Pulley</span>;
    default:
      return null;
  }
};
