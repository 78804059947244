import { motion, useMotionValue, useSpring, useTransform } from "motion/react";
import { useEffect } from "react";

export function AnimatedNumber({
  value,
  initialValue = value,
}: {
  initialValue?: number;
  value: number;
}) {
  const motionValue = useMotionValue(initialValue);

  const springValue = useSpring(motionValue, {
    bounce: 0,
    duration: 300,
  });

  const animatedValue = useTransform(springValue, (latest) =>
    latest.toFixed(0),
  );

  useEffect(() => {
    motionValue.set(value);
  }, [motionValue, value]);

  return <motion.span>{animatedValue}</motion.span>;
}
