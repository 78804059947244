import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../../components/BackButton";
import { CountryFlag } from "../../../../../components/CountryFlag";
import { ExercisesParametersPerWorkRelationshipEditionBlock } from "../../../../../components/ExercisesParametersPerWorkRelationshipEditionBlock";
import { Page } from "../../../../../components/Page";
import { BreadCrumb } from "../../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../../components/ui/Layout/OneColumnLayout";
import { Typography } from "../../../../../components/ui/Typography";
import { useEquityTypeIdParam } from "../../../../../paths";
import { ExercisesTaxParametersEditionPage_EquityType$key } from "./__generated__/ExercisesTaxParametersEditionPage_EquityType.graphql";
import { ExercisesTaxParametersEditionPage_Query } from "./__generated__/ExercisesTaxParametersEditionPage_Query.graphql";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment ExercisesTaxParametersEditionPage_EquityType on EquityType {
    name
    taxResidenceCountry {
      name
      ...CountryFlag_Country
    }
    ...ExercisesParametersPerWorkRelationshipEditionBlock_EquityType
  }
`;

const ExercisesTaxParametersEditionPage_: React.FC<{
  equityTypeFragment: ExercisesTaxParametersEditionPage_EquityType$key;
}> = ({ equityTypeFragment }) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);
  const equityTypeName = `${equityType.taxResidenceCountry.name} - ${equityType.name}`;

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="../..">Legal content</BreadCrumb.Link>
          <BreadCrumb.Link to="..">Exercises Tax Parameters</BreadCrumb.Link>
          <BreadCrumb.Link to=".">{equityTypeName}</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex items-center gap-2">
            <Typography variant="Medium/Large">
              <CountryFlag countryFragment={equityType.taxResidenceCountry} />
            </Typography>
            <Typography variant="Medium/Default">{equityTypeName}</Typography>
          </div>
        </div>
        <ExercisesParametersPerWorkRelationshipEditionBlock
          equityTypeFragment={equityType}
          workRelationship="directEmployee"
        />
        <ExercisesParametersPerWorkRelationshipEditionBlock
          equityTypeFragment={equityType}
          workRelationship="eoREmployee"
        />
        <ExercisesParametersPerWorkRelationshipEditionBlock
          equityTypeFragment={equityType}
          workRelationship="contractor"
        />
      </div>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query ExercisesTaxParametersEditionPage_Query($equityTypeId: UUID!) {
    equityType(id: $equityTypeId) {
      ...ExercisesTaxParametersEditionPage_EquityType
    }
  }
`;

const ExercisesTaxParametersEditionPage: React.FC = () => {
  const equityTypeId = useEquityTypeIdParam();
  const { equityType } =
    useLazyLoadQuery<ExercisesTaxParametersEditionPage_Query>(QUERY, {
      equityTypeId,
    });

  if (!equityType) throw new Error("Equity type not found");

  return (
    <Page
      analyticsName="Super Admin - ExercisesTaxParametersEdition"
      title={`Super admin | ExercisesTaxParametersEdition`}
    >
      <ExercisesTaxParametersEditionPage_ equityTypeFragment={equityType} />
    </Page>
  );
};

export default ExercisesTaxParametersEditionPage;
