import compact from "lodash/compact";
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EsopPoolProjectionGraph_Organization$key } from "./__generated__/EsopPoolProjectionGraph_Organization.graphql";
import { Legend } from "./ui/Legend";
import { Progress } from "./ui/Progress";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EsopPoolProjectionGraph_Organization on Organization {
    stockOptionPlanName
    esopPoolShares
    poolAvailableShares
    poolGrantedSharesBreakdown {
      total
    }
    poolPendingSharesBreakdown {
      total
    }
    poolDraftedSharesBreakdown {
      total
    }
  }
`;

const EsopPoolProjectionGraphLegend: React.FC<{
  chip: React.ReactElement<typeof Legend.Chip>;
  label: string;
  shares: number;
}> = ({ chip, label, shares }) => {
  return (
    <Legend chip={chip} className="flex-1">
      <Typography as="div" variant="Medium/Extra Small">
        <FormattedNumber value={shares} />
      </Typography>
      <Typography as="div" variant="Regular/Extra Small">
        {label}
      </Typography>
    </Legend>
  );
};

export const EsopPoolProjectionGraph: React.FC<{
  currendDraftSharesQuantity?: number;
  organizationFragment: EsopPoolProjectionGraph_Organization$key;
}> = ({ currendDraftSharesQuantity = 0, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const isThereACurrentDraft = currendDraftSharesQuantity > 0;
  const availableSharesWithoutDrafted =
    organization.poolAvailableShares -
    organization.poolDraftedSharesBreakdown.total -
    currendDraftSharesQuantity;

  const progresses = compact([
    <Progress.Value
      className="bg-purple-05"
      key="granted"
      value={organization.poolGrantedSharesBreakdown.total}
    />,
    <Progress.Value
      className="bg-orange-05"
      key="pending"
      value={organization.poolPendingSharesBreakdown.total}
    />,
    <Progress.Value
      className="bg-yellow-05"
      key="draft"
      value={organization.poolDraftedSharesBreakdown.total}
    />,
    isThereACurrentDraft ? (
      <Progress.Value
        className="bg-red-05"
        key="current"
        value={currendDraftSharesQuantity}
      />
    ) : null,
    <Progress.Value
      className="bg-green-05"
      key="available"
      value={availableSharesWithoutDrafted}
    />,
  ]);

  return (
    <div className="space-y-4">
      <div className="space-y-2">
        <Typography as="div" variant="Regular/Extra Small">
          {organization.stockOptionPlanName}
        </Typography>

        {organization.esopPoolShares !== null && (
          <Progress max={organization.esopPoolShares}>{...progresses}</Progress>
        )}
      </div>

      <div className="flex gap-4">
        <EsopPoolProjectionGraphLegend
          chip={<Legend.Chip className="bg-purple-05" />}
          label="Granted"
          shares={organization.poolGrantedSharesBreakdown.total}
        />
        {organization.poolPendingSharesBreakdown.total > 0 ? (
          <EsopPoolProjectionGraphLegend
            chip={<Legend.Chip className="bg-orange-05" />}
            label="Pending"
            shares={organization.poolPendingSharesBreakdown.total}
          />
        ) : null}
        {organization.poolDraftedSharesBreakdown.total > 0 ? (
          <EsopPoolProjectionGraphLegend
            chip={<Legend.Chip className="bg-yellow-05" />}
            label="Drafted"
            shares={organization.poolDraftedSharesBreakdown.total}
          />
        ) : null}

        {isThereACurrentDraft ? (
          <EsopPoolProjectionGraphLegend
            chip={<Legend.Chip className="bg-red-05" />}
            label="This draft"
            shares={currendDraftSharesQuantity}
          />
        ) : null}
        <EsopPoolProjectionGraphLegend
          chip={<Legend.Chip className="bg-green-05" />}
          label="Available"
          shares={availableSharesWithoutDrafted}
        />
      </div>
    </div>
  );
};
