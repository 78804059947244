import { useEffect, useRef } from "react";
import React from "react";
import { useIntersection } from "react-use";

import { Button } from "./Button";

export const LoadMoreButton: React.FC<{
  className?: string;
  loading?: boolean;
  onLoadMoreRequest: () => void;
}> = ({ className, loading, onLoadMoreRequest }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    rootMargin: "50px",
  });

  useEffect(() => {
    if (intersection?.isIntersecting) {
      onLoadMoreRequest();
    }
  }, [intersection?.isIntersecting, onLoadMoreRequest]);

  return (
    <Button
      className={className}
      loading={loading}
      loadingText="Loading..."
      onClick={onLoadMoreRequest}
      ref={intersectionRef}
      size="extra small"
      type="button"
      variant="Secondary Full"
    >
      Load more...
    </Button>
  );
};
