/**
 * @generated SignedSource<<cfb4b03af1ea15a5dc4f833e57e5241e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantsListSlideOver_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "CTMSGrantsListSlideOver_Organization";
};
export type CTMSGrantsListSlideOver_Organization$key = {
  readonly " $data"?: CTMSGrantsListSlideOver_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantsListSlideOver_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CTMSGrantsListSlideOver_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c1204d64ba9f37f2a47130c9287aa13c";

export default node;
