import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { Button } from "@remote-com/norma";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { startTransition, useMemo, useTransition } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import {
  MiscellaneousLegalDocumentDeletionConfirmationModal,
  useMiscellaneousLegalDocumentDeletionConfirmationModalState,
} from "../../../../components/MiscellaneousLegalDocumentDeletionConfirmationModal";
import { Page } from "../../../../components/Page";
import { ShortDate } from "../../../../components/ShortDate";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { LinkButton } from "../../../../components/ui/Button";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { SearchBar } from "../../../../components/ui/SearchBar";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { useDebounced } from "../../../../hooks/useDebounced";
import { useQuery } from "../../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../../paths";
import {
  DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$data,
  DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$key,
} from "./__generated__/DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument.graphql";
import { DocumentsUploadOrganizationView_Query } from "./__generated__/DocumentsUploadOrganizationView_Query.graphql";
import { DocumentsUploadOrganizationView_Root_Query } from "./__generated__/DocumentsUploadOrganizationView_Root_Query.graphql";
import { UploadDocumentSlideOverRemote } from "./UploadDocumentSlideOver";

const DOCUMENTS_FRAGMENT = graphql`
  fragment DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument on OrganizationMiscellaneousLegalDocument
  @relay(plural: true) {
    displayedName
    document {
      updatedAt
      downloadUrl
    }
    ...MiscellaneousLegalDocumentDeletionConfirmationModal_OrganizationMiscellaneousLegalDocument
  }
`;

type MiscellaneousLegalDocument =
  DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$data[number];

const columnHelper = createColumnHelper<MiscellaneousLegalDocument>();

const OrganizationsTable: React.FC<{
  miscellaneousLegalDocumentsFragment: DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument$key;
  onDeletion: () => void;
}> = ({ miscellaneousLegalDocumentsFragment, onDeletion }) => {
  const miscellaneousLegalDocuments = useFragment(
    DOCUMENTS_FRAGMENT,
    miscellaneousLegalDocumentsFragment,
  );

  const {
    closeMiscellaneousLegalDocumentDeletionConfirmationModal,
    miscellaneousLegalDocumentDeletionConfirmationModalState,
    showMiscellaneousLegalDocumentDeletionConfirmationModal,
  } = useMiscellaneousLegalDocumentDeletionConfirmationModalState();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.displayedName, {
        cell: (context) => (
          <Typography variant="Medium/Extra Small">
            {context.getValue()}
          </Typography>
        ),
        enableSorting: true,
        header: () => "Documents uploaded",
        id: "document",
      }),
      columnHelper.accessor(
        (row) => new Date(row.document.updatedAt).getTime(),
        {
          cell: (context) => <ShortDate value={context.getValue()} />,
          enableSorting: true,
          header: () => "Uploaded on",
          id: "updated-date",
        },
      ),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const miscellaneousLegalDocument = context.row.original;
          return (
            <div className="flex items-center justify-end gap-2">
              <Button
                onClick={() =>
                  showMiscellaneousLegalDocumentDeletionConfirmationModal(
                    miscellaneousLegalDocument,
                  )
                }
                size="xs"
                tone="destructive"
                type="button"
                variant="outline"
              >
                Delete file
              </Button>
              <LinkButton
                leftIcon={<ArrowTopRightOnSquareIcon />}
                size="extra small"
                target="_blank"
                to={miscellaneousLegalDocument.document.downloadUrl}
                type="button"
                variant="Secondary Full"
              />
            </div>
          );
        },
        enableSorting: false,
        header: () => "",
        id: "actions",
        meta: { alignRight: true },
      }),
    ],
    [showMiscellaneousLegalDocumentDeletionConfirmationModal],
  );

  const data = useMemo(
    () => [...miscellaneousLegalDocuments],
    [miscellaneousLegalDocuments],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "organization" }],
    },
  });

  if (miscellaneousLegalDocuments.length === 0) {
    return (
      <NoticeMessage size="Small">
        No files match your current filters.
      </NoticeMessage>
    );
  }

  return (
    <div>
      <MiscellaneousLegalDocumentDeletionConfirmationModal
        onClose={closeMiscellaneousLegalDocumentDeletionConfirmationModal}
        onDeletion={onDeletion}
        state={miscellaneousLegalDocumentDeletionConfirmationModalState}
      />
      <Table.Smart table={table} />
    </div>
  );
};

const QUERY = graphql`
  query DocumentsUploadOrganizationView_Query(
    $organizationId: OrganizationId!
    $search: String
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      foundMiscellaneousLegalDocuments: miscellaneousLegalDocuments(
        search: $search
      ) {
        ...DocumentsUploadOrganizationView_OrganizationMiscellaneousLegalDocument
      }
      ...UploadDocumentSlideOver_Organization
    }
  }
`;

const DocumentsUploadOrganizationView_: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const {
    debouncedState: debouncedSearch,
    isDebouncing: searchIsDebouncing,
    liveState: liveSearch,
    setState: setSearch,
  } = useDebounced({
    initialState: "",
  });

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  function handleSearchChange(value: string) {
    startSearchTransition(() => {
      setSearch(value);
    });
  }

  const { query, refreshQuery } =
    useQuery<DocumentsUploadOrganizationView_Query>(QUERY, {
      organizationId,
      search: debouncedSearch,
    });

  const uploadDocumentSlideOverRemoteController =
    UploadDocumentSlideOverRemote.useController();

  const handleSlideOverClose = () => {
    startTransition(() => {
      refreshQuery();
    });
  };

  const handleDeletion = () => {
    startTransition(() => {
      refreshQuery();
    });
  };

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Documents upload</BreadCrumb.Link>
          <BreadCrumb.Link to=".">
            Upload legal documents to an organization
          </BreadCrumb.Link>
        </BreadCrumb>
      }
      maxWidth="800"
      showFooter
    >
      <div className="space-y-6">
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <Typography as="div" className="flex-grow" variant="Medium/Default">
              {query.organization.name}
            </Typography>
            <Button
              onClick={() =>
                uploadDocumentSlideOverRemoteController.open({
                  data: { organizationFragment: query.organization },
                  onClose: handleSlideOverClose,
                })
              }
              size="xs"
              type="button"
            >
              Upload new file
            </Button>
          </div>
          <SearchBar
            className="w-full"
            loading={searchTransitionIsInProgress || searchIsDebouncing}
            onChange={handleSearchChange}
            placeholder="Search document..."
            value={liveSearch}
          />
          <OrganizationsTable
            miscellaneousLegalDocumentsFragment={
              query.organization.foundMiscellaneousLegalDocuments
            }
            onDeletion={handleDeletion}
          />
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};

const ROOT_QUERY = graphql`
  query DocumentsUploadOrganizationView_Root_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      name
    }
  }
`;

const DocumentsUploadOrganizationView: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<DocumentsUploadOrganizationView_Root_Query>(
    ROOT_QUERY,
    {
      organizationId,
    },
  );

  return (
    <UploadDocumentSlideOverRemote.Provider>
      <Page
        analyticsName="Super Admin - Documents upload - Organization"
        title={`Super admin | Documents upload - ${query.organization.name}`}
      >
        <DocumentsUploadOrganizationView_ />
      </Page>
    </UploadDocumentSlideOverRemote.Provider>
  );
};

export default DocumentsUploadOrganizationView;
