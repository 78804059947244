import classNames from "classnames";
import React from "react";

import { Typography } from "../../../components/ui/Typography";

export const CartaSignUpButton: React.FC<React.ComponentProps<"button">> = ({
  className,
  ...props
}) => (
  <button
    className={classNames(
      "w-full rounded-lg bg-black-07 px-6 py-4 text-center text-white",
      className,
    )}
    {...props}
  >
    <Typography variant="Medium/Small">Connect your Carta account</Typography>
  </button>
);
