import { useMemo } from "react";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { RadioGroup } from "../ui/Form/RadioGroup";
import { Section } from "./Section";

export function RadioGroupSection({
  boardConsentHandledOutsideEasop,
  setBoardConsentHandledOutsideEasop,
  type,
}: {
  boardConsentHandledOutsideEasop?: boolean;
  setBoardConsentHandledOutsideEasop: (value: boolean) => void;
  type:
    | "FAIR_MARKET_VALUE"
    | "GRANT"
    | "GRANT_AMENDMENT_REQUEST"
    | "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
}) {
  const applicationName = useApplicationName();
  const sectionSubTitle = useMemo(() => {
    switch (type) {
      case "FAIR_MARKET_VALUE":
        return "A new 409A valuation requires a board signature. Please select below how you want to proceed:";
      case "GRANT":
        return "Decide how you want to approve these grants";
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return "Decide how you want to approve these grant amendments";
    }
  }, [type]);

  const boardConsentHandledInsideEasopDescription = useMemo(() => {
    switch (type) {
      case "FAIR_MARKET_VALUE":
        return `${applicationName} will generate a written board consent to approve the 409A valuation, together with all required documentation`;
      case "GRANT":
        return `${applicationName} will generate a written board consent to approve your grants, together with all required documentation`;
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return `${applicationName} will generate a written board consent to approve your grant amendments, together with all required documentation`;
    }
  }, [applicationName, type]);

  const boardConsentHandledOutsideEasopDescription = useMemo(() => {
    switch (type) {
      case "FAIR_MARKET_VALUE":
        return `Board approval takes place outside of ${applicationName}`;
      case "GRANT":
        return `Board approval takes place outside of ${applicationName} and you can export the list of grants and append it to your standard board consent or minutes of your board meeting`;
      case "GRANT_AMENDMENT_REQUEST":
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return `Board approval takes place outside of ${applicationName} and you can export the list of grant amendments and append it to your standard board consent or minutes of your board meeting`;
    }
  }, [applicationName, type]);

  return (
    <Section subtitle={sectionSubTitle} title="How would you like to proceed">
      <RadioGroup
        onChange={setBoardConsentHandledOutsideEasop}
        value={boardConsentHandledOutsideEasop}
      >
        <div className="space-y-4">
          <RadioGroup.Card
            description={boardConsentHandledInsideEasopDescription}
            tag={`Recommended by ${applicationName}`}
            value={false}
          >
            Board approval takes place on {applicationName}
          </RadioGroup.Card>
          <RadioGroup.Card
            description={boardConsentHandledOutsideEasopDescription}
            value={true}
          >
            Follow your own board approval process
          </RadioGroup.Card>
        </div>
      </RadioGroup>
    </Section>
  );
}
