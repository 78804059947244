import { Link, useSearchParams } from "react-router-dom";

import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import { NoticeMessage } from "./ui/NoticeMessage";

const ContactWrapper: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const applicationName = useApplicationName();
  const mailtoSubject = encodeURIComponent(
    `Question to ${applicationName} support`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Link
      className="cursor-pointer !font-medium text-primary"
      to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
    >
      {children}
    </Link>
  );
};

export const OAuthErrorNotice: React.FC = () => {
  const [searchParams] = useSearchParams();
  const error = searchParams.get("error");
  const applicationName = useApplicationName();

  if (!error) {
    return null;
  }

  switch (error) {
    case "INSUFFICIENT_ACCESS_RIGHTS":
      return (
        <NoticeMessage size="Small" variant="Danger">
          Your Carta account is missing access rights to some data we need.
          Please try again with an account from your organization with more
          rights (eg: legal administrator or company viewer) or{" "}
          <ContactWrapper>contact us</ContactWrapper> to get help.
        </NoticeMessage>
      );
    case "ISSUER_ALREADY_CONNECTED":
      return (
        <NoticeMessage size="Small" variant="Danger">
          This company has already been associated with another organization.
          Please <ContactWrapper>contact our support</ContactWrapper> for more
          information
        </NoticeMessage>
      );
    case "MULTIPLE_ISSUERS_SELECTED":
      return (
        <NoticeMessage size="Small" variant="Danger">
          It looks like you selected more than one organisation in Carta. Please
          start again the connection flow, and in Carta&apos;s issuer account
          list displayed, please select only the organisation you want to
          connect to
          {applicationName}.
        </NoticeMessage>
      );
    case "NO_ISSUER_SELECTED":
      return (
        <NoticeMessage size="Small" variant="Danger">
          It looks like you did not select any organisation in Carta. Please
          start again the connection flow, and in Carta&apos;s issuer account
          list displayed, please select the organisation you want to connect to
          {applicationName}.
        </NoticeMessage>
      );

    default:
      return (
        <NoticeMessage size="Small" variant="Danger">
          Something went wrong. Please try again.
          <br />
          Code: {error}
        </NoticeMessage>
      );
  }
};
