/**
 * @generated SignedSource<<e3288a6f5ddea48c280fcec5a8b23eaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationship_EquityType$data = {
  readonly awardSuperType: AwardSuperType;
  readonly fullname: string | null;
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly code: string;
    readonly emoji: string;
    readonly name: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipAssumptions_EquityType" | "EquityTypeWorkRelationshipDetailed_EquityType" | "EquityTypeWorkRelationshipGranteePortal_EquityType" | "EquityTypeWorkRelationshipOverview_EquityType">;
  readonly " $fragmentType": "EquityTypeWorkRelationship_EquityType";
};
export type EquityTypeWorkRelationship_EquityType$key = {
  readonly " $data"?: EquityTypeWorkRelationship_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationship_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationship_EquityType",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipOverview_EquityType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipDetailed_EquityType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipGranteePortal_EquityType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipAssumptions_EquityType"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "awardSuperType",
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "a2cddc5933f82a516a748811f5a09f0e";

export default node;
