/**
 * @generated SignedSource<<d6ee38d2755ef2ade0293a6f01f2856d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalHydratedContentTab_Reporting_CTMSGrant$data = ReadonlyArray<{
  readonly hydratedPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_AdminPortalContent">;
  } | null;
  readonly id: string;
  readonly label: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantRelatedHydratedContent_CTMSGrant">;
  readonly " $fragmentType": "GranteePortalHydratedContentTab_Reporting_CTMSGrant";
}>;
export type GranteePortalHydratedContentTab_Reporting_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: GranteePortalHydratedContentTab_Reporting_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalHydratedContentTab_Reporting_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteePortalHydratedContentTab_Reporting_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantRelatedHydratedContent_CTMSGrant"
    },
    {
      "alias": "hydratedPortalContent",
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "reportingGranteePortalContent",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantRelatedHydratedContent_AdminPortalContent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "ab0cf64765441d72fc3431ed47ea0a45";

export default node;
