import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { NeedsAdditionalFormalitiesWarning_Instrument$key } from "./__generated__/NeedsAdditionalFormalitiesWarning_Instrument.graphql";
import { LinkToEquityType } from "./LinkToEquityType";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const INSTRUMENT_FRAGMENT = graphql`
  fragment NeedsAdditionalFormalitiesWarning_Instrument on Instrument {
    ...LinkToEquityType_Instrument
  }
`;

export const NeedsAdditionalFormalitiesWarning: React.FC<{
  className?: string;
  instrumentFragment: NeedsAdditionalFormalitiesWarning_Instrument$key;
}> = ({ className, instrumentFragment }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  return (
    <NoticeMessage className={className} size="Small" variant="Warning">
      When granting that type of equity incentive,&nbsp;
      <Typography variant="Medium/Extra Small">
        you will have additional formalities at the time of grant or just after.
      </Typography>
      &nbsp;Please head over to our geography portal for complete
      information:&nbsp;
      <LinkToEquityType
        className="inline-block"
        instrumentFragment={instrument}
        variant="Medium/Extra Small"
      />
    </NoticeMessage>
  );
};
