import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DraftGrantVestingScheduleWarningMessage } from "../../../../../components/ DraftGrantVestingScheduleWarningMessage";
import { ShortDate } from "../../../../../components/ShortDate";
import { InformationRows } from "../../../../../components/ui/InformationRows";
import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Typography } from "../../../../../components/ui/Typography";
import { DraftGrantDetailsVestingSchedule_Account$key } from "./__generated__/DraftGrantDetailsVestingSchedule_Account.graphql";
import { DraftGrantDetailsVestingSchedule_EasopGrant$key } from "./__generated__/DraftGrantDetailsVestingSchedule_EasopGrant.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment DraftGrantDetailsVestingSchedule_Account on Account {
    isSuperAdmin
  }
`;

const EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantDetailsVestingSchedule_EasopGrant on EasopGrant {
    vestingStartDate
    vestingSchedule {
      name
    }
    ...DraftGrantVestingScheduleWarningMessage_EasopGrant
  }
`;

export const DraftGrantDetailsVestingSchedule: React.FC<{
  children: React.ReactNode;
  easopGrantFragment: DraftGrantDetailsVestingSchedule_EasopGrant$key;
  viewerFragment: DraftGrantDetailsVestingSchedule_Account$key;
}> = ({ children, easopGrantFragment, viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);

  return (
    <RoundedBox className="flex flex-col p-6" withBorder withShadow>
      <Typography variant="Medium/Small">Vesting schedule</Typography>

      <div className="py-4">{children}</div>

      <InformationRows>
        <InformationRows.Row label="Vesting schedule">
          {easopGrant.vestingSchedule.name}
        </InformationRows.Row>
        <InformationRows.Row label="Start date">
          <ShortDate value={easopGrant.vestingStartDate} />
        </InformationRows.Row>
      </InformationRows>
      {viewer.isSuperAdmin && (
        <DraftGrantVestingScheduleWarningMessage
          easopGrantFragment={easopGrant}
        />
      )}
    </RoundedBox>
  );
};
