import { BoxedIcon, Pill, Text } from "@remote-com/norma";
import { IconRocket } from "@remote-com/norma/icons/IconRocket";
import { isBefore, isSameDay } from "date-fns";
import { forwardRef, useMemo, useState } from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import {
  AwardSuperType,
  GranteePortalVestingSection_Grantee$data,
  GranteePortalVestingSection_Grantee$key,
} from "./__generated__/GranteePortalVestingSection_Grantee.graphql";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import {
  GranteeVestingGraph,
  VESTING_SCHEDULE_GRANTS_STYLE,
} from "./GranteeVestingGraph";
import { SectionHero } from "./SectionHero";
import { ShortDate } from "./ShortDate";
import { Divider } from "./ui/Divider";
import { Switch } from "./ui/Switch";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalVestingSection_Grantee on Grantee {
    totalVestedSharesBreakdown {
      total
    }
    totalGrantedSharesBreakdown {
      total
    }
    ctmsGrants(
      grantStatusIn: [Active, Terminated]
      orderBy: { field: vestingStartDate, direction: ASC }
    ) {
      matchingInstrument {
        awardSuperType
      }
      label
      vestingDataPoints {
        date
        cumulativeVested
      }
    }
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useOrganizationSharesUtil_Organization
    }
    ...GranteeVestingGraph_Grantee
  }
`;

interface NextVestingEvent {
  additionalVestedShares: number;
  ctmsGrantIndex: number;
  date: string;
}

const getNextVestingEventFromDate = ({
  grantee,
  lookingFromVestingEvent,
}: {
  grantee: GranteePortalVestingSection_Grantee$data;
  lookingFromVestingEvent: NextVestingEvent | null;
}) => {
  return grantee.ctmsGrants.reduce<NextVestingEvent | null>(
    (nextVestingEvent, ctmsGrant, ctmsGrantIndex) => {
      const ctmsGrantNextVestingEvent = ctmsGrant.vestingDataPoints.find(
        (dataPoint) => {
          if (!dataPoint.cumulativeVested) return false;
          if (
            isBefore(
              dataPoint.date,
              lookingFromVestingEvent?.date ?? new Date().toISOString(),
            )
          )
            return false;

          if (lookingFromVestingEvent) {
            if (
              lookingFromVestingEvent.ctmsGrantIndex === ctmsGrantIndex &&
              isSameDay(dataPoint.date, lookingFromVestingEvent.date)
            )
              return false;
          }

          return true;
        },
      );

      if (!ctmsGrantNextVestingEvent) return nextVestingEvent;

      if (
        nextVestingEvent &&
        !isBefore(ctmsGrantNextVestingEvent.date, nextVestingEvent.date)
      )
        return nextVestingEvent;

      const previousVestingEvent = ctmsGrant.vestingDataPoints.findLast(
        (dataPoint) =>
          dataPoint.cumulativeVested > 0 &&
          isBefore(dataPoint.date, ctmsGrantNextVestingEvent.date),
      );

      return {
        additionalVestedShares: previousVestingEvent
          ? ctmsGrantNextVestingEvent.cumulativeVested -
            previousVestingEvent.cumulativeVested
          : ctmsGrantNextVestingEvent.cumulativeVested,
        ctmsGrantIndex,
        date: ctmsGrantNextVestingEvent.date,
      };
    },
    null,
  );
};

const VestingScheduleLegend: React.FC<{
  grantee: GranteePortalVestingSection_Grantee$data;
  vestingScheduleView: "cumulative" | "split";
}> = ({ grantee, vestingScheduleView }) => {
  switch (vestingScheduleView) {
    case "cumulative":
      return <Pill tone="purple">All grants</Pill>;
    case "split":
      return (
        <>
          {grantee.ctmsGrants.map(({ label }, index) => (
            <Pill
              key={label}
              tone={
                VESTING_SCHEDULE_GRANTS_STYLE[
                  index % VESTING_SCHEDULE_GRANTS_STYLE.length
                ]?.pillTone
              }
            >
              Grant {label}
            </Pill>
          ))}
        </>
      );
  }
};

const SharesUnitLabel: React.FC<{
  awardSuperType: AwardSuperType | null;
}> = ({ awardSuperType }) => {
  switch (awardSuperType) {
    case "RSU":
      return <>shares</>;
    case "SAR":
      return <>virtual shares</>;
    default:
      return <>options</>;
  }
};

export const GranteePortalVestingSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: GranteePortalVestingSection_Grantee$key;
    id: string;
    valuationMultiple: number;
  }
>(function GranteePortalVestingSection(
  { granteeFragment, id, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const displayValuation =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const [vestingScheduleView, setVestingScheduleView] = useState<
    "cumulative" | "split"
  >("split");

  const nextVestingEvent = useMemo(
    () =>
      getNextVestingEventFromDate({
        grantee,
        lookingFromVestingEvent: null,
      }),
    [grantee],
  );

  const followingVestingEvent = useMemo(
    () =>
      nextVestingEvent
        ? getNextVestingEventFromDate({
            grantee,
            lookingFromVestingEvent: nextVestingEvent,
          })
        : null,
    [grantee, nextVestingEvent],
  );

  return (
    <SectionHero id={id} ref={ref} withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconRocket} size="sm" tone="pink" />
          <Text variant="lgMedium">Vesting your options over time</Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          The idea is to make you committed for the long run! 🤝 You will then
          progressively vest (unlock) your options, month after month (or
          quarter after quarter) as you stay at the company, until you unlock
          100% of them! You can see below how many options you have already
          vested (unlocked):
        </Text>
        {displayValuation && (
          <div className="flex flex-col gap-6 py-6 lg:flex-row lg:items-center">
            <div className="space-y-6 lg:flex-1">
              <Text variant="lgMedium">Your ownership projection</Text>
              <div className="h-[348px] w-full">
                <GranteeVestingGraph
                  granteeFragment={grantee}
                  groupBy="year"
                  rounded
                  valuationMultiple={valuationMultiple}
                  view="cumulative"
                />
              </div>
              <div className="text-center">
                <Pill tone="purple" type="subtle">
                  Projection at {valuationMultiple}x
                </Pill>
              </div>
            </div>
            <div className="flex flex-col overflow-hidden rounded-2xl border-[0.5px] border-grey-300 text-center shadow-100 lg:w-[280px] lg:border-none">
              <div className="space-y-2 rounded-t-2xl border-[8px] border-white bg-purple-100 p-8">
                <Text variant="lgMedium">
                  Cumulative net equity value vested today
                </Text>
                <Text className="text-purple-700" variant="4xlMedium">
                  <FormattedCurrency
                    maximumFractionDigits={0}
                    value={
                      sharesToValue(
                        grantee.totalVestedSharesBreakdown.total *
                          valuationMultiple,
                      ) ?? 0
                    }
                  />
                </Text>
                <Text variant="lg">
                  <FormattedNumber
                    value={grantee.totalVestedSharesBreakdown.total}
                  />{" "}
                  options
                </Text>
                <Pill
                  // @ts-expect-error: we have no other way to set the Pill background color
                  style={{ backgroundColor: "white" }}
                  tone="purple"
                  type="subtle"
                >
                  At {valuationMultiple}x valuation
                </Pill>
              </div>
              <div className="space-y-2 border-[8px] border-white bg-white p-8">
                <Text variant="lgMedium">
                  Cumulative net equity value unvested
                </Text>
                <Text className="text-purple-700" variant="4xlMedium">
                  <FormattedCurrency
                    maximumFractionDigits={0}
                    value={
                      sharesToValue(
                        (grantee.totalGrantedSharesBreakdown.total -
                          grantee.totalVestedSharesBreakdown.total) *
                          valuationMultiple,
                      ) ?? 0
                    }
                  />
                </Text>
                <Text variant="lg">
                  <FormattedNumber
                    value={
                      grantee.totalGrantedSharesBreakdown.total -
                      grantee.totalVestedSharesBreakdown.total
                    }
                  />{" "}
                  options
                </Text>
                <Pill tone="purple" type="subtle">
                  At {valuationMultiple}x valuation
                </Pill>
              </div>
            </div>
          </div>
        )}
        <Text className="text-grey-600" variant="sm">
          Your company has set a specific schedule 🗓 (called the “vesting
          schedule”) defining when you vest (unlock) your options. Usually, you
          will not vest (unlock) any of them in your first year at the company,
          to be sure you&apos;re fully onboard 🚢 before allowing you to own a
          slice of the company.
        </Text>
        <Divider />
        <div className="flex flex-col gap-6 py-6 lg:flex-row lg:items-center">
          <div className="space-y-6 lg:flex-1">
            <Text variant="lgMedium">Your vesting schedule</Text>
            <div className="flex justify-center">
              <Switch
                getOptionLabel={(option) => {
                  switch (option) {
                    case "cumulative":
                      return "Cumulative";
                    case "split":
                      return "Split view";
                  }
                }}
                getOptionValue={(option) => option}
                name="vesting-graph-view"
                onChange={setVestingScheduleView}
                options={["split", "cumulative"] as const}
                selectedOption={vestingScheduleView}
              />
            </div>

            <div className="h-[348px] w-full">
              <GranteeVestingGraph
                granteeFragment={grantee}
                groupBy="month"
                valuationMultiple={valuationMultiple}
                view={vestingScheduleView}
              />
            </div>
            <div className="flex justify-center gap-6">
              <VestingScheduleLegend
                grantee={grantee}
                vestingScheduleView={vestingScheduleView}
              />
            </div>
          </div>
          {nextVestingEvent && (
            <div className="flex w-full flex-col overflow-hidden rounded-2xl border-[0.5px] border-grey-300 text-center shadow-100 lg:w-[280px] lg:border-none">
              <div className="space-y-2 p-8">
                <Text variant="lgMedium">Next vesting event</Text>
                {displayValuation && (
                  <Text
                    className={
                      VESTING_SCHEDULE_GRANTS_STYLE[
                        nextVestingEvent.ctmsGrantIndex %
                          VESTING_SCHEDULE_GRANTS_STYLE.length
                      ]?.textColor
                    }
                    variant="4xlMedium"
                  >
                    <FormattedCurrency
                      maximumFractionDigits={0}
                      value={
                        sharesToValue(
                          nextVestingEvent.additionalVestedShares *
                            valuationMultiple,
                        ) ?? 0
                      }
                    />
                  </Text>
                )}
                <Text variant="lg">
                  <FormattedNumber
                    value={nextVestingEvent.additionalVestedShares}
                  />{" "}
                  <SharesUnitLabel
                    awardSuperType={
                      grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]
                        ?.matchingInstrument?.awardSuperType ?? null
                    }
                  />
                </Text>
                <div className="space-y-0.5">
                  <Text className="text-grey-500" variant="xs">
                    Event date:
                  </Text>
                  <Text variant="smMedium">
                    <ShortDate value={nextVestingEvent.date} />
                  </Text>
                </div>
                <Pill
                  tone={
                    VESTING_SCHEDULE_GRANTS_STYLE[
                      nextVestingEvent.ctmsGrantIndex %
                        VESTING_SCHEDULE_GRANTS_STYLE.length
                    ]?.pillTone
                  }
                >
                  Grant{" "}
                  {grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]?.label}
                </Pill>
              </div>
              {followingVestingEvent && (
                <>
                  <Divider />
                  <div className="space-y-2 p-8">
                    <Text variant="lgMedium">Following vesting event</Text>
                    {displayValuation && (
                      <Text
                        className={
                          VESTING_SCHEDULE_GRANTS_STYLE[
                            followingVestingEvent.ctmsGrantIndex %
                              VESTING_SCHEDULE_GRANTS_STYLE.length
                          ]?.textColor
                        }
                        variant="4xlMedium"
                      >
                        <FormattedCurrency
                          maximumFractionDigits={0}
                          value={
                            sharesToValue(
                              followingVestingEvent.additionalVestedShares *
                                valuationMultiple,
                            ) ?? 0
                          }
                        />
                      </Text>
                    )}
                    <Text variant="lg">
                      <FormattedNumber
                        value={followingVestingEvent.additionalVestedShares}
                      />{" "}
                      <SharesUnitLabel
                        awardSuperType={
                          grantee.ctmsGrants[nextVestingEvent.ctmsGrantIndex]
                            ?.matchingInstrument?.awardSuperType ?? null
                        }
                      />
                    </Text>
                    <div className="space-y-0.5">
                      <Text className="text-grey-500" variant="xs">
                        Event date:
                      </Text>
                      <Text variant="smMedium">
                        <ShortDate value={followingVestingEvent.date} />
                      </Text>
                    </div>
                    <Pill
                      tone={
                        VESTING_SCHEDULE_GRANTS_STYLE[
                          followingVestingEvent.ctmsGrantIndex %
                            VESTING_SCHEDULE_GRANTS_STYLE.length
                        ]?.pillTone
                      }
                    >
                      Grant{" "}
                      {
                        grantee.ctmsGrants[followingVestingEvent.ctmsGrantIndex]
                          ?.label
                      }
                    </Pill>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </SectionHero>
  );
});
