/**
 * @generated SignedSource<<4ae6ace79b4d0d61c0cfaec259457689>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteesListSlideOver_Query$variables = {
  granteesIds: ReadonlyArray<string>;
  organizationId: string;
  search?: string | null;
  skip: number;
  take: number;
};
export type GranteesListSlideOver_Query$data = {
  readonly result: {
    readonly items: ReadonlyArray<{
      readonly email: string;
      readonly id: string;
      readonly name: string;
    }>;
    readonly total: number;
  };
};
export type GranteesListSlideOver_Query = {
  response: GranteesListSlideOver_Query$data;
  variables: GranteesListSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteesIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "take"
},
v5 = [
  {
    "alias": "result",
    "args": [
      {
        "kind": "Variable",
        "name": "granteesIds",
        "variableName": "granteesIds"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "skip",
        "variableName": "skip"
      },
      {
        "kind": "Variable",
        "name": "take",
        "variableName": "take"
      }
    ],
    "concreteType": "GranteesForOrganizationFilterByIdResult",
    "kind": "LinkedField",
    "name": "granteesForOrganizationFilterById",
    "plural": false,
    "selections": [
      {
        "alias": "items",
        "args": null,
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantees",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "total",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteesListSlideOver_Query",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "GranteesListSlideOver_Query",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b546aaeb197b780ab78991c6859d76ec",
    "id": null,
    "metadata": {},
    "name": "GranteesListSlideOver_Query",
    "operationKind": "query",
    "text": "query GranteesListSlideOver_Query(\n  $organizationId: OrganizationId!\n  $granteesIds: [GranteeId!]!\n  $search: String\n  $take: Int!\n  $skip: Int!\n) {\n  result: granteesForOrganizationFilterById(organizationId: $organizationId, granteesIds: $granteesIds, search: $search, take: $take, skip: $skip) {\n    items: grantees {\n      id\n      name\n      email\n    }\n    total\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7e37d873e876f4695a4c5ca2c26a8e8";

export default node;
