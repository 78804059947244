/**
 * @generated SignedSource<<4cd8a52041b5d88eea9e0d19cb6760b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesTaxParametersPage_Query$variables = Record<PropertyKey, never>;
export type ExercisesTaxParametersPage_Query$data = {
  readonly equityTypes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ExercisesTaxParametersPage_EquityType">;
  }>;
};
export type ExercisesTaxParametersPage_Query = {
  response: ExercisesTaxParametersPage_Query$data;
  variables: ExercisesTaxParametersPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseTaxRange",
    "kind": "LinkedField",
    "name": "taxRanges",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseBenefit",
    "kind": "LinkedField",
    "name": "benefits",
    "plural": true,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "watchout",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExercisesTaxParametersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExercisesTaxParametersPage_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExercisesTaxParametersPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeExerciseParameters",
            "kind": "LinkedField",
            "name": "exerciseParameters",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "directEmployee",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "eoREmployee",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                "kind": "LinkedField",
                "name": "contractor",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3197351418ee3620ee4c8646f6cdd8f4",
    "id": null,
    "metadata": {},
    "name": "ExercisesTaxParametersPage_Query",
    "operationKind": "query",
    "text": "query ExercisesTaxParametersPage_Query {\n  equityTypes {\n    ...ExercisesTaxParametersPage_EquityType\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment EquityTypesExercisesTaxParametersTable_EquityType on EquityType {\n  id\n  name\n  taxResidenceCountry {\n    name\n    ...CountryFlag_Country\n  }\n  exerciseParameters {\n    lastUpdatedAt\n    directEmployee {\n      taxRanges {\n        __typename\n      }\n      benefits {\n        __typename\n      }\n      watchout\n    }\n    eoREmployee {\n      taxRanges {\n        __typename\n      }\n      benefits {\n        __typename\n      }\n      watchout\n    }\n    contractor {\n      taxRanges {\n        __typename\n      }\n      benefits {\n        __typename\n      }\n      watchout\n    }\n  }\n}\n\nfragment ExercisesTaxParametersPage_EquityType on EquityType {\n  ...EquityTypesExercisesTaxParametersTable_EquityType\n}\n"
  }
};
})();

(node as any).hash = "7ff79cddeab9d8122835d2e875bd27d1";

export default node;
