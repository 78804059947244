import {
  CheckIcon,
  ClockIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BookADemoSlideOver } from "../../../components/BookADemo/BookADemoSlideOver";
import { OAuthErrorNotice } from "../../../components/OAuthErrorNotice";
import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { Typography } from "../../../components/ui/Typography";
import { useTrackEvent } from "../../../hooks/useAnalytics";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../../paths";
import { useAuthentication } from "../../../services/AuthenticationProvider";
import { SignUp_generateSetupWorkspaceAuthorizationFlowURLMutation } from "./__generated__/SignUp_generateSetupWorkspaceAuthorizationFlowURLMutation.graphql";
import { BoldBlueLink } from "./BoldBlueLink";
import { CartaSignUpButton } from "./CartaSignUpButton";
import { FeatureCheck } from "./FeatureCheck";
import leftImage from "./left.png";
import { PoweredByCartaPill } from "./PoweredByCartaPill";
import rightImage from "./right.png";
import { TestimonialCard } from "./TestimonialCard";

const GENERATE_URL_MUTATION = graphql`
  mutation SignUp_generateSetupWorkspaceAuthorizationFlowURLMutation(
    $successReturnURL: String!
    $failureReturnURL: String!
    $userSource: String
  ) {
    setupWorkspaceAuthorizationFlowURL: generateSetupWorkspaceAuthorizationFlowURL(
      successReturnURL: $successReturnURL
      failureReturnURL: $failureReturnURL
      userSource: $userSource
    )
  }
`;

const SignUpPage_: React.FC = () => {
  const authentication = useAuthentication();
  const [generateSetupWorkspaceAuthorizationFlowURL] =
    useSafeMutation<SignUp_generateSetupWorkspaceAuthorizationFlowURLMutation>(
      GENERATE_URL_MUTATION,
    );

  const trackEvent = useTrackEvent();
  const [searchParams] = useSearchParams();

  const handleCartaButtonClick = async () => {
    trackEvent("CTMS Sign Up - Sign Up Started", {
      ctms: "Carta",
    });

    const successReturnURL = new URL(
      generatePath(APPLICATION_ROUTES["setupWorkspace"], {}),
      window.location.origin,
    ).toString();

    const failureReturnURL = window.location.href;

    const { setupWorkspaceAuthorizationFlowURL } =
      await generateSetupWorkspaceAuthorizationFlowURL({
        variables: {
          failureReturnURL,
          successReturnURL,
          userSource: searchParams.get("source"),
        },
      });

    window.location.href = setupWorkspaceAuthorizationFlowURL;
  };

  if (authentication.account) {
    return <Navigate replace to={APPLICATION_ROUTES["roleSelection"]} />;
  }

  return (
    <div className="flex h-full flex-col items-center justify-between gap-6">
      <div className="pointer-events-none absolute inset-0 hidden items-center justify-between md:flex">
        <img alt="left" className="h-[550px]" src={leftImage} />
        <img alt="right" className="h-[550px]" src={rightImage} />
      </div>

      <div className="flex flex-1 flex-col items-center gap-6 md:justify-center">
        <AuthLayout.Card
          extraLarge
          logo={null}
          title={
            <div className="flex flex-col items-center gap-6">
              <div className="text-center">
                Award and manage equity incentives globally
              </div>
              <PoweredByCartaPill />
            </div>
          }
        >
          <div className="space-y-6">
            <div className="flex flex-wrap items-center justify-center gap-6">
              <FeatureCheck icon={CheckIcon}>
                No credit card required.
              </FeatureCheck>
              <FeatureCheck icon={ShieldCheckIcon}>
                Secure connection.
              </FeatureCheck>
              <FeatureCheck icon={ClockIcon}>Setup in 30 seconds.</FeatureCheck>
            </div>
            <OAuthErrorNotice />
            <div className="space-y-2">
              <CartaSignUpButton
                className="block w-full"
                onClick={handleCartaButtonClick}
              />
              <Typography
                as="div"
                className="text-center text-black-05"
                variant="Regular/Caption"
              >
                Or
              </Typography>
              <BookADemoSlideOver
                renderButton={({ openSlideOver }) => (
                  <Button
                    fullWidth
                    onClick={openSlideOver}
                    size="medium"
                    variant="Secondary Outline"
                  >
                    Book a demo
                  </Button>
                )}
              />
            </div>
            <Typography
              as="div"
              className="text-center text-black-05"
              variant="Regular/Caption"
            >
              By creating your account, you agree to Easop’s{" "}
              <BoldBlueLink to="https://www.easop.com/privacy/terms-of-use">
                Terms of Use
              </BoldBlueLink>{" "}
              and{" "}
              <BoldBlueLink to="https://www.easop.com/privacy/privacy-policy">
                Privacy Policy
              </BoldBlueLink>
            </Typography>
          </div>
        </AuthLayout.Card>
      </div>
      <TestimonialCard />
    </div>
  );
};

const SignUpPage: React.FC = () => {
  return (
    <Page
      analyticsCategory="Sign Up"
      analyticsName="Sign Up - Home"
      title="Sign Up"
    >
      <SignUpPage_ />
    </Page>
  );
};

export default SignUpPage;
