import { useMemo, useState } from "react";
import { useSubscription } from "react-relay";
import {
  GraphQLSubscriptionConfig,
  GraphQLTaggedNode,
  OperationType,
} from "relay-runtime";

export const useSubscriptionLatestResponse = <
  TSubscriptionPayload extends OperationType,
>({
  subscription,
  variables,
}: {
  subscription: GraphQLTaggedNode;
  variables: TSubscriptionPayload["variables"];
}) => {
  const [state, setState] = useState<
    | { response: TSubscriptionPayload["response"]; state: "loaded" }
    | { state: "loading" }
  >({ state: "loading" });

  const config: GraphQLSubscriptionConfig<TSubscriptionPayload> = useMemo(
    () => ({
      onNext: (response) => {
        setState({ response, state: "loaded" });
      },
      subscription,
      variables,
    }),
    [subscription, variables],
  );

  useSubscription<TSubscriptionPayload>(config);

  return state;
};
