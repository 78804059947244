/**
 * @generated SignedSource<<020947e2a29ad13de0372625e0fe827b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardConsentsPage_Query$variables = {
  organizationId: string;
};
export type BoardConsentsPage_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"BoardConsentsPage_Organization">;
  } | null;
};
export type BoardConsentsPage_Query = {
  response: BoardConsentsPage_Query$data;
  variables: BoardConsentsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardConsentsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoardConsentsPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardConsentsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "boardConsents",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "signedDocument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "downloadUrl",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grantCount",
                    "storageKey": null
                  }
                ],
                "type": "GrantBoardConsent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "granteeTerminationCTMSGrantAmendmentRequestCount",
                    "storageKey": null
                  }
                ],
                "type": "GranteeTerminationGrantAmendmentBoardConsent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ctmsGrantAmendmentRequestsCount",
                    "storageKey": null
                  }
                ],
                "type": "GrantAmendmentBoardConsent",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FairMarketValue",
                    "kind": "LinkedField",
                    "name": "fairMarketValue",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "FairMarketValueBoardConsent",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2818869af265969f32cd649b88f66c6",
    "id": null,
    "metadata": {},
    "name": "BoardConsentsPage_Query",
    "operationKind": "query",
    "text": "query BoardConsentsPage_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...BoardConsentsPage_Organization\n  }\n}\n\nfragment BoardConsentsDocumentsTable_Organization on Organization {\n  boardConsents {\n    __typename\n    ... on GrantBoardConsent {\n      grantCount\n    }\n    ... on GranteeTerminationGrantAmendmentBoardConsent {\n      granteeTerminationCTMSGrantAmendmentRequestCount\n    }\n    ... on GrantAmendmentBoardConsent {\n      ctmsGrantAmendmentRequestsCount\n    }\n    ... on FairMarketValueBoardConsent {\n      fairMarketValue {\n        value\n        id\n      }\n    }\n    completedAt\n    signedDocument {\n      downloadUrl\n      id\n    }\n    id\n  }\n}\n\nfragment BoardConsentsPage_Organization on Organization {\n  id\n  name\n  boardConsents {\n    __typename\n    completedAt\n    signedDocument {\n      downloadUrl\n      id\n    }\n    id\n  }\n  ...BoardConsentsDocumentsTable_Organization\n}\n"
  }
};
})();

(node as any).hash = "7173af2aef50276a427922f263fbdf74";

export default node;
