/**
 * @generated SignedSource<<dac8a956a1be70625dea6bae97f03b9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantOwnershipRow_Grantee$data = {
  readonly managementCompanyName: string | null;
  readonly name: string;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentType": "GrantOwnershipRow_Grantee";
};
export type GrantOwnershipRow_Grantee$key = {
  readonly " $data"?: GrantOwnershipRow_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantOwnershipRow_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantOwnershipRow_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCompanyName",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "c35ccbeebbdf2a99742ed5239b39b649";

export default node;
