/**
 * @generated SignedSource<<c05841676ff5df10fd7890905fe376ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment$data = {
  readonly __typename: string;
  readonly displayName?: string;
  readonly easopGrantUsageCount?: number;
  readonly id: string;
  readonly isDeletable?: boolean;
  readonly isEditable?: boolean;
  readonly " $fragmentType": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment";
};
export type PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment$key = {
  readonly " $data"?: PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment">;
};

import PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery_graphql from './PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "easopGrantUsageCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isEditable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeletable",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "OrganizationFixedPostTerminationExercisePeriod",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v0/*: any*/),
      "type": "OrganizationVariablePostTerminationExercisePeriod",
      "abstractKey": null
    }
  ],
  "type": "OrganizationPostTerminationExercisePeriod",
  "abstractKey": "__isOrganizationPostTerminationExercisePeriod"
};
})();

(node as any).hash = "30b5bf5c3545e1059bef0925eae96632";

export default node;
