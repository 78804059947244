import React, { useState } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { SelectedItemsActionCard } from "../../../../components/SelectedItemsActionCard";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { RepricingPage_Organization$key } from "./__generated__/RepricingPage_Organization.graphql";
import { RepricingPage_Query } from "./__generated__/RepricingPage_Query.graphql";
import { RepricingForm, RepricingSuggestion } from "./RepricingForm";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RepricingPage_Organization on Organization @relay(plural: true) {
    ...RepricingForm_Organization
  }
`;

const RepricingPage_: React.FC<{
  organizationsFragment: RepricingPage_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const [selectedSuggestions, setSelectedSuggestions] = useState<
    Set<RepricingSuggestion>
  >(new Set<RepricingSuggestion>());

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Change grant instrument</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <Typography as="div" className="flex-grow" variant="Medium/Default">
            Repricing
          </Typography>
          <SelectedItemsActionCard
            actions={
              <Button
                disabled={selectedSuggestions.size === 0}
                form="repricing-form"
                size="small"
                type="submit"
              >
                Reprice grants
              </Button>
            }
            emptyListLabel="Select grants"
            itemCount={selectedSuggestions.size}
            pluralLabel="grants selected"
            singularLabel="grant selected"
          />
        </div>
        <RepricingForm
          organizationsFragment={organizations}
          selectedSuggestions={selectedSuggestions}
          setSelectedSuggestions={setSelectedSuggestions}
        />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query RepricingPage_Query {
    organizations {
      ...RepricingPage_Organization
    }
  }
`;

const RepricingPage: React.FC = () => {
  const { organizations } = useLazyLoadQuery<RepricingPage_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Repricing"
      title={`Super admin | Repricing`}
    >
      <RepricingPage_ organizationsFragment={organizations} />
    </Page>
  );
};

export default RepricingPage;
