import classNames from "classnames";
import React from "react";

import { RemoteLikeApplicationLayout } from "./RemoteLikeApplicationLayout";

export const RemoteEquityOnboardingLayout: React.FC<{
  children: React.ReactNode;
  className?: string;
  subtitle: React.ReactNode;
  title: React.ReactNode;
}> = ({ children, className, subtitle, title }) => {
  return (
    <RemoteLikeApplicationLayout>
      <div
        className={classNames(
          "mx-auto flex flex-col items-center gap-16 py-14",
          className,
        )}
      >
        <div className="flex flex-col items-center gap-3 text-center">
          <div className="w-full justify-items-center font-remote-brand text-remote-4XL/Medium">
            {title}
          </div>
          <div className="w-full justify-items-center text-remote-Base text-remote-grey-700">
            {subtitle}
          </div>
        </div>
        {children}
      </div>
    </RemoteLikeApplicationLayout>
  );
};
