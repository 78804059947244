/**
 * @generated SignedSource<<a260aaf66db9451d0997e642dfad97c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DownloadUnderwaterGrantsCSV_CTMSGrant$data = ReadonlyArray<{
  readonly equityTypeAwardName: string | null;
  readonly exercisePrice: number | null;
  readonly formattedGranteeName: string;
  readonly grantDate: string;
  readonly label: string;
  readonly quantityIssued: number;
  readonly " $fragmentType": "DownloadUnderwaterGrantsCSV_CTMSGrant";
}>;
export type DownloadUnderwaterGrantsCSV_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: DownloadUnderwaterGrantsCSV_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DownloadUnderwaterGrantsCSV_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DownloadUnderwaterGrantsCSV_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedGranteeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityIssued",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "equityTypeAwardName",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "bb1a985ddac622bbda1ebe2e57ac5398";

export default node;
