/**
 * @generated SignedSource<<4061e7b9c1ec3b060e6a1c485ce1d92d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminationPlanningEntryModal_TerminationPlanningEntry$data = {
  readonly grantee: {
    readonly id: string;
  };
  readonly id: string;
  readonly terminationDate: string;
  readonly vestingEndDate: string | null;
  readonly waiveCliff: boolean;
  readonly " $fragmentType": "TerminationPlanningEntryModal_TerminationPlanningEntry";
};
export type TerminationPlanningEntryModal_TerminationPlanningEntry$key = {
  readonly " $data"?: TerminationPlanningEntryModal_TerminationPlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminationPlanningEntryModal_TerminationPlanningEntry">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminationPlanningEntryModal_TerminationPlanningEntry",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waiveCliff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "TerminationPlanningEntry",
  "abstractKey": null
};
})();

(node as any).hash = "83e77e5317f78bdb8ce3074c16cb17d7";

export default node;
