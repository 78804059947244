export type AccelerationClause =
  | "DoubleTrigger25"
  | "DoubleTrigger35"
  | "DoubleTrigger50"
  | "DoubleTrigger100"
  | "SingleTrigger";

export const ACCELERATION_CLAUSE_LABELS: Record<AccelerationClause, string> = {
  DoubleTrigger25: "Double Trigger (25%)",
  DoubleTrigger35: "Double Trigger (35%)",
  DoubleTrigger50: "Double Trigger (50%)",
  DoubleTrigger100: "Double Trigger (100%)",
  SingleTrigger: "Single Trigger",
};

export const AccelerationClauseOptions = [
  { label: "Single Trigger", value: "SingleTrigger" },
  { label: "Double Trigger (100%)", value: "DoubleTrigger100" },
  { label: "Double Trigger (50%)", value: "DoubleTrigger50" },
  { label: "Double Trigger (35%)", value: "DoubleTrigger35" },
  { label: "Double Trigger (25%)", value: "DoubleTrigger25" },
] as const;
