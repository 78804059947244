/**
 * @generated SignedSource<<da7c757e057a77f853aae449c4138d2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantType = "real" | "virtual";
export type Exercise_Grantee_Query$variables = {
  grantType: GrantType;
  granteeId: string;
};
export type Exercise_Grantee_Query$data = {
  readonly grantee: {
    readonly ctmsGrants: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"Exercise_Grantee_CTMSGrant">;
    }>;
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"Exercise_Grantee_Organization">;
    };
  } | null;
};
export type Exercise_Grantee_Query = {
  response: Exercise_Grantee_Query$data;
  variables: Exercise_Grantee_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grantType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "grantType",
    "variableName": "grantType"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Exercise_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Exercise_Grantee_Organization"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Exercise_Grantee_CTMSGrant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Exercise_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToCarta",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToPulley",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToAngelList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legacyCartaIssuerId",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "earlyExercise",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exerciseStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityExercised",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cumulativeVested",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityIssued",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exercisePrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "terminationDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exercisableUntilDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exercisableNonExercisedAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVirtual",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ActivePostTerminationExercisePeriod",
                "kind": "LinkedField",
                "name": "activePostTerminationExercisePeriod",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PostTerminationExercisePeriod",
                    "kind": "LinkedField",
                    "name": "value",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unit",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isExtended",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Instrument",
                "kind": "LinkedField",
                "name": "matchingInstrument",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "529a894e945d2547ea0d4d8d43a2e223",
    "id": null,
    "metadata": {},
    "name": "Exercise_Grantee_Query",
    "operationKind": "query",
    "text": "query Exercise_Grantee_Query(\n  $granteeId: GranteeId!\n  $grantType: GrantType!\n) {\n  grantee(id: $granteeId) {\n    organization {\n      id\n      name\n      ...Exercise_Grantee_Organization\n    }\n    ctmsGrants(grantType: $grantType) {\n      ...Exercise_Grantee_CTMSGrant\n      id\n    }\n    id\n  }\n}\n\nfragment CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod on ActivePostTerminationExercisePeriod {\n  value {\n    duration\n    unit\n  }\n  isExtended\n}\n\nfragment ExerciseBox_CTMSGrant on CTMSGrant {\n  label\n  grantStatus\n  exerciseStatus\n  quantityExercised\n  cumulativeVested\n  quantityIssued\n  earlyExercise\n  exercisePrice\n  terminationDate\n  exercisableUntilDate\n  settled\n  exercisableNonExercisedAmount\n  grantDate\n  isVirtual\n  activePostTerminationExercisePeriod {\n    ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod\n  }\n  matchingInstrument {\n    name\n    id\n  }\n}\n\nfragment Exercise_Grantee_CTMSGrant on CTMSGrant {\n  id\n  earlyExercise\n  ...ExerciseBox_CTMSGrant\n}\n\nfragment Exercise_Grantee_Organization on Organization {\n  ...useOrganizationCTMS_Organization\n}\n\nfragment useOrganizationCTMS_Organization on Organization {\n  isConnectedToCarta\n  isConnectedToPulley\n  isConnectedToAngelList\n  legacyCartaIssuerId\n}\n"
  }
};
})();

(node as any).hash = "ee50c52d0a46da87e61f97dd21978c31";

export default node;
