/**
 * @generated SignedSource<<df8b1d9251babf353ec1b4957689249d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationReview_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly activePostTerminationExercisePeriod: {
      readonly value: {
        readonly duration: number;
        readonly unit: DurationUnit;
      };
    } | null;
    readonly equityTypeAwardName: string | null;
    readonly id: string;
    readonly label: string;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "GranteeTerminationReview_Grantee";
};
export type GranteeTerminationReview_Grantee$key = {
  readonly " $data"?: GranteeTerminationReview_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationReview_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationReview_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "grantStatusIn",
          "value": [
            "Active"
          ]
        }
      ],
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ActivePostTerminationExercisePeriod",
          "kind": "LinkedField",
          "name": "activePostTerminationExercisePeriod",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PostTerminationExercisePeriod",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "duration",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unit",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equityTypeAwardName",
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "8651a77ac982460a4b5d0344527f68ab";

export default node;
