/**
 * @generated SignedSource<<671ac4ec889539dd9aa0f956c90d2f60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetVestingEligibilityThresholdModal_Organization$data = {
  readonly id: string;
  readonly tenureGrantsEligibilityVestedRatioThreshold: number | null;
  readonly " $fragmentType": "SetVestingEligibilityThresholdModal_Organization";
};
export type SetVestingEligibilityThresholdModal_Organization$key = {
  readonly " $data"?: SetVestingEligibilityThresholdModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SetVestingEligibilityThresholdModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetVestingEligibilityThresholdModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenureGrantsEligibilityVestedRatioThreshold",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c06f30c9ffc25bf78935488d3e6ad6c4";

export default node;
