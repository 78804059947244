import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { SharesValue, SharesValueMode } from "../SharesValue";
import { ShortDate } from "../ShortDate";
import { Divider } from "../ui/Divider";
import { LabeledValueRoundedList } from "../ui/LabeledValueRoundedList";
import { TerminationPlanningEntryDetails_Organization$key } from "./__generated__/TerminationPlanningEntryDetails_Organization.graphql";
import { TerminationPlanningEntryDetails_TerminationPlanningEntry$key } from "./__generated__/TerminationPlanningEntryDetails_TerminationPlanningEntry.graphql";

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment TerminationPlanningEntryDetails_TerminationPlanningEntry on TerminationPlanningEntry {
    terminationDate
    waiveCliff
    vestingEndDate
    sharesReturning
    grantee {
      name
      jobTitle
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment TerminationPlanningEntryDetails_Organization on Organization {
    ...SharesValue_Organization
  }
`;

export const TerminationPlanningEntryDetails: React.FC<{
  equityMode: SharesValueMode;
  organizationFragment: TerminationPlanningEntryDetails_Organization$key;
  planningEntryFragment: TerminationPlanningEntryDetails_TerminationPlanningEntry$key;
}> = ({ equityMode, organizationFragment, planningEntryFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntry = useFragment(
    PLANNING_ENTRY_FRAGMENT,
    planningEntryFragment,
  );

  return (
    <LabeledValueRoundedList>
      <LabeledValueRoundedList.LabeledValue label="Grantee">
        {planningEntry.grantee.name}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Job title">
        {planningEntry.grantee.jobTitle ?? "-"}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Termination date">
        <ShortDate value={planningEntry.terminationDate} />
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Cliff ends on">
        NOT IMPLEMENTED
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Cliff setting">
        {planningEntry.waiveCliff ? "Waived" : "Not waived"}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      <LabeledValueRoundedList.LabeledValue label="Vesting acceleration">
        {planningEntry.vestingEndDate ? "Yes" : "No"}
      </LabeledValueRoundedList.LabeledValue>
      <Divider />
      {planningEntry.vestingEndDate && (
        <>
          <LabeledValueRoundedList.LabeledValue label="Accelerate until">
            <ShortDate value={planningEntry.vestingEndDate} />
          </LabeledValueRoundedList.LabeledValue>
          <Divider />
        </>
      )}
      <LabeledValueRoundedList.LabeledValue label="Amount of shares returning to pool">
        <SharesValue
          appendSharesLabel
          mode={equityMode}
          organizationFragment={organization}
          shares={planningEntry.sharesReturning}
          variant="Medium/Extra Small"
        />
      </LabeledValueRoundedList.LabeledValue>
    </LabeledValueRoundedList>
  );
};
