import classNames from "classnames";
import { useMemo } from "react";
import {
  Cell,
  Pie,
  PieChart as RechartPieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import { isNonEmptyArray } from "../helpers/ts-utlity";

export function PieChart<
  TPieElementKey extends string,
  TPieElement extends {
    className: string;
    key: TPieElementKey;
    value: number;
  },
>({
  elements,
  height,
  innerRadius = 50,
  loading,
  outerRadius = 80,
  renderTooltip,
}: {
  elements: Array<TPieElement>;
  height: number;
  innerRadius?: number;
  loading?: boolean;
  outerRadius?: number;
  renderTooltip?: (element: TPieElement) => React.ReactNode;
}) {
  const hasNonEmptyElement = useMemo(
    () => elements.some((element) => element.value > 0),
    [elements],
  );
  if (!hasNonEmptyElement) {
    return (
      <svg style={{ height, width: height }}>
        <circle
          className="stroke-gray-02"
          cx={height / 2}
          cy={height / 2}
          fill="none"
          r={(outerRadius + innerRadius) / 2}
          strokeWidth={outerRadius - innerRadius}
        />
      </svg>
    );
  }

  return (
    <ResponsiveContainer
      className={classNames("transition-all", {
        "animate-pulse": loading,
      })}
      height={height}
      width="100%"
    >
      <RechartPieChart>
        <Tooltip
          content={({ active, payload }) => {
            if (!active || !payload || !isNonEmptyArray(payload)) return null;

            const { payload: hoveredElement } = payload[0].payload as {
              payload: TPieElement;
            };

            return renderTooltip?.(hoveredElement);
          }}
          cursor={false}
          trigger="hover"
        />
        <Pie
          data={elements}
          dataKey="value"
          endAngle={-360}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
        >
          {elements.map((element) => (
            <Cell
              className={element.className}
              key={element.key}
              style={{ outline: "none" }}
            />
          ))}
        </Pie>
      </RechartPieChart>
    </ResponsiveContainer>
  );
}
