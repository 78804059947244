import { useFragment } from "react-relay";
import { createSearchParams, generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES, GrantsTableSearchParams } from "../paths";
import { GrantsInTheirLastExerciseDaysWarningMessage_Organization$key } from "./__generated__/GrantsInTheirLastExerciseDaysWarningMessage_Organization.graphql";
import { LinkButton } from "./ui/Button";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantsInTheirLastExerciseDaysWarningMessage_Organization on Organization {
    id
  }
`;

export const GrantsInTheirLastExerciseDaysWarningMessage: React.FC<{
  organizationFragment: GrantsInTheirLastExerciseDaysWarningMessage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const grantsTableSearchParams: GrantsTableSearchParams = {
    orderBy: "remainingDaysToExercise",
    orderDirection: "ASC",
  };

  return (
    <NoticeMessage hasColor={false} size="Large" variant="Warning">
      <div className="space-y-4">
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Terminated option grants will reach the end of their post-termination
          exercise periods in{" "}
          <strong className="text-orange">45 days or less</strong>.
        </Typography>
        <LinkButton
          size="small"
          to={{
            pathname: generatePath(
              APPLICATION_ROUTES["organizationEquityGrantsTerminated"],
              {
                organizationId: organization.id,
              },
            ),
            search: createSearchParams(grantsTableSearchParams).toString(),
          }}
          variant="Secondary Outline"
        >
          View option grants
        </LinkButton>
      </div>
    </NoticeMessage>
  );
};
