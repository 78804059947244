import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Transition } from "react-transition-group";

import { Divider } from "./Divider";
import { Picture } from "./Picture";
import { Typography } from "./Typography";

const Header: React.FC<
  React.PropsWithChildren<{
    borderless?: boolean;
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    onClose: () => void;
    padding?: 6 | 10;
    subtitle?: React.ReactNode;
  }>
> = ({ borderless, children, icon, onClose, padding = 6, subtitle }) => {
  return (
    <div
      className={classNames("rounded-tl-lg bg-white py-6", {
        "border-b-[0.5px] border-gray-04": !borderless,
        "px-6": padding === 6,
        "px-10": padding === 10,
      })}
    >
      <div className="flex items-center gap-4">
        {icon && (
          <div className="flex items-center justify-center rounded-full bg-primary-01 p-1">
            {React.cloneElement(icon, {
              ...icon.props,
              className: classNames(
                icon.props.className,
                /* tailwind */ `h-6 w-6 text-primary`,
              ),
            })}
          </div>
        )}

        <div className="flex-1 space-y-1">
          <div className="flex gap-4">
            <Dialog.Title as="h6">
              <Typography
                as="span"
                className="text-black-07"
                variant="Medium/Default"
              >
                {children}
              </Typography>
            </Dialog.Title>
          </div>
          {subtitle && (
            <Typography
              className="block text-black-05"
              variant="Regular/Extra Small"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        <div className="flex h-7 items-center">
          <button
            className="text-black-07 hover:text-black-09"
            onClick={onClose}
            type="button"
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
      </div>
    </div>
  );
};

const AlternativeHeader: React.FC<
  React.PropsWithChildren<{
    onClose: () => void;
    subtitle?: React.ReactNode;
  }>
> = ({ children, onClose, subtitle }) => {
  return (
    <>
      <div className="relative space-y-1 rounded-tl-lg bg-gray-01 py-6 pl-10 pr-6">
        <button
          className="absolute -left-13 top-4 flex h-9 w-9 items-center justify-center rounded-full bg-gray-04"
          onClick={onClose}
          type="button"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        <Dialog.Title>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            {children}
          </Typography>
          <Typography as="div" variant="Medium/Default">
            {subtitle}
          </Typography>
        </Dialog.Title>
      </div>
      <Divider />
    </>
  );
};

const NotionHeader: React.FC<
  React.PropsWithChildren<{
    backgroundImage?: string;
    emoji?: React.ReactNode;
    onClose: () => void;
    subtitle?: React.ReactNode;
  }>
> = ({ backgroundImage, children, emoji, onClose, subtitle }) => {
  return (
    <>
      <div className="relative flex h-36 gap-6 rounded-tl-lg bg-gray-01 px-6">
        {backgroundImage && (
          <div className="absolute inset-0 flex h-full justify-end overflow-hidden">
            <Picture className="w-1/3 object-cover" image={backgroundImage} />
          </div>
        )}
        <button
          className="absolute -left-13 top-4 flex h-9 w-9 items-center justify-center rounded-full bg-gray-07"
          onClick={onClose}
          type="button"
        >
          <XMarkIcon className="h-5 w-5 text-white" />
        </button>
        {emoji && (
          <div className="mt-4 flex h-42 w-48 items-center justify-center rounded-lg border-[0.5px] border-gray-04 bg-white font-emoji text-[70px] leading-7">
            {emoji}
          </div>
        )}
        <Dialog.Title as="div" className="flex flex-col justify-center">
          <Typography
            as="div"
            className="uppercase text-black-05"
            variant="Regular/Caption"
          >
            {children}
          </Typography>
          <Typography as="div" variant="Medium/Default">
            {subtitle}
          </Typography>
        </Dialog.Title>
      </div>
      <Divider />
    </>
  );
};

const WIDTHS = {
  "2xl": classNames("max-w-2xl"),
  "4xl": classNames("max-w-4xl"),
  "600": classNames("max-w-[600px]"),
  sm: classNames("max-w-[401px]"),
};

const SlideOver_: React.FC<{
  children?: React.ReactNode;
  floating?: boolean;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  onClose: () => void;
  onExited?: () => void;
  show: boolean;
  width?: keyof typeof WIDTHS;
}> = ({
  children,
  floating,
  footer,
  header,
  onClose,
  onExited,
  show,
  width = "2xl",
}) => {
  const dialogRef = React.useRef<HTMLDivElement>(null);
  return (
    <Transition
      appear
      in={show}
      mountOnEnter
      nodeRef={dialogRef}
      onExited={onExited}
      timeout={300}
      unmountOnExit
    >
      {(transitionState) => (
        <Dialog
          as="div"
          className={classNames("fixed inset-0 z-10 h-full overflow-hidden")}
          onClose={onClose}
          open
          ref={dialogRef}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={classNames(
                "absolute inset-0 bg-black-09/40 transition",
                {
                  "duration-250 opacity-0 ease-in-out":
                    transitionState === "exiting",
                  "opacity-0": transitionState === "exited",
                  "opacity-100 duration-300 ease-in-out":
                    transitionState === "entering",
                },
              )}
            />
            <DialogPanel
              className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-0 sm:pl-10 md:pl-16"
              data-cy="SlideOver"
            >
              <div
                className={classNames(
                  "pointer-events-auto flex w-screen transform flex-col shadow-xl transition",
                  WIDTHS[width],
                  {
                    "duration-250 translate-x-full ease-in-out":
                      transitionState === "exiting",
                    "py-4 pr-4": floating,
                    "translate-x-0 duration-300 ease-in-out":
                      transitionState === "entering",
                    "translate-x-full": transitionState === "exited",
                  },
                )}
              >
                <div
                  className={classNames(
                    "flex flex-1 flex-col overflow-hidden bg-white",
                    {
                      "rounded-lg": floating,
                    },
                  )}
                >
                  {header}
                  <div className="flex-1 overflow-y-auto">{children}</div>
                  {footer && (
                    <div className="border-t-[0.5px] border-gray-04">
                      {footer}
                    </div>
                  )}
                </div>
              </div>
            </DialogPanel>
          </div>
        </Dialog>
      )}
    </Transition>
  );
};

export const SlideOver = Object.assign(SlideOver_, {
  AlternativeHeader,
  Header,
  NotionHeader,
});
