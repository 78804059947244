/**
 * @generated SignedSource<<cae0a321d999c16b9c6d54ee901a3844>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationSideBarContent_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRoleSwitcher_Organization">;
  readonly " $fragmentType": "ApplicationSideBarContent_Organization";
};
export type ApplicationSideBarContent_Organization$key = {
  readonly " $data"?: ApplicationSideBarContent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBarContent_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationSideBarContent_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "cdb12b1fb4f41b50dfe486075ffb84d6";

export default node;
