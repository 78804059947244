/**
 * @generated SignedSource<<5eb4a54f267c30617471fcfc0ea80d91>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityGridLevels_Query$variables = {
  organizationId: string;
};
export type EquityGridLevels_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"EquityGridLevels_Organization">;
  } | null;
};
export type EquityGridLevels_Query = {
  response: EquityGridLevels_Query$data;
  variables: EquityGridLevels_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityGridLevels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityGridLevels_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityGridLevels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityGrid",
            "kind": "LinkedField",
            "name": "equityGrid",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityGridLevel",
                "kind": "LinkedField",
                "name": "levels",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EquityGridLevel",
                        "kind": "LinkedField",
                        "name": "equityGridLevel",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "offGrid",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "missingSharesToEquityGridLevel",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2b53d6a80222b8d665b6d3d78faf749",
    "id": null,
    "metadata": {},
    "name": "EquityGridLevels_Query",
    "operationKind": "query",
    "text": "query EquityGridLevels_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...EquityGridLevels_Organization\n  }\n}\n\nfragment EquityGridLevelsGraph_EquityGridLevel on EquityGridLevel {\n  id\n  name\n}\n\nfragment EquityGridLevelsGraph_Organization on Organization {\n  grantees {\n    edges {\n      node {\n        id\n        status\n        equityGridLevel {\n          id\n        }\n        offGrid\n        missingSharesToEquityGridLevel\n      }\n    }\n  }\n  ...GranteesListSlideOver_Organization\n}\n\nfragment EquityGridLevels_Organization on Organization {\n  id\n  name\n  equityGrid {\n    levels {\n      name\n      id\n      ...EquityGridLevelsGraph_EquityGridLevel\n    }\n    id\n  }\n  ...EquityGridLevelsGraph_Organization\n}\n\nfragment GranteesListSlideOver_Organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "9480a61b8680b404db2d3c35f99b779f";

export default node;
