/**
 * @generated SignedSource<<1b4066cdda9c53bb83862de8f875e70e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type FileInput_CreateDocument_Mutation$variables = {
  contentLength: number;
  contentType: string;
  originalFilename: string;
};
export type FileInput_CreateDocument_Mutation$data = {
  readonly createDocument: {
    readonly document: {
      readonly id: string;
    };
    readonly expectedContentDisposition: string;
    readonly uploadUrl: string;
  };
};
export type FileInput_CreateDocument_Mutation = {
  response: FileInput_CreateDocument_Mutation$data;
  variables: FileInput_CreateDocument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentLength"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contentType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "originalFilename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contentLength",
        "variableName": "contentLength"
      },
      {
        "kind": "Variable",
        "name": "contentType",
        "variableName": "contentType"
      },
      {
        "kind": "Variable",
        "name": "originalFilename",
        "variableName": "originalFilename"
      }
    ],
    "concreteType": "CreateDocumentResult",
    "kind": "LinkedField",
    "name": "createDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uploadUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expectedContentDisposition",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FileInput_CreateDocument_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FileInput_CreateDocument_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "74857a521f4b3ac4441daac2ae4cf753",
    "id": null,
    "metadata": {},
    "name": "FileInput_CreateDocument_Mutation",
    "operationKind": "mutation",
    "text": "mutation FileInput_CreateDocument_Mutation(\n  $contentLength: Int!\n  $contentType: String!\n  $originalFilename: String!\n) {\n  createDocument(contentLength: $contentLength, contentType: $contentType, originalFilename: $originalFilename) {\n    document {\n      id\n    }\n    uploadUrl\n    expectedContentDisposition\n  }\n}\n"
  }
};
})();

(node as any).hash = "85eace5fb66f827f5bbae4aa3170f9e5";

export default node;
