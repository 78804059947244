import { useCallback } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { PlanningEntrySuggestionsTable } from "../../../components/PlanningEntrySuggestionsTable";
import {
  RefresherGrantPlanningEntryModal,
  useRefresherGrantPlanningEntryModalState,
} from "../../../components/RefresherGrantPlanningEntryModal";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { useOrganizationIdParam } from "../../../paths";
import { Ignored_Planning_Query } from "./__generated__/Ignored_Planning_Query.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment Ignored_Planning_Organization on Organization {
    id
    name
    ignoredPlanningEntrySuggestions {
      id
      type
      ...PlanningEntrySuggestionsTable_PlanningEntrySuggestion
      ...RefresherGrantPlanningEntryModal_PlanningEntrySuggestion
    }
    ...PlanningEntrySuggestionsTable_Organization
    ...RefresherGrantPlanningEntryModal_Organization
  }
`;

const QUERY = graphql`
  query Ignored_Planning_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      ...Ignored_Planning_Organization @relay(mask: false)
    }
  }
`;

const AdminPlanningIgnoredPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<Ignored_Planning_Query>(QUERY, {
    organizationId,
  });

  const {
    closeRefresherGrantPlanningEntryModal,
    openRefresherGrantPlanningEntryModalInCreateMode,
    refresherGrantPlanningEntryModalState,
  } = useRefresherGrantPlanningEntryModalState();

  const onPlanningEntrySuggestionPlanClick = useCallback(
    (planningEntrySuggestionId: string) => {
      const planningEntrySuggestion =
        query.organization.ignoredPlanningEntrySuggestions.find(
          (planningEntrySuggestion) =>
            planningEntrySuggestion.id === planningEntrySuggestionId,
        );

      if (!planningEntrySuggestion) {
        throw new Error("Planning entry suggestion not found");
      }

      openRefresherGrantPlanningEntryModalInCreateMode({
        planningEntrySuggestionFragment: planningEntrySuggestion,
        refresherGrantPlanningEntryType: planningEntrySuggestion.type,
      });
    },
    [
      query.organization.ignoredPlanningEntrySuggestions,
      openRefresherGrantPlanningEntryModalInCreateMode,
    ],
  );

  return (
    <>
      <RefresherGrantPlanningEntryModal
        onClose={closeRefresherGrantPlanningEntryModal}
        organizationFragment={query.organization}
        state={refresherGrantPlanningEntryModalState}
      />
      <Page
        analyticsCategory="Planning"
        analyticsName="Admin - Planning - Ignored"
        organizationId={query.organization.id}
        title={`Admin | ${query.organization.name} planning | ignored`}
      >
        <RoundedBox className="overflow-hidden" withBorder>
          <PlanningEntrySuggestionsTable
            onPlanningEntrySuggestionPlanClick={
              onPlanningEntrySuggestionPlanClick
            }
            organizationFragment={query.organization}
            planningEntrySuggestionsFragment={
              query.organization.ignoredPlanningEntrySuggestions
            }
          />
        </RoundedBox>
      </Page>
    </>
  );
};

export default AdminPlanningIgnoredPage;
