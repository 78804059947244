import { useCallback, useEffect } from "react";

export function useNavatticPopup({
  title,
  url,
}: {
  title: string;
  url: string;
}) {
  useEffect(() => {
    if (window.NavatticEmbed) {
      window.NavatticEmbed.initPopup(url, { title });
    }
  }, [title, url]);

  const openPopup = useCallback(() => {
    if (window.NavatticEmbed) {
      window.NavatticEmbed.openPopup(url);
    }
  }, [url]);

  return { openPopup };
}
