import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../components/Page";
import { Divider } from "../../components/ui/Divider";
import { CenteredColumnLayout } from "../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../components/ui/RoundedBox";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { ApplicationThemeProvider } from "../../hooks/useApplicationTheme";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../hooks/useQuery";
import { useEquityOfferCandidateAccessSecretKey } from "../../paths";
import { EquityOfferCandidate } from "../Admin/EquityOffer/Candidate";
import { EquityOfferCompany } from "../Admin/EquityOffer/Company";
import { EquityOfferEquiuniversity } from "../Admin/EquityOffer/Equiuniversity";
import { EquityOfferFinalThoughts } from "../Admin/EquityOffer/FinalThoughts";
import { EquityOfferIncentive } from "../Admin/EquityOffer/Incentive";
import { EquityOfferProjection } from "../Admin/EquityOffer/Projection";
import { EquityOfferVesting } from "../Admin/EquityOffer/Vesting";
import NotFoundPage from "../NotFound/NotFound";
import { EquityOffer_EquityOffer$key } from "./__generated__/EquityOffer_EquityOffer.graphql";
import { EquityOffer_Query } from "./__generated__/EquityOffer_Query.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquityOffer_EquityOffer on CandidateAccess_EquityOffer {
    id
    displayCompanyInformation
    displayFAQ
    displayProjectionScenarios
    finalThoughts
    organization {
      id
      ...useApplicationTheme_Organization
    }
    instrument {
      isVirtual
    }
    ...Candidate_EquityOffer
    ...Company_EquityOffer
    ...Incentive_EquityOffer
    ...Vesting_EquityOffer
    ...Projection_EquityOffer
    ...FinalThoughts_EquityOffer
  }
`;

const SectionsContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <RoundedBox withBorder withShadow>
      {children}
    </RoundedBox>
  );
};

const Section: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="p-10">{children}</div>;
};

const EquityOffer_: React.FC<{
  equityOfferFragment: EquityOffer_EquityOffer$key;
}> = ({ equityOfferFragment }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);
  const displayCompanyInformation = useMemo(
    () => equityOffer.displayCompanyInformation,
    [equityOffer.displayCompanyInformation],
  );
  const displayFAQ = useMemo(
    () => equityOffer.displayFAQ,
    [equityOffer.displayFAQ],
  );
  const displayProjectionScenarios = useMemo(
    () => equityOffer.displayProjectionScenarios,
    [equityOffer.displayProjectionScenarios],
  );

  return (
    <div className="h-full overflow-auto bg-white p-6">
      <CenteredColumnLayout>
        <div className="space-y-6">
          <SectionsContainer>
            <Section>
              <EquityOfferCandidate equityOfferFragment={equityOffer} />
            </Section>
            <Divider />
            {displayCompanyInformation && (
              <>
                <Section>
                  <EquityOfferCompany
                    equityOfferFragment={equityOffer}
                    isVirtual={equityOffer.instrument?.isVirtual}
                  />
                </Section>
                <Divider />
              </>
            )}
            <Section>
              <EquityOfferIncentive equityOfferFragment={equityOffer} />
            </Section>
            <Divider />
            <Section>
              <EquityOfferVesting equityOfferFragment={equityOffer} />
            </Section>
            <Divider />
            {displayProjectionScenarios && (
              <>
                <Section>
                  <EquityOfferProjection equityOfferFragment={equityOffer} />
                </Section>
                <Divider />
              </>
            )}
          </SectionsContainer>

          {equityOffer.finalThoughts && (
            <SectionsContainer>
              <Section>
                <EquityOfferFinalThoughts equityOfferFragment={equityOffer} />
              </Section>
            </SectionsContainer>
          )}
          {displayFAQ && (
            <SectionsContainer>
              <Section>
                <EquityOfferEquiuniversity
                  isVirtual={equityOffer.instrument?.isVirtual}
                />
              </Section>
            </SectionsContainer>
          )}
        </div>
      </CenteredColumnLayout>
    </div>
  );
};

const QUERY = graphql`
  query EquityOffer_Query($candidateAccessSecretKey: String!) {
    getEquityOfferUsingCandidateAccessSecretKey(
      candidateAccessSecretKey: $candidateAccessSecretKey
    ) {
      __typename
      ... on GetEquityOfferUsingCandidateAccessSecretKeySuccess {
        equityOffer {
          organization {
            id
            name
            ...useApplicationTheme_Organization
          }
          ...EquityOffer_EquityOffer
        }
      }
    }
  }
`;

const EquityOffer: React.FC = () => {
  const candidateAccessSecretKey = useEquityOfferCandidateAccessSecretKey();

  const { query } = useQuery<EquityOffer_Query>(QUERY, {
    candidateAccessSecretKey,
  });

  if (
    query.getEquityOfferUsingCandidateAccessSecretKey.__typename !==
    "GetEquityOfferUsingCandidateAccessSecretKeySuccess"
  ) {
    return <NotFoundPage />;
  }

  return (
    <ApplicationThemeProvider
      organizationFragment={
        query.getEquityOfferUsingCandidateAccessSecretKey.equityOffer
          .organization
      }
    >
      <FeatureFlagProvider
        organizationName={
          query.getEquityOfferUsingCandidateAccessSecretKey.equityOffer
            .organization.name
        }
      >
        <AnalyticsContextProvider
          organizationId={
            query.getEquityOfferUsingCandidateAccessSecretKey.equityOffer
              .organization.id
          }
        >
          <Page
            analyticsCategory="Equity offer"
            analyticsName="Equity offer"
            title="Equity offer"
          >
            <EquityOffer_
              equityOfferFragment={
                query.getEquityOfferUsingCandidateAccessSecretKey.equityOffer
              }
            />
          </Page>
        </AnalyticsContextProvider>
      </FeatureFlagProvider>
    </ApplicationThemeProvider>
  );
};

export default EquityOffer;
