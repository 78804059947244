/**
 * @generated SignedSource<<0d96c6059b1ccde792506773df8cec1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsPriceEvolution_CTMSGrant$data = {
  readonly easopGrant: {
    readonly exercisePrice: number;
  } | null;
  readonly exercisePrice: number | null;
  readonly isVirtual: boolean;
  readonly pricePerShareAtGrant: number | null;
  readonly " $fragmentSpreads": FragmentRefs<"PriceEvolutionGraphForVSOP_CTMSGrant" | "PriceEvolutionGraph_CTMSGrant">;
  readonly " $fragmentType": "CtmsGrantDetailsPriceEvolution_CTMSGrant";
};
export type CtmsGrantDetailsPriceEvolution_CTMSGrant$key = {
  readonly " $data"?: CtmsGrantDetailsPriceEvolution_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsPriceEvolution_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exercisePrice",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsPriceEvolution_CTMSGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pricePerShareAtGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceEvolutionGraph_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PriceEvolutionGraphForVSOP_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "dce30d682029d4d7eec6b48746b670c9";

export default node;
