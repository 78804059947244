/**
 * @generated SignedSource<<5b8b6e58778c341d7abe976f2c2c88b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectCompanies_Companies$data = ReadonlyArray<{
  readonly completeName: string;
  readonly id: string;
  readonly " $fragmentType": "SelectCompanies_Companies";
}>;
export type SelectCompanies_Companies$key = ReadonlyArray<{
  readonly " $data"?: SelectCompanies_Companies$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectCompanies_Companies">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SelectCompanies_Companies",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completeName",
      "storageKey": null
    }
  ],
  "type": "Company",
  "abstractKey": null
};

(node as any).hash = "f555f922b3f5e0cc3dcfca572b1fba76";

export default node;
