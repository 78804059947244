/**
 * @generated SignedSource<<a587d889bab8b28297dcfa46be7b683b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type TableCellGrantee_Grantee$data = {
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
  readonly " $fragmentType": "TableCellGrantee_Grantee";
};
export type TableCellGrantee_Grantee$key = {
  readonly " $data"?: TableCellGrantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"TableCellGrantee_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TableCellGrantee_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNameWithCountryFlag_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "1c624f0e596800dd06e75bc01dce59b6";

export default node;
