/**
 * @generated SignedSource<<1e9e7f12bf2192167367a3ad59e64111>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
export type IncentiveSettings_InstrumentsQuery$variables = {
  countryCode?: string | null;
  workRelationship?: WorkRelationship | null;
};
export type IncentiveSettings_InstrumentsQuery$data = {
  readonly instruments: ReadonlyArray<{
    readonly id: string;
    readonly isVirtual: boolean;
    readonly name: string;
  }>;
};
export type IncentiveSettings_InstrumentsQuery = {
  response: IncentiveSettings_InstrumentsQuery$data;
  variables: IncentiveSettings_InstrumentsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workRelationship"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "countryCode",
        "variableName": "countryCode"
      },
      {
        "kind": "Variable",
        "name": "workRelationship",
        "variableName": "workRelationship"
      }
    ],
    "concreteType": "Instrument",
    "kind": "LinkedField",
    "name": "instruments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isVirtual",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IncentiveSettings_InstrumentsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IncentiveSettings_InstrumentsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8b3dc1bf314ca2a028dad7b0304cee18",
    "id": null,
    "metadata": {},
    "name": "IncentiveSettings_InstrumentsQuery",
    "operationKind": "query",
    "text": "query IncentiveSettings_InstrumentsQuery(\n  $countryCode: String\n  $workRelationship: WorkRelationship\n) {\n  instruments(countryCode: $countryCode, workRelationship: $workRelationship) {\n    id\n    name\n    isVirtual\n  }\n}\n"
  }
};
})();

(node as any).hash = "e51eb855268ddea15c03b6efa1cb332f";

export default node;
