/**
 * @generated SignedSource<<88bdb2a5d72a330b294e906d4938ff3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_Account">;
  readonly " $fragmentType": "UnderReview_Viewer";
};
export type UnderReview_Viewer$key = {
  readonly " $data"?: UnderReview_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderReview_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderReview_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTable_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "22509844bdae584d80a0f4452aae3c8d";

export default node;
