/**
 * @generated SignedSource<<294c22ac4a35007fb975e768b7fe98fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargestGain_Query$variables = {
  organizationId: string;
};
export type LargestGain_Query$data = {
  readonly largestGainDataPoints: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"LargestGain_LargestGainDataPoint">;
  }>;
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"LargestGain_Organization">;
  } | null;
};
export type LargestGain_Query = {
  response: LargestGain_Query$data;
  variables: LargestGain_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LargestGain_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LargestGain_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "LargestGainDataPoint",
        "kind": "LinkedField",
        "name": "largestGainDataPoints",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LargestGain_LargestGainDataPoint"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LargestGain_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "LargestGainDataPoint",
        "kind": "LinkedField",
        "name": "largestGainDataPoints",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": true,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gainInUSD",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valueGrantedInUSD",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a6e82e65925d8e75e3118c2d4d38be5",
    "id": null,
    "metadata": {},
    "name": "LargestGain_Query",
    "operationKind": "query",
    "text": "query LargestGain_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...LargestGain_Organization\n  }\n  largestGainDataPoints(organizationId: $organizationId) {\n    ...LargestGain_LargestGainDataPoint\n  }\n}\n\nfragment GranteesListSlideOver_Organization on Organization {\n  id\n}\n\nfragment LargestGainGraph_LargestGainDataPoint on LargestGainDataPoint {\n  grantees {\n    id\n  }\n  gainInUSD\n  valueGrantedInUSD\n}\n\nfragment LargestGainGraph_Organization on Organization {\n  ...GranteesListSlideOver_Organization\n}\n\nfragment LargestGain_LargestGainDataPoint on LargestGainDataPoint {\n  ...LargestGainGraph_LargestGainDataPoint\n}\n\nfragment LargestGain_Organization on Organization {\n  id\n  ...LargestGainGraph_Organization\n}\n"
  }
};
})();

(node as any).hash = "a66281bdcdae56cba6dc0f3a1e2c5300";

export default node;
