/**
 * @generated SignedSource<<ea0b7e9d58c1454a0bb28429adc93614>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SynchronizeEmployeesDataItem_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeWithAnotherGranteeModal_Organization">;
  readonly " $fragmentType": "SynchronizeEmployeesDataItem_Organization";
};
export type SynchronizeEmployeesDataItem_Organization$key = {
  readonly " $data"?: SynchronizeEmployeesDataItem_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SynchronizeEmployeesDataItem_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SynchronizeEmployeesDataItem_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeWithAnotherGranteeModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "2285f043a7f9f02c7afa5e36051cf290";

export default node;
