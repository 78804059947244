/**
 * @generated SignedSource<<2d4c9a6d110290a393328dd404a330c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BackloadedVestingOccurrence = "Every3Months" | "EveryMonth";
export type BackloadedVestingScheduleAttributes = {
  cliffActivatedOnFirstPeriod: boolean;
  name: string;
  periods: ReadonlyArray<BackloadedVestingSchedulePeriodAttributes>;
  vestingOccurrence: BackloadedVestingOccurrence;
};
export type BackloadedVestingSchedulePeriodAttributes = {
  durationInMonths: number;
  percentageVested: number;
};
export type AddNew_BackloadedVestingSchedules_Mutation$variables = {
  attributes: BackloadedVestingScheduleAttributes;
  organizationId: string;
};
export type AddNew_BackloadedVestingSchedules_Mutation$data = {
  readonly createBackloadedVestingSchedule: {
    readonly __typename: string;
  };
};
export type AddNew_BackloadedVestingSchedules_Mutation = {
  response: AddNew_BackloadedVestingSchedules_Mutation$data;
  variables: AddNew_BackloadedVestingSchedules_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNew_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNew_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a41c55c7538a6df845a4b018ec3b4f59",
    "id": null,
    "metadata": {},
    "name": "AddNew_BackloadedVestingSchedules_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddNew_BackloadedVestingSchedules_Mutation(\n  $attributes: BackloadedVestingScheduleAttributes!\n  $organizationId: OrganizationId!\n) {\n  createBackloadedVestingSchedule(attributes: $attributes, organizationId: $organizationId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "18ebba893ba5b82860c03a89413c974e";

export default node;
