/**
 * @generated SignedSource<<d591139a7b072ef9e896f45fc6355aec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$data = {
  readonly grantDate: string;
  readonly isVirtual: boolean;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType">;
    } | null;
  } | null;
  readonly " $fragmentType": "GrantAmendmentRequestBoxPTEPSlide_CtmsGrant";
};
export type GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$key = {
  readonly " $data"?: GrantAmendmentRequestBoxPTEPSlide_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantAmendmentRequestBoxPTEPSlide_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantAmendmentRequestBoxPTEPSlide_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "b3875138d1b148fae372e5becc02f60b";

export default node;
