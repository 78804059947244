import classNames from "classnames";
import { ReactNode } from "react";

import { Footer } from "../../Footer";

export const OneColumnLayout: React.FC<{
  Breadcrumb?: ReactNode;
  children: ReactNode;
  className?: string;
  showFooter?: boolean;
}> = ({ Breadcrumb, children, className, showFooter = false }) => {
  return (
    <div className="flex flex-col gap-10 space-y-6 bg-white p-6">
      {Breadcrumb}
      <main className={classNames("max-w-[1392px]", className)}>
        {children}
      </main>
      {showFooter ? <Footer className="pb-4 pt-12" /> : null}
    </div>
  );
};
