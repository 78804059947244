import React, { useCallback, useContext, useReducer } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { WithRequired } from "../../../../helpers/ts-utlity";
import { GrantAmendmentRequests } from "../../../../services/grantAmendmentRequest";
import { GranteeTerminationContext_Grantee$key } from "./__generated__/GranteeTerminationContext_Grantee.graphql";
import { GranteeTerminationInformation } from "./GranteeTerminationInformation";

export type GranteeTerminationContextValues = Partial<GrantAmendmentRequests> &
  Partial<GranteeTerminationInformation> & {
    granteeId: string;
    granteeName: string;
  };

type ReducerAction = ReducerAmendmentAction | ReducerInformationAction;

type ReducerAmendmentAction = GrantAmendmentRequests & {
  type: "SET_AMENDMENT";
};

type ReducerInformationAction = GranteeTerminationInformation & {
  type: "SET_TERMINATION_INFORMATION";
};

const GranteeTerminationRequiredContextValuesWhenCompleted = [
  "terminationDate",
  "terminationLastDayAtTheCompany",
  "terminationType",
  "terminationPersonalEmail",
] as const;

type GranteeTerminationCompletedContextValues = WithRequired<
  GranteeTerminationContextValues,
  GranteeTerminationRequiredContextValueWhenCompleted
>;

interface GranteeTerminationContext {
  dispatchAction: React.Dispatch<ReducerAction>;
  getCompletedState: () => GranteeTerminationCompletedContextValues;
  state: GranteeTerminationContextValues;
}

type GranteeTerminationRequiredContextValueWhenCompleted =
  (typeof GranteeTerminationRequiredContextValuesWhenCompleted)[number];

const reduce: React.Reducer<GranteeTerminationContextValues, ReducerAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case "SET_AMENDMENT": {
      return {
        ...state,
        grantAmendments: action.grantAmendments,
      };
    }
    case "SET_TERMINATION_INFORMATION": {
      return {
        ...state,
        terminationDate: action.terminationDate,
        terminationLastDayAtTheCompany: action.terminationLastDayAtTheCompany,
        terminationNewRelationship: action.terminationNewRelationship,
        terminationPersonalEmail: action.terminationPersonalEmail,
        terminationType: action.terminationType,
      };
    }
  }
};

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTerminationContext_Grantee on Grantee {
    id
    name
  }
`;

export const GranteeTerminationContextProvider: React.FC<{
  children: React.ReactNode;
  granteeFragment: GranteeTerminationContext_Grantee$key;
}> = ({ children, granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const initialValue: GranteeTerminationContextValues = {
    granteeId: grantee.id,
    granteeName: grantee.name,
  };
  const [state, dispatchAction] = useReducer(reduce, initialValue);

  const getCompletedState = useCallback(() => {
    GranteeTerminationRequiredContextValuesWhenCompleted.forEach((key) => {
      if (typeof state[key] === "undefined") {
        throw new Error(`${key} not found in context`);
      }
    });

    return state as GranteeTerminationCompletedContextValues;
  }, [state]);

  return (
    <Context.Provider value={{ dispatchAction, getCompletedState, state }}>
      {children}
    </Context.Provider>
  );
};

const Context = React.createContext<GranteeTerminationContext | null>(null);

export const useGranteeTerminationContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get grantee termination context");
  }

  return context;
};

export type GranteeTerminationStep = "AMENDMENT" | "INFORMATION" | "REVIEW";
