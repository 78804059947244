/**
 * @generated SignedSource<<4b6e03e909c63c2d50c2675758f3e6a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderwaterGrantsGraph_CTMSGrant$data = ReadonlyArray<{
  readonly id: string;
  readonly label: string;
  readonly " $fragmentType": "UnderwaterGrantsGraph_CTMSGrant";
}>;
export type UnderwaterGrantsGraph_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: UnderwaterGrantsGraph_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrantsGraph_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UnderwaterGrantsGraph_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "a7d4321b02d95311a18a20e5055e5fce";

export default node;
