import { ArrowPathIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

import { Typography } from "../../../components/ui/Typography";

export function HRISSetupLinkButton({
  logoSrc,
  to,
}: {
  logoSrc: string;
  to: string;
}) {
  return (
    <Link
      className="flex cursor-pointer items-center justify-between gap-6 rounded-lg bg-gray-01 p-4 transition-all hover:bg-gray-02"
      to={to}
    >
      <div className="flex items-center justify-center rounded border border-gray-02 bg-gray-01 p-1">
        <ArrowPathIcon className="w-4 text-gray-09" />
      </div>
      <div className="flex items-center gap-2">
        <Typography as="div" variant="Medium/Extra Small">
          Connect
        </Typography>
        <img alt="logo" className="block h-4 object-contain" src={logoSrc} />
      </div>
      <ChevronRightIcon className="w-5" />
    </Link>
  );
}
