interface FileInfo {
  filename: string;
  url: string;
}

export const downloadFile = ({ filename, url }: FileInfo) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
};
