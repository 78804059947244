/**
 * @generated SignedSource<<75922518c1f12fd10a027377613e4212>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditTerminationPlanningEntryResultFailureReason = "MISSING_INFORMATION";
export type CreateTerminationPlanningEntryAttributes = {
  granteeId: string;
  terminationDate: string;
  vestingEndDate?: string | null;
  waiveCliff: boolean;
};
export type TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation$variables = {
  attributes: CreateTerminationPlanningEntryAttributes;
  organizationId: string;
};
export type TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation$data = {
  readonly createTerminationPlanningEntry: {
    readonly __typename: "EditTerminationPlanningEntryResultFailure";
    readonly reason: EditTerminationPlanningEntryResultFailureReason;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation = {
  response: TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation$data;
  variables: TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "createTerminationPlanningEntry",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reason",
            "storageKey": null
          }
        ],
        "type": "EditTerminationPlanningEntryResultFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e8636cd08823531827e3fec8634516a4",
    "id": null,
    "metadata": {},
    "name": "TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation TerminationPlanningEntryModal_CreateTerminationPlanningEntry_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: CreateTerminationPlanningEntryAttributes!\n) {\n  createTerminationPlanningEntry(organizationId: $organizationId, attributes: $attributes) {\n    __typename\n    ... on EditTerminationPlanningEntryResultFailure {\n      reason\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9873b2be2408065a7bc45be64d1c63c6";

export default node;
