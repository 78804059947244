/**
 * @generated SignedSource<<f7bc50b909eb31bbcf87042eb07de7e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityOfferSettingsStep = "CANDIDATE" | "COMPANY" | "EQUIUNIVERSITY" | "FINAL_THOUGHTS" | "INCENTIVE" | "PROJECTION" | "VESTING";
export type EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation$variables = {
  equityOfferId: string;
  step: EquityOfferSettingsStep;
};
export type EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation$data = {
  readonly addStepToEquityOfferVisitedSteps: {
    readonly visitedSteps: ReadonlyArray<EquityOfferSettingsStep>;
  };
};
export type EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation = {
  response: EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation$data;
  variables: EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityOfferId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "step"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "equityOfferId",
    "variableName": "equityOfferId"
  },
  {
    "kind": "Variable",
    "name": "step",
    "variableName": "step"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visitedSteps",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityOffer",
        "kind": "LinkedField",
        "name": "addStepToEquityOfferVisitedSteps",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityOffer",
        "kind": "LinkedField",
        "name": "addStepToEquityOfferVisitedSteps",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ddc2209724c56f72ba541d9fcf4ce821",
    "id": null,
    "metadata": {},
    "name": "EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityOfferContext_AddStepToEquityOfferVisitedSteps_Mutation(\n  $equityOfferId: UUID!\n  $step: EquityOfferSettingsStep!\n) {\n  addStepToEquityOfferVisitedSteps(equityOfferId: $equityOfferId, step: $step) {\n    visitedSteps\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2267b9f4b9b6db2df0185eb3a3e31809";

export default node;
