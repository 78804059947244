import React, { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  CTMSGrantAmendmentRequestDeletionConfirmationModal,
  useCTMSGrantAmendmentRequestDeletionConfirmationModalState,
} from "../../../components/CTMSGrantAmendmentRequestDeletionConfirmationModal";
import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useCTMSGrantAmendmentRequestIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { ModifyCTMSGrantPageContent } from "../CtmsGrant/Modify/ModifyCTMSGrantPageContent";
import { Details_CTMSGrantAmendmentRequest_Query } from "./__generated__/Details_CTMSGrantAmendmentRequest_Query.graphql";

const QUERY = graphql`
  query Details_CTMSGrantAmendmentRequest_Query(
    $organizationId: OrganizationId!
    $ctmsGrantAmendmentRequestId: UUID!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...ModifyCTMSGrantPageContent_Organization
    }
    ctmsGrantAmendmentRequest(id: $ctmsGrantAmendmentRequestId) {
      isEditable
      isDeletable
      ctmsGrant {
        ...ModifyCTMSGrantPageContent_CTMSGrant
      }
    }
  }
`;

const CTMSGrantAmendmentRequestDetails: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const ctmsGrantAmendmentRequestId = useCTMSGrantAmendmentRequestIdParam();

  const {
    query: { ctmsGrantAmendmentRequest, organization },
  } = useQuery<Details_CTMSGrantAmendmentRequest_Query>(QUERY, {
    ctmsGrantAmendmentRequestId,
    organizationId,
  });

  const navigate = useNavigate();

  const onSaveButtonClick = useCallback(() => {
    void navigate(
      generatePath(
        APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequestReviewModification,
        {
          ctmsGrantAmendmentRequestId,
          organizationId,
        },
      ),
    );
  }, [navigate, organizationId, ctmsGrantAmendmentRequestId]);

  const {
    closeModal: closeDeleteConfirmationModal,
    showModal: openDeleteConfirmationModal,
    state: deleteConfirmationModalState,
  } = useCTMSGrantAmendmentRequestDeletionConfirmationModalState();

  const onDeletionCompleted = () => {
    closeDeleteConfirmationModal();
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
        organizationId,
      }),
    );
  };

  if (!organization || !ctmsGrantAmendmentRequest) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Grants"
      analyticsName="Admin - Grants - Grant amendment request details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} grant amendment request details`}
    >
      <CTMSGrantAmendmentRequestDeletionConfirmationModal
        onClose={closeDeleteConfirmationModal}
        onDeletionCompleted={onDeletionCompleted}
        state={deleteConfirmationModalState}
      />
      <ModifyCTMSGrantPageContent
        ctmsGrantFragment={ctmsGrantAmendmentRequest.ctmsGrant}
        onDeleteButtonClick={() => {
          if (!ctmsGrantAmendmentRequest.isDeletable) return;

          openDeleteConfirmationModal([ctmsGrantAmendmentRequestId]);
        }}
        onSaveButtonClick={onSaveButtonClick}
        organizationFragment={organization}
        readOnly={!ctmsGrantAmendmentRequest.isEditable}
      />
    </Page>
  );
};

export default CTMSGrantAmendmentRequestDetails;
