/**
 * @generated SignedSource<<9a5458f438c4b6ff472a4218c9eb654e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharesValue_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "SharesValue_Organization";
};
export type SharesValue_Organization$key = {
  readonly " $data"?: SharesValue_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharesValue_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharesValue_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3cf8c71e2794e847f3b9effc301dd5d2";

export default node;
