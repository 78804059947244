/**
 * @generated SignedSource<<bae6e5a5287899351d97673b9d0b2c01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation$variables = {
  ceoNote: string;
  organizationId: string;
};
export type BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation$data = {
  readonly updateOrganizationPendingGrantAmendmentCeoNote: {
    readonly pendingGrantAmendmentCeoNote: string | null;
  };
};
export type BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation = {
  response: BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation$data;
  variables: BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ceoNote"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "ceoNote",
    "variableName": "ceoNote"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pendingGrantAmendmentCeoNote",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingGrantAmendmentCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationPendingGrantAmendmentCeoNote",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0675808d73c597aaecd29c4d0856eb5",
    "id": null,
    "metadata": {},
    "name": "BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation(\n  $organizationId: OrganizationId!\n  $ceoNote: String!\n) {\n  updateOrganizationPendingGrantAmendmentCeoNote(organizationId: $organizationId, ceoNote: $ceoNote) {\n    pendingGrantAmendmentCeoNote\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "306a423ffc2666629af42f2c12ca1976";

export default node;
