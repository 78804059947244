/**
 * @generated SignedSource<<321decd66410029fa629be693cc5f881>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeographiesDocumentsTable_DownloadDocumentsItem_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useDownloadInstrumentDocumentsPreview_Organization">;
  readonly " $fragmentType": "GeographiesDocumentsTable_DownloadDocumentsItem_Organization";
};
export type GeographiesDocumentsTable_DownloadDocumentsItem_Organization$key = {
  readonly " $data"?: GeographiesDocumentsTable_DownloadDocumentsItem_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeographiesDocumentsTable_DownloadDocumentsItem_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeographiesDocumentsTable_DownloadDocumentsItem_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDownloadInstrumentDocumentsPreview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "73f1bbc95b6abe1bedd876f6aff9634e";

export default node;
