/**
 * @generated SignedSource<<5754ace375a95d44b4afec9482d58452>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VestingSchedule_Instrument$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_Rule100KWatchout_EquityType">;
  } | null;
  readonly " $fragmentType": "VestingSchedule_Instrument";
};
export type VestingSchedule_Instrument$key = {
  readonly " $data"?: VestingSchedule_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedule_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingSchedule_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityType",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_Rule100KWatchout_EquityType"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "5408f374b6fbf2d7c92ce014214abff7";

export default node;
