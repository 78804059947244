/**
 * @generated SignedSource<<d3ce5b4db876c08da22e202400e77b3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteeForm_HRISProviderEmployee$data = ReadonlyArray<{
  readonly USStateOfResidence: {
    readonly code: string;
  } | null;
  readonly email: string;
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly id: string;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly startDate: string | null;
  readonly taxResidenceCountry: {
    readonly code: string;
  } | null;
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "GranteeForm_HRISProviderEmployee";
}>;
export type GranteeForm_HRISProviderEmployee$key = ReadonlyArray<{
  readonly " $data"?: GranteeForm_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_HRISProviderEmployee">;
}>;

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeForm_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "USState",
      "kind": "LinkedField",
      "name": "USStateOfResidence",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};
})();

(node as any).hash = "49bc44f4e05659fef1b06ce86f765d9c";

export default node;
