import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { StripePaymentMethodExpiration_StripePaymentMethod$key } from "./__generated__/StripePaymentMethodExpiration_StripePaymentMethod.graphql";

const STRIPE_PAYMENT_METHOD_FRAGMENT = graphql`
  fragment StripePaymentMethodExpiration_StripePaymentMethod on StripePaymentMethod {
    cardExpirationMonth
    cardExpirationYear
  }
`;

export const StripePaymentMethodExpiration: React.FC<{
  stripePaymentMethodFragment: StripePaymentMethodExpiration_StripePaymentMethod$key;
}> = ({ stripePaymentMethodFragment }) => {
  const { cardExpirationMonth, cardExpirationYear } = useFragment(
    STRIPE_PAYMENT_METHOD_FRAGMENT,
    stripePaymentMethodFragment,
  );

  if (
    typeof cardExpirationMonth !== "number" ||
    typeof cardExpirationYear !== "number"
  ) {
    return null;
  }

  return (
    <>
      Expires {cardExpirationMonth.toString().padStart(2, "0")}/
      {cardExpirationYear}
    </>
  );
};
