/**
 * @generated SignedSource<<9202f2951b8b80d714960384065f82df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type PTEP_Grantee$data = {
  readonly defaultAccelerationClause: AccelerationClause | null;
  readonly defaultPostTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly earlyExerciseIsAllowedByDefault: boolean;
  readonly " $fragmentType": "PTEP_Grantee";
};
export type PTEP_Grantee$key = {
  readonly " $data"?: PTEP_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"PTEP_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PTEP_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultAccelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExerciseIsAllowedByDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "defaultPostTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "11b29c36617351af3ae220f5a84f162a";

export default node;
