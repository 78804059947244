import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

import { RoundedBox } from "./RoundedBox";
import { Typography } from "./Typography";

const SIZES = {
  4: "space-y-4 p-4",
  6: "space-y-6 py-6 px-4 sm:px-6",
  10: "space-y-4 p-10",
};

export const Card: React.FC<
  React.PropsWithChildren<{
    className?: string;
    onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
    shadow?: boolean;
    size?: keyof typeof SIZES;
    title?: React.ReactNode;
  }>
> = ({
  children,
  className,
  onCloseButtonClick,
  shadow = true,
  size = 6,
  title,
}) => (
  <RoundedBox
    className={classNames(SIZES[size], className)}
    withBorder
    withShadow={shadow}
  >
    {(title || onCloseButtonClick) && (
      <div className="flex items-center justify-between">
        {title && (
          <Typography as="div" variant="Medium/Default">
            {title}
          </Typography>
        )}
        {onCloseButtonClick && (
          <button onClick={onCloseButtonClick} type="button">
            <XMarkIcon className="h-6 w-6 text-gray-09" />
          </button>
        )}
      </div>
    )}
    <div>{children}</div>
  </RoundedBox>
);
