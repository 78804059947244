/**
 * @generated SignedSource<<e9e8d741020cd360665d62cde517fead>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Exercise_Grantee_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "Exercise_Grantee_Organization";
};
export type Exercise_Grantee_Organization$key = {
  readonly " $data"?: Exercise_Grantee_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Exercise_Grantee_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Exercise_Grantee_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "271a23803e14911f153665cccc7200b0";

export default node;
