/**
 * @generated SignedSource<<b00151f09a1ad9e55d31e7b242c506b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipGranteePortal_Refresh_Query$variables = {
  equityTypeId: string;
};
export type EquityTypeWorkRelationshipGranteePortal_Refresh_Query$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipGranteePortal_EquityType">;
  } | null;
};
export type EquityTypeWorkRelationshipGranteePortal_Refresh_Query = {
  response: EquityTypeWorkRelationshipGranteePortal_Refresh_Query$data;
  variables: EquityTypeWorkRelationshipGranteePortal_Refresh_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "content",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastModifiedAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypeWorkRelationshipGranteePortal_Refresh_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypeWorkRelationshipGranteePortal_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityTypeWorkRelationshipGranteePortal_Refresh_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "eoREmployeeReportingGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "directEmployeeReportingGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "contractorReportingGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "eoREmployeeTaxationGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "directEmployeeTaxationGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "contractorTaxationGranteePortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "370e84724fa4e9dfad333a00757c6dca",
    "id": null,
    "metadata": {},
    "name": "EquityTypeWorkRelationshipGranteePortal_Refresh_Query",
    "operationKind": "query",
    "text": "query EquityTypeWorkRelationshipGranteePortal_Refresh_Query(\n  $equityTypeId: UUID!\n) {\n  equityType(id: $equityTypeId) {\n    ...EquityTypeWorkRelationshipGranteePortal_EquityType\n    id\n  }\n}\n\nfragment AdminPortalContent_AdminPortalContent on AdminPortalContent {\n  content\n  lastModifiedAt\n}\n\nfragment EquityTypeWorkRelationshipGranteePortal_EquityType on EquityType {\n  id\n  ...GranteePortalReportingContent_EquityType\n  ...GranteePortalTaxationContent_EquityType\n}\n\nfragment GranteePortalReportingContent_EquityType on EquityType {\n  taxResidenceCountry {\n    name\n  }\n  eoREmployeeReportingGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  directEmployeeReportingGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  contractorReportingGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n}\n\nfragment GranteePortalTaxationContent_EquityType on EquityType {\n  taxResidenceCountry {\n    name\n  }\n  eoREmployeeTaxationGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  directEmployeeTaxationGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  contractorTaxationGranteePortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbeb57276bc72b09b1917d06548c6032";

export default node;
