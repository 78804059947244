import { ForwardIcon } from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CtmsGrantActivePTEPValue } from "../../../../components/CtmsGrantActivePTEPValue";
import { CtmsGrantRemainingDaysToExerciseMessage } from "../../../../components/CtmsGrantRemainingDaysToExerciseMessage";
import { DetailsBox } from "../../../../components/DetailsBox";
import { Duration } from "../../../../components/Duration";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { SingleValueProgress } from "../../../../components/ui/SingleValueProgress";
import { CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$key } from "./__generated__/CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant.graphql";
import { CtmsGrantDetailsPostTerminationExercisePeriod_Organization$key } from "./__generated__/CtmsGrantDetailsPostTerminationExercisePeriod_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant on CTMSGrant {
    terminationDate
    exercisableUntilDate
    remainingDaysToExercise
    isVirtual
    activePostTerminationExercisePeriod {
      ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod
    }
    postTerminationExercisePeriod {
      __typename
      ... on OrganizationVariablePostTerminationExercisePeriod {
        extensionDuration
        extensionDurationUnit
        thresholdForExtensionInYears
      }
    }
    ...CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPostTerminationExercisePeriod_Organization on Organization {
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

export const CtmsGrantDetailsPostTerminationExercisePeriod: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsPostTerminationExercisePeriod_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsPostTerminationExercisePeriod_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const now = useMemo(() => new Date(), []);
  const exercisableUntilDate = useMemo(
    () =>
      ctmsGrant.exercisableUntilDate
        ? new Date(ctmsGrant.exercisableUntilDate)
        : null,
    [ctmsGrant.exercisableUntilDate],
  );
  const terminationDate = useMemo(
    () =>
      ctmsGrant.terminationDate ? new Date(ctmsGrant.terminationDate) : null,
    [ctmsGrant.terminationDate],
  );

  const ptepLabel = ctmsGrant.isVirtual
    ? "Post-termination settlement period"
    : "Post-termination exercise period";

  return (
    <DetailsBox Icon={ForwardIcon} title={ptepLabel}>
      <div className="space-y-4">
        {exercisableUntilDate &&
        terminationDate &&
        ctmsGrant.remainingDaysToExercise !== null ? (
          <InformationRows>
            <InformationRows.Row label="Start date">
              <FormattedDate
                day="2-digit"
                month="short"
                timeZone="UTC"
                value={terminationDate}
                year="numeric"
              />
            </InformationRows.Row>
            <InformationRows.Row label={`${ptepLabel} status`}>
              <div className="flex flex-col items-end">
                <CtmsGrantRemainingDaysToExerciseMessage
                  ctmsGrantFragment={ctmsGrant}
                />
                <SingleValueProgress
                  className="relative mt-2 h-2 w-[280px]"
                  current={Math.abs(now.getTime() - terminationDate.getTime())}
                  max={Math.abs(
                    exercisableUntilDate.getTime() - terminationDate.getTime(),
                  )}
                  variant="primary"
                />
              </div>
            </InformationRows.Row>
            <InformationRows.Row label="Last day to exercise">
              <FormattedDate
                day="2-digit"
                month="short"
                timeZone="UTC"
                value={exercisableUntilDate}
                year="numeric"
              />
            </InformationRows.Row>
          </InformationRows>
        ) : (
          <InformationRows>
            <InformationRows.Row label={ptepLabel}>
              {ctmsGrant.activePostTerminationExercisePeriod ? (
                <CtmsGrantActivePTEPValue
                  activePostTerminationExercisePeriodFragment={
                    ctmsGrant.activePostTerminationExercisePeriod
                  }
                />
              ) : (
                <MissingCTMSInformationTag
                  organizationFragment={organization}
                />
              )}
            </InformationRows.Row>
            <InformationRows.Row label={`Variable ${ptepLabel.toLowerCase()}`}>
              {ctmsGrant.postTerminationExercisePeriod?.__typename ===
              "OrganizationVariablePostTerminationExercisePeriod"
                ? "Yes"
                : "No"}
            </InformationRows.Row>
            {ctmsGrant.postTerminationExercisePeriod?.__typename ===
              "OrganizationVariablePostTerminationExercisePeriod" && (
              <InformationRows.Row label="Activate extended period after">
                <FormattedMessage
                  defaultMessage={`{duration} {duration, plural,
                        =0 {year}
                        one {year}
                        other {years}
                    } of service`}
                  values={{
                    duration:
                      ctmsGrant.postTerminationExercisePeriod
                        .thresholdForExtensionInYears,
                  }}
                />
              </InformationRows.Row>
            )}
            {ctmsGrant.postTerminationExercisePeriod?.__typename ===
              "OrganizationVariablePostTerminationExercisePeriod" && (
              <InformationRows.Row label="Period after activation">
                <Duration
                  duration={
                    ctmsGrant.postTerminationExercisePeriod.extensionDuration
                  }
                  durationUnit={
                    ctmsGrant.postTerminationExercisePeriod
                      .extensionDurationUnit
                  }
                />
              </InformationRows.Row>
            )}
          </InformationRows>
        )}
      </div>
    </DetailsBox>
  );
};
