/**
 * @generated SignedSource<<e98c7006baa01c2ee83756eae7053628>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDocumentationDetailsPage_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "GrantDocumentationDetailsPage_Organization";
};
export type GrantDocumentationDetailsPage_Organization$key = {
  readonly " $data"?: GrantDocumentationDetailsPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationDetailsPage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDocumentationDetailsPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "71f1a98a52001765c4fe64a483864142";

export default node;
