import { zodResolver } from "@hookform/resolvers/zod";
import { BoxedIcon, Button, StandaloneLink } from "@remote-com/norma";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import { IconV2OutlineArrowUpRight } from "@remote-com/norma/icons/IconV2OutlineArrowUpRight";
import { format } from "date-fns";
import { isNil } from "lodash";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { FormattedCurrency } from "../../../components/Formatted/FormattedCurrency";
import { FormattedCurrencyCompact } from "../../../components/Formatted/FormattedCurrencyCompact";
import { CartaLogo } from "../../../components/ui/CartaLogo";
import { RemoteInputText } from "../../../components/ui/Form/Inputs/RemoteInputText";
import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import { useTrackRemoteEquityOnboardingFlowEvent } from "../../../hooks/useTrackRemoteEquityOnboardingFlowEvent";
import { SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation } from "./__generated__/SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation.graphql";
import { SubmitPricePerShareView_Organization$key } from "./__generated__/SubmitPricePerShareView_Organization.graphql";
import { PricePerShareWarningCard } from "./NoticeCard";
import { PricePerShareExplanationDrawer } from "./PricePerShareExplanationDrawer";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SubmitPricePerShareView_Organization on Organization {
    id
    cartaIssuerName
    cartaGreatestPricePerShareValue
    cartaFullyDilutedShares
    cartaLatestFairMarketValue
    ...useTrackRemoteEquityOnboardingFlowEvent_Organization
  }
`;

const CREATE_PRICE_PER_SHARE_AND_SET_ORGANIZATION_NAME_TO_CARTA_ISSUER_NAME_MUTATION = graphql`
  mutation SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation(
    $pricePerShareAttributes: PricePerShareAttributes!
  ) {
    createPricePerShareAndSetOrganizationNameToCartaIssuerName(
      pricePerShareAttributes: $pricePerShareAttributes
    ) {
      name
    }
  }
`;

const FORM_SCHEMA = z.object({
  pricePerShare: z.coerce
    .number({
      invalid_type_error: "Price per share is required",
    })
    .positive(),
});

type FormValues = z.infer<typeof FORM_SCHEMA>;

export const SubmitPricePerShareView: React.FC<{
  onStepCompleted: () => void;
  organizationFragment: SubmitPricePerShareView_Organization$key;
}> = ({ onStepCompleted, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const trackEvent = useTrackRemoteEquityOnboardingFlowEvent({
    organizationFragment: organization,
  });
  const form = useForm<FormValues>({
    defaultValues: {
      pricePerShare: organization.cartaGreatestPricePerShareValue ?? undefined,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });
  const [
    triggerCreatePricePerShareAndSetOrganizationNameToCartaIssuerName,
    mutationIsInFlight,
  ] =
    useSafeMutation<SubmitPricePerShareView_CreatePricePerShareAndSetOrganizationNameToCartaIssuerName_Mutation>(
      CREATE_PRICE_PER_SHARE_AND_SET_ORGANIZATION_NAME_TO_CARTA_ISSUER_NAME_MUTATION,
    );

  const handleSubmit = form.handleSubmit(async (formData) => {
    trackEvent("Equity data confirmed", {
      cartaPricePerShare: organization.cartaGreatestPricePerShareValue,
      organizationName: organization.cartaIssuerName,
      pricePerShare: formData.pricePerShare,
      pricePerShareUpdated:
        formData.pricePerShare !== organization.cartaGreatestPricePerShareValue,
    });
    await triggerCreatePricePerShareAndSetOrganizationNameToCartaIssuerName({
      variables: {
        pricePerShareAttributes: {
          boardDetermined: false,
          date: format(new Date(), "yyyy-MM-dd"),
          organizationId: organization.id,
          value: formData.pricePerShare,
        },
      },
    });

    onStepCompleted();
  });

  const [pricePerShare] = form.watch(["pricePerShare"]);

  const valuation = useMemo(() => {
    if (isNil(pricePerShare) || isNil(organization.cartaFullyDilutedShares)) {
      return null;
    }

    return organization.cartaFullyDilutedShares * pricePerShare;
  }, [organization.cartaFullyDilutedShares, pricePerShare]);

  return (
    <RemoteEquityOnboardingLayout
      subtitle={
        <div className="max-w-[433px]">
          Get started with Remote Equity by connecting to your cap table
          management solution.
        </div>
      }
      title={
        <div className="max-w-[518px]">
          Connect your cap table management solution (CTMS)
        </div>
      }
    >
      <form className="w-full max-w-[752px] space-y-14" onSubmit={handleSubmit}>
        <div className="space-y-8">
          <div className="space-y-6 rounded-remote-md bg-remote-white px-2 pb-8 pt-6">
            <div className="flex items-center justify-between px-6">
              <div>
                <div className="font-remote-brand text-remote-LG/Medium text-remote-grey-800">
                  Your details on Carta
                </div>
                <div className="text-remote-SM/Normal text-remote-grey-700">
                  Please make sure this information is correct.
                </div>
              </div>
              <CartaLogo className="w-full max-w-14" />
            </div>
            <div className="space-y-6 rounded-remote-sm bg-remote-grey-50 px-8 py-6">
              <div className="space-y-4 rounded-remote-lg border-[0.5px] border-grey-300 bg-white px-4 py-8 shadow-100">
                <div className="space-y-2 text-center">
                  <div className="flex justify-center">
                    <BoxedIcon Icon={IconV2DuotoneBriefcase} tone="brand" />
                  </div>
                  <div className="space-y-0.5">
                    <div className="text-remote-SM/Normal text-grey-600">
                      {organization.cartaIssuerName} valued at
                    </div>
                    <div className="font-remote-brand text-remote-3XL/Medium text-grey-800">
                      {!isNil(valuation) ? (
                        <FormattedCurrencyCompact
                          currency="USD"
                          maximumFractionDigits={2}
                          value={valuation}
                        />
                      ) : (
                        <>$-</>
                      )}
                    </div>
                  </div>
                </div>
                {isNil(organization.cartaGreatestPricePerShareValue) && (
                  <PricePerShareWarningCard />
                )}
                <div className="space-y-4 rounded-remote-lg bg-grey-50 p-4">
                  <div className="grid grid-cols-2 items-center text-remote-Base">
                    <div className="text-grey-600">Fair Market Value</div>
                    <div className="text-right text-grey-800">
                      {isNil(organization.cartaLatestFairMarketValue) ? (
                        <>$-</>
                      ) : (
                        <FormattedCurrency
                          value={organization.cartaLatestFairMarketValue}
                        />
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 items-center text-remote-Base">
                    <div className="text-grey-600">
                      Fully Diluted Shares Count
                    </div>
                    <div className="text-right text-grey-800">
                      {isNil(organization.cartaFullyDilutedShares) ? (
                        <>-</>
                      ) : (
                        <FormattedNumber
                          value={organization.cartaFullyDilutedShares}
                        />
                      )}
                    </div>
                  </div>
                  <label className="grid grid-cols-2 items-center text-remote-Base/Medium">
                    <div className="text-grey-600">
                      Confirm your Price Per Share
                    </div>
                    <RemoteInputText.Form
                      control={form.control}
                      label="Price per share"
                      name="pricePerShare"
                      size="sm"
                      type="number"
                    />
                  </label>
                </div>
              </div>

              <PricePerShareExplanationDrawer>
                <StandaloneLink
                  className="w-full"
                  IconAfter={IconV2OutlineArrowUpRight}
                >
                  Why we need the price per share and how we calculate it
                </StandaloneLink>
              </PricePerShareExplanationDrawer>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center">
          <Button
            IconAfter={IconV2OutlineArrowRight}
            isLoading={mutationIsInFlight}
            size="lg"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
    </RemoteEquityOnboardingLayout>
  );
};
