import React from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../components/BackButton";
import { GeographiesDocumentsTable } from "../../../components/GeographiesDocumentsTable";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { GeographiesDocumentsPage_Organization$key } from "./__generated__/GeographiesDocumentsPage_Organization.graphql";
import { GeographiesDocumentsPage_Query } from "./__generated__/GeographiesDocumentsPage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GeographiesDocumentsPage_Organization on Organization {
    id
    name
    organizationGeographies {
      __typename
    }
    ...GeographiesDocumentsTable_Organization
  }
`;

const AdminGeographiesDocumentsPage_: React.FC<{
  organizationFragment: GeographiesDocumentsPage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Documents</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Legal documents</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="flex-grow space-y-2">
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Default">
                Country-Specific Templates
              </Typography>
              <Tag className="uppercase">
                <FormattedMessage
                  defaultMessage="{count, plural, one {# geography} other {# geographies}}"
                  values={{
                    count: organization.organizationGeographies.length,
                  }}
                />
              </Tag>
            </div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              The documents below are not approved or signed. They will be used
              as templates for your next equity grants in your unlocked
              geographies.
            </Typography>
          </div>
        </div>
        <GeographiesDocumentsTable organizationFragment={organization} />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query GeographiesDocumentsPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GeographiesDocumentsPage_Organization
    }
  }
`;

const AdminGeographiesDocumentsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<GeographiesDocumentsPage_Query>(
    QUERY,
    {
      organizationId,
    },
    { fetchPolicy: "store-and-network" },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Legal Documents"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} legal documents`}
    >
      <AdminGeographiesDocumentsPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default AdminGeographiesDocumentsPage;
