import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useGranteePositionToEquityGridLevel_Grantee$key } from "./__generated__/useGranteePositionToEquityGridLevel_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment useGranteePositionToEquityGridLevel_Grantee on Grantee {
    offGrid
    missingSharesToEquityGridLevel
    equityGridLevel {
      __typename
    }
  }
`;

export const useGranteePositionToEquityGridLevel = ({
  grantedSharesInDraft,
  granteeFragment,
}: {
  grantedSharesInDraft: number;
  granteeFragment: useGranteePositionToEquityGridLevel_Grantee$key;
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const granteeEquityGridLevel = grantee.equityGridLevel;

  if (grantee.missingSharesToEquityGridLevel === null) return null;

  const missingSharesToEquityGridLevel =
    grantee.missingSharesToEquityGridLevel - grantedSharesInDraft;

  if (grantee.offGrid) {
    return "OFF_GRID" as const;
  }

  if (!granteeEquityGridLevel) {
    return "NO_LEVEL" as const;
  }

  if (missingSharesToEquityGridLevel < 0) {
    return "ABOVE_LEVEL" as const;
  }

  if (missingSharesToEquityGridLevel > 0) {
    return "BELOW_LEVEL" as const;
  }

  return "RIGHT_ON_LEVEL" as const;
};
