import classNames from "classnames";
import { isEmpty } from "lodash";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  GranteeDetailsGrantsList_Grantee$data,
  GranteeDetailsGrantsList_Grantee$key,
} from "./__generated__/GranteeDetailsGrantsList_Grantee.graphql";
import {
  GranteeDetailsGrantsList_Organization$data,
  GranteeDetailsGrantsList_Organization$key,
} from "./__generated__/GranteeDetailsGrantsList_Organization.graphql";
import { CtmsGrantActiveOrTerminatedTag } from "./CtmsGrantActiveOrTerminatedTag";
import { CtmsGrantExerciseTag } from "./CtmsGrantExerciseTag";
import { CtmsGrantRemainingDaysToExerciseMessage } from "./CtmsGrantRemainingDaysToExerciseMessage";
import { SharesValue, SharesValueMode } from "./SharesValue";
import { Progress } from "./ui/Progress";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeDetailsGrantsList_Grantee on Grantee {
    ctmsGrants {
      id
      label
      cumulativeVested
      quantityIssued
      isActive
      ...CtmsGrantActiveOrTerminatedTag_CtmsGrant
      ...CtmsGrantExerciseTag_CtmsGrant
      ...CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeDetailsGrantsList_Organization on Organization {
    id
    ...SharesValue_Organization
  }
`;

const GAP = {
  2: "gap-2",
  6: "gap-6",
};

const LinkToGrantDetails: React.FC<{
  equityMode: SharesValueMode;
  grant: GranteeDetailsGrantsList_Grantee$data["ctmsGrants"][number];
  openInNewTab?: boolean;
  organization: GranteeDetailsGrantsList_Organization$data;
  positionInList: number;
  spaceBetween: 2 | 6;
}> = ({
  equityMode,
  grant,
  openInNewTab,
  organization,
  positionInList,
  spaceBetween,
}) => {
  return (
    <Link
      className="space-y-4 py-3.5 hover:bg-gray-01"
      target={openInNewTab ? "_blank" : undefined}
      to={generatePath(APPLICATION_ROUTES["organizationEquityCtmsGrant"], {
        ctmsGrantId: grant.id,
        organizationId: organization.id,
      })}
    >
      <div className={classNames("flex items-center", GAP[spaceBetween])}>
        <Typography
          className="flex h-6 w-6 shrink-0 items-center justify-center rounded bg-gray-02"
          variant="Regular/Extra Small"
        >
          {positionInList}
        </Typography>
        <Typography
          className="min-w-[72px] shrink-0 whitespace-nowrap text-left text-primary"
          variant="Medium/Small"
        >
          {grant.label}
        </Typography>
        <Progress
          className="h-3 flex-grow bg-gray-03"
          max={grant.quantityIssued}
        >
          <Progress.Value
            className={classNames(grant.isActive ? "bg-primary" : "bg-red")}
            value={grant.cumulativeVested}
          />
        </Progress>
        <div className="flex min-w-[152px] shrink-0 flex-col items-end">
          <Typography className="text-black-05" variant="Regular/Extra Small">
            <SharesValue
              mode={equityMode}
              organizationFragment={organization}
              shares={grant.cumulativeVested}
              variant="Regular/Extra Small"
            />
            /
          </Typography>
          <SharesValue
            appendSharesLabel
            className="whitespace-nowrap"
            mode={equityMode}
            organizationFragment={organization}
            shares={grant.quantityIssued}
            variant="Medium/Small"
          />
        </div>
      </div>
      {!grant.isActive && (
        <div className="flex items-center gap-2">
          <CtmsGrantActiveOrTerminatedTag ctmsGrantFragment={grant} />
          <CtmsGrantExerciseTag ctmsGrantFragment={grant} />
          <CtmsGrantRemainingDaysToExerciseMessage ctmsGrantFragment={grant} />
        </div>
      )}
    </Link>
  );
};

export const GranteeDetailsGrantsList: React.FC<{
  equityMode: SharesValueMode;
  granteeFragment: GranteeDetailsGrantsList_Grantee$key;
  openInNewTab?: boolean;
  organizationFragment: GranteeDetailsGrantsList_Organization$key;
  spaceBetween?: 2 | 6;
}> = ({
  equityMode,
  granteeFragment,
  openInNewTab,
  organizationFragment,
  spaceBetween = 2,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  if (isEmpty(grantee.ctmsGrants)) {
    return null;
  }

  return (
    <div className="flex flex-col divide-y-[0.5px] divide-gray-04">
      {grantee.ctmsGrants.map((ctmsGrant, index) => (
        <LinkToGrantDetails
          equityMode={equityMode}
          grant={ctmsGrant}
          key={ctmsGrant.id}
          openInNewTab={openInNewTab}
          organization={organization}
          positionInList={index + 1}
          spaceBetween={spaceBetween}
        />
      ))}
    </div>
  );
};
