/**
 * @generated SignedSource<<8cbfc8eb628c71e66434e993b038a544>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodGraph_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InsightNotAvailableBox_Viewer">;
  readonly " $fragmentType": "PostTerminationExercisePeriodGraph_Viewer";
};
export type PostTerminationExercisePeriodGraph_Viewer$key = {
  readonly " $data"?: PostTerminationExercisePeriodGraph_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodGraph_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostTerminationExercisePeriodGraph_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InsightNotAvailableBox_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "1f7f6c91321a3aea95b755f456fa114b";

export default node;
