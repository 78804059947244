/**
 * @generated SignedSource<<4bd30342d76d9fce33035376b41ef6a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateSlideOver_Query$variables = {
  organizationId: string;
};
export type CreateSlideOver_Query$data = {
  readonly organization: {
    readonly hasCooleyAsOutsideCounsel: boolean;
    readonly termOfOptionsInMonths: number;
    readonly " $fragmentSpreads": FragmentRefs<"PostTerminationForm_Organization">;
  };
};
export type CreateSlideOver_Query = {
  response: CreateSlideOver_Query$data;
  variables: CreateSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasCooleyAsOutsideCounsel",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termOfOptionsInMonths",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSlideOver_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PostTerminationForm_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de40df869fd0c818e0dfd542909c3501",
    "id": null,
    "metadata": {},
    "name": "CreateSlideOver_Query",
    "operationKind": "query",
    "text": "query CreateSlideOver_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    hasCooleyAsOutsideCounsel\n    termOfOptionsInMonths\n    ...PostTerminationForm_Organization\n    id\n  }\n}\n\nfragment PostTerminationForm_Organization on Organization {\n  hasCooleyAsOutsideCounsel\n}\n"
  }
};
})();

(node as any).hash = "9679d886769307a51f6ac0ee5a652b6e";

export default node;
