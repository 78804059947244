/**
 * @generated SignedSource<<bcdbb44ac7ab8ef65b8da5e4d76d3e05>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipOverview_Refresh_Query$variables = {
  equityTypeId: string;
};
export type EquityTypeWorkRelationshipOverview_Refresh_Query$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipOverview_EquityType">;
  } | null;
};
export type EquityTypeWorkRelationshipOverview_Refresh_Query = {
  response: EquityTypeWorkRelationshipOverview_Refresh_Query$data;
  variables: EquityTypeWorkRelationshipOverview_Refresh_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "content",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastModifiedAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypeWorkRelationshipOverview_Refresh_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypeWorkRelationshipOverview_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityTypeWorkRelationshipOverview_Refresh_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "eoREmployeeOverviewAdminPortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "directEmployeeOverviewAdminPortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminPortalContent",
            "kind": "LinkedField",
            "name": "contractorOverviewAdminPortalContent",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ec3f781d77b4332ccbf8e97ed5a6ec4b",
    "id": null,
    "metadata": {},
    "name": "EquityTypeWorkRelationshipOverview_Refresh_Query",
    "operationKind": "query",
    "text": "query EquityTypeWorkRelationshipOverview_Refresh_Query(\n  $equityTypeId: UUID!\n) {\n  equityType(id: $equityTypeId) {\n    ...EquityTypeWorkRelationshipOverview_EquityType\n    id\n  }\n}\n\nfragment AdminPortalContent_AdminPortalContent on AdminPortalContent {\n  content\n  lastModifiedAt\n}\n\nfragment EquityTypeWorkRelationshipOverview_EquityType on EquityType {\n  id\n  taxResidenceCountry {\n    name\n  }\n  eoREmployeeOverviewAdminPortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  directEmployeeOverviewAdminPortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n  contractorOverviewAdminPortalContent {\n    ...AdminPortalContent_AdminPortalContent\n  }\n}\n"
  }
};
})();

(node as any).hash = "1f0df6d3cab29e32d3c4d09fe98b4a38";

export default node;
