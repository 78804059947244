/**
 * @generated SignedSource<<62f3ce368b55d1e3d8e6f4804b0f0128>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteesFilters_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteesFilters_Deferred_Organization">;
  readonly " $fragmentType": "GranteesFilters_Organization";
};
export type GranteesFilters_Organization$key = {
  readonly " $data"?: GranteesFilters_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesFilters_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesFilters_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteesFilters_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "61caf8243020285bd1a05ec238eb3b95";

export default node;
