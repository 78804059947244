import { useState } from "react";
import { useDebounce } from "react-use";

export function useDebounced<State>({
  delay = 1000,
  initialState,
  onDebounce,
}: {
  delay?: number;
  initialState: State;
  onDebounce?: (state: State) => void;
}) {
  const [isDebouncing, setIsDebouncing] = useState(false);
  const [liveState, setLiveState] = useState<State>(initialState);
  const [debouncedState, setDebouncedState] = useState<State>(initialState);

  const [isReady, cancel] = useDebounce(
    () => {
      setIsDebouncing(false);
      setDebouncedState(liveState);
      // this condition prevent calling onDebounce on initial render
      if (isDebouncing) {
        onDebounce?.(liveState);
      }
    },
    delay,
    [liveState, onDebounce],
  );

  function setState(newState: State) {
    setIsDebouncing(true);
    setLiveState(newState);
  }

  return {
    cancel,
    debouncedState,
    isDebouncing,
    isReady,
    liveState,
    setState,
  };
}
