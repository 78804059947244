/**
 * @generated SignedSource<<d08d2f3bf49931c5709029146a399f3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipGranteePortal_EquityType$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalReportingContent_EquityType" | "GranteePortalTaxationContent_EquityType">;
  readonly " $fragmentType": "EquityTypeWorkRelationshipGranteePortal_EquityType";
};
export type EquityTypeWorkRelationshipGranteePortal_EquityType$key = {
  readonly " $data"?: EquityTypeWorkRelationshipGranteePortal_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipGranteePortal_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipGranteePortal_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalReportingContent_EquityType"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePortalTaxationContent_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "6e067d0af3db688855cb9d187387c309";

export default node;
