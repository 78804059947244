import { ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DetailsBox } from "../../../../components/DetailsBox";
import { MissingCTMSInformationTag } from "../../../../components/MissingInformationTag";
import {
  PriceEvolutionGraph,
  PriceEvolutionGraphMode,
  PriceEvolutionGraphModes,
} from "../../../../components/PriceEvolutionGraph";
import { PriceEvolutionGraphForVSOP } from "../../../../components/PriceEvolutionGraphForVSOP";
import { Divider } from "../../../../components/ui/Divider";
import { Switch } from "../../../../components/ui/Switch";
import { Typography } from "../../../../components/ui/Typography";
import { CtmsGrantDetailsPriceEvolution_CTMSGrant$key } from "./__generated__/CtmsGrantDetailsPriceEvolution_CTMSGrant.graphql";
import { CtmsGrantDetailsPriceEvolution_Organization$key } from "./__generated__/CtmsGrantDetailsPriceEvolution_Organization.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPriceEvolution_CTMSGrant on CTMSGrant {
    exercisePrice
    pricePerShareAtGrant
    isVirtual
    easopGrant {
      exercisePrice
    }
    ...PriceEvolutionGraph_CTMSGrant
    ...PriceEvolutionGraphForVSOP_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsPriceEvolution_Organization on Organization {
    latestPricePerShare {
      value
    }
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
    ...PriceEvolutionGraph_Organization
    ...PriceEvolutionGraphForVSOP_Organization
  }
`;

export const CtmsGrantDetailsPriceEvolution: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsPriceEvolution_CTMSGrant$key;
  organizationFragment: CtmsGrantDetailsPriceEvolution_Organization$key;
}> = ({ ctmsGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const exercisePrice =
    ctmsGrant.exercisePrice ?? ctmsGrant.easopGrant?.exercisePrice ?? null;
  const latestPricePerShare = organization.latestPricePerShare?.value ?? null;
  const pricePerShareAtGrant = ctmsGrant.pricePerShareAtGrant;

  const [selectedGraphMode, setSelectedGraphMode] =
    useState<PriceEvolutionGraphMode>(PriceEvolutionGraphModes[0]);

  return (
    <DetailsBox
      Icon={ArrowTrendingUpIcon}
      padded={false}
      title="Price evolution"
    >
      <div className="space-y-6">
        <div className="flex justify-center">
          <Switch
            getOptionValue={(option) => option}
            name="price-evolution-mode"
            onChange={setSelectedGraphMode}
            options={PriceEvolutionGraphModes}
            selectedOption={selectedGraphMode}
          />
        </div>

        <div className="flex flex-col gap-4 px-6">
          {ctmsGrant.isVirtual ? (
            <PriceEvolutionGraphForVSOP
              ctmsGrantFragment={ctmsGrant}
              graphMode={selectedGraphMode}
              organizationFragment={organization}
            />
          ) : (
            <PriceEvolutionGraph
              ctmsGrantFragment={ctmsGrant}
              graphMode={selectedGraphMode}
              organizationFragment={organization}
            />
          )}
        </div>
        <Divider />
        <div className="flex justify-between divide-x-[0.5px] divide-gray-04 px-6 pb-6 text-black-05 [&>*:first-child]:pr-6 [&>*:not(:first-child)]:pl-6 [&>*]:grow">
          <div className="flex flex-col items-start gap-1">
            {pricePerShareAtGrant !== null && latestPricePerShare !== null ? (
              <Typography className="text-primary" variant="Medium/Default">
                x
                <FormattedNumber
                  maximumFractionDigits={1}
                  value={latestPricePerShare / pricePerShareAtGrant}
                />
              </Typography>
            ) : (
              <MissingCTMSInformationTag organizationFragment={organization} />
            )}
            <Typography variant="Medium/Extra Small">
              Price Per Share multiple
            </Typography>
            <Typography variant="Regular/Caption">
              PPS at grant vs PPS today
            </Typography>
          </div>
          <div className="flex flex-col items-start gap-1">
            {exercisePrice !== null && latestPricePerShare !== null ? (
              <Typography className="text-primary" variant="Medium/Default">
                x
                <FormattedNumber
                  maximumFractionDigits={1}
                  value={latestPricePerShare / exercisePrice}
                />
              </Typography>
            ) : (
              <MissingCTMSInformationTag organizationFragment={organization} />
            )}
            <Typography variant="Medium/Extra Small">Grant multiple</Typography>
            <Typography variant="Regular/Caption">
              {ctmsGrant.isVirtual
                ? "Virtual Exercise Price vs PPS today"
                : "Exercise Price vs PPS today"}
            </Typography>
          </div>
        </div>
      </div>
    </DetailsBox>
  );
};
