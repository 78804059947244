/**
 * @generated SignedSource<<34df76310e3fb398329899a02d38b873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverGrantsCard_BoardConsent$data = {
  readonly __typename: "GrantAmendmentBoardConsent";
  readonly ctmsGrantAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_BoardConsent";
} | {
  readonly __typename: "GrantBoardConsent";
  readonly grants: ReadonlyArray<{
    readonly grantee: {
      readonly equityGridLevel: {
        readonly name: string;
      } | null;
      readonly jobTitle: string | null;
      readonly name: string;
      readonly offGrid: boolean;
      readonly workRelationship: WorkRelationship | null;
    };
    readonly id: string;
    readonly instrument: {
      readonly awardSuperType: AwardSuperType;
      readonly taxResidenceCountry: {
        readonly emoji: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
      };
    };
    readonly isVirtual: boolean;
    readonly postTerminationExercisePeriod: {
      readonly displayName: string;
    };
    readonly quantityGranted: number;
    readonly vestingSchedule: {
      readonly name: string;
    };
    readonly vestingStartDate: string;
    readonly " $fragmentSpreads": FragmentRefs<"ApprovalGranteeEquityGridLevelTag_EasopGrant">;
  }>;
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_BoardConsent";
} | {
  readonly __typename: "GranteeTerminationGrantAmendmentBoardConsent";
  readonly granteeTerminationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_BoardConsent";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ApprovalSlideOverGrantsCard_BoardConsent";
};
export type ApprovalSlideOverGrantsCard_BoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverGrantsCard_BoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverGrantsCard_BoardConsent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverGrantsCard_BoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
          "kind": "LinkedField",
          "name": "granteeTerminationCTMSGrantAmendmentRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverGrantsCard_GrantAmendmentCard_GranteeTerminationCTMSGrantAmendmentRequest"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GranteeTerminationGrantAmendmentBoardConsent",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EasopGrant",
          "kind": "LinkedField",
          "name": "grants",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Instrument",
              "kind": "LinkedField",
              "name": "instrument",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "awardSuperType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Country",
                  "kind": "LinkedField",
                  "name": "taxResidenceCountry",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "emoji",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CountryFlag_Country"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "grantee",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "jobTitle",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workRelationship",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EquityGridLevel",
                  "kind": "LinkedField",
                  "name": "equityGridLevel",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "offGrid",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantityGranted",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "vestingSchedule",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "postTerminationExercisePeriod",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "displayName",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "vestingStartDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isVirtual",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalGranteeEquityGridLevelTag_EasopGrant"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GrantBoardConsent",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CTMSGrantAmendmentRequest",
          "kind": "LinkedField",
          "name": "ctmsGrantAmendmentRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverGrantsCard_GrantAmendmentCard_CTMSGrantAmendmentRequest"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "GrantAmendmentBoardConsent",
      "abstractKey": null
    }
  ],
  "type": "BoardConsent",
  "abstractKey": "__isBoardConsent"
};
})();

(node as any).hash = "290a54a0e7fc7d950ac5ac4555dbd5db";

export default node;
