import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { Typography } from "../../../../components/ui/Typography";
import {
  QuantityVestedGraph_Organization$data,
  QuantityVestedGraph_Organization$key,
} from "./__generated__/QuantityVestedGraph_Organization.graphql";
import {
  QuantityVestedSlideOver,
  useQuantityVestedSlideOverState,
} from "./QuantityVestedSlideOver";

const ORGANIZATION_FRAGMENT = graphql`
  fragment QuantityVestedGraph_Organization on Organization {
    grantees {
      edges {
        node {
          id
          status
          totalGrantedSharesBreakdown {
            total
          }
          totalVestedSharesBreakdown {
            total
          }
        }
      }
    }
    ...QuantityVestedSlideOver_Organization
  }
`;

const useGetGranteePositionLabel = () => {
  const intl = useIntl();
  return useCallback(
    ({
      barKey,
      threshold,
    }: {
      barKey: "above" | "below" | "fully-vested";
      threshold: number;
    }) => {
      switch (barKey) {
        case "above":
          return `Vested ${intl.formatNumber(threshold, {
            style: "percent",
          })} or more`;
        case "below":
          return `Vested less than ${intl.formatNumber(threshold, {
            style: "percent",
          })}`;
        case "fully-vested":
          return "Fully vested";
      }
    },
    [intl],
  );
};

const Tooltip: React.FC<{
  barClassName: string;
  barKey: "above" | "below" | "fully-vested";
  granteesCount: number;
  threshold: number;
}> = ({ barClassName, barKey, granteesCount, threshold }) => {
  const getGranteePositionLabel = useGetGranteePositionLabel();
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grantees"
      topContent={getGranteePositionLabel({
        barKey,
        threshold,
      })}
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{granteesCount, plural,
                =0 {# grantee}
                =1 {# grantee}
                other {# grantees}
              }`}
              values={{ granteesCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

type Grantee =
  QuantityVestedGraph_Organization$data["grantees"]["edges"][number]["node"];

export const QuantityVestedGraph: React.FC<{
  organizationFragment: QuantityVestedGraph_Organization$key;
  threshold: number;
}> = ({ organizationFragment, threshold }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    aboveThresholdGrantees,
    belowThresholdGrantees,
    fullyVestedGrantees,
  } = useMemo(() => {
    const activeGranteesWithGrantedShares = organization.grantees.edges
      .map((edge) => edge.node)
      .filter((grantee) => {
        if (grantee.totalGrantedSharesBreakdown.total === 0) {
          return false;
        }
        switch (grantee.status) {
          case "Active":
            return true;
          case "Terminated":
            return false;
        }
      });
    return activeGranteesWithGrantedShares.reduce<{
      aboveThresholdGrantees: Array<Grantee>;
      belowThresholdGrantees: Array<Grantee>;
      fullyVestedGrantees: Array<Grantee>;
    }>(
      (acc, grantee) => {
        if (
          grantee.totalVestedSharesBreakdown.total ===
          grantee.totalGrantedSharesBreakdown.total
        ) {
          acc.fullyVestedGrantees.push(grantee);
        } else if (
          grantee.totalVestedSharesBreakdown.total <
          grantee.totalGrantedSharesBreakdown.total * threshold
        ) {
          acc.belowThresholdGrantees.push(grantee);
        } else {
          acc.aboveThresholdGrantees.push(grantee);
        }
        return acc;
      },
      {
        aboveThresholdGrantees: [],
        belowThresholdGrantees: [],
        fullyVestedGrantees: [],
      },
    );
  }, [organization.grantees, threshold]);

  const intl = useIntl();

  const stacks = useMemo(
    () =>
      (
        [
          {
            className: classNames("bg-green-05 fill-green-05"),
            grantees: belowThresholdGrantees,
            key: "below",
            label: `Less than ${intl.formatNumber(threshold, {
              style: "percent",
            })}`,
            value: belowThresholdGrantees.length,
          },
          {
            className: classNames("bg-purple-05 fill-purple-05"),
            grantees: aboveThresholdGrantees,
            key: "above",
            label: `${intl.formatNumber(threshold, {
              style: "percent",
            })} or more`,
            value: aboveThresholdGrantees.length,
          },
          {
            className: classNames("bg-orange-05 fill-orange-05"),
            grantees: fullyVestedGrantees,
            key: "fully-vested",
            label: "Fully vested",
            value: fullyVestedGrantees.length,
          },
        ] as const
      ).map((stack) => ({
        ...stack,
        elements: [
          {
            className: stack.className,
            key: stack.key,
            value: stack.value,
          },
        ],
      })),
    [
      belowThresholdGrantees,
      aboveThresholdGrantees,
      fullyVestedGrantees,
      intl,
      threshold,
    ],
  );

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useQuantityVestedSlideOverState();

  const getGranteePositionLabel = useGetGranteePositionLabel();

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) =>
      openSlideOver({
        barClassName: stack.className,
        granteesIds: stack.grantees.map((grantee) => grantee.id),
        title: (
          <FormattedMessage
            defaultMessage={`There {granteesCount, plural, 
            =0 {is # grantee who has} 
            =1 {is # grantee who has} 
            other {are # grantees who have}} {granteePositionLabel}`}
            values={{
              granteePositionLabel: getGranteePositionLabel({
                barKey: stack.key,
                threshold,
              }).toLowerCase(),
              granteesCount: stack.grantees.length,
            }}
          />
        ),
      }),
    [openSlideOver, getGranteePositionLabel, threshold],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        barKey={stack.key}
        granteesCount={stack.grantees.length}
        threshold={threshold}
      />
    ),
    [threshold],
  );

  return (
    <>
      <QuantityVestedSlideOver
        onClose={closeSlideOver}
        organizationFragment={organization}
        state={slideOverState}
      />
      <BarGraph
        barGap={88}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={stacks}
        yLabel="# of grantees"
        yTickFormatter={(v) => v.toString()}
      />
    </>
  );
};
