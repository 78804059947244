/**
 * @generated SignedSource<<8f4fadbea1240e795c91a1e21fb6a57d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type Shares_Instrument$data = {
  readonly valuation: {
    readonly type: ValuationType;
    readonly valueInDollars: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"QuestionCards_FairMarketValue_InstrumentValuation">;
  };
  readonly " $fragmentType": "Shares_Instrument";
};
export type Shares_Instrument$key = {
  readonly " $data"?: Shares_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"Shares_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Shares_Instrument",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "concreteType": "InstrumentValuation",
      "kind": "LinkedField",
      "name": "valuation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "valueInDollars",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuestionCards_FairMarketValue_InstrumentValuation"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "48a229572d6805256c47861bf029a5ca";

export default node;
