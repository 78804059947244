/**
 * @generated SignedSource<<4ad931432636c7359201ff5ef47ce776>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type LinearVestingOccurrence = "Every12Months" | "Every2Months" | "Every3Months" | "Every6Months" | "EveryMonth" | "Once";
export type EditSlideOver_VestingSchedule_Query$variables = {
  vestingScheduleId: string;
};
export type EditSlideOver_VestingSchedule_Query$data = {
  readonly vestingSchedule: {
    readonly __typename: "BackloadedVestingSchedule";
    readonly cliffActivatedOnFirstPeriod: boolean;
    readonly cliffDurationInMonths: number;
    readonly durationInMonths: number;
    readonly id: string;
    readonly name: string;
    readonly periods: ReadonlyArray<{
      readonly durationInMonths: number;
      readonly percentageVested: number;
    }>;
    readonly vestedAtCliffPercentage: number;
    readonly vestingOccurrence: LinearVestingOccurrence;
  } | {
    readonly __typename: "LinearVestingSchedule";
    readonly cliffDurationInMonths: number;
    readonly durationInMonths: number;
    readonly id: string;
    readonly name: string;
    readonly vestedAtCliffPercentage: number;
    readonly vestingOccurrence: LinearVestingOccurrence;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type EditSlideOver_VestingSchedule_Query = {
  response: EditSlideOver_VestingSchedule_Query$data;
  variables: EditSlideOver_VestingSchedule_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vestingScheduleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "vestingScheduleId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationInMonths",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cliffDurationInMonths",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vestedAtCliffPercentage",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vestingOccurrence",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cliffActivatedOnFirstPeriod",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "BackloadedVestingSchedulePeriod",
  "kind": "LinkedField",
  "name": "periods",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "percentageVested",
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSlideOver_VestingSchedule_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "vestingSchedule",
          "args": (v1/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/)
              ],
              "type": "LinearVestingSchedule",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/),
                (v8/*: any*/),
                (v9/*: any*/),
                (v10/*: any*/)
              ],
              "type": "BackloadedVestingSchedule",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSlideOver_VestingSchedule_Query",
    "selections": [
      {
        "alias": "vestingSchedule",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "LinearVestingSchedule",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "type": "BackloadedVestingSchedule",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a83fb21c5e870bbe5ec76b2c023a734",
    "id": null,
    "metadata": {},
    "name": "EditSlideOver_VestingSchedule_Query",
    "operationKind": "query",
    "text": "query EditSlideOver_VestingSchedule_Query(\n  $vestingScheduleId: ID!\n) {\n  vestingSchedule: node(id: $vestingScheduleId) {\n    __typename\n    ... on LinearVestingSchedule {\n      __typename\n      id\n      name\n      durationInMonths\n      cliffDurationInMonths\n      vestedAtCliffPercentage\n      vestingOccurrence\n    }\n    ... on BackloadedVestingSchedule {\n      __typename\n      id\n      name\n      durationInMonths\n      cliffDurationInMonths\n      vestedAtCliffPercentage\n      vestingOccurrence\n      cliffActivatedOnFirstPeriod\n      periods {\n        percentageVested\n        durationInMonths\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a8abd1b8fdb89005cef086c72f91d2f9";

export default node;
