/**
 * @generated SignedSource<<12989262c86a3b2bf2c5a1ef3ea474e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account";
};
export type UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account$key = {
  readonly " $data"?: UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "be22febf350bef76e09dec12a223b077";

export default node;
