import { graphql, useFragment } from "react-relay";

import { CtmsGrantActiveOrTerminatedTag_CtmsGrant$key } from "./__generated__/CtmsGrantActiveOrTerminatedTag_CtmsGrant.graphql";
import { Tag } from "./ui/Tag";

const GRANT_FRAGMENT = graphql`
  fragment CtmsGrantActiveOrTerminatedTag_CtmsGrant on CTMSGrant {
    isActive
  }
`;

export const CtmsGrantActiveOrTerminatedTag: React.FC<{
  ctmsGrantFragment: CtmsGrantActiveOrTerminatedTag_CtmsGrant$key;
  length?: "long" | "short";
}> = ({ ctmsGrantFragment, length = "short" }) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  return !ctmsGrant.isActive ? (
    <Tag color="red">
      {length === "short" ? "Terminated" : "Terminated grant"}
    </Tag>
  ) : (
    <Tag color="green">{length === "short" ? "Active" : "Active grant"}</Tag>
  );
};
