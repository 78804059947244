/**
 * @generated SignedSource<<b739c60c43df8a65ba02783e49eea697>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type useDownloadInstrumentDocumentsPreview_Instrument$data = {
  readonly awardAgreementDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly boardConsentDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  };
  readonly countrySpecificAddendumDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly formOfExerciseAgreementDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly grantNoticeDocumentTemplate: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly id: string;
  readonly taxResidenceCountry: {
    readonly name: string;
  };
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "useDownloadInstrumentDocumentsPreview_Instrument";
};
export type useDownloadInstrumentDocumentsPreview_Instrument$key = {
  readonly " $data"?: useDownloadInstrumentDocumentsPreview_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDownloadInstrumentDocumentsPreview_Instrument">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useDownloadInstrumentDocumentsPreview_Instrument",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "grantNoticeDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "formOfExerciseAgreementDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "countrySpecificAddendumDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "boardConsentDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "awardAgreementDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DocumentTemplate",
      "kind": "LinkedField",
      "name": "formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};
})();

(node as any).hash = "27c917d6586d63602cdb2ac9ed55e8e4";

export default node;
