import classNames from "classnames";
import { BackSquare, ReceiptItem, StopCircle, Timer } from "iconsax-react";
import { isEmpty } from "lodash";
import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { PlanningChart } from "../../../../components/PlanningChart";
import { Divider } from "../../../../components/ui/Divider";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { PlanningChartView_Organization$key } from "./__generated__/PlanningChartView_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningChartView_Organization on Organization {
    availableSharesForPlanningNow
    planningReservedShares
    planningReturningShares
    planningSharesLeft
    planningEntries {
      __typename
    }
    ...PlanningChart_Organization
  }
`;

const SharesBox: React.FC<{
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  label: string;
  shares: number;
}> = ({ icon, label, shares }) => (
  <RoundedBox className="flex items-center gap-4 p-6" withBorder>
    {React.cloneElement(icon, {
      ...icon.props,
      className: classNames(
        icon.props.className,

        /* tailwind */ `h-8 w-8`,
      ),
    })}
    <div className="space-y-2">
      <Typography as="div" variant="Medium/Default">
        <FormattedNumber signDisplay="negative" value={shares} />
      </Typography>
      <Typography
        as="div"
        className="uppercase text-black-05"
        variant="Regular/Caption"
      >
        {label}
      </Typography>
    </div>
  </RoundedBox>
);

export const PlanningChartView: React.FC<{
  organizationFragment: PlanningChartView_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6 xl:grid-cols-4">
        <SharesBox
          icon={<Timer className="text-black-05" variant="Bulk" />}
          label="AVAILABLE NOW"
          shares={organization.availableSharesForPlanningNow}
        />
        <SharesBox
          icon={<StopCircle className="text-primary-05" variant="Bulk" />}
          label="RESERVED"
          shares={-organization.planningReservedShares}
        />
        <SharesBox
          icon={<BackSquare className="text-green-05" variant="Bulk" />}
          label="RETURNING"
          shares={organization.planningReturningShares}
        />
        <SharesBox
          icon={<ReceiptItem className="text-black-05" variant="Bulk" />}
          label="LEFT AFTER PLANNING"
          shares={organization.planningSharesLeft}
        />
      </div>
      <RoundedBox withBorder>
        <div className="space-y-2 p-6">
          <Typography as="div" variant="Medium/Small">
            Pool projection
          </Typography>
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Extra Small"
          >
            Waterfall view of the options reserved and returning
          </Typography>
        </div>
        <Divider />
        <div className="p-6">
          {isEmpty(organization.planningEntries) ? (
            <NoticeMessage hasColor={false} size="Large" variant="Info">
              No entries in this planning yet
            </NoticeMessage>
          ) : (
            <PlanningChart organizationFragment={organization} />
          )}
        </div>
      </RoundedBox>
    </div>
  );
};
