/**
 * @generated SignedSource<<61c4e19688d10c992e8b497bac8d3774>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalDetails_GrantAmendmentBoardConsent">;
  readonly " $fragmentType": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent";
};
export type ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalDetails_GrantAmendmentBoardConsent"
    }
  ],
  "type": "GrantAmendmentBoardConsent",
  "abstractKey": null
};

(node as any).hash = "acab379c6b573b195555df3eacb17150";

export default node;
