/**
 * @generated SignedSource<<4e49dea9a020ba147ae2ec141ea94142>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EarlyExerciseBeneficialPage_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationModal_Organization">;
  readonly " $fragmentType": "EarlyExerciseBeneficialPage_Organization";
}>;
export type EarlyExerciseBeneficialPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: EarlyExerciseBeneficialPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EarlyExerciseBeneficialPage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SelectOrganizationModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3e06c259322c96ab8d8a2e4fa2cb65bf";

export default node;
