/**
 * @generated SignedSource<<d490d5b421e53718010878dd36be1cf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type useEquityOfferTrackEvent_EquityOffer$data = {
  readonly benefits: ReadonlyArray<string>;
  readonly candidateName: string | null;
  readonly displayCompanyInformation: boolean;
  readonly displayEquityAsDollarValue: boolean;
  readonly displayEquityAsPercentage: boolean;
  readonly displayFAQ: boolean;
  readonly displayProjectionScenarios: boolean;
  readonly exercisePrice: number | null;
  readonly finalThoughts: string | null;
  readonly id: string;
  readonly instrument: {
    readonly description: string;
    readonly id: string;
  } | null;
  readonly organization: {
    readonly latestFairMarketValue: {
      readonly value: number;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  };
  readonly position: string | null;
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  } | null;
  readonly salary: number | null;
  readonly shares: number | null;
  readonly taxResidenceCountry: {
    readonly name: string;
  } | null;
  readonly vestingSchedule: {
    readonly id: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentType": "useEquityOfferTrackEvent_EquityOffer";
};
export type useEquityOfferTrackEvent_EquityOffer$key = {
  readonly " $data"?: useEquityOfferTrackEvent_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useEquityOfferTrackEvent_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useEquityOfferTrackEvent_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "candidateName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCompanyInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsDollarValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEquityAsPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "benefits",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayFAQ",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayProjectionScenarios",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalThoughts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FairMarketValue",
          "kind": "LinkedField",
          "name": "latestFairMarketValue",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationSharesUtil_Organization"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "e6b4b0eab38f91f3209471d9cd967060";

export default node;
