import { debounce } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { GraphQLTaggedNode, OperationType } from "relay-runtime";

import { useManualQuery } from "./useManualQuery";

export function useDebouncedManualQuery<TOperationType extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  {
    delayInMs = 500,
    fetchPolicy,
  }: {
    delayInMs?: number;
    fetchPolicy?: "network-only" | "store-or-network";
  } = {},
) {
  const [triggerQuery, queryIsInFlight] = useManualQuery<TOperationType>(
    gqlQuery,
    { fetchPolicy },
  );

  const debouncedManualQuery = useMemo(
    () => debounce(triggerQuery, delayInMs),
    [triggerQuery, delayInMs],
  );

  const flushDebouncedQueries = useCallback(() => {
    debouncedManualQuery.flush();
  }, [debouncedManualQuery]);

  useEffect(() => {
    return () => {
      flushDebouncedQueries();
    };
  }, [flushDebouncedQueries]);

  return [debouncedManualQuery, queryIsInFlight] as const;
}
