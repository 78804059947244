/**
 * @generated SignedSource<<3d9e338a2c3c1666c6abb7e04e511032>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ValuationWarningMessage_Organization$data = {
  readonly id: string;
  readonly lastestUKValuation: {
    readonly remainingDaysBeforeExpiration: number;
  } | null;
  readonly latestFairMarketValue: {
    readonly remainingDaysBeforeExpiration: number;
  } | null;
  readonly " $fragmentType": "ValuationWarningMessage_Organization";
};
export type ValuationWarningMessage_Organization$key = {
  readonly " $data"?: ValuationWarningMessage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ValuationWarningMessage_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "remainingDaysBeforeExpiration",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ValuationWarningMessage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UKValuation",
      "kind": "LinkedField",
      "name": "lastestUKValuation",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "81475ff694838a39d57cbe8db533a43a";

export default node;
