/**
 * @generated SignedSource<<4395da550615c2f0387584b2d86e7597>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinalThoughts_EquityOffer$data = {
  readonly finalThoughts: string | null;
  readonly " $fragmentType": "FinalThoughts_EquityOffer";
};
export type FinalThoughts_EquityOffer$key = {
  readonly " $data"?: FinalThoughts_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinalThoughts_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinalThoughts_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finalThoughts",
      "storageKey": null
    }
  ],
  "type": "IEquityOffer",
  "abstractKey": "__isIEquityOffer"
};

(node as any).hash = "d02ccfa0f88a25f9791574ac9abdc4ca";

export default node;
