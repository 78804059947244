import { useAsyncFn } from "react-use";

import { Button } from "../../components/ui/Button";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";

interface Props {
  className?: string;
  email: string;
}

const PasswordResetTokenExpiredCard = ({ email }: Props) => {
  const [
    handleRequestNewLinkButtonClickState,
    handleRequestNewLinkButtonClick,
  ] = useAsyncFn(async () => {
    await AuthenticationAPIClient.resetPassword({ email });
    return { success: true };
  }, [email]);

  return (
    <AuthLayout.Card
      logo={<LogoWithoutText />}
      subtitle="It looks like your reset password link has expired."
      title="Woops, too late!"
    >
      <Button
        disabled={handleRequestNewLinkButtonClickState.value?.success}
        fullWidth
        loading={handleRequestNewLinkButtonClickState.loading}
        onClick={handleRequestNewLinkButtonClick}
        success={handleRequestNewLinkButtonClickState.value?.success}
        type="button"
      >
        {handleRequestNewLinkButtonClickState.value?.success
          ? "We just sent you a new link!"
          : "Request a new reset password link"}
      </Button>
    </AuthLayout.Card>
  );
};

export default PasswordResetTokenExpiredCard;
