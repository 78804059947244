import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Typography } from "../../../../components/ui/Typography";
import { useEquityTypeWorkRelationshipCategoryParam } from "../../../../paths";
import { EquityTypeWorkRelationshipCategory } from "../../../../services/workRelationship";
import { EquityTypeWorkRelationshipAssumptions_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipAssumptions_EquityType.graphql";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipAssumptions_EquityType on EquityType {
    awardSuperType
    taxResidenceCountry {
      code
    }
  }
`;

const AssumptionListItem: React.FC<React.PropsWithChildren> = ({
  children,
}) => (
  <li>
    <Typography variant="Regular/Small">{children}</Typography>
  </li>
);

const AssumptionList: React.FC<
  React.PropsWithChildren<{
    title: React.ReactNode;
  }>
> = ({ children, title }) => (
  <div className="space-y-2 rounded-lg bg-gray-01 p-6">
    <Typography as="div" variant="Medium/Default">
      {title}
    </Typography>
    {children && <ul className="list-disc pl-5">{children}</ul>}
  </div>
);

const StockOptions_EOREmployee: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has issued the stock options is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does not have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title={`The "equity" type issued is:`}>
      <AssumptionListItem>
        a non-qualified stock option (NSO)
      </AssumptionListItem>
      <AssumptionListItem>
        which has been reserved for issuance under an equity pool
      </AssumptionListItem>
      <AssumptionListItem>
        which entitles his/her holder to acquire shares of common stock of the
        issuing entity against payment of an exercise price (whether in cash or
        otherwise such as a cashless exercise), which is at least equal to the
        fair market value of the shares of common stock at the time of grant,
        determined on the basis of a 409A valuation report
      </AssumptionListItem>

      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the option is not transferable,
        except in case of death)
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to transfer restrictions (e.g. right of first refusal)
        set out in the company articles of association and other restrictions
        imposed by US laws
      </AssumptionListItem>

      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the option itself (but will need to pay an exercise price if he or she
        wants to exercise the option))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the service relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="The person receiving the option (i.e. the grantee) is:">
      <AssumptionListItem>
        a natural person (unless indicated otherwise in the summary, for example
        if references are made to persons working via a personal “management
        company” or “personal holding vehicle”)
      </AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the stock option (from the grant of the option to the sale
        of the underlying shares) and does not qualify as a tax resident or
        subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        an employee of a local “employer of record”, under an enforceable local
        employment agreement (with the local “employer of record” being the sole
        legal employer); and the relationships between the entity issuing the
        option, the “employer of record” and the grantee is structured in such
        as way that it is recognized as valid under the laws of the grantee’s
        country of residence and work.
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
  </>
);

const SAR_EOREmployee: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has offered the Stock Appreciation Rights is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does not have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title={`The "equity" type issued is:`}>
      <AssumptionListItem>a Stock Appreciation Right (SAR)</AssumptionListItem>
      <AssumptionListItem>
        which entitles their holder to acquire a cash bonus upon a liquidity
        event only (or earlier, at the entire discretion of the issuing entity)
      </AssumptionListItem>

      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the SAR is not transferable,
        except in case of death)
      </AssumptionListItem>

      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the SAR itself (but could be asked to pay an exercise price if this has
        been foreseen in the grant documentation))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the service relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="the person receiving the SAR (i.e. the grantee) is:">
      <AssumptionListItem>a natural person</AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the SAR (from the grant of the SAR to the liquidity event
        giving rise to settlement of the SAR) and does not qualify as a tax
        resident or subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        an employee of a local “employer of record”, under an enforceable local
        employment agreement (with the local “employer of record” being the sole
        legal employer); and the relationship between the entity issuing the
        SAR, the “employer of record” and the grantee is structured in such as
        way that it is recognized as valid under the laws of the grantee’s
        country of residence and work.
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
  </>
);

const StockOptions_DirectEmployee: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has issued the stock options is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title="The equity type issued is:">
      <AssumptionListItem>
        {isUSGeography
          ? "a non-qualified stock option (NSO) or incentive stock option (ISO)"
          : "a non-qualified stock option (NSO) for US law purposes"}
      </AssumptionListItem>
      <AssumptionListItem>
        which has been reserved for issuance under an equity pool
      </AssumptionListItem>
      <AssumptionListItem>
        which entitles his/her holder to acquire shares of common stock of the
        issuing entity against payment of an exercise price (whether in cash or
        otherwise such as a cashless exercise), which is at least equal to the
        fair market value of the shares of common stock at the time of grant,
        determined on the basis of a 409A valuation report
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the option is not transferable,
        except in case of death)
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to transfer restrictions (e.g. right of first refusal)
        set out in the company articles of association and other restrictions
        imposed by US laws
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to transfer restrictions (e.g. right of first refusal)
        set out in the company articles of association and other restrictions
        imposed by US laws
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the option itself (but will need to pay an exercise price if he or she
        wants to exercise the option))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the working relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="The person receiving the option (i.e. the grantee) is:">
      <AssumptionListItem>a natural person</AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the stock option (from the grant of the option to the sale
        of the underlying shares) and does not qualify as a tax resident or
        subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        an employee of the issuing entity or an affiliate of the issuing entity
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
  </>
);

const SAR_DirectEmployee: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has offered the Stock Appreciation Rights is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title="The equity type issued is:">
      <AssumptionListItem>a Stock Appreciation Right (SAR)</AssumptionListItem>
      <AssumptionListItem>
        which entitles their holder to acquire a cash bonus upon a liquidity
        event only (or earlier, at the entire discretion of the issuing entity)
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the SAR is not transferable,
        except in case of death)
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the SAR itself (but could be asked to pay an exercise price if this has
        been foreseen in the grant documentation))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the service relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="The person receiving the SAR (i.e. the grantee) is:">
      <AssumptionListItem>a natural person</AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the SAR (from the grant of the SAR to the liquidity event
        giving rise to settlement of the SAR) and does not qualify as a tax
        resident or subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        an employee of the issuing entity or an affiliate of the issuing entity
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
  </>
);

const StockOptions_Contractor: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has issued the stock options is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does not have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title="The equity type issued is:">
      <AssumptionListItem>
        a non-qualified stock option (NSO)
      </AssumptionListItem>
      <AssumptionListItem>
        which has been reserved for issuance under an equity pool
      </AssumptionListItem>
      <AssumptionListItem>
        which entitles his/her holder to acquire shares of common stock of the
        issuing entity against payment of an exercise price (whether in cash or
        otherwise such as a cashless exercise), which is at least equal to the
        fair market value of the shares of common stock at the time of grant,
        determined on the basis of a 409A valuation report
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the option is not transferable,
        except in case of death)
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to transfer restrictions (e.g. right of first refusal)
        set out in the company articles of association and other restrictions
        imposed by US laws
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the option itself (but will need to pay an exercise price if he or she
        wants to exercise the option))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the service relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="The person receiving the option (i.e. the grantee) is:">
      <AssumptionListItem>
        a natural person (unless indicated otherwise in the summary, for example
        if references are made to persons working via a personal “management
        company” or “personal holding vehicle”)
      </AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the stock option (from the grant of the option to the sale
        of the underlying shares) and does not qualify as a tax resident or
        subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        working as a self-employed person (i.e. not as a “disguised employee”)
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
    <AssumptionList title="Grant, exercise and sale take place at different points in time" />
    <AssumptionList title="VAT implications have been excluded from the scope of the summary" />
  </>
);

const SAR_Contractor: React.FC<{
  isUSGeography: boolean;
}> = ({ isUSGeography }) => (
  <>
    <AssumptionList title="The entity that has offered the Stock Appreciation Rights is:">
      <AssumptionListItem>a Delaware corporation (C-Corp)</AssumptionListItem>
      <AssumptionListItem>
        whose shares are not listed (either on a regulated or non-regulated
        market)
      </AssumptionListItem>
      {!isUSGeography && (
        <AssumptionListItem>
          that does not have a taxable presence in the grantee’s country of
          residence (be it through a subsidiary, permanent establishment or
          deemed related company)
        </AssumptionListItem>
      )}
    </AssumptionList>

    <AssumptionList title={`The "equity" type issued is:`}>
      <AssumptionListItem>a Stock Appreciation Right (SAR)</AssumptionListItem>
      <AssumptionListItem>
        which entitles their holder to acquire a cash bonus upon a liquidity
        event only (or earlier, at the entire discretion of the issuing entity)
      </AssumptionListItem>
      <AssumptionListItem>
        which is subject to a vesting schedule, which is not subject to
        performance conditions
      </AssumptionListItem>
      <AssumptionListItem>
        which is personal to the grantee (i.e. the SAR is not transferable,
        except in case of death)
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered free of charge (the grantee does not need to pay for
        the SAR itself (but could be asked to pay an exercise price if this has
        been foreseen in the grant documentation))
      </AssumptionListItem>
      <AssumptionListItem>
        which is offered in relation to the service relationship
      </AssumptionListItem>
    </AssumptionList>

    <AssumptionList title="The person receiving the SAR (i.e. the grantee) is:">
      <AssumptionListItem>
        a natural person (unless indicated otherwise in the summary, for example
        if references are made to persons working via a personal “management
        company” or “personal holding vehicle”)
      </AssumptionListItem>
      <AssumptionListItem>
        {isUSGeography
          ? "a US resident and taxpayer"
          : "neither a US citizen nor a US taxpayer"}
      </AssumptionListItem>
      <AssumptionListItem>
        working and residing only in the same country during the entire
        lifecycle of the SAR (from the grant of the SAR to the liquidity event
        giving rise to settlement of the SAR) and does not qualify as a tax
        resident or subject to social security in any additional jurisdiction
      </AssumptionListItem>
      <AssumptionListItem>
        working as a self-employed person (i.e. not as a “disguised employee”)
      </AssumptionListItem>
      <AssumptionListItem>
        not a significant or controlling stakeholder
      </AssumptionListItem>
    </AssumptionList>
  </>
);

const ASSUMPTIONS_COMPONENTS_MAP: Record<
  `${"SAR" | "StockOptions"}_${EquityTypeWorkRelationshipCategory}`,
  React.FC<{
    isUSGeography: boolean;
  }>
> = {
  SAR_contractor: SAR_Contractor,
  SAR_directEmployee: SAR_DirectEmployee,
  SAR_eoREmployee: SAR_EOREmployee,
  StockOptions_contractor: StockOptions_Contractor,
  StockOptions_directEmployee: StockOptions_DirectEmployee,
  StockOptions_eoREmployee: StockOptions_EOREmployee,
};

const EquityTypeWorkRelationshipAssumptions: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipAssumptions_EquityType$key;
}> = ({ equityTypeFragment }) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);
  const equityTypeWorkRelationshipCategory =
    useEquityTypeWorkRelationshipCategoryParam();

  const AssumptionsComponent =
    ASSUMPTIONS_COMPONENTS_MAP[
      `${
        equityType.awardSuperType === "SAR" ? "SAR" : "StockOptions"
      }_${equityTypeWorkRelationshipCategory}`
    ];

  return (
    <div className="space-y-4">
      <Typography as="div" variant="Medium/Large">
        Some assumptions
      </Typography>
      <AssumptionsComponent
        isUSGeography={equityType.taxResidenceCountry.code === "US"}
      />
    </div>
  );
};

export default EquityTypeWorkRelationshipAssumptions;
