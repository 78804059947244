import React from "react";
import SanitizeHTML from "sanitize-html";

import { EDITOR_SECTIONS } from "./sections";

export const escapeHTML = (unsafeHtml: string) => {
  return SanitizeHTML(unsafeHtml, {
    allowedAttributes: {
      a: ["href", "name", "target"],
      img: ["src"],
    },
    allowedClasses: {
      "*": [
        ...EDITOR_SECTIONS.map(({ classAttr }) => classAttr ?? ""),
        "wysiwygCirlcleLetterContainer",
        "wysiwygCirlcleLetter",
        ...[2, 4, 6, 8].map((margin) => `mb-${margin}`),
      ],
    },
    // URL schemes we permit
    allowedSchemes: ["http", "https", "mailto"],
    allowedSchemesByTag: {},
    allowedTags: [
      "blockquote",
      "p",
      "a",
      "h3",
      "h4",
      "h5",
      "h6",
      "ul",
      "ol",
      "li",
      "b",
      "i",
      "strong",
      "aside",
      "section",
      "em",
      "strike",
      "del",
      "br",
      "span",
      "div",
      "sup",
      "sub",
    ],
    // Lots of these won't come up by default because we don't allow them
    selfClosing: [
      "img",
      "br",
      "hr",
      "area",
      "base",
      "basefont",
      "input",
      "link",
      "meta",
    ],
  });
};

export const SafeHTML: React.FC<React.PropsWithChildren> = ({ children }) => {
  const unsafeHtml = React.Children.toArray(children)
    .filter((child) => typeof child === "string")
    .join("");

  return (
    <div
      className="mce-content-body"
      dangerouslySetInnerHTML={{ __html: escapeHTML(unsafeHtml) }}
    />
  );
};
