import {
  Drawer,
  DrawerHeader,
  DrawerLayout,
  DrawerPane,
  DrawerTitle,
  ListDisc,
  Text,
} from "@remote-com/norma";

export const PricePerShareExplanationDrawer: React.FC<{
  children: React.ReactElement;
}> = ({ children }) => {
  return (
    <Drawer
      $layout="fullWidth"
      aria-labelledby="price-per-share-explanation-drawer-title"
      Trigger={children}
    >
      <DrawerLayout>
        <DrawerHeader>
          <DrawerTitle id="price-per-share-explanation-drawer-title">
            Price per share
          </DrawerTitle>
        </DrawerHeader>
        <DrawerPane>
          <div className="space-y-4 text-remote-grey-700">
            <Text className="text-remote-grey-900" variant="baseMedium">
              Why we need the price per share and how we calculate it
            </Text>
            <Text variant="sm">
              We might have found at least one convertible note or SAFE in Carta
              that has not been converted yet. It might be impossible for us to
              put a $ value on your equity grants for that reason.
            </Text>
            <Text variant="sm">
              A way to estimate your Price Per Share until your convertible
              note(s) and/or SAFE convert is to simply apply the following
              formula:
            </Text>
            <Text variant="sm">
              PPS proxy = Post-money Valuation Cap divided by Fully Diluted
              number of shares.
            </Text>
            <Text variant="sm">
              Keep in mind that this will be an overestimated amount because:
            </Text>
            <ListDisc discTone="secondary" gap="md" size="md">
              <li>
                the calculation takes into account the company’s current
                (fully-diluted) number of shares, and not the one
                post-conversion of the convertibles/SAFE
              </li>
              <li>
                the calculation is based on the highest valuation cap, which is
                the highest valuation at which the convertibles/SAFE can convert
                (but they could also well convert at a lower company valuation)
              </li>
            </ListDisc>
          </div>
        </DrawerPane>
      </DrawerLayout>
    </Drawer>
  );
};
