/**
 * @generated SignedSource<<38389e85484d4cbf83ca1c5d5a691171>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnusedGeographies_Query$variables = Record<PropertyKey, never>;
export type UnusedGeographies_Query$data = {
  readonly organizationsNotOriginatingFromRemoteEquity: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"UnusedGeographies_Organization">;
  }>;
};
export type UnusedGeographies_Query = {
  response: UnusedGeographies_Query$data;
  variables: UnusedGeographies_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "isOriginatingFromRemoteEquity": false
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnusedGeographies_Query",
    "selections": [
      {
        "alias": "organizationsNotOriginatingFromRemoteEquity",
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnusedGeographies_Organization"
          }
        ],
        "storageKey": "organizations(filters:{\"isOriginatingFromRemoteEquity\":false})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UnusedGeographies_Query",
    "selections": [
      {
        "alias": "organizationsNotOriginatingFromRemoteEquity",
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "topCompany",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGeography",
            "kind": "LinkedField",
            "name": "organizationGeographies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasDraftsPending",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unlockedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Geography",
                "kind": "LinkedField",
                "name": "geography",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "country",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emoji",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationSpecificPrice",
                "kind": "LinkedField",
                "name": "price",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "priceAfterDiscount",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billableActiveGranteesCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settlingGranteesCount",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "organizations(filters:{\"isOriginatingFromRemoteEquity\":false})"
      }
    ]
  },
  "params": {
    "cacheID": "f5606e5ee29b9f6f4b7c09229c0a929a",
    "id": null,
    "metadata": {},
    "name": "UnusedGeographies_Query",
    "operationKind": "query",
    "text": "query UnusedGeographies_Query {\n  organizationsNotOriginatingFromRemoteEquity: organizations(filters: {isOriginatingFromRemoteEquity: false}) {\n    ...UnusedGeographies_Organization\n    id\n  }\n}\n\nfragment UnusedGeographies_Organization on Organization {\n  id\n  name\n  topCompany {\n    country {\n      code\n    }\n    id\n  }\n  organizationGeographies {\n    hasDraftsPending\n    unlockedAt\n    geography {\n      country {\n        emoji\n        name\n        code\n      }\n    }\n    price {\n      priceAfterDiscount\n      id\n    }\n    billableActiveGranteesCount\n    settlingGranteesCount\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "75d7a55c14bfa6412d26dc7a8235b235";

export default node;
