import { zodResolver } from "@hookform/resolvers/zod";
import { chain } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../hooks/useSafeMutation";
import {
  RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation,
  RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$variables,
} from "./__generated__/RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation.graphql";
import {
  RefresherGrantPlanningEntryModal_Organization$data,
  RefresherGrantPlanningEntryModal_Organization$key,
} from "./__generated__/RefresherGrantPlanningEntryModal_Organization.graphql";
import { RefresherGrantPlanningEntryModal_PlanningEntrySuggestion$key } from "./__generated__/RefresherGrantPlanningEntryModal_PlanningEntrySuggestion.graphql";
import {
  RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$data,
  RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$key,
} from "./__generated__/RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry.graphql";
import { RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation } from "./__generated__/RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation.graphql";
import { GrantedSharesInput } from "./GrantedSharesInput";
import { useToaster } from "./Toaster";
import { FormRow } from "./ui/Form/FormRow";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "./ui/Modal";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Toast } from "./ui/Toast";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RefresherGrantPlanningEntryModal_Organization on Organization {
    id
    activeGrantees: grantees(filters: { status: Active }) {
      edges {
        node {
          id
          name
        }
      }
    }
    planningEntries {
      ... on RefresherGrantPlanningEntry {
        __typename
        id
        grantee {
          id
        }
        type
      }
    }
    fullyDilutedShares
    latestPricePerShare {
      value
    }
  }
`;

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry on RefresherGrantPlanningEntry {
    id
    sharesGranted
    grantee {
      id
    }
  }
`;

const PLANNING_ENTRY_SUGGESTION_FRAGMENT = graphql`
  fragment RefresherGrantPlanningEntryModal_PlanningEntrySuggestion on PlanningEntrySuggestion {
    id
    shares
    grantee {
      id
    }
  }
`;

const CREATE_REFRESHER_GRANT_PLANNING_ENTRY_MUTATION = graphql`
  mutation RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation(
    $organizationId: OrganizationId!
    $attributes: CreateRefresherGrantPlanningEntryAttributes!
    $planningEntrySuggestionId: UUID
  ) {
    createRefresherGrantPlanningEntry(
      organizationId: $organizationId
      attributes: $attributes
      planningEntrySuggestionId: $planningEntrySuggestionId
    ) {
      __typename
    }
  }
`;

const UPDATE_REFRESHER_GRANT_PLANNING_ENTRY_MUTATION = graphql`
  mutation RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation(
    $planningEntryId: PlanningEntryId!
    $attributes: CreateRefresherGrantPlanningEntryAttributes!
  ) {
    updateRefresherGrantPlanningEntry(
      planningEntryId: $planningEntryId
      attributes: $attributes
    ) {
      __typename
    }
  }
`;

type State = {
  editedPlanningEntryFragment: null | RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$key;
  open: boolean;
  planningEntrySuggestionFragment: null | RefresherGrantPlanningEntryModal_PlanningEntrySuggestion$key;
  refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
};

export const useRefresherGrantPlanningEntryModalState = () => {
  const [state, setState] = useState<State>({
    editedPlanningEntryFragment: null,
    open: false,
    planningEntrySuggestionFragment: null,
    refresherGrantPlanningEntryType: "PROMOTION_GRANT",
  });

  const openRefresherGrantPlanningEntryModalInEditMode = useCallback(
    ({
      editedPlanningEntryFragment,
      refresherGrantPlanningEntryType,
    }: {
      editedPlanningEntryFragment: RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$key;
      refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
    }) => {
      setState({
        editedPlanningEntryFragment,
        open: true,
        planningEntrySuggestionFragment: null,
        refresherGrantPlanningEntryType,
      });
    },
    [],
  );

  const openRefresherGrantPlanningEntryModalInCreateMode = useCallback(
    ({
      planningEntrySuggestionFragment,
      refresherGrantPlanningEntryType,
    }: {
      planningEntrySuggestionFragment: null | RefresherGrantPlanningEntryModal_PlanningEntrySuggestion$key;
      refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
    }) => {
      setState({
        editedPlanningEntryFragment: null,
        open: true,
        planningEntrySuggestionFragment,
        refresherGrantPlanningEntryType,
      });
    },
    [],
  );

  const closeRefresherGrantPlanningEntryModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }));
  }, []);

  return {
    closeRefresherGrantPlanningEntryModal,
    openRefresherGrantPlanningEntryModalInCreateMode,
    openRefresherGrantPlanningEntryModalInEditMode,
    refresherGrantPlanningEntryModalState: state,
  };
};

const schema = z.object({
  granteeId: z.string(),
  sharesGranted: z.number().positive().min(1),
});

export type RefresherGrantPlanningEntryType =
  RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$variables["attributes"]["type"];
type FormValues = z.infer<typeof schema>;

const getModalTitle = ({
  editedPlanningEntry,
  refresherGrantPlanningEntryType,
}: {
  editedPlanningEntry: null | RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$data;
  refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
}) => {
  switch (refresherGrantPlanningEntryType) {
    case "EXCEPTIONAL_GRANT": {
      return editedPlanningEntry
        ? "Update exceptional grant"
        : "Plan an exceptional grant";
    }
    case "LEVELING_GRANT": {
      return editedPlanningEntry
        ? "Update leveling grant"
        : "Plan a leveling grant";
    }
    case "PROMOTION_GRANT": {
      return editedPlanningEntry
        ? "Update promotion grant"
        : "Plan a promotion grant";
    }
    case "TENURE_GRANT": {
      return editedPlanningEntry
        ? "Update tenure grant"
        : "Plan a tenure grant";
    }
  }
};

const RefresherGrantPlanningEntryAlreadyExistsForGranteeWarning: React.FC<{
  refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
}> = ({ refresherGrantPlanningEntryType }) => {
  const warning = useMemo(() => {
    switch (refresherGrantPlanningEntryType) {
      case "EXCEPTIONAL_GRANT": {
        return "This grantee is already set for a new exceptional grant in the planning.";
      }
      case "LEVELING_GRANT": {
        return "This grantee is already set for a leveling grant in the planning.";
      }
      case "PROMOTION_GRANT": {
        return "This grantee is already set for a new promotion grant in the planning.";
      }
      case "TENURE_GRANT": {
        return "This grantee is already set for a tenure grant in the planning.";
      }
    }
  }, [refresherGrantPlanningEntryType]);

  return (
    <NoticeMessage hasColor={false} size="Small" variant="Warning">
      {warning}
    </NoticeMessage>
  );
};

const ModalContent: React.FC<{
  editedPlanningEntryFragment: null | RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$key;
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organization: RefresherGrantPlanningEntryModal_Organization$data;
  planningEntrySuggestionFragment: null | RefresherGrantPlanningEntryModal_PlanningEntrySuggestion$key;
  refresherGrantPlanningEntryType: RefresherGrantPlanningEntryType;
}> = ({
  editedPlanningEntryFragment,
  onClose,
  onPlanningEntryEdited,
  organization,
  planningEntrySuggestionFragment,
  refresherGrantPlanningEntryType,
}) => {
  const editedPlanningEntry =
    useFragment(PLANNING_ENTRY_FRAGMENT, editedPlanningEntryFragment) ?? null;
  const planningEntrySuggestion =
    useFragment(
      PLANNING_ENTRY_SUGGESTION_FRAGMENT,
      planningEntrySuggestionFragment,
    ) ?? null;

  const activeGrantees = useMemo(
    () => organization.activeGrantees.edges.map((edge) => edge.node),
    [organization.activeGrantees],
  );
  const refresherGrantPlanningEntries = useMemo(
    () =>
      chain(
        organization.planningEntries.map((entry) =>
          entry.__typename === "RefresherGrantPlanningEntry" &&
          entry.type === refresherGrantPlanningEntryType
            ? entry
            : null,
        ),
      )
        .compact()
        .value(),
    [organization.planningEntries, refresherGrantPlanningEntryType],
  );
  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues: (editedPlanningEntry
      ? {
          granteeId: editedPlanningEntry.grantee.id,
          sharesGranted: editedPlanningEntry.sharesGranted,
        }
      : {
          granteeId: planningEntrySuggestion?.grantee.id ?? null,
          sharesGranted: planningEntrySuggestion?.shares ?? null,
        }) as unknown as FormValues,
    resolver: zodResolver(schema),
  });

  const [granteeId] = watch(["granteeId"]);

  const refresherGrantPlanningEntryAlreadyExistsForGrantee = useMemo(
    () =>
      refresherGrantPlanningEntries.some(
        (entry) =>
          entry.grantee.id === granteeId &&
          entry.id !== editedPlanningEntry?.id,
      ),
    [granteeId, refresherGrantPlanningEntries, editedPlanningEntry],
  );

  const [
    createRefresherGrantPlanningEntry,
    createRefresherGrantPlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation>(
      CREATE_REFRESHER_GRANT_PLANNING_ENTRY_MUTATION,
    );
  const [
    updateRefresherGrantPlanningEntry,
    updateRefresherGrantPlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<RefresherGrantPlanningEntryModal_UpdateRefresherGrantPlanningEntry_Mutation>(
      UPDATE_REFRESHER_GRANT_PLANNING_ENTRY_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (data) => {
    const attributes = schema.parse(data);
    if (editedPlanningEntry) {
      await updateRefresherGrantPlanningEntry({
        variables: {
          attributes: {
            ...attributes,
            type: refresherGrantPlanningEntryType,
          },
          planningEntryId: editedPlanningEntry.id,
        },
      });
      switch (refresherGrantPlanningEntryType) {
        case "EXCEPTIONAL_GRANT": {
          toaster.push(
            <Toast title="Great!">
              Exceptional grant successfully updated!
            </Toast>,
          );
          break;
        }
        case "LEVELING_GRANT": {
          toaster.push(
            <Toast title="Great!">Leveling grant successfully updated!</Toast>,
          );
          break;
        }
        case "PROMOTION_GRANT": {
          toaster.push(
            <Toast title="Great!">Promotion grant successfully updated!</Toast>,
          );
          break;
        }
        case "TENURE_GRANT": {
          toaster.push(
            <Toast title="Great!">Tenure grant successfully updated!</Toast>,
          );
          break;
        }
      }
      onPlanningEntryEdited?.();
    } else {
      await createRefresherGrantPlanningEntry({
        variables: {
          attributes: {
            ...attributes,
            type: refresherGrantPlanningEntryType,
          },
          organizationId: organization.id,
          planningEntrySuggestionId: planningEntrySuggestion?.id ?? null,
        },
      });

      if (planningEntrySuggestion) {
        toaster.push(
          <Toast title="Great!">
            This grant is now included your planning!
          </Toast>,
        );
      } else {
        switch (refresherGrantPlanningEntryType) {
          case "EXCEPTIONAL_GRANT": {
            toaster.push(
              <Toast title="Great!">
                New exceptional grant successfully created!
              </Toast>,
            );
            break;
          }
          case "LEVELING_GRANT": {
            toaster.push(
              <Toast title="Great!">
                New leveling grant successfully created!
              </Toast>,
            );
            break;
          }
          case "PROMOTION_GRANT": {
            toaster.push(
              <Toast title="Great!">
                New promotion grant successfully created!
              </Toast>,
            );
            break;
          }
          case "TENURE_GRANT": {
            toaster.push(
              <Toast title="Great!">
                New tenure grant successfully created!
              </Toast>,
            );
            break;
          }
        }
      }
    }

    onClose();
  });

  const title = getModalTitle({
    editedPlanningEntry,
    refresherGrantPlanningEntryType,
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.SubmitButton
          form="refresher-grant-planning-entry-form"
          loading={
            formState.isSubmitting ||
            createRefresherGrantPlanningEntryMutationIsInFlight ||
            updateRefresherGrantPlanningEntryMutationIsInFlight
          }
        >
          {editedPlanningEntry ? "Update" : "Plan"}
        </Modal.SubmitButton>
      }
      onClose={onClose}
      title={title}
    >
      <Modal.Form id="refresher-grant-planning-entry-form" onSubmit={onSubmit}>
        <div className="space-y-4">
          <FormRow.Form control={control} label="Employee" name="granteeId">
            <SelectAutocomplete.Form
              control={control}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              name="granteeId"
              options={activeGrantees}
              placeholder="Grantee"
              usePortal
            />
          </FormRow.Form>
          {refresherGrantPlanningEntryAlreadyExistsForGrantee && (
            <RefresherGrantPlanningEntryAlreadyExistsForGranteeWarning
              refresherGrantPlanningEntryType={refresherGrantPlanningEntryType}
            />
          )}
        </div>
        <FormRow.Form
          control={control}
          label="Quantity granted"
          name="sharesGranted"
        >
          <GrantedSharesInput.Form
            control={control}
            fullyDilutedShares={organization.fullyDilutedShares}
            latestPricePerShare={
              organization.latestPricePerShare?.value ?? null
            }
            name="sharesGranted"
            placeholder="Amount"
          />
        </FormRow.Form>
      </Modal.Form>
    </Modal.Content>
  );
};

export const RefresherGrantPlanningEntryModal: React.FC<{
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organizationFragment: RefresherGrantPlanningEntryModal_Organization$key;
  state: State;
}> = ({ onClose, onPlanningEntryEdited, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Modal dataCy="refresher-entry-modal" onClose={onClose} show={state.open}>
      <ModalContent
        editedPlanningEntryFragment={state.editedPlanningEntryFragment}
        onClose={onClose}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organization={organization}
        planningEntrySuggestionFragment={state.planningEntrySuggestionFragment}
        refresherGrantPlanningEntryType={state.refresherGrantPlanningEntryType}
      />
    </Modal>
  );
};
