import { useOutletContext } from "react-router-dom";

import { SectionLabel } from "./SECTIONS";

export interface RemoteLikeGranteePortalLayoutOutletContext {
  containerHeight: number;
  containerScrollY: number;
  setActiveSection: (sectionLabel: SectionLabel) => void;
}

export function useRemoteLikeGranteePortalLayoutOutletContext() {
  return useOutletContext<RemoteLikeGranteePortalLayoutOutletContext>();
}
