/**
 * @generated SignedSource<<a201a2dca9e85651ddb1f41257382bef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeAdminPortalContentSlot = "contractorDetailedAdminPortalContentId" | "contractorOverviewAdminPortalContentId" | "contractorReportingGranteePortalContentId" | "contractorTaxationGranteePortalContentId" | "directEmployeeDetailedAdminPortalContentId" | "directEmployeeOverviewAdminPortalContentId" | "directEmployeeReportingGranteePortalContentId" | "directEmployeeTaxationGranteePortalContentId" | "eoREmployeeDetailedAdminPortalContentId" | "eoREmployeeOverviewAdminPortalContentId" | "eoREmployeeReportingGranteePortalContentId" | "eoREmployeeTaxationGranteePortalContentId";
export type EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation$variables = {
  content: string;
  equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot;
  equityTypeId: string;
};
export type EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation$data = {
  readonly saveEquityTypeAdminPortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  };
};
export type EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation = {
  response: EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation$data;
  variables: EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityTypeAdminPortalContentSlot"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityTypeId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "content",
    "variableName": "content"
  },
  {
    "kind": "Variable",
    "name": "equityTypeAdminPortalContentSlot",
    "variableName": "equityTypeAdminPortalContentSlot"
  },
  {
    "kind": "Variable",
    "name": "equityTypeId",
    "variableName": "equityTypeId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AdminPortalContent",
        "kind": "LinkedField",
        "name": "saveEquityTypeAdminPortalContent",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AdminPortalContent_AdminPortalContent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AdminPortalContent",
        "kind": "LinkedField",
        "name": "saveEquityTypeAdminPortalContent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "content",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d0424722108bbf5b8e94884a7ade6899",
    "id": null,
    "metadata": {},
    "name": "EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityTypeWorkRelationshipDetailed_SaveAdminPortalContent_Mutation(\n  $equityTypeId: String!\n  $equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot!\n  $content: String!\n) {\n  saveEquityTypeAdminPortalContent(equityTypeId: $equityTypeId, equityTypeAdminPortalContentSlot: $equityTypeAdminPortalContentSlot, content: $content) {\n    ...AdminPortalContent_AdminPortalContent\n  }\n}\n\nfragment AdminPortalContent_AdminPortalContent on AdminPortalContent {\n  content\n  lastModifiedAt\n}\n"
  }
};
})();

(node as any).hash = "8c0364d52c25166e896cfeb2defdb4b5";

export default node;
