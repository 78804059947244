/**
 * @generated SignedSource<<f80e992d3e0722e66e1733be0229058b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafeCalculatorEmptyPlaceholder_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "SafeCalculatorEmptyPlaceholder_Account";
};
export type SafeCalculatorEmptyPlaceholder_Account$key = {
  readonly " $data"?: SafeCalculatorEmptyPlaceholder_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"SafeCalculatorEmptyPlaceholder_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SafeCalculatorEmptyPlaceholder_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "ded344dd5ea95c5f3d8c341339a0e174";

export default node;
