import { Link } from "react-router-dom";

import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import ErrorPage from "./Error/Error";

export default function MaintenanceInProgressErrorPage() {
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const applicationName = useApplicationName();

  return (
    <ErrorPage
      action={
        <Link className="text-primary" to={`mailto:${supportEmailAddress}`}>
          Contact us
        </Link>
      }
      subtitle={
        <>
          {applicationName} is down for maintenance.
          <br />
          We should be back in a few minutes.
        </>
      }
      title="Sorry!"
    />
  );
}
