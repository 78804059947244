import { Text } from "@remote-com/norma";
import classNames from "classnames";

export const RemoteLikeInfoBlock: React.FC<{
  info: {
    key?: string;
    label: string;
    value?: null | React.ReactNode;
  }[];
}> = ({ info }) => {
  return (
    <div className="divide-y-[0.5px] divide-grey-300 @container">
      {info.map(({ label, key = label, value }) => (
        <div
          className={classNames(
            "gap-x-10 gap-y-1",
            "flex flex-col py-2",
            "@md:grid @md:h-12 @md:grid-cols-[1fr_2fr] @md:items-center @md:py-0",
          )}
          key={key}
        >
          <Text variant="smMedium">{label}</Text>
          <Text className="text-grey-600" variant="sm">
            {value ?? "-"}
          </Text>
        </div>
      ))}
    </div>
  );
};
