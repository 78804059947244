/**
 * @generated SignedSource<<7cdc3bb9b6b30b12cb8e62d81adbf53c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsDraftsList_Grantee$data = {
  readonly drafts: ReadonlyArray<{
    readonly id: string;
    readonly label: string;
    readonly quantityGranted: number;
  }>;
  readonly " $fragmentType": "GranteeDetailsDraftsList_Grantee";
};
export type GranteeDetailsDraftsList_Grantee$key = {
  readonly " $data"?: GranteeDetailsDraftsList_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsDraftsList_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsDraftsList_Grantee",
  "selections": [
    {
      "alias": "drafts",
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrantsWithoutCtmsGrant",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityGranted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "821b2eeb1fa7208b99a3bc0538bc88de";

export default node;
