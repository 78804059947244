import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { VestingSchedules_Organization$key } from "./__generated__/VestingSchedules_Organization.graphql";
import { VestingSchedules_Query } from "./__generated__/VestingSchedules_Query.graphql";
import { VestingSchedules_Viewer$key } from "./__generated__/VestingSchedules_Viewer.graphql";
import { VestingSchedulesGraph } from "./VestingSchedulesGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment VestingSchedules_Organization on Organization {
    id
    name
    ...VestingSchedulesGraph_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment VestingSchedules_Viewer on Account {
    ...VestingSchedulesGraph_Viewer
  }
`;

const AdminEquityGridLevelsPage_: React.FC<{
  organizationFragment: VestingSchedules_Organization$key;
  viewerFragment: VestingSchedules_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <InsightPage
      subtitle="Split of different vesting schedules used and number of grants associated"
      title="⏳ Vesting schedules"
    >
      <VestingSchedulesGraph
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query VestingSchedules_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...VestingSchedules_Organization
    }
    me {
      ...VestingSchedules_Viewer
    }
  }
`;

const AdminInsightsVestingSchedulesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { me, organization } = useLazyLoadQuery<VestingSchedules_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Vesting schedules"
      analyticsName="Admin - Insights - Vesting Schedules"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | vesting schedules`}
    >
      <AdminEquityGridLevelsPage_
        organizationFragment={organization}
        viewerFragment={me}
      />
    </Page>
  );
};

export default AdminInsightsVestingSchedulesPage;
