/**
 * @generated SignedSource<<186f97d713dca6e9a818325e6d8d5cf4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningEntrySuggestionType = "LEVELING_GRANT" | "TENURE_GRANT";
export type Ignored_Planning_Query$variables = {
  organizationId: string;
};
export type Ignored_Planning_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly ignoredPlanningEntrySuggestions: ReadonlyArray<{
      readonly id: string;
      readonly type: PlanningEntrySuggestionType;
      readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsTable_PlanningEntrySuggestion" | "RefresherGrantPlanningEntryModal_PlanningEntrySuggestion">;
    }>;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PlanningEntrySuggestionsTable_Organization" | "RefresherGrantPlanningEntryModal_Organization">;
  };
};
export type Ignored_Planning_Query = {
  response: Ignored_Planning_Query$data;
  variables: Ignored_Planning_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Ignored_Planning_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PlanningEntrySuggestion",
              "kind": "LinkedField",
              "name": "ignoredPlanningEntrySuggestions",
              "plural": true,
              "selections": [
                (v2/*: any*/),
                (v4/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PlanningEntrySuggestionsTable_PlanningEntrySuggestion"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RefresherGrantPlanningEntryModal_PlanningEntrySuggestion"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PlanningEntrySuggestionsTable_Organization"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RefresherGrantPlanningEntryModal_Organization"
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Ignored_Planning_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PlanningEntrySuggestion",
            "kind": "LinkedField",
            "name": "ignoredPlanningEntrySuggestions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shares",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Grantee",
                "kind": "LinkedField",
                "name": "grantee",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "taxResidenceCountry",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emoji",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PricePerShare",
            "kind": "LinkedField",
            "name": "latestPricePerShare",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullyDilutedShares",
            "storageKey": null
          },
          {
            "alias": "activeGrantees",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "status": "Active"
                }
              }
            ],
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "grantees(filters:{\"status\":\"Active\"})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "planningEntries",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "type": "RefresherGrantPlanningEntry",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "335bc0fc012712600dc82088713f0095",
    "id": null,
    "metadata": {},
    "name": "Ignored_Planning_Query",
    "operationKind": "query",
    "text": "query Ignored_Planning_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ignoredPlanningEntrySuggestions {\n      id\n      type\n      ...PlanningEntrySuggestionsTable_PlanningEntrySuggestion\n      ...RefresherGrantPlanningEntryModal_PlanningEntrySuggestion\n    }\n    ...PlanningEntrySuggestionsTable_Organization\n    ...RefresherGrantPlanningEntryModal_Organization\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment PlanningEntrySuggestionsTable_Organization on Organization {\n  ...useOrganizationSharesUtil_Organization\n}\n\nfragment PlanningEntrySuggestionsTable_PlanningEntrySuggestion on PlanningEntrySuggestion {\n  id\n  type\n  shares\n  grantee {\n    ...GranteeNameWithCountryFlag_Grantee\n    id\n  }\n}\n\nfragment RefresherGrantPlanningEntryModal_Organization on Organization {\n  id\n  activeGrantees: grantees(filters: {status: Active}) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  planningEntries {\n    __typename\n    ... on RefresherGrantPlanningEntry {\n      __typename\n      id\n      grantee {\n        id\n      }\n      type\n    }\n    id\n  }\n  fullyDilutedShares\n  latestPricePerShare {\n    value\n    id\n  }\n}\n\nfragment RefresherGrantPlanningEntryModal_PlanningEntrySuggestion on PlanningEntrySuggestion {\n  id\n  shares\n  grantee {\n    id\n  }\n}\n\nfragment useOrganizationSharesUtil_Organization on Organization {\n  latestPricePerShare {\n    value\n    id\n  }\n  fullyDilutedShares\n}\n"
  }
};
})();

(node as any).hash = "1a5d6dac7fcdb394c9671f7c13a8f39e";

export default node;
