/**
 * @generated SignedSource<<495cd02b22b7a2a363573906ca0fb933>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberApprovalsTables_SignatureRequests$data = ReadonlyArray<{
  readonly boardConsent: {
    readonly __typename: string;
    readonly completedAt: string | null;
    readonly ctmsGrantAmendmentRequestsCount?: number;
    readonly fairMarketValue?: {
      readonly valuationFirm: string | null;
      readonly value: number;
    };
    readonly grantCount?: number;
    readonly granteeTerminationCTMSGrantAmendmentRequestCount?: number;
    readonly missingSignaturesCount: number;
    readonly signatureRequestsCount: number;
    readonly signedDocument: {
      readonly downloadUrl: string;
    } | null;
    readonly totalGrantedShares?: number;
  };
  readonly completedAt: string | null;
  readonly id: string;
  readonly " $fragmentType": "BoardMemberApprovalsTables_SignatureRequests";
}>;
export type BoardMemberApprovalsTables_SignatureRequests$key = ReadonlyArray<{
  readonly " $data"?: BoardMemberApprovalsTables_SignatureRequests$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberApprovalsTables_SignatureRequests">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "BoardMemberApprovalsTables_SignatureRequests",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grantCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalGrantedShares",
              "storageKey": null
            }
          ],
          "type": "GrantBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "granteeTerminationCTMSGrantAmendmentRequestCount",
              "storageKey": null
            }
          ],
          "type": "GranteeTerminationGrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctmsGrantAmendmentRequestsCount",
              "storageKey": null
            }
          ],
          "type": "GrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FairMarketValue",
              "kind": "LinkedField",
              "name": "fairMarketValue",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "valuationFirm",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "FairMarketValueBoardConsent",
          "abstractKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "missingSignaturesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signatureRequestsCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Document",
          "kind": "LinkedField",
          "name": "signedDocument",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "downloadUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SignatureRequest",
  "abstractKey": null
};
})();

(node as any).hash = "97f9644b1e0f97142f3d1b674b4a05ff";

export default node;
