/**
 * @generated SignedSource<<063e8a863858007b472c851de32b32a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoView_Account$data = {
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly " $fragmentType": "BookADemoView_Account";
};
export type BookADemoView_Account$key = {
  readonly " $data"?: BookADemoView_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoView_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoView_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "d2fd00199458c37136da76bea6c118ea";

export default node;
