/**
 * @generated SignedSource<<1f70a7fed1d142e254d154e35639b4c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationship_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipDetailed_Viewer" | "EquityTypeWorkRelationshipGranteePortal_Viewer" | "EquityTypeWorkRelationshipOverview_Viewer">;
  readonly " $fragmentType": "EquityTypeWorkRelationship_Viewer";
};
export type EquityTypeWorkRelationship_Viewer$key = {
  readonly " $data"?: EquityTypeWorkRelationship_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationship_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationship_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipOverview_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipDetailed_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypeWorkRelationshipGranteePortal_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f482788269ab6ef05d49c799ad75a366";

export default node;
