/**
 * @generated SignedSource<<e0bfdc140f68e2b33e7098b146dc8f26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinishRemoteEquityOnboardingPage_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ConnectCTMSView_Viewer">;
  readonly " $fragmentType": "FinishRemoteEquityOnboardingPage_Viewer";
};
export type FinishRemoteEquityOnboardingPage_Viewer$key = {
  readonly " $data"?: FinishRemoteEquityOnboardingPage_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinishRemoteEquityOnboardingPage_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinishRemoteEquityOnboardingPage_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectCTMSView_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "74ae61b0e5d8af22efa67f7631168120";

export default node;
