import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useQuery } from "../../../../hooks/useQuery";
import {
  useEquityTypeIdParam,
  useEquityTypeWorkRelationshipCategoryParam,
  useOrganizationIdParam,
} from "../../../../paths";
import {
  EQUITY_TYPE_WORK_RELATIONSHIP_MAP,
  EquityTypeWorkRelationshipData,
} from "../../../../services/workRelationship";
import NotFoundPage from "../../../NotFound/NotFound";
import { EquityTypeWorkRelationshipDocuments_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipDocuments_EquityType.graphql";
import { EquityTypeWorkRelationshipDocuments_Organization$key } from "./__generated__/EquityTypeWorkRelationshipDocuments_Organization.graphql";
import { EquityTypeWorkRelationshipDocuments_Query } from "./__generated__/EquityTypeWorkRelationshipDocuments_Query.graphql";
import EquityTypeWorkRelationshipDocumentSet from "./EquityTypeWorkRelationshipDocumentSet";

const EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_ORGANIZATION_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDocuments_Organization on Organization {
    ...EquityTypeWorkRelationshipDocumentSet_Organization
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipDocuments_EquityType on EquityType {
    ...EquityTypeWorkRelationshipDocumentSet_EquityType
    instruments {
      id
      workRelationship
      ...EquityTypeWorkRelationshipDocumentSet_Instrument
    }
  }
`;
const EquityTypeWorkRelationshipDocuments_: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipDocuments_EquityType$key;
  organizationFragment: EquityTypeWorkRelationshipDocuments_Organization$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({ equityTypeFragment, organizationFragment, workRelationshipData }) => {
  const organization = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_DOCUMENTS_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const instruments = equityType.instruments.filter((instrument) =>
    workRelationshipData.types.includes(instrument.workRelationship),
  );

  return (
    <div className="mt-6 space-y-6 divide-y-[0.5px] divide-gray-04">
      {instruments.map((instrument) => (
        <EquityTypeWorkRelationshipDocumentSet
          equityTypeFragment={equityType}
          instrumentFragment={instrument}
          key={instrument.id}
          organizationFragment={organization}
        />
      ))}
    </div>
  );
};

const QUERY = graphql`
  query EquityTypeWorkRelationshipDocuments_Query(
    $organizationId: OrganizationId!
    $equityTypeId: UUID!
  ) {
    organization(id: $organizationId) {
      ...EquityTypeWorkRelationshipDocuments_Organization
    }
    equityType(id: $equityTypeId) {
      ...EquityTypeWorkRelationshipDocuments_EquityType
    }
  }
`;

const EquityTypeWorkRelationshipDocuments: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const equityTypeId = useEquityTypeIdParam();
  const equityTypeWorkRelationshipCategory =
    useEquityTypeWorkRelationshipCategoryParam();

  const { query } = useQuery<EquityTypeWorkRelationshipDocuments_Query>(QUERY, {
    equityTypeId,
    organizationId,
  });

  if (!query.organization || !query.equityType) {
    return <NotFoundPage />;
  }

  const equityTypeWorkRelationshipData =
    EQUITY_TYPE_WORK_RELATIONSHIP_MAP[equityTypeWorkRelationshipCategory];

  return (
    <EquityTypeWorkRelationshipDocuments_
      equityTypeFragment={query.equityType}
      organizationFragment={query.organization}
      workRelationshipData={equityTypeWorkRelationshipData}
    />
  );
};

export default EquityTypeWorkRelationshipDocuments;
