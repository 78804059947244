/**
 * @generated SignedSource<<46044cb19f6edd6715d7cad6c83e705e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation$variables = {
  id: string;
};
export type GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation$data = {
  readonly deleteGranteeTerminationCTMSGrantAmendmentRequests: {
    readonly __typename: "Organization";
  };
};
export type GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation = {
  response: GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation$data;
  variables: GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "items": [
      {
        "kind": "Variable",
        "name": "granteeTerminationCTMSGrantAmendmentRequestIds.0",
        "variableName": "id"
      }
    ],
    "kind": "ListValue",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteGranteeTerminationCTMSGrantAmendmentRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteGranteeTerminationCTMSGrantAmendmentRequests",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2837340b280b24c7c2d98ad99d8b4676",
    "id": null,
    "metadata": {},
    "name": "GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteeTerminationAmendmentRequest_DeleteGrantAmendment_Mutation(\n  $id: UUID!\n) {\n  deleteGranteeTerminationCTMSGrantAmendmentRequests(granteeTerminationCTMSGrantAmendmentRequestIds: [$id]) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b1039e8efff5886b75071a67136898d2";

export default node;
