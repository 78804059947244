/**
 * @generated SignedSource<<8680fd432766fd453fe53bf89f1289ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation$variables = {
  organizationName: string;
};
export type CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation$data = {
  readonly createSandboxOrganization: {
    readonly id: string;
  };
};
export type CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation = {
  response: CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation$data;
  variables: CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationName"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationName",
        "variableName": "organizationName"
      }
    ],
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "createSandboxOrganization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e16ed78f53aa99cec5a32175fee5e897",
    "id": null,
    "metadata": {},
    "name": "CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation(\n  $organizationName: String!\n) {\n  createSandboxOrganization(organizationName: $organizationName) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a195d9dad9020b984a8ac2cb985f84fe";

export default node;
