import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { useDebouncedSafeMutation } from "../../hooks/useDebouncedSafeMutation";
import { FormRow } from "../ui/Form/FormRow";
import { TextArea } from "../ui/Form/Inputs/TextArea";
import { BoardNoteSection_Organization$key } from "./__generated__/BoardNoteSection_Organization.graphql";
import { BoardNoteSection_UpdateOrganizationPendingCeoNote_Mutation } from "./__generated__/BoardNoteSection_UpdateOrganizationPendingCeoNote_Mutation.graphql";
import { BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation } from "./__generated__/BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation.graphql";
import { BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation } from "./__generated__/BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation.graphql";
import { BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation } from "./__generated__/BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation.graphql";
import { Section } from "./Section";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardNoteSection_Organization on Organization {
    id
    pendingCeoNote
    pendingGrantAmendmentCeoNote
    pendingGranteeTerminationGrantAmendmentCeoNote
    pendingFairMarketValueCeoNote
  }
`;

const UPDATE_ORGANIZATION_PENDING_CEO_NOTE_MUTATION = graphql`
  mutation BoardNoteSection_UpdateOrganizationPendingCeoNote_Mutation(
    $organizationId: OrganizationId!
    $ceoNote: String!
  ) {
    updateOrganizationPendingCeoNote(
      id: $organizationId
      pendingCeoNote: $ceoNote
    ) {
      pendingCeoNote
    }
  }
`;

const UPDATE_ORGANIZATION_PENDING_GRANT_AMENDMENT_CEO_NOTE_MUTATION = graphql`
  mutation BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation(
    $organizationId: OrganizationId!
    $ceoNote: String!
  ) {
    updateOrganizationPendingGrantAmendmentCeoNote(
      organizationId: $organizationId
      ceoNote: $ceoNote
    ) {
      pendingGrantAmendmentCeoNote
    }
  }
`;

const UPDATE_ORGANIZATION_PENDING_GRANTEE_TERMINATION_GRANT_AMENDMENT_CEO_NOTE_MUTATION = graphql`
  mutation BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation(
    $organizationId: OrganizationId!
    $ceoNote: String!
  ) {
    updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote(
      id: $organizationId
      ceoNote: $ceoNote
    ) {
      pendingGranteeTerminationGrantAmendmentCeoNote
    }
  }
`;

const UPDATE_ORGANIZATION_PENDING_FAIR_MARKET_VALUE_CEO_NOTE_MUTATION = graphql`
  mutation BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation(
    $organizationId: OrganizationId!
    $ceoNote: String!
  ) {
    updateOrganizationPendingFairMarketValueCeoNote(
      organizationId: $organizationId
      ceoNote: $ceoNote
    ) {
      pendingFairMarketValueCeoNote
    }
  }
`;

const formSchema = z.object({
  ceoNote: z.string(),
});

export function BoardNoteSection({
  organizationFragment,
  type,
}: {
  organizationFragment: BoardNoteSection_Organization$key;
  type:
    | "FAIR_MARKET_VALUE"
    | "GRANT"
    | "GRANT_AMENDMENT_REQUEST"
    | "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const ceoNote = useMemo(() => {
    switch (type) {
      case "FAIR_MARKET_VALUE":
        return organization.pendingFairMarketValueCeoNote;
      case "GRANT":
        return organization.pendingCeoNote;
      case "GRANT_AMENDMENT_REQUEST":
        return organization.pendingGrantAmendmentCeoNote;
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return organization.pendingGranteeTerminationGrantAmendmentCeoNote;
    }
  }, [organization, type]);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { ceoNote: ceoNote ?? undefined },
    resolver: zodResolver(formSchema),
  });

  const [updateOrganizationPendingCeoNote] =
    useDebouncedSafeMutation<BoardNoteSection_UpdateOrganizationPendingCeoNote_Mutation>(
      UPDATE_ORGANIZATION_PENDING_CEO_NOTE_MUTATION,
    );
  const [updateOrganizationPendingGrantAmendmentCeoNote] =
    useDebouncedSafeMutation<BoardNoteSection_UpdateOrganizationPendingGrantAmendmentCeoNote_Mutation>(
      UPDATE_ORGANIZATION_PENDING_GRANT_AMENDMENT_CEO_NOTE_MUTATION,
    );
  const [updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote] =
    useDebouncedSafeMutation<BoardNoteSection_UpdateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote_Mutation>(
      UPDATE_ORGANIZATION_PENDING_GRANTEE_TERMINATION_GRANT_AMENDMENT_CEO_NOTE_MUTATION,
    );
  const [updateOrganizationPendingFairMarketValueCeoNote] =
    useDebouncedSafeMutation<BoardNoteSection_UpdateOrganizationPendingFairMarketValueCeoNote_Mutation>(
      UPDATE_ORGANIZATION_PENDING_FAIR_MARKET_VALUE_CEO_NOTE_MUTATION,
    );

  const onSubmit = handleSubmit(async (data) => {
    const validatedData = formSchema.parse(data);
    switch (type) {
      case "FAIR_MARKET_VALUE":
        return updateOrganizationPendingFairMarketValueCeoNote({
          variables: {
            ceoNote: validatedData.ceoNote,
            organizationId: organization.id,
          },
        });
      case "GRANT":
        return updateOrganizationPendingCeoNote({
          variables: {
            ceoNote: validatedData.ceoNote,
            organizationId: organization.id,
          },
        });
      case "GRANT_AMENDMENT_REQUEST":
        return updateOrganizationPendingGrantAmendmentCeoNote({
          variables: {
            ceoNote: validatedData.ceoNote,
            organizationId: organization.id,
          },
        });
      case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
        return updateOrganizationPendingGranteeTerminationGrantAmendmentCeoNote(
          {
            variables: {
              ceoNote: validatedData.ceoNote,
              organizationId: organization.id,
            },
          },
        );
    }
  });

  watch(() => {
    void onSubmit();
  });

  return (
    <Section
      subtitle={
        <>
          Enter a board note that will be attached to the board approval request
        </>
      }
      title="Add a board note"
    >
      <FormRow.Form control={control} name="ceoNote">
        <TextArea.Form
          className="min-h-[189px]"
          control={control}
          name="ceoNote"
          placeholder="John is a junior developer..."
        />
      </FormRow.Form>
    </Section>
  );
}
