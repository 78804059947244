import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { differenceInDays, format } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import {
  LoadMoreGrantsSlideOver,
  LoadMoreGrantsSlideOverState,
} from "../../../../components/LoadMoreGrantsSlideOver";
import { Tag } from "../../../../components/ui/Tag";
import { Typography } from "../../../../components/ui/Typography";
import { checkPropertiesNotNull } from "../../../../helpers/ts-utlity";
import { APPLICATION_ROUTES } from "../../../../paths";
import { CliffPeriodsSlideOver_OrganizationCTMSGrantsPagination_Query } from "./__generated__/CliffPeriodsSlideOver_OrganizationCTMSGrantsPagination_Query.graphql";
import {
  CliffPeriodsSlideOver_Query,
  CliffPeriodsSlideOver_Query$data,
} from "./__generated__/CliffPeriodsSlideOver_Query.graphql";
import {
  CliffPeriodsSlideOver_Refetchable_Organization$data,
  CliffPeriodsSlideOver_Refetchable_Organization$key,
} from "./__generated__/CliffPeriodsSlideOver_Refetchable_Organization.graphql";

const ORGANIZATION_REFETCHABLE_FRAGMENT = graphql`
  fragment CliffPeriodsSlideOver_Refetchable_Organization on Organization
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    filters: { type: "OrganizationCTMSGrantsFilters" }
  )
  @refetchable(
    queryName: "CliffPeriodsSlideOver_OrganizationCTMSGrantsPagination_Query"
  ) {
    ctmsGrants(first: $count, after: $cursor, filters: $filters)
      @connection(key: "CTMSGrants_Organization_ctmsGrants") {
      totalCount
      edges {
        node {
          id
          label
          firstVestingEventDate
          grantee {
            id
            name
            email
          }
        }
      }
    }
  }
`;

const QUERY = graphql`
  query CliffPeriodsSlideOver_Query(
    $filters: OrganizationCTMSGrantsFilters
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      ...CliffPeriodsSlideOver_Refetchable_Organization
        @arguments(filters: $filters)
    }
  }
`;

const Cell: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div
      className={classNames(
        "table-cell align-middle",
        "py-2 first:pl-2 last:pr-2",
        "border-y-[0.5px] first:border-l-[0.5px] last:border-r-[0.5px]",
        "border-transparent transition-all group-hover:border-gray-04",
        "first:rounded-l last:rounded-r",
      )}
    >
      {children}
    </div>
  );
};

const Row: React.FC<{
  grant: CliffPeriodsSlideOver_Refetchable_Organization$data["ctmsGrants"]["edges"][number]["node"];
  organization: CliffPeriodsSlideOver_Query$data["organization"];
}> = ({ grant, organization }) => {
  const now = useMemo(() => format(new Date(), "yyyy-MM-dd"), []);
  const differenceInDaysFromNow = useCallback(
    (date: string) => {
      const formattedDate = format(new Date(date), "yyyy-MM-dd");
      return differenceInDays(new Date(formattedDate), new Date(now));
    },
    [now],
  );

  return (
    <Link
      className="group table-row cursor-pointer"
      to={generatePath(APPLICATION_ROUTES["organizationGrantee"], {
        granteeId: grant.grantee.id,
        organizationId: organization.id,
      })}
    >
      <Cell>
        <Tag color="gray" size="Small">
          {grant.label}
        </Tag>
      </Cell>
      <Cell>
        <div className="w-[300px] space-y-1">
          <Typography as="div" variant="Regular/Extra Small">
            {grant.grantee.name}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Caption"
          >
            {grant.grantee.email}
          </Typography>
        </div>
      </Cell>
      {checkPropertiesNotNull(grant, "firstVestingEventDate") ? (
        <Cell>
          <Tag color="gray" size="Small">
            <FormattedNumber
              style="unit"
              unit="day"
              unitDisplay="long"
              value={differenceInDaysFromNow(grant.firstVestingEventDate)}
            />
          </Tag>
        </Cell>
      ) : null}
      <Cell>
        <div className="w-5 shrink-0 px-4">
          <ArrowTopRightOnSquareIcon className="h-5 text-primary opacity-0 transition-all group-hover:opacity-100" />
        </div>
      </Cell>
    </Link>
  );
};

const CliffPeriodsSlideOverContent: React.FC<{
  organizationId: string;
  state: LoadMoreGrantsSlideOverState;
}> = ({ organizationId, state }) => {
  const { organization } = useLazyLoadQuery<CliffPeriodsSlideOver_Query>(
    QUERY,
    {
      filters: {
        idIn: state.ctmsGrantsIds,
      },
      organizationId,
    },
  );

  const {
    data: { ctmsGrants },
    hasNext,
    isLoadingNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    CliffPeriodsSlideOver_OrganizationCTMSGrantsPagination_Query,
    CliffPeriodsSlideOver_Refetchable_Organization$key
  >(ORGANIZATION_REFETCHABLE_FRAGMENT, organization);

  const handleSearchChange = useCallback(
    (search: string) => {
      refetch({
        filters: {
          idIn: state.ctmsGrantsIds,
          search: isEmpty(search) ? null : search,
        },
      });
    },
    [refetch, state.ctmsGrantsIds],
  );

  return (
    <LoadMoreGrantsSlideOver.Layout
      onSearchChange={handleSearchChange}
      state={state}
    >
      <LoadMoreGrantsSlideOver.GrantsList
        hasNext={hasNext}
        loading={isLoadingNext}
        noResult={ctmsGrants.totalCount === 0}
        onLoadMoreClick={() => loadNext(20)}
      >
        <div className="table w-full border-separate border-spacing-0">
          <div className="table-row-group">
            {ctmsGrants.edges.map(({ node: grant }) => (
              <Row grant={grant} key={grant.id} organization={organization} />
            ))}
          </div>
        </div>
      </LoadMoreGrantsSlideOver.GrantsList>
    </LoadMoreGrantsSlideOver.Layout>
  );
};

export const CliffPeriodsSlideOver: React.FC<{
  onClose: () => void;
  organizationId: string;
  state: LoadMoreGrantsSlideOverState;
}> = ({ onClose, organizationId, state }) => {
  return (
    <LoadMoreGrantsSlideOver onClose={onClose} state={state}>
      <CliffPeriodsSlideOverContent
        organizationId={organizationId}
        state={state}
      />
    </LoadMoreGrantsSlideOver>
  );
};
