/**
 * @generated SignedSource<<fe3e167ef37f66a5662de51915588e24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Implementation_CTMSGrantAmendmentRequests_Query$variables = {
  organizationId: string;
};
export type Implementation_CTMSGrantAmendmentRequests_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"Implementation_CTMSGrantAmendmentRequests_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"Implementation_CTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type Implementation_CTMSGrantAmendmentRequests_Query = {
  response: Implementation_CTMSGrantAmendmentRequests_Query$data;
  variables: Implementation_CTMSGrantAmendmentRequests_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "IMPLEMENTATION"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Implementation_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Implementation_CTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Implementation_CTMSGrantAmendmentRequests_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Implementation_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "implementationCTMSGrantAmendmentRequests",
            "args": [
              (v4/*: any*/)
            ],
            "concreteType": "CTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "ctmsGrantAmendmentRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
          },
          {
            "alias": "searchedImplementationCTMSGrantAmendmentRequests",
            "args": [
              {
                "kind": "Literal",
                "name": "search",
                "value": ""
              },
              (v4/*: any*/)
            ],
            "concreteType": "CTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "ctmsGrantAmendmentRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isUnfavorable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeletable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CTMSGrant",
                "kind": "LinkedField",
                "name": "ctmsGrant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "taxResidenceCountry",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emoji",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ctmsGrantAmendmentRequests(search:\"\",statusIn:[\"IMPLEMENTATION\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f64c895dfbfaabbfb1170633930016c",
    "id": null,
    "metadata": {},
    "name": "Implementation_CTMSGrantAmendmentRequests_Query",
    "operationKind": "query",
    "text": "query Implementation_CTMSGrantAmendmentRequests_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...Implementation_CTMSGrantAmendmentRequests_Organization\n  }\n  me {\n    ...Implementation_CTMSGrantAmendmentRequests_Account\n    id\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  id\n  status\n  isUnfavorable\n  isDeletable\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment Implementation_CTMSGrantAmendmentRequests_Account on Account {\n  isSuperAdmin\n}\n\nfragment Implementation_CTMSGrantAmendmentRequests_Organization on Organization {\n  id\n  name\n  implementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [IMPLEMENTATION]) {\n    id\n  }\n  searchedImplementationCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [IMPLEMENTATION], search: \"\") {\n    ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n    id\n  }\n  ...LegalWorkflowLayout_Organization\n  ...CTMSGrantAmendmentRequestsTable_Organization\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n"
  }
};
})();

(node as any).hash = "2bdabcd6ca2678423a8687d034b2e117";

export default node;
