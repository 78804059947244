import classNames from "classnames";

import { Typography } from "../../../components/ui/Typography";

export const CheckboxFormRow = ({
  checkbox,
  className,
  label,
  variant = "Regular/Extra Small",
}: {
  checkbox: React.ReactNode;
  className?: string;
  label: React.ReactNode;
  variant?: React.ComponentProps<typeof Typography>["variant"];
}) => {
  return (
    <label
      className={classNames(
        "flex cursor-pointer items-center gap-2",
        className,
      )}
    >
      {checkbox}
      <Typography className="select-none" variant={variant}>
        {label}
      </Typography>
    </label>
  );
};
