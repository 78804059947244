/**
 * @generated SignedSource<<9d32a432c82e67e8d9c4328efe075f75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewEarlyExerciseAllowedSlider_CTMSGrant$data = {
  readonly earlyExercise: boolean;
  readonly id: string;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_Rule100KWatchout_EquityType" | "GrantWatchouts_Rule83BWatchout_EquityType">;
    } | null;
    readonly isEarlyExerciseBeneficial: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialNoticeMessage_Instrument">;
  } | null;
  readonly " $fragmentType": "NewEarlyExerciseAllowedSlider_CTMSGrant";
};
export type NewEarlyExerciseAllowedSlider_CTMSGrant$key = {
  readonly " $data"?: NewEarlyExerciseAllowedSlider_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewEarlyExerciseAllowedSlider_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewEarlyExerciseAllowedSlider_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEarlyExerciseBeneficial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_Rule83BWatchout_EquityType"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_Rule100KWatchout_EquityType"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EarlyExerciseBeneficialNoticeMessage_Instrument"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "01fae542986213fa95761b8aff654564";

export default node;
