import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { useAsync, useDebounce } from "react-use";
import { z } from "zod";

import { Page } from "../../components/Page";
import { Button } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { InfoCard } from "../../components/ui/InfoCard";
import { LoadingSpinner } from "../../components/ui/LoadingSpinner";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import { PasswordInput } from "../../components/ui/PasswordInput";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../paths";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";
import NotFoundPage from "../NotFound/NotFound";
import PasswordResetTokenExpiredCard from "./PasswordResetTokenExpiredCard";
import {
  PasswordStrengthInfoCard,
  passwordValidationSchema,
  validatePasswordStrength,
} from "./PasswordStrength";

const formSchema = z.object({
  password: passwordValidationSchema(),
});

export type FormInputs = z.infer<typeof formSchema>;

interface Props {
  email: string;
  passwordResetToken: string;
}

const UpdatePasswordPage_ = ({ email, passwordResetToken }: Props) => {
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      password: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = form.handleSubmit(async (formData) => {
    const loginResult = await AuthenticationAPIClient.updatePassword({
      email,
      newPassword: formData.password,
      passwordResetToken,
    });

    if (loginResult.outcome === "FAILURE") {
      throw new Error(loginResult.reason);
    } else {
      void navigate(generatePath(APPLICATION_ROUTES.roleSelection, {}), {
        replace: true,
      });
    }
  });

  const validatePasswordResetTokenState = useAsync(() => {
    return AuthenticationAPIClient.validatePasswordResetToken({
      email,
      passwordResetToken,
    });
  }, [email, passwordResetToken]);

  const password = form.watch("password");

  const validatePasswordStrengthResult = useMemo(
    () => validatePasswordStrength(password),
    [password],
  );

  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    setIsTyping(true);
  }, [password]);

  useDebounce(
    () => {
      setIsTyping(false);
    },
    1000,
    [password],
  );

  if (validatePasswordResetTokenState.loading)
    return (
      <div className="flex h-full w-full justify-center align-middle">
        <LoadingSpinner className="h-10 w-10" />
      </div>
    );

  if (validatePasswordResetTokenState.error) {
    throw validatePasswordResetTokenState.error;
  }

  if (!validatePasswordResetTokenState.value) {
    throw new Error("empty state value");
  }

  if (
    validatePasswordResetTokenState.value.outcome === "FAILURE" &&
    validatePasswordResetTokenState.value.reason ===
      "EXPIRED_PASSWORD_RESET_TOKEN"
  ) {
    return <PasswordResetTokenExpiredCard email={email} />;
  }

  if (validatePasswordResetTokenState.value.outcome === "FAILURE") {
    return (
      <InfoCard
        title="Oops! Something seems wrong with this URL"
        variant="error"
      >
        Reason: {validatePasswordResetTokenState.value.reason}
      </InfoCard>
    );
  }

  return (
    <AuthLayout.Card
      logo={<LogoWithoutText />}
      subtitle={
        isTyping
          ? "Go on, we aren't looking 🙈."
          : "Go on, we aren't looking 🐵."
      }
      title="Choose a new password"
    >
      <form className="mt-10" onSubmit={onSubmit}>
        <FormRow className="hidden" id="email" label="Email">
          <Input
            autoComplete="email"
            disabled
            placeholder="Email address"
            type="email"
            value={email}
          />
        </FormRow>

        <FormRow
          className="mt-2"
          error={form.formState.errors.password?.message}
          label="Choose a new password *"
        >
          <PasswordInput
            autoComplete="new-password"
            id="new-password"
            placeholder="Enter a strong password"
            {...form.register("password")}
          />
        </FormRow>

        {form.formState.errors.password &&
          !validatePasswordStrengthResult.passwordIsStrongEnough && (
            <PasswordStrengthInfoCard className="mt-6" password={password} />
          )}

        <Button
          className="mt-6"
          fullWidth
          loading={form.formState.isSubmitting}
          type="submit"
        >
          Update my password
        </Button>
      </form>
    </AuthLayout.Card>
  );
};

const UpdatePasswordPage = () => {
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const passwordResetToken = searchParams.get("passwordResetToken");

  if (!email || !passwordResetToken) return <NotFoundPage />;

  return (
    <Page
      analyticsCategory="Authentication"
      analyticsName="Authentication - Update password"
      title="Authentication - Update password"
    >
      <div className="m-auto w-full max-w-md px-4">
        <UpdatePasswordPage_
          email={email}
          passwordResetToken={passwordResetToken}
        />
      </div>
    </Page>
  );
};

export default UpdatePasswordPage;
