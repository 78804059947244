import React from "react";

import { AlertElementProps } from "../Alerter";
import { Alert } from "../ui/Alert";

export const GrantOnManagementCompaniesNotAllowedAlert: React.FC<
  Omit<AlertElementProps, "children" | "title">
> = ({ ...props }) => {
  return (
    <Alert title="" {...props}>
      Grant on management companies is not allowed
    </Alert>
  );
};
