/**
 * @generated SignedSource<<99ec8679018405897d6b112daafc98a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingImplementation_Organization$data = {
  readonly easopGrants: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly easopGrantsPendingCTMSImplementation: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly id: string;
  readonly name: string;
  readonly searchedEasopGrantsPendingCTMSImplementation: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_EasopGrant">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GenerateCTMSXlsxButton_Organization" | "GrantsTable_Organization" | "LegalWorkflowLayout_Organization" | "useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "PendingImplementation_Organization";
};
export type PendingImplementation_Organization$key = {
  readonly " $data"?: PendingImplementation_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingImplementation_Organization">;
};

import PendingImplementation_Organization_RefetchQuery_graphql from './PendingImplementation_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  "PendingCtmsImplementation"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "easopGrantsSearch"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PendingImplementation_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PendingImplementation_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GenerateCTMSXlsxButton_Organization"
    },
    {
      "alias": "searchedEasopGrantsPendingCTMSImplementation",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "easopGrantsSearch"
            },
            {
              "kind": "Literal",
              "name": "statusIn",
              "value": (v1/*: any*/)
            }
          ],
          "kind": "ObjectValue",
          "name": "filters"
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "GrantsTable_EasopGrant"
        }
      ],
      "storageKey": null
    },
    {
      "alias": "easopGrantsPendingCTMSImplementation",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": (v1/*: any*/)
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingCtmsImplementation\"]})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTable_Organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "bae8ee9899cbaf9e4cb88879dbf2c3fc";

export default node;
