/**
 * @generated SignedSource<<1ec7ac529236888cd027f285d2f62a1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WantToKnowMoreBox_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "WantToKnowMoreBox_Organization";
};
export type WantToKnowMoreBox_Organization$key = {
  readonly " $data"?: WantToKnowMoreBox_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"WantToKnowMoreBox_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WantToKnowMoreBox_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3bd8be549ed017faf3c9f992826d05d0";

export default node;
