import React from "react";

import { FAQQuestion } from "../../../components/ui/FAQQuestion";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { Typography } from "../../../components/ui/Typography";

const FAQ: React.FC = () => (
  <div className="space-y-2">
    <FAQQuestion question="When can I exercise my options?">
      <p>
        As a rule, you can only exercise the options that have already vested.
      </p>
      <p>
        A common type of vesting schedule for startups is 4 years, with a 1 year
        cliff and a monthly (or quarterly) vesting, meaning that 25% of the
        options will vest at the end of the 1st year starting from the date on
        which you were granted the options, and the remaining options will vest
        on a monthly (or quarterly) basis throughout the next 3 years, meaning
        that each month you will vest an additional ~2% (75% / 36 months) of
        your options (or ~6% every quarter in case of a quarterly vesting).
      </p>
      <NoticeMessage size="Small" variant="Idea">
        <FAQQuestion.StrongText>Example:</FAQQuestion.StrongText> When you
        started at the company on June 15, 2021 you were granted 20,000 shares
        with a vesting schedule of 4 years, with a 1 year cliff and monthly
        vesting.
        <FAQQuestion.UnorderedList>
          <li>
            On June 15, 2022, 25% of your options vest: you now have 5,000
            options vested that you can exercise
          </li>
          <li>
            On November 15, 2022, you will have 7,085 options vested since you
            vest 417 new options per month (15,000 remaining options / 36
            months)
          </li>
          <li>On June 15, 2025, 100% of your options will have vested</li>
        </FAQQuestion.UnorderedList>
      </NoticeMessage>
      <p>
        Even if it is not common, there could also be certain periods during
        which you can’t exercise.
      </p>
      <p>
        You could also have been granted the possibility to “early exercise”
        (exercise options that haven’t vested yet). Again, this is not something
        that’s frequently seen.
      </p>
    </FAQQuestion>

    <FAQQuestion question="What will I need to pay when I exercise my options?">
      <p>
        In order to exercise your options, you will have to pay the exercise
        price, also called strike price. The strike price will most often (but
        not always) be the FMV (which stands for “fair market value”) of your
        company’s shares of common stock at the time you were granted the
        options.
      </p>
      <p>
        Depending on your country of residence/place of work and the work
        relationship you have with the company, you may also have to pay taxes
        on top of the exercise price.
      </p>
    </FAQQuestion>

    <FAQQuestion question="What are the risks if I exercise my options?">
      By exercising your options, you’ll become an effective shareholder of the
      company. As such, your risks are then the same as other shareholders in a
      company: if the company goes bankrupt or, for instance, get sold at a
      price which is lower than the price you’ve paid to exercise your options
      (and the taxes you’ve paid thereon), then any or all of your investment in
      the company would be lost.
    </FAQQuestion>

    <FAQQuestion question="How can I know how much my options are worth at a certain point in time?">
      <p>
        The potential value of your options can be assed thanks to the price per
        share (PPS). The price per share is determined by reference to the
        highest valuation at which external investors - such as venture capital
        firms or business angels - have invested in your company during your
        company’s latest financing round. It’s basically what your company’s
        potentially worth in the eyes of these investors at a certain point in
        time, divided by the number of shares of the company.
      </p>
      <NoticeMessage size="Small" variant="Danger">
        You should pay attention to the fact that the value of your option grant
        is theoretical. It may increase or decrease over time, according to your
        company’s future success. In addition, the price per share will
        typically be the value of the shares of preferred stock, which is a
        category of shares usually held by the (external) investors. You will
        normally only be able to acquire shares of common stock, which are
        subject to more restrictions and have less rights compared to shares of
        preferred stock. It’s only in the scenario of a successful exit that the
        value of your shares will equal the value of the investors’ shares.
      </NoticeMessage>
    </FAQQuestion>
    <FAQQuestion question="What happens when I leave, will my options be lost?">
      <p>
        If you have not reached the end of the cliff period, you will lose all
        your options.
      </p>
      <p>
        If you have already vested some of your options, you will be able to
        exercise the vested options within a certain period called the
        post-termination exercise period (PTEP), which is traditionally set at 3
        months in US companies.
      </p>
      <NoticeMessage size="Small" variant="Idea">
        <FAQQuestion.StrongText>Example:</FAQQuestion.StrongText> When you
        started at the company, you had been granted 20,000 shares with a PTEP
        of 3 months. When you leave the company on August 15, you have already
        vested 12,000 shares. If you haven’t exercised them before, you will be
        able to do so until November 15 (Termination date + 3 months). After
        this date, you won’t be able to exercise the options, which will be
        lost. The remaining 8,000 options that had not been vested are lost
        anyway.
      </NoticeMessage>
    </FAQQuestion>
    <FAQQuestion question="When can I sell my shares?">
      <p>
        You will typically be able to sell your shares if and when a liquidity
        event occurs. Liquidity events can be:
        <ul className="my-4 ml-4 list-disc">
          <li>
            an exit - also called “trade sale” or M&A: a sale of (usually) 100%
            of the shares of a private company to a third party buyer. This is
            the most frequent scenario.{" "}
          </li>
          <li>
            a secondary sale or liquidity program: a sale by an existing
            shareholder of shares in a private company to another shareholder, a
            third party or to the company itself (that does not occur in
            connection with an exit or an IPO).
          </li>
          <li>
            an IPO: time when a privately held company goes public, i.e. the
            shares become listed on a regulated market such as Nasdaq or NYSE.
          </li>
        </ul>
        There is no guarantee that a liquidity event will ever take place.
      </p>
    </FAQQuestion>
  </div>
);

const VirtualFAQ: React.FC = () => (
  <div className="space-y-2">
    <FAQQuestion question="What are SAR?">
      <p>
        SAR (also known as virtual stock options or phantom stocks) can be seen
        as a kind of cash bonus equal to the increase in the company&apos;s
        stock price between the time you receive the SAR and the time the
        company is acquired or goes public (IPO).
      </p>

      <p>
        SAR, are a type of “equity-like” incentive that allows employees or
        contractors to benefit from an increase in company&apos;s stock price
        without actually holding any stock or stock options.
      </p>

      <p>
        With SAR, you receive the right to a cash payment equal to the increase
        in the company&apos;s stock price between the date on which you receive
        the SAR, and the date on which an exit event (e.g. an acquisition or IPO
        of your company) happens.
      </p>

      <p>
        Each SAR corresponds to one share of Common Stock, so the value of each
        SAR will increase together with the value of one share of Common Stock,
        but the company does not actually grant (and you do not actually
        receive) any stock or stock options. Instead the company grants (and you
        receive) a “cash equivalent” bonus corresponding to the up-value in the
        company stock.
      </p>

      <p>
        “Shares of Common Stock” are the shares held by founders and employees,
        as opposed to the “Preferred Shares”, which are typically held by
        investors.
      </p>
    </FAQQuestion>

    <FAQQuestion question="How are SAR different from “standard” stock options?">
      <p>
        Stock options give grantees the right to purchase company stock at a
        fixed price, known as the exercise price or strike price. If the stock
        price goes up, the grantee can purchase the stock at a price which is
        defined at the time the stock options are granted. Thereafter, if an
        exit event occurs, the grantee can sell their stock at the higher market
        price and pocket the difference as profit. However, if the stock price
        doesn&apos;t increase, the grantee may never exercise their options and
        will not receive any benefit.
      </p>

      <p>
        SAR (also known as virtual stock options or phantom stocks), on the
        other hand, are a potential cash bonus equal to the increase in the
        company&apos;s stock price at a specific moment, i.e. upon a specified
        exit event (e.g. an acquisition or IPO of the company).
      </p>

      <p>
        Each SAR corresponds to one share of Common Stock, so each SAR value
        will increase together with the value of one share of Common Stock, but
        you do not actually receive any stock or stock options. Instead you
        receive a “cash equivalent” bonus corresponding to the increase in value
        of the shares of Common Stock.
      </p>

      <p>
        You will never become a shareholder, but the economic result will be
        similar to the one a stock option holder would be entitled to.
      </p>
    </FAQQuestion>

    <FAQQuestion question="Why SAR and not “standard” stock options?">
      <p>
        For a few reasons, here are the main ones:
        <FAQQuestion.OrderedList>
          <li>
            <FAQQuestion.StrongText>
              Overcome regulatory/tax obstacles
            </FAQQuestion.StrongText>
            <br />
            SAR are an alternative to incentivize grantees residing in a country
            where it’s complex or impossible to grant “standard” stock options
            and/or where no tax-favored scheme exists for regular equity awards.
            If it’s either illegal, or it would take the company too much time,
            money and energy to offer standard equity awards, and there’s not
            even a tax advantage in doing it, why bother granting equity and not
            something more flexible?
          </li>
          <li>
            <FAQQuestion.StrongText>Easy to manage</FAQQuestion.StrongText>
            <br />
            SAR can be easier to put in place and administer, as they don’t
            require cap table management and have less legal/tax constraints
            attached.
          </li>
          <li>
            <FAQQuestion.StrongText>Flexible</FAQQuestion.StrongText>
            <br />
            SAR are not “real” equity, so the company can more easily define the
            parameters they want to associate with the grants.
          </li>
        </FAQQuestion.OrderedList>
      </p>
    </FAQQuestion>
    <FAQQuestion question="What is the main downside of SAR for you?">
      <p>
        The cash payment will qualify as either salary (for employees) or
        business income (for contractors), and salary/business income are
        usually more heavily taxed compared to capital gains, which is the usual
        tax type applicable when someone having purchased shares after having
        exercised their “standard” stock options eventually sells them.
      </p>

      <p>
        This difference between SAR and “standard” stock options should be
        nuanced though, as many option holders decide to exercise their options
        as part of an exit event, which makes the gain they make at the time of
        an exit potentially subject to income tax too.
      </p>
    </FAQQuestion>
    <FAQQuestion question="What triggers the payment of the cash bonus?">
      <p>
        The rule is: only at the time of a “liquidity event”, such as if the
        company is acquired by a third party buyer (M&A) or goes through an IPO,
        which is successful enough to satisfy every shareholder before holders
        of SAR to be able to receive their bonus.
      </p>
    </FAQQuestion>
    <FAQQuestion question="What happens with the SAR if you leave the company before a liquidity event?">
      <p>
        One clarification first: similar to stock options, if you leave before
        the end of the cliff, you get nothing. After the cliff, the maximum you
        can get is the vested part of the SAR.
      </p>
      <NoticeMessage size="Small" variant="Idea">
        <FAQQuestion.StrongText>Example:</FAQQuestion.StrongText> The SAR vest
        over a period of 4 years, with 1 year cliff. If you leave before year 1,
        you don&apos;t get anything. If you leave after 2 years, you get 50%.
        After 3 years: 75%, etc.
      </NoticeMessage>
      Now, here are the main options that the company may have granted you:
      <FAQQuestion.OrderedList>
        <li>
          The company can always choose to pay you your bonus, if the company
          has enough liquidity to afford it of course! In that case, the amount
          of the cash bonus will equal the delta between the fair market value
          (FMV) of the shares of Common Stock underlying the SAR at the time you
          leave, and the FMV of the shares of Common Stock at the time you were
          granted the SAR (but again, only for the vested part of your SAR).
        </li>
        <li>
          The company can give you - either at the time of grant, or later on
          when you leave - a “post-termination settlement period” (PTSP). The
          PTSP is a time window following the time at which you leave during
          which, if a liquidity event takes place, you can receive the vested
          part of your SAR.
        </li>
        <li>
          The company hasn&apos;t given a PTSP and the company doesn&apos;t want
          or is not able to offer the cash bonus. In that case, you get nothing
          unless you stay until a liquidity event.
        </li>
      </FAQQuestion.OrderedList>
    </FAQQuestion>
    <FAQQuestion question="What is considered as a liquidity event?">
      It&apos;s defined in the award documentation or in your Plan. Typically,
      it would be: an acquisition, a merger, a transfer of all or substantially
      all of the company&apos;s assets or an IPO.
    </FAQQuestion>
    <FAQQuestion question="Do you need to pay something to get your cash bonus (similar to the strike price the stock options holders need to pay to exercise their options)?">
      It depends on what the company opted at the time of grant:
      <FAQQuestion.UnorderedList>
        <li>
          <p>
            <FAQQuestion.StrongText>
              No strike price (virtual exercise price)
            </FAQQuestion.StrongText>
          </p>

          <p>
            In that case, the exercise price is purely virtual and corresponds
            to the fair market value (FMV) of the shares of Common Stock at the
            time of grant, which is simply deducted from the amount of the total
            cash bonus you can receive upon a liquidity event.
          </p>
          <p>
            You don&apos;t need to pay anything. If you leave, you can keep the
            vested part of your SAR (if you have allowed a “post-termination
            settlement period” - cf. question above “What happens with the SAR
            if you leave the company before a liquidity event?”).
          </p>
        </li>
        <li>
          <p>
            <FAQQuestion.StrongText>Real strike price</FAQQuestion.StrongText>
          </p>

          <p>
            The message with this exercise price is: you may leave the company
            and keep your vested SAR (and hope to get something in case of a
            successful exit), but you have to pay for that, and there&apos;s no
            assurance that you&apos;ll receive anything.
          </p>

          <p>
            How would that work? If you leave before a liquidity event, the
            company could ask you to pay the company a certain price to keep the
            right to receive the vested part of your SAR for a certain period of
            time after you leave (i.e. during your “post termination settlement
            period” - cf. question above “What happens with the SAR if you leave
            the company before a liquidity event?”).
          </p>
        </li>
      </FAQQuestion.UnorderedList>
    </FAQQuestion>

    <FAQQuestion question="How are SAR taxed?">
      <p>
        In most cases, taxation will not occur before you actually receives your
        cash bonus. Most of the time, the bonus that you will receive will be
        taxed as salary (if you’re an employee) or as business income (if you’re
        a contractor) in your country of tax residence.
      </p>
    </FAQQuestion>

    <FAQQuestion question="Can you convert your SAR into shares?">
      <p>The rule is: No, you can&apos;t.</p>
      <p>
        Now the exception: The company could decide to turn your cash bonus into
        shares of common stock in very specific situations (e.g. the company is
        acquired and shareholders get paid in shares and not in cash).
      </p>
    </FAQQuestion>
  </div>
);

export const EquityOfferEquiuniversity: React.FC<{
  isVirtual?: boolean;
}> = ({ isVirtual }) => {
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <Typography variant="Medium/Large">A few explanations...</Typography>
        <Typography variant="Regular/Small">
          {isVirtual
            ? "You will often be told about a number of SAR, but what does it mean, how do you get it, what is the outcome?"
            : "You will often be told about a number of shares, but what does it mean, how do you get it, what is the outcome?"}
        </Typography>
      </div>

      {isVirtual ? <VirtualFAQ /> : <FAQ />}
    </div>
  );
};
