/**
 * @generated SignedSource<<3b793a331d40e64f975f01d2f2032b48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type FairMarketValueStatus = "Approved" | "Draft" | "PendingBoardConsent" | "PendingReview" | "Reviewed";
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueApprovalTable_FairMarketValue$data = ReadonlyArray<{
  readonly date: string;
  readonly id: string;
  readonly status: FairMarketValueStatus;
  readonly valuationFirm: string | null;
  readonly valuationReportDocument: {
    readonly downloadUrl: string;
  } | null;
  readonly value: number;
  readonly " $fragmentType": "FairMarketValueApprovalTable_FairMarketValue";
}>;
export type FairMarketValueApprovalTable_FairMarketValue$key = ReadonlyArray<{
  readonly " $data"?: FairMarketValueApprovalTable_FairMarketValue$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueApprovalTable_FairMarketValue">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FairMarketValueApprovalTable_FairMarketValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valuationFirm",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "valuationReportDocument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "downloadUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "a035d1a53e4e74d5ea389675dab5cac2";

export default node;
