/**
 * @generated SignedSource<<bb72f37bcacb93508624aca62fe0b6a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsVestingSchedule_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "CtmsGrantDetailsVestingSchedule_Organization";
};
export type CtmsGrantDetailsVestingSchedule_Organization$key = {
  readonly " $data"?: CtmsGrantDetailsVestingSchedule_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsVestingSchedule_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsVestingSchedule_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7869c3484cc8b5028c35af59b3d74358";

export default node;
