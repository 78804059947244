/**
 * @generated SignedSource<<42496f9618ddc9c34f39263497aae484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SpreadExplanationSlideOver_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "SpreadExplanationSlideOver_Organization";
};
export type SpreadExplanationSlideOver_Organization$key = {
  readonly " $data"?: SpreadExplanationSlideOver_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SpreadExplanationSlideOver_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpreadExplanationSlideOver_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6ec0c091d822d78fd9ebea066e3dd847";

export default node;
