/**
 * @generated SignedSource<<a72a258c70d53d7dceaf9ee00d0de064>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipCategory = "Contractor" | "DirectEmployee" | "EoREmployee";
export type LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation$variables = {
  equityTypeId: string;
  equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory;
  noLegalWatchOut: boolean;
};
export type LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation$data = {
  readonly setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityTypesLegalWatchOutsTable_EquityType" | "LegalWatchOutsEditionBlock_EquityType">;
  };
};
export type LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation = {
  response: LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation$data;
  variables: LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeWorkRelationshipCategory"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "noLegalWatchOut"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "equityTypeId",
    "variableName": "equityTypeId"
  },
  {
    "kind": "Variable",
    "name": "equityTypeWorkRelationshipCategory",
    "variableName": "equityTypeWorkRelationshipCategory"
  },
  {
    "kind": "Variable",
    "name": "noLegalWatchOut",
    "variableName": "noLegalWatchOut"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "noWatchOut",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LegalWatchOut",
    "kind": "LinkedField",
    "name": "watchOuts",
    "plural": true,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastUpdatedAt",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LegalWatchOutsEditionBlock_EquityType"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EquityTypesLegalWatchOutsTable_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForDirectEmployee",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForEoREmployee",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForContractor",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae124c128a960734342b7f8441d825bf",
    "id": null,
    "metadata": {},
    "name": "LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation",
    "operationKind": "mutation",
    "text": "mutation LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation(\n  $equityTypeId: UUID!\n  $equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory!\n  $noLegalWatchOut: Boolean!\n) {\n  setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory(equityTypeId: $equityTypeId, equityTypeWorkRelationshipCategory: $equityTypeWorkRelationshipCategory, noLegalWatchOut: $noLegalWatchOut) {\n    ...LegalWatchOutsEditionBlock_EquityType\n    ...EquityTypesLegalWatchOutsTable_EquityType\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment EquityTypesLegalWatchOutsTable_EquityType on EquityType {\n  id\n  name\n  taxResidenceCountry {\n    name\n    ...CountryFlag_Country\n  }\n  legalWatchOutsForDirectEmployee {\n    lastUpdatedAt\n  }\n  legalWatchOutsForEoREmployee {\n    lastUpdatedAt\n  }\n  legalWatchOutsForContractor {\n    lastUpdatedAt\n  }\n}\n\nfragment LegalWatchOutsEditionBlock_EquityType on EquityType {\n  id\n  legalWatchOutsForDirectEmployee {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n  legalWatchOutsForEoREmployee {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n  legalWatchOutsForContractor {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n}\n\nfragment LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts on EquityTypeWorkRelationshipCategoryLegalWatchOuts {\n  noWatchOut\n  watchOuts {\n    id\n    title\n    content\n  }\n}\n"
  }
};
})();

(node as any).hash = "63a2e92699f5305e5b50cdc73c3d6d18";

export default node;
