import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useDisplayFullyDilutedValuesInGranteePortal_Grantee$key } from "./__generated__/useDisplayFullyDilutedValuesInGranteePortal_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment useDisplayFullyDilutedValuesInGranteePortal_Grantee on Grantee {
    status
    hasSettlingGrant
    organization {
      granteePortalSettings {
        displayFullyDilutedValues
      }
    }
  }
`;

export const useDisplayFullyDilutedValuesInGranteePortal = ({
  granteeFragment,
}: {
  granteeFragment: useDisplayFullyDilutedValuesInGranteePortal_Grantee$key;
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return useMemo(() => {
    if (!grantee.organization.granteePortalSettings.displayFullyDilutedValues)
      return false;
    switch (grantee.status) {
      case "Active":
        return true;
      case "Terminated":
        return grantee.hasSettlingGrant;
    }
  }, [grantee]);
};
