import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Text } from "@remote-com/norma";
import { Pill } from "@remote-com/norma";
import { IconV2SolidLink } from "@remote-com/norma/icons/IconV2SolidLink";
import { IconV2SolidLinkAlt } from "@remote-com/norma/icons/IconV2SolidLinkAlt";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import Select, { createFilter, CSSObjectWithLabel } from "react-select";
import { z } from "zod";

import { useToaster } from "../../../../components/Toaster";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { useTrackRemoteEquityOnboardingFlowEvent } from "../../../../hooks/useTrackRemoteEquityOnboardingFlowEvent";
import useIgnoreCleanupSuggestionMutation from "../../CleanupGrantees/useIgnoreCleanupSuggestionMutation";
import {
  MatchGranteeTableRow_HRISProviderEmployee$data,
  MatchGranteeTableRow_HRISProviderEmployee$key,
} from "./__generated__/MatchGranteeTableRow_HRISProviderEmployee.graphql";
import { MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation } from "./__generated__/MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation.graphql";
import {
  MatchGranteeTableRow_Organization$data,
  MatchGranteeTableRow_Organization$key,
} from "./__generated__/MatchGranteeTableRow_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MatchGranteeTableRow_Organization on Organization {
    id
    grantees {
      edges {
        node {
          id
          name
          email
          jobTitle
        }
      }
    }
    ...useTrackRemoteEquityOnboardingFlowEvent_Organization
  }
`;

const HRIS_PROVIDER_EMPLOYEE_FRAGMENT = graphql`
  fragment MatchGranteeTableRow_HRISProviderEmployee on HRISProviderEmployee {
    hRISProviderEmployeeId
    name
    email
    jobTitle
    hRISProvider
    ignored
    grantee {
      id
    }
    suggestedGrantee {
      id
    }
  }
`;

const MATCH_HRIS_PROVIDER_EMPLOYEE_AND_GRANTEE_MUTATION = graphql`
  mutation MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation(
    $granteeId: GranteeId!
    $hRISProvider: HRISProvider!
    $hRISProviderEmployeeId: String!
  ) {
    matchHRISProviderEmployeeAndGrantee(
      granteeId: $granteeId
      hRISProvider: $hRISProvider
      hRISProviderEmployeeId: $hRISProviderEmployeeId
    ) {
      __typename
    }
  }
`;

const schema = z.object({
  granteeId: z.string(),
});

type Grantee =
  MatchGranteeTableRow_Organization$data["grantees"]["edges"][number]["node"];
type HRISProviderEmployee = MatchGranteeTableRow_HRISProviderEmployee$data;

const SelectedOption: React.FC<{
  grantee: Grantee;
}> = ({ grantee }) => {
  return (
    <Typography className="text-remote-grey-900" variant="Regular/Extra Small">
      {grantee.email}
    </Typography>
  );
};
const SelectableOption: React.FC<{
  grantee: Grantee;
  hRISProviderEmployee: HRISProviderEmployee;
}> = ({ grantee, hRISProviderEmployee }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex-1 space-y-1">
        <Typography
          as="div"
          className="text-remote-grey-900"
          variant="Regular/Extra Small"
        >
          {grantee.email}
        </Typography>
        <Typography
          as="div"
          className="text-remote-grey-500"
          variant="Regular/Caption"
        >
          {grantee.name}
        </Typography>
      </div>
      {grantee.id === hRISProviderEmployee.suggestedGrantee?.id && (
        <Pill tone="blue">SUGGESTED</Pill>
      )}
    </div>
  );
};

export const MatchGranteeTableRow: React.FC<{
  hRISProviderEmployeeFragment: MatchGranteeTableRow_HRISProviderEmployee$key;
  organizationFragment: MatchGranteeTableRow_Organization$key;
  refreshData: () => void;
}> = ({ hRISProviderEmployeeFragment, organizationFragment, refreshData }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const hRISProviderEmployee = useFragment(
    HRIS_PROVIDER_EMPLOYEE_FRAGMENT,
    hRISProviderEmployeeFragment,
  );
  const trackEvent = useTrackRemoteEquityOnboardingFlowEvent({
    organizationFragment: organization,
  });
  const options = useMemo(
    () =>
      organization.grantees.edges.map(({ node }) => ({
        grantee: node,
        label: node.email,
        value: node.id,
      })),
    [organization.grantees.edges],
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      granteeId:
        hRISProviderEmployee.grantee?.id ??
        hRISProviderEmployee.suggestedGrantee?.id,
    },
    resolver: zodResolver(schema),
  });

  const selectedGranteeId = watch("granteeId");

  const selectedGrantee = useMemo(
    () =>
      organization.grantees.edges.find(
        ({ node }) => node.id === selectedGranteeId,
      )?.node,
    [organization.grantees.edges, selectedGranteeId],
  );

  const [
    matchHRISProviderEmployeeAndGrantee,
    matchHRISProviderEmployeeAndGranteeIsInFlight,
  ] =
    useSafeMutation<MatchGranteeTableRow_MatchHRISProviderEmployeeAndGrantee_Mutation>(
      MATCH_HRIS_PROVIDER_EMPLOYEE_AND_GRANTEE_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (data) => {
    const { granteeId } = schema.parse(data);
    trackEvent("Employee matched", {
      granteeId,
      remoteEmployeeId: hRISProviderEmployee.hRISProviderEmployeeId,
    });
    await matchHRISProviderEmployeeAndGrantee({
      variables: {
        granteeId,
        hRISProvider: hRISProviderEmployee.hRISProvider,
        hRISProviderEmployeeId: hRISProviderEmployee.hRISProviderEmployeeId,
      },
    });
    toaster.push(
      <Toast title="Wonderful!">
        {hRISProviderEmployee.name} has been matched
      </Toast>,
    );
    refreshData();
  });

  const [ignoreCleanupSuggestion, ignoreCleanupSuggestionMutationIsInFlight] =
    useIgnoreCleanupSuggestionMutation();

  const onIgnoreButtonClick = useCallback(async () => {
    trackEvent("Employee ignored", {
      remoteEmployeeId: hRISProviderEmployee.hRISProviderEmployeeId,
    });
    await ignoreCleanupSuggestion({
      variables: {
        HRISProvider: hRISProviderEmployee.hRISProvider,
        HRISProviderEmployeeId: hRISProviderEmployee.hRISProviderEmployeeId,
        organizationId: organization.id,
      },
    });

    toaster.push(
      <Toast title="Ok!">{hRISProviderEmployee.name} has been ignored</Toast>,
    );
    refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hRISProviderEmployee,
    ignoreCleanupSuggestion,
    organization.id,
    refreshData,
    toaster,
  ]);

  return (
    <Table.Row className="hover:!bg-remote-white">
      <form
        className="group-[.grid]:col-span-full group-[.grid]:grid group-[.grid]:grid-cols-subgrid"
        onSubmit={onSubmit}
      >
        <Table.Cell stretchContent>
          <RoundedBox
            background="subtle"
            className="w-full space-y-1 p-4 text-remote-grey-900"
          >
            <Text variant="smMedium">{hRISProviderEmployee.name}</Text>
            {hRISProviderEmployee.jobTitle && (
              <Text variant="xs">{hRISProviderEmployee.jobTitle}</Text>
            )}
            <RoundedBox
              background="remote-grey-100"
              className="w-full px-3 py-4 text-remote-grey-900"
            >
              <Typography variant="Regular/Extra Small">
                {hRISProviderEmployee.email}
              </Typography>
            </RoundedBox>
          </RoundedBox>
        </Table.Cell>
        <Table.Cell>
          <IconV2SolidLinkAlt className="w-4 text-remote-grey-600" />
        </Table.Cell>
        <Table.Cell stretchContent>
          <RoundedBox
            background="subtle"
            className="w-full space-y-1 p-4 text-remote-grey-900"
          >
            <Text className="text-remote-grey-700" variant="sm">
              Select an account to match
            </Text>
            {selectedGrantee && (
              <div className="flex gap-1">
                <Text variant="smMedium">{selectedGrantee.name}</Text>
                {selectedGrantee.id ===
                  hRISProviderEmployee.suggestedGrantee?.id && (
                  <Pill tone="blue">SUGGESTED</Pill>
                )}
              </div>
            )}
            <Controller
              control={control}
              name="granteeId"
              render={({ field, fieldState }) => {
                const selectedOption = options.find(
                  (option) => option.value === field.value,
                );
                return (
                  <FormRow error={fieldState.error?.message}>
                    <Select
                      filterOption={createFilter({
                        stringify: ({ data: { grantee } }) =>
                          `${grantee.name} ${grantee.email}`,
                      })}
                      formatOptionLabel={(
                        { grantee },
                        formatOptionLabelMeta,
                      ) => {
                        switch (formatOptionLabelMeta.context) {
                          case "menu":
                            return (
                              <SelectableOption
                                grantee={grantee}
                                hRISProviderEmployee={hRISProviderEmployee}
                              />
                            );
                          case "value":
                            return <SelectedOption grantee={grantee} />;
                        }
                      }}
                      menuPortalTarget={document.body}
                      name="grantee"
                      onChange={(newValue) => field.onChange(newValue?.value)}
                      options={options}
                      placeholder="Select email"
                      styles={{
                        control: (baseStyles) =>
                          ({
                            ...baseStyles,
                            borderRadius: "8px",
                            height: "52px",
                          }) as unknown as CSSObjectWithLabel,
                      }}
                      value={selectedOption}
                    />
                  </FormRow>
                );
              }}
            />
          </RoundedBox>
        </Table.Cell>
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Button
              disabled={
                !isValid ||
                (hRISProviderEmployee.grantee !== null &&
                  hRISProviderEmployee.grantee.id === selectedGranteeId)
              }
              IconBefore={IconV2SolidLink}
              isLoading={matchHRISProviderEmployeeAndGranteeIsInFlight}
              size="sm"
              type="submit"
            >
              Match
            </Button>
            {!hRISProviderEmployee.ignored && (
              <Button
                isLoading={ignoreCleanupSuggestionMutationIsInFlight}
                onClick={onIgnoreButtonClick}
                size="sm"
                tone="secondary"
                variant="outline"
              >
                Ignore
              </Button>
            )}
          </div>
        </Table.Cell>
      </form>
    </Table.Row>
  );
};
