import { InformationCircleIcon } from "@heroicons/react/24/outline";

import { Divider } from "./ui/Divider";
import { NoticeMessage } from "./ui/NoticeMessage";
import { RoundedBox } from "./ui/RoundedBox";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

export const Item: React.FC<
  React.PropsWithChildren<{
    label: string;
    tooltipContent?: React.ReactNode;
  }>
> = ({ children, label, tooltipContent }) => (
  <div className="space-y-2">
    <div className="flex items-center gap-1">
      <Typography className="text-black-05" variant="Regular/Extra Small">
        {label}
      </Typography>
      {tooltipContent && (
        <TooltipContainer tooltipContent={tooltipContent}>
          <InformationCircleIcon className="w-4 text-primary" />
        </TooltipContainer>
      )}
    </div>
    <Typography as="div" variant="Medium/Extra Small">
      {children}
    </Typography>
  </div>
);

const _DetailsHeader: React.FC<
  React.PropsWithChildren<{
    actions?: React.ReactNode;
    subtitle?: React.ReactNode;
    title?: React.ReactNode;
    warning?: React.ReactNode;
  }>
> = ({ actions, children, subtitle, title, warning }) => {
  return (
    <div className="relative overflow-hidden rounded-lg shadow-100">
      <div className="absolute left-0 right-0 top-0 h-1 bg-primary" />
      <RoundedBox withBorder>
        <div className="space-y-4 p-6">
          <div className="space-y-2 text-center">
            <Typography
              as="div"
              className="mx-auto text-center"
              variant="Medium/Large"
            >
              {title}
            </Typography>
            {subtitle && (
              <div className="flex items-center justify-center gap-2">
                {subtitle}
              </div>
            )}
            {warning && (
              <div className="flex items-center justify-center">
                <NoticeMessage size="Small" variant="Warning">
                  {warning}
                </NoticeMessage>
              </div>
            )}
          </div>
          {actions && (
            <div className="flex items-center justify-center gap-2">
              {actions}
            </div>
          )}
        </div>
        <Divider />
        <div className="grid grid-cols-2 gap-6 p-6 lg:auto-cols-fr lg:grid-flow-col">
          {children}
        </div>
      </RoundedBox>
    </div>
  );
};

const DetailsHeader = Object.assign(_DetailsHeader, { Item });

export default DetailsHeader;
