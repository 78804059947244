/**
 * @generated SignedSource<<e2fcaded085df778ab83efea7c110961>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantAmendmentRequestAttributes = {
  accelerationClause?: AccelerationClause | null;
  accelerationClauseIsModified: boolean;
  earlyExercise?: boolean | null;
  earlyExerciseIsModified: boolean;
  postTerminationExercisePeriodId?: any | null;
  postTerminationExercisePeriodIsModified: boolean;
  totalAccelerationDate?: string | null;
  vestingScheduleId?: any | null;
  vestingStartDate?: string | null;
  waiveCliff?: boolean | null;
};
export type ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation$variables = {
  attributes: CTMSGrantAmendmentRequestAttributes;
  ctmsGrantId: string;
};
export type ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation$data = {
  readonly createCTMSGrantAmendmentRequest: {
    readonly __typename: "CTMSGrantAmendmentRequest";
  };
};
export type ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation = {
  response: ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation$data;
  variables: ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "ctmsGrantId",
    "variableName": "ctmsGrantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "createCTMSGrantAmendmentRequest",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "createCTMSGrantAmendmentRequest",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e0c5eebffcff63b748f9b76cf731213d",
    "id": null,
    "metadata": {},
    "name": "ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation",
    "operationKind": "mutation",
    "text": "mutation ReviewCTMSGrantModification_CreateCTMSGrantAmendmentRequest_Mutation(\n  $ctmsGrantId: CtmsGrantId!\n  $attributes: CTMSGrantAmendmentRequestAttributes!\n) {\n  createCTMSGrantAmendmentRequest(ctmsGrantId: $ctmsGrantId, attributes: $attributes) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "845a0d72d4a058954271b943dc5d11ec";

export default node;
