import {
  ArrowPathIcon,
  ChevronDownIcon,
  StarIcon,
  TicketIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";

import { Button } from "../../../../../components/ui/Button";
import { MenuButton } from "../../../../../components/ui/MenuButton";

export function PlanNewMenuButton({
  onExceptionalGrantClick,
  onNewHireGrantClick,
  onPromotionGrantClick,
  onTenureGrantClick,
  onTerminationClick,
}: {
  onExceptionalGrantClick: () => void;
  onNewHireGrantClick: () => void;
  onPromotionGrantClick: () => void;
  onTenureGrantClick: () => void;
  onTerminationClick: () => void;
}) {
  return (
    <MenuButton
      button={
        <Button
          rightIcon={<ChevronDownIcon />}
          size="small"
          variant="Primary Full"
        >
          + Plan new
        </Button>
      }
      placement="bottom start"
    >
      <MenuButton.Item
        leftIcon={<UserPlusIcon />}
        onClick={onNewHireGrantClick}
      >
        New Hire grant
      </MenuButton.Item>
      <MenuButton.Item
        leftIcon={<ArrowPathIcon />}
        onClick={onTenureGrantClick}
      >
        Tenure grant
      </MenuButton.Item>
      <MenuButton.Item
        leftIcon={<TicketIcon />}
        onClick={onPromotionGrantClick}
      >
        Promotion grant
      </MenuButton.Item>
      <MenuButton.Item
        leftIcon={<StarIcon />}
        onClick={onExceptionalGrantClick}
      >
        Exceptional grant
      </MenuButton.Item>
      <MenuButton.Item
        dataCy="create-termination-menu-button"
        leftIcon={<XCircleIcon />}
        onClick={onTerminationClick}
      >
        Termination
      </MenuButton.Item>
    </MenuButton>
  );
}
