import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Typography } from "../../../../../components/ui/Typography";

export function Hero({
  children,
  imageSource,
  title,
}: React.PropsWithChildren<{
  imageSource: string;
  title: React.ReactNode;
}>) {
  return (
    <RoundedBox background="gradient-light-aubergine" rounded="2xl">
      <div className="flex items-center gap-10 p-10">
        <img className="w-[176px]" src={imageSource} />
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Large">
            {title}
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Small"
          >
            {children}
          </Typography>
        </div>
      </div>
    </RoundedBox>
  );
}
