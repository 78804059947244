/**
 * @generated SignedSource<<acea1875b51fb76e2261c1465733fa96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueSendToRewiewSlider_Organization$data = {
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly date: string;
    readonly id: string;
    readonly valuationFirm: string | null;
  };
  readonly " $fragmentType": "FairMarketValueSendToRewiewSlider_Organization";
};
export type FairMarketValueSendToRewiewSlider_Organization$key = {
  readonly " $data"?: FairMarketValueSendToRewiewSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueSendToRewiewSlider_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueSendToRewiewSlider_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "FairMarketValue",
        "kind": "LinkedField",
        "name": "latestFairMarketValue",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valuationFirm",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "2fcff3b754fd5ec8271e82c7b7da5656";

export default node;
