/**
 * @generated SignedSource<<fd30fae045791fdb835f3c68c9f7a2df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Exercise_Grantee_CTMSGrant$data = ReadonlyArray<{
  readonly earlyExercise: boolean;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseBox_CTMSGrant">;
  readonly " $fragmentType": "Exercise_Grantee_CTMSGrant";
}>;
export type Exercise_Grantee_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: Exercise_Grantee_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"Exercise_Grantee_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Exercise_Grantee_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExerciseBox_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "993822e79e63c2ceca7723535241869e";

export default node;
