import { CheckIcon, ClockIcon } from "@heroicons/react/24/outline";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { Typography } from "../../../components/ui/Typography";
import { ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest$key } from "./__generated__/ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest.graphql";

const EXERCISE_REQUEST_FRAGMENT = graphql`
  fragment ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest on CTMSExerciseRequest {
    status
  }
`;

export const ExerciseRequestWaitingForFundsSection: React.FC<{
  ctmsExerciseRequestFragment: ExerciseRequestWaitingForFundsSection_CTMSExerciseRequest$key;
}> = ({ ctmsExerciseRequestFragment }) => {
  const ctmsExerciseRequest = useFragment(
    EXERCISE_REQUEST_FRAGMENT,
    ctmsExerciseRequestFragment,
  );

  const isWaitingForFunds = ctmsExerciseRequest.status !== "Done";

  return (
    <ExerciseRequestTimelineSection
      active={!isWaitingForFunds}
      chipContent={
        isWaitingForFunds ? (
          <ClockIcon className="h-4 w-4" />
        ) : (
          <CheckIcon className="h-4 w-4" />
        )
      }
      chipRounded
    >
      <Typography as="div" variant="Medium/Default">
        Waiting for funds
      </Typography>
    </ExerciseRequestTimelineSection>
  );
};
