import classNames from "classnames";

import { FormattedCurrencyCompact } from "./Formatted/FormattedCurrencyCompact";
import { FormattedFMV } from "./FormattedFMV";
import { SafeSimulatorGraph } from "./SafeSimulatorGraph";
import { Typography } from "./ui/Typography";

export const SafeSimulatorGraphSection: React.FC<{
  className?: string;
  currentShareholdersShares: null | number;
  optionPoolShares: null | number;
  postConversionCurrentPricePerShare: null | number;
  postConversionLatestValuation: null | number;
  postConversionSafeShares: null | number;
}> = ({
  className,
  currentShareholdersShares,
  optionPoolShares,
  postConversionCurrentPricePerShare,
  postConversionLatestValuation,
  postConversionSafeShares,
}) => {
  return (
    <div
      className={classNames(
        "flex flex-col items-center gap-4 text-center",
        className,
      )}
    >
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Large">
          {postConversionLatestValuation ? (
            <FormattedCurrencyCompact value={postConversionLatestValuation} />
          ) : (
            <>$-</>
          )}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          CURRENT VALUATION
        </Typography>
      </div>
      <SafeSimulatorGraph
        currentShareholdersShares={currentShareholdersShares}
        optionPoolShares={optionPoolShares}
        postConversionSafeShares={postConversionSafeShares}
      />
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Large">
          {postConversionCurrentPricePerShare ? (
            <FormattedFMV value={postConversionCurrentPricePerShare} />
          ) : (
            <>$-</>
          )}
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          CURRENT SAFE PRICE
        </Typography>
      </div>
    </div>
  );
};
