/**
 * @generated SignedSource<<2402282262da5866cff236c623c13cf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BackloadedVestingOccurrence = "Every3Months" | "EveryMonth";
export type BackloadedVestingScheduleAttributes = {
  cliffActivatedOnFirstPeriod: boolean;
  name: string;
  periods: ReadonlyArray<BackloadedVestingSchedulePeriodAttributes>;
  vestingOccurrence: BackloadedVestingOccurrence;
};
export type BackloadedVestingSchedulePeriodAttributes = {
  durationInMonths: number;
  percentageVested: number;
};
export type EditSlideOver_BackloadedVestingSchedules_Mutation$variables = {
  attributes: BackloadedVestingScheduleAttributes;
  id: any;
};
export type EditSlideOver_BackloadedVestingSchedules_Mutation$data = {
  readonly updateBackloadedVestingSchedule: {
    readonly __typename: string;
  };
};
export type EditSlideOver_BackloadedVestingSchedules_Mutation = {
  response: EditSlideOver_BackloadedVestingSchedules_Mutation$data;
  variables: EditSlideOver_BackloadedVestingSchedules_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSlideOver_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSlideOver_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "updateBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2582cf3abe87c53ad43b292cf0ab7c4e",
    "id": null,
    "metadata": {},
    "name": "EditSlideOver_BackloadedVestingSchedules_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditSlideOver_BackloadedVestingSchedules_Mutation(\n  $attributes: BackloadedVestingScheduleAttributes!\n  $id: VestingScheduleId!\n) {\n  updateBackloadedVestingSchedule(attributes: $attributes, id: $id) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "13c3a045544b812b3e321728e745125c";

export default node;
