/**
 * @generated SignedSource<<48333fe65b2a569a72605e8a8ea91fd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
import { FragmentRefs } from "relay-runtime";
export type BookADemoModal_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly plan: OrganizationPlan;
  readonly " $fragmentType": "BookADemoModal_Organization";
};
export type BookADemoModal_Organization$key = {
  readonly " $data"?: BookADemoModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookADemoModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookADemoModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "plan",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "88fe96c69745f07521a00e147a174361";

export default node;
