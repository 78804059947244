export const preventNumberInputsScroll = () => {
  document.addEventListener("wheel", () => {
    const activeElement = document.activeElement as HTMLElement | null;
    if (
      activeElement?.getAttribute("type") === "number" &&
      typeof activeElement.blur === "function"
    ) {
      activeElement.blur();
    }
  });
};
