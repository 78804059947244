import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../components/BackButton";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { Ignored_Organization$key } from "./__generated__/Ignored_Organization.graphql";
import { Ignored_Query } from "./__generated__/Ignored_Query.graphql";
import { CreateNewGranteeItem } from "./CreateNewGrantees/CreateNewGranteeItem";
import { SynchronizeEmployeesDataItem } from "./SynchronizeEmployeesData/SynchronizeEmployeesDataItem";
import { UpdateInformationItem } from "./UpdateInformation/UpdateInformationItem";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Ignored_Organization on Organization {
    id
    name
    ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee: HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(
      ignored: true
    ) {
      id
      ...UpdateInformationItem_HRISProviderEmployee
    }
    ignoredHRISProviderEmployeesSuggestedAsNewGrantee: HRISProviderEmployeesSuggestedAsNewGrantee(
      ignored: true
    ) {
      id
      ...CreateNewGranteeItem_HRISProviderEmployee
    }
    ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(
      ignored: true
    ) {
      id
      ...SynchronizeEmployeesDataItem_HRISProviderEmployee
    }
    ...UpdateInformationItem_Organization
    ...SynchronizeEmployeesDataItem_Organization
    ...CreateNewGranteeItem_Organization
  }
`;

const UpdateInformation_: React.FC<{
  organizationFragment: Ignored_Organization$key;
  refreshQuery: () => void;
}> = ({ organizationFragment, refreshQuery }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const listLength =
    organization.ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee
      .length +
    organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee.length +
    organization
      .ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
      .length;

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="../..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to="..">Clean up grantees</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Update information</BreadCrumb.Link>
        </BreadCrumb>
      }
      fullHeight
      showFooter
    >
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Ignored items to synchronize
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Find here the items that you previously ignored.
            </Typography>
          </div>
        </div>
        {organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee.map(
          (hrisProviderEmployee, index) => (
            <CreateNewGranteeItem
              hideIgnoreButton
              hrisProviderEmployeeFragment={hrisProviderEmployee}
              key={hrisProviderEmployee.id}
              listIndex={index}
              listLength={listLength}
              onDataMutation={refreshQuery}
              organizationFragment={organization}
            />
          ),
        )}
        {organization.ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee.map(
          (hrisProviderEmployee, index) => (
            <SynchronizeEmployeesDataItem
              hideIgnoreButton
              hrisProviderEmployeeFragment={hrisProviderEmployee}
              key={hrisProviderEmployee.id}
              listIndex={
                organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee
                  .length + index
              }
              listLength={listLength}
              onDataMutation={refreshQuery}
              organizationFragment={organization}
            />
          ),
        )}
        {organization.ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee.map(
          (hrisProviderEmployee, index) => (
            <UpdateInformationItem
              hideIgnoreButton
              hrisProviderEmployeeFragment={hrisProviderEmployee}
              key={hrisProviderEmployee.id}
              listIndex={
                organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee
                  .length +
                organization
                  .ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
                  .length +
                index
              }
              listLength={listLength}
              onDataMutation={refreshQuery}
              organizationFragment={organization}
            />
          ),
        )}
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query Ignored_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      granteeCleanupSuggestionsWithIgnoredCount: granteeCleanupSuggestionsCount(
        ignored: true
      )
      ...Ignored_Organization
    }
  }
`;

const IgnoredPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<Ignored_Query>(QUERY, {
    organizationId,
  });

  const [searchParams] = useSearchParams();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (query.organization.granteeCleanupSuggestionsWithIgnoredCount === 0) {
    return (
      <Navigate
        replace
        to={{
          pathname: generatePath(
            APPLICATION_ROUTES["organizationSettingsCleanupGrantees"],
            {
              organizationId: query.organization.id,
            },
          ),
          search: searchParams.toString(),
        }}
      />
    );
  }

  return (
    <Page
      analyticsName="Admin - Clean Up - Ignored"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} ignored`}
    >
      <UpdateInformation_
        organizationFragment={query.organization}
        refreshQuery={refreshQuery}
      />
    </Page>
  );
};

export default IgnoredPage;
