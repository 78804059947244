import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { Avatar } from "@remote-com/norma";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthenticatedAccount } from "../../../../services/AuthenticatedGuard";
import { useAuthentication } from "../../../../services/AuthenticationProvider";
import { MenuButton } from "../../MenuButton";
import remoteEquityLogo from "./remote-equity-logo.png";

const NavBar: React.FC = () => {
  const authenticatedAccount = useAuthenticatedAccount();
  const fullName = `${authenticatedAccount.firstName} ${authenticatedAccount.lastName}`;

  const authentication = useAuthentication();
  const navigate = useNavigate();

  const handleLogOutButtonClick = useCallback(() => {
    authentication.logout();
    void navigate("/", { replace: true });
  }, [authentication, navigate]);

  return (
    <div className="border-b border-remote-grey-200 bg-white">
      <div className="container mx-auto flex items-center justify-between gap-4 px-10 py-5.5">
        <img
          alt="Remote Equity Logo"
          className="w-full max-w-[182px] object-contain"
          src={remoteEquityLogo}
        />
        <MenuButton
          button={
            <div className="flex cursor-pointer items-center gap-2">
              <Avatar name={fullName} size="sm" />
              <div>
                <div className="text-remote-XS/Medium text-remote-grey-900">
                  {fullName}
                </div>
                <div className="text-remote-XS text-remote-grey-600">
                  {authenticatedAccount.email}
                </div>
              </div>
            </div>
          }
        >
          <MenuButton.Item
            leftIcon={<ArrowLeftOnRectangleIcon />}
            onClick={handleLogOutButtonClick}
            variant="Danger"
          >
            Log out
          </MenuButton.Item>
        </MenuButton>
      </div>
    </div>
  );
};

export const RemoteLikeApplicationLayout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <div className="grid min-h-full grid-rows-[auto_1fr] bg-remote-background-base font-remote-body">
      <div className="sticky top-0 z-10">
        <NavBar />
      </div>
      <div className={"container mx-auto h-full px-10 py-6"}>{children}</div>
    </div>
  );
};
