/**
 * @generated SignedSource<<0683165c864341f2bdc09d83be7379ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaxDetailsContent_FairMarketValueAtExercise$data = {
  readonly date: string;
  readonly isLatest: boolean;
  readonly value: number;
  readonly " $fragmentType": "TaxDetailsContent_FairMarketValueAtExercise";
};
export type TaxDetailsContent_FairMarketValueAtExercise$key = {
  readonly " $data"?: TaxDetailsContent_FairMarketValueAtExercise$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_FairMarketValueAtExercise">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxDetailsContent_FairMarketValueAtExercise",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLatest",
      "storageKey": null
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "769bc6836c834f098d2a21ebd937b2d8";

export default node;
