/**
 * @generated SignedSource<<40bbc2506419f86e1c98361d7ea91044>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SafeCalculatorSimulatedGrantGrantedSharesInputMode = "FULLY_DILUTED" | "SHARES" | "USD_VALUE";
export type SafeCalculatorSimulatedGrantType = "POST_MONEY" | "PRE_MONEY";
export type SafeCalculatorSimulatedGrantInput = {
  grantedSharesInputMode: SafeCalculatorSimulatedGrantGrantedSharesInputMode;
  shares: number;
  type: SafeCalculatorSimulatedGrantType;
};
export type OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation$variables = {
  input: SafeCalculatorSimulatedGrantInput;
  simulatedGrantId: string;
};
export type OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation$data = {
  readonly updateSafeCalculatorVersionSimulatedGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion">;
  };
};
export type OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation = {
  response: OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation$data;
  variables: OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "simulatedGrantId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "simulatedGrantId",
    "variableName": "simulatedGrantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuationCapInUSD",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmountInvestedInUSD",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastIssuanceDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAsValuation",
  "storageKey": null
},
v8 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "updateSafeCalculatorVersionSimulatedGrant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "updateSafeCalculatorVersionSimulatedGrant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preConversionFDS",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionPoolSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "safes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SafePostMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v8/*: any*/),
                "type": "SafePreMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mfnDate",
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "type": "SafePostMoneyMFN",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SafeCalculatorSimulatedGrant",
            "kind": "LinkedField",
            "name": "simulatedGrants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantedSharesInputMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shares",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c16db2bdfb774cdea0e993be21bbb007",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation(\n  $simulatedGrantId: ID!\n  $input: SafeCalculatorSimulatedGrantInput!\n) {\n  updateSafeCalculatorVersionSimulatedGrant(input: $input, simulatedGrantId: $simulatedGrantId) {\n    ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion\n    id\n  }\n}\n\nfragment OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion on SafeCalculatorVersion {\n  id\n  preConversionFDS\n  optionPoolSize\n  safes {\n    __typename\n    ... on SafePostMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePreMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePostMoneyMFN {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      mfnDate\n      useAsValuation\n    }\n    id\n  }\n  simulatedGrants {\n    id\n    type\n    grantedSharesInputMode\n    shares\n  }\n}\n"
  }
};
})();

(node as any).hash = "3ac982eb9bd90aa6dae4737182668a3f";

export default node;
