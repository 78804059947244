import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { RefreshSquare } from "iconsax-react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { PlanningEntrySuggestionsCollapsibleSection_Organization$key } from "./__generated__/PlanningEntrySuggestionsCollapsibleSection_Organization.graphql";
import { PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion$key } from "./__generated__/PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion.graphql";
import { PlanningEntrySuggestionsTable } from "./PlanningEntrySuggestionsTable";
import { Button } from "./ui/Button";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningEntrySuggestionsCollapsibleSection_Organization on Organization {
    ...PlanningEntrySuggestionsTable_Organization
  }
`;

const PLANNING_ENTRY_SUGGESTION_FRAGMENT = graphql`
  fragment PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion on PlanningEntrySuggestion
  @relay(plural: true) {
    ...PlanningEntrySuggestionsTable_PlanningEntrySuggestion
  }
`;

export const PlanningEntrySuggestionsCollapsibleSection: React.FC<{
  onPlanningEntrySuggestionIgnoreClick: (
    planningEntrySuggestionId: string,
  ) => void;
  onPlanningEntrySuggestionPlanClick: (
    planningEntrySuggestionId: string,
  ) => void;
  organizationFragment: PlanningEntrySuggestionsCollapsibleSection_Organization$key;
  planningEntrySuggestionsFragment: PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion$key;
}> = ({
  onPlanningEntrySuggestionIgnoreClick,
  onPlanningEntrySuggestionPlanClick,
  organizationFragment,
  planningEntrySuggestionsFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntrySuggestions = useFragment(
    PLANNING_ENTRY_SUGGESTION_FRAGMENT,
    planningEntrySuggestionsFragment,
  );

  return (
    <RoundedBox className="overflow-hidden" withBorder="purple" withShadow>
      <Disclosure defaultOpen>
        {({ open }) => (
          <div className="divide-y-[0.5px] divide-purple-05">
            <Disclosure.Button className="flex w-full items-center gap-4 bg-gradient-light-aubergine px-4 py-3 text-left">
              <RefreshSquare className="h-6 w-6 text-purple" variant="Bulk" />
              <Typography className="flex-grow" variant="Medium/Small">
                Automated suggestions
              </Typography>
              <Button
                leftIcon={
                  <ChevronDownIcon
                    className={classNames({
                      "rotate-180": open,
                    })}
                  />
                }
                size="extra small"
                variant="Secondary Outline"
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <Disclosure.Panel>
                <PlanningEntrySuggestionsTable
                  onPlanningEntrySuggestionIgnoreClick={
                    onPlanningEntrySuggestionIgnoreClick
                  }
                  onPlanningEntrySuggestionPlanClick={
                    onPlanningEntrySuggestionPlanClick
                  }
                  organizationFragment={organization}
                  planningEntrySuggestionsFragment={planningEntrySuggestions}
                />
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
    </RoundedBox>
  );
};
