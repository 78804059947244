import React from "react";
import { Helmet } from "react-helmet-async";
import { useDeepCompareEffect } from "react-use";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { analytics } from "../services/analytics";
import { SuspenseWithLoadingFallback } from "./LoadingPlaceholder";

export const Page: React.FC<
  React.PropsWithChildren<{
    analyticsCategory?: string;
    analyticsName: string;
    analyticsProperties?: Record<string, unknown>;
    organizationId?: string;
    title: string;
  }>
> = ({
  analyticsCategory = "",
  analyticsName,
  analyticsProperties = {},
  children,
  organizationId,
  title,
}) => {
  const titlePrefix = useApplicationName();
  useDeepCompareEffect(() => {
    void analytics.page(analyticsCategory, analyticsName, analyticsProperties, {
      context: {
        groupId: organizationId,
      },
    });
  }, [analyticsCategory, analyticsName, analyticsProperties, organizationId]);

  return (
    <>
      <Helmet>
        <title>
          {titlePrefix} | {title}
        </title>
      </Helmet>
      <SuspenseWithLoadingFallback>{children}</SuspenseWithLoadingFallback>
    </>
  );
};
