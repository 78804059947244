import classNames from "classnames";

export const RemoteIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="#0061FF" height="24" rx="12" width="24.0001" />
    <path
      d="M15.5052 14.6024L15.5642 14.6024L15.5982 14.6024L15.5982 16.8L15.2373 16.8C12.8937 16.8 11.5377 15.4672 11.5377 13.2913L11.5377 12.1724L12.2992 11.9814C12.5222 11.9251 12.725 11.8075 12.8843 11.6419C13.0436 11.4762 13.1531 11.2693 13.2004 11.0446C13.2477 10.8199 13.2308 10.5865 13.1517 10.3709C13.0725 10.1554 12.9344 9.96627 12.7528 9.82521C12.5713 9.68415 12.3538 9.59678 12.125 9.57304C11.8962 9.5493 11.6653 9.59016 11.4586 9.69094C11.2519 9.79173 11.0777 9.94841 10.9558 10.1431C10.834 10.3379 10.7693 10.5628 10.7693 10.7924L10.7693 15.5862L8.40015 15.5862L8.40015 10.7094C8.40002 10.5626 8.40935 10.416 8.4281 10.2705C8.64924 8.53965 10.1636 7.20001 11.9992 7.20001C13.6633 7.20001 15.064 8.3016 15.4777 9.79808C15.6928 10.5819 15.623 11.4163 15.2808 12.1538C14.9908 12.776 14.526 13.3009 13.9428 13.6645C14.1385 14.4561 14.5547 14.6024 15.4777 14.6024"
      fill="white"
    />
  </svg>
);
