/**
 * @generated SignedSource<<b0f9116dc18ce6ed8c2b4fea5b74ef0f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type Drafts_SendDraftsForReview_Mutation$variables = {
  easopGrantsIds: ReadonlyArray<string>;
  organizationId: string;
};
export type Drafts_SendDraftsForReview_Mutation$data = {
  readonly submitEasopGrantsForReview: ReadonlyArray<{
    readonly grantStatus: EasopGrantStatus;
  }>;
};
export type Drafts_SendDraftsForReview_Mutation = {
  response: Drafts_SendDraftsForReview_Mutation$data;
  variables: Drafts_SendDraftsForReview_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "easopGrantsIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "easopGrantsIds",
    "variableName": "easopGrantsIds"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Drafts_SendDraftsForReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "submitEasopGrantsForReview",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Drafts_SendDraftsForReview_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "submitEasopGrantsForReview",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b78da721ab0df8f616a69d86108cf06d",
    "id": null,
    "metadata": {},
    "name": "Drafts_SendDraftsForReview_Mutation",
    "operationKind": "mutation",
    "text": "mutation Drafts_SendDraftsForReview_Mutation(\n  $easopGrantsIds: [UUID!]!\n  $organizationId: OrganizationId!\n) {\n  submitEasopGrantsForReview(easopGrantsIds: $easopGrantsIds, organizationId: $organizationId) {\n    grantStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "97dc228c6aaaec334f8fbad7853b263c";

export default node;
