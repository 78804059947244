/**
 * @generated SignedSource<<f1e9f6f9dd27ab46d07413aa6b1aeb7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_BoardMember$data = {
  readonly account: {
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly organization: {
    readonly " $fragmentSpreads": FragmentRefs<"EquityOverviewCard_Organization" | "EsopPoolChartCard_Organization" | "FairMarketValueHistoryCard_Organization" | "FairMarketValueHistoryChart_Organization" | "GrantedSharesBreakdownCard_Organization" | "OrganizationInfoCard_Organization" | "PricePerShareHistoryCard_Organization" | "PricePerShareHistoryChart_Organization">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"BoardMemberTasksCard_BoardMember">;
  readonly " $fragmentType": "Overview_BoardMember";
};
export type Overview_BoardMember$key = {
  readonly " $data"?: Overview_BoardMember$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_BoardMember">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Overview_BoardMember",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityOverviewCard_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EsopPoolChartCard_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PricePerShareHistoryCard_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PricePerShareHistoryChart_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FairMarketValueHistoryCard_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FairMarketValueHistoryChart_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrganizationInfoCard_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantedSharesBreakdownCard_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Account",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BoardMemberTasksCard_BoardMember"
    }
  ],
  "type": "BoardMember",
  "abstractKey": null
};

(node as any).hash = "ce75b1875dca67f410edb0fd7e331a69";

export default node;
