import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { lowercaseFirstLetter } from "../helpers/string";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import { LockedGeographySlideOverRemoteController } from "../pages/Admin/Countries/LockedGeographySlideOver/LockedGeographySlideOver";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../services/workRelationship";
import {
  GranteeNotGrantableAlert_Grantee$data,
  GranteeNotGrantableAlert_Grantee$key,
} from "./__generated__/GranteeNotGrantableAlert_Grantee.graphql";
import {
  GranteeNotGrantableAlert_Organization$data,
  GranteeNotGrantableAlert_Organization$key,
} from "./__generated__/GranteeNotGrantableAlert_Organization.graphql";
import {
  GranteeNotGrantableAlert_Viewer$data,
  GranteeNotGrantableAlert_Viewer$key,
} from "./__generated__/GranteeNotGrantableAlert_Viewer.graphql";
import { Button, LinkButton } from "./ui/Button";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeNotGrantableAlert_Grantee on Grantee {
    grantableStatus
    workRelationship
    taxResidenceCountry {
      emoji
      code
      name
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeNotGrantableAlert_Organization on Organization {
    id
    name
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GranteeNotGrantableAlert_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)
  }
`;

function CountryNotUnlockedMessage({
  grantee,
  organization,
  viewer,
}: {
  grantee: GranteeNotGrantableAlert_Grantee$data;
  organization: GranteeNotGrantableAlert_Organization$data;
  viewer: GranteeNotGrantableAlert_Viewer$data;
}) {
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const linkToMail = useMemo(() => {
    if (!grantee.taxResidenceCountry)
      return `mailto:${supportEmailAddress}?subject=${encodeURIComponent(
        `[${organization.name}] granting employee`,
      )}`;

    return `mailto:${supportEmailAddress}?subject=${encodeURIComponent(
      `[${organization.name}] granting employee in ${grantee.taxResidenceCountry.name}`,
    )}`;
  }, [grantee.taxResidenceCountry, organization.name, supportEmailAddress]);

  return (
    <LockedGeographySlideOverRemoteController.Provider>
      <NoticeMessage
        className="mt-4"
        hasColor={false}
        size="Large"
        variant="Warning"
      >
        <div className="space-y-4">
          <Typography as="div" variant="Medium/Extra Small">
            You need to unlock {grantee.taxResidenceCountry!.emoji}{" "}
            {grantee.taxResidenceCountry!.name} in order to incentivize this
            grantee
          </Typography>
          {viewer.isAllowedToUnlockCountryForOrganization ? (
            <LockedGeographySlideOverRemoteController.Controller
              render={({ open, transitionInProgress }) => (
                <Button
                  loading={transitionInProgress}
                  onClick={() => {
                    open({
                      data: {
                        countryCode: grantee.taxResidenceCountry!.code,
                        organizationId: organization.id,
                      },
                    });
                  }}
                  size="small"
                  type="button"
                >
                  Unlock {grantee.taxResidenceCountry!.emoji}{" "}
                  {grantee.taxResidenceCountry!.name}
                </Button>
              )}
            />
          ) : (
            <LinkButton
              size="small"
              to={linkToMail}
              variant="Secondary Outline"
            >
              Contact us
            </LinkButton>
          )}
        </div>
      </NoticeMessage>
    </LockedGeographySlideOverRemoteController.Provider>
  );
}

export const GranteeNotGrantableAlert: React.FC<{
  granteeFragment: GranteeNotGrantableAlert_Grantee$key;
  onCompleteGranteeInformationClick: () => void;
  organizationFragment: GranteeNotGrantableAlert_Organization$key;
  viewerFragment: GranteeNotGrantableAlert_Viewer$key;
}> = ({
  granteeFragment,
  onCompleteGranteeInformationClick,
  organizationFragment,
  viewerFragment,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const granteeWorkRelationshipPluralLabel = grantee.workRelationship
    ? lowercaseFirstLetter(
        WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship].pluralLabel,
      )
    : "";
  const mailtoSubject_MissingWorkRelationshipInGeography = encodeURI(
    `[${
      organization.name
    }] Why can't I grant ${granteeWorkRelationshipPluralLabel} in ${
      grantee.taxResidenceCountry?.name || ""
    }?`,
  );
  const mailtoSubject_EquityTypeInGeographyNotUnlocked = encodeURI(
    `[${organization.name}] I would like to set up a special plan in ${
      grantee.taxResidenceCountry?.name || ""
    }`,
  );

  switch (grantee.grantableStatus) {
    case "COUNTRY_NOT_UNLOCKED":
      return (
        <CountryNotUnlockedMessage
          grantee={grantee}
          organization={organization}
          viewer={viewer}
        />
      );
    case "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED":
      return (
        <NoticeMessage className="mt-4" size="Small" variant="Danger">
          Your subscription does not let you incentivize this grantee.
          <br />
          <Typography variant="Medium/Extra Small">
            We need to set up a special plan for you to be able to do it.
          </Typography>{" "}
          Please{" "}
          <a
            className="text-primary"
            href={`mailto:${supportEmailAddress}?subject=${mailtoSubject_EquityTypeInGeographyNotUnlocked}`}
          >
            contact us
          </a>
          .
        </NoticeMessage>
      );
    case "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED":
      return (
        <NoticeMessage className="mt-4" size="Small" variant="Danger">
          Your organization does not allow you to create grant for management
          companies.
          <br />
          Please contact the {applicationName} support team.
        </NoticeMessage>
      );
    case "GRANTABLE":
      return null;
    case "GRANTEE_IS_MISSING_REQUIRED_INFORMATION":
      return (
        <NoticeMessage className="mt-4" size="Small" variant="Danger">
          This grantee is missing some mandatory information.
          <br />
          Please{" "}
          <button
            className="text-primary underline"
            onClick={onCompleteGranteeInformationClick}
            type="button"
          >
            click here
          </button>{" "}
          to complete their profile.
        </NoticeMessage>
      );
    case "WORK_RELATIONSHIP_NOT_COVERED":
      return (
        <NoticeMessage className="mt-4" size="Small" variant="Danger">
          Sorry, you are not able to issue grants to{" "}
          <Typography variant="Medium/Extra Small">
            {granteeWorkRelationshipPluralLabel}
          </Typography>{" "}
          in{" "}
          <Typography variant="Medium/Extra Small">
            {grantee.taxResidenceCountry?.emoji}{" "}
            {grantee.taxResidenceCountry?.name}
          </Typography>
          .<br />
          Please{" "}
          <a
            className="text-primary"
            href={`mailto:${supportEmailAddress}?subject=${mailtoSubject_MissingWorkRelationshipInGeography}`}
          >
            contact us
          </a>{" "}
          for more details.
        </NoticeMessage>
      );
  }
};
