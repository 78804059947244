import { XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ReactNode } from "react";

import { useBoolean } from "../../../hooks/useBoolean";
import { Button } from "../Button";
import { ConfirmationModal } from "../ConfirmationModal";
import { Logo } from "../Logo/Logo";

export const FlowLayout: React.FC<{
  children: ReactNode;
  className?: string;
  onCloseClick: () => void;
  Sidenav?: ReactNode;
  sidenavClassnames?: string;
}> = ({ children, className, onCloseClick, Sidenav, sidenavClassnames }) => {
  const {
    setFalse: hideExitModal,
    setTrue: showExitModal,
    value: exitModalIsShown,
  } = useBoolean(false);

  return (
    <div className="flex h-full">
      <ConfirmationModal
        cancelLabel="Continue draft"
        confirmationLabel="Exit draft"
        onClose={hideExitModal}
        onConfirmed={onCloseClick}
        show={exitModalIsShown}
        suspense
        title="Do you want to exit?"
      >
        When you exit the flow, any unsaved changes will be lost.
      </ConfirmationModal>

      <div
        className={classNames(
          sidenavClassnames,
          "flex h-full w-[360px] flex-col items-start justify-between p-6",
        )}
      >
        <Button
          leftIcon={<XMarkIcon />}
          onClick={showExitModal}
          variant="Tertiary Link"
        >
          Close
        </Button>

        {Sidenav}

        <Logo className="block h-[23px]" variant="black" />
      </div>
      <main className={classNames(className, "flex-auto overflow-auto")}>
        {children}
      </main>
    </div>
  );
};
