/**
 * @generated SignedSource<<a5fab81124cedacd1cca8358d8932de2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationLayout_Subscription$variables = {
  id: string;
};
export type OrganizationLayout_Subscription$data = {
  readonly organizationUpdates: {
    readonly setupIsComplete: boolean;
  };
};
export type OrganizationLayout_Subscription = {
  response: OrganizationLayout_Subscription$data;
  variables: OrganizationLayout_Subscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "setupIsComplete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationLayout_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationUpdates",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationLayout_Subscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationUpdates",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce95db9ea89ea7fbc6dbddb06ed09c4e",
    "id": null,
    "metadata": {},
    "name": "OrganizationLayout_Subscription",
    "operationKind": "subscription",
    "text": "subscription OrganizationLayout_Subscription(\n  $id: OrganizationId!\n) {\n  organizationUpdates(id: $id) {\n    setupIsComplete\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b9523b707ebc96c012cc55a1977918f";

export default node;
