/**
 * @generated SignedSource<<68b1b290c9e3a675aa53c028a9e8231b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityPlanThresholdsExceededPage_Query$variables = Record<PropertyKey, never>;
export type RemoteEquityPlanThresholdsExceededPage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityPlanThresholdsExceededPage_Organization">;
  }>;
};
export type RemoteEquityPlanThresholdsExceededPage_Query = {
  response: RemoteEquityPlanThresholdsExceededPage_Query$data;
  variables: RemoteEquityPlanThresholdsExceededPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "orderBy": {
        "direction": "ASC",
        "field": "name"
      },
      "remoteEquityPlanThresholdsExceeded": true,
      "status": "Active"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoteEquityPlanThresholdsExceededPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RemoteEquityPlanThresholdsExceededPage_Organization"
          }
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"remoteEquityPlanThresholdsExceeded\":true,\"status\":\"Active\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RemoteEquityPlanThresholdsExceededPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "billableGrantees",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGeography",
            "kind": "LinkedField",
            "name": "billableOrganizationGeographies",
            "plural": true,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityPlanEmployeeThreshold",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityPlanCountryThreshold",
            "storageKey": null
          }
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"remoteEquityPlanThresholdsExceeded\":true,\"status\":\"Active\"})"
      }
    ]
  },
  "params": {
    "cacheID": "a872afe45c830e0c9714fcdedbbfcf46",
    "id": null,
    "metadata": {},
    "name": "RemoteEquityPlanThresholdsExceededPage_Query",
    "operationKind": "query",
    "text": "query RemoteEquityPlanThresholdsExceededPage_Query {\n  organizations(filters: {remoteEquityPlanThresholdsExceeded: true, orderBy: {field: name, direction: ASC}, status: Active}) {\n    ...RemoteEquityPlanThresholdsExceededPage_Organization\n    id\n  }\n}\n\nfragment RemoteEquityPlanCountryThresholdProgressBar_Organization on Organization {\n  billableOrganizationGeographies {\n    __typename\n    id\n  }\n  remoteEquityPlanCountryThreshold\n}\n\nfragment RemoteEquityPlanEmployeeThresholdProgressBar_Organization on Organization {\n  billableGrantees {\n    __typename\n    id\n  }\n  remoteEquityPlanEmployeeThreshold\n}\n\nfragment RemoteEquityPlanThresholdsExceededPage_Organization on Organization {\n  id\n  name\n  billableGrantees {\n    __typename\n    id\n  }\n  billableOrganizationGeographies {\n    __typename\n    id\n  }\n  remoteEquityPlanEmployeeThreshold\n  remoteEquityPlanCountryThreshold\n  ...RemoteEquityPlanEmployeeThresholdProgressBar_Organization\n  ...RemoteEquityPlanCountryThresholdProgressBar_Organization\n}\n"
  }
};
})();

(node as any).hash = "e2d0e779643134aeb002945dd3fcbe7c";

export default node;
