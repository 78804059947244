/**
 * @generated SignedSource<<88256221794453ebed1239322e4494ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CTMSGrantAmendmentRequestStatus = "IMPLEMENTATION" | "IMPLEMENTED" | "PENDING_APPROVAL" | "READY_FOR_CONSENT" | "UNDER_REVIEW";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$data = ReadonlyArray<{
  readonly ctmsGrant: {
    readonly grantee: {
      readonly id: string;
      readonly name: string;
      readonly workRelationship: WorkRelationship | null;
      readonly " $fragmentSpreads": FragmentRefs<"GranteeNameWithCountryFlag_Grantee">;
    };
    readonly id: string;
    readonly label: string;
  };
  readonly id: string;
  readonly isDeletable: boolean;
  readonly isUnfavorable: boolean;
  readonly status: CTMSGrantAmendmentRequestStatus;
  readonly " $fragmentType": "CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest";
}>;
export type CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$key = ReadonlyArray<{
  readonly " $data"?: CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUnfavorable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDeletable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GranteeNameWithCountryFlag_Grantee"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrantAmendmentRequest",
  "abstractKey": null
};
})();

(node as any).hash = "6ce1aca87942efd44485fbd7abb12d24";

export default node;
