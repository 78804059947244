/**
 * @generated SignedSource<<a05d873d88094e1f44b7d0a5d18c5e53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GenerateCTMSXlsxButton_Carta_Mutation$variables = {
  organizationId: string;
};
export type GenerateCTMSXlsxButton_Carta_Mutation$data = {
  readonly generateOrganizationPendingImplementationOptionsCartaImportableXLSXDataURL: string | null;
  readonly generateOrganizationPendingImplementationRSUsCartaImportableXLSXDataURL: string | null;
  readonly generateOrganizationPendingImplementationSARsCartaImportableXLSXDataURL: string | null;
};
export type GenerateCTMSXlsxButton_Carta_Mutation = {
  response: GenerateCTMSXlsxButton_Carta_Mutation$data;
  variables: GenerateCTMSXlsxButton_Carta_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = [
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "generateOrganizationPendingImplementationOptionsCartaImportableXLSXDataURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "generateOrganizationPendingImplementationRSUsCartaImportableXLSXDataURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v1/*: any*/),
    "kind": "ScalarField",
    "name": "generateOrganizationPendingImplementationSARsCartaImportableXLSXDataURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GenerateCTMSXlsxButton_Carta_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GenerateCTMSXlsxButton_Carta_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d2dc51d27c4c53ca794999aef341e77e",
    "id": null,
    "metadata": {},
    "name": "GenerateCTMSXlsxButton_Carta_Mutation",
    "operationKind": "mutation",
    "text": "mutation GenerateCTMSXlsxButton_Carta_Mutation(\n  $organizationId: OrganizationId!\n) {\n  generateOrganizationPendingImplementationOptionsCartaImportableXLSXDataURL(organizationId: $organizationId)\n  generateOrganizationPendingImplementationRSUsCartaImportableXLSXDataURL(organizationId: $organizationId)\n  generateOrganizationPendingImplementationSARsCartaImportableXLSXDataURL(organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "81eea1433e54e5dc8e0c772e1c0ad626";

export default node;
