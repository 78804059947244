/**
 * @generated SignedSource<<1b5a524e2db3d1c53854e126e193c017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipDocumentSet_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useDownloadInstrumentDocumentsPreview_Organization">;
  readonly " $fragmentType": "EquityTypeWorkRelationshipDocumentSet_Organization";
};
export type EquityTypeWorkRelationshipDocumentSet_Organization$key = {
  readonly " $data"?: EquityTypeWorkRelationshipDocumentSet_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipDocumentSet_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipDocumentSet_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDownloadInstrumentDocumentsPreview_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "773f2b53d2139e3ccc243b7de3459903";

export default node;
