/**
 * @generated SignedSource<<5cf3b1bbaa657af0c0567aa59b73cfe3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation$variables = {
  organizationId: string;
  showProjectionScenarios: boolean;
};
export type GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation$data = {
  readonly setShowGranteePortalProjectionScenarios: {
    readonly granteePortalSettings: {
      readonly showProjectionScenarios: boolean;
    };
  };
};
export type GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation = {
  response: GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation$data;
  variables: GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showProjectionScenarios"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "showProjectionScenarios",
    "variableName": "showProjectionScenarios"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "GranteePortalSettings",
  "kind": "LinkedField",
  "name": "granteePortalSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showProjectionScenarios",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setShowGranteePortalProjectionScenarios",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setShowGranteePortalProjectionScenarios",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2132e15b419ca662bb0c4560d9778dac",
    "id": null,
    "metadata": {},
    "name": "GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteePortalSettingsProjectionSection_SetShowGranteePortalProjectionScenarios_Mutation(\n  $organizationId: OrganizationId!\n  $showProjectionScenarios: Boolean!\n) {\n  setShowGranteePortalProjectionScenarios(organizationId: $organizationId, showProjectionScenarios: $showProjectionScenarios) {\n    granteePortalSettings {\n      showProjectionScenarios\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "61fb3b0779bd1455e60d5d39d2928ce7";

export default node;
