import React, { useCallback, useMemo } from "react";

import { AlertElementProps } from "../Alerter";
import { Modal } from "./Modal";

export const Alert: React.FC<React.PropsWithChildren<AlertElementProps>> = ({
  buttonLabel = "OK",
  children,
  isShown,
  onCloseButtonClick,
  title,
}) => {
  const onClose = useCallback(
    () => onCloseButtonClick && onCloseButtonClick(),
    [onCloseButtonClick],
  );
  const show = useMemo(() => isShown || false, [isShown]);

  return (
    <Modal onClose={onClose} show={show} suspense>
      <Modal.Content
        actionsInHeader={
          <Modal.ActionButton onClick={onClose} variant="Danger Full">
            {buttonLabel}
          </Modal.ActionButton>
        }
        onClose={onClose}
        title={title}
      >
        {children}
      </Modal.Content>
    </Modal>
  );
};
