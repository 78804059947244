import classNames from "classnames";
import { forwardRef } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

export const _Checkbox = forwardRef<
  HTMLInputElement,
  Omit<React.ComponentProps<"input">, "type">
>(function Checkbox({ className, ...props }, ref) {
  return (
    <input
      className={classNames(
        "form-checkbox h-5 w-5 rounded border-[0.5px] border-gray-07 transition-all checked:bg-primary",
        className,
      )}
      ref={ref}
      type="checkbox"
      {...props}
    />
  );
});

const FormCheckbox = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof _Checkbox> & {
  control: Control<TFieldValues>;
  name: TName;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <_Checkbox
          {...field}
          {...props}
          checked={field.value ?? false}
          onChange={(e) => {
            field.onChange(e.currentTarget.checked);
          }}
        />
      )}
    />
  );
};

export const Checkbox = Object.assign(_Checkbox, {
  Form: FormCheckbox,
});
