/**
 * @generated SignedSource<<516e56c62b2cbd2875e85b4b70afc42d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDocumentationDetailsPage_EasopGrant$data = {
  readonly grantee: {
    readonly name: string;
  };
  readonly label: string;
  readonly " $fragmentSpreads": FragmentRefs<"useGrantDocuments_EasopGrant">;
  readonly " $fragmentType": "GrantDocumentationDetailsPage_EasopGrant";
};
export type GrantDocumentationDetailsPage_EasopGrant$key = {
  readonly " $data"?: GrantDocumentationDetailsPage_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationDetailsPage_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantDocumentationDetailsPage_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGrantDocuments_EasopGrant"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "76eb02cb3129f5a83e4808048577ae18";

export default node;
