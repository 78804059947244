/**
 * @generated SignedSource<<65c60a1a8c6534a4d79e54fada0d52c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Review_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GrantGranteeDetailsRow_Grantee" | "GrantOwnershipRow_Grantee" | "GrantVestingOverviewRow_Grantee" | "InstrumentNotAvailableForGranteeAlert_Grantee">;
  readonly " $fragmentType": "Review_Grantee";
};
export type Review_Grantee$key = {
  readonly " $data"?: Review_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"Review_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Review_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantOwnershipRow_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantVestingOverviewRow_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantGranteeDetailsRow_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InstrumentNotAvailableForGranteeAlert_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "4b3055d5ece7af14958b8376aa9e1418";

export default node;
