import { PlusIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import _, { debounce, isNil } from "lodash";
import { Suspense, useCallback, useMemo, useState, useTransition } from "react";
import { Controller, useForm } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../../components/ui/Button";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { SearchBar } from "../../../components/ui/SearchBar";
import { Switch } from "../../../components/ui/Switch";
import { zodExtra } from "../../../helpers/zod-extra";
import { HRIS_PROVIDER_MAP } from "../../../hooks/useHRISProvider";
import {
  WORK_RELATIONSHIP_TO_LABEL_HELPER,
  WORK_RELATIONSHIP_TYPES,
  WORK_RELATIONSHIPS,
} from "../../../services/workRelationship";
import { OrganizationGranteesFilters } from "./__generated__/Grantees_GranteesPagination_Query.graphql";
import { GranteesFilters_Deferred_Organization$key } from "./__generated__/GranteesFilters_Deferred_Organization.graphql";
import { GranteesFilters_Organization$key } from "./__generated__/GranteesFilters_Organization.graphql";

const EQUITY_TYPES = ["ALL", "EQUITY_DRAFTED", "EQUITY_PLANNED"] as const;

const filterFormSchema = z.object({
  countryCode: z.string().optional(),
  equityGridLevelId: z.string().optional(),
  equityType: z.enum(EQUITY_TYPES).optional(),
  hRISProvider: z.enum(["DEEL", "REMOTE"]).optional(),
  maximumTotalOwnershipValue: zodExtra.preprocessNaNToUndefined(
    z.number().optional().nullable(),
  ),
  minimumTotalOwnershipValue: zodExtra.preprocessNaNToUndefined(
    z.number().min(0).optional().nullable(),
  ),
  workRelationship: z.enum(WORK_RELATIONSHIP_TYPES).optional(),
});

const DEFERRED_ORGANIZATION_FRAGMENT = graphql`
  fragment GranteesFilters_Deferred_Organization on Organization {
    equityGrid {
      activated
      levels {
        id
        name
      }
    }
    allGrantees: grantees {
      edges {
        node {
          id
          workRelationship
          taxResidenceCountry {
            name
            emoji
            code
          }
          hRISProviderEmployee {
            hRISProvider
          }
        }
      }
    }
  }
`;

export type FilterFormSchema = z.infer<typeof filterFormSchema>;

const EQUITY_TYPE_SWITCH_LABELS: Record<(typeof EQUITY_TYPES)[number], string> =
  {
    ALL: "All",
    EQUITY_DRAFTED: "Equity drafted",
    EQUITY_PLANNED: "Equity planned",
  };

const Deferred: React.FC<{
  filters: OrganizationGranteesFilters;
  onFiltersChange: (filters: OrganizationGranteesFilters) => void;
  organizationFragment: GranteesFilters_Deferred_Organization$key;
}> = ({ filters, onFiltersChange, organizationFragment }) => {
  const organization = useFragment(
    DEFERRED_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const grantees = useMemo(
    () => organization.allGrantees.edges.map((edge) => edge.node),
    [organization.allGrantees.edges],
  );

  const granteesCountries = useMemo(
    () =>
      _(grantees)
        .map((grantee) => grantee.taxResidenceCountry)
        .compact()
        .uniqBy((country) => country.code)
        .value(),
    [grantees],
  );
  const granteesWorkRelationships = useMemo(
    () =>
      _(grantees)
        .map((grantee) => grantee.workRelationship)
        .compact()
        .uniq()
        .value(),
    [grantees],
  );

  const granteesHRISProvider = useMemo(
    () =>
      _(grantees)
        .map((grantee) => grantee.hRISProviderEmployee?.hRISProvider)
        .compact()
        .uniq()
        .sortBy((hRISProvider) => (hRISProvider !== "REMOTE" ? 1 : -1))
        .value(),
    [grantees],
  );

  const granteesHRISProviderOptions = useMemo(
    () =>
      granteesHRISProvider.map((hRISProvider) => ({
        label: HRIS_PROVIDER_MAP[hRISProvider],
        value: hRISProvider,
      })),
    [granteesHRISProvider],
  );

  const filterForm = useForm<z.output<typeof filterFormSchema>>({
    resolver: zodResolver(filterFormSchema),
    values: {
      countryCode: filters.countryCode ?? undefined,
      equityGridLevelId: filters.equityGridLevelId ?? undefined,
      equityType: filters.hasDraftedGrants
        ? "EQUITY_DRAFTED"
        : filters.hasPlannedGrants
          ? "EQUITY_PLANNED"
          : "ALL",
      hRISProvider: filters.hRISProvider ?? undefined,
      maximumTotalOwnershipValue:
        filters.maximumTotalOwnershipValue ?? undefined,
      minimumTotalOwnershipValue:
        filters.minimumTotalOwnershipValue ?? undefined,
      workRelationship: filters.workRelationship ?? undefined,
    },
  });

  const someFiltersAreActive = useMemo(
    () =>
      !isNil(filters.countryCode) ||
      !isNil(filters.workRelationship) ||
      !isNil(filters.minimumTotalOwnershipValue) ||
      !isNil(filters.maximumTotalOwnershipValue) ||
      !isNil(filters.equityGridLevelId) ||
      !isNil(filters.hRISProvider),
    [filters],
  );

  const activeFiltersCountry = useMemo(
    () =>
      granteesCountries.find((country) => country.code === filters.countryCode),
    [filters.countryCode, granteesCountries],
  );

  const [filtersTransitionInProgress, startFiltersTransition] = useTransition();
  const handleSubmit = filterForm.handleSubmit(
    ({ equityType, ...submittedFilters }) => {
      startFiltersTransition(() => {
        onFiltersChange({
          ...filters,
          hasDraftedGrants: equityType === "EQUITY_DRAFTED" ? true : null,
          hasPlannedGrants: equityType === "EQUITY_PLANNED" ? true : null,
          ...submittedFilters,
        });
      });
    },
  );

  const [searchTransitionInProgress, startSearchTransition] = useTransition();
  const onFullTextFilterChange = useCallback(
    (search: string) => {
      startSearchTransition(() => {
        onFiltersChange({ ...filters, search });
      });
    },
    [filters, onFiltersChange],
  );

  const debouncedOnFullTextFilterChange = useMemo(
    () => debounce(onFullTextFilterChange, 500),
    [onFullTextFilterChange],
  );

  const [realTimeFullTextFilter, setRealTimeFullTextFilter] = useState(
    filters.search ?? "",
  );

  return (
    <div className="space-y-4">
      <div className="relative flex items-center justify-between gap-4">
        <div className="relative flex items-center gap-4">
          {filters.status === "Active" && (
            <Controller
              control={filterForm.control}
              name="equityType"
              render={({ field }) => (
                <Switch
                  getOptionValue={(option) =>
                    EQUITY_TYPE_SWITCH_LABELS[option ?? "ALL"]
                  }
                  name={field.name}
                  onChange={(value) => {
                    startFiltersTransition(() => {
                      field.onChange(value);
                      onFiltersChange({
                        ...filters,
                        hasDraftedGrants:
                          value === "EQUITY_DRAFTED" ? true : null,
                        hasPlannedGrants:
                          value === "EQUITY_PLANNED" ? true : null,
                      });
                    });
                  }}
                  options={EQUITY_TYPES}
                  selectedOption={field.value}
                />
              )}
            />
          )}
          <SearchBar.FiltersBox
            popoverButton={
              <SearchBar.FiltersBoxPopoverButton
                loading={filtersTransitionInProgress}
              />
            }
            renderApplyButton={() => <Button />}
            renderClearButton={(close) => (
              <Button
                onClick={() => {
                  filterForm.reset();
                  void handleSubmit();
                  close();
                }}
              />
            )}
            renderForm={(close) => (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  void handleSubmit();
                  close();
                }}
              />
            )}
          >
            <SearchBar.FiltersBoxItem label="Work relationship">
              <Controller
                control={filterForm.control}
                name="workRelationship"
                render={({ field, fieldState }) => (
                  <SelectAutocomplete
                    getOptionLabel={(option) => option.singularLabel}
                    getOptionValue={(option) => option.id}
                    invalid={!!fieldState.error}
                    name={field.name}
                    onChange={(option) => {
                      field.onChange(option?.id);
                    }}
                    options={WORK_RELATIONSHIPS.filter((workRelationship) =>
                      granteesWorkRelationships.includes(workRelationship.id),
                    )}
                    placeholder="Filter..."
                    usePortal
                    value={WORK_RELATIONSHIP_TO_LABEL_HELPER[field.value!]}
                  />
                )}
              />
            </SearchBar.FiltersBoxItem>
            <SearchBar.FiltersBoxItem label="Country">
              <Controller
                control={filterForm.control}
                name="countryCode"
                render={({ field, fieldState }) => (
                  <SelectAutocomplete
                    getOptionLabel={(country) =>
                      `${country.emoji} ${country.name}`
                    }
                    getOptionValue={(country) => country.code}
                    invalid={!!fieldState.error}
                    name={field.name}
                    onChange={(country) => {
                      field.onChange(country?.code);
                    }}
                    options={granteesCountries}
                    placeholder="Filter..."
                    usePortal
                    value={
                      granteesCountries.find(
                        (country) => country.code === field.value,
                      ) ?? null
                    }
                  />
                )}
              />
            </SearchBar.FiltersBoxItem>
            {granteesHRISProvider.length > 0 && (
              <SearchBar.FiltersBoxItem label="Employees from">
                <Controller
                  control={filterForm.control}
                  name="hRISProvider"
                  render={({ field, fieldState }) => (
                    <SelectAutocomplete
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                      invalid={!!fieldState.error}
                      name={field.name}
                      onChange={(option) => {
                        field.onChange(option?.value);
                      }}
                      options={granteesHRISProviderOptions}
                      placeholder="Select source"
                      usePortal
                      value={
                        granteesHRISProviderOptions.find(
                          (option) => option.value === field.value,
                        ) ?? null
                      }
                    />
                  )}
                />
              </SearchBar.FiltersBoxItem>
            )}
            {organization.equityGrid.activated && (
              <SearchBar.FiltersBoxItem label="Equity level">
                <Controller
                  control={filterForm.control}
                  name="equityGridLevelId"
                  render={({ field, fieldState }) => (
                    <SelectAutocomplete
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      invalid={!!fieldState.error}
                      name={field.name}
                      onChange={(option) => {
                        field.onChange(option?.id);
                      }}
                      options={organization.equityGrid.levels}
                      placeholder="Filter..."
                      usePortal
                      value={
                        organization.equityGrid.levels.find(
                          (level) => level.id === field.value,
                        ) ?? null
                      }
                    />
                  )}
                />
              </SearchBar.FiltersBoxItem>
            )}
            <SearchBar.FiltersBoxItem label="$ Total ownership">
              <div className="flex gap-2 [&>div]:flex-1">
                <Input
                  placeholder="Minimum"
                  type="number"
                  {...filterForm.register("minimumTotalOwnershipValue", {
                    valueAsNumber: true,
                  })}
                />
                <Input
                  placeholder="Maximum"
                  type="number"
                  {...filterForm.register("maximumTotalOwnershipValue", {
                    valueAsNumber: true,
                  })}
                />
              </div>
            </SearchBar.FiltersBoxItem>
          </SearchBar.FiltersBox>
        </div>

        <SearchBar
          className="w-full max-w-[300px]"
          loading={searchTransitionInProgress}
          onChange={(value) => {
            setRealTimeFullTextFilter(value);
            debouncedOnFullTextFilterChange(value);
          }}
          placeholder="Search grantees"
          value={realTimeFullTextFilter}
        />
      </div>
      {someFiltersAreActive && (
        <SearchBar.FilterChipsContainer
          onClearAllFiltersButtonClick={() => {
            filterForm.reset();
            void handleSubmit();
          }}
        >
          {filters.workRelationship && (
            <SearchBar.FilterChip
              label="Work relationship"
              onRemoveButtonClick={() => {
                filterForm.setValue("workRelationship", undefined);
                void handleSubmit();
              }}
            >
              {
                WORK_RELATIONSHIP_TO_LABEL_HELPER[filters.workRelationship]
                  .singularLabel
              }
            </SearchBar.FilterChip>
          )}
          {activeFiltersCountry && (
            <SearchBar.FilterChip
              label="Country"
              onRemoveButtonClick={() => {
                filterForm.setValue("countryCode", undefined);
                void handleSubmit();
              }}
            >
              {activeFiltersCountry.emoji} {activeFiltersCountry.name}
            </SearchBar.FilterChip>
          )}
          {filters.equityGridLevelId && (
            <SearchBar.FilterChip
              label="Equity level"
              onRemoveButtonClick={() => {
                filterForm.setValue("equityGridLevelId", undefined);
                void handleSubmit();
              }}
            >
              {
                organization.equityGrid.levels.find(
                  (level) => level.id === filters.equityGridLevelId,
                )?.name
              }
            </SearchBar.FilterChip>
          )}
          {(!isNil(filters.maximumTotalOwnershipValue) ||
            !isNil(filters.minimumTotalOwnershipValue)) && (
            <SearchBar.FilterChip
              label="Total ownership"
              onRemoveButtonClick={() => {
                filterForm.setValue("minimumTotalOwnershipValue", undefined);
                filterForm.setValue("maximumTotalOwnershipValue", undefined);
                void handleSubmit();
              }}
            >
              <FormattedNumber
                currency="USD"
                maximumFractionDigits={0}
                style="currency"
                value={filters.minimumTotalOwnershipValue ?? 0}
              />{" "}
              -{" "}
              {!isNil(filters.maximumTotalOwnershipValue) ? (
                <FormattedNumber
                  currency="USD"
                  maximumFractionDigits={0}
                  style="currency"
                  value={filters.maximumTotalOwnershipValue}
                />
              ) : (
                "∞"
              )}
            </SearchBar.FilterChip>
          )}
          {!isNil(filters.hRISProvider) && (
            <SearchBar.FilterChip
              label="Employees from"
              onRemoveButtonClick={() => {
                filterForm.setValue("hRISProvider", undefined);
                void handleSubmit();
              }}
            >
              {HRIS_PROVIDER_MAP[filters.hRISProvider]}
            </SearchBar.FilterChip>
          )}
        </SearchBar.FilterChipsContainer>
      )}
    </div>
  );
};

const Skeleton: React.FC = () => {
  return (
    <div className="relative flex items-center gap-4">
      <SearchBar loading placeholder="Search grantees..." readOnly />
      <Button
        className="animate-pulse whitespace-nowrap"
        disabled
        leftIcon={<PlusIcon />}
        size="small"
        variant="Primary Outline"
      >
        Add filter
      </Button>
    </div>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteesFilters_Organization on Organization {
    ...GranteesFilters_Deferred_Organization @defer
  }
`;

const GranteesFilters: React.FC<
  Omit<React.ComponentProps<typeof Deferred>, "organizationFragment"> & {
    onFiltersChange: (filters: OrganizationGranteesFilters) => void;
    organizationFragment: GranteesFilters_Organization$key;
  }
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Suspense fallback={<Skeleton />}>
      <Deferred organizationFragment={organization} {...props} />
    </Suspense>
  );
};

export default GranteesFilters;
