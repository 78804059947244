/**
 * @generated SignedSource<<351440d77aa8d265c25a86e95a19e3a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderwaterGrantsGraph_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "UnderwaterGrantsGraph_Organization";
};
export type UnderwaterGrantsGraph_Organization$key = {
  readonly " $data"?: UnderwaterGrantsGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrantsGraph_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderwaterGrantsGraph_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0c0985d4370e6118bfc54995546fce58";

export default node;
