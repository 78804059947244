/**
 * @generated SignedSource<<ce1420333f25ecc2a33af56aee5deadb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnlockCountry_OrganizationStripeAccount$data = {
  readonly invoicesDefaultPaymentMethod: {
    readonly __typename: "StripePaymentMethod";
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetails_OrganizationStripeAccount">;
  readonly " $fragmentType": "UnlockCountry_OrganizationStripeAccount";
};
export type UnlockCountry_OrganizationStripeAccount$key = {
  readonly " $data"?: UnlockCountry_OrganizationStripeAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnlockCountry_OrganizationStripeAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnlockCountry_OrganizationStripeAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingDetails_OrganizationStripeAccount"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StripePaymentMethod",
      "kind": "LinkedField",
      "name": "invoicesDefaultPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StripeCustomer",
  "abstractKey": null
};

(node as any).hash = "9a6f1ae8f0e50bb257643b082a3568ab";

export default node;
