import {
  CloudArrowDownIcon,
  DocumentCheckIcon,
  GiftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../components/FreemiumBlockerCard";
import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { GrantEquityFreemium_Organization$key } from "./__generated__/GrantEquityFreemium_Organization.graphql";
import { GrantEquityFreemium_Query } from "./__generated__/GrantEquityFreemium_Query.graphql";
import GrantEquityFreemiumIllustration from "./grant-equity-freemium-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantEquityFreemium_Organization on Organization {
    id
    name
    ...FreemiumBlockerCard_Organization
  }
`;

const GrantEquityFreemiumPage_: React.FC<{
  organizationFragment: GrantEquityFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const applicationName = useApplicationName();

  return (
    <CenteredColumnLayout showFooter>
      <FreemiumBlockerCard
        detailsList={
          <>
            <FreemiumBlockerCard.LabelWithIcon
              icon={<GiftIcon />}
              label="Draft as many grants as you want, whenever you want, compliantly"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<MagnifyingGlassIcon />}
              label={`${applicationName} team is covering you by looking at your equity and correcting potential mistakes`}
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<DocumentCheckIcon />}
              label="Get your grants approved by your board in seconds"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<CloudArrowDownIcon />}
              label="Grants are automatically implemented in Carta"
            />
          </>
        }
        mailtoSubject="About granting equity to my employees"
        organizationFragment={organization}
        rightSideIllustration={
          <div
            className={classNames(
              "bg-gradient-nightfall",
              "relative w-[453px] max-w-[50%] rounded-2xl",
            )}
          >
            <div className="absolute -right-4 bottom-0 left-[60px] top-0 flex items-center">
              <img
                alt="Grant equity freemium illustration"
                src={GrantEquityFreemiumIllustration}
              />
            </div>
          </div>
        }
        subTitle="Streamline every aspect of equity compensation management while finally mixing manual tasks and external paralegals."
        title="Get Rid of Manual Work & Legal Costs"
      />
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query GrantEquityFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...GrantEquityFreemium_Organization
    }
  }
`;

const GrantEquityFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<GrantEquityFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Grant equity freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} grant equity freemium`}
    >
      <GrantEquityFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default GrantEquityFreemiumPage;
