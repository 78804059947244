import { graphql, useFragment, useLazyLoadQuery } from "react-relay";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { RemoteEquityPlanCountryThresholdProgressBar } from "../../../../components/RemoteEquityPlanCountryThresholdProgressBar";
import { RemoteEquityPlanEmployeeThresholdProgressBar } from "../../../../components/RemoteEquityPlanEmployeeThresholdProgressBar";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { RemoteEquityPlanThresholdsExceededPage_Organization$key } from "./__generated__/RemoteEquityPlanThresholdsExceededPage_Organization.graphql";
import { RemoteEquityPlanThresholdsExceededPage_Query } from "./__generated__/RemoteEquityPlanThresholdsExceededPage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteEquityPlanThresholdsExceededPage_Organization on Organization
  @relay(plural: true) {
    id
    name
    billableGrantees {
      __typename
    }
    billableOrganizationGeographies {
      __typename
    }
    remoteEquityPlanEmployeeThreshold
    remoteEquityPlanCountryThreshold
    ...RemoteEquityPlanEmployeeThresholdProgressBar_Organization
    ...RemoteEquityPlanCountryThresholdProgressBar_Organization
  }
`;

const RemoteEquityPlanThresholdsExceededPage_: React.FC<{
  organizationsFragment: RemoteEquityPlanThresholdsExceededPage_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
          <BreadCrumb.Link to=".">
            Remote equity plan thresholds exceeded
          </BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <Typography variant="Medium/Default">
            Plan threshold passed
          </Typography>
        </div>
        <Table.Containerized className="w-full">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Organization</Table.HeaderCell>
              <Table.HeaderCell>Employees</Table.HeaderCell>
              <Table.HeaderCell>Countries</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {organizations.map((organization) => {
              return (
                <Table.Row key={organization.id}>
                  <Table.Cell>{organization.name}</Table.Cell>
                  <Table.Cell>
                    <div className="text-LG/Medium">
                      {organization.billableGrantees.length}/
                      {organization.remoteEquityPlanEmployeeThreshold}
                    </div>
                    <RemoteEquityPlanEmployeeThresholdProgressBar
                      organizationFragment={organization}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div className="text-LG/Medium">
                      {organization.billableOrganizationGeographies.length}/
                      {organization.remoteEquityPlanCountryThreshold}
                    </div>
                    <RemoteEquityPlanCountryThresholdProgressBar
                      organizationFragment={organization}
                    />
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table.Containerized>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query RemoteEquityPlanThresholdsExceededPage_Query {
    organizations(
      filters: {
        remoteEquityPlanThresholdsExceeded: true
        orderBy: { field: name, direction: ASC }
        status: Active
      }
    ) {
      ...RemoteEquityPlanThresholdsExceededPage_Organization
    }
  }
`;

const RemoteEquityPlanThresholdsExceededPage: React.FC = () => {
  const { organizations } =
    useLazyLoadQuery<RemoteEquityPlanThresholdsExceededPage_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Remote Equity Plan Thresholds Exceeded"
      title={`Super admin | remote equity plan thresholds exceeded`}
    >
      <RemoteEquityPlanThresholdsExceededPage_
        organizationsFragment={organizations}
      />
    </Page>
  );
};

export default RemoteEquityPlanThresholdsExceededPage;
