/**
 * @generated SignedSource<<41c172935bf90fc131974c5c9dabab8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_Admin_Viewer$data = {
  readonly boardMembers: ReadonlyArray<{
    readonly organization: {
      readonly id: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AdminTasksCard_BoardMember">;
  }>;
  readonly firstName: string;
  readonly isAllowedToManageOrganization: boolean;
  readonly lastName: string;
  readonly " $fragmentType": "Overview_Admin_Viewer";
};
export type Overview_Admin_Viewer$key = {
  readonly " $data"?: Overview_Admin_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_Admin_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "Overview_Admin_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AdminTasksCard_BoardMember"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "c851779ea307b2a1ecf90d44cae24ea0";

export default node;
