/**
 * @generated SignedSource<<ba7ff1666331a3b165e456fa1518f86a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_CTMSGrantAmendmentRequests_Query$variables = {
  organizationId: string;
};
export type UnderReview_CTMSGrantAmendmentRequests_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"UnderReview_CTMSGrantAmendmentRequests_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnderReview_CTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type UnderReview_CTMSGrantAmendmentRequests_Query = {
  response: UnderReview_CTMSGrantAmendmentRequests_Query$data;
  variables: UnderReview_CTMSGrantAmendmentRequests_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "UNDER_REVIEW"
  ]
},
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isUnfavorable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeletable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "CTMSGrant",
    "kind": "LinkedField",
    "name": "ctmsGrant",
    "plural": false,
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "workRelationship",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnderReview_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderReview_CTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderReview_CTMSGrantAmendmentRequests_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnderReview_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "underReviewAmendmentRequests",
            "args": [
              (v4/*: any*/)
            ],
            "concreteType": "CTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "ctmsGrantAmendmentRequests",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"UNDER_REVIEW\"])"
          },
          {
            "alias": "searchedUnderReview_CTMSGrantAmendmentRequests",
            "args": [
              {
                "kind": "Literal",
                "name": "search",
                "value": ""
              },
              (v4/*: any*/)
            ],
            "concreteType": "CTMSGrantAmendmentRequest",
            "kind": "LinkedField",
            "name": "ctmsGrantAmendmentRequests",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": "ctmsGrantAmendmentRequests(search:\"\",statusIn:[\"UNDER_REVIEW\"])"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ed3edadecd612102870f12f6d320c89",
    "id": null,
    "metadata": {},
    "name": "UnderReview_CTMSGrantAmendmentRequests_Query",
    "operationKind": "query",
    "text": "query UnderReview_CTMSGrantAmendmentRequests_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...UnderReview_CTMSGrantAmendmentRequests_Organization\n  }\n  me {\n    ...UnderReview_CTMSGrantAmendmentRequests_Account\n    id\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  id\n  status\n  isUnfavorable\n  isDeletable\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment LegalWorkflowLayout_Organization on Organization {\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment UnderReview_CTMSGrantAmendmentRequests_Account on Account {\n  isSuperAdmin\n}\n\nfragment UnderReview_CTMSGrantAmendmentRequests_Organization on Organization {\n  id\n  name\n  underReviewAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [UNDER_REVIEW]) {\n    id\n    ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n  }\n  searchedUnderReview_CTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(statusIn: [UNDER_REVIEW], search: \"\") {\n    id\n    ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n  }\n  ...LegalWorkflowLayout_Organization\n  ...CTMSGrantAmendmentRequestsTable_Organization\n}\n"
  }
};
})();

(node as any).hash = "cd0329b7894a21efbc5b2a5518f51f06";

export default node;
