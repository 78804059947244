/**
 * @generated SignedSource<<3e6cca6514cf7be1e0d96507f2346d39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsEquityGridLevelTag_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GranteeDetailsEquityGridLevelTag_Organization";
};
export type GranteeDetailsEquityGridLevelTag_Organization$key = {
  readonly " $data"?: GranteeDetailsEquityGridLevelTag_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsEquityGridLevelTag_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsEquityGridLevelTag_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "824bfb632bd3a979d17b3104c77c3cad";

export default node;
