import { CheckIcon, PlusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface Props {
  active?: boolean;
  create?: boolean;
  help?: React.ReactNode;
  label: React.ReactNode;
  selected?: boolean;
}

export const SelectRow = ({ active, create, help, label, selected }: Props) => (
  <div className="flex gap-1">
    {create && <PlusIcon className="w-4" />}

    <div>
      <div
        className={classNames(
          "truncate",
          selected ? "font-semibold" : "font-normal",
        )}
      >
        {label}
      </div>

      {help ? (
        <div
          className={classNames("truncate transition-all", {
            "text-gray-04": active,
            "text-gray-06": !active,
          })}
        >
          {help}
        </div>
      ) : null}
    </div>

    {selected && (
      <span
        className={classNames(
          "absolute inset-y-0 right-2 flex items-center",
          active ? "text-white" : "text-primary-06",
        )}
      >
        <CheckIcon aria-hidden="true" className="h-5 w-5" />
      </span>
    )}
  </div>
);
