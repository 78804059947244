import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EquityTypeWorkRelationshipData } from "../../../../../services/workRelationship";
import { AdminPortalContent_AdminPortalContent$key } from "../__generated__/AdminPortalContent_AdminPortalContent.graphql";
import { EquityTypeAdminPortalContentSlot } from "../__generated__/EquityTypeWorkRelationshipGranteePortal_SaveContent_Mutation.graphql";
import AdminPortalContent from "../AdminPortalContent";
import { GranteePortalTaxationContent_EquityType$key } from "./__generated__/GranteePortalTaxationContent_EquityType.graphql";
import { GranteePortalTaxationContent_Viewer$key } from "./__generated__/GranteePortalTaxationContent_Viewer.graphql";

const EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT = graphql`
  fragment GranteePortalTaxationContent_EquityType on EquityType {
    taxResidenceCountry {
      name
    }
    eoREmployeeTaxationGranteePortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    directEmployeeTaxationGranteePortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
    contractorTaxationGranteePortalContent {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment GranteePortalTaxationContent_Viewer on Account {
    ...AdminPortalContent_Viewer
  }
`;

const GranteePortalTaxationContent: React.FC<{
  equityTypeFragment: GranteePortalTaxationContent_EquityType$key;
  isLoading: boolean;
  onSubmit: (props: {
    content: string;
    equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot;
  }) => void;
  viewerFragment: GranteePortalTaxationContent_Viewer$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({
  equityTypeFragment,
  isLoading,
  onSubmit,
  viewerFragment,
  workRelationshipData,
}) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const { adminPortalContent, equityTypeAdminPortalContentSlot } =
    useMemo(() => {
      let adminPortalContent: AdminPortalContent_AdminPortalContent$key | null;
      let equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot;
      switch (workRelationshipData.slug) {
        case "contractor":
          adminPortalContent =
            equityType.contractorTaxationGranteePortalContent;
          equityTypeAdminPortalContentSlot =
            "contractorTaxationGranteePortalContentId";
          break;
        case "directEmployee":
          adminPortalContent =
            equityType.directEmployeeTaxationGranteePortalContent;
          equityTypeAdminPortalContentSlot =
            "directEmployeeTaxationGranteePortalContentId";
          break;
        case "eoREmployee":
          adminPortalContent =
            equityType.eoREmployeeTaxationGranteePortalContent;
          equityTypeAdminPortalContentSlot =
            "eoREmployeeTaxationGranteePortalContentId";
          break;
      }
      return { adminPortalContent, equityTypeAdminPortalContentSlot };
    }, [equityType, workRelationshipData]);

  return (
    <AdminPortalContent
      adminPortalContentFragment={adminPortalContent}
      countryName={equityType.taxResidenceCountry.name}
      isLoading={isLoading}
      onSubmit={(content: string) =>
        onSubmit({ content, equityTypeAdminPortalContentSlot })
      }
      viewerFragment={viewer}
    />
  );
};

export default GranteePortalTaxationContent;
