/**
 * @generated SignedSource<<b00e8f98beeea398ba74fbc126b25ac0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PTEP_Organization$data = {
  readonly allowAcceleration: boolean;
  readonly allowEarlyExercise: boolean;
  readonly hasCooleyAsOutsideCounsel: boolean;
  readonly id: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization" | "EarlyExerciseBeneficialNoticeMessage_Organization">;
  readonly " $fragmentType": "PTEP_Organization";
};
export type PTEP_Organization$key = {
  readonly " $data"?: PTEP_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PTEP_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PTEP_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowEarlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAcceleration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCooleyAsOutsideCounsel",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EarlyExerciseBeneficialNoticeMessage_Organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "639feb7709437637a5bdcb9318557aa3";

export default node;
