/**
 * @generated SignedSource<<e165a455a0e694e699d635d9775a23a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTermination_Grantee$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationGrantAmendmentsList_Grantee" | "GranteeTerminationInformation_Grantee" | "GranteeTerminationReview_Grantee">;
  readonly " $fragmentType": "GranteeTermination_Grantee";
};
export type GranteeTermination_Grantee$key = {
  readonly " $data"?: GranteeTermination_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTermination_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTermination_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationInformation_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationGrantAmendmentsList_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationReview_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "6c761f72005ea8d40c1e3405b0dc90eb";

export default node;
