import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";

import { Typography } from "./Typography";

type Variant = {
  cardClassName: string;
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  iconClassName: string;
};

const VARIANT: Record<"error" | "warning", Variant> = {
  error: {
    cardClassName: /* tailwind */ `bg-red-01 text-red-07`,
    icon: <ExclamationCircleIcon />,
    iconClassName: /* tailwind */ `bg-red-02 text-red-05`,
  },
  warning: {
    cardClassName: /* tailwind */ `bg-orange-01 text-orange-07`,
    icon: <ExclamationTriangleIcon />,
    iconClassName: /* tailwind */ `bg-orange-02 text-orange-05`,
  },
} as const;

export const InfoCard: React.FC<
  React.ComponentProps<"div"> & {
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    title: React.ReactNode;
    variant: keyof typeof VARIANT;
  }
> = ({ children, className, icon: _icon, title, variant, ...props }) => {
  const icon = _icon ?? VARIANT[variant].icon;

  return (
    <div
      className={classNames(
        "flex items-center gap-4 rounded-lg px-4 py-3",
        VARIANT[variant].cardClassName,
        className,
      )}
      {...props}
    >
      <div
        className={classNames(
          "rounded-full p-1",
          VARIANT[variant].iconClassName,
        )}
      >
        {React.cloneElement(icon, {
          ...icon.props,
          className: classNames(
            icon.props.className,

            /* tailwind */ `h-5 w-auto`,
          ),
        })}
      </div>
      <div>
        <Typography as="div" variant="Medium/Extra Small">
          {title}
        </Typography>
        {children && (
          <Typography as="div" variant="Regular/Extra Small">
            {children}
          </Typography>
        )}
      </div>
    </div>
  );
};
