/**
 * @generated SignedSource<<4c44bb96836fad5ee7dc78353e5d78df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GranteeEquityTypesGroupLayout_Grantee_Query$variables = {
  granteeId: string;
};
export type GranteeEquityTypesGroupLayout_Grantee_Query$data = {
  readonly grantee: {
    readonly ctmsGrants: ReadonlyArray<{
      readonly isVirtual: boolean;
      readonly taxationGranteePortalContent: {
        readonly __typename: "AdminPortalContent";
      } | null;
    }>;
    readonly id: string;
    readonly organization: {
      readonly id: string;
      readonly isConnectedToCarta: boolean;
      readonly name: string;
    };
  };
};
export type GranteeEquityTypesGroupLayout_Grantee_Query = {
  response: GranteeEquityTypesGroupLayout_Grantee_Query$data;
  variables: GranteeEquityTypesGroupLayout_Grantee_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isVirtual",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AdminPortalContent",
  "kind": "LinkedField",
  "name": "taxationGranteePortalContent",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteeEquityTypesGroupLayout_Grantee_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "CTMSGrant",
              "kind": "LinkedField",
              "name": "ctmsGrants",
              "plural": true,
              "selections": [
                (v4/*: any*/),
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteeEquityTypesGroupLayout_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7ca1eacd8da0af2fb13d94c5ef7e9da8",
    "id": null,
    "metadata": {},
    "name": "GranteeEquityTypesGroupLayout_Grantee_Query",
    "operationKind": "query",
    "text": "query GranteeEquityTypesGroupLayout_Grantee_Query(\n  $granteeId: GranteeId!\n) {\n  grantee(id: $granteeId) {\n    organization {\n      id\n      name\n      isConnectedToCarta\n    }\n    id\n    ctmsGrants {\n      isVirtual\n      taxationGranteePortalContent {\n        __typename\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc96efa876dc5ce31ceb09198927bc61";

export default node;
