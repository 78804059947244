/**
 * @generated SignedSource<<1559eedbc7035cdb92a4b07518120811>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation$variables = {
  equityGridLevelId: string;
};
export type EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation$data = {
  readonly deleteEquityGridLevel: any | null;
};
export type EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation = {
  response: EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation$data;
  variables: EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityGridLevelId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "equityGridLevelId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteEquityGridLevel",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d8e5652011acb7931ae62fea1f14dde3",
    "id": null,
    "metadata": {},
    "name": "EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation",
    "operationKind": "mutation",
    "text": "mutation EditEquityGridLevelSlideOver_DeleteEquityGridLevel_Mutation(\n  $equityGridLevelId: EquityGridLevelId!\n) {\n  deleteEquityGridLevel(id: $equityGridLevelId)\n}\n"
  }
};
})();

(node as any).hash = "da0afadffc11c6010e0fef67f6f993dc";

export default node;
