import { HelpCard } from "../../../../components/ui/HelpCard";
import { Typography } from "../../../../components/ui/Typography";

export function ConfigureGrantsLayout({
  children,
  navigation,
  rightAction,
  subtitle,
  title,
}: React.PropsWithChildren<{
  navigation?: React.ReactNode;
  rightAction?: React.ReactNode;
  subtitle?: React.ReactNode;
  title?: React.ReactNode;
}>) {
  return (
    <div className="space-y-10">
      {title && (
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="flex justify-between gap-4">
              {title && (
                <Typography as="div" variant="Medium/Large">
                  {title}
                </Typography>
              )}
              {rightAction && <div>{rightAction}</div>}
            </div>
            {subtitle && <div>{subtitle}</div>}
          </div>
          {navigation && <div>{navigation}</div>}
        </div>
      )}
      <div>{children}</div>
      <HelpCard />
    </div>
  );
}
