/**
 * @generated SignedSource<<b62ba2db6314546940b9a4f50a6376c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamIncentivization_TeamIncentivizationDataPoint$data = ReadonlyArray<{
  readonly grantees: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly valueInUSD: number;
  readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivizationGraph_TeamIncentivizationDataPoint">;
  readonly " $fragmentType": "TeamIncentivization_TeamIncentivizationDataPoint";
}>;
export type TeamIncentivization_TeamIncentivizationDataPoint$key = ReadonlyArray<{
  readonly " $data"?: TeamIncentivization_TeamIncentivizationDataPoint$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivization_TeamIncentivizationDataPoint">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamIncentivization_TeamIncentivizationDataPoint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueInUSD",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TeamIncentivizationGraph_TeamIncentivizationDataPoint"
    }
  ],
  "type": "TeamIncentivizationDataPoint",
  "abstractKey": null
};

(node as any).hash = "f8f29101d7b655772c34cb7f4817f25c";

export default node;
