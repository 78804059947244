import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { z } from "zod";

import { Page } from "../../../components/Page";
import { useToaster } from "../../../components/Toaster";
import {
  AnchorButton,
  Button,
  LinkButton,
} from "../../../components/ui/Button";
import { Divider } from "../../../components/ui/Divider";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { TextArea } from "../../../components/ui/Form/Inputs/TextArea";
import { Toast } from "../../../components/ui/Toast";
import { Typography } from "../../../components/ui/Typography";
import { useEquityOfferShareUtils } from "../../../hooks/useEquityOfferShareUtils";
import { useEquityOfferTrackEvent } from "../../../hooks/useEquityOfferTrackEvent";
import { APPLICATION_ROUTES } from "../../../paths";
import { FinalThoughtsSettings_EquityOffer$key } from "./__generated__/FinalThoughtsSettings_EquityOffer.graphql";
import { useEquityOfferContext } from "./EquityOfferContext";
import { EquityOfferPreviewLayout } from "./EquityOfferPreviewLayout";
import { EquityOfferFinalThoughts } from "./FinalThoughts";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment FinalThoughtsSettings_EquityOffer on EquityOffer {
    finalThoughts
    organization {
      id
      name
    }
    ...useEquityOfferShareUtils_EquityOffer
    ...useEquityOfferTrackEvent_EquityOffer
    ...FinalThoughts_EquityOffer
    ...EquityOfferPreviewLayout_EquityOffer
  }
`;

const formSchema = z.object({
  finalThoughts: z.string(),
});

const EquityOfferFinalThoughtsSettingsPage_: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<FinalThoughtsSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      finalThoughts: equityOffer.finalThoughts ?? "",
    },
    resolver: zodResolver(formSchema),
    shouldFocusError: false,
  });

  const onSubmit = handleSubmit(async (formInputs) => {
    await equityOfferContext.updatePartialEquityOffer({
      finalThoughts: formInputs.finalThoughts,
    });
  });

  watch(() => {
    void onSubmit();
  });

  useEffect(() => {
    equityOfferContext.onStepEntered("FINAL_THOUGHTS");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      return () => {
        equityOfferContext.trackStepCompleted("FINAL_THOUGHTS");
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { candidateOfferPDFPath, candidateOfferUrl } = useEquityOfferShareUtils(
    {
      equityOfferFragment: equityOffer,
    },
  );

  const toaster = useToaster();
  const { trackEquityOfferShared } = useEquityOfferTrackEvent({
    equityOfferFragment: equityOffer,
  });

  const onCopyLinkButtonClick = async () => {
    await navigator.clipboard.writeText(candidateOfferUrl);
    toaster.push(
      <Toast>Candidate offer link successfully copied to clipboard</Toast>,
    );
    trackEquityOfferShared("link");
  };

  return (
    <EquityOfferPreviewLayout
      equityOfferFragment={equityOffer}
      Preview={<EquityOfferFinalThoughts equityOfferFragment={equityOffer} />}
      title="Final thoughts"
    >
      <form className="block space-y-6">
        <section>
          <FormRow label="Write a few lines to finalize your offer">
            <TextArea
              {...register("finalThoughts")}
              className="h-32 resize-none"
              id="finalThoughts"
            />
          </FormRow>
        </section>
        <Divider />
        <section className="space-y-4">
          <Typography as="div" variant="Medium/Large">
            ⚡️ Share your equity offer
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Share the offer to your candidate.
          </Typography>
        </section>
        <section className="space-y-4">
          <div>
            <Input
              data-cy="candidateOfferUrl"
              onClick={(event) => {
                event.currentTarget.select();
              }}
              readOnly
              value={candidateOfferUrl}
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <Button
              fullWidth
              onClick={onCopyLinkButtonClick}
              size="small"
              type="button"
            >
              Copy link
            </Button>
            <AnchorButton
              fullWidth
              href={candidateOfferPDFPath}
              size="small"
              variant="Primary Outline"
            >
              Generate PDF version
            </AnchorButton>
          </div>
          <div>
            <LinkButton
              fullWidth
              size="small"
              to={generatePath(
                APPLICATION_ROUTES["organizationToolsEquityOffersSettings"],
                { organizationId: equityOffer.organization.id },
              )}
              variant="Secondary Full"
            >
              Back to equity offers
            </LinkButton>
          </div>
        </section>
      </form>
    </EquityOfferPreviewLayout>
  );
};

const EquityOfferFinalThoughtsSettingsPage: React.FC = () => {
  const equityOfferContext = useEquityOfferContext();
  const equityOffer = useFragment<FinalThoughtsSettings_EquityOffer$key>(
    EQUITY_OFFER_FRAGMENT,
    equityOfferContext.equityOffer,
  );
  const { organization } = equityOffer;

  return (
    <Page
      analyticsCategory="Equity Offer Configuration"
      analyticsName="Admin - Equity offer configuration - Final thoughts"
      organizationId={organization.id}
      title={`Admin | ${organization.name} equity offer final thoughts configuration`}
    >
      <EquityOfferFinalThoughtsSettingsPage_ />
    </Page>
  );
};

export default EquityOfferFinalThoughtsSettingsPage;
