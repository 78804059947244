/**
 * @generated SignedSource<<044626ab0c22021229cceda2086d3824>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantVestingScheduleWarningMessage_EasopGrant$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant">;
  readonly " $fragmentType": "DraftGrantVestingScheduleWarningMessage_EasopGrant";
};
export type DraftGrantVestingScheduleWarningMessage_EasopGrant$key = {
  readonly " $data"?: DraftGrantVestingScheduleWarningMessage_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingScheduleWarningMessage_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantVestingScheduleWarningMessage_EasopGrant",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DraftGrantVestingScheduleWarningMessage_Deferred_EasopGrant"
        }
      ]
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "62eb7cae444eb1d48f21a97d253130b9";

export default node;
