import { graphql, useFragment } from "react-relay";

import { useQuery } from "../../hooks/useQuery";
import { FormRow } from "../ui/Form/FormRow";
import { Input } from "../ui/Form/Inputs/Input";
import { Modal } from "../ui/Modal";
import { EditSafeCalculatorVersionModal_Query } from "./__generated__/EditSafeCalculatorVersionModal_Query.graphql";
import { EditSafeCalculatorVersionModal_SafeCalculatorVersion$key } from "./__generated__/EditSafeCalculatorVersionModal_SafeCalculatorVersion.graphql";
import { useSafeCalculatorVersionForm } from "./useSafeCalculatorVersionForm";

const SAFE_CALCULATOR_VERSION_FRAGMENT = graphql`
  fragment EditSafeCalculatorVersionModal_SafeCalculatorVersion on SafeCalculatorVersion {
    id
    name
  }
`;

const ModalContent: React.FC<{
  onClose: () => void;
  onSubmit: (props: { id: string; name: string }) => void;
  safeCalculatorVersionFragment: EditSafeCalculatorVersionModal_SafeCalculatorVersion$key;
}> = ({ onClose, onSubmit: _onSubmit, safeCalculatorVersionFragment }) => {
  const safeCalculatorVersion = useFragment(
    SAFE_CALCULATOR_VERSION_FRAGMENT,
    safeCalculatorVersionFragment,
  );
  const {
    control,
    formState: { isDirty, isSubmitting },
    handleSubmit,
  } = useSafeCalculatorVersionForm({
    defaultValues: { name: safeCalculatorVersion.name },
  });

  const onSubmit = handleSubmit((formData) => {
    _onSubmit({ id: safeCalculatorVersion.id, name: formData.name });
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.ActionButton
          disabled={!isDirty}
          form="edit-safe-converter-version-form"
          loading={isSubmitting}
          type="submit"
        >
          Save
        </Modal.ActionButton>
      }
      onClose={onClose}
      subTitle="Edit the SAFE converter version"
      title="Edit version"
    >
      <Modal.Form id="edit-safe-converter-version-form" onSubmit={onSubmit}>
        <FormRow.Form
          control={control}
          label="Version name"
          name="name"
          subLabel="Give meaning but keep it short"
        >
          <Input.Form
            control={control}
            name="name"
            placeholder="e.g. Simulation for new valuation"
          />
        </FormRow.Form>
      </Modal.Form>
    </Modal.Content>
  );
};

const QUERY = graphql`
  query EditSafeCalculatorVersionModal_Query($safeCalculatorVersionId: ID!) {
    safeCalculatorVersion(id: $safeCalculatorVersionId) {
      ...EditSafeCalculatorVersionModal_SafeCalculatorVersion
    }
  }
`;

export const EditSafeCalculatorVersionModal: React.FC<{
  onClose: () => void;
  onSubmit: (props: { id: string; name: string }) => void;
  safeCalculatorVersionId: string;
  show: boolean;
}> = ({ onClose, onSubmit, safeCalculatorVersionId, show }) => {
  const {
    query: { safeCalculatorVersion },
  } = useQuery<EditSafeCalculatorVersionModal_Query>(QUERY, {
    safeCalculatorVersionId,
  });

  return (
    <Modal onClose={onClose} show={show}>
      <ModalContent
        onClose={onClose}
        onSubmit={onSubmit}
        safeCalculatorVersionFragment={safeCalculatorVersion}
      />
    </Modal>
  );
};
