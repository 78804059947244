/**
 * @generated SignedSource<<4e5854d075b1d1276d4fee754c8987fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactUsModal_Viewer$data = {
  readonly email: string;
  readonly fullName: string;
  readonly " $fragmentType": "ContactUsModal_Viewer";
};
export type ContactUsModal_Viewer$key = {
  readonly " $data"?: ContactUsModal_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactUsModal_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactUsModal_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "5d49c8479f7e35b525fcf1c986398b80";

export default node;
