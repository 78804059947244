/**
 * @generated SignedSource<<38d1018cf2cc9fe38b258ce4fda4dd7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsSafeCalculatorLayout_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly safeCalculatorVersions: ReadonlyArray<{
    readonly default: boolean;
    readonly id: string;
    readonly name: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SafeCalculatorEmptyPlaceholder_Organization">;
  readonly " $fragmentType": "OrganizationSettingsSafeCalculatorLayout_Organization";
};
export type OrganizationSettingsSafeCalculatorLayout_Organization$key = {
  readonly " $data"?: OrganizationSettingsSafeCalculatorLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorLayout_Organization">;
};

import OrganizationSettingsSafeCalculatorLayout_Organization_RefetchQuery_graphql from './OrganizationSettingsSafeCalculatorLayout_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": OrganizationSettingsSafeCalculatorLayout_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "OrganizationSettingsSafeCalculatorLayout_Organization",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SafeCalculatorVersion",
      "kind": "LinkedField",
      "name": "safeCalculatorVersions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "default",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SafeCalculatorEmptyPlaceholder_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "7b3ba5d53824dcc6136ad6199bf73f26";

export default node;
