/**
 * @generated SignedSource<<ad1b9175096a3f4900854ff98c754fdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type GrantWarning = "CAP_TABLE_MISMATCH" | "GRANTEE_CONTRACT_NOT_STARTED" | "TAX_FAVORED_SUBPLAN_EXPIRED" | "VALUATION_EXPIRED" | "VALUATION_MANUALLY_INVALIDATED" | "VALUATION_MISSING" | "VALUATION_NOT_BOARD_APPROVED" | "VALUATION_OUTDATED" | "VALUATION_PENDING_BOARD_APPROVAL";
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetails_EasopGrant$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly createdAt: string;
  readonly earlyExercise: boolean;
  readonly exercisePrice: number;
  readonly exercisePriceBelowFMVSetOn: string | null;
  readonly formattedGranteeName: string;
  readonly grantStatus: EasopGrantStatus;
  readonly grantee: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Grantee">;
  };
  readonly granteeManagementCompanyName: string | null;
  readonly id: string;
  readonly instrument: {
    readonly id: string;
  };
  readonly isVirtual: boolean;
  readonly label: string;
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  };
  readonly quantityGranted: number;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
  readonly vestingSchedule: {
    readonly id: string;
    readonly name: string;
  };
  readonly vestingStartDate: string;
  readonly warnings: ReadonlyArray<GrantWarning>;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_EasopGrant">;
  readonly " $fragmentType": "DraftGrantDetails_EasopGrant";
};
export type DraftGrantDetails_EasopGrant$key = {
  readonly " $data"?: DraftGrantDetails_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetails_EasopGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetails_EasopGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warnings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "formattedGranteeName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePriceBelowFMVSetOn",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "granteeManagementCompanyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DraftGrantDetailsComponent_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsComponent_EasopGrant"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "49c452daeb2c74160e35bebc5e5987bb";

export default node;
