/**
 * @generated SignedSource<<8b8306ebba82ae7ff2ea1f8f0b7cbf2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateNewGranteeItem_Organization$data = {
  readonly id: string;
  readonly ignoredHRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_HRISProviderEmployee">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization" | "MergeEmployeeWithAnotherGranteeModal_Organization">;
  readonly " $fragmentType": "CreateNewGranteeItem_Organization";
};
export type CreateNewGranteeItem_Organization$key = {
  readonly " $data"?: CreateNewGranteeItem_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateNewGranteeItem_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeWithAnotherGranteeModal_Organization"
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedAsNewGrantee",
      "args": [
        {
          "kind": "Literal",
          "name": "ignored",
          "value": true
        }
      ],
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeForm_HRISProviderEmployee"
        }
      ],
      "storageKey": "HRISProviderEmployeesSuggestedAsNewGrantee(ignored:true)"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "15f9a8ed55d23b3e466b0e9cf017d5b4";

export default node;
