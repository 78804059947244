/**
 * @generated SignedSource<<006ff931cdbabd7326785306a9275e4a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type MergeEmployeeTable_HRISProviderEmployee$data = {
  readonly USStateOfResidence: {
    readonly code: string;
    readonly name: string;
  } | null;
  readonly email: string;
  readonly hRISProvider: HRISProvider;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly startDate: string | null;
  readonly taxResidenceCountry: {
    readonly code: string;
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "MergeEmployeeTable_HRISProviderEmployee";
};
export type MergeEmployeeTable_HRISProviderEmployee$key = {
  readonly " $data"?: MergeEmployeeTable_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeTable_HRISProviderEmployee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MergeEmployeeTable_HRISProviderEmployee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "USState",
      "kind": "LinkedField",
      "name": "USStateOfResidence",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};
})();

(node as any).hash = "2325e8c1229e6feff5e39a70f148c2be";

export default node;
