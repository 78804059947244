/**
 * @generated SignedSource<<88aec672a1d2d02f6fbdeef606e9ee44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsGrantsList_Grantee$data = {
  readonly ctmsGrants: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly id: string;
    readonly isActive: boolean;
    readonly label: string;
    readonly quantityIssued: number;
    readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantActiveOrTerminatedTag_CtmsGrant" | "CtmsGrantExerciseTag_CtmsGrant" | "CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant">;
  }>;
  readonly " $fragmentType": "GranteeDetailsGrantsList_Grantee";
};
export type GranteeDetailsGrantsList_Grantee$key = {
  readonly " $data"?: GranteeDetailsGrantsList_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsGrantsList_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsGrantsList_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cumulativeVested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityIssued",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isActive",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantActiveOrTerminatedTag_CtmsGrant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantExerciseTag_CtmsGrant"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CtmsGrantRemainingDaysToExerciseMessage_CtmsGrant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "560b15f145498bb1fb807339d008a427";

export default node;
