import {
  ArrowRightIcon,
  EyeSlashIcon,
  SparklesIcon,
  SwatchIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import {
  generatePath,
  Link,
  Navigate,
  useSearchParams,
} from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { Divider } from "../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { CleanupGrantees_Organization$key } from "./__generated__/CleanupGrantees_Organization.graphql";
import { CleanupGrantees_Query } from "./__generated__/CleanupGrantees_Query.graphql";

const ActionCard: React.FC<{
  count: number;
  description: string;
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  title: string;
  to: string;
}> = ({ count, description, icon, title, to }) => {
  const [searchParams] = useSearchParams();
  return (
    <Link
      className="group flex-1"
      to={{
        pathname: to,
        search: searchParams.toString(),
      }}
    >
      <RoundedBox
        className="flex h-full flex-col gap-4 py-4 transition-all hover:border-primary hover:shadow-200 active:shadow-100"
        withBorder
        withShadow
      >
        <div className="flex flex-grow flex-col px-4">
          <div className="flex items-center justify-between">
            <Typography variant="Semibold/Extra Large">{count}</Typography>
            {React.cloneElement(icon, {
              className: classNames(
                "h-10 w-10 text-primary",
                icon.props.className,
              ),
              ...icon.props,
            })}
          </div>
          <Typography className="pt-4" variant="Medium/Small">
            {title}
          </Typography>
          <Typography
            className="pt-2 text-black-05"
            variant="Regular/Extra Small"
          >
            {description}
          </Typography>
        </div>
        <Divider />
        <div className="flex items-center justify-between px-4 transition-all group-hover:text-primary">
          <Typography variant="Medium/Extra Small">Show items</Typography>
          <ArrowRightIcon className="h-5 text-primary" />
        </div>
      </RoundedBox>
    </Link>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment CleanupGrantees_Organization on Organization {
    id
    name
    HRISProviderEmployeesSuggestedToUpdateLinkedGrantee {
      __typename
    }
    ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee: HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(
      ignored: true
    ) {
      __typename
    }
    HRISProviderEmployeesSuggestedAsNewGrantee {
      __typename
    }
    ignoredHRISProviderEmployeesSuggestedAsNewGrantee: HRISProviderEmployeesSuggestedAsNewGrantee(
      ignored: true
    ) {
      __typename
    }
    HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee {
      __typename
    }
    ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(
      ignored: true
    ) {
      __typename
    }
  }
`;

const CleanupGrantees_: React.FC<{
  organizationFragment: CleanupGrantees_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ignoredCount =
    organization
      .ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
      .length +
    organization.ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee
      .length +
    organization.ignoredHRISProviderEmployeesSuggestedAsNewGrantee.length;
  const applicationName = useApplicationName();

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Clean up grantees</BreadCrumb.Link>
        </BreadCrumb>
      }
      fullHeight
      showFooter
    >
      <div className="space-y-6">
        <div className="space-y-2">
          <Typography as="div" variant="Medium/Default">
            Clean up grantees
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Clean up your grantee details by synchronizing {applicationName}{" "}
            grantees data with your HR systems employee data
          </Typography>
        </div>
        <div className="grid grid-cols-3 gap-6">
          {organization
            .HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
            .length > 0 && (
            <ActionCard
              count={
                organization
                  .HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee
                  .length
              }
              description={`Prevent duplicates by merging ${applicationName} grantees data with employees from your other HR solutions`}
              icon={<SparklesIcon />}
              title="Synchronize employees data"
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationSettingsCleanupGranteesSynchronizeEmployeesData"
                ],
                {
                  organizationId: organization.id,
                },
              )}
            />
          )}
          {organization.HRISProviderEmployeesSuggestedAsNewGrantee.length >
            0 && (
            <ActionCard
              count={
                organization.HRISProviderEmployeesSuggestedAsNewGrantee.length
              }
              description="Import employees data from your other HR solutions"
              icon={<UserPlusIcon />}
              title="Create new grantees directly from your HR solutions employee data"
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationSettingsCleanupGranteesCreateNewGrantees"
                ],
                {
                  organizationId: organization.id,
                },
              )}
            />
          )}
          {organization.HRISProviderEmployeesSuggestedToUpdateLinkedGrantee
            .length > 0 && (
            <ActionCard
              count={
                organization.HRISProviderEmployeesSuggestedToUpdateLinkedGrantee
                  .length
              }
              description="Data of your employees have been updated on one of your HR solutions"
              icon={<SwatchIcon />}
              title="Update information"
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationSettingsCleanupGranteesUpdateInformation"
                ],
                {
                  organizationId: organization.id,
                },
              )}
            />
          )}
          {ignoredCount > 0 && (
            <ActionCard
              count={ignoredCount}
              description="Find here the items that you ignored previously."
              icon={<EyeSlashIcon />}
              title="Ignored"
              to={generatePath(
                APPLICATION_ROUTES[
                  "organizationSettingsCleanupGranteesIgnored"
                ],
                {
                  organizationId: organization.id,
                },
              )}
            />
          )}
        </div>
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query CleanupGrantees_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      granteeCleanupSuggestionsCount
      granteeCleanupSuggestionsWithIgnoredCount: granteeCleanupSuggestionsCount(
        ignored: true
      )
      ...CleanupGrantees_Organization
    }
  }
`;

const CleanupGranteesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const [searchParams] = useSearchParams();
  const lookUpIgnoredEmployees = Boolean(searchParams.get("ignored"));

  const { query } = useQuery<CleanupGrantees_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (
    query.organization.granteeCleanupSuggestionsCount === 0 &&
    (!lookUpIgnoredEmployees ||
      query.organization.granteeCleanupSuggestionsWithIgnoredCount === 0)
  ) {
    return (
      <Navigate
        replace
        to={generatePath(APPLICATION_ROUTES["organizationHome"], {
          organizationId: query.organization.id,
        })}
      />
    );
  }

  return (
    <Page
      analyticsName="Admin - Clean Up"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} clean up grantees`}
    >
      <CleanupGrantees_ organizationFragment={query.organization} />
    </Page>
  );
};

export default CleanupGranteesPage;
