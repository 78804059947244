import { isEmpty } from "lodash";
import {
  startTransition,
  useCallback,
  useMemo,
  useState,
  useTransition,
} from "react";
import { FormattedMessage } from "react-intl";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EmptyListPlaceholder } from "../../../../../components/EmptyListPlaceholder/EmptyListPlaceholder";
import {
  NewHirePlanningEntryModal,
  useNewHirePlanningEntryModalState,
} from "../../../../../components/NewHirePlanningEntryModal";
import { PlanningEntriesTable } from "../../../../../components/PlanningEntriesTable";
import { PlanningEntrySuggestionsCollapsibleSection } from "../../../../../components/PlanningEntrySuggestionsCollapsibleSection";
import {
  RefresherGrantPlanningEntryModal,
  useRefresherGrantPlanningEntryModalState,
} from "../../../../../components/RefresherGrantPlanningEntryModal";
import {
  TerminationPlanningEntryModal,
  useTerminationPlanningEntryModalState,
} from "../../../../../components/TerminationPlanningEntryModal";
import { useToaster } from "../../../../../components/Toaster";
import { SearchBar } from "../../../../../components/ui/SearchBar";
import { Switch } from "../../../../../components/ui/Switch";
import { Toast } from "../../../../../components/ui/Toast";
import { useDebounced } from "../../../../../hooks/useDebounced";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { PlanningEditableListView_DuplicatePlanningEntry_Mutation } from "./__generated__/PlanningEditableListView_DuplicatePlanningEntry_Mutation.graphql";
import { PlanningEditableListView_IgnorePlanningEntrySuggestion_Mutation } from "./__generated__/PlanningEditableListView_IgnorePlanningEntrySuggestion_Mutation.graphql";
import { PlanningEditableListView_Organization$key } from "./__generated__/PlanningEditableListView_Organization.graphql";
import {
  PlanningEditableListView_Organization_Refetchable,
  PlanningEditableListView_Organization_Refetchable$variables,
} from "./__generated__/PlanningEditableListView_Organization_Refetchable.graphql";
import { PlanNewMenuButton } from "./PlanNewMenuButton";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningEditableListView_Organization on Organization
  @argumentDefinitions(
    search: { type: "String", defaultValue: "" }
    planningEntryType: { type: "PlanningEntryType", defaultValue: null }
    planningEntrySuggestionType: {
      type: "PlanningEntrySuggestionType"
      defaultValue: null
    }
    skipPlanningEntrySuggestions: { type: "Boolean", defaultValue: false }
  )
  @refetchable(queryName: "PlanningEditableListView_Organization_Refetchable") {
    searchOnlyPlanningEntries: planningEntries(search: $search) {
      __typename
      ... on NewHireGrantPlanningEntry {
        id
        type
      }
      ... on RefresherGrantPlanningEntry {
        id
        type
      }
      ... on TerminationPlanningEntry {
        id
        type
      }
    }
    foundPlanningEntries: planningEntries(
      search: $search
      type: $planningEntryType
    ) {
      __typename
      ... on NewHireGrantPlanningEntry {
        type
      }
      ... on RefresherGrantPlanningEntry {
        type
      }
      ... on TerminationPlanningEntry {
        type
      }
      ...PlanningEntriesTable_PlanningEntries
    }
    foundPlanningEntriesSuggestions: planningEntrySuggestions(
      search: $search
      type: $planningEntrySuggestionType
    ) @skip(if: $skipPlanningEntrySuggestions) {
      id
      type
      ...PlanningEntrySuggestionsCollapsibleSection_PlanningEntrySuggestion
      ...RefresherGrantPlanningEntryModal_PlanningEntrySuggestion
    }
    ...NewHirePlanningEntryModal_Organization
    ...RefresherGrantPlanningEntryModal_Organization
    ...TerminationPlanningEntryModal_Organization

    ...NewHirePlanningEntryModal_Organization
    ...RefresherGrantPlanningEntryModal_Organization
    ...TerminationPlanningEntryModal_Organization

    ...PlanningEntrySuggestionsCollapsibleSection_Organization
    ...PlanningEntriesTable_Organization
  }
`;

const IGNORE_PLANNING_ENTRY_SUGGESTION_MUTATION = graphql`
  mutation PlanningEditableListView_IgnorePlanningEntrySuggestion_Mutation(
    $planningEntrySuggestionId: UUID!
  ) {
    ignorePlanningEntrySuggestion(
      planningEntrySuggestionId: $planningEntrySuggestionId
    ) {
      __typename
    }
  }
`;

const DUPLICATE_PLANNING_ENTRY_MUTATION = graphql`
  mutation PlanningEditableListView_DuplicatePlanningEntry_Mutation(
    $planningEntryId: PlanningEntryId!
  ) {
    duplicatePlanningEntry(planningEntryId: $planningEntryId) {
      __typename
    }
  }
`;

const PlanningEntryTypeOptions = [
  "NEW_HIRE_GRANT",
  "TENURE_GRANT",
  "LEVELING_GRANT",
  "PROMOTION_GRANT",
  "EXCEPTIONAL_GRANT",
  "TERMINATION",
] as const;

type PlanningEntryType = (typeof PlanningEntryTypeOptions)[number];

const usePlanningEntryTypeOptionLabelMap = () => {
  const PlanningEntryTypeOptionLabelMap: Record<
    PlanningEntryType,
    (entriesCount: number) => React.ReactNode
  > = {
    EXCEPTIONAL_GRANT: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# Exceptional} other {# Exceptional}}"
        values={{
          count,
        }}
      />
    ),
    LEVELING_GRANT: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# Leveling} other {# Leveling}}"
        values={{
          count,
        }}
      />
    ),
    NEW_HIRE_GRANT: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# New Hire} other {# New Hires}}"
        values={{
          count,
        }}
      />
    ),
    PROMOTION_GRANT: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# Promotion} other {# Promotions}}"
        values={{
          count,
        }}
      />
    ),
    TENURE_GRANT: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# Tenure} other {# Tenures}}"
        values={{
          count,
        }}
      />
    ),
    TERMINATION: (count: number) => (
      <FormattedMessage
        defaultMessage="{count, plural, one {# Termination} other {# Terminations}}"
        values={{
          count,
        }}
      />
    ),
  };

  return PlanningEntryTypeOptionLabelMap;
};

function computeRefetchableFragmentVariables({
  planningEntryType,
  search,
}: {
  planningEntryType: "ALL" | PlanningEntryType;
  search?: null | string;
}): Partial<PlanningEditableListView_Organization_Refetchable$variables> {
  switch (planningEntryType) {
    case "ALL":
      return {
        planningEntrySuggestionType: null,
        planningEntryType: null,
        search,
        skipPlanningEntrySuggestions: false,
      };
    case "EXCEPTIONAL_GRANT":
    case "NEW_HIRE_GRANT":
    case "PROMOTION_GRANT":
    case "TERMINATION":
      return {
        planningEntrySuggestionType: null,
        planningEntryType: planningEntryType,
        search,
        skipPlanningEntrySuggestions: true,
      };
    case "LEVELING_GRANT":
    case "TENURE_GRANT":
      return {
        planningEntrySuggestionType: planningEntryType,
        planningEntryType: planningEntryType,
        search,
        skipPlanningEntrySuggestions: false,
      };
  }
}

export const PlanningEditableListView: React.FC<{
  organizationFragment: PlanningEditableListView_Organization$key;
}> = ({ organizationFragment }) => {
  const toaster = useToaster();

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  const [organization, refetchOrganization] = useRefetchableFragment<
    PlanningEditableListView_Organization_Refetchable,
    PlanningEditableListView_Organization$key
  >(ORGANIZATION_FRAGMENT, organizationFragment);

  const searchOnlyPlanningEntries = useMemo(
    () =>
      organization.searchOnlyPlanningEntries.map((entry) => {
        if (entry.__typename === "%other") {
          throw new Error(`Unexpected entry type`);
        }
        return entry;
      }),
    [organization.searchOnlyPlanningEntries],
  );

  const [selectedPlanningEntryType, setSelectedPlanningEntryType] = useState<
    "ALL" | PlanningEntryType
  >("ALL");

  const onDebounce = useCallback(
    (search: string) => {
      startSearchTransition(() => {
        refetchOrganization(
          computeRefetchableFragmentVariables({
            planningEntryType: selectedPlanningEntryType,
            search,
          }),
        );
      });
    },
    [refetchOrganization, selectedPlanningEntryType],
  );

  const {
    isDebouncing: searchIsDebouncing,
    liveState: liveSearch,
    setState: setSearch,
  } = useDebounced({
    initialState: "",
    onDebounce,
  });

  const handleSelectedPlanningEntryTypeChange = useCallback(
    (planningEntryType: "ALL" | PlanningEntryType) => {
      startSearchTransition(() => {
        setSelectedPlanningEntryType(planningEntryType);
        refetchOrganization(
          computeRefetchableFragmentVariables({
            planningEntryType,
            search: liveSearch,
          }),
        );
      });
    },
    [liveSearch, refetchOrganization],
  );

  const refetchOrganizationWithCurrentVariables = useCallback(() => {
    refetchOrganization(
      computeRefetchableFragmentVariables({
        planningEntryType: selectedPlanningEntryType,
        search: liveSearch,
      }),
    );
  }, [liveSearch, refetchOrganization, selectedPlanningEntryType]);

  const PlanningEntryTypeOptionLabelMap = usePlanningEntryTypeOptionLabelMap();

  const getPlanningEntryTypeLabel = useCallback(
    (planningEntryType: PlanningEntryType) => {
      const entriesCount = searchOnlyPlanningEntries.filter(
        (entry) => entry.type === planningEntryType,
      ).length;

      return PlanningEntryTypeOptionLabelMap[planningEntryType](entriesCount);
    },
    [searchOnlyPlanningEntries, PlanningEntryTypeOptionLabelMap],
  );

  const {
    closeNewHirePlanningEntryModal,
    newHirePlanningEntryModalState,
    openNewHirePlanningEntryModalInCreateMode,
  } = useNewHirePlanningEntryModalState();

  const {
    closeTerminationPlanningEntryModal,
    openTerminationPlanningEntryModalInCreateMode,
    terminationPlanningEntryModalState,
  } = useTerminationPlanningEntryModalState();

  const {
    closeRefresherGrantPlanningEntryModal,
    openRefresherGrantPlanningEntryModalInCreateMode,
    refresherGrantPlanningEntryModalState,
  } = useRefresherGrantPlanningEntryModalState();

  const handlePlanningEntrySuggestionPlanClick = useCallback(
    (planningEntrySuggestionId: string) => {
      const planningEntrySuggestion =
        organization.foundPlanningEntriesSuggestions?.find(
          (planningEntrySuggestion) =>
            planningEntrySuggestion.id === planningEntrySuggestionId,
        );

      if (!planningEntrySuggestion) {
        throw new Error("Planning entry suggestion not found");
      }

      switch (planningEntrySuggestion.type) {
        case "LEVELING_GRANT":
        case "TENURE_GRANT":
          openRefresherGrantPlanningEntryModalInCreateMode({
            planningEntrySuggestionFragment: planningEntrySuggestion,
            refresherGrantPlanningEntryType: planningEntrySuggestion.type,
          });
          break;
      }
    },
    [
      organization.foundPlanningEntriesSuggestions,
      openRefresherGrantPlanningEntryModalInCreateMode,
    ],
  );

  const [_ignorePlanningEntrySuggestion] =
    useSafeMutation<PlanningEditableListView_IgnorePlanningEntrySuggestion_Mutation>(
      IGNORE_PLANNING_ENTRY_SUGGESTION_MUTATION,
    );

  const handlePlanningEntrySuggestionIgnoreClick = useCallback(
    async (planningEntrySuggestionId: string) => {
      await _ignorePlanningEntrySuggestion({
        variables: {
          planningEntrySuggestionId,
        },
      });

      startTransition(() => {
        refetchOrganizationWithCurrentVariables();
      });
    },
    [_ignorePlanningEntrySuggestion, refetchOrganizationWithCurrentVariables],
  );

  const [_duplicatePlanningEntry] =
    useSafeMutation<PlanningEditableListView_DuplicatePlanningEntry_Mutation>(
      DUPLICATE_PLANNING_ENTRY_MUTATION,
    );

  const handleDuplicatePlanningEntryClick = useCallback(
    async (planningEntryId: string) => {
      await _duplicatePlanningEntry({
        variables: {
          planningEntryId,
        },
      });

      toaster.push(
        <Toast title="Wonderful!">
          Planning entry successfully duplicated!
        </Toast>,
      );

      startTransition(() => {
        refetchOrganizationWithCurrentVariables();
      });
    },
    [_duplicatePlanningEntry, refetchOrganizationWithCurrentVariables, toaster],
  );

  const planNewMenuButton = (
    <PlanNewMenuButton
      onExceptionalGrantClick={() =>
        openRefresherGrantPlanningEntryModalInCreateMode({
          planningEntrySuggestionFragment: null,
          refresherGrantPlanningEntryType: "EXCEPTIONAL_GRANT",
        })
      }
      onNewHireGrantClick={openNewHirePlanningEntryModalInCreateMode}
      onPromotionGrantClick={() =>
        openRefresherGrantPlanningEntryModalInCreateMode({
          planningEntrySuggestionFragment: null,
          refresherGrantPlanningEntryType: "PROMOTION_GRANT",
        })
      }
      onTenureGrantClick={() =>
        openRefresherGrantPlanningEntryModalInCreateMode({
          planningEntrySuggestionFragment: null,
          refresherGrantPlanningEntryType: "TENURE_GRANT",
        })
      }
      onTerminationClick={openTerminationPlanningEntryModalInCreateMode}
    />
  );

  const planningEntrySuggestions =
    organization.foundPlanningEntriesSuggestions?.filter(
      (planningEntrySuggestion) =>
        selectedPlanningEntryType === "ALL" ||
        planningEntrySuggestion.type === selectedPlanningEntryType,
    );

  const handleNewHirePlanningEntryModalClose = useCallback(() => {
    closeNewHirePlanningEntryModal();
    startTransition(() => {
      refetchOrganizationWithCurrentVariables();
    });
  }, [closeNewHirePlanningEntryModal, refetchOrganizationWithCurrentVariables]);

  const handleTerminationPlanningEntryModalClose = useCallback(() => {
    closeTerminationPlanningEntryModal();
    startTransition(() => {
      refetchOrganizationWithCurrentVariables();
    });
  }, [
    closeTerminationPlanningEntryModal,
    refetchOrganizationWithCurrentVariables,
  ]);

  const handleRefresherGrantPlanningEntryModalClose = useCallback(() => {
    closeRefresherGrantPlanningEntryModal();
    startTransition(() => {
      refetchOrganizationWithCurrentVariables();
    });
  }, [
    closeRefresherGrantPlanningEntryModal,
    refetchOrganizationWithCurrentVariables,
  ]);

  return (
    <>
      <NewHirePlanningEntryModal
        onClose={handleNewHirePlanningEntryModalClose}
        organizationFragment={organization}
        state={newHirePlanningEntryModalState}
      />
      <TerminationPlanningEntryModal
        onClose={handleTerminationPlanningEntryModalClose}
        organizationFragment={organization}
        state={terminationPlanningEntryModalState}
      />
      <RefresherGrantPlanningEntryModal
        onClose={handleRefresherGrantPlanningEntryModalClose}
        organizationFragment={organization}
        state={refresherGrantPlanningEntryModalState}
      />
      <div className="space-y-4">
        <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
          {planNewMenuButton}
          <SearchBar
            className="w-full max-w-[300px]"
            loading={searchTransitionIsInProgress || searchIsDebouncing}
            onChange={setSearch}
            placeholder="Search"
            value={liveSearch}
          />
          <Switch
            getOptionLabel={(option) =>
              option === "ALL" ? "All" : getPlanningEntryTypeLabel(option)
            }
            getOptionValue={(option) => option}
            loading={searchTransitionIsInProgress}
            name="planning-entry-type"
            onChange={handleSelectedPlanningEntryTypeChange}
            options={["ALL", ...PlanningEntryTypeOptions] as const}
            selectedOption={selectedPlanningEntryType}
          />
        </div>
        {planningEntrySuggestions && !isEmpty(planningEntrySuggestions) && (
          <PlanningEntrySuggestionsCollapsibleSection
            onPlanningEntrySuggestionIgnoreClick={
              handlePlanningEntrySuggestionIgnoreClick
            }
            onPlanningEntrySuggestionPlanClick={
              handlePlanningEntrySuggestionPlanClick
            }
            organizationFragment={organization}
            planningEntrySuggestionsFragment={planningEntrySuggestions}
          />
        )}

        {isEmpty(organization.foundPlanningEntries) ? (
          <EmptyListPlaceholder
            action={planNewMenuButton}
            subtitle="Feel free to add new hire grants, tenure grants or terminations in your planning"
            title="No equity planned yet"
          />
        ) : (
          <PlanningEntriesTable
            onDuplicatePlanningEntryClick={handleDuplicatePlanningEntryClick}
            organizationFragment={organization}
            planningEntriesFragment={organization.foundPlanningEntries}
          />
        )}
      </div>
    </>
  );
};
