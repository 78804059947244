import { useCallback, useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay";
import {
  FetchPolicy,
  fetchQuery,
  GraphQLTaggedNode,
  OperationType,
  VariablesOf,
} from "relay-runtime";

export function useQuery<TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
  initialFetchPolicy = "store-and-network" as FetchPolicy,
): {
  query: TQuery["response"];
  refreshQuery: () => void;
  refreshQueryWithoutSuspense: () => void;
} {
  const [options, setOptions] = useState({
    fetchKey: 0,
    fetchPolicy: initialFetchPolicy,
  });

  const refreshQuery = useCallback(() => {
    setOptions((options) => ({
      fetchKey: options.fetchKey + 1,
      fetchPolicy: "network-only",
    }));
  }, []);

  const environment = useRelayEnvironment();
  const refreshQueryWithoutSuspense = useCallback(() => {
    // @ts-expect-error - relay-runtime types are not up-to-date
    fetchQuery(environment, gqlQuery, variables).subscribe({});
  }, [environment, gqlQuery, variables]);

  const query = useLazyLoadQuery<TQuery>(gqlQuery, variables, options);

  return { query, refreshQuery, refreshQueryWithoutSuspense };
}
