/**
 * @generated SignedSource<<2edbdab23708c66b81a3423a12da6cea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation$variables = {
  message: string;
  organizationId: string;
};
export type useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation$data = {
  readonly notifyInSlackChannel: any | null;
};
export type useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation = {
  response: useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation$data;
  variables: useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "message"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "channel",
        "value": "equity_alerting"
      },
      {
        "kind": "Variable",
        "name": "message",
        "variableName": "message"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "notifyInSlackChannel",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "197e610f0e937c6f789c1a11c39dff6d",
    "id": null,
    "metadata": {},
    "name": "useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation",
    "operationKind": "mutation",
    "text": "mutation useMismatchBetweenCtmsAndEasopCapTableGuard_NotifyInSlackChannel_Mutation(\n  $organizationId: OrganizationId!\n  $message: String!\n) {\n  notifyInSlackChannel(organizationId: $organizationId, message: $message, channel: equity_alerting)\n}\n"
  }
};
})();

(node as any).hash = "e134db59d591d1e62e1a3e251e2c1596";

export default node;
