/**
 * @generated SignedSource<<75d76cfb28204752f7b617bbf6359b47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation$variables = {
  granteeIds: ReadonlyArray<string>;
  organizationId: string;
};
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation$data = {
  readonly grantAccessToGranteePortal: ReadonlyArray<{
    readonly __typename: "Grantee";
  }>;
};
export type InviteGranteesModal_GrantAccessToGranteePortal_Mutation = {
  response: InviteGranteesModal_GrantAccessToGranteePortal_Mutation$data;
  variables: InviteGranteesModal_GrantAccessToGranteePortal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "granteeIds",
    "variableName": "granteeIds"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantAccessToGranteePortal",
        "plural": true,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantAccessToGranteePortal",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb8de3f895c70be8e429d820add4631e",
    "id": null,
    "metadata": {},
    "name": "InviteGranteesModal_GrantAccessToGranteePortal_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteGranteesModal_GrantAccessToGranteePortal_Mutation(\n  $organizationId: OrganizationId!\n  $granteeIds: [GranteeId!]!\n) {\n  grantAccessToGranteePortal(organizationId: $organizationId, granteeIds: $granteeIds, source: \"Remote Equity Web App\") {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "428eb0a14ed4f0283695af1ff082be9b";

export default node;
