/**
 * @generated SignedSource<<1ab26997d8615a4a0aa18bffc7c31d9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
export type ChangeGrantInstrumentForm_Instruments_Query$variables = {
  countryCode?: string | null;
  workRelationship?: WorkRelationship | null;
};
export type ChangeGrantInstrumentForm_Instruments_Query$data = {
  readonly instruments: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type ChangeGrantInstrumentForm_Instruments_Query = {
  response: ChangeGrantInstrumentForm_Instruments_Query$data;
  variables: ChangeGrantInstrumentForm_Instruments_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workRelationship"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "countryCode",
        "variableName": "countryCode"
      },
      {
        "kind": "Variable",
        "name": "workRelationship",
        "variableName": "workRelationship"
      }
    ],
    "concreteType": "Instrument",
    "kind": "LinkedField",
    "name": "instruments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeGrantInstrumentForm_Instruments_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeGrantInstrumentForm_Instruments_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9348c790a6b695baf1d7a61a792c7ad1",
    "id": null,
    "metadata": {},
    "name": "ChangeGrantInstrumentForm_Instruments_Query",
    "operationKind": "query",
    "text": "query ChangeGrantInstrumentForm_Instruments_Query(\n  $countryCode: String\n  $workRelationship: WorkRelationship\n) {\n  instruments(countryCode: $countryCode, workRelationship: $workRelationship) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddb7fe6582455a875f2197c1f2b4e5a5";

export default node;
