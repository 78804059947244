/**
 * @generated SignedSource<<15a65f2f8eab3c31236775e31fdd9b12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ApprovalSlideOverContent_Query$variables = {
  signatureRequestId: string;
};
export type ApprovalSlideOverContent_Query$data = {
  readonly signatureRequest: {
    readonly pandadocSignatureSessionURL: {
      readonly url?: string;
    };
  };
};
export type ApprovalSlideOverContent_Query = {
  response: ApprovalSlideOverContent_Query$data;
  variables: ApprovalSlideOverContent_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "signatureRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "signatureRequestId"
  }
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "PandadocSignatureSessionURLSuccessOutput",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApprovalSlideOverContent_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequest",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "pandadocSignatureSessionURL",
              "plural": false,
              "selections": [
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApprovalSlideOverContent_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SignatureRequest",
        "kind": "LinkedField",
        "name": "signatureRequest",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pandadocSignatureSessionURL",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23dd2376172cce98c13670b7ee422380",
    "id": null,
    "metadata": {},
    "name": "ApprovalSlideOverContent_Query",
    "operationKind": "query",
    "text": "query ApprovalSlideOverContent_Query(\n  $signatureRequestId: UUID!\n) {\n  signatureRequest(id: $signatureRequestId) {\n    pandadocSignatureSessionURL {\n      __typename\n      ... on PandadocSignatureSessionURLSuccessOutput {\n        url\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f573c09ffd9870d8f0f143ae9b21b6c1";

export default node;
