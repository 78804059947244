import classNames from "classnames";
import { useMemo } from "react";

import { Typography } from "./Typography";

const computeStringChecksum = (string: string) =>
  string.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);

const computeColorBasedStyle = (string: string) =>
  ColorBasedStyle[
    Colors[computeStringChecksum(string) % Colors.length] ?? "Brand"
  ];

const Colors = [
  "Brand",
  "Light Blue",
  "Cyan",
  "Green",
  "Light Green",
  "Purple",
  "Pink",
  "Fuchsia",
  "Red",
  "Orange",
  "Yellow",
] as const;

type Color = (typeof Colors)[number];

const ColorBasedStyle: Record<Color, string> = {
  Brand: /* tailwind */ "bg-remote-brand-100 text-remote-brand-600",
  Cyan: /* tailwind */ "bg-remote-cyan-100 text-remote-cyan-600",
  Fuchsia: /* tailwind */ "bg-remote-fuchsia-100 text-remote-fuchsia-600",
  Green: /* tailwind */ "bg-remote-green-100 text-remote-green-600",
  "Light Blue":
    /* tailwind */ "bg-remote-blueLight-100 text-remote-blueLight-600",
  "Light Green":
    /* tailwind */ "bg-remote-greenLight-100 text-remote-greenLight-600",
  Orange: /* tailwind */ "bg-remote-orange-100 text-remote-orange-600",
  Pink: /* tailwind */ "bg-remote-pink-100 text-remote-pink-600",
  Purple: /* tailwind */ "bg-remote-purple-100 text-remote-purple-600",
  Red: /* tailwind */ "bg-remote-red-100 text-remote-red-600",
  Yellow: /* tailwind */ "bg-remote-yellow-100 text-remote-yellow-600",
};

export const Avatar = ({ children }: { children: string }) => {
  const colorBasedStyle = useMemo(
    () => computeColorBasedStyle(children),
    [children],
  );

  return (
    <Typography
      className={classNames(
        "flex h-8 w-8 select-none items-center justify-center rounded-full",
        colorBasedStyle,
      )}
      variant="Semibold/Caption"
    >
      {children.charAt(0)}
    </Typography>
  );
};
