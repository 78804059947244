import React from "react";
import { FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import {
  PostTerminationExercisePeriod,
  PtepDurationUnit,
} from "../helpers/ptep-utils";
import { Duration_PostTerminationExercisePeriod$key } from "./__generated__/Duration_PostTerminationExercisePeriod.graphql";

const PTEP_FRAGMENT = graphql`
  fragment Duration_PostTerminationExercisePeriod on PostTerminationExercisePeriod {
    duration
    unit
  }
`;

const _Duration: React.FC<
  | {
      duration: number;
      durationUnit: PtepDurationUnit;
      postTerminationExercisePeriod?: undefined;
    }
  | {
      duration?: undefined;
      durationUnit?: undefined;
      postTerminationExercisePeriod: PostTerminationExercisePeriod;
    }
> = ({ duration, durationUnit, postTerminationExercisePeriod }) => {
  if (postTerminationExercisePeriod) {
    return (
      <FormattedNumber
        style="unit"
        unit={postTerminationExercisePeriod.unit}
        unitDisplay="long"
        value={postTerminationExercisePeriod.duration}
      />
    );
  } else {
    return (
      <FormattedNumber
        style="unit"
        unit={durationUnit}
        unitDisplay="long"
        value={duration}
      />
    );
  }
};

const DurationFromFragment: React.FC<{
  postTerminationExercisePeriodFragment: Duration_PostTerminationExercisePeriod$key;
}> = ({ postTerminationExercisePeriodFragment }) => {
  const postTerminationExercisePeriod = useFragment(
    PTEP_FRAGMENT,
    postTerminationExercisePeriodFragment,
  );

  return (
    <_Duration postTerminationExercisePeriod={postTerminationExercisePeriod} />
  );
};

export const Duration = Object.assign(_Duration, {
  FromFragment: DurationFromFragment,
});
