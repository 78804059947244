/**
 * @generated SignedSource<<26e1a7edb1ad98d286d6a82301325196>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type TaxDetailsContent_CTMSGrant$data = {
  readonly grantee: {
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    } | null;
    readonly workRelationship: WorkRelationship | null;
  };
  readonly matchingInstrument: {
    readonly equityType: {
      readonly exerciseParameters: {
        readonly contractor: {
          readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_ExerciseParameters">;
        } | null;
        readonly directEmployee: {
          readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_ExerciseParameters">;
        } | null;
        readonly eoREmployee: {
          readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_ExerciseParameters">;
        } | null;
      };
    } | null;
  } | null;
  readonly " $fragmentType": "TaxDetailsContent_CTMSGrant";
};
export type TaxDetailsContent_CTMSGrant$key = {
  readonly " $data"?: TaxDetailsContent_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "TaxDetailsContent_ExerciseParameters"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxDetailsContent_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EquityTypeExerciseParameters",
              "kind": "LinkedField",
              "name": "exerciseParameters",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                  "kind": "LinkedField",
                  "name": "directEmployee",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                  "kind": "LinkedField",
                  "name": "eoREmployee",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
                  "kind": "LinkedField",
                  "name": "contractor",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "c7a5cc534f2e07b88701df02ca826b8b";

export default node;
