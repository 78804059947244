import { Typography } from "../../../components/ui/Typography";
import angelaPicture from "./angela.png";
import sliteBlackLogo from "./slite-black.png";

export const TestimonialCard: React.FC = () => (
  <div className="z-10 w-full max-w-[800px] space-y-2 justify-self-end rounded-lg border-[0.5px] border-gray-04 bg-gradient-light-aubergine px-6 py-4">
    <Typography as="div" variant="Regular/Extra Small">
      “Easop is the best company I’ve ever dealt with when it comes to
      international employee education for equity compensation. They are always
      up-to-date on the latest regulations, and they make the process easy for
      us.”
    </Typography>
    <div className="flex items-center gap-4">
      <img
        alt="Angela Legras"
        className="w-full max-w-[40px]"
        src={angelaPicture}
      />
      <div className="flex flex-1 items-center gap-2">
        <Typography variant="Medium/Caption">Angela Legras</Typography>
        <Typography className="text-black-05" variant="Regular/Caption">
          Finance & Admin Specialist
        </Typography>
      </div>
      <img alt="Slite" className="w-full max-w-[60px]" src={sliteBlackLogo} />
    </div>
  </div>
);
