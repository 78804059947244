/**
 * @generated SignedSource<<1c1c387927f54dc262afb608bdf2159a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PlanningEntryDetailsSlideOver_Organization$data = {
  readonly fullyDilutedShares: number | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"NewHireGrantPlanningEntryDetails_Organization" | "NewHirePlanningEntryModal_Organization" | "RefresherGrantPlanningEntryDetails_Organization" | "RefresherGrantPlanningEntryModal_Organization" | "TerminationPlanningEntryDetails_Organization" | "TerminationPlanningEntryModal_Organization">;
  readonly " $fragmentType": "PlanningEntryDetailsSlideOver_Organization";
};
export type PlanningEntryDetailsSlideOver_Organization$key = {
  readonly " $data"?: PlanningEntryDetailsSlideOver_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntryDetailsSlideOver_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PlanningEntryDetailsSlideOver_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullyDilutedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewHireGrantPlanningEntryDetails_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefresherGrantPlanningEntryDetails_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TerminationPlanningEntryDetails_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewHirePlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TerminationPlanningEntryModal_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RefresherGrantPlanningEntryModal_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "419a2f404d9c397a01940af458bcf59b";

export default node;
