import { graphql, useFragment } from "react-relay";

import { Button } from "../../../../components/ui/Button";
import { Typography } from "../../../../components/ui/Typography";
import { useStripeCustomerPortal } from "../../../../hooks/useStripeCustomerPortal";
import { BillingDetails_Organization$key } from "./__generated__/BillingDetails_Organization.graphql";
import { BillingDetails_OrganizationStripeAccount$key } from "./__generated__/BillingDetails_OrganizationStripeAccount.graphql";
import { StripePaymentMethodExpiration } from "./StripePaymentMethodExpiration";
import { StripePaymentMethodLabel } from "./StripePaymentMethodLabel";
import { StripePaymentMethodLogo } from "./StripePaymentMethodLogo";

const ORGANIZATION_STRIPE_ACCOUNT_FRAGMENT = graphql`
  fragment BillingDetails_OrganizationStripeAccount on StripeCustomer {
    email
    invoicesDefaultPaymentMethod {
      ...StripePaymentMethodExpiration_StripePaymentMethod
      ...StripePaymentMethodLabel_StripePaymentMethod
      ...StripePaymentMethodLogo_StripePaymentMethod
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment BillingDetails_Organization on Organization {
    id
    subscriptionBillingPeriod
  }
`;

const Row: React.FC<{
  label: React.ReactNode;
  value: React.ReactNode;
}> = ({ label, value }) => (
  <div className="flex items-center justify-between gap-2 py-4">
    <Typography as="div" className="text-gray-09" variant="Regular/Extra Small">
      {label}
    </Typography>
    <Typography as="div" className="text-right" variant="Medium/Extra Small">
      {value}
    </Typography>
  </div>
);

export const BillingDetails: React.FC<{
  organizationFragment: BillingDetails_Organization$key;
  organizationStripeAccountFragment: BillingDetails_OrganizationStripeAccount$key;
}> = ({ organizationFragment, organizationStripeAccountFragment }) => {
  const organizationStripeAccount = useFragment(
    ORGANIZATION_STRIPE_ACCOUNT_FRAGMENT,
    organizationStripeAccountFragment,
  );
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { openStripeCustomerPortal } = useStripeCustomerPortal({
    organizationId: organization.id,
    target: "_self",
  });

  return (
    <div className="space-y-4">
      <Typography variant="Medium/Small">Billing details</Typography>
      <div className="divide-y-[0.5px] divide-gray-04 rounded-lg border-[0.5px] border-gray-04 px-4 py-2">
        {organizationStripeAccount.invoicesDefaultPaymentMethod && (
          <>
            <Row
              label="Payment method"
              value={
                <StripePaymentMethodLogo
                  className="h-5.5"
                  stripePaymentMethodFragment={
                    organizationStripeAccount.invoicesDefaultPaymentMethod
                  }
                />
              }
            />
            <Row
              label="Card details"
              value={
                <div className="space-y-1">
                  <div>
                    <StripePaymentMethodLabel
                      stripePaymentMethodFragment={
                        organizationStripeAccount.invoicesDefaultPaymentMethod
                      }
                    />
                  </div>
                  <Typography
                    className="text-black-05"
                    variant="Regular/Caption"
                  >
                    <StripePaymentMethodExpiration
                      stripePaymentMethodFragment={
                        organizationStripeAccount.invoicesDefaultPaymentMethod
                      }
                    />
                  </Typography>
                </div>
              }
            />
          </>
        )}
        <Row
          label="Billing frequency"
          value={
            organization.subscriptionBillingPeriod === "monthly"
              ? "Monthly"
              : "Yearly"
          }
        />
        <Row
          label="Billing email"
          value={organizationStripeAccount.email ?? "-"}
        />
        <div className="space-y-4 py-4 text-right">
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Caption"
          >
            Update billing details and payment method by clicking the button
            below
          </Typography>
          <Button
            onClick={openStripeCustomerPortal}
            size="small"
            type="button"
            variant="Secondary Full"
          >
            Update billing details
          </Button>
        </div>
      </div>
    </div>
  );
};
