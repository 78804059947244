/**
 * @generated SignedSource<<9835e9d92f86c6be9f8dc230a73f2e3a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsPage_Query$variables = Record<PropertyKey, never>;
export type LegalWatchOutsPage_Query$data = {
  readonly equityTypes: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsPage_EquityType">;
  }>;
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsPage_Organization">;
  }>;
};
export type LegalWatchOutsPage_Query = {
  response: LegalWatchOutsPage_Query$data;
  variables: LegalWatchOutsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filters",
    "value": {
      "orderBy": {
        "direction": "ASC",
        "field": "name"
      },
      "status": "Active"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastUpdatedAt",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalWatchOutsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LegalWatchOutsPage_EquityType"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LegalWatchOutsPage_Organization"
          }
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"status\":\"Active\"})"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LegalWatchOutsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityTypes",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForDirectEmployee",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForEoREmployee",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForContractor",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": "organizations(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"},\"status\":\"Active\"})"
      }
    ]
  },
  "params": {
    "cacheID": "6f2e5bfd81a07109f54fd2a5cec9f15b",
    "id": null,
    "metadata": {},
    "name": "LegalWatchOutsPage_Query",
    "operationKind": "query",
    "text": "query LegalWatchOutsPage_Query {\n  equityTypes {\n    ...LegalWatchOutsPage_EquityType\n    id\n  }\n  organizations(filters: {status: Active, orderBy: {field: name, direction: ASC}}) {\n    ...LegalWatchOutsPage_Organization\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment EquityTypesLegalWatchOutsTable_EquityType on EquityType {\n  id\n  name\n  taxResidenceCountry {\n    name\n    ...CountryFlag_Country\n  }\n  legalWatchOutsForDirectEmployee {\n    lastUpdatedAt\n  }\n  legalWatchOutsForEoREmployee {\n    lastUpdatedAt\n  }\n  legalWatchOutsForContractor {\n    lastUpdatedAt\n  }\n}\n\nfragment LegalWatchOutsPage_EquityType on EquityType {\n  ...EquityTypesLegalWatchOutsTable_EquityType\n}\n\nfragment LegalWatchOutsPage_Organization on Organization {\n  id\n  name\n  ...SelectOrganizationModal_Organization\n}\n\nfragment SelectOrganizationModal_Organization on Organization {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "aa8ad198ace1be14216f2a5e26dbb083";

export default node;
