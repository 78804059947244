import { graphql, useFragment } from "react-relay";

import { useApplicationSupportEmailAddress } from "../hooks/useApplicationTheme";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../services/workRelationship";
import { EarlyExerciseBeneficialNoticeMessage_Instrument$key } from "./__generated__/EarlyExerciseBeneficialNoticeMessage_Instrument.graphql";
import { EarlyExerciseBeneficialNoticeMessage_Organization$key } from "./__generated__/EarlyExerciseBeneficialNoticeMessage_Organization.graphql";
import { CountryFlag } from "./CountryFlag";
import { NoticeMessage } from "./ui/NoticeMessage";

const INSTRUMENT_FRAGMENT = graphql`
  fragment EarlyExerciseBeneficialNoticeMessage_Instrument on Instrument {
    workRelationship
    taxResidenceCountry {
      name
      emoji
      ...CountryFlag_Country
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EarlyExerciseBeneficialNoticeMessage_Organization on Organization {
    name
  }
`;

export const EarlyExerciseBeneficialNoticeMessage: React.FC<{
  hasColor?: boolean;
  instrumentFragment: EarlyExerciseBeneficialNoticeMessage_Instrument$key;
  organizationFragment: EarlyExerciseBeneficialNoticeMessage_Organization$key;
}> = ({ hasColor = true, instrumentFragment, organizationFragment }) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const workRelationshipLabel =
    WORK_RELATIONSHIP_TO_LABEL_HELPER[instrument.workRelationship].pluralLabel;

  const country = instrument.taxResidenceCountry;
  const supportEmailAddress = useApplicationSupportEmailAddress();

  const mailtoSubjectEarlyExerciseBeneficial = encodeURI(
    `[${organization.name}] How early exercise could be beneficial for ${workRelationshipLabel} in ${country.name} ${country.emoji}?`,
  );

  return (
    <NoticeMessage hasColor={hasColor} size="Large" variant="Positive">
      Early exercise could be beneficial for {workRelationshipLabel} in{" "}
      {country.name} <CountryFlag countryFragment={country} />.
      <br />
      Read{" "}
      <a
        className="font-medium text-primary"
        href="https://www.easop.com/blog/should-you-allow-your-international-employees-to-early-exercise-their-stock-options"
        rel="noreferrer"
        target="_blank"
      >
        this
      </a>{" "}
      or{" "}
      <a
        className="font-medium text-primary"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubjectEarlyExerciseBeneficial}`}
      >
        contact us
      </a>{" "}
      for more info.
    </NoticeMessage>
  );
};
