/**
 * @generated SignedSource<<2b1e377dc0ca472c307eacec1e7498d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectOrganizationModal_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "SelectOrganizationModal_Organization";
}>;
export type SelectOrganizationModal_Organization$key = ReadonlyArray<{
  readonly " $data"?: SelectOrganizationModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectOrganizationModal_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SelectOrganizationModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "c91d11c406bd1e6fdf0de68c25fd46fc";

export default node;
