import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { FolderAdd } from "iconsax-react";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDropArea } from "react-use";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { isNonEmptyArray } from "../../../helpers/ts-utlity";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import {
  BulkUploadDrafts_Mutation,
  BulkUploadDrafts_Mutation$data,
} from "./__generated__/BulkUploadDrafts_Mutation.graphql";
import { BulkUploadDrafts_Query } from "./__generated__/BulkUploadDrafts_Query.graphql";

const QUERY = graphql`
  query BulkUploadDrafts_Query {
    organizations {
      id
      name
    }
  }
`;

const MUTATION = graphql`
  mutation BulkUploadDrafts_Mutation(
    $organizationId: OrganizationId!
    $base64XLSX: String!
  ) {
    bulkUploadDraftsXLSX(
      base64XLSX: $base64XLSX
      organizationId: $organizationId
    ) {
      easopGrantsCreated {
        id
        label
      }
      errors {
        line
        code
        error
      }
    }
  }
`;

const XLSXFileInput: React.FC<{
  invalid: boolean;
  onChange: (props: { content: string; fileName: string }) => void;
  onError: () => void;
}> = ({ invalid, onChange, onError }) => {
  const readFiles = useCallback(
    (files: File[]) => {
      if (!isNonEmptyArray(files)) return;
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        if (typeof reader.result !== "string")
          throw new Error(
            `Expected reader.result to be a string, got ${typeof reader.result}`,
          );

        onChange({ content: reader.result, fileName: file.name });
      };
      reader.onerror = function () {
        onError();
      };
    },
    [onChange, onError],
  );

  const [bond] = useDropArea({
    onFiles: (files) => {
      readFiles(files);
    },
  });

  return (
    <div>
      <input
        accept=".xlsx"
        className="sr-only"
        id="file-input"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const files = Array.from(event.target.files ?? []);
          readFiles(files);
        }}
        type="file"
      />
      <label
        className={classNames(
          "group block cursor-pointer rounded-lg border-[0.5px] border-dashed px-3 py-6 text-center text-white",
          invalid ? "border-red" : "border-gray-09",
        )}
        htmlFor="file-input"
        {...bond}
      >
        <div className="space-y-2 transition-all group-hover:scale-[1.02]">
          <FolderAdd className="mx-auto h-6 w-6 text-primary" variant="Bulk" />
          <Typography
            as="div"
            className="text-gray-08"
            variant="Regular/Extra Small"
          >
            <Typography className="text-black-05" variant="Medium/Extra Small">
              Drag & drop
            </Typography>{" "}
            or{" "}
            <Typography className="text-primary" variant="Medium/Extra Small">
              Choose a file
            </Typography>{" "}
            to upload
          </Typography>
        </div>
      </label>
    </div>
  );
};

const schema = z.object({
  base64XLSX: z.string(),
  organizationId: z.string(),
});

type FORM_VALUES = z.input<typeof schema>;

const BulkUploadDrafts_: React.FC = () => {
  const {
    query: { organizations },
  } = useQuery<BulkUploadDrafts_Query>(QUERY, {});

  const { control, handleSubmit } = useForm<FORM_VALUES>({
    defaultValues: { base64XLSX: undefined, organizationId: undefined },
    resolver: zodResolver(schema),
  });

  const [fileName, setFileName] = React.useState<null | string>(null);

  const [triggerBulkUploadDraftsXLSX, uploadInFlight] =
    useSafeMutation<BulkUploadDrafts_Mutation>(MUTATION);

  const [bulkUploadResult, setBulkUploadResult] = React.useState<
    BulkUploadDrafts_Mutation$data["bulkUploadDraftsXLSX"] | null
  >(null);

  const onSubmit = handleSubmit(async (data) => {
    const attributes = schema.parse(data);
    setBulkUploadResult(null);

    const { bulkUploadDraftsXLSX: result } = await triggerBulkUploadDraftsXLSX({
      variables: {
        base64XLSX: data.base64XLSX,
        organizationId: attributes.organizationId,
      },
    });

    setBulkUploadResult(result);
  });

  return (
    <CenteredColumnLayout maxWidth="800" showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Bulk upload drafts
        </Typography>
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Import drafts from xlsx
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              You can find the template{" "}
              <Link
                className="text-primary underline"
                target="_blank"
                to="https://docs.google.com/spreadsheets/d/1HJQ6VZS6OmxXfYZ9_Fsddz27g7Hb7F8Y-vL9_wcXvWE/"
              >
                here
              </Link>
              .
            </Typography>
          </div>
          <form className="space-y-4" onSubmit={onSubmit}>
            <FormRow.Form
              control={control}
              label="Organization"
              name="organizationId"
            >
              <SelectAutocomplete.Form
                control={control}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                name="organizationId"
                options={organizations}
              />
            </FormRow.Form>
            <FormRow.Form control={control} name="base64XLSX">
              <Controller
                control={control}
                name="base64XLSX"
                render={({ field, fieldState }) => {
                  return (
                    <XLSXFileInput
                      invalid={fieldState.invalid}
                      onChange={({ content, fileName }) => {
                        field.onChange(content);
                        setFileName(fileName);
                      }}
                      onError={() => {
                        field.onChange(undefined);
                        setFileName(null);
                      }}
                    />
                  );
                }}
              />
            </FormRow.Form>
            {fileName && (
              <RoundedBox background="gray" rounded="4px">
                <div className="flex items-center gap-2 px-3 py-4">
                  <FolderAdd className="h-6 w-6 text-primary" variant="Bulk" />
                  <div className="space-y-2">
                    <Typography
                      className="text-black-05"
                      variant="Medium/Extra Small"
                    >
                      {fileName}
                    </Typography>
                  </div>
                </div>
              </RoundedBox>
            )}
            <Button loading={uploadInFlight} size="small" type="submit">
              Upload
            </Button>
          </form>

          {bulkUploadResult && (
            <>
              {bulkUploadResult.errors.length > 0 && (
                <div className="space-y-2">
                  <Typography
                    as="div"
                    className="text-remote-red-700"
                    variant="Medium/Default"
                  >
                    Errors:
                  </Typography>
                  <ul className="list-disc whitespace-pre-wrap pl-5">
                    {bulkUploadResult.errors.map(
                      ({ code, error, line }, index) => {
                        switch (code) {
                          case "ERROR_CREATING_EASOP_GRANT":
                            return (
                              <li key={index}>
                                Error creating grant for line {line} - {error}
                              </li>
                            );
                          case "ERROR_PARSING_XLSX":
                            return (
                              <li key={index}>Error parsing file - {error}</li>
                            );
                          case "ERROR_PARSING_XLSX_ROW":
                            return (
                              <li key={index}>
                                Error on line {line} - {error}
                              </li>
                            );
                          case "ERROR_UPDATING_GRANTEE":
                            return (
                              <li key={index}>
                                Error updating grantee for line {line} - {error}
                              </li>
                            );
                          case "NOT_ENOUGH_SHARES_AVAILABLE":
                            return <li key={index}>{error}</li>;
                        }
                      },
                    )}
                  </ul>
                </div>
              )}
              {bulkUploadResult.easopGrantsCreated.length > 0 && (
                <div className="space-y-2">
                  <Typography
                    as="div"
                    className="text-remote-green-700"
                    variant="Medium/Default"
                  >
                    Drafts created:
                  </Typography>
                  <ul className="list-disc pl-5">
                    {bulkUploadResult.easopGrantsCreated.map(
                      (easopGrant, index) => (
                        <li key={index}>
                          Grant {easopGrant.label} successfully created
                        </li>
                      ),
                    )}
                  </ul>
                </div>
              )}
            </>
          )}
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};

const BulkUploadDrafts: React.FC = () => {
  return (
    <Page
      analyticsName="Super Admin - Bulk upload drafts"
      title={`Super admin | Bulk upload drafts`}
    >
      <BulkUploadDrafts_ />
    </Page>
  );
};

export default BulkUploadDrafts;
