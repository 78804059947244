/**
 * @generated SignedSource<<139c71f030458e4fd4ab3c3f792bce55>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookADemoPage_Query$variables = {
  organizationId: string;
};
export type BookADemoPage_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"BookADemoPage_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"BookADemoPage_Organization">;
  } | null;
};
export type BookADemoPage_Query = {
  response: BookADemoPage_Query$data;
  variables: BookADemoPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookADemoPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookADemoPage_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookADemoPage_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookADemoPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityIsOrganizationCartaLawFirm",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteCompanyId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "plan",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97a25bf5ff7939aa2346bfb4029f5e1a",
    "id": null,
    "metadata": {},
    "name": "BookADemoPage_Query",
    "operationKind": "query",
    "text": "query BookADemoPage_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...BookADemoPage_Organization\n  }\n  me {\n    ...BookADemoPage_Viewer\n    id\n  }\n}\n\nfragment BookADemoPage_Organization on Organization {\n  remoteEquityIsOrganizationCartaLawFirm\n  ...TestLawFirmCartaConnectionView_Organization\n  ...BookADemoView_Organization\n}\n\nfragment BookADemoPage_Viewer on Account {\n  ...BookADemoView_Account\n}\n\nfragment BookADemoView_Account on Account {\n  email\n  firstName\n  lastName\n}\n\nfragment BookADemoView_Organization on Organization {\n  id\n  name\n  plan\n}\n\nfragment TestLawFirmCartaConnectionView_Organization on Organization {\n  id\n  ...useTrackRemoteEquityOnboardingFlowEvent_Organization\n}\n\nfragment useTrackRemoteEquityOnboardingFlowEvent_Organization on Organization {\n  id\n  name\n  remoteCompanyId\n}\n"
  }
};
})();

(node as any).hash = "e730bd0c033141ee25df716e08d8f820";

export default node;
