/**
 * @generated SignedSource<<752752a69ffa5cd4797219e78d7ff475>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation$variables = {
  displayedName: string;
  documentId: string;
  organizationId: string;
  useDocumentAsOrganizationEquityPlan: boolean;
  useDocumentAsOrganizationSubPlanOnEquityTypeId?: string | null;
};
export type UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation$data = {
  readonly createOrganizationMiscellaneousLegalDocument: {
    readonly __typename: "OrganizationMiscellaneousLegalDocument";
  };
};
export type UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation = {
  response: UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation$data;
  variables: UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayedName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "documentId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useDocumentAsOrganizationEquityPlan"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "useDocumentAsOrganizationSubPlanOnEquityTypeId"
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "displayedName",
        "variableName": "displayedName"
      },
      {
        "kind": "Variable",
        "name": "documentId",
        "variableName": "documentId"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "useDocumentAsOrganizationEquityPlan",
        "variableName": "useDocumentAsOrganizationEquityPlan"
      },
      {
        "kind": "Variable",
        "name": "useDocumentAsOrganizationSubPlanOnEquityTypeId",
        "variableName": "useDocumentAsOrganizationSubPlanOnEquityTypeId"
      }
    ],
    "concreteType": "OrganizationMiscellaneousLegalDocument",
    "kind": "LinkedField",
    "name": "createOrganizationMiscellaneousLegalDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "aa68d6244d802de5d10a3fb5a64491b3",
    "id": null,
    "metadata": {},
    "name": "UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation",
    "operationKind": "mutation",
    "text": "mutation UploadDocumentSlideOver_CreateOrganizationMiscellaneousLegalDocument_Mutation(\n  $organizationId: OrganizationId!\n  $documentId: UUID!\n  $displayedName: String!\n  $useDocumentAsOrganizationEquityPlan: Boolean!\n  $useDocumentAsOrganizationSubPlanOnEquityTypeId: ID\n) {\n  createOrganizationMiscellaneousLegalDocument(organizationId: $organizationId, documentId: $documentId, displayedName: $displayedName, useDocumentAsOrganizationEquityPlan: $useDocumentAsOrganizationEquityPlan, useDocumentAsOrganizationSubPlanOnEquityTypeId: $useDocumentAsOrganizationSubPlanOnEquityTypeId) {\n    __typename\n  }\n}\n"
  }
};
})();

(node as any).hash = "440c2f0db661bda5f979f0021612d657";

export default node;
