/**
 * @generated SignedSource<<c9744a5dac58c5844bb54d1e60f9253b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Projection_Grantee_Grantee$data = {
  readonly organization: {
    readonly granteePortalSettings: {
      readonly showProjectionScenarios: boolean;
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"GranteeOutcome_Grantee" | "useDisplayFullyDilutedValuesInGranteePortal_Grantee">;
  readonly " $fragmentType": "Projection_Grantee_Grantee";
};
export type Projection_Grantee_Grantee$key = {
  readonly " $data"?: Projection_Grantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"Projection_Grantee_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Projection_Grantee_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showProjectionScenarios",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeOutcome_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDisplayFullyDilutedValuesInGranteePortal_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "f9ff5594e386f1d1d259c40c85e40b53";

export default node;
