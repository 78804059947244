import { ButtonIcon, Pill } from "@remote-com/norma";
import { IconDownload } from "@remote-com/norma/icons/IconDownload";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { snakeCase } from "lodash";
import React, { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { CountryFlag } from "../../../../components/CountryFlag";
import { Page } from "../../../../components/Page";
import { SelectOrganizationModal } from "../../../../components/SelectOrganizationModal";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Toggle } from "../../../../components/ui/Form/Toggle";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { downloadFile } from "../../../../helpers/downloadFile";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation } from "./__generated__/EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation.graphql";
import {
  EarlyExerciseBeneficialPage_Instrument$data,
  EarlyExerciseBeneficialPage_Instrument$key,
} from "./__generated__/EarlyExerciseBeneficialPage_Instrument.graphql";
import { EarlyExerciseBeneficialPage_Mutation } from "./__generated__/EarlyExerciseBeneficialPage_Mutation.graphql";
import { EarlyExerciseBeneficialPage_Organization$key } from "./__generated__/EarlyExerciseBeneficialPage_Organization.graphql";
import { EarlyExerciseBeneficialPage_Query } from "./__generated__/EarlyExerciseBeneficialPage_Query.graphql";

const INSTRUMENTS_FRAGMENT = graphql`
  fragment EarlyExerciseBeneficialPage_Instrument on Instrument
  @relay(plural: true) {
    id
    description
    isEarlyExerciseBeneficial
    taxResidenceCountry {
      ...CountryFlag_Country
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment EarlyExerciseBeneficialPage_Organization on Organization
  @relay(plural: true) {
    id
    name
    ...SelectOrganizationModal_Organization
  }
`;

const MUTATION = graphql`
  mutation EarlyExerciseBeneficialPage_Mutation(
    $isEarlyExerciseBeneficial: Boolean!
    $instrumentId: ID!
  ) {
    setEarlyExerciseIsBeneficialForInstrument(
      instrumentId: $instrumentId
      isEarlyExerciseBeneficial: $isEarlyExerciseBeneficial
    ) {
      isEarlyExerciseBeneficial
    }
  }
`;

const GENERATE_CTMS_GRANTS_WITH_BENEFICIAL_EARLY_EXERCISE_XLSX_EXPORT_URL_MUTATION = graphql`
  mutation EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation(
    $organizationId: OrganizationId!
  ) {
    generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl(
      organizationId: $organizationId
    )
  }
`;

type Instrument = EarlyExerciseBeneficialPage_Instrument$data[number];

const columnHelper = createColumnHelper<Instrument>();

const EarlyExerciseBeneficialPage_: React.FC<{
  instrumentsFragment: EarlyExerciseBeneficialPage_Instrument$key;
  organizationsFragment: EarlyExerciseBeneficialPage_Organization$key;
}> = ({ instrumentsFragment, organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  const [
    generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl,
    mutationIsInFlight,
  ] =
    useSafeMutation<EarlyExerciseBeneficial_GenerateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl_Mutation>(
      GENERATE_CTMS_GRANTS_WITH_BENEFICIAL_EARLY_EXERCISE_XLSX_EXPORT_URL_MUTATION,
    );

  const generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExport = useCallback(
    async ({ organizationId }: { organizationId: string }) => {
      const {
        generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl: url,
      } = await generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl({
        variables: { organizationId },
      });

      const organizationName =
        organizations.find((organization) => organization.id === organizationId)
          ?.name ?? "unknown";

      downloadFile({
        filename: `grants_with_early_exercise_beneficial_${snakeCase(organizationName)}.xlsx`,
        url,
      });
    },
    [generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExportUrl, organizations],
  );

  const {
    setFalse: closeSelectOrganizationModal,
    setTrue: openSelectOrganizationModal,
    value: isSelectOrganizationModalOpen,
  } = useBoolean();

  const instruments = useFragment(INSTRUMENTS_FRAGMENT, instrumentsFragment);
  const instrumentsWithEarlyExerciseBeneficial = useMemo(
    () =>
      instruments.filter((instrument) => instrument.isEarlyExerciseBeneficial),
    [instruments],
  );

  const [setEarlyExerciseIsBeneficialForInstrument] =
    useSafeMutation<EarlyExerciseBeneficialPage_Mutation>(MUTATION);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.description, {
        cell: (context) => {
          const instrument = context.row.original;
          return (
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Large">
                <CountryFlag countryFragment={instrument.taxResidenceCountry} />
              </Typography>
              <Typography variant="Medium/Extra Small">
                {instrument.description}
              </Typography>
            </div>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Instrument",
        id: "name",
      }),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const instrument = context.row.original;
          return (
            <div className="flex items-center justify-end">
              <Toggle
                enabled={instrument.isEarlyExerciseBeneficial}
                onChange={async () => {
                  await setEarlyExerciseIsBeneficialForInstrument({
                    variables: {
                      instrumentId: instrument.id,
                      isEarlyExerciseBeneficial:
                        !instrument.isEarlyExerciseBeneficial,
                    },
                  });
                }}
                size="small"
              />
            </div>
          );
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "",
        id: "action",
      }),
    ],
    [setEarlyExerciseIsBeneficialForInstrument],
  );

  const data = useMemo(() => [...instruments], [instruments]);

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    initialState: {
      sorting: [{ desc: false, id: "name" }],
    },
  });

  return (
    <>
      <SelectOrganizationModal
        onClose={closeSelectOrganizationModal}
        onSelect={(organizationId) => {
          void generateCTMSGrantsWithBeneficialEarlyExerciseXLSXExport({
            organizationId,
          });
        }}
        organizationsFragment={organizations}
        show={isSelectOrganizationModalOpen}
      />
      <OneColumnLayout
        Breadcrumb={
          <BreadCrumb>
            <BreadCrumb.Link to="..">Legal content</BreadCrumb.Link>
            <BreadCrumb.Link to=".">
              Mark Early Exercise as Beneficial
            </BreadCrumb.Link>
          </BreadCrumb>
        }
        showFooter
      >
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="flex items-center gap-4">
            <BackButton />
            <div className="flex-grow space-y-2">
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Default">
                  Mark Early Exercise as Beneficial
                </Typography>
                <Pill>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# instrument} other {# instruments}}"
                    values={{
                      count: instrumentsWithEarlyExerciseBeneficial.length,
                    }}
                  />
                </Pill>
              </div>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                Configure for which instruments is early exercise beneficial
              </Typography>
            </div>
            <ButtonIcon
              disabled={mutationIsInFlight}
              Icon={IconDownload}
              label="Export grants"
              onClick={openSelectOrganizationModal}
              tone="secondary"
            />
          </div>
          <Table.Smart
            searchBarPlaceholder="Search instruments..."
            showSearchBar
            table={table}
          />
        </RoundedBox>
      </OneColumnLayout>
    </>
  );
};

const QUERY = graphql`
  query EarlyExerciseBeneficialPage_Query {
    instruments {
      ...EarlyExerciseBeneficialPage_Instrument
    }
    organizations(
      filters: { status: Active, orderBy: { field: name, direction: ASC } }
    ) {
      ...EarlyExerciseBeneficialPage_Organization
    }
  }
`;

const EarlyExerciseBeneficialPage: React.FC = () => {
  const { instruments, organizations } =
    useLazyLoadQuery<EarlyExerciseBeneficialPage_Query>(QUERY, {});
  return (
    <Page
      analyticsName="Super Admin - Early Exercise Beneficial"
      title={`Super admin | early exercise beneficial`}
    >
      <EarlyExerciseBeneficialPage_
        instrumentsFragment={instruments}
        organizationsFragment={organizations}
      />
    </Page>
  );
};

export default EarlyExerciseBeneficialPage;
