/**
 * @generated SignedSource<<c2c5fbe7935beddea92a1f28451c46ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetails_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsExercise_Organization" | "CtmsGrantDetailsGrant_Organization" | "CtmsGrantDetailsGrantee_Organization" | "CtmsGrantDetailsHeader_Organization" | "CtmsGrantDetailsPostTerminationExercisePeriod_Organization" | "CtmsGrantDetailsPriceEvolution_Organization" | "CtmsGrantDetailsVestingSchedule_Organization" | "MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "CtmsGrantDetails_Organization";
};
export type CtmsGrantDetails_Organization$key = {
  readonly " $data"?: CtmsGrantDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsHeader_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsGrant_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsVestingSchedule_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsPostTerminationExercisePeriod_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsExercise_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsGrantee_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantDetailsPriceEvolution_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "076c6e6c65633a60875720373db69b7b";

export default node;
