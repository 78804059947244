/**
 * @generated SignedSource<<5e48fe9a7ddb769f3b2fc9d758e84de6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useGenerateOrganizationCartaAuthorizationFlowURL_Mutation$variables = {
  doNotConnectIfMultipleIssuersSelected: boolean;
  organizationId: string;
  returnURL: string;
};
export type useGenerateOrganizationCartaAuthorizationFlowURL_Mutation$data = {
  readonly generateOrganizationCartaAuthorizationFlowURL: string;
};
export type useGenerateOrganizationCartaAuthorizationFlowURL_Mutation = {
  response: useGenerateOrganizationCartaAuthorizationFlowURL_Mutation$data;
  variables: useGenerateOrganizationCartaAuthorizationFlowURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "doNotConnectIfMultipleIssuersSelected"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "returnURL"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "doNotConnectIfMultipleIssuersSelected",
        "variableName": "doNotConnectIfMultipleIssuersSelected"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "returnURL",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "generateOrganizationCartaAuthorizationFlowURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useGenerateOrganizationCartaAuthorizationFlowURL_Mutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useGenerateOrganizationCartaAuthorizationFlowURL_Mutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "aa1af4205c9a7f0bad5fb4aab7811482",
    "id": null,
    "metadata": {},
    "name": "useGenerateOrganizationCartaAuthorizationFlowURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useGenerateOrganizationCartaAuthorizationFlowURL_Mutation(\n  $organizationId: OrganizationId!\n  $returnURL: String!\n  $doNotConnectIfMultipleIssuersSelected: Boolean!\n) {\n  generateOrganizationCartaAuthorizationFlowURL(organizationId: $organizationId, returnURL: $returnURL, doNotConnectIfMultipleIssuersSelected: $doNotConnectIfMultipleIssuersSelected)\n}\n"
  }
};
})();

(node as any).hash = "33dfa4175e35c9bc52ba78eea91d2674";

export default node;
