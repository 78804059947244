/**
 * @generated SignedSource<<71eb285801024ff18c7eafa3c230dfec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type LockedGeographySlideOver_EquityTypeDescription_EquityType$data = {
  readonly description: string | null;
  readonly fullname: string | null;
  readonly id: string;
  readonly instruments: ReadonlyArray<{
    readonly workRelationship: WorkRelationship;
  }>;
  readonly name: EquityTypeName;
  readonly taxFavored: boolean;
  readonly " $fragmentType": "LockedGeographySlideOver_EquityTypeDescription_EquityType";
};
export type LockedGeographySlideOver_EquityTypeDescription_EquityType$key = {
  readonly " $data"?: LockedGeographySlideOver_EquityTypeDescription_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_EquityTypeDescription_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockedGeographySlideOver_EquityTypeDescription_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxFavored",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "4e3a3b782c1107d2570b015f294d0c1e";

export default node;
