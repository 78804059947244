import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { ConfirmationModal } from "../../../../components/ui/ConfirmationModal";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import { EarlyExercise_Query } from "./__generated__/EarlyExercise_Query.graphql";
import { EarlyExercise_SetEarlyExerciseAllowedForOrganization_Mutation } from "./__generated__/EarlyExercise_SetEarlyExerciseAllowedForOrganization_Mutation.graphql";
import { BlueLink } from "./BlueLink";
import { ConfigureGrantsLayout } from "./ConfigureGrantsLayout";
import { ToggleCard } from "./ToggleCard";

const QUERY = graphql`
  query EarlyExercise_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      allowEarlyExercise
      hasNotImplementedEasopGrantsWithEarlyExercise
    }
  }
`;

const SET_EARLY_EXERCISE_ALLOWED_MUTATION = graphql`
  mutation EarlyExercise_SetEarlyExerciseAllowedForOrganization_Mutation(
    $organizationId: OrganizationId!
    $allowEarlyExercise: Boolean!
  ) {
    setEarlyExerciseAllowedForOrganization(
      organizationId: $organizationId
      allowEarlyExercise: $allowEarlyExercise
    ) {
      allowEarlyExercise
    }
  }
`;

export default function EarlyExercise() {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<EarlyExercise_Query>(QUERY, {
    organizationId,
  });
  const [mutate, mutationIsInFlight] =
    useSafeMutation<EarlyExercise_SetEarlyExerciseAllowedForOrganization_Mutation>(
      SET_EARLY_EXERCISE_ALLOWED_MUTATION,
    );
  const turnEarlyExerciseOn = () => {
    void mutate({
      variables: {
        allowEarlyExercise: true,
        organizationId,
      },
    });
  };
  const turnEarlyExerciseOff = () => {
    void mutate({
      variables: {
        allowEarlyExercise: false,
        organizationId,
      },
    });
  };

  const handleToggle = (allowEarlyExercise: boolean) => {
    if (allowEarlyExercise) {
      turnEarlyExerciseOn();
    } else {
      if (query.organization.hasNotImplementedEasopGrantsWithEarlyExercise) {
        showConfirmationModal();
      } else {
        turnEarlyExerciseOff();
      }
    }
  };

  const {
    setFalse: closeConfirmationModal,
    setTrue: showConfirmationModal,
    value: confirmationModalIsShown,
  } = useBoolean(false);

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - Early Exercise"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity early exercise settings`}
    >
      <ConfirmationModal
        confirmationLabel="Turn off"
        onClose={closeConfirmationModal}
        onConfirmed={() => {
          turnEarlyExerciseOff();
          closeConfirmationModal();
        }}
        show={confirmationModalIsShown}
        title="Early Exercise is active on current drafts."
      >
        We noticed that you currently have drafts that allow employees to
        benefit from early exercise. If you want those employees to still
        benefit from early exercise, you should turn off this setting but only
        after the grants have been implemented.
      </ConfirmationModal>
      <ConfigureGrantsLayout title="Early Exercise">
        <ToggleCard
          description={
            <>
              This allows your team members to exercise options before they vest
              any options. It can be set up more granularly in the{" "}
              <BlueLink
                to={generatePath(
                  APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                  { organizationId },
                )}
              >
                New Hires
              </BlueLink>{" "}
              and{" "}
              <BlueLink
                to={generatePath(
                  APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                  { organizationId },
                )}
              >
                Retention
              </BlueLink>{" "}
              policies
            </>
          }
          loading={mutationIsInFlight}
          onToggle={handleToggle}
          title="Allow early exercise at company level"
          toggled={query.organization?.allowEarlyExercise ?? false}
        />
      </ConfigureGrantsLayout>
    </Page>
  );
}
