import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CountryFlag_Country$key } from "./__generated__/CountryFlag_Country.graphql";
import { TooltipContainer } from "./ui/TooltipContainer";

const FRAGMENT = graphql`
  fragment CountryFlag_Country on Country {
    emoji
    name
  }
`;

export const CountryFlag: React.FC<{
  countryFragment?: CountryFlag_Country$key | null;
}> = ({ countryFragment }) => {
  const country = useFragment(FRAGMENT, countryFragment);
  if (!country) return null;
  return (
    <TooltipContainer
      className="inline cursor-pointer"
      tooltipClassName="py-2"
      tooltipContent={
        <>
          {country.emoji} {country.name}
        </>
      }
    >
      <span>{country.emoji}</span>
    </TooltipContainer>
  );
};
