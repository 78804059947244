/**
 * @generated SignedSource<<ea5b1372b26ae2663345e21d0010d8e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type CreateOngoingObligationSlideOver_EquityType$data = ReadonlyArray<{
  readonly id: string;
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  };
  readonly " $fragmentType": "CreateOngoingObligationSlideOver_EquityType";
}>;
export type CreateOngoingObligationSlideOver_EquityType$key = ReadonlyArray<{
  readonly " $data"?: CreateOngoingObligationSlideOver_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateOngoingObligationSlideOver_EquityType">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CreateOngoingObligationSlideOver_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "f440c2ac8425d842a4a58339498d3856";

export default node;
