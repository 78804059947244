/**
 * @generated SignedSource<<b1d189a87abb76fa34f1d1550179f03f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipAssumptions_EquityType$data = {
  readonly awardSuperType: AwardSuperType;
  readonly taxResidenceCountry: {
    readonly code: string;
  };
  readonly " $fragmentType": "EquityTypeWorkRelationshipAssumptions_EquityType";
};
export type EquityTypeWorkRelationshipAssumptions_EquityType$key = {
  readonly " $data"?: EquityTypeWorkRelationshipAssumptions_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipAssumptions_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipAssumptions_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "awardSuperType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "dd7160d907a7a67c44e880c779cd9745";

export default node;
