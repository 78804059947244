/**
 * @generated SignedSource<<beac4d377947d35189fcbb7716db768f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DebugSubscription$variables = Record<PropertyKey, never>;
export type DebugSubscription$data = {
  readonly countdown: number;
};
export type DebugSubscription = {
  response: DebugSubscription$data;
  variables: DebugSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "from",
        "value": 1000
      }
    ],
    "kind": "ScalarField",
    "name": "countdown",
    "storageKey": "countdown(from:1000)"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DebugSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DebugSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d51218fcb3c9580ddf108a129404bbb1",
    "id": null,
    "metadata": {},
    "name": "DebugSubscription",
    "operationKind": "subscription",
    "text": "subscription DebugSubscription {\n  countdown(from: 1000)\n}\n"
  }
};
})();

(node as any).hash = "f731358444c4b23cf7436d76cebca051";

export default node;
