import { EasopGrantStatus } from "../../components/__generated__/RequestChangesOnEasopGrantsButton_Mutation.graphql";

export const GRANT_STATUS_LABEL_MAP: Record<EasopGrantStatus, string> = {
  Active: "Active",
  Draft: "Draft",
  PendingBoardConsent: "Pending",
  PendingCtmsImplementation: "Implementation",
  PendingReview: "Under review",
  Reviewed: "Board approval",
};
