/**
 * @generated SignedSource<<493fd7f03de960422d5d81ad90710a82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type MatchGranteeTableRow_HRISProviderEmployee$data = {
  readonly email: string;
  readonly grantee: {
    readonly id: string;
  } | null;
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly ignored: boolean | null;
  readonly jobTitle: string | null;
  readonly name: string;
  readonly suggestedGrantee: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "MatchGranteeTableRow_HRISProviderEmployee";
};
export type MatchGranteeTableRow_HRISProviderEmployee$key = {
  readonly " $data"?: MatchGranteeTableRow_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MatchGranteeTableRow_HRISProviderEmployee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MatchGranteeTableRow_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ignored",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "suggestedGrantee",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};
})();

(node as any).hash = "369a05ff2c53a9164e57f0fd0d9da2d8";

export default node;
