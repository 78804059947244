import { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  GRANTEE_PORTAL_EQUITY_TYPES_GROUPS,
  useEquityTypesGroupParam,
} from "../paths";
import { useGranteePortalGranteeShares_Grantee$key } from "./__generated__/useGranteePortalGranteeShares_Grantee.graphql";

const SHARES_GRANTEE_FRAGMENT = graphql`
  fragment useGranteePortalGranteeShares_Grantee on Grantee {
    totalGrantedSharesBreakdown {
      real
      virtual
    }
    totalVestedSharesBreakdown {
      real
      virtual
    }
  }
`;

export const useGranteePortalGranteeShares = ({
  granteeFragment,
}: {
  granteeFragment: useGranteePortalGranteeShares_Grantee$key;
}) => {
  const grantee = useFragment(SHARES_GRANTEE_FRAGMENT, granteeFragment);
  const equityTypesGroup = useEquityTypesGroupParam();
  return useMemo(() => {
    switch (equityTypesGroup) {
      case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
        return {
          totalGrantedShares: grantee.totalGrantedSharesBreakdown.real,
          totalVestedShares: grantee.totalVestedSharesBreakdown.real,
        };
      case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
        return {
          totalGrantedShares: grantee.totalGrantedSharesBreakdown.virtual,
          totalVestedShares: grantee.totalVestedSharesBreakdown.virtual,
        };
    }
  }, [equityTypesGroup, grantee]);
};

export const useGranteePortalGrantType = () => {
  const equityTypesGroup = useEquityTypesGroupParam();
  switch (equityTypesGroup) {
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
      return "real";
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
      return "virtual";
  }
};
