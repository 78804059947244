import { useApplicationName } from "../hooks/useApplicationTheme";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

export const SafeCalculatorDisclaimer: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <RoundedBox background="gray" className="p-6">
      <Typography
        className="space-y-2 text-gray-09"
        variant="Regular/Extra Small"
      >
        <div>Disclaimer</div>
        <div>
          <strong>{applicationName} makes no guarantee of accuracy.</strong>{" "}
          This simulation is based on information provided by you and may or may
          not have been reviewed by people with the expertise required to
          provide you with complete, accurate or reliable information. Your
          company may have another investment structure or other investment
          vehicles, which this simulator cannot take into consideration. If this
          is the case, the provided simulation should be ignored.
        </div>
        <div>
          <strong>
            {applicationName} does NOT provide professional advice.
          </strong>{" "}
          If you need specific advice on your exact shareholding structure or
          the best way to calculate the exact number of shares pre- and/or
          post-conversion please see a professional who is licensed or
          knowledgeable in that area. This simulator is not presented as a
          source of legal, financial or accounting advice. You should not rely
          on this simulator, for legal, financial or accounting advice.
        </div>
      </Typography>
    </RoundedBox>
  );
};
