import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants$key } from "./__generated__/useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants.graphql";

export const EASOP_GRANTS_FRAGMENT = graphql`
  fragment useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants on EasopGrant
  @relay(plural: true) {
    usesAnExpiredTaxFavoredSubplan
  }
`;

export function useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan({
  easopGrants,
}: {
  easopGrants: useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants$key;
}) {
  const grants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrants);

  return useMemo(
    () =>
      grants.some(
        ({ usesAnExpiredTaxFavoredSubplan }) => usesAnExpiredTaxFavoredSubplan,
      ),
    [grants],
  );
}
