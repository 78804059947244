/**
 * @generated SignedSource<<667e3a70aa41028037700bace1ed9cb7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type GranteeForm_Organization$data = {
  readonly HRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_HRISProviderEmployee">;
  }>;
  readonly companies: ReadonlyArray<{
    readonly completeName: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"SelectCompanies_Companies">;
  }>;
  readonly employersOfRecord: ReadonlyArray<{
    readonly hRISProvider: HRISProvider | null;
    readonly id: string;
    readonly name: string;
  }>;
  readonly equityGrid: {
    readonly activated: boolean;
    readonly levels: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectHRISProviderMessage_Organization">;
  readonly " $fragmentType": "GranteeForm_Organization";
};
export type GranteeForm_Organization$key = {
  readonly " $data"?: GranteeForm_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeForm_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeForm_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "companies",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completeName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SelectCompanies_Companies"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityGridLevel",
          "kind": "LinkedField",
          "name": "levels",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "GranteeForm_HRISProviderEmployee"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmployerOfRecord",
      "kind": "LinkedField",
      "name": "employersOfRecord",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hRISProvider",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ConnectHRISProviderMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "45a5e11df501cb911b8458470d3cc83b";

export default node;
