/**
 * @generated SignedSource<<b90c6bb7962479025aa1a09a43a0bc75>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$data = {
  readonly grantee: {
    readonly id: string;
  };
  readonly id: string;
  readonly sharesGranted: number;
  readonly " $fragmentType": "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry";
};
export type RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$key = {
  readonly " $data"?: RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharesGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "RefresherGrantPlanningEntry",
  "abstractKey": null
};
})();

(node as any).hash = "186ad01afe63bf2d122c58833f2a9ef7";

export default node;
