/**
 * @generated SignedSource<<26ddd7a32bdb82da7e71827660dbe30e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeGrantDetails_CTMSGrant$data = {
  readonly label: string;
  readonly vestingScheduleName: string | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantStatusTag_CtmsGrant" | "VestingOverview_CTMSGrant">;
  readonly " $fragmentType": "GranteeGrantDetails_CTMSGrant";
};
export type GranteeGrantDetails_CTMSGrant$key = {
  readonly " $data"?: GranteeGrantDetails_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeGrantDetails_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeGrantDetails_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingScheduleName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingOverview_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CtmsGrantStatusTag_CtmsGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "34b1ea86a72009a1bb6fb86e3a6ecc17";

export default node;
