import React from "react";

import { Divider } from "../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";

export const InsightPage: React.FC<{
  aboveChildren?: React.ReactNode;
  aboveContent?: React.ReactNode;
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
  topRightContent?: React.ReactNode;
  yellowBanner?: React.ReactNode;
}> = ({
  aboveChildren,
  aboveContent,
  children,
  subtitle,
  title,
  topRightContent,
  yellowBanner,
}) => {
  return (
    <CenteredColumnLayout
      backButton={{ label: "Back to all insights" }}
      maxWidth="800"
      showFooter
    >
      <div className="space-y-6">
        {aboveContent}
        <RoundedBox withBorder withShadow>
          <div className="flex w-full items-start justify-between p-6">
            <div className="space-y-1">
              <Typography as="div" variant="Medium/Small">
                {title}
              </Typography>
              {subtitle && (
                <Typography
                  as="div"
                  className="text-gray-09"
                  variant="Regular/Extra Small"
                >
                  {subtitle}
                </Typography>
              )}
            </div>
            {topRightContent}
          </div>
          <Divider />
          {aboveChildren && (
            <>
              <div className="w-full p-6">{aboveChildren}</div>
              <Divider />
            </>
          )}
          {yellowBanner && (
            <Typography
              as="div"
              className="w-full bg-yellow-01 px-4 py-2 text-center text-yellow-09"
              variant="Regular/Extra Small"
            >
              {yellowBanner}
            </Typography>
          )}
          <div className="flex w-full flex-col items-center gap-6 p-6">
            {children}
          </div>
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};
