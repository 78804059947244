/**
 * @generated SignedSource<<3aaa1bceb96db7b39c7a1924b0514017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationInfoCard_Organization$data = {
  readonly esopPoolShares: number | null;
  readonly hasBeenOnboarded: boolean;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly latestValuation: number | null;
  readonly name: string;
  readonly " $fragmentType": "OrganizationInfoCard_Organization";
};
export type OrganizationInfoCard_Organization$key = {
  readonly " $data"?: OrganizationInfoCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationInfoCard_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationInfoCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latestValuation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasBeenOnboarded",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "17eeb3c411f717db277234eeab898d81";

export default node;
