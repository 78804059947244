import { BoxedIcon, Text } from "@remote-com/norma";
import { IconV2DuotoneBriefcase } from "@remote-com/norma/icons/IconV2DuotoneBriefcase";
import { forwardRef, useMemo } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { graphql, useFragment } from "react-relay";

import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { GranteePortalWhatDoYouHaveSection_Grantee$key } from "./__generated__/GranteePortalWhatDoYouHaveSection_Grantee.graphql";
import { ColoredBoxValue } from "./ColoredBoxValue";
import { FormattedCurrency } from "./Formatted/FormattedCurrency";
import { FormattedCurrencyCompact } from "./Formatted/FormattedCurrencyCompact";
import { RangeInput } from "./RangeInput";
import { SectionHero } from "./SectionHero";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalWhatDoYouHaveSection_Grantee on Grantee {
    ctmsGrantsCount
    totalGrantedSharesBreakdown {
      total
    }
    organization {
      latestValuation
      granteePortalSettings {
        displayFullyDilutedValues
      }
      ...useOrganizationSharesUtil_Organization
    }
  }
`;

export const GranteePortalWhatDoYouHaveSection = forwardRef<
  HTMLDivElement,
  {
    granteeFragment: GranteePortalWhatDoYouHaveSection_Grantee$key;
    id: string;
    onValuationMultipleChange: (valuationMultiple: number) => void;
    valuationMultiple: number;
  }
>(function GranteePortalWhatDoYouHaveSection(
  { granteeFragment, id, onValuationMultipleChange, valuationMultiple },
  ref,
) {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const totalGrantedShares = grantee.totalGrantedSharesBreakdown.total;
  const totalGrantedUSDValue = sharesToValue(totalGrantedShares);

  const { organzationValuationMultiplied, totalGrantedUSDValueMultiplied } =
    useMemo(() => {
      return {
        organzationValuationMultiplied:
          grantee.organization.latestValuation !== null
            ? valuationMultiple * grantee.organization.latestValuation
            : null,
        totalGrantedUSDValueMultiplied:
          totalGrantedUSDValue !== null
            ? valuationMultiple * totalGrantedUSDValue
            : null,
      };
    }, [
      grantee.organization.latestValuation,
      valuationMultiple,
      totalGrantedUSDValue,
    ]);

  const displayFullyDilutedValues =
    grantee.organization.granteePortalSettings.displayFullyDilutedValues;

  return (
    <SectionHero id={id} ref={ref} withBorder>
      <div className="space-y-6">
        <div className="flex items-center gap-3">
          <BoxedIcon Icon={IconV2DuotoneBriefcase} size="sm" tone="pink" />
          <Text variant="lgMedium">What do you have exactly?</Text>
        </div>
        <Text className="text-grey-600" variant="sm">
          You will often be told about a number of shares, but what really
          matters for you is the potential value of these shares. You will see
          below what your shares could potentially be worth in terms of $ value
          as of today:
        </Text>
        <div className="flex flex-col gap-4 lg:flex-row lg:gap-6">
          <ColoredBoxValue
            className="flex-1"
            title="Compensation"
            tone="blueLight"
            unit={
              <FormattedMessage
                defaultMessage="{count, plural, one {grant} other {grants}}"
                values={{
                  count: grantee.ctmsGrantsCount,
                }}
              />
            }
            value={grantee.ctmsGrantsCount}
          />
          <ColoredBoxValue
            className="flex-1"
            title="Ownership"
            tone="green"
            unit="shares"
            value={<FormattedNumber value={totalGrantedShares} />}
          />
          {displayFullyDilutedValues &&
            totalGrantedUSDValueMultiplied !== null && (
              <ColoredBoxValue
                className="flex-1"
                title="Net Equity Value"
                tone="purple"
                unit="USD"
                value={
                  <FormattedCurrency
                    maximumFractionDigits={0}
                    value={totalGrantedUSDValueMultiplied}
                  />
                }
              />
            )}
        </div>
        {displayFullyDilutedValues && (
          <div className="flex flex-col gap-4 rounded-2xl bg-background-subtle p-6 lg:flex-row lg:items-center">
            <div className="flex-1 space-y-2">
              <div className="space-y-1">
                <Text variant="medium">
                  The company&apos;s model over the years
                </Text>
                <Text className="text-grey-600" variant="sm">
                  This would be the company valuation multiplied by
                </Text>
              </div>
              <RangeInput
                id="threshold-range"
                max={10}
                min={1}
                onChange={onValuationMultipleChange}
                renderStepLabel={({ step }) => `${step}x`}
                step={1}
                tone="purple"
                value={valuationMultiple}
              />
            </div>
            {organzationValuationMultiplied !== null && (
              <div className="space-y-2 text-center lg:text-right">
                <Text variant="lgMedium">Company valuation</Text>
                <Text className="text-purple-700" variant="4xlMedium">
                  <FormattedCurrencyCompact
                    value={organzationValuationMultiplied}
                  />
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </SectionHero>
  );
});
