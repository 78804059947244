import { formatISO } from "date-fns";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../../../../components/LoadingPlaceholder";
import { VestingSchedulePreviewBox } from "../../../../../components/VestingSchedulePreviewBox";
import { isNonEmptyArray } from "../../../../../helpers/ts-utlity";
import { VestingSchedulePreview_BackloadedVestingSchedulePreview_Query } from "./__generated__/VestingSchedulePreview_BackloadedVestingSchedulePreview_Query.graphql";
import { VestingSchedulePreview_LinearVestingSchedulePreview_Query } from "./__generated__/VestingSchedulePreview_LinearVestingSchedulePreview_Query.graphql";
import {
  BackloadedVestingScheduleFormValues,
  LinearVestingScheduleFormValues,
  VestingScheduleFormType,
} from "./FORM_SCHEMA";

const LINEAR_VESTING_SCHEDULE_PREVIEW_QUERY = graphql`
  query VestingSchedulePreview_LinearVestingSchedulePreview_Query(
    $vestingStartDate: Date!
    $quantityGranted: Int!
    $vestingOccurrence: LinearVestingOccurrence!
    $durationInMonths: Int!
    $cliffDurationInMonths: Int!
    $vestedAtCliffPercentage: Float!
  ) {
    vestingDataPointsFromLinearVestingScheduleParameters(
      vestingStartDate: $vestingStartDate
      quantityGranted: $quantityGranted
      vestingOccurrence: $vestingOccurrence
      durationInMonths: $durationInMonths
      cliffDurationInMonths: $cliffDurationInMonths
      vestedAtCliffPercentage: $vestedAtCliffPercentage
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

export function LinearVestingSchedulePreview({
  values,
}: {
  values: LinearVestingScheduleFormValues;
}) {
  const query =
    useLazyLoadQuery<VestingSchedulePreview_LinearVestingSchedulePreview_Query>(
      LINEAR_VESTING_SCHEDULE_PREVIEW_QUERY,
      {
        cliffDurationInMonths:
          values.hasCliff && values.cliffDurationInMonths
            ? Number(values.cliffDurationInMonths)
            : 0,
        durationInMonths: values.durationInMonths
          ? Number(values.durationInMonths)
          : 0,
        quantityGranted: 10_000,
        vestedAtCliffPercentage:
          values.hasCliff && values.vestedAtCliffPercentage
            ? Number(values.vestedAtCliffPercentage)
            : 0,
        vestingOccurrence: values.vestingOccurrence ?? "EveryMonth",
        vestingStartDate: formatISO(new Date(), { representation: "date" }),
      },
    );

  const vestingDataPoints =
    query.vestingDataPointsFromLinearVestingScheduleParameters.map(
      ({ cumulativeVested, date }) => ({
        cumulativeVested,
        date: new Date(date),
      }),
    );

  if (!isNonEmptyArray(vestingDataPoints)) {
    return null;
  }

  return <VestingSchedulePreviewBox vestingDataPoints={vestingDataPoints} />;
}

const BACKLOADED_VESTING_SCHEDULE_PREVIEW_QUERY = graphql`
  query VestingSchedulePreview_BackloadedVestingSchedulePreview_Query(
    $vestingStartDate: Date!
    $quantityGranted: Int!
    $vestingOccurrence: BackloadedVestingOccurrence!
    $periods: [BackloadedVestingSchedulePeriodAttributes!]!
    $name: String!
    $cliffActivatedOnFirstPeriod: Boolean!
  ) {
    vestingDataPointsFromBackloadedVestingScheduleAttributes(
      vestingStartDate: $vestingStartDate
      quantityGranted: $quantityGranted
      attributes: {
        cliffActivatedOnFirstPeriod: $cliffActivatedOnFirstPeriod
        name: $name
        periods: $periods
        vestingOccurrence: $vestingOccurrence
      }
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

export function BackloadedVestingSchedulePreview({
  values,
}: {
  values: BackloadedVestingScheduleFormValues;
}) {
  const query =
    useLazyLoadQuery<VestingSchedulePreview_BackloadedVestingSchedulePreview_Query>(
      BACKLOADED_VESTING_SCHEDULE_PREVIEW_QUERY,
      {
        cliffActivatedOnFirstPeriod:
          values.cliffActivatedOnFirstPeriod ?? false,
        name: values.name ?? "__name",
        periods:
          values.periods?.map(({ durationInMonths, percentageVested }) => ({
            durationInMonths,
            percentageVested,
          })) ?? [],
        quantityGranted: 10_000,
        vestingOccurrence: values.vestingOccurrence ?? "EveryMonth",
        vestingStartDate: formatISO(new Date(), { representation: "date" }),
      },
    );

  const vestingDataPoints =
    query.vestingDataPointsFromBackloadedVestingScheduleAttributes.map(
      ({ cumulativeVested, date }) => ({
        cumulativeVested,
        date: new Date(date),
      }),
    );

  if (!isNonEmptyArray(vestingDataPoints)) {
    return null;
  }

  return <VestingSchedulePreviewBox vestingDataPoints={vestingDataPoints} />;
}

export function VestingSchedulePreview({
  type,
  values,
}: {
  type: VestingScheduleFormType;
  values: BackloadedVestingScheduleFormValues | LinearVestingScheduleFormValues;
}) {
  switch (type) {
    case "BACKLOADED":
      return (
        <Suspense fallback={<LoadingPlaceholder />}>
          <BackloadedVestingSchedulePreview
            values={values as BackloadedVestingScheduleFormValues}
          />
        </Suspense>
      );
    case "LINEAR":
      return (
        <Suspense fallback={<LoadingPlaceholder />}>
          <LinearVestingSchedulePreview
            values={values as LinearVestingScheduleFormValues}
          />
        </Suspense>
      );
  }
}
