import { useCallback, useMemo, useState, useTransition } from "react";
import { useFragment, usePaginationFragment } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { CreateGranteeButton } from "../../../components/CreateGranteeButton";
import { Page } from "../../../components/Page";
import { Button } from "../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { Tab } from "../../../components/ui/Tab";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  Grantees_GranteesPagination_Query,
  OrganizationGranteesFilters,
} from "./__generated__/Grantees_GranteesPagination_Query.graphql";
import { Grantees_Organization$key } from "./__generated__/Grantees_Organization.graphql";
import { Grantees_Query } from "./__generated__/Grantees_Query.graphql";
import { Grantees_Refetchable_Organization$key } from "./__generated__/Grantees_Refetchable_Organization.graphql";
import { Grantees_Viewer$key } from "./__generated__/Grantees_Viewer.graphql";
import { OrganizationGranteesTable } from "./GranteesTable/OrganizationGranteesTable";
import { HRISIntegrationNotice } from "./HRISIntegrationNotice";
import { SuspensedInviteGranteesModal } from "./SuspensedInviteGranteesModal";
import { SuspensedTabList } from "./SuspensedTabList";

const VIEWER_FRAGMENT = graphql`
  fragment Grantees_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)

    ...OrganizationGranteesTable_Viewer
      @arguments(organizationId: $organizationId)
    ...SuspensedInviteGranteesModal_Viewer
  }
`;

export const ORGANIZATION_FRAGMENT = graphql`
  fragment Grantees_Organization on Organization
  @argumentDefinitions(
    organizationId: { type: "OrganizationId!" }
    filters: { type: "OrganizationGranteesFilters!" }
  ) {
    id
    name
    isConnectedToHRISProvider
    isOriginatingFromRemoteEquity
    ...OrganizationGranteesTable_Organization
    ...CreateGranteeButton_Organization
    ...SuspensedInviteGranteesModal_Organization
      @arguments(organizationId: $organizationId)
    ...SuspensedTabList_Organization
    ...Grantees_Refetchable_Organization
      @arguments(organizationId: $organizationId, filters: $filters)
    ...LargeOneColumnLayout_Organization
  }
`;

const ORGANIZATION_REFETCHABLE_FRAGMENT = graphql`
  fragment Grantees_Refetchable_Organization on Organization
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 20 }
    filters: { type: "OrganizationGranteesFilters!" }
    organizationId: { type: "OrganizationId!" }
  )
  @refetchable(queryName: "Grantees_GranteesPagination_Query") {
    paginatedGrantees: grantees(
      first: $count
      after: $cursor
      filters: $filters
    ) @connection(key: "Grantees_Organization_paginatedGrantees") {
      __id
      totalCount
      edges {
        node {
          id
          ...OrganizationGranteesTable_Grantees
            @arguments(organizationId: $organizationId)
        }
      }
    }
  }
`;

const AdminGranteesPage_: React.FC<{
  filters: OrganizationGranteesFilters;
  organizationFragment: Grantees_Organization$key;
  setFilters: (filters: OrganizationGranteesFilters) => void;
  viewerFragment: Grantees_Viewer$key;
}> = ({ filters, organizationFragment, setFilters, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const {
    data: { paginatedGrantees: grantees },
    hasNext,
    isLoadingNext,
    loadNext,
    refetch,
  } = usePaginationFragment<
    Grantees_GranteesPagination_Query,
    Grantees_Refetchable_Organization$key
  >(ORGANIZATION_REFETCHABLE_FRAGMENT, organization);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const handleFiltersChange = (filters: OrganizationGranteesFilters) => {
    setFilters(filters);
    refetch({ filters });
  };

  const handleDataUpdated = () => {
    refetch({ filters });
  };

  const [tabIsTransitioning, startTabTransition] = useTransition();

  const handleTabChange = (index: number) => {
    startTabTransition(() => {
      const newStatus = index === 0 ? "Active" : "Terminated";

      handleFiltersChange({
        ...filters,
        hasDraftedGrants: null,
        hasPlannedGrants: null,
        status: newStatus,
      });
    });
  };

  const selectedIndex = filters.status === "Active" ? 0 : 1;

  const pageAttributes = useMemo(() => {
    switch (selectedIndex) {
      case 0:
        return {
          analyticsName: "Admin - Active Grantees",
          title: `Admin | ${organization.name} active grantees`,
        };
      case 1:
        return {
          analyticsName: "Admin - Terminated Grantees",
          title: `Admin | ${organization.name} terminated grantees`,
        };
    }
  }, [organization.name, selectedIndex]);

  const handleLoadNext = () => {
    loadNext(20);
  };

  return (
    <>
      <Page
        analyticsName={pageAttributes.analyticsName}
        organizationId={organization.id}
        title={pageAttributes.title}
      >
        <LargeOneColumnLayout
          organizationFragment={organization}
          title="Grantees"
          topBarActionsRender={() =>
            viewer.isAllowedToManageOrganization && (
              <div className="flex items-center gap-2">
                <SuspensedInviteGranteesModal
                  onDataUpdated={handleDataUpdated}
                  organizationFragment={organization}
                  renderController={({
                    granteePortalInvitationIsAllowedForOrganization,
                    organizationHasActiveWithoutPortalAccessGrantees,
                    showInviteGranteeModal,
                  }) => {
                    if (
                      !granteePortalInvitationIsAllowedForOrganization ||
                      !organizationHasActiveWithoutPortalAccessGrantees
                    ) {
                      return null;
                    }

                    return (
                      <Button
                        onClick={showInviteGranteeModal}
                        size="small"
                        variant="Secondary Outline"
                      >
                        Portal invitation
                      </Button>
                    );
                  }}
                  viewerFragment={viewer}
                />

                <CreateGranteeButton
                  mode="new-page"
                  organizationFragment={organization}
                />
              </div>
            )
          }
        >
          <Tab.Group onChange={handleTabChange} selectedIndex={selectedIndex}>
            <SuspensedTabList
              loading={tabIsTransitioning}
              organizationFragment={organization}
            />
            {viewer.isAllowedToManageOrganization &&
              !organization.isOriginatingFromRemoteEquity &&
              !organization.isConnectedToHRISProvider && (
                <HRISIntegrationNotice organizationId={organization.id} />
              )}

            <Tab.Panels className="space-y-4">
              <OrganizationGranteesTable
                filters={filters}
                granteesFragment={grantees.edges.map((edge) => edge.node)}
                granteesRelayConnectionIds={[grantees.__id]}
                hasNext={hasNext}
                isLoadingNext={isLoadingNext}
                loadNext={handleLoadNext}
                onFiltersChange={handleFiltersChange}
                organizationFragment={organization}
                totalCount={grantees.totalCount}
                viewerFragment={viewer}
              />
            </Tab.Panels>
          </Tab.Group>
        </LargeOneColumnLayout>
      </Page>
    </>
  );
};

const QUERY = graphql`
  query Grantees_Query(
    $organizationId: OrganizationId!
    $filters: OrganizationGranteesFilters!
  ) {
    organization(id: $organizationId) {
      ...Grantees_Organization
        @arguments(organizationId: $organizationId, filters: $filters)
    }
    me {
      ...Grantees_Viewer @arguments(organizationId: $organizationId)
    }
  }
`;

const filtersSchema = z.object({
  countryCode: z.string().nullish(),
  equityGridLevelId: z.string().nullish(),
  hasDraftedGrants: z.boolean().nullish(),
  hasPlannedGrants: z.boolean().nullish(),
  hRISProvider: z.enum(["DEEL", "REMOTE"]).nullish(),
  maximumTotalOwnershipValue: z.number().nullish(),
  minimumTotalOwnershipValue: z.number().nullish(),
  orderBy: z
    .object({
      direction: z.enum(["ASC", "DESC"]),
      field: z.enum([
        "equityGridLevel",
        "grantsCount",
        "jobTitle",
        "name",
        "portalAccess",
        "quantityExercisedRatio",
        "quantityGranted",
        "quantityVestedRatio",
        "settled",
      ]),
    })
    .nullish(),
  portalAccess: z.boolean().nullish(),
  portalInvitationSuggested: z.boolean().nullish(),
  search: z.string().nullish(),
  status: z.enum(["Active", "Terminated"]).nullish(),
  workRelationship: z
    .enum([
      "Advisor",
      "ContractorManagementCompany",
      "ContractorNaturalPerson",
      "DirectEmployee",
      "EoREmployee",
    ])
    .nullish(),
});

const AdminGranteesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();

  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, _setFilters] = useState<OrganizationGranteesFilters>(() => {
    const filters = searchParams.get("filters");

    if (filters) {
      const parsingResult = filtersSchema.safeParse(
        JSON.parse(decodeURI(atob(filters))),
      );
      if (parsingResult.success) {
        return parsingResult.data;
      }
    }

    return {
      status: "Active",
    };
  });

  const setFilters = useCallback(
    (filters: OrganizationGranteesFilters) => {
      setSearchParams((params) => ({
        ...params,
        filters: btoa(encodeURI(JSON.stringify(filters))),
      }));
      _setFilters(filters);
    },
    [setSearchParams],
  );

  const { query } = useQuery<Grantees_Query>(QUERY, {
    filters,
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGranteesPage_
      filters={filters}
      organizationFragment={query.organization}
      setFilters={setFilters}
      viewerFragment={query.me}
    />
  );
};

export default AdminGranteesPage;
