import React, { useState } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql } from "relay-runtime";

import { useToaster } from "../../../../components/Toaster";
import { Button } from "../../../../components/ui/Button";
import { Toast } from "../../../../components/ui/Toast";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { EquityTypeWorkRelationshipData } from "../../../../services/workRelationship";
import { EquityTypeWorkRelationshipGranteePortal_EquityType$key } from "./__generated__/EquityTypeWorkRelationshipGranteePortal_EquityType.graphql";
import { EquityTypeWorkRelationshipGranteePortal_SaveContent_Mutation } from "./__generated__/EquityTypeWorkRelationshipGranteePortal_SaveContent_Mutation.graphql";
import { EquityTypeWorkRelationshipGranteePortal_Viewer$key } from "./__generated__/EquityTypeWorkRelationshipGranteePortal_Viewer.graphql";
import GranteePortalReportingContent from "./EquityTypeWorkRelationshipGranteePortal/GranteePortalReportingContent";
import GranteePortalTaxationContent from "./EquityTypeWorkRelationshipGranteePortal/GranteePortalTaxationContent";

const EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipGranteePortal_EquityType on EquityType {
    id
    ...GranteePortalReportingContent_EquityType
    ...GranteePortalTaxationContent_EquityType
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationshipGranteePortal_Viewer on Account {
    ...GranteePortalReportingContent_Viewer
    ...GranteePortalTaxationContent_Viewer
  }
`;

const SAVE_ADMIN_PORTAL_CONTENT_MUTATION = graphql`
  mutation EquityTypeWorkRelationshipGranteePortal_SaveContent_Mutation(
    $equityTypeId: String!
    $equityTypeAdminPortalContentSlot: EquityTypeAdminPortalContentSlot!
    $content: String!
  ) {
    saveEquityTypeAdminPortalContent(
      equityTypeId: $equityTypeId
      equityTypeAdminPortalContentSlot: $equityTypeAdminPortalContentSlot
      content: $content
    ) {
      ...AdminPortalContent_AdminPortalContent
    }
  }
`;

const REFRESH_QUERY = graphql`
  query EquityTypeWorkRelationshipGranteePortal_Refresh_Query(
    $equityTypeId: UUID!
  ) {
    equityType(id: $equityTypeId) {
      ...EquityTypeWorkRelationshipGranteePortal_EquityType
    }
  }
`;

const REPORTING_TAB_LABEL = "Reporting - Grantee portal";
const TAXATION_TAB_LABEL = "Taxation - Grantee portal";

const EquityTypeWorkRelationshipGranteePortal: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationshipGranteePortal_EquityType$key;
  viewerFragment: EquityTypeWorkRelationshipGranteePortal_Viewer$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({ equityTypeFragment, viewerFragment, workRelationshipData }) => {
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const viewer = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT,
    viewerFragment,
  );

  const [saveContent, saveContentIsInFlight] =
    useSafeMutation<EquityTypeWorkRelationshipGranteePortal_SaveContent_Mutation>(
      SAVE_ADMIN_PORTAL_CONTENT_MUTATION,
    );

  const toaster = useToaster();
  const relayEnvironment = useRelayEnvironment();
  const refreshEquityType = () => {
    // @ts-expect-error - relay-runtime types are not up-to-date
    fetchQuery(relayEnvironment, REFRESH_QUERY, {
      equityTypeId: equityType.id,
    }).subscribe({});
  };

  const menus = [REPORTING_TAB_LABEL, TAXATION_TAB_LABEL];
  const [activeMenu, setActiveMenu] =
    useState<(typeof menus)[number]>(REPORTING_TAB_LABEL);

  const onSubmit: React.ComponentProps<
    typeof GranteePortalReportingContent
  >["onSubmit"] = async ({ content, equityTypeAdminPortalContentSlot }) => {
    await saveContent({
      variables: {
        content,
        equityTypeAdminPortalContentSlot,
        equityTypeId: equityType.id,
      },
    });

    // Needed to update equity type in store after save
    refreshEquityType();

    toaster.push(
      <Toast title="Wonderful!">The documentation has been saved!</Toast>,
    );
  };

  return (
    <>
      <div className="my-4 flex items-center justify-center gap-2">
        {menus.map((menu) => (
          <Button
            key={menu}
            onClick={() => {
              setActiveMenu(menu);
            }}
            size="small"
            variant={activeMenu === menu ? "Primary Outline" : "Tertiary Link"}
          >
            {menu}
          </Button>
        ))}
      </div>

      {activeMenu === REPORTING_TAB_LABEL ? (
        <GranteePortalReportingContent
          equityTypeFragment={equityType}
          isLoading={saveContentIsInFlight}
          onSubmit={onSubmit}
          viewerFragment={viewer}
          workRelationshipData={workRelationshipData}
        />
      ) : (
        <GranteePortalTaxationContent
          equityTypeFragment={equityType}
          isLoading={saveContentIsInFlight}
          onSubmit={onSubmit}
          viewerFragment={viewer}
          workRelationshipData={workRelationshipData}
        />
      )}
    </>
  );
};

export default EquityTypeWorkRelationshipGranteePortal;
