import { useMemo } from "react";
import { useWindowSize } from "react-use";

export const useTailwindActiveBreakpoints = () => {
  const { width } = useWindowSize();

  return useMemo(() => {
    // TODO: Get those breakpoints values from tailwind.config.js. See https://tailwindcss.com/docs/configuration#referencing-in-java-script
    return {
      isLg: width < 1280 && width >= 1024,
      isLgOrLarger: width >= 1024,
      isLgOrSmaller: width < 1280,
      isMd: width < 1024 && width >= 768,
      isMdOrLarger: width >= 768,
      isMdOrSmaller: width < 1024,
      isSm: width < 768 && width >= 640,
      isSmOrLarger: width >= 640,
      isSmOrSmaller: width < 768,
      isXl: width >= 1280,
      isXs: width < 640,
    };
  }, [width]);
};
