/**
 * @generated SignedSource<<6d2cf549f9e15ace856f9af5dd593ecd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatchEasopGrantsAndCreateBoardConsentResultFailureError = "DIFFERENT_ACCELERATION_CLAUSE";
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type BoardApproval_SendDraftsToBoard_Mutation$variables = {
  acknowledged409ARenewalObligation: boolean;
  boardConsentHandledOutsideEasop: boolean;
  boardConsentSignatureDate?: string | null;
  confirmedBoardMembersList: boolean;
  easopGrantsIds: ReadonlyArray<string>;
  grantsRequireEligibilityAcknowledgmentChecked: boolean;
  organizationId: string;
  signedBoardConsentDocumentIds?: ReadonlyArray<string> | null;
};
export type BoardApproval_SendDraftsToBoard_Mutation$data = {
  readonly batchEasopGrantsAndCreateBoardConsent: {
    readonly __typename: "BatchEasopGrantsAndCreateBoardConsentResultFailure";
    readonly error: BatchEasopGrantsAndCreateBoardConsentResultFailureError;
  } | {
    readonly __typename: "BatchEasopGrantsAndCreateBoardConsentResultSuccess";
    readonly easopGrants: ReadonlyArray<{
      readonly grantStatus: EasopGrantStatus;
    }>;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type BoardApproval_SendDraftsToBoard_Mutation = {
  response: BoardApproval_SendDraftsToBoard_Mutation$data;
  variables: BoardApproval_SendDraftsToBoard_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "acknowledged409ARenewalObligation"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentHandledOutsideEasop"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentSignatureDate"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "confirmedBoardMembersList"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "easopGrantsIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grantsRequireEligibilityAcknowledgmentChecked"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signedBoardConsentDocumentIds"
},
v8 = [
  {
    "kind": "Variable",
    "name": "acknowledged409ARenewalObligation",
    "variableName": "acknowledged409ARenewalObligation"
  },
  {
    "kind": "Variable",
    "name": "boardConsentHandledOutsideEasop",
    "variableName": "boardConsentHandledOutsideEasop"
  },
  {
    "kind": "Variable",
    "name": "boardConsentSignatureDate",
    "variableName": "boardConsentSignatureDate"
  },
  {
    "kind": "Variable",
    "name": "confirmedBoardMembersList",
    "variableName": "confirmedBoardMembersList"
  },
  {
    "kind": "Variable",
    "name": "easopGrantsIds",
    "variableName": "easopGrantsIds"
  },
  {
    "kind": "Variable",
    "name": "grantsRequireEligibilityAcknowledgmentChecked",
    "variableName": "grantsRequireEligibilityAcknowledgmentChecked"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "signedBoardConsentDocumentIds",
    "variableName": "signedBoardConsentDocumentIds"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "BatchEasopGrantsAndCreateBoardConsentResultFailure",
  "abstractKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "batchEasopGrantsAndCreateBoardConsent",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrants",
                "plural": true,
                "selections": [
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "BatchEasopGrantsAndCreateBoardConsentResultSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "batchEasopGrantsAndCreateBoardConsent",
        "plural": false,
        "selections": [
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EasopGrant",
                "kind": "LinkedField",
                "name": "easopGrants",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "BatchEasopGrantsAndCreateBoardConsentResultSuccess",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "214a4a2b4fefbfb7f1d8b0eabee32ebc",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_SendDraftsToBoard_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardApproval_SendDraftsToBoard_Mutation(\n  $easopGrantsIds: [UUID!]!\n  $organizationId: OrganizationId!\n  $acknowledged409ARenewalObligation: Boolean!\n  $confirmedBoardMembersList: Boolean!\n  $grantsRequireEligibilityAcknowledgmentChecked: Boolean!\n  $boardConsentHandledOutsideEasop: Boolean!\n  $boardConsentSignatureDate: Date\n  $signedBoardConsentDocumentIds: [String!]\n) {\n  batchEasopGrantsAndCreateBoardConsent(easopGrantsIds: $easopGrantsIds, organizationId: $organizationId, acknowledged409ARenewalObligation: $acknowledged409ARenewalObligation, confirmedBoardMembersList: $confirmedBoardMembersList, grantsRequireEligibilityAcknowledgmentChecked: $grantsRequireEligibilityAcknowledgmentChecked, boardConsentHandledOutsideEasop: $boardConsentHandledOutsideEasop, boardConsentSignatureDate: $boardConsentSignatureDate, signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds) {\n    __typename\n    ... on BatchEasopGrantsAndCreateBoardConsentResultFailure {\n      error\n    }\n    ... on BatchEasopGrantsAndCreateBoardConsentResultSuccess {\n      easopGrants {\n        grantStatus\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62e1c9091cb8187b9cdc2c80d8ea393c";

export default node;
