import { debounce } from "lodash";
import { useMemo, useState, useTransition } from "react";

import { LoadMoreButton } from "./ui/LoadMoreButton";
import { NoticeMessage } from "./ui/NoticeMessage";
import { SearchBar } from "./ui/SearchBar";
import { SlideOver } from "./ui/SlideOver";
import { Typography } from "./ui/Typography";

export type LoadMoreGrantsSlideOverState =
  | {
      ctmsGrantsIds: null | string[];
      show: false;
      title: null | React.ReactNode;
    }
  | {
      ctmsGrantsIds: string[];
      show: true;
      title: React.ReactNode;
    };

export function useLoadMoreGrantsSlideOverState() {
  const [state, setState] = useState<LoadMoreGrantsSlideOverState>({
    ctmsGrantsIds: [],
    show: false,
    title: null,
  });

  const open = ({
    ctmsGrantsIds,
    title,
  }: {
    ctmsGrantsIds: string[];
    title: React.ReactNode;
  }) => {
    setState({
      ctmsGrantsIds,
      show: true,
      title,
    });
  };

  const close = () => {
    setState((previousState) => ({
      ...previousState,
      show: false,
    }));
  };

  return {
    close,
    open,
    state,
  };
}

function GrantsList({
  children,
  hasNext,
  loading,
  noResult,
  onLoadMoreClick,
}: {
  children: React.ReactNode;
  hasNext: boolean;
  loading?: boolean;
  noResult: boolean;
  onLoadMoreClick: () => void;
}) {
  if (noResult) {
    return (
      <NoticeMessage size="Small">
        No grants match your current filters.
      </NoticeMessage>
    );
  }

  return (
    <div className="space-y-6">
      {children}
      {hasNext && (
        <div className="text-center">
          <LoadMoreButton
            className="mx-auto"
            loading={loading}
            onLoadMoreRequest={onLoadMoreClick}
          />
        </div>
      )}
    </div>
  );
}

const Layout: React.FC<
  React.PropsWithChildren<{
    onSearchChange: (search: string) => void;
    state: LoadMoreGrantsSlideOverState;
  }>
> = ({ children, onSearchChange, state }) => {
  const [search, setSearch] = useState("");
  const [transitionInProgress, startTransition] = useTransition();

  const debouncedOnSearchChange = useMemo(
    () =>
      debounce((search: string) => {
        startTransition(() => {
          onSearchChange(search);
        });
      }, 500),
    [onSearchChange],
  );

  return (
    <div className="space-y-6 p-6">
      {state.title && (
        <Typography
          as="div"
          className="w-full text-center"
          variant="Medium/Small"
        >
          {state.title}
        </Typography>
      )}
      <SearchBar
        loading={transitionInProgress}
        onChange={(value) => {
          setSearch(value);
          debouncedOnSearchChange(value);
        }}
        placeholder="Search grants or grantees..."
        value={search}
      />
      {children}
    </div>
  );
};

const _LoadMoreGrantsSlideOver: React.FC<{
  children: React.ReactNode;
  onClose: () => void;
  state: LoadMoreGrantsSlideOverState;
}> = ({ children, onClose, state }) => {
  return (
    <SlideOver
      floating
      header={<SlideOver.Header onClose={onClose} padding={6} />}
      onClose={onClose}
      show={state.show}
      width="2xl"
    >
      {children}
    </SlideOver>
  );
};

export const LoadMoreGrantsSlideOver = Object.assign(_LoadMoreGrantsSlideOver, {
  GrantsList,
  Layout,
});
