/**
 * @generated SignedSource<<3fde3057c0f42c0af7861bc46943127b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityOfferStatus = "DRAFTED" | "IMPLEMENTED" | "OFFER";
import { FragmentRefs } from "relay-runtime";
export type EquityOfferStatusTag_EquityOffer$data = {
  readonly status: EquityOfferStatus;
  readonly " $fragmentType": "EquityOfferStatusTag_EquityOffer";
};
export type EquityOfferStatusTag_EquityOffer$key = {
  readonly " $data"?: EquityOfferStatusTag_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferStatusTag_EquityOffer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOfferStatusTag_EquityOffer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};

(node as any).hash = "3aa6b468dc69442bc4b09efb06542068";

export default node;
