import { EnvelopeIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useApplicationSupportEmailAddress } from "../hooks/useApplicationTheme";
import { FreemiumBlockerCard_Organization$key } from "./__generated__/FreemiumBlockerCard_Organization.graphql";
import { PaidPlanTag } from "./PaidPlanTag";
import { LinkButton } from "./ui/Button";
import { Divider } from "./ui/Divider";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FreemiumBlockerCard_Organization on Organization {
    name
  }
`;

const LabelWithIcon: React.FC<{
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  label: string;
}> = ({ icon, label }) => (
  <div className="flex items-center gap-2 px-4">
    <div className="rounded bg-gray-01 p-1 text-black-05">
      {React.cloneElement(icon, {
        ...icon.props,
        className: classNames(icon.props.className, /* tailwind */ `h-4 w-4`),
      })}
    </div>
    <Typography className="text-balck-05" variant="Regular/Extra Small">
      {label}
    </Typography>
  </div>
);

const FreemiumBlockerCard_: React.FC<{
  detailsList: React.ReactNode;
  mailtoSubject: string;
  organizationFragment: FreemiumBlockerCard_Organization$key;
  rightSideIllustration: React.ReactNode;
  subTitle: React.ReactNode;
  title: string;
}> = ({
  detailsList,
  mailtoSubject: _mailtoSubject,
  organizationFragment,
  rightSideIllustration,
  subTitle,
  title,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const supportEmailAddress = useApplicationSupportEmailAddress();
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] ${_mailtoSubject}`,
  );

  return (
    <RoundedBox
      className={classNames("shadow-300", "flex gap-6 px-14 py-16")}
      withBorder
    >
      <div className="flex-1 space-y-6">
        <PaidPlanTag />
        <Typography as="div" variant="Medium/Large">
          {title}
        </Typography>
        <Typography as="div" className="text-black-05" variant="Regular/Small">
          {subTitle}
        </Typography>
        <LinkButton
          size="small"
          to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
          variant="Primary Full"
        >
          Contact Us
        </LinkButton>
        <Divider withGradient />
        <div className="space-y-4">{detailsList}</div>
        <Divider withGradient />
        <div className="space-y-1">
          <Typography
            as="div"
            className="text-gray-09"
            variant="Regular/Extra Small"
          >
            Any questions? Feel free to contact our team
          </Typography>
          <Link
            className="flex cursor-pointer gap-1 text-primary"
            to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
          >
            <EnvelopeIcon className="h-5 w-5" />
            <Typography variant="Medium/Extra Small">Contact Us</Typography>
          </Link>
        </div>
      </div>
      {rightSideIllustration}
    </RoundedBox>
  );
};

export const FreemiumBlockerCard = Object.assign(FreemiumBlockerCard_, {
  LabelWithIcon,
});
