import { ReactNode } from "react";

import { RoundedBox } from "./RoundedBox";
import { Typography } from "./Typography";

export const Scenario: React.FC<{
  children: ReactNode;
  className?: string;
  frequency: string;
  title: string;
}> = ({ children, className, frequency, title }) => {
  return (
    <RoundedBox className={className} withBorder withShadow>
      <Typography
        as="div"
        className="rounded-t-lg bg-black-07 px-4 py-2 text-white"
        variant="Medium/Small"
      >
        {frequency}
      </Typography>
      <div className="p-6">
        <Typography as="div" className="text-black-07" variant="Medium/Large">
          {title}
        </Typography>
        <Typography
          as="div"
          className="mt-2 text-black-07"
          variant="Regular/Small"
        >
          {children}
        </Typography>
      </div>
    </RoundedBox>
  );
};
