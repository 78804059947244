import { useMemo } from "react";

const CSV_SEPARATOR = ",";

export function useCSVDownloadLink({
  header,
  rows,
}: {
  header: string[];
  rows: string[][];
}) {
  return useMemo(() => {
    const csv = [
      header.join(CSV_SEPARATOR),
      ...rows.map((row) => row.map(escapeCSV).join(CSV_SEPARATOR)),
    ].join("\n");

    const blob = new Blob([csv], { type: "text/csv" });

    return URL.createObjectURL(blob);
  }, [rows, header]);
}

function escapeCSV(field: string): string {
  // Check if the field contains commas, double quotes, or line breaks
  if (/["\r\n,]/.test(field)) {
    // Double up any double quotes in the field
    return `"${field.replace(/"/g, '""')}"`;
  }
  return field;
}
