import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { useOrganizationIdParam } from "../../../paths";
import { Projection_Planning_Query } from "./__generated__/Projection_Planning_Query.graphql";
import { PlanningChartView } from "./Planning/PlanningChartView";

const QUERY = graphql`
  query Projection_Planning_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      id
      ...PlanningChartView_Organization
    }
  }
`;

const AdminPlanningProjectionPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<Projection_Planning_Query>(QUERY, {
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Planning"
      analyticsName="Admin - Planning - Projection"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} planning | projection`}
    >
      <PlanningChartView organizationFragment={query.organization} />
    </Page>
  );
};

export default AdminPlanningProjectionPage;
