/**
 * @generated SignedSource<<8465f00416e5de44da7a23f37dd0a0b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type CliffPeriodsGraph_Organization$data = {
  readonly grantees: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly ctmsGrants: ReadonlyArray<{
          readonly firstVestingEventDate: string | null;
          readonly id: string;
        }>;
        readonly status: GranteeStatus;
        readonly totalVestedSharesBreakdown: {
          readonly total: number;
        };
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "CliffPeriodsGraph_Organization";
};
export type CliffPeriodsGraph_Organization$key = {
  readonly " $data"?: CliffPeriodsGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CliffPeriodsGraph_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CliffPeriodsGraph_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteesConnection",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Grantee",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "grantStatusIn",
                      "value": [
                        "Active"
                      ]
                    }
                  ],
                  "concreteType": "CTMSGrant",
                  "kind": "LinkedField",
                  "name": "ctmsGrants",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstVestingEventDate",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SharesBreakdown",
                  "kind": "LinkedField",
                  "name": "totalVestedSharesBreakdown",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "total",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "2d7f63106fb2c5400b1dcc6b902d9211";

export default node;
