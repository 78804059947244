import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { zodExtra } from "../../helpers/zod-extra";
import { Button } from "../ui/Button";
import { FormActions } from "../ui/Form/FormActions";
import { FormRow } from "../ui/Form/FormRow";
import { DatePicker } from "../ui/Form/Inputs/DatePicker";
import { SelectAutocomplete } from "../ui/Form/Inputs/Select/SelectAutocomplete";
import { SlideOver } from "../ui/SlideOver";

const schema = z
  .object({
    vestingEndCustomDate: zodExtra.ISODate().optional(),
    vestingEndOption: zodExtra.vestingEndOption(),
  })
  .refine(
    ({ vestingEndCustomDate, vestingEndOption }) =>
      vestingEndOption !== "CUSTOM_DATE" ||
      typeof vestingEndCustomDate === "string",
    { message: "Required", path: ["vestingEndCustomDate"] },
  );

type GrantAmendmentRequestVestingEndFormInputs = z.output<typeof schema>;

export const VESTING_OPTION_MAP = {
  CUSTOM_DATE: "Custom date",
  LAST_DAY_AT_THE_COMPANY: "Last day at the company",
  TERMINATION_DATE: "Termination date",
} as const;

export const GrantAmendmentRequestBoxVestingEndSlide: React.FC<{
  currentVesting: GrantAmendmentRequestVestingEndFormInputs;
  onClose: () => void;
  onSubmit: (
    grantVestingAmendment: GrantAmendmentRequestVestingEndFormInputs,
  ) => void;
  open: boolean;
}> = ({ currentVesting, onClose, onSubmit, open }) => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      ...currentVesting,
    },
    resolver: zodResolver(schema),
  });

  const onSubmitForm = handleSubmit(
    (data: GrantAmendmentRequestVestingEndFormInputs) => {
      onSubmit({
        vestingEndCustomDate:
          data.vestingEndOption === "CUSTOM_DATE"
            ? data.vestingEndCustomDate
            : undefined,
        vestingEndOption: data.vestingEndOption,
      });

      onClose();
    },
  );

  const vestingEndOption = watch("vestingEndOption");
  const options = [
    { label: VESTING_OPTION_MAP.TERMINATION_DATE, value: "TERMINATION_DATE" },
    {
      label: VESTING_OPTION_MAP.LAST_DAY_AT_THE_COMPANY,
      value: "LAST_DAY_AT_THE_COMPANY",
    },
    { label: VESTING_OPTION_MAP.CUSTOM_DATE, value: "CUSTOM_DATE" },
  ] as const;

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={10}>
          Vesting schedule ends
        </SlideOver.Header>
      }
      onClose={onClose}
      show={open}
    >
      <div className="flex flex-col gap-4 px-10 py-6">
        <Controller
          control={control}
          name="vestingEndOption"
          render={({ field, fieldState }) => (
            <FormRow error={fieldState.error?.message} label="Vesting ends on">
              <SelectAutocomplete
                getOptionLabel={(vestingEndOption) => vestingEndOption.label}
                getOptionValue={(vestingEndOption) => vestingEndOption.value}
                onChange={(option) => {
                  field.onChange(option?.value);
                }}
                options={options}
                value={
                  field.value
                    ? options.find((option) => option.value === field.value)
                    : null
                }
              />
            </FormRow>
          )}
        />

        {vestingEndOption === "CUSTOM_DATE" ? (
          <Controller
            control={control}
            name="vestingEndCustomDate"
            render={({ field, fieldState }) => (
              <FormRow error={fieldState.error?.message} label="Select a date">
                <DatePicker
                  onChange={field.onChange}
                  panelPosition="bottom"
                  placeholder="Select a date"
                  value={field.value}
                />
              </FormRow>
            )}
          />
        ) : null}

        <FormActions className="mt-14 justify-between" onCancel={onClose}>
          <Button onClick={onSubmitForm} size="small" type="button">
            Modify
          </Button>
        </FormActions>
      </div>
    </SlideOver>
  );
};
