/**
 * @generated SignedSource<<51f332e6f8b88c7e2dc35c0130769b0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriptionBillingPeriod = "monthly" | "yearly";
import { FragmentRefs } from "relay-runtime";
export type LockedGeographySlideOver_Content_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly subscriptionBillingPeriod: SubscriptionBillingPeriod | null;
  readonly " $fragmentType": "LockedGeographySlideOver_Content_Organization";
};
export type LockedGeographySlideOver_Content_Organization$key = {
  readonly " $data"?: LockedGeographySlideOver_Content_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LockedGeographySlideOver_Content_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LockedGeographySlideOver_Content_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionBillingPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "1b54cbf7da32c7e538c36e6a6243f21d";

export default node;
