import { CenteredColumnLayout } from "../../components/ui/Layout/CenteredColumnLayout";
import { Picture } from "../../components/ui/Picture";
import { Typography } from "../../components/ui/Typography";
import image from "./error.png";

export default function ErrorPage({
  action,
  subtitle,
  title,
}: React.PropsWithChildren<{
  action?: React.ReactNode;
  subtitle: React.ReactNode;
  title: React.ReactNode;
}>) {
  return (
    <CenteredColumnLayout backgroundColor="gray-01" fullHeight showFooter>
      <div className="flex h-full items-center justify-center">
        <div className="mx-auto w-full max-w-lg space-y-6 rounded-lg border-[0.5px] border-gray-04 bg-white px-6 py-10 text-center shadow-100">
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Extra Large">
              {title}
            </Typography>
            <Typography as="div" variant="Regular/Small">
              {subtitle}
            </Typography>
          </div>
          {action && (
            <Typography as="div" variant="Medium/Default">
              {action}
            </Typography>
          )}
          <Picture className="mx-auto w-full" image={image} />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}
