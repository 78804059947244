import {
  CalendarDaysIcon,
  ClipboardDocumentCheckIcon,
  ExclamationTriangleIcon,
  HandThumbUpIcon,
  ReceiptPercentIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { generatePath } from "react-router-dom";

import { Page } from "../../../components/Page";
import { SuperAdminHomeLink } from "../../../components/SuperAdminHomeLink";
import { Divider } from "../../../components/ui/Divider";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";

const _LegalContent: React.FC = () => {
  return (
    <CenteredColumnLayout maxWidth="800" showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Legal content
        </Typography>
        <RoundedBox className="flex flex-col" withBorder>
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="primary"
                icon={<ReceiptPercentIcon />}
              />
            }
            subTitle="Create a new tax parameter per country and per work relationship"
            title="Exercises Tax Parameters"
            to={generatePath(
              APPLICATION_ROUTES["superAdminExercisesTaxParameters"],
            )}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="purple"
                icon={<CalendarDaysIcon />}
              />
            }
            subTitle="Create a new ongoing obligation for a specific instrument"
            title="Ongoing Obligations"
            to={generatePath(
              APPLICATION_ROUTES["superAdminOngoingObligations"],
            )}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="green"
                icon={<ClipboardDocumentCheckIcon />}
              />
            }
            subTitle="Configure documents that need to be approved by board members"
            title="Documents approval"
            to={generatePath(APPLICATION_ROUTES["superAdminDocumentsApproval"])}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="orange"
                icon={<ExclamationTriangleIcon />}
              />
            }
            subTitle="Create a new watch out to be displayed when granting equity"
            title="Legal Watch Outs"
            to={generatePath(APPLICATION_ROUTES["superAdminLegalWatchOuts"])}
          />
          <Divider />
          <SuperAdminHomeLink
            icon={
              <SuperAdminHomeLink.Icon
                color="green"
                icon={<HandThumbUpIcon />}
              />
            }
            subTitle="Configure for which instruments is early exercise beneficial"
            title="Mark Early Exercise as Beneficial"
            to={generatePath(
              APPLICATION_ROUTES["superAdminEarlyExerciseBeneficial"],
            )}
          />
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};

const LegalContent: React.FC = () => {
  return (
    <Page
      analyticsName="Super Admin - Legal content"
      title={`Super admin | Legal content`}
    >
      <_LegalContent />
    </Page>
  );
};

export default LegalContent;
