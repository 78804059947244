import React from "react";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { useQuery } from "../../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { OrganizationSettingsBilling_Query } from "./__generated__/OrganizationSettingsBilling_Query.graphql";
import { EasopOrganizationSettingsBillingPage } from "./EasopOrganizationSettingsBilling";
import { RemoteEquityOrganizationSettingsBillingPage } from "./RemoteEquityOrganizationSettingsBilling";

const QUERY = graphql`
  query OrganizationSettingsBilling_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      isOriginatingFromRemoteEquity
      ...EasopOrganizationSettingsBilling_Organization
      ...RemoteEquityOrganizationSettingsBilling_Organization
    }
    organizationStripeAccount(organizationId: $organizationId) {
      ...EasopOrganizationSettingsBilling_StripeCustomer
    }
  }
`;

const OrganizationSettingsBillingPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<OrganizationSettingsBilling_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Admin Settings"
      analyticsName="Admin - Plan & Billing"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} plan & billing`}
    >
      {query.organization.isOriginatingFromRemoteEquity ? (
        <RemoteEquityOrganizationSettingsBillingPage
          organizationFragment={query.organization}
        />
      ) : (
        <EasopOrganizationSettingsBillingPage
          organizationFragment={query.organization}
          stripeCustomerFragment={query.organizationStripeAccount}
        />
      )}
    </Page>
  );
};

export default OrganizationSettingsBillingPage;
