import classNames from "classnames";
import { isNil } from "lodash";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FormattedCurrencyCompact } from "../../../components/Formatted/FormattedCurrencyCompact";
import { FormattedFMV } from "../../../components/FormattedFMV";
import { LabelValue } from "../../../components/ui/LabelValue";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { Company_EquityOffer$key } from "./__generated__/Company_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Company_EquityOffer on IEquityOffer {
    exercisePrice
    displayCompanyInformation
    organizationLatestValuation
    organizationLatestPricePerShare {
      value
    }
    organizationLatestFairMarketValue {
      value
    }
    organization {
      name
    }
  }
`;

export const EquityOfferCompany: React.FC<{
  equityOfferFragment: Company_EquityOffer$key;
  forceMobile?: boolean;
  hideNoticeMessages?: boolean;
  isVirtual?: boolean;
  preview?: boolean;
}> = ({
  equityOfferFragment,
  forceMobile = false,
  hideNoticeMessages = false,
  isVirtual,
  preview = false,
}) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);
  const organization = equityOffer.organization;

  if (!equityOffer.displayCompanyInformation && !preview) {
    return null;
  }

  const strikePrice =
    equityOffer.exercisePrice ??
    equityOffer.organizationLatestFairMarketValue?.value;

  return (
    <div className="flex flex-col gap-6">
      <Typography variant="Medium/Large">
        What is {organization.name}, Inc worth today?
      </Typography>

      <div
        className={classNames("flex flex-col gap-4 md:flex-row", {
          "flex-col": forceMobile,
        })}
      >
        <RoundedBox
          className="flex flex-1 flex-col gap-4 p-4"
          withBorder
          withShadow
        >
          <LabelValue label="Company valuation">
            {!isNil(equityOffer.organizationLatestValuation) ? (
              <FormattedCurrencyCompact
                value={equityOffer.organizationLatestValuation}
              />
            ) : (
              <>-</>
            )}
          </LabelValue>
          {!hideNoticeMessages && (
            <NoticeMessage size="Small" variant="Idea">
              The company valuation in the eyes of the latest investors
            </NoticeMessage>
          )}
        </RoundedBox>
        <RoundedBox
          className="flex flex-1 flex-col gap-4 p-4"
          withBorder
          withShadow
        >
          <LabelValue label="Price per share">
            {equityOffer.organizationLatestPricePerShare ? (
              <FormattedFMV
                value={equityOffer.organizationLatestPricePerShare.value}
              />
            ) : (
              <>-</>
            )}
          </LabelValue>
          {!hideNoticeMessages && (
            <NoticeMessage size="Small" variant="Idea">
              The valuation of 1 share held by the latest investors (preferred
              shares)
            </NoticeMessage>
          )}
        </RoundedBox>
        <RoundedBox
          className="flex flex-1 flex-col gap-4 p-4"
          withBorder
          withShadow
        >
          <LabelValue label="Strike price">
            {!isNil(strikePrice) ? (
              <FormattedFMV value={strikePrice} />
            ) : (
              <>-</>
            )}
          </LabelValue>
          {!hideNoticeMessages && (
            <NoticeMessage size="Small" variant="Idea">
              {isVirtual
                ? "The strike price is most of the time virtual, i.e. it will simply be deducted from the amount of the total cash bonus you can receive upon a liquidity event. You don't need to pay anything."
                : "The price you would pay to buy 1 share"}
            </NoticeMessage>
          )}
        </RoundedBox>
      </div>
    </div>
  );
};
