/**
 * @generated SignedSource<<6933b8e768f12362f30135ce5aa6a937>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderwaterGrants_Query$variables = {
  organizationId: string;
};
export type UnderwaterGrants_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrants_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrants_Organization">;
  } | null;
};
export type UnderwaterGrants_Query = {
  response: UnderwaterGrants_Query$data;
  variables: UnderwaterGrants_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnderwaterGrants_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderwaterGrants_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnderwaterGrants_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnderwaterGrants_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": "activeCTMSGrants",
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "statusIn": [
                    "Active"
                  ]
                }
              }
            ],
            "concreteType": "OrganizationCTMSGrantsConnection",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationCTMSGrantsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "exercisePrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equityTypeAwardName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "formattedGranteeName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grantDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "quantityIssued",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\"]})"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FairMarketValue",
            "kind": "LinkedField",
            "name": "latestFairMarketValue",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63ba6c95fed287215dfac19ae400a6c3",
    "id": null,
    "metadata": {},
    "name": "UnderwaterGrants_Query",
    "operationKind": "query",
    "text": "query UnderwaterGrants_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n    ...UnderwaterGrants_Organization\n  }\n  me {\n    ...UnderwaterGrants_Viewer\n    id\n  }\n}\n\nfragment BookADemoSlideOver_Viewer on Account {\n  email\n  firstName\n  lastName\n}\n\nfragment DownloadUnderwaterGrantsCSV_CTMSGrant on CTMSGrant {\n  label\n  exercisePrice\n  formattedGranteeName\n  grantDate\n  quantityIssued\n  equityTypeAwardName\n}\n\nfragment UnderwaterGrantsGraph_CTMSGrant on CTMSGrant {\n  id\n  label\n}\n\nfragment UnderwaterGrantsGraph_Organization on Organization {\n  id\n}\n\nfragment UnderwaterGrants_Organization on Organization {\n  id\n  name\n  activeCTMSGrants: ctmsGrants(filters: {statusIn: [Active]}) {\n    edges {\n      node {\n        id\n        exercisePrice\n        equityTypeAwardName\n        ...DownloadUnderwaterGrantsCSV_CTMSGrant\n        ...UnderwaterGrantsGraph_CTMSGrant\n      }\n    }\n  }\n  latestFairMarketValue {\n    value\n    id\n  }\n  ...UnderwaterGrantsGraph_Organization\n}\n\nfragment UnderwaterGrants_Viewer on Account {\n  isSuperAdmin\n  ...BookADemoSlideOver_Viewer\n}\n"
  }
};
})();

(node as any).hash = "67b01fb9a6df5bda895db4a28546899f";

export default node;
