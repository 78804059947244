/**
 * @generated SignedSource<<1657e0f1e8f4ad77f977c6e2aeab4bde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteEquityPlanCountryThresholdProgressBar_Organization$data = {
  readonly billableOrganizationGeographies: ReadonlyArray<{
    readonly __typename: "OrganizationGeography";
  }>;
  readonly remoteEquityPlanCountryThreshold: number | null;
  readonly " $fragmentType": "RemoteEquityPlanCountryThresholdProgressBar_Organization";
};
export type RemoteEquityPlanCountryThresholdProgressBar_Organization$key = {
  readonly " $data"?: RemoteEquityPlanCountryThresholdProgressBar_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoteEquityPlanCountryThresholdProgressBar_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoteEquityPlanCountryThresholdProgressBar_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "billableOrganizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "remoteEquityPlanCountryThreshold",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0d68d6640b31f23a6a84fd24f3b96744";

export default node;
