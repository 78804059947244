import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";

import { Typography } from "./Typography";

export const ArrowLine: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <div className="flex items-center gap-2">
    <div className="flex h-8 w-8 rounded-full bg-primary-01 p-1">
      <ArrowRightCircleIcon className="text-primary" />
    </div>
    <Typography variant="Regular/Extra Small">{children}</Typography>
  </div>
);
