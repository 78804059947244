import { deburr } from "lodash";

const normalizeString = (string: string) => {
  return deburr(string).toLowerCase();
};

export const searchInString = (string: string, search: string) =>
  normalizeString(string).includes(normalizeString(search));

export const lowercaseFirstLetter = (str: string) =>
  `${str.charAt(0).toLowerCase()}${str.slice(1)}`;
