/**
 * @generated SignedSource<<dbbdd4adf76237740e055e8f5a8c398f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OngoingObligationsTable_DeleteOngoingObligation_Mutation$variables = {
  ongoingObligationId: string;
};
export type OngoingObligationsTable_DeleteOngoingObligation_Mutation$data = {
  readonly deleteOngoingObligation: any | null;
};
export type OngoingObligationsTable_DeleteOngoingObligation_Mutation = {
  response: OngoingObligationsTable_DeleteOngoingObligation_Mutation$data;
  variables: OngoingObligationsTable_DeleteOngoingObligation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ongoingObligationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ongoingObligationId",
        "variableName": "ongoingObligationId"
      }
    ],
    "kind": "ScalarField",
    "name": "deleteOngoingObligation",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OngoingObligationsTable_DeleteOngoingObligation_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OngoingObligationsTable_DeleteOngoingObligation_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "772780ae0345e060df06e0aae297643a",
    "id": null,
    "metadata": {},
    "name": "OngoingObligationsTable_DeleteOngoingObligation_Mutation",
    "operationKind": "mutation",
    "text": "mutation OngoingObligationsTable_DeleteOngoingObligation_Mutation(\n  $ongoingObligationId: UUID!\n) {\n  deleteOngoingObligation(ongoingObligationId: $ongoingObligationId)\n}\n"
  }
};
})();

(node as any).hash = "2f701587c366feec56fd3561d60a978e";

export default node;
