/**
 * @generated SignedSource<<32da52965300ed91ef420ddb069202ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest">;
  readonly " $fragmentType": "ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest";
}>;
export type ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest$key = ReadonlyArray<{
  readonly " $data"?: ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest"
    }
  ],
  "type": "GranteeTerminationCTMSGrantAmendmentRequest",
  "abstractKey": null
};

(node as any).hash = "a0309da857cc0c04a9ef2f8b7a4929a2";

export default node;
