/**
 * @generated SignedSource<<70869cc7f3f5f134f7f1c6d6587cd868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverChartCard_GrantBoardConsent$data = {
  readonly __typename: "GrantBoardConsent";
  readonly organization: {
    readonly esopPoolShares: number | null;
    readonly poolAvailableShares: number;
    readonly poolGrantedToVestSharesBreakdown: {
      readonly total: number;
    };
    readonly poolPendingSharesBreakdown: {
      readonly total: number;
    };
    readonly poolSettlingSharesBreakdown: {
      readonly total: number;
    };
    readonly poolVestedExercisedSharesBreakdown: {
      readonly total: number;
    };
    readonly poolVestedToExerciseSharesBreakdown: {
      readonly total: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"EsopChartGrantedStacked_Organization" | "useOrganizationSharesUtil_Organization">;
  };
  readonly totalGrantedShares: number;
  readonly " $fragmentType": "ApprovalSlideOverChartCard_GrantBoardConsent";
};
export type ApprovalSlideOverChartCard_GrantBoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverChartCard_GrantBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverChartCard_GrantBoardConsent">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverChartCard_GrantBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalGrantedShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "useOrganizationSharesUtil_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EsopChartGrantedStacked_Organization"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "poolAvailableShares",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "poolVestedExercisedSharesBreakdown",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "poolVestedToExerciseSharesBreakdown",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "poolGrantedToVestSharesBreakdown",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "poolSettlingSharesBreakdown",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SharesBreakdown",
          "kind": "LinkedField",
          "name": "poolPendingSharesBreakdown",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "esopPoolShares",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GrantBoardConsent",
  "abstractKey": null
};
})();

(node as any).hash = "574a713e39d3c465917bfc367a020841";

export default node;
