import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { EquityGridLevelTopUpTip_Grantee$key } from "./__generated__/EquityGridLevelTopUpTip_Grantee.graphql";
import { Button } from "./ui/Button";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment EquityGridLevelTopUpTip_Grantee on Grantee {
    equityGridLevel {
      name
    }
    offGrid
    missingSharesToEquityGridLevel
  }
`;

export const EquityGridLevelTopUpTip: React.FC<{
  granteeFragment: EquityGridLevelTopUpTip_Grantee$key;
  onGrantTipClicked: (shares: number) => void;
}> = ({ granteeFragment, onGrantTipClicked }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const granteeEquityGridLevel = grantee.equityGridLevel;

  if (grantee.offGrid) return null;
  if (!granteeEquityGridLevel) return null;
  if (grantee.missingSharesToEquityGridLevel === null) return null;
  if (grantee.missingSharesToEquityGridLevel <= 0) return null;

  const sharesToBeGranted = Math.abs(grantee.missingSharesToEquityGridLevel);

  return (
    <Typography
      as="div"
      className="flex flex-col gap-4 rounded bg-gray-01 px-4 py-3"
      variant="Regular/Extra Small"
    >
      <div className="flex items-center gap-4">
        <span>ℹ️</span>
        <div className="flex flex-col gap-1.5">
          <Typography className="text-black-05" variant="Regular/Caption">
            Based on your equity grid
          </Typography>
          <span>
            Grantees with <Tag>{granteeEquityGridLevel.name}</Tag> equity level
            should receive{" "}
            <Tag>
              <FormattedNumber value={sharesToBeGranted} /> shares
            </Tag>
            .
          </span>
        </div>
      </div>
      <div>
        <Button
          onClick={() => onGrantTipClicked(sharesToBeGranted)}
          size="small"
          type="button"
        >
          Grant <FormattedNumber value={sharesToBeGranted} /> shares?
        </Button>
      </div>
    </Typography>
  );
};
