/**
 * @generated SignedSource<<b960fc5f248acfcf3dafda4765b7cd58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type EquityIncentivesAvailable_Geography$data = {
  readonly equityTypes: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly instruments: ReadonlyArray<{
      readonly workRelationship: WorkRelationship;
    }>;
    readonly name: EquityTypeName;
    readonly taxFavored: boolean;
  }>;
  readonly " $fragmentType": "EquityIncentivesAvailable_Geography";
};
export type EquityIncentivesAvailable_Geography$key = {
  readonly " $data"?: EquityIncentivesAvailable_Geography$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityIncentivesAvailable_Geography">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityIncentivesAvailable_Geography",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityType",
      "kind": "LinkedField",
      "name": "equityTypes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavored",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Instrument",
          "kind": "LinkedField",
          "name": "instruments",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "workRelationship",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Geography",
  "abstractKey": null
};

(node as any).hash = "8091e9499f37dc0cd76fee2f3c5fbbdc";

export default node;
