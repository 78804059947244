import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useEquityOfferVestingSettingsForm } from "../pages/Admin/EquityOffer/VestingSettings";
import { EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization$key } from "./__generated__/EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization.graphql";
import { SelectOrganizationPostTerminationExercisePeriod } from "./GrantForm/SelectOrganizationPostTerminationExercisePeriod";
import { FormRow } from "./ui/Form/FormRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization on Organization {
    ...SelectOrganizationPostTerminationExercisePeriod_Organization
  }
`;

export const EquityOfferPostTerminationExercisePeriodSelectionBlock: React.FC<{
  control: ReturnType<typeof useEquityOfferVestingSettingsForm>["control"];
  isVirtual?: boolean;
  organizationFragment: EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization$key;
}> = ({ control, isVirtual, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <FormRow.Form
      control={control}
      label={
        isVirtual
          ? "Post-termination settlement period"
          : "Post-termination exercise period"
      }
      name="postTerminationExercisePeriodId"
    >
      <SelectOrganizationPostTerminationExercisePeriod
        control={control}
        doNotAllowVariablePTEP
        isVirtual={isVirtual ?? false}
        name="postTerminationExercisePeriodId"
        organizationFragment={organization}
      />
    </FormRow.Form>
  );
};
