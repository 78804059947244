/**
 * @generated SignedSource<<70310d6e9ba89c8d40088459d315cfff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepricedToCtmsGrantTag_CtmsGrant$data = {
  readonly id: string;
  readonly label: string;
  readonly organizationId: string;
  readonly " $fragmentType": "RepricedToCtmsGrantTag_CtmsGrant";
};
export type RepricedToCtmsGrantTag_CtmsGrant$key = {
  readonly " $data"?: RepricedToCtmsGrantTag_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepricedToCtmsGrantTag_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepricedToCtmsGrantTag_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "dece1311e9047551e8af955882253d2b";

export default node;
