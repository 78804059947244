import classNames from "classnames";
import React, { forwardRef, useMemo } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

import { LoadingSpinner } from "../../LoadingSpinner";
import { Typography } from "../../Typography";

const FMV_DECIMAL_STEP = 0.00001;

export const getInputWrapperClassName = ({
  disabled,
  invalid,
}: {
  disabled: boolean;
  invalid: boolean;
}) => {
  return classNames(
    "flex rounded-lg border-[0.5px] focus-within:outline-none",
    {
      "bg-white-01": !disabled,
      "border-gray-07 focus-within:border-primary focus-within:shadow-100 hover:border-gray-09 hover:focus-within:border-primary":
        !invalid,
      "border-red focus-within:border-gray-07 focus-within:shadow-input-focus":
        invalid,
      "cursor-not-allowed bg-gray-02 opacity-50": disabled,
    },
  );
};

export const getInputClassName = ({ disabled }: { disabled: boolean }) => {
  return classNames(
    /* tailwind */ `font-regularExtraSmall placeholder:font-regularExtraSmall form-input w-full rounded-lg !border-0 bg-transparent px-3 py-4 font-sans text-black-07 !shadow-none ring-0 placeholder:font-sans placeholder:text-gray-08 focus:outline-0 focus:ring-0`,
    {
      "cursor-not-allowed": disabled,
    },
  );
};

const Wrapper: React.FC<{
  _forwardInvalidToChildren?: boolean;
  after?: React.ReactNode;
  before?: React.ReactNode;
  children: React.ReactElement<{
    className?: string;
    disabled?: boolean;
    invalid?: boolean;
  }>;
  className?: string;
  disabled?: boolean;
  invalid?: boolean;
  loading?: boolean;
}> = ({
  _forwardInvalidToChildren = true,
  after,
  before,
  children,
  className,
  disabled = false,
  invalid = false,
  loading,
}) => {
  const hasLeftAddon = !!before;
  const hasRightAddon = !!after;

  const childrenProps = useMemo(() => {
    const props = {
      disabled,
      ...children.props,
      className: classNames(
        children.props.className,
        getInputClassName({ disabled }),
        {
          "pl-2": hasLeftAddon,
          "rounded-l-lg": !hasLeftAddon,
          "rounded-r-lg": !hasRightAddon,
        },
      ),
    };

    if (_forwardInvalidToChildren && invalid) {
      props.invalid = invalid;
    }

    return props;
  }, [
    _forwardInvalidToChildren,
    children.props,
    disabled,
    hasLeftAddon,
    hasRightAddon,
    invalid,
  ]);

  return (
    <div
      className={classNames(
        getInputWrapperClassName({ disabled, invalid }),
        className,
      )}
    >
      {before && (
        <Typography
          as="div"
          className="flex select-none items-center whitespace-nowrap pl-3 text-gray-09"
          variant="Regular/Extra Small"
        >
          {before}
        </Typography>
      )}

      <div className="flex-grow [&_button.date-picker]:!border-0">
        {React.cloneElement(children, childrenProps)}
      </div>

      {after && (
        <Typography
          className="flex select-none items-center whitespace-nowrap pr-3 text-gray-09"
          variant="Regular/Extra Small"
        >
          {after}
        </Typography>
      )}
      {loading && (
        <div className="flex items-center justify-center pr-3">
          <LoadingSpinner className="w-4" />
        </div>
      )}
    </div>
  );
};

const _Input = forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input"> & {
    _forwardInvalidToChildren?: boolean;
    after?: React.ReactNode;
    before?: React.ReactNode;
    invalid?: boolean;
    loading?: boolean;
    wrapperClassName?: string;
  }
>(function Input(
  {
    _forwardInvalidToChildren = true,
    after,
    before,
    className,
    disabled = false,
    id,
    invalid = false,
    loading,
    type = "text",
    wrapperClassName,
    ...inputProps
  },
  ref,
) {
  return (
    <Wrapper
      _forwardInvalidToChildren={_forwardInvalidToChildren}
      after={after}
      before={before}
      className={wrapperClassName}
      disabled={disabled}
      invalid={invalid}
      loading={loading}
    >
      <input
        className={className}
        id={id}
        ref={ref}
        type={type}
        {...inputProps}
      />
    </Wrapper>
  );
});

const FormInput = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  name,
  valueAsNumber,
  ...props
}: React.ComponentProps<typeof _Input> & {
  control: Control<TFieldValues>;
  name: TName;
  valueAsNumber?: boolean;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <_Input
          {...field}
          {...props}
          _forwardInvalidToChildren={false}
          onChange={(event) => {
            if (valueAsNumber) {
              return field.onChange(+event.currentTarget.value);
            }

            return field.onChange(event);
          }}
          value={field.value ?? ""}
        />
      )}
    />
  );
};

export const Input = Object.assign(_Input, {
  FMV_DECIMAL_STEP,
  Form: FormInput,
  Wrapper,
});
