import { compact } from "lodash";
import React, { useMemo, useState } from "react";

import { SHARES_VALUE_MODE_LABELS, SharesValueMode } from "./SharesValue";
import { Switch } from "./ui/Switch";

export const useSharesInputModesAvailable = ({
  fullyDilutedShares,
  latestPricePerShare,
}: {
  fullyDilutedShares: null | number;
  latestPricePerShare: null | number;
}): SharesValueMode[] => {
  return compact([
    "SHARES",
    fullyDilutedShares !== null ? "FULLY_DILUTED" : null,
    latestPricePerShare !== null ? "USD_VALUE" : null,
  ]);
};

export const useSharesValueSwitcherMode = ({
  fullyDilutedShares,
  initialMode: _initialMode,
  latestPricePerShare,
}: {
  fullyDilutedShares: null | number;
  initialMode: SharesValueMode;
  latestPricePerShare: null | number;
}) => {
  const modesAvailable = useSharesInputModesAvailable({
    fullyDilutedShares,
    latestPricePerShare,
  });

  const initialMode = useMemo(() => {
    if (modesAvailable.includes(_initialMode)) {
      return _initialMode;
    }
    return "SHARES";
  }, [_initialMode, modesAvailable]);

  return useState<SharesValueMode>(initialMode);
};

export const SharesValueSwitcher: React.FC<{
  fullyDilutedShares: null | number;
  isVirtualShares?: boolean;
  latestPricePerShare: null | number;
  onChange: (value: SharesValueMode) => void;
  options?: SharesValueMode[];
  value: SharesValueMode;
}> = ({
  fullyDilutedShares,
  isVirtualShares,
  latestPricePerShare,
  onChange,
  options: _options = ["SHARES", "FULLY_DILUTED", "USD_VALUE"],
  value,
}) => {
  const modesAvailable = useSharesInputModesAvailable({
    fullyDilutedShares,
    latestPricePerShare,
  });

  const options = useMemo(() => {
    return _options.filter((option) => modesAvailable.includes(option));
  }, [_options, modesAvailable]);

  return (
    <Switch
      getOptionLabel={(option) =>
        isVirtualShares
          ? SHARES_VALUE_MODE_LABELS[option].virtualSharesLabel
          : SHARES_VALUE_MODE_LABELS[option].sharesLabel
      }
      getOptionValue={(option) => option}
      name="shares-value-mode"
      onChange={onChange}
      options={options}
      selectedOption={value}
    />
  );
};
