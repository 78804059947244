import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useMemo } from "react";

import { InstrumentValuationType } from "../pages/Admin/Equity/PrepareYourGrants/useInstrumentValuationGuard";
import { Tag } from "./ui/Tag";

type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";

export const getInstrumentValuationTypeFromBoardConsentVoidedReason = (
  reason: BoardConsentVoidedReason,
) => {
  switch (reason) {
    case "FMV_409A_EXPIRED":
      return "FMV_409A";
    case "UK_VALUATION_EXPIRED":
      return "UK_VALUATION";
  }
};

export const BoardConsentVoidedReasonTag: React.FC<{
  instrumentValuationType: InstrumentValuationType;
}> = ({ instrumentValuationType }) => {
  const message = useMemo(() => {
    switch (instrumentValuationType) {
      case "FMV_409A":
        return "This board consent has been voided due to a change with your 409A valuation certificate";
      case "UK_VALUATION":
        return "This board consent has been voided due to a change with your EMI valuation";
    }
  }, [instrumentValuationType]);

  return (
    <Tag className="flex items-center gap-2" color="red">
      <ExclamationTriangleIcon className="w-4" />
      {message}
    </Tag>
  );
};
