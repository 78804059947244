/**
 * @generated SignedSource<<6bbfcd8169c6f07f76c47b18cce6e854>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AdminOnboardingStep = "ConnectHRIS" | "SetupGrantees";
export type OnboardingSlider_SkipStep_Mutation$variables = {
  organizationId: string;
  step: AdminOnboardingStep;
};
export type OnboardingSlider_SkipStep_Mutation$data = {
  readonly skipAdminOnboardingStep: {
    readonly adminOnboardingConnectHRISStepSkipped: boolean;
    readonly adminOnboardingSetupGranteesStepCompleted: boolean;
    readonly adminOnboardingSetupGranteesStepSkipped: boolean;
  };
};
export type OnboardingSlider_SkipStep_Mutation = {
  response: OnboardingSlider_SkipStep_Mutation$data;
  variables: OnboardingSlider_SkipStep_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "step"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "step",
    "variableName": "step"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminOnboardingConnectHRISStepSkipped",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminOnboardingSetupGranteesStepSkipped",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "adminOnboardingSetupGranteesStepCompleted",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnboardingSlider_SkipStep_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "skipAdminOnboardingStep",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnboardingSlider_SkipStep_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "skipAdminOnboardingStep",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "61e48de0e0084a2aa37c09172d8b657f",
    "id": null,
    "metadata": {},
    "name": "OnboardingSlider_SkipStep_Mutation",
    "operationKind": "mutation",
    "text": "mutation OnboardingSlider_SkipStep_Mutation(\n  $organizationId: OrganizationId!\n  $step: AdminOnboardingStep!\n) {\n  skipAdminOnboardingStep(organizationId: $organizationId, step: $step) {\n    adminOnboardingConnectHRISStepSkipped\n    adminOnboardingSetupGranteesStepSkipped\n    adminOnboardingSetupGranteesStepCompleted\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "63848a03f39edad263bfc90c426b1dfb";

export default node;
