/**
 * @generated SignedSource<<7fbf1e7e396019efeca292cfd56318dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteGranteesModal_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Organization">;
  readonly " $fragmentType": "InviteGranteesModal_Organization";
};
export type InviteGranteesModal_Organization$key = {
  readonly " $data"?: InviteGranteesModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InviteGranteesModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "664933a69d3f00eab0b6120cd7b931d5";

export default node;
