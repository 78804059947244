import classNames from "classnames";
import React from "react";

const _SectionHero: React.ForwardRefRenderFunction<
  HTMLDivElement,
  React.ComponentProps<"div"> & {
    hideBorderOnMobile?: boolean;
    withBorder?: boolean;
  }
> = ({ className, hideBorderOnMobile, withBorder, ...props }, ref) => {
  return (
    <div
      className={classNames(
        "space-y-6 p-6 md:rounded-2xl md:p-14",
        {
          "border-[0.5px] border-grey-300": withBorder && !hideBorderOnMobile,
          "md:border-[0.5px] md:border-grey-300": withBorder,
        },
        className,
      )}
      ref={ref}
      {...props}
    />
  );
};

export const SectionHero = React.forwardRef(_SectionHero);
