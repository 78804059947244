import { parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { z, ZodTypeAny } from "zod";

const durationUnitSchema = () => z.enum(["day", "month", "year"]);

const linearVestingOccurrenceSchema = () =>
  z.enum([
    "Every12Months",
    "Every2Months",
    "Every3Months",
    "Every6Months",
    "EveryMonth",
    "Once",
  ]);

const backloadedVestingOccurrenceSchema = () =>
  z.enum(["Every3Months", "EveryMonth"]);

const preprocessNaNToUndefined = <U extends ZodTypeAny>(schema: U) =>
  z.preprocess(
    (value) => (typeof value === "number" && isNaN(value) ? undefined : value),
    schema,
  );

const preprocessStringToNumber = <U extends ZodTypeAny>(schema: U) =>
  z.preprocess(
    (value) =>
      (typeof value === "string" && value) || typeof value === "number"
        ? Number(value)
        : undefined,
    schema,
  );

const isISODate = (ISODate: string) => {
  const date = parseISO(ISODate);
  return !isNaN(date.getTime());
};

const ISODate = () =>
  z.string().refine(isISODate, { message: "Not a valid date" });

const vestingEndOption = () =>
  z.enum(["TERMINATION_DATE", "LAST_DAY_AT_THE_COMPANY", "CUSTOM_DATE"]);

const nonEmptyNullableString = () =>
  z
    .string()
    .trim()
    .nullable()
    .default(null)
    .transform((value) => (isEmpty(value) ? null : value));

const postTerminationExercisePeriod = () =>
  z.object({
    duration: z.number().int().min(1),
    unit: durationUnitSchema(),
  });

const equityTypeWorkRelationshipCategory = () =>
  z.enum(["DirectEmployee", "EoREmployee", "Contractor"]);

const accelerationClause = () =>
  z.enum([
    "SingleTrigger",
    "DoubleTrigger100",
    "DoubleTrigger50",
    "DoubleTrigger35",
    "DoubleTrigger25",
  ]);

export const zodExtra = {
  accelerationClause,
  backloadedVestingOccurrenceSchema,
  durationUnitSchema,
  equityTypeWorkRelationshipCategory,
  ISODate,
  linearVestingOccurrenceSchema,
  nonEmptyNullableString,
  postTerminationExercisePeriod,
  preprocessNaNToUndefined,
  preprocessStringToNumber,
  vestingEndOption,
};
