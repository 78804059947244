import classNames from "classnames";
import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { RemoteEquityPlanEmployeeThresholdProgressBar_Organization$key } from "./__generated__/RemoteEquityPlanEmployeeThresholdProgressBar_Organization.graphql";
import { Progress } from "./ui/Progress";

const ORGANIZATION_FRAGMENT = graphql`
  fragment RemoteEquityPlanEmployeeThresholdProgressBar_Organization on Organization {
    billableGrantees {
      __typename
    }
    remoteEquityPlanEmployeeThreshold
  }
`;

export const RemoteEquityPlanEmployeeThresholdProgressBar: React.FC<{
  organizationFragment: RemoteEquityPlanEmployeeThresholdProgressBar_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const organizationOverflowedGranteesThreshold = useMemo(
    () =>
      Boolean(
        organization.remoteEquityPlanEmployeeThreshold &&
          organization.billableGrantees.length >
            organization.remoteEquityPlanEmployeeThreshold,
      ),
    [
      organization.billableGrantees.length,
      organization.remoteEquityPlanEmployeeThreshold,
    ],
  );

  if (organization.remoteEquityPlanEmployeeThreshold === null) return null;

  return (
    <Progress
      className="h-2 bg-grey-100"
      max={organization.remoteEquityPlanEmployeeThreshold}
    >
      <Progress.Value
        className={classNames({
          "bg-orange-600": organizationOverflowedGranteesThreshold,
          "bg-primary": !organizationOverflowedGranteesThreshold,
        })}
        value={organization.billableGrantees.length}
      />
    </Progress>
  );
};
