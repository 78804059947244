/**
 * @generated SignedSource<<9bd5956489de2ca8d367f08cf9eda607>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantsTable_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "GrantsTable_Account";
};
export type GrantsTable_Account$key = {
  readonly " $data"?: GrantsTable_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantsTable_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "ce6aab63900c096def12d3ea1b54a5e6";

export default node;
