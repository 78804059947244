import { useCallback, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import {
  RevokeGranteeAccessModal_Grantee$data,
  RevokeGranteeAccessModal_Grantee$key,
} from "./__generated__/RevokeGranteeAccessModal_Grantee.graphql";
import { RevokeGranteeAccessModal_RevokeAccessToGranteePortal_Mutation } from "./__generated__/RevokeGranteeAccessModal_RevokeAccessToGranteePortal_Mutation.graphql";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const GRANTEE_FRAGMENT = graphql`
  fragment RevokeGranteeAccessModal_Grantee on Grantee {
    id
    name
  }
`;

const REVOKE_ACCESS_TO_GRANTEE_PORTAL_MUTATION = graphql`
  mutation RevokeGranteeAccessModal_RevokeAccessToGranteePortal_Mutation(
    $granteeId: GranteeId!
  ) {
    revokeAccessToGranteePortal(granteeId: $granteeId) {
      hasPortalAccess
      hasVisitedTheirPortal
      hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    }
  }
`;

type State =
  | {
      granteeFragment: RevokeGranteeAccessModal_Grantee$key;
      shown: true;
    }
  | {
      granteeFragment?: null | RevokeGranteeAccessModal_Grantee$key;
      shown: false;
    };

export const useRevokeGranteeAccessModalState = () => {
  const [revokeGranteeAccessModalState, setState] = useState<State>({
    shown: false,
  });

  const showRevokeGranteeAccessModal = useCallback(
    (granteeFragment: RevokeGranteeAccessModal_Grantee$key) => {
      setState({
        granteeFragment,
        shown: true,
      });
    },
    [],
  );

  const hideRevokeGranteeAccessModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    hideRevokeGranteeAccessModal,
    revokeGranteeAccessModalState,
    showRevokeGranteeAccessModal,
  };
};

export const RevokeGranteeAccessModal: React.FC<{
  onClose: () => void;
  onGranteeAccessRevoked?: () => void;
  state: State;
}> = ({ onClose, onGranteeAccessRevoked, state }) => {
  const grantee = useFragment(
    GRANTEE_FRAGMENT,
    state.shown ? state.granteeFragment : null,
  );

  const [
    _revokeAccessToGranteePortal,
    revokeAccessToGranteePortalMutationIsInFlight,
  ] =
    useSafeMutation<RevokeGranteeAccessModal_RevokeAccessToGranteePortal_Mutation>(
      REVOKE_ACCESS_TO_GRANTEE_PORTAL_MUTATION,
    );

  const toaster = useToaster();

  const revokeAccessToGranteePortal = async (
    grantee: RevokeGranteeAccessModal_Grantee$data,
  ) => {
    await _revokeAccessToGranteePortal({
      variables: {
        granteeId: grantee.id,
      },
    });

    toaster.push(
      <Toast title="Alright!">
        {grantee.name} access was successfully revoked.
      </Toast>,
    );

    onGranteeAccessRevoked?.();

    onClose();
  };

  return (
    <ConfirmationModal
      loading={revokeAccessToGranteePortalMutationIsInFlight}
      onClose={onClose}
      onConfirmed={() => grantee && revokeAccessToGranteePortal(grantee)}
      show={state.shown}
      title="Revoke access"
    >
      <>
        The grantee will lose access to its portal.
        <br />
        Please confirm
      </>
    </ConfirmationModal>
  );
};
