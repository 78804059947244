/**
 * @generated SignedSource<<2e824870d219abd04c9bddbefa5600b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BackloadedVestingOccurrence = "Every3Months" | "EveryMonth";
export type BackloadedVestingScheduleAttributes = {
  cliffActivatedOnFirstPeriod: boolean;
  name: string;
  periods: ReadonlyArray<BackloadedVestingSchedulePeriodAttributes>;
  vestingOccurrence: BackloadedVestingOccurrence;
};
export type BackloadedVestingSchedulePeriodAttributes = {
  durationInMonths: number;
  percentageVested: number;
};
export type CreateSlideOver_BackloadedVestingSchedules_Mutation$variables = {
  attributes: BackloadedVestingScheduleAttributes;
  organizationId: string;
};
export type CreateSlideOver_BackloadedVestingSchedules_Mutation$data = {
  readonly createBackloadedVestingSchedule: {
    readonly id: string;
  };
};
export type CreateSlideOver_BackloadedVestingSchedules_Mutation = {
  response: CreateSlideOver_BackloadedVestingSchedules_Mutation$data;
  variables: CreateSlideOver_BackloadedVestingSchedules_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSlideOver_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSlideOver_BackloadedVestingSchedules_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "createBackloadedVestingSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf46ce4b2241daf3c28d4971dfd3c12f",
    "id": null,
    "metadata": {},
    "name": "CreateSlideOver_BackloadedVestingSchedules_Mutation",
    "operationKind": "mutation",
    "text": "mutation CreateSlideOver_BackloadedVestingSchedules_Mutation(\n  $attributes: BackloadedVestingScheduleAttributes!\n  $organizationId: OrganizationId!\n) {\n  createBackloadedVestingSchedule(attributes: $attributes, organizationId: $organizationId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "65a81ac8f4fa137586665a70bf0f6afb";

export default node;
