/**
 * @generated SignedSource<<c34784c533f0ed9eeb1092b1a09e7591>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationGranteesTable_Organization$data = {
  readonly equityGrid: {
    readonly activated: boolean;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GranteesFilters_Organization" | "GranteesTableRow_Organization">;
  readonly " $fragmentType": "OrganizationGranteesTable_Organization";
};
export type OrganizationGranteesTable_Organization$key = {
  readonly " $data"?: OrganizationGranteesTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGranteesTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationGranteesTable_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGrid",
      "kind": "LinkedField",
      "name": "equityGrid",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activated",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesTableRow_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteesFilters_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a37bc86e9bf3aa7b34f7698c641ecd89";

export default node;
