import { isNil } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useRelayEnvironment } from "react-relay";
import { commitLocalUpdate, graphql } from "relay-runtime";

import { useAlerter } from "../../../../components/Alerter";
import { PandaDocFrame } from "../../../../components/PandaDocFrame";
import { PoolDetails } from "../../../../components/PoolDetails";
import { useToaster } from "../../../../components/Toaster";
import { Alert } from "../../../../components/ui/Alert";
import { Button } from "../../../../components/ui/Button";
import { Modal } from "../../../../components/ui/Modal";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useManualQuery } from "../../../../hooks/useManualQuery";
import { ApprovalSlideOverContent_BoardConsent$key } from "./__generated__/ApprovalSlideOverContent_BoardConsent.graphql";
import { ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent$key } from "./__generated__/ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent.graphql";
import { ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent$key } from "./__generated__/ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent.graphql";
import { ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent$key } from "./__generated__/ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent.graphql";
import { ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent$key } from "./__generated__/ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent.graphql";
import {
  ApprovalSlideOverContent_Organization$data,
  ApprovalSlideOverContent_Organization$key,
} from "./__generated__/ApprovalSlideOverContent_Organization.graphql";
import { ApprovalSlideOverContent_Query } from "./__generated__/ApprovalSlideOverContent_Query.graphql";
import {
  ApprovalSlideOverContent_SignatureRequest$data,
  ApprovalSlideOverContent_SignatureRequest$key,
} from "./__generated__/ApprovalSlideOverContent_SignatureRequest.graphql";
import {
  GrantAmendmentsBoardConsentApprovalDetails,
  GranteeTerminationGrantAmendmentsBoardConsentApprovalDetails,
  GrantsBoardConsentApprovalDetails,
} from "./ApprovalDetails";
import { ApprovalOrganizationBlock } from "./ApprovalOrganizationBlock";
import { ApprovalSlideOverChartCard } from "./ApprovalSlideOverChartCard";
import { ApprovalSlideOverFairMarketValueDetailsCard } from "./ApprovalSlideOverFairMarketValueDetailsCard";
import { ApprovalSlideOverGrantsCard } from "./ApprovalSlideOverGrantsCard";

const ContentContainer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="divide-y-[0.5px] divide-gray-04 overflow-x-hidden sm:overflow-x-auto">
    {children}
  </div>
);

const CEONoteBlock: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="px-6 py-10">
    <Typography variant="Medium/Small">
      Additional notes regarding this board consent
    </Typography>

    <div className="mt-6 whitespace-pre-line bg-gray-01 p-4">{children}</div>
  </div>
);

const Title: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography
    as="div"
    className="border-t border-gray-04 p-6 text-black-05"
    variant="Regular/Extra Small"
  >
    {children}
  </Typography>
);

const Footer: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="flex flex-col gap-6 px-6 py-10">{children}</div>
);

const GRANT_AMENDMENT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent on GrantAmendmentBoardConsent {
    id
    ...ApprovalDetails_GrantAmendmentBoardConsent
  }
`;

const GrantAmendmentBoardConsentBlocks: React.FC<{
  boardConsentFragment: ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent$key;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsentFragment, organization }) => {
  const boardConsent = useFragment(
    GRANT_AMENDMENT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );

  return (
    <>
      <Title>
        Here is an overview of the grant(s) amendment(s) pending your signature.
        Read it carefully. If you agree with the suggested grant(s)
        amendment(s), please proceed to signing of the board consent.
      </Title>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="needs my approval for"
      >
        <GrantAmendmentsBoardConsentApprovalDetails
          grantAmendmentBoardConsentFragment={boardConsent}
        />
      </ApprovalOrganizationBlock>
    </>
  );
};

const GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent on GranteeTerminationGrantAmendmentBoardConsent {
    id
    ...ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent
  }
`;

const GranteeTerminationGrantAmendmentBoardConsentBlocks: React.FC<{
  boardConsentFragment: ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent$key;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsentFragment, organization }) => {
  const boardConsent = useFragment(
    GRANTEE_TERMINATION_GRANT_AMENDMENT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );

  return (
    <>
      <Title>
        Here is an overview of the grant(s) amendment(s) pending your signature.
        Read it carefully. If you agree with the suggested grant(s)
        amendment(s), please proceed to signing of the board consent.
      </Title>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="needs my approval for"
      >
        <GranteeTerminationGrantAmendmentsBoardConsentApprovalDetails
          grantAmendmentBoardConsentFragment={boardConsent}
        />
      </ApprovalOrganizationBlock>
    </>
  );
};

const FAIR_MARKET_VALUE_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent on FairMarketValueBoardConsent {
    fairMarketValue {
      ...ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue
    }
  }
`;

const FairMarketValueBoardConsentBlocks: React.FC<{
  boardConsentFragment: ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent$key;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsentFragment, organization }) => {
  const boardConsent = useFragment(
    FAIR_MARKET_VALUE_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );

  return (
    <>
      <Title>
        Here is an overview of the approval. Read it carefully. Once you’re
        ready, please proceed to signing the documents or decline the approval.
      </Title>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="needs my approval for its latest 409A valuation"
      >
        <ApprovalSlideOverFairMarketValueDetailsCard
          fairMarketValueFragment={boardConsent.fairMarketValue}
        />
      </ApprovalOrganizationBlock>
    </>
  );
};

const GRANT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent on GrantBoardConsent {
    id
    totalGrantedShares
    ...ApprovalSlideOverChartCard_GrantBoardConsent
  }
`;

const GrantBoardConsentBlocks: React.FC<{
  boardConsentFragment: ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent$key;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsentFragment, organization }) => {
  const boardConsent = useFragment(
    GRANT_BOARD_CONSENT_BLOCKS_BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );

  return (
    <>
      <Title>
        Here is an overview of the equity incentive grant(s) pending your
        signature. Read it carefully. If you agree with the suggested grant(s),
        please proceed to signing of the board consent.
      </Title>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="needs my approval for"
      >
        <GrantsBoardConsentApprovalDetails
          grantedShares={boardConsent.totalGrantedShares}
          organizationFragment={organization}
        />
      </ApprovalOrganizationBlock>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="pool overview projection"
      >
        <ApprovalSlideOverChartCard grantBoardConsentFragment={boardConsent} />
      </ApprovalOrganizationBlock>
      <ApprovalOrganizationBlock
        organizationFragment={organization}
        title="pool details"
      >
        <PoolDetails
          grantedShares={boardConsent.totalGrantedShares}
          organizationFragment={organization}
        />
      </ApprovalOrganizationBlock>
    </>
  );
};

const QUERY = graphql`
  query ApprovalSlideOverContent_Query($signatureRequestId: UUID!) {
    signatureRequest(id: $signatureRequestId) @required(action: THROW) {
      pandadocSignatureSessionURL {
        ... on PandadocSignatureSessionURLSuccessOutput {
          url
        }
      }
    }
  }
`;

const BOARD_CONSENT_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_BoardConsent on BoardConsent {
    __typename
    id
    ceoNote
    ...ApprovalSlideOverGrantsCard_BoardConsent
  }
`;

const SIGNATURE_REQUEST_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_SignatureRequest on SignatureRequest {
    id
    completedAt
    boardConsent {
      id
      __typename
      ... on GrantBoardConsent {
        grantCount
        ...ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent
      }
      ... on GranteeTerminationGrantAmendmentBoardConsent {
        granteeTerminationCTMSGrantAmendmentRequestCount
        ...ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent
      }
      ... on GrantAmendmentBoardConsent {
        ctmsGrantAmendmentRequestsCount
        ...ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent
      }
      ... on FairMarketValueBoardConsent {
        ...ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent
      }
      ...ApprovalSlideOverContent_BoardConsent
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment ApprovalSlideOverContent_Organization on Organization {
    name
    ...ApprovalOrganizationBlock_Organization
    ...PoolDetails_Organization
    ...ApprovalSlideOverGrantsCard_Organization
    ...ApprovalDetails_Organization
  }
`;

type BoardConsent =
  ApprovalSlideOverContent_SignatureRequest$data["boardConsent"];

const _GrantBoardConsentBlocks: React.FC<{
  boardConsent: BoardConsent;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsent, organization }) => {
  switch (boardConsent.__typename) {
    case "FairMarketValueBoardConsent":
      return (
        <FairMarketValueBoardConsentBlocks
          boardConsentFragment={boardConsent}
          organization={organization}
        />
      );
    case "GrantAmendmentBoardConsent":
      return (
        <GrantAmendmentBoardConsentBlocks
          boardConsentFragment={boardConsent}
          organization={organization}
        />
      );
    case "GrantBoardConsent":
      return (
        <GrantBoardConsentBlocks
          boardConsentFragment={boardConsent}
          organization={organization}
        />
      );
    case "GranteeTerminationGrantAmendmentBoardConsent":
      return (
        <GranteeTerminationGrantAmendmentBoardConsentBlocks
          boardConsentFragment={boardConsent}
          organization={organization}
        />
      );
  }
};

const FooterMessage: React.FC<{
  boardConsent: BoardConsent;
  organization: ApprovalSlideOverContent_Organization$data;
}> = ({ boardConsent, organization }) => {
  switch (boardConsent.__typename) {
    case "FairMarketValueBoardConsent":
      return (
        <>
          <Typography variant="Medium/Default">
            Approve the 409A valuation?
          </Typography>
          <Typography className="text-black-05" variant="Regular/Small">
            There&apos;s only one thing left to do, if you agree with the
            suggested valuation, you can click on Sign the documents, or else,
            decline the approval.
          </Typography>
        </>
      );
    case "GrantAmendmentBoardConsent":
      return (
        <>
          <Typography variant="Medium/Large">
            <FormattedMessage
              defaultMessage="Approve the {count, plural, one {grant amendment} other {grant amendments}} for {organizationName}?"
              values={{
                count: boardConsent.ctmsGrantAmendmentRequestsCount,
                organizationName: organization.name,
              }}
            />
          </Typography>
          <Typography className="text-black-05" variant="Regular/Small">
            <FormattedMessage
              defaultMessage="There's only one thing left to do, if you agree with the suggested {count, plural, one {grant amendment} other {grant amendments}}, you can simply click on Sign the documents."
              values={{
                count: boardConsent.ctmsGrantAmendmentRequestsCount,
              }}
            />
          </Typography>
        </>
      );
    case "GrantBoardConsent":
      return (
        <>
          <Typography variant="Medium/Large">
            Approve the equity grant for {organization.name}?
          </Typography>
          <Typography className="text-black-05" variant="Regular/Small">
            <FormattedMessage
              defaultMessage="There's only one thing left to do, if you agree with the suggested {count, plural, one {grant} other {grants}}, you can simply click on Sign the documents."
              values={{
                count: boardConsent.grantCount,
              }}
            />
          </Typography>
        </>
      );
    case "GranteeTerminationGrantAmendmentBoardConsent":
      return (
        <>
          <Typography variant="Medium/Large">
            <FormattedMessage
              defaultMessage="Approve the {count, plural, one {grant amendment} other {grant amendments}} for {organizationName}?"
              values={{
                count:
                  boardConsent.granteeTerminationCTMSGrantAmendmentRequestCount,
                organizationName: organization.name,
              }}
            />
          </Typography>
          <Typography className="text-black-05" variant="Regular/Small">
            <FormattedMessage
              defaultMessage="There's only one thing left to do, if you agree with the suggested {count, plural, one {grant amendment} other {grant amendments}}, you can simply click on Sign the documents."
              values={{
                count:
                  boardConsent.granteeTerminationCTMSGrantAmendmentRequestCount,
              }}
            />
          </Typography>
        </>
      );
  }
};

export const ApprovalSlideOverContent: React.FC<{
  onCompleted: () => void;
  organizationFragment: ApprovalSlideOverContent_Organization$key;
  signatureRequestFragment: ApprovalSlideOverContent_SignatureRequest$key;
}> = ({ onCompleted, organizationFragment, signatureRequestFragment }) => {
  const relayEnvironment = useRelayEnvironment();

  const signatureRequest = useFragment(
    SIGNATURE_REQUEST_FRAGMENT,
    signatureRequestFragment,
  );

  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const toaster = useToaster();

  const boardConsent = useFragment<ApprovalSlideOverContent_BoardConsent$key>(
    BOARD_CONSENT_FRAGMENT,
    signatureRequest.boardConsent,
  );

  const [
    generatePandaDocSignatureSessionUrl,
    generatePandaDocSignatureSessionUrlIsInFlight,
  ] = useManualQuery<ApprovalSlideOverContent_Query>(QUERY, {
    fetchPolicy: "network-only",
  });

  const [pandaDocSignatureSessionURL, setPandaDocSignatureSessionURL] =
    useState<null | string>(null);

  const setSignatureCompleted = useCallback(
    (signatureRequestId: string, boardConsentId: string) => {
      // @ts-expect-error - relay-runtime types are not up-to-date
      commitLocalUpdate(relayEnvironment, (store) => {
        store
          .get(signatureRequestId)
          ?.setValue(new Date().toISOString(), "completedAt")
          .setValue(true, "justSigned");

        toaster.push(
          <Toast title="Remarkable!" variant="congrats">
            Board consent successfully signed!
          </Toast>,
        );

        const boardConsent = store.get(boardConsentId);
        const missingSignaturesCount = boardConsent?.getValue(
          "missingSignaturesCount",
        );

        if (boardConsent && typeof missingSignaturesCount === "number") {
          boardConsent.setValue(
            missingSignaturesCount - 1,
            "missingSignaturesCount",
          );
        }
      });
    },
    [relayEnvironment, toaster],
  );

  const alerter = useAlerter();

  const handleSignButtonClick = useCallback(async () => {
    const result = await new Promise<
      ApprovalSlideOverContent_Query["response"]
    >((resolve, reject) =>
      generatePandaDocSignatureSessionUrl({
        onError: (error) => {
          // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
          reject(error);
        },
        onResponse: (response) => {
          resolve(response);
        },
        variables: {
          signatureRequestId: signatureRequest.id,
        },
      }),
    );

    if (!result.signatureRequest.pandadocSignatureSessionURL.url) {
      alerter.push(
        <Alert title="">
          We are really sorry, our e-signature provider is experiencing issues
          at the moment. Please try again in a moment.
        </Alert>,
      );
      return;
    }
    setPandaDocSignatureSessionURL(
      result.signatureRequest.pandadocSignatureSessionURL.url,
    );
  }, [generatePandaDocSignatureSessionUrl, signatureRequest.id, alerter]);

  const showApprovalSlideOverGrantsCard = useMemo(() => {
    switch (signatureRequest.boardConsent.__typename) {
      case "FairMarketValueBoardConsent":
        return false;
      case "GrantAmendmentBoardConsent":
      case "GrantBoardConsent":
      case "GranteeTerminationGrantAmendmentBoardConsent":
        return true;
    }
  }, [signatureRequest]);

  return (
    <>
      <Modal.Raw
        noEmptyBorder
        onClose={() => {
          setPandaDocSignatureSessionURL(null);
        }}
        panelClassName={
          /* tailwind */ `md:w-full max-w-screen-xl md:h-[90%] h-screen w-screen`
        }
        show={!!pandaDocSignatureSessionURL}
        suspense
      >
        <div className="h-full w-full">
          {pandaDocSignatureSessionURL && (
            <PandaDocFrame
              height="100%"
              onDocumentCompleted={() => {
                setPandaDocSignatureSessionURL(null);
                setSignatureCompleted(
                  signatureRequest.id,
                  signatureRequest.boardConsent.id,
                );
                onCompleted();
              }}
              sessionUrl={pandaDocSignatureSessionURL}
              width="100%"
            />
          )}
        </div>
      </Modal.Raw>

      <ContentContainer>
        <_GrantBoardConsentBlocks
          boardConsent={signatureRequest.boardConsent}
          organization={organization}
        />

        {showApprovalSlideOverGrantsCard && (
          <div className="px-6 py-10">
            <ApprovalSlideOverGrantsCard
              boardConsentFragment={boardConsent}
              isCompleted={!!signatureRequest.completedAt}
              organizationFragment={organization}
            />
          </div>
        )}

        {boardConsent.ceoNote && (
          <CEONoteBlock>{boardConsent.ceoNote}</CEONoteBlock>
        )}

        <Footer>
          <FooterMessage
            boardConsent={signatureRequest.boardConsent}
            organization={organization}
          />

          {isNil(signatureRequest.completedAt) && (
            <Button
              className="self-start"
              loading={generatePandaDocSignatureSessionUrlIsInFlight}
              loadingText="Loading documentation"
              onClick={handleSignButtonClick}
            >
              Sign the documents
            </Button>
          )}
        </Footer>
      </ContentContainer>
    </>
  );
};
