import {
  ArrowRightIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { isNil } from "lodash";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import {
  CtmsGrantDetailsExercise_CTMSGrant$data,
  CtmsGrantDetailsExercise_CTMSGrant$key,
} from "./__generated__/CtmsGrantDetailsExercise_CTMSGrant.graphql";
import {
  CtmsGrantDetailsExercise_Organization$data,
  CtmsGrantDetailsExercise_Organization$key,
} from "./__generated__/CtmsGrantDetailsExercise_Organization.graphql";
import { CtmsGrantActivePTEPValue } from "./CtmsGrantActivePTEPValue";
import { DetailsBox } from "./DetailsBox";
import { ExerciseOverview } from "./ExerciseOverview";
import { ExerciseStatusTag } from "./ExerciseStatusTag";
import { FormattedFMV } from "./FormattedFMV";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import { ShortDate } from "./ShortDate";
import { InformationRows } from "./ui/InformationRows";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment CtmsGrantDetailsExercise_CTMSGrant on CTMSGrant {
    exercisePrice
    cumulativeVested
    quantityExercised
    exercisableNonExercisedAmount
    isVirtual
    exerciseStatus
    earlyExercise
    cumulativeVested
    quantityIssued
    exercisableNonExercisedShares
    totalExerciseAmount
    settled
    exerciseRequests {
      ctmsId
      quantityExercised
      totalExercisePrice
      status
    }
    activePostTerminationExercisePeriod {
      ...CtmsGrantActivePTEPValue_ActivePostTerminationExercisePeriod
    }
    exercisableUntilDate
    ...ExerciseOverview_CTMSGrant
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment CtmsGrantDetailsExercise_Organization on Organization {
    id
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

const LastDayToExerciseRow: React.FC<{
  ctmsGrant: CtmsGrantDetailsExercise_CTMSGrant$data;
  organization: CtmsGrantDetailsExercise_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  if (ctmsGrant.exercisableUntilDate) {
    return (
      <InformationRows.Row label="Last day to exercise">
        <ShortDate value={ctmsGrant.exercisableUntilDate} />
      </InformationRows.Row>
    );
  }

  if (ctmsGrant.activePostTerminationExercisePeriod) {
    <InformationRows.Row
      label={
        ctmsGrant.isVirtual
          ? "Post-termination settlement period"
          : "Post-termination exercise period"
      }
    >
      <CtmsGrantActivePTEPValue
        activePostTerminationExercisePeriodFragment={
          ctmsGrant.activePostTerminationExercisePeriod
        }
      />
    </InformationRows.Row>;
  }

  return (
    <InformationRows.Row label="Last day to exercise">
      <MissingCTMSInformationTag organizationFragment={organization} />
    </InformationRows.Row>
  );
};

const VirtualCtmsGrantDetailsExerciseContent_: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = ({
  ctmsGrantFragment,
  displayLastDayToExercise,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <div className="space-y-4">
      <NoticeMessage size="Small">
        Nothing to exercise with VSOP as the virtual exercise amount is directly
        deducted from the cash bonus
      </NoticeMessage>

      <InformationRows>
        <InformationRows.Row label="Early exercise">
          <Tag color="gray">Not possible</Tag>
        </InformationRows.Row>

        <InformationRows.Row label="Virtual exercise price (one share)">
          {!isNil(ctmsGrant.exercisePrice) ? (
            <FormattedFMV value={ctmsGrant.exercisePrice} />
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
        <InformationRows.Row label="Amount deducted when fully vested">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={ctmsGrant.totalExerciseAmount}
          />
        </InformationRows.Row>
        {displayLastDayToExercise && (
          <LastDayToExerciseRow
            ctmsGrant={ctmsGrant}
            organization={organization}
          />
        )}
      </InformationRows>
    </div>
  );
};

const _VirtualCtmsGrantDetailsExercise: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = ({ ...props }) => {
  return (
    <DetailsBox
      headerRightAddon={<Tag color="red">Not exercisable</Tag>}
      Icon={CurrencyDollarIcon}
      title="Exercise"
    >
      <VirtualCtmsGrantDetailsExerciseContent_ {...props} />
    </DetailsBox>
  );
};

const ExerciseRequestsRow: React.FC<{
  ctmsGrant: CtmsGrantDetailsExercise_CTMSGrant$data;
  organization: CtmsGrantDetailsExercise_Organization$data;
}> = ({ ctmsGrant, organization }) => {
  const pendingApprovalExerciseRequests = ctmsGrant.exerciseRequests.filter(
    (exerciseRequest) => exerciseRequest.status === "PendingApproval",
  );
  // If the grant is settled and there are no pending requests, we don't show the row
  if (ctmsGrant.settled && pendingApprovalExerciseRequests.length === 0) {
    return null;
  }

  const rowLabel = "Exercise requests";

  if (pendingApprovalExerciseRequests.length === 0) {
    return (
      <InformationRows.Row label={rowLabel}>
        <Tag color="gray">No requests</Tag>
      </InformationRows.Row>
    );
  }

  return (
    <InformationRows.AltRow label={rowLabel}>
      <div
        className={classNames("grid gap-x-4 gap-y-2", {
          "grid-cols-1": ctmsGrant.exerciseRequests.length === 1,
          "grid-cols-2": ctmsGrant.exerciseRequests.length === 2,
          "grid-cols-3": ctmsGrant.exerciseRequests.length >= 3,
        })}
      >
        {pendingApprovalExerciseRequests.map((exerciseRequest) => (
          <Link
            className="flex cursor-pointer items-center justify-between gap-2 rounded-lg border-[0.5px] border-gray-04 p-4 hover:border-primary"
            key={exerciseRequest.ctmsId}
            to={generatePath(
              APPLICATION_ROUTES["organizationExerciseRequestDetails"],
              {
                ctmsExerciseRequestCTMSId: exerciseRequest.ctmsId,
                organizationId: organization.id,
              },
            )}
          >
            <div className="space-y-2">
              <Typography
                as="div"
                className="text-primary"
                variant="Medium/Default"
              >
                {ctmsGrant.earlyExercise ? (
                  <FormattedNumber
                    style="percent"
                    value={
                      exerciseRequest.quantityExercised /
                      ctmsGrant.quantityIssued
                    }
                  />
                ) : (
                  <FormattedNumber
                    style="percent"
                    value={
                      ctmsGrant.cumulativeVested === 0
                        ? 0
                        : exerciseRequest.quantityExercised /
                          ctmsGrant.cumulativeVested
                    }
                  />
                )}
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Extra Small"
              >
                {ctmsGrant.earlyExercise ? (
                  <FormattedMessage
                    defaultMessage={`<strong>{quantityExercised, number}</strong> of the {quantityGranted, number} exercisable shares exercised`}
                    values={{
                      quantityExercised: exerciseRequest.quantityExercised,
                      quantityGranted: ctmsGrant.quantityIssued,
                      strong: (chunks) => (
                        <Typography
                          className="text-black-07"
                          variant="Medium/Extra Small"
                        >
                          {chunks}
                        </Typography>
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage={`<strong>{quantityExercised, number}</strong> of the {quantityVested, number} vested shares exercised`}
                    values={{
                      quantityExercised: exerciseRequest.quantityExercised,
                      quantityVested: ctmsGrant.cumulativeVested,
                      strong: (chunks) => (
                        <Typography
                          className="text-black-07"
                          variant="Medium/Extra Small"
                        >
                          {chunks}
                        </Typography>
                      ),
                    }}
                  />
                )}
              </Typography>
            </div>
            <ArrowRightIcon className="w-5 shrink-0 text-primary" />
          </Link>
        ))}
      </div>
    </InformationRows.AltRow>
  );
};

const RealCtmsGrantDetailsExerciseContent_: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = ({
  ctmsGrantFragment,
  displayLastDayToExercise,
  organizationFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);
  const amountPaidForExercisedShares = ctmsGrant.exerciseRequests.reduce(
    (acc, exerciseRequest) =>
      acc +
      (exerciseRequest.status === "Done"
        ? exerciseRequest.totalExercisePrice
        : 0),
    0,
  );

  return (
    <InformationRows>
      <InformationRows.Row label="Early exercise">
        {ctmsGrant.earlyExercise ? (
          <Tag color="green" size="Small">
            Allowed
          </Tag>
        ) : (
          <Tag color="gray" size="Small">
            Not Allowed
          </Tag>
        )}
      </InformationRows.Row>
      <ExerciseRequestsRow ctmsGrant={ctmsGrant} organization={organization} />
      <InformationRows.Row label="Quantity exercised">
        <ExerciseOverview ctmsGrantFragment={ctmsGrant} />
      </InformationRows.Row>
      {!ctmsGrant.settled && (
        <InformationRows.Row label="Exercise price (one share)">
          {!isNil(ctmsGrant.exercisePrice) ? (
            <FormattedNumber
              currency="USD"
              style="currency"
              value={ctmsGrant.exercisePrice}
            />
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
      )}
      {amountPaidForExercisedShares > 0 && (
        <InformationRows.Row label="Amount paid for exercised shares">
          <FormattedNumber
            currency="USD"
            style="currency"
            value={amountPaidForExercisedShares}
          />
        </InformationRows.Row>
      )}
      {!ctmsGrant.settled && (
        <InformationRows.Row label="Quantity exercisable">
          <FormattedNumber value={ctmsGrant.exercisableNonExercisedShares} />
        </InformationRows.Row>
      )}
      {!ctmsGrant.settled && (
        <InformationRows.Row label="Exercise price (all exercisable shares)">
          {!isNil(ctmsGrant.exercisableNonExercisedAmount) ? (
            <FormattedNumber
              currency="USD"
              style="currency"
              value={ctmsGrant.exercisableNonExercisedAmount}
            />
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
      )}
      {displayLastDayToExercise && (
        <LastDayToExerciseRow
          ctmsGrant={ctmsGrant}
          organization={organization}
        />
      )}
    </InformationRows>
  );
};

const RealCtmsGrantDetailsExercise_: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = ({ ...props }) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, props.ctmsGrantFragment);

  return (
    <DetailsBox
      headerRightAddon={
        <div className="flex items-center gap-2">
          <ExerciseStatusTag exerciseStatus={ctmsGrant.exerciseStatus} />
          {ctmsGrant.settled && <Tag color="red">Settled</Tag>}
        </div>
      }
      Icon={CurrencyDollarIcon}
      title="Exercise"
    >
      <RealCtmsGrantDetailsExerciseContent_ {...props} />
    </DetailsBox>
  );
};

const Content: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = (props) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, props.ctmsGrantFragment);

  return ctmsGrant.isVirtual ? (
    <VirtualCtmsGrantDetailsExerciseContent_ {...props} />
  ) : (
    <RealCtmsGrantDetailsExerciseContent_ {...props} />
  );
};

const CtmsGrantDetailsExercise_: React.FC<{
  ctmsGrantFragment: CtmsGrantDetailsExercise_CTMSGrant$key;
  displayLastDayToExercise: boolean;
  organizationFragment: CtmsGrantDetailsExercise_Organization$key;
}> = (props) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, props.ctmsGrantFragment);

  return ctmsGrant.isVirtual ? (
    <_VirtualCtmsGrantDetailsExercise {...props} />
  ) : (
    <RealCtmsGrantDetailsExercise_ {...props} />
  );
};

export const CtmsGrantDetailsExercise = Object.assign(
  CtmsGrantDetailsExercise_,
  {
    Content,
  },
);
