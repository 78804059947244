import { useMemo } from "react";
import { graphql, useFragment } from "react-relay";

import { useOrganizationCTMS_Organization$key } from "./__generated__/useOrganizationCTMS_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useOrganizationCTMS_Organization on Organization {
    isConnectedToCarta
    isConnectedToPulley
    isConnectedToAngelList
    legacyCartaIssuerId
  }
`;

export const useOrganizationCTMS = ({
  organizationFragment,
}: {
  organizationFragment: useOrganizationCTMS_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return useMemo(() => {
    if (organization.legacyCartaIssuerId || organization.isConnectedToCarta) {
      return {
        loginUrl: "https://login.app.carta.com/",
        name: "Carta",
      } as const;
    }

    if (organization.isConnectedToPulley) {
      return {
        loginUrl: "https://pulley.com/user/login",
        name: "Pulley",
      } as const;
    }

    if (organization.isConnectedToAngelList) {
      return {
        loginUrl: "https://venture.angellist.com/v/login",
        name: "AngelList",
      } as const;
    }

    return null;
  }, [
    organization.legacyCartaIssuerId,
    organization.isConnectedToCarta,
    organization.isConnectedToPulley,
    organization.isConnectedToAngelList,
  ]);
};
