/**
 * @generated SignedSource<<d8cf5f00b271e6a8defd079d2b6b08c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminUpcomingCard_Organization$data = {
  readonly hasCTMSGrantsInTheirLastExerciseDays: boolean;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly country: {
      readonly code: string;
    };
    readonly taxFavoredSubplanExpirationDate: string | null;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsInTheirLastExerciseDaysWarningMessage_Organization">;
  readonly " $fragmentType": "AdminUpcomingCard_Organization";
};
export type AdminUpcomingCard_Organization$key = {
  readonly " $data"?: AdminUpcomingCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"AdminUpcomingCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AdminUpcomingCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "code",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxFavoredSubplanExpirationDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCTMSGrantsInTheirLastExerciseDays",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsInTheirLastExerciseDaysWarningMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "6f73ad45fa810e0b9699039af23da101";

export default node;
