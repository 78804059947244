/**
 * @generated SignedSource<<acb01a8ff7b9a3318c1b56c84febf8da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_CTMSGrantAmendmentRequests_Query$variables = {
  organizationId: string;
};
export type PendingApproval_CTMSGrantAmendmentRequests_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_CTMSGrantAmendmentRequests_Viewer">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_CTMSGrantAmendmentRequests_Organization">;
  } | null;
};
export type PendingApproval_CTMSGrantAmendmentRequests_Query = {
  response: PendingApproval_CTMSGrantAmendmentRequests_Query$data;
  variables: PendingApproval_CTMSGrantAmendmentRequests_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_CTMSGrantAmendmentRequests_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_CTMSGrantAmendmentRequests_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GrantAmendmentBoardConsent",
            "kind": "LinkedField",
            "name": "pendingApprovalGrantAmendmentBoardConsents",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidedReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPandaDocDocumentSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatureRequest",
                "kind": "LinkedField",
                "name": "signatureRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryLastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "ctmsGrantAmendmentRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isUnfavorable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeletable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "ctmsGrant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workRelationship",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "taxResidenceCountry",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isBoardConsent"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0f5237a80c39e2931c5d47fd558bded8",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_CTMSGrantAmendmentRequests_Query",
    "operationKind": "query",
    "text": "query PendingApproval_CTMSGrantAmendmentRequests_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...PendingApproval_CTMSGrantAmendmentRequests_Organization\n  }\n  me {\n    ...PendingApproval_CTMSGrantAmendmentRequests_Viewer\n    id\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest on CTMSGrantAmendmentRequest {\n  id\n  status\n  isUnfavorable\n  isDeletable\n  ctmsGrant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment CTMSGrantAmendmentRequestsTable_Organization on Organization {\n  id\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment PendingApproval_CTMSGrantAmendmentRequests_Organization on Organization {\n  id\n  name\n  pendingApprovalGrantAmendmentBoardConsents {\n    id\n    voidedReason\n    isPandaDocDocumentSent\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    signatureRequests {\n      id\n      completedAt\n      signatoryFirstName\n      signatoryLastName\n    }\n    ctmsGrantAmendmentRequests {\n      ...CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest\n      id\n    }\n    ...SendReminderButton_BoardConsent\n  }\n  ...LargeOneColumnLayout_Organization\n  ...CTMSGrantAmendmentRequestsTable_Organization\n}\n\nfragment PendingApproval_CTMSGrantAmendmentRequests_Viewer on Account {\n  isSuperAdmin\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "bda64eff02ec360e32ef692cc469a1e9";

export default node;
