/**
 * @generated SignedSource<<db0bbd7188816c37953d3f618ed468df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationReview_Organization$data = {
  readonly granteePortalSettings: {
    readonly displayFullyDilutedValues: boolean;
  };
  readonly id: string;
  readonly name: string;
  readonly postTerminationExercisePeriods: ReadonlyArray<{
    readonly displayName: string;
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"grantAmendmentRequest_useAmendedLastDayToExercise_Organization">;
  readonly " $fragmentType": "GranteeTerminationReview_Organization";
};
export type GranteeTerminationReview_Organization$key = {
  readonly " $data"?: GranteeTerminationReview_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationReview_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationReview_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteePortalSettings",
      "kind": "LinkedField",
      "name": "granteePortalSettings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayFullyDilutedValues",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "grantAmendmentRequest_useAmendedLastDayToExercise_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "0226d52b03be23f3ac8268b3a86e97b9";

export default node;
