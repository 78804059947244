import { Disclosure, Transition } from "@headlessui/react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useToaster } from "../../../../../components/Toaster";
import { Button } from "../../../../../components/ui/Button";
import { Divider } from "../../../../../components/ui/Divider";
import { Checkbox } from "../../../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { Input } from "../../../../../components/ui/Form/Inputs/Input";
import { TextArea } from "../../../../../components/ui/Form/Inputs/TextArea";
import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Toast } from "../../../../../components/ui/Toast";
import { Typography } from "../../../../../components/ui/Typography";
import { useSafeMutation } from "../../../../../hooks/useSafeMutation";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../../../../../services/workRelationship";
import { CheckboxFormRow } from "../../../../Admin/EquityOffer/CheckboxFormRow";
import { LegalWatchOutsEditionBlock_EquityType$key } from "./__generated__/LegalWatchOutsEditionBlock_EquityType.graphql";
import { LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key } from "./__generated__/LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts.graphql";
import { LegalWatchOutsEditionBlock_SetEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory_Mutation } from "./__generated__/LegalWatchOutsEditionBlock_SetEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory_Mutation.graphql";
import { LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation } from "./__generated__/LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation.graphql";

const EQUITY_TYPE_FRAGMENT = graphql`
  fragment LegalWatchOutsEditionBlock_EquityType on EquityType {
    id
    legalWatchOutsForDirectEmployee {
      ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts
    }
    legalWatchOutsForEoREmployee {
      ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts
    }
    legalWatchOutsForContractor {
      ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts
    }
  }
`;

const LEGAL_WATCHOUTS_FRAGMENT = graphql`
  fragment LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts on EquityTypeWorkRelationshipCategoryLegalWatchOuts {
    noWatchOut
    watchOuts {
      id
      title
      content
    }
  }
`;

const MUTATION = graphql`
  mutation LegalWatchOutsEditionBlock_SetEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory_Mutation(
    $equityTypeId: UUID!
    $equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory!
    $legalWatchOuts: [EquityTypeLegalWatchOutsAttributes!]!
  ) {
    setEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory(
      equityTypeId: $equityTypeId
      equityTypeWorkRelationshipCategory: $equityTypeWorkRelationshipCategory
      legalWatchOuts: $legalWatchOuts
    ) {
      ...LegalWatchOutsEditionBlock_EquityType
      ...EquityTypesLegalWatchOutsTable_EquityType
    }
  }
`;

const NO_LEGAL_WATCHOUT_MUTATION = graphql`
  mutation LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation(
    $equityTypeId: UUID!
    $equityTypeWorkRelationshipCategory: EquityTypeWorkRelationshipCategory!
    $noLegalWatchOut: Boolean!
  ) {
    setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory(
      equityTypeId: $equityTypeId
      equityTypeWorkRelationshipCategory: $equityTypeWorkRelationshipCategory
      noLegalWatchOut: $noLegalWatchOut
    ) {
      ...LegalWatchOutsEditionBlock_EquityType
      ...EquityTypesLegalWatchOutsTable_EquityType
    }
  }
`;

const schema = z.object({
  legalWatchOuts: z.array(
    z.object({
      content: z.string().trim().min(1),
      id: z.string().optional(),
      title: z.string().trim().min(1),
    }),
  ),
});

type FormInputs = z.infer<typeof schema>;

export const LegalWatchOutsEditionBlock: React.FC<{
  equityTypeFragment: LegalWatchOutsEditionBlock_EquityType$key;
  equityTypeWorkRelationshipCategory:
    | "Contractor"
    | "DirectEmployee"
    | "EoREmployee";
}> = ({ equityTypeFragment, equityTypeWorkRelationshipCategory }) => {
  const equityType = useFragment(EQUITY_TYPE_FRAGMENT, equityTypeFragment);

  const legalWatchOutsForDirectEmployee =
    useFragment<LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key>(
      LEGAL_WATCHOUTS_FRAGMENT,
      equityType.legalWatchOutsForDirectEmployee,
    );
  const legalWatchOutsForEoREmployee =
    useFragment<LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key>(
      LEGAL_WATCHOUTS_FRAGMENT,
      equityType.legalWatchOutsForEoREmployee,
    );

  const legalWatchOutsForContractor =
    useFragment<LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts$key>(
      LEGAL_WATCHOUTS_FRAGMENT,
      equityType.legalWatchOutsForContractor,
    );

  const initialLegalWatchOuts = useMemo(() => {
    switch (equityTypeWorkRelationshipCategory) {
      case "Contractor":
        return legalWatchOutsForContractor;
      case "DirectEmployee":
        return legalWatchOutsForDirectEmployee;
      case "EoREmployee":
        return legalWatchOutsForEoREmployee;
    }
  }, [
    equityTypeWorkRelationshipCategory,
    legalWatchOutsForContractor,
    legalWatchOutsForDirectEmployee,
    legalWatchOutsForEoREmployee,
  ]);

  const { control, formState, handleSubmit, reset, setValue, watch } = useForm({
    resolver: zodResolver(schema),
    values: {
      legalWatchOuts:
        initialLegalWatchOuts.watchOuts as FormInputs["legalWatchOuts"],
    },
  });

  const [
    setEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory,
    mutationIsInFlight,
  ] =
    useSafeMutation<LegalWatchOutsEditionBlock_SetEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory_Mutation>(
      MUTATION,
    );

  const toaster = useToaster();

  const saveLegalWatchOuts = useCallback(
    async (legalWatchOuts: FormInputs["legalWatchOuts"]) => {
      await setEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory({
        variables: {
          equityTypeId: equityType.id,
          equityTypeWorkRelationshipCategory,
          legalWatchOuts: legalWatchOuts.map(({ content, id, title }) => ({
            content,
            id,
            title,
          })),
        },
      });
      toaster.push(
        <Toast title="Well done!">
          Legal watch outs saved for{" "}
          {
            EQUITY_TYPE_WORK_RELATIONSHIP_MAP[
              equityTypeWorkRelationshipCategory
            ].label
          }
        </Toast>,
      );
    },
    [
      equityType.id,
      equityTypeWorkRelationshipCategory,
      setEquityTypeLegalWatchOutsForEquityTypeWorkRelationshipCategory,
      toaster,
    ],
  );

  const onSubmit = handleSubmit(async (formInputs) => {
    await saveLegalWatchOuts(formInputs.legalWatchOuts);
  });

  const [legalWatchOuts] = watch(["legalWatchOuts"]);

  const addLegalWatchOut = useCallback(
    () =>
      setValue("legalWatchOuts", [
        ...legalWatchOuts,
        { content: "", id: undefined, title: "" },
      ]),
    [legalWatchOuts, setValue],
  );

  const deleteLegalWatchOut = useCallback(
    async (index: number) => {
      await saveLegalWatchOuts(legalWatchOuts.filter((_, i) => i !== index));
    },
    [legalWatchOuts, saveLegalWatchOuts],
  );

  const [setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory] =
    useSafeMutation<LegalWatchOutsEditionBlock_SetEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory_Mutation>(
      NO_LEGAL_WATCHOUT_MUTATION,
    );

  const setNoLegalWatchOut = useCallback(
    async (noLegalWatchOut: boolean) => {
      await setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory({
        variables: {
          equityTypeId: equityType.id,
          equityTypeWorkRelationshipCategory,
          noLegalWatchOut,
        },
      });
      toaster.push(
        <Toast title="Well done!">
          Legal watch outs saved for{" "}
          {
            EQUITY_TYPE_WORK_RELATIONSHIP_MAP[
              equityTypeWorkRelationshipCategory
            ].label
          }
        </Toast>,
      );
    },
    [
      equityType.id,
      equityTypeWorkRelationshipCategory,
      setEquityTypeNoLegalWatchOutForEquityTypeWorkRelationshipCategory,
      toaster,
    ],
  );

  return (
    <RoundedBox className="overflow-hidden" withBorder>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between bg-gray-02 px-6 py-4 text-left">
              <Typography variant="Medium/Extra Small">
                {
                  EQUITY_TYPE_WORK_RELATIONSHIP_MAP[
                    equityTypeWorkRelationshipCategory
                  ].label
                }
              </Typography>
              <ChevronDownIcon
                className={classNames(
                  {
                    "rotate-180": open,
                  },
                  "h-4",
                )}
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel>
                <form className="space-y-6 p-10" onSubmit={onSubmit}>
                  <div className="flex gap-10">
                    <div className="flex flex-1 flex-col gap-2">
                      <Typography variant="Medium/Extra Small">
                        Watch out
                      </Typography>
                      <ul className="list-inside list-disc text-black-05">
                        <li>
                          Leave empty if you don’t want to display anything
                        </li>
                      </ul>
                    </div>
                    <div className="w-[551px] shrink-0 space-y-6">
                      {!initialLegalWatchOuts.noWatchOut &&
                        legalWatchOuts.map((_, index) => (
                          <div
                            className="flex flex-col gap-2"
                            key={`legalWatchOut-${index}`}
                          >
                            <div className="flex items-center justify-between">
                              <Typography variant="Medium/Extra Small">
                                Watch out {index + 1}
                              </Typography>
                              <Button
                                leftIcon={<XMarkIcon />}
                                loading={mutationIsInFlight}
                                onClick={() => deleteLegalWatchOut(index)}
                                size="extra small"
                                type="button"
                                variant="Secondary Full"
                              >
                                DELETE WATCH OUT
                              </Button>
                            </div>
                            <FormRow
                              error={
                                formState.errors.legalWatchOuts?.[index]?.title
                                  ?.message
                              }
                            >
                              <Input
                                placeholder="Title"
                                {...control.register(
                                  `legalWatchOuts.${index}.title`,
                                )}
                              />
                            </FormRow>
                            <FormRow
                              error={
                                formState.errors.legalWatchOuts?.[index]
                                  ?.content?.message
                              }
                            >
                              <TextArea
                                {...control.register(
                                  `legalWatchOuts.${index}.content`,
                                )}
                                className="h-[254px] resize-none"
                                placeholder="Content"
                              />
                            </FormRow>
                          </div>
                        ))}
                      <div className="flex justify-between">
                        {!initialLegalWatchOuts.noWatchOut ? (
                          <Button
                            leftIcon={<PlusIcon />}
                            onClick={addLegalWatchOut}
                            size="extra small"
                            type="button"
                            variant="Primary Outline"
                          >
                            Add watch out
                          </Button>
                        ) : (
                          <div />
                        )}
                        <CheckboxFormRow
                          checkbox={
                            <Checkbox
                              checked={initialLegalWatchOuts.noWatchOut}
                              onChange={(e) => {
                                const checked = e.currentTarget.checked;
                                void setNoLegalWatchOut(checked);
                              }}
                            />
                          }
                          label="All good, nothing to worry about"
                        />
                      </div>
                    </div>
                  </div>
                  {!initialLegalWatchOuts.noWatchOut && (
                    <>
                      <Divider />
                      <div className="flex justify-end gap-2">
                        <Button
                          disabled={!formState.isDirty || mutationIsInFlight}
                          onClick={() => reset()}
                          size="extra small"
                          type="button"
                          variant="Secondary Full"
                        >
                          Discard
                        </Button>
                        <Button
                          disabled={!formState.isDirty || mutationIsInFlight}
                          loading={mutationIsInFlight}
                          size="extra small"
                          type="submit"
                          variant="Primary Full"
                        >
                          Save changes
                        </Button>
                      </div>
                    </>
                  )}
                </form>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </RoundedBox>
  );
};
