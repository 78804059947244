import { FormattedDate } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GranteeStatusTag_Grantee$key } from "./__generated__/GranteeStatusTag_Grantee.graphql";
import { Tag } from "./ui/Tag";

const GRANTEES_FRAGMENT = graphql`
  fragment GranteeStatusTag_Grantee on Grantee {
    status
    hasSettlingGrant
    terminationDate
  }
`;

export const GranteeStatusTag: React.FC<{
  granteeFragment: GranteeStatusTag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEES_FRAGMENT, granteeFragment);
  if (grantee.status === "Active") {
    return <Tag color="glass-green">Active</Tag>;
  }

  if (grantee.hasSettlingGrant) {
    return <Tag color="orange">Settling</Tag>;
  }

  if (grantee.terminationDate) {
    return (
      <Tag color="red">
        Terminated{" "}
        <FormattedDate
          day="numeric"
          month="short"
          value={grantee.terminationDate}
          year="numeric"
        />
      </Tag>
    );
  }

  return <Tag color="red">Terminated</Tag>;
};
