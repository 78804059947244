import React from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { useApplicationSupportEmailAddress } from "../hooks/useApplicationTheme";
import { OrganizationInfoCard_Organization$key } from "./__generated__/OrganizationInfoCard_Organization.graphql";
import { FormattedCurrencyCompact } from "./Formatted/FormattedCurrencyCompact";
import { FormattedFMV } from "./FormattedFMV";
import { Card } from "./ui/Card";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationInfoCard_Organization on Organization {
    name

    latestPricePerShare {
      value
    }
    latestFairMarketValue {
      value
    }
    esopPoolShares
    latestValuation
    hasBeenOnboarded
  }
`;

const Item: React.FC<React.PropsWithChildren<{ title: React.ReactNode }>> = ({
  children,
  title,
}) => (
  <div>
    <Typography as="div" variant="Medium/Default">
      {children}
    </Typography>
    <Typography
      as="div"
      className="text-black-05"
      variant="Regular/Extra Small"
    >
      {title}
    </Typography>
  </div>
);

export const OrganizationInfoCard: React.FC<{
  className?: string;
  organizationFragment: OrganizationInfoCard_Organization$key;
}> = ({ className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const mailtoSubject = encodeURIComponent(
    `[${organization.name}] Setting up my workspace`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Card className={className} title={organization.name}>
      <div className="grid grid-cols-2 gap-4">
        {!organization.hasBeenOnboarded && (
          <NoticeMessage className="col-span-2" size="Large" variant="Warning">
            Latest valuation and PPS are based on the price per share that was
            manually entered during the onboarding.{" "}
            <Link
              className="font-semibold text-primary"
              to={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
            >
              Contact us
            </Link>{" "}
            if you want to update it or if you need support defining it.
          </NoticeMessage>
        )}
        <Item title="Latest valuation">
          {organization.latestValuation !== null ? (
            <FormattedCurrencyCompact value={organization.latestValuation} />
          ) : (
            "-"
          )}
        </Item>

        <Item title="Price per share">
          {organization.latestPricePerShare ? (
            <FormattedFMV value={organization.latestPricePerShare.value} />
          ) : (
            "-"
          )}
        </Item>
        <Item title="Pool Size">
          {organization.esopPoolShares !== null ? (
            <FormattedNumber value={organization.esopPoolShares} />
          ) : (
            "-"
          )}
        </Item>

        <Item
          title={
            <>
              Fair Market Value
              <br />
              (Shares of Common Stock)
            </>
          }
        >
          {organization.latestFairMarketValue ? (
            <FormattedFMV value={organization.latestFairMarketValue.value} />
          ) : (
            "-"
          )}
        </Item>
      </div>
    </Card>
  );
};
