import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { APPLICATION_ROUTES } from "../paths";
import { AdminEntitiesOverview_Organization$key } from "./__generated__/AdminEntitiesOverview_Organization.graphql";
import { LinkButton } from "./ui/Button";
import { Card } from "./ui/Card";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminEntitiesOverview_Organization on Organization {
    id
    activeCTMSGrantCount: ctmsGrantCount(grantStatus: [Active])
    granteeCountGroupedByStatus {
      Active
    }
    draftEasopGrants: easopGrants(filters: { statusIn: [Draft] }) {
      __typename
    }
    pendingReviewEasopGrants: easopGrants(
      filters: { statusIn: [PendingReview] }
    ) {
      __typename
    }
    reviewedEasopGrants: easopGrants(filters: { statusIn: [Reviewed] }) {
      __typename
    }
    pendingBoardConsentEasopGrants: easopGrants(
      filters: { statusIn: [PendingBoardConsent] }
    ) {
      __typename
    }
    pendingCtmsImplementationEasopGrants: easopGrants(
      filters: { statusIn: [PendingCtmsImplementation] }
    ) {
      __typename
    }

    activeGeographyCount
  }
`;

const EntityRow: React.FC<{
  count: React.ReactNode;
  icon: React.ReactNode;
  label: React.ReactNode;
  linkTo: string;
}> = ({ count, icon, label, linkTo }) => (
  <div className="flex items-center gap-6 pb-2 pt-2 first:pt-0 last:pb-0">
    <div className="flex flex-1 items-center gap-2">
      <Typography as="div" variant="Medium/Default">
        {icon}
      </Typography>
      <Typography
        as="div"
        className="flex-1 text-black-05"
        variant="Regular/Extra Small"
      >
        {label}
      </Typography>
    </div>
    <Typography as="div" className="text-primary" variant="Medium/Default">
      {count}
    </Typography>
    <LinkButton size="small" to={linkTo} variant="Primary Outline">
      View
    </LinkButton>
  </div>
);

export const AdminEntitiesOverview: React.FC<{
  className?: string;
  organizationFragment: AdminEntitiesOverview_Organization$key;
}> = ({ className, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const draftsCount = useMemo(
    () =>
      organization.draftEasopGrants.length +
      organization.pendingReviewEasopGrants.length +
      organization.reviewedEasopGrants.length,
    [
      organization.draftEasopGrants.length,
      organization.pendingReviewEasopGrants.length,
      organization.reviewedEasopGrants.length,
    ],
  );
  const pendingCount = useMemo(
    () =>
      organization.pendingBoardConsentEasopGrants.length +
      organization.pendingCtmsImplementationEasopGrants.length,
    [
      organization.pendingBoardConsentEasopGrants.length,
      organization.pendingCtmsImplementationEasopGrants.length,
    ],
  );

  return (
    <Card className={className} title="Overview">
      <div className="divide-y-[0.5px] divide-gray-04">
        <EntityRow
          count={organization.granteeCountGroupedByStatus.Active}
          icon="🏂"
          label={
            <FormattedMessage
              defaultMessage="{count, plural, one {Grantee} other {Grantees}}"
              values={{
                count: organization.granteeCountGroupedByStatus.Active,
              }}
            />
          }
          linkTo={generatePath(APPLICATION_ROUTES["organizationGrantees"], {
            organizationId: organization.id,
          })}
        />
        <EntityRow
          count={organization.activeCTMSGrantCount}
          icon="🚀"
          label={
            <FormattedMessage
              defaultMessage="{count, plural, one {Grant} other {Grants}}"
              values={{ count: organization.activeCTMSGrantCount }}
            />
          }
          linkTo={generatePath(APPLICATION_ROUTES["organizationEquityGrants"], {
            organizationId: organization.id,
          })}
        />
        <EntityRow
          count={draftsCount}
          icon="✍️"
          label={
            <FormattedMessage
              defaultMessage="{count, plural, one {Draft} other {Drafts}}"
              values={{ count: draftsCount }}
            />
          }
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationPrepareYourGrants"],
            {
              organizationId: organization.id,
            },
          )}
        />
        <EntityRow
          count={pendingCount}
          icon="⏱️"
          label={
            <FormattedMessage
              defaultMessage="Pending {count, plural, one {grant} other {grants}}"
              values={{ count: pendingCount }}
            />
          }
          linkTo={generatePath(
            APPLICATION_ROUTES["organizationPrepareYourGrantsPendingApproval"],
            {
              organizationId: organization.id,
            },
          )}
        />
        <EntityRow
          count={organization.activeGeographyCount}
          icon="🌎"
          label={
            <FormattedMessage
              defaultMessage="Active {count, plural, one {geography} other {geographies}}"
              values={{ count: organization.activeGeographyCount }}
            />
          }
          linkTo={generatePath(APPLICATION_ROUTES["organizationCountries"], {
            organizationId: organization.id,
          })}
        />
      </div>
    </Card>
  );
};
