/**
 * @generated SignedSource<<80f8f2250543944fd47ef2cb167ab4c6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriptionBillingPeriod = "monthly" | "yearly";
import { FragmentRefs } from "relay-runtime";
export type UnlockCountries_Organization$data = {
  readonly availableToUnlockGeographies: ReadonlyArray<{
    readonly geography: {
      readonly country: {
        readonly code: string;
        readonly emoji: string;
        readonly name: string;
      };
    };
    readonly standardPrice: {
      readonly priceAfterDiscount: number;
    } | null;
  }>;
  readonly id: string;
  readonly name: string;
  readonly subscriptionBillingPeriod: SubscriptionBillingPeriod | null;
  readonly " $fragmentSpreads": FragmentRefs<"LargeCenteredDetailsLayout_Organization">;
  readonly " $fragmentType": "UnlockCountries_Organization";
};
export type UnlockCountries_Organization$key = {
  readonly " $data"?: UnlockCountries_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnlockCountries_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnlockCountries_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionBillingPeriod",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GeographyWithPrice",
      "kind": "LinkedField",
      "name": "availableToUnlockGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Geography",
          "kind": "LinkedField",
          "name": "geography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emoji",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationSpecificPrice",
          "kind": "LinkedField",
          "name": "standardPrice",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceAfterDiscount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "2bb1749863f421074838337ba2eff2d0";

export default node;
