import React from "react";
import { graphql, useFragment } from "react-relay";

import { LargeOneColumnLayout } from "../components/ui/Layout/LargeOneColumnLayout";
import { SearchBar } from "../components/ui/SearchBar";
import { LegalWorkflowLayout_Organization$key } from "./__generated__/LegalWorkflowLayout_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LegalWorkflowLayout_Organization on Organization {
    ...LargeOneColumnLayout_Organization
  }
`;

export function LegalWorkflowLayout({
  actionButton,
  children,
  onSearchChange,
  organizationFragment,
  searchIsLoading,
  searchPlaceholder,
  searchValue,
  subtitle,
  title,
  topBarActionsRender,
  warnings,
}: React.PropsWithChildren<
  {
    actionButton?: React.ReactNode;
    organizationFragment: LegalWorkflowLayout_Organization$key;
    subtitle: React.ReactNode;
    title: React.ReactNode;
    topBarActionsRender?: (props: {
      mainContentIsScrolled?: boolean;
    }) => React.ReactNode;
    warnings?: React.ReactNode;
  } & (
    | {
        onSearchChange: (search: string) => void;
        searchIsLoading: boolean;
        searchPlaceholder?: string;
        searchValue: string;
      }
    | {
        onSearchChange?: undefined;
        searchIsLoading?: undefined;
        searchPlaceholder?: undefined;
        searchValue?: undefined;
      }
  )
>) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <LargeOneColumnLayout
      maxWidth={1200}
      organizationFragment={organization}
      subtitle={subtitle}
      title={title}
      topBarActionsRender={topBarActionsRender}
      topBarItemsVerticalAlignment="bottom"
    >
      <div className="space-y-6">
        <div className="flex gap-4">
          {actionButton}
          {onSearchChange && (
            <SearchBar
              className="w-full max-w-[270px]"
              loading={searchIsLoading}
              onChange={onSearchChange}
              placeholder={searchPlaceholder}
              value={searchValue}
            />
          )}
        </div>

        {warnings}

        <div>{children}</div>
      </div>
    </LargeOneColumnLayout>
  );
}
