/**
 * @generated SignedSource<<2953a0cc5566ad9a1ab8359823103a36>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewHirePlanningEntryModal_NewHireGrantPlanningEntry$data = {
  readonly equityGridLevel: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly newHirerEntrySharesGranted: number | null;
  readonly position: string;
  readonly " $fragmentType": "NewHirePlanningEntryModal_NewHireGrantPlanningEntry";
};
export type NewHirePlanningEntryModal_NewHireGrantPlanningEntry$key = {
  readonly " $data"?: NewHirePlanningEntryModal_NewHireGrantPlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewHirePlanningEntryModal_NewHireGrantPlanningEntry">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewHirePlanningEntryModal_NewHireGrantPlanningEntry",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": "newHirerEntrySharesGranted",
      "args": null,
      "kind": "ScalarField",
      "name": "sharesGranted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "NewHireGrantPlanningEntry",
  "abstractKey": null
};
})();

(node as any).hash = "2e98c90c4f8a44f30f021d2f81fc3153";

export default node;
