import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useScrollToTopOnNavigation(ref: React.RefObject<HTMLElement>) {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    ref.current?.scrollTo({
      behavior: "instant", // Optional if you want to skip the scrolling animation
      left: 0,
      top: 0,
    });
  }, [pathname, ref]);
}
