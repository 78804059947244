import classNames from "classnames";
import React, { ReactNode } from "react";

import { Typography } from "./Typography";

export const LabelValue: React.FC<{
  children: ReactNode;
  className?: string;
  isPrimary?: boolean;
  label: string;
}> = ({ children, className, isPrimary, label }) => {
  return (
    <div className={classNames(className, "flex flex-col")}>
      <Typography
        as="div"
        className={isPrimary ? "text-black-07" : "text-black-05"}
        variant="Regular/Extra Small"
      >
        {label}
      </Typography>
      <Typography
        as="div"
        className={isPrimary ? "text-primary" : ""}
        variant="Medium/Default"
      >
        {children}
      </Typography>
    </div>
  );
};
