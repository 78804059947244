import { ArrowLeftIcon, CheckIcon } from "@heroicons/react/24/outline";
import { graphql, useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";

import { useAlerter } from "../../../../components/Alerter";
import { CountryNotUnlockedAlert } from "../../../../components/Alerts/CountryNotUnlockedAlert";
import { EquityTypeInCountryNotUnlockedAlert } from "../../../../components/Alerts/EquityTypeInCountryNotUnlockedAlert";
import { GranteeIsMissingRequiredInformationAlert } from "../../../../components/Alerts/GranteeIsMissingRequiredInformationAlert";
import { GrantOnManagementCompaniesNotAllowedAlert } from "../../../../components/Alerts/GrantOnManagementCompaniesNotAllowedAlert";
import { InstrumentNotAvailableForGranteeAlert } from "../../../../components/Alerts/InstrumentNotAvailableForGranteeAlert";
import { LabelAlreadyExistsAlert } from "../../../../components/Alerts/LabelAlreadyExistsAlert";
import { PTEPNotProvidedAlert } from "../../../../components/Alerts/PTEPNotProvidedAlert";
import { PTEPProvidedForFixedPTEPInstrumentAlert } from "../../../../components/Alerts/PTEPProvidedForFixedPTEPInstrumentAlert";
import { WorkRelationshipNotCoveredAlert } from "../../../../components/Alerts/WorkRelationshipNotCoveredAlert";
import { LongDate } from "../../../../components/LongDate";
import { Page } from "../../../../components/Page";
import { useToaster } from "../../../../components/Toaster";
import { Button, LinkButton } from "../../../../components/ui/Button";
import { Toast } from "../../../../components/ui/Toast";
import { Typography } from "../../../../components/ui/Typography";
import { useTrackEvent } from "../../../../hooks/useAnalytics";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import {
  AssistedGrantContextValues,
  useAssistedGrantContext,
  useIsAssistedGrantVirtual,
} from "../Context";
import { AssistedGrantPageTitle } from "../Shared";
import {
  EasopGrantAttributes,
  Review_CreateGrant_Mutation,
} from "./__generated__/Review_CreateGrant_Mutation.graphql";
import { Review_Grantee$key } from "./__generated__/Review_Grantee.graphql";
import { Review_Instrument$key } from "./__generated__/Review_Instrument.graphql";
import { Review_Organization$key } from "./__generated__/Review_Organization.graphql";
import { Review_OrganizationPostTerminationExercisePeriod$key } from "./__generated__/Review_OrganizationPostTerminationExercisePeriod.graphql";
import { Review_Query } from "./__generated__/Review_Query.graphql";
import { Review_VestingSchedule$key } from "./__generated__/Review_VestingSchedule.graphql";
import { GrantDetailsRow } from "./GrantDetailsRow";
import { GrantGranteeDetailsRow } from "./GrantGranteeDetailsRow";
import { GrantOwnershipRow } from "./GrantOwnershipRow";
import { GrantPostTerminationRow } from "./GrantPostTerminationRow";
import { GrantReviewRow } from "./GrantReviewRow";
import { GrantVestingOverviewRow } from "./GrantVestingOverviewRow";
import { GrantVestingScheduleRow } from "./GrantVestingScheduleRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Review_Organization on Organization {
    id
    ...GrantOwnershipRow_Organization
    ...GrantPostTerminationRow_Organization
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment Review_Instrument on Instrument {
    ...GrantDetailsRow_Instrument
    ...InstrumentNotAvailableForGranteeAlert_Instrument
  }
`;

const ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_FRAGMENT = graphql`
  fragment Review_OrganizationPostTerminationExercisePeriod on OrganizationPostTerminationExercisePeriod {
    duration
    durationUnit
    ... on OrganizationVariablePostTerminationExercisePeriod {
      extensionDuration
      extensionDurationUnit
      thresholdForExtensionInYears
    }
  }
`;

const VESTING_SCHEDULE_FRAGMENT = graphql`
  fragment Review_VestingSchedule on VestingSchedule {
    ...GrantVestingScheduleRow_VestingSchedule
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment Review_Grantee on Grantee {
    ...GrantOwnershipRow_Grantee
    ...GrantVestingOverviewRow_Grantee
    ...GrantGranteeDetailsRow_Grantee
    ...InstrumentNotAvailableForGranteeAlert_Grantee
  }
`;

const CREATE_GRANT_MUTATION = graphql`
  mutation Review_CreateGrant_Mutation(
    $attributes: EasopGrantAttributes!
    $planningEntrySourceId: PlanningEntryId
    $equityOfferSourceId: UUID
  ) {
    createEasopGrant(
      attributes: $attributes
      planningEntrySourceId: $planningEntrySourceId
      equityOfferSourceId: $equityOfferSourceId
    ) {
      __typename
      ... on EditEasopGrantResultFailure {
        error
      }
      ... on EditEasopGrantResultSucess {
        easopGrant {
          planningEntrySource {
            __typename
          }
          equityOfferSource {
            __typename
          }
          grantee {
            name
          }
        }
      }
    }
  }
`;

const MandatoryProperties = [
  "id",
  "label",
  "granteeId",
  "instrumentId",
  "exercisePrice",
  "quantityGranted",
  "vestingScheduleId",
  "vestingStartDate",
  "earlyExercise",
  "accelerationClause",
] as const;

type CompleteAssistedGrantContextValues = Partial<AssistedGrantContextValues> &
  Required<
    Pick<AssistedGrantContextValues, (typeof MandatoryProperties)[number]>
  >;

const checkGrantCompleteness = (
  state: AssistedGrantContextValues,
): CompleteAssistedGrantContextValues => {
  MandatoryProperties.forEach((key) => {
    if (typeof state[key] === "undefined") {
      throw new Error(`${key} not found in context`);
    }
  });

  return state as CompleteAssistedGrantContextValues;
};

const AdminAssistedGrantReviewPage_: React.FC<{
  granteeFragment: Review_Grantee$key;
  instrumentFragment: Review_Instrument$key;
  organizationFragment: Review_Organization$key;
  organizationPostTerminationExercisePeriodFragment?: null | Review_OrganizationPostTerminationExercisePeriod$key;
  vestingScheduleFragment: Review_VestingSchedule$key;
}> = ({
  granteeFragment,
  instrumentFragment,
  organizationFragment,
  organizationPostTerminationExercisePeriodFragment,
  vestingScheduleFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const vestingSchedule = useFragment(
    VESTING_SCHEDULE_FRAGMENT,
    vestingScheduleFragment,
  );
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const organizationPostTerminationExercisePeriod = useFragment(
    ORGANIZATION_POST_TERMINATION_EXERCISE_PERIOD_FRAGMENT,
    organizationPostTerminationExercisePeriodFragment,
  );

  const context = useAssistedGrantContext();
  const navigate = useNavigate();
  const alerter = useAlerter();

  const state = checkGrantCompleteness(context.state);

  const [_createGrant, mutationInFlight] =
    useSafeMutation<Review_CreateGrant_Mutation>(CREATE_GRANT_MUTATION);

  const toaster = useToaster();

  const trackEvent = useTrackEvent();
  const isGrantVirtual = useIsAssistedGrantVirtual();
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    trackEvent("Grant Draft - Draft Saved", context.state.analyticsContext);
    const attributes: EasopGrantAttributes = {
      accelerationClause: state.accelerationClause,
      earlyExercise: state.earlyExercise,
      exercisePrice: state.exercisePrice,
      exercisePriceBelowFMVSetOn: state.exercisePriceBelowFMVSetOn,
      granteeId: state.granteeId,
      id: state.id,
      instrumentId: state.instrumentId,
      label: state.label,
      organizationId: organization.id,
      postTerminationExercisePeriodId: state.postTerminationExercisePeriodId,
      quantityGranted: state.quantityGranted,
      vestingScheduleId: state.vestingScheduleId,
      vestingStartDate: state.vestingStartDate,
    };

    const { createEasopGrant: result } = await _createGrant({
      variables: {
        attributes,
        equityOfferSourceId: state.equityOfferSource?.id,
        planningEntrySourceId:
          state.planningEntrySource && "id" in state.planningEntrySource
            ? state.planningEntrySource.id
            : null,
      },
    });

    switch (result.__typename) {
      case "EditEasopGrantResultFailure": {
        switch (result.error) {
          case "COUNTRY_NOT_UNLOCKED": {
            alerter.push(<CountryNotUnlockedAlert />);
            break;
          }
          case "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED": {
            alerter.push(<EquityTypeInCountryNotUnlockedAlert />);
            break;
          }
          case "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED": {
            alerter.push(<GrantOnManagementCompaniesNotAllowedAlert />);
            break;
          }
          case "GRANTEE_IS_MISSING_REQUIRED_INFORMATION": {
            alerter.push(<GranteeIsMissingRequiredInformationAlert />);
            break;
          }
          case "INSTRUMENT_NOT_AVAILABLE_FOR_GRANTEE": {
            alerter.push(
              <InstrumentNotAvailableForGranteeAlert
                granteeFragment={grantee}
                instrumentFragment={instrument}
              />,
            );
            break;
          }
          case "LABEL_ALREADY_EXISTS": {
            alerter.push(<LabelAlreadyExistsAlert />);
            break;
          }
          case "PTEP_NOT_PROVIDED": {
            alerter.push(<PTEPNotProvidedAlert />);
            break;
          }
          case "PTEP_PROVIDED_FOR_FIXED_PTEP_INSTRUMENT": {
            alerter.push(<PTEPProvidedForFixedPTEPInstrumentAlert />);
            break;
          }
          case "WORK_RELATIONSHIP_NOT_COVERED": {
            alerter.push(<WorkRelationshipNotCoveredAlert />);
            break;
          }
        }
        break;
      }
      case "EditEasopGrantResultSucess": {
        if (result.easopGrant.planningEntrySource) {
          toaster.push(
            <Toast title="Great!">
              Your draft to {result.easopGrant.grantee.name} has been
              successfully created.
            </Toast>,
          );
          void navigate(
            generatePath(APPLICATION_ROUTES.organizationPlanning, {
              organizationId: organization.id,
            }),
          );
          break;
        }

        if (result.easopGrant.equityOfferSource) {
          toaster.push(
            <Toast title="Great!">
              Your draft to {result.easopGrant.grantee.name} has been
              successfully created.
            </Toast>,
          );
          void navigate(
            generatePath(
              APPLICATION_ROUTES.organizationToolsEquityOffersSettings,
              {
                organizationId: organization.id,
              },
            ),
          );
          break;
        }

        void navigate(
          generatePath(APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts, {
            organizationId: organization.id,
          }),
        );
        break;
      }
      case "%other":
        throw new Error(`Unexpected entry type`);
    }
  };

  return (
    <div className="max-w-[792px] space-y-4">
      <AssistedGrantPageTitle subtitle="Here’s a summary of the grant before you can validate it.">
        Let’s review the draft and save it
      </AssistedGrantPageTitle>
      <form
        className="divide-y-[0.5px] divide-gray-04 rounded-lg border-[0.5px] border-gray-04 shadow-100"
        onSubmit={handleSubmit}
      >
        <div className="flex items-center gap-4 px-6 py-4">
          <Typography as="div" variant="Title 5">
            {state.label}
          </Typography>
          <Typography
            as="div"
            className="rounded bg-gray-02 px-2 py-1 text-gray-09"
            variant="Medium/Extra Small"
          >
            Draft
          </Typography>
          <Typography variant="Regular/Caption">
            Drafted on <LongDate value={new Date()} />
          </Typography>
        </div>

        <GrantOwnershipRow
          granteeFragment={grantee}
          organizationFragment={organization}
          quantityGranted={state.quantityGranted}
        />

        <GrantDetailsRow
          exercisePrice={state.exercisePrice}
          instrumentFragment={instrument}
          quantityGranted={state.quantityGranted}
        />

        <GrantVestingScheduleRow
          vestingScheduleFragment={vestingSchedule}
          vestingStartDate={state.vestingStartDate}
        />

        <GrantVestingOverviewRow
          granteeFragment={grantee}
          label={state.label}
          quantityGranted={state.quantityGranted}
          vestingScheduleId={state.vestingScheduleId}
          vestingStartDate={state.vestingStartDate}
        />

        {organizationPostTerminationExercisePeriod && (
          <GrantPostTerminationRow
            extendedStandardPostTerminationExercisePeriod={
              organizationPostTerminationExercisePeriod.extensionDuration
            }
            extendedStandardPostTerminationExercisePeriodUnit={
              organizationPostTerminationExercisePeriod.extensionDurationUnit
            }
            extendedStandardPostTerminationExercisePeriodYearsOfServiceRelationshipThreshold={
              organizationPostTerminationExercisePeriod.thresholdForExtensionInYears
            }
            hasVariableStandardPostTerminationExercisePeriod={
              !!organizationPostTerminationExercisePeriod.thresholdForExtensionInYears
            }
            isVirtual={isGrantVirtual}
            organizationFragment={organization}
            standardPostTerminationExercisePeriod={
              organizationPostTerminationExercisePeriod.duration
            }
            standardPostTerminationExercisePeriodUnit={
              organizationPostTerminationExercisePeriod.durationUnit
            }
          />
        )}

        <GrantGranteeDetailsRow granteeFragment={grantee} />

        <GrantReviewRow>
          <div className="flex justify-between">
            <LinkButton
              leftIcon={<ArrowLeftIcon />}
              to={generatePath(
                APPLICATION_ROUTES.organizationAssistedGrantVestingPTEP,
                {
                  organizationId: organization.id,
                },
              )}
              variant="Tertiary Link"
            >
              Back
            </LinkButton>
            <Button
              leftIcon={<CheckIcon />}
              loading={mutationInFlight}
              type="submit"
            >
              Confirm and save draft
            </Button>
          </div>
        </GrantReviewRow>
      </form>
    </div>
  );
};

const QUERY = graphql`
  query Review_Query(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
    $instrumentId: UUID!
    $vestingScheduleId: VestingScheduleId!
    $organizationPostTerminationExercisePeriodId: ID!
    $skipOrganizationPostTerminationExercisePeriod: Boolean!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...Review_Organization
    }
    instrument(id: $instrumentId) @required(action: THROW) {
      ...Review_Instrument
    }
    vestingSchedule(id: $vestingScheduleId) @required(action: THROW) {
      ...Review_VestingSchedule
    }
    grantee(id: $granteeId) @required(action: THROW) {
      ...Review_Grantee
    }
    organizationPostTerminationExercisePeriod: node(
      id: $organizationPostTerminationExercisePeriodId
    ) @skip(if: $skipOrganizationPostTerminationExercisePeriod) {
      ... on OrganizationPostTerminationExercisePeriod {
        ...Review_OrganizationPostTerminationExercisePeriod
      }
    }
  }
`;

const AdminAssistedGrantReviewPage: React.FC = () => {
  const context = useAssistedGrantContext();
  const organizationId = useOrganizationIdParam();

  const state = checkGrantCompleteness(context.state);

  const {
    query: {
      grantee,
      instrument,
      organization,
      organizationPostTerminationExercisePeriod,
      vestingSchedule,
    },
  } = useQuery<Review_Query>(QUERY, {
    granteeId: state.granteeId,
    instrumentId: state.instrumentId,
    organizationId,
    organizationPostTerminationExercisePeriodId:
      state.postTerminationExercisePeriodId ?? "__PLACEHOLDER__",
    skipOrganizationPostTerminationExercisePeriod:
      !state.postTerminationExercisePeriodId,
    vestingScheduleId: state.vestingScheduleId,
  });

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Review"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - review`}
    >
      <AdminAssistedGrantReviewPage_
        granteeFragment={grantee}
        instrumentFragment={instrument}
        organizationFragment={organization}
        organizationPostTerminationExercisePeriodFragment={
          organizationPostTerminationExercisePeriod
        }
        vestingScheduleFragment={vestingSchedule}
      />
    </Page>
  );
};

export default AdminAssistedGrantReviewPage;
