/**
 * @generated SignedSource<<3c311e4a72352e144933ac653eb0b48e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation$variables = {
  simulatedGrantId: string;
};
export type OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation$data = {
  readonly deleteSafeCalculatorVersionSimulatedGrant: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion">;
  };
};
export type OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation = {
  response: OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation$data;
  variables: OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "simulatedGrantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "simulatedGrantId",
    "variableName": "simulatedGrantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valuationCapInUSD",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalAmountInvestedInUSD",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastIssuanceDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useAsValuation",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "deleteSafeCalculatorVersionSimulatedGrant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SafeCalculatorVersion",
        "kind": "LinkedField",
        "name": "deleteSafeCalculatorVersionSimulatedGrant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "preConversionFDS",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optionPoolSize",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "safes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "SafePostMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "SafePreMoney",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "mfnDate",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "type": "SafePostMoneyMFN",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SafeCalculatorSimulatedGrant",
            "kind": "LinkedField",
            "name": "simulatedGrants",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grantedSharesInputMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shares",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bce4c11e21337ca7be48e26e948dbb2e",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation(\n  $simulatedGrantId: ID!\n) {\n  deleteSafeCalculatorVersionSimulatedGrant(simulatedGrantId: $simulatedGrantId) {\n    ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion\n    id\n  }\n}\n\nfragment OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion on SafeCalculatorVersion {\n  id\n  preConversionFDS\n  optionPoolSize\n  safes {\n    __typename\n    ... on SafePostMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePreMoney {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      useAsValuation\n    }\n    ... on SafePostMoneyMFN {\n      id\n      valuationCapInUSD\n      totalAmountInvestedInUSD\n      lastIssuanceDate\n      mfnDate\n      useAsValuation\n    }\n    id\n  }\n  simulatedGrants {\n    id\n    type\n    grantedSharesInputMode\n    shares\n  }\n}\n"
  }
};
})();

(node as any).hash = "f96c22d9e2852fd350b92637a1cdcf53";

export default node;
