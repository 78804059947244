/**
 * @generated SignedSource<<d58f0c3261fbf6a431f85a704a200a21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantableStatus = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTABLE" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "WORK_RELATIONSHIP_NOT_COVERED";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type Grantee_Grantee$data = ReadonlyArray<{
  readonly company: {
    readonly completeName: string;
  } | null;
  readonly contractStartDate: string | null;
  readonly equityGridLevel: {
    readonly name: string;
  } | null;
  readonly grantableStatus: GrantableStatus;
  readonly id: string;
  readonly jobTitle: string | null;
  readonly managementCompanyName: string | null;
  readonly name: string;
  readonly offGrid: boolean;
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee" | "GranteeNotGrantableAlert_Grantee" | "GranteeSelect_Grantees">;
  readonly " $fragmentType": "Grantee_Grantee";
}>;
export type Grantee_Grantee$key = ReadonlyArray<{
  readonly " $data"?: Grantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"Grantee_Grantee">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Grantee_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCompanyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantableStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offGrid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completeName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeSelect_Grantees"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNotGrantableAlert_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "52e1e582f30bd72fbe849af20c9f0ac1";

export default node;
