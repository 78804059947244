/**
 * @generated SignedSource<<79883ebd14114c8e9a25802258b4f684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReviewCTMSGrantModification_Query$variables = {
  ctmsGrantId: string;
  organizationId: string;
};
export type ReviewCTMSGrantModification_Query$data = {
  readonly ctmsGrant: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ReviewCTMSGrantModificationPageContent_CTMSGrant">;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"ReviewCTMSGrantModificationPageContent_Organization">;
  } | null;
};
export type ReviewCTMSGrantModification_Query = {
  response: ReviewCTMSGrantModification_Query$data;
  variables: ReviewCTMSGrantModification_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "ctmsGrantId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReviewCTMSGrantModification_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReviewCTMSGrantModificationPageContent_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CTMSGrant",
        "kind": "LinkedField",
        "name": "ctmsGrant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReviewCTMSGrantModificationPageContent_CTMSGrant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReviewCTMSGrantModification_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "postTerminationExercisePeriods",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "OrganizationFixedPostTerminationExercisePeriod",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "OrganizationVariablePostTerminationExercisePeriod",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "vestingSchedules",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isConnectedToCarta",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "CTMSGrant",
        "kind": "LinkedField",
        "name": "ctmsGrant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf03f36facafd0269178e0932e6a8047",
    "id": null,
    "metadata": {},
    "name": "ReviewCTMSGrantModification_Query",
    "operationKind": "query",
    "text": "query ReviewCTMSGrantModification_Query(\n  $organizationId: OrganizationId!\n  $ctmsGrantId: CtmsGrantId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...ReviewCTMSGrantModificationPageContent_Organization\n  }\n  ctmsGrant(id: $ctmsGrantId) {\n    id\n    ...ReviewCTMSGrantModificationPageContent_CTMSGrant\n  }\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeCenteredDetailsLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment ReviewCTMSGrantModificationPageContent_CTMSGrant on CTMSGrant {\n  id\n  label\n}\n\nfragment ReviewCTMSGrantModificationPageContent_Organization on Organization {\n  id\n  postTerminationExercisePeriods {\n    __typename\n    ... on OrganizationFixedPostTerminationExercisePeriod {\n      id\n      displayName\n    }\n    ... on OrganizationVariablePostTerminationExercisePeriod {\n      id\n      displayName\n    }\n    id\n  }\n  vestingSchedules {\n    __typename\n    id\n    name\n  }\n  ...LargeCenteredDetailsLayout_Organization\n}\n"
  }
};
})();

(node as any).hash = "e4e3313bdd8939bb50605d63c7266db3";

export default node;
