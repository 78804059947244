/**
 * @generated SignedSource<<de5f08ecc62bec24160c83fbcb39d78c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueHistoryChart_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ValuationHistoryChart_Organization">;
  readonly " $fragmentType": "FairMarketValueHistoryChart_Organization";
};
export type FairMarketValueHistoryChart_Organization$key = {
  readonly " $data"?: FairMarketValueHistoryChart_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueHistoryChart_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueHistoryChart_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ValuationHistoryChart_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ebbf3435d3dc47a83684589089837b2a";

export default node;
