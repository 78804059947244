/**
 * @generated SignedSource<<7283772cd4020cc70b7535a2d7e7f33d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type LinkToEquityType_Instrument$data = {
  readonly equityType: {
    readonly id: string;
    readonly name: EquityTypeName;
    readonly taxResidenceCountry: {
      readonly emoji: string;
    };
  };
  readonly workRelationship: WorkRelationship;
  readonly " $fragmentType": "LinkToEquityType_Instrument";
};
export type LinkToEquityType_Instrument$key = {
  readonly " $data"?: LinkToEquityType_Instrument$data;
  readonly " $fragmentSpreads": FragmentRefs<"LinkToEquityType_Instrument">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkToEquityType_Instrument",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": null,
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      "action": "THROW"
    }
  ],
  "type": "Instrument",
  "abstractKey": null
};

(node as any).hash = "2f12af6213b5983f059b644ecb2895e2";

export default node;
