import { zodResolver } from "@hookform/resolvers/zod";
import { startTransition, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { SelectOrganizationPostTerminationExercisePeriod } from "../../../../components/GrantForm/SelectOrganizationPostTerminationExercisePeriod";
import { GrantWatchouts } from "../../../../components/GrantWatchouts";
import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { CreateSlideOverRemote } from "../../Grants/Configure/PostTermination/CreateSlideOver";
import { NewPTEPSlider_CTMSGrant$key } from "./__generated__/NewPTEPSlider_CTMSGrant.graphql";
import { NewPTEPSlider_Organization$key } from "./__generated__/NewPTEPSlider_Organization.graphql";
import { NewPTEPSlider_Unfavorable_Query } from "./__generated__/NewPTEPSlider_Unfavorable_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewPTEPSlider_CTMSGrant on CTMSGrant {
    id
    isVirtual
    postTerminationExercisePeriod {
      id
    }
    matchingInstrument {
      equityType {
        ...GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType
      }
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewPTEPSlider_Organization on Organization {
    postTerminationExercisePeriods {
      id
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod
    }
    ...SelectOrganizationPostTerminationExercisePeriod_Organization
  }
`;

const UNFAVORABLE_QUERY = graphql`
  query NewPTEPSlider_Unfavorable_Query(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    areCTMSGrantAmendmentRequestParametersUnfavorable(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    )
  }
`;

const formSchema = z.object({
  organizationPostTerminationExercisePeriodId: z.string(),
});

function NewPTEPSlider({
  ctmsGrantFragment,
  newOrganizationPostTerminationExercisePeriodId,
  onClose,
  onExited,
  onNewOrganizationPostTerminationExercisePeriodChanges,
  organizationFragment,
  show,
}: {
  ctmsGrantFragment: NewPTEPSlider_CTMSGrant$key;
  newOrganizationPostTerminationExercisePeriodId: null | string;
  onClose: () => void;
  onExited?: () => void;
  onNewOrganizationPostTerminationExercisePeriodChanges: (props: {
    organizationPostTerminationExercisePeriodId: string;
  }) => void;
  organizationFragment: NewPTEPSlider_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const defaultValues = useMemo(() => {
    if (newOrganizationPostTerminationExercisePeriodId)
      return {
        organizationPostTerminationExercisePeriodId:
          newOrganizationPostTerminationExercisePeriodId,
      };

    if (ctmsGrant.postTerminationExercisePeriod) {
      return {
        organizationPostTerminationExercisePeriodId:
          ctmsGrant.postTerminationExercisePeriod.id,
      };
    }

    return { organizationPostTerminationExercisePeriodId: undefined };
  }, [
    newOrganizationPostTerminationExercisePeriodId,
    ctmsGrant.postTerminationExercisePeriod,
  ]);

  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onNewOrganizationPostTerminationExercisePeriodChanges(
      formSchema.parse(data),
    );
    onClose();
  });

  const [
    selectedOrganizationPostTerminationExercisePeriodId,
    setSelectedOrganizationPostTerminationExercisePeriodId,
  ] = useState<null | string>(
    defaultValues.organizationPostTerminationExercisePeriodId ?? null,
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "organizationPostTerminationExercisePeriodId": {
          const { organizationPostTerminationExercisePeriodId } = value;
          startTransition(() => {
            setSelectedOrganizationPostTerminationExercisePeriodId(
              organizationPostTerminationExercisePeriodId ?? null,
            );
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setSelectedOrganizationPostTerminationExercisePeriodId]);

  const selectedOrganizationPostTerminationExercisePeriod = useMemo(
    () =>
      organization.postTerminationExercisePeriods.find(
        ({ id }) => selectedOrganizationPostTerminationExercisePeriodId === id,
      ) ?? null,
    [
      organization.postTerminationExercisePeriods,
      selectedOrganizationPostTerminationExercisePeriodId,
    ],
  );

  const {
    areCTMSGrantAmendmentRequestParametersUnfavorable: isNewPTEPUnfavorable,
  } = useLazyLoadQuery<NewPTEPSlider_Unfavorable_Query>(UNFAVORABLE_QUERY, {
    attributes: {
      accelerationClauseIsModified: false,
      earlyExerciseIsModified: false,
      postTerminationExercisePeriodId:
        selectedOrganizationPostTerminationExercisePeriodId,
      postTerminationExercisePeriodIsModified: Boolean(
        selectedOrganizationPostTerminationExercisePeriodId,
      ),
    },
    ctmsGrantId: ctmsGrant.id,
  });

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Post-termination exercise period
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <CreateSlideOverRemote.Provider>
        <form onSubmit={onSubmit}>
          <div className="space-y-6 p-6">
            <FormRow
              error={
                formState.errors.organizationPostTerminationExercisePeriodId
                  ?.message
              }
              label={
                ctmsGrant.isVirtual
                  ? "Post-termination settlement period"
                  : "Post-termination exercise period"
              }
            >
              <SelectOrganizationPostTerminationExercisePeriod
                control={control}
                isVirtual={ctmsGrant.isVirtual}
                name="organizationPostTerminationExercisePeriodId"
                organizationFragment={organization}
              />
            </FormRow>
            {isNewPTEPUnfavorable && (
              <NoticeMessage hasColor={false} size="Large" variant="Warning">
                Reducing the post-termination period is usually considered as
                not grantee friendly. The grantee&apos;s acceptation will be
                required.
              </NoticeMessage>
            )}
            {selectedOrganizationPostTerminationExercisePeriod &&
              ctmsGrant.matchingInstrument?.equityType && (
                <GrantWatchouts.PtepGreaterThan90DaysWatchout.UsingPtepFragment
                  equityTypeFragment={ctmsGrant.matchingInstrument.equityType}
                  hasColor={false}
                  postTerminationExercisePeriodFragment={
                    selectedOrganizationPostTerminationExercisePeriod
                  }
                  size="Large"
                />
              )}
          </div>
          <Divider />
          <div className="space-y-4 px-6 py-10">
            <div className="flex items-center justify-end gap-2">
              <Button
                disabled={formState.isSubmitting}
                onClick={onClose}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              <Button
                loading={formState.isSubmitting}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </div>
            <Typography
              as="div"
              className="text-right text-black-05"
              variant="Regular/Caption"
            >
              Amending a grant will always require the approval from the board.
            </Typography>
          </div>
        </form>
      </CreateSlideOverRemote.Provider>
    </SlideOver>
  );
}

export const NewPTEPSliderRemote = makeRemoteController<{
  ctmsGrantFragment: NewPTEPSlider_CTMSGrant$key;
  newOrganizationPostTerminationExercisePeriodId: null | string;
  onNewOrganizationPostTerminationExercisePeriodChanges: (props: {
    organizationPostTerminationExercisePeriodId: string;
  }) => void;
  organizationFragment: NewPTEPSlider_Organization$key;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <NewPTEPSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        newOrganizationPostTerminationExercisePeriodId={
          state.data.newOrganizationPostTerminationExercisePeriodId
        }
        onClose={close}
        onExited={reset}
        onNewOrganizationPostTerminationExercisePeriodChanges={
          state.data.onNewOrganizationPostTerminationExercisePeriodChanges
        }
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
