export const shadowTemplates = ["Success", "Warning", "Danger"].map(
  (callout) => ({
    content: `
    <div class="wysiwygShadowBox">
      <h4>Title</h4>
  
      <div class="wysiwygCallout${callout}">
        All good, nothing required on your side!
      </div>
  
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam
      </p>
    </div>
  `,
    description: "",
    title: `Shadow block - ${callout}`,
  }),
);

export const noticeWithTitleTemplate = {
  content: `
    <div class="wysiwygNotice">
      <h4 class="mb-2">Title</h4>
  
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam
      </p>
    </div>
  `,
  description: "",
  title: "Notice with title",
};
