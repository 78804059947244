import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import { MarkOrganizationAsTerminatedButton_Mutation } from "./__generated__/MarkOrganizationAsTerminatedButton_Mutation.graphql";
import { MarkOrganizationAsTerminatedButton_Organization$key } from "./__generated__/MarkOrganizationAsTerminatedButton_Organization.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const TERMINATE_ORGANIZATION_MUTATION = graphql`
  mutation MarkOrganizationAsTerminatedButton_Mutation(
    $organizationId: OrganizationId!
  ) {
    markOrganizationAsTerminated(id: $organizationId) {
      terminated
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment MarkOrganizationAsTerminatedButton_Organization on Organization {
    id
    name
    terminated
  }
`;

export const MarkOrganizationAsTerminatedButton: React.FC<{
  organizationFragment: MarkOrganizationAsTerminatedButton_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const {
    setFalse: closeTerminateConfirmationModal,
    setTrue: showTerminateConfirmationModal,
    value: terminateConfirmationModalIsShown,
  } = useBoolean(false);

  const [_terminateOrganization, organizationDeletionIsInFlight] =
    useSafeMutation<MarkOrganizationAsTerminatedButton_Mutation>(
      TERMINATE_ORGANIZATION_MUTATION,
    );

  const toaster = useToaster();

  const terminateOrganization = useCallback(async () => {
    await _terminateOrganization({
      variables: { organizationId: organization.id },
    });
    closeTerminateConfirmationModal();
    toaster.push(
      <Toast title="Alright!">
        {organization.name} successfully terminated! 👋
      </Toast>,
    );
  }, [
    _terminateOrganization,
    closeTerminateConfirmationModal,
    organization,
    toaster,
  ]);
  return (
    <>
      <ConfirmationModal
        confirmationLabel="Terminate"
        loading={organizationDeletionIsInFlight}
        onClose={closeTerminateConfirmationModal}
        onConfirmed={terminateOrganization}
        show={terminateConfirmationModalIsShown}
        title="Terminate organization"
      >
        Are you sure you want to terminate {organization.name}?
      </ConfirmationModal>
      <Button
        disabled={organization.terminated}
        onClick={showTerminateConfirmationModal}
        size="small"
        type="button"
        variant="Danger Outline"
      >
        Terminate organization
      </Button>
    </>
  );
};
