export const RemoteGlyph: React.FC<React.ComponentProps<"svg">> = ({
  className,
  ...props
}) => {
  return (
    <svg
      className={className}
      fill="none"
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect fill="#0061FF" height="47.9998" rx="23.9999" width="48" />
      <path
        d="M31.0101 29.2048L31.128 29.2048L31.1961 29.2048L31.1961 33.6L30.4743 33.6C25.787 33.6 23.075 30.9344 23.075 26.5826L23.075 24.3447L24.5981 23.9628C25.0441 23.8502 25.4496 23.6149 25.7682 23.2837C26.0869 22.9525 26.3059 22.5386 26.4005 22.0892C26.495 21.6398 26.4612 21.173 26.303 20.7418C26.1447 20.3107 25.8684 19.9325 25.5054 19.6504C25.1423 19.3683 24.7073 19.1935 24.2496 19.1461C23.792 19.0986 23.3302 19.1803 22.9168 19.3819C22.5034 19.5834 22.155 19.8968 21.9113 20.2863C21.6676 20.6757 21.5383 21.1257 21.5382 21.5848L21.5382 31.1724L16.8 31.1724L16.8 21.4187C16.7997 21.1252 16.8184 20.832 16.8559 20.5409C17.2982 17.0793 20.327 14.4 23.998 14.4C27.3262 14.4 30.1277 16.6032 30.9551 19.5961C31.3852 21.1638 31.2457 22.8327 30.5613 24.3075C29.9813 25.5521 29.0517 26.6017 27.8853 27.329C28.2766 28.9122 29.109 29.2048 30.9551 29.2048"
        fill="white"
      />
    </svg>
  );
};
