import {
  ArrowDownIcon,
  ArrowDownOnSquareStackIcon,
  ArrowUpIcon,
  CalculatorIcon,
  CalendarIcon,
  ClockIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  GlobeEuropeAfricaIcon,
  PencilSquareIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { now } from "lodash";
import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LeftConfetti, RightConfetti } from "../../../components/Confettis";
import { ExerciseRequestDataBox } from "../../../components/ExerciseRequestDataBox";
import { ExerciseRequestTimelineSection } from "../../../components/ExerciseRequestTimelineSection";
import { LongDate } from "../../../components/LongDate";
import { SingleValueProgress } from "../../../components/ui/SingleValueProgress";
import { Typography } from "../../../components/ui/Typography";
import { getEquityTypeWorkRelationship } from "../../../services/workRelationship";
import { ExerciseRequestGranteeSection_CTMSGrant$key } from "./__generated__/ExerciseRequestGranteeSection_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment ExerciseRequestGranteeSection_CTMSGrant on CTMSGrant {
    terminationDate
    exercisableUntilDate
    remainingDaysToExercise
    grantee {
      name
      email
      workRelationship
      taxResidenceCountry {
        name
      }
    }
  }
`;

export const ExerciseRequestGranteeSection: React.FC<{
  ctmsGrantFragment: ExerciseRequestGranteeSection_CTMSGrant$key;
  exerciseFundsReceivedAt: Date | null;
  quantityExercised: number;
  requestSubmittedAt: Date;
  simulated: boolean;
  spread: number;
  totalExercisePrice: number;
}> = ({
  ctmsGrantFragment,
  exerciseFundsReceivedAt,
  quantityExercised,
  requestSubmittedAt,
  simulated,
  spread,
  totalExercisePrice,
}) => {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const { exercisableUntilDate, terminationDate } = useMemo(() => {
    return {
      exercisableUntilDate: ctmsGrant.exercisableUntilDate
        ? new Date(ctmsGrant.exercisableUntilDate)
        : null,
      terminationDate: ctmsGrant.terminationDate
        ? new Date(ctmsGrant.terminationDate)
        : null,
    };
  }, [ctmsGrant]);

  return (
    <ExerciseRequestTimelineSection
      active
      chipContent={<CalculatorIcon className="h-4 w-4" />}
    >
      <div className="space-y-6">
        <div className="flex gap-2">
          <LeftConfetti className="w-6 shrink-0" />
          <Typography variant="Medium/Large">
            {simulated
              ? `${ctmsGrant.grantee.name} exercise request simulation!`
              : `${ctmsGrant.grantee.name} has requested to exercise!`}
          </Typography>
          <RightConfetti className="w-6 shrink-0" />
        </div>
        <div className="space-y-2">
          <ExerciseRequestDataBox>
            <ExerciseRequestDataBox.Item
              icon={<UserIcon />}
              tooltipLabel="Grantee"
            >
              {ctmsGrant.grantee.name}
            </ExerciseRequestDataBox.Item>
            <ExerciseRequestDataBox.Item
              icon={<EnvelopeIcon />}
              tooltipLabel="Email address"
            >
              {ctmsGrant.grantee.email}
            </ExerciseRequestDataBox.Item>
            {ctmsGrant.grantee.workRelationship && (
              <ExerciseRequestDataBox.Item
                icon={<PencilSquareIcon />}
                tooltipLabel="Work relationship"
              >
                {
                  getEquityTypeWorkRelationship(
                    ctmsGrant.grantee.workRelationship,
                  ).label
                }
              </ExerciseRequestDataBox.Item>
            )}
            {ctmsGrant.grantee.taxResidenceCountry && (
              <ExerciseRequestDataBox.Item
                icon={<GlobeEuropeAfricaIcon />}
                tooltipLabel="Tax residence"
              >
                {ctmsGrant.grantee.taxResidenceCountry.name}
              </ExerciseRequestDataBox.Item>
            )}
          </ExerciseRequestDataBox>
          <ExerciseRequestDataBox>
            <ExerciseRequestDataBox.Item
              icon={<DocumentDuplicateIcon />}
              tooltipLabel="Quantity exercised"
            >
              <FormattedNumber value={quantityExercised} />
            </ExerciseRequestDataBox.Item>
            <ExerciseRequestDataBox.Item
              icon={<CurrencyDollarIcon />}
              tooltipLabel="Exercise amount"
            >
              <FormattedNumber
                currency="USD"
                style="currency"
                value={totalExercisePrice}
              />
            </ExerciseRequestDataBox.Item>

            <ExerciseRequestDataBox.Item
              icon={
                spread > 0 ? (
                  <ArrowUpIcon />
                ) : spread < 0 ? (
                  <ArrowDownIcon />
                ) : undefined
              }
              tooltipLabel="Spread amount"
            >
              <FormattedNumber currency="USD" style="currency" value={spread} />
            </ExerciseRequestDataBox.Item>
            <ExerciseRequestDataBox.Item
              icon={<CalendarIcon />}
              tooltipLabel="Exercise request date"
            >
              <LongDate value={requestSubmittedAt} />
            </ExerciseRequestDataBox.Item>
            {exerciseFundsReceivedAt && (
              <ExerciseRequestDataBox.Item
                icon={<ArrowDownOnSquareStackIcon />}
                tooltipLabel="Funds received on"
              >
                <LongDate value={exerciseFundsReceivedAt} />
              </ExerciseRequestDataBox.Item>
            )}
          </ExerciseRequestDataBox>
          {terminationDate &&
            exercisableUntilDate &&
            ctmsGrant.remainingDaysToExercise !== null && (
              <ExerciseRequestDataBox>
                <ExerciseRequestDataBox.Item
                  className="!gap-4"
                  icon={<ClockIcon />}
                  tooltipContent={
                    <>
                      <FormattedNumber
                        style="unit"
                        unit="day"
                        value={ctmsGrant.remainingDaysToExercise}
                      />{" "}
                      left
                    </>
                  }
                  tooltipLabel="Time remaining to exercise"
                >
                  <SingleValueProgress
                    className="h-2 w-[204px]"
                    current={Math.abs(now() - terminationDate.getTime())}
                    max={Math.abs(
                      exercisableUntilDate.getTime() -
                        terminationDate.getTime(),
                    )}
                    variant="primary"
                  />
                  <div>
                    <FormattedNumber
                      style="unit"
                      unit="day"
                      value={ctmsGrant.remainingDaysToExercise}
                    />{" "}
                    left
                  </div>
                </ExerciseRequestDataBox.Item>
              </ExerciseRequestDataBox>
            )}
        </div>
      </div>
    </ExerciseRequestTimelineSection>
  );
};
