/**
 * @generated SignedSource<<67fa581b1884c032b2b73c86d672b9e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationPlan = "freeTrial" | "freemiumDemoBooked" | "freemiumDemoToBook" | "fullAccess";
export type RoleSelectionPage_Query$variables = Record<PropertyKey, never>;
export type RoleSelectionPage_Query$data = {
  readonly me: {
    readonly boardMembers: ReadonlyArray<{
      readonly id: string;
      readonly organization: {
        readonly name: string;
        readonly plan: OrganizationPlan;
        readonly planIsFreemium: boolean;
        readonly terminated: boolean;
      };
      readonly pendingSignatureRequestsCount: number;
    }>;
    readonly email: string;
    readonly equityViewers: ReadonlyArray<{
      readonly id: string;
      readonly organization: {
        readonly id: string;
        readonly name: string;
        readonly plan: OrganizationPlan;
        readonly planIsFreemium: boolean;
        readonly terminated: boolean;
      };
    }>;
    readonly grantees: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly organization: {
        readonly name: string;
        readonly plan: OrganizationPlan;
        readonly planIsFreemium: boolean;
        readonly terminated: boolean;
      };
    }>;
    readonly isAllowedToCreateSandboxOrganization: boolean;
    readonly isSuperAdmin: boolean;
    readonly organizationsAsAdmin: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly plan: OrganizationPlan;
      readonly planIsFreemium: boolean;
      readonly terminated: boolean;
    }>;
  };
};
export type RoleSelectionPage_Query = {
  response: RoleSelectionPage_Query$data;
  variables: RoleSelectionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "planIsFreemium",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "plan",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminated",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organizationsAsAdmin",
  "plural": true,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pendingSignatureRequestsCount",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "EquityViewer",
  "kind": "LinkedField",
  "name": "equityViewers",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSuperAdmin",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAllowedToCreateSandboxOrganization",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    (v1/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleSelectionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RoleSelectionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v8/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0a7b43bc697b11e5bf41c75d6e62c8a8",
    "id": null,
    "metadata": {},
    "name": "RoleSelectionPage_Query",
    "operationKind": "query",
    "text": "query RoleSelectionPage_Query {\n  me {\n    email\n    organizationsAsAdmin {\n      id\n      name\n      planIsFreemium\n      plan\n      terminated\n    }\n    boardMembers {\n      id\n      pendingSignatureRequestsCount\n      organization {\n        name\n        planIsFreemium\n        plan\n        terminated\n        id\n      }\n    }\n    grantees {\n      id\n      name\n      organization {\n        name\n        planIsFreemium\n        plan\n        terminated\n        id\n      }\n    }\n    equityViewers {\n      id\n      organization {\n        id\n        name\n        planIsFreemium\n        plan\n        terminated\n      }\n    }\n    isSuperAdmin\n    isAllowedToCreateSandboxOrganization\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd206d9c38f7ef4271957284edc47506";

export default node;
