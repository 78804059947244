import React, { useCallback, useContext, useEffect, useMemo } from "react";

import { DocumentTemplateType } from "../pages/Admin/Documents/useGrantDocuments";
import { SafeSimulatorContextReducerAction } from "../pages/SafeSimulator/Context";
import { analytics } from "../services/analytics";
import { Account } from "../services/AuthenticationProvider";
import {
  Safe,
  SafeWithoutId,
  SimulatedGrant,
} from "../services/SafeCalculatorService";

const AnalyticsContext = React.createContext<{ organizationId?: string }>({});

export const AnalyticsContextProvider: React.FC<{
  children: React.ReactNode;
  organizationId: null | string;
}> = ({ children, organizationId }) => {
  const value = useMemo(
    () => ({ organizationId: organizationId ?? undefined }),
    [organizationId],
  );
  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalyticsContext = () => useContext(AnalyticsContext);

export const useTrackEvent = () => {
  const analyticsContext = useAnalyticsContext();
  return useCallback(
    (...props: Parameters<(typeof analytics)["track"]>) => {
      const [eventName, eventProperties] = props;
      const context = analyticsContext.organizationId
        ? { groupId: analyticsContext.organizationId }
        : {};
      void analytics.track(eventName, eventProperties, {
        context,
      });
    },
    [analyticsContext],
  );
};

export const useIdentifyAccountInAnalytics = (account: Account | null) => {
  const trackEvent = useTrackEvent();
  useEffect(() => {
    if (account) {
      void analytics.identify(account.id, () => {
        trackEvent("Log In");
      });
    } else {
      void analytics.reset();
    }
  }, [account, trackEvent]);
};

export const useTrackDraftGrantMethodSelected = () => {
  const trackEvent = useTrackEvent();
  return useCallback(
    (grantMethod: "batch" | "beginner" | "expert") =>
      trackEvent("Grant Draft Method Selected", {
        grantMethod,
      }),
    [trackEvent],
  );
};

export const useTrackDocumentsDownloaded = () => {
  const trackEvent = useTrackEvent();
  return useCallback(
    ({
      documentsTypes,
      downloadType,
    }: {
      documentsTypes: Array<DocumentTemplateType>;
      downloadType: "MULTIPLE" | "UNIQUE";
    }) =>
      trackEvent("Document Downloaded", {
        documentsTypes,
        downloadType,
      }),
    [trackEvent],
  );
};

const SAFE_TYPE_TO_LABEL: Record<Safe["type"], string> = {
  POST_MONEY: "Post Money",
  POST_MONEY_MFN: "Post Money MFN",
  PRE_MONEY: "Pre Money",
};

const useTrackNotLoggedSafeSimulatorSafeEvent = () => {
  const trackEvent = useTrackEvent();
  return (event: string, safe: Safe | SafeWithoutId) => {
    switch (safe.type) {
      case "POST_MONEY":
      case "PRE_MONEY": {
        trackEvent(event, {
          last_issuance_date: safe.lastIssuanceDate,
          total_amount_invested: safe.totalAmountInvestedInUSD,
          valuation_cap: safe.valuationCapInUSD,
        });
        break;
      }
      case "POST_MONEY_MFN": {
        trackEvent(event, {
          last_issuance_date: safe.lastIssuanceDate,
          mfn_date: safe.mfnDate,
          total_amount_invested: safe.totalAmountInvestedInUSD,
          valuation_cap: safe.valuationCapInUSD,
        });
        break;
      }
    }
  };
};

export const useTrackSafeSimulatorGrantSimulationEvent = () => {
  const trackEvent = useTrackEvent();

  return (
    event: string,
    simulatedGrant: Omit<SimulatedGrant, "id"> | SimulatedGrant,
  ) => {
    trackEvent(event, {
      pre_post_conversion:
        simulatedGrant.type === "PRE_MONEY"
          ? "Pre conversion"
          : "Post conversion",
      shares_granted: simulatedGrant.shares,
    });
  };
};

export const useTrackSafeSimulatorEvent = ({
  loggedIn,
}: {
  loggedIn: boolean;
}) => {
  const trackEvent = useTrackEvent();
  const trackSafeEvent = useTrackNotLoggedSafeSimulatorSafeEvent();
  const trackGrantSimulationEvent = useTrackSafeSimulatorGrantSimulationEvent();
  const formattedLoggedIn = loggedIn ? "Logged" : "Not logged";

  return ({ action }: { action: SafeSimulatorContextReducerAction }) => {
    switch (action.type) {
      case "ADD_SAFE": {
        trackSafeEvent(
          `SAFE converter - ${formattedLoggedIn} - ${
            SAFE_TYPE_TO_LABEL[action.safe.type]
          } Safe Added`,
          action.safe,
        );
        break;
      }
      case "ADD_SIMULATED_GRANT": {
        trackGrantSimulationEvent(
          `SAFE converter - ${formattedLoggedIn} - Grant Simulated`,
          action.simulatedGrant,
        );
        break;
      }
      case "DELETE_SAFE": {
        trackEvent(`SAFE converter - ${formattedLoggedIn} - SAFE deleted`, {
          safe_type: SAFE_TYPE_TO_LABEL[action.safe.type],
        });
        break;
      }
      case "DELETE_SIMULATED_GRANT":
      case "DISCARD_CHANGES":
      case "EDIT_SIMULATED_GRANT":
      case "SET_SAFE_AS_CURRENT_VALUATION": {
        break;
      }
      case "EDIT_SAFE": {
        trackSafeEvent(
          `SAFE converter - ${formattedLoggedIn} - ${
            SAFE_TYPE_TO_LABEL[action.safe.type]
          } Safe Edited`,
          action.safe,
        );
        break;
      }
      case "RESET": {
        trackEvent(`SAFE converter - ${formattedLoggedIn} - Simulation reset`);
        break;
      }
      case "SET_SHARES": {
        break;
      }
    }
  };
};
