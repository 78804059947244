import React, { useCallback, useContext, useMemo } from "react";

import { ConfirmationModal } from "../../components/ui/ConfirmationModal";
import { useBoolean } from "../../hooks/useBoolean";
import {
  Safe,
  SafeCalculatorService,
  SafeCalculatorStateComputedValues,
  SafeCalculatorStateStaticValues,
  SafeWithoutId,
  SimulatedGrant,
} from "../../services/SafeCalculatorService";

interface SafeSimulatorContext {
  computedValues: SafeCalculatorStateComputedValues;
  onInitialSharesUpdated: (props: {
    optionPoolShares?: number;
    preConversionFullyDilutedShares: number;
  }) => Promise<void>;
  onSafeAdded: (safe: SafeWithoutId) => Promise<void>;
  onSafeDeleted: (safe: Safe) => Promise<void>;
  onSafeEdited: (safe: Safe) => Promise<void>;
  onSafeSetAsCurrentValuation: (safe: Safe) => Promise<void>;
  onSimulatedGrantAdded: (
    simulatedGrant: Omit<SimulatedGrant, "id">,
  ) => Promise<void>;
  onSimulatedGrantDeleted: (simulatedGrant: SimulatedGrant) => Promise<void>;
  onSimulatedGrantEdited: (simulatedGrant: SimulatedGrant) => Promise<void>;
  reset: () => void;
  state: SafeCalculatorStateStaticValues;
}

const Context = React.createContext<null | SafeSimulatorContext>(null);

export const ISafeSimulatorContextProvider: React.FC<{
  children: React.ReactNode;
  onInitialSharesUpdated: (props: {
    optionPoolShares?: number;
    preConversionFullyDilutedShares: number;
  }) => Promise<void>;
  onReset: () => void;
  onSafeAdded: (safe: SafeWithoutId) => Promise<void>;
  onSafeDeleted: (safe: Safe) => Promise<void>;
  onSafeEdited: (safe: Safe) => Promise<void>;
  onSafeSetAsCurrentValuation: (safe: Safe) => Promise<void>;
  onSimulatedGrantAdded: (
    simulatedGrant: Omit<SimulatedGrant, "id">,
  ) => Promise<void>;
  onSimulatedGrantDeleted: (simulatedGrant: SimulatedGrant) => Promise<void>;
  onSimulatedGrantEdited: (simulatedGrant: SimulatedGrant) => Promise<void>;
  state: SafeCalculatorStateStaticValues;
}> = ({
  children,
  onInitialSharesUpdated,
  onReset,
  onSafeAdded,
  onSafeDeleted,
  onSafeEdited,
  onSafeSetAsCurrentValuation,
  onSimulatedGrantAdded,
  onSimulatedGrantDeleted,
  onSimulatedGrantEdited,
  state,
}) => {
  const computedValues = useMemo(
    () => SafeCalculatorService.getComputedValueFromState(state),
    [state],
  );

  const {
    setFalse: closeResetConfirmationModal,
    setTrue: openResetConfirmationModal,
    value: resetConfirmationModalIsShown,
  } = useBoolean();

  const onResetConfirmed = useCallback(() => {
    onReset();
    closeResetConfirmationModal();
  }, [onReset, closeResetConfirmationModal]);

  return (
    <Context.Provider
      value={{
        computedValues,
        onInitialSharesUpdated,
        onSafeAdded,
        onSafeDeleted,
        onSafeEdited,
        onSafeSetAsCurrentValuation,
        onSimulatedGrantAdded,
        onSimulatedGrantDeleted,
        onSimulatedGrantEdited,
        reset: openResetConfirmationModal,
        state,
      }}
    >
      <ConfirmationModal
        confirmationLabel="Confirm"
        onClose={closeResetConfirmationModal}
        onConfirmed={onResetConfirmed}
        show={resetConfirmationModalIsShown}
        title="Are you sure you want to reset the converter?"
      >
        This will remove all input data from the converter
      </ConfirmationModal>
      {children}
    </Context.Provider>
  );
};

export const useSafeSimulatorContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get safe simulator context");
  }

  return context;
};
