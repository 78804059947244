import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GranteeGrantDetails_CTMSGrant$key } from "./__generated__/GranteeGrantDetails_CTMSGrant.graphql";
import { GranteeGrantDetails_Organization$key } from "./__generated__/GranteeGrantDetails_Organization.graphql";
import { CtmsGrantStatusTag } from "./CtmsGrantStatusTag";
import { MissingCTMSInformationTag } from "./MissingInformationTag";
import { ShortDate } from "./ShortDate";
import { InformationRows } from "./ui/InformationRows";
import { Typography } from "./ui/Typography";
import { vestingGraphColors } from "./VestingGraph";
import { VestingOverview } from "./VestingOverview";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeGrantDetails_Organization on Organization {
    ...MissingInformationTag_MissingCTMSInformationTag_Organization
  }
`;

const CTMS_GRANT_FRAGMENT = graphql`
  fragment GranteeGrantDetails_CTMSGrant on CTMSGrant {
    label
    vestingScheduleName
    vestingStartDate
    ...VestingOverview_CTMSGrant
    ...CtmsGrantStatusTag_CtmsGrant
  }
`;

export const GranteeGrantDetails: React.FC<{
  ctmsGrantFragment: GranteeGrantDetails_CTMSGrant$key;
  grantIndex: number;
  organizationFragment: GranteeGrantDetails_Organization$key;
}> = ({ ctmsGrantFragment, grantIndex, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  return (
    <>
      <div className="flex items-center gap-2">
        <div
          className={classNames(
            "h-2.5 w-2.5 rounded-sm",
            vestingGraphColors(grantIndex)?.backgroundColor,
          )}
        ></div>
        <Typography as="div" variant="Medium/Extra Small">
          {ctmsGrant.label}
        </Typography>
      </div>
      <InformationRows>
        <InformationRows.Row label="Vesting status">
          <CtmsGrantStatusTag ctmsGrantFragment={ctmsGrant} />
        </InformationRows.Row>
        <InformationRows.Row label="Vesting overview">
          <VestingOverview ctmsGrantFragment={ctmsGrant} />
        </InformationRows.Row>
        <InformationRows.Row label="Vesting schedule">
          {ctmsGrant.vestingScheduleName ? (
            ctmsGrant.vestingScheduleName
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
        <InformationRows.Row label="Start date">
          {ctmsGrant.vestingStartDate ? (
            <ShortDate value={ctmsGrant.vestingStartDate} />
          ) : (
            <MissingCTMSInformationTag organizationFragment={organization} />
          )}
        </InformationRows.Row>
      </InformationRows>
    </>
  );
};
