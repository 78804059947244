export const LeftConfetti: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    fill="none"
    viewBox="0 0 27 30"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.8653 16.3944C8.58878 16.3944 5.93262 13.7253 5.93262 10.4329"
      stroke="#4EADB9"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M-8.21948e-05 4.96813C3.27646 4.96813 5.93262 7.63721 5.93262 10.9297"
      stroke="#4EADB9"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M20.4096 3.97438C23.497 4.07982 25.9148 6.68031 25.8098 9.78271"
      stroke="#583DDD"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M18.9214 22.8526C18.9214 26.2477 16.1824 29 12.8037 29"
      stroke="#F8D14A"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <ellipse
      cx="5.93263"
      cy="0.993593"
      fill="#2A55E1"
      rx="0.988783"
      ry="0.993593"
    />
  </svg>
);

export const RightConfetti: React.FC<React.ComponentProps<"svg">> = (props) => (
  <svg
    fill="none"
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.34654e-05 11.4152C3.00118 11.4152 5.43408 8.98233 5.43408 5.9812"
      stroke="#583DDD"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M10.8681 1.00005C7.86698 1.00005 5.43408 3.43295 5.43408 6.43408"
      stroke="#583DDD"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M16.3254 11.4151C19.1533 11.5112 21.3678 13.8816 21.2717 16.7095"
      stroke="#4EADB9"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M10.2831 16.3963C10.2831 19.491 7.77438 21.9998 4.67969 21.9998"
      stroke="#2A55E1"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <circle cx="16.3022" cy="7.33951" fill="#F8D14A" r="0.905671" />
  </svg>
);
