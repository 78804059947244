/**
 * @generated SignedSource<<705dd55280dde32b1434936588469b7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsAdmins_Account$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Item_Account" | "RemoteCompanyAdminsList_Account">;
  readonly " $fragmentType": "OrganizationSettingsAdmins_Account";
};
export type OrganizationSettingsAdmins_Account$key = {
  readonly " $data"?: OrganizationSettingsAdmins_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrganizationSettingsAdmins_Account",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoteCompanyAdminsList_Account"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationSettingsAdmins_Item_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "54be036b1013e237fde24d8dc55f5749";

export default node;
