import classNames from "classnames";
import React from "react";

import { RoundedBox } from "./ui/RoundedBox";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

const _DataBoxItem: React.FC<{
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactElement<React.ComponentProps<"div">>;
  tooltipContent?: React.ReactNode;
  tooltipLabel: React.ReactNode;
}> = ({ children, className, icon, tooltipContent, tooltipLabel }) => {
  return (
    <TooltipContainer
      defaultStyle={false}
      tooltipContent={
        <RoundedBox className="flex items-center gap-1 !bg-black-07 p-2 text-white">
          {icon &&
            React.cloneElement(icon, {
              className: classNames(
                /* tailwind */ `h-4 w-4 shrink-0 text-gray-09`,
                icon.props.className,
              ),
            })}
          <Typography className="flex flex-col" variant="Regular/Extra Small">
            <span className="text-gray-07">{tooltipLabel}</span>
            <span>{tooltipContent ?? children}</span>
          </Typography>
        </RoundedBox>
      }
    >
      <Typography
        as="div"
        className={classNames("flex items-center gap-1", className)}
        variant="Regular/Extra Small"
      >
        {icon &&
          React.cloneElement(icon, {
            className: classNames(
              /* tailwind */ `h-4 w-4 shrink-0 text-gray-09`,
              icon.props.className,
            ),
          })}
        {children}
      </Typography>
    </TooltipContainer>
  );
};

const _DataBox: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <RoundedBox className="flex gap-6 px-4 py-2" withBorder>
      {children}
    </RoundedBox>
  );
};

export const ExerciseRequestDataBox = Object.assign(_DataBox, {
  Item: _DataBoxItem,
});
