/**
 * @generated SignedSource<<1f873a0593e1c669e25ed0788babaf06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ValuationType = "FMV_409A" | "UK_VALUATION";
import { FragmentRefs } from "relay-runtime";
export type QuestionCards_FairMarketValue_InstrumentValuation$data = {
  readonly type: ValuationType;
  readonly valueInDollars: number | null;
  readonly " $fragmentType": "QuestionCards_FairMarketValue_InstrumentValuation";
};
export type QuestionCards_FairMarketValue_InstrumentValuation$key = {
  readonly " $data"?: QuestionCards_FairMarketValue_InstrumentValuation$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionCards_FairMarketValue_InstrumentValuation">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionCards_FairMarketValue_InstrumentValuation",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueInDollars",
      "storageKey": null
    }
  ],
  "type": "InstrumentValuation",
  "abstractKey": null
};

(node as any).hash = "8e2267d0e77acd3f9096e2166dcf9856";

export default node;
