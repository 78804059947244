/**
 * @generated SignedSource<<4bd726e56d4859bacd9c9952bfc609ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FairMarketValueHistoryCard_Organization$data = {
  readonly latestFairMarketValue: {
    readonly boardDetermined: boolean;
    readonly tag: string | null;
    readonly value: number;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly " $fragmentType": "FairMarketValueHistoryCard_Organization";
};
export type FairMarketValueHistoryCard_Organization$key = {
  readonly " $data"?: FairMarketValueHistoryCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueHistoryCard_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FairMarketValueHistoryCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "boardDetermined",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tag",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "af7d4795f3dbb9208c112224456ab5f9";

export default node;
