export function formatCurrency(
  amount: number,
  options: Intl.NumberFormatOptions = {},
) {
  return new Intl.NumberFormat("en-US", {
    ...options,
    currency: "USD",
    style: "currency",
  }).format(amount);
}

export function formatNumber(
  amount: number,
  options: Intl.NumberFormatOptions = {},
) {
  return new Intl.NumberFormat("en-US", options).format(amount);
}

export function formatPercentage(percentage: number) {
  return new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    style: "percent",
  }).format(percentage);
}
