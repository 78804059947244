/**
 * @generated SignedSource<<b93cf70936dcc9ad0667d4ef12330bf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesParametersPerWorkRelationshipEditionBlock_EquityType$data = {
  readonly exerciseParameters: {
    readonly contractor: {
      readonly benefits: ReadonlyArray<{
        readonly content: string;
      }>;
      readonly ctmsTaxRateInPercent: number | null;
      readonly taxRanges: ReadonlyArray<{
        readonly description: string | null;
        readonly percentage: number;
      }>;
      readonly watchout: string | null;
    } | null;
    readonly directEmployee: {
      readonly benefits: ReadonlyArray<{
        readonly content: string;
      }>;
      readonly ctmsTaxRateInPercent: number | null;
      readonly taxRanges: ReadonlyArray<{
        readonly description: string | null;
        readonly percentage: number;
      }>;
      readonly watchout: string | null;
    } | null;
    readonly eoREmployee: {
      readonly benefits: ReadonlyArray<{
        readonly content: string;
      }>;
      readonly ctmsTaxRateInPercent: number | null;
      readonly taxRanges: ReadonlyArray<{
        readonly description: string | null;
        readonly percentage: number;
      }>;
      readonly watchout: string | null;
    } | null;
  };
  readonly id: string;
  readonly " $fragmentType": "ExercisesParametersPerWorkRelationshipEditionBlock_EquityType";
};
export type ExercisesParametersPerWorkRelationshipEditionBlock_EquityType$key = {
  readonly " $data"?: ExercisesParametersPerWorkRelationshipEditionBlock_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesParametersPerWorkRelationshipEditionBlock_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseTaxRange",
    "kind": "LinkedField",
    "name": "taxRanges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "percentage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ExerciseBenefit",
    "kind": "LinkedField",
    "name": "benefits",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "watchout",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "ctmsTaxRateInPercent",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesParametersPerWorkRelationshipEditionBlock_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeExerciseParameters",
      "kind": "LinkedField",
      "name": "exerciseParameters",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "directEmployee",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "eoREmployee",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityTypeExerciseParametersPerWorkRelationship",
          "kind": "LinkedField",
          "name": "contractor",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "26a3d991c24f872ab09f7677412106a9";

export default node;
