/**
 * @generated SignedSource<<2087f9fc26fb627b99f814a4978520ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Context_AssistedGrant_EquityOffer$data = {
  readonly exercisePrice: number | null;
  readonly id: string;
  readonly instrument: {
    readonly id: string;
  } | null;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly shares: number | null;
  readonly vestingSchedule: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"Grantee_EquityOffer">;
  readonly " $fragmentType": "Context_AssistedGrant_EquityOffer";
};
export type Context_AssistedGrant_EquityOffer$key = {
  readonly " $data"?: Context_AssistedGrant_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"Context_AssistedGrant_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Context_AssistedGrant_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Grantee_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "99cca3d83ac3cc03dcf31a59c532c958";

export default node;
