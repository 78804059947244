import classNames from "classnames";

export const ReplyIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    viewBox="0 0 18 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M10.9109 15.2559C10.5855 14.9305 10.5855 14.4028 10.9109 14.0774L14.4883 10.5L8.16683 10.5C4.02469 10.5 0.666828 7.14214 0.666828 3V1.33333C0.666828 0.873096 1.03992 0.5 1.50016 0.5C1.9604 0.5 2.3335 0.873096 2.3335 1.33333V3C2.3335 6.22166 4.94517 8.83333 8.16683 8.83333L14.4883 8.83333L10.9109 5.25592C10.5855 4.93049 10.5855 4.40285 10.9109 4.07741C11.2363 3.75197 11.764 3.75197 12.0894 4.07741L17.0894 9.07741C17.4149 9.40285 17.4149 9.93049 17.0894 10.2559L12.0894 15.2559C11.764 15.5814 11.2363 15.5814 10.9109 15.2559Z"
      fillRule="evenodd"
    />
  </svg>
);
