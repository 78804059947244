import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatISO } from "date-fns";
import { isNil } from "lodash";
import capitalize from "lodash/capitalize";
import React, { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import * as z from "zod";

import { EquityGridLevelTopUpTip } from "../../../components/EquityGridLevelTopUpTip";
import { EsopPoolProjectionGraph } from "../../../components/EsopPoolProjectionGraph";
import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "../../../components/GrantedSharesInput";
import { GranteeDetailsGrantsList } from "../../../components/GranteeDetailsGrantsList";
import { Page } from "../../../components/Page";
import { SharesValue, SharesValueMode } from "../../../components/SharesValue";
import { Button, LinkButton } from "../../../components/ui/Button";
import { Divider } from "../../../components/ui/Divider";
import { Checkbox } from "../../../components/ui/Form/Checkbox";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { TextButton } from "../../../components/ui/TextButton";
import { FadeAndScaleTransition } from "../../../components/ui/Transition";
import { Typography } from "../../../components/ui/Typography";
import { zodExtra } from "../../../helpers/zod-extra";
import { useTrackEvent } from "../../../hooks/useAnalytics";
import { useBoolean } from "../../../hooks/useBoolean";
import { useOrganizationSharesUtil } from "../../../hooks/useOrganizationSharesUtil";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import {
  Shares_Grantee$data,
  Shares_Grantee$key,
} from "./__generated__/Shares_Grantee.graphql";
import { Shares_Instrument$key } from "./__generated__/Shares_Instrument.graphql";
import {
  Shares_Organization$data,
  Shares_Organization$key,
} from "./__generated__/Shares_Organization.graphql";
import { Shares_OwnershipOverviewCard_Organization$key } from "./__generated__/Shares_OwnershipOverviewCard_Organization.graphql";
import { Shares_Query } from "./__generated__/Shares_Query.graphql";
import { useAssistedGrantContext, useIsAssistedGrantVirtual } from "./Context";
import {
  FairMarketValueQuestionCard,
  SarValueTodayQuestionCard,
  ValueTodayQuestionCard,
} from "./QuestionCards";
import { AssistedGrantPageGridLayout, AssistedGrantPageTitle } from "./Shared";

const OWNERSHIP_OVERVIEW_CARD_ORGANIZATION_FRAGMENT = graphql`
  fragment Shares_OwnershipOverviewCard_Organization on Organization {
    poolDraftedSharesBreakdown {
      total
    }
    ...GranteeDetailsGrantsList_Organization
    ...useOrganizationSharesUtil_Organization
    ...SharesValue_Organization
  }
`;

const OwnershipOverviewCard: React.FC<{
  equityMode: SharesValueMode;
  granted: {
    ownership: number;
    shares: number;
    value: number;
  };
  grantee: Shares_Grantee$data;
  onHowDoWeComeToThisPotentialValueTodayQuestionButtonClick: React.ComponentProps<"button">["onClick"];
  organizationFragment: Shares_OwnershipOverviewCard_Organization$key;
}> = ({
  equityMode,
  granted,
  grantee,
  onHowDoWeComeToThisPotentialValueTodayQuestionButtonClick,
  organizationFragment,
}) => {
  const organization = useFragment(
    OWNERSHIP_OVERVIEW_CARD_ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const isGrantVirtual = useIsAssistedGrantVirtual();

  return (
    <RoundedBox withBorder>
      <div className="space-y-4 p-6 text-center">
        <Typography as="div" variant="Medium/Default">
          {isGrantVirtual ? "Ownership (virtual)" : "Ownership"} of{" "}
          {grantee.name}
        </Typography>

        <div className="flex flex-col">
          {grantee.totalGrantedSharesBreakdown.total > 0 && (
            <>
              <GranteeDetailsGrantsList
                equityMode={equityMode}
                granteeFragment={grantee}
                openInNewTab
                organizationFragment={organization}
                spaceBetween={6}
              />
              <Divider />
            </>
          )}
          <div className="flex items-center justify-between gap-6 py-[19px]">
            <div className="flex items-center gap-6">
              <Typography
                className="flex h-6 w-6 shrink-0 items-center justify-center rounded bg-gray-02"
                variant="Regular/Extra Small"
              >
                {grantee.ctmsGrantsCount + 1}
              </Typography>
              <Typography variant="Medium/Small">Draft</Typography>
            </div>
            <SharesValue
              appendSharesLabel
              mode={equityMode}
              organizationFragment={organization}
              shares={granted.shares}
              variant="Medium/Small"
            />
          </div>
          <Divider />
          <div className="flex items-center justify-between gap-6 py-[19px]">
            <Typography variant="Medium/Small">
              Total projected ownership
            </Typography>
            <SharesValue
              appendSharesLabel
              className="text-primary"
              mode={equityMode}
              organizationFragment={organization}
              shares={
                granted.shares + grantee.totalGrantedSharesBreakdown.total
              }
              variant="Medium/Small"
            />
          </div>
        </div>
      </div>
      <Divider />
      <Typography
        as="div"
        className="cursor-pointer p-4 text-center text-primary"
        onClick={onHowDoWeComeToThisPotentialValueTodayQuestionButtonClick}
        variant="Medium/Extra Small"
      >
        How do we come to this potential value today?
      </Typography>
    </RoundedBox>
  );
};

const makeSchema = ({
  instrumentValuationValue,
  maximumGrantedQuantity,
}: {
  instrumentValuationValue: null | number;
  maximumGrantedQuantity: number;
}) =>
  z
    .object({
      exercisePrice: zodExtra.preprocessNaNToUndefined(z.number().positive()),
      exercisePriceBelowFMVSetOn: z
        .string()
        .trim()
        .optional()
        .nullable()
        .default(null),
      quantityGranted: zodExtra.preprocessNaNToUndefined(
        z.number().int().positive().lte(maximumGrantedQuantity),
      ),
    })
    .refine(
      ({ exercisePrice, exercisePriceBelowFMVSetOn }) => {
        if (!instrumentValuationValue) {
          return true;
        }
        return (
          (!exercisePriceBelowFMVSetOn &&
            instrumentValuationValue <= exercisePrice) ||
          (!!exercisePriceBelowFMVSetOn &&
            instrumentValuationValue > exercisePrice)
        );
      },
      {
        message:
          "You need to confirm that you want to lower the exercise price",
        path: ["exercisePrice"],
      },
    );

const PoolProjectionCard: React.FC<{
  currendDraftSharesQuantity?: number;
  organization: Shares_Organization$data;
}> = ({ currendDraftSharesQuantity = 0, organization }) => {
  return (
    <RoundedBox className="space-y-4 p-6" withBorder>
      <Typography as="div" className="text-center" variant="Medium/Default">
        Pool projection
      </Typography>
      <EsopPoolProjectionGraph
        currendDraftSharesQuantity={currendDraftSharesQuantity}
        organizationFragment={organization}
      />
    </RoundedBox>
  );
};

export type AssistedGrantSelectSharesFormInputs = z.infer<
  ReturnType<typeof makeSchema>
>;

const ORGANIZATION_FRAGMENT = graphql`
  fragment Shares_Organization on Organization {
    id
    equityGrid {
      activated
    }
    poolAvailableShares
    poolDraftedSharesBreakdown {
      total
    }
    fullyDilutedShares
    latestPricePerShare {
      value
    }
    ...Shares_OwnershipOverviewCard_Organization
    ...QuestionCards_ValueToday_Organization
    ...useOrganizationSharesUtil_Organization
    ...EsopPoolProjectionGraph_Organization
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment Shares_Grantee on Grantee {
    name
    totalGrantedSharesBreakdown {
      total
    }
    ctmsGrantsCount
    ...EquityGridLevelTopUpTip_Grantee
    ...GranteeDetailsGrantsList_Grantee
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment Shares_Instrument on Instrument {
    valuation(organizationId: $organizationId) {
      type
      valueInDollars
      ...QuestionCards_FairMarketValue_InstrumentValuation
    }
  }
`;

const AdminAssistedGrantSharesPage_: React.FC<{
  granteeFragment: Shares_Grantee$key;
  instrumentFragment: Shares_Instrument$key;
  organizationFragment: Shares_Organization$key;
}> = ({ granteeFragment, instrumentFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const intl = useIntl();
  const context = useAssistedGrantContext();
  const navigate = useNavigate();
  const trackEvent = useTrackEvent();
  const isGrantVirtual = useIsAssistedGrantVirtual();
  const exercisePriceLabel = `${
    isGrantVirtual ? "virtual " : ""
  }exercise price`;

  const availableForNewDraft = useMemo(
    () =>
      organization.poolAvailableShares -
      organization.poolDraftedSharesBreakdown.total,
    [
      organization.poolAvailableShares,
      organization.poolDraftedSharesBreakdown.total,
    ],
  );

  const schema = useMemo(() => {
    return makeSchema({
      instrumentValuationValue: instrument.valuation.valueInDollars,
      maximumGrantedQuantity: availableForNewDraft,
    });
  }, [availableForNewDraft, instrument.valuation.valueInDollars]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      exercisePrice: instrument.valuation.valueInDollars,
      ...context.state,
    },
    resolver: zodResolver(schema),
  });

  const quantityGranted = watch("quantityGranted");
  const exercisePrice = watch("exercisePrice");

  const { sharesToFullyDilutedRatio, sharesToValue } =
    useOrganizationSharesUtil({
      organizationFragment: organization,
    });

  const onSubmit = handleSubmit((_data) => {
    const data = _data as AssistedGrantSelectSharesFormInputs;
    context.dispatchAction({
      type: "SET_SHARES",
      ...data,
      exercisePrice: data.exercisePrice,
      exercisePriceEqualsOrganizationValuation:
        data.exercisePrice === instrument.valuation.valueInDollars,
      ownershipGranted: sharesToFullyDilutedRatio(data.quantityGranted),
      sharesInputMode: grantedSharesController.mode,
      valueGranted: sharesToValue(data.quantityGranted),
    });
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationAssistedGrantVesting, {
        organizationId: organization.id,
      }),
    );
  });
  const {
    setFalse: hideValueTodayQuestionCard,
    setTrue: showValueTodayQuestionCard,
    value: valueTodayQuestionCardIsShown,
  } = useBoolean(false);
  const {
    setFalse: hideFairMarketValueQuestionCard,
    setTrue: showFairMarketValueQuestionCard,
    value: fairMarketValueQuestionCardIsShown,
  } = useBoolean(false);

  const showEquityGridFeature = organization.equityGrid.activated;

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares: organization.fullyDilutedShares,
    initialState: {
      mode: context.isParameterImmutable("quantityGranted")
        ? "SHARES"
        : "FULLY_DILUTED",
      shares: context.state.quantityGranted,
    },
    latestPricePerShare: organization.latestPricePerShare?.value ?? null,
    maximumShares: availableForNewDraft,
  });

  useEffect(() => {
    if (grantedSharesController.shares === null) {
      resetField("quantityGranted");

      return;
    }

    setValue("quantityGranted", grantedSharesController.shares);
  }, [setValue, grantedSharesController.shares, resetField]);

  const exercisePriceLowerThanValuationMessage = useMemo(() => {
    switch (instrument.valuation.type) {
      case "FMV_409A":
        return (
          <>
            I acknowledge that I am aware of the risks and adverse tax
            consequences that can arise as a result of opting for{" "}
            {isGrantVirtual ? "a " : "an "}
            {exercisePriceLabel} which is lower than the fair market value of
            the company’s shares as at the time of grant of the options
          </>
        );
      case "UK_VALUATION":
        return (
          <>
            I acknowledge that I am aware of the risks and adverse tax
            consequences that can arise as a result of opting for{" "}
            {isGrantVirtual ? "a " : "an "}
            {exercisePriceLabel} which is lower than the EMI valuation of the
            company’s shares as at the time of grant of the options
          </>
        );
    }
  }, [instrument, exercisePriceLabel, isGrantVirtual]);

  return (
    <form id="assisted-grant-select-grantee" onSubmit={onSubmit}>
      <AssistedGrantPageGridLayout
        aside={
          <div className="flex h-full flex-col space-y-6">
            <FadeAndScaleTransition show={valueTodayQuestionCardIsShown}>
              {isGrantVirtual ? (
                <SarValueTodayQuestionCard
                  onCloseButtonClick={hideValueTodayQuestionCard}
                  organizationFragment={organization}
                />
              ) : (
                <ValueTodayQuestionCard
                  onCloseButtonClick={hideValueTodayQuestionCard}
                  organizationFragment={organization}
                />
              )}
            </FadeAndScaleTransition>
            <FadeAndScaleTransition
              className="mt-auto"
              show={fairMarketValueQuestionCardIsShown}
            >
              <FairMarketValueQuestionCard
                instrumentValuationFragment={instrument.valuation}
                onCloseButtonClick={hideFairMarketValueQuestionCard}
              />
            </FadeAndScaleTransition>
          </div>
        }
        header={
          <AssistedGrantPageTitle>
            How many shares do you want to grant {grantee.name}?
          </AssistedGrantPageTitle>
        }
      >
        <div className="col-start-1 space-y-6">
          <FormRow
            error={errors.quantityGranted?.message}
            label={`Target ownership (${
              isGrantVirtual ? "virtual" : "fully diluted"
            })`}
          >
            <GrantedSharesInput
              isVirtual={isGrantVirtual}
              spacing={2}
              {...grantedSharesController.inputController}
              disabled={context.isParameterImmutable("quantityGranted")}
            />
          </FormRow>
          {showEquityGridFeature &&
            !context.isParameterImmutable("quantityGranted") && (
              <EquityGridLevelTopUpTip
                granteeFragment={grantee}
                onGrantTipClicked={(shares) => {
                  trackEvent(
                    "Grant Draft - Use Equity Grid Value",
                    context.state.analyticsContext,
                  );
                  grantedSharesController.setMode("SHARES");
                  grantedSharesController.setShares(shares);
                }}
              />
            )}
          <OwnershipOverviewCard
            equityMode={grantedSharesController.mode}
            granted={{
              ownership: grantedSharesController.ownership ?? 0,
              shares: grantedSharesController.shares ?? 0,
              value: grantedSharesController.value ?? 0,
            }}
            grantee={grantee}
            onHowDoWeComeToThisPotentialValueTodayQuestionButtonClick={
              showValueTodayQuestionCard
            }
            organizationFragment={organization}
          />

          <PoolProjectionCard
            currendDraftSharesQuantity={grantedSharesController.shares ?? 0}
            organization={organization}
          />

          <Divider />

          <FormRow
            context={
              isGrantVirtual ? (
                <>
                  Example: There’s no real exercise price, it’s the base price
                  used to calculate the cash bonus, e.g. if the virtual exercise
                  price per SAR is 1$, you grant 1,000 SARs, and the shares are
                  sold at the time of an exit at a price of 6$, the grantee will
                  get 5,000$.
                  <br />
                  <TextButton
                    onClick={showFairMarketValueQuestionCard}
                    type="button"
                  >
                    More on the Fair Market Value
                  </TextButton>
                </>
              ) : (
                <>
                  Let’s take a simple example: If the exercise price per share
                  is 1.5$ and you grant stock options for 1,000 shares, your
                  team member will have to pay 1,500$ to exercise their options.
                  <br />
                  <TextButton
                    onClick={showFairMarketValueQuestionCard}
                    type="button"
                  >
                    More on the Fair Market Value
                  </TextButton>
                </>
              )
            }
            error={errors.exercisePrice?.message}
            id="exercise-price"
            label={capitalize(exercisePriceLabel)}
            subLabel={
              isGrantVirtual
                ? "It’s called virtual because your team member won’t have to pay anything"
                : "The exercise price, also called strike price, is the price your team member will need to pay to exercise their stock options and become actual shareholders."
            }
          >
            <Input
              before="$"
              min={0}
              step={Input.FMV_DECIMAL_STEP}
              type="number"
              {...control.register("exercisePrice", {
                valueAsNumber: true,
              })}
              disabled={context.isParameterImmutable("exercisePrice")}
              placeholder={`E.g. ${intl.formatNumber(1.5)}`}
            />
          </FormRow>

          {!isNil(exercisePrice) &&
          !isNil(instrument.valuation.valueInDollars) &&
          exercisePrice < instrument.valuation.valueInDollars ? (
            <div className="mt-4 rounded border-[0.5px] border-orange-05 bg-orange-01 p-4 shadow-100">
              <Typography as="div" variant="Medium/Small">
                Please confirm that you want to lower the {exercisePriceLabel}.
              </Typography>
              <div className="mt-4 flex gap-4">
                <Controller
                  control={control}
                  name="exercisePriceBelowFMVSetOn"
                  render={({ field }) => (
                    <Checkbox
                      checked={!!field.value}
                      onChange={(e) => {
                        field.onChange(
                          e.currentTarget.checked
                            ? formatISO(new Date(), {
                                representation: "date",
                              })
                            : null,
                        );
                      }}
                    />
                  )}
                />

                <Typography as="div" variant="Regular/Extra Small">
                  {exercisePriceLowerThanValuationMessage}
                </Typography>
              </div>
            </div>
          ) : null}
          <div className="flex justify-between">
            <LinkButton
              leftIcon={<ArrowLeftIcon />}
              to={generatePath(
                APPLICATION_ROUTES.organizationAssistedGrantGranteeInstrument,
                {
                  organizationId: organization.id,
                },
              )}
              variant="Tertiary Link"
            >
              Back
            </LinkButton>
            {quantityGranted && exercisePrice ? (
              <Button
                data-cy="sharesNextStepButton"
                rightIcon={<ArrowRightIcon />}
                type="submit"
              >
                Next
              </Button>
            ) : null}
          </div>
        </div>
      </AssistedGrantPageGridLayout>
    </form>
  );
};

const QUERY = graphql`
  query Shares_Query(
    $organizationId: OrganizationId!
    $granteeId: GranteeId!
    $instrumentId: UUID!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...Shares_Organization
    }
    grantee(id: $granteeId) @required(action: THROW) {
      ...Shares_Grantee
    }
    instrument(id: $instrumentId) @required(action: THROW) {
      ...Shares_Instrument
    }
  }
`;

const AdminAssistedGrantSharesPage: React.FC = () => {
  const context = useAssistedGrantContext();
  const organizationId = useOrganizationIdParam();

  const { granteeId, instrumentId } = context.state;
  if (!granteeId) {
    throw new Error("grantee id not found in context");
  }
  if (!instrumentId) {
    throw new Error("instrument id not found in context");
  }

  const {
    query: { grantee, instrument, organization },
  } = useQuery<Shares_Query>(QUERY, {
    granteeId,
    instrumentId,
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Shares"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - shares`}
    >
      <AdminAssistedGrantSharesPage_
        granteeFragment={grantee}
        instrumentFragment={instrument}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminAssistedGrantSharesPage;
