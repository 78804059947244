/**
 * @generated SignedSource<<db1ceb880cfbb690a7cbc25effe346f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation$variables = {
  organizationId: string;
  returnURL: string;
};
export type OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation$data = {
  readonly generateOrganizationDeelAuthorizationFlowURL: string;
};
export type OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation = {
  response: OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation$data;
  variables: OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "returnURL"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      },
      {
        "kind": "Variable",
        "name": "returnURL",
        "variableName": "returnURL"
      }
    ],
    "kind": "ScalarField",
    "name": "generateOrganizationDeelAuthorizationFlowURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4bbf3fa21d72b03225d749eaf69cff62",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsIntegrationDeel_GenerateOrganizationFlowURLMutation(\n  $organizationId: OrganizationId!\n  $returnURL: String!\n) {\n  generateOrganizationDeelAuthorizationFlowURL(organizationId: $organizationId, returnURL: $returnURL)\n}\n"
  }
};
})();

(node as any).hash = "740c900c5298069b9e2b0110a2cf594b";

export default node;
