/**
 * @generated SignedSource<<2351e9f19d312116faa9b9384f559618>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGrantDocuments_EasopGrant$data = {
  readonly awardAgreementDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly countrySpecificAddendumDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly formOfEarlyExerciseStockPurchaseAgreementDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly formOfExerciseAgreementDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly grantNoticeDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly grantee: {
    readonly name: string;
  };
  readonly label: string;
  readonly signedBoardConsentDocument: {
    readonly downloadUrl: string;
    readonly updatedAt: string;
  } | null;
  readonly " $fragmentType": "useGrantDocuments_EasopGrant";
};
export type useGrantDocuments_EasopGrant$key = {
  readonly " $data"?: useGrantDocuments_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGrantDocuments_EasopGrant">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "downloadUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "updatedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGrantDocuments_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "grantNoticeDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "awardAgreementDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "signedBoardConsentDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "countrySpecificAddendumDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "formOfExerciseAgreementDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Document",
      "kind": "LinkedField",
      "name": "formOfEarlyExerciseStockPurchaseAgreementDocument",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};
})();

(node as any).hash = "07ab4ccd31124b3799b78b7f6eff03ac";

export default node;
