import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { first, isNil } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { LoadingPlaceholder } from "../../../components/LoadingPlaceholder";
import { Page } from "../../../components/Page";
import { useCartaIssuerIdSelectionSchema } from "../../../components/SelectCartaIssuerBlock";
import { Button, LinkButton } from "../../../components/ui/Button";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { Input } from "../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { PoweredByCarta } from "../../../components/ui/PoweredByCarta/PoweredByCarta";
import { Typography } from "../../../components/ui/Typography";
import { useTrackEvent } from "../../../hooks/useAnalytics";
import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../hooks/useBoolean";
import { useQuery } from "../../../hooks/useQuery";
import { useSafeMutation } from "../../../hooks/useSafeMutation";
import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import { useStateToken } from "../../../layouts/SetupWorkspaceLayout/useSetupWorkspaceContext";
import { APPLICATION_ROUTES } from "../../../paths";
import { useAuthenticatedAccount } from "../../../services/AuthenticatedGuard";
import { CartaIssuerSelection_SetupWorkspace_Mutation } from "./__generated__/CartaIssuerSelection_SetupWorkspace_Mutation.graphql";
import {
  CartaIssuerSelectionPageQuery,
  CartaIssuerSelectionPageQuery$data,
} from "./__generated__/CartaIssuerSelectionPageQuery.graphql";
import { SetupWorkspacePricePerShareSlideOver } from "./SetupWorkspacePricePerShareSlideOver";

const QUERY = graphql`
  query CartaIssuerSelectionPageQuery($workspaceSetupStateToken: String!) {
    issuers: getCartaIssuersWithSetupIssuerSelectionPropertiesUsingWorkspaceSetupStateToken(
      workspaceSetupStateToken: $workspaceSetupStateToken
    ) {
      cartaIssuer {
        id
        name
      }
      greatestPricePerShareValue
      cannotBeSelectedReason
    }
  }
`;

const SETUP_WORKSPACE_MUTATION = graphql`
  mutation CartaIssuerSelection_SetupWorkspace_Mutation(
    $latestPricePerShareValue: Float!
    $selectedCartaIssuerId: String!
    $workspaceSetupStateToken: String!
  ) {
    setupWorkspaceUsingWorkspaceSetupStateToken(
      latestPricePerShare: $latestPricePerShareValue
      selectedCartaIssuerId: $selectedCartaIssuerId
      workspaceSetupStateToken: $workspaceSetupStateToken
      source: "Remote Equity Web App"
    ) {
      id
    }
  }
`;

type IssuerCannotBeSelectedReason =
  CartaIssuerSelectionPageQuery$data["issuers"][number]["cannotBeSelectedReason"];

const useFormSchema = ({
  issuers,
}: {
  issuers: {
    cannotBeSelectedReason: IssuerCannotBeSelectedReason;
    id: string;
  }[];
}) => {
  const cartaIssuerId = useCartaIssuerIdSelectionSchema({ issuers });

  return z.object({
    cartaIssuerId,
    latestPricePerShareValue: z.coerce.number().positive(),
  });
};

const useSetupWorkspace = () => {
  const [triggerSetupWorkspaceMutation] =
    useSafeMutation<CartaIssuerSelection_SetupWorkspace_Mutation>(
      SETUP_WORKSPACE_MUTATION,
    );

  const navigate = useNavigate();

  return useCallback(
    async function setupWorkspace({
      cartaIssuerId,
      latestPricePerShareValue,
      workspaceSetupStateToken,
    }: {
      cartaIssuerId: string;
      latestPricePerShareValue: number;
      workspaceSetupStateToken: string;
    }) {
      const { setupWorkspaceUsingWorkspaceSetupStateToken: organization } =
        await triggerSetupWorkspaceMutation({
          variables: {
            latestPricePerShareValue,
            selectedCartaIssuerId: cartaIssuerId,
            workspaceSetupStateToken,
          },
        });

      void navigate(
        generatePath(APPLICATION_ROUTES.organizationHome, {
          organizationId: organization.id,
        }),
        {
          replace: true,
        },
      );
    },
    [navigate, triggerSetupWorkspaceMutation],
  );
};

type FormInputs = z.infer<ReturnType<typeof useFormSchema>>;

const CartaIssuerSelectionPageContent: React.FC<{
  query: CartaIssuerSelectionPageQuery$data;
  stateToken: string;
}> = ({ query, stateToken }) => {
  const issuers = useMemo(
    () =>
      query.issuers.map(
        ({
          cannotBeSelectedReason,
          cartaIssuer,
          greatestPricePerShareValue,
        }) => ({
          ...cartaIssuer,
          cannotBeSelectedReason,
          greatestPricePerShareValue,
        }),
      ),
    [query.issuers],
  );

  const firstValidIssuer = issuers.find(
    (issuer) => !issuer.cannotBeSelectedReason,
  );

  const formSchema = useFormSchema({
    issuers,
  });

  const form = useForm({
    defaultValues: {
      cartaIssuerId: firstValidIssuer?.id,
      latestPricePerShareValue: firstValidIssuer?.greatestPricePerShareValue,
    },
    resolver: zodResolver(formSchema),
  });

  const account = useAuthenticatedAccount();

  const setupWorkspace = useSetupWorkspace();

  const trackEvent = useTrackEvent();

  const onSubmit = form.handleSubmit(async (_formData) => {
    const formData = _formData as FormInputs;

    const issuer = issuers.find(
      (issuer) => issuer.id === formData.cartaIssuerId,
    );

    await setupWorkspace({
      cartaIssuerId: formData.cartaIssuerId,
      latestPricePerShareValue: formData.latestPricePerShareValue,
      workspaceSetupStateToken: stateToken,
    });

    if (!issuer?.greatestPricePerShareValue) {
      trackEvent("CTMS Sign Up - PPS Updated", {
        account_id: account.id,
        ctms_orgazation_id: formData.cartaIssuerId,
        pps_updated: formData.latestPricePerShareValue,
      });
    }
  });

  const cartaIssuerId = form.watch("cartaIssuerId");

  const cartaIssuer = useMemo(
    () => issuers.find((issuer) => issuer.id === cartaIssuerId),
    [cartaIssuerId, issuers],
  );

  const applicationName = useApplicationName();

  useEffect(() => {
    if (!cartaIssuer) return;
    form.setValue(
      "latestPricePerShareValue",
      cartaIssuer.greatestPricePerShareValue,
    );
  }, [cartaIssuer, form]);

  const issuersOptions = useMemo(
    () =>
      issuers.map((issuer) => ({
        label: issuer.name,
        value: issuer.id,
      })),
    [issuers],
  );

  const {
    setFalse: hideExplanation,
    setTrue: showExplanation,
    value: isExplanationShown,
  } = useBoolean(false);

  const singleIssuer = issuers.length === 1 ? first(issuers) : null;

  const supportEmailAddress = useApplicationSupportEmailAddress();

  if (
    singleIssuer &&
    singleIssuer.cannotBeSelectedReason === "ISSUER_ALREADY_CONNECTED"
  ) {
    const mailtoSubject = encodeURI(
      `[${singleIssuer.name}] What was the account used to create this company?`,
    );

    return (
      <AuthLayout.Card
        emoji="✋"
        logo={null}
        subtitle={
          <div className="space-y-4">
            <div>
              Looks like an account with the same organization credentials
              already exists...
            </div>
            <div>
              Please contact our{" "}
              <strong>
                <a
                  className="text-primary"
                  href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
                >
                  support team
                </a>
              </strong>{" "}
              for more details.
            </div>
          </div>
        }
        title="This company already exists"
      >
        <LinkButton
          fullWidth
          size="medium"
          to={generatePath(APPLICATION_ROUTES.signUp)}
          variant="Primary Full"
        >
          Set up a new account
        </LinkButton>
      </AuthLayout.Card>
    );
  }

  return (
    <>
      <SetupWorkspacePricePerShareSlideOver
        onClose={hideExplanation}
        show={isExplanationShown}
      />
      <AuthLayout.Card
        emoji="☝️"
        logo={null}
        subtitle="Please provide the following information"
        title="One more thing..."
      >
        <form className="space-y-6" onSubmit={onSubmit}>
          <Controller
            control={form.control}
            name="cartaIssuerId"
            render={({ field, fieldState }) => (
              <FormRow error={fieldState.error?.message} label="Organization">
                <SelectAutocomplete
                  disabled={issuers.length === 1}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(option) => {
                    field.onChange(option?.value);
                  }}
                  options={issuersOptions}
                  value={
                    field.value
                      ? issuersOptions.find(
                          (option) => option.value === field.value,
                        )
                      : null
                  }
                />
              </FormRow>
            )}
          />

          {isNil(cartaIssuer?.greatestPricePerShareValue) && (
            <>
              <FormRow
                className={classNames({
                  "text-black-05": !form.formState.errors,
                })}
                error={form.formState.errors.latestPricePerShareValue?.message}
                label="Price Per Share"
              >
                <Input
                  before="$"
                  min="0"
                  step={Input.FMV_DECIMAL_STEP}
                  type="number"
                  {...form.control.register("latestPricePerShareValue")}
                />
              </FormRow>
              <button
                className="flex items-center gap-2 text-left text-primary"
                onClick={showExplanation}
                type="button"
              >
                <Typography as="div" variant="Medium/Extra Small">
                  Why do we need this price and how to calculate it?
                </Typography>
                <ArrowTopRightOnSquareIcon className="w-5" />
              </button>
            </>
          )}

          <Button
            fullWidth
            loading={form.formState.isSubmitting}
            size="medium"
            type="submit"
            variant="Primary Full"
          >
            Enjoy {applicationName}!
          </Button>
          <PoweredByCarta className="mx-auto" />
        </form>
      </AuthLayout.Card>
    </>
  );
};

const CartaIssuerSelectionPage_: React.FC = () => {
  const stateToken = useStateToken();
  const { query } = useQuery<CartaIssuerSelectionPageQuery>(QUERY, {
    workspaceSetupStateToken: stateToken,
  });

  const setupWorkspace = useSetupWorkspace();

  const firstIssuer = query.issuers[0];

  const isSingleIssuerWithDefinedPricePerShare =
    firstIssuer &&
    query.issuers.length === 1 &&
    !isNil(firstIssuer.greatestPricePerShareValue);

  useEffectOnce(() => {
    if (isSingleIssuerWithDefinedPricePerShare) {
      void setupWorkspace({
        cartaIssuerId: firstIssuer.cartaIssuer.id,
        latestPricePerShareValue: firstIssuer.greatestPricePerShareValue,
        workspaceSetupStateToken: stateToken,
      });
    }
  });

  if (isSingleIssuerWithDefinedPricePerShare) {
    return <LoadingPlaceholder />;
  }

  return (
    <CartaIssuerSelectionPageContent query={query} stateToken={stateToken} />
  );
};

const CartaIssuerSelectionPage: React.FC = () => {
  return (
    <Page
      analyticsCategory="Sign Up"
      analyticsName="Sign Up - Carta issuer selection"
      title="Sign Up - Carta issuer selection"
    >
      <CartaIssuerSelectionPage_ />
    </Page>
  );
};

export default CartaIssuerSelectionPage;
