/**
 * @generated SignedSource<<bf1565d0ec1654cc256a00000b18e35d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestCompletedSection_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "ExerciseRequestCompletedSection_Viewer";
};
export type ExerciseRequestCompletedSection_Viewer$key = {
  readonly " $data"?: ExerciseRequestCompletedSection_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestCompletedSection_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestCompletedSection_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "9e42576a4b1a71db2817d66ae4f7d4fb";

export default node;
