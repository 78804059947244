import React from "react";
import { useFragment } from "react-relay";
import { generatePath, Navigate, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { Typography } from "../../../../components/ui/Typography";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { UpdateInformation_Organization$key } from "./__generated__/UpdateInformation_Organization.graphql";
import { UpdateInformation_Query } from "./__generated__/UpdateInformation_Query.graphql";
import { UpdateInformationItem } from "./UpdateInformationItem";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UpdateInformation_Organization on Organization {
    id
    name
    ...UpdateInformationItem_Organization
    HRISProviderEmployeesSuggestedToUpdateLinkedGrantee {
      id
      ...UpdateInformationItem_HRISProviderEmployee
    }
  }
`;

const UpdateInformation_: React.FC<{
  organizationFragment: UpdateInformation_Organization$key;
  refreshQuery: () => void;
}> = ({ organizationFragment, refreshQuery }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="../..">Settings</BreadCrumb.Link>
          <BreadCrumb.Link to="..">Clean up grantees</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Update information</BreadCrumb.Link>
        </BreadCrumb>
      }
      fullHeight
      showFooter
    >
      <div className="space-y-6">
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Update information
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Stay up-to-date on your grantee’s data
            </Typography>
          </div>
        </div>
        {organization.HRISProviderEmployeesSuggestedToUpdateLinkedGrantee.map(
          (hrisProviderEmployee, index) => (
            <UpdateInformationItem
              hrisProviderEmployeeFragment={hrisProviderEmployee}
              key={hrisProviderEmployee.id}
              listIndex={index}
              listLength={
                organization.HRISProviderEmployeesSuggestedToUpdateLinkedGrantee
                  .length
              }
              onDataMutation={refreshQuery}
              organizationFragment={organization}
            />
          ),
        )}
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query UpdateInformation_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      HRISProviderEmployeesSuggestedToUpdateLinkedGrantee {
        __typename
      }
      ...UpdateInformation_Organization
    }
  }
`;

const UpdateInformationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query, refreshQuery } = useQuery<UpdateInformation_Query>(QUERY, {
    organizationId,
  });
  const [searchParams] = useSearchParams();

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (
    query.organization.HRISProviderEmployeesSuggestedToUpdateLinkedGrantee
      .length === 0
  ) {
    return (
      <Navigate
        replace
        to={{
          pathname: generatePath(
            APPLICATION_ROUTES["organizationSettingsCleanupGrantees"],
            {
              organizationId: query.organization.id,
            },
          ),
          search: searchParams.toString(),
        }}
      />
    );
  }

  return (
    <Page
      analyticsName="Admin - Clean Up - Update Information"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} update information`}
    >
      <UpdateInformation_
        organizationFragment={query.organization}
        refreshQuery={refreshQuery}
      />
    </Page>
  );
};

export default UpdateInformationPage;
