import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { PortalAccessTag_Grantee$key } from "./__generated__/PortalAccessTag_Grantee.graphql";
import { ShortDate } from "./ShortDate";
import { Tag } from "./ui/Tag";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment PortalAccessTag_Grantee on Grantee {
    cannotBeInvitedReason
    hasPortalAccess
    hasVisitedTheirPortal
    lastSeenAt
    firstSeenAt
  }
`;

const _NotInvitedTag = (_: unknown, ref: React.Ref<HTMLElement>) => {
  return (
    <Tag color="dashed" ref={ref}>
      Not invited
    </Tag>
  );
};
const NotInvitedTag = React.forwardRef(_NotInvitedTag);
const NotInvitedTagWithTooltip: React.FC = () => {
  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <NotInvitedTag />
          <Typography as="div" variant="Medium/Caption">
            Not invited
          </Typography>
          <Typography as="div" variant="Regular/Caption">
            Your grantee hasn’t been invited to its portal yet.
          </Typography>
        </div>
      }
    >
      <NotInvitedTag />
    </TooltipContainer>
  );
};

const _InvitedAndVisitedTag = (_: unknown, ref: React.Ref<HTMLElement>) => {
  return (
    <Tag color="green" ref={ref}>
      Invited
    </Tag>
  );
};
const InvitedAndVisitedTag = React.forwardRef(_InvitedAndVisitedTag);
const InvitedAndVisitedTagWithTooltip: React.FC<{
  granteeFragment: PortalAccessTag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const applicationName = useApplicationName();

  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <InvitedAndVisitedTag />
          <Typography as="div" variant="Medium/Caption">
            Invited
          </Typography>
          {grantee.firstSeenAt && (
            <Typography as="div" variant="Regular/Caption">
              <span className="text-gray-07">
                First connection to {applicationName} on
              </span>
              <br />
              <ShortDate value={grantee.firstSeenAt} />
            </Typography>
          )}
          {grantee.lastSeenAt && (
            <Typography as="div" variant="Regular/Caption">
              <span className="text-gray-07">Last seen on</span>
              <br />
              <ShortDate value={grantee.lastSeenAt} />
            </Typography>
          )}
        </div>
      }
    >
      <InvitedAndVisitedTag />
    </TooltipContainer>
  );
};

const _InvitedButNotVisitedTag = (_: unknown, ref: React.Ref<HTMLElement>) => {
  return (
    <Tag color="orange" ref={ref}>
      Pending
    </Tag>
  );
};
const InvitedButNotVisitedTag = React.forwardRef(_InvitedButNotVisitedTag);
const InvitedButNotVisitedTagWithTooltip: React.FC = () => {
  const applicationName = useApplicationName();

  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <InvitedButNotVisitedTag />
          <Typography as="div" variant="Medium/Caption">
            Invited
          </Typography>
          <Typography as="div" variant="Regular/Caption">
            <span className="text-gray-07">
              First connection to {applicationName} on
            </span>
            <br />-
          </Typography>
        </div>
      }
    >
      <InvitedButNotVisitedTag />
    </TooltipContainer>
  );
};

const NotInvitableTagWithTooltip: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <TooltipContainer
      tooltipContent={
        <div className="space-y-2">
          <NotInvitedTag />
          <Typography as="div" variant="Medium/Caption">
            Cannot be invited
          </Typography>
          <Typography as="div" variant="Regular/Caption">
            {children}
          </Typography>
        </div>
      }
    >
      <NotInvitedTag />
    </TooltipContainer>
  );
};

const PortalAccessTagWithToolTip: React.FC<{
  granteeFragment: PortalAccessTag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  if (grantee.hasPortalAccess) {
    if (grantee.hasVisitedTheirPortal) {
      return (
        <InvitedAndVisitedTagWithTooltip granteeFragment={granteeFragment} />
      );
    }
    return <InvitedButNotVisitedTagWithTooltip />;
  }

  if (grantee.cannotBeInvitedReason) {
    switch (grantee.cannotBeInvitedReason) {
      case "ALREADY_INVITED":
        return (
          <NotInvitableTagWithTooltip>
            This grantee has already been invited.
          </NotInvitableTagWithTooltip>
        );
      case "MISSING_INFORMATION":
        return (
          <NotInvitableTagWithTooltip>
            Missing mandatory information prevents the creation of the portal.
          </NotInvitableTagWithTooltip>
        );
      case "NO_GRANTS":
        return (
          <NotInvitableTagWithTooltip>
            Grantees without grants can’t be invited to their portal.
          </NotInvitableTagWithTooltip>
        );
      case "REMOTE_EMPLOYEE":
        return (
          <NotInvitableTagWithTooltip>
            Remote employees can’t be invited to their portal.
          </NotInvitableTagWithTooltip>
        );
      case "SETTLED":
        return (
          <NotInvitableTagWithTooltip>
            Terminated and settled grantees can no longer access their portal.
          </NotInvitableTagWithTooltip>
        );
    }
  }
  return <NotInvitedTagWithTooltip />;
};

export const PortalAccessTag = {
  InvitedAndVisited: InvitedAndVisitedTag,
  InvitedButNotVisited: InvitedButNotVisitedTag,
  NotInvited: NotInvitedTag,
  WithToolTip: PortalAccessTagWithToolTip,
};
