import contractorThumb from "./contractor.svg";
import directEmployeeThumb from "./directEmployee.svg";
import eoREmployeeThumb from "./eoREmployee.svg";

export const WORK_RELATIONSHIP_TYPES = [
  "DirectEmployee",
  "EoREmployee",
  "Advisor",
  "ContractorNaturalPerson",
  "ContractorManagementCompany",
] as const;

export type WorkRelationshipType = (typeof WORK_RELATIONSHIP_TYPES)[number];

export const WORK_RELATIONSHIP_TO_LABEL_HELPER: Record<
  WorkRelationshipType,
  {
    help: string;
    id: WorkRelationshipType;
    pluralLabel: string;
    singularLabel: string;
  }
> = {
  Advisor: {
    help: "i.e. person providing occasional advisory services",
    id: "Advisor",
    pluralLabel: "Advisors",
    singularLabel: "Advisor",
  },
  ContractorManagementCompany: {
    help: "i.e. self-employed working via management company",
    id: "ContractorManagementCompany",
    pluralLabel: "Contractors via management company",
    singularLabel: "Contractor via management company",
  },
  ContractorNaturalPerson: {
    help: "i.e. self-employed individual",
    id: "ContractorNaturalPerson",
    pluralLabel: "Contractors as a natural person",
    singularLabel: "Contractor as a natural person",
  },
  DirectEmployee: {
    help: "i.e. via your own holding or subsidiary",
    id: "DirectEmployee",
    pluralLabel: "Direct employees",
    singularLabel: "Direct employee",
  },
  EoREmployee: {
    help: "i.e. via Remote, Deel, Oyster, etc",
    id: "EoREmployee",
    pluralLabel: "EoR employees",
    singularLabel: "EoR employee",
  },
};

export const WORK_RELATIONSHIPS = Object.values(
  WORK_RELATIONSHIP_TO_LABEL_HELPER,
);

export type EquityTypeWorkRelationshipCategory =
  | "contractor"
  | "directEmployee"
  | "eoREmployee";

export const stringIsEquityTypeWorkRelationshipCategory = (
  string: string,
): string is EquityTypeWorkRelationshipCategory =>
  string === "directEmployee" ||
  string === "eoREmployee" ||
  string === "contractor";

export interface EquityTypeWorkRelationshipData {
  color: string;
  description: (countryName: string) => string;
  emoji: string;
  label: string;
  slug: EquityTypeWorkRelationshipCategory;
  thumbnail: string;
  types: WorkRelationshipType[];
}

const _EQUITY_TYPE_WORK_RELATIONSHIP_MAP: Record<
  EquityTypeWorkRelationshipCategory,
  EquityTypeWorkRelationshipData
> = {
  ["contractor"]: {
    color: /* tailwind */ "bg-purple-05",
    description: (countryName: string) =>
      `Discover what you and the contractor have to do to comply with the local rules and how this equity incentive will likely be taxed in ${countryName}; review template documents to be used for grants to contractors and advisors.`,
    emoji: "👨🏾‍💻",
    label: "Contractor",
    slug: "contractor",
    thumbnail: contractorThumb,
    types: [
      "Advisor",
      "ContractorNaturalPerson",
      "ContractorManagementCompany",
    ],
  },
  ["directEmployee"]: {
    color: /* tailwind */ "bg-primary-05",
    description: (countryName: string) =>
      `Discover what you (including your subsidiary) and the employee have to do to comply with local rules and how this equity incentive will be taxed in ${countryName}, review template documents to be used for grants to employees.`,
    emoji: "🧑🏻‍💼",
    label: "Direct employee",
    slug: "directEmployee",
    thumbnail: directEmployeeThumb,
    types: ["DirectEmployee"],
  },
  ["eoREmployee"]: {
    color: /* tailwind */ "bg-green-05",
    description: (countryName: string) =>
      `Discover what you, the EoR and the employee have to do to comply with local rules and how this equity incentive will be taxed in ${countryName}, review template documents to be used for grants to EoR employees.`,
    emoji: "🧑🏽‍💻",
    label: "EoR employee",
    slug: "eoREmployee",
    thumbnail: eoREmployeeThumb,
    types: ["EoREmployee"],
  },
};

export const EQUITY_TYPE_WORK_RELATIONSHIP_MAP: Record<
  | "Contractor"
  | "DirectEmployee"
  | "EoREmployee"
  | EquityTypeWorkRelationshipCategory,
  EquityTypeWorkRelationshipData
> = {
  ..._EQUITY_TYPE_WORK_RELATIONSHIP_MAP,
  Contractor: _EQUITY_TYPE_WORK_RELATIONSHIP_MAP.contractor,
  DirectEmployee: _EQUITY_TYPE_WORK_RELATIONSHIP_MAP.directEmployee,
  EoREmployee: _EQUITY_TYPE_WORK_RELATIONSHIP_MAP.eoREmployee,
};

export function getEquityTypeWorkRelationship(
  workRelationship: WorkRelationshipType,
) {
  const workRelationshipData = Object.values(
    EQUITY_TYPE_WORK_RELATIONSHIP_MAP,
  ).find((equityTypeWorkRelationshipData) => {
    return equityTypeWorkRelationshipData.types.includes(workRelationship);
  });

  if (!workRelationshipData) {
    throw new Error(`Couldn't find work relationship "${workRelationship}"`);
  }

  return workRelationshipData;
}
