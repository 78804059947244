/**
 * @generated SignedSource<<d5c600dbe73c01ed268d7da04083eeff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalOverviewBeginnerInformation_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteeEquity_Grantee" | "GranteePotentialValue_Grantee" | "useGranteePortalGranteeShares_Grantee">;
  readonly " $fragmentType": "GranteePortalOverviewBeginnerInformation_Grantee";
};
export type GranteePortalOverviewBeginnerInformation_Grantee$key = {
  readonly " $data"?: GranteePortalOverviewBeginnerInformation_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalOverviewBeginnerInformation_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalOverviewBeginnerInformation_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGranteePortalGranteeShares_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteePotentialValue_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeEquity_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "694e9b891b474c9fbcfeae94877dbf2e";

export default node;
