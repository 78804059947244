/**
 * @generated SignedSource<<2baee390f8f0fdb154c8009f0afbbb41>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type CtmsGrantDetailsGrantee_Grantee$data = {
  readonly USStateOfResidence: {
    readonly name: string;
  } | null;
  readonly company: {
    readonly completeName: string;
  } | null;
  readonly contractStartDate: string | null;
  readonly email: string;
  readonly jobTitle: string | null;
  readonly managementCompanyName: string | null;
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeFormSlide_Grantee">;
  readonly " $fragmentType": "CtmsGrantDetailsGrantee_Grantee";
};
export type CtmsGrantDetailsGrantee_Grantee$key = {
  readonly " $data"?: CtmsGrantDetailsGrantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"CtmsGrantDetailsGrantee_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CtmsGrantDetailsGrantee_Grantee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "managementCompanyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completeName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "USState",
      "kind": "LinkedField",
      "name": "USStateOfResidence",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "57dea0f4b29fcca900ec100ab907cfef";

export default node;
