/**
 * @generated SignedSource<<16d4220f0d44e166b3ccaf5d00da8682>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountAttributes = {
  email: string;
  firstName: string;
  lastName: string;
};
export type InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation$variables = {
  attributes: AccountAttributes;
  organizationId: string;
};
export type InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation$data = {
  readonly createAccountAndAddItAsAdmin: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsAdmins_Organization">;
    };
  };
};
export type InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation = {
  response: InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation$data;
  variables: InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsAdmins_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateAccountAndAddItAsRoleResult",
        "kind": "LinkedField",
        "name": "createAccountAndAddItAsAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "adminInvitationIsAllowed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Admin",
                "kind": "LinkedField",
                "name": "admins",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "filters",
                    "value": {
                      "orderBy": {
                        "direction": "ASC",
                        "field": "name"
                      }
                    }
                  }
                ],
                "concreteType": "RemoteCompanyAdmin",
                "kind": "LinkedField",
                "name": "remoteCompanyAdmins",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userSlug",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowedAsAdmin",
                    "storageKey": null
                  }
                ],
                "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de5bba7fbf9adf8f11148d2d227c9ae4",
    "id": null,
    "metadata": {},
    "name": "InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteAdminSlideOver_CreateAccountAndAddItAsAdmin_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: AccountAttributes!\n) {\n  createAccountAndAddItAsAdmin(organizationId: $organizationId, attributes: $attributes, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsAdmins_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsAdmins_Item_Admin on Admin {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsAdmins_Organization on Organization {\n  id\n  name\n  adminInvitationIsAllowed\n  admins {\n    id\n    ...OrganizationSettingsAdmins_Item_Admin\n  }\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    __typename\n    id\n  }\n  ...RemoteCompanyAdminsList_Organization\n}\n\nfragment RemoteCompanyAdminsList_Organization on Organization {\n  id\n  name\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    id\n    userSlug\n    name\n    email\n    allowedAsAdmin\n  }\n}\n"
  }
};
})();

(node as any).hash = "fd6954f46e8bff7ea9697869eebe9bef";

export default node;
