import { useCallback } from "react";
import { useIntl } from "react-intl";

const DAYS_IN_YEAR = 365.25;
const MONTHS_IN_YEAR = 12;
const DAYS_IN_MONTH = DAYS_IN_YEAR / MONTHS_IN_YEAR;

type Duration = {
  duration: number;
  unit: DurationUnit;
};
type DurationUnit = "day" | "month" | "year";

export const durationToYears = ({ duration, unit }: Duration) => {
  switch (unit) {
    case "day":
      return duration / DAYS_IN_YEAR;
    case "month":
      return duration / MONTHS_IN_YEAR;
    case "year":
      return duration;
  }
};

export const durationToMonths = ({ duration, unit }: Duration) => {
  switch (unit) {
    case "day":
      return duration / DAYS_IN_MONTH;
    case "month":
      return duration;
    case "year":
      return duration * MONTHS_IN_YEAR;
  }
};

export const durationToDays = ({ duration, unit }: Duration) => {
  switch (unit) {
    case "day":
      return duration;
    case "month":
      return duration * DAYS_IN_MONTH;
    case "year":
      return duration * DAYS_IN_YEAR;
  }
};

export const compareDurations = (a: Duration, b: Duration) => {
  return Math.sign(durationToMonths(a) - durationToMonths(b));
};

export const useDurationFormatter = () => {
  const intl = useIntl();

  return useCallback(
    ({ duration, unit }: Duration) =>
      intl.formatNumber(duration, {
        style: "unit",
        unit: unit,
        unitDisplay: "long",
      }),
    [intl],
  );
};
