import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../components/Page";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { LargeCenteredDetailsLayout } from "../../../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { Typography } from "../../../../../components/ui/Typography";
import { useQuery } from "../../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useOrganizationIdParam,
} from "../../../../../paths";
import { DoubleTriggerAccelerationDetails_Query } from "./__generated__/DoubleTriggerAccelerationDetails_Query.graphql";

const QUERY = graphql`
  query DoubleTriggerAccelerationDetails_Query(
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      ...LargeCenteredDetailsLayout_Organization
    }
  }
`;

export default function DoubleTriggerAccelerationDetails() {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<DoubleTriggerAccelerationDetails_Query>(QUERY, {
    organizationId,
  });

  const parentLink = generatePath(
    APPLICATION_ROUTES["organizationEquityConfigureAcceleration"],
    {
      organizationId,
    },
  );

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - Double trigger acceleration details"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity acceleration settings | Double trigger acceleration details`}
    >
      <LargeCenteredDetailsLayout
        organizationFragment={query.organization}
        parents={[
          {
            link: parentLink,
            title: "Acceleration",
          },
        ]}
        subtitle="Here are the terms of the Double trigger acceleration"
        title="Double trigger acceleration"
      >
        <FormRowContainer title="Terms">
          <Typography as="div" variant="Regular/Small">
            If: (i) the Company consummates a Change in Control (as defined
            below), (ii) the Optionee&apos;s Service Relationship is terminated
            by the Company or any of its Affiliates without Cause (as defined in
            the Plan) or the Optionee resigns from the Company for Good Reason
            (as defined below), in either case within 12 months after or 3
            months prior to the consummation of such Change in Control; and
            (iii) provided that the Optionee executes and delivers to the
            Company an effective release of claims in favor of and in a form
            acceptable to the Company and allow the releases contained therein
            to become effective according to its terms (by not invoking any
            legal right to revoke it) within any applicable time period set
            forth therein; then 100% of the Optionee’s then unvested shares
            subject to the Option will immediately vest, effective as of the
            date of the termination of the Optionee’s Service Relationship.
            <br />
            <br />
            “Change in Control” shall have the meaning ascribed thereto in the
            Plan. In case the Plan does not include a definition of Change in
            Control, Change in Control shall have the meaning ascribed to the
            terms “Sale Event”, “Change of Control” or “Corporate Transaction”.
            In the event the Plan does not include a definition of any of the
            above terms, Change in Control shall mean the consummation of (i)
            the dissolution or liquidation of the Company, (ii) the sale of all
            or substantially all of the assets of the Company on a consolidated
            basis to an unrelated person or entity, (iii) a merger,
            reorganization or consolidation pursuant to which the holders of the
            Company’s outstanding voting power immediately prior to such
            transaction do not own a majority of the outstanding voting power of
            the surviving or resulting entity (or its ultimate parent, if
            applicable), (iv) the acquisition of all or a majority of the
            outstanding voting stock of the Company in a single transaction or a
            series of related transactions by a Person or group of Persons, or
            (v) any other acquisition of the business of the Company, as
            determined by the Board; provided, however, that the Company’s
            Initial Public Offering, any subsequent public offering or another
            capital raising event, or a merger effected solely to change the
            Company’s domicile shall not constitute a “Change in Control”.
            <br />
            <br />
            &ldquo;Good Reason&rdquo; shall have the meaning ascribed thereto in
            the Plan. In the event the Plan does not include a definition of
            “Good Reason”, “Good Reason” shall mean any of the following actions
            taken by the Company or a successor corporation or entity without
            the Optionee’s consent (unless such action is taken in response to
            conduct by the Optionee that constitutes Cause): (i) a material
            reduction of the Optionee&apos;s base compensation, other than a
            reduction that applies generally to all executives and does not
            exceed 10%; (ii) a material reduction in Optionholder&apos;s
            authority, duties or responsibilities; provided, however, that a
            change in job position (including a change in title) shall not be
            deemed a &ldquo;material reduction&rdquo; unless the Optionee&apos;s
            new authority, duties or responsibilities are materially reduced
            from the prior authority, duties or responsibilities; (iii) failure
            or refusal of a successor to the Company to materially assume the
            Company&apos;s obligations under the Optionee&apos;s applicable
            offer letter in the event of a Change in Control; or (4) relocation
            of the Optionee&apos;s principal place of employment that results in
            an increase in the Optionee&apos;s one-way driving distance by more
            than 50 miles from the Optionee&apos;s then current principal
            residence. In order to resign for Good Reason, the Optionee must
            provide written notice of the event giving rise to Good Reason to
            the Board within 90 days after the condition arises, allow the
            Company 30 days to cure such condition, and if the Company fails to
            cure the condition within such period, the Optionee&apos;s
            resignation from all positions the Optionee then holds with the
            Company must be effective not later than 90 days after the end of
            the Company&apos;s cure period.
          </Typography>
        </FormRowContainer>
      </LargeCenteredDetailsLayout>
    </Page>
  );
}
