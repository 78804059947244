/**
 * @generated SignedSource<<df46a22e912d34819548184b591786c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation$variables = {
  ctmsGrantId: string;
  generateNewDocuments: boolean;
  instrumentId: string;
};
export type ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation$data = {
  readonly changeGrantInstrument: {
    readonly matchingInstrument: {
      readonly name: string;
    } | null;
  };
};
export type ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation = {
  response: ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation$data;
  variables: ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "generateNewDocuments"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "instrumentId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "ctmsGrantId",
    "variableName": "ctmsGrantId"
  },
  {
    "kind": "Variable",
    "name": "generateNewDocuments",
    "variableName": "generateNewDocuments"
  },
  {
    "kind": "Variable",
    "name": "instrumentId",
    "variableName": "instrumentId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CTMSGrant",
        "kind": "LinkedField",
        "name": "changeGrantInstrument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "matchingInstrument",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "CTMSGrant",
        "kind": "LinkedField",
        "name": "changeGrantInstrument",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Instrument",
            "kind": "LinkedField",
            "name": "matchingInstrument",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d4d58aab7348e987036cac029a3a175",
    "id": null,
    "metadata": {},
    "name": "ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation",
    "operationKind": "mutation",
    "text": "mutation ChangeGrantInstrumentForm_ChangeGrantInstrument_Mutation(\n  $ctmsGrantId: CtmsGrantId!\n  $instrumentId: UUID!\n  $generateNewDocuments: Boolean!\n) {\n  changeGrantInstrument(ctmsGrantId: $ctmsGrantId, generateNewDocuments: $generateNewDocuments, instrumentId: $instrumentId) {\n    matchingInstrument {\n      name\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b5de370bdc10180b94cd65390dfce40";

export default node;
