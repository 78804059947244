/**
 * @generated SignedSource<<7c41616a6899694e43c129e22f100da9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateOrganizationGeographyTaxFavoredSubplanAttributes = {
  subplanApprovalDate: string;
  subplanName: string;
};
export type SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation$variables = {
  attributes: UpdateOrganizationGeographyTaxFavoredSubplanAttributes;
  countryCode: string;
  organizationId: string;
};
export type SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation$data = {
  readonly updateOrganizationGeographyTaxFavoredSubplan: {
    readonly isTaxFavoredSubplanExpired: boolean;
    readonly taxFavoredEquityTypeSubplanApprovalDate: string | null;
    readonly taxFavoredEquityTypeSubplanName: string | null;
    readonly taxFavoredSubplanExpirationDate: string | null;
  };
};
export type SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation = {
  response: SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation$data;
  variables: SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "countryCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "countryCode"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxFavoredEquityTypeSubplanName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxFavoredEquityTypeSubplanApprovalDate",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTaxFavoredSubplanExpired",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxFavoredSubplanExpirationDate",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "updateOrganizationGeographyTaxFavoredSubplan",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "OrganizationGeography",
        "kind": "LinkedField",
        "name": "updateOrganizationGeographyTaxFavoredSubplan",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb9a98c26b72d0bd2e0bfea57137a1d0",
    "id": null,
    "metadata": {},
    "name": "SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation",
    "operationKind": "mutation",
    "text": "mutation SubplanApprovalDatePage_UpdateOrganizationGeography_Mutation(\n  $organizationId: OrganizationId!\n  $countryCode: String!\n  $attributes: UpdateOrganizationGeographyTaxFavoredSubplanAttributes!\n) {\n  updateOrganizationGeographyTaxFavoredSubplan(organizationId: $organizationId, countryCode: $countryCode, attributes: $attributes) {\n    taxFavoredEquityTypeSubplanName\n    taxFavoredEquityTypeSubplanApprovalDate\n    isTaxFavoredSubplanExpired\n    taxFavoredSubplanExpirationDate\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "33e485c63585e3bcd82160ca648fde8b";

export default node;
