import {
  BookOpenIcon,
  CloudArrowUpIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/outline";
import { Suspense } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";
import { graphql } from "relay-runtime";

import { LoadingPlaceholder } from "../../components/LoadingPlaceholder";
import { ApplicationSideBar } from "../../components/ui/ApplicationSideBar/ApplicationSideBar";
import { AnalyticsContextProvider } from "../../hooks/useAnalytics";
import { FeatureFlagProvider } from "../../hooks/useIsFeatureEnabled";
import { APPLICATION_ROUTES } from "../../paths";
import { useAuthentication } from "../../services/AuthenticationProvider";
import { SuperAdminLayout_Query } from "./__generated__/SuperAdminLayout_Query.graphql";
import { SuperAdminLayout_Viewer$key } from "./__generated__/SuperAdminLayout_Viewer.graphql";

const VIEWER_FRAGMENT = graphql`
  fragment SuperAdminLayout_Viewer on Account {
    ...ApplicationSideBar_Viewer
  }
`;

const SuperAdminLayout_: React.FC<{
  viewerFragment: SuperAdminLayout_Viewer$key;
}> = ({ viewerFragment }) => {
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  return (
    <ApplicationSideBar.Layout>
      <ApplicationSideBar
        activeRole={null}
        className="flex-shrink-0"
        homePath={generatePath(APPLICATION_ROUTES["superAdmin"])}
        organizationFragment={null}
        viewerFragment={viewer}
      >
        <div className="flex flex-col gap-[1px]">
          <ApplicationSideBar.NavLink
            icon={<BookOpenIcon />}
            to={generatePath(APPLICATION_ROUTES["superAdminLegalContent"])}
          >
            Legal content
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            icon={<Cog8ToothIcon />}
            to={generatePath(APPLICATION_ROUTES["superAdminOrganizationAdmin"])}
          >
            Organization admin
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            icon={<CloudArrowUpIcon />}
            to={generatePath(APPLICATION_ROUTES["superAdminDocumentsUpload"])}
          >
            Documents upload
          </ApplicationSideBar.NavLink>
          <ApplicationSideBar.NavLink
            icon={<CloudArrowUpIcon />}
            to={generatePath(APPLICATION_ROUTES["superAdminBulkUploadDrafts"])}
          >
            Bulk upload drafts
          </ApplicationSideBar.NavLink>
        </div>
      </ApplicationSideBar>
      <div className="h-full flex-auto overflow-x-hidden bg-white px-4 sm:overflow-auto sm:px-6 sm:py-6">
        <Suspense fallback={<LoadingPlaceholder />}>
          <Outlet />
        </Suspense>
      </div>
    </ApplicationSideBar.Layout>
  );
};

const QUERY = graphql`
  query SuperAdminLayout_Query {
    me {
      isSuperAdmin
      ...SuperAdminLayout_Viewer
    }
  }
`;

const SuperAdminLayout: React.FC = () => {
  const { me: viewer } = useLazyLoadQuery<SuperAdminLayout_Query>(QUERY, {});
  const { account } = useAuthentication();

  if (!viewer.isSuperAdmin) {
    throw new Error("Not authorized");
  }

  return (
    <FeatureFlagProvider account={account}>
      <AnalyticsContextProvider organizationId={null}>
        <SuperAdminLayout_ viewerFragment={viewer} />
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default SuperAdminLayout;
