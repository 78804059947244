/**
 * @generated SignedSource<<0adfb00fbf09b024eb1df8db44fc1080>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EsopChartGrantedStacked_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "EsopChartGrantedStacked_Organization";
};
export type EsopChartGrantedStacked_Organization$key = {
  readonly " $data"?: EsopChartGrantedStacked_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EsopChartGrantedStacked_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EsopChartGrantedStacked_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "13d590a5fed340b0637ed262192707d6";

export default node;
