/**
 * @generated SignedSource<<5abd46da0bcc468793c22039ad9b0ce6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeGrantInstrumentPage_Query$variables = Record<PropertyKey, never>;
export type ChangeGrantInstrumentPage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"ChangeGrantInstrumentPage_Organization">;
  }>;
};
export type ChangeGrantInstrumentPage_Query = {
  response: ChangeGrantInstrumentPage_Query$data;
  variables: ChangeGrantInstrumentPage_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeGrantInstrumentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ChangeGrantInstrumentPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChangeGrantInstrumentPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3f7cbcbed8e6f5ab0b7f3db57b05a93",
    "id": null,
    "metadata": {},
    "name": "ChangeGrantInstrumentPage_Query",
    "operationKind": "query",
    "text": "query ChangeGrantInstrumentPage_Query {\n  organizations {\n    ...ChangeGrantInstrumentPage_Organization\n    id\n  }\n}\n\nfragment ChangeGrantInstrumentForm_Organization on Organization {\n  name\n  id\n}\n\nfragment ChangeGrantInstrumentPage_Organization on Organization {\n  ...ChangeGrantInstrumentForm_Organization\n}\n"
  }
};

(node as any).hash = "7c78616febb7d33ec03f68980dc8f97b";

export default node;
