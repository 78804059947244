import { zodResolver } from "@hookform/resolvers/zod";
import { sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../paths";
import { NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation } from "./__generated__/NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation.graphql";
import { NewHirePlanningEntryModal_NewHireGrantPlanningEntry$key } from "./__generated__/NewHirePlanningEntryModal_NewHireGrantPlanningEntry.graphql";
import {
  NewHirePlanningEntryModal_Organization$data,
  NewHirePlanningEntryModal_Organization$key,
} from "./__generated__/NewHirePlanningEntryModal_Organization.graphql";
import { NewHirePlanningEntryModal_UpdateNewHirePlanningEntry_Mutation } from "./__generated__/NewHirePlanningEntryModal_UpdateNewHirePlanningEntry_Mutation.graphql";
import {
  GrantedSharesInput,
  useGrantedSharesController,
} from "./GrantedSharesInput";
import { useToaster } from "./Toaster";
import { FormRow } from "./ui/Form/FormRow";
import { Input } from "./ui/Form/Inputs/Input";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "./ui/Modal";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewHirePlanningEntryModal_Organization on Organization {
    id
    equityGrid {
      activated
      levels {
        id
        name
        equityInShares
      }
    }
    fullyDilutedShares
    latestPricePerShare {
      value
    }
  }
`;

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment NewHirePlanningEntryModal_NewHireGrantPlanningEntry on NewHireGrantPlanningEntry {
    id
    position
    newHirerEntrySharesGranted: sharesGranted
    equityGridLevel {
      id
    }
  }
`;

const CREATE_NEW_HIRE_PLANNING_ENTRY_MUTATION = graphql`
  mutation NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation(
    $organizationId: OrganizationId!
    $attributes: CreateNewHirePlanningEntryAttributes!
  ) {
    createNewHirePlanningEntry(
      organizationId: $organizationId
      attributes: $attributes
    ) {
      __typename
    }
  }
`;

const UPDATE_NEW_HIRE_PLANNING_ENTRY_MUTATION = graphql`
  mutation NewHirePlanningEntryModal_UpdateNewHirePlanningEntry_Mutation(
    $planningEntryId: PlanningEntryId!
    $attributes: CreateNewHirePlanningEntryAttributes!
  ) {
    updateNewHirePlanningEntry(
      planningEntryId: $planningEntryId
      attributes: $attributes
    ) {
      __typename
    }
  }
`;

const schema = z.object({
  equityGridLevelId: z.string().nullable(),
  position: z.string().trim().min(1),
  sharesGranted: z.number().positive().min(1),
});

type FormValues = z.infer<typeof schema>;

type State = {
  editedPlanningEntryFragment: NewHirePlanningEntryModal_NewHireGrantPlanningEntry$key | null;
  open: boolean;
};

export const useNewHirePlanningEntryModalState = () => {
  const [state, setState] = useState<State>({
    editedPlanningEntryFragment: null,
    open: false,
  });

  const openNewHirePlanningEntryModalInEditMode = useCallback(
    (
      editedPlanningEntryFragment: NewHirePlanningEntryModal_NewHireGrantPlanningEntry$key,
    ) => {
      setState({
        editedPlanningEntryFragment,
        open: true,
      });
    },
    [],
  );

  const openNewHirePlanningEntryModalInCreateMode = useCallback(() => {
    setState({
      editedPlanningEntryFragment: null,
      open: true,
    });
  }, []);

  const closeNewHirePlanningEntryModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }));
  }, []);

  return {
    closeNewHirePlanningEntryModal,
    newHirePlanningEntryModalState: state,
    openNewHirePlanningEntryModalInCreateMode,
    openNewHirePlanningEntryModalInEditMode,
  };
};

const ModalContent: React.FC<{
  editedPlanningEntryFragment: NewHirePlanningEntryModal_NewHireGrantPlanningEntry$key | null;
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organization: NewHirePlanningEntryModal_Organization$data;
}> = ({
  editedPlanningEntryFragment,
  onClose,
  onPlanningEntryEdited,
  organization,
}) => {
  const editedPlanningEntry = useFragment(
    PLANNING_ENTRY_FRAGMENT,
    editedPlanningEntryFragment,
  );

  const { control, getValues, handleSubmit, resetField, setValue, watch } =
    useForm({
      defaultValues: (editedPlanningEntry
        ? {
            equityGridLevelId: editedPlanningEntry.equityGridLevel?.id ?? null,
            position: editedPlanningEntry.position,
            sharesGranted: editedPlanningEntry.newHirerEntrySharesGranted,
          }
        : {
            equityGridLevelId: null,
            position: null,
            sharesGranted: null,
          }) as unknown as FormValues,
      resolver: zodResolver(schema),
    });

  const grantedSharesController = useGrantedSharesController({
    fullyDilutedShares: organization.fullyDilutedShares,
    initialState: { mode: "SHARES", shares: getValues().sharesGranted },
    latestPricePerShare: organization.latestPricePerShare?.value ?? null,
  });

  useEffect(() => {
    if (grantedSharesController.shares === null) {
      return resetField("sharesGranted");
    }

    setValue("sharesGranted", grantedSharesController.shares);
  }, [grantedSharesController.shares, resetField, setValue]);

  const equityGridLevels = useMemo(
    () => sortBy(organization.equityGrid.levels, "name"),
    [organization.equityGrid.levels],
  );

  const onSelectedEquityGridLevelIdChanged = useCallback(
    (equityGridLevelId: string) => {
      const equityGridLevel = equityGridLevels.find(
        (level) => level.id === equityGridLevelId,
      );
      if (equityGridLevel) {
        if (equityGridLevel.equityInShares === null)
          throw new Error("Missing equityInShares on equityGridLevel");
        grantedSharesController.setShares(equityGridLevel.equityInShares);
      }
    },
    [equityGridLevels, grantedSharesController],
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "equityGridLevelId": {
          const { equityGridLevelId } = value;
          if (equityGridLevelId)
            onSelectedEquityGridLevelIdChanged(equityGridLevelId);
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onSelectedEquityGridLevelIdChanged]);

  const [selectedEquityGridLevelId] = watch(["equityGridLevelId"]);

  const [
    createNewHirePlanningEntry,
    createNewHirePlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation>(
      CREATE_NEW_HIRE_PLANNING_ENTRY_MUTATION,
    );
  const [
    updateNewHirePlanningEntry,
    updateNewHirePlanningEntryMutationIsInFlight,
  ] =
    useSafeMutation<NewHirePlanningEntryModal_UpdateNewHirePlanningEntry_Mutation>(
      UPDATE_NEW_HIRE_PLANNING_ENTRY_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (data) => {
    const attributes = schema.parse(data);

    if (editedPlanningEntry) {
      await updateNewHirePlanningEntry({
        variables: {
          attributes,
          planningEntryId: editedPlanningEntry.id,
        },
      });
      toaster.push(
        <Toast title="Great!">New hire entry successfully updated!</Toast>,
      );
      onPlanningEntryEdited?.();
    } else {
      await createNewHirePlanningEntry({
        variables: {
          attributes,
          organizationId: organization.id,
        },
      });
      toaster.push(
        <Toast title="Great!">New hire entry successfully created!</Toast>,
      );
    }
    onClose();
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.SubmitButton
          form="new-hire-planning-entry-form"
          loading={
            createNewHirePlanningEntryMutationIsInFlight ||
            updateNewHirePlanningEntryMutationIsInFlight
          }
        >
          {editedPlanningEntry ? "Update" : "Plan"}
        </Modal.SubmitButton>
      }
      onClose={onClose}
      title={
        editedPlanningEntry ? "Update new hire entry" : "Plan a new hire entry"
      }
    >
      <Modal.Form id="new-hire-planning-entry-form" onSubmit={onSubmit}>
        <FormRow.Form
          control={control}
          label="New hire position"
          name="position"
        >
          <Input.Form
            control={control}
            name="position"
            placeholder="e.g. Product Designer"
          />
        </FormRow.Form>
        {organization.equityGrid.activated &&
          equityGridLevels.every(
            ({ equityInShares }) => equityInShares !== null,
          ) && (
            <FormRow.Form
              control={control}
              label="New hire level"
              name="equityGridLevelId"
            >
              <SelectAutocomplete.Form
                control={control}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                name="equityGridLevelId"
                options={equityGridLevels}
                usePortal
              />
            </FormRow.Form>
          )}
        <FormRow.Form
          control={control}
          label="Quantity granted"
          name="sharesGranted"
        >
          <GrantedSharesInput
            {...grantedSharesController.inputController}
            disabled={!!selectedEquityGridLevelId}
            placeholder="Amount"
          />
        </FormRow.Form>
        {selectedEquityGridLevelId && (
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Willing to change the shares amount?{" "}
            <Link
              className="font-medium text-primary"
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityConfigureEquityGrid"],
                {
                  organizationId: organization.id,
                },
              )}
            >
              Update your equity grid
            </Link>
          </Typography>
        )}
      </Modal.Form>
    </Modal.Content>
  );
};
export const NewHirePlanningEntryModal: React.FC<{
  onClose: () => void;
  onPlanningEntryEdited?: () => void;
  organizationFragment: NewHirePlanningEntryModal_Organization$key;
  state: State;
}> = ({ onClose, onPlanningEntryEdited, organizationFragment, state }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Modal onClose={onClose} show={state.open}>
      <ModalContent
        editedPlanningEntryFragment={state.editedPlanningEntryFragment}
        onClose={onClose}
        onPlanningEntryEdited={onPlanningEntryEdited}
        organization={organization}
      />
    </Modal>
  );
};
