import { useCallback, useState } from "react";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { GranteeDeletionConfirmationModal_DeleteGrantee_Mutation } from "./__generated__/GranteeDeletionConfirmationModal_DeleteGrantee_Mutation.graphql";
import { ConfirmationModal } from "./ui/ConfirmationModal";

const DELETE_GRANTEE_MUTATION = graphql`
  mutation GranteeDeletionConfirmationModal_DeleteGrantee_Mutation(
    $id: GranteeId!
    $connections: [ID!]!
  ) {
    deleteGrantee(id: $id) {
      id @deleteEdge(connections: $connections)
    }
  }
`;

type State =
  | {
      granteeId: string;
      shown: true;
    }
  | {
      granteeId?: null | string;
      shown: false;
    };

export const useGranteeDeletionConfirmationModalState = () => {
  const [granteeDeletionConfirmationModalState, setState] = useState<State>({
    shown: false,
  });

  const showGranteeDeletionConfirmationModal = useCallback(
    (granteeId: string) => {
      setState({
        granteeId,
        shown: true,
      });
    },
    [],
  );

  const closeGranteeDeletionConfirmationModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    closeGranteeDeletionConfirmationModal,
    granteeDeletionConfirmationModalState,
    showGranteeDeletionConfirmationModal,
  };
};

export const GranteeDeletionConfirmationModal: React.FC<{
  granteesRelayConnectionIds?: string[];
  onClose: () => void;
  onGranteeDeleted?: (granteeId: string) => void;
  state: State;
}> = ({
  granteesRelayConnectionIds = [],
  onClose,
  onGranteeDeleted,
  state,
}) => {
  const [deleteGrantee, deleteGranteeMutationIsInflight] =
    useSafeMutation<GranteeDeletionConfirmationModal_DeleteGrantee_Mutation>(
      DELETE_GRANTEE_MUTATION,
    );

  const handleConfirmation = useCallback(async () => {
    if (!state.granteeId) {
      return;
    }

    await deleteGrantee({
      variables: {
        connections: granteesRelayConnectionIds,
        id: state.granteeId,
      },
    });

    onGranteeDeleted?.(state.granteeId);
  }, [
    granteesRelayConnectionIds,
    deleteGrantee,
    onGranteeDeleted,
    state.granteeId,
  ]);

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      loading={deleteGranteeMutationIsInflight}
      onClose={onClose}
      onConfirmed={handleConfirmation}
      show={state.shown}
      title="Delete a grantee"
    >
      Are you sure you want to delete this grantee?
    </ConfirmationModal>
  );
};
