import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { graphql, useFragment } from "react-relay";

import {
  GrantsTableStatusTag_EasopGrant$data,
  GrantsTableStatusTag_EasopGrant$key,
} from "./__generated__/GrantsTableStatusTag_EasopGrant.graphql";
import {
  GrantsTableStatusTag_Organization$data,
  GrantsTableStatusTag_Organization$key,
} from "./__generated__/GrantsTableStatusTag_Organization.graphql";
import { LoadingSpinner } from "./ui/LoadingSpinner";
import { Tag } from "./ui/Tag";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantsTableStatusTag_Organization on Organization {
    isConnectedToCarta
  }
`;

const EASOP_GRANT_FRAGMENT = graphql`
  fragment GrantsTableStatusTag_EasopGrant on EasopGrant {
    grantStatus
    createCTMSDraftJobStatus
    boardConsent {
      isPandaDocDocumentSent
      voidedReason
    }
  }
`;

const EasopGrantPendingApprovalStatusTag: React.FC<{
  easopGrant: GrantsTableStatusTag_EasopGrant$data;
}> = ({ easopGrant }) => {
  if (
    !easopGrant.boardConsent ||
    !easopGrant.boardConsent.isPandaDocDocumentSent
  ) {
    return (
      <Tag className="flex items-center gap-2" color="dashed">
        <LoadingSpinner className="h-3 w-3 shrink-0" /> Generating board consent
      </Tag>
    );
  }

  if (easopGrant.boardConsent.voidedReason) {
    return <Tag color="red">Voided</Tag>;
  }

  return <Tag color="dashed">Pending board approval</Tag>;
};

const EasopGrantPendingImplementationStatusTag: React.FC<{
  easopGrant: GrantsTableStatusTag_EasopGrant$data;
  organization: GrantsTableStatusTag_Organization$data;
}> = ({ easopGrant, organization }) => {
  if (!organization.isConnectedToCarta) {
    return <Tag color="dashed">Pending implementation</Tag>;
  }

  if (!easopGrant.createCTMSDraftJobStatus) {
    return (
      <Tag color="dashed" icon={<ExclamationTriangleIcon />}>
        NO DRAFT CREATION
      </Tag>
    );
  }

  switch (easopGrant.createCTMSDraftJobStatus) {
    case "ACTIVE":
    case "CREATED":
      return (
        <Tag className="flex items-center gap-2" color="dashed">
          <LoadingSpinner className="h-3 w-3 shrink-0" /> CREATING DRAFT
        </Tag>
      );
    case "COMPLETED":
      return (
        <Tag color="green" icon={<CheckIcon />}>
          DRAFT CREATED
        </Tag>
      );
    case "FAILED":
      return (
        <Tag color="orange" icon={<ExclamationTriangleIcon />}>
          DRAFT CREATION FAILED
        </Tag>
      );
  }
};

export const GrantsTableStatusTag: React.FC<{
  easopGrantFragment: GrantsTableStatusTag_EasopGrant$key;
  organizationFragment: GrantsTableStatusTag_Organization$key;
}> = ({ easopGrantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);

  switch (easopGrant.grantStatus) {
    case "Active":
      return <Tag color="dashed">Implemented</Tag>;
    case "Draft":
      return <Tag color="dashed">Draft</Tag>;
    case "PendingBoardConsent":
      return <EasopGrantPendingApprovalStatusTag easopGrant={easopGrant} />;
    case "PendingCtmsImplementation":
      return (
        <EasopGrantPendingImplementationStatusTag
          easopGrant={easopGrant}
          organization={organization}
        />
      );
    case "PendingReview":
      return <Tag color="dashed">Pending review</Tag>;
    case "Reviewed":
      return <Tag color="dashed">Reviewed</Tag>;
  }
};
