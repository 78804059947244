import { Button } from "@remote-com/norma";

import { useApplicationSupportEmailAddress } from "../../../hooks/useApplicationTheme";

export default function ContactUsCard() {
  const mailtoSubject = encodeURI(
    `Questions regarding remote equity onboarding`,
  );
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <div className="flex items-center justify-between rounded-remote-md border-[0.5px] border-grey-300 px-8 py-6">
      <div className="text-remote-SM/Normal text-grey-700">
        If you have any questions, feel free to contact us
      </div>
      <Button
        as="a"
        href={`mailto:${supportEmailAddress}?subject=${mailtoSubject}`}
        size="md"
        variant="ghost"
      >
        Contact us
      </Button>
    </div>
  );
}
