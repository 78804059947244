/**
 * @generated SignedSource<<89dfcffec3f24e952d75b3b8c5ec3a20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type ExercisesTaxParametersEditionPage_EquityType$data = {
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesParametersPerWorkRelationshipEditionBlock_EquityType">;
  readonly " $fragmentType": "ExercisesTaxParametersEditionPage_EquityType";
};
export type ExercisesTaxParametersEditionPage_EquityType$key = {
  readonly " $data"?: ExercisesTaxParametersEditionPage_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesTaxParametersEditionPage_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExercisesTaxParametersEditionPage_EquityType",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExercisesParametersPerWorkRelationshipEditionBlock_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "1f553b93cd9c868415b329f2b05c4fd5";

export default node;
