/**
 * @generated SignedSource<<d8a093b10a6fb4254152a851f27338cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeGrantInstrumentPage_Organization$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ChangeGrantInstrumentForm_Organization">;
  readonly " $fragmentType": "ChangeGrantInstrumentPage_Organization";
}>;
export type ChangeGrantInstrumentPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: ChangeGrantInstrumentPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeGrantInstrumentPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ChangeGrantInstrumentPage_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeGrantInstrumentForm_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "50be2a8a3e47daa23a0596aa65f40975";

export default node;
