import { PlusIcon } from "@heroicons/react/24/outline";
import { Suspense } from "react";
import { graphql, useFragment } from "react-relay";
import { generatePath, Outlet } from "react-router-dom";

import { DraftGrantButton } from "../../../components/DraftGrantButton";
import { LinkButton } from "../../../components/ui/Button";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { TabNavigation } from "../../../components/ui/TabNavigation";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { AdminGrantsLayout_Organization$key } from "./__generated__/AdminGrantsLayout_Organization.graphql";
import { AdminGrantsLayout_Query } from "./__generated__/AdminGrantsLayout_Query.graphql";
import { AdminGrantsLayout_Viewer$key } from "./__generated__/AdminGrantsLayout_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminGrantsLayout_Organization on Organization {
    id
    planIsFreemium
    activeCTMSGrants: ctmsGrants(filters: { statusIn: [Active] }) {
      totalCount
    }
    terminatedCTMSGrants: ctmsGrants(filters: { statusIn: [Terminated] }) {
      totalCount
    }
    canceledCTMSGrants: ctmsGrants(filters: { statusIn: [Canceled] }) {
      totalCount
    }
    ...DraftGrantButton_Organization @defer
    ...LargeOneColumnLayout_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment AdminGrantsLayout_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const AdminGrantsLayout_: React.FC<{
  organizationFragment: AdminGrantsLayout_Organization$key;
  viewerFragment: AdminGrantsLayout_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  return (
    <LargeOneColumnLayout
      organizationFragment={organization}
      title="Grants"
      topBarActionsRender={() =>
        viewer.isAllowedToManageOrganization &&
        (!organization.planIsFreemium ? (
          <Suspense>
            <DraftGrantButton organizationFragment={organization} />
          </Suspense>
        ) : (
          <LinkButton
            leftIcon={<PlusIcon />}
            size="small"
            to={generatePath(
              APPLICATION_ROUTES["organizationPrepareYourGrantsDrafts"],
              {
                organizationId: organization.id,
              },
            )}
            type="button"
          >
            Draft equity
          </LinkButton>
        ))
      }
    >
      <div className="space-y-4">
        <TabNavigation>
          <TabNavigation.Link
            count={organization.activeCTMSGrants.totalCount}
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityGrantsActive"],
              {
                organizationId: organization.id,
              },
            )}
          >
            Active
          </TabNavigation.Link>
          <TabNavigation.Link
            count={organization.terminatedCTMSGrants.totalCount}
            to={generatePath(
              APPLICATION_ROUTES["organizationEquityGrantsTerminated"],
              {
                organizationId: organization.id,
              },
            )}
          >
            Terminated
          </TabNavigation.Link>
          {organization.canceledCTMSGrants.totalCount > 0 && (
            <TabNavigation.Link
              count={organization.canceledCTMSGrants.totalCount}
              to={generatePath(
                APPLICATION_ROUTES["organizationEquityGrantsCanceled"],
                {
                  organizationId: organization.id,
                },
              )}
            >
              Canceled
            </TabNavigation.Link>
          )}
        </TabNavigation>
        <Outlet />
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query AdminGrantsLayout_Query($organizationId: OrganizationId!) {
    me {
      ...AdminGrantsLayout_Viewer @arguments(organizationId: $organizationId)
    }
    organization(id: $organizationId) {
      ...AdminGrantsLayout_Organization
    }
  }
`;

const AdminGrantsLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<AdminGrantsLayout_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <AdminGrantsLayout_
      organizationFragment={query.organization}
      viewerFragment={query.me}
    />
  );
};

export default AdminGrantsLayout;
