export const DjamoLogo = () => (
  <svg
    fill="none"
    height="28"
    viewBox="0 0 92 28"
    width="92"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_66_7622)">
      <path
        d="M14.8068 16.9179H18.4858L16.1672 21.2946H12.0667C10.2655 21.2946 9.48951 20.8815 9.47033 19.6619C8.3973 20.9405 7.03685 21.5601 5.36982 21.5601C3.82733 21.5601 2.62975 20.911 1.75791 19.6127C0.914809 18.2948 0.656132 16.6328 0.981874 14.6362C1.77707 9.89575 4.75666 7.54515 9.93023 7.54515H10.141L9.27874 11.7742H9.02002C7.61169 11.7742 6.65363 12.8561 6.37579 14.7838C6.16502 16.1508 6.73027 17.0458 7.83205 17.0458C8.95296 17.0458 9.56616 16.2 10.0069 14.0658L12.2583 3.08008H17.6714L14.8068 16.9179Z"
        fill="#485864"
      />
      <path
        d="M27.4919 0.560547C25.798 0.560547 24.4254 1.39458 24.1138 3.09279C23.7537 4.96183 24.9024 5.99683 26.7132 5.99683C28.407 5.99683 29.8089 5.1628 30.1204 3.4646C30.4709 1.5855 29.3027 0.560547 27.4919 0.560547Z"
        fill="#485864"
      />
      <path
        d="M20.2297 11.8349H22.5855L19.3535 27.4404H24.9024L25.7299 23.632L27.0149 17.3617L29.0884 7.36328H22.5855L20.2297 11.8349Z"
        fill="#485864"
      />
      <path
        d="M43.5397 17.0051H47.0453L44.669 21.3728H40.7118C38.8854 21.3728 38.1194 20.941 38.0507 19.7631C37.1179 20.9606 35.861 21.5593 34.2605 21.5593C32.6992 21.5593 31.4521 20.9115 30.4898 19.5865C29.5569 18.2712 29.2132 16.6616 29.5079 14.7182C29.773 12.8239 30.6076 11.2633 32.002 10.0168C34.7906 7.49438 39.0621 6.74841 44.2664 7.64159L45.1305 7.8084L42.7542 11.8915L41.8214 11.7934C38.0507 11.4105 35.5763 12.4215 35.0755 14.6986C34.7121 16.2101 35.2424 17.1229 36.3225 17.1229C37.4714 17.1229 38.1489 16.2395 38.6006 14.1489L38.8166 13.1184H44.3646L43.5397 17.0051Z"
        fill="#485864"
      />
      <path
        d="M70.4785 17.1124H73.9259L71.576 21.5593H67.1379C65.9726 21.5593 65.2152 21.3395 64.8559 20.8998C64.4966 20.4601 64.448 19.6307 64.7102 18.3815L65.6133 13.9846C65.8949 12.5456 65.6133 11.976 64.5645 11.976C63.5157 11.976 63.137 12.4357 62.7583 14.2244L61.2434 21.5593H55.7372L57.3007 13.9846C57.6115 12.5456 57.3007 11.976 56.2519 11.976C55.7081 11.976 55.3294 12.1459 55.0672 12.4657C54.8341 12.7854 54.6205 13.465 54.4068 14.4742L52.9307 21.5593H47.4246L49.3959 12.026H47.0459L49.3959 7.57911H52.7657C54.5428 7.57911 55.2614 7.9688 55.3585 9.13801C56.3102 7.9688 57.7571 7.2793 59.0584 7.2793C61.1463 7.2793 62.3795 8.0388 62.7583 9.57768C64.1372 8.0388 65.6813 7.2793 67.3613 7.2793C70.8963 7.2793 72.0611 9.35788 71.1392 13.8747L70.4785 17.1124Z"
        fill="#485864"
      />
      <path
        d="M74.9953 13.8762C76.0481 8.83967 80.0619 7.2793 83.8195 7.2793C86.0427 7.2793 87.7837 7.79282 89.1907 9.02727C90.5977 10.2321 91.0303 12.1381 90.5186 14.9131C90.0069 17.3524 88.758 19.1793 87.0857 20.1767C85.4526 21.1248 83.6817 21.5593 81.6649 21.5593C77.4845 21.5494 73.7955 19.4953 74.9953 13.8762ZM84.8227 14.1033C85.0299 12.8689 84.3607 12.0788 83.3176 12.0788C81.8322 12.0788 80.9377 12.9281 80.6618 14.6168C80.4847 15.871 81.1736 16.6709 82.2257 16.6709C83.7216 16.6709 84.5182 15.9302 84.8227 14.1033Z"
        fill="#485864"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_7622">
        <rect
          fill="white"
          height="28"
          transform="translate(0.845703)"
          width="90.3"
        />
      </clipPath>
    </defs>
  </svg>
);
