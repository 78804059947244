/**
 * @generated SignedSource<<70059450a6e4d7ce1ed0cb60754d2917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateNewHirePlanningEntryAttributes = {
  equityGridLevelId?: string | null;
  position: string;
  sharesGranted: number;
};
export type NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation$variables = {
  attributes: CreateNewHirePlanningEntryAttributes;
  organizationId: string;
};
export type NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation$data = {
  readonly createNewHirePlanningEntry: {
    readonly __typename: "Organization";
  };
};
export type NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation = {
  response: NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation$data;
  variables: NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createNewHirePlanningEntry",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createNewHirePlanningEntry",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4cdd68aa3c7e172df6a52d218eb26bdb",
    "id": null,
    "metadata": {},
    "name": "NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation NewHirePlanningEntryModal_CreateNewHirePlanningEntry_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: CreateNewHirePlanningEntryAttributes!\n) {\n  createNewHirePlanningEntry(organizationId: $organizationId, attributes: $attributes) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "20ac2b86d48a3a989e30910602507f65";

export default node;
