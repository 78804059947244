/**
 * @generated SignedSource<<219510db25efc1a4908ed93cdec53300>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GenerateCTMSXlsxButton_Organization$data = {
  readonly easopGrantsPendingCTMSImplementation: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
  readonly " $fragmentType": "GenerateCTMSXlsxButton_Organization";
};
export type GenerateCTMSXlsxButton_Organization$key = {
  readonly " $data"?: GenerateCTMSXlsxButton_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GenerateCTMSXlsxButton_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GenerateCTMSXlsxButton_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": "easopGrantsPendingCTMSImplementation",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "PendingCtmsImplementation"
            ]
          }
        }
      ],
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": "easopGrants(filters:{\"statusIn\":[\"PendingCtmsImplementation\"]})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationCTMS_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "26fb4b4fda43f4629cba8363a21f7e64";

export default node;
