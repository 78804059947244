/**
 * @generated SignedSource<<e57fba3e09d55b4fac4d133cf5d8a6b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubplanApprovalDatePage_Query$variables = Record<PropertyKey, never>;
export type SubplanApprovalDatePage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SubplanApprovalDatePage_Organization">;
  }>;
};
export type SubplanApprovalDatePage_Query = {
  response: SubplanApprovalDatePage_Query$data;
  variables: SubplanApprovalDatePage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubplanApprovalDatePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubplanApprovalDatePage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SubplanApprovalDatePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "terminated",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationGeography",
            "kind": "LinkedField",
            "name": "organizationGeographies",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "country",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTaxFavoredEquityTypeUnlocked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxFavoredEquityTypeSubplanName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxFavoredEquityTypeSubplanApprovalDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTaxFavoredSubplanExpired",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "taxFavoredSubplanExpirationDate",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b4b5dc5a2741d813740c99afb98995d",
    "id": null,
    "metadata": {},
    "name": "SubplanApprovalDatePage_Query",
    "operationKind": "query",
    "text": "query SubplanApprovalDatePage_Query {\n  organizations {\n    ...SubplanApprovalDatePage_Organization\n    id\n  }\n}\n\nfragment SubplanApprovalDatePage_Organization on Organization {\n  id\n  name\n  terminated\n  organizationGeographies {\n    country {\n      code\n    }\n    isTaxFavoredEquityTypeUnlocked\n    taxFavoredEquityTypeSubplanName\n    taxFavoredEquityTypeSubplanApprovalDate\n    isTaxFavoredSubplanExpired\n    taxFavoredSubplanExpirationDate\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "321e039d3866344dfc3b3d56f725eb54";

export default node;
