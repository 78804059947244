import classNames from "classnames";
import React from "react";

import { LinkButton } from "./ui/Button";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const _DetailedBlockWithLink: React.FC<{
  children: React.ReactNode;
  className?: string;
  footer: React.ReactNode;
  header: React.ReactNode;
}> = ({ children, className, footer, header }) => {
  return (
    <div className={classNames("flex w-full flex-col p-6", className)}>
      <div>{header}</div>
      <div className="flex-1 pt-2">{children}</div>
      <div className="pt-10">{footer}</div>
    </div>
  );
};

const Title: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Typography as="div" variant="Medium/Small">
      {children}
    </Typography>
  );
};

const Description: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Typography
      as="div"
      className="flex-1 text-black-05"
      variant="Regular/Extra Small"
    >
      {children}
    </Typography>
  );
};

const HeaderTag: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <Tag className="uppercase">{children}</Tag>;
};

const FooterLink: React.FC<{
  children: React.ReactNode;
  to: string;
}> = ({ children, to }) => {
  return (
    <LinkButton size="extra small" to={to}>
      {children}
    </LinkButton>
  );
};

const FooterIcon: React.FC<{
  icon: React.ReactElement<React.ComponentProps<"svg">>;
}> = ({ icon }) => {
  return (
    <div className="flex h-8 w-8 justify-center rounded bg-gray-02 text-gray-09">
      {React.cloneElement(icon, {
        ...icon.props,
        className: classNames(
          icon.props.className,

          /* tailwind */ `h-full w-4`,
        ),
      })}
    </div>
  );
};

export const DetailedBlockWithLink = Object.assign(_DetailedBlockWithLink, {
  Description,
  FooterIcon,
  FooterLink,
  HeaderTag,
  Title,
});
