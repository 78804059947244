import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationCTMS } from "../hooks/useOrganizationCTMS";
import { MissingInformationTag_MissingCTMSInformationTag_Organization$key } from "./__generated__/MissingInformationTag_MissingCTMSInformationTag_Organization.graphql";
import { Tag } from "./ui/Tag";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MissingInformationTag_MissingCTMSInformationTag_Organization on Organization {
    ...useOrganizationCTMS_Organization
  }
`;

export const MissingCTMSInformationTag: React.FC<
  Omit<React.ComponentProps<typeof Tag>, "children"> & {
    organizationFragment: MissingInformationTag_MissingCTMSInformationTag_Organization$key;
  }
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });

  return (
    <Tag color="gray" {...props}>
      Missing {organizationCTMS?.name ?? "CTMS"} Information
    </Tag>
  );
};

export const MissingInformationTag: React.FC<
  Omit<React.ComponentProps<typeof Tag>, "children">
> = (props) => {
  return (
    <Tag color="gray" {...props}>
      Missing Information
    </Tag>
  );
};
