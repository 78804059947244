/**
 * @generated SignedSource<<c44ccd4348c27dfc4997f0a3adcbcb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantOwnershipRow_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationSharesUtil_Organization">;
  readonly " $fragmentType": "GrantOwnershipRow_Organization";
};
export type GrantOwnershipRow_Organization$key = {
  readonly " $data"?: GrantOwnershipRow_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantOwnershipRow_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantOwnershipRow_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useOrganizationSharesUtil_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "fbf848914b5c776ca26fd2ffe581279f";

export default node;
