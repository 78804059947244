/**
 * @generated SignedSource<<f7aaf443ff3a82aa73aaf674d222707d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestsListSlideOver_ExerciseRequests$data = ReadonlyArray<{
  readonly ctmsGrant: {
    readonly grantee: {
      readonly email: string;
      readonly id: string;
      readonly name: string;
    };
    readonly id: string;
    readonly label: string;
  };
  readonly quantityExercised: number;
  readonly " $fragmentType": "ExerciseRequestsListSlideOver_ExerciseRequests";
}>;
export type ExerciseRequestsListSlideOver_ExerciseRequests$key = ReadonlyArray<{
  readonly " $data"?: ExerciseRequestsListSlideOver_ExerciseRequests$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestsListSlideOver_ExerciseRequests">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExerciseRequestsListSlideOver_ExerciseRequests",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantityExercised",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};
})();

(node as any).hash = "6810ab14659dc80d6716c4504e2196ac";

export default node;
