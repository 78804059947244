/**
 * @generated SignedSource<<c0ee10cabe96327bb9d01fef1337c18c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MarkOrganizationAsTerminatedButton_Mutation$variables = {
  organizationId: string;
};
export type MarkOrganizationAsTerminatedButton_Mutation$data = {
  readonly markOrganizationAsTerminated: {
    readonly terminated: boolean;
  };
};
export type MarkOrganizationAsTerminatedButton_Mutation = {
  response: MarkOrganizationAsTerminatedButton_Mutation$data;
  variables: MarkOrganizationAsTerminatedButton_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminated",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MarkOrganizationAsTerminatedButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "markOrganizationAsTerminated",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MarkOrganizationAsTerminatedButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "markOrganizationAsTerminated",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3c3ce0b99be509c1e83da33b46aedd5",
    "id": null,
    "metadata": {},
    "name": "MarkOrganizationAsTerminatedButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation MarkOrganizationAsTerminatedButton_Mutation(\n  $organizationId: OrganizationId!\n) {\n  markOrganizationAsTerminated(id: $organizationId) {\n    terminated\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bbad6ff1782bb1e4fc6e40e61ecf870e";

export default node;
