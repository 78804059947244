import { generatePath } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { Button, LinkButton } from "../../../components/ui/Button";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../../paths";

export const HRISIntegrationNotice: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const [
    isHRISIntegrationNoticeDismissed,
    setIsHRISIntegrationNoticeDismissed,
  ] = useLocalStorage("grantees-page-hris-integration-notice-dismissed", false);

  if (isHRISIntegrationNoticeDismissed) {
    return null;
  }

  return (
    <div className="flex items-center justify-between gap-6 rounded bg-purple-01 p-4">
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Extra Small">
          Connect to Remote or Deel and enrich your grantee data
        </Typography>
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Automatically import and sync your HRIS data (more integrations coming
          soon!)
        </Typography>
      </div>
      <div className="flex gap-2">
        <LinkButton
          size="extra small"
          to={generatePath(
            APPLICATION_ROUTES["organizationSettingsIntegrations"],
            {
              organizationId,
            },
          )}
          variant="Primary Full"
        >
          View integrations
        </LinkButton>
        <Button
          onClick={() => setIsHRISIntegrationNoticeDismissed(true)}
          size="extra small"
          variant="Primary Outline"
        >
          Dismiss
        </Button>
      </div>
    </div>
  );
};
