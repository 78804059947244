import { Suspense } from "react";
import { useTimeoutFn } from "react-use";

import { useBoolean } from "../hooks/useBoolean";
import { LoadingLogo } from "./LoadingLogo";
import { FadeAndScaleTransition } from "./ui/Transition";

export const LoadingPlaceholder: React.FC = () => {
  const { setTrue: setLoadingIsTakingTooLong, value: loadingIsTakingTooLong } =
    useBoolean(false);

  useTimeoutFn(() => {
    setLoadingIsTakingTooLong();
  }, 100);

  return (
    <FadeAndScaleTransition
      className="flex h-full w-full items-center justify-center p-4"
      show={loadingIsTakingTooLong}
    >
      <div className="flex h-full w-full flex-col items-center justify-center gap-6">
        <LoadingLogo className="w-full max-w-[48px]" />
      </div>
    </FadeAndScaleTransition>
  );
};

export const SuspenseWithLoadingFallback: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return <Suspense fallback={<LoadingPlaceholder />}>{children}</Suspense>;
};
