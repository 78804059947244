/**
 * @generated SignedSource<<35a1576bfafefbead036eef2daaf996c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
export type CreateRefresherGrantPlanningEntryAttributes = {
  granteeId: string;
  sharesGranted: number;
  type: RefresherGrantPlanningEntryType;
};
export type RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$variables = {
  attributes: CreateRefresherGrantPlanningEntryAttributes;
  organizationId: string;
  planningEntrySuggestionId?: string | null;
};
export type RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$data = {
  readonly createRefresherGrantPlanningEntry: {
    readonly __typename: "Organization";
  };
};
export type RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation = {
  response: RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$data;
  variables: RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planningEntrySuggestionId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "attributes",
    "variableName": "attributes"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "planningEntrySuggestionId",
    "variableName": "planningEntrySuggestionId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createRefresherGrantPlanningEntry",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "createRefresherGrantPlanningEntry",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1234a38eaf84a33d4b677c875acb1a82",
    "id": null,
    "metadata": {},
    "name": "RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation",
    "operationKind": "mutation",
    "text": "mutation RefresherGrantPlanningEntryModal_CreateRefresherGrantPlanningEntry_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: CreateRefresherGrantPlanningEntryAttributes!\n  $planningEntrySuggestionId: UUID\n) {\n  createRefresherGrantPlanningEntry(organizationId: $organizationId, attributes: $attributes, planningEntrySuggestionId: $planningEntrySuggestionId) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "72b2cf85a3146a0ce5f5ff895edab8b3";

export default node;
