import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { isEmpty } from "lodash";
import React, { Suspense, useMemo } from "react";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  checkPropertiesNotNull,
  NonNullableProperties,
} from "../helpers/ts-utlity";
import { useApplicationName } from "../hooks/useApplicationTheme";
import { useGranteePositionToEquityGridLevel } from "../hooks/useGranteePositionToEquityGridLevel";
import { useOrganizationCTMS } from "../hooks/useOrganizationCTMS";
import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import { InstrumentValuationType } from "../pages/Admin/Equity/PrepareYourGrants/useInstrumentValuationGuard";
import { ACCELERATION_CLAUSE_LABELS } from "../services/AccelerationClause";
import {
  AccelerationClause,
  DraftWatchoutTag_Deferred_EasopGrant$data,
  DraftWatchoutTag_Deferred_EasopGrant$key,
} from "./__generated__/DraftWatchoutTag_Deferred_EasopGrant.graphql";
import { DraftWatchoutTag_EasopGrant$key } from "./__generated__/DraftWatchoutTag_EasopGrant.graphql";
import {
  DraftWatchoutTag_Organization$data,
  DraftWatchoutTag_Organization$key,
} from "./__generated__/DraftWatchoutTag_Organization.graphql";
import { ShortDate } from "./ShortDate";
import { LoadingSpinner } from "./ui/LoadingSpinner";
import { NoticeMessage } from "./ui/NoticeMessage";
import { RoundedBox } from "./ui/RoundedBox";
import { Tag } from "./ui/Tag";
import { TooltipContainer } from "./ui/TooltipContainer";
import { Typography } from "./ui/Typography";
import { VerticallyLabeledValue } from "./ui/VerticallyLabeledValue";

const DEFERRED_GRANT_FRAGMENT = graphql`
  fragment DraftWatchoutTag_Deferred_EasopGrant on EasopGrant
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    vestingStartDate
    quantityGranted
    accelerationClause
    equityTypeName
    watchouts {
      type
      key
    }
    grantee {
      ...useGranteePositionToEquityGridLevel_Grantee
      contractStartDate @required(action: THROW)
      jobTitle
      totalGrantedSharesBreakdown {
        total
      }
      equityGridLevel {
        name
        equityInUsd
      }
      defaultVestingSchedule {
        name
      }
      defaultPostTerminationExercisePeriod {
        displayName
      }
      taxResidenceCountry {
        name
      }
    }
    vestingSchedule {
      name
    }
    postTerminationExercisePeriod {
      displayName
    }
    instrument {
      valuation(organizationId: $organizationId) {
        type
      }
    }
  }
`;

const GRANT_FRAGMENT = graphql`
  fragment DraftWatchoutTag_EasopGrant on EasopGrant
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    ...DraftWatchoutTag_Deferred_EasopGrant
      @arguments(organizationId: $organizationId)
      @defer
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftWatchoutTag_Organization on Organization {
    name
    equityGrid {
      activated
    }
    ...useOrganizationSharesUtil_Organization
    ...useOrganizationCTMS_Organization
  }
`;

const WatchOutWrapper: React.FC<{
  children: React.ReactElement;
  watchOutTooltipItems: React.ReactElement[];
}> = ({ children, watchOutTooltipItems }) => (
  <TooltipContainer
    className="flex items-center"
    defaultStyle={false}
    position="right"
    tooltipClassName="w-[320px]"
    tooltipContent={
      <RoundedBox className="space-y-6 p-6 text-black" withBorder withShadow>
        {...watchOutTooltipItems}
      </RoundedBox>
    }
  >
    {children}
  </TooltipContainer>
);

const WatchoutNoticeMessage: React.FC<{
  children: React.ReactNode;
  className?: string;
  hasColor?: boolean;
  watchOutType: WatchOut["type"];
}> = ({ children, className, hasColor = false, watchOutType }) => {
  const variant = useMemo(() => {
    switch (watchOutType) {
      case "INFORMATION":
        return "Idea";
      case "WARNING":
        return "Warning";
    }
  }, [watchOutType]);
  return (
    <NoticeMessage
      className={className}
      hasColor={hasColor}
      size="Small"
      variant={variant}
    >
      {children}
    </NoticeMessage>
  );
};

const getAccelerationWatchOut = ({
  accelerationClause,
  watchOutType,
}: {
  accelerationClause: AccelerationClause;
  watchOutType: WatchOut["type"];
}) => {
  return (
    <>
      <WatchoutNoticeMessage watchOutType={watchOutType}>
        This grant has an acceleration clause
      </WatchoutNoticeMessage>
      <VerticallyLabeledValue label="Clause">
        {ACCELERATION_CLAUSE_LABELS[accelerationClause]}
      </VerticallyLabeledValue>
    </>
  );
};

const buildStrikePriceDiffersFromOrganizationValuationMessage = ({
  instrumentValuationType,
}: {
  instrumentValuationType: InstrumentValuationType;
}) => {
  switch (instrumentValuationType) {
    case "FMV_409A":
      return "Strike price differs from latest FMV";
    case "UK_VALUATION":
      return "Strike price differs from latest EMI valuation";
  }
};

const buildMissingInstrumentValuationMessage = ({
  instrumentValuationType,
}: {
  instrumentValuationType: InstrumentValuationType;
}) => {
  switch (instrumentValuationType) {
    case "FMV_409A":
      return "409A certificate has not been uploaded";
    case "UK_VALUATION":
      return "EMI valuation has not been uploaded";
  }
};

const WatchoutTooltipItem: React.FC<{
  children: React.ReactNode;
  title: React.ReactNode;
}> = ({ children, title }) => {
  return (
    <div className="space-y-4">
      <Typography as="div" variant="Medium/Small">
        {title}
      </Typography>
      <div className="space-y-4 border-l-2 border-primary pl-4">{children}</div>
    </div>
  );
};

type EasopGrant = DraftWatchoutTag_Deferred_EasopGrant$data;
type WatchOut = EasopGrant["watchouts"][number];

export const VestingStartDateDiffersFromContractStartDateWatchout: React.FC<{
  className?: string;
  hasColor?: boolean;
  watchOutType: WatchOut["type"];
}> = ({ className, hasColor = false, watchOutType }) => {
  return (
    <WatchoutNoticeMessage
      className={className}
      hasColor={hasColor}
      watchOutType={watchOutType}
    >
      Contract & vesting start dates are different
    </WatchoutNoticeMessage>
  );
};

export const VestingStartDateEqualsContractStartDateWatchout: React.FC<{
  className?: string;
  hasColor?: boolean;
  watchOutType: WatchOut["type"];
}> = ({ className, hasColor = false, watchOutType }) => {
  return (
    <WatchoutNoticeMessage
      className={className}
      hasColor={hasColor}
      watchOutType={watchOutType}
    >
      Contract & vesting start dates are the same
    </WatchoutNoticeMessage>
  );
};

const watchoutToTooltipItem = ({
  applicationName,
  ctmsName,
  grant,
  organization,
  watchout,
}: {
  applicationName: string;
  ctmsName: null | string;
  grant: EasopGrant;
  organization: DraftWatchoutTag_Organization$data;
  watchout: WatchOut;
}) => {
  switch (watchout.key) {
    case "CUSTOM_BOARD_CONSENT_EXHIBIT_CONTENT": {
      return (
        <WatchoutTooltipItem
          key="custom_board_consent_exhibit_content"
          title="Custom board consent exhibit content"
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            This organization uses a custom board consent exhibit content.
            <br />
            Please make sure it is correct before reviewing the grant.
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "EARLY_EXERCISE_IS_ALLOWED": {
      return (
        <WatchoutTooltipItem key="early-exercise" title="Early exercise">
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            Early exercise is allowed
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "EXERCISE_PRICE_SHOULD_BE_EQUAL_TO_INSTRUMENT_VALUATION": {
      return (
        <WatchoutTooltipItem key="strike-price" title="Strike Price">
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            {buildStrikePriceDiffersFromOrganizationValuationMessage({
              instrumentValuationType: grant.instrument.valuation.type,
            })}
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "GRANTED_TO_CORPORATION": {
      return (
        <WatchoutTooltipItem
          key="grant_to_corporation"
          title="Grant to corporation"
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            Grants to non-individual contractors (corporations) have legal
            implications
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "HAS_ACCELERATION_CLAUSE": {
      if (!grant.accelerationClause) return null;
      return (
        <WatchoutTooltipItem key="acceleration" title="Acceleration">
          {getAccelerationWatchOut({
            accelerationClause: grant.accelerationClause,
            watchOutType: watchout.type,
          })}
        </WatchoutTooltipItem>
      );
    }
    case "MISSING_CARTA_UPLOAD_FILE_SCOPE": {
      return (
        <WatchoutTooltipItem
          key="missing_carta_upload_file_scope"
          title={
            <>Upload document on Carta not allowed for {organization.name}</>
          }
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            You need to reset Carta connection in order to allow file upload.
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "MISSING_EQUITY_PLAN_DOCUMENT": {
      return (
        <WatchoutTooltipItem
          key="missing_equity_plan_document"
          title={<>Missing Equity plan document</>}
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            You need to upload the equity plan document for {organization.name}.
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "MISSING_EQUITY_SUB_PLAN_DOCUMENT": {
      return (
        <WatchoutTooltipItem
          key="missing_equity_sub_plan_document"
          title={
            <>Missing Equity sub plan document for {grant.equityTypeName}</>
          }
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            You need to upload the equity sub-plan document for{" "}
            {organization.name}.
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "MISSING_INSTRUMENT_VALUATION": {
      return (
        <WatchoutTooltipItem
          key="missing-instrument-valuation"
          title="Missing valuation"
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            {buildMissingInstrumentValuationMessage({
              instrumentValuationType: grant.instrument.valuation.type,
            })}
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "MISSING_VESTING_SCHEDULE_IN_CTMS": {
      if (!ctmsName) return null;
      return (
        <WatchoutTooltipItem
          key="missing_vesting_schedule_in_ctms"
          title={<>Missing vesting schedule in {ctmsName}</>}
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            <strong>{grant.vestingSchedule.name}</strong> should be implemented
            in {ctmsName}
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "NO_SUBSIDIARY_FOR_GRANTEE_COUNTRY": {
      return (
        <WatchoutTooltipItem
          key="no_subsidiary_for_grantee_country"
          title="Work Relationship"
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            Direct employee grant in a country where no entity has been
            registered.
            <br />
            Country: {grant.grantee.taxResidenceCountry?.name ?? "-"}
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "PTEP_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_PTEP": {
      return (
        <WatchoutTooltipItem
          key="post-termination-exercise-period"
          title="Post-termination exercise period"
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            PTEP differs from default
          </WatchoutNoticeMessage>
          <VerticallyLabeledValue label="Default PTEP">
            {grant.grantee.defaultPostTerminationExercisePeriod?.displayName ??
              "Not set"}
          </VerticallyLabeledValue>
          <VerticallyLabeledValue label="This grant">
            {grant.postTerminationExercisePeriod.displayName}
          </VerticallyLabeledValue>
        </WatchoutTooltipItem>
      );
    }
    case "PULLEY_FIRST_GRANT": {
      return (
        <WatchoutTooltipItem
          key="pulley_first_grant"
          title={<>Company first Pulley grant via {applicationName}</>}
        >
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            You need to contact Pulley support so they can enable &quot;prepend
            terms&quot; option for {organization.name}.
          </WatchoutNoticeMessage>
        </WatchoutTooltipItem>
      );
    }
    case "VESTING_SCHEDULE_SHOULD_BE_EQUAL_TO_GRANTEE_DEFAULT_VESTING_SCHEDULE": {
      return (
        <WatchoutTooltipItem key="vesting-schedule" title="Vesting schedule">
          <WatchoutNoticeMessage watchOutType={watchout.type}>
            Vesting schedule differs from default
          </WatchoutNoticeMessage>
          <VerticallyLabeledValue label="Default Vesting">
            {grant.grantee.defaultVestingSchedule?.name || "-"}
          </VerticallyLabeledValue>
          <VerticallyLabeledValue label="This grant">
            {grant.vestingSchedule.name}
          </VerticallyLabeledValue>
        </WatchoutTooltipItem>
      );
    }
    case "VESTING_START_DATE_SHOULD_BE_EQUAL_TO_CONTRACT_START_DATE_FOR_INITIAL_GRANTS": {
      return (
        <WatchoutTooltipItem
          key="vesting-start-date"
          title="Vesting start date"
        >
          <VestingStartDateDiffersFromContractStartDateWatchout
            watchOutType={watchout.type}
          />
          <VerticallyLabeledValue label="Contract start date">
            <ShortDate value={grant.grantee.contractStartDate} />
          </VerticallyLabeledValue>
          <VerticallyLabeledValue label="Vesting start date">
            <ShortDate value={grant.vestingStartDate} />
          </VerticallyLabeledValue>
        </WatchoutTooltipItem>
      );
    }
    case "VESTING_START_DATE_SHOULD_DIFFER_FROM_CONTRACT_START_DATE_FOR_REFRESHER_GRANTS": {
      return (
        <WatchoutTooltipItem
          key="vesting-start-date"
          title="Vesting start date"
        >
          <VestingStartDateEqualsContractStartDateWatchout
            watchOutType={watchout.type}
          />
          <VerticallyLabeledValue label="Contract start date">
            <ShortDate value={grant.grantee.contractStartDate} />
          </VerticallyLabeledValue>
          <VerticallyLabeledValue label="Vesting start date">
            <ShortDate value={grant.vestingStartDate} />
          </VerticallyLabeledValue>
        </WatchoutTooltipItem>
      );
    }
  }
};

const DeferredWatchout: React.FC<{
  grantFragment: DraftWatchoutTag_Deferred_EasopGrant$key;
  organizationFragment: DraftWatchoutTag_Organization$key;
}> = ({ grantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grant = useFragment(DEFERRED_GRANT_FRAGMENT, grantFragment);

  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  const applicationName = useApplicationName();

  const watchOutTooltipItems = useMemo(
    () =>
      grant.watchouts.reduce<React.ReactElement[]>(
        (watchOutTooltipItems, watchout) => {
          const watchOutTooltipItem = watchoutToTooltipItem({
            applicationName,
            ctmsName: organizationCTMS?.name ?? null,
            grant,
            organization,
            watchout,
          });

          if (watchOutTooltipItem) {
            watchOutTooltipItems.push(watchOutTooltipItem);
          }

          return watchOutTooltipItems;
        },
        [],
      ),
    [grant, organizationCTMS, organization, applicationName],
  );

  const hasWarnings = useMemo(
    () => grant.watchouts.some((watchout) => watchout.type === "WARNING"),
    [grant.watchouts],
  );

  if (isEmpty(watchOutTooltipItems)) return null;

  return (
    <WatchOutWrapper watchOutTooltipItems={watchOutTooltipItems}>
      {!hasWarnings ? (
        <Tag
          className="cursor-help"
          color="purple"
          icon={<InformationCircleIcon />}
          size="Extra Small"
        />
      ) : (
        <Tag
          className="cursor-help"
          color="orange"
          icon={<ExclamationTriangleIcon />}
          size="Extra Small"
        />
      )}
    </WatchOutWrapper>
  );
};

const Watchout: React.FC<{
  grantFragment: DraftWatchoutTag_EasopGrant$key;
  organizationFragment: DraftWatchoutTag_Organization$key;
}> = ({ grantFragment, organizationFragment }) => {
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);
  return (
    <Suspense fallback={<LoadingSpinner className="h-4 w-4" />}>
      <DeferredWatchout
        grantFragment={grant}
        organizationFragment={organizationFragment}
      />
    </Suspense>
  );
};

type GranteePositionToEquityGridLevel = Exclude<
  ReturnType<typeof useGranteePositionToEquityGridLevel>,
  "NO_LEVEL" | "OFF_GRID"
>;

const EquityGridLevelNoticeMessage: React.FC<{
  granteePositionToEquityGridLevel: GranteePositionToEquityGridLevel;
}> = ({ granteePositionToEquityGridLevel }) => {
  if (granteePositionToEquityGridLevel === null) return null;

  switch (granteePositionToEquityGridLevel) {
    case "ABOVE_LEVEL":
      return (
        <NoticeMessage size="Small" variant="Positive">
          👆 Above equity grid level
        </NoticeMessage>
      );
    case "BELOW_LEVEL":
      return (
        <NoticeMessage size="Small" variant="Warning">
          👇 Below equity grid level
        </NoticeMessage>
      );
    case "RIGHT_ON_LEVEL":
      return (
        <NoticeMessage size="Small" variant="Positive">
          👌 Right on equity grid level
        </NoticeMessage>
      );
  }
};
const _EquityGridLevelTag: React.ForwardRefRenderFunction<
  HTMLElement,
  {
    granteePositionToEquityGridLevel: GranteePositionToEquityGridLevel;
  }
> = ({ granteePositionToEquityGridLevel }, ref) => {
  if (granteePositionToEquityGridLevel === null) return null;

  switch (granteePositionToEquityGridLevel) {
    case "ABOVE_LEVEL":
      return (
        <Tag className="cursor-help" color="green" ref={ref} size="Extra Small">
          <span className="h-4 font-emoji">👆</span>
        </Tag>
      );
    case "BELOW_LEVEL":
      return (
        <Tag
          className="cursor-help"
          color="orange"
          ref={ref}
          size="Extra Small"
        >
          <span className="h-4 font-emoji">👇</span>
        </Tag>
      );
    case "RIGHT_ON_LEVEL":
      return (
        <Tag className="cursor-help" color="green" ref={ref} size="Extra Small">
          <span className="h-4 font-emoji">👌</span>
        </Tag>
      );
  }
};

const EquityGridLevelTag = React.forwardRef(_EquityGridLevelTag);

const EquityGridWatchoutTooltipItem: React.FC<{
  grant: EasopGrant;
  grantee: NonNullableProperties<EasopGrant["grantee"], "equityGridLevel">;
  granteePositionToEquityGridLevel: GranteePositionToEquityGridLevel;
  organization: DraftWatchoutTag_Organization$data;
}> = ({ grant, grantee, granteePositionToEquityGridLevel, organization }) => {
  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: organization,
  });

  const totalGrantedShares =
    grantee.totalGrantedSharesBreakdown.total + grant.quantityGranted;
  const totalGrantedValue = sharesToValue(totalGrantedShares);

  return (
    <WatchoutTooltipItem title="Equity grid level">
      <EquityGridLevelNoticeMessage
        granteePositionToEquityGridLevel={granteePositionToEquityGridLevel}
      />
      <div className="flex items-center gap-2">
        <Tag color="gray" size="Extra Small">
          {grantee.equityGridLevel.name}
        </Tag>
        <Typography variant="Regular/Caption">{grantee.jobTitle}</Typography>
      </div>
      <VerticallyLabeledValue label="Grid level ownership">
        <FormattedNumber
          currency="USD"
          maximumFractionDigits={0}
          style="currency"
          value={grantee.equityGridLevel.equityInUsd}
        />
      </VerticallyLabeledValue>
      <VerticallyLabeledValue label="This grantee">
        {totalGrantedValue !== null ? (
          <FormattedNumber
            currency="USD"
            maximumFractionDigits={0}
            style="currency"
            value={totalGrantedValue}
          />
        ) : (
          <>
            <FormattedNumber value={totalGrantedShares} /> shares
          </>
        )}
      </VerticallyLabeledValue>
    </WatchoutTooltipItem>
  );
};

const DeferredEquityGridWatchout: React.FC<{
  grantFragment: DraftWatchoutTag_Deferred_EasopGrant$key;
  organizationFragment: DraftWatchoutTag_Organization$key;
}> = ({ grantFragment, organizationFragment }) => {
  const grant = useFragment(DEFERRED_GRANT_FRAGMENT, grantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { grantee } = grant;

  const granteePositionToEquityGridLevel = useGranteePositionToEquityGridLevel({
    grantedSharesInDraft: grant.quantityGranted,
    granteeFragment: grant.grantee,
  });

  if (!organization.equityGrid.activated) return null;

  if (!checkPropertiesNotNull(grantee, "equityGridLevel")) return null;

  if (
    granteePositionToEquityGridLevel === "OFF_GRID" ||
    granteePositionToEquityGridLevel === "NO_LEVEL"
  )
    return null;

  return (
    <WatchOutWrapper
      watchOutTooltipItems={[
        <EquityGridWatchoutTooltipItem
          grant={grant}
          grantee={grantee}
          granteePositionToEquityGridLevel={granteePositionToEquityGridLevel}
          key="equity-grid-level"
          organization={organization}
        />,
      ]}
    >
      <EquityGridLevelTag
        granteePositionToEquityGridLevel={granteePositionToEquityGridLevel}
      />
    </WatchOutWrapper>
  );
};

const EquityGridWatchout: React.FC<{
  grantFragment: DraftWatchoutTag_EasopGrant$key;
  organizationFragment: DraftWatchoutTag_Organization$key;
}> = ({ grantFragment, organizationFragment }) => {
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);
  return (
    <Suspense fallback={<LoadingSpinner className="h-4 w-4" />}>
      <DeferredEquityGridWatchout
        grantFragment={grant}
        organizationFragment={organizationFragment}
      />
    </Suspense>
  );
};

export const DraftWatchoutTag = {
  EquityGridWatchout,
  Watchout,
};
