import { isNil } from "lodash";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { InformationRows } from "../../../../components/ui/InformationRows";
import { GrantPostTerminationRow_Organization$key } from "./__generated__/GrantPostTerminationRow_Organization.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantPostTerminationRow_Organization on Organization {
    hasCooleyAsOutsideCounsel
  }
`;

export const GrantPostTerminationRow: React.FC<{
  extendedStandardPostTerminationExercisePeriod?: null | number;

  extendedStandardPostTerminationExercisePeriodUnit?: null | string;
  extendedStandardPostTerminationExercisePeriodYearsOfServiceRelationshipThreshold?:
    | null
    | number;
  hasVariableStandardPostTerminationExercisePeriod?: boolean;

  isVirtual: boolean;
  organizationFragment: GrantPostTerminationRow_Organization$key;
  standardPostTerminationExercisePeriod: number;
  standardPostTerminationExercisePeriodUnit: string;
}> = ({
  extendedStandardPostTerminationExercisePeriod,
  extendedStandardPostTerminationExercisePeriodUnit,
  extendedStandardPostTerminationExercisePeriodYearsOfServiceRelationshipThreshold,
  hasVariableStandardPostTerminationExercisePeriod,
  isVirtual,
  organizationFragment,
  standardPostTerminationExercisePeriod,
  standardPostTerminationExercisePeriodUnit,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <GrantReviewRow
      title={
        isVirtual
          ? "Post-termination settlement period"
          : "Post-termination exercise period"
      }
    >
      <InformationRows>
        <InformationRows.Row label="Period">
          <FormattedNumber
            style="unit"
            unit={standardPostTerminationExercisePeriodUnit}
            unitDisplay="long"
            value={standardPostTerminationExercisePeriod}
          />
        </InformationRows.Row>
        {!organization.hasCooleyAsOutsideCounsel && (
          <InformationRows.Row
            label={
              isVirtual
                ? "Variable post-termination settlement period"
                : "Variable post-termination exercise period"
            }
          >
            {hasVariableStandardPostTerminationExercisePeriod ? "Yes" : "No"}
          </InformationRows.Row>
        )}
        {hasVariableStandardPostTerminationExercisePeriod &&
          typeof extendedStandardPostTerminationExercisePeriodYearsOfServiceRelationshipThreshold ===
            "number" && (
            <InformationRows.Row label="Activate extended period after">
              <FormattedNumber
                style="unit"
                unit="year"
                unitDisplay="long"
                value={
                  extendedStandardPostTerminationExercisePeriodYearsOfServiceRelationshipThreshold
                }
              />{" "}
              of service
            </InformationRows.Row>
          )}
        {hasVariableStandardPostTerminationExercisePeriod &&
          extendedStandardPostTerminationExercisePeriodUnit &&
          !isNil(extendedStandardPostTerminationExercisePeriod) && (
            <InformationRows.Row label="Period after activation">
              <FormattedNumber
                style="unit"
                unit={extendedStandardPostTerminationExercisePeriodUnit}
                unitDisplay="long"
                value={extendedStandardPostTerminationExercisePeriod}
              />
            </InformationRows.Row>
          )}
      </InformationRows>
    </GrantReviewRow>
  );
};
