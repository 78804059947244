import * as Sentry from "@sentry/react";
import React, { useCallback, useMemo, useReducer } from "react";
import { useLocalStorage } from "react-use";
import { v4 } from "uuid";
import { z } from "zod";

import { safeSchema } from "../../components/SafeModal";
import { simulatedGrantSchema } from "../../components/SimulateAGrantFormModal/FORM_SCHEMA";
import { useTrackSafeSimulatorEvent } from "../../hooks/useAnalytics";
import {
  Safe,
  SafeCalculatorStateStaticValues,
  SafeWithoutId,
  SimulatedGrant,
} from "../../services/SafeCalculatorService";
import { ISafeSimulatorContextProvider } from "./IContext";

const initialState: SafeCalculatorStateStaticValues = {
  optionPoolShares: null,
  preConversionFullyDilutedShares: null,
  safes: [],
  simulatedGrants: [],
};

// ⚠️⚠️⚠️ Remember to keep the schema backwards compatible whenever you edit it ⚠️⚠️⚠️
const stateSchema = z
  .object({
    optionPoolShares: z.number().nullable(),
    preConversionFullyDilutedShares: z.number().nullable(),
  })
  .merge(
    z.object({
      safes: z.array(safeSchema.innerType().and(z.object({ id: z.string() }))),
      simulatedGrants: z
        .array(simulatedGrantSchema.and(z.object({ id: z.string() })))
        .default([]),
    }),
  );

export type SafeSimulatorContextReducerAction =
  | {
      optionPoolShares?: number;
      preConversionFullyDilutedShares?: number;
      type: "SET_SHARES";
    }
  | {
      persistedState: SafeCalculatorStateStaticValues;
      type: "DISCARD_CHANGES";
    }
  | {
      safe: Safe;
      type: "DELETE_SAFE";
    }
  | {
      safe: Safe;
      type: "EDIT_SAFE";
    }
  | {
      safe: Safe;
      type: "SET_SAFE_AS_CURRENT_VALUATION";
    }
  | {
      safe: SafeWithoutId;
      type: "ADD_SAFE";
    }
  | {
      simulatedGrant: Omit<SimulatedGrant, "id">;
      type: "ADD_SIMULATED_GRANT";
    }
  | {
      simulatedGrant: SimulatedGrant;
      type: "DELETE_SIMULATED_GRANT";
    }
  | {
      simulatedGrant: SimulatedGrant;
      type: "EDIT_SIMULATED_GRANT";
    }
  | {
      type: "RESET";
    };

type Reducer = React.Reducer<
  SafeCalculatorStateStaticValues,
  SafeSimulatorContextReducerAction
>;

const reduce: Reducer = (state, action) => {
  switch (action.type) {
    case "ADD_SAFE": {
      const newSafe = { ...action.safe, id: v4() };
      return {
        ...state,
        safes: [...state.safes, newSafe],
        simulatedGrants: [],
      };
    }
    case "ADD_SIMULATED_GRANT": {
      const newSimulatedGrant = {
        ...action.simulatedGrant,
        id: v4(),
      };
      return {
        ...state,
        simulatedGrants: [...state.simulatedGrants, newSimulatedGrant],
      };
    }
    case "DELETE_SAFE": {
      return {
        ...state,
        safes: state.safes.filter((safe) => safe.id !== action.safe.id),
        simulatedGrants: [],
      };
    }
    case "DELETE_SIMULATED_GRANT": {
      return {
        ...state,
        simulatedGrants: state.simulatedGrants.filter(
          (simulatedGrant) => simulatedGrant.id !== action.simulatedGrant.id,
        ),
      };
    }
    case "DISCARD_CHANGES": {
      return action.persistedState;
    }
    case "EDIT_SAFE": {
      return {
        ...state,
        safes: state.safes.map((safe) => {
          if (safe.id === action.safe.id) {
            return action.safe;
          }
          return safe;
        }),
        simulatedGrants: [],
      };
    }
    case "EDIT_SIMULATED_GRANT": {
      return {
        ...state,
        simulatedGrants: state.simulatedGrants.map((simulatedGrant) => {
          if (simulatedGrant.id === action.simulatedGrant.id) {
            return action.simulatedGrant;
          }
          return simulatedGrant;
        }),
      };
    }
    case "RESET": {
      return {
        optionPoolShares: null,
        preConversionFullyDilutedShares: null,
        safes: [],
        simulatedGrants: [],
      };
    }
    case "SET_SAFE_AS_CURRENT_VALUATION": {
      return {
        ...state,
        safes: state.safes.map((safe) =>
          safe.id === action.safe.id
            ? { ...safe, useAsValuation: true }
            : { ...safe, useAsValuation: false },
        ),
        simulatedGrants: [],
      };
    }
    case "SET_SHARES": {
      const preConversionFullyDilutedShares =
        action.preConversionFullyDilutedShares
          ? Math.round(action.preConversionFullyDilutedShares)
          : null;
      const optionPoolShares = action.optionPoolShares
        ? Math.round(action.optionPoolShares)
        : null;
      const cappedOptionPoolShares = optionPoolShares
        ? preConversionFullyDilutedShares
          ? Math.min(optionPoolShares, preConversionFullyDilutedShares)
          : optionPoolShares
        : null;

      return {
        ...state,
        optionPoolShares: cappedOptionPoolShares,
        preConversionFullyDilutedShares,
        simulatedGrants: [],
      };
    }
  }
};

const onStateChangeReducerWrapper =
  (
    reduce: Reducer,
    onStateChange: (props: {
      action: SafeSimulatorContextReducerAction;
      state: SafeCalculatorStateStaticValues;
    }) => void,
  ): Reducer =>
  (state, action) => {
    const newState = reduce(state, action);
    onStateChange({ action, state: newState });
    return newState;
  };

export const SafeSimulatorContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [savedState, saveState] = useLocalStorage("safe_simulator_state");
  const trackEvent = useTrackSafeSimulatorEvent({ loggedIn: false });

  const validatedSavedState = useMemo(() => {
    if (!savedState) {
      return initialState;
    }
    const result = stateSchema.safeParse(savedState);
    if (result.success) {
      return result.data;
    } else {
      Sentry.captureMessage(
        `Invalid safe simulator state : ${JSON.stringify(savedState, null, 2)}`,
      );
      Sentry.captureException(result.error);
      return initialState;
    }
  }, [savedState]);

  const onStateChange = ({
    action,
    state,
  }: {
    action: SafeSimulatorContextReducerAction;
    state: SafeCalculatorStateStaticValues;
  }) => {
    trackEvent({
      action,
    });
    saveState(state);
  };

  const [state, dispatchAction] = useReducer(
    onStateChangeReducerWrapper(reduce, onStateChange),
    validatedSavedState,
  );

  const asyncDispatchAction = useCallback(
    (...props: Parameters<typeof dispatchAction>) => {
      dispatchAction(...props);
      return Promise.resolve();
    },
    [],
  );

  return (
    <ISafeSimulatorContextProvider
      onInitialSharesUpdated={(props) =>
        asyncDispatchAction({
          type: "SET_SHARES",
          ...props,
        })
      }
      onReset={() => dispatchAction({ type: "RESET" })}
      onSafeAdded={(safe) =>
        asyncDispatchAction({
          safe,
          type: "ADD_SAFE",
        })
      }
      onSafeDeleted={(safe) =>
        asyncDispatchAction({
          safe,
          type: "DELETE_SAFE",
        })
      }
      onSafeEdited={(safe) =>
        asyncDispatchAction({
          safe,
          type: "EDIT_SAFE",
        })
      }
      onSafeSetAsCurrentValuation={(safe) =>
        asyncDispatchAction({
          safe,
          type: "SET_SAFE_AS_CURRENT_VALUATION",
        })
      }
      onSimulatedGrantAdded={(simulatedGrant) =>
        asyncDispatchAction({
          simulatedGrant,
          type: "ADD_SIMULATED_GRANT",
        })
      }
      onSimulatedGrantDeleted={(simulatedGrant) =>
        asyncDispatchAction({
          simulatedGrant,
          type: "DELETE_SIMULATED_GRANT",
        })
      }
      onSimulatedGrantEdited={(simulatedGrant) =>
        asyncDispatchAction({
          simulatedGrant,
          type: "EDIT_SIMULATED_GRANT",
        })
      }
      state={state}
    >
      {children}
    </ISafeSimulatorContextProvider>
  );
};
