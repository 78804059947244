import { formatISO } from "date-fns";
import { graphql } from "relay-runtime";

import { useQuery } from "../hooks/useQuery";
import { VestingScheduleGraph_VestingSchedule_Query } from "./__generated__/VestingScheduleGraph_VestingSchedule_Query.graphql";
import { VestingGraph } from "./VestingGraph";

const VESTING_SCHEDULE_QUERY = graphql`
  query VestingScheduleGraph_VestingSchedule_Query(
    $vestingScheduleId: VestingScheduleId!
    $vestingStartDate: Date!
    $quantityGranted: Int!
  ) {
    vestingDataPointsFromVestingScheduleId(
      vestingScheduleId: $vestingScheduleId
      vestingStartDate: $vestingStartDate
      quantityGranted: $quantityGranted
    ) @required(action: THROW) {
      cumulativeVested
      date
    }
  }
`;

export const VestingScheduleGraph: React.FC<
  React.ComponentProps<"div"> & {
    dateDisplayMode: "absolute" | "relative";
    quantityGranted: number;
    vestingScheduleId: string;
    vestingStartDate: Date;
  }
> = ({
  dateDisplayMode,
  quantityGranted,
  vestingScheduleId,
  vestingStartDate,
}) => {
  const {
    query: { vestingDataPointsFromVestingScheduleId: vestingDataPoints },
  } = useQuery<VestingScheduleGraph_VestingSchedule_Query>(
    VESTING_SCHEDULE_QUERY,
    {
      quantityGranted,
      vestingScheduleId,
      vestingStartDate: formatISO(vestingStartDate, {
        representation: "date",
      }),
    },
  );

  if (!vestingDataPoints || vestingDataPoints.length === 0) {
    return null;
  }

  return (
    <VestingGraph
      dateDisplayMode={dateDisplayMode}
      displayTodayLabel={false}
      showLegend={false}
      vestingsDataPoints={[
        {
          dataPoints: vestingDataPoints.map(({ cumulativeVested, date }) => ({
            cumulativeVested,
            date: new Date(date),
          })),
          key: "vesting_schedule_overview",
          label: "Vesting schedule preview",
        },
      ]}
      vestingStartDate={vestingDataPoints[0]?.date}
    />
  );
};
