/**
 * @generated SignedSource<<7f95da36387457e5767ebae1a2785b3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Overview_Grantee_CTMSGrant$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeGrantDetails_CTMSGrant" | "GranteeVestingSchedule_CTMSGrant">;
  readonly " $fragmentType": "Overview_Grantee_CTMSGrant";
}>;
export type Overview_Grantee_CTMSGrant$key = ReadonlyArray<{
  readonly " $data"?: Overview_Grantee_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"Overview_Grantee_CTMSGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Overview_Grantee_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeGrantDetails_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeVestingSchedule_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "de18e77bb7d4ac2c4a03f76a420d85fc";

export default node;
