import * as Sentry from "@sentry/react";
import { useEffect } from "react";

import { Account } from "../services/AuthenticationProvider";

const useIdentifyAccountInSentry = (account: Account | null) => {
  useEffect(() => {
    Sentry.setUser(
      account
        ? {
            email: account.email,
            id: account.id,
            username: `${account.firstName} ${account.lastName}`,
          }
        : null,
    );
  }, [account]);
};

export default useIdentifyAccountInSentry;
