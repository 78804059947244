import { ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Activity,
  Calendar,
  Calendar1,
  Diamonds,
  Graph,
  IconProps, // eslint-disable-next-line no-restricted-imports
  Tag,
  TicketDiscount,
  TrendDown,
} from "iconsax-react";
import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { HelpCard } from "../../../components/ui/HelpCard";
import { LargeOneColumnLayout } from "../../../components/ui/Layout/LargeOneColumnLayout";
import { Typography } from "../../../components/ui/Typography";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { useSetAdminPageVisited } from "../useSetAdminPageVisited";
import { Home_AdminInsight_Organization$key } from "./__generated__/Home_AdminInsight_Organization.graphql";
import { Home_Query } from "./__generated__/Home_Query.graphql";

const InsightLink: React.FC<{
  description: React.ReactNode;
  icon: React.ElementType<IconProps>;
  title: React.ReactNode;
  to: string;
}> = ({ description, icon: Icon, title, to }) => {
  return (
    <Link
      className="grid grid-cols-[auto_1fr_auto] items-center gap-4 border-b-[0.5px] border-gray-04 px-2 py-4 transition-all hover:bg-gray-01"
      to={to}
    >
      <div className="p-2">
        <Icon className="w-6 text-purple-05" variant="Bulk" />
      </div>
      <div className="space-y-2">
        <Typography as="div" variant="Medium/Small">
          {title}
        </Typography>
        <Typography
          as="div"
          className="text-gray-09"
          variant="Regular/Extra Small"
        >
          {description}
        </Typography>
      </div>
      <div className="p-2">
        <ChevronRightIcon className="w-5" />
      </div>
    </Link>
  );
};

const Category: React.FC<{
  children: React.ReactNode;
  title: string;
}> = ({ children, title }) => {
  return (
    <div className="space-y-2">
      <Typography className="uppercase text-gray-09" variant="Regular/Caption">
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment Home_AdminInsight_Organization on Organization {
    id
    equityGrid {
      activated
    }
    ...LargeOneColumnLayout_Organization
  }
`;

const AdminInsightsHomePage_: React.FC<{
  organizationFragment: Home_AdminInsight_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const organizationId = organization.id;

  useSetAdminPageVisited({
    organizationId,
    page: "INSIGHTS",
  });

  return (
    <LargeOneColumnLayout
      helpFooter={
        <HelpCard
          buttonLabel="Let us know"
          label="Are you missing an insight?"
        />
      }
      maxWidth={1200}
      organizationFragment={organization}
      subtitle="Overview of your company's equity repartition"
      title="Insights"
    >
      <div className="space-y-10">
        <Category title="Retention">
          <InsightLink
            description="Quantity vested by employee"
            icon={Diamonds}
            title="Quantity vested"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsQuantityVested"],
              {
                organizationId,
              },
            )}
          />
          <InsightLink
            description="Current cliff periods by employee"
            icon={TicketDiscount}
            title="Cliff periods"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsCliffPeriods"],
              {
                organizationId,
              },
            )}
          />
          <InsightLink
            description="Current status by grant"
            icon={TrendDown}
            title="Underwater grants"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsUnderwaterGrants"],
              {
                organizationId,
              },
            )}
          />
        </Category>
        <Category title="Activity">
          <InsightLink
            description="Activity by grant exercised over time"
            icon={Activity}
            title="Exercise activity"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsExercisesActivity"],
              {
                organizationId,
              },
            )}
          />
        </Category>
        <Category title="Incentivization">
          <InsightLink
            description="Current repartition by vesting status"
            icon={Graph}
            title="Team incentivization"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsTeamIncentivization"],
              {
                organizationId,
              },
            )}
          />
        </Category>
        <Category title="Company policy">
          <InsightLink
            description="Repartition by grant parameter"
            icon={Calendar1}
            title="Post-termination exercise periods"
            to={generatePath(APPLICATION_ROUTES["organizationInsightsPTEP"], {
              organizationId,
            })}
          />
          <InsightLink
            description="Repartition by grant parameter"
            icon={Calendar}
            title="Vesting schedules"
            to={generatePath(
              APPLICATION_ROUTES["organizationInsightsVestingSchedules"],
              {
                organizationId,
              },
            )}
          />
          {organization.equityGrid.activated && (
            <InsightLink
              description="Repartition and employee status by level"
              icon={Tag}
              title="Equity grid levels"
              to={generatePath(
                APPLICATION_ROUTES["organizationInsightsEquityGridLevels"],
                {
                  organizationId,
                },
              )}
            />
          )}
        </Category>
      </div>
    </LargeOneColumnLayout>
  );
};

const QUERY = graphql`
  query Home_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...Home_AdminInsight_Organization
    }
  }
`;

const AdminInsightsHomePage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<Home_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Home"
      analyticsName="Admin - Insights"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} insights`}
    >
      <AdminInsightsHomePage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default AdminInsightsHomePage;
