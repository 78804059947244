/**
 * @generated SignedSource<<d5052d8070fee69ba4fe6d5a35e4dc5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant$data = ReadonlyArray<{
  readonly label: string;
  readonly " $fragmentType": "GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant";
}>;
export type GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant$key = ReadonlyArray<{
  readonly " $data"?: GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeVestingSchedule_MissingGrantsVestingSchedule_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "caf473a2fbf162c89ab99943cd2878fb";

export default node;
