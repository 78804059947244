/**
 * @generated SignedSource<<c2723039910d6ee09975198de334537c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettingsUsageSection_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InviteGranteesModal_Viewer" | "PreviewGranteesPortalModal_Viewer">;
  readonly " $fragmentType": "GranteePortalSettingsUsageSection_Viewer";
};
export type GranteePortalSettingsUsageSection_Viewer$key = {
  readonly " $data"?: GranteePortalSettingsUsageSection_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsUsageSection_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalSettingsUsageSection_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InviteGranteesModal_Viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PreviewGranteesPortalModal_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "a08a074a2d9810b7ddd3e8dfa2686d1c";

export default node;
