/**
 * @generated SignedSource<<dda48885737d7c2f0c64ee7cc5aed51c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type Country_OrganizationGeography$data = {
  readonly activeAndTerminatedOptionGrantsCount: number;
  readonly billableGranteesCount: number;
  readonly draftCount: number;
  readonly geography: {
    readonly country: {
      readonly emoji: string;
      readonly name: string;
    };
    readonly equityTypes: ReadonlyArray<{
      readonly id: string;
      readonly instruments: ReadonlyArray<{
        readonly id: string;
        readonly workRelationship: WorkRelationship;
      }>;
      readonly name: EquityTypeName;
      readonly taxFavored: boolean;
    }>;
  };
  readonly isTaxFavoredEquityTypeUnlocked: boolean;
  readonly unlockedAt: string;
  readonly " $fragmentType": "Country_OrganizationGeography";
};
export type Country_OrganizationGeography$key = {
  readonly " $data"?: Country_OrganizationGeography$data;
  readonly " $fragmentSpreads": FragmentRefs<"Country_OrganizationGeography">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Country_OrganizationGeography",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unlockedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isTaxFavoredEquityTypeUnlocked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftCount",
      "storageKey": null
    },
    {
      "alias": "activeAndTerminatedOptionGrantsCount",
      "args": [
        {
          "kind": "Literal",
          "name": "ctmsGrantStatusIn",
          "value": [
            "Active",
            "Terminated"
          ]
        }
      ],
      "kind": "ScalarField",
      "name": "optionGrantsCount",
      "storageKey": "optionGrantsCount(ctmsGrantStatusIn:[\"Active\",\"Terminated\"])"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billableGranteesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Geography",
      "kind": "LinkedField",
      "name": "geography",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityTypes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Instrument",
              "kind": "LinkedField",
              "name": "instruments",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "workRelationship",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "taxFavored",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrganizationGeography",
  "abstractKey": null
};
})();

(node as any).hash = "fbe0d24167d2b52d1d0b4785928eb0da";

export default node;
