/**
 * @generated SignedSource<<47faef5bcb2a1e1be90fe06cd3665214>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentType": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account";
};
export type Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account$key = {
  readonly " $data"?: Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Implementation_GranteeTerminationCTMSGrantAmendmentRequests_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "fe8ecc0e1b83c2e452bea355c32d6938";

export default node;
