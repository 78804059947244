import {
  ClockIcon,
  CloudArrowDownIcon,
  DocumentCheckIcon,
  LockOpenIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../components/FreemiumBlockerCard";
import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { TerminationsFreemium_Organization$key } from "./__generated__/TerminationsFreemium_Organization.graphql";
import { TerminationsFreemium_Query } from "./__generated__/TerminationsFreemium_Query.graphql";
import TerminationsFreemiumIllustration from "./terminations-freemium-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment TerminationsFreemium_Organization on Organization {
    id
    name
    ...FreemiumBlockerCard_Organization
  }
`;

const TerminationsFreemiumPage_: React.FC<{
  organizationFragment: TerminationsFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <CenteredColumnLayout showFooter>
      <FreemiumBlockerCard
        detailsList={
          <>
            <FreemiumBlockerCard.LabelWithIcon
              icon={<ClockIcon />}
              label="Waive the cliff, accelerate vesting or extend PTEP in a few clicks"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<LockOpenIcon />}
              label="Be mindful of legal requirements and compliance"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<DocumentCheckIcon />}
              label="Learn from the experience and build your alum network"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<CloudArrowDownIcon />}
              label="Terminations are automatically implemented in Carta"
            />
          </>
        }
        mailtoSubject="About terminations"
        organizationFragment={organization}
        rightSideIllustration={
          <div
            className={classNames(
              "bg-gradient-glass-green",
              "relative w-[453px] max-w-[50%] rounded-2xl",
            )}
          >
            <div className="absolute bottom-0 left-4 right-2 top-0 flex items-center">
              <img
                alt="Terminations freemium illustration"
                src={TerminationsFreemiumIllustration}
              />
            </div>
          </div>
        }
        subTitle="Follow best practices and minimize the negative impact of terminations on employees and the company. In a few clicks, offer terminated team members a vesting acceleration or an extended post-termination exercise period for exercising their options."
        title="Conduct Equity Terminations Like a Pro"
      />
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query TerminationsFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...TerminationsFreemium_Organization
    }
  }
`;

const TerminationsFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<TerminationsFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Terminations freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} terminations freemium`}
    >
      <TerminationsFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default TerminationsFreemiumPage;
