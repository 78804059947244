import { zodResolver } from "@hookform/resolvers/zod";
import { sumBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  DefaultValues,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { graphql, useFragment } from "react-relay";

import { isNonEmptyArray } from "../../../../../helpers/ts-utlity";
import { useComputeVestingScheduleName } from "../../../../../hooks/useFormattedVestingSchedule";
import { useVestingScheduleForm_Organization$key } from "./__generated__/useVestingScheduleForm_Organization.graphql";
import {
  BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA,
  BackloadedVestingScheduleFormValues,
  LINEAR_VESTING_SCHEDULE_FORM_SCHEMA,
  LinearVestingScheduleFormValues,
} from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useVestingScheduleForm_Organization on Organization {
    ...useFormattedVestingSchedule_Organization
  }
`;

export type LinearVestingScheduleForm = ReturnType<
  typeof useLinearVestingScheduleForm
>;

export function useLinearVestingScheduleForm({
  currentVestingScheduleId,
  defaultValues: _defaultValues,
  organizationFragment,
}: {
  currentVestingScheduleId: null | string;
  defaultValues?: DefaultValues<LinearVestingScheduleFormValues> | null;
  organizationFragment: useVestingScheduleForm_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const defaultValues = useMemo(
    (): DefaultValues<LinearVestingScheduleFormValues> =>
      _defaultValues ?? {
        cliffDurationInMonths: 6,
        durationInMonths: 24,
        hasCliff: false,
        name: "24 months, no cliff, monthly vesting",
        vestedAtCliffPercentage: 25,
        vestingOccurrence: "EveryMonth",
      },
    [_defaultValues],
  );

  const form = useForm<LinearVestingScheduleFormValues>({
    defaultValues,
    resolver: zodResolver(LINEAR_VESTING_SCHEDULE_FORM_SCHEMA),
  });

  const validatedDefaultValues = useMemo(() => {
    const output = LINEAR_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(defaultValues);
    if (output.success) {
      return output.data;
    }
    return null;
  }, [defaultValues]);

  const [validatedLiveValues, setValidatedLiveValues] =
    useState<LinearVestingScheduleFormValues | null>(validatedDefaultValues);

  form.watch((values) => {
    const output = LINEAR_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(values);
    if (output.success) {
      setValidatedLiveValues(output.data);
    }
  });

  const computeVestingScheduleName = useComputeVestingScheduleName({
    currentVestingScheduleId,
    organizationFragment: organization,
  });
  const nameController = useController({
    control: form.control,
    name: "name",
  });

  form.watch((values, changed) => {
    if (changed.name !== "name") {
      const output = LINEAR_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(values);
      if (output.success) {
        const validatedValues = output.data;
        const computedVestingScheduleName = computeVestingScheduleName({
          cliffDurationInMonths: validatedValues.cliffDurationInMonths ?? 0,
          durationInMonths: validatedValues.durationInMonths,
          hasCliff: validatedValues.hasCliff,
          periods: null,
          type: "LINEAR",
          vestedAtCliffPercentage: validatedValues.vestedAtCliffPercentage ?? 0,
          vestingOccurrence: validatedValues.vestingOccurrence,
        });

        nameController.field.onChange(computedVestingScheduleName);
      }
    }
  });

  const [vestingOccurrence, hasCliff] = form.watch([
    "vestingOccurrence",
    "hasCliff",
  ]);

  return {
    control: form.control,
    handleSubmit: form.handleSubmit,
    hasCliff,
    validatedLiveValues,
    vestingOccurrence,
  };
}

const getDurationInMonthsFromValues = (
  values: DefaultValues<BackloadedVestingScheduleFormValues>,
) =>
  sumBy(values.periods ?? [], (period) =>
    period?.durationInMonths ? Number(period.durationInMonths) : 0,
  );
const getVestedPercentageFromValues = (
  values: DefaultValues<BackloadedVestingScheduleFormValues>,
) =>
  sumBy(values.periods ?? [], (period) =>
    period?.percentageVested ? Number(period.percentageVested) : 0,
  );

export type BackloadedVestingScheduleForm = ReturnType<
  typeof useBackloadedVestingScheduleForm
>;

export function useBackloadedVestingScheduleForm({
  currentVestingScheduleId,
  defaultValues: _defaultValues,
  organizationFragment,
}: {
  currentVestingScheduleId: null | string;
  defaultValues?: DefaultValues<BackloadedVestingScheduleFormValues> | null;
  organizationFragment: useVestingScheduleForm_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const defaultValues = useMemo(
    (): DefaultValues<BackloadedVestingScheduleFormValues> =>
      _defaultValues ?? {
        cliffActivatedOnFirstPeriod: false,
        name: "Backloaded: 48 months, no cliff, monthly vesting (25-25-25-25)",
        periods: [
          {
            durationInMonths: 12,
            percentageVested: 25,
          },
          {
            durationInMonths: 12,
            percentageVested: 25,
          },
          {
            durationInMonths: 12,
            percentageVested: 25,
          },
          {
            durationInMonths: 12,
            percentageVested: 25,
          },
        ],
        vestingOccurrence: "EveryMonth",
      },
    [_defaultValues],
  );

  const form = useForm<BackloadedVestingScheduleFormValues>({
    defaultValues,
    resolver: zodResolver(BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA),
  });

  const validatedDefaultValues = useMemo(() => {
    const output =
      BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(defaultValues);
    if (output.success) {
      return output.data;
    }
    return null;
  }, [defaultValues]);

  const [validatedLiveValues, setValidatedLiveValues] =
    useState<BackloadedVestingScheduleFormValues | null>(
      validatedDefaultValues,
    );

  form.watch((values) => {
    const output = BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(values);
    if (output.success) {
      setValidatedLiveValues(output.data);
    }
  });

  const [durationInMonths, setDurationInMonths] = useState(
    getDurationInMonthsFromValues(defaultValues),
  );
  const [percentageVested, setPercentageVested] = useState(
    getVestedPercentageFromValues(defaultValues),
  );

  const computeVestingScheduleName = useComputeVestingScheduleName({
    currentVestingScheduleId,
    organizationFragment: organization,
  });
  const nameController = useController({
    control: form.control,
    name: "name",
  });

  form.watch((values, changed) => {
    setDurationInMonths(getDurationInMonthsFromValues(values));
    setPercentageVested(getVestedPercentageFromValues(values));
    if (changed.name !== "name") {
      const output = BACKLOADED_VESTING_SCHEDULE_FORM_SCHEMA.safeParse(values);
      if (output.success) {
        const validatedValues = output.data;
        if (isNonEmptyArray(validatedValues.periods)) {
          const computedVestingScheduleName = computeVestingScheduleName({
            cliffDurationInMonths: validatedValues.periods[0].durationInMonths,
            durationInMonths: getDurationInMonthsFromValues(validatedValues),
            hasCliff: validatedValues.cliffActivatedOnFirstPeriod,
            periods: validatedValues.periods,
            type: "BACKLOADED",
            vestedAtCliffPercentage:
              validatedValues.periods[0].percentageVested,
            vestingOccurrence: validatedValues.vestingOccurrence,
          });

          nameController.field.onChange(computedVestingScheduleName);
        }
      }
    }
  });

  const periodsController = useFieldArray({
    control: form.control,
    name: "periods",
  });

  const addPeriod = useCallback(() => {
    periodsController.append({ durationInMonths: 12, percentageVested: 25 });
  }, [periodsController]);

  const deletePeriod = useCallback(
    (index: number) => {
      periodsController.remove(index);
    },
    [periodsController],
  );

  return {
    addPeriod,
    control: form.control,
    deletePeriod,
    handleSubmit: form.handleSubmit,
    periodsController,
    totalDurationInMonths: durationInMonths,
    totalPercentageVested: percentageVested,
    validatedLiveValues,
  };
}
