import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { ShortDate } from "../../../../../components/ShortDate";
import { Button } from "../../../../../components/ui/Button";
import { RoundedBox } from "../../../../../components/ui/RoundedBox";
import { Tag } from "../../../../../components/ui/Tag";
import { Typography } from "../../../../../components/ui/Typography";
import { GRANT_STATUS_LABEL_MAP } from "../../../../../layouts/AdminLayout/GRANT_STATUS_LABEL_MAP";
import { GranteeDetailsEquityGridLevelTag } from "../../../Grantee/GranteeDetails/GranteeDetailsEquityGridLevelTag";
import {
  DraftGrantDetailsHeader_EasopGrant$data,
  DraftGrantDetailsHeader_EasopGrant$key,
} from "./__generated__/DraftGrantDetailsHeader_EasopGrant.graphql";
import { DraftGrantDetailsHeader_Organization$key } from "./__generated__/DraftGrantDetailsHeader_Organization.graphql";

const EASOP_GRANT_FRAGMENT = graphql`
  fragment DraftGrantDetailsHeader_EasopGrant on EasopGrant {
    label
    grantStatus
    formattedGranteeName
    warnings
    instrumentValuationType
    createdAt
    quantityGranted
    grantee {
      ...GranteeDetailsEquityGridLevelTag_Grantee
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftGrantDetailsHeader_Organization on Organization {
    equityGrid {
      activated
    }
    ...GranteeDetailsEquityGridLevelTag_Organization
  }
`;

const GrantWarningTags: React.FC<{
  easopGrant: DraftGrantDetailsHeader_EasopGrant$data;
}> = ({ easopGrant }) => {
  if (easopGrant.warnings.includes("VALUATION_OUTDATED")) {
    return (
      <Tag className="uppercase" color="red">
        Outdated
      </Tag>
    );
  }

  if (
    easopGrant.warnings.includes("VALUATION_EXPIRED") ||
    easopGrant.warnings.includes("VALUATION_MISSING") ||
    easopGrant.warnings.includes("VALUATION_MANUALLY_INVALIDATED")
  ) {
    switch (easopGrant.instrumentValuationType) {
      case "FMV_409A":
        return (
          <Tag className="uppercase" color="red">
            409A valuation expired
          </Tag>
        );
      case "UK_VALUATION":
        return (
          <Tag className="uppercase" color="red">
            EMI valuation expired
          </Tag>
        );
    }
  }

  if (easopGrant.warnings.includes("VALUATION_NOT_BOARD_APPROVED")) {
    return (
      <Tag className="uppercase" color="orange">
        Pending 409A
      </Tag>
    );
  }
};

export const DraftGrantDetailsHeader: React.FC<{
  deleteGrantIsInFlight?: boolean;
  easopGrantFragment: DraftGrantDetailsHeader_EasopGrant$key;
  onDeleteButtonClicked?: () => void;
  onEditButtonClicked?: () => void;
  organizationFragment: DraftGrantDetailsHeader_Organization$key;
  showActionButtons: boolean;
}> = ({
  deleteGrantIsInFlight,
  easopGrantFragment,
  onDeleteButtonClicked,
  onEditButtonClicked,
  organizationFragment,
  showActionButtons,
}) => {
  const easopGrant = useFragment(EASOP_GRANT_FRAGMENT, easopGrantFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const { grantee } = easopGrant;

  const showEquityGridFeature = organization.equityGrid.activated;

  return (
    <RoundedBox className="flex flex-col gap-4 px-6 py-4" withBorder withShadow>
      <div className="flex items-center">
        <div className="flex items-center gap-6 divide-x-[0.5px] divide-gray-04 [&>*:not(:first-child)]:pl-6">
          <div className="flex flex-col gap-1">
            <Typography variant="Medium/Default">{easopGrant.label}</Typography>
            <Typography variant="Regular/Small">
              to {easopGrant.formattedGranteeName}
            </Typography>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Tag color="gray">
                {GRANT_STATUS_LABEL_MAP[easopGrant.grantStatus || "Draft"]}
              </Tag>
              <GrantWarningTags easopGrant={easopGrant} />
            </div>
            <Typography className="text-black-05" variant="Regular/Extra Small">
              Drafted &nbsp;
              <ShortDate value={easopGrant.createdAt} />
            </Typography>
          </div>
        </div>
        {showActionButtons ? (
          <div className="ml-auto flex gap-4">
            <Button
              loading={deleteGrantIsInFlight}
              onClick={onDeleteButtonClicked}
              size="small"
              type="button"
              variant="Danger Outline"
            >
              Delete draft
            </Button>
            <Button onClick={onEditButtonClicked} size="small" type="button">
              Edit draft
            </Button>
          </div>
        ) : null}
      </div>
      {showEquityGridFeature && (
        <div className="mr-auto">
          <GranteeDetailsEquityGridLevelTag
            grantedSharesInDraft={easopGrant.quantityGranted}
            granteeFragment={grantee}
            organizationFragment={organization}
            showActionButtons={false}
          />
        </div>
      )}
    </RoundedBox>
  );
};
