/**
 * @generated SignedSource<<ddf1d912ca584cb5cb85a01282dfb753>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantButton_DefaultGrantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewEquitySelectionModal_DefaultGrantee">;
  readonly " $fragmentType": "DraftGrantButton_DefaultGrantee";
};
export type DraftGrantButton_DefaultGrantee$key = {
  readonly " $data"?: DraftGrantButton_DefaultGrantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantButton_DefaultGrantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantButton_DefaultGrantee",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "NewEquitySelectionModal_DefaultGrantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "0ff46310dbc453e668bee3c7857d5ffd";

export default node;
