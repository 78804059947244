/**
 * @generated SignedSource<<981468fcf8e53695ab4108d5d58aec42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnusedGeographies_Organization$data = ReadonlyArray<{
  readonly id: string;
  readonly name: string;
  readonly organizationGeographies: ReadonlyArray<{
    readonly billableActiveGranteesCount: number;
    readonly geography: {
      readonly country: {
        readonly code: string;
        readonly emoji: string;
        readonly name: string;
      };
    };
    readonly hasDraftsPending: boolean;
    readonly price: {
      readonly priceAfterDiscount: number;
    } | null;
    readonly settlingGranteesCount: number;
    readonly unlockedAt: string;
  }>;
  readonly topCompany: {
    readonly country: {
      readonly code: string;
    };
  } | null;
  readonly " $fragmentType": "UnusedGeographies_Organization";
}>;
export type UnusedGeographies_Organization$key = ReadonlyArray<{
  readonly " $data"?: UnusedGeographies_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnusedGeographies_Organization">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UnusedGeographies_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "topCompany",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "country",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationGeography",
      "kind": "LinkedField",
      "name": "organizationGeographies",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasDraftsPending",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unlockedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Geography",
          "kind": "LinkedField",
          "name": "geography",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Country",
              "kind": "LinkedField",
              "name": "country",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "emoji",
                  "storageKey": null
                },
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationSpecificPrice",
          "kind": "LinkedField",
          "name": "price",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priceAfterDiscount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billableActiveGranteesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "settlingGranteesCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "2fbc5a243b9b3fde949505d34281da0b";

export default node;
