import { zodResolver } from "@hookform/resolvers/zod";
import { isNil } from "lodash";
import { useEffect, useMemo } from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { zodExtra } from "../../helpers/zod-extra";
import {
  useGrantForm_Organization$data,
  useGrantForm_Organization$key,
} from "./__generated__/useGrantForm_Organization.graphql";

const makeSchema = ({
  ctmsGrants,
  currentGrantId,
  easopGrants,
  instruments,
  maximumGrantedQuantity,
}: {
  ctmsGrants: useGrantForm_Organization$data["ctmsGrants"]["edges"][number]["node"][];
  currentGrantId?: string;
  easopGrants: useGrantForm_Organization$data["easopGrants"];
  instruments: useGrantForm_Organization$data["instruments"];
  maximumGrantedQuantity: number;
}) =>
  z
    .object({
      accelerationClause: zodExtra.accelerationClause().optional().nullable(),
      earlyExercise: z.boolean(),
      exercisePrice: zodExtra.preprocessNaNToUndefined(z.number().positive()),
      exercisePriceBelowFMVSetOn: z.string().nullish(),
      granteeId: z.string(),
      instrumentId: z.string(),
      label: z
        .string()
        .trim()
        .min(1)
        .refine(
          (label) =>
            !(
              easopGrants.some(
                (easopGrant) =>
                  easopGrant.label === label &&
                  easopGrant.id !== currentGrantId,
              ) || ctmsGrants.some((ctmsGrant) => ctmsGrant.label === label)
            ),
          "This id is already used",
        ),
      postTerminationExercisePeriodId: z.string(),
      quantityGranted: zodExtra.preprocessNaNToUndefined(
        z.number().int().positive().lte(maximumGrantedQuantity),
      ),
      vestingScheduleId: z.string(),
      vestingStartDate: z.string(),
    })
    .refine(
      ({ exercisePrice, exercisePriceBelowFMVSetOn, instrumentId }) => {
        const selectedInstrument = instruments.find(
          (instrument) => instrument.id === instrumentId,
        );
        return (
          !selectedInstrument ||
          selectedInstrument.valuation.valueInDollars === null ||
          (!exercisePriceBelowFMVSetOn &&
            selectedInstrument.valuation.valueInDollars <= exercisePrice) ||
          (!!exercisePriceBelowFMVSetOn &&
            selectedInstrument.valuation.valueInDollars > exercisePrice)
        );
      },
      {
        message:
          "You need to confirm that you want to lower the exercise price",
        path: ["exercisePrice"],
      },
    );

export type UseGrantFormInputs = z.infer<ReturnType<typeof makeSchema>>;

const ORGANIZATION_FRAGMENT = graphql`
  fragment useGrantForm_Organization on Organization
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    id
    instruments {
      id
      valuation(organizationId: $organizationId) {
        valueInDollars
      }
    }
    easopGrants {
      id
      label
    }
    ctmsGrants {
      edges {
        node {
          label
        }
      }
    }
    poolAvailableShares
    poolDraftedSharesBreakdown {
      total
    }
  }
`;

export const useGrantForm = ({
  currentGrantId,
  defaultValues,
  maximumGrantedQuantity,
  organizationFragment,
}: {
  currentGrantId?: string;
  defaultValues?: DefaultValues<UseGrantFormInputs>;
  maximumGrantedQuantity: number;
  organizationFragment: useGrantForm_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const ctmsGrants = useMemo(
    () => organization.ctmsGrants.edges.map(({ node }) => node),
    [organization.ctmsGrants.edges],
  );

  const resolver = useMemo(() => {
    return zodResolver(
      makeSchema({
        ctmsGrants,
        currentGrantId,
        easopGrants: organization.easopGrants,
        instruments: organization.instruments,
        maximumGrantedQuantity,
      }),
    );
  }, [
    maximumGrantedQuantity,
    organization.instruments,
    organization.easopGrants,
    ctmsGrants,
    currentGrantId,
  ]);

  const form = useForm({
    defaultValues,
    resolver: (data, context, options) => resolver(data, context, options),
  });

  const quantityGranted = form.watch("quantityGranted");
  const exercisePrice = form.watch("exercisePrice");
  const exercisePriceBelowFMVSetOn = form.watch("exercisePriceBelowFMVSetOn");
  const instrumentId = form.watch("instrumentId");

  const instrument = organization.instruments.find(
    (instrument) => instrument.id === instrumentId,
  );

  useEffect(() => {
    if (isNil(quantityGranted)) return;

    const cappedQuantityGranted = Math.min(
      quantityGranted,
      maximumGrantedQuantity,
    );

    form.setValue("quantityGranted", cappedQuantityGranted);
  }, [
    form,
    maximumGrantedQuantity,
    organization.poolAvailableShares,
    organization.poolDraftedSharesBreakdown.total,
    quantityGranted,
  ]);

  useEffect(() => {
    if (
      isNil(exercisePrice) ||
      !instrument ||
      instrument.valuation.valueInDollars === null
    )
      return;

    if (exercisePrice < instrument.valuation.valueInDollars) {
      form.setValue("exercisePriceBelowFMVSetOn", exercisePriceBelowFMVSetOn);
    } else {
      form.setValue("exercisePriceBelowFMVSetOn", null);
    }
  }, [form, instrument, exercisePrice, exercisePriceBelowFMVSetOn]);

  return form;
};
