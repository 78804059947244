import { Link } from "react-router-dom";

export function BlueLink({
  children,
  to,
}: React.PropsWithChildren<{
  to: string;
}>) {
  return (
    <Link className="font-medium text-primary" to={to}>
      {children}
    </Link>
  );
}
