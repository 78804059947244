/**
 * @generated SignedSource<<f0193861174d2af9e369fa02bfacc4eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type TerminationType = "DEATH" | "DISABILITY" | "INVOLUNTARY" | "RETIREMENT" | "VOLUNTARY" | "WITH_CAUSE";
import { FragmentRefs } from "relay-runtime";
export type GranteeTerminationInformation_Grantee$data = {
  readonly allCtmsGrants: ReadonlyArray<{
    readonly grantDate: string;
  }>;
  readonly email: string;
  readonly id: string;
  readonly name: string;
  readonly terminationInformations: {
    readonly terminationDate: string;
    readonly terminationLastDayAtTheCompany: string;
    readonly terminationNewRelationship: string | null;
    readonly terminationPersonalEmail: string;
    readonly terminationType: TerminationType;
  } | null;
  readonly " $fragmentType": "GranteeTerminationInformation_Grantee";
};
export type GranteeTerminationInformation_Grantee$key = {
  readonly " $data"?: GranteeTerminationInformation_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationInformation_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTerminationInformation_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GranteeTerminationInformations",
      "kind": "LinkedField",
      "name": "terminationInformations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationLastDayAtTheCompany",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationNewRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "terminationPersonalEmail",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "allCtmsGrants",
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grantDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "406e87e96cb42a4aa2ed6c42f376f2e3";

export default node;
