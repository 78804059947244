import { useCallback, useState } from "react";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { useToaster } from "./Toaster";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const DELETE_GRANT_MUTATION = graphql`
  mutation DraftGrantDeletionConfirmationModal_DeleteEasopGrant_Mutation(
    $id: UUID!
  ) {
    deleteEasopGrant(id: $id)
  }
`;

type State =
  | {
      grantId: string;
      shown: true;
    }
  | {
      grantId?: null | string;
      shown: false;
    };

export const useDraftGrantDeletionConfirmationModalState = () => {
  const [draftGrantDeletionConfirmationModalState, setState] = useState<State>({
    shown: false,
  });

  const showDraftGrantDeletionConfirmationModal = useCallback(
    (grantId: string) => {
      setState({
        grantId,
        shown: true,
      });
    },
    [],
  );

  const closeDraftGrantDeletionConfirmationModal = useCallback(() => {
    setState((previousState) => ({
      ...previousState,
      shown: false,
    }));
  }, []);

  return {
    closeDraftGrantDeletionConfirmationModal,
    draftGrantDeletionConfirmationModalState,
    showDraftGrantDeletionConfirmationModal,
  };
};

export const DraftGrantDeletionConfirmationModal: React.FC<{
  onClose: () => void;
  onGrantDeleted: (grantId: string) => void;
  state: State;
}> = ({ onClose, onGrantDeleted, state }) => {
  const [deleteGrant, deleteGrantMutationIsInflight] = useSafeMutation(
    DELETE_GRANT_MUTATION,
  );

  const toaster = useToaster();

  const handleConfirmation = useCallback(async () => {
    if (!state.grantId) {
      return;
    }

    await deleteGrant({
      variables: {
        id: state.grantId,
      },
    });

    toaster.push(<Toast title="Wonderful!">Draft successfully deleted!</Toast>);

    onGrantDeleted(state.grantId);
  }, [deleteGrant, onGrantDeleted, state, toaster]);

  return (
    <ConfirmationModal
      confirmationLabel="Delete"
      loading={deleteGrantMutationIsInflight}
      onClose={onClose}
      onConfirmed={handleConfirmation}
      show={state.shown}
      title="Delete draft"
    >
      Are you sure you want to delete this draft?
    </ConfirmationModal>
  );
};
