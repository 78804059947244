/**
 * @generated SignedSource<<ded8091e96229ff1ec173615df489cfd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type PlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "NEW_HIRE_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT" | "TERMINATION";
export type RefresherGrantPlanningEntryType = "EXCEPTIONAL_GRANT" | "LEVELING_GRANT" | "PROMOTION_GRANT" | "TENURE_GRANT";
import { FragmentRefs } from "relay-runtime";
export type PlanningEntryDetailsSlideOver_PlanningEntry$data = {
  readonly __typename: string;
  readonly editable: boolean;
  readonly id: string;
  readonly refresherType?: RefresherGrantPlanningEntryType;
  readonly type: PlanningEntryType;
  readonly " $fragmentSpreads": FragmentRefs<"NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry" | "NewHirePlanningEntryModal_NewHireGrantPlanningEntry" | "RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry" | "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry" | "TerminationPlanningEntryDetails_TerminationPlanningEntry" | "TerminationPlanningEntryModal_TerminationPlanningEntry">;
  readonly " $fragmentType": "PlanningEntryDetailsSlideOver_PlanningEntry";
};
export type PlanningEntryDetailsSlideOver_PlanningEntry$key = {
  readonly " $data"?: PlanningEntryDetailsSlideOver_PlanningEntry$data;
  readonly " $fragmentSpreads": FragmentRefs<"PlanningEntryDetailsSlideOver_PlanningEntry">;
};

import PlanningEntryDetailsSlideOver_PlanningEntry_RefetchQuery_graphql from './PlanningEntryDetailsSlideOver_PlanningEntry_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PlanningEntryDetailsSlideOver_PlanningEntry_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PlanningEntryDetailsSlideOver_PlanningEntry",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewHireGrantPlanningEntryDetails_NewHireGrantPlanningEntry"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NewHirePlanningEntryModal_NewHireGrantPlanningEntry"
        }
      ],
      "type": "NewHireGrantPlanningEntry",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "refresherType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RefresherGrantPlanningEntryModal_RefresherGrantPlanningEntry"
        }
      ],
      "type": "RefresherGrantPlanningEntry",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TerminationPlanningEntryDetails_TerminationPlanningEntry"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TerminationPlanningEntryModal_TerminationPlanningEntry"
        }
      ],
      "type": "TerminationPlanningEntry",
      "abstractKey": null
    }
  ],
  "type": "PlanningEntry",
  "abstractKey": "__isPlanningEntry"
};

(node as any).hash = "a1d361bb5aa703716911736f9e63a95b";

export default node;
