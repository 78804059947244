import { useState } from "react";
import { useInterval } from "react-use";

const useServerHealth = () => {
  const [state, setState] = useState<
    | {
        state: "loaded";
        value:
          | {
              environment: null | string;
              status: "up";
              updatedAt: Date;
              version: null | string;
            }
          | { status: "down"; updatedAt: Date };
      }
    | { state: "loading" }
  >({ state: "loading" });
  const [refreshInterval, setRefreshInterval] = useState(0);

  useInterval(async () => {
    setRefreshInterval(30 * 1000);
    const result = await fetch("/health");

    if (!result.ok) {
      setRefreshInterval(5 * 1000);
      setState({
        state: "loaded",
        value: { status: "down", updatedAt: new Date() },
      });
    } else {
      const version = result.headers.get("x-version");
      const environment = result.headers.get("x-environment");

      setState({
        state: "loaded",
        value: { environment, status: "up", updatedAt: new Date(), version },
      });
    }
  }, refreshInterval);

  return state;
};

export default useServerHealth;
