import React from "react";
import { graphql } from "react-relay";
import { Link } from "react-router-dom";

import { Modal } from "../../../../components/ui/Modal";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { OrganizationAddedEasopAsCartaLegalAdminModal_CartaOAuthFlowURL_Query } from "./__generated__/OrganizationAddedEasopAsCartaLegalAdminModal_CartaOAuthFlowURL_Query.graphql";
import { OrganizationAddedEasopAsCartaLegalAdminModal_Query } from "./__generated__/OrganizationAddedEasopAsCartaLegalAdminModal_Query.graphql";

const QUERY = graphql`
  query OrganizationAddedEasopAsCartaLegalAdminModal_Query(
    $organizationId: OrganizationId!
  ) {
    isRemoteEquityOrganizationCartaLawFirm(organizationId: $organizationId) {
      __typename
      ... on IsRemoteEquityOrganizationCartaLawFirmFailure {
        __typename
      }
      ... on IsRemoteEquityOrganizationCartaLawFirmSuccess {
        remoteEquityIsOrganizationCartaLawFirm
      }
    }
  }
`;

const OrganizationAddedEasopAsCartaLegalAdminSuccessModal: React.FC<{
  onClose: () => void;
  onExited: () => void;
  result: boolean;
  show: boolean;
}> = ({ onClose, onExited, result, show }) => {
  return (
    <Modal onClose={onClose} onExited={onExited} show={show}>
      <Modal.Content
        onClose={onClose}
        title="Has organization added easop as carta legal admin?"
      >
        {result ? (
          <>
            <span className="text-remote-green-600">Yes</span>, Easop has been
            added as a Carta legal admin
          </>
        ) : (
          <>
            <span className="text-remote-red-600">No</span>, Easop has not been
            added as a Carta legal admin
          </>
        )}
      </Modal.Content>
    </Modal>
  );
};

const CARTA_OAUTH_FLOW_URL_QUERY = graphql`
  query OrganizationAddedEasopAsCartaLegalAdminModal_CartaOAuthFlowURL_Query(
    $returnURL: String!
  ) {
    connectEasopCartaAuthorizationFlowURL(returnURL: $returnURL)
  }
`;

const OrganizationAddedEasopAsCartaLegalAdminFailureModal: React.FC<{
  onClose: () => void;
  onExited: () => void;
  show: boolean;
}> = ({ onClose, onExited, show }) => {
  const { query } =
    useQuery<OrganizationAddedEasopAsCartaLegalAdminModal_CartaOAuthFlowURL_Query>(
      CARTA_OAUTH_FLOW_URL_QUERY,
      {
        returnURL: window.location.href,
      },
    );

  return (
    <Modal onClose={onClose} onExited={onExited} show={show}>
      <Modal.Content onClose={onClose} title="Easop is not connected to Carta">
        Please click{" "}
        <Link
          className="text-remote-blueLight-600"
          to={query.connectEasopCartaAuthorizationFlowURL}
        >
          on the following link
        </Link>{" "}
        to connect Easop to Carta then try again.
      </Modal.Content>
    </Modal>
  );
};

export const OrganizationAddedEasopAsCartaLegalAdminModal: React.FC<{
  onExited: () => void;
  organizationId: string;
}> = ({ onExited, organizationId }) => {
  const { query } =
    useQuery<OrganizationAddedEasopAsCartaLegalAdminModal_Query>(QUERY, {
      organizationId,
    });
  const { setFalse: closeModal, value: modalIsOpen } = useBoolean(true);

  switch (query.isRemoteEquityOrganizationCartaLawFirm.__typename) {
    case "IsRemoteEquityOrganizationCartaLawFirmFailure":
      return (
        <OrganizationAddedEasopAsCartaLegalAdminFailureModal
          onClose={closeModal}
          onExited={onExited}
          show={modalIsOpen}
        />
      );
    case "IsRemoteEquityOrganizationCartaLawFirmSuccess":
      return (
        <OrganizationAddedEasopAsCartaLegalAdminSuccessModal
          onClose={closeModal}
          onExited={onExited}
          result={
            query.isRemoteEquityOrganizationCartaLawFirm
              .remoteEquityIsOrganizationCartaLawFirm
          }
          show={modalIsOpen}
        />
      );
    default:
      throw new Error("Unexpected typename");
  }
};
