import { zodResolver } from "@hookform/resolvers/zod";
import { startTransition, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { SelectVestingScheduleInput } from "../../../../components/ui/Form/SelectVestingScheduleInput";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { CreateSlideOverRemote as CreateVestingScheduleSlideOverRemote } from "../../Grants/Configure/VestingSchedules/CreateSlideOver";
import { NewVestingScheduleSlider_CTMSGrant$key } from "./__generated__/NewVestingScheduleSlider_CTMSGrant.graphql";
import { NewVestingScheduleSlider_Organization$key } from "./__generated__/NewVestingScheduleSlider_Organization.graphql";
import { NewVestingScheduleSlider_Unfavorable_Query } from "./__generated__/NewVestingScheduleSlider_Unfavorable_Query.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment NewVestingScheduleSlider_CTMSGrant on CTMSGrant {
    id
    vestingSchedule {
      id
    }
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment NewVestingScheduleSlider_Organization on Organization {
    ...SelectVestingScheduleInput_Organization
  }
`;

const UNFAVORABLE_QUERY = graphql`
  query NewVestingScheduleSlider_Unfavorable_Query(
    $ctmsGrantId: CtmsGrantId!
    $attributes: CTMSGrantAmendmentRequestAttributes!
  ) {
    areCTMSGrantAmendmentRequestParametersUnfavorable(
      ctmsGrantId: $ctmsGrantId
      attributes: $attributes
    )
  }
`;

const formSchema = z.object({
  vestingScheduleId: z.string(),
});

function NewVestingScheduleSlider({
  ctmsGrantFragment,
  newVestingScheduleId,
  onClose,
  onExited,
  onNewVestingScheduleChanges,
  organizationFragment,
  show,
}: {
  ctmsGrantFragment: NewVestingScheduleSlider_CTMSGrant$key;
  newVestingScheduleId: null | string;
  onClose: () => void;
  onExited?: () => void;
  onNewVestingScheduleChanges: (props: { vestingScheduleId: string }) => void;
  organizationFragment: NewVestingScheduleSlider_Organization$key;
  show: boolean;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const defaultValues = useMemo(() => {
    if (newVestingScheduleId)
      return { vestingScheduleId: newVestingScheduleId };

    if (ctmsGrant.vestingSchedule) {
      return {
        vestingScheduleId: ctmsGrant.vestingSchedule.id,
      };
    }

    return { vestingScheduleId: undefined };
  }, [newVestingScheduleId, ctmsGrant.vestingSchedule]);

  const { control, formState, handleSubmit, watch } = useForm({
    defaultValues,
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onNewVestingScheduleChanges(formSchema.parse(data));
    onClose();
  });

  const [selectedVestingScheduleId, setSelectedVestingScheduleId] = useState<
    null | string
  >(defaultValues.vestingScheduleId ?? null);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      switch (name) {
        case "vestingScheduleId": {
          const { vestingScheduleId } = value;
          startTransition(() => {
            setSelectedVestingScheduleId(vestingScheduleId ?? null);
          });
          break;
        }
        default:
          break;
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setSelectedVestingScheduleId]);

  const {
    areCTMSGrantAmendmentRequestParametersUnfavorable:
      isNewVestingScheduleUnfavorable,
  } = useLazyLoadQuery<NewVestingScheduleSlider_Unfavorable_Query>(
    UNFAVORABLE_QUERY,
    {
      attributes: {
        accelerationClauseIsModified: false,
        earlyExerciseIsModified: false,
        postTerminationExercisePeriodIsModified: false,
        vestingScheduleId: selectedVestingScheduleId,
      },
      ctmsGrantId: ctmsGrant.id,
    },
  );

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Vesting schedule
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <CreateVestingScheduleSlideOverRemote.Provider>
        <form onSubmit={onSubmit}>
          <div className="space-y-6 p-6">
            <FormRow.Form
              control={control}
              label="Vesting schedule"
              name="vestingScheduleId"
            >
              <SelectVestingScheduleInput.Form
                control={control}
                name="vestingScheduleId"
                organizationFragment={organization}
              />
            </FormRow.Form>
            {isNewVestingScheduleUnfavorable && (
              <NoticeMessage hasColor={false} size="Large" variant="Warning">
                Extending or slowing down the vesting schedule is usually
                considered as not grantee friendly. The grantee&apos;s
                acceptation will be required.
              </NoticeMessage>
            )}
          </div>
          <Divider />
          <div className="space-y-4 px-6 py-10">
            <div className="flex items-center justify-end gap-2">
              <Button
                disabled={formState.isSubmitting}
                onClick={onClose}
                size="small"
                type="button"
                variant="Secondary Full"
              >
                Cancel
              </Button>
              <Button
                loading={formState.isSubmitting}
                size="small"
                type="submit"
              >
                Save
              </Button>
            </div>
            <Typography
              as="div"
              className="text-right text-black-05"
              variant="Regular/Caption"
            >
              Amending a grant will always require the approval from the board.
            </Typography>
          </div>
        </form>
      </CreateVestingScheduleSlideOverRemote.Provider>
    </SlideOver>
  );
}

export const NewVestingScheduleSliderRemote = makeRemoteController<{
  ctmsGrantFragment: NewVestingScheduleSlider_CTMSGrant$key;
  newVestingScheduleId: null | string;
  onNewVestingScheduleChanges: (props: { vestingScheduleId: string }) => void;
  organizationFragment: NewVestingScheduleSlider_Organization$key;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <NewVestingScheduleSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        newVestingScheduleId={state.data.newVestingScheduleId}
        onClose={close}
        onExited={reset}
        onNewVestingScheduleChanges={state.data.onNewVestingScheduleChanges}
        organizationFragment={state.data.organizationFragment}
        show={state.show}
      />
    );
  },
});
