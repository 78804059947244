/**
 * @generated SignedSource<<d51c95e81dc84f1d93a5121e0b6bce2c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantableStatus = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTABLE" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "WORK_RELATIONSHIP_NOT_COVERED";
import { FragmentRefs } from "relay-runtime";
export type GranteeSelect_Grantees$data = ReadonlyArray<{
  readonly grantableStatus: GrantableStatus;
  readonly id: string;
  readonly name: string;
  readonly taxResidenceCountry: {
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly " $fragmentType": "GranteeSelect_Grantees";
}>;
export type GranteeSelect_Grantees$key = ReadonlyArray<{
  readonly " $data"?: GranteeSelect_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeSelect_Grantees">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GranteeSelect_Grantees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantableStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "bf323985492a0237b23f9eca141d987c";

export default node;
