import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useApplicationName } from "../hooks/useApplicationTheme";
import { useGranteePortalGranteeShares } from "../hooks/useGranteePortal";
import { GranteeEquity } from "../pages/Grantee/GranteeEquity";
import { GranteePotentialValue } from "../pages/Grantee/GranteePotentialValue";
import {
  GRANTEE_PORTAL_EQUITY_TYPES_GROUPS,
  useEquityTypesGroupParam,
} from "../paths";
import { GranteePortalOverviewBeginnerInformation_Grantee$key } from "./__generated__/GranteePortalOverviewBeginnerInformation_Grantee.graphql";
import { About } from "./ui/About";
import { NegativeDivider } from "./ui/Divider";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalOverviewBeginnerInformation_Grantee on Grantee {
    ...useGranteePortalGranteeShares_Grantee
    ...GranteePotentialValue_Grantee
    ...GranteeEquity_Grantee
  }
`;

const Options: React.FC<{
  granteeFragment: GranteePortalOverviewBeginnerInformation_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const { totalGrantedShares } = useGranteePortalGranteeShares({
    granteeFragment: grantee,
  });
  return (
    <>
      <Typography variant="Medium/Large">
        Do you know what stock options are?
      </Typography>

      <About title="📘 DEFINITION">
        A stock option is an option to buy shares at a certain price determined
        at the time of grant.
      </About>

      <Typography as="div" variant="Regular/Small">
        Options are neither salary nor a cash bonus. An option is an agreement
        with your company that gives you the right, if you stay long enough in
        your company, to buy a slice of the company yourself at a low price if
        your company proves to be successful 💪. This slice has been
        specifically saved and put aside for you!
      </Typography>

      <NegativeDivider />

      <Typography variant="Medium/Large">
        Is it going to make you rich?
      </Typography>

      <Typography as="div" variant="Regular/Small">
        Nothing is sure, but it might provide you with a significant gain 💸,
        which will be all the more advantageous if your company&apos;s valuation
        has increased substantially over time, that you stay for a number of
        years with the company, and if you eventually have the possibility to
        sell your shares.
      </Typography>

      <About title="💡 DID YOU KNOW">
        UK-based Fintech Revolut has turned at least 76 of its employees into
        millionaires thanks to stock options.
      </About>

      <NegativeDivider />

      <Typography variant="Medium/Large">What do you have exactly?</Typography>

      <Typography as="div" variant="Regular/Small">
        You will often be told about a number of shares{" "}
        <Tag color="orange">
          You’ve received an option to buy{" "}
          <FormattedNumber value={totalGrantedShares} /> shares
        </Tag>
        , but what really matters for you is the potential value of these
        shares. You will see below what your shares could potentially be worth
        in terms of $ value as of today:
      </Typography>

      <GranteePotentialValue granteeFragment={grantee} />

      <About title="📘 DEFINITION">
        A share is a unit of ownership of a company: the capital of a company is
        divided into a limited amount of shares, and members that own shares of
        a company are referred to as shareholders.
      </About>

      <NegativeDivider />

      <Typography variant="Medium/Large">
        However -we need to remind you-, you don’t own these shares, yet!
      </Typography>

      <Typography as="div" variant="Regular/Small">
        You&apos;ll need to work for the company for a certain time before you
        unlock the options giving you the right to buy these shares. We call
        this unlocking process the “vesting”. You will see below what options
        have already been unlocked (vested):
      </Typography>

      <GranteeEquity granteeFragment={grantee} />

      <Typography as="div" variant="Regular/Small">
        Your company has set a specific schedule 🗓 (called the “vesting
        schedule”) defining when you vest (unlock) your options. Usually, you
        will not vest (unlock) any of them in your first year at the company, to
        be sure you’re fully onboard 🚢 before allowing you to own a slice of
        the company.
      </Typography>

      <About title="📘 DEFINITION">
        Also called vesting period, the vesting schedule determines when the
        option holder may exercise their options. A common type of vesting
        schedules for startups is 4 years, with a 1 year cliff and a monthly
        vesting → 25% of the options vest at the end of the 1st year, and the
        remaining options vest on a monthly basis throughout the next 3 years.
        Another type of vesting schedule is “backloaded vesting”, in which the
        majority of the options become vested towards the later stages of the
        vesting period. Most vesting are time-based, but some may be linked to
        specific individual or collective performance or milestones.
      </About>

      <Typography as="div" variant="Regular/Small">
        You get it: the idea is to make you committed for the long run! 🤝 You
        will then progressively vest (unlock) your options, month after month
        (or quarter after quarter) as you stay at the company, until you unlock
        100% of them! You can see below how many options you have already vested
        (unlocked):
      </Typography>
    </>
  );
};

const SARs: React.FC<{
  granteeFragment: GranteePortalOverviewBeginnerInformation_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const applicationName = useApplicationName();

  return (
    <>
      <Typography variant="Medium/Large">
        Do you know what virtual stock options are?
      </Typography>

      <About title="📘 DEFINITION">
        Virtual stock options can be seen as a kind of cash bonus equal to the
        increase in the company&apos;s stock price between the time you receive
        the virtual stock options and the time the company is acquired or goes
        public (IPO).
      </About>

      <Typography as="div" variant="Regular/Small">
        Virtual stock options, also called phantom stock are a type of equity
        incentive that allow you to benefit from an increase in your
        company&apos;s stock price without actually owning any stock or stock
        options.
      </Typography>

      <NoticeMessage size="Small">
        Your legal documentation will refer to “Stock Appreciation Rights”,
        which is the technical/legal name of virtual stock options granted
        through {applicationName}.
      </NoticeMessage>

      <Typography as="div" variant="Regular/Small">
        With virtual stock options, you receive the right to a cash payment
        equal to the increase in your company&apos;s stock price between the
        date on which you receive the virtual stock options, and the date on
        which an exit event (e.g. an acquisition or IPO of your company)
        happens.
      </Typography>

      <Typography as="div" variant="Regular/Small">
        Each virtual stock option corresponds to one share of Common Stock, so
        the value of each virtual stock option will increase together with the
        value of one share of Common Stock, but you do not actually receive any
        stock or stock options. Instead you receive a “cash equivalent” bonus
        corresponding to the up-value in your company stock.
      </Typography>

      <NoticeMessage size="Small">
        Shares of Common Stock are the shares held by founders and employees, as
        opposed to the Preferred Shares, which are typically held by investors.
      </NoticeMessage>

      <NegativeDivider />

      <Typography variant="Medium/Large">
        Is it going to make you rich?
      </Typography>

      <Typography as="div" variant="Regular/Small">
        Nothing is sure, but it might provide you with a significant gain 💸,
        which will be all the more advantageous if your company&apos;s valuation
        has increased substantially over time and that you stay for a number of
        years with the company.
      </Typography>

      <Typography as="div" variant="Regular/Small">
        There must be a successful exit event for you to be able to receive your
        money. It’s not sufficient that the company shares are sold to a third
        party or to the public following an IPO, there must be proceeds for
        everyone (and investors usually get more than others because they have
        certain rights (liquidation preference rights) that give them an
        advantage compared to other shareholders).
      </Typography>

      <NegativeDivider />

      <Typography variant="Medium/Large">What do you have exactly?</Typography>

      <Typography as="div" variant="Regular/Small">
        You will often be told about a number of virtual stock options but what
        really matters for you is the potential value of these virtual stock
        options, which is related to the underlined stock of your company.
      </Typography>

      <Typography as="div" variant="Regular/Small">
        You will see below what your virtual stock options could potentially be
        worth in terms of $ value as of today:
      </Typography>

      <GranteePotentialValue granteeFragment={grantee} />

      <NegativeDivider />

      <Typography variant="Medium/Large">
        However -we need to remind you-, you don’t own these shares, yet!
      </Typography>

      <Typography as="div" variant="Regular/Small">
        You&apos;ll need to work for the company for a certain time before you
        can unlock your virtual stock options!
      </Typography>

      <Typography as="div" variant="Regular/Small">
        We call this unlocking process the “vesting”. You will see below what
        part of your virtual stock options have already been unlocked (vested):
      </Typography>

      <GranteeEquity granteeFragment={grantee} />

      <Typography as="div" variant="Regular/Small">
        Your company has set a specific schedule 🗓 (called the “vesting
        schedule”) defining when you vest (unlock) your virtual stock options.
        Usually, you will not vest (unlock) any of these in your first year at
        the company, to be sure you’re fully onboard 🚢 before allowing you to
        have right to part of the upside in the company stock price.
      </Typography>

      <About title="📘 DEFINITION">
        Also called vesting period, the vesting schedule determines when the
        option holder may exercise their options. A common type of vesting
        schedules for startups is 4 years, with a 1 year cliff and a monthly
        vesting → 25% of the options vest at the end of the 1st year, and the
        remaining options vest on a monthly basis throughout the next 3 years.
      </About>

      <Typography as="div" variant="Regular/Small">
        A common type of vesting schedules for startups is 4 years, with a 1
        year cliff and a monthly vesting → 25% of the Virtual stock options vest
        at the end of the 1st year, and the remaining virtual stock options vest
        on a monthly basis throughout the next 3 years. Another type of vesting
        schedule is “backloaded vesting”, in which the majority of the options
        become vested towards the later stages of the vesting period. Most
        vesting are time-based, but some may be linked to specific individual or
        collective performance or milestones.
      </Typography>

      <Typography as="div" variant="Regular/Small">
        You get it: the idea is to make you committed for the long run! 🤝 You
        will then progressively vest (unlock) your virtual stock options, month
        after month (or quarter after quarter) as you stay at the company, until
        you unlock 100% of them! You can see below how many virtual stock
        options you have already vested (unlocked):
      </Typography>

      <About title="💡 REMEMBER" variant="red">
        Having unlocked virtual stock options does not give you the right to
        receive your cash bonus whenever you want. It is only in case of a
        successful liquidity event that you’ll be able to receive your bonus.
      </About>
    </>
  );
};

export const GranteePortalOverviewBeginnerInformation: React.FC<{
  granteeFragment: GranteePortalOverviewBeginnerInformation_Grantee$key;
}> = ({ granteeFragment }) => {
  const equityTypesGroup = useEquityTypesGroupParam();
  switch (equityTypesGroup) {
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
      return <Options granteeFragment={granteeFragment} />;
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
      return <SARs granteeFragment={granteeFragment} />;
  }
};
