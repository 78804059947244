/**
 * @generated SignedSource<<70dbfccfc4287f14ef8ff739a283ca11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CleanupGrantees_Organization$data = {
  readonly HRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly HRISProviderEmployeesSuggestedToUpdateLinkedGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly id: string;
  readonly ignoredHRISProviderEmployeesSuggestedAsNewGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee: ReadonlyArray<{
    readonly __typename: "HRISProviderEmployee";
  }>;
  readonly name: string;
  readonly " $fragmentType": "CleanupGrantees_Organization";
};
export type CleanupGrantees_Organization$key = {
  readonly " $data"?: CleanupGrantees_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGrantees_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "ignored",
    "value": true
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CleanupGrantees_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee(ignored:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedAsNewGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedAsNewGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "HRISProviderEmployeesSuggestedAsNewGrantee(ignored:true)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "ignoredHRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
      "args": (v1/*: any*/),
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "HRISProviderEmployeesSuggestedToBeLinkedToAnExistingGrantee(ignored:true)"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "3d490ba214f1a8adee69166608f15863";

export default node;
