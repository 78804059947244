import { isBefore } from "date-fns";
import { chain, isEmpty, isNil, last, sumBy } from "lodash";

import {
  convertSharesToFullyDilutedRatio,
  convertSharesToValue,
} from "../hooks/useOrganizationSharesUtil";

export type Safe = AddTypeToISafe<ISafe>;

export type SafeWithoutId = AddTypeToISafe<Omit<ISafe, "id">>;

type AddTypeToISafe<T> = T &
  (
    | {
        mfnDate: string;
        type: "POST_MONEY_MFN";
      }
    | { type: "POST_MONEY" }
    | { type: "PRE_MONEY" }
  );
type ISafe = {
  id: string;
  lastIssuanceDate: string;
  totalAmountInvestedInUSD: number;
  useAsValuation: boolean;
  valuationCapInUSD: number;
};

type SafePostMoney = Safe & { type: "POST_MONEY" };
type SafePostMoneyMFN = Safe & {
  type: "POST_MONEY_MFN";
};
type SafePreMoney = Safe & { type: "PRE_MONEY" };

const isSafePostMoney = (safe: Safe): safe is SafePostMoney =>
  safe.type === "POST_MONEY";
const isSafePreMoney = (safe: Safe): safe is SafePreMoney =>
  safe.type === "PRE_MONEY";
const isSafePostMoneyMFN = (safe: Safe): safe is SafePostMoneyMFN =>
  safe.type === "POST_MONEY_MFN";

export type SafeCalculatorStateComputedValues = {
  currentShareholdersShares: null | number;
  postConversionCurrentPricePerShare: null | number;
  postConversionCurrentShareholdersOwnership: null | number;
  postConversionFullyDilutedShares: null | number;
  postConversionOptionPoolOwnership: null | number;
  postConversionSafeOwnership: null | number;
  postConversionSafeShares: null | number;
  preConversionCurrentShareholdersOwnership: null | number;
  preConversionOptionPoolOwnership: null | number;
  safesWithPostConversionShares: SafeWithPostConversionShares[];
  simulatedGrantsWithComputedValues: SimulatedGrantWithComputedValues[];
};

export type SafeCalculatorStateStaticValues = {
  optionPoolShares: null | number;
  preConversionFullyDilutedShares: null | number;
  safes: Safe[];
  simulatedGrants: SimulatedGrant[];
};

export type SimulatedGrant = {
  grantedSharesInputMode: "FULLY_DILUTED" | "SHARES" | "USD_VALUE";
  id: string;
  shares: number;
  type: "POST_MONEY" | "PRE_MONEY";
};

export type SimulatedGrantWithComputedValues = SimulatedGrant & {
  postConversionOwnership: number;
  postConversionValueInUSD: null | number;
  preConversionOwnership: number;
};

type PostMoneyMFNSafeWithPostConversionShares =
  WithPostConversionShares<SafePostMoneyMFN>;
type PostMoneySafeWithPostConversionShares =
  WithPostConversionShares<SafePostMoney>;
type PreMoneySafeWithPostConversionShares =
  WithPostConversionShares<SafePreMoney>;
type SafeWithPostConversionShares = WithPostConversionShares<Safe>;

type WithPostConversionShares<T> = T & {
  postConversionOwnership: null | number;
  postConversionPPSInUSD: null | number;
  postConversionShares: null | number;
};

const getComputedValueFromState = (state: SafeCalculatorStateStaticValues) => {
  const {
    optionPoolShares,
    preConversionFullyDilutedShares,
    safes,
    simulatedGrants,
  } = state;

  if (!preConversionFullyDilutedShares) {
    const computedValues: SafeCalculatorStateComputedValues = {
      currentShareholdersShares: null,
      postConversionCurrentPricePerShare: null,
      postConversionCurrentShareholdersOwnership: null,
      postConversionFullyDilutedShares: null,
      postConversionOptionPoolOwnership: null,
      postConversionSafeOwnership: null,
      postConversionSafeShares: null,
      preConversionCurrentShareholdersOwnership: null,
      preConversionOptionPoolOwnership: null,
      safesWithPostConversionShares: safes.map((safe) => ({
        ...safe,
        postConversionOwnership: null,
        postConversionPPSInUSD: null,
        postConversionShares: null,
      })),
      simulatedGrantsWithComputedValues: [],
    };

    return computedValues;
  } else {
    const optionPoolSharesAsNumber = optionPoolShares ?? 0;
    const currentShareholdersShares =
      preConversionFullyDilutedShares - optionPoolSharesAsNumber;

    const { postConversionFullyDilutedShares, safesWithPostConversionShares } =
      SafeCalculatorService.computeSafesWithPostConversionShares({
        preConversionFullyDilutedShares: preConversionFullyDilutedShares,
        safes,
      });

    const postConversionSafeShares =
      postConversionFullyDilutedShares - preConversionFullyDilutedShares;

    const preConversionCurrentShareholdersOwnership =
      currentShareholdersShares / preConversionFullyDilutedShares;

    const postConversionCurrentShareholdersOwnership =
      currentShareholdersShares / postConversionFullyDilutedShares;

    const preConversionOptionPoolOwnership =
      optionPoolSharesAsNumber / preConversionFullyDilutedShares;

    const postConversionOptionPoolOwnership =
      optionPoolSharesAsNumber / postConversionFullyDilutedShares;

    const postConversionCurrentPricePerShare =
      safesWithPostConversionShares.find((safe) => safe.useAsValuation)
        ?.postConversionPPSInUSD ??
      last(safesWithPostConversionShares)?.postConversionPPSInUSD ??
      null;

    const postConversionSafeOwnership =
      postConversionSafeShares / postConversionFullyDilutedShares;

    const simulatedGrantsWithComputedValues =
      SafeCalculatorService.computeSimulatedGrants({
        postConversionCurrentPricePerShare,
        postConversionFullyDilutedShares,
        preConversionFullyDilutedShares,
        simulatedGrants,
      });

    const computedValues: SafeCalculatorStateComputedValues = {
      currentShareholdersShares,
      postConversionCurrentPricePerShare,
      postConversionCurrentShareholdersOwnership,
      postConversionFullyDilutedShares,
      postConversionOptionPoolOwnership,
      postConversionSafeOwnership,
      postConversionSafeShares,
      preConversionCurrentShareholdersOwnership,
      preConversionOptionPoolOwnership,
      safesWithPostConversionShares,
      simulatedGrantsWithComputedValues,
    };

    return computedValues;
  }
};

const computeSafesWithPostConversionShares = ({
  preConversionFullyDilutedShares,
  safes,
}: {
  preConversionFullyDilutedShares: number;
  safes: Safe[];
}) => {
  if (isEmpty(safes)) {
    return {
      iteration: 0,
      postConversionFullyDilutedShares: preConversionFullyDilutedShares,
      safesWithPostConversionShares: [],
    };
  }

  let previousPostConversionFullyDilutedShares = null;
  let postConversionFullyDilutedShares = preConversionFullyDilutedShares;
  let preMoneySafesWithPostConversionSharesAndPricePerShare: Omit<
    PreMoneySafeWithPostConversionShares,
    "postConversionOwnership"
  >[] = [];
  let postMoneySafesWithPostConversionSharesAndPricePerShare: Omit<
    PostMoneySafeWithPostConversionShares,
    "postConversionOwnership"
  >[] = [];
  let postMoneyMFNSafesWithPostConversionSharesAndPricePerShare: Omit<
    PostMoneyMFNSafeWithPostConversionShares,
    "postConversionOwnership"
  >[] = [];

  let iteration = 0;

  while (
    previousPostConversionFullyDilutedShares !==
      postConversionFullyDilutedShares &&
    iteration < 100
  ) {
    iteration++;
    previousPostConversionFullyDilutedShares = postConversionFullyDilutedShares;

    preMoneySafesWithPostConversionSharesAndPricePerShare = chain(safes)
      .filter(isSafePreMoney)
      .map((safe) => {
        const postConversionPPSInUSD =
          safe.valuationCapInUSD / preConversionFullyDilutedShares;
        const postConversionShares = Math.round(
          safe.totalAmountInvestedInUSD / postConversionPPSInUSD,
        );
        return { ...safe, postConversionPPSInUSD, postConversionShares };
      })
      .value();

    postMoneySafesWithPostConversionSharesAndPricePerShare = chain(safes)
      .filter(isSafePostMoney)
      .map((safe) => {
        const ownership =
          safe.totalAmountInvestedInUSD / safe.valuationCapInUSD;
        const postConversionShares = Math.round(
          ownership * postConversionFullyDilutedShares,
        );
        const postConversionPPSInUSD =
          safe.totalAmountInvestedInUSD / postConversionShares;
        return { ...safe, postConversionPPSInUSD, postConversionShares };
      })
      .value();

    postMoneyMFNSafesWithPostConversionSharesAndPricePerShare = chain(safes)
      .filter(isSafePostMoneyMFN)
      .map((postMoneyMFNSafe) => {
        const postConversionPPSInUSD = chain([
          ...preMoneySafesWithPostConversionSharesAndPricePerShare,
          ...postMoneySafesWithPostConversionSharesAndPricePerShare,
        ])
          .filter(
            (safe) =>
              !isBefore(
                new Date(safe.lastIssuanceDate),
                new Date(postMoneyMFNSafe.mfnDate),
              ),
          )
          .map((safe) => safe.postConversionPPSInUSD)
          .min()
          .value();
        if (!postConversionPPSInUSD) {
          // IF MFN is the latest safe registered, we handle it as a regular post money safe
          const ownership =
            postMoneyMFNSafe.totalAmountInvestedInUSD /
            postMoneyMFNSafe.valuationCapInUSD;
          const postConversionShares = Math.round(
            ownership * postConversionFullyDilutedShares,
          );
          const postConversionPPSInUSD =
            postMoneyMFNSafe.totalAmountInvestedInUSD / postConversionShares;
          return {
            ...postMoneyMFNSafe,
            postConversionPPSInUSD,
            postConversionShares,
          };
        }
        const postConversionShares = Math.round(
          postMoneyMFNSafe.totalAmountInvestedInUSD / postConversionPPSInUSD,
        );
        return {
          ...postMoneyMFNSafe,
          postConversionPPSInUSD,
          postConversionShares,
        };
      })
      .value();

    postConversionFullyDilutedShares =
      preConversionFullyDilutedShares +
      sumBy(
        [
          ...preMoneySafesWithPostConversionSharesAndPricePerShare,
          ...postMoneySafesWithPostConversionSharesAndPricePerShare,
          ...postMoneyMFNSafesWithPostConversionSharesAndPricePerShare,
        ],
        (safe) => safe.postConversionShares ?? 0,
      );
  }

  return {
    iteration,
    postConversionFullyDilutedShares,
    safesWithPostConversionShares: chain([
      ...preMoneySafesWithPostConversionSharesAndPricePerShare,
      ...postMoneySafesWithPostConversionSharesAndPricePerShare,
      ...postMoneyMFNSafesWithPostConversionSharesAndPricePerShare,
    ])
      .sortBy((safe) => new Date(safe.lastIssuanceDate).getTime())
      .map((safe) => {
        return {
          ...safe,
          postConversionOwnership:
            safe.postConversionShares !== null
              ? safe.postConversionShares / postConversionFullyDilutedShares
              : null,
        };
      })
      .value(),
  };
};

const computeSimulatedGrants = ({
  postConversionCurrentPricePerShare,
  postConversionFullyDilutedShares,
  preConversionFullyDilutedShares,
  simulatedGrants,
}: {
  postConversionCurrentPricePerShare?: null | number;
  postConversionFullyDilutedShares: number;
  preConversionFullyDilutedShares: number;
  simulatedGrants: SimulatedGrant[];
}) => {
  return simulatedGrants.map((simulatedGrant) => {
    const preConversionOwnership = convertSharesToFullyDilutedRatio({
      fullyDilutedShares: preConversionFullyDilutedShares,
      shares: simulatedGrant.shares,
    });
    const postConversionOwnership = convertSharesToFullyDilutedRatio({
      fullyDilutedShares: postConversionFullyDilutedShares,
      shares: simulatedGrant.shares,
    });
    const postConversionValueInUSD = isNil(postConversionCurrentPricePerShare)
      ? null
      : convertSharesToValue({
          latestPricePerShare: postConversionCurrentPricePerShare,
          shares: simulatedGrant.shares,
        });

    return {
      ...simulatedGrant,
      postConversionOwnership,
      postConversionValueInUSD,
      preConversionOwnership,
    };
  });
};

export const SafeCalculatorService = {
  computeSafesWithPostConversionShares,
  computeSimulatedGrants,
  getComputedValueFromState,
};
