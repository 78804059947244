/**
 * @generated SignedSource<<b607fab2b4716a45947d9068226ce3e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderwaterGrants_Organization$data = {
  readonly activeCTMSGrants: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly equityTypeAwardName: string | null;
        readonly exercisePrice: number | null;
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"DownloadUnderwaterGrantsCSV_CTMSGrant" | "UnderwaterGrantsGraph_CTMSGrant">;
      };
    }>;
  };
  readonly id: string;
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrantsGraph_Organization">;
  readonly " $fragmentType": "UnderwaterGrants_Organization";
};
export type UnderwaterGrants_Organization$key = {
  readonly " $data"?: UnderwaterGrants_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderwaterGrants_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnderwaterGrants_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "activeCTMSGrants",
      "args": [
        {
          "kind": "Literal",
          "name": "filters",
          "value": {
            "statusIn": [
              "Active"
            ]
          }
        }
      ],
      "concreteType": "OrganizationCTMSGrantsConnection",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationCTMSGrantsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CTMSGrant",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "exercisePrice",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "equityTypeAwardName",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "DownloadUnderwaterGrantsCSV_CTMSGrant"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UnderwaterGrantsGraph_CTMSGrant"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "ctmsGrants(filters:{\"statusIn\":[\"Active\"]})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnderwaterGrantsGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "068ed57d29e9114e7808ec6f6ae047b5";

export default node;
