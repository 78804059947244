import { DocumentIcon } from "@heroicons/react/24/outline";

import { Typography } from "./ui/Typography";

export const DocumentRow: React.FC<{ label: string }> = ({ label }) => {
  return (
    <Typography
      className="flex items-center gap-4 rounded-md border-[0.5px] border-dashed border-gray-07 bg-gray-01 p-4 text-primary"
      variant="Medium/Small"
    >
      <DocumentIcon className="w-5 shrink-0 text-black-07" />
      {label}
    </Typography>
  );
};
