/**
 * @generated SignedSource<<d9de282dd26e4b6ec4440649b2803d32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WarningTaxFavoredSubplanExpired_EasopGrants$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants">;
  readonly " $fragmentType": "WarningTaxFavoredSubplanExpired_EasopGrants";
}>;
export type WarningTaxFavoredSubplanExpired_EasopGrants$key = ReadonlyArray<{
  readonly " $data"?: WarningTaxFavoredSubplanExpired_EasopGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"WarningTaxFavoredSubplanExpired_EasopGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "WarningTaxFavoredSubplanExpired_EasopGrants",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSomeEasopGrantsUsesAnExpiredTaxFavoredSubplan_EasopGrants"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "d6e7cfe25a5a4b0b3a5047ab940a80f7";

export default node;
