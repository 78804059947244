/**
 * @generated SignedSource<<a8dbbebbcd253d630c6e3574442d4705>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LargeOneColumnLayout_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Footer_Organization">;
  readonly " $fragmentType": "LargeOneColumnLayout_Organization";
};
export type LargeOneColumnLayout_Organization$key = {
  readonly " $data"?: LargeOneColumnLayout_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LargeOneColumnLayout_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Footer_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "468e9dc6c4ad865e8af32301aeab7dd7";

export default node;
