/**
 * @generated SignedSource<<3bd97eef394415f6636e442f3bcdb3f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery$variables = {
  id: string;
};
export type PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_FairMarketValueApprovals_Organization">;
  } | null;
};
export type PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery = {
  response: PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery$data;
  variables: PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_FairMarketValueApprovals_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValueBoardConsent",
                "kind": "LinkedField",
                "name": "pendingApprovalFairMarketValueBoardConsents",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "voidedReason",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isPandaDocDocumentSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SignatureRequest",
                    "kind": "LinkedField",
                    "name": "signatureRequests",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signatoryFirstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signatoryLastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FairMarketValue",
                    "kind": "LinkedField",
                    "name": "fairMarketValue",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "date",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "valuationFirm",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Document",
                        "kind": "LinkedField",
                        "name": "valuationReportDocument",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "downloadUrl",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isBoardConsent"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isConnectedToCarta",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9734f86a9b84105d82cd894bb8e51b42",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery",
    "operationKind": "query",
    "text": "query PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PendingApproval_FairMarketValueApprovals_Organization\n    id\n  }\n}\n\nfragment FairMarketValueApprovalTable_FairMarketValue on FairMarketValue {\n  id\n  date\n  value\n  valuationFirm\n  status\n  valuationReportDocument {\n    downloadUrl\n    id\n  }\n}\n\nfragment Footer_Organization on Organization {\n  isConnectedToCarta\n}\n\nfragment LargeOneColumnLayout_Organization on Organization {\n  ...Footer_Organization\n}\n\nfragment PendingApproval_FairMarketValueApprovals_Organization on Organization {\n  id\n  name\n  pendingApprovalFairMarketValueBoardConsents {\n    id\n    voidedReason\n    isPandaDocDocumentSent\n    canBoardMemberSignatureRequestReminderEmailsBeSent\n    signatureRequests {\n      id\n      completedAt\n      signatoryFirstName\n      signatoryLastName\n    }\n    fairMarketValue {\n      ...FairMarketValueApprovalTable_FairMarketValue\n      id\n    }\n    ...SendReminderButton_BoardConsent\n  }\n  ...LargeOneColumnLayout_Organization\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "4643c3dcebacf99485a22d7d7a955b90";

export default node;
