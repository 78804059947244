export const REMOTE_COLORS = {
  alabaster: "#0F1419",
  athensGray: "#0F1419",
  attention: {
    background: "#FFFCF5",
    border: "#FEC24B",
    foreground: "#B54708",
  },
  background: {
    base: "#F3F4F8",
    subtle: "#FAFCFF",
  },
  bayoux: "#4B5865",
  bayoux_15: "#E3E9EF",
  bayoux_30: "#CDD6DF",
  bayoux_45: "#9AA6B2",
  blank: "blank",
  blueLight: {
    50: "#F5FCFF",
    100: "#F0FAFF",
    200: "#E0F4FE",
    300: "#B9E7FE",
    400: "#7CD2FD",
    500: "#36B9FA",
    600: "#0BA1EC",
    700: "#0086C9",
    800: "#0267A2",
    900: "#065586",
  },
  brand: {
    50: "#EFF7FF",
    100: "#DBEDFE",
    200: "#BFDFFE",
    300: "#93CBFF",
    400: "#63B4FF",
    500: "#2496FF",
    600: "#0061FF",
    700: "#0053D9",
    800: "#0045B3",
    900: "#013D9E",
  },
  cabaret: "#0F1419",
  cabaret_15: "#0F1419",
  candlelight: "#FEC24B",
  candlelight_15: "#FFF9EB",
  candlelight_30: "#FEEEC7",
  candlelight_75: "#FEDB89",
  catskillWhite: "#0F1419",
  cerise: "#EE46B6",
  cerise_15: "#FCE7F5",
  cerulean: "#36B9FA",
  cerulean_15: "#E0F4FE",
  cerulean_30: "#B9E7FE",
  chambray: "#0F1419",
  chambray_15: "#0F1419",
  conifer: "#8DE13A",
  conifer_15: "#F3FEE7",
  conifer_30: "#E3FBCC",
  conifer_75: "#ABEF67",
  cosmos: "#0F1419",
  cyan: {
    50: "#F5FEFF",
    100: "#ECFDFF",
    200: "#CFF6FE",
    300: "#A5EDFC",
    400: "#67E1F9",
    500: "#22CCEE",
    600: "#06ABD4",
    700: "#088DB2",
    800: "#0E7290",
    900: "#156075",
  },
  darkBlue: "#0F1419",
  darkBlue_15: "#E3E9EF",
  darkBlue_30: "#CDD6DF",
  darkBlue_45: "#9AA6B2",
  darkBlue_60: "#697786",
  darkBlue_75: "#4B5865",
  darkBlue_90: "#202D39",
  darkTurquoise: "#088DB2",
  darkTurquoise_15: "#ECFDFF",
  deYork: "#32D583",
  deYork_15: "#ECFDF3",
  deYork_30: "#D1FADF",
  error: "#D92020",
  feta: "#0F1419",
  foam: "#0F1419",
  forget: "#0F1419",
  fuchsia: {
    50: "#FEFAFF",
    100: "#FDF4FF",
    200: "#FBE8FF",
    300: "#F6D0FE",
    400: "#F1ABFC",
    500: "#E879F9",
    600: "#D946EF",
    700: "#C026D3",
    800: "#A01CAF",
    900: "#83198F",
  },
  geyser: "#0F1419",
  ginFizz: "#0F1419",
  governor: "#0F1419",
  green: {
    50: "#F6FEF8",
    100: "#ECFDF3",
    200: "#D1FADF",
    300: "#A6F4C0",
    400: "#6CE996",
    500: "#32D583",
    600: "#12B749",
    700: "#039835",
    800: "#027A2A",
    900: "#056023",
  },
  greenLight: {
    50: "#FAFEF5",
    100: "#F3FEE7",
    200: "#E3FBCC",
    300: "#D1F8AB",
    400: "#ABEF67",
    500: "#8DE13A",
    600: "#71C61C",
    700: "#58A30D",
    800: "#3E7C0F",
    900: "#3A6212",
  },
  grey: {
    50: "#F8FAFC",
    100: "#EEF2F6",
    200: "#E3E9EF",
    300: "#CDD6DF",
    400: "#9AA6B2",
    500: "#697786",
    600: "#4B5865",
    700: "#364452",
    800: "#202D39",
    900: "#0F1419",
  },
  heather: "#0F1419",
  heather_15: "#0F1419",
  iceberg: "#0F1419",
  informative: {
    background: "#F5FCFF",
    border: "#93CBFF",
    foreground: "#0053D9",
  },
  inputBorder: "#CDD6DF",
  inputBorderDisabled: "#CDD6DF",
  inputBorderFocus: "#0061FF",
  inputBorderHover: "#697786",
  inputDescription: "#697786",
  inputGroupDescription: "#697786",
  inputGroupLabel: "#0F1419",
  inputLabel: "#4B5865",
  inputLabelDisabled: "#CDD6DF",
  inputPlaceholder: "#4B5865",
  inputValue: "#0F1419",
  irisBlue: "#0061FF",
  irisBlue_15: "#EFF7FF",
  irisBlue_30: "#DBEDFE",
  irisBlue_45: "#BFDFFE",
  irisBlue_60: "#93CBFF",
  irisBlue_75: "#63B4FF",
  irisBlue_90: "#2496FF",
  linkWater: "#0F1419",
  lynch: "#697786",
  lynch_15: "#F8FAFC",
  lynch_30: "#EEF2F6",
  lynch_45: "#E3E9EF",
  lynch_60: "#CDD6DF",
  lynch_75: "#9AA6B2",
  mischka: "#0F1419",
  moonRaker: "#0F1419",
  mustard: "#FEC24B",
  mustard_15: "#FFF9EB",
  mustard_30: "#FEEEC7",
  mustard_75: "#FEDB89",
  negative: {
    background: "#FFFAFA",
    border: "#FD9B9B",
    foreground: "#D92020",
  },
  neutral: {
    background: "#F8FAFC",
    border: "#9AA6B2",
    foreground: "#4B5865",
  },
  orange: {
    50: "#FFF9F5",
    100: "#FFF5ED",
    200: "#FFE7D5",
    300: "#FFCFAE",
    400: "#FF9466",
    500: "#FF5F2E",
    600: "#FF3F05",
    700: "#E63505",
    800: "#BC2406",
    900: "#971F0C",
  },
  pattensBlue: "#0F1419",
  pigeon: "#0F1419",
  pink: {
    50: "#FEF6FB",
    100: "#FDF2F9",
    200: "#FCE7F5",
    300: "#FCCEED",
    400: "#FAA7DE",
    500: "#F670C5",
    600: "#EE46B6",
    700: "#DD2593",
    800: "#C11579",
    900: "#9E165F",
  },
  positive: {
    background: "#F6FEF8",
    border: "#6CE996",
    foreground: "#027A2A",
  },
  primary: "#0061FF",
  purple: {
    50: "#FAFAFF",
    100: "#F5F3FF",
    200: "#ECE9FE",
    300: "#DCD6FE",
    400: "#C0B4FE",
    500: "#9D8AFB",
    600: "#7F5AF8",
    700: "#6638EF",
    800: "#5325DC",
    900: "#451FB8",
  },
  purpleMinsk: "#0F1419",
  radiance: "#2496FF",
  radiance_15: "#DBEDFE",
  radiance_30: "#BFDFFE",
  radiance_75: "#63B4FF",
  red: {
    50: "#FFFAFA",
    100: "#FEF2F2",
    200: "#FEE2E2",
    300: "#FECACA",
    400: "#FD9B9B",
    500: "#F96666",
    600: "#F03838",
    700: "#D92020",
    800: "#B41818",
    900: "#911818",
  },
  redPink: "#F03838",
  redPink_15: "#FEE2E2",
  redPink_30: "#FECACA",
  redPink_75: "#FD9B9B",
  royalPurple: "#0F1419",
  salmon: "#FF9466",
  salmon_15: "#FFF5ED",
  salmon_30: "#FFE7D5",
  salmon_75: "#FFCFAE",
  selago: "#0F1419",
  spindle: "#0F1419",
  turquoise: "#0F1419",
  violet: "#7F5AF8",
  violet_15: "#F5F3FF",
  violet_30: "#ECE9FE",
  white: "#FFFFFF",
  wispPink: "#0F1419",
  yellow: {
    50: "#FFFCF5",
    100: "#FFF9EB",
    200: "#FEEEC7",
    300: "#FEDB89",
    400: "#FEC24B",
    500: "#FDAD22",
    600: "#F79009",
    700: "#DC6503",
    800: "#B54708",
    900: "#933A0D",
  },
  zircon: "#0F1419",
};
