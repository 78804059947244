/**
 * @generated SignedSource<<908b7f67899dde9867366c4968e4d4bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StripePaymentMethodLabel_StripePaymentMethod$data = {
  readonly cardLast4Digits: string | null;
  readonly " $fragmentType": "StripePaymentMethodLabel_StripePaymentMethod";
};
export type StripePaymentMethodLabel_StripePaymentMethod$key = {
  readonly " $data"?: StripePaymentMethodLabel_StripePaymentMethod$data;
  readonly " $fragmentSpreads": FragmentRefs<"StripePaymentMethodLabel_StripePaymentMethod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StripePaymentMethodLabel_StripePaymentMethod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cardLast4Digits",
      "storageKey": null
    }
  ],
  "type": "StripePaymentMethod",
  "abstractKey": null
};

(node as any).hash = "a7bb35fc9f8f2f4061c4d555a0909825";

export default node;
