/**
 * @generated SignedSource<<3c2c8f71de684d129ed78d05852a541e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent$data = {
  readonly id: string;
  readonly totalGrantedShares: number;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverChartCard_GrantBoardConsent">;
  readonly " $fragmentType": "ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent";
};
export type ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalGrantedShares",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalSlideOverChartCard_GrantBoardConsent"
    }
  ],
  "type": "GrantBoardConsent",
  "abstractKey": null
};

(node as any).hash = "0fca6eb41e3444fbd5b9afc60f47e58e";

export default node;
