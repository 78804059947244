/**
 * @generated SignedSource<<19cdabe68ce65c0261469d9522c9571e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query$variables = {
  organizationId: string;
};
export type TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query$data = {
  readonly isRemoteEquityOrganizationCartaLawFirm: {
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmFailure";
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmFailure";
  } | {
    readonly __typename: "IsRemoteEquityOrganizationCartaLawFirmSuccess";
    readonly remoteEquityIsOrganizationCartaLawFirm: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query = {
  response: TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query$data;
  variables: TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "isRemoteEquityOrganizationCartaLawFirm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "remoteEquityIsOrganizationCartaLawFirm",
            "storageKey": null
          }
        ],
        "type": "IsRemoteEquityOrganizationCartaLawFirmSuccess",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49f9f4f63725a05d7f953e630f8d8800",
    "id": null,
    "metadata": {},
    "name": "TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query",
    "operationKind": "query",
    "text": "query TestLawFirmCartaConnectionView_HasOrganizationAddedEasopAsCartaLegalAdmin_Query(\n  $organizationId: OrganizationId!\n) {\n  isRemoteEquityOrganizationCartaLawFirm(organizationId: $organizationId) {\n    __typename\n    ... on IsRemoteEquityOrganizationCartaLawFirmFailure {\n      __typename\n    }\n    ... on IsRemoteEquityOrganizationCartaLawFirmSuccess {\n      remoteEquityIsOrganizationCartaLawFirm\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61fb0cf1d660a19c28ba7c35c207e10d";

export default node;
