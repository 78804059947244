/**
 * @generated SignedSource<<58fb28b3d1035b2333fdb3c861996ff9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GrantableStatus = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTABLE" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "WORK_RELATIONSHIP_NOT_COVERED";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GranteeNotGrantableAlert_Grantee$data = {
  readonly grantableStatus: GrantableStatus;
  readonly taxResidenceCountry: {
    readonly code: string;
    readonly emoji: string;
    readonly name: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentType": "GranteeNotGrantableAlert_Grantee";
};
export type GranteeNotGrantableAlert_Grantee$key = {
  readonly " $data"?: GranteeNotGrantableAlert_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeNotGrantableAlert_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeNotGrantableAlert_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantableStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emoji",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "c6cae41d052e1ce74bd5d93c8bef92da";

export default node;
