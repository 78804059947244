import { zodResolver } from "@hookform/resolvers/zod";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { SelectCartaIssuerBlock_Organization$key } from "./__generated__/SelectCartaIssuerBlock_Organization.graphql";
import {
  SelectCartaIssuerBlock_Query,
  SelectCartaIssuerBlock_Query$data,
} from "./__generated__/SelectCartaIssuerBlock_Query.graphql";
import { SelectCartaIssuerBlock_SelectCartaIssuerIdMutation } from "./__generated__/SelectCartaIssuerBlock_SelectCartaIssuerIdMutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { FormRow } from "./ui/Form/FormRow";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { RoundedBox } from "./ui/RoundedBox";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SelectCartaIssuerBlock_Organization on Organization {
    id
    selectedCartaIssuerId
  }
`;

const QUERY = graphql`
  query SelectCartaIssuerBlock_Query($organizationId: OrganizationId!) {
    issuers: listCartaIssuersWithConnectionIssuerSelectionPropertiesForOrganization(
      organizationId: $organizationId
    ) {
      cartaIssuer {
        id
        name
      }
      cannotBeSelectedReason
    }
  }
`;

const SELECT_CARTA_ISSUER_ID_MUTATION = graphql`
  mutation SelectCartaIssuerBlock_SelectCartaIssuerIdMutation(
    $organizationId: OrganizationId!
    $cartaIssuerId: String!
  ) {
    selectCartaIssuerId(
      organizationId: $organizationId
      cartaIssuerId: $cartaIssuerId
    ) {
      selectedCartaIssuerId
    }
  }
`;

type IssuerCannotBeSelectedReason =
  SelectCartaIssuerBlock_Query$data["issuers"][number]["cannotBeSelectedReason"];

export const useCartaIssuerIdSelectionSchema = ({
  issuers,
}: {
  issuers: Array<{
    cannotBeSelectedReason: IssuerCannotBeSelectedReason;
    id: string;
  }>;
}) => {
  const findIssuerFromId = useCallback(
    (cartaIssuerId: string) =>
      issuers.find((issuer) => issuer.id === cartaIssuerId),
    [issuers],
  );
  return useMemo(
    () =>
      z.string().superRefine((cartaIssuerId, ctx) => {
        const issuer = findIssuerFromId(cartaIssuerId);
        if (!issuer?.cannotBeSelectedReason) return;
        switch (issuer.cannotBeSelectedReason) {
          case "INSUFFICIENT_ACCESS_RIGHTS": {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message:
                "Your Carta account is missing access rights to some data we need. Please try again with an account from your organization with more rights (eg: legal administrator or company viewer) or contact us to get help.",
            });
            break;
          }
          case "ISSUER_ALREADY_CONNECTED": {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "This company has already been created",
            });
            break;
          }
        }
      }),
    [findIssuerFromId],
  );
};

const useFormSchema = ({
  issuers,
}: {
  issuers: Array<{
    cannotBeSelectedReason: IssuerCannotBeSelectedReason;
    id: string;
  }>;
}) => {
  const cartaIssuerId = useCartaIssuerIdSelectionSchema({ issuers });
  return z.object({
    cartaIssuerId,
  });
};

type FormInputs = z.infer<ReturnType<typeof useFormSchema>>;

export const SelectCartaIssuerBlock: React.FC<{
  organizationFragment: SelectCartaIssuerBlock_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const query = useLazyLoadQuery<SelectCartaIssuerBlock_Query>(QUERY, {
    organizationId: organization.id,
  });

  const issuers = useMemo(
    () =>
      query.issuers.map(({ cannotBeSelectedReason, cartaIssuer }) => ({
        ...cartaIssuer,
        cannotBeSelectedReason,
      })),
    [query.issuers],
  );

  const schema = useFormSchema({
    issuers,
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      cartaIssuerId: organization.selectedCartaIssuerId,
    },
    resolver: zodResolver(schema),
  });

  const [selectCartaIssuerId, selectCartaIssuerIdIsInFlight] =
    useSafeMutation<SelectCartaIssuerBlock_SelectCartaIssuerIdMutation>(
      SELECT_CARTA_ISSUER_ID_MUTATION,
    );

  const toaster = useToaster();

  const onSubmit = handleSubmit(async (_formInputs) => {
    const formInputs = _formInputs as FormInputs;
    await selectCartaIssuerId({
      variables: {
        cartaIssuerId: formInputs.cartaIssuerId,
        organizationId: organization.id,
      },
    });
    toaster.push(
      <Toast title="Well done!">You are now connected to Carta</Toast>,
    );
  });

  const cartaIssuersOptions = useMemo(
    () =>
      issuers.map((cartaIssuer) => ({
        label: cartaIssuer.name,
        value: cartaIssuer.id,
      })),
    [issuers],
  );

  if (isEmpty(issuers)) return null;

  return (
    <RoundedBox
      background="orange"
      className="border-orange px-10 py-6 text-black-07"
      withBorder
    >
      <form className="space-y-6" onSubmit={onSubmit}>
        <div className="space-y-2">
          <Typography as="div" variant="Medium/Small">
            You have more than 1 organization linked to your account in Carta
          </Typography>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            Select the Carta organization
          </Typography>
          <Controller
            control={control}
            name="cartaIssuerId"
            render={({ field, fieldState }) => (
              <FormRow error={fieldState.error?.message}>
                <SelectAutocomplete
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  onChange={(option) => field.onChange(option?.value)}
                  options={cartaIssuersOptions}
                  value={
                    field.value
                      ? cartaIssuersOptions.find(
                          (option) => option.value === field.value,
                        )
                      : null
                  }
                />
              </FormRow>
            )}
          />
        </div>
        <Button
          fullWidth
          loading={selectCartaIssuerIdIsInFlight}
          size="small"
          type="submit"
        >
          Update
        </Button>
      </form>
    </RoundedBox>
  );
};
