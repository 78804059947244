import classNames from "classnames";
import { addMonths, isFuture, isPast } from "date-fns";
import { chain, isEmpty, sortBy } from "lodash";
import { useCallback, useEffect, useMemo, useTransition } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { useLoadMoreGrantsSlideOverState } from "../../../../components/LoadMoreGrantsSlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { filterByPropertiesNotNull } from "../../../../helpers/ts-utlity";
import { CliffPeriodsGraph_Organization$key } from "./__generated__/CliffPeriodsGraph_Organization.graphql";
import { CliffPeriodsSlideOver } from "./CliffPeriodsSlideOver";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CliffPeriodsGraph_Organization on Organization {
    id
    grantees {
      edges {
        node {
          status
          ctmsGrants(grantStatusIn: [Active]) {
            id
            firstVestingEventDate
          }
          totalVestedSharesBreakdown {
            total
          }
        }
      }
    }
  }
`;

const useGetGranteePositionLabel = () => {
  const intl = useIntl();
  return useCallback(
    ({
      barKey,
      thresholdInMonths,
    }: {
      barKey: "after" | "before";
      thresholdInMonths: number;
    }) => {
      switch (barKey) {
        case "after":
          return `In ${intl.formatNumber(thresholdInMonths, {
            style: "unit",
            unit: "month",
            unitDisplay: "long",
          })} or more`;
        case "before":
          return `In less than ${intl.formatNumber(thresholdInMonths, {
            style: "unit",
            unit: "month",
            unitDisplay: "long",
          })}`;
      }
    },
    [intl],
  );
};

const Tooltip: React.FC<{
  barClassName: string;
  barKey: "after" | "before";
  granteesCount: number;
  thresholdInMonths: number;
}> = ({ barClassName, barKey, granteesCount, thresholdInMonths }) => {
  const getGranteePositionLabel = useGetGranteePositionLabel();
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grantees"
      topContent={`Passing cliff ${getGranteePositionLabel({
        barKey,
        thresholdInMonths,
      }).toLowerCase()}`}
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{granteesCount, plural,
                =0 {# grantee}
                =1 {# grantee}
                other {# grantees}
              }`}
              values={{ granteesCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

export const CliffPeriodsGraph: React.FC<{
  excludeRefreshers: boolean;
  onGranteesWhoHaventPassedCliffCountChange: (count: number) => void;
  organizationFragment: CliffPeriodsGraph_Organization$key;
  thresholdInMonths: number;
}> = ({
  excludeRefreshers,
  onGranteesWhoHaventPassedCliffCountChange,
  organizationFragment,
  thresholdInMonths,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const granteesWithUpcomingCliff = useMemo(() => {
    const activeGrantees = organization.grantees.edges
      .map((edge) => edge.node)
      .filter((grantee) => {
        switch (grantee.status) {
          case "Active":
            return true;
          case "Terminated":
            return false;
        }
      });

    const activeGranteesWithNoVestedShares = activeGrantees.filter(
      (grantee) => grantee.totalVestedSharesBreakdown.total === 0,
    );

    const granteesWithUpcomingCliff = chain(
      excludeRefreshers ? activeGranteesWithNoVestedShares : activeGrantees,
    )
      .map((grantee) => {
        const ctmsGrantsWithUpcomingCliffInTheFuture =
          filterByPropertiesNotNull(
            [...grantee.ctmsGrants],
            "firstVestingEventDate",
          ).filter((ctmsGrant) =>
            isFuture(new Date(ctmsGrant.firstVestingEventDate)),
          );

        if (isEmpty(ctmsGrantsWithUpcomingCliffInTheFuture)) {
          return null;
        }

        const ctmsGrantWithClosestCliffDate = sortBy(
          ctmsGrantsWithUpcomingCliffInTheFuture,
          (ctmsGrant) => new Date(ctmsGrant.firstVestingEventDate).getTime(),
        )[0];

        if (!ctmsGrantWithClosestCliffDate) {
          return null;
        }

        return {
          grant: ctmsGrantWithClosestCliffDate,
          grantee,
          upcomingCliffDate: new Date(
            ctmsGrantWithClosestCliffDate.firstVestingEventDate,
          ),
        };
      })
      .compact()
      .value();

    return granteesWithUpcomingCliff;
  }, [organization.grantees, excludeRefreshers]);

  useEffect(
    () =>
      onGranteesWhoHaventPassedCliffCountChange(
        granteesWithUpcomingCliff.length,
      ),
    [
      granteesWithUpcomingCliff.length,
      onGranteesWhoHaventPassedCliffCountChange,
    ],
  );

  const [
    granteesWithUpcomingCliffBeforeThreshold,
    granteesWithUpcomingCliffAfterThreshold,
  ] = useMemo(
    () =>
      chain(granteesWithUpcomingCliff)
        .partition(({ upcomingCliffDate }) =>
          isPast(addMonths(upcomingCliffDate, -thresholdInMonths)),
        )
        .value(),
    [granteesWithUpcomingCliff, thresholdInMonths],
  );

  const getGranteePositionLabel = useGetGranteePositionLabel();

  const stacks = useMemo(
    () =>
      (
        [
          {
            className: classNames("bg-green-05 fill-green-05"),
            grantees: granteesWithUpcomingCliffBeforeThreshold.map(
              ({ grantee }) => grantee,
            ),
            grants: granteesWithUpcomingCliffBeforeThreshold.map(
              ({ grant }) => grant,
            ),
            key: "before",
            label: getGranteePositionLabel({
              barKey: "before",
              thresholdInMonths,
            }),
            value: granteesWithUpcomingCliffBeforeThreshold.length,
          },
          {
            className: classNames("bg-purple-05 fill-purple-05"),
            grantees: granteesWithUpcomingCliffAfterThreshold.map(
              ({ grantee }) => grantee,
            ),
            grants: granteesWithUpcomingCliffAfterThreshold.map(
              ({ grant }) => grant,
            ),
            key: "after",
            label: getGranteePositionLabel({
              barKey: "after",
              thresholdInMonths,
            }),
            value: granteesWithUpcomingCliffAfterThreshold.length,
          },
        ] as const
      ).map((stack) => ({
        ...stack,
        elements: [
          {
            className: stack.className,
            key: stack.key,
            value: stack.value,
          },
        ],
      })),
    [
      granteesWithUpcomingCliffBeforeThreshold,
      granteesWithUpcomingCliffAfterThreshold,
      thresholdInMonths,
      getGranteePositionLabel,
    ],
  );

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useLoadMoreGrantsSlideOverState();

  const [slideOverIsOpening, startSlideOverIsOpeningTransition] =
    useTransition();

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) => {
      startSlideOverIsOpeningTransition(() => {
        openSlideOver({
          ctmsGrantsIds: stack.grants.map((grant) => grant.id),
          title: (
            <FormattedMessage
              defaultMessage={`There {granteesCount, plural, 
                =0 {is # grantee} 
                =1 {is # grantee} 
                other {are # grantees}} passing their cliff {granteePositionLabel}`}
              values={{
                granteePositionLabel: getGranteePositionLabel({
                  barKey: stack.key,
                  thresholdInMonths,
                }).toLowerCase(),
                granteesCount: stack.grantees.length,
              }}
            />
          ),
        });
      });
    },
    [getGranteePositionLabel, openSlideOver, thresholdInMonths],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        barKey={stack.key}
        granteesCount={stack.grantees.length}
        thresholdInMonths={thresholdInMonths}
      />
    ),
    [thresholdInMonths],
  );

  return (
    <>
      <CliffPeriodsSlideOver
        onClose={closeSlideOver}
        organizationId={organization.id}
        state={slideOverState}
      />
      <BarGraph
        barGap={188}
        loading={slideOverIsOpening}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={stacks}
        yLabel="# of grantees"
        yTickFormatter={(v) => v.toString()}
      />
    </>
  );
};
