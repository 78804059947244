import { ComponentProps, Fragment } from "react";
import { FormattedNumberParts } from "react-intl";

import { AnimatedNumber } from "../AnimatedNumber";

export function FormattedNumberAnimated({
  className,
  ...props
}: Omit<ComponentProps<typeof FormattedNumberParts>, "children"> & {
  className?: string;
}) {
  return (
    <FormattedNumberParts {...props}>
      {(parts) => (
        <span className={className}>
          {parts.map((part, index) => {
            switch (part.type) {
              case "integer":
                return (
                  <AnimatedNumber key={index} value={Number(part.value)} />
                );
              default:
                return <Fragment key={index}>{part.value}</Fragment>;
            }
          })}
        </span>
      )}
    </FormattedNumberParts>
  );
}
