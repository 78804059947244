/**
 * @generated SignedSource<<bb601a21fdaf79fb0e1e38e71618ed94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsComponent_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsGrant_Organization" | "DraftGrantDetailsHeader_Organization" | "GranteeDetailsBox_Organization">;
  readonly " $fragmentType": "DraftGrantDetailsComponent_Organization";
};
export type DraftGrantDetailsComponent_Organization$key = {
  readonly " $data"?: DraftGrantDetailsComponent_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsComponent_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeDetailsBox_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsGrant_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsHeader_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "671ceeb0005a53403e909039ea0761b8";

export default node;
