/**
 * @generated SignedSource<<f3a3580132f60ccec95beac9f0261a35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DurationUnit = "day" | "month" | "year";
export type PostTermination_Query$variables = {
  organizationId: string;
  search?: string | null;
};
export type PostTermination_Query$data = {
  readonly organization: {
    readonly deceasePostTerminationExercisePeriod: {
      readonly duration: number;
      readonly unit: DurationUnit;
    } | null;
    readonly disabilityPostTerminationExercisePeriod: {
      readonly duration: number;
      readonly unit: DurationUnit;
    } | null;
    readonly id: string;
    readonly name: string;
    readonly postTerminationExercisePeriods: ReadonlyArray<{
      readonly __typename: string;
      readonly id?: string;
      readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment">;
    }>;
  };
};
export type PostTermination_Query = {
  response: PostTermination_Query$data;
  variables: PostTermination_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  (v2/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "duration",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "PostTerminationExercisePeriod",
  "kind": "LinkedField",
  "name": "disabilityPostTerminationExercisePeriod",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "PostTerminationExercisePeriod",
  "kind": "LinkedField",
  "name": "deceasePostTerminationExercisePeriod",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "easopGrantUsageCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isEditable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeletable",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostTermination_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": (v4/*: any*/),
              "concreteType": null,
              "kind": "LinkedField",
              "name": "postTerminationExercisePeriods",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "OrganizationFixedPostTerminationExercisePeriod",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v6/*: any*/),
                  "type": "OrganizationVariablePostTerminationExercisePeriod",
                  "abstractKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment"
                }
              ],
              "storageKey": null
            },
            (v8/*: any*/),
            (v9/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostTermination_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "postTerminationExercisePeriods",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isOrganizationPostTerminationExercisePeriod"
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v10/*: any*/),
                "type": "OrganizationFixedPostTerminationExercisePeriod",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v10/*: any*/),
                "type": "OrganizationVariablePostTerminationExercisePeriod",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20816daf3bbd40e57a7285b37d3bf450",
    "id": null,
    "metadata": {},
    "name": "PostTermination_Query",
    "operationKind": "query",
    "text": "query PostTermination_Query(\n  $organizationId: OrganizationId!\n  $search: String\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    postTerminationExercisePeriods(search: $search) {\n      __typename\n      ... on OrganizationFixedPostTerminationExercisePeriod {\n        id\n      }\n      ... on OrganizationVariablePostTerminationExercisePeriod {\n        id\n      }\n      ...PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment\n      id\n    }\n    disabilityPostTerminationExercisePeriod {\n      duration\n      unit\n    }\n    deceasePostTerminationExercisePeriod {\n      duration\n      unit\n    }\n  }\n}\n\nfragment PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment on OrganizationPostTerminationExercisePeriod {\n  __isOrganizationPostTerminationExercisePeriod: __typename\n  __typename\n  ... on OrganizationFixedPostTerminationExercisePeriod {\n    id\n    easopGrantUsageCount\n    displayName\n    isEditable\n    isDeletable\n  }\n  ... on OrganizationVariablePostTerminationExercisePeriod {\n    id\n    easopGrantUsageCount\n    displayName\n    isEditable\n    isDeletable\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "88434826f1594c2274f3a9d6d9839886";

export default node;
