/**
 * @generated SignedSource<<f58df3b6c7867b2eaf5f54d1a2704bfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsBox_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "GranteeDetailsBox_Viewer";
};
export type GranteeDetailsBox_Viewer$key = {
  readonly " $data"?: GranteeDetailsBox_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsBox_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsBox_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "08bf9eb15560a17fd278e3bfd8dcca90";

export default node;
