/**
 * @generated SignedSource<<8ddd9a7cfe59162e58d8648b861a2c63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type LinearVestingOccurrence = "Every12Months" | "Every2Months" | "Every3Months" | "Every6Months" | "EveryMonth" | "Once";
import { FragmentRefs } from "relay-runtime";
export type VestingSchedulesTable_VestingScheduleFragment$data = {
  readonly __typename: string;
  readonly backloadedVestingOccurrence?: LinearVestingOccurrence;
  readonly cliffDurationInMonths: number;
  readonly durationInMonths: number;
  readonly easopGrantCount: number;
  readonly id: string;
  readonly isUsed: boolean;
  readonly linearVestingOccurrence?: LinearVestingOccurrence;
  readonly name: string;
  readonly " $fragmentType": "VestingSchedulesTable_VestingScheduleFragment";
};
export type VestingSchedulesTable_VestingScheduleFragment$key = {
  readonly " $data"?: VestingSchedulesTable_VestingScheduleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSchedulesTable_VestingScheduleFragment">;
};

import VestingSchedulesTable_VestingScheduleFragment_RefetchQuery_graphql from './VestingSchedulesTable_VestingScheduleFragment_RefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": VestingSchedulesTable_VestingScheduleFragment_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "VestingSchedulesTable_VestingScheduleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cliffDurationInMonths",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationInMonths",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "backloadedVestingOccurrence",
          "args": null,
          "kind": "ScalarField",
          "name": "vestingOccurrence",
          "storageKey": null
        }
      ],
      "type": "BackloadedVestingSchedule",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "linearVestingOccurrence",
          "args": null,
          "kind": "ScalarField",
          "name": "vestingOccurrence",
          "storageKey": null
        }
      ],
      "type": "LinearVestingSchedule",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "easopGrantCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isUsed",
      "storageKey": null
    }
  ],
  "type": "VestingSchedule",
  "abstractKey": "__isVestingSchedule"
};

(node as any).hash = "82cd252d49b4ae5746f4f19092068d5b";

export default node;
