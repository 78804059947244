import { BoxedIcon, BoxedIconTone } from "@remote-com/norma";
import {
  DocumentText,
  DocumentUpload,
  Flash,
  Notification,
  Send2,
} from "iconsax-react";
import { isEmpty } from "lodash";
import React, {
  ElementType,
  startTransition,
  useCallback,
  useMemo,
} from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment, useRefetchableFragment } from "react-relay";
import { generatePath, Link, To } from "react-router-dom";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES } from "../../paths";
import { FairMarketValueSendToRewiewSliderRemote } from "../FairMarketValueSendToRewiewSlider";
import { Card } from "../ui/Card";
import { NoticeMessage } from "../ui/NoticeMessage";
import { Typography } from "../ui/Typography";
import { AdminTasksCard_BoardMember$key } from "./__generated__/AdminTasksCard_BoardMember.graphql";
import { AdminTasksCard_Organization$key } from "./__generated__/AdminTasksCard_Organization.graphql";

const PolymorphicTask: React.FC<{
  children: React.ReactNode;
  className?: string;
  linkTo?: To;
  onClick?: () => void;
}> = ({ children, className, linkTo, onClick }) =>
  linkTo ? (
    <Link className={className} onClick={onClick} to={linkTo}>
      {children}
    </Link>
  ) : (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );

const Task: React.FC<{
  description: React.ReactNode;
  Icon: ElementType;
  linkTo?: To;
  onClick?: () => void;
  title: React.ReactNode;
  tone: BoxedIconTone;
}> = ({ description, Icon, linkTo, onClick, title, tone }) => (
  <PolymorphicTask
    className="flex cursor-pointer items-center gap-4 p-4 hover:bg-background-subtle"
    linkTo={linkTo}
    onClick={onClick}
  >
    <BoxedIcon Icon={Icon} size="md" tone={tone} />
    <div className="flex flex-col gap-1">
      <Typography variant="Medium/Small">{title}</Typography>
      <Typography className="text-grey-600" variant="Regular/Extra Small">
        {description}
      </Typography>
    </div>
  </PolymorphicTask>
);

const ORGANIZATION_FRAGMENT = graphql`
  fragment AdminTasksCard_Organization on Organization
  @refetchable(queryName: "AdminTasksCard_Organization_RefetchQuery") {
    id
    pendingApprovalGrantBoardConsents {
      canBoardMemberSignatureRequestReminderEmailsBeSent
    }
    pendingApprovalGrantAmendmentBoardConsents {
      canBoardMemberSignatureRequestReminderEmailsBeSent
    }
    pendingApprovalGranteeTerminationGrantAmendmentBoardConsents {
      canBoardMemberSignatureRequestReminderEmailsBeSent
    }
    pendingApprovalFairMarketValueBoardConsents {
      canBoardMemberSignatureRequestReminderEmailsBeSent
    }
    reviewedDrafts: easopGrants(filters: { statusIn: [Reviewed] }) {
      notReadyForBoardConsentReasons
    }
    grantees {
      edges {
        node {
          cannotBeInvitedReason
        }
      }
    }
    readyForConsentGranteeTerminationCTMSGrantAmendmentRequests: granteeTerminationCTMSGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    readyForConsentCTMSGrantAmendmentRequests: ctmsGrantAmendmentRequests(
      statusIn: [READY_FOR_CONSENT]
    ) {
      __typename
    }
    latestFairMarketValue {
      status
    }
  }
`;

const BOARD_MEMBER_FRAGMENT = graphql`
  fragment AdminTasksCard_BoardMember on BoardMember {
    pendingSignatureRequestsCount
    id
  }
`;

export const AdminTasksCard: React.FC<{
  boardMemberFragment: AdminTasksCard_BoardMember$key | null;
  className?: string;
  organizationFragment: AdminTasksCard_Organization$key;
}> = ({ boardMemberFragment, className, organizationFragment }) => {
  const [organization, refetchOrganization_] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );
  const boardMember = useFragment(BOARD_MEMBER_FRAGMENT, boardMemberFragment);

  const granteesWithSuggestedPortalInvitation = useMemo(
    () =>
      organization.grantees.edges
        .map((edge) => edge.node)
        .filter((grantee) => !grantee.cannotBeInvitedReason),
    [organization.grantees],
  );

  const refetchOrganization = useCallback(() => {
    startTransition(() => {
      refetchOrganization_({});
    });
  }, [refetchOrganization_]);

  const applicationName = useApplicationName();

  const fairMarketValueSendToRewiewSliderController =
    FairMarketValueSendToRewiewSliderRemote.useController();

  const tasks = useMemo(() => {
    const tasks: React.ReactNode[] = [];

    if (organization.latestFairMarketValue?.status === "Draft") {
      tasks.push(
        <Task
          description={<>Make sure to validate it into {applicationName}.</>}
          Icon={() => <DocumentUpload variant="Bulk" />}
          key="validate-fmv"
          onClick={() => {
            fairMarketValueSendToRewiewSliderController.open({
              data: {
                onValuationSentForReview: () => {
                  refetchOrganization();
                },
                organizationId: organization.id,
              },
            });
          }}
          title="Validate your new 409A"
          tone="green"
        />,
      );
    }

    if (boardMember && boardMember.pendingSignatureRequestsCount > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# board consent} other {# board consents}} awaiting your signature"
              values={{
                count: boardMember.pendingSignatureRequestsCount,
              }}
            />
          }
          Icon={() => <DocumentText variant="Bulk" />}
          key="board-member"
          linkTo={generatePath(APPLICATION_ROUTES.boardMemberApprovals, {
            boardMemberId: boardMember.id,
          })}
          title="Sign board consents"
          tone="purple"
        />,
      );
    }

    const readyForBoardConsentDrafts = organization.reviewedDrafts.filter(
      (draft) => isEmpty(draft.notReadyForBoardConsentReasons),
    );

    if (readyForBoardConsentDrafts.length > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# reviewed draft} other {# reviewed drafts}} ready for board approval"
              values={{
                count: readyForBoardConsentDrafts.length,
              }}
            />
          }
          Icon={() => <Send2 variant="Bulk" />}
          key="reviewed-easop-grants"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationPrepareYourGrantsBoardApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send drafts to your board"
          tone="fuchsia"
        />,
      );
    }

    if (organization.readyForConsentCTMSGrantAmendmentRequests.length > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# amendment} other {# amendments}} ready for board approval"
              values={{
                count:
                  organization.readyForConsentCTMSGrantAmendmentRequests.length,
              }}
            />
          }
          Icon={() => <Send2 variant="Bulk" />}
          key="ready-for-consent-grant-amendment-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequestsBoardApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send amendments to your board"
          tone="fuchsia"
        />,
      );
    }

    if (
      organization.readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
        .length > 0
    ) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# termination} other {# terminations}} ready for board approval"
              values={{
                count:
                  organization
                    .readyForConsentGranteeTerminationCTMSGrantAmendmentRequests
                    .length,
              }}
            />
          }
          Icon={() => <Send2 variant="Bulk" />}
          key="ready-for-consent-grantee-termination-grant-amendment-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsBoardApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send termination amendments to your board"
          tone="fuchsia"
        />,
      );
    }

    if (organization.latestFairMarketValue?.status === "Reviewed") {
      tasks.push(
        <Task
          description="409A ready for board approval"
          Icon={() => <Send2 variant="Bulk" />}
          key="ready-for-consent-fair-market-value"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationFairMarketValueApprovalsBoardApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send 409A to your board"
          tone="fuchsia"
        />,
      );
    }

    const pendingGrantSignatureRequestsCount =
      organization.pendingApprovalGrantBoardConsents.filter(
        (boardConsent) =>
          boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent,
      ).length;

    if (pendingGrantSignatureRequestsCount > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# board consent is} other {# board consents are}} pending signature"
              values={{
                count: pendingGrantSignatureRequestsCount,
              }}
            />
          }
          Icon={() => <Notification variant="Bulk" />}
          key="pending-grant-signature-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationPrepareYourGrantsPendingApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send a reminder"
          tone="cyan"
        />,
      );
    }

    const pendingGrantAmendmentSignatureRequestsCount =
      organization.pendingApprovalGrantAmendmentBoardConsents.filter(
        (boardConsent) =>
          boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent,
      ).length;

    if (pendingGrantAmendmentSignatureRequestsCount > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# amendment is} other {# amendments are}} pending signature"
              values={{
                count: pendingGrantAmendmentSignatureRequestsCount,
              }}
            />
          }
          Icon={() => <Notification variant="Bulk" />}
          key="pending-grant-amendment-signature-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationCTMSGrantAmendmentRequestsPendingApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send a reminder"
          tone="cyan"
        />,
      );
    }

    const pendingGranteeTerminationGrantAmendmentSignatureRequestsCount =
      organization.pendingApprovalGranteeTerminationGrantAmendmentBoardConsents.filter(
        (boardConsent) =>
          boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent,
      ).length;

    if (pendingGranteeTerminationGrantAmendmentSignatureRequestsCount > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# termination is} other {# terminations are}} pending signature"
              values={{
                count:
                  pendingGranteeTerminationGrantAmendmentSignatureRequestsCount,
              }}
            />
          }
          Icon={() => <Notification variant="Bulk" />}
          key="pending-grantee-termination-grant-amendment-signature-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationGranteeTerminationCTMSGrantAmendmentRequestsPendingApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send a reminder"
          tone="cyan"
        />,
      );
    }

    const pendingFairMarketValueSignatureRequestsCount =
      organization.pendingApprovalFairMarketValueBoardConsents.filter(
        (boardConsent) =>
          boardConsent.canBoardMemberSignatureRequestReminderEmailsBeSent,
      ).length;

    if (pendingFairMarketValueSignatureRequestsCount > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# board consent is} other {# board consents are}} pending signature"
              values={{
                count: pendingGrantSignatureRequestsCount,
              }}
            />
          }
          Icon={() => <Notification variant="Bulk" />}
          key="pending-fair-market-value-signature-requests"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationFairMarketValueApprovalsPendingApproval,
            {
              organizationId: organization.id,
            },
          )}
          title="Send a reminder"
          tone="cyan"
        />,
      );
    }

    if (granteesWithSuggestedPortalInvitation.length > 0) {
      tasks.push(
        <Task
          description={
            <FormattedMessage
              defaultMessage="{count, plural, one {# employee} other {# employees}} awaiting access to their portal"
              values={{
                count: granteesWithSuggestedPortalInvitation.length,
              }}
            />
          }
          Icon={() => <Flash variant="Bulk" />}
          key="grantees-with-suggested-portal-invitation"
          linkTo={generatePath(
            APPLICATION_ROUTES.organizationToolsGranteePortalSettings,
            {
              organizationId: organization.id,
            },
          )}
          title="Invite employees to their portal"
          tone="orange"
        />,
      );
    }

    return tasks;
  }, [
    organization,
    boardMember,
    granteesWithSuggestedPortalInvitation.length,
    applicationName,
    refetchOrganization,
    fairMarketValueSendToRewiewSliderController,
  ]);

  return (
    <Card
      className={className}
      title={
        tasks.length > 0 ? (
          <FormattedMessage
            defaultMessage="{count, plural, one {Task} other {Tasks}} ({count})"
            values={{
              count: tasks.length,
            }}
          />
        ) : (
          <>Tasks</>
        )
      }
    >
      <div>
        {tasks.length > 0 ? (
          <div className="divide-y-[0.5px] divide-gray-04">{tasks}</div>
        ) : (
          <NoticeMessage hasColor={false} size="Large" variant="Info">
            Nothing to do here!
          </NoticeMessage>
        )}
      </div>
    </Card>
  );
};
