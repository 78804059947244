/**
 * @generated SignedSource<<43b5c93f49f9488de1b116183ccd1c18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type useDisplayFullyDilutedValuesInGranteePortal_Grantee$data = {
  readonly hasSettlingGrant: boolean;
  readonly organization: {
    readonly granteePortalSettings: {
      readonly displayFullyDilutedValues: boolean;
    };
  };
  readonly status: GranteeStatus;
  readonly " $fragmentType": "useDisplayFullyDilutedValuesInGranteePortal_Grantee";
};
export type useDisplayFullyDilutedValuesInGranteePortal_Grantee$key = {
  readonly " $data"?: useDisplayFullyDilutedValuesInGranteePortal_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDisplayFullyDilutedValuesInGranteePortal_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useDisplayFullyDilutedValuesInGranteePortal_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSettlingGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GranteePortalSettings",
          "kind": "LinkedField",
          "name": "granteePortalSettings",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayFullyDilutedValues",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "1fcdf69b94ed68fcec78dd74a752c400";

export default node;
