import { Tag } from "./ui/Tag";

export type PlanningEntryType =
  | "EXCEPTIONAL_GRANT"
  | "LEVELING_GRANT"
  | "NEW_HIRE_GRANT"
  | "PROMOTION_GRANT"
  | "TENURE_GRANT"
  | "TERMINATION";

export const PLANNING_ENTRY_TYPE_LABELS: Record<PlanningEntryType, string> = {
  EXCEPTIONAL_GRANT: "Exceptional",
  LEVELING_GRANT: "Leveling",
  NEW_HIRE_GRANT: "New hire",
  PROMOTION_GRANT: "Promotion",
  TENURE_GRANT: "Tenure",
  TERMINATION: "Termination",
};

export const PlanningEntryTypeTag: React.FC<{
  type: PlanningEntryType;
}> = ({ type }) => {
  return (
    <Tag className="whitespace-nowrap" color="gray">
      {PLANNING_ENTRY_TYPE_LABELS[type]}
    </Tag>
  );
};
