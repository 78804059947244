import React from "react";
import { graphql, useFragment } from "react-relay";
import { ResponsiveContainer } from "recharts";

import { FairMarketValueHistoryChart_Organization$key } from "./__generated__/FairMarketValueHistoryChart_Organization.graphql";
import { ValuationHistoryChart } from "./ValuationHistoryChart";

const ORGANIZATION_FRAGMENT = graphql`
  fragment FairMarketValueHistoryChart_Organization on Organization {
    ...ValuationHistoryChart_Organization
  }
`;

export const FairMarketValueHistoryChart: React.FC<
  Omit<React.ComponentProps<typeof ResponsiveContainer>, "children"> & {
    organizationFragment: FairMarketValueHistoryChart_Organization$key;
  }
> = ({ organizationFragment, ...props }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <ValuationHistoryChart
      fairMarketValue
      organizationFragment={organization}
      {...props}
    />
  );
};
