/**
 * @generated SignedSource<<04a23548a4695f9631ad56f5192b641d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Implementation_CTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly implementationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly name: string;
  readonly searchedImplementationCTMSGrantAmendmentRequests: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_Organization" | "LegalWorkflowLayout_Organization">;
  readonly " $fragmentType": "Implementation_CTMSGrantAmendmentRequests_Organization";
};
export type Implementation_CTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: Implementation_CTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"Implementation_CTMSGrantAmendmentRequests_Organization">;
};

import Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "IMPLEMENTATION"
  ]
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "grantAmendmentRequestsSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": Implementation_CTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "Implementation_CTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "implementationCTMSGrantAmendmentRequests",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "ctmsGrantAmendmentRequests(statusIn:[\"IMPLEMENTATION\"])"
    },
    {
      "alias": "searchedImplementationCTMSGrantAmendmentRequests",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "grantAmendmentRequestsSearch"
        },
        (v1/*: any*/)
      ],
      "concreteType": "CTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "ctmsGrantAmendmentRequests",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CTMSGrantAmendmentRequestsTable_CTMSGrantAmendmentRequest"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CTMSGrantAmendmentRequestsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "b53a912b50c508e6d6624e4c3d8fc346";

export default node;
