/**
 * @generated SignedSource<<7e5164f60d9fe63569e91ca82635a68f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetails_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Organization">;
  readonly " $fragmentType": "DraftGrantDetails_Organization";
};
export type DraftGrantDetails_Organization$key = {
  readonly " $data"?: DraftGrantDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetails_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsComponent_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "55a7e2b90fc90acf7e94a97d5e0b7108";

export default node;
