/**
 * @generated SignedSource<<f62d59134803d622e7940d7693db6acb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssistedGrantLayout_Query$variables = {
  equityOfferId: string;
  organizationId: string;
  planningEntryId: any;
  skipEquityOffer: boolean;
  skipPlanningEntry: boolean;
};
export type AssistedGrantLayout_Query$data = {
  readonly equityOffer?: {
    readonly " $fragmentSpreads": FragmentRefs<"Context_AssistedGrant_EquityOffer">;
  } | null;
  readonly organization: {
    readonly name: string;
  } | null;
  readonly planningEntry?: {
    readonly " $fragmentSpreads": FragmentRefs<"Context_AssistedGrant_PlanningEntry">;
  } | null;
};
export type AssistedGrantLayout_Query = {
  response: AssistedGrantLayout_Query$data;
  variables: AssistedGrantLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "equityOfferId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "planningEntryId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipEquityOffer"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipPlanningEntry"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "planningEntryId",
    "variableName": "planningEntryId"
  }
],
v8 = [
  {
    "kind": "Variable",
    "name": "equityOfferId",
    "variableName": "equityOfferId"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = [
  (v9/*: any*/)
],
v12 = [
  (v10/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssistedGrantLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v6/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "skipPlanningEntry",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "planningEntry",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Context_AssistedGrant_PlanningEntry"
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "skipEquityOffer",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "EquityOffer",
            "kind": "LinkedField",
            "name": "equityOffer",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Context_AssistedGrant_EquityOffer"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssistedGrantLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "condition": "skipPlanningEntry",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v7/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "planningEntry",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isPlanningEntry"
              },
              (v9/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": "newHirerEntrySharesGranted",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sharesGranted",
                    "storageKey": null
                  }
                ],
                "type": "NewHireGrantPlanningEntry",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sharesGranted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "grantee",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  }
                ],
                "type": "RefresherGrantPlanningEntry",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      },
      {
        "condition": "skipEquityOffer",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "EquityOffer",
            "kind": "LinkedField",
            "name": "equityOffer",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shares",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "exercisePrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "vestingSchedule",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "postTerminationExercisePeriod",
                "plural": false,
                "selections": (v12/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Instrument",
                "kind": "LinkedField",
                "name": "instrument",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "candidateName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workRelationship",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractStartDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "code",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "cb375bff2eef5c0028977b2e3a5f6c02",
    "id": null,
    "metadata": {},
    "name": "AssistedGrantLayout_Query",
    "operationKind": "query",
    "text": "query AssistedGrantLayout_Query(\n  $organizationId: OrganizationId!\n  $planningEntryId: PlanningEntryId!\n  $skipPlanningEntry: Boolean!\n  $equityOfferId: String!\n  $skipEquityOffer: Boolean!\n) {\n  organization(id: $organizationId) {\n    name\n    id\n  }\n  planningEntry(planningEntryId: $planningEntryId) @skip(if: $skipPlanningEntry) {\n    __typename\n    ...Context_AssistedGrant_PlanningEntry\n    id\n  }\n  equityOffer(equityOfferId: $equityOfferId) @skip(if: $skipEquityOffer) {\n    ...Context_AssistedGrant_EquityOffer\n    id\n  }\n}\n\nfragment Context_AssistedGrant_EquityOffer on EquityOffer {\n  id\n  shares\n  exercisePrice\n  vestingSchedule {\n    __typename\n    id\n  }\n  postTerminationExercisePeriod {\n    __typename\n    id\n  }\n  instrument {\n    id\n  }\n  ...Grantee_EquityOffer\n}\n\nfragment Context_AssistedGrant_PlanningEntry on PlanningEntry {\n  __isPlanningEntry: __typename\n  __typename\n  ... on NewHireGrantPlanningEntry {\n    id\n    newHirerEntrySharesGranted: sharesGranted\n  }\n  ... on RefresherGrantPlanningEntry {\n    id\n    sharesGranted\n    grantee {\n      id\n    }\n  }\n}\n\nfragment Grantee_EquityOffer on EquityOffer {\n  candidateName\n  position\n  workRelationship\n  contractStartDate\n  taxResidenceCountry {\n    code\n  }\n}\n"
  }
};
})();

(node as any).hash = "40dc94ba7eaba97f995daf6f0a4a6456";

export default node;
