import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { CountriesTableFooterContent } from "../../../components/CountriesTableFooterContent";
import { FormattedCurrencyCompact } from "../../../components/Formatted/FormattedCurrencyCompact";
import { Page } from "../../../components/Page";
import { RedirectHandler } from "../../../components/RedirectHandler";
import { LinkButton } from "../../../components/ui/Button";
import { LargeCenteredDetailsLayout } from "../../../components/ui/Layout/LargeCenteredDetailsLayout";
import { SearchBar } from "../../../components/ui/SearchBar";
import { Table } from "../../../components/ui/Table";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { useSetAdminPageVisited } from "../useSetAdminPageVisited";
import {
  UnlockCountries_Organization$data,
  UnlockCountries_Organization$key,
} from "./__generated__/UnlockCountries_Organization.graphql";
import { UnlockCountries_Query } from "./__generated__/UnlockCountries_Query.graphql";
import { CountriesTableFooter } from "./CountriesTableFooter";

const ORGANIZATION_FRAGMENT = graphql`
  fragment UnlockCountries_Organization on Organization {
    id
    name
    subscriptionBillingPeriod
    availableToUnlockGeographies {
      geography {
        country {
          name
          code
          emoji
        }
      }
      standardPrice {
        priceAfterDiscount
      }
    }
    ...LargeCenteredDetailsLayout_Organization
  }
`;

type AvailableToUnlockGeography =
  UnlockCountries_Organization$data["availableToUnlockGeographies"][number];

const columnHelper = createColumnHelper<AvailableToUnlockGeography>();

const UnlockCountriesPage_: React.FC<{
  organizationFragment: UnlockCountries_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  useSetAdminPageVisited({
    organizationId: organization.id,
    page: "GEOGRAPHIES",
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.geography.country.name, {
        cell: (context) => {
          const item = context.row.original;

          return (
            <div className="flex items-center gap-4">
              <div className="font-emoji text-2xl">
                {item.geography.country.emoji}
              </div>
              <Typography as="div" variant="Medium/Extra Small">
                {item.geography.country.name}
              </Typography>
            </div>
          );
        },
        enableGlobalFilter: true,
        enableSorting: true,
        header: () => "Name",
        id: "country",
        sortingFn: "text",
      }),
      columnHelper.accessor((row) => row.standardPrice?.priceAfterDiscount, {
        cell: (context) => {
          const value = context.getValue();

          if (!value) {
            return "-";
          }

          return (
            <FormattedCurrencyCompact
              maximumFractionDigits={2}
              minimumFractionDigits={0}
              value={value}
            />
          );
        },
        enableGlobalFilter: false,
        enableSorting: true,
        header: () =>
          organization.subscriptionBillingPeriod === "monthly"
            ? "Monthly price"
            : "Yearly price",
        id: "price",
        meta: {
          alignRight: true,
        },
      }),
      columnHelper.display({
        cell: (context) => {
          const item = context.row.original;

          return (
            <LinkButton
              size="extra small"
              to={generatePath(
                APPLICATION_ROUTES["organizationUnlockCountry"],
                {
                  countryCode: item.geography.country.code,
                  organizationId: organization.id,
                },
              )}
              variant="Primary Full"
            >
              Unlock country
            </LinkButton>
          );
        },
        id: "action",
        meta: {
          alignRight: true,
        },
      }),
    ],
    [organization.id, organization.subscriptionBillingPeriod],
  );

  const [searchInputValue, setSearchInputValue] = useState("");

  const handleSearch = useCallback(
    (value: string) => {
      setSearchInputValue(value);
    },
    [setSearchInputValue],
  );

  const table = useReactTable({
    columns,
    data: useMemo(
      () => [...organization.availableToUnlockGeographies],
      [organization.availableToUnlockGeographies],
    ),
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    onGlobalFilterChange: handleSearch,
    state: {
      globalFilter: searchInputValue,
    },
  });

  return (
    <LargeCenteredDetailsLayout
      organizationFragment={organization}
      parents={[
        {
          link: "..",
          title: "Countries",
        },
      ]}
      subtitle={
        <div className="space-y-2">
          <div>There are 75+ countries available</div>
          <SearchBar
            className="w-full max-w-[260px]"
            onChange={handleSearch}
            placeholder="Search countries..."
            value={searchInputValue ?? ""}
          />
        </div>
      }
      title="Unlock new countries"
    >
      <div className="space-y-6">
        <div>
          <Table.Smart
            footer={
              <CountriesTableFooter>
                <Table.Row>
                  <Table.Cell className="col-span-full">
                    <CountriesTableFooterContent
                      organizationId={organization.id}
                    />
                  </Table.Cell>
                </Table.Row>
              </CountriesTableFooter>
            }
            rowRenderer={({ cells, rowData }) => {
              return (
                <Table.LinkRow
                  className="cursor-pointer"
                  to={generatePath(
                    APPLICATION_ROUTES["organizationUnlockCountry"],
                    {
                      countryCode: rowData.original.geography.country.code,
                      organizationId: organization.id,
                    },
                  )}
                >
                  {cells}
                </Table.LinkRow>
              );
            }}
            table={table}
            tableClassName="grid-cols-[1fr_auto_auto]"
            tableDisplay="grid"
          />
        </div>
      </div>
    </LargeCenteredDetailsLayout>
  );
};

const QUERY = graphql`
  query UnlockCountries_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...UnlockCountries_Organization
    }
    me {
      isAllowedToUnlockCountryForOrganization(organizationId: $organizationId)
    }
  }
`;

const UnlockCountriesPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<UnlockCountries_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  if (!query.me.isAllowedToUnlockCountryForOrganization) {
    return (
      <RedirectHandler
        to={generatePath(APPLICATION_ROUTES["organizationCountries"], {
          organizationId,
        })}
      />
    );
  }

  return (
    <Page
      analyticsCategory="Countries"
      analyticsName="Admin - Unlock countries"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} unlock countries`}
    >
      <UnlockCountriesPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default UnlockCountriesPage;
