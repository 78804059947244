import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LongDate } from "../../../../components/LongDate";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { GrantVestingScheduleRow_VestingSchedule$key } from "./__generated__/GrantVestingScheduleRow_VestingSchedule.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const VESTING_SCHEDULE_FRAGMENT = graphql`
  fragment GrantVestingScheduleRow_VestingSchedule on VestingSchedule {
    name
  }
`;

export const GrantVestingScheduleRow: React.FC<{
  vestingScheduleFragment: GrantVestingScheduleRow_VestingSchedule$key;
  vestingStartDate: string;
}> = ({ vestingScheduleFragment, vestingStartDate }) => {
  const vestingSchedule = useFragment(
    VESTING_SCHEDULE_FRAGMENT,
    vestingScheduleFragment,
  );

  return (
    <GrantReviewRow title="Vesting schedule">
      <InformationRows>
        <InformationRows.Row label="Vesting schedule">
          {vestingSchedule.name}
        </InformationRows.Row>
        <InformationRows.Row label="Start date">
          <LongDate value={vestingStartDate} />
        </InformationRows.Row>
      </InformationRows>
    </GrantReviewRow>
  );
};
