import { isNil, omitBy } from "lodash";
import React, { useCallback, useContext, useEffect, useReducer } from "react";
import { useFragment } from "react-relay";
import { useLocation, useMatch } from "react-router-dom";
import { graphql } from "relay-runtime";
import { v4 as uuidv4 } from "uuid";

import { SharesValueMode } from "../../../components/SharesValue";
import { StepState } from "../../../components/ui/Step";
import { keysOf } from "../../../helpers/object-utility";
import { useTrackEvent } from "../../../hooks/useAnalytics";
import { APPLICATION_ROUTES } from "../../../paths";
import { AccelerationClause } from "../../../services/AccelerationClause";
import { generateGrantLabel } from "../../../services/easopGrant";
import {
  Context_AssistedGrant_EquityOffer$data,
  Context_AssistedGrant_EquityOffer$key,
} from "./__generated__/Context_AssistedGrant_EquityOffer.graphql";
import { AwardSuperType } from "./__generated__/Context_AssistedGrant_Instrument.graphql";
import {
  Context_AssistedGrant_PlanningEntry$data,
  Context_AssistedGrant_PlanningEntry$key,
} from "./__generated__/Context_AssistedGrant_PlanningEntry.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
graphql`
  fragment Context_AssistedGrant_Instrument on Instrument {
    # eslint-disable-next-line relay/unused-fields
    awardSuperType
  }
`;

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment Context_AssistedGrant_PlanningEntry on PlanningEntry {
    __typename
    ... on NewHireGrantPlanningEntry {
      id
      newHirerEntrySharesGranted: sharesGranted
    }
    ... on RefresherGrantPlanningEntry {
      id
      sharesGranted
      grantee {
        id
      }
    }
  }
`;

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment Context_AssistedGrant_EquityOffer on EquityOffer {
    id
    shares
    exercisePrice
    vestingSchedule {
      id
    }
    postTerminationExercisePeriod {
      id
    }
    instrument {
      id
    }
    # eslint-disable-next-line relay/must-colocate-fragment-spreads
    ...Grantee_EquityOffer
  }
`;

export interface AssistedGrantContextValues {
  accelerationClause?: AccelerationClause | null;
  analyticsContext: AssistedGrantAnalyticsContext;
  awardSuperType?: AwardSuperType;
  earlyExercise?: boolean;
  equityOfferSource: EquityOffer | null;
  exercisePrice?: number;
  exercisePriceBelowFMVSetOn?: null | string;
  granteeId?: string;
  granteeManagementCompanyName?: null | string;
  hasCompletedPostTerminationExercisePeriodStep?: boolean;
  id: string;
  instrumentId?: string;
  label: string;
  lastActionDispatched: null | ReducerAction["type"];
  planningEntrySource: null | PlanningEntry;
  postTerminationExercisePeriodId?: null | string;
  quantityGranted?: number;
  vestingScheduleId?: string;
  vestingStartDate?: string;
}
type AssistedGrantAnalyticsContext = {
  accelerationAllowed?: boolean;
  earlyExerciseAllowed?: boolean;
  exercisePrice?: number;
  exercisePriceEqualsOrganizationValuation?: boolean;
  granteeCountry?: string;
  granteeId?: string;
  grantId: string;
  grantLabel: string;
  grantMethod: "beginner";
  instrumentId?: string;
  instrumentName?: string;
  ownership?: null | number;
  postTerminationExercisePeriodId?: null | string;
  shares?: number;
  sharesInputMode?: SharesValueMode;
  valueInUSD?: null | number;
  vestingScheduleId?: string;
  vestingScheduleName?: string;
};

interface AssistedGrantContext {
  dispatchAction: React.Dispatch<ReducerAction>;
  isParameterImmutable: (
    parameter: keyof AssistedGrantContextValues,
  ) => boolean;
  state: AssistedGrantContextValues;
}

type EquityOffer = Context_AssistedGrant_EquityOffer$data;

type PlanningEntry = Context_AssistedGrant_PlanningEntry$data;

type ReducerAction =
  | {
      accelerationClause: AccelerationClause | null;
      earlyExercise: boolean;
      postTerminationExercisePeriodId?: null | string;
      type: "SET_PTEP";
    }
  | {
      awardSuperType: AwardSuperType;
      instrumentId: string;
      instrumentName: string;
      type: "SELECT_INSTRUMENT";
    }
  | {
      exercisePrice: number;
      exercisePriceBelowFMVSetOn: null | string;
      exercisePriceEqualsOrganizationValuation: boolean;
      ownershipGranted: null | number;
      quantityGranted: number;
      sharesInputMode: SharesValueMode;
      type: "SET_SHARES";
      valueGranted: null | number;
    }
  | {
      granteeCountry: string;
      granteeId: string;
      granteeManagementCompanyName: null | string;
      type: "SELECT_GRANTEE";
    }
  | {
      type: "SELECT_VESTING_SCHEDULE";
      vestingScheduleId: string;
      vestingScheduleName: string;
      vestingStartDate: string;
    };

const Context = React.createContext<AssistedGrantContext | null>(null);

const reduce: React.Reducer<AssistedGrantContextValues, ReducerAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case "SELECT_GRANTEE": {
      if (
        action.granteeId === state.granteeId &&
        action.granteeManagementCompanyName ===
          state.granteeManagementCompanyName
      ) {
        return state;
      }

      const analyticsContext: AssistedGrantAnalyticsContext = {
        ...state.analyticsContext,
        granteeCountry: action.granteeCountry,
        granteeId: action.granteeId,
      };

      return {
        ...state,
        analyticsContext,
        granteeId: action.granteeId,
        granteeManagementCompanyName:
          action.granteeManagementCompanyName ?? null,
        lastActionDispatched: action.type,
      };
    }
    case "SELECT_INSTRUMENT": {
      if (
        state.instrumentId === action.instrumentId &&
        state.awardSuperType === action.awardSuperType
      ) {
        return state;
      }

      const analyticsContext: AssistedGrantAnalyticsContext = {
        ...state.analyticsContext,
        instrumentId: action.instrumentId,
        instrumentName: action.instrumentName,
      };

      return {
        ...state,
        analyticsContext,
        awardSuperType: action.awardSuperType,
        instrumentId: action.instrumentId,
        lastActionDispatched: action.type,
        postTerminationExercisePeriodId: null,
      };
    }
    case "SELECT_VESTING_SCHEDULE": {
      const analyticsContext: AssistedGrantAnalyticsContext = {
        ...state.analyticsContext,
        vestingScheduleId: action.vestingScheduleId,
        vestingScheduleName: action.vestingScheduleName,
      };
      return {
        ...state,
        analyticsContext,
        lastActionDispatched: action.type,
        vestingScheduleId: action.vestingScheduleId,
        vestingStartDate: action.vestingStartDate,
      };
    }
    case "SET_PTEP": {
      const analyticsContext: AssistedGrantAnalyticsContext = {
        ...state.analyticsContext,
        accelerationAllowed: Boolean(action.accelerationClause),
        earlyExerciseAllowed: action.earlyExercise,
        postTerminationExercisePeriodId: action.postTerminationExercisePeriodId,
      };

      return {
        ...state,
        accelerationClause: action.accelerationClause,
        analyticsContext,
        earlyExercise: action.earlyExercise,
        hasCompletedPostTerminationExercisePeriodStep: true,
        lastActionDispatched: action.type,
        postTerminationExercisePeriodId: action.postTerminationExercisePeriodId,
      };
    }
    case "SET_SHARES": {
      const analyticsContext: AssistedGrantAnalyticsContext = {
        ...state.analyticsContext,
        exercisePrice: action.exercisePrice,
        exercisePriceEqualsOrganizationValuation:
          action.exercisePriceEqualsOrganizationValuation,
        ownership: action.ownershipGranted,
        shares: action.quantityGranted,
        sharesInputMode: action.sharesInputMode,
        valueInUSD: action.valueGranted,
      };
      return {
        ...state,
        analyticsContext,
        exercisePrice: action.exercisePrice,
        exercisePriceBelowFMVSetOn: action.exercisePriceBelowFMVSetOn,
        lastActionDispatched: action.type,
        quantityGranted: action.quantityGranted,
      };
    }
  }
};

const getStateFixedQuantityGranted = ({
  equityOfferSource,
  planningEntrySource,
}: {
  equityOfferSource: Context_AssistedGrant_EquityOffer$data | null;
  planningEntrySource: Context_AssistedGrant_PlanningEntry$data | null;
}) => {
  if (planningEntrySource) {
    switch (planningEntrySource.__typename) {
      case "NewHireGrantPlanningEntry":
        return planningEntrySource.newHirerEntrySharesGranted;
      case "RefresherGrantPlanningEntry":
        return planningEntrySource.sharesGranted;
      case "%other":
        throw new Error("Unexpected typename");
    }
  }
  if (equityOfferSource?.shares) {
    return equityOfferSource.shares;
  }
  return null;
};

const getStateFixedValues = ({
  equityOfferSource,
  planningEntrySource,
}: {
  equityOfferSource: Context_AssistedGrant_EquityOffer$data | null;
  planningEntrySource: Context_AssistedGrant_PlanningEntry$data | null;
}): Partial<AssistedGrantContextValues> => {
  return omitBy(
    {
      exercisePrice: equityOfferSource?.exercisePrice ?? undefined,
      granteeId:
        planningEntrySource && "grantee" in planningEntrySource
          ? planningEntrySource.grantee.id
          : undefined,
      instrumentId: equityOfferSource?.instrument?.id,
      postTerminationExercisePeriodId:
        equityOfferSource?.postTerminationExercisePeriod?.id ?? undefined,
      quantityGranted: getStateFixedQuantityGranted({
        equityOfferSource,
        planningEntrySource,
      }),
      vestingScheduleId: equityOfferSource?.vestingSchedule?.id,
    },
    isNil,
  );
};

export const AssistedGrantContextProvider: React.FC<{
  children: React.ReactNode;
  equityOfferSourceFragment: Context_AssistedGrant_EquityOffer$key | null;
  planningEntrySourceFragment: Context_AssistedGrant_PlanningEntry$key | null;
}> = ({ children, equityOfferSourceFragment, planningEntrySourceFragment }) => {
  const grantLabel = generateGrantLabel();
  const grantId = uuidv4();
  const planningEntrySource =
    useFragment(PLANNING_ENTRY_FRAGMENT, planningEntrySourceFragment) ?? null;
  const equityOfferSource =
    useFragment(EQUITY_OFFER_FRAGMENT, equityOfferSourceFragment) ?? null;

  const initialFixedValues = getStateFixedValues({
    equityOfferSource,
    planningEntrySource,
  });

  const initialValue: AssistedGrantContextValues = {
    analyticsContext: {
      grantId,
      grantLabel,
      grantMethod: "beginner",
    },
    equityOfferSource,
    id: grantId,
    label: grantLabel,
    lastActionDispatched: null,
    planningEntrySource,
    ...initialFixedValues,
  };
  const [state, dispatchAction] = useReducer(reduce, initialValue);

  const isParameterImmutable = useCallback(
    (parameter: keyof AssistedGrantContextValues) => {
      const fixedValues = getStateFixedValues({
        equityOfferSource: state.equityOfferSource,
        planningEntrySource: state.planningEntrySource,
      });
      return keysOf(fixedValues).includes(parameter);
    },
    [state.planningEntrySource, state.equityOfferSource],
  );

  const _trackEvent = useTrackEvent();
  const trackEvent = useCallback(
    (eventName: string) => _trackEvent(eventName, state.analyticsContext),
    [_trackEvent, state.analyticsContext],
  );

  useEffect(() => {
    if (!state.lastActionDispatched) return;
    switch (state.lastActionDispatched) {
      case "SELECT_GRANTEE":
        trackEvent("Grant Draft - Grantee Step Validated");
        break;
      case "SELECT_INSTRUMENT":
        trackEvent("Grant Draft - Instrument Step Validated");
        break;
      case "SELECT_VESTING_SCHEDULE":
        trackEvent("Grant Draft - Vesting Schedule Validated");
        break;
      case "SET_PTEP":
        trackEvent("Grant Draft - Vesting PTEP Validated");
        break;
      case "SET_SHARES":
        trackEvent("Grant Draft - Shares Validated");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.lastActionDispatched]);

  return (
    <Context.Provider
      value={{
        dispatchAction,
        isParameterImmutable,
        state,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAssistedGrantContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get assisted grant context");
  }

  return context;
};

export const useIsAssistedGrantVirtual = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error("can't get assisted grant context");
  }

  if (
    context.state.awardSuperType === undefined ||
    context.state.awardSuperType !== "SAR"
  ) {
    return false;
  }

  return true;
};

export type AssistedGrantStep =
  | "organizationAssistedGrantGrantee"
  | "organizationAssistedGrantGranteeGrantee"
  | "organizationAssistedGrantGranteeInstrument"
  | "organizationAssistedGrantReview"
  | "organizationAssistedGrantShares"
  | "organizationAssistedGrantVesting"
  | "organizationAssistedGrantVestingPTEP"
  | "organizationAssistedGrantVestingVestingSchedule";

const getAssistedGrantGranteeGranteeStepState = (
  context: AssistedGrantContext,
): StepState => {
  if (!context.state.granteeId) {
    return "active";
  }

  return "completed";
};

const getAssistedGrantGranteeInstrumentStepState = (
  context: AssistedGrantContext,
): StepState => {
  const previousStepState = getAssistedGrantGranteeGranteeStepState(context);

  if (previousStepState !== "completed") {
    return "upcoming";
  }

  if (!context.state.instrumentId) {
    return "active";
  }

  return "completed";
};

const getAssistedGrantGranteeStepState = (
  context: AssistedGrantContext,
): StepState => {
  const granteeStepState = getAssistedGrantGranteeGranteeStepState(context);
  const instrumentStepState =
    getAssistedGrantGranteeInstrumentStepState(context);
  const childSteps = [granteeStepState, instrumentStepState];

  if (childSteps.every((step) => step === "completed")) {
    return "completed";
  }

  if (childSteps.some((step) => step === "active")) {
    return "active";
  }

  return "upcoming";
};

const getAssistedGrantSharesStepState = (
  context: AssistedGrantContext,
): StepState => {
  const previousStepState = getAssistedGrantGranteeInstrumentStepState(context);

  if (previousStepState !== "completed") {
    return "upcoming";
  }

  if (typeof context.state.quantityGranted !== "number") {
    return "active";
  }

  return "completed";
};

const getAssistedGrantVestingVestingScheduleStepState = (
  context: AssistedGrantContext,
): StepState => {
  const previousStepState = getAssistedGrantSharesStepState(context);

  if (previousStepState !== "completed") {
    return "upcoming";
  }

  if (!context.state.vestingScheduleId) {
    return "active";
  }

  return "completed";
};

const getAssistedGrantVestingPTEPStepState = (
  context: AssistedGrantContext,
): StepState => {
  const previousStepState =
    getAssistedGrantVestingVestingScheduleStepState(context);

  if (previousStepState !== "completed") {
    return "upcoming";
  }

  if (!context.state.hasCompletedPostTerminationExercisePeriodStep) {
    return "active";
  }

  return "completed";
};

const getAssistedGrantVestingStepState = (
  context: AssistedGrantContext,
): StepState => {
  const vestingScheduleStepState =
    getAssistedGrantVestingVestingScheduleStepState(context);
  const ptepStepState = getAssistedGrantVestingPTEPStepState(context);
  const childSteps = [vestingScheduleStepState, ptepStepState];

  if (childSteps.every((step) => step === "completed")) {
    return "completed";
  }

  if (childSteps.some((step) => step === "active")) {
    return "active";
  }

  return "upcoming";
};

const getAssistedGrantReviewStepState = (
  context: AssistedGrantContext,
): StepState => {
  const previousStepState = getAssistedGrantVestingPTEPStepState(context);

  if (previousStepState !== "completed") {
    return "upcoming";
  }

  return "active";
};

export const useGetAssistedGrantStepState = () => {
  const context = useAssistedGrantContext();

  return useCallback(
    (step: AssistedGrantStep): StepState => {
      switch (step) {
        case "organizationAssistedGrantGrantee":
          return getAssistedGrantGranteeStepState(context);
        case "organizationAssistedGrantGranteeGrantee":
          return getAssistedGrantGranteeGranteeStepState(context);
        case "organizationAssistedGrantGranteeInstrument":
          return getAssistedGrantGranteeInstrumentStepState(context);
        case "organizationAssistedGrantReview":
          return getAssistedGrantReviewStepState(context);
        case "organizationAssistedGrantShares":
          return getAssistedGrantSharesStepState(context);
        case "organizationAssistedGrantVesting":
          return getAssistedGrantVestingStepState(context);
        case "organizationAssistedGrantVestingPTEP":
          return getAssistedGrantVestingPTEPStepState(context);
        case "organizationAssistedGrantVestingVestingSchedule":
          return getAssistedGrantVestingVestingScheduleStepState(context);
      }
    },
    [context],
  );
};

export const useVisitedStep = (): AssistedGrantStep => {
  const location = useLocation();
  const organizationAssistedGrantMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrant,
  );
  const organizationAssistedGrantGranteeMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantGrantee,
  );
  const organizationAssistedGrantGranteeGranteeMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantGranteeGrantee,
  );
  const organizationAssistedGrantGranteeInstrumentMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantGranteeInstrument,
  );
  const organizationAssistedGrantSharesMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantShares,
  );
  const organizationAssistedGrantVestingMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantVesting,
  );
  const organizationAssistedGrantVestingVestingScheduleMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantVestingVestingSchedule,
  );
  const organizationAssistedGrantVestingPTEPMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantVestingPTEP,
  );
  const organizationAssistedGrantReviewMatch = useMatch(
    APPLICATION_ROUTES.organizationAssistedGrantReview,
  );

  if (
    organizationAssistedGrantGranteeMatch ||
    organizationAssistedGrantMatch ||
    organizationAssistedGrantGranteeGranteeMatch
  ) {
    return "organizationAssistedGrantGranteeGrantee";
  }

  if (organizationAssistedGrantGranteeInstrumentMatch) {
    return "organizationAssistedGrantGranteeInstrument";
  }

  if (organizationAssistedGrantSharesMatch) {
    return "organizationAssistedGrantShares";
  }

  if (
    organizationAssistedGrantVestingMatch ||
    organizationAssistedGrantVestingVestingScheduleMatch
  ) {
    return "organizationAssistedGrantVestingVestingSchedule";
  }

  if (organizationAssistedGrantVestingPTEPMatch) {
    return "organizationAssistedGrantVestingPTEP";
  }

  if (organizationAssistedGrantReviewMatch) {
    return "organizationAssistedGrantReview";
  }

  throw new Error(`no matching step for ${location.pathname}`);
};
