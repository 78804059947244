import classNames from "classnames";
import React, { ForwardRefRenderFunction, ReactNode } from "react";

import { ThemeColor } from "./colors";

type Color =
  | "aubergine"
  | "dashed"
  | Exclude<ThemeColor, "black" | "white" | "yellow">;

const SIZES = {
  "Extra Small": {
    paddingWithChildren: "px-1",
    paddingWithoutChildren: "p-0.5",
  },
  Small: {
    paddingWithChildren: "px-2",
    paddingWithoutChildren: "p-1",
  },
};

const style: Record<Color, string> = {
  aubergine: classNames(`bg-gradient-light-aubergine text-purple-09`),
  dashed: classNames(
    `border-[0.5px] border-dashed border-gray-04 bg-transparent text-gray-09`,
  ),
  fuchsia: classNames(`bg-fuchsia-02 text-fuchsia-09`),
  "glass-green": classNames(`bg-glass-green-02 text-glass-green-09`),
  gray: classNames(`bg-gray-02 text-black-07`),
  green: classNames(`bg-green-02 text-green-09`),
  orange: classNames(`bg-orange-02 text-orange-09`),
  primary: classNames(`bg-primary-02 text-primary-09`),
  purple: classNames(`bg-purple-02 text-purple-09`),
  red: classNames(`bg-red-02 text-red-09`),
};

const _Tag: ForwardRefRenderFunction<
  HTMLElement,
  {
    children?: ReactNode;
    className?: string;
    color?: Color;
    icon?: React.ReactElement<React.ComponentProps<"svg">>;
    size?: keyof typeof SIZES;
    skeleton?: boolean;
    wrapText?: boolean;
  }
> = (
  {
    children,
    className,
    color = "primary",
    icon,
    size = "Small",
    skeleton,
    wrapText = false,
  },
  ref,
) => {
  return (
    <span
      className={classNames(
        className,
        style[color],
        "inline-flex gap-2 rounded-full text-[10px] uppercase leading-[1.6]",
        {
          "animate-pulse select-none !text-opacity-0": skeleton,
          [SIZES[size].paddingWithChildren]: children,
          [SIZES[size].paddingWithoutChildren]: !children,
          "whitespace-nowrap": !wrapText,
        },
      )}
      ref={ref}
    >
      {icon &&
        React.cloneElement(icon, {
          ...icon.props,
          className: classNames(icon.props.className, "h-4 w-4"),
        })}
      {children}
    </span>
  );
};

/**
 * @deprecated use @remote-com/norma Pill instead
 */
export const Tag = React.forwardRef(_Tag);
