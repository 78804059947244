import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LongDate } from "../../../../components/LongDate";
import { Typography } from "../../../../components/ui/Typography";
import EditableHtml from "../../../../components/Wysiwyg/EditableHtml";
import { AdminPortalContent_AdminPortalContent$key } from "./__generated__/AdminPortalContent_AdminPortalContent.graphql";
import { AdminPortalContent_Viewer$key } from "./__generated__/AdminPortalContent_Viewer.graphql";

const ADMIN_PORTAL_CONTENT_FRAGMENT = graphql`
  fragment AdminPortalContent_AdminPortalContent on AdminPortalContent {
    content
    lastModifiedAt
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT = graphql`
  fragment AdminPortalContent_Viewer on Account {
    ...EditableHtml_Viewer
  }
`;

const generateCountrySpecificAdminPortalContentEmptyContentFallback = ({
  countryName,
}: {
  countryName: string;
}) => `
<div class="wysiwygInfo">
  This information is available on demand for ${countryName}. If you would like to receive an answer to a particular question or have a look at the full knowledge base for this country, do not hesitate to contact your dedicated support team.<br/>
  <br/>
  <br/>
  We will always provide you with specific information around local reporting and tax obligations when they're important for you, such as at the time of a grant, exercise, or when a taxable event occurs.
</div>
`;

const AdminPortalContent: React.FC<{
  adminPortalContentFragment: AdminPortalContent_AdminPortalContent$key | null;
  countryName: string;
  isLoading?: boolean;
  onSubmit?: (content: string) => void;
  viewerFragment?: AdminPortalContent_Viewer$key | null;
}> = ({
  adminPortalContentFragment,
  countryName,
  isLoading,
  onSubmit = () => null,
  viewerFragment = null,
}) => {
  const adminPortalContent = useFragment(
    ADMIN_PORTAL_CONTENT_FRAGMENT,
    adminPortalContentFragment,
  );

  const viewer = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT,
    viewerFragment,
  );

  return (
    <>
      {adminPortalContent?.lastModifiedAt && (
        <Typography
          as="div"
          className="flex items-center gap-4 border-l-[0.5px] border-primary pl-4 text-gray-09"
          variant="Regular/Extra Small"
        >
          Updated
          <div className="text-primary">
            <LongDate value={adminPortalContent?.lastModifiedAt} />
          </div>
        </Typography>
      )}

      <EditableHtml
        accountFragment={viewer}
        className="mt-12"
        isLoading={isLoading}
        onSubmit={(content) => onSubmit(content)}
        unsafeHtml={
          adminPortalContent?.content ||
          generateCountrySpecificAdminPortalContentEmptyContentFallback({
            countryName,
          })
        }
      />
    </>
  );
};

export default AdminPortalContent;
