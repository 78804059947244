/**
 * @generated SignedSource<<2db653acdf73d2d1ad0db9f680998291>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteeStatus = "Active" | "Terminated";
import { FragmentRefs } from "relay-runtime";
export type GranteeStatusTag_Grantee$data = {
  readonly hasSettlingGrant: boolean;
  readonly status: GranteeStatus;
  readonly terminationDate: string | null;
  readonly " $fragmentType": "GranteeStatusTag_Grantee";
};
export type GranteeStatusTag_Grantee$key = {
  readonly " $data"?: GranteeStatusTag_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeStatusTag_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeStatusTag_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasSettlingGrant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminationDate",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "a2ef6b3cd11adb55b401e781019dfdcb";

export default node;
