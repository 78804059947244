/**
 * @generated SignedSource<<9ed8601537117f179c1fc29e025b3b11>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalProjectionScenarioKey = "CURRENT_VALUE" | "FIRST_ROUND" | "SECOND_ROUND" | "THIRD_ROUND";
export type GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation$variables = {
  displayed: boolean;
  key: GranteePortalProjectionScenarioKey;
  organizationId: string;
};
export type GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation$data = {
  readonly setGranteePortalProjectionScenarioDisplayed: {
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario">;
  };
};
export type GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation = {
  response: GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation$data;
  variables: GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "displayed"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "key"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "displayed",
    "variableName": "displayed"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "key"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GranteePortalProjectionScenario",
        "kind": "LinkedField",
        "name": "setGranteePortalProjectionScenarioDisplayed",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "GranteePortalProjectionScenario",
        "kind": "LinkedField",
        "name": "setGranteePortalProjectionScenarioDisplayed",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "multiple",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalInformation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ed083d377672f869c7d2406428060eed",
    "id": null,
    "metadata": {},
    "name": "GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation",
    "operationKind": "mutation",
    "text": "mutation GranteePortalSettingsProjectionSection_SetGranteePortalProjectionScenarioDisplayed_Mutation(\n  $organizationId: OrganizationId!\n  $key: GranteePortalProjectionScenarioKey!\n  $displayed: Boolean!\n) {\n  setGranteePortalProjectionScenarioDisplayed(organizationId: $organizationId, key: $key, displayed: $displayed) {\n    ...GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario\n    id\n  }\n}\n\nfragment GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario on GranteePortalProjectionScenario {\n  key\n  name\n  multiple\n  displayed\n  additionalInformation\n}\n"
  }
};
})();

(node as any).hash = "7bef0fe1b67f884fb9fea03cbc440c42";

export default node;
