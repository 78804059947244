import classNames from "classnames";

import { Typography } from "./Typography";

export const Pill: React.FC<
  React.PropsWithChildren<{
    skeleton?: boolean;
  }>
> = ({ children, skeleton }) => (
  <span
    className={classNames(
      "flex items-center rounded-full bg-gray-02 px-2 py-0.5 transition-all",
      {
        "animate-pulse text-transparent": skeleton,
        "text-black-07": !skeleton,
      },
    )}
  >
    <Typography variant="Medium/Extra Small">{children}</Typography>
  </span>
);
