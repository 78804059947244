/**
 * @generated SignedSource<<b0d9ea94715e0554c1dad16a14df6e79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation$variables = {
  boardConsentHandledOutsideEasop: boolean;
  boardConsentSignatureDate?: string | null;
  ctmsGrantAmendmentRequestIds: ReadonlyArray<string>;
  signedBoardConsentDocumentIds?: ReadonlyArray<string> | null;
};
export type BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation$data = {
  readonly sendCTMSGrantAmendmentRequestsToBoardForConsent: ReadonlyArray<{
    readonly __typename: "CTMSGrantAmendmentRequest";
  }>;
};
export type BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation = {
  response: BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation$data;
  variables: BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentHandledOutsideEasop"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardConsentSignatureDate"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantAmendmentRequestIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signedBoardConsentDocumentIds"
},
v4 = [
  {
    "kind": "Variable",
    "name": "boardConsentHandledOutsideEasop",
    "variableName": "boardConsentHandledOutsideEasop"
  },
  {
    "kind": "Variable",
    "name": "boardConsentSignatureDate",
    "variableName": "boardConsentSignatureDate"
  },
  {
    "kind": "Variable",
    "name": "ctmsGrantAmendmentRequestIds",
    "variableName": "ctmsGrantAmendmentRequestIds"
  },
  {
    "kind": "Variable",
    "name": "signedBoardConsentDocumentIds",
    "variableName": "signedBoardConsentDocumentIds"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "sendCTMSGrantAmendmentRequestsToBoardForConsent",
        "plural": true,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CTMSGrantAmendmentRequest",
        "kind": "LinkedField",
        "name": "sendCTMSGrantAmendmentRequestsToBoardForConsent",
        "plural": true,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f3e9a0300b3d5c694801e12712da9dcf",
    "id": null,
    "metadata": {},
    "name": "BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation",
    "operationKind": "mutation",
    "text": "mutation BoardApproval_CTMSGrantAmendmentRequests_SendGrantAmendmentRequestsToBoardForConsent_Mutation(\n  $ctmsGrantAmendmentRequestIds: [UUID!]!\n  $boardConsentHandledOutsideEasop: Boolean!\n  $boardConsentSignatureDate: Date\n  $signedBoardConsentDocumentIds: [String!]\n) {\n  sendCTMSGrantAmendmentRequestsToBoardForConsent(ctmsGrantAmendmentRequestIds: $ctmsGrantAmendmentRequestIds, boardConsentHandledOutsideEasop: $boardConsentHandledOutsideEasop, boardConsentSignatureDate: $boardConsentSignatureDate, signedBoardConsentDocumentIds: $signedBoardConsentDocumentIds) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5b1289a73dfa167d1c89d587f19eb5a";

export default node;
