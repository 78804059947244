/**
 * @generated SignedSource<<fe60017bcaa12901fa85df371ea2e3f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useOrganizationCTMS_Organization$data = {
  readonly isConnectedToAngelList: boolean;
  readonly isConnectedToCarta: boolean;
  readonly isConnectedToPulley: boolean;
  readonly legacyCartaIssuerId: string | null;
  readonly " $fragmentType": "useOrganizationCTMS_Organization";
};
export type useOrganizationCTMS_Organization$key = {
  readonly " $data"?: useOrganizationCTMS_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"useOrganizationCTMS_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useOrganizationCTMS_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToCarta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToPulley",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isConnectedToAngelList",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legacyCartaIssuerId",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b3bf55ff47b73b52a00bef823b796a5f";

export default node;
