import classNames from "classnames";
import { graphql, useFragment } from "react-relay";

import { Typography } from "../../../components/ui/Typography";
import { FinalThoughts_EquityOffer$key } from "./__generated__/FinalThoughts_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment FinalThoughts_EquityOffer on IEquityOffer {
    finalThoughts
  }
`;

const PREVIEW_PLACEHOLDER = `We look forward to hearing from you soon, and we very much hope you'll be able to join us for your next adventure!

— John Smith, Head of HR @ Acme, Ltd.
`;

export const EquityOfferFinalThoughts: React.FC<{
  equityOfferFragment: FinalThoughts_EquityOffer$key;
}> = ({ equityOfferFragment }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  return (
    <div
      className={classNames("space-y-2 transition-all", {
        "pointer-events-none opacity-50": !equityOffer.finalThoughts,
      })}
    >
      <Typography as="div" variant="Medium/Large">
        Moving forward
      </Typography>
      <Typography
        as="div"
        className="whitespace-pre-line"
        variant="Regular/Small"
      >
        {equityOffer.finalThoughts ?? PREVIEW_PLACEHOLDER}
      </Typography>
    </div>
  );
};
