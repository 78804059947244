import {
  BellIcon,
  GlobeAmericasIcon,
  ReceiptPercentIcon,
  WifiIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../components/FreemiumBlockerCard";
import { Page } from "../../../components/Page";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { ExercisesFreemium_Organization$key } from "./__generated__/ExercisesFreemium_Organization.graphql";
import { ExercisesFreemium_Query } from "./__generated__/ExercisesFreemium_Query.graphql";
import ExercisesFreemiumIllustration from "./exercises-freemium-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ExercisesFreemium_Organization on Organization {
    id
    name
    ...FreemiumBlockerCard_Organization
  }
`;

const ExercisesFreemiumPage_: React.FC<{
  organizationFragment: ExercisesFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <CenteredColumnLayout showFooter>
      <FreemiumBlockerCard
        detailsList={
          <>
            <FreemiumBlockerCard.LabelWithIcon
              icon={<GlobeAmericasIcon />}
              label="No more lengthy research sessions and navigating convoluted tax codes"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<BellIcon />}
              label="Receive notifications of new obligations for local filings and reporting"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<ReceiptPercentIcon />}
              label="Get all the information needed at your fingertips, from applicable tax rates to potential benefits and crucial watch-outs"
            />
            <FreemiumBlockerCard.LabelWithIcon
              icon={<WifiIcon />}
              label="Streamline all relevant data to Employers of record"
            />
          </>
        }
        mailtoSubject="About the exercise handler"
        organizationFragment={organization}
        rightSideIllustration={
          <div
            className={classNames(
              "bg-gradient-light-aubergine",
              "relative w-[453px] max-w-[50%] rounded-2xl",
            )}
          >
            <div className="absolute -left-4 -right-7 bottom-0 top-0 flex items-center">
              <img
                alt="Exercises freemium illustration"
                src={ExercisesFreemiumIllustration}
              />
            </div>
          </div>
        }
        subTitle="Take the guesswork out of the equation by accessing a complete tax summary tailored to each grantee's situation based on their country of tax residence and work relationship."
        title="Exercise Handler for your Global Workforce"
      />
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query ExercisesFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...ExercisesFreemium_Organization
    }
  }
`;

const ExercisesFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<ExercisesFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Exercises freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} exercises freemium`}
    >
      <ExercisesFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default ExercisesFreemiumPage;
