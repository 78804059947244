import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useEquityOfferShareUtils } from "../hooks/useEquityOfferShareUtils";
import { useEquityOfferTrackEvent } from "../hooks/useEquityOfferTrackEvent";
import { EquityOfferShareModal_EquityOffer$key } from "./__generated__/EquityOfferShareModal_EquityOffer.graphql";
import { useToaster } from "./Toaster";
import { AnchorButton, Button } from "./ui/Button";
import { Input } from "./ui/Form/Inputs/Input";
import { Modal } from "./ui/Modal";
import { Toast } from "./ui/Toast";
import { Typography } from "./ui/Typography";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquityOfferShareModal_EquityOffer on EquityOffer {
    ...useEquityOfferShareUtils_EquityOffer
    ...useEquityOfferTrackEvent_EquityOffer
  }
`;

export const EquityOfferShareModal: React.FC<{
  equityOfferFragment: EquityOfferShareModal_EquityOffer$key;
  onClose: () => void;
  show: boolean;
}> = ({ equityOfferFragment, onClose, show }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  const { candidateOfferPDFPath, candidateOfferUrl } = useEquityOfferShareUtils(
    {
      equityOfferFragment: equityOffer,
    },
  );

  const { trackEquityOfferShared } = useEquityOfferTrackEvent({
    equityOfferFragment: equityOffer,
  });

  const toaster = useToaster();

  const onCopyLinkButtonClick = async () => {
    await navigator.clipboard.writeText(candidateOfferUrl);
    toaster.push(
      <Toast>Candidate offer link successfully copied to clipboard</Toast>,
    );
    trackEquityOfferShared("link");
  };

  return (
    <Modal onClose={onClose} show={show} suspense width={680}>
      <Modal.Content
        onClose={onClose}
        subTitle="Share the offer to your candidate."
        title="⚡️ Share equity offer"
      >
        <div>
          <Input
            data-cy="candidateOfferUrl"
            onClick={(event) => {
              event.currentTarget.select();
            }}
            readOnly
            value={candidateOfferUrl}
          />
        </div>
        <div className="flex items-center gap-6">
          <Button onClick={onCopyLinkButtonClick} size="small" type="button">
            Copy link
          </Button>
          <Typography className="text-gray-07" variant="Regular/Caption">
            OR
          </Typography>
          <AnchorButton
            href={candidateOfferPDFPath}
            size="small"
            variant="Primary Outline"
          >
            Generate PDF version
          </AnchorButton>
        </div>
      </Modal.Content>
    </Modal>
  );
};
