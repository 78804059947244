import { graphql, useFragment } from "react-relay";

import { EquityOfferStatusTag_EquityOffer$key } from "./__generated__/EquityOfferStatusTag_EquityOffer.graphql";
import { Tag } from "./ui/Tag";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment EquityOfferStatusTag_EquityOffer on EquityOffer {
    status
  }
`;

export const EquityOfferStatusTag: React.FC<{
  equityOfferFragment: EquityOfferStatusTag_EquityOffer$key;
}> = ({ equityOfferFragment }) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  switch (equityOffer.status) {
    case "DRAFTED":
      return <Tag color="green">Drafted</Tag>;
    case "IMPLEMENTED":
      return <Tag color="green">Implemented</Tag>;
    case "OFFER":
      return <Tag color="gray">Offer</Tag>;
  }
};
