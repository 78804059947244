/**
 * @generated SignedSource<<d7d6ba5e54a78f999958a8edc05c195a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery$variables = {
  id: string;
};
export type PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment">;
  } | null;
};
export type PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery = {
  response: PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery$data;
  variables: PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "easopGrantUsageCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isEditable",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeletable",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v2/*: any*/),
                "type": "OrganizationFixedPostTerminationExercisePeriod",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v2/*: any*/),
                "type": "OrganizationVariablePostTerminationExercisePeriod",
                "abstractKey": null
              }
            ],
            "type": "OrganizationPostTerminationExercisePeriod",
            "abstractKey": "__isOrganizationPostTerminationExercisePeriod"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6aeabba952725f7fff5de1b6151f3be2",
    "id": null,
    "metadata": {},
    "name": "PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery",
    "operationKind": "query",
    "text": "query PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment_RefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment\n    id\n  }\n}\n\nfragment PostTerminationExercisePeriodsTable_PostTerminationExercisePeriodFragment on OrganizationPostTerminationExercisePeriod {\n  __isOrganizationPostTerminationExercisePeriod: __typename\n  __typename\n  ... on OrganizationFixedPostTerminationExercisePeriod {\n    id\n    easopGrantUsageCount\n    displayName\n    isEditable\n    isDeletable\n  }\n  ... on OrganizationVariablePostTerminationExercisePeriod {\n    id\n    easopGrantUsageCount\n    displayName\n    isEditable\n    isDeletable\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "30b5bf5c3545e1059bef0925eae96632";

export default node;
