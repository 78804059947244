import { Text } from "@remote-com/norma";
import { graphql, useFragment } from "react-relay";

import { CartaGlyph } from "../../../../components/CompaniesGlyphs/CartaGlyph";
import { RemoteGlyph } from "../../../../components/CompaniesGlyphs/RemoteGlyph";
import { Table } from "../../../../components/ui/Table";
import { Typography } from "../../../../components/ui/Typography";
import { MatchGranteeTable_HRISProviderEmployee$key } from "./__generated__/MatchGranteeTable_HRISProviderEmployee.graphql";
import { MatchGranteeTable_Organization$key } from "./__generated__/MatchGranteeTable_Organization.graphql";
import { MatchGranteeEmptyStateIllustration } from "./MatchGranteeEmptyStateIllustration";
import { MatchGranteeTableRow } from "./MatchGranteeTableRow";

const ORGANIZATION_FRAGMENT = graphql`
  fragment MatchGranteeTable_Organization on Organization {
    id
    ...MatchGranteeTableRow_Organization
  }
`;

const HRIS_PROVIDER_EMPLOYEES_FRAGMENT = graphql`
  fragment MatchGranteeTable_HRISProviderEmployee on HRISProviderEmployee
  @relay(plural: true) {
    id
    ...MatchGranteeTableRow_HRISProviderEmployee
  }
`;

export const MatchGranteeTable: React.FC<{
  ctmsName: string;
  hRISProviderEmployeesFragment: MatchGranteeTable_HRISProviderEmployee$key;
  organizationFragment: MatchGranteeTable_Organization$key;
  refreshData: () => void;
  showEmptyState?: boolean;
}> = ({
  ctmsName,
  hRISProviderEmployeesFragment,
  organizationFragment,
  refreshData,
  showEmptyState,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const hRISProviderEmployees = useFragment(
    HRIS_PROVIDER_EMPLOYEES_FRAGMENT,
    hRISProviderEmployeesFragment,
  );

  return (
    <Table
      className="grid grid-cols-[1fr_auto_1fr_auto] gap-x-2"
      display="grid"
    >
      <Table.Header className="!bg-remote-white">
        <Table.Row className="hover:!bg-remote-white">
          <Table.HeaderCell>
            <div className="flex items-center gap-2">
              <RemoteGlyph className="h-8 w-8" />
              <Text className="text-remote-grey-600" variant="smMedium">
                Remote account
              </Text>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell />
          <Table.HeaderCell>
            <div className="flex items-center gap-2">
              <CartaGlyph className="h-8 w-8" />
              <Text className="text-remote-grey-600" variant="smMedium">
                Carta account
              </Text>
            </div>
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {showEmptyState ? (
          <div className="col-span-full flex flex-col gap-10 bg-background-subtle px-10 py-[104px]">
            <MatchGranteeEmptyStateIllustration className="h-[120px]" />
            <div className="space-y-2 text-center">
              <Typography
                as="div"
                className="text-remote-grey-900"
                variant="Medium/Default"
              >
                There are no accounts to match
              </Typography>
              <Typography
                as="div"
                className="text-remote-grey-600"
                variant="Regular/Small"
              >
                All accounts from Remote have been match to a profile in{" "}
                {ctmsName}
              </Typography>
            </div>
          </div>
        ) : (
          hRISProviderEmployees.map((employee) => (
            <MatchGranteeTableRow
              hRISProviderEmployeeFragment={employee}
              key={employee.id}
              organizationFragment={organization}
              refreshData={refreshData}
            />
          ))
        )}
      </Table.Body>
    </Table>
  );
};
