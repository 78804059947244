import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useLocationChangeHandler = (
  handler: (location: ReturnType<typeof useLocation>) => void,
) => {
  const location = useLocation();

  useEffect(() => {
    handler(location);
  }, [handler, location]);
};
