/**
 * @generated SignedSource<<43c3a5250ef2783d3980f7a5c9479824>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CTMSGrantAmendmentRequestsTable_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "CTMSGrantAmendmentRequestsTable_Organization";
};
export type CTMSGrantAmendmentRequestsTable_Organization$key = {
  readonly " $data"?: CTMSGrantAmendmentRequestsTable_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"CTMSGrantAmendmentRequestsTable_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CTMSGrantAmendmentRequestsTable_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f106dc8d13ac21679901f3e4dd4af491";

export default node;
