/**
 * @generated SignedSource<<1f763436fab7f9000e5b1b12a74a9d3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type VestingSettings_EquityOffer$data = {
  readonly id: string;
  readonly instrument: {
    readonly isVirtual: boolean;
  } | null;
  readonly organization: {
    readonly advisorsNewHiresGrantDefaults: {
      readonly postTerminationExercisePeriod: {
        readonly id: string;
      } | null;
    };
    readonly employeesAndContractorsNewHiresGrantDefaults: {
      readonly postTerminationExercisePeriod: {
        readonly id: string;
      } | null;
    };
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization" | "SelectVestingScheduleInput_Organization">;
  };
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly vestingSchedule: {
    readonly id: string;
  } | null;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOfferPreviewLayout_EquityOffer" | "Vesting_EquityOffer">;
  readonly " $fragmentType": "VestingSettings_EquityOffer";
};
export type VestingSettings_EquityOffer$key = {
  readonly " $data"?: VestingSettings_EquityOffer$data;
  readonly " $fragmentSpreads": FragmentRefs<"VestingSettings_EquityOffer">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "postTerminationExercisePeriod",
  "plural": false,
  "selections": (v1/*: any*/),
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VestingSettings_EquityOffer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isVirtual",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationGrantDefaults",
          "kind": "LinkedField",
          "name": "employeesAndContractorsNewHiresGrantDefaults",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationGrantDefaults",
          "kind": "LinkedField",
          "name": "advisorsNewHiresGrantDefaults",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EquityOfferPostTerminationExercisePeriodSelectionBlock_Organization"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SelectVestingScheduleInput_Organization"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Vesting_EquityOffer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityOfferPreviewLayout_EquityOffer"
    }
  ],
  "type": "EquityOffer",
  "abstractKey": null
};
})();

(node as any).hash = "72d06ed7dcef5da4a5df799db6a1e088";

export default node;
