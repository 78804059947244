import axios from "axios";

interface Account {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
}

const login = async ({
  email,
  longLivedSession,
  password,
}: {
  email: string;
  longLivedSession: boolean;
  password: string;
}) => {
  const result = await axios.post<
    | { account: Account; outcome: "SUCCESS" }
    | {
        outcome: "FAILURE";
        reason:
          | "ACCOUNT_HAS_NO_PASSWORD"
          | "EMAIL_NOT_FOUND"
          | "INVALID_PASSWORD";
      }
  >(
    "/api/login",
    { email, longLivedSession, password },
    { validateStatus: (status) => status < 500 },
  );

  return result.data;
};

const signUp = async ({
  email,
  firstName,
  lastName,
  longLivedSession,
  origin,
  password,
}: {
  email: string;
  firstName: string;
  lastName: string;
  longLivedSession: boolean;
  origin: "SELF_SERVED_FLOW";
  password: string;
}) => {
  const result = await axios.post<
    | { account: Account; outcome: "SUCCESS" }
    | {
        outcome: "FAILURE";
        reason: "EMAIL_ALREADY_USED" | "PASSWORD_TOO_WEAK";
      }
  >(
    "/api/sign-up",
    { email, firstName, lastName, longLivedSession, origin, password },
    { validateStatus: (status) => status < 500 },
  );

  return result.data;
};

const logout = async () => {
  const result = await axios.post<{ outcome: "SUCCESS" }>("/api/logout", {});

  return result.data;
};

const resetPassword = async ({ email }: { email: string }) => {
  const result = await axios.post<
    | {
        outcome: "FAILURE";
        reason: "EMAIL_NOT_FOUND";
      }
    | { outcome: "SUCCESS" }
  >(
    "/api/reset-password",
    { email },
    { validateStatus: (status) => status < 500 },
  );

  return result.data;
};

const validatePasswordResetToken = async ({
  email,
  passwordResetToken,
}: {
  email: string;
  passwordResetToken: string;
}) => {
  const result = await axios.post<
    | { account: Account; outcome: "SUCCESS" }
    | {
        outcome: "FAILURE";
        reason: "EMAIL_NOT_FOUND" | "EXPIRED_PASSWORD_RESET_TOKEN";
      }
  >(
    "/api/validate-password-reset-token",
    { email, passwordResetToken },
    { validateStatus: (status) => status < 500 },
  );

  return result.data;
};

const updatePassword = async ({
  email,
  newPassword,
  passwordResetToken,
}: {
  email: string;
  newPassword: string;
  passwordResetToken: string;
}) => {
  const result = await axios.post<
    | { account: Account; outcome: "SUCCESS" }
    | {
        outcome: "FAILURE";
        reason:
          | "EMAIL_NOT_FOUND"
          | "EXPIRED_PASSWORD_RESET_TOKEN"
          | "PASSWORD_RESET_TOKEN_MISMATCH"
          | "PASSWORD_TOO_WEAK";
      }
  >(
    "/api/update-password",
    { email, newPassword, passwordResetToken },
    { validateStatus: (status) => status < 500 },
  );

  return result.data;
};

const getMe = async () => {
  const result = await axios.get<
    { account: Account; outcome: "SUCCESS" } | { outcome: "FAILURE" }
  >("/api/me", { validateStatus: (status) => status < 500 });

  return result.data;
};

export const AuthenticationAPIClient = {
  getMe,
  login,
  logout,
  resetPassword,
  signUp,
  updatePassword,
  validatePasswordResetToken,
};
