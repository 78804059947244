/**
 * @generated SignedSource<<411b6d723fd0829525846691c2159999>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HRISProvider = "DEEL" | "REMOTE";
export type HRISProviderEmployeeField = "Email" | "JobTitle" | "Name" | "StartDate" | "TaxResidenceCountryCode" | "USStateOfResidenceCode" | "WorkRelationship";
export type useMergeGrantee_MergeGranteeMutation$variables = {
  granteeId: string;
  hRISProvider: HRISProvider;
  hRISProviderEmployeeId: string;
  selectedHRISProviderEmployeeFields: ReadonlyArray<HRISProviderEmployeeField>;
};
export type useMergeGrantee_MergeGranteeMutation$data = {
  readonly mergeHRISProviderEmployeeWithGrantee: {
    readonly HRISProviderEmployee: {
      readonly " $fragmentSpreads": FragmentRefs<"useMergeGrantee_HRISProviderEmployee">;
    };
    readonly grantee: {
      readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeModal_Grantee" | "MergeEmployeeWithAnotherGranteeModal_Grantee">;
    };
  };
};
export type useMergeGrantee_MergeGranteeMutation = {
  response: useMergeGrantee_MergeGranteeMutation$data;
  variables: useMergeGrantee_MergeGranteeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hRISProvider"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hRISProviderEmployeeId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedHRISProviderEmployeeFields"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "granteeId",
    "variableName": "granteeId"
  },
  {
    "kind": "Variable",
    "name": "hRISProvider",
    "variableName": "hRISProvider"
  },
  {
    "kind": "Variable",
    "name": "hRISProviderEmployeeId",
    "variableName": "hRISProviderEmployeeId"
  },
  {
    "kind": "Variable",
    "name": "selectedHRISProviderEmployeeFields",
    "variableName": "selectedHRISProviderEmployeeFields"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMergeGrantee_MergeGranteeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MergeHRISProviderEmployeeWithGranteeResult",
        "kind": "LinkedField",
        "name": "mergeHRISProviderEmployeeWithGrantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployee",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useMergeGrantee_HRISProviderEmployee"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantee",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MergeEmployeeModal_Grantee"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MergeEmployeeWithAnotherGranteeModal_Grantee"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMergeGrantee_MergeGranteeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MergeHRISProviderEmployeeWithGranteeResult",
        "kind": "LinkedField",
        "name": "mergeHRISProviderEmployeeWithGrantee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HRISProviderEmployee",
            "kind": "LinkedField",
            "name": "HRISProviderEmployee",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hRISProvider",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hRISProviderEmployeeId",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantee",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contractStartDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Country",
                "kind": "LinkedField",
                "name": "taxResidenceCountry",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emoji",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "USState",
                "kind": "LinkedField",
                "name": "USStateOfResidence",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "workRelationship",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9b1f00c8ea0346aa4dc041fd762ea9af",
    "id": null,
    "metadata": {},
    "name": "useMergeGrantee_MergeGranteeMutation",
    "operationKind": "mutation",
    "text": "mutation useMergeGrantee_MergeGranteeMutation(\n  $granteeId: GranteeId!\n  $hRISProvider: HRISProvider!\n  $hRISProviderEmployeeId: String!\n  $selectedHRISProviderEmployeeFields: [HRISProviderEmployeeField!]!\n) {\n  mergeHRISProviderEmployeeWithGrantee(granteeId: $granteeId, hRISProvider: $hRISProvider, hRISProviderEmployeeId: $hRISProviderEmployeeId, selectedHRISProviderEmployeeFields: $selectedHRISProviderEmployeeFields) {\n    HRISProviderEmployee {\n      ...useMergeGrantee_HRISProviderEmployee\n      id\n    }\n    grantee {\n      ...MergeEmployeeModal_Grantee\n      ...MergeEmployeeWithAnotherGranteeModal_Grantee\n      id\n    }\n  }\n}\n\nfragment MergeEmployeeModal_Grantee on Grantee {\n  ...useMergeGrantee_Grantee\n  ...MergeEmployeeTable_Grantee\n}\n\nfragment MergeEmployeeTable_Grantee on Grantee {\n  name\n  email\n  jobTitle\n  contractStartDate\n  taxResidenceCountry {\n    code\n    name\n    emoji\n  }\n  USStateOfResidence {\n    code\n    name\n  }\n  workRelationship\n}\n\nfragment MergeEmployeeWithAnotherGranteeModal_Grantee on Grantee {\n  ...useMergeGrantee_Grantee\n  ...MergeEmployeeTable_Grantee\n}\n\nfragment useMergeGrantee_Grantee on Grantee {\n  id\n}\n\nfragment useMergeGrantee_HRISProviderEmployee on HRISProviderEmployee {\n  hRISProvider\n  hRISProviderEmployeeId\n}\n"
  }
};
})();

(node as any).hash = "1efc662b3963fe99e12e1a29451617f4";

export default node;
