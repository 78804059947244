/**
 * @generated SignedSource<<bc5ff16a0b61beade507651f05ccb840>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MergeEmployeeWithAnotherGranteeModal_Grantee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeTable_Grantee" | "useMergeGrantee_Grantee">;
  readonly " $fragmentType": "MergeEmployeeWithAnotherGranteeModal_Grantee";
};
export type MergeEmployeeWithAnotherGranteeModal_Grantee$key = {
  readonly " $data"?: MergeEmployeeWithAnotherGranteeModal_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeWithAnotherGranteeModal_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MergeEmployeeWithAnotherGranteeModal_Grantee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergeGrantee_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeTable_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "86d536ab528218c0c53f42a0323d0253";

export default node;
