import { makeRemoteController } from "../../helpers/makeRemoteController";
import { Modal } from "./Modal";

export const ConfirmationModal: React.FC<
  React.PropsWithChildren<{
    cancelLabel?: string;
    confirmationLabel?: string;
    dataCy?: string;
    loading?: boolean;
    onClose: () => void;
    onConfirmed: () => void;
    show: boolean;
    suspense?: boolean;
    title: React.ReactNode;
  }>
> = ({
  cancelLabel = "Cancel",
  children,
  confirmationLabel = "Confirm",
  dataCy,
  loading,
  onClose,
  onConfirmed,
  show,
  suspense,
  title,
}) => {
  return (
    <Modal dataCy={dataCy} onClose={onClose} show={show} suspense={suspense}>
      <Modal.Content
        actionsInHeader={
          <>
            <Modal.ActionButton onClick={onClose} variant="Secondary Full">
              {cancelLabel}
            </Modal.ActionButton>
            <Modal.ActionButton
              loading={loading}
              onClick={onConfirmed}
              variant="Danger Full"
            >
              {confirmationLabel}
            </Modal.ActionButton>
          </>
        }
        onClose={onClose}
        subTitle={children}
        title={title}
      />
    </Modal>
  );
};

export const ConfirmationModalRemote = makeRemoteController<
  Omit<
    React.ComponentProps<typeof ConfirmationModal>,
    "onClose" | "onConfirmed" | "show"
  >,
  {
    confirmed: boolean;
  }
>({
  render: ({ close, state }) => {
    if (!state.data) {
      return null;
    }

    const props = {
      ...state.data,
      onClose: () => {
        close({ confirmed: false });
      },
      onConfirmed: () => {
        close({ confirmed: true });
      },
    };

    return <ConfirmationModal show={state.show} {...props} />;
  },
});
