import classNames from "classnames";

export const CountriesTableFooter: React.FC<React.ComponentProps<"footer">> = ({
  className,
  ...props
}) => (
  <footer
    className={classNames(
      "table-footer-group border-t-[0.5px] border-gray-04 bg-gray-01 text-black-05 group-[.grid]:col-span-full group-[.grid]:grid group-[.grid]:grid-cols-subgrid [&>tr]:p-4",
      className,
    )}
    {...props}
  />
);
