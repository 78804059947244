/**
 * @generated SignedSource<<0202d4cbab90aeccf3408826a0e6d66a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateInformation_Organization$data = {
  readonly HRISProviderEmployeesSuggestedToUpdateLinkedGrantee: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UpdateInformationItem_HRISProviderEmployee">;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateInformationItem_Organization">;
  readonly " $fragmentType": "UpdateInformation_Organization";
};
export type UpdateInformation_Organization$key = {
  readonly " $data"?: UpdateInformation_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateInformation_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateInformation_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateInformationItem_Organization"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HRISProviderEmployee",
      "kind": "LinkedField",
      "name": "HRISProviderEmployeesSuggestedToUpdateLinkedGrantee",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UpdateInformationItem_HRISProviderEmployee"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "299bbd034ea350e6d9ec55efb8917c6a";

export default node;
