/**
 * @generated SignedSource<<b91869ea5f2cc2b182a140dc712282de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation$variables = {
  allowedAsAdmin: boolean;
  remoteCompanyAdminId: string;
};
export type RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation$data = {
  readonly setRemoteCompanyAdminAllowedAsAdmin: {
    readonly " $fragmentSpreads": FragmentRefs<"RemoteCompanyAdminsList_Organization">;
  };
};
export type RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation = {
  response: RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation$data;
  variables: RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "allowedAsAdmin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "remoteCompanyAdminId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "allowedAsAdmin",
    "variableName": "allowedAsAdmin"
  },
  {
    "kind": "Variable",
    "name": "remoteCompanyAdminId",
    "variableName": "remoteCompanyAdminId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setRemoteCompanyAdminAllowedAsAdmin",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RemoteCompanyAdminsList_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "setRemoteCompanyAdminAllowedAsAdmin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "orderBy": {
                    "direction": "ASC",
                    "field": "name"
                  }
                }
              }
            ],
            "concreteType": "RemoteCompanyAdmin",
            "kind": "LinkedField",
            "name": "remoteCompanyAdmins",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userSlug",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "allowedAsAdmin",
                "storageKey": null
              }
            ],
            "storageKey": "remoteCompanyAdmins(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"name\"}})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d37be3a2dd98a15bc740a6ebec2a336f",
    "id": null,
    "metadata": {},
    "name": "RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation",
    "operationKind": "mutation",
    "text": "mutation RemoteCompanyAdminsList_SetRemoteCompanyAdminAllowedAsAdmin_Mutation(\n  $allowedAsAdmin: Boolean!\n  $remoteCompanyAdminId: String!\n) {\n  setRemoteCompanyAdminAllowedAsAdmin(allowedAsAdmin: $allowedAsAdmin, remoteCompanyAdminId: $remoteCompanyAdminId) {\n    ...RemoteCompanyAdminsList_Organization\n    id\n  }\n}\n\nfragment RemoteCompanyAdminsList_Organization on Organization {\n  id\n  name\n  remoteCompanyAdmins(filters: {orderBy: {direction: ASC, field: name}}) {\n    id\n    userSlug\n    name\n    email\n    allowedAsAdmin\n  }\n}\n"
  }
};
})();

(node as any).hash = "39594d72fc1249a2be73ffba5251880a";

export default node;
