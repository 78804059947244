import {
  MenuButton as __MenuButton,
  Menu,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { Button } from "./Button";
import { LoadingSpinner } from "./LoadingSpinner";
import { Typography } from "./Typography";

type MenuItemVariant = "Danger" | "Default" | "Warning";

const STYLES: Record<MenuItemVariant, string> = {
  Danger:
    "bg-white text-red-05 hover:bg-red-01 hover:text-red-05 active:bg-red-02 active:text-red-05 disabled:text-red-02",
  Default:
    "bg-white text-black-07 hover:bg-gray-02 active:bg-gray-03 disabled:bg-gray-01 disabled:text-gray-05",
  Warning:
    "bg-white text-orange-05 hover:bg-orange-01 hover:text-orange-05 active:bg-orange-02 active:text-orange-05 disabled:text-orange-02",
};

const _LinkItem: React.ForwardRefRenderFunction<
  HTMLAnchorElement,
  React.ComponentProps<typeof Link> & {
    leftIcon: React.ReactElement<React.ComponentProps<"svg">>;
    variant?: MenuItemVariant;
  }
> = ({ children, className, leftIcon, variant = "Default", ...props }, ref) => (
  <Link
    className={classNames(
      "cursor-pointer px-2 py-1.5 transition-all",
      STYLES[variant],
      className,
    )}
    {...props}
    ref={ref}
  >
    <Typography
      as="div"
      className="flex w-full items-center gap-2"
      variant="Regular/Extra Small"
    >
      {leftIcon && <div className="h-4 w-4 p-[1px]">{leftIcon}</div>}
      {children}
    </Typography>
  </Link>
);
const LinkItem = React.forwardRef(_LinkItem);

const _Item: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  {
    children: React.ReactNode;
    dataCy?: string;
    disabled?: boolean;
    leftIcon?: React.ReactElement<React.ComponentProps<"svg">>;
    loading?: boolean;
    onClick: () => void;
    variant?: MenuItemVariant;
  }
> = (
  {
    children,
    dataCy,
    disabled,
    leftIcon,
    loading = false,
    onClick,
    variant = "Default",
  },
  ref,
) => (
  <button
    className={classNames(
      "select-none px-2 py-1.5 transition-all",
      disabled
        ? "cursor-not-allowed"
        : loading
          ? "pointer-events-none"
          : "cursor-pointer",
      STYLES[variant],
    )}
    data-cy={dataCy}
    disabled={disabled}
    onClick={onClick}
    ref={ref}
    type="button"
  >
    <Transition
      as="div"
      className="flex items-center justify-center"
      enter="transition-all duration-150"
      enterFrom="opacity-0 -ml-6"
      enterTo="opacity-100 ml-0"
      leave="transition-all duration-75"
      leaveFrom="opacity-100 ml-0"
      leaveTo="opacity-0 -ml-6"
      show={loading}
    >
      <LoadingSpinner className="h-4 w-4" />
    </Transition>

    {!loading && (
      <Typography
        as="div"
        className="flex w-full items-center gap-2"
        variant="Regular/Extra Small"
      >
        {leftIcon && <div className="h-4 w-4 p-[1px]">{leftIcon}</div>}
        {children}
      </Typography>
    )}
  </button>
);
const Item = React.forwardRef(_Item);

export type MenuButtonPlacement = `${Placement} ${Align}` | `${Placement}`;
type Align = "end" | "start";
type Placement = "bottom" | "left" | "right" | "top";

const _MenuButton: React.FC<{
  button?: React.ReactElement<React.ComponentProps<"button">>;
  children: React.ReactNode;
  menuClassName?: React.ComponentProps<"div">["className"];
  placement?: MenuButtonPlacement;
}> = ({
  button = (
    <Button
      leftIcon={<EllipsisHorizontalIcon />}
      size="small"
      variant="Secondary Full"
    />
  ),
  children,
  menuClassName,
  placement = "bottom start",
}) => {
  return (
    <Menu as="div" className="relative">
      <__MenuButton as="div">{button}</__MenuButton>
      <MenuItems
        anchor={{ gap: 8, to: placement }}
        className={classNames(
          "z-10 min-w-[200px] rounded border-[0.5px] border-gray-07 bg-white shadow-100 focus:outline-none",
          menuClassName,
        )}
      >
        <div className="flex w-full flex-col py-2">
          {React.Children.map(
            React.Children.toArray(children).filter(Boolean),
            (child) => (
              <Menu.Item>{child}</Menu.Item>
            ),
          )}
        </div>
      </MenuItems>
    </Menu>
  );
};

export const MenuButton = Object.assign(_MenuButton, {
  Item,
  LinkItem,
});
