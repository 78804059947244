/**
 * @generated SignedSource<<bf93232afeb7a0e1fe4a8ed9d00185b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type Review_OrganizationPostTerminationExercisePeriod$data = {
  readonly duration: number;
  readonly durationUnit: DurationUnit;
  readonly extensionDuration?: number;
  readonly extensionDurationUnit?: DurationUnit;
  readonly thresholdForExtensionInYears?: number;
  readonly " $fragmentType": "Review_OrganizationPostTerminationExercisePeriod";
};
export type Review_OrganizationPostTerminationExercisePeriod$key = {
  readonly " $data"?: Review_OrganizationPostTerminationExercisePeriod$data;
  readonly " $fragmentSpreads": FragmentRefs<"Review_OrganizationPostTerminationExercisePeriod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Review_OrganizationPostTerminationExercisePeriod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationUnit",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extensionDuration",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "extensionDurationUnit",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "thresholdForExtensionInYears",
          "storageKey": null
        }
      ],
      "type": "OrganizationVariablePostTerminationExercisePeriod",
      "abstractKey": null
    }
  ],
  "type": "OrganizationPostTerminationExercisePeriod",
  "abstractKey": "__isOrganizationPostTerminationExercisePeriod"
};

(node as any).hash = "d2790161b132795a6bc742356b7f071d";

export default node;
