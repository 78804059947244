/**
 * @generated SignedSource<<6076f74bc7db0261a35fcf4d53e579c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewPTEPSlider_CTMSGrant$data = {
  readonly id: string;
  readonly isVirtual: boolean;
  readonly matchingInstrument: {
    readonly equityType: {
      readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType">;
    } | null;
  } | null;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "NewPTEPSlider_CTMSGrant";
};
export type NewPTEPSlider_CTMSGrant$key = {
  readonly " $data"?: NewPTEPSlider_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewPTEPSlider_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewPTEPSlider_CTMSGrant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVirtual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "matchingInstrument",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "b20a62c4aa56be71bb7ec863e7c02045";

export default node;
