export const SharesIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    className={className}
    fill="currentColor"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.4448 4.63262C14.0182 3.18595 12.8115 1.97928 11.3648 1.55262C10.2648 1.23262 9.50482 1.25928 8.97815 1.65262C8.34482 2.12595 8.27148 2.97929 8.27148 3.58595V5.24595C8.27148 6.88595 9.01815 7.71929 10.4848 7.71929H12.3982C12.9982 7.71929 13.8582 7.64595 14.3315 7.01262C14.7382 6.49262 14.7715 5.73262 14.4448 4.63262Z" />
    <path
      d="M12.6056 8.90579C12.4323 8.70579 12.179 8.59246 11.919 8.59246H9.53229C8.35896 8.59246 7.40562 7.63912 7.40562 6.46579V4.07912C7.40562 3.81912 7.29229 3.56579 7.09229 3.39246C6.89896 3.21912 6.63229 3.13912 6.37896 3.17246C4.81229 3.37246 3.37229 4.23246 2.43229 5.52579C1.48562 6.82579 1.13896 8.41246 1.43896 9.99912C1.87229 12.2925 3.70562 14.1258 6.00562 14.5591C6.37229 14.6325 6.73896 14.6658 7.10562 14.6658C8.31229 14.6658 9.47896 14.2925 10.4723 13.5658C11.7656 12.6258 12.6256 11.1858 12.8256 9.61912C12.859 9.35912 12.779 9.09912 12.6056 8.90579Z"
      opacity="0.4"
    />
  </svg>
);
