/**
 * @generated SignedSource<<d40e73469d5d1aa7185069f800fb3d66>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetails_Account$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsComponent_Account">;
  readonly " $fragmentType": "DraftGrantDetails_Account";
};
export type DraftGrantDetails_Account$key = {
  readonly " $data"?: DraftGrantDetails_Account$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetails_Account">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetails_Account",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v0/*: any*/),
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "DraftGrantDetailsComponent_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "50b628760a2ab51b2af27dca31f13803";

export default node;
