import { graphql, useFragment } from "react-relay";

import { GranteeNameWithCountryFlag_Grantee$key } from "./__generated__/GranteeNameWithCountryFlag_Grantee.graphql";
import { CountryFlag } from "./CountryFlag";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteeNameWithCountryFlag_Grantee on Grantee {
    name
    taxResidenceCountry {
      ...CountryFlag_Country
    }
  }
`;

export const GranteeNameWithCountryFlag: React.FC<{
  granteeFragment: GranteeNameWithCountryFlag_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return grantee.taxResidenceCountry ? (
    <>
      <CountryFlag countryFragment={grantee.taxResidenceCountry} />{" "}
      {grantee.name}
    </>
  ) : (
    grantee.name
  );
};
