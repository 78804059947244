import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import { differenceInYears } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useApplicationName } from "../../hooks/useApplicationTheme";
import { useManualQuery } from "../../hooks/useManualQuery";
import { useQuery } from "../../hooks/useQuery";
import { GrantAmendmentRequest } from "../../services/grantAmendmentRequest";
import { CtmsGrantExerciseTag } from "../CtmsGrantExerciseTag";
import { CtmsGrantVestingTag } from "../CtmsGrantVestingTag";
import { GrantWatchouts } from "../GrantWatchouts";
import { LongDate } from "../LongDate";
import { Toggle } from "../ui/Form/Toggle";
import { NoticeMessage } from "../ui/NoticeMessage";
import { RoundedBox } from "../ui/RoundedBox";
import { StateUpdateRow } from "../ui/StateUpdateRow";
import { Tag } from "../ui/Tag";
import { FadeTransition } from "../ui/Transition";
import { Typography } from "../ui/Typography";
import { VestedProgress } from "../ui/VestedProgress";
import { GrantAmendmentRequestBox_CTMSGrant$key } from "./__generated__/GrantAmendmentRequestBox_CTMSGrant.graphql";
import { GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query } from "./__generated__/GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query.graphql";
import { GrantAmendmentRequestBox_Organization$key } from "./__generated__/GrantAmendmentRequestBox_Organization.graphql";
import { GrantAmendmentRequestBox_Viewer$key } from "./__generated__/GrantAmendmentRequestBox_Viewer.graphql";
import { GrantAmendmentRequestBoxPTEP } from "./GrantAmendmentRequestBoxPTEP";
import { GrantAmendmentRequestBoxVestingEnd } from "./GrantAmendmentRequestBoxVestingEnd";

const VIEWER_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBox_Viewer on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    isAllowedToManageOrganization(organizationId: $organizationId)
  }
`;

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBox_Organization on Organization {
    ...GrantAmendmentRequestBoxPTEP_Organization
  }
`;

const GRANT_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBox_CTMSGrant on CTMSGrant {
    id
    label
    quantityIssued
    grantee {
      name
      contractStartDate
    }
    easopGrant {
      postTerminationExercisePeriod {
        __typename
        ... on OrganizationVariablePostTerminationExercisePeriod {
          thresholdForExtensionInYears
        }
      }
    }
    matchingInstrument {
      equityType {
        ...GrantWatchouts_CliffWaivedWatchout_EquityType
      }
    }
    ...CtmsGrantVestingTag_CtmsGrant
    ...CtmsGrantExerciseTag_CtmsGrant
    ...GrantAmendmentRequestBoxPTEP_CtmsGrant
    ...GrantAmendmentRequestBoxVestingEnd_CtmsGrant
  }
`;

const GET_QUANTITY_VESTED_AT_END_OF_VESTING_QUERY = graphql`
  query GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query(
    $ctmsGrantId: CtmsGrantId!
    $grantAmendmentAttributes: GranteeTerminationCTMSGrantAmendmentRequestAttributes!
    $terminationDate: Date!
    $terminationLastDayAtTheCompany: Date!
  ) {
    getQuantityVestedAtEndOfVestingForGrantAmendment(
      ctmsGrantId: $ctmsGrantId
      grantAmendmentAttributes: $grantAmendmentAttributes
      terminationDate: $terminationDate
      terminationLastDayAtTheCompany: $terminationLastDayAtTheCompany
    ) {
      __typename
      ... on GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess {
        value
      }
      ... on GetQuantityVestedAtEndOfVestingForGrantAmendmentFailure {
        __typename
      }
    }
  }
`;

export const GrantAmendmentRequestBox: React.FC<{
  ctmsGrantFragment: GrantAmendmentRequestBox_CTMSGrant$key;
  grantAmendment: GrantAmendmentRequest | null;
  onChange: (grantAmendment: GrantAmendmentRequest) => GrantAmendmentRequest;
  organizationFragment: GrantAmendmentRequestBox_Organization$key;
  readOnly?: boolean;
  terminationDate: string;
  terminationLastDayAtTheCompany: string;
  viewerFragment: GrantAmendmentRequestBox_Viewer$key;
}> = ({
  ctmsGrantFragment,
  grantAmendment,
  onChange,
  organizationFragment,
  readOnly: _readOnly,
  terminationDate,
  terminationLastDayAtTheCompany,
  viewerFragment,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const [simulateQuantityVested] =
    useManualQuery<GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query>(
      GET_QUANTITY_VESTED_AT_END_OF_VESTING_QUERY,
    );
  const readOnly = _readOnly || !viewer.isAllowedToManageOrganization;

  const {
    query: {
      getQuantityVestedAtEndOfVestingForGrantAmendment:
        defaultQuantityVestedOnTermination,
    },
  } = useQuery<GrantAmendmentRequestBox_GetQuantityVestedAtEndOfVesting_Query>(
    GET_QUANTITY_VESTED_AT_END_OF_VESTING_QUERY,
    {
      ctmsGrantId: ctmsGrant.id,
      grantAmendmentAttributes: {
        vestingEndOption: "TERMINATION_DATE",
      },
      terminationDate,
      terminationLastDayAtTheCompany,
    },
  );

  const [simulatedQuantityVested, setSimulatedQuantityVested] = useState<
    | { status: "error" }
    | { status: "loaded"; value: number }
    | { status: "pristine" }
  >({ status: "pristine" });

  useEffect(() => {
    if (!grantAmendment) return;

    simulateQuantityVested({
      onResponse: (response) => {
        switch (
          response.getQuantityVestedAtEndOfVestingForGrantAmendment.__typename
        ) {
          case "GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess":
            return setSimulatedQuantityVested({
              status: "loaded",
              value:
                response.getQuantityVestedAtEndOfVestingForGrantAmendment.value,
            });
          default:
            return setSimulatedQuantityVested({ status: "error" });
        }
      },
      variables: {
        ctmsGrantId: ctmsGrant.id,
        grantAmendmentAttributes: {
          postTerminationExercisePeriodId:
            grantAmendment.postTerminationExercisePeriodId,
          vestingEndCustomDate: grantAmendment.vestingEndCustomDate,
          vestingEndOption: grantAmendment.vestingEndOption,
          waiveCliff: grantAmendment.waiveCliff,
        },
        terminationDate,
        terminationLastDayAtTheCompany,
      },
    });
  }, [
    ctmsGrant.id,
    setSimulatedQuantityVested,
    simulateQuantityVested,
    terminationDate,
    terminationLastDayAtTheCompany,
    grantAmendment,
  ]);

  const hasPassedCliff = useMemo(
    () =>
      defaultQuantityVestedOnTermination.__typename ===
        "GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess" &&
      defaultQuantityVestedOnTermination.value > 0,
    [defaultQuantityVestedOnTermination],
  );

  const granteeIsInTheirExtendedPTEPPeriod = useMemo(() => {
    if (
      !ctmsGrant.easopGrant ||
      !ctmsGrant.grantee.contractStartDate ||
      ctmsGrant.easopGrant.postTerminationExercisePeriod.__typename !==
        "OrganizationVariablePostTerminationExercisePeriod"
    )
      return false;

    const granteeYearsOfService = differenceInYears(
      new Date(terminationLastDayAtTheCompany),
      new Date(ctmsGrant.grantee.contractStartDate),
    );

    return (
      granteeYearsOfService >=
      ctmsGrant.easopGrant.postTerminationExercisePeriod
        .thresholdForExtensionInYears
    );
  }, [
    ctmsGrant.easopGrant,
    ctmsGrant.grantee.contractStartDate,
    terminationLastDayAtTheCompany,
  ]);

  const showExtendedPTEWarning =
    viewer.isSuperAdmin && granteeIsInTheirExtendedPTEPPeriod;

  const applicationName = useApplicationName();

  return (
    <RoundedBox withBorder withShadow>
      <div className="space-y-2 p-6">
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-col items-start gap-2">
            <Typography as="div" variant="Medium/Default">
              {ctmsGrant.label}
            </Typography>

            {!hasPassedCliff ? <Tag color="orange">⚠ Cliff period</Tag> : null}

            <div className="flex gap-2">
              <CtmsGrantVestingTag ctmsGrantFragment={ctmsGrant} />
              <CtmsGrantExerciseTag ctmsGrantFragment={ctmsGrant} />
            </div>
          </div>

          {simulatedQuantityVested.status === "error" ||
          defaultQuantityVestedOnTermination.__typename !==
            "GetQuantityVestedAtEndOfVestingForGrantAmendmentSuccess" ? (
            <NoticeMessage size="Small" variant="Warning">
              This grant was made outside of {applicationName}, we are unable to
              retrieve enough information from your CTMS to simulate the new
              vesting schedule
            </NoticeMessage>
          ) : simulatedQuantityVested.status === "pristine" ||
            defaultQuantityVestedOnTermination.value ===
              simulatedQuantityVested.value ? (
            <VestedProgress
              grantedShares={ctmsGrant.quantityIssued}
              vestedShares={defaultQuantityVestedOnTermination.value}
            />
          ) : (
            <div className="flex items-center gap-5">
              <FadeTransition appear className="flex items-center gap-5" show>
                <VestedProgress
                  grantedShares={ctmsGrant.quantityIssued}
                  variant="red"
                  vestedShares={defaultQuantityVestedOnTermination.value}
                  vestedSharesClassName="line-through"
                />
                <ArrowSmallRightIcon className="w-6 text-primary" />
              </FadeTransition>
              <VestedProgress
                grantedShares={ctmsGrant.quantityIssued}
                vestedShares={simulatedQuantityVested.value}
              />
            </div>
          )}
        </div>
        {showExtendedPTEWarning && (
          <NoticeMessage
            className="mx-auto w-fit"
            size="Small"
            variant="Warning"
          >
            This grantee is in their <strong>extended PTEP.</strong> Please
            update CTMS accordingly.
          </NoticeMessage>
        )}
      </div>

      {!hasPassedCliff && (
        <div className="flex flex-col gap-4 border-t-[0.5px] border-gray-04 p-6">
          <Typography as="div" variant="Regular/Extra Small">
            Cliff period
          </Typography>

          <StateUpdateRow
            label={
              <>
                Won’t be reached on {<LongDate value={terminationDate} />}. You
                can decide to waive the cliff and allow {ctmsGrant.grantee.name}{" "}
                to vest shares.
              </>
            }
          >
            <div className="flex items-center gap-4">
              <Typography as="div" variant="Regular/Extra Small">
                Waive cliff?
              </Typography>
              <Toggle
                disabled={readOnly}
                enabled={grantAmendment?.waiveCliff || false}
                onChange={(waiveCliff) => {
                  onChange({
                    ctmsGrantId: ctmsGrant.id,
                    waiveCliff,
                  });
                }}
              />
            </div>
          </StateUpdateRow>

          {ctmsGrant.matchingInstrument?.equityType && (
            <GrantWatchouts.CliffWaivedWatchout
              cliffWaived={grantAmendment?.waiveCliff ?? false}
              equityTypeFragment={ctmsGrant.matchingInstrument.equityType}
            />
          )}
        </div>
      )}

      {hasPassedCliff || grantAmendment?.waiveCliff ? (
        <>
          <GrantAmendmentRequestBoxPTEP
            ctmsGrantFragment={ctmsGrant}
            grantAmendment={grantAmendment}
            onChange={onChange}
            organizationFragment={organization}
            readOnly={readOnly}
            terminationDate={terminationDate}
          />
          <GrantAmendmentRequestBoxVestingEnd
            ctmsGrantFragment={ctmsGrant}
            grantAmendment={grantAmendment}
            onChange={onChange}
            readOnly={readOnly}
            terminationDate={terminationDate}
            terminationLastDayAtTheCompany={terminationLastDayAtTheCompany}
          />
        </>
      ) : null}
    </RoundedBox>
  );
};
