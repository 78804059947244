import React from "react";

import { AlertElementProps } from "../Alerter";
import { Alert } from "../ui/Alert";

export const EquityTypeInCountryNotUnlockedAlert: React.FC<
  Omit<AlertElementProps, "children" | "title">
> = ({ ...props }) => {
  return (
    <Alert title="" {...props}>
      We need to set up a special plan for this equity type
    </Alert>
  );
};
