import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { Link, NavLink } from "react-router-dom";

import { Typography } from "./Typography";

const Join: React.FC = () => (
  <ChevronRightIcon className="h-5 w-5 text-gray-09" />
);

const _Link: React.FC<React.ComponentProps<typeof Link>> = ({
  children,
  className,
  ...props
}) => (
  <NavLink
    className={({ isActive }) =>
      classNames(
        {
          "text-gray-09 transition-all hover:text-black-05": !isActive,
        },
        className,
      )
    }
    end
    {...props}
  >
    {({ isActive }) => (
      <Typography
        variant={isActive ? "Medium/Extra Small" : "Regular/Extra Small"}
      >
        {children}
      </Typography>
    )}
  </NavLink>
);

const _BreadCrumb: React.FC<{
  children:
    | React.ReactElement<typeof _Link>
    | React.ReactElement<typeof _Link>[];
  className?: string;
}> = ({ children, className }) => (
  <div className={classNames("flex items-center gap-2", className)}>
    {React.Children.map(children, (child, index) => {
      const isFirstItem = index === 0;

      return (
        <>
          {!isFirstItem && <Join />}
          {child}
        </>
      );
    })}
  </div>
);

export const BreadCrumb = Object.assign(_BreadCrumb, { Link: _Link });
