/**
 * @generated SignedSource<<98fd7d16bc952db562b7825c40e435cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesFilters_Exercises$data = ReadonlyArray<{
  readonly ctmsGrant: {
    readonly grantee: {
      readonly email: string;
      readonly name: string;
    };
  };
  readonly ctmsGrantLabel: string;
  readonly ctmsId: string;
  readonly totalExercisePrice: number;
  readonly " $fragmentType": "ExercisesFilters_Exercises";
}>;
export type ExercisesFilters_Exercises$key = ReadonlyArray<{
  readonly " $data"?: ExercisesFilters_Exercises$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesFilters_Exercises">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExercisesFilters_Exercises",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctmsGrantLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalExercisePrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Grantee",
          "kind": "LinkedField",
          "name": "grantee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CTMSExerciseRequest",
  "abstractKey": null
};

(node as any).hash = "91d6e723f361d7a440af5e557d1e61a1";

export default node;
