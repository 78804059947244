/**
 * @generated SignedSource<<2b5ddf5aeae2ad167a05fb890be0df24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDocumentationPage_Query$variables = {
  organizationId: string;
};
export type GrantDocumentationPage_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationPage_Organization">;
  } | null;
};
export type GrantDocumentationPage_Query = {
  response: GrantDocumentationPage_Query$data;
  variables: GrantDocumentationPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantDocumentationPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GrantDocumentationPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GrantDocumentationPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "filters",
                "value": {
                  "orderBy": {
                    "direction": "ASC",
                    "field": "label"
                  }
                }
              }
            ],
            "concreteType": "EasopGrant",
            "kind": "LinkedField",
            "name": "easopGrants",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Grantee",
                "kind": "LinkedField",
                "name": "grantee",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "taxResidenceCountry",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emoji",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "grantNoticeDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "awardAgreementDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "signedBoardConsentDocument",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "countrySpecificAddendumDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "formOfExerciseAgreementDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Document",
                "kind": "LinkedField",
                "name": "formOfEarlyExerciseStockPurchaseAgreementDocument",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantityGranted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Instrument",
                "kind": "LinkedField",
                "name": "instrument",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "awardSuperType",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": "easopGrants(filters:{\"orderBy\":{\"direction\":\"ASC\",\"field\":\"label\"}})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78819762f8439f53730a28a0994aee4b",
    "id": null,
    "metadata": {},
    "name": "GrantDocumentationPage_Query",
    "operationKind": "query",
    "text": "query GrantDocumentationPage_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...GrantDocumentationPage_Organization\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment GrantDocumentationPage_GrantCard on EasopGrant {\n  ...useGrantDocuments_EasopGrant\n  label\n  quantityGranted\n  instrument {\n    awardSuperType\n    id\n  }\n  grantee {\n    name\n    ...GranteeNameWithCountryFlag_Grantee\n    id\n  }\n  signedBoardConsentDocument {\n    __typename\n    id\n  }\n}\n\nfragment GrantDocumentationPage_Organization on Organization {\n  id\n  name\n  easopGrants(filters: {orderBy: {field: label, direction: ASC}}) {\n    ...GrantDocumentationPage_GrantCard\n    id\n    label\n    signedBoardConsentDocument {\n      __typename\n      id\n    }\n  }\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment useGrantDocuments_EasopGrant on EasopGrant {\n  label\n  grantee {\n    name\n    id\n  }\n  grantNoticeDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  awardAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  signedBoardConsentDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  countrySpecificAddendumDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  formOfExerciseAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  formOfEarlyExerciseStockPurchaseAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "069b39276d657506590440486098e6f1";

export default node;
