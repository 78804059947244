import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { SelectCompanies_Companies$key } from "./__generated__/SelectCompanies_Companies.graphql";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { SuspensedInput } from "./ui/SuspensedInput";

const COMPANIES_FRAGMENT = graphql`
  fragment SelectCompanies_Companies on Company @relay(plural: true) {
    id
    completeName
  }
`;

interface Props {
  companies: SelectCompanies_Companies$key;
  invalid?: boolean;
  onChange: (companyId?: string) => void;
  placeholder?: string;
  selectedCompanyId?: null | string;
}

const SelectCompaniesFetcher: React.FC<Props> = ({
  companies,
  invalid = false,
  onChange,
  placeholder,
  selectedCompanyId,
}) => {
  const companiesFragment = useFragment(COMPANIES_FRAGMENT, companies);

  const options = companiesFragment.map((company) => ({
    id: company.id,
    label: company.completeName,
    value: company.id,
  }));

  return (
    <SelectAutocomplete
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      invalid={invalid}
      onChange={(newValue) => onChange(newValue?.value)}
      options={options}
      placeholder={placeholder}
      usePortal
      value={options.find(({ id }) => selectedCompanyId === id) ?? null}
    />
  );
};

export const SelectCompanies: React.FC<Props> = (props) => {
  return (
    <Suspense fallback={<SuspensedInput />}>
      <SelectCompaniesFetcher {...props} />
    </Suspense>
  );
};
