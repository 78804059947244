import { InputText as _RemoteInputText } from "@remote-com/norma";
import React from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

const FormInput = <TFieldValues extends FieldValues>({
  control,
  name,
  ...props
}: React.ComponentProps<typeof _RemoteInputText> & {
  control: Control<TFieldValues>;
  name: FieldPath<TFieldValues>;
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <_RemoteInputText
          {...field}
          {...props}
          errorText={fieldState.error?.message}
          onChange={(event) => {
            field.onChange(event);
          }}
          value={field.value ?? ""}
        />
      )}
    />
  );
};

export const RemoteInputText = Object.assign(_RemoteInputText, {
  Form: FormInput,
});
