import { useCallback } from "react";
import { useMutation, UseMutationConfig } from "react-relay";
import { useError } from "react-use";
import { GraphQLTaggedNode, MutationParameters } from "relay-runtime";

export function useSafeMutation<TMutation extends MutationParameters>(
  gqlQuery: GraphQLTaggedNode,
) {
  const dispatchError = useError();

  const [triggerMutation_, mutationIsInFlight] =
    useMutation<TMutation>(gqlQuery);

  const triggerMutation = useCallback(
    (
      config: Omit<
        UseMutationConfig<TMutation>,
        "onCompleted" | "onError" | "updater"
      >,
    ) => {
      return new Promise<TMutation["response"]>((resolve, reject) => {
        triggerMutation_({
          onCompleted: (response, errors) => {
            if (errors && errors.length > 0) {
              // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
              reject(errors);
            }
            resolve(response);
          },
          onError: (error) => {
            dispatchError(error);
          },
          updater: (store) => {
            store.invalidateStore();
          },
          ...config,
        });
      });
    },
    [dispatchError, triggerMutation_],
  );

  return [triggerMutation, mutationIsInFlight] as const;
}
