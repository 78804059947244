import { ChevronRightIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import {
  FavoriteChart,
  Heart,
  People,
  Scanning,
  SunFog,
  UserTick,
} from "iconsax-react";
import React from "react";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { LinkButton } from "../../../../components/ui/Button";
import { FormRowContainer } from "../../../../components/ui/Form/FormRowContainer";
import { Progress } from "../../../../components/ui/Progress";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { useApplicationSupportEmailAddress } from "../../../../hooks/useApplicationTheme";
import { useQuery } from "../../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../../paths";
import { EquityPhilosophy_Query } from "./__generated__/EquityPhilosophy_Query.graphql";
import { ConfigureGrantsLayout } from "./ConfigureGrantsLayout";

const QUERY = graphql`
  query EquityPhilosophy_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
    }
  }
`;

const LinkWithIcon: React.FC<{
  children: React.ReactNode;
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  to: string;
}> = ({ children, icon, to }) => (
  <Link to={to}>
    <RoundedBox className="flex items-center gap-4 p-6" withBorder>
      {React.cloneElement(icon, {
        className: classNames("w-6 text-purple", icon.props.className),
        ...icon.props,
      })}
      <Typography className="flex-1" variant="Medium/Small">
        {children}
      </Typography>
      <ChevronRightIcon className="w-5 text-black-05" />
    </RoundedBox>
  </Link>
);

const Card: React.FC<{
  className?: string;
  description: React.ReactNode;
  icon: React.ReactElement<React.ComponentProps<"svg">>;
  title: React.ReactNode;
}> = ({ className, description, icon, title }) => (
  <RoundedBox
    background="gray"
    className={classNames("space-y-4 p-6", className)}
    rounded="2xl"
  >
    {React.cloneElement(icon, {
      className: classNames("w-6 text-primary", icon.props.className),
      ...icon.props,
    })}
    <div className="space-y-2">
      <Typography as="div" variant="Medium/Small">
        {title}
      </Typography>
      <Typography
        as="div"
        className="text-black-05"
        variant="Regular/Extra Small"
      >
        {description}
      </Typography>
    </div>
  </RoundedBox>
);

export default function EquityPhilosophy() {
  const organizationId = useOrganizationIdParam();

  const { query } = useQuery<EquityPhilosophy_Query>(QUERY, {
    organizationId,
  });

  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Page
      analyticsCategory="Admin Configure Equity Philosophy"
      analyticsName="Admin - Equity - Configure - Equity Philosophy"
      organizationId={organizationId}
      title={`Admin | ${query.organization.name} organization equity philosophy settings`}
    >
      <ConfigureGrantsLayout>
        <div className="space-y-10">
          <RoundedBox
            background="gradient-light-aubergine"
            className="flex items-start gap-10 p-10"
            rounded="2xl"
          >
            <div className="flex-1 space-y-4">
              <Heart className="h-8 w-8 text-purple" variant="Bulk" />
              <Typography as="div" variant="Medium/Large">
                Become a Better Employer
              </Typography>
              <Typography
                as="div"
                className="text-black-05"
                variant="Regular/Small"
              >
                Define your equity philosophy, set up your bands, allocate
                levels and build a story around your hiring and retention
                policies to motivate your hard working team
              </Typography>
              <Progress className="bg-gray-03" max={100}>
                <Progress.Value className="bg-black-07" value={25} />
              </Progress>
              <div className="flex items-center gap-2">
                <Typography variant="Regular/Extra Small">
                  Need some help?
                </Typography>
                <LinkButton
                  size="small"
                  to={`mailto:${supportEmailAddress}?subject=Talk%20to%our%20experts`}
                  variant="Secondary Outline"
                >
                  Talk to us
                </LinkButton>
              </div>
            </div>
            <RoundedBox
              className="flex flex-1 flex-col gap-2 p-4"
              rounded="2xl"
              withBorder
            >
              <Typography as="div" variant="Medium/Extra Small">
                CHECKLIST
              </Typography>
              <LinkWithIcon
                icon={<Scanning variant="Bulk" />}
                to={generatePath(
                  APPLICATION_ROUTES["organizationEquityConfigureEquityGrid"],
                  {
                    organizationId,
                  },
                )}
              >
                Set up your equity grid
              </LinkWithIcon>
              <LinkWithIcon
                icon={<UserTick variant="Bulk" />}
                to={generatePath(
                  APPLICATION_ROUTES["organizationEquityConfigureNewHires"],
                  {
                    organizationId,
                  },
                )}
              >
                Define your new hires policy
              </LinkWithIcon>
              <LinkWithIcon
                icon={<UserTick variant="Bulk" />}
                to={generatePath(
                  APPLICATION_ROUTES["organizationEquityConfigureRefreshers"],
                  {
                    organizationId,
                  },
                )}
              >
                Define your tenure grants policy
              </LinkWithIcon>
            </RoundedBox>
          </RoundedBox>
          <FormRowContainer title="EQUITY COMPENSATION, EQUITABLY">
            <div className="flex w-full items-start gap-6">
              <Card
                className="flex-1"
                description={
                  <>
                    Define retention and new hire policies and ensure
                    compensations are fair.
                  </>
                }
                icon={<SunFog variant="Bulk" />}
                title="Decide on your equity philosophy"
              />
              <Card
                className="flex-1"
                description={
                  <>
                    Make informed decisions on equity bands and team
                    incentivization.
                  </>
                }
                icon={<FavoriteChart variant="Bulk" />}
                title="Use equity bands"
              />
              <Card
                className="flex-1"
                description={
                  <>
                    Determine equity compensation in line with market rates and
                    retain more talents.
                  </>
                }
                icon={<People variant="Bulk" />}
                title="Make competitive decisions"
              />
            </div>
          </FormRowContainer>
        </div>
      </ConfigureGrantsLayout>
    </Page>
  );
}
