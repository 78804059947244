import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
  LightBulbIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { useLocalStorage } from "react-use";

import { Typography, TypographyVariant } from "./Typography";

type Size = "Large" | "Small";
type Variant = "Danger" | "Idea" | "Info" | "Positive" | "Warning";

const EMOJI: Record<
  Variant,
  React.ReactElement<React.ComponentProps<"svg">>
> = {
  Danger: <ExclamationTriangleIcon />,
  Idea: <LightBulbIcon />,
  Info: <InformationCircleIcon />,
  Positive: <CheckIcon />,
  Warning: <ExclamationCircleIcon />,
};

const STYLE_SIZE: Record<Size, string> = {
  Large: classNames("p-4 gap-4"),
  Small: classNames("p-2 gap-2"),
};

const HAS_COLOR_STYLE: Record<Variant, null | string> = {
  Danger: classNames("bg-red-50 border border-red-400"),
  Idea: classNames("bg-yellow-50 border border-yellow-400"),
  Info: classNames("bg-purple-50 border border-purple-400"),
  Positive: classNames("bg-green-50 border border-green-400"),
  Warning: classNames("bg-orange-50 border border-orange-400"),
};

const ICON_STYLE: Record<Variant, string> = {
  Danger: /* tailwind */ `bg-red-02 text-red-08`,
  Idea: /* tailwind */ `bg-yellow-02 text-yellow-08`,
  Info: /* tailwind */ `bg-purple-02 text-purple-08`,
  Positive: /* tailwind */ `bg-green-02 text-green-08`,
  Warning: /* tailwind */ `bg-orange-02 text-orange-08`,
};

const TYPOGRAPHY_VARIANTS: Record<
  Size,
  { body: TypographyVariant; title: TypographyVariant }
> = {
  Large: {
    body: "Regular/Extra Small",
    title: "Medium/Extra Small",
  },
  Small: {
    body: "Regular/Extra Small",
    title: "Medium/Extra Small",
  },
};

export const NoticeMessage = ({
  children,
  className,
  emoji,
  hasColor = true,
  isHidden = false,
  onHide,
  size,
  title,
  variant = "Info",
}: {
  children: ReactNode;
  className?: string;
  emoji?: React.ReactElement<React.ComponentProps<"svg">>;
  hasColor?: boolean;
  isHidden?: boolean;
  onHide?: () => void;
  size: Size;
  title?: string;
  variant?: Variant;
}) => {
  if (isHidden) {
    return null;
  }
  return (
    <Typography
      as="div"
      className={classNames(
        "flex items-center rounded-remote-md bg-grey-100",
        title ? "text-black-05" : "text-black-07",
        STYLE_SIZE[size],
        hasColor ? HAS_COLOR_STYLE[variant] : null,
        className,
      )}
      data-cy="notice-message"
      variant={TYPOGRAPHY_VARIANTS[size].body}
    >
      <div
        className={classNames(
          "w-6 flex-shrink-0 rounded-remote-md p-1 text-center",
          ICON_STYLE[variant],
        )}
      >
        {emoji || EMOJI[variant]}
      </div>

      <div className="flex flex-grow flex-col gap-2">
        {title && (
          <Typography
            className="text-black-07"
            variant={TYPOGRAPHY_VARIANTS[size].title}
          >
            {title}
          </Typography>
        )}
        <div>{children}</div>
      </div>
      {onHide ? (
        <button className="h-5 w-5 flex-shrink-0" onClick={onHide}>
          <XMarkIcon />
        </button>
      ) : null}
    </Typography>
  );
};

export const ClosableNoticeMessage: React.FC<
  React.ComponentProps<typeof NoticeMessage> & {
    localStorageClosedKey: string;
  }
> = ({ localStorageClosedKey, ...props }) => {
  const { isHidden, onHide } = useClosableNoticeMessageState({
    localStorageClosedKey,
  });
  return <NoticeMessage {...props} isHidden={isHidden} onHide={onHide} />;
};

export const useClosableNoticeMessageState = ({
  localStorageClosedKey,
}: {
  localStorageClosedKey: string;
}) => {
  const [isHidden, setIsHidden] = useLocalStorage(
    `notice-message-${localStorageClosedKey}`,
    false,
  );
  return {
    isHidden,
    onHide: () => {
      setIsHidden(true);
    },
  };
};
