/**
 * @generated SignedSource<<99474fc29b2123e0b6d7529ee569de6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepricedFromCtmsGrantTag_CtmsGrant$data = {
  readonly id: string;
  readonly label: string;
  readonly organizationId: string;
  readonly " $fragmentType": "RepricedFromCtmsGrantTag_CtmsGrant";
};
export type RepricedFromCtmsGrantTag_CtmsGrant$key = {
  readonly " $data"?: RepricedFromCtmsGrantTag_CtmsGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepricedFromCtmsGrantTag_CtmsGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RepricedFromCtmsGrantTag_CtmsGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organizationId",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "f4724849d0bc2479583ffb1e25361ef8";

export default node;
