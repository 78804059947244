/**
 * @generated SignedSource<<c375185670e2390df83286e11bbf7dd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly searchedUnderReviewAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
  }>;
  readonly underReviewAmendmentRequests: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationAmendmentRequestsTable_Organization" | "LegalWorkflowLayout_Organization">;
  readonly " $fragmentType": "UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization";
};
export type UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$key = {
  readonly " $data"?: UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization">;
};

import UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql from './UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "kind": "Literal",
  "name": "statusIn",
  "value": [
    "UNDER_REVIEW"
  ]
},
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "grantAmendmentRequestsSearch"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "UnderReview_GranteeTerminationCTMSGrantAmendmentRequests_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "underReviewAmendmentRequests",
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": "granteeTerminationCTMSGrantAmendmentRequests(statusIn:[\"UNDER_REVIEW\"])"
    },
    {
      "alias": "searchedUnderReviewAmendmentRequests",
      "args": [
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "grantAmendmentRequestsSearch"
        },
        (v1/*: any*/)
      ],
      "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
      "kind": "LinkedField",
      "name": "granteeTerminationCTMSGrantAmendmentRequests",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LegalWorkflowLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeTerminationAmendmentRequestsTable_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "3434912ad3b25246f3894a072ba4b5d0";

export default node;
