/**
 * @generated SignedSource<<2ca05b4d75e03f08527c826ec1f27d47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation$variables = {
  granteeTerminationCTMSGrantAmendmentRequestIds: ReadonlyArray<string>;
  organizationId: string;
};
export type useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation$data = {
  readonly generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL: string;
};
export type useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation = {
  response: useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation$data;
  variables: useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "granteeTerminationCTMSGrantAmendmentRequestIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "granteeTerminationCTMSGrantAmendmentRequestIds",
        "variableName": "granteeTerminationCTMSGrantAmendmentRequestIds"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "501676d16fd3c0fd0ce616c2cdc55b2e",
    "id": null,
    "metadata": {},
    "name": "useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDownloadBoardConsentPreview_GenerateGranteeTerminationGrantAmendmentBoardConsentPreviewURL_Mutation(\n  $granteeTerminationCTMSGrantAmendmentRequestIds: [UUID!]!\n  $organizationId: OrganizationId!\n) {\n  generateGranteeTerminationGrantAmendmentBoardConsentPreviewURL(granteeTerminationCTMSGrantAmendmentRequestIds: $granteeTerminationCTMSGrantAmendmentRequestIds, organizationId: $organizationId)\n}\n"
  }
};
})();

(node as any).hash = "3569be858595b709c518e86e53466992";

export default node;
