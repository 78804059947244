/**
 * @generated SignedSource<<f1a7af28cd2655dacdc1815d40e1805d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantAmendmentRequestStatus = "IMPLEMENTATION" | "IMPLEMENTED" | "PENDING_APPROVAL" | "READY_FOR_CONSENT" | "UNDER_REVIEW";
import { FragmentRefs } from "relay-runtime";
export type Context_CTMSGrantAmendmentRequest$data = {
  readonly accelerationClause: AccelerationClause | null;
  readonly accelerationClauseIsModified: boolean;
  readonly earlyExercise: boolean | null;
  readonly earlyExerciseIsModified: boolean;
  readonly postTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly postTerminationExercisePeriodIsModified: boolean;
  readonly status: CTMSGrantAmendmentRequestStatus;
  readonly totalAccelerationDate: string | null;
  readonly vestingSchedule: {
    readonly id: string;
  } | null;
  readonly vestingStartDate: string | null;
  readonly waiveCliff: boolean | null;
  readonly " $fragmentType": "Context_CTMSGrantAmendmentRequest";
};
export type Context_CTMSGrantAmendmentRequest$key = {
  readonly " $data"?: Context_CTMSGrantAmendmentRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"Context_CTMSGrantAmendmentRequest">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Context_CTMSGrantAmendmentRequest",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postTerminationExercisePeriodIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClauseIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExerciseIsModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalAccelerationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waiveCliff",
      "storageKey": null
    }
  ],
  "type": "CTMSGrantAmendmentRequest",
  "abstractKey": null
};
})();

(node as any).hash = "192f7ea7c810d302ff2ea3db68229d79";

export default node;
