import classNames from "classnames";
import React from "react";

type ProgressVariant = "full-red" | "light-red" | "primary";

const style: Record<ProgressVariant, string> = {
  ["full-red"]: /* tailwind */ "bg-red",
  ["light-red"]: /* tailwind */ "bg-red-02",
  primary: /* tailwind */ "bg-primary",
};

export const SingleValueProgress: React.FC<{
  className?: string;
  current: number;
  max: number;
  variant?: ProgressVariant;
}> = ({ className, current, max, variant = "primary", ...props }) => {
  return (
    <div
      className={classNames(
        "flex h-2 overflow-hidden rounded-3xl bg-gray-02",
        className,
      )}
      {...props}
    >
      <div
        className={classNames("h-full rounded-3xl", style[variant])}
        style={{ width: `${(current / max) * 100}%` }}
      />
    </div>
  );
};
