import classNames from "classnames";
import { chain, isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { BarGraph } from "../../../../components/BarGraph";
import {
  GranteesListSlideOver,
  useGranteesListSlideOverState,
} from "../../../../components/GranteesListSlideOver";
import { GraphTooltip } from "../../../../components/GraphTooltip";
import { InsightNotAvailableBox } from "../../../../components/InsightNotAvailableBox";
import { Typography } from "../../../../components/ui/Typography";
import {
  arePtepEquals,
  ptepDurationToDays,
} from "../../../../helpers/ptep-utils";
import { filterByPropertiesNotNull } from "../../../../helpers/ts-utlity";
import {
  PostTerminationExercisePeriodGraph_Organization$data,
  PostTerminationExercisePeriodGraph_Organization$key,
} from "./__generated__/PostTerminationExercisePeriodGraph_Organization.graphql";
import { PostTerminationExercisePeriodGraph_Viewer$key } from "./__generated__/PostTerminationExercisePeriodGraph_Viewer.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriodGraph_Organization on Organization {
    grantees {
      edges {
        node {
          id
          status
          ctmsGrants(grantStatusIn: [Active]) {
            activePostTerminationExercisePeriod {
              value {
                duration
                unit
              }
            }
          }
        }
      }
    }
    ...GranteesListSlideOver_Organization
    ...InsightNotAvailableBox_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment PostTerminationExercisePeriodGraph_Viewer on Account {
    ...InsightNotAvailableBox_Viewer
  }
`;

const Tooltip: React.FC<{
  barClassName?: string;
  granteesCount: number;
  title: string;
}> = ({ barClassName, granteesCount, title }) => {
  return (
    <GraphTooltip
      bottomContent="Click on the bar to see the list of grantees"
      topContent={title}
    >
      <div className="flex gap-2">
        <div className="h-5 py-1.5">
          <div className={classNames("h-2 w-2 rounded-full", barClassName)} />
        </div>
        <div className="flex-grow space-y-1">
          <Typography as="div" variant="Medium/Extra Small">
            <FormattedMessage
              defaultMessage={`{granteesCount, plural,
                =0 {# grantee}
                =1 {# grantee}
                other {# grantees}
              }`}
              values={{ granteesCount }}
            />
          </Typography>
        </div>
      </div>
    </GraphTooltip>
  );
};

type Grantee =
  PostTerminationExercisePeriodGraph_Organization$data["grantees"]["edges"][number]["node"];
type PTEP = NonNullable<
  Grantee["ctmsGrants"][number]["activePostTerminationExercisePeriod"]
>["value"];

export const PostTerminationExercisePeriodGraph: React.FC<{
  organizationFragment: PostTerminationExercisePeriodGraph_Organization$key;
  viewerFragment: PostTerminationExercisePeriodGraph_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);

  const granteesGroupedByPTEP = useMemo(() => {
    const activeGrantees = organization.grantees.edges
      .map((edge) => edge.node)
      .filter((grantee) => {
        switch (grantee.status) {
          case "Active":
            return true;
          case "Terminated":
            return false;
        }
      });

    return activeGrantees.reduce<{
      granteesRecordWithSinglePTEP: Record<
        string,
        { grantees: Grantee[]; ptep: PTEP }
      >;
      granteesWithMixedPTEP: Grantee[];
    }>(
      (acc, grantee) => {
        const firstPTEP =
          grantee.ctmsGrants.find(
            (grant) => grant.activePostTerminationExercisePeriod,
          )?.activePostTerminationExercisePeriod?.value ?? null;

        if (firstPTEP) {
          const allGrantsAreUsingSamePTEP = filterByPropertiesNotNull(
            grantee.ctmsGrants,
            "activePostTerminationExercisePeriod",
          ).every((grant) =>
            arePtepEquals(
              grant.activePostTerminationExercisePeriod.value,
              firstPTEP,
            ),
          );
          if (!allGrantsAreUsingSamePTEP) {
            acc.granteesWithMixedPTEP.push(grantee);
          } else {
            const key = `${firstPTEP.duration}:${firstPTEP.unit}`;
            acc.granteesRecordWithSinglePTEP[key] = {
              grantees: [
                ...(acc.granteesRecordWithSinglePTEP[key]?.grantees ?? []),
                grantee,
              ],
              ptep: acc.granteesRecordWithSinglePTEP[key]?.ptep ?? firstPTEP,
            };
          }
        }

        return acc;
      },
      {
        granteesRecordWithSinglePTEP: {},
        granteesWithMixedPTEP: [],
      },
    );
  }, [organization.grantees]);

  const intl = useIntl();

  const stacks = useMemo(() => {
    const stacks = chain(granteesGroupedByPTEP.granteesRecordWithSinglePTEP)
      .entries()
      .sortBy(([, { ptep }]) => ptepDurationToDays(ptep))
      .map(([key, { grantees, ptep }]) => ({
        className: classNames("bg-green-05 fill-green-05"),
        grantees,
        key,
        label: intl.formatNumber(ptep.duration, {
          style: "unit",
          unit: ptep.unit,
          unitDisplay: "long",
        }),
        value: grantees.length,
      }))
      .value();

    if (!isEmpty(granteesGroupedByPTEP.granteesWithMixedPTEP)) {
      stacks.push({
        className: classNames("bg-primary-05 fill-primary-05"),
        grantees: granteesGroupedByPTEP.granteesWithMixedPTEP,
        key: "mixed",
        label: "Mixed",
        value: granteesGroupedByPTEP.granteesWithMixedPTEP.length,
      });
    }

    return stacks.map((stack) => ({
      ...stack,
      elements: [
        {
          className: stack.className,
          key: stack.key,
          value: stack.value,
        },
      ],
    }));
  }, [granteesGroupedByPTEP, intl]);

  const {
    close: closeSlideOver,
    open: openSlideOver,
    state: slideOverState,
  } = useGranteesListSlideOverState();

  const onStackClick = useCallback(
    (stack: (typeof stacks)[number]) =>
      openSlideOver({
        granteesIds: stack.grantees.map((grantee) => grantee.id),
        title: (
          <FormattedMessage
            defaultMessage={`There {granteesCount, plural, 
            =0 {is # grantee} 
            =1 {is # grantee} 
            other {are # grantees}} with {ptep} PTEP`}
            values={{
              granteesCount: stack.grantees.length,
              ptep: stack.key === "mixed" ? "mixed" : `a ${stack.label}`,
            }}
          />
        ),
      }),
    [openSlideOver],
  );

  const renderTooltip = useCallback(
    (stack: (typeof stacks)[number]) => (
      <Tooltip
        barClassName={stack.className}
        granteesCount={stack.grantees.length}
        title={`${stack.label} PTEP`}
      />
    ),
    [],
  );

  if (isEmpty(stacks)) {
    return (
      <InsightNotAvailableBox
        organizationFragment={organization}
        viewerFragment={viewer}
      />
    );
  }

  return (
    <>
      <GranteesListSlideOver
        onClose={closeSlideOver}
        organizationFragment={organization}
        state={slideOverState}
      />
      <BarGraph
        barGap={60}
        barSize={40}
        onBackgroundClick={({ stack }) => onStackClick(stack)}
        onStackElementClick={({ stack }) => onStackClick(stack)}
        renderBackgroundTooltip={({ stack }) => renderTooltip(stack)}
        renderTooltip={({ stack }) => renderTooltip(stack)}
        showBackground
        stacks={stacks}
        yLabel="# of grantees"
        yTickFormatter={(v) => v.toString()}
      />
    </>
  );
};
