/**
 * @generated SignedSource<<cbe385aba8c82903b05d0cb249d4acb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharesInDraftMessage_Organization$data = {
  readonly easopGrants: ReadonlyArray<{
    readonly boardConsent: {
      readonly __typename: string;
    } | null;
    readonly quantityGranted: number;
  }>;
  readonly " $fragmentType": "SharesInDraftMessage_Organization";
};
export type SharesInDraftMessage_Organization$key = {
  readonly " $data"?: SharesInDraftMessage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharesInDraftMessage_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharesInDraftMessage_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "boardConsent",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantityGranted",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0dea110f46fc95341fb314ca1e802228";

export default node;
