import { RoundedBox } from "./RoundedBox";
import { Typography } from "./Typography";

const _LabeledValueRoundedList: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return <RoundedBox withBorder>{children}</RoundedBox>;
};

const LabeledValue: React.FC<{
  children: React.ReactNode;
  label: React.ReactNode;
}> = ({ children, label }) => {
  return (
    <div className="flex justify-between px-4 py-3">
      <Typography variant="Regular/Extra Small">{label}</Typography>
      <Typography variant="Medium/Extra Small">{children}</Typography>
    </div>
  );
};

export const LabeledValueRoundedList = Object.assign(_LabeledValueRoundedList, {
  LabeledValue,
});
