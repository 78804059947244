/**
 * @generated SignedSource<<cc7beeb831f08f42b8fe851885dfbd6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type EditEasopGrantResultFailureError = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "INSTRUMENT_NOT_AVAILABLE_FOR_GRANTEE" | "LABEL_ALREADY_EXISTS" | "PTEP_NOT_PROVIDED" | "PTEP_PROVIDED_FOR_FIXED_PTEP_INSTRUMENT" | "WORK_RELATIONSHIP_NOT_COVERED";
export type EasopGrantAttributes = {
  accelerationClause?: AccelerationClause | null;
  earlyExercise: boolean;
  exercisePrice: number;
  exercisePriceBelowFMVSetOn?: string | null;
  granteeId: string;
  id: string;
  instrumentId: string;
  label: string;
  organizationId: string;
  postTerminationExercisePeriodId: any;
  quantityGranted: number;
  vestingScheduleId: any;
  vestingStartDate: string;
};
export type GrantFormSlide_UpdateGrant_Mutation$variables = {
  attributes: EasopGrantAttributes;
};
export type GrantFormSlide_UpdateGrant_Mutation$data = {
  readonly updateEasopGrant: {
    readonly __typename: "EditEasopGrantResultFailure";
    readonly error: EditEasopGrantResultFailureError;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  };
};
export type GrantFormSlide_UpdateGrant_Mutation = {
  response: GrantFormSlide_UpdateGrant_Mutation$data;
  variables: GrantFormSlide_UpdateGrant_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "attributes"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "updateEasopGrant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "error",
            "storageKey": null
          }
        ],
        "type": "EditEasopGrantResultFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantFormSlide_UpdateGrant_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GrantFormSlide_UpdateGrant_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "71236c13181abd51ca1a14f83b3a29e2",
    "id": null,
    "metadata": {},
    "name": "GrantFormSlide_UpdateGrant_Mutation",
    "operationKind": "mutation",
    "text": "mutation GrantFormSlide_UpdateGrant_Mutation(\n  $attributes: EasopGrantAttributes!\n) {\n  updateEasopGrant(attributes: $attributes) {\n    __typename\n    ... on EditEasopGrantResultFailure {\n      error\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5fbe0b732e9a70f24518a9ef07eceb5b";

export default node;
