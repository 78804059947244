/**
 * @generated SignedSource<<29ceed0b1e839a53f6aa8955579b3e4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeDetailsGrantsList_Organization$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SharesValue_Organization">;
  readonly " $fragmentType": "GranteeDetailsGrantsList_Organization";
};
export type GranteeDetailsGrantsList_Organization$key = {
  readonly " $data"?: GranteeDetailsGrantsList_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeDetailsGrantsList_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeDetailsGrantsList_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesValue_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "a934e6d4cbbfb720c7c8240e548be89d";

export default node;
