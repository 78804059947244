import { Control, useWatch } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { DurationInput } from "../../../../../components/ui/DurationInput";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { Input } from "../../../../../components/ui/Form/Inputs/Input";
import { RadioGroup } from "../../../../../components/ui/Form/RadioGroup";
import { ClosableNoticeMessage } from "../../../../../components/ui/NoticeMessage";
import { PostTerminationForm_Organization$key } from "./__generated__/PostTerminationForm_Organization.graphql";
import { FormValues } from "./FORM_SCHEMA";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PostTerminationForm_Organization on Organization {
    hasCooleyAsOutsideCounsel
  }
`;

export function PostTerminationForm({
  control,
  doNotAllowVariablePTEP,
  id,
  onSubmit,
  organizationFragment,
}: {
  control: Control<FormValues>;
  doNotAllowVariablePTEP?: boolean;
  id?: string;
  onSubmit: React.ComponentProps<"form">["onSubmit"];
  organizationFragment: PostTerminationForm_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const type = useWatch({
    control,
    name: "type",
  });

  return (
    <form className="block space-y-10" id={id} onSubmit={onSubmit}>
      {!doNotAllowVariablePTEP && !organization.hasCooleyAsOutsideCounsel && (
        <FormRowContainer title="Post-termination type">
          <RadioGroup.Form control={control} name="type">
            <div className="grid grid-cols-2 gap-4">
              <RadioGroup.Card
                description="Fixed post-termination exercise period is the standard period where the grantee can exercise their shares until a fixed period after their grants have been terminated"
                tag="Most common"
                value="FIXED"
              >
                Fixed
              </RadioGroup.Card>
              <RadioGroup.Card
                description="Variable post-termination exercise period is a period that extends after the grantee has worked a certain amount of time in the company"
                value="VARIABLE"
              >
                Variable
              </RadioGroup.Card>
            </div>
          </RadioGroup.Form>
        </FormRowContainer>
      )}
      {type === "FIXED" && (
        <ClosableNoticeMessage
          localStorageClosedKey="fixed-ptep-settings-information"
          size="Large"
          title="What should I do regarding post-termination exercise periods?"
        >
          The most common post-termination exercise period is 3 months, but it
          is up to you to decide if you want to extend that period. The
          post-termination exercise period is basically the time period your
          team member will have to exercise their options if and when they leave
          the company (for reasons other than cause, disability and death).
        </ClosableNoticeMessage>
      )}
      {type === "VARIABLE" && (
        <ClosableNoticeMessage
          localStorageClosedKey="variable-ptep-settings-information"
          size="Large"
          title="Why would I need a variable Post-termination Exercise Period?"
        >
          This would allow you to reward your team members who would be staying
          longer with the company!
        </ClosableNoticeMessage>
      )}
      <div className="space-y-6">
        <FormRowContainer title="Period">
          <FormRow.Form
            control={control}
            label="The period is equal to"
            name="duration"
          >
            <DurationInput.Form
              control={control}
              durationName="duration"
              unitName="durationUnit"
            />
          </FormRow.Form>
        </FormRowContainer>
        {type === "VARIABLE" && (
          <>
            <FormRow.Form
              control={control}
              label="Activate extended period after... # years"
              name="thresholdForExtensionInYears"
              subLabel="Equals to the number of years after which the variable period applies"
            >
              <Input.Form
                before="Years"
                control={control}
                name="thresholdForExtensionInYears"
              />
            </FormRow.Form>
            <FormRow.Form
              control={control}
              label="The period after activation is equal to"
              name="extensionDuration"
            >
              <DurationInput.Form
                control={control}
                durationName="extensionDuration"
                unitName="extensionDurationUnit"
              />
            </FormRow.Form>
          </>
        )}
      </div>
    </form>
  );
}
