/**
 * @generated SignedSource<<d31072332a343083c80b1f1a3f889f96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type CTMSGrantAmendmentRequestAttributes = {
  accelerationClause?: AccelerationClause | null;
  accelerationClauseIsModified: boolean;
  earlyExercise?: boolean | null;
  earlyExerciseIsModified: boolean;
  postTerminationExercisePeriodId?: any | null;
  postTerminationExercisePeriodIsModified: boolean;
  totalAccelerationDate?: string | null;
  vestingScheduleId?: any | null;
  vestingStartDate?: string | null;
  waiveCliff?: boolean | null;
};
export type NewPTEPSlider_Unfavorable_Query$variables = {
  attributes: CTMSGrantAmendmentRequestAttributes;
  ctmsGrantId: string;
};
export type NewPTEPSlider_Unfavorable_Query$data = {
  readonly areCTMSGrantAmendmentRequestParametersUnfavorable: boolean;
};
export type NewPTEPSlider_Unfavorable_Query = {
  response: NewPTEPSlider_Unfavorable_Query$data;
  variables: NewPTEPSlider_Unfavorable_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ctmsGrantId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "ctmsGrantId",
        "variableName": "ctmsGrantId"
      }
    ],
    "kind": "ScalarField",
    "name": "areCTMSGrantAmendmentRequestParametersUnfavorable",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPTEPSlider_Unfavorable_Query",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPTEPSlider_Unfavorable_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "80b171a25d62c012685e24ed633c8671",
    "id": null,
    "metadata": {},
    "name": "NewPTEPSlider_Unfavorable_Query",
    "operationKind": "query",
    "text": "query NewPTEPSlider_Unfavorable_Query(\n  $ctmsGrantId: CtmsGrantId!\n  $attributes: CTMSGrantAmendmentRequestAttributes!\n) {\n  areCTMSGrantAmendmentRequestParametersUnfavorable(ctmsGrantId: $ctmsGrantId, attributes: $attributes)\n}\n"
  }
};
})();

(node as any).hash = "d96e7b3fed6861b5bb1235232886d2e3";

export default node;
