import { zodResolver } from "@hookform/resolvers/zod";
import { DefaultValues, useForm } from "react-hook-form";

import { FormValues, safeCalculatorVersionSchema } from "./FORM_SCHEMA";

export function useSafeCalculatorVersionForm({
  defaultValues = {
    name: undefined,
  },
}: {
  defaultValues?: DefaultValues<FormValues>;
} = {}) {
  return useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(safeCalculatorVersionSchema),
  });
}
