/**
 * @generated SignedSource<<80675e95b7188f072a471f51beb48995>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EsopPoolProjectionGraph_Organization$data = {
  readonly esopPoolShares: number | null;
  readonly poolAvailableShares: number;
  readonly poolDraftedSharesBreakdown: {
    readonly total: number;
  };
  readonly poolGrantedSharesBreakdown: {
    readonly total: number;
  };
  readonly poolPendingSharesBreakdown: {
    readonly total: number;
  };
  readonly stockOptionPlanName: string | null;
  readonly " $fragmentType": "EsopPoolProjectionGraph_Organization";
};
export type EsopPoolProjectionGraph_Organization$key = {
  readonly " $data"?: EsopPoolProjectionGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EsopPoolProjectionGraph_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "total",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EsopPoolProjectionGraph_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stockOptionPlanName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "poolAvailableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolGrantedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolPendingSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolDraftedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "476a8f910ca2419af05c8defb113b40e";

export default node;
