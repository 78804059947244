/**
 * @generated SignedSource<<1659fc3abf80c9a4002272cb02b1a4ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactUsModal_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "ContactUsModal_Organization";
};
export type ContactUsModal_Organization$key = {
  readonly " $data"?: ContactUsModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactUsModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactUsModal_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "b7df1faa669fff9f09dc3766721b5f73";

export default node;
