/**
 * @generated SignedSource<<37c49b963eb6eb420b4f582c122d5943>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsGrant_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "DraftGrantDetailsGrant_Viewer";
};
export type DraftGrantDetailsGrant_Viewer$key = {
  readonly " $data"?: DraftGrantDetailsGrant_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsGrant_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsGrant_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "f6231f6bbdf12e8407b5e23f18066ccd";

export default node;
