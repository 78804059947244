/**
 * @generated SignedSource<<176f8d3b9c417dfe7440fefb566215c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
import { FragmentRefs } from "relay-runtime";
export type ModifyCTMSGrantPageContent_CTMSGrant$data = {
  readonly cumulativeVested: number;
  readonly earlyExercise: boolean;
  readonly easopGrant: {
    readonly accelerationClause: AccelerationClause | null;
  } | null;
  readonly id: string;
  readonly label: string;
  readonly postTerminationExercisePeriod: {
    readonly displayName: string;
  } | null;
  readonly vestingDataPoints: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
  readonly vestingSchedule: {
    readonly cliffDurationInMonths: number;
  } | null;
  readonly vestingScheduleName: string | null;
  readonly vestingStartDate: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"NewAccelerationAllowedSlider_CTMSGrant" | "NewEarlyExerciseAllowedSlider_CTMSGrant" | "NewPTEPSlider_CTMSGrant" | "NewVestingScheduleSlider_CTMSGrant" | "NewVestingStartDateSlider_CTMSGrant" | "VestingAccelerationSlider_CTMSGrant">;
  readonly " $fragmentType": "ModifyCTMSGrantPageContent_CTMSGrant";
};
export type ModifyCTMSGrantPageContent_CTMSGrant$key = {
  readonly " $data"?: ModifyCTMSGrantPageContent_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModifyCTMSGrantPageContent_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cumulativeVested",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModifyCTMSGrantPageContent_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExercise",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingScheduleName",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accelerationClause",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "postTerminationExercisePeriod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VestingDataPoint",
      "kind": "LinkedField",
      "name": "vestingDataPoints",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cliffDurationInMonths",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VestingAccelerationSlider_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewPTEPSlider_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewAccelerationAllowedSlider_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewEarlyExerciseAllowedSlider_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewVestingStartDateSlider_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewVestingScheduleSlider_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "e8e8db6951d2b0618988d2b89648c87f";

export default node;
