/**
 * @generated SignedSource<<9ffac0dc1c0b8a836fc63ca241c16039>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestNotifySection_FairMarketValueAtExercise$data = {
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_FairMarketValueAtExercise">;
  readonly " $fragmentType": "ExerciseRequestNotifySection_FairMarketValueAtExercise";
};
export type ExerciseRequestNotifySection_FairMarketValueAtExercise$key = {
  readonly " $data"?: ExerciseRequestNotifySection_FairMarketValueAtExercise$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_FairMarketValueAtExercise">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestNotifySection_FairMarketValueAtExercise",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_FairMarketValueAtExercise"
    }
  ],
  "type": "FairMarketValue",
  "abstractKey": null
};

(node as any).hash = "e0dd3fa2a91870c551f296bcf04ac887";

export default node;
