import { Button } from "@remote-com/norma";
import { IconV2OutlineArrowRight } from "@remote-com/norma/icons/IconV2OutlineArrowRight";
import React from "react";

import { RemoteEquityOnboardingLayout } from "../../../components/ui/Layout/RemoteLikeApplicationLayout/RemoteEquityOnboardingLayout";
import CONFIGURATION from "../../../services/configuration";
import DemoBookedIllustration from "./demo-booked-illustration.png";

const REMOTE_DASHBOARD_EQUITY_URL = CONFIGURATION.REMOTE_DASHBOARD_EQUITY_URL;
if (!REMOTE_DASHBOARD_EQUITY_URL) {
  throw new Error("REMOTE_DASHBOARD_EQUITY_URL is required");
}

export const DemoBookedView: React.FC = () => {
  return (
    <RemoteEquityOnboardingLayout
      className="max-w-[570px] !gap-10"
      subtitle={
        <>
          Our equity team will finalize your account setup before the meeting.
          <br />
          You&apos;ll get access to Remote Equity right after the meeting. Speak
          soon!
        </>
      }
      title={
        <>
          Meeting booked <span className="font-emoji">🚀</span>
        </>
      }
    >
      <>
        <img
          alt="Demo booked illustration"
          className="w-[270px]"
          src={DemoBookedIllustration}
        />
        <Button
          as="a"
          href={REMOTE_DASHBOARD_EQUITY_URL}
          IconAfter={IconV2OutlineArrowRight}
          size="lg"
          tone="primary"
          variant="solid"
        >
          Back to Remote
        </Button>
      </>
    </RemoteEquityOnboardingLayout>
  );
};
