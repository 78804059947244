import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { isBefore, max } from "date-fns";
import { isEmpty } from "lodash";
import { useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../../components/ui/Form/Inputs/DatePicker";
import { Input } from "../../../../components/ui/Form/Inputs/Input";
import { SelectAutocomplete } from "../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { NoticeMessage } from "../../../../components/ui/NoticeMessage";
import { TooltipContainer } from "../../../../components/ui/TooltipContainer";
import { Typography } from "../../../../components/ui/Typography";
import { zodExtra } from "../../../../helpers/zod-extra";
import { GranteeTerminationInformation_Grantee$key } from "./__generated__/GranteeTerminationInformation_Grantee.graphql";
import { GranteeTerminationInformation_Organization$key } from "./__generated__/GranteeTerminationInformation_Organization.graphql";
import { useGranteeTerminationContext } from "./GranteeTerminationContext";
import { GranteeTerminationLayout } from "./GranteeTerminationLayout";

const ORGANIZATION_FRAGMENT = graphql`
  fragment GranteeTerminationInformation_Organization on Organization {
    id
    name
  }
`;
const GRANTEE_FRAGMENT = graphql`
  fragment GranteeTerminationInformation_Grantee on Grantee {
    id
    name
    email
    terminationInformations {
      terminationDate
      terminationLastDayAtTheCompany
      terminationNewRelationship
      terminationType
      terminationPersonalEmail
    }
    allCtmsGrants: ctmsGrants {
      grantDate
    }
  }
`;

const TERMINATION_TYPE_ENUM = [
  "VOLUNTARY",
  "INVOLUNTARY",
  "WITH_CAUSE",
  "DISABILITY",
  "RETIREMENT",
  "DEATH",
] as const;

export const TERMINATION_TYPE_LABEL: Record<
  (typeof TERMINATION_TYPE_ENUM)[number],
  string
> = {
  DEATH: "Death",
  DISABILITY: "Disability",
  INVOLUNTARY: "Involuntary",
  RETIREMENT: "Retirement",
  VOLUNTARY: "Voluntary",
  WITH_CAUSE: "With cause",
};

const RELATIONSHIPS = [
  "Advisor",
  "Ex-Advisor",
  "Board Member",
  "Consultant",
  "Ex-Consultant",
  "Employee",
  "Ex-Employee",
  "Executive",
  "Founder",
  "International Employee",
  "Investor",
  "Officer",
  "Other",
];

const useGranteeTerminationInformationFormSchema = ({
  granteeGreaterGrantDate,
}: {
  granteeGreaterGrantDate: Date | undefined;
}) => {
  const intl = useIntl();
  return z.object({
    terminationDate: zodExtra.ISODate().refine(
      (terminationDate) => {
        if (!granteeGreaterGrantDate) return true;

        return !isBefore(new Date(terminationDate), granteeGreaterGrantDate);
      },
      `Termination date must be after greater grant date (${intl.formatDate(
        granteeGreaterGrantDate,
        {
          day: "2-digit",
          month: "short",
          year: "numeric",
        },
      )})`,
    ),
    terminationLastDayAtTheCompany: zodExtra.ISODate(),
    terminationNewRelationship: zodExtra.nonEmptyNullableString(),
    terminationPersonalEmail: z.string().trim().email(),
    terminationType: z.enum(TERMINATION_TYPE_ENUM),
  });
};

export type GranteeTerminationInformation =
  z.output<GranteeTerminationInformationFormSchema>;

export type GranteeTerminationInformationFormInputs =
  z.infer<GranteeTerminationInformationFormSchema>;

type GranteeTerminationInformationFormSchema = ReturnType<
  typeof useGranteeTerminationInformationFormSchema
>;

export const GranteeTerminationInformation: React.FC<{
  granteeFragment: GranteeTerminationInformation_Grantee$key;
  onNextClick: (data: GranteeTerminationInformation) => void;
  organizationFragment: GranteeTerminationInformation_Organization$key;
}> = ({ granteeFragment, onNextClick: _onNextClick, organizationFragment }) => {
  const granteeTerminationContext = useGranteeTerminationContext();
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const granteeGreaterGrantDate = useMemo(
    () =>
      !isEmpty(grantee.allCtmsGrants)
        ? max(grantee.allCtmsGrants.map(({ grantDate }) => new Date(grantDate)))
        : undefined,
    [grantee.allCtmsGrants],
  );

  const schema = useGranteeTerminationInformationFormSchema({
    granteeGreaterGrantDate,
  });

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      terminationDate:
        granteeTerminationContext.state.terminationDate ||
        grantee.terminationInformations?.terminationDate ||
        undefined,
      terminationLastDayAtTheCompany:
        granteeTerminationContext.state.terminationLastDayAtTheCompany ||
        grantee.terminationInformations?.terminationLastDayAtTheCompany ||
        undefined,
      terminationNewRelationship:
        granteeTerminationContext.state.terminationNewRelationship ||
        grantee.terminationInformations?.terminationNewRelationship,
      terminationPersonalEmail:
        granteeTerminationContext.state.terminationPersonalEmail ||
        grantee.terminationInformations?.terminationPersonalEmail ||
        grantee.email,
      terminationType:
        granteeTerminationContext.state.terminationType ||
        grantee.terminationInformations?.terminationType ||
        undefined,
    },
    resolver: zodResolver(schema),
  });

  const onTerminationDateChanged = useCallback(
    (value: string) => {
      const { terminationLastDayAtTheCompany } = getValues();
      if (value && !terminationLastDayAtTheCompany) {
        setValue("terminationLastDayAtTheCompany", value);
      }
    },
    [getValues, setValue],
  );

  const intl = useIntl();

  const terminateTypeOptions = TERMINATION_TYPE_ENUM.map((value) => ({
    label: TERMINATION_TYPE_LABEL[value],
    value,
  }));

  const relationshipsOptions = RELATIONSHIPS.map((value) => ({
    label: value,
    value,
  }));

  const onNextClick = handleSubmit((_data) => {
    const data = _data as GranteeTerminationInformation;
    return _onNextClick(data);
  });

  const terminationType = watch("terminationType");

  return (
    <GranteeTerminationLayout
      grantee={{ id: grantee.id, name: grantee.name }}
      onNextClick={onNextClick}
      organization={{ id: organization.id, name: organization.name }}
    >
      <div className="space-y-6 px-10 py-6">
        <div className="space-y-3">
          <Typography variant="Medium/Small">🌴 Termination dates</Typography>
          <p>
            <Typography className="text-black-05" variant="Regular/Extra Small">
              {grantee.name} will be{" "}
              <Typography
                className="text-black-07"
                variant="Medium/Extra Small"
              >
                terminated on
              </Typography>
              <span className="mx-2.5 my-1 inline-flex w-[172px] items-center">
                <Controller
                  control={control}
                  name="terminationDate"
                  render={({ field, fieldState }) => (
                    <FormRow
                      className="w-full"
                      error={fieldState.error?.message}
                      id="termination-date"
                    >
                      <DatePicker
                        className="w-full"
                        minDate={granteeGreaterGrantDate}
                        onChange={(value) => {
                          field.onChange(value);
                          onTerminationDateChanged(value);
                        }}
                        panelPosition="bottom"
                        placeholder={`E.g. ${intl.formatDate(new Date(), {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}`}
                        value={field.value}
                      />
                    </FormRow>
                  )}
                />
              </span>
              . In case the grantee needs to complete a notice period for the
              contract to be ended, their{" "}
              <Typography
                className="text-black-07"
                variant="Medium/Extra Small"
              >
                last day at the company
              </Typography>{" "}
              is
              <span className="mx-2.5 my-1 inline-flex w-[172px] items-center">
                <Controller
                  control={control}
                  name="terminationLastDayAtTheCompany"
                  render={({ field, fieldState }) => (
                    <FormRow
                      className="w-full"
                      error={fieldState.error?.message}
                      id="last-day-at-the-company-date"
                    >
                      <DatePicker
                        className="w-full"
                        onChange={(value) => {
                          field.onChange(value);
                          onTerminationDateChanged(value);
                        }}
                        panelPosition="bottom"
                        placeholder={`E.g. ${intl.formatDate(new Date(), {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        })}`}
                        value={field.value}
                      />
                    </FormRow>
                  )}
                />
              </span>
              .
            </Typography>
          </p>
        </div>
        <Divider />
        <div className="space-y-3">
          <Typography variant="Medium/Small">❎ Termination details</Typography>
          <p>
            <Typography className="text-black-05" variant="Regular/Extra Small">
              The{" "}
              <Typography
                className="text-black-07"
                variant="Medium/Extra Small"
              >
                reason of termination
              </Typography>{" "}
              is
              <span className="mx-2.5 my-1 inline-flex items-center gap-2.5">
                <Controller
                  control={control}
                  name="terminationType"
                  render={({ field, fieldState }) => (
                    <FormRow
                      className="!w-[172px]"
                      error={fieldState.error?.message}
                      id="termination-type"
                    >
                      <SelectAutocomplete
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(newValue) => field.onChange(newValue?.value)}
                        options={terminateTypeOptions}
                        placeholder="E.g. Voluntary"
                        usePortal
                        value={
                          terminateTypeOptions.find(
                            (option) => option.value === field.value,
                          ) ?? null
                        }
                      />
                    </FormRow>
                  )}
                />
                <TooltipContainer
                  position="left"
                  tooltipClassName="w-[267px]"
                  tooltipContent={
                    <Typography
                      className="flex flex-col gap-4"
                      variant="Regular/Extra Small"
                    >
                      <span>
                        This impacts the post termination exercise period which
                        are defined in the Plan.
                      </span>
                      <span>What does it mean?</span>
                      <div className="flex flex-col">
                        <span>Voluntary</span>
                        <Typography
                          className="text-gray-07"
                          variant="Regular/Caption"
                        >
                          When the employee takes the initiative to leave the
                          company.
                        </Typography>
                      </div>
                      <div className="flex flex-col">
                        <span>Involuntary</span>
                        <Typography
                          className="text-gray-07"
                          variant="Regular/Caption"
                        >
                          Employees are involuntarily terminated when the
                          employer decides to fire or lay off the employee.
                        </Typography>
                      </div>
                      <div className="flex flex-col">
                        <span>With cause</span>
                        <Typography
                          className="text-gray-07"
                          variant="Regular/Caption"
                        >
                          Employees who are terminated for cause are fired for
                          misconduct. The misconduct that leads to a termination
                          for cause might include violations of company rules,
                          ethics violations, theft, breaches of contract,
                          harassment, insubordination, violence, and others.
                        </Typography>
                      </div>
                    </Typography>
                  }
                >
                  <InformationCircleIcon className="h-4 w-4 flex-shrink-0 cursor-pointer text-primary" />
                </TooltipContainer>
              </span>
              and the{" "}
              <Typography
                className="text-black-07"
                variant="Medium/Extra Small"
              >
                new relationship
              </Typography>{" "}
              of the grantee is
              <span className="mx-2.5 my-1 inline-flex items-center gap-2.5">
                <Controller
                  control={control}
                  name="terminationNewRelationship"
                  render={({ field, fieldState }) => (
                    <FormRow
                      className="!w-[172px]"
                      error={fieldState.error?.message}
                      id="termination-new-relationship"
                    >
                      <SelectAutocomplete
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        onChange={(newValue) => field.onChange(newValue?.value)}
                        options={relationshipsOptions}
                        placeholder="E.g. Ex-Employee"
                        usePortal
                        value={
                          relationshipsOptions.find(
                            (option) => option.value === field.value,
                          ) ?? null
                        }
                      />
                    </FormRow>
                  )}
                />
                <TooltipContainer
                  tooltipClassName="w-[267px]"
                  tooltipContent={
                    <Typography
                      className="flex flex-col gap-4"
                      variant="Regular/Extra Small"
                    >
                      <span>Why do we need to know and how to choose?</span>
                      <Typography
                        className="text-gray-07"
                        variant="Regular/Caption"
                      >
                        The new relationship is used for expense reporting and
                        setting the user&apos;s shareholder experience in your
                        cap table management solution.
                      </Typography>
                    </Typography>
                  }
                >
                  <InformationCircleIcon className="h-4 w-4 flex-shrink-0 cursor-pointer text-primary" />
                </TooltipContainer>
              </span>
              .
            </Typography>
          </p>
          {terminationType === "WITH_CAUSE" && (
            <NoticeMessage size="Small" variant="Warning">
              Please be aware that a termination &quot;with cause&quot; is a{" "}
              <span className="font-medium">termination for misconduct.</span>{" "}
              The misconduct that leads to a termination for cause might include
              violations of company rules, ethics violations, theft, breaches of
              contract, harassment, insubordination, and violence. If this is
              the case, the grantee will be{" "}
              <span className="font-medium">
                prohibited from exercising their vested stock options.
              </span>
            </NoticeMessage>
          )}
        </div>
        <Divider />
        <div className="space-y-3">
          <Typography variant="Medium/Small">
            💡 After the termination
          </Typography>
          <p>
            <Typography className="text-black-05" variant="Regular/Extra Small">
              In order for the grantee to keep track of their equity, confirm
              the grantee’s{" "}
              <Typography
                className="text-black-07"
                variant="Medium/Extra Small"
              >
                personal email address
              </Typography>
              <FormRow
                className="mx-2.5 my-1 inline-flex !w-[172px]"
                error={errors.terminationPersonalEmail?.message}
                id="email"
              >
                <Input
                  {...control.register("terminationPersonalEmail")}
                  placeholder="E.g. john.doe@personal.com"
                />
              </FormRow>
              .
            </Typography>
          </p>
        </div>
      </div>
    </GranteeTerminationLayout>
  );
};
