import { zodResolver } from "@hookform/resolvers/zod";
import { addMonths } from "date-fns";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { graphql, useFragment } from "react-relay";
import { z } from "zod";

import { Button } from "../../../../components/ui/Button";
import { Divider } from "../../../../components/ui/Divider";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { DatePicker } from "../../../../components/ui/Form/Inputs/DatePicker";
import { RadioGroup } from "../../../../components/ui/Form/RadioGroup";
import { SlideOver } from "../../../../components/ui/SlideOver";
import { Typography } from "../../../../components/ui/Typography";
import { makeRemoteController } from "../../../../helpers/makeRemoteController";
import { VestingAccelerationSlider_CTMSGrant$key } from "./__generated__/VestingAccelerationSlider_CTMSGrant.graphql";

const CTMS_GRANT_FRAGMENT = graphql`
  fragment VestingAccelerationSlider_CTMSGrant on CTMSGrant {
    vestingStartDate
    vestingSchedule {
      durationInMonths
    }
  }
`;

const formSchema = z.object({
  totalAccelerationDate: z.string(),
});

function VestingAccelerationSlider({
  ctmsGrantFragment,
  onClose,
  onExited,
  onTotalAccelerationDateChange,
  show,
  totalAccelerationDate,
}: {
  ctmsGrantFragment: VestingAccelerationSlider_CTMSGrant$key;
  onClose: () => void;
  onExited?: () => void;
  onTotalAccelerationDateChange: (props: {
    totalAccelerationDate: string;
  }) => void;
  show: boolean;
  totalAccelerationDate: null | string;
}) {
  const ctmsGrant = useFragment(CTMS_GRANT_FRAGMENT, ctmsGrantFragment);

  const { control, formState, handleSubmit } = useForm({
    defaultValues: {
      totalAccelerationDate: totalAccelerationDate ?? undefined,
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = handleSubmit((data) => {
    onTotalAccelerationDateChange(formSchema.parse(data));
    onClose();
  });

  const [typeOfAcceleration, setTypeOfAcceleration] =
    useState("totalAcceleration");

  const vestingEndDate = useMemo(() => {
    if (!ctmsGrant.vestingStartDate) return null;
    if (!ctmsGrant.vestingSchedule) return null;
    return addMonths(
      new Date(ctmsGrant.vestingStartDate),
      ctmsGrant.vestingSchedule.durationInMonths,
    );
  }, [ctmsGrant]);

  return (
    <SlideOver
      header={
        <SlideOver.Header onClose={onClose} padding={6}>
          Vesting schedule acceleration
        </SlideOver.Header>
      }
      onClose={onClose}
      onExited={onExited}
      show={show}
      width="600"
    >
      <form onSubmit={onSubmit}>
        <div className="space-y-6 p-6">
          <FormRow label="Type of acceleration">
            <RadioGroup
              onChange={setTypeOfAcceleration}
              value={typeOfAcceleration}
            >
              <RadioGroup.Card
                description={
                  <>
                    The entire grant will become fully vested on the date
                    selected below, i.e. all the stock options from the grant
                    will have vested on the selected date.
                  </>
                }
                value="totalAcceleration"
              >
                Total acceleration
              </RadioGroup.Card>
            </RadioGroup>
          </FormRow>
          <FormRow.Form
            control={control}
            label="Acceleration date"
            name="totalAccelerationDate"
          >
            <DatePicker.Form
              control={control}
              maxDate={vestingEndDate}
              name="totalAccelerationDate"
              placeholder="Select a date prior to the vesting end date"
            />
          </FormRow.Form>
        </div>
        <Divider />
        <div className="space-y-4 px-6 py-10">
          <div className="flex items-center justify-end gap-2">
            <Button
              disabled={formState.isSubmitting}
              onClick={onClose}
              size="small"
              type="button"
              variant="Secondary Full"
            >
              Cancel
            </Button>
            <Button loading={formState.isSubmitting} size="small" type="submit">
              Save
            </Button>
          </div>
          <Typography
            as="div"
            className="text-right text-black-05"
            variant="Regular/Caption"
          >
            Accelerating the vesting will require the approval of the board.
          </Typography>
        </div>
      </form>
    </SlideOver>
  );
}

export const VestingAccelerationSliderRemote = makeRemoteController<{
  ctmsGrantFragment: VestingAccelerationSlider_CTMSGrant$key;
  onTotalAccelerationDateChange: (props: {
    totalAccelerationDate: string;
  }) => void;
  totalAccelerationDate: null | string;
}>({
  render: ({ close, reset, state }) => {
    if (!state.data) {
      return null;
    }

    return (
      <VestingAccelerationSlider
        ctmsGrantFragment={state.data.ctmsGrantFragment}
        onClose={close}
        onExited={reset}
        onTotalAccelerationDateChange={state.data.onTotalAccelerationDateChange}
        show={state.show}
        totalAccelerationDate={state.data.totalAccelerationDate}
      />
    );
  },
});
