/* eslint-disable relay/unused-fields */
import { useCallback, useMemo } from "react";
import { useFragment, useRelayEnvironment } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { fetchQuery, graphql } from "relay-runtime";

import { DraftGrantDetailsComponent } from "../../../../components/DraftGrantDetailsComponent";
import { GrantFormSlide } from "../../../../components/GrantForm/GrantFormSlide";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { CenteredColumnLayout } from "../../../../components/ui/Layout/CenteredColumnLayout";
import { useBoolean } from "../../../../hooks/useBoolean";
import { useQuery } from "../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  APPLICATION_ROUTES,
  useEasopGrantIdParam,
  useOrganizationIdParam,
} from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { DraftGrantDetails_Account$key } from "./__generated__/DraftGrantDetails_Account.graphql";
import { DraftGrantDetails_DeleteGrant_Mutation } from "./__generated__/DraftGrantDetails_DeleteGrant_Mutation.graphql";
import {
  DraftGrantDetails_EasopGrant$data,
  DraftGrantDetails_EasopGrant$key,
} from "./__generated__/DraftGrantDetails_EasopGrant.graphql";
import { DraftGrantDetails_Organization$key } from "./__generated__/DraftGrantDetails_Organization.graphql";
import { DraftGrantDetails_Query } from "./__generated__/DraftGrantDetails_Query.graphql";

const PreviousStepLink: React.FC<{
  grantStatus: DraftGrantDetails_EasopGrant$data["grantStatus"];
  organizationId: string;
}> = ({ grantStatus, organizationId }) =>
  grantStatus === "PendingReview" ? (
    <BreadCrumb.Link
      to={generatePath(
        APPLICATION_ROUTES.organizationPrepareYourGrantsUnderReview,
        { organizationId },
      )}
    >
      Under review
    </BreadCrumb.Link>
  ) : grantStatus === "Reviewed" ? (
    <BreadCrumb.Link
      to={generatePath(
        APPLICATION_ROUTES.organizationPrepareYourGrantsBoardApproval,
        { organizationId },
      )}
    >
      Board approval
    </BreadCrumb.Link>
  ) : grantStatus === "PendingCtmsImplementation" ? (
    <BreadCrumb.Link
      to={generatePath(
        APPLICATION_ROUTES.organizationPrepareYourGrantsPendingImplementation,
        { organizationId },
      )}
    >
      Pending implementation
    </BreadCrumb.Link>
  ) : grantStatus === "PendingBoardConsent" ? (
    <BreadCrumb.Link
      to={generatePath(
        APPLICATION_ROUTES.organizationPrepareYourGrantsPendingApproval,
        { organizationId },
      )}
    >
      Pending approval
    </BreadCrumb.Link>
  ) : (
    <BreadCrumb.Link
      to={generatePath(APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts, {
        organizationId,
      })}
    >
      Drafts
    </BreadCrumb.Link>
  );

const ORGANIZATION_FRAGMENT = graphql`
  fragment DraftGrantDetails_Organization on Organization {
    id
    name
    ...DraftGrantDetailsComponent_Organization
  }
`;

const ACCOUNT_FRAGMENT = graphql`
  fragment DraftGrantDetails_Account on Account
  @argumentDefinitions(organizationId: { type: "OrganizationId!" }) {
    isSuperAdmin
    isAllowedToManageOrganization(organizationId: $organizationId)
    ...DraftGrantDetailsComponent_Account
      @arguments(organizationId: $organizationId)
  }
`;

const GRANT_FRAGMENT = graphql`
  fragment DraftGrantDetails_EasopGrant on EasopGrant {
    id
    warnings
    label
    grantStatus
    formattedGranteeName
    exercisePrice
    exercisePriceBelowFMVSetOn
    quantityGranted
    vestingStartDate
    earlyExercise
    accelerationClause
    granteeManagementCompanyName
    postTerminationExercisePeriod {
      displayName
    }
    createdAt
    isVirtual
    vestingSchedule {
      id
      name
    }
    vestingDataPoints {
      cumulativeVested
      date
    }
    instrument {
      id
    }
    grantee {
      id
      ...DraftGrantDetailsComponent_Grantee
    }
    ...DraftGrantDetailsComponent_EasopGrant
  }
`;

const GRANT_QUERY = graphql`
  query DraftGrantDetails_Grant_Query($grantId: UUID!) {
    easopGrant(id: $grantId) {
      ...DraftGrantDetails_EasopGrant
    }
  }
`;

const DELETE_GRANT_MUTATION = graphql`
  mutation DraftGrantDetails_DeleteGrant_Mutation($id: UUID!) {
    deleteEasopGrant(id: $id)
  }
`;

const DraftGrantDetailsPage_: React.FC<{
  accountFragment: DraftGrantDetails_Account$key;
  grantFragment: DraftGrantDetails_EasopGrant$key;
  organizationFragment: DraftGrantDetails_Organization$key;
}> = ({ accountFragment, grantFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const account = useFragment(ACCOUNT_FRAGMENT, accountFragment);
  const grant = useFragment(GRANT_FRAGMENT, grantFragment);

  const showActionButtons = useMemo(
    () =>
      (account.isAllowedToManageOrganization &&
        grant.grantStatus === "Draft") ||
      (grant.grantStatus === "PendingReview" && account.isSuperAdmin),
    [
      account.isAllowedToManageOrganization,
      account.isSuperAdmin,
      grant.grantStatus,
    ],
  );

  const relayEnvironment = useRelayEnvironment();

  const updateGrantData = () =>
    // @ts-expect-error - relay-runtime types are not up-to-date
    fetchQuery(relayEnvironment, GRANT_QUERY, { grantId: grant.id }).subscribe(
      {},
    );

  const navigate = useNavigate();

  const {
    setFalse: closeGrantFormSlide,
    setTrue: openGrantFormSlide,
    value: grantFormSlideIsOpened,
  } = useBoolean(false);

  const onGrantDeleted = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationPrepareYourGrantsDrafts, {
        organizationId: organization.id,
      }),
    );
  }, [navigate, organization.id]);

  const [deleteGrant, deleteGrantMutationIsInFlight] =
    useSafeMutation<DraftGrantDetails_DeleteGrant_Mutation>(
      DELETE_GRANT_MUTATION,
    );
  const handleDeleteButtonClick = async () => {
    await deleteGrant({
      variables: { id: grant.id },
    });

    onGrantDeleted();
  };

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES.organizationHome, {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <PreviousStepLink
            grantStatus={grant.grantStatus}
            organizationId={organization.id}
          />
          <BreadCrumb.Link to=".">{grant.label}</BreadCrumb.Link>
        </BreadCrumb>
      }
    >
      <GrantFormSlide
        easopGrantId={grant.id}
        onClose={closeGrantFormSlide}
        onGrantDeleted={onGrantDeleted}
        onGrantUpdated={updateGrantData}
        open={grantFormSlideIsOpened}
        organizationId={organization.id}
      />

      <DraftGrantDetailsComponent
        deleteGrantIsInFlight={deleteGrantMutationIsInFlight}
        easopGrantFragment={grant}
        granteeFragment={grant.grantee}
        onDeleteButtonClicked={handleDeleteButtonClick}
        onEditButtonClicked={openGrantFormSlide}
        organizationFragment={organization}
        showActionButtons={showActionButtons}
        viewerFragment={account}
      />
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query DraftGrantDetails_Query(
    $organizationId: OrganizationId!
    $easopGrantId: UUID!
  ) {
    organization(id: $organizationId) {
      id
      name
      ...DraftGrantDetails_Organization
    }
    easopGrant(id: $easopGrantId) {
      ...DraftGrantDetails_EasopGrant
    }
    me {
      ...DraftGrantDetails_Account @arguments(organizationId: $organizationId)
    }
  }
`;

const DraftGrantDetailsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const easopGrantId = useEasopGrantIdParam();
  const {
    query: { easopGrant: grant, me, organization },
  } = useQuery<DraftGrantDetails_Query>(QUERY, {
    easopGrantId,
    organizationId,
  });

  if (!organization || !grant) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="New Equity Flow"
      analyticsName="Admin - Draft details"
      organizationId={organization.id}
      title={`Admin | ${organization.name} draft details`}
    >
      <DraftGrantDetailsPage_
        accountFragment={me}
        grantFragment={grant}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default DraftGrantDetailsPage;
