import React from "react";
import { graphql, useFragment } from "react-relay";

import {
  SelectOrganizationModal_Organization$data,
  SelectOrganizationModal_Organization$key,
} from "./__generated__/SelectOrganizationModal_Organization.graphql";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "./ui/Modal";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SelectOrganizationModal_Organization on Organization
  @relay(plural: true) {
    id
    name
  }
`;

const ModalContent: React.FC<{
  onClose: () => void;
  onSelect: (organizationId: string) => void;
  organizations: SelectOrganizationModal_Organization$data;
}> = ({ onClose, onSelect, organizations }) => {
  return (
    <Modal.Content onClose={onClose} title="Select organization">
      <SelectAutocomplete
        getOptionLabel={(organization) => organization.name}
        getOptionValue={(organization) => organization.id}
        onChange={(option) => {
          if (option) {
            onSelect(option.id);
            onClose();
          }
        }}
        options={organizations}
        usePortal
      />
    </Modal.Content>
  );
};

export const SelectOrganizationModal: React.FC<{
  onClose: () => void;
  onSelect: (organizationId: string) => void;
  organizationsFragment: SelectOrganizationModal_Organization$key;
  show: boolean;
}> = ({ onClose, onSelect, organizationsFragment, show }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  return (
    <Modal onClose={onClose} show={show}>
      <ModalContent
        onClose={onClose}
        onSelect={onSelect}
        organizations={organizations}
      />
    </Modal>
  );
};
