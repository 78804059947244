import { useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import { useEquityOfferShareUtils_EquityOffer$key } from "./__generated__/useEquityOfferShareUtils_EquityOffer.graphql";

const EQUITY_OFFER_FRAGMENT = graphql`
  fragment useEquityOfferShareUtils_EquityOffer on EquityOffer {
    id
    candidateAccessSecretKey
  }
`;

const getCandidateOfferUrl = ({
  candidateAccessSecretKey,
}: {
  candidateAccessSecretKey: string;
}) => {
  const currentURL = new URL(window.location.href);

  currentURL.pathname = generatePath(APPLICATION_ROUTES["candidateOffer"], {
    equityOfferCandidateAccessSecretKey: candidateAccessSecretKey,
  });

  return currentURL.toString();
};

const getCandidateOfferPDFPath = ({
  equityOfferId,
}: {
  equityOfferId: string;
}) => {
  return `/api/candidate-offers/${equityOfferId}/offer.pdf`;
};

export const useEquityOfferShareUtils = ({
  equityOfferFragment,
}: {
  equityOfferFragment: useEquityOfferShareUtils_EquityOffer$key;
}) => {
  const equityOffer = useFragment(EQUITY_OFFER_FRAGMENT, equityOfferFragment);

  const candidateOfferUrl = useMemo(
    () =>
      getCandidateOfferUrl({
        candidateAccessSecretKey: equityOffer.candidateAccessSecretKey,
      }),
    [equityOffer.candidateAccessSecretKey],
  );

  const candidateOfferPDFPath = useMemo(
    () =>
      getCandidateOfferPDFPath({
        equityOfferId: equityOffer.id,
      }),
    [equityOffer.id],
  );

  return { candidateOfferPDFPath, candidateOfferUrl };
};
