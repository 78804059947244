/**
 * @generated SignedSource<<02e7be6df7a5e214b3be41791f65fb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeGrantDetails_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "GranteeGrantDetails_Organization";
};
export type GranteeGrantDetails_Organization$key = {
  readonly " $data"?: GranteeGrantDetails_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeGrantDetails_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeGrantDetails_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "f37992f9cfa67bab199d5cc0a1cfbf23";

export default node;
