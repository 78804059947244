import classNames from "classnames";
import React, { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../hooks/useSafeMutation";
import { SendReminderButton_BoardConsent$key } from "./__generated__/SendReminderButton_BoardConsent.graphql";
import { SendReminderButton_sendBoardConsentSignatureRequestReminderEmails_Mutation } from "./__generated__/SendReminderButton_sendBoardConsentSignatureRequestReminderEmails_Mutation.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { Toast } from "./ui/Toast";

const BOARD_CONSENT_FRAGMENT = graphql`
  fragment SendReminderButton_BoardConsent on BoardConsent {
    id
  }
`;

const SEND_BOARD_CONSENT_SIGNATURE_REQUEST_REMINDER_EMAILS_MUTATION = graphql`
  mutation SendReminderButton_sendBoardConsentSignatureRequestReminderEmails_Mutation(
    $boardConsentId: UUID!
  ) {
    sendBoardConsentSignatureRequestReminderEmails(
      boardConsentId: $boardConsentId
    ) {
      ...SendReminderButton_BoardConsent
      canBoardMemberSignatureRequestReminderEmailsBeSent
    }
  }
`;

export const SendReminderButton: React.FC<
  Omit<
    React.ComponentProps<typeof Button>,
    "children" | "loading" | "onClick" | "size" | "type" | "variant"
  > & {
    boardConsentFragment: SendReminderButton_BoardConsent$key;
  }
> = ({ boardConsentFragment, className, ...props }) => {
  const toaster = useToaster();

  const boardConsent = useFragment(
    BOARD_CONSENT_FRAGMENT,
    boardConsentFragment,
  );

  const [
    sendBoardConsentSignatureRequestReminderEmails,
    sendBoardConsentSignatureRequestReminderEmailsMutationIsInFlight,
  ] =
    useSafeMutation<SendReminderButton_sendBoardConsentSignatureRequestReminderEmails_Mutation>(
      SEND_BOARD_CONSENT_SIGNATURE_REQUEST_REMINDER_EMAILS_MUTATION,
    );

  const handleReminderButtonClick = useCallback(async () => {
    await sendBoardConsentSignatureRequestReminderEmails({
      variables: { boardConsentId: boardConsent.id },
    });

    toaster.push(
      <Toast title="Great!">
        A reminder has been successfully sent to the missing board members!
      </Toast>,
    );
  }, [
    boardConsent.id,
    sendBoardConsentSignatureRequestReminderEmails,
    toaster,
  ]);

  return (
    <Button
      className={classNames("flex", className)}
      loading={sendBoardConsentSignatureRequestReminderEmailsMutationIsInFlight}
      onClick={handleReminderButtonClick}
      size="small"
      type="button"
      variant="Secondary Full"
      {...props}
    >
      Send a reminder
    </Button>
  );
};
