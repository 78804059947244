/**
 * @generated SignedSource<<6f4fa8956a8db7ab25d23bc5a0ff9c64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AccelerationClause = "DoubleTrigger100" | "DoubleTrigger25" | "DoubleTrigger35" | "DoubleTrigger50" | "SingleTrigger";
export type AwardSuperType = "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
export type GrantableStatus = "COUNTRY_NOT_UNLOCKED" | "EQUITY_TYPE_IN_GEOGRAPHY_NOT_UNLOCKED" | "GRANTABLE" | "GRANTEE_IS_MISSING_REQUIRED_INFORMATION" | "GRANT_ON_MANAGEMENT_COMPANIES_NOT_ALLOWED" | "WORK_RELATIONSHIP_NOT_COVERED";
export type ValuationType = "FMV_409A" | "UK_VALUATION";
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type GrantForm_Grantees$data = ReadonlyArray<{
  readonly contractStartDate: string | null;
  readonly ctmsGrants: ReadonlyArray<{
    readonly __typename: "CTMSGrant";
  }>;
  readonly defaultAccelerationClause: AccelerationClause | null;
  readonly defaultPostTerminationExercisePeriod: {
    readonly id: string;
  } | null;
  readonly defaultVestingSchedule: {
    readonly id: string;
  } | null;
  readonly earlyExerciseIsAllowedByDefault: boolean;
  readonly easopGrants: ReadonlyArray<{
    readonly __typename: "EasopGrant";
  }>;
  readonly equityGridLevel: {
    readonly __typename: "EquityGridLevel";
  } | null;
  readonly grantableStatus: GrantableStatus;
  readonly id: string;
  readonly instruments: ReadonlyArray<{
    readonly equityType: {
      readonly awardSuperType: AwardSuperType;
      readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType" | "GrantWatchouts_Rule100KWatchout_EquityType" | "GrantWatchouts_Rule83BWatchout_EquityType">;
    } | null;
    readonly id: string;
    readonly isEarlyExerciseBeneficial: boolean;
    readonly name: string;
    readonly needsAdditionalFormalities: boolean;
    readonly valuation: {
      readonly requiresNewValuationToGrant: boolean | null;
      readonly type: ValuationType;
      readonly valueInDollars: number | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialNoticeMessage_Instrument" | "NeedsAdditionalFormalitiesWarning_Instrument" | "PostTerminationExercisePeriodSelectionBlock_Instrument">;
  }>;
  readonly name: string;
  readonly workRelationship: WorkRelationship | null;
  readonly " $fragmentSpreads": FragmentRefs<"EquityGridLevelTopUpTip_Grantee" | "GranteeFormSlide_Grantee" | "GranteeNotGrantableAlert_Grantee" | "GranteeSelect_Grantees">;
  readonly " $fragmentType": "GrantForm_Grantees";
}>;
export type GrantForm_Grantees$key = ReadonlyArray<{
  readonly " $data"?: GrantForm_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantForm_Grantees">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "GrantForm_Grantees",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contractStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CTMSGrant",
      "kind": "LinkedField",
      "name": "ctmsGrants",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EasopGrant",
      "kind": "LinkedField",
      "name": "easopGrants",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "sortBy",
          "value": "TaxFavored"
        },
        {
          "kind": "Literal",
          "name": "sortDirection",
          "value": "DESC"
        }
      ],
      "concreteType": "Instrument",
      "kind": "LinkedField",
      "name": "instruments",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "needsAdditionalFormalities",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isEarlyExerciseBeneficial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "concreteType": "InstrumentValuation",
          "kind": "LinkedField",
          "name": "valuation",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valueInDollars",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "requiresNewValuationToGrant",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EquityType",
          "kind": "LinkedField",
          "name": "equityType",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "awardSuperType",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_Rule100KWatchout_EquityType"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "GrantWatchouts_Rule83BWatchout_EquityType"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EarlyExerciseBeneficialNoticeMessage_Instrument"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NeedsAdditionalFormalitiesWarning_Instrument"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PostTerminationExercisePeriodSelectionBlock_Instrument"
        }
      ],
      "storageKey": "instruments(sortBy:\"TaxFavored\",sortDirection:\"DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityGridLevel",
      "kind": "LinkedField",
      "name": "equityGridLevel",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantableStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "workRelationship",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "defaultVestingSchedule",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "defaultAccelerationClause",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "earlyExerciseIsAllowedByDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "defaultPostTerminationExercisePeriod",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeSelect_Grantees"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeFormSlide_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeNotGrantableAlert_Grantee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityGridLevelTopUpTip_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "4e966d4ace2961efa8763090f87af606";

export default node;
