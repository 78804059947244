/**
 * @generated SignedSource<<d931195b659036fac3ac6677237c0f38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantType = "real" | "virtual";
export type Reporting_Grantee_Query$variables = {
  grantType: GrantType;
  granteeId: string;
};
export type Reporting_Grantee_Query$data = {
  readonly grantee: {
    readonly ctmsGrants: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"GranteePortalHydratedContentTab_Reporting_CTMSGrant">;
    }>;
    readonly organization: {
      readonly id: string;
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalHydratedContentTab_Grantee">;
  } | null;
};
export type Reporting_Grantee_Query = {
  response: Reporting_Grantee_Query$data;
  variables: Reporting_Grantee_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grantType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "granteeId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "granteeId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "grantType",
    "variableName": "grantType"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workRelationship",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Reporting_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GranteePortalHydratedContentTab_Grantee"
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "GranteePortalHydratedContentTab_Reporting_CTMSGrant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Reporting_Grantee_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Grantee",
        "kind": "LinkedField",
        "name": "grantee",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "CTMSGrant",
            "kind": "LinkedField",
            "name": "ctmsGrants",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Instrument",
                "kind": "LinkedField",
                "name": "matchingInstrument",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Country",
                    "kind": "LinkedField",
                    "name": "taxResidenceCountry",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emoji",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquityType",
                    "kind": "LinkedField",
                    "name": "equityType",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "equityTypeAwardName",
                "storageKey": null
              },
              {
                "alias": "hydratedPortalContent",
                "args": null,
                "concreteType": "AdminPortalContent",
                "kind": "LinkedField",
                "name": "reportingGranteePortalContent",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "content",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastModifiedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a679b84d33a7eb9c5e728601a4e9f469",
    "id": null,
    "metadata": {},
    "name": "Reporting_Grantee_Query",
    "operationKind": "query",
    "text": "query Reporting_Grantee_Query(\n  $granteeId: GranteeId!\n  $grantType: GrantType!\n) {\n  grantee(id: $granteeId) {\n    ...GranteePortalHydratedContentTab_Grantee\n    organization {\n      id\n      name\n    }\n    ctmsGrants(grantType: $grantType) {\n      ...GranteePortalHydratedContentTab_Reporting_CTMSGrant\n      id\n    }\n    id\n  }\n}\n\nfragment AdminPortalContent_AdminPortalContent on AdminPortalContent {\n  content\n  lastModifiedAt\n}\n\nfragment GrantRelatedHydratedContent_AdminPortalContent on AdminPortalContent {\n  error\n  content\n  ...AdminPortalContent_AdminPortalContent\n}\n\nfragment GrantRelatedHydratedContent_CTMSGrant on CTMSGrant {\n  label\n  matchingInstrument {\n    workRelationship\n    taxResidenceCountry {\n      name\n      emoji\n    }\n    equityType {\n      name\n      id\n    }\n    id\n  }\n  equityTypeAwardName\n}\n\nfragment GranteePortalHydratedContentTab_Grantee on Grantee {\n  workRelationship\n  taxResidenceCountry {\n    __typename\n  }\n}\n\nfragment GranteePortalHydratedContentTab_Reporting_CTMSGrant on CTMSGrant {\n  id\n  label\n  ...GrantRelatedHydratedContent_CTMSGrant\n  hydratedPortalContent: reportingGranteePortalContent {\n    ...GrantRelatedHydratedContent_AdminPortalContent\n  }\n}\n"
  }
};
})();

(node as any).hash = "af6646fdfa8780915a72c4b37e94077c";

export default node;
