import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { RoundedBox } from "../../components/ui/RoundedBox";
import { Typography } from "../../components/ui/Typography";
import { useDisplayFullyDilutedValuesInGranteePortal } from "../../hooks/useDisplayFullyDilutedValuesInGranteePortal";
import { useGranteePortalGranteeShares } from "../../hooks/useGranteePortal";
import { useOrganizationSharesUtil } from "../../hooks/useOrganizationSharesUtil";
import { GranteePotentialValue_Grantee$key } from "./__generated__/GranteePotentialValue_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePotentialValue_Grantee on Grantee {
    organization {
      ...useOrganizationSharesUtil_Organization
    }
    ...useDisplayFullyDilutedValuesInGranteePortal_Grantee
    ...useGranteePortalGranteeShares_Grantee
  }
`;

export const GranteePotentialValue: React.FC<{
  granteeFragment: GranteePotentialValue_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const { totalGrantedShares } = useGranteePortalGranteeShares({
    granteeFragment: grantee,
  });

  const grantedValue = sharesToValue(totalGrantedShares);

  const displayFullyDilutedValues = useDisplayFullyDilutedValuesInGranteePortal(
    { granteeFragment: grantee },
  );

  if (!displayFullyDilutedValues) return null;

  if (grantedValue === null) return null;

  return (
    <RoundedBox className="flex-grow p-6" withBorder withShadow>
      <div>
        <Typography as="div" variant="Medium/Default">
          Current potential equity value*
        </Typography>

        <Typography
          as="div"
          className="mt-4 text-primary"
          variant="Medium/Extra Large"
        >
          <FormattedNumber
            currency="USD"
            maximumFractionDigits={0}
            style="currency"
            value={grantedValue}
          />
        </Typography>
      </div>

      <Typography
        as="div"
        className="mt-4 border-t-[0.5px] border-gray-04 pt-4 text-black-05"
        variant="Regular/Extra Small"
      >
        * You should pay attention to the fact that the value of your option
        grant is still theoretical at this stage.
        <br />
        Your company&apos;s valuation may increase or decrease over time,
        according to your company’s future success.
      </Typography>
    </RoundedBox>
  );
};
