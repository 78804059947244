import classNames from "classnames";
import React from "react";

import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";

const ListValuesRow: React.FC<
  React.PropsWithChildren<{
    title: React.ReactNode;
  }>
> = ({ children, title }) => (
  <div className="flex items-center justify-between py-4">
    <Typography
      as="div"
      className="select-none text-gray-09"
      variant="Regular/Extra Small"
    >
      {title}
    </Typography>
    <Typography as="div" className="text-gray-09" variant="Regular/Extra Small">
      {children}
    </Typography>
  </div>
);

const _ListValues: React.FC<React.PropsWithChildren> = ({ children }) => (
  <div className="divide-y-[0.5px] divide-gray-04">{children}</div>
);

const ListValues = Object.assign(_ListValues, { Row: ListValuesRow });

const MultipleValuesItem: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className }) => (
  <div
    className={classNames(
      "border-t-[0.5px] border-gray-04 px-3 py-3.5 first:rounded-t first:border-none last:rounded-b",
      className,
    )}
  >
    {children}
  </div>
);

const _MultipleValues: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography
    as="div"
    className="rounded border-[0.5px] border-gray-04 bg-white-01"
    variant="Regular/Extra Small"
  >
    {children}
  </Typography>
);

const MultipleValues = Object.assign(_MultipleValues, {
  Item: MultipleValuesItem,
});

const SingleValue: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Typography
    as="div"
    className="rounded border-[0.5px] border-gray-04 bg-gray-01 px-3 py-3.5"
    variant="Regular/Extra Small"
  >
    {children}
  </Typography>
);

const TITLE_SIZE_CLASS_NAMES = {
  large: classNames("text-XL/Medium font-brand"),
  medium: classNames("text-Base/Medium"),
};

const _Item: React.FC<
  React.PropsWithChildren<{
    borderless?: boolean;
    className?: string;
    fullWidth?: boolean;
    subTitle?: React.ReactNode;
    title?: React.ReactNode;
    titleSize?: keyof typeof TITLE_SIZE_CLASS_NAMES;
  }>
> = ({
  borderless = false,
  children,
  className,
  fullWidth = false,
  subTitle,
  title,
  titleSize = "medium",
}) => (
  <div
    className={classNames(
      "space-y-2 border-gray-04 py-6",
      {
        "border-t-[0.5px]": !borderless,
        "px-10": !fullWidth,
      },
      className,
    )}
  >
    {(title || subTitle) && (
      <div className="space-y-2">
        {title && (
          <div className={TITLE_SIZE_CLASS_NAMES[titleSize]}>{title}</div>
        )}
        {subTitle && (
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            {subTitle}
          </Typography>
        )}
      </div>
    )}
    {children}
  </div>
);

const Item = Object.assign(_Item, {
  ListValues,
  MultipleValues,
  SingleValue,
});

const _SettingsPage: React.FC<
  React.PropsWithChildren<{
    action?: React.ReactNode;
    className?: string;
    subTitle?: React.ReactNode;
    title?: React.ReactNode;
    titleSize?: keyof typeof TITLE_SIZE_CLASS_NAMES;
  }>
> = ({
  action,
  children,
  className,
  subTitle,
  title,
  titleSize = "medium",
}) => {
  return (
    <RoundedBox
      className={classNames("w-full", className)}
      withBorder
      withShadow
    >
      <div className="flex items-start justify-between gap-1.5 p-10 pb-6">
        <div className="space-y-4">
          {title && (
            <div className={TITLE_SIZE_CLASS_NAMES[titleSize]}>{title}</div>
          )}
          {subTitle && (
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              {subTitle}
            </Typography>
          )}
        </div>
        <div className="shrink-0">{action}</div>
      </div>
      {children}
    </RoundedBox>
  );
};

export const SettingsContainer = Object.assign(_SettingsPage, { Item });
