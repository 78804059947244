/**
 * @generated SignedSource<<d57c50dbfe59b36400d6a1d641797038>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DurationUnit = "day" | "month" | "year";
import { FragmentRefs } from "relay-runtime";
export type GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod$data = {
  readonly duration: number;
  readonly durationUnit: DurationUnit;
  readonly " $fragmentType": "GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod";
};
export type GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod$key = {
  readonly " $data"?: GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationUnit",
      "storageKey": null
    }
  ],
  "type": "OrganizationPostTerminationExercisePeriod",
  "abstractKey": "__isOrganizationPostTerminationExercisePeriod"
};

(node as any).hash = "2cfc3690359033ffe64ff6a6a7e3232e";

export default node;
