import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";
import { z } from "zod";

import { Button } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { Modal } from "../../components/ui/Modal";
import { Typography } from "../../components/ui/Typography";
import { useBoolean } from "../../hooks/useBoolean";
import { useSafeMutation } from "../../hooks/useSafeMutation";
import { APPLICATION_ROUTES } from "../../paths";
import { CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation } from "./__generated__/CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation.graphql";

const CREATE_SANDBOX_ORGANIZATION_FORM_SCHEMA = z.object({
  organizationName: z.string().min(1),
});

const CREATE_SANDBOX_ORGANIZATION_MUTATION = graphql`
  mutation CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation(
    $organizationName: String!
  ) {
    createSandboxOrganization(organizationName: $organizationName) {
      id
    }
  }
`;

const CreateSandboxOrganizationModalButton: React.FC = () => {
  const {
    setFalse: closeModal,
    setTrue: openModal,
    value: modalIsOpen,
  } = useBoolean(false);
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof CREATE_SANDBOX_ORGANIZATION_FORM_SCHEMA>>(
    {
      resolver: zodResolver(CREATE_SANDBOX_ORGANIZATION_FORM_SCHEMA),
    },
  );

  const [triggerCreateSandboxOrganizationMutation] =
    useSafeMutation<CreateSandboxOrganizationModal_CreateSandboxOrganization_Mutation>(
      CREATE_SANDBOX_ORGANIZATION_MUTATION,
    );

  const handleFormSubmit = form.handleSubmit(async (data) => {
    const createSandboxOrganizationMutationResult =
      await triggerCreateSandboxOrganizationMutation({
        variables: {
          organizationName: data.organizationName,
        },
      });

    void navigate(
      generatePath(APPLICATION_ROUTES.organizationHome, {
        organizationId:
          createSandboxOrganizationMutationResult.createSandboxOrganization.id,
      }),
    );
  });

  const handleCloseModalButtonClick = () => {
    form.reset();

    closeModal();
  };

  return (
    <>
      <hr className="border-t-[0.5px] border-[#d9d9d9]" />
      <div className="flex items-center gap-4 rounded-lg bg-gray-01 p-4">
        <div className="flex items-center justify-center rounded-lg bg-yellow-02 p-1">
          <PlayCircleIcon className="w-4 text-yellow-08" />
        </div>
        <div className="space-y-4">
          <Typography variant="Regular/Extra Small">
            Create a sandbox organization and demo the platform!
          </Typography>
          <Button onClick={openModal} size="small" variant="Primary Full">
            + New Sandbox
          </Button>
        </div>
      </div>
      <Modal
        onClose={handleCloseModalButtonClick}
        show={modalIsOpen}
        width={768}
      >
        <Modal.Content
          actionsInHeader={
            <>
              <Modal.ActionButton
                onClick={handleCloseModalButtonClick}
                variant="Secondary Full"
              >
                Cancel
              </Modal.ActionButton>
              <Modal.SubmitButton
                form="create-sandbox-organization"
                loading={form.formState.isSubmitting}
              >
                Create Sandbox
              </Modal.SubmitButton>
            </>
          }
          onClose={handleCloseModalButtonClick}
          title="Create Sandbox Organization"
        >
          <Modal.Form
            id="create-sandbox-organization"
            onSubmit={handleFormSubmit}
          >
            <FormRow
              error={form.formState.errors.organizationName?.message}
              label="Sandbox organization name"
            >
              <Input
                {...form.register("organizationName")}
                placeholder="Acme"
                type="text"
              />
            </FormRow>
          </Modal.Form>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default CreateSandboxOrganizationModalButton;
