import { SecurityUser } from "iconsax-react";
import { graphql, useFragment } from "react-relay";
import { generatePath } from "react-router-dom";

import { SettingsContainer } from "../../pages/Admin/OrganizationSettings/SettingsContainer";
import { APPLICATION_ROUTES } from "../../paths";
import { LinkButton } from "../ui/Button";
import { NoticeMessage } from "../ui/NoticeMessage";
import { Typography } from "../ui/Typography";
import { BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization$key } from "./__generated__/BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization.graphql";
import { Section } from "./Section";

const ORGANIZATION_FRAGMENT = graphql`
  fragment BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization on Organization {
    id
    boardMembers {
      id
      account {
        firstName
        lastName
        email
      }
    }
  }
`;

export function BoardMembersSection({
  organizationFragment,
}: {
  organizationFragment: BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <Section
      subtitle="The following board members will be required to sign this board consent. Take a moment to verify that all board members required are listed below."
      title="Confirm board members"
    >
      <div className="space-y-4">
        {organization.boardMembers.length > 0 ? (
          <SettingsContainer.Item.MultipleValues>
            {organization.boardMembers.map((boardMember) => (
              <SettingsContainer.Item.MultipleValues.Item
                className="group flex items-center gap-4"
                key={boardMember.id}
              >
                <div className="flex items-center justify-center p-2">
                  <SecurityUser
                    className="h-6 w-6 text-purple-05"
                    variant="Bulk"
                  />
                </div>
                <div className="flex-1 space-y-1">
                  <Typography as="div" variant="Regular/Extra Small">
                    {boardMember.account.firstName}{" "}
                    {boardMember.account.lastName}
                  </Typography>
                  <Typography
                    as="div"
                    className="text-black-05"
                    variant="Regular/Extra Small"
                  >
                    {boardMember.account.email}
                  </Typography>
                </div>
              </SettingsContainer.Item.MultipleValues.Item>
            ))}
          </SettingsContainer.Item.MultipleValues>
        ) : (
          <NoticeMessage hasColor={false} size="Small">
            You haven’t invited any board members.
          </NoticeMessage>
        )}

        <LinkButton
          size="small"
          to={generatePath(
            APPLICATION_ROUTES.organizationSettingsBoardMembers,
            { organizationId: organization.id },
          )}
          variant="Secondary Full"
        >
          Manage board members
        </LinkButton>
      </div>
    </Section>
  );
}
