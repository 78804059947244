/**
 * @generated SignedSource<<0ad1d007cb34a2893c0df7744bcbab26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestNotifySection_Viewer$data = {
  readonly isAllowedToManageOrganization: boolean;
  readonly " $fragmentType": "ExerciseRequestNotifySection_Viewer";
};
export type ExerciseRequestNotifySection_Viewer$key = {
  readonly " $data"?: ExerciseRequestNotifySection_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestNotifySection_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestNotifySection_Viewer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "ScalarField",
      "name": "isAllowedToManageOrganization",
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "fea760a569d52fb5e274a9b2deaaa5f1";

export default node;
