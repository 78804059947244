import { FormattedDateParts } from "react-intl";

function getOrdinalSuffix(day: number) {
  const tens = day % 10;
  const hundreds = day % 100;

  if (tens === 1 && hundreds !== 11) {
    return `${day}st`;
  }
  if (tens === 2 && hundreds !== 12) {
    return `${day}nd`;
  }
  if (tens === 3 && hundreds !== 13) {
    return `${day}rd`;
  }
  return `${day}th`;
}

export const LongDate = ({ value }: { value: Date | number | string }) => {
  return (
    <FormattedDateParts
      day="numeric"
      month="long"
      timeZone="UTC"
      value={value}
      year="numeric"
    >
      {(parts) => {
        const day = parts.find((part) => part.type === "day");
        const month = parts.find((part) => part.type === "month");
        const year = parts.find((part) => part.type === "year");

        if (!day || !month || !year) {
          return null;
        }

        return (
          <>
            {month.value} {getOrdinalSuffix(parseInt(day.value))}, {year.value}
          </>
        );
      }}
    </FormattedDateParts>
  );
};
