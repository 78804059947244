/**
 * @generated SignedSource<<85bee49592da775bad73661afaf0829e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useMergeGrantee_Grantee$data = {
  readonly id: string;
  readonly " $fragmentType": "useMergeGrantee_Grantee";
};
export type useMergeGrantee_Grantee$key = {
  readonly " $data"?: useMergeGrantee_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useMergeGrantee_Grantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useMergeGrantee_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "3f8bffdc608fb2f0e0bf0f796b47e2e6";

export default node;
