import { RoundedBox } from "../ui/RoundedBox";
import { Typography } from "../ui/Typography";
import image from "./empty-list-placeholder-image.png";

export function EmptyListPlaceholder({
  action,
  hideImage,
  loading,
  subtitle,
  title,
}: {
  action?: React.ReactNode;
  hideImage?: boolean;
  loading?: boolean;
  subtitle?: React.ReactNode;
  title: React.ReactNode;
}) {
  return (
    <RoundedBox background="gray" loading={loading} rounded="3xl">
      <div className="space-y-6 p-10">
        {!hideImage && (
          <img alt="Empty list" className="mx-auto w-1/3" src={image} />
        )}
        <div className="space-y-2 text-center">
          <Typography as="div" variant="Medium/Default">
            {title}
          </Typography>
          {subtitle && (
            <Typography
              as="div"
              className="text-gray-09"
              variant="Regular/Small"
            >
              {subtitle}
            </Typography>
          )}
        </div>
        {action && <div className="text-center">{action}</div>}
      </div>
    </RoundedBox>
  );
}
