/**
 * @generated SignedSource<<afc107f3e3fdbafaa5c4066d7bc5c0e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BoardConsentVoidedReason = "FMV_409A_EXPIRED" | "UK_VALUATION_EXPIRED";
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_FairMarketValueApprovals_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly pendingApprovalFairMarketValueBoardConsents: ReadonlyArray<{
    readonly canBoardMemberSignatureRequestReminderEmailsBeSent: boolean;
    readonly fairMarketValue: {
      readonly " $fragmentSpreads": FragmentRefs<"FairMarketValueApprovalTable_FairMarketValue">;
    };
    readonly id: string;
    readonly isPandaDocDocumentSent: boolean;
    readonly signatureRequests: ReadonlyArray<{
      readonly completedAt: string | null;
      readonly id: string;
      readonly signatoryFirstName: string;
      readonly signatoryLastName: string;
    }>;
    readonly voidedReason: BoardConsentVoidedReason | null;
    readonly " $fragmentSpreads": FragmentRefs<"SendReminderButton_BoardConsent">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"LargeOneColumnLayout_Organization">;
  readonly " $fragmentType": "PendingApproval_FairMarketValueApprovals_Organization";
};
export type PendingApproval_FairMarketValueApprovals_Organization$key = {
  readonly " $data"?: PendingApproval_FairMarketValueApprovals_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_FairMarketValueApprovals_Organization">;
};

import PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery_graphql from './PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": PendingApproval_FairMarketValueApprovals_Organization_RefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PendingApproval_FairMarketValueApprovals_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValueBoardConsent",
      "kind": "LinkedField",
      "name": "pendingApprovalFairMarketValueBoardConsents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "voidedReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isPandaDocDocumentSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SignatureRequest",
          "kind": "LinkedField",
          "name": "signatureRequests",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryFirstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "signatoryLastName",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FairMarketValue",
          "kind": "LinkedField",
          "name": "fairMarketValue",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FairMarketValueApprovalTable_FairMarketValue"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SendReminderButton_BoardConsent"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeOneColumnLayout_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "4643c3dcebacf99485a22d7d7a955b90";

export default node;
