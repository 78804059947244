/**
 * @generated SignedSource<<e24858175e1791db4379419211ac686a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GranteePortalProjectionScenarioKey = "CURRENT_VALUE" | "FIRST_ROUND" | "SECOND_ROUND" | "THIRD_ROUND";
import { FragmentRefs } from "relay-runtime";
export type GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$data = {
  readonly additionalInformation: string | null;
  readonly displayed: boolean;
  readonly key: GranteePortalProjectionScenarioKey;
  readonly multiple: number;
  readonly name: string;
  readonly " $fragmentType": "GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario";
};
export type GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$key = {
  readonly " $data"?: GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalSettingsProjectionSection_GranteePortalProjectionScenario",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multiple",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformation",
      "storageKey": null
    }
  ],
  "type": "GranteePortalProjectionScenario",
  "abstractKey": null
};

(node as any).hash = "65a99909c0cfcfeebbe72e0545e02bcd";

export default node;
