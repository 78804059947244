import classNames from "classnames";
import React from "react";

const Value: React.FC<
  Omit<React.ComponentProps<"div">, "children"> & {
    value: number;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = ({ className, value: _, ...props }) => (
  <div className="h-full" {...props}>
    <div className={classNames("h-full w-full rounded-3xl", className)} />
  </div>
);

const _Progress: React.FC<
  Omit<React.ComponentProps<"div">, "children"> & {
    children?:
      | React.ReactElement<React.ComponentProps<typeof Value>>
      | React.ReactElement<React.ComponentProps<typeof Value>>[];
    max: number;
    skeleton?: boolean;
  }
> = ({ children, className, max, skeleton, ...props }) => {
  return (
    <div
      className={classNames(
        "flex h-2 gap-[3px] overflow-hidden rounded-3xl",
        {
          "animate-pulse": skeleton,
        },
        className,
      )}
      {...props}
    >
      {children &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            ...child.props,
            style: {
              ...child.props.style,
              display: child.props.value > 0 ? "block" : "none",
              flexBasis: `${(child.props.value / max) * 100}%`,
            },
          }),
        )}
    </div>
  );
};

export const Progress = Object.assign(_Progress, { Value });
