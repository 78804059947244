import saveAs from "file-saver";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../services/workRelationship";
import { useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation } from "./__generated__/useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation.graphql";
import { useDownloadInstrumentDocumentsPreview_Instrument$key } from "./__generated__/useDownloadInstrumentDocumentsPreview_Instrument.graphql";
import { useDownloadInstrumentDocumentsPreview_Organization$key } from "./__generated__/useDownloadInstrumentDocumentsPreview_Organization.graphql";
import { useTrackDocumentsDownloaded } from "./useAnalytics";
import { useSafeMutation } from "./useSafeMutation";

const INSTRUMENT_FRAGMENT = graphql`
  fragment useDownloadInstrumentDocumentsPreview_Instrument on Instrument {
    id
    workRelationship
    taxResidenceCountry {
      name
    }
    grantNoticeDocumentTemplate {
      id
      name
    }
    formOfExerciseAgreementDocumentTemplate {
      id
      name
    }
    countrySpecificAddendumDocumentTemplate {
      id
      name
    }
    boardConsentDocumentTemplate {
      id
      name
    }
    awardAgreementDocumentTemplate {
      id
      name
    }
    formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate {
      id
      name
    }
  }
`;
const ORGANIZATION_FRAGMENT = graphql`
  fragment useDownloadInstrumentDocumentsPreview_Organization on Organization {
    id
    isReadyToGrant
    plan
  }
`;

const GENERATE_INSTRUMENT_DOCUMENTS_PREVIEW_URL_MUTATION = graphql`
  mutation useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation(
    $instrumentId: UUID!
    $organizationId: OrganizationId!
  ) {
    generateInstrumentDocumentsPreviewURL(
      instrumentId: $instrumentId
      organizationId: $organizationId
    )
  }
`;

export const useDownloadInstrumentDocumentsPreview = ({
  instrumentFragment,
  organizationFragment,
}: {
  instrumentFragment: useDownloadInstrumentDocumentsPreview_Instrument$key;
  organizationFragment: useDownloadInstrumentDocumentsPreview_Organization$key;
}) => {
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const documentations = useMemo(() => {
    const documentations = [
      {
        document: instrument.awardAgreementDocumentTemplate,
        documentType: "AWARD_AGREEMENT",
      },
      {
        document: instrument.boardConsentDocumentTemplate,
        documentType: "BOARD_CONSENT",
      },
      {
        document: instrument.countrySpecificAddendumDocumentTemplate,
        documentType: "COUNTRY_SPECIFIC_ADDENDUM",
      },
      {
        document:
          instrument.formOfEarlyExerciseStockPurchaseAgreementDocumentTemplate,
        documentType: "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT",
      },
      {
        document: instrument.formOfExerciseAgreementDocumentTemplate,
        documentType: "FORM_OF_EXERCISE_AGREEMENT",
      },
      {
        document: instrument.grantNoticeDocumentTemplate,
        documentType: "GRANT_NOTICE",
      },
    ] as const;
    return _(documentations)
      .map(({ document, documentType }) =>
        document
          ? {
              ...document,
              type: documentType,
            }
          : null,
      )
      .compact()
      .value();
  }, [instrument]);

  const [
    generateInstrumentDocumentsPreviewURL,
    generateInstrumentDocumentsPreviewURLMutationIsInFlight,
  ] =
    useSafeMutation<useDownloadInstrumentDocumentsPreview_GenerateInstrumentDocumentsPreviewURL_Mutation>(
      GENERATE_INSTRUMENT_DOCUMENTS_PREVIEW_URL_MUTATION,
    );

  const workRelationship =
    WORK_RELATIONSHIP_TO_LABEL_HELPER[instrument.workRelationship];

  const trackDocumentsDownloaded = useTrackDocumentsDownloaded();

  const download = useCallback(async () => {
    const result = await generateInstrumentDocumentsPreviewURL({
      variables: {
        instrumentId: instrument.id,
        organizationId: organization.id,
      },
    });

    trackDocumentsDownloaded({
      documentsTypes: documentations.map(({ type }) => type),
      downloadType: "MULTIPLE",
    });

    saveAs(
      result.generateInstrumentDocumentsPreviewURL,
      `${instrument.taxResidenceCountry.name} - ${workRelationship.singularLabel} documentation.zip`,
    );
  }, [
    generateInstrumentDocumentsPreviewURL,
    instrument,
    organization,
    workRelationship,
    trackDocumentsDownloaded,
    documentations,
  ]);

  const isDownloadAllowed = useMemo(() => {
    if (!organization.isReadyToGrant) {
      return false;
    }

    switch (organization.plan) {
      case "freemiumDemoBooked":
      case "freemiumDemoToBook":
      case "freeTrial":
        return false;
      case "fullAccess":
        return true;
    }
  }, [organization]);

  return {
    download,
    downloadIsInFlight: generateInstrumentDocumentsPreviewURLMutationIsInFlight,
    isDownloadAllowed,
  };
};
