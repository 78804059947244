import {
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  FolderOpenIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { DetailedBlockWithLink } from "../../../components/DetailedBlockWithLink";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Typography } from "../../../components/ui/Typography";
import { isNonEmptyArray } from "../../../helpers/ts-utlity";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { DocumentsPage_Organization$key } from "./__generated__/DocumentsPage_Organization.graphql";
import { DocumentsPage_Query } from "./__generated__/DocumentsPage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment DocumentsPage_Organization on Organization {
    id
    name
    organizationGeographies {
      __typename
    }
    boardConsents {
      signedDocument {
        __typename
      }
    }
    easopGrants {
      signedBoardConsentDocument {
        __typename
      }
    }
    miscellaneousLegalDocuments {
      __typename
    }
  }
`;

const AdminDocumentsPage_: React.FC<{
  organizationFragment: DocumentsPage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const signedBoardConsentsCount = useMemo(
    () =>
      organization.boardConsents.filter(
        (boardConsent) => boardConsent.signedDocument,
      ).length,
    [organization.boardConsents],
  );
  const easopGrantsWithSignedDocumentsCount = useMemo(
    () =>
      organization.easopGrants.filter(
        (easopGrant) => easopGrant.signedBoardConsentDocument,
      ).length,
    [organization.easopGrants],
  );
  const applicationName = useApplicationName();

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Documents</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <Typography variant="Medium/Default">Documents</Typography>
        <RoundedBox
          className="grid grid-cols-1 divide-x-[0.5px] divide-gray-04 !rounded sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          withBorder
        >
          <DetailedBlockWithLink
            footer={
              <div className="flex justify-between">
                <DetailedBlockWithLink.FooterLink
                  to={generatePath(
                    APPLICATION_ROUTES["organizationDocumentsGeographies"],
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  View legal documents
                </DetailedBlockWithLink.FooterLink>
                <DetailedBlockWithLink.FooterIcon
                  icon={<DocumentDuplicateIcon />}
                />
              </div>
            }
            header={
              <DetailedBlockWithLink.HeaderTag>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# geography} other {# geographies}}"
                  values={{
                    count: organization.organizationGeographies.length,
                  }}
                />
              </DetailedBlockWithLink.HeaderTag>
            }
          >
            <DetailedBlockWithLink.Title>
              Country-Specific Templates
            </DetailedBlockWithLink.Title>
            <DetailedBlockWithLink.Description>
              Find here all country-specific legal documents to be used for next
              grants in your unlocked geographies
            </DetailedBlockWithLink.Description>
          </DetailedBlockWithLink>

          <DetailedBlockWithLink
            footer={
              <div className="flex justify-between">
                <DetailedBlockWithLink.FooterLink
                  to={generatePath(
                    APPLICATION_ROUTES["organizationDocumentsBoardConsents"],
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  View board consents
                </DetailedBlockWithLink.FooterLink>
                <DetailedBlockWithLink.FooterIcon icon={<PencilIcon />} />
              </div>
            }
            header={
              <DetailedBlockWithLink.HeaderTag>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# document} other {# documents}}"
                  values={{
                    count: signedBoardConsentsCount,
                  }}
                />
              </DetailedBlockWithLink.HeaderTag>
            }
          >
            <DetailedBlockWithLink.Title>
              Board consents
            </DetailedBlockWithLink.Title>
            <DetailedBlockWithLink.Description>
              Find here all board consents regarding your past and current
              grants
            </DetailedBlockWithLink.Description>
          </DetailedBlockWithLink>

          <DetailedBlockWithLink
            footer={
              <div className="flex justify-between">
                <DetailedBlockWithLink.FooterLink
                  to={generatePath(
                    APPLICATION_ROUTES[
                      "organizationDocumentsGrantDocumentation"
                    ],
                    {
                      organizationId: organization.id,
                    },
                  )}
                >
                  View documentation
                </DetailedBlockWithLink.FooterLink>
                <DetailedBlockWithLink.FooterIcon icon={<FolderOpenIcon />} />
              </div>
            }
            header={
              <DetailedBlockWithLink.HeaderTag>
                <FormattedMessage
                  defaultMessage="{count, plural, one {# grant} other {# grants}}"
                  values={{
                    count: easopGrantsWithSignedDocumentsCount,
                  }}
                />
              </DetailedBlockWithLink.HeaderTag>
            }
          >
            <DetailedBlockWithLink.Title>
              Grant documentation
            </DetailedBlockWithLink.Title>
            <DetailedBlockWithLink.Description>
              Find here all grant documentation (e.g. grant notice, award
              agreement, etc.) relating to grants made
            </DetailedBlockWithLink.Description>
          </DetailedBlockWithLink>
          {isNonEmptyArray(organization.miscellaneousLegalDocuments) && (
            <DetailedBlockWithLink
              footer={
                <div className="flex justify-between">
                  <DetailedBlockWithLink.FooterLink
                    to={generatePath(
                      APPLICATION_ROUTES[
                        "organizationDocumentsLegalDocumentation"
                      ],
                      {
                        organizationId: organization.id,
                      },
                    )}
                  >
                    View documents
                  </DetailedBlockWithLink.FooterLink>
                  <DetailedBlockWithLink.FooterIcon
                    icon={<DocumentCheckIcon />}
                  />
                </div>
              }
              header={
                <DetailedBlockWithLink.HeaderTag>
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# document} other {# documents}}"
                    values={{
                      count: organization.miscellaneousLegalDocuments.length,
                    }}
                  />
                </DetailedBlockWithLink.HeaderTag>
              }
            >
              <DetailedBlockWithLink.Title>
                External legal documentation
              </DetailedBlockWithLink.Title>
              <DetailedBlockWithLink.Description>
                Access here legal documentation signed outside of{" "}
                {applicationName} and relating indirectly to equity awards (e.g.
                409a valuations, equity incentive plans, custom equity awards,
                etc.)
              </DetailedBlockWithLink.Description>
            </DetailedBlockWithLink>
          )}
        </RoundedBox>
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query DocumentsPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...DocumentsPage_Organization
    }
  }
`;

const AdminDocumentsPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<DocumentsPage_Query>(
    QUERY,
    {
      organizationId,
    },
    { fetchPolicy: "store-and-network" },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Documents"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} documents`}
    >
      <AdminDocumentsPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default AdminDocumentsPage;
