import { Control } from "react-hook-form";
import { graphql, useFragment } from "react-relay";

import { Button } from "../../../../../components/ui/Button";
import { FormRow } from "../../../../../components/ui/Form/FormRow";
import { FormRowContainer } from "../../../../../components/ui/Form/FormRowContainer";
import { SelectAutocomplete } from "../../../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Typography } from "../../../../../components/ui/Typography";
import { ACCELERATION_CLAUSE_LABELS } from "../../../../../services/AccelerationClause";
import { GrantDefaultsForm_Organization$key } from "./__generated__/GrantDefaultsForm_Organization.graphql";
import {
  FormValues,
  GrantDefaultsFormAccelerationClause,
  mapFormToGraphQLAccelerationClause,
} from "./FORM_SCHEMA";

const ACCELERATION_OPTIONS: Array<{
  label: string;
  value: GrantDefaultsFormAccelerationClause;
}> = (
  [
    "NONE",
    "SINGLE_TRIGGER",
    "DOUBLE_TRIGGER_100",
    "DOUBLE_TRIGGER_50",
    "DOUBLE_TRIGGER_35",
    "DOUBLE_TRIGGER_25",
  ] as const
).map((value) => {
  const accelerationClause = mapFormToGraphQLAccelerationClause(value);
  if (!accelerationClause)
    return {
      label: "None",
      value,
    };
  return {
    label: ACCELERATION_CLAUSE_LABELS[accelerationClause],
    value,
  };
});

const ALLOW_EARLY_EXERCISE_OPTIONS = [
  {
    label: "No early exercise",
    value: "NO",
  },
  {
    label: "Allow early exercise",
    value: "YES",
  },
];

const ORGANIZATION_FRAGMENT = graphql`
  fragment GrantDefaultsForm_Organization on Organization {
    allowAcceleration
    allowEarlyExercise
    vestingSchedules {
      id
      name
    }
    postTerminationExercisePeriods {
      id
      displayName
    }
  }
`;

export function GrantDefaultsForm({
  aboveButtons,
  control,
  description,
  formIsDirty,
  loading,
  onDiscard,
  onSubmit,
  organizationFragment,
}: {
  aboveButtons?: React.ReactNode;
  control: Control<FormValues>;
  description: React.ReactNode;
  formIsDirty?: boolean;
  loading?: boolean;
  onDiscard?: () => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  organizationFragment: GrantDefaultsForm_Organization$key;
}) {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <form className="block space-y-10" onSubmit={onSubmit}>
      <div className="space-y-8">
        <FormRowContainer title="Default parameters">
          <Typography className="text-black-05" variant="Regular/Small">
            {description}
          </Typography>
        </FormRowContainer>
        <FormRow.Form
          control={control}
          label="Vesting schedule"
          name="vestingScheduleId"
        >
          <SelectAutocomplete.Form
            control={control}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            name="vestingScheduleId"
            options={organization.vestingSchedules}
          />
        </FormRow.Form>
        <FormRow.Form
          control={control}
          label="Post termination exercise period"
          name="postTerminationPeriodId"
        >
          <SelectAutocomplete.Form
            control={control}
            getOptionLabel={(option) => option.displayName}
            getOptionValue={(option) => option.id}
            name="postTerminationPeriodId"
            options={organization.postTerminationExercisePeriods}
          />
        </FormRow.Form>
        <FormRow.Form
          context={
            !organization.allowAcceleration ? (
              <>
                According to your configuration, acceleration is disabled at the
                company level.
              </>
            ) : null
          }
          control={control}
          label="Acceleration"
          name="acceleration"
        >
          <SelectAutocomplete.Form
            control={control}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            name="acceleration"
            options={ACCELERATION_OPTIONS}
            readOnly={!organization.allowAcceleration}
          />
        </FormRow.Form>
        <FormRow.Form
          context={
            !organization.allowEarlyExercise ? (
              <>
                According to your configuration, early exercise is disabled at
                the company level.
              </>
            ) : null
          }
          control={control}
          label="Early exercise"
          name="allowEarlyExercise"
        >
          <SelectAutocomplete.Form
            control={control}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            name="allowEarlyExercise"
            options={ALLOW_EARLY_EXERCISE_OPTIONS}
            readOnly={!organization.allowEarlyExercise}
          />
        </FormRow.Form>
      </div>
      {aboveButtons}
      <div className="flex items-center gap-2">
        <Button
          disabled={!formIsDirty}
          loading={loading}
          size="small"
          type="submit"
        >
          Save
        </Button>
        <Button
          disabled={!formIsDirty || loading}
          onClick={onDiscard}
          size="small"
          type="button"
          variant="Secondary Full"
        >
          Discard
        </Button>
      </div>
    </form>
  );
}
