import classNames from "classnames";
import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import {
  PlanningChart_Organization$data,
  PlanningChart_Organization$key,
} from "./__generated__/PlanningChart_Organization.graphql";
import { BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME, BarGraph } from "./BarGraph";
import {
  SHARES_VALUE_MODE_LABELS,
  SHARES_VALUE_MODES,
  SharesValue,
} from "./SharesValue";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment PlanningChart_Organization on Organization {
    availableSharesForPlanningNow

    planningNewHiresTotalShares
    planningTenureGrantsTotalShares
    planningPromotionGrantsTotalShares
    planningExceptionalGrantsTotalShares
    planningTerminationsTotalShares
    planningSharesLeft

    ...SharesValue_Organization
  }
`;

const TooltipContent: React.FC<{
  label: string;
  organization: PlanningChart_Organization$data;
  shares: number;
}> = ({ label, organization, shares }) => {
  return (
    <div className="flex flex-col gap-2 rounded bg-black-07 p-4 text-white">
      <Typography as="div" variant="Regular/Extra Small">
        {label}
      </Typography>

      {SHARES_VALUE_MODES.map((mode) => (
        <div className="flex flex-col" key={mode}>
          <Typography variant="Regular/Caption">
            {SHARES_VALUE_MODE_LABELS[mode].sharesLabel}:
          </Typography>
          <SharesValue
            mode={mode}
            organizationFragment={organization}
            shares={shares}
            variant="Medium/Extra Small"
          />
        </div>
      ))}
    </div>
  );
};

export const PlanningChart: React.FC<{
  organizationFragment: PlanningChart_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const intl = useIntl();

  const yTickFormatter = useCallback(
    (value: number) =>
      intl.formatNumber(value, {
        compactDisplay: "short",
        notation: "compact",
      }),
    [intl],
  );

  const maxGraphValue = useMemo(
    () => organization.availableSharesForPlanningNow * 1.5,
    [organization.availableSharesForPlanningNow],
  );

  const stacks = useMemo(
    () =>
      [
        {
          elements: [
            {
              className: classNames("bg-gray-09 fill-gray-09"),
              fullyRounded: true,
              key: "pool-baseline",
              value: organization.availableSharesForPlanningNow,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-pool-baseline",
              value: maxGraphValue - organization.availableSharesForPlanningNow,
            },
          ],
          label: "Available",
          shares: organization.availableSharesForPlanningNow,
        },
        {
          elements: [
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "below-new-hires",
              value:
                organization.availableSharesForPlanningNow -
                organization.planningNewHiresTotalShares,
            },
            {
              className: classNames("bg-primary-05 fill-primary-05"),
              fullyRounded: true,
              key: "new-hires",
              value: organization.planningNewHiresTotalShares,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-new-hires",
              value: maxGraphValue - organization.availableSharesForPlanningNow,
            },
          ],
          label: "New hires",
          shares: organization.planningNewHiresTotalShares,
        },
        {
          elements: [
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "below-tenure",
              value:
                organization.availableSharesForPlanningNow -
                organization.planningNewHiresTotalShares -
                organization.planningTenureGrantsTotalShares,
            },
            {
              className: classNames("bg-primary-05 fill-primary-05"),
              fullyRounded: true,
              key: "tenure",
              value: organization.planningTenureGrantsTotalShares,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-tenure",
              value:
                maxGraphValue -
                organization.availableSharesForPlanningNow +
                organization.planningNewHiresTotalShares,
            },
          ],
          label: "Tenure",
          shares: organization.planningTenureGrantsTotalShares,
        },
        {
          elements: [
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "below-promotion",
              value:
                organization.availableSharesForPlanningNow -
                organization.planningNewHiresTotalShares -
                organization.planningTenureGrantsTotalShares -
                organization.planningPromotionGrantsTotalShares,
            },
            {
              className: classNames("bg-primary-05 fill-primary-05"),
              fullyRounded: true,
              key: "promotion",
              value: organization.planningPromotionGrantsTotalShares,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-promotion",
              value:
                maxGraphValue -
                organization.availableSharesForPlanningNow +
                organization.planningNewHiresTotalShares +
                organization.planningTenureGrantsTotalShares,
            },
          ],
          label: "Promotion",
          shares: organization.planningPromotionGrantsTotalShares,
        },
        {
          elements: [
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "below-exceptional",
              value:
                organization.availableSharesForPlanningNow -
                organization.planningNewHiresTotalShares -
                organization.planningTenureGrantsTotalShares -
                organization.planningPromotionGrantsTotalShares -
                organization.planningExceptionalGrantsTotalShares,
            },
            {
              className: classNames("bg-primary-05 fill-primary-05"),
              fullyRounded: true,
              key: "exceptional",
              value: organization.planningExceptionalGrantsTotalShares,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-exceptional",
              value:
                maxGraphValue -
                organization.availableSharesForPlanningNow +
                organization.planningNewHiresTotalShares +
                organization.planningTenureGrantsTotalShares +
                organization.planningPromotionGrantsTotalShares,
            },
          ],
          label: "Exceptional",
          shares: organization.planningExceptionalGrantsTotalShares,
        },
        {
          elements: [
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "below-terminations",
              value:
                organization.planningSharesLeft -
                organization.planningTerminationsTotalShares,
            },
            {
              className: classNames("bg-green-05 fill-green-05"),
              fullyRounded: true,
              key: "terminations",
              value: organization.planningTerminationsTotalShares,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-terminations",
              value: maxGraphValue - organization.planningSharesLeft,
            },
          ],
          label: "Termination",
          shares: organization.planningTerminationsTotalShares,
        },
        {
          elements: [
            {
              className: classNames("bg-gray-09 fill-gray-09"),
              fullyRounded: true,
              key: "shares-left",
              value: organization.planningSharesLeft,
            },
            {
              className: BAR_GRAPH_BACKGROUND_COLOR_CLASS_NAME,
              key: "above-shares-left",
              value: maxGraphValue - organization.planningSharesLeft,
            },
          ],
          label: "Left",
          shares: organization.planningSharesLeft,
        },
      ] as const,
    [
      maxGraphValue,
      organization.availableSharesForPlanningNow,
      organization.planningNewHiresTotalShares,
      organization.planningTenureGrantsTotalShares,
      organization.planningPromotionGrantsTotalShares,
      organization.planningExceptionalGrantsTotalShares,
      organization.planningSharesLeft,
      organization.planningTerminationsTotalShares,
    ],
  );

  return (
    <BarGraph
      barGap={32}
      barRounded={true}
      barSize={80}
      renderTooltip={({ stack }) => {
        return (
          <TooltipContent
            label={stack.label}
            organization={organization}
            shares={stack.shares}
          />
        );
      }}
      stacks={stacks}
      yLabel="# Shares"
      yTickFormatter={yTickFormatter}
    />
  );
};
