import { z } from "zod";

export const FORM_SCHEMA = z.object({
  equityInUsd: z.number().positive().int(),
  name: z.string().trim().min(1),
  role: z.string().trim().nullish(),
  yearlySalary: z.coerce
    .number()
    .nonnegative()
    .int()
    .optional()
    .transform((v) => v || undefined),
});

export type FormValues = z.infer<typeof FORM_SCHEMA>;
