/**
 * @generated SignedSource<<9aea8fa75d7560303409a16570cc8107>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DraftGrantDetailsVestingSchedule_EasopGrant$data = {
  readonly vestingSchedule: {
    readonly name: string;
  };
  readonly vestingStartDate: string;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantVestingScheduleWarningMessage_EasopGrant">;
  readonly " $fragmentType": "DraftGrantDetailsVestingSchedule_EasopGrant";
};
export type DraftGrantDetailsVestingSchedule_EasopGrant$key = {
  readonly " $data"?: DraftGrantDetailsVestingSchedule_EasopGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftGrantDetailsVestingSchedule_EasopGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftGrantDetailsVestingSchedule_EasopGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "vestingSchedule",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DraftGrantVestingScheduleWarningMessage_EasopGrant"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "789c85eeb824d743d10c25422b8da5d1";

export default node;
