/**
 * @generated SignedSource<<fb85a9c8f1bf04de6b16a893ac6d1534>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PriceEvolutionGraph_Organization$data = {
  readonly latestFairMarketValue: {
    readonly value: number;
  } | null;
  readonly latestPricePerShare: {
    readonly value: number;
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"MissingInformationTag_MissingCTMSInformationTag_Organization">;
  readonly " $fragmentType": "PriceEvolutionGraph_Organization";
};
export type PriceEvolutionGraph_Organization$key = {
  readonly " $data"?: PriceEvolutionGraph_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"PriceEvolutionGraph_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PriceEvolutionGraph_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PricePerShare",
      "kind": "LinkedField",
      "name": "latestPricePerShare",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "latestFairMarketValue",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MissingInformationTag_MissingCTMSInformationTag_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "3ebf5a6fadbca3233596a6b9bdcb1c3c";

export default node;
