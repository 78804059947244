import { XMarkIcon } from "@heroicons/react/24/outline";
import { compact } from "lodash";
import { Suspense, useState } from "react";
import { useFragment } from "react-relay";
import { generatePath, Link } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { Button } from "../../../../components/ui/Button";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { AnalyticsContextProvider } from "../../../../hooks/useAnalytics";
import { FeatureFlagProvider } from "../../../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useEquityTypeIdParam,
  useEquityTypeWorkRelationshipCategoryParam,
  useOrganizationIdParam,
} from "../../../../paths";
import { useAuthentication } from "../../../../services/AuthenticationProvider";
import {
  EQUITY_TYPE_WORK_RELATIONSHIP_MAP,
  EquityTypeWorkRelationshipData,
} from "../../../../services/workRelationship";
import NotFoundPage from "../../../NotFound/NotFound";
import { EquityTypeWorkRelationship_EquityType$key } from "./__generated__/EquityTypeWorkRelationship_EquityType.graphql";
import { EquityTypeWorkRelationship_Query } from "./__generated__/EquityTypeWorkRelationship_Query.graphql";
import { EquityTypeWorkRelationship_Viewer$key } from "./__generated__/EquityTypeWorkRelationship_Viewer.graphql";
import EquityTypeWorkRelationshipAssumptions from "./EquityTypeWorkRelationshipAssumptions";
import EquityTypeWorkRelationshipDetailed from "./EquityTypeWorkRelationshipDetailed";
import EquityTypeWorkRelationshipDocuments from "./EquityTypeWorkRelationshipDocuments";
import EquityTypeWorkRelationshipGranteePortal from "./EquityTypeWorkRelationshipGranteePortal";
import EquityTypeWorkRelationshipOverview from "./EquityTypeWorkRelationshipOverview";

const EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationship_EquityType on EquityType {
    ...EquityTypeWorkRelationshipOverview_EquityType
    ...EquityTypeWorkRelationshipDetailed_EquityType
    ...EquityTypeWorkRelationshipGranteePortal_EquityType
    ...EquityTypeWorkRelationshipAssumptions_EquityType
    fullname
    name
    taxResidenceCountry {
      code
      name
      emoji
    }
    awardSuperType
  }
`;

const EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT = graphql`
  fragment EquityTypeWorkRelationship_Viewer on Account {
    isSuperAdmin
    ...EquityTypeWorkRelationshipOverview_Viewer
    ...EquityTypeWorkRelationshipDetailed_Viewer
    ...EquityTypeWorkRelationshipGranteePortal_Viewer
  }
`;

const SUMMARY_TAB_LABEL = "Summary";
const DETAILED_TAB_LABEL = "Reporting, Taxation & Compliance";
const DOCUMENTS_TAB_LABEL = "Documents";
const ASSUMPTIONS_TAB_LABEL = "Assumptions";
const GRANTEE_PORTAL_TAB_LABEL = "Grantee portal";

const EquityTypeWorkRelationship_: React.FC<{
  equityTypeFragment: EquityTypeWorkRelationship_EquityType$key;
  organizationId: string;
  viewerFragment: EquityTypeWorkRelationship_Viewer$key;
  workRelationshipData: EquityTypeWorkRelationshipData;
}> = ({
  equityTypeFragment,
  organizationId,
  viewerFragment,
  workRelationshipData,
}) => {
  const equityType = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_EQUITY_TYPE_FRAGMENT,
    equityTypeFragment,
  );

  const viewer = useFragment(
    EQUITY_TYPE_WORK_RELATIONSHIP_VIEWER_FRAGMENT,
    viewerFragment,
  );

  const menus = compact([
    SUMMARY_TAB_LABEL,
    equityType.awardSuperType !== "SAR" ? DETAILED_TAB_LABEL : null,
    DOCUMENTS_TAB_LABEL,
    ASSUMPTIONS_TAB_LABEL,
    viewer.isSuperAdmin ? GRANTEE_PORTAL_TAB_LABEL : null,
  ]);

  const [activeMenu, setActiveMenu] =
    useState<(typeof menus)[number]>(SUMMARY_TAB_LABEL);

  return (
    <div className="h-full overflow-auto bg-white p-6">
      <RoundedBox
        className="relative m-auto max-w-[960px] p-10"
        withBorder
        withShadow
      >
        <Link
          to={generatePath(APPLICATION_ROUTES["organizationCountry"], {
            countryCode: equityType.taxResidenceCountry.code,
            organizationId,
          })}
        >
          <Typography
            as="div"
            className="absolute right-10 top-10 flex items-center gap-2 px-6 py-3"
            variant="Medium/Small"
          >
            <XMarkIcon className="w-6" />
            Close
          </Typography>
        </Link>
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationCountry"], {
              countryCode: equityType.taxResidenceCountry.code,
              organizationId,
            })}
          >
            {equityType.taxResidenceCountry.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">
            {equityType.fullname ?? equityType.name}
          </BreadCrumb.Link>
        </BreadCrumb>

        <Typography className="mt-6" variant="Title 3">
          {`${workRelationshipData.emoji} ${workRelationshipData.label} in ${equityType.taxResidenceCountry.emoji} ${equityType.taxResidenceCountry.name}`}
        </Typography>

        <div className="mt-4 flex items-center gap-2">
          {menus.map((menu) => (
            <Button
              key={menu}
              onClick={() => setActiveMenu(menu)}
              size="medium"
              variant={
                activeMenu === menu ? "Primary Outline" : "Tertiary Link"
              }
            >
              {menu}
            </Button>
          ))}
        </div>

        <hr className="my-10 border-gray-04" />

        <Suspense>
          {activeMenu === SUMMARY_TAB_LABEL ? (
            <div className="mt-12">
              <EquityTypeWorkRelationshipOverview
                equityTypeFragment={equityType}
                viewerFragment={viewer}
                workRelationshipData={workRelationshipData}
              />
            </div>
          ) : activeMenu === DETAILED_TAB_LABEL ? (
            <div className="mt-12">
              <EquityTypeWorkRelationshipDetailed
                equityTypeFragment={equityType}
                viewerFragment={viewer}
                workRelationshipData={workRelationshipData}
              />
            </div>
          ) : activeMenu === DOCUMENTS_TAB_LABEL ? (
            <EquityTypeWorkRelationshipDocuments />
          ) : activeMenu === ASSUMPTIONS_TAB_LABEL ? (
            <EquityTypeWorkRelationshipAssumptions
              equityTypeFragment={equityType}
            />
          ) : activeMenu === GRANTEE_PORTAL_TAB_LABEL ? (
            <EquityTypeWorkRelationshipGranteePortal
              equityTypeFragment={equityType}
              viewerFragment={viewer}
              workRelationshipData={workRelationshipData}
            />
          ) : (
            <NotFoundPage />
          )}
        </Suspense>
      </RoundedBox>
    </div>
  );
};

const QUERY = graphql`
  query EquityTypeWorkRelationship_Query(
    $equityTypeId: UUID!
    $organizationId: OrganizationId!
  ) {
    organization(id: $organizationId) {
      id
      name
    }
    equityType(id: $equityTypeId) {
      ...EquityTypeWorkRelationship_EquityType
    }
    me {
      ...EquityTypeWorkRelationship_Viewer
    }
  }
`;

const EquityTypeWorkRelationship: React.FC = () => {
  const equityTypeId = useEquityTypeIdParam();
  const organizationId = useOrganizationIdParam();
  const equityTypeWorkRelationshipCategory =
    useEquityTypeWorkRelationshipCategoryParam();

  const {
    query: { equityType, me, organization },
  } = useQuery<EquityTypeWorkRelationship_Query>(QUERY, {
    equityTypeId,
    organizationId,
  });
  const { account } = useAuthentication();

  if (!organization || !equityType) {
    return <NotFoundPage />;
  }

  const equityTypeWorkRelationshipData =
    EQUITY_TYPE_WORK_RELATIONSHIP_MAP[equityTypeWorkRelationshipCategory];

  return (
    <FeatureFlagProvider account={account} organizationName={organization.name}>
      <AnalyticsContextProvider organizationId={organizationId}>
        <Page
          analyticsCategory="Countries"
          analyticsName="Admin - Countries - Equity type"
          organizationId={organization.id}
          title={`Admin | ${organization.name} Countries - Equity type`}
        >
          <EquityTypeWorkRelationship_
            equityTypeFragment={equityType}
            organizationId={organizationId}
            viewerFragment={me}
            workRelationshipData={equityTypeWorkRelationshipData}
          />
        </Page>
      </AnalyticsContextProvider>
    </FeatureFlagProvider>
  );
};

export default EquityTypeWorkRelationship;
