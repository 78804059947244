import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { useQuery } from "../../../hooks/useQuery";
import { useOrganizationIdParam } from "../../../paths";
import { Planning_Query } from "./__generated__/Planning_Query.graphql";
import { PlanningEditableListView } from "./Planning/PlanningEditableListView/PlanningEditableListView";

const QUERY = graphql`
  query Planning_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      name
      id
      ...PlanningEditableListView_Organization
    }
  }
`;

const AdminPlanningPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<Planning_Query>(
    QUERY,
    {
      organizationId,
    },
    "network-only",
  );

  return (
    <Page
      analyticsCategory="Planning"
      analyticsName="Admin - Planning"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} planning`}
    >
      <PlanningEditableListView organizationFragment={query.organization} />
    </Page>
  );
};

export default AdminPlanningPage;
