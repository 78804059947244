/**
 * @generated SignedSource<<278f0d135eb01e0bbf059803b32e571f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteeTermination_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTerminationGrantAmendmentsList_Viewer">;
  readonly " $fragmentType": "GranteeTermination_Viewer";
};
export type GranteeTermination_Viewer$key = {
  readonly " $data"?: GranteeTermination_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteeTermination_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteeTermination_Viewer",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GranteeTerminationGrantAmendmentsList_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "e30c8ea2985df75b46f530398acdd836";

export default node;
