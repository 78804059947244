import {
  BellIcon,
  ClockIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import React from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FreemiumBlockerCard } from "../../../components/FreemiumBlockerCard";
import { Page } from "../../../components/Page";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { LinkButton } from "../../../components/ui/Button";
import { Card } from "../../../components/ui/Card";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import { CountriesFreemium_Organization$key } from "./__generated__/CountriesFreemium_Organization.graphql";
import { CountriesFreemium_Query } from "./__generated__/CountriesFreemium_Query.graphql";
import knowledgeBaseFreemiumIllustration from "./knowledge-base-freemium-illustration.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment CountriesFreemium_Organization on Organization {
    id
    name
    organizationGeographies {
      geography {
        country {
          code
          name
          emoji
        }
      }
    }
    ...FreemiumBlockerCard_Organization
  }
`;

const CountriesFreemiumPage_: React.FC<{
  organizationFragment: CountriesFreemium_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return (
    <CenteredColumnLayout
      breadcrumbs={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to=".">Countries</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <div className="space-y-10">
        <Card size={10} title="Your geographies">
          <Table className="w-full border-b-[0.5px] border-gray-04">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Price per year</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {organization.organizationGeographies.map(({ geography }) => (
                <Table.Row key={geography.country.code}>
                  <Table.Cell>
                    <div className="flex items-center">
                      <Typography className="w-12" variant="Medium/Large">
                        {geography.country.emoji}
                      </Typography>
                      <Typography variant="Medium/Extra Small">
                        {geography.country.name}
                      </Typography>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <Tag color="glass-green" size="Small">
                      Unlocked for free
                    </Tag>
                  </Table.Cell>
                  <Table.Cell className="text-right">
                    <LinkButton
                      size="small"
                      to={generatePath(
                        APPLICATION_ROUTES["organizationCountry"],
                        {
                          countryCode: geography.country.code,
                          organizationId: organization.id,
                        },
                      )}
                    >
                      Discover
                    </LinkButton>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Card>
        <FreemiumBlockerCard
          detailsList={
            <>
              <FreemiumBlockerCard.LabelWithIcon
                icon={<GlobeAmericasIcon />}
                label="Access country-specific insights for smarter planning and decision-making"
              />
              <FreemiumBlockerCard.LabelWithIcon
                icon={<BellIcon />}
                label="Get notifications of new obligations for local filing and reporting"
              />
              <FreemiumBlockerCard.LabelWithIcon
                icon={<ClockIcon />}
                label="Save time with law firms and tighten your budget"
              />
            </>
          }
          mailtoSubject="About the knowledge base"
          organizationFragment={organization}
          rightSideIllustration={
            <div
              className={classNames(
                "bg-gradient-nightfall",
                "relative w-[453px] max-w-[50%] rounded-2xl",
              )}
            >
              <div className="absolute bottom-0 left-6 top-6 w-full overflow-y-hidden">
                <img
                  alt="Knowledge base freemium illustration"
                  src={knowledgeBaseFreemiumIllustration}
                />
              </div>
            </div>
          }
          subTitle="Avoid penalties, fines, and missed deadlines with continuous updates of new regulations and tax laws, generated and monitored by 60+ top law firms"
          title="Stay Compliant in 70+ Countries"
        />
      </div>
    </CenteredColumnLayout>
  );
};

const QUERY = graphql`
  query CountriesFreemium_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...CountriesFreemium_Organization
    }
  }
`;

const CountriesFreemiumPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { query } = useQuery<CountriesFreemium_Query>(QUERY, {
    organizationId,
  });

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Countries freemium"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} countries freemium`}
    >
      <CountriesFreemiumPage_ organizationFragment={query.organization} />
    </Page>
  );
};

export default CountriesFreemiumPage;
