/**
 * @generated SignedSource<<24316c1a9f9917a787eed3171b8f0972>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CTMSSolution = "ANGELLIST" | "ASTRELLA" | "CAPDESK" | "CARTA" | "CERTENT" | "COMPTRACK" | "EQUIFY" | "GLOBAL_SHARES" | "LEDGY" | "LTSE_EQUITY" | "NO_CTMS" | "OTHER" | "PULLEY" | "SHAREWORKS" | "SPREADSHEETS" | "VESTD";
export type ReasonOfContact = "NEED_SUPPORT" | "USING_OTHER_CTMS" | "WANT_TO_KNOW_MORE";
export type ContactUsNotificationAttributes = {
  ctmsSolution: CTMSSolution;
  email: string;
  headquartersLocationCountryCode: string;
  name: string;
  reasonOfContact: ReasonOfContact;
};
export type ContactUsModal_SendContactUsNotification_Mutation$variables = {
  attributes: ContactUsNotificationAttributes;
  organizationId: string;
};
export type ContactUsModal_SendContactUsNotification_Mutation$data = {
  readonly sendContactUsNotification: boolean;
};
export type ContactUsModal_SendContactUsNotification_Mutation = {
  response: ContactUsModal_SendContactUsNotification_Mutation$data;
  variables: ContactUsModal_SendContactUsNotification_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "attributes"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "attributes",
        "variableName": "attributes"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "kind": "ScalarField",
    "name": "sendContactUsNotification",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactUsModal_SendContactUsNotification_Mutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContactUsModal_SendContactUsNotification_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "84ed0f3d504959dffb9eddaf681fad41",
    "id": null,
    "metadata": {},
    "name": "ContactUsModal_SendContactUsNotification_Mutation",
    "operationKind": "mutation",
    "text": "mutation ContactUsModal_SendContactUsNotification_Mutation(\n  $organizationId: OrganizationId!\n  $attributes: ContactUsNotificationAttributes!\n) {\n  sendContactUsNotification(organizationId: $organizationId, attributes: $attributes)\n}\n"
  }
};
})();

(node as any).hash = "5407191db50b836a54c150ea33e391d5";

export default node;
