import { useCallback } from "react";
import { graphql, useFragment } from "react-relay";

import { useTrackRemoteEquityOnboardingFlowEvent_Organization$key } from "./__generated__/useTrackRemoteEquityOnboardingFlowEvent_Organization.graphql";
import { useTrackEvent } from "./useAnalytics";

const ORGANIZATION_FRAGMENT = graphql`
  fragment useTrackRemoteEquityOnboardingFlowEvent_Organization on Organization {
    id
    name
    remoteCompanyId
  }
`;

export const useTrackRemoteEquityOnboardingFlowEvent = ({
  organizationFragment,
}: {
  organizationFragment: useTrackRemoteEquityOnboardingFlowEvent_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const _trackEvent = useTrackEvent();
  return useCallback(
    (eventName: string, additionalContext: object = {}) =>
      _trackEvent(`Remote Equity onboarding flow - ${eventName}`, {
        organizationId: organization.id,
        organizationName: organization.name,
        remoteCompanyId: organization.remoteCompanyId,
        ...additionalContext,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [_trackEvent],
  );
};
