/**
 * @generated SignedSource<<a8422b06295312ae1a870024d0981e0c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MergeEmployeeModal_HRISProviderEmployee$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeTable_HRISProviderEmployee" | "useMergeGrantee_HRISProviderEmployee">;
  readonly " $fragmentType": "MergeEmployeeModal_HRISProviderEmployee";
};
export type MergeEmployeeModal_HRISProviderEmployee$key = {
  readonly " $data"?: MergeEmployeeModal_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"MergeEmployeeModal_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MergeEmployeeModal_HRISProviderEmployee",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useMergeGrantee_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeTable_HRISProviderEmployee"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "77ec9fcb5efeaf0c5f3ae92548a3cf41";

export default node;
