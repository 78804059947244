/**
 * @generated SignedSource<<30dd44d1db726c3f5547c02e6f44a8d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent$data = {
  readonly fairMarketValue: {
    readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue">;
  };
  readonly " $fragmentType": "ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent";
};
export type ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValue",
      "kind": "LinkedField",
      "name": "fairMarketValue",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ApprovalSlideOverFairMarketValueDetailsCard_FairMarketValue"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FairMarketValueBoardConsent",
  "abstractKey": null
};

(node as any).hash = "2ba25cc4157e07d95d48e2595fcdb7b6";

export default node;
