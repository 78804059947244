/**
 * @generated SignedSource<<2b1bba9833fc9427a1e559a98c3a6778>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipOverview_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_Viewer">;
  readonly " $fragmentType": "EquityTypeWorkRelationshipOverview_Viewer";
};
export type EquityTypeWorkRelationshipOverview_Viewer$key = {
  readonly " $data"?: EquityTypeWorkRelationshipOverview_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipOverview_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipOverview_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AdminPortalContent_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "8b1f64fd19143167f2fdd75d38261abe";

export default node;
