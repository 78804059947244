/**
 * @generated SignedSource<<067b17b9c9d7bc53320d2036e20cbb01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent">;
  readonly " $fragmentType": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent";
};
export type ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent$key = {
  readonly " $data"?: ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApprovalDetails_GranteeTerminationGrantAmendmentBoardConsent"
    }
  ],
  "type": "GranteeTerminationGrantAmendmentBoardConsent",
  "abstractKey": null
};

(node as any).hash = "5b4fff306dff7973a3e6dfd0db32fe37";

export default node;
