/**
 * @generated SignedSource<<4e32eff3bebc27158fe05e2c07ad89a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation$variables = {
  organizationId: string;
  tenureGrantsEligibilityVestedRatioThreshold?: number | null;
};
export type SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation$data = {
  readonly updateOrganizationTenureGrantsEligibilityVestedRatioThreshold: {
    readonly " $fragmentSpreads": FragmentRefs<"SetVestingEligibilityThresholdModal_Organization">;
  };
};
export type SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation = {
  response: SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation$data;
  variables: SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenureGrantsEligibilityVestedRatioThreshold"
},
v2 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Variable",
    "name": "tenureGrantsEligibilityVestedRatioThreshold",
    "variableName": "tenureGrantsEligibilityVestedRatioThreshold"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationTenureGrantsEligibilityVestedRatioThreshold",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SetVestingEligibilityThresholdModal_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "updateOrganizationTenureGrantsEligibilityVestedRatioThreshold",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tenureGrantsEligibilityVestedRatioThreshold",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bbe485f8cf6bd7bed923d8c065488de7",
    "id": null,
    "metadata": {},
    "name": "SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation",
    "operationKind": "mutation",
    "text": "mutation SetVestingEligibilityThresholdModal_SetOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation(\n  $tenureGrantsEligibilityVestedRatioThreshold: Float\n  $organizationId: OrganizationId!\n) {\n  updateOrganizationTenureGrantsEligibilityVestedRatioThreshold(tenureGrantsEligibilityVestedRatioThreshold: $tenureGrantsEligibilityVestedRatioThreshold, organizationId: $organizationId) {\n    ...SetVestingEligibilityThresholdModal_Organization\n    id\n  }\n}\n\nfragment SetVestingEligibilityThresholdModal_Organization on Organization {\n  id\n  tenureGrantsEligibilityVestedRatioThreshold\n}\n"
  }
};
})();

(node as any).hash = "3f6d81816f3ddac5483804a06c7c984c";

export default node;
