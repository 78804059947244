import { useCallback } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../hooks/useBoolean";
import { useSafeMutation } from "../hooks/useSafeMutation";
import {
  DisconnectIntegrationButton_Mutation,
  IntegrationProvider,
} from "./__generated__/DisconnectIntegrationButton_Mutation.graphql";
import { DisconnectIntegrationButton_Organization$key } from "./__generated__/DisconnectIntegrationButton_Organization.graphql";
import { useToaster } from "./Toaster";
import { Button } from "./ui/Button";
import { ConfirmationModal } from "./ui/ConfirmationModal";
import { Toast } from "./ui/Toast";

const ORGANIZATION_FRAGMENT = graphql`
  fragment DisconnectIntegrationButton_Organization on Organization {
    id
  }
`;

const MUTATION = graphql`
  mutation DisconnectIntegrationButton_Mutation(
    $organizationId: OrganizationId!
    $provider: IntegrationProvider!
  ) {
    disconnectOrganizationIntegration(
      organizationId: $organizationId
      provider: $provider
    ) {
      ...OrganizationSettingsIntegrations_Organization
    }
  }
`;

const PROVIDER_NAME: Record<IntegrationProvider, string> = {
  CARTA: "Carta",
  DEEL: "Deel",
  REMOTE: "Remote",
};

export const DisconnectIntegrationButton: React.FC<{
  organizationFragment: DisconnectIntegrationButton_Organization$key;
  provider: IntegrationProvider;
}> = ({ organizationFragment, provider }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [_disconnectOrganizationIntegration, mutationIsInFlight] =
    useSafeMutation<DisconnectIntegrationButton_Mutation>(MUTATION);

  const toaster = useToaster();

  const providerName = PROVIDER_NAME[provider];

  const disconnectOrganizationIntegration = useCallback(async () => {
    await _disconnectOrganizationIntegration({
      variables: {
        organizationId: organization.id,
        provider,
      },
    });

    toaster.push(<Toast title="Fine">{providerName} disconnected</Toast>);
  }, [
    _disconnectOrganizationIntegration,
    organization.id,
    provider,
    providerName,
    toaster,
  ]);

  const {
    setFalse: closeConfirmationModal,
    setTrue: showConfirmationModal,
    value: confirmationModalIsShown,
  } = useBoolean(false);

  return (
    <>
      <ConfirmationModal
        confirmationLabel="Disconnect"
        loading={mutationIsInFlight}
        onClose={closeConfirmationModal}
        onConfirmed={disconnectOrganizationIntegration}
        show={confirmationModalIsShown}
        title={`Disconnect from ${providerName}`}
      >
        Are you sure you want to disconnect from {providerName}?
      </ConfirmationModal>
      <Button
        onClick={showConfirmationModal}
        size="extra small"
        variant="Secondary Full"
      >
        Disconnect
      </Button>
    </>
  );
};
