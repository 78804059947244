import React from "react";

export const TwoColumnsGrid: React.FC<{
  children: Array<null | React.ReactElement>;
}> = ({ children }) => {
  return (
    <>
      <div className="hidden grid-cols-2 items-start gap-6 md:grid">
        <div className="space-y-6">
          {children.filter((_, index) => index % 2 === 0).map((child) => child)}
        </div>
        <div className="space-y-6">
          {children.filter((_, index) => index % 2 === 1).map((child) => child)}
        </div>
      </div>
      <div className="space-y-4 md:hidden">{children}</div>
    </>
  );
};
