import { graphql, useFragment } from "react-relay";

import AdminPortalContent from "../pages/Admin/Countries/EquityType/AdminPortalContent";
import { getEquityTypeWorkRelationship } from "../services/workRelationship";
import { GrantRelatedHydratedContent_AdminPortalContent$key } from "./__generated__/GrantRelatedHydratedContent_AdminPortalContent.graphql";
import {
  GrantRelatedHydratedContent_CTMSGrant$data,
  GrantRelatedHydratedContent_CTMSGrant$key,
} from "./__generated__/GrantRelatedHydratedContent_CTMSGrant.graphql";
import { GranteePortalMissingInformationMessage } from "./GranteePortalMissingInformationMessage";
import { LogoWithoutText } from "./ui/Logo/Logo";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const GRANT_FRAGMENT = graphql`
  fragment GrantRelatedHydratedContent_CTMSGrant on CTMSGrant {
    label
    matchingInstrument {
      workRelationship
      taxResidenceCountry {
        name
        emoji
      }
      equityType {
        name
      }
    }
    equityTypeAwardName
  }
`;

const PORTAL_CONTENT_FRAGMENT = graphql`
  fragment GrantRelatedHydratedContent_AdminPortalContent on AdminPortalContent {
    error
    content
    ...AdminPortalContent_AdminPortalContent
  }
`;

type GrantRelatedHydratedContentGrant =
  GrantRelatedHydratedContent_CTMSGrant$data;

export const GrantRelatedHydratedContent: React.FC<{
  adminPortalContentFragment: GrantRelatedHydratedContent_AdminPortalContent$key | null;
  ctmsGrantFragment: GrantRelatedHydratedContent_CTMSGrant$key;
}> = ({ adminPortalContentFragment, ctmsGrantFragment }) => {
  const grant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);
  const adminPortalContent = useFragment(
    PORTAL_CONTENT_FRAGMENT,
    adminPortalContentFragment,
  );

  const getWorkRelationShipLabelFromInstrument = (
    instrument: NonNullable<
      GrantRelatedHydratedContentGrant["matchingInstrument"]
    >,
  ) => {
    const equityTypeWorkRelationshipData = getEquityTypeWorkRelationship(
      instrument.workRelationship,
    );
    return `${equityTypeWorkRelationshipData.emoji} ${equityTypeWorkRelationshipData.label} in ${instrument.taxResidenceCountry.emoji} ${instrument.taxResidenceCountry.name}`;
  };

  const getInstrumentDetailedName = (
    instrument: NonNullable<
      GrantRelatedHydratedContentGrant["matchingInstrument"]
    >,
  ) => {
    return `${instrument.taxResidenceCountry.emoji} ${
      instrument.equityType?.name || ""
    } - 
    ${getEquityTypeWorkRelationship(instrument.workRelationship).label}`;
  };

  if (!grant.matchingInstrument) {
    return (
      <RoundedBox
        className="flex flex-col items-center gap-6 p-10 text-center"
        withBorder
      >
        <LogoWithoutText className="w-10" />
        <Typography variant="Medium/Large">
          We’re so sorry but {grant.equityTypeAwardName} information is not
          available yet.
        </Typography>
        <div className="flex flex-col items-center gap-4">
          <Typography variant="Medium/Default">
            🙈 📊 ⛔️ ➡️ 👩🏽‍💻 🙉 ✅
          </Typography>
          <Typography variant="Regular/Small">
            Please contact your company admin if you want to know more.
          </Typography>
        </div>
      </RoundedBox>
    );
  }

  if (!adminPortalContent?.content) {
    return (
      <RoundedBox
        className="flex flex-col items-center gap-6 p-10 text-center"
        withBorder
      >
        <LogoWithoutText className="w-10" />
        <Typography variant="Medium/Large">
          We’re so sorry but{" "}
          {getInstrumentDetailedName(grant.matchingInstrument)} information is
          coming soon...
        </Typography>
        <div className="flex flex-col items-center gap-4">
          <Typography variant="Medium/Default">👩🏽‍💻 ➡️ ✅</Typography>
          <Typography variant="Regular/Small">
            We’re still crafting the information related to this country and
            equity type.
          </Typography>
        </div>
      </RoundedBox>
    );
  }

  return (
    <>
      {adminPortalContent?.error ===
      "MISSING_INFORMATION_FOR_HYDRATED_CONTENT" ? (
        <GranteePortalMissingInformationMessage />
      ) : null}
      <Typography variant="Title 4">
        {getWorkRelationShipLabelFromInstrument(grant.matchingInstrument)}
      </Typography>
      <AdminPortalContent
        adminPortalContentFragment={adminPortalContent}
        countryName={grant.matchingInstrument.taxResidenceCountry.name}
      />
    </>
  );
};
