import { graphql, useFragment } from "react-relay";

import { ExportSelectedItemsSection_EasopGrants$key } from "./__generated__/ExportSelectedItemsSection_EasopGrants.graphql";
import { ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest$key } from "./__generated__/ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest.graphql";
import { ExportGranteeTerminationGrantsAmendmentsSection } from "./ExportGranteeTerminationGrantsAmendmentsSection";
import { ExportGrantsAmendmentsSection } from "./ExportGrantsAmendmentsSection";
import { ExportGrantsSection } from "./ExportGrantsSection";

const EASOP_GRANTS_FRAGMENT = graphql`
  fragment ExportSelectedItemsSection_EasopGrants on EasopGrant
  @relay(plural: true) {
    ...ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants
  }
`;

const GRANTEE_TERMINATION_CTMS_GRANT_AMENDMENT_REQUESTS_FRAGMENT = graphql`
  fragment ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest
  @relay(plural: true) {
    ...ExportGranteeTerminationGrantsAmendmentsSection_GranteeTerminationCTMSGrantAmendmentRequest
  }
`;

export const ExportSelectedItemsSection: React.FC<{
  ctmsGrantAmendmentRequestsIds: string[];
  easopGrantsFragment: ExportSelectedItemsSection_EasopGrants$key;
  granteeTerminationCTMSGrantAmendmentRequestsFragment: ExportSelectedItemsSection_GranteeTerminationCTMSGrantAmendmentRequest$key;
  onPreviewBoardConsentClick: () => void;
  previewBoardConsentIsInFlight: boolean;
  type:
    | "GRANT"
    | "GRANT_AMENDMENT_REQUEST"
    | "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST";
}> = ({
  ctmsGrantAmendmentRequestsIds,
  easopGrantsFragment,
  granteeTerminationCTMSGrantAmendmentRequestsFragment,
  onPreviewBoardConsentClick,
  previewBoardConsentIsInFlight,
  type,
}) => {
  const easopGrants = useFragment(EASOP_GRANTS_FRAGMENT, easopGrantsFragment);
  const granteeTerminationCTMSGrantAmendmentRequests = useFragment(
    GRANTEE_TERMINATION_CTMS_GRANT_AMENDMENT_REQUESTS_FRAGMENT,
    granteeTerminationCTMSGrantAmendmentRequestsFragment,
  );

  switch (type) {
    case "GRANT":
      return (
        <ExportGrantsSection
          easopGrantsFragment={easopGrants}
          onPreviewBoardConsentClick={onPreviewBoardConsentClick}
          previewBoardConsentIsInFlight={previewBoardConsentIsInFlight}
          type={type}
        />
      );
    case "GRANT_AMENDMENT_REQUEST":
      return (
        <ExportGrantsAmendmentsSection
          ctmsGrantAmendmentRequestIds={ctmsGrantAmendmentRequestsIds}
          onPreviewBoardConsentClick={onPreviewBoardConsentClick}
          previewBoardConsentIsInFlight={previewBoardConsentIsInFlight}
        />
      );
    case "GRANTEE_TERMINATION_GRANT_AMENDMENT_REQUEST":
      return (
        <ExportGranteeTerminationGrantsAmendmentsSection
          grantAmendmentRequestsFragment={
            granteeTerminationCTMSGrantAmendmentRequests
          }
          onPreviewBoardConsentClick={onPreviewBoardConsentClick}
          previewBoardConsentIsInFlight={previewBoardConsentIsInFlight}
        />
      );
  }
};
