/**
 * @generated SignedSource<<d7693f39b09465648cc1ce09bf3e2ff0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$data = {
  readonly name: EquityTypeName;
  readonly " $fragmentType": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType";
};
export type GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$key = {
  readonly " $data"?: GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "9948bf220f49e8a4213b17a328e5def3";

export default node;
