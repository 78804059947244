import classNames from "classnames";
import React from "react";

export const ExerciseRequestTimelineSection: React.FC<{
  active?: boolean;
  children?: React.ReactNode;
  chipContent?: React.ReactElement;
  chipRounded?: boolean;
  className?: string;
}> = ({ active, children, chipContent, chipRounded, className }) => {
  return (
    <div className={classNames("flex gap-6", className)}>
      <div className="flex w-8 flex-col items-center">
        {chipContent && (
          <div
            className={classNames(
              "flex h-8 w-8 shrink-0 items-center justify-center",
              active ? "bg-primary-02 text-primary" : "bg-gray-03 text-gray-07",
              chipRounded ? "rounded-full" : "rounded",
            )}
          >
            {chipContent}
          </div>
        )}
        <div className="w-0.5 flex-grow bg-primary-02"></div>
      </div>
      {children && <div className="flex-1 pb-6">{children}</div>}
    </div>
  );
};
