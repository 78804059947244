/**
 * @generated SignedSource<<515f75cc8114abb884c5bf1d6d32c173>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewEquitySelectionModal_DefaultGrantee$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GrantFormSlide_DefaultGrantee">;
  readonly " $fragmentType": "NewEquitySelectionModal_DefaultGrantee";
};
export type NewEquitySelectionModal_DefaultGrantee$key = {
  readonly " $data"?: NewEquitySelectionModal_DefaultGrantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewEquitySelectionModal_DefaultGrantee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewEquitySelectionModal_DefaultGrantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GrantFormSlide_DefaultGrantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "486ce3e8be7fb0c8b8c67a92de172a6c";

export default node;
