import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { LongDate } from "../../../../components/LongDate";
import { InformationRows } from "../../../../components/ui/InformationRows";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../../../../services/workRelationship";
import { GrantGranteeDetailsRow_Grantee$key } from "./__generated__/GrantGranteeDetailsRow_Grantee.graphql";
import { GrantReviewRow } from "./GrantReviewRow";

const GRANTEE_FRAGMENT = graphql`
  fragment GrantGranteeDetailsRow_Grantee on Grantee {
    workRelationship @required(action: THROW)
    managementCompanyName
    contractStartDate @required(action: THROW)
    taxResidenceCountry @required(action: THROW) {
      emoji
      name
    }
  }
`;

export const GrantGranteeDetailsRow: React.FC<{
  granteeFragment: GrantGranteeDetailsRow_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  return (
    <GrantReviewRow title="Grantee details">
      <InformationRows>
        <InformationRows.Row label="Work relationship">
          {
            WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
              .singularLabel
          }
        </InformationRows.Row>
        {grantee.workRelationship === "ContractorManagementCompany" && (
          <InformationRows.Row label="For legal entity">
            {grantee.managementCompanyName}
          </InformationRows.Row>
        )}
        <InformationRows.Row label="Contract start date">
          <LongDate value={grantee.contractStartDate} />
        </InformationRows.Row>
        <InformationRows.Row label="Country of tax residence">
          {grantee.taxResidenceCountry.emoji} {grantee.taxResidenceCountry.name}
        </InformationRows.Row>
      </InformationRows>
    </GrantReviewRow>
  );
};
