import { generatePath } from "react-router-dom";

import {
  useApplicationName,
  useApplicationSupportEmailAddress,
} from "../hooks/useApplicationTheme";
import { BlueLink } from "../pages/Admin/Grants/Configure/BlueLink";
import { APPLICATION_ROUTES } from "../paths";
import { Typography } from "./ui/Typography";

export const CountriesTableFooterContent: React.FC<{
  organizationId: string;
}> = ({ organizationId }) => {
  const applicationName = useApplicationName();
  const supportEmailAddress = useApplicationSupportEmailAddress();

  return (
    <Typography variant="Regular/Caption">
      Missing a geography? Unlock new countries in order to grant equity in
      those locations with {applicationName}. Unlocking a country involves a 12
      month commitment. For any special requests, you can always{" "}
      <BlueLink to={`mailto:${supportEmailAddress}`}>
        reach out to the {applicationName} team
      </BlueLink>
      . Any information related to pricing and billing can be managed in your{" "}
      <BlueLink
        to={generatePath(APPLICATION_ROUTES["organizationSettingsBilling"], {
          organizationId,
        })}
      >
        billing settings
      </BlueLink>
      .
    </Typography>
  );
};
