/**
 * @generated SignedSource<<399fe4cbb9c29879063797f49c42b02d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalReportingContent_EquityType$data = {
  readonly contractorReportingGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly directEmployeeReportingGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly eoREmployeeReportingGranteePortalContent: {
    readonly " $fragmentSpreads": FragmentRefs<"AdminPortalContent_AdminPortalContent">;
  } | null;
  readonly taxResidenceCountry: {
    readonly name: string;
  };
  readonly " $fragmentType": "GranteePortalReportingContent_EquityType";
};
export type GranteePortalReportingContent_EquityType$key = {
  readonly " $data"?: GranteePortalReportingContent_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteePortalReportingContent_EquityType">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AdminPortalContent_AdminPortalContent"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteePortalReportingContent_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "eoREmployeeReportingGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "directEmployeeReportingGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminPortalContent",
      "kind": "LinkedField",
      "name": "contractorReportingGranteePortalContent",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "4689a19603bffd14f595609898cda584";

export default node;
