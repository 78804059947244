/**
 * @generated SignedSource<<55a870ab14ebc50b6b33c2f1f89d5039>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationGranteesTable_Grantees$data = ReadonlyArray<{
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesTableRow_Grantee">;
  readonly " $fragmentType": "OrganizationGranteesTable_Grantees";
}>;
export type OrganizationGranteesTable_Grantees$key = ReadonlyArray<{
  readonly " $data"?: OrganizationGranteesTable_Grantees$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationGranteesTable_Grantees">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "OrganizationGranteesTable_Grantees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "organizationId",
          "variableName": "organizationId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "GranteesTableRow_Grantee"
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};

(node as any).hash = "6ebcd3f61e8d1be08231aa5ae3108966";

export default node;
