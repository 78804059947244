/**
 * @generated SignedSource<<bd0f25aa0c54e495e2a09614947d5603>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExercisesTaxParametersPage_EquityType$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypesExercisesTaxParametersTable_EquityType">;
  readonly " $fragmentType": "ExercisesTaxParametersPage_EquityType";
}>;
export type ExercisesTaxParametersPage_EquityType$key = ReadonlyArray<{
  readonly " $data"?: ExercisesTaxParametersPage_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExercisesTaxParametersPage_EquityType">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExercisesTaxParametersPage_EquityType",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EquityTypesExercisesTaxParametersTable_EquityType"
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "c38dd3befe4ac98e3671102818ce46d9";

export default node;
