import { useCallback, useMemo } from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FormattedPercentage } from "../../../../components/Formatted/FormattedPercentage";
import { Page } from "../../../../components/Page";
import { RangeInput } from "../../../../components/RangeInput";
import { FormRow } from "../../../../components/ui/Form/FormRow";
import { useOrganizationIdParam } from "../../../../paths";
import NotFoundPage from "../../../NotFound/NotFound";
import { InsightPage } from "../InsightPage";
import { QuantityVested_Organization$key } from "./__generated__/QuantityVested_Organization.graphql";
import { QuantityVested_Query } from "./__generated__/QuantityVested_Query.graphql";
import { QuantityVestedGraph } from "./QuantityVestedGraph";

const ORGANIZATION_FRAGMENT = graphql`
  fragment QuantityVested_Organization on Organization {
    id
    name
    ...QuantityVestedGraph_Organization
  }
`;

const AdminQuantityVestedPage_: React.FC<{
  organizationFragment: QuantityVested_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const [searchParams, setSearchParams] = useSearchParams();

  const setThreshold = useCallback(
    (threshold: number) => {
      setSearchParams(
        { threshold: String(threshold) },
        {
          replace: true,
        },
      );
    },
    [setSearchParams],
  );

  const threshold = useMemo(
    () => Number(searchParams.get("threshold") ?? "80"),
    [searchParams],
  );

  return (
    <InsightPage
      aboveChildren={
        <FormRow label="Define the quantity vested threshold">
          <RangeInput
            id="threshold-range"
            max={90}
            min={10}
            onChange={setThreshold}
            renderStepLabel={({ step }) => (
              <FormattedPercentage value={step / 100} />
            )}
            step={10}
            value={threshold}
          />
        </FormRow>
      }
      subtitle="Split of grantees below or above the defined threshold"
      title="🎱 Quantity vested"
    >
      <QuantityVestedGraph
        organizationFragment={organization}
        threshold={threshold / 100}
      />
    </InsightPage>
  );
};

const QUERY = graphql`
  query QuantityVested_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      name
      id
      ...QuantityVested_Organization
    }
  }
`;

const AdminQuantityVestedPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const { organization } = useLazyLoadQuery<QuantityVested_Query>(QUERY, {
    organizationId,
  });

  if (!organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsCategory="Quantity Vested"
      analyticsName="Admin - Insights - Quantity Vested"
      organizationId={organization.id}
      title={`Admin | ${organization.name} insights | quantity vested`}
    >
      <AdminQuantityVestedPage_ organizationFragment={organization} />
    </Page>
  );
};

export default AdminQuantityVestedPage;
