import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { generatePath, Link } from "react-router-dom";
import { z } from "zod";

import { Button, LinkButton } from "../../components/ui/Button";
import { FormRow } from "../../components/ui/Form/FormRow";
import { Input } from "../../components/ui/Form/Inputs/Input";
import { InfoCard } from "../../components/ui/InfoCard";
import {
  LogoWithoutText,
  RemoteEquityLogo,
} from "../../components/ui/Logo/Logo";
import { PasswordInput } from "../../components/ui/PasswordInput";
import { RemoteIcon } from "../../components/ui/RemoteIcon";
import { Typography } from "../../components/ui/Typography";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { APPLICATION_ROUTES } from "../../paths";
import { AuthenticationAPIClient } from "../../services/AuthenticationAPIClient";
import { Account } from "../../services/AuthenticationProvider";
import CONFIGURATION from "../../services/configuration";

const formSchema = z.object({
  email: z.string().trim().email(),
  password: z.string(),
});

export type FormInputs = z.infer<typeof formSchema>;

const REMOTE_DASHBOARD_EQUITY_URL = CONFIGURATION.REMOTE_DASHBOARD_EQUITY_URL;
if (!REMOTE_DASHBOARD_EQUITY_URL) {
  throw new Error("REMOTE_DASHBOARD_EQUITY_URL is required");
}

const LoginPage: React.FC<{
  onLoginSuccess: (account: Account) => void;
}> = ({ onLoginSuccess }) => {
  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const [failure, setFailure] = useState<
    "ACCOUNT_HAS_NO_PASSWORD" | "EMAIL_NOT_FOUND" | "INVALID_PASSWORD" | null
  >(null);

  const onSubmit = form.handleSubmit(async (_formData) => {
    const formData = _formData as FormInputs;
    setFailure(null);

    const loginResult = await AuthenticationAPIClient.login({
      email: formData.email,
      longLivedSession: true,
      password: formData.password,
    });

    if (loginResult.outcome === "FAILURE") {
      setFailure(loginResult.reason);
    } else {
      onLoginSuccess?.(loginResult.account);
    }
  });

  return (
    <AuthLayout hideSignInButton>
      <div className="flex w-full flex-col gap-8">
        <AuthLayout.Card
          logo={<LogoWithoutText />}
          subtitle="Use your Easop credentials to log in to the platform."
          title="Log in with Easop"
        >
          <form className="space-y-8 text-center" onSubmit={onSubmit}>
            <div className="space-y-4">
              {failure === "EMAIL_NOT_FOUND" && (
                <InfoCard title="Email does not exist." variant="warning">
                  This email does not seem to exist.
                </InfoCard>
              )}
              {(failure === "INVALID_PASSWORD" ||
                failure === "ACCOUNT_HAS_NO_PASSWORD") && (
                <InfoCard title="Invalid password." variant="warning">
                  That&apos;s not the right password.{" "}
                  <Link
                    className="text-primary"
                    to={generatePath(APPLICATION_ROUTES["forgotPassword"], {})}
                  >
                    Forgot your password
                  </Link>
                  ?
                </InfoCard>
              )}
              <FormRow
                error={form.formState.errors.email?.message?.toString()}
                id="email"
                label="Email address"
              >
                <Input
                  autoComplete="email"
                  placeholder="Email"
                  type="email"
                  {...form.register("email")}
                />
              </FormRow>

              <FormRow
                error={form.formState.errors.password?.message?.toString()}
                label="Password"
              >
                <PasswordInput
                  autoComplete="current-password"
                  id="password"
                  placeholder="Password"
                  {...form.register("password")}
                />
              </FormRow>
            </div>
            <div className="space-y-4">
              <Button
                loading={form.formState.isSubmitting}
                remoteLike
                type="submit"
              >
                Log in
              </Button>

              <Typography
                as="div"
                className="text-center text-primary"
                variant="Medium/Extra Small"
              >
                <Link
                  to={generatePath(APPLICATION_ROUTES["forgotPassword"], {})}
                >
                  Forgot your password?
                </Link>
              </Typography>
            </div>
          </form>
        </AuthLayout.Card>
        <AuthLayout.Card
          logo={<RemoteEquityLogo variant="black" />}
          subtitle={
            <div className="m-auto max-w-[348px]">
              Are you a Remote Equity user? Simply log in to Remote.com and
              access your Remote Equity dashboard from the Equity tab.
            </div>
          }
        >
          <div className="flex items-center">
            <LinkButton
              className="m-auto"
              leftIcon={<RemoteIcon className="text-primary" />}
              remoteLike
              to={REMOTE_DASHBOARD_EQUITY_URL}
              variant="Primary Outline"
            >
              Go to Remote
            </LinkButton>
          </div>
        </AuthLayout.Card>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
