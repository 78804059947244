export const PayflowLogo = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 98 24"
    width="98"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_66_7600)">
      <path
        d="M31.3409 14.7239V18.5264H28.9414V6.88584H34.3948C34.9107 6.87357 35.4238 6.9663 35.9027 7.15841C36.3817 7.35052 36.8166 7.638 37.1811 8.00337C37.5455 8.36875 37.8319 8.8044 38.0228 9.28386C38.2137 9.76332 38.3051 10.2766 38.2915 10.7925C38.3098 11.3166 38.2195 11.8389 38.0262 12.3265C37.8328 12.814 37.5408 13.2563 37.1683 13.6256C36.7958 13.9948 36.3509 14.283 35.8617 14.472C35.3725 14.661 34.8494 14.7468 34.3254 14.7239H31.3409ZM31.3409 12.7408H33.8197C34.0875 12.7633 34.3569 12.7304 34.6114 12.6443C34.8659 12.5582 35.1 12.4206 35.299 12.2401C35.498 12.0596 35.6578 11.8401 35.7683 11.5953C35.8789 11.3504 35.9379 11.0854 35.9416 10.8168C35.9454 10.5481 35.8938 10.2816 35.7901 10.0337C35.6865 9.78586 35.5329 9.56199 35.339 9.37603C35.1451 9.19007 34.9149 9.046 34.663 8.95279C34.411 8.85957 34.1425 8.81921 33.8743 8.8342H31.3409V12.7408Z"
        fill="#485864"
      />
      <path
        d="M38.966 14.2529C38.9658 13.0832 39.425 11.9601 40.2447 11.1256C41.0644 10.2911 42.179 9.81181 43.3486 9.79102C44.583 9.79102 45.8671 10.4157 46.3232 11.4221H46.3777L46.462 10.0141H48.5838V18.5264H46.457L46.3727 17.1184H46.3182C45.8224 18.1645 44.5136 18.7545 43.3436 18.7545C42.168 18.7323 41.0487 18.247 40.2289 17.404C39.4092 16.5611 38.9553 15.4287 38.966 14.2529ZM46.1447 14.2529C46.1447 13.6191 45.8929 13.0113 45.4448 12.5632C44.9967 12.1151 44.3889 11.8633 43.7551 11.8633C43.1213 11.8633 42.5135 12.1151 42.0654 12.5632C41.6173 13.0113 41.3655 13.6191 41.3655 14.2529C41.3655 14.8867 41.6173 15.4945 42.0654 15.9426C42.5135 16.3907 43.1213 16.6425 43.7551 16.6425C44.3889 16.6425 44.9967 16.3907 45.4448 15.9426C45.8929 15.4945 46.1447 14.8867 46.1447 14.2529Z"
        fill="#485864"
      />
      <path
        d="M53.3927 17.034L49.7637 9.99414H52.3714L54.6321 14.7039L56.8928 9.99414H59.4955L53.1002 22.3536H50.547L53.3927 17.034Z"
        fill="#485864"
      />
      <path
        d="M64.295 9.36945V9.99412H67.1605V11.8979H64.295V18.5262H61.8955V11.8979H60.4727V9.99412H61.8955V9.16123C61.8955 7.26741 63.3035 5.79002 65.579 5.79002C66.198 5.77742 66.8132 5.88867 67.3886 6.11723L67.0911 8.01105C66.7373 7.84464 66.3517 7.7567 65.9608 7.75326C65.741 7.73191 65.5192 7.75864 65.3108 7.8316C65.1023 7.90456 64.9123 8.02201 64.7538 8.17578C64.5953 8.32955 64.4722 8.51597 64.3929 8.72209C64.3137 8.92822 64.2803 9.14911 64.295 9.36945ZM71.3497 6.01312V18.5262H68.9502V6.01312H71.3497Z"
        fill="#485864"
      />
      <path
        d="M72.8418 14.2529C72.8418 11.7741 74.9984 9.79102 77.6408 9.79102C80.2832 9.79102 82.4349 11.8038 82.4349 14.2529C82.4349 16.702 80.2832 18.7545 77.6408 18.7545C74.9984 18.7545 72.8418 16.7218 72.8418 14.2529ZM80.0353 14.2529C80.0353 13.7781 79.8945 13.314 79.6306 12.9193C79.3667 12.5246 78.9917 12.217 78.5529 12.0356C78.1142 11.8541 77.6315 11.8069 77.1659 11.8999C76.7003 11.9929 76.2728 12.2219 75.9374 12.558C75.602 12.894 75.3739 13.322 75.2818 13.7878C75.1898 14.2536 75.238 14.7362 75.4204 15.1746C75.6028 15.613 75.9111 15.9874 76.3064 16.2504C76.7016 16.5135 77.166 16.6534 77.6408 16.6524C78.2759 16.6498 78.8842 16.396 79.3329 15.9464C79.7815 15.4969 80.034 14.888 80.0353 14.2529Z"
        fill="#485864"
      />
      <path
        d="M82.4844 9.99414H85.0921L87.2636 14.7039L89.5242 9.99414H90.5455L92.8062 14.7039L94.9777 9.99414H97.5705L93.5003 18.5263H92.5087L90.0299 13.4199L87.5511 18.5263H86.5596L82.4844 9.99414Z"
        fill="#485864"
      />
      <path
        d="M3.61324 24H0.683265C0.55178 24 0.42568 23.9478 0.332706 23.8548C0.239732 23.7618 0.1875 23.6357 0.1875 23.5042V0.495765C0.1875 0.36428 0.239732 0.23818 0.332706 0.145206C0.42568 0.0522323 0.55178 0 0.683265 0L11.724 0C16.7312 0 20.6477 3.80748 20.6477 8.67093C20.6477 11.0377 19.7076 13.3075 18.034 14.981C16.3605 16.6545 14.0907 17.5947 11.724 17.5947H7.49012V20.1132C7.52186 20.631 7.44341 21.1497 7.25994 21.635C7.07647 22.1203 6.79216 22.5611 6.42578 22.9285C6.05939 23.2958 5.61927 23.5812 5.13445 23.7659C4.64963 23.9506 4.13115 24.0304 3.61324 24ZM1.17903 23.0233H3.61324C5.40295 23.0233 6.51346 21.9128 6.51346 20.1231V17.1138C6.51346 16.9823 6.5657 16.8562 6.65867 16.7633C6.75165 16.6703 6.87774 16.6181 7.00923 16.6181H11.7289C13.8342 16.6155 15.8528 15.7797 17.3438 14.2934C18.8347 12.8071 19.6768 10.791 19.6859 8.68581C19.6859 4.37265 16.2156 0.99153 11.7537 0.99153H1.17903V23.0233Z"
        fill="#485864"
      />
      <g opacity="0.6">
        <path
          d="M3.61324 24.0006H0.683265C0.55178 24.0006 0.42568 23.9484 0.332706 23.8554C0.239732 23.7624 0.1875 23.6363 0.1875 23.5048V0.496369C0.188815 0.405959 0.21483 0.31763 0.262731 0.24094C0.310632 0.164251 0.378595 0.102122 0.459263 0.0612774C0.539932 0.0204325 0.630235 0.00242699 0.7204 0.00920935C0.810565 0.0159917 0.897158 0.0473037 0.970809 0.0997567L1.76403 0.709548C7.92144 5.48873 12.1454 5.48873 15.5562 5.48873C18.7341 5.48873 19.2893 5.86055 20.1569 6.63394L20.3701 6.82729C20.4573 6.89838 20.5155 6.99888 20.5337 7.10988C20.628 7.62512 20.6761 8.14774 20.6775 8.67154C20.6775 11.0383 19.7373 13.3081 18.0638 14.9816C16.3902 16.6551 14.1204 17.5953 11.7537 17.5953H7.49012V20.1138C7.52186 20.6316 7.44341 21.1503 7.25994 21.6356C7.07647 22.1209 6.79216 22.5617 6.42578 22.9291C6.05939 23.2964 5.61927 23.5818 5.13445 23.7665C4.64963 23.9512 4.13115 24.031 3.61324 24.0006ZM1.17903 23.0239H3.61324C5.40295 23.0239 6.51346 21.9134 6.51346 20.1237V17.1144C6.51346 16.9829 6.5657 16.8568 6.65867 16.7639C6.75165 16.6709 6.87774 16.6187 7.00923 16.6187H11.7289C12.8779 16.6215 14.0138 16.3747 15.058 15.8953C16.1023 15.4159 17.0299 14.7154 17.7767 13.8422C18.5235 12.969 19.0718 11.944 19.3834 10.8381C19.6951 9.73218 19.7628 8.57175 19.5818 7.43708L19.4876 7.3528C18.8382 6.77276 18.4961 6.45547 15.5215 6.45547C7.58928 6.44555 4.02472 3.70397 1.17903 1.4879V23.0239Z"
          fill="#485864"
        />
      </g>
      <g opacity="0.85">
        <path
          d="M3.61324 24.0003H0.683265C0.55178 24.0003 0.42568 23.9481 0.332706 23.8551C0.239732 23.7622 0.1875 23.6361 0.1875 23.5046V6.61881C0.1875 6.48733 0.239732 6.36123 0.332706 6.26825C0.42568 6.17528 0.55178 6.12305 0.683265 6.12305C3.82642 6.12305 5.7252 7.54589 7.738 9.05302C10.1425 10.8576 12.8642 12.9002 18.4961 13.2472C18.585 13.2533 18.6705 13.2832 18.7438 13.3338C18.8171 13.3844 18.8754 13.4538 18.9126 13.5347C18.9481 13.6148 18.9618 13.7029 18.9522 13.79C18.9426 13.8771 18.9101 13.9601 18.858 14.0305C18.031 15.1393 16.956 16.0392 15.7189 16.6581C14.4819 17.2771 13.1171 17.598 11.7339 17.5951H7.49012V20.1135C7.52186 20.6314 7.44341 21.1501 7.25994 21.6353C7.07647 22.1206 6.79216 22.5615 6.42578 22.9288C6.05939 23.2961 5.61927 23.5816 5.13445 23.7663C4.64963 23.951 4.13115 24.0308 3.61324 24.0003ZM1.17903 23.0237H3.61324C5.40295 23.0237 6.51346 21.9132 6.51346 20.1235V17.1142C6.51346 16.9827 6.5657 16.8566 6.65867 16.7636C6.75165 16.6706 6.87774 16.6184 7.00923 16.6184H11.7289C12.8053 16.6202 13.8706 16.4012 14.8589 15.9748C15.8473 15.5485 16.7376 14.9238 17.4748 14.1396C12.1949 13.6141 9.54259 11.6112 7.14804 9.83137C5.3038 8.44819 3.67769 7.24348 1.16912 7.10466L1.17903 23.0237Z"
          fill="#485864"
        />
      </g>
      <g opacity="0.6">
        <path
          d="M3.61324 23.9992H0.683265C0.55178 23.9992 0.42568 23.947 0.332706 23.854C0.239732 23.761 0.1875 23.6349 0.1875 23.5034V15.7199C0.1875 15.5884 0.239732 15.4623 0.332706 15.3694C0.42568 15.2764 0.55178 15.2242 0.683265 15.2242C2.79027 15.2242 5.2096 14.2326 8.00572 13.0924C11.5405 11.6497 15.5413 10.0137 19.9735 10.0137C20.0467 10.0142 20.1189 10.0309 20.1849 10.0626C20.2509 10.0943 20.3091 10.1402 20.3552 10.1971C20.4002 10.2545 20.432 10.3212 20.4483 10.3923C20.4646 10.4635 20.465 10.5373 20.4494 10.6086C19.9969 12.5854 18.89 14.3514 17.308 15.6203C15.726 16.8891 13.7618 17.5863 11.7339 17.5989H7.49012V20.1174C7.52113 20.6348 7.44214 21.1529 7.25834 21.6376C7.07455 22.1223 6.79013 22.5625 6.42382 22.9293C6.05752 23.2961 5.61765 23.5811 5.1332 23.7655C4.64875 23.9499 4.13071 24.0296 3.61324 23.9992ZM1.17903 23.0226H3.61324C5.40295 23.0226 6.51346 21.912 6.51346 20.1223V17.113C6.51346 16.9815 6.5657 16.8554 6.65867 16.7625C6.75165 16.6695 6.87774 16.6173 7.00923 16.6173H11.7289C13.4258 16.6054 15.0751 16.0558 16.4399 15.0474C17.8046 14.039 18.8144 12.6237 19.324 11.0052C15.3579 11.1539 11.6397 12.666 8.36763 14.0046C5.67562 15.1002 3.32569 16.062 1.17903 16.1909V23.0226Z"
          fill="#485864"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_66_7600">
        <rect
          fill="white"
          height="24"
          transform="translate(0.1875)"
          width="97.403"
        />
      </clipPath>
    </defs>
  </svg>
);
