/**
 * @generated SignedSource<<87059252e604231659f79f45a223ee04>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepricingPage_Organization$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"RepricingForm_Organization">;
  readonly " $fragmentType": "RepricingPage_Organization";
}>;
export type RepricingPage_Organization$key = ReadonlyArray<{
  readonly " $data"?: RepricingPage_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"RepricingPage_Organization">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "RepricingPage_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RepricingForm_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "0d6108be9ea71bbe38508880aafd7cbe";

export default node;
