/**
 * @generated SignedSource<<7c26376d1c9c91134ea2d8822f6ed48b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SubscriptionBillingPeriod = "monthly" | "yearly";
import { FragmentRefs } from "relay-runtime";
export type UnlockCountry_Organization$data = {
  readonly id: string;
  readonly name: string;
  readonly subscriptionBillingPeriod: SubscriptionBillingPeriod | null;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetails_Organization" | "LargeCenteredDetailsLayout_Organization">;
  readonly " $fragmentType": "UnlockCountry_Organization";
};
export type UnlockCountry_Organization$key = {
  readonly " $data"?: UnlockCountry_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnlockCountry_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnlockCountry_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionBillingPeriod",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LargeCenteredDetailsLayout_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BillingDetails_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "13059b2b301a89de620277cebbe9e4d5";

export default node;
