/**
 * @generated SignedSource<<5a91337dc4154e3c327f7c4d26fef918>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuspensedInviteGranteesModal_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedInviteGranteesModal_Deferred_Organization">;
  readonly " $fragmentType": "SuspensedInviteGranteesModal_Organization";
};
export type SuspensedInviteGranteesModal_Organization$key = {
  readonly " $data"?: SuspensedInviteGranteesModal_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuspensedInviteGranteesModal_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuspensedInviteGranteesModal_Organization",
  "selections": [
    {
      "kind": "Defer",
      "selections": [
        {
          "args": [
            {
              "kind": "Variable",
              "name": "organizationId",
              "variableName": "organizationId"
            }
          ],
          "kind": "FragmentSpread",
          "name": "SuspensedInviteGranteesModal_Deferred_Organization"
        }
      ]
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "7d4c6c85fa4cfec15c518c81dbfd976b";

export default node;
