/**
 * @generated SignedSource<<a3a416fbfa343bd7268ff4ddc0345ddf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationSettingsSafeCalculatorLayout_Query$variables = {
  organizationId: string;
};
export type OrganizationSettingsSafeCalculatorLayout_Query$data = {
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorLayout_Account">;
  };
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly safeCalculatorVersions: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsSafeCalculatorLayout_Organization">;
  } | null;
};
export type OrganizationSettingsSafeCalculatorLayout_Query = {
  response: OrganizationSettingsSafeCalculatorLayout_Query$data;
  variables: OrganizationSettingsSafeCalculatorLayout_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsSafeCalculatorLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SafeCalculatorVersion",
            "kind": "LinkedField",
            "name": "safeCalculatorVersions",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsSafeCalculatorLayout_Organization"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "OrganizationSettingsSafeCalculatorLayout_Account"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsSafeCalculatorLayout_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SafeCalculatorVersion",
            "kind": "LinkedField",
            "name": "safeCalculatorVersions",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "default",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1772e68bc4e6887e03d3172d0c0aafd3",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsSafeCalculatorLayout_Query",
    "operationKind": "query",
    "text": "query OrganizationSettingsSafeCalculatorLayout_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    safeCalculatorVersions {\n      id\n    }\n    ...OrganizationSettingsSafeCalculatorLayout_Organization\n  }\n  me {\n    ...OrganizationSettingsSafeCalculatorLayout_Account\n    id\n  }\n}\n\nfragment OrganizationSettingsSafeCalculatorLayout_Account on Account {\n  isSuperAdmin\n  ...SafeCalculatorEmptyPlaceholder_Account\n}\n\nfragment OrganizationSettingsSafeCalculatorLayout_Organization on Organization {\n  id\n  name\n  safeCalculatorVersions {\n    id\n    name\n    default\n  }\n  ...SafeCalculatorEmptyPlaceholder_Organization\n}\n\nfragment SafeCalculatorEmptyPlaceholder_Account on Account {\n  isSuperAdmin\n}\n\nfragment SafeCalculatorEmptyPlaceholder_Organization on Organization {\n  id\n}\n"
  }
};
})();

(node as any).hash = "2c7d6dc29ca28bc2ed9db16ec1a428b6";

export default node;
