import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { generatePath, useSearchParams } from "react-router-dom";
import { graphql } from "relay-runtime";

import { APPLICATION_ROUTES } from "../paths";
import { EQUITY_TYPE_WORK_RELATIONSHIP_MAP } from "../services/workRelationship";
import {
  EquityTypesExercisesTaxParametersTable_EquityType$data,
  EquityTypesExercisesTaxParametersTable_EquityType$key,
} from "./__generated__/EquityTypesExercisesTaxParametersTable_EquityType.graphql";
import { CountryFlag } from "./CountryFlag";
import { ShortDate } from "./ShortDate";
import { Table } from "./ui/Table";
import { Tag } from "./ui/Tag";
import { Typography } from "./ui/Typography";

const EQUITY_TYPES_FRAGMENT = graphql`
  fragment EquityTypesExercisesTaxParametersTable_EquityType on EquityType
  @relay(plural: true) {
    id
    name
    taxResidenceCountry {
      name
      ...CountryFlag_Country
    }
    exerciseParameters {
      lastUpdatedAt
      # eslint-disable-next-line relay/unused-fields
      directEmployee {
        taxRanges {
          __typename
        }
        benefits {
          __typename
        }
        watchout
      }
      # eslint-disable-next-line relay/unused-fields
      eoREmployee {
        taxRanges {
          __typename
        }
        benefits {
          __typename
        }
        watchout
      }
      # eslint-disable-next-line relay/unused-fields
      contractor {
        taxRanges {
          __typename
        }
        benefits {
          __typename
        }
        watchout
      }
    }
  }
`;

const CompleteTag: React.FC = () => (
  <Tag color="glass-green" icon={<CheckIcon />} size="Extra Small" />
);

const UncompleteTag: React.FC = () => (
  <Tag color="red" icon={<XMarkIcon />} size="Extra Small" />
);

type EquityType =
  EquityTypesExercisesTaxParametersTable_EquityType$data[number];

const columnHelper = createColumnHelper<EquityType>();

export const EquityTypesExercisesTaxParametersTable: React.FC<{
  equityTypesFragment: EquityTypesExercisesTaxParametersTable_EquityType$key;
}> = ({ equityTypesFragment }) => {
  const equityTypes = useFragment(EQUITY_TYPES_FRAGMENT, equityTypesFragment);

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        (row) => `${row.taxResidenceCountry.name} - ${row.name}`,
        {
          cell: (context) => {
            const equityType = context.row.original;
            return (
              <div className="flex items-center gap-4">
                <Typography variant="Medium/Large">
                  <CountryFlag
                    countryFragment={equityType.taxResidenceCountry}
                  />
                </Typography>
                <Typography variant="Medium/Extra Small">
                  {context.getValue()}
                </Typography>
              </div>
            );
          },
          enableGlobalFilter: true,
          enableSorting: true,
          header: () => "Equity type",
          id: "equityType",
        },
      ),
      ...(["directEmployee", "eoREmployee", "contractor"] as const).map(
        (workRelationship) =>
          columnHelper.accessor(
            (row) =>
              Boolean(
                row.exerciseParameters[workRelationship]?.taxRanges.length,
              ) ||
              Boolean(
                row.exerciseParameters[workRelationship]?.benefits.length,
              ) ||
              Boolean(
                row.exerciseParameters[workRelationship]?.watchout?.length,
              ),
            {
              cell: (context) => {
                const isComplete = context.getValue();
                return isComplete ? <CompleteTag /> : <UncompleteTag />;
              },
              enableGlobalFilter: false,
              enableSorting: false,
              header: () =>
                EQUITY_TYPE_WORK_RELATIONSHIP_MAP[workRelationship].label,
              id: workRelationship,
            },
          ),
      ),
      columnHelper.accessor((row) => row.exerciseParameters.lastUpdatedAt, {
        cell: (context) => {
          const lastEdited = context.getValue();
          if (!lastEdited) {
            return "-";
          }
          return <ShortDate value={lastEdited} />;
        },
        enableGlobalFilter: false,
        enableSorting: false,
        header: () => "Last edited",
        id: "lastEdited",
      }),
    ],
    [],
  );

  const data = useMemo(() => [...equityTypes], [equityTypes]);

  const [searchParams, setSearchParams] = useSearchParams();

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      setSearchParams({ search: value });
    },
    [setSearchParams],
  );

  const table = useReactTable({
    columns,
    data,
    enableGlobalFilter: true,
    enableRowSelection: false,
    enableSortingRemoval: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    globalFilterFn: "includesString",
    initialState: {
      sorting: [{ desc: false, id: "equityType" }],
    },
    onGlobalFilterChange,
    state: {
      globalFilter: searchParams.get("search"),
    },

    // Hack to set width auto when size is not set
    defaultColumn: { size: 0 },
  });

  return (
    <Table.Smart
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES["superAdminExercisesTaxParametersEdition"],
            {
              equityTypeId: rowData.original.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      searchBarPlaceholder="Search equity types..."
      showSearchBar
      table={table}
    />
  );
};
