import { useCallback } from "react";
import { generatePath, Outlet, useNavigate } from "react-router-dom";
import { graphql } from "relay-runtime";

import { FlowLayout } from "../../../components/ui/Layout/FlowLayout";
import { FeatureFlagProvider } from "../../../hooks/useIsFeatureEnabled";
import { useQuery } from "../../../hooks/useQuery";
import {
  APPLICATION_ROUTES,
  useEquityOfferIdParam,
  useOrganizationIdParam,
} from "../../../paths";
import { useAuthentication } from "../../../services/AuthenticationProvider";
import NotFoundPage from "../../NotFound/NotFound";
import { EquityOfferLayout_Query } from "./__generated__/EquityOfferLayout_Query.graphql";
import { EquityOfferAside } from "./EquityOfferAside";
import { EquityOfferContextProvider } from "./EquityOfferContext";

const QUERY = graphql`
  query EquityOfferLayout_Query(
    $organizationId: OrganizationId!
    $equityOfferId: String!
  ) {
    organization(id: $organizationId) {
      name
    }
    equityOffer(equityOfferId: $equityOfferId) {
      ...EquityOfferContext_EquityOffer
      ...EquityOfferAside_EquityOffer
    }
  }
`;

export const EquityOfferLayout: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const equityOfferId = useEquityOfferIdParam();
  const navigate = useNavigate();

  const { query } = useQuery<EquityOfferLayout_Query>(QUERY, {
    equityOfferId,
    organizationId,
  });
  const { account } = useAuthentication();

  const handleExitButtonClick = useCallback(() => {
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationToolsEquityOffersSettings, {
        organizationId,
      }),
    );
  }, [navigate, organizationId]);

  if (!query.organization || !query.equityOffer) {
    return <NotFoundPage />;
  }

  return (
    <FeatureFlagProvider
      account={account}
      organizationName={query.organization.name}
    >
      <EquityOfferContextProvider equityOfferFragment={query.equityOffer}>
        <FlowLayout
          className="mt-20 pt-6"
          onCloseClick={handleExitButtonClick}
          Sidenav={<EquityOfferAside equityOfferFragment={query.equityOffer} />}
        >
          <Outlet />
        </FlowLayout>
      </EquityOfferContextProvider>
    </FeatureFlagProvider>
  );
};
