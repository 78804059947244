/**
 * @generated SignedSource<<4ab7c119688099a0a7636d694abf7c3e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityTypeWorkRelationshipDocumentSet_EquityType$data = {
  readonly taxResidenceCountry: {
    readonly name: string;
  };
  readonly " $fragmentType": "EquityTypeWorkRelationshipDocumentSet_EquityType";
};
export type EquityTypeWorkRelationshipDocumentSet_EquityType$key = {
  readonly " $data"?: EquityTypeWorkRelationshipDocumentSet_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypeWorkRelationshipDocumentSet_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityTypeWorkRelationshipDocumentSet_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "04204bbb0462267732bb90c9491d6b05";

export default node;
