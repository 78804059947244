/**
 * @generated SignedSource<<f61666433fb78f1b56e9a19aca941f1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExportSelectedItemsSection_EasopGrants$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants">;
  readonly " $fragmentType": "ExportSelectedItemsSection_EasopGrants";
}>;
export type ExportSelectedItemsSection_EasopGrants$key = ReadonlyArray<{
  readonly " $data"?: ExportSelectedItemsSection_EasopGrants$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExportSelectedItemsSection_EasopGrants">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ExportSelectedItemsSection_EasopGrants",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ExportGrantsSection_ConfirmSubmitBoardConsentSlide_EasopGrants"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "266a89b8047cec9e2acc87d61ceb18f7";

export default node;
