/**
 * @generated SignedSource<<da149bfd47ccfdfa8fed89c29f6a9dc8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SuperAdminLayout_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBar_Viewer">;
  readonly " $fragmentType": "SuperAdminLayout_Viewer";
};
export type SuperAdminLayout_Viewer$key = {
  readonly " $data"?: SuperAdminLayout_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SuperAdminLayout_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SuperAdminLayout_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ApplicationSideBar_Viewer"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "dabee45d0fd0834557498ca3e29297bc";

export default node;
