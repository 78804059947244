/**
 * @generated SignedSource<<93062267b050716e870e1979a7775a85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewVestingStartDateSlider_CTMSGrant$data = {
  readonly id: string;
  readonly vestingStartDate: string | null;
  readonly " $fragmentType": "NewVestingStartDateSlider_CTMSGrant";
};
export type NewVestingStartDateSlider_CTMSGrant$key = {
  readonly " $data"?: NewVestingStartDateSlider_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewVestingStartDateSlider_CTMSGrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewVestingStartDateSlider_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vestingStartDate",
      "storageKey": null
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};

(node as any).hash = "09a07c664f8c2256bb4adc59f2bf8f02";

export default node;
