import { chain, isNull } from "lodash";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export type NonEmptyArray<T> = [T, ...T[]];

export type NonNullableProperties<T, K extends keyof T> = T & {
  [P in K]-?: NonNullable<T[P]>;
};

export type WithDeepUndefined<T> = T extends object
  ? {
      [P in keyof T]: T[P] | undefined;
    }
  : T;

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export function checkPropertiesNotNull<T, K extends keyof T>(
  obj: T,
  ...keys: K[]
): obj is NonNullableProperties<T, K> {
  return !keys.some((key) => isNull(obj[key]));
}

export function filterByPropertiesNotNull<T, K extends keyof T>(
  arr: Array<T> | ReadonlyArray<T>,
  ...keys: K[]
): Array<NonNullableProperties<T, K>> {
  return chain(arr)
    .map((obj) => (checkPropertiesNotNull(obj, ...keys) ? obj : null))
    .compact()
    .value();
}

export function isNonEmptyArray<A>(
  arr: Array<A> | ReadonlyArray<A>,
): arr is NonEmptyArray<A> {
  return arr.length > 0;
}
