import classNames from "classnames";

type Size = "medium" | "small";

const Sizes = {
  medium: {
    checked: classNames("h-5.5 w-5.5 border-4"),
    unchecked: classNames("h-5.5 w-5.5 border"),
  },
  small: {
    checked: classNames("h-3.5 w-3.5 border-2"),
    unchecked: classNames("h-3.5 w-3.5 border-[0.5px]"),
  },
} as const;

export const Radio: React.FC<{
  checked: boolean;
  className?: string;
  size?: Size;
}> = ({ checked, className, size = "medium" }) => {
  return (
    <div
      className={classNames(
        "box-border rounded-full transition-all",
        {
          "border-gray-09": !checked,
          "border-primary": checked,
          [Sizes[size].checked]: checked,
          [Sizes[size].unchecked]: !checked,
        },
        className,
      )}
    />
  );
};
