/**
 * @generated SignedSource<<543ba9cfc3c451c0fe5a55a8816b15c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization$data = {
  readonly boardMembers: ReadonlyArray<{
    readonly account: {
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly id: string;
  }>;
  readonly id: string;
  readonly " $fragmentType": "BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization";
};
export type BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization$key = {
  readonly " $data"?: BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BoardMembersSection_ConfirmSubmitBoardConsentSlide_Organization",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BoardMember",
      "kind": "LinkedField",
      "name": "boardMembers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Account",
          "kind": "LinkedField",
          "name": "account",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};
})();

(node as any).hash = "b5a0f9d290035432617122abd6236a54";

export default node;
