import { round } from "lodash";
import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationSharesUtil_Organization$key } from "./__generated__/useOrganizationSharesUtil_Organization.graphql";

export function convertFullyDilutedRatioToShares({
  fullyDilutedRatio,
  fullyDilutedShares,
}: {
  fullyDilutedRatio: number;
  fullyDilutedShares: number;
}) {
  return Math.round(fullyDilutedRatio * fullyDilutedShares);
}

export function convertFullyDilutedRatioToValue({
  fullyDilutedRatio,
  fullyDilutedShares,
  latestPricePerShare,
}: {
  fullyDilutedRatio: number;
  fullyDilutedShares: number;
  latestPricePerShare: number;
}) {
  return convertSharesToValue({
    latestPricePerShare: latestPricePerShare,
    shares: convertFullyDilutedRatioToShares({
      fullyDilutedRatio,
      fullyDilutedShares,
    }),
  });
}

export function convertSharesToFullyDilutedRatio({
  fullyDilutedShares,
  shares,
}: {
  fullyDilutedShares: number;
  shares: number;
}) {
  return shares / fullyDilutedShares;
}

export function convertSharesToValue({
  latestPricePerShare,
  shares,
}: {
  latestPricePerShare: number;
  shares: number;
}) {
  return round(shares * latestPricePerShare, 4);
}

export function convertValueToFullyDilutedRatio({
  fullyDilutedShares,
  latestPricePerShare,
  value,
}: {
  fullyDilutedShares: number;
  latestPricePerShare: number;
  value: number;
}) {
  return convertSharesToFullyDilutedRatio({
    fullyDilutedShares,
    shares: convertValueToShares({
      latestPricePerShare,
      value,
    }),
  });
}

export function convertValueToShares({
  latestPricePerShare,
  value,
}: {
  latestPricePerShare: number;
  value: number;
}) {
  return round(value / latestPricePerShare);
}

export const useSharesUtil = ({
  fullyDilutedShares,
  latestPricePerShare,
}: {
  fullyDilutedShares: null | number;
  latestPricePerShare: null | number;
}) => {
  const sharesToFullyDilutedRatio = useCallback(
    (shares: number) =>
      fullyDilutedShares !== null
        ? convertSharesToFullyDilutedRatio({ fullyDilutedShares, shares })
        : null,
    [fullyDilutedShares],
  );
  const sharesToValue = useCallback(
    (shares: number) =>
      latestPricePerShare !== null
        ? convertSharesToValue({ latestPricePerShare, shares })
        : null,
    [latestPricePerShare],
  );
  const valueToShares = useCallback(
    (value: number) =>
      latestPricePerShare !== null
        ? convertValueToShares({ latestPricePerShare, value })
        : null,
    [latestPricePerShare],
  );
  const valueToFullyDilutedRatio = useCallback(
    (value: number) =>
      fullyDilutedShares !== null && latestPricePerShare !== null
        ? convertValueToFullyDilutedRatio({
            fullyDilutedShares,
            latestPricePerShare,
            value,
          })
        : null,
    [fullyDilutedShares, latestPricePerShare],
  );
  const fullyDilutedRatioToShares = useCallback(
    (fullyDilutedRatio: number) =>
      fullyDilutedShares !== null
        ? convertFullyDilutedRatioToShares({
            fullyDilutedRatio,
            fullyDilutedShares,
          })
        : null,
    [fullyDilutedShares],
  );
  const fullyDilutedRatioToValue = useCallback(
    (fullyDilutedRatio: number) =>
      fullyDilutedShares !== null && latestPricePerShare !== null
        ? convertFullyDilutedRatioToValue({
            fullyDilutedRatio,
            fullyDilutedShares,
            latestPricePerShare,
          })
        : null,
    [fullyDilutedShares, latestPricePerShare],
  );

  return useMemo(
    () => ({
      fullyDilutedRatioToShares,
      fullyDilutedRatioToValue,
      sharesToFullyDilutedRatio,
      sharesToValue,
      valueToFullyDilutedRatio,
      valueToShares,
    }),
    [
      fullyDilutedRatioToShares,
      fullyDilutedRatioToValue,
      sharesToFullyDilutedRatio,
      sharesToValue,
      valueToFullyDilutedRatio,
      valueToShares,
    ],
  );
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment useOrganizationSharesUtil_Organization on Organization {
    latestPricePerShare {
      value
    }
    fullyDilutedShares
  }
`;

export const useOrganizationSharesUtil = ({
  organizationFragment,
}: {
  organizationFragment: useOrganizationSharesUtil_Organization$key;
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  return useSharesUtil({
    fullyDilutedShares: organization.fullyDilutedShares,
    latestPricePerShare: organization.latestPricePerShare
      ? organization.latestPricePerShare.value
      : null,
  });
};
