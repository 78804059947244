/**
 * @generated SignedSource<<33357341a82709da320d9c698b41c32e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EasopGrantStatus = "Active" | "Draft" | "PendingBoardConsent" | "PendingCtmsImplementation" | "PendingReview" | "Reviewed";
export type RequestChangesOnEasopGrantsButton_Mutation$variables = {
  easopGrantsIds: ReadonlyArray<string>;
};
export type RequestChangesOnEasopGrantsButton_Mutation$data = {
  readonly requestChangesOnEasopGrants: ReadonlyArray<{
    readonly grantStatus: EasopGrantStatus;
  }>;
};
export type RequestChangesOnEasopGrantsButton_Mutation = {
  response: RequestChangesOnEasopGrantsButton_Mutation$data;
  variables: RequestChangesOnEasopGrantsButton_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "easopGrantsIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "easopGrantsIds",
    "variableName": "easopGrantsIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RequestChangesOnEasopGrantsButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "requestChangesOnEasopGrants",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RequestChangesOnEasopGrantsButton_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "requestChangesOnEasopGrants",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b97aea4e9cb636267834be7f3daf8c8",
    "id": null,
    "metadata": {},
    "name": "RequestChangesOnEasopGrantsButton_Mutation",
    "operationKind": "mutation",
    "text": "mutation RequestChangesOnEasopGrantsButton_Mutation(\n  $easopGrantsIds: [UUID!]!\n) {\n  requestChangesOnEasopGrants(easopGrantsIds: $easopGrantsIds) {\n    grantStatus\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "5685dbab7c87bc983acfc171ad2c4cd3";

export default node;
