import { useCallback } from "react";
import { graphql } from "relay-runtime";

import { useToaster } from "../components/Toaster";
import { Toast } from "../components/ui/Toast";
import { useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation } from "./__generated__/useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation.graphql";
import { useSafeMutation } from "./useSafeMutation";

const RESEND_INVITATION_EMAIL_MUTATION = graphql`
  mutation useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation(
    $granteeId: GranteeId!
  ) {
    resendInvitationEmailToGrantee(granteeId: $granteeId) {
      hasPortalAccess
      hasVisitedTheirPortal
      hasBeenInvitedMoreThanAWeekAgoButDidNotVisitTheirPortal
    }
  }
`;

export const useResendGranteeInvitationEmail = () => {
  const toaster = useToaster();

  const [
    _resendInvitationEmailMutation,
    resendInvitationEmailMutationIsInFlight,
  ] =
    useSafeMutation<useResendGranteeInvitationEmail_ResendInvitationEmail_Mutation>(
      RESEND_INVITATION_EMAIL_MUTATION,
    );

  const resendInvitationEmailMutation = useCallback(
    async (grantee: { id: string; name: string }) => {
      if (resendInvitationEmailMutationIsInFlight) return;

      await _resendInvitationEmailMutation({
        variables: {
          granteeId: grantee.id,
        },
      });

      toaster.push(
        <Toast title="Nice!">
          Invitation email has been resent to {grantee.name}!
        </Toast>,
      );
    },
    [
      _resendInvitationEmailMutation,
      resendInvitationEmailMutationIsInFlight,
      toaster,
    ],
  );

  return {
    resendInvitationEmail: resendInvitationEmailMutation,
    resendInvitationEmailIsInFlight: resendInvitationEmailMutationIsInFlight,
  };
};
