/**
 * @generated SignedSource<<1393921535caa3406b13ea531449df39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TeamIncentivizationGraph_TeamIncentivizationDataPoint$data = ReadonlyArray<{
  readonly grantees: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
  readonly grantsCount: number;
  readonly valueInUSD: number;
  readonly " $fragmentType": "TeamIncentivizationGraph_TeamIncentivizationDataPoint";
}>;
export type TeamIncentivizationGraph_TeamIncentivizationDataPoint$key = ReadonlyArray<{
  readonly " $data"?: TeamIncentivizationGraph_TeamIncentivizationDataPoint$data;
  readonly " $fragmentSpreads": FragmentRefs<"TeamIncentivizationGraph_TeamIncentivizationDataPoint">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "TeamIncentivizationGraph_TeamIncentivizationDataPoint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantees",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grantsCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "valueInUSD",
      "storageKey": null
    }
  ],
  "type": "TeamIncentivizationDataPoint",
  "abstractKey": null
};

(node as any).hash = "8ac474e15a67b5647bebe4f5229ff103";

export default node;
