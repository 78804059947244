/**
 * @generated SignedSource<<8c6505d8871c88f3f763c90d0d077281>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteePortalFreemium_Query$variables = {
  organizationId: string;
};
export type GranteePortalFreemium_Query$data = {
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GranteePortalFreemium_Organization">;
  } | null;
};
export type GranteePortalFreemium_Query = {
  response: GranteePortalFreemium_Query$data;
  variables: GranteePortalFreemium_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GranteePortalFreemium_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GranteePortalFreemium_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GranteePortalFreemium_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "GranteesConnection",
            "kind": "LinkedField",
            "name": "grantees",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Grantee",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "workRelationship",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Country",
                        "kind": "LinkedField",
                        "name": "taxResidenceCountry",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": "activeCTMSGrants",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "grantStatusIn",
                            "value": [
                              "Active"
                            ]
                          }
                        ],
                        "concreteType": "CTMSGrant",
                        "kind": "LinkedField",
                        "name": "ctmsGrants",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": "ctmsGrants(grantStatusIn:[\"Active\"])"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "freemiumPortalPreviewGrantee",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "21244d2bec587b4dd987627e11c2a8c5",
    "id": null,
    "metadata": {},
    "name": "GranteePortalFreemium_Query",
    "operationKind": "query",
    "text": "query GranteePortalFreemium_Query(\n  $organizationId: OrganizationId!\n) {\n  organization(id: $organizationId) {\n    id\n    name\n    ...GranteePortalFreemium_Organization\n  }\n}\n\nfragment FreemiumBlockerCard_Organization on Organization {\n  name\n}\n\nfragment GranteePortalFreemium_Organization on Organization {\n  id\n  name\n  grantees {\n    totalCount\n  }\n  freemiumPortalPreviewGrantee {\n    id\n  }\n  ...FreemiumBlockerCard_Organization\n  ...PreviewGranteePortalFreemiumModal_Organization\n}\n\nfragment PreviewGranteePortalFreemiumModal_Organization on Organization {\n  id\n  grantees {\n    edges {\n      node {\n        id\n        name\n        workRelationship\n        taxResidenceCountry {\n          code\n        }\n        activeCTMSGrants: ctmsGrants(grantStatusIn: [Active]) {\n          __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "261616cd969d073719e058e27cc34917";

export default node;
