import { IntlErrorCode } from "@formatjs/intl";
import { GlobalStyles, themeV2 } from "@remote-com/norma";
import "@remote-com/norma/styles.css";
import * as Sentry from "@sentry/react";
import React, { StrictMode, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";

import { AlerterProvider } from "./components/Alerter";
import { LoadingPlaceholder } from "./components/LoadingPlaceholder";
import { ToasterProvider } from "./components/Toaster";
import {
  ConfigCatProvider,
  FeatureFlagProvider,
} from "./hooks/useIsFeatureEnabled";
import Router from "./Router";
import CONFIGURATION from "./services/configuration";
import { PostHogProvider } from "./services/PostHogProvider";
import { RelayProvider } from "./services/RelayProvider";
import { RelayStoreContextProvider } from "./services/RelayStoreContextProvider";

Sentry.init({
  dsn: CONFIGURATION.SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV7BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be 50%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,

  enabled: CONFIGURATION.ENVIRONMENT !== "local",
  environment: CONFIGURATION.ENVIRONMENT,
  tunnel: "/server/bugs",
});

const CONFIG_CAT_SDK_KEY = CONFIGURATION.CONFIG_CAT_SDK_KEY;
if (!CONFIG_CAT_SDK_KEY) {
  throw new Error("CONFIG_CAT_SDK_KEY is required");
}

const App: React.FC = () => (
  <StrictMode>
    <Sentry.ErrorBoundary showDialog>
      <ThemeProvider theme={themeV2}>
        <GlobalStyles />
        <PostHogProvider>
          <ConfigCatProvider configCatSDKKey={CONFIG_CAT_SDK_KEY}>
            <FeatureFlagProvider>
              <IntlProvider
                locale="en-US"
                onError={(error) => {
                  if (error.code === IntlErrorCode.MISSING_TRANSLATION) {
                    return;
                  }
                  // eslint-disable-next-line no-console
                  console.error(error);
                }}
              >
                <HelmetProvider>
                  <RelayStoreContextProvider>
                    <RelayProvider>
                      <ToasterProvider>
                        <AlerterProvider>
                          <Helmet>
                            <title>Easop</title>
                            <link
                              href={
                                CONFIGURATION.ENVIRONMENT === "production"
                                  ? "/favicon.ico"
                                  : "/favicon-development.ico"
                              }
                              id="favicon"
                              rel="icon"
                              type="image/x-icon"
                            />
                          </Helmet>
                          <Suspense fallback={<LoadingPlaceholder />}>
                            <Router />
                          </Suspense>
                        </AlerterProvider>
                      </ToasterProvider>
                    </RelayProvider>
                  </RelayStoreContextProvider>
                </HelmetProvider>
              </IntlProvider>
            </FeatureFlagProvider>
          </ConfigCatProvider>
        </PostHogProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>
);

export default App;
