/**
 * @generated SignedSource<<0b0f74b7b28b237beb6be79f8ade5686>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DocumentsTable_Documents$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useGrantDocuments_EasopGrant">;
  readonly " $fragmentType": "DocumentsTable_Documents";
};
export type DocumentsTable_Documents$key = {
  readonly " $data"?: DocumentsTable_Documents$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsTable_Documents">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DocumentsTable_Documents",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useGrantDocuments_EasopGrant"
    }
  ],
  "type": "EasopGrant",
  "abstractKey": null
};

(node as any).hash = "81ab048f387aefa42139dfa0524fe3e7";

export default node;
