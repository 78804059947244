import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useDisplayFullyDilutedValuesInGranteePortal } from "../hooks/useDisplayFullyDilutedValuesInGranteePortal";
import { useOrganizationSharesUtil } from "../hooks/useOrganizationSharesUtil";
import {
  GRANTEE_PORTAL_EQUITY_TYPES_GROUPS,
  useEquityTypesGroupParam,
} from "../paths";
import { getEquityTypeWorkRelationship } from "../services/workRelationship";
import {
  GranteePortalOverviewHeader_Grantee$data,
  GranteePortalOverviewHeader_Grantee$key,
} from "./__generated__/GranteePortalOverviewHeader_Grantee.graphql";
import { LabelValue } from "./ui/LabelValue";
import { Typography } from "./ui/Typography";

const GRANTEE_FRAGMENT = graphql`
  fragment GranteePortalOverviewHeader_Grantee on Grantee {
    name
    email
    taxResidenceCountry {
      emoji
      name
    }
    workRelationship
    jobTitle
    organization {
      name
      ...useOrganizationSharesUtil_Organization
    }
    totalGrantedSharesBreakdown {
      real
      virtual
    }
    ...useDisplayFullyDilutedValuesInGranteePortal_Grantee
  }
`;

const useShares = ({
  grantee,
}: {
  grantee: GranteePortalOverviewHeader_Grantee$data;
}) => {
  const equityTypesGroup = useEquityTypesGroupParam();
  switch (equityTypesGroup) {
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
      return grantee.totalGrantedSharesBreakdown.real;
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
      return grantee.totalGrantedSharesBreakdown.virtual;
  }
};

const Shares: React.FC<{
  shares: number;
}> = ({ shares }) => {
  const equityTypesGroup = useEquityTypesGroupParam();
  switch (equityTypesGroup) {
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.STOCK_OPTIONS:
      return (
        <>
          <FormattedNumber value={shares} /> shares
        </>
      );
    case GRANTEE_PORTAL_EQUITY_TYPES_GROUPS.VIRTUAL_STOCK_OPTIONS:
      return (
        <>
          <FormattedNumber value={shares} /> virtual shares
        </>
      );
  }
};

export const GranteePortalOverviewHeader: React.FC<{
  granteeFragment: GranteePortalOverviewHeader_Grantee$key;
}> = ({ granteeFragment }) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const workRelationship = grantee.workRelationship
    ? getEquityTypeWorkRelationship(grantee.workRelationship)
    : null;
  const shares = useShares({ grantee });
  const { sharesToValue } = useOrganizationSharesUtil({
    organizationFragment: grantee.organization,
  });

  const displayFullyDilutedValues = useDisplayFullyDilutedValuesInGranteePortal(
    { granteeFragment: grantee },
  );

  const valueInUSD = sharesToValue(shares);

  return (
    <div className="space-y-4">
      <Typography variant="Medium/Extra Large">
        Hello {grantee.name}!
      </Typography>

      <Typography as="div" variant="Medium/Default">
        You’ve received some equity from {grantee.organization.name} 🔥 🙏 🚀 🎸
        🤘
      </Typography>

      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <LabelValue label="Total shares received:">
          <Shares shares={shares} />
        </LabelValue>

        {displayFullyDilutedValues && valueInUSD !== null && (
          <LabelValue label="Current potential value:">
            <FormattedNumber
              currency="USD"
              maximumFractionDigits={0}
              style="currency"
              value={valueInUSD}
            />
          </LabelValue>
        )}

        {grantee.email ? (
          <LabelValue label="Email:">
            <div className="truncate">{grantee.email}</div>
          </LabelValue>
        ) : null}

        {grantee.taxResidenceCountry ? (
          <LabelValue label="Country of residence:">
            {grantee.taxResidenceCountry.emoji}{" "}
            {grantee.taxResidenceCountry.name}
          </LabelValue>
        ) : null}

        {workRelationship ? (
          <LabelValue label="Work relationship:">
            {workRelationship.label}
          </LabelValue>
        ) : null}

        {grantee.jobTitle ? (
          <LabelValue label="Job title:">{grantee.jobTitle}</LabelValue>
        ) : null}
      </div>
    </div>
  );
};
