import ErrorPage from "./Error/Error";

const RemoteAdminNotAllowedPage: React.FC = () => {
  return (
    <ErrorPage
      subtitle={
        <>
          You do not have admin right for your organization on Remote Equity.
          <br />
          <br />
          Please contact your Remote Equity admins to have your access granted.
        </>
      }
      title="Access denied"
    />
  );
};

export default RemoteAdminNotAllowedPage;
