/**
 * @generated SignedSource<<ba5bd15e0000015ced222891487cdfc5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription$variables = {
  boardConsentsIds: ReadonlyArray<string>;
};
export type PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription$data = {
  readonly boardConsentsUpdates: {
    readonly " $fragmentSpreads": FragmentRefs<"PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationGrantAmendmentBoardConsent">;
  };
};
export type PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription = {
  response: PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription$data;
  variables: PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardConsentsIds"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "boardConsentsIds"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "boardConsentsUpdates",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationGrantAmendmentBoardConsent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "boardConsentsUpdates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voidedReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isPandaDocDocumentSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canBoardMemberSignatureRequestReminderEmailsBeSent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SignatureRequest",
                "kind": "LinkedField",
                "name": "signatureRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryFirstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "signatoryLastName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "GranteeTerminationCTMSGrantAmendmentRequest",
                "kind": "LinkedField",
                "name": "granteeTerminationCTMSGrantAmendmentRequests",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeletable",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CTMSGrant",
                    "kind": "LinkedField",
                    "name": "grant",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Grantee",
                        "kind": "LinkedField",
                        "name": "grantee",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "workRelationship",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Country",
                            "kind": "LinkedField",
                            "name": "taxResidenceCountry",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isBoardConsent"
              }
            ],
            "type": "GranteeTerminationGrantAmendmentBoardConsent",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6eba0ee79471c139ab0b63aece4f446d",
    "id": null,
    "metadata": {},
    "name": "PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription",
    "operationKind": "subscription",
    "text": "subscription PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_BoardConsentsUpdatesSubscription(\n  $boardConsentsIds: [UUID!]!\n) {\n  boardConsentsUpdates(ids: $boardConsentsIds) {\n    __typename\n    ...PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationGrantAmendmentBoardConsent\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment GranteeNameWithCountryFlag_Grantee on Grantee {\n  name\n  taxResidenceCountry {\n    ...CountryFlag_Country\n  }\n}\n\nfragment GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest on GranteeTerminationCTMSGrantAmendmentRequest {\n  id\n  status\n  isDeletable\n  grant {\n    id\n    label\n    grantee {\n      id\n      name\n      workRelationship\n      ...GranteeNameWithCountryFlag_Grantee\n    }\n  }\n}\n\nfragment PendingApproval_GranteeTerminationCTMSGrantAmendmentRequests_GranteeTerminationGrantAmendmentBoardConsent on GranteeTerminationGrantAmendmentBoardConsent {\n  id\n  voidedReason\n  isPandaDocDocumentSent\n  canBoardMemberSignatureRequestReminderEmailsBeSent\n  signatureRequests {\n    id\n    completedAt\n    signatoryFirstName\n    signatoryLastName\n  }\n  granteeTerminationCTMSGrantAmendmentRequests {\n    ...GranteeTerminationAmendmentRequestsTable_GranteeTerminationCTMSGrantAmendmentRequest\n    id\n  }\n  ...SendReminderButton_BoardConsent\n}\n\nfragment SendReminderButton_BoardConsent on BoardConsent {\n  __isBoardConsent: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "30bfdd7bd17129d2c698dd10244068e7";

export default node;
