/**
 * @generated SignedSource<<f5ceeab7a3bf13733a134b52c542fc68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$variables = {
  fairMarketValueId: string;
};
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$data = {
  readonly generateFairMarketValueBoardConsentPreviewURL: string;
};
export type useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation = {
  response: useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$data;
  variables: useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fairMarketValueId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "fairMarketValueId",
        "variableName": "fairMarketValueId"
      }
    ],
    "kind": "ScalarField",
    "name": "generateFairMarketValueBoardConsentPreviewURL",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "24e625ddaf7468abc2465d5892d0761f",
    "id": null,
    "metadata": {},
    "name": "useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation",
    "operationKind": "mutation",
    "text": "mutation useDownloadBoardConsentPreview_GenerateFairMarketValueBoardConsentPreviewURL_Mutation(\n  $fairMarketValueId: ID!\n) {\n  generateFairMarketValueBoardConsentPreviewURL(fairMarketValueId: $fairMarketValueId)\n}\n"
  }
};
})();

(node as any).hash = "5f768f7dec5723b6d944a2bce648c60a";

export default node;
