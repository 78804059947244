import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { Button } from "../../../../components/ui/Button";
import { ConfirmationModal } from "../../../../components/ui/ConfirmationModal";
import { useBoolean } from "../../../../hooks/useBoolean";
import { APPLICATION_ROUTES } from "../../../../paths";

export const CancelFlowButton: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const {
    setFalse: hideExitModal,
    setTrue: showExitModal,
    value: exitModalIsShown,
  } = useBoolean(false);

  const navigate = useNavigate();

  const goToGrantsPage = useCallback(() => {
    hideExitModal();
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationEquityGrants, {
        organizationId,
      }),
    );
  }, [hideExitModal, navigate, organizationId]);

  return (
    <>
      <ConfirmationModal
        cancelLabel="Continue modification"
        confirmationLabel="Exit modification"
        onClose={hideExitModal}
        onConfirmed={() => {
          goToGrantsPage();
        }}
        show={exitModalIsShown}
        suspense
        title="Cancel grant modification?"
      >
        When you exit the flow, any unsaved changes will be lost.
      </ConfirmationModal>
      <Button onClick={showExitModal} size="small" variant="Secondary Full">
        Cancel
      </Button>
    </>
  );
};
