/**
 * @generated SignedSource<<b1f41eb7eeacc6cb5889700e62244542>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EditEquityGridLevelSlideOver_Query$variables = {
  equityGridLevelId: string;
};
export type EditEquityGridLevelSlideOver_Query$data = {
  readonly node: {
    readonly __typename: "EquityGridLevel";
    readonly equityGridId: string;
    readonly equityInUsd: number;
    readonly name: string;
    readonly role: string | null;
    readonly yearlySalary: number | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type EditEquityGridLevelSlideOver_Query = {
  response: EditEquityGridLevelSlideOver_Query$data;
  variables: EditEquityGridLevelSlideOver_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityGridLevelId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityGridLevelId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equityInUsd",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "yearlySalary",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "equityGridId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEquityGridLevelSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "EquityGridLevel",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEquityGridLevelSlideOver_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "type": "EquityGridLevel",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3742bbc08ab437c3bd23ec9ebf2e50fd",
    "id": null,
    "metadata": {},
    "name": "EditEquityGridLevelSlideOver_Query",
    "operationKind": "query",
    "text": "query EditEquityGridLevelSlideOver_Query(\n  $equityGridLevelId: ID!\n) {\n  node(id: $equityGridLevelId) {\n    __typename\n    ... on EquityGridLevel {\n      __typename\n      equityInUsd\n      name\n      role\n      yearlySalary\n      equityGridId\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3d137cef4d6a585414da8b88bdf500df";

export default node;
