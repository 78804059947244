/**
 * @generated SignedSource<<e01e318f533f02fae6c15da4ece308d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RepricingPage_Query$variables = Record<PropertyKey, never>;
export type RepricingPage_Query$data = {
  readonly organizations: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"RepricingPage_Organization">;
  }>;
};
export type RepricingPage_Query = {
  response: RepricingPage_Query$data;
  variables: RepricingPage_Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RepricingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RepricingPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RepricingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizations",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f2cf7a54715cced4614fd02f59624ed4",
    "id": null,
    "metadata": {},
    "name": "RepricingPage_Query",
    "operationKind": "query",
    "text": "query RepricingPage_Query {\n  organizations {\n    ...RepricingPage_Organization\n    id\n  }\n}\n\nfragment RepricingForm_Organization on Organization {\n  name\n  id\n}\n\nfragment RepricingPage_Organization on Organization {\n  ...RepricingForm_Organization\n}\n"
  }
};

(node as any).hash = "d78184e78c5eb229e381cbff31ec38f7";

export default node;
