/**
 * @generated SignedSource<<6ffccc8a8a51ce4ad80af7eba0059e8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewEarlyExerciseAllowedSlider_Organization$data = {
  readonly hasCooleyAsOutsideCounsel: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"EarlyExerciseBeneficialNoticeMessage_Organization">;
  readonly " $fragmentType": "NewEarlyExerciseAllowedSlider_Organization";
};
export type NewEarlyExerciseAllowedSlider_Organization$key = {
  readonly " $data"?: NewEarlyExerciseAllowedSlider_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewEarlyExerciseAllowedSlider_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewEarlyExerciseAllowedSlider_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasCooleyAsOutsideCounsel",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EarlyExerciseBeneficialNoticeMessage_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "08c3f998be224abf8ca4d359a6a5ad84";

export default node;
