import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GranteeDetailsDraftsList } from "../GranteeDetailsDraftsList";
import { GranteeDetailsGrantsList } from "../GranteeDetailsGrantsList";
import { SharesValue, SharesValueMode } from "../SharesValue";
import { Divider } from "../ui/Divider";
import { LabeledValueRoundedList } from "../ui/LabeledValueRoundedList";
import { RoundedBox } from "../ui/RoundedBox";
import { Typography } from "../ui/Typography";
import { RefresherGrantPlanningEntryDetails_Organization$key } from "./__generated__/RefresherGrantPlanningEntryDetails_Organization.graphql";
import {
  RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry$key,
  RefresherGrantPlanningEntryType,
} from "./__generated__/RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry.graphql";

const PLANNING_ENTRY_FRAGMENT = graphql`
  fragment RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry on RefresherGrantPlanningEntry {
    sharesGranted
    refresherType
    granteeOtherRefresherGrantPlanningEntries {
      id
      sharesGranted
      refresherType
    }
    grantee {
      name
      jobTitle
      totalPlannedSharesBreakdown {
        total
      }
      totalDraftedSharesBreakdown {
        total
      }
      totalGrantedSharesBreakdown {
        total
      }
      ctmsGrantsCount
      ...GranteeDetailsGrantsList_Grantee
      ...GranteeDetailsDraftsList_Grantee
    }
  }
`;

const REFRESHER_GRANT_PLANNING_ENTRY_TYPE_LABEL_MAP: Record<
  RefresherGrantPlanningEntryType,
  string
> = {
  EXCEPTIONAL_GRANT: "Exceptional grant",
  LEVELING_GRANT: "leveling grant",
  PROMOTION_GRANT: "promotion grant",
  TENURE_GRANT: "tenure grant",
};

const ORGANIZATION_FRAGMENT = graphql`
  fragment RefresherGrantPlanningEntryDetails_Organization on Organization {
    ...GranteeDetailsGrantsList_Organization
    ...GranteeDetailsDraftsList_Organization
    ...SharesValue_Organization
  }
`;

export const RefresherGrantPlanningEntryDetails: React.FC<{
  equityMode: SharesValueMode;
  organizationFragment: RefresherGrantPlanningEntryDetails_Organization$key;
  planningEntryFragment: RefresherGrantPlanningEntryDetails_RefresherGrantPlanningEntry$key;
}> = ({ equityMode, organizationFragment, planningEntryFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const planningEntry = useFragment(
    PLANNING_ENTRY_FRAGMENT,
    planningEntryFragment,
  );

  return (
    <>
      <LabeledValueRoundedList>
        <LabeledValueRoundedList.LabeledValue label="Grantee">
          {planningEntry.grantee.name}
        </LabeledValueRoundedList.LabeledValue>
        <Divider />
        <LabeledValueRoundedList.LabeledValue label="Job title">
          {planningEntry.grantee.jobTitle ?? "-"}
        </LabeledValueRoundedList.LabeledValue>
        <Divider />
        <LabeledValueRoundedList.LabeledValue label="Compensation">
          <SharesValue
            appendSharesLabel
            mode={equityMode}
            organizationFragment={organization}
            shares={planningEntry.sharesGranted}
            variant="Medium/Extra Small"
          />
        </LabeledValueRoundedList.LabeledValue>
      </LabeledValueRoundedList>
      <RoundedBox className="space-y-4 p-6" withBorder>
        <Typography as="div" variant="Medium/Default">
          Equity overview
        </Typography>

        <div className="divide-y-[0.5px] divide-gray-04">
          <GranteeDetailsGrantsList
            equityMode={equityMode}
            granteeFragment={planningEntry.grantee}
            organizationFragment={organization}
          />
          <GranteeDetailsDraftsList
            equityMode={equityMode}
            granteeFragment={planningEntry.grantee}
            organizationFragment={organization}
            startAtPosition={planningEntry.grantee.ctmsGrantsCount + 1}
          />
          <div className="flex items-center justify-between gap-6 py-[19px]">
            <Typography variant="Medium/Small">
              This{" "}
              {
                REFRESHER_GRANT_PLANNING_ENTRY_TYPE_LABEL_MAP[
                  planningEntry.refresherType
                ]
              }
            </Typography>
            <SharesValue
              appendSharesLabel
              mode={equityMode}
              organizationFragment={organization}
              shares={planningEntry.sharesGranted}
              variant="Medium/Small"
            />
          </div>
          {planningEntry.granteeOtherRefresherGrantPlanningEntries.map(
            (otherPlanningEntry) => (
              <div
                className="flex items-center justify-between gap-6 py-[19px]"
                key={otherPlanningEntry.id}
              >
                <Typography variant="Medium/Small">
                  Other{" "}
                  {
                    REFRESHER_GRANT_PLANNING_ENTRY_TYPE_LABEL_MAP[
                      otherPlanningEntry.refresherType
                    ]
                  }
                </Typography>
                <SharesValue
                  appendSharesLabel
                  mode={equityMode}
                  organizationFragment={organization}
                  shares={otherPlanningEntry.sharesGranted}
                  variant="Medium/Small"
                />
              </div>
            ),
          )}
          <div className="flex items-center justify-between gap-6 py-[19px]">
            <Typography variant="Medium/Small">Total ownership</Typography>
            <SharesValue
              appendSharesLabel
              className="text-primary"
              mode={equityMode}
              organizationFragment={organization}
              shares={
                planningEntry.grantee.totalPlannedSharesBreakdown.total +
                planningEntry.grantee.totalDraftedSharesBreakdown.total +
                planningEntry.grantee.totalGrantedSharesBreakdown.total
              }
              variant="Medium/Small"
            />
          </div>
        </div>
      </RoundedBox>
    </>
  );
};
