import classNames from "classnames";
import { isNil } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { HRISProviderEmployeeField } from "../hooks/__generated__/useMergeGrantee_MergeGranteeMutation.graphql";
import { useApplicationName } from "../hooks/useApplicationTheme";
import { WORK_RELATIONSHIP_TO_LABEL_HELPER } from "../services/workRelationship";
import {
  MergeEmployeeTable_Grantee$data,
  MergeEmployeeTable_Grantee$key,
} from "./__generated__/MergeEmployeeTable_Grantee.graphql";
import {
  MergeEmployeeTable_HRISProviderEmployee$data,
  MergeEmployeeTable_HRISProviderEmployee$key,
} from "./__generated__/MergeEmployeeTable_HRISProviderEmployee.graphql";
import { ShortDate } from "./ShortDate";
import { Radio } from "./ui/Form/Radio";
import { RoundedBox } from "./ui/RoundedBox";
import { Table } from "./ui/Table";
import { Tag } from "./ui/Tag";

const GRANTEE_FRAGMENT = graphql`
  fragment MergeEmployeeTable_Grantee on Grantee {
    name
    email
    jobTitle
    contractStartDate
    taxResidenceCountry {
      code
      name
      emoji
    }
    USStateOfResidence {
      code
      name
    }
    workRelationship
  }
`;

const EMPLOYEE_FRAGMENT = graphql`
  fragment MergeEmployeeTable_HRISProviderEmployee on HRISProviderEmployee {
    name
    email
    hRISProvider
    jobTitle
    startDate
    USStateOfResidence {
      code
      name
    }
    workRelationship
    taxResidenceCountry {
      code
      name
      emoji
    }
  }
`;

export const HRIS_PROVIDER_EMPLOYEE_FIELD_TO_LABEL_MAP: Record<
  HRISProviderEmployeeField,
  string
> = {
  Email: "Email",
  JobTitle: "Job title",
  Name: "Full name",
  StartDate: "Contract start date",
  TaxResidenceCountryCode: "Country of residence",
  USStateOfResidenceCode: "US state of residence",
  WorkRelationship: "Work relationship",
};

const NOT_SET_LABEL = <Tag color="gray">Missing</Tag>;

const FIELD_TO_GRANTEE_VALUE_GETTER: Record<
  HRISProviderEmployeeField,
  (grantee: MergeEmployeeTable_Grantee$data) => null | React.ReactNode
> = {
  Email: (grantee) => grantee.email ?? null,
  JobTitle: (grantee) => grantee.jobTitle ?? null,
  Name: (grantee) => grantee.name ?? null,
  StartDate: (grantee) =>
    grantee.contractStartDate ? (
      <ShortDate value={grantee.contractStartDate} />
    ) : null,
  TaxResidenceCountryCode: (grantee) =>
    grantee.taxResidenceCountry
      ? `${grantee.taxResidenceCountry.name} ${grantee.taxResidenceCountry.emoji}`
      : null,
  USStateOfResidenceCode: (grantee) => grantee.USStateOfResidence?.name ?? null,
  WorkRelationship: (grantee) =>
    grantee.workRelationship
      ? WORK_RELATIONSHIP_TO_LABEL_HELPER[grantee.workRelationship]
          .singularLabel
      : null,
};

const FIELD_TO_HRIS_EMPLOYEE_VALUE_GETTER: Record<
  HRISProviderEmployeeField,
  (
    hrisEmployee: MergeEmployeeTable_HRISProviderEmployee$data,
  ) => null | React.ReactNode
> = {
  Email: (hrisEmployee) => hrisEmployee.email ?? null,
  JobTitle: (hrisEmployee) => hrisEmployee.jobTitle ?? null,
  Name: (hrisEmployee) => hrisEmployee.name ?? null,
  StartDate: (hrisEmployee) =>
    hrisEmployee.startDate ? (
      <ShortDate value={hrisEmployee.startDate} />
    ) : null,
  TaxResidenceCountryCode: (hrisEmployee) =>
    hrisEmployee.taxResidenceCountry
      ? `${hrisEmployee.taxResidenceCountry.name} ${hrisEmployee.taxResidenceCountry.emoji}`
      : null,
  USStateOfResidenceCode: (hrisEmployee) =>
    hrisEmployee.USStateOfResidence?.name ?? null,
  WorkRelationship: (hrisEmployee) =>
    hrisEmployee.workRelationship
      ? WORK_RELATIONSHIP_TO_LABEL_HELPER[hrisEmployee.workRelationship]
          .singularLabel
      : null,
};

export type MergeEmployeeTableState = Record<
  HRISProviderEmployeeField,
  boolean
>;

function getInitialState({
  grantee,
}: {
  grantee: MergeEmployeeTable_Grantee$data;
}): MergeEmployeeTableState {
  return {
    Email: isNil(grantee.email),
    JobTitle: isNil(grantee.jobTitle),
    Name: isNil(grantee.name),
    StartDate: isNil(grantee.contractStartDate),
    TaxResidenceCountryCode: isNil(grantee.taxResidenceCountry?.code),
    USStateOfResidenceCode: isNil(grantee.USStateOfResidence?.code),
    WorkRelationship: isNil(grantee.workRelationship),
  };
}

function isFieldValueDifferent({
  employee,
  field,
  grantee,
}: {
  employee: MergeEmployeeTable_HRISProviderEmployee$data;
  field: HRISProviderEmployeeField;
  grantee: MergeEmployeeTable_Grantee$data;
}) {
  switch (field) {
    case "Email":
      return grantee.email !== employee.email;
    case "JobTitle":
      return grantee.jobTitle !== employee.jobTitle;
    case "Name":
      return grantee.name !== employee.name;
    case "StartDate":
      return grantee.contractStartDate !== employee.startDate;
    case "TaxResidenceCountryCode":
      return (
        grantee.taxResidenceCountry?.code !== employee.taxResidenceCountry?.code
      );
    case "USStateOfResidenceCode":
      return (
        grantee.USStateOfResidence?.code !== employee.USStateOfResidence?.code
      );
    case "WorkRelationship":
      return grantee.workRelationship !== employee.workRelationship;
  }
}

export const useMergeEmployeeTableState = ({
  granteeFragment,
}: {
  granteeFragment: MergeEmployeeTable_Grantee$key;
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const [state, setState] = useState<MergeEmployeeTableState>(() =>
    getInitialState({ grantee }),
  );

  const resetState = useCallback(
    () => setState(getInitialState({ grantee })),
    [grantee],
  );

  const selectedFieldsList = (
    Object.entries(state) as [HRISProviderEmployeeField, boolean][]
  )
    .filter(([, value]) => value)
    .map(([key]) => key);

  return { resetState, selectedFieldsList, setState, state } as const;
};

const Row: React.FC<{
  employee: MergeEmployeeTable_HRISProviderEmployee$data;
  employeeValueIsSelected: boolean;
  field: HRISProviderEmployeeField;
  grantee: MergeEmployeeTable_Grantee$data;
  onChange: (hrisValueIsSelected: boolean) => void;
}> = ({ employee, employeeValueIsSelected, field, grantee, onChange }) => {
  const employeeValueIsNull =
    FIELD_TO_HRIS_EMPLOYEE_VALUE_GETTER[field](employee) === null;
  return (
    <Table.Row>
      <Table.Cell variant="Medium/Extra Small">
        {HRIS_PROVIDER_EMPLOYEE_FIELD_TO_LABEL_MAP[field]}
      </Table.Cell>
      <Table.Cell>
        <div
          className={classNames("flex items-center gap-2", {
            "cursor-pointer": !employeeValueIsNull,
          })}
          onClick={() => {
            if (employeeValueIsNull) return;
            onChange(true);
          }}
        >
          {!employeeValueIsNull && (
            <Radio checked={employeeValueIsSelected} size="small" />
          )}
          <span>
            {FIELD_TO_HRIS_EMPLOYEE_VALUE_GETTER[field](employee) ??
              NOT_SET_LABEL}
          </span>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div
          className="flex cursor-pointer items-center gap-2"
          onClick={() => onChange(false)}
        >
          <Radio checked={!employeeValueIsSelected} size="small" />
          <span>
            {FIELD_TO_GRANTEE_VALUE_GETTER[field](grantee) ?? NOT_SET_LABEL}
          </span>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export const MergeEmployeeTable: React.FC<{
  employeeFragment: MergeEmployeeTable_HRISProviderEmployee$key;
  fieldsToDisplay?: HRISProviderEmployeeField[];
  granteeFragment: MergeEmployeeTable_Grantee$key;
  onChange: (updatedState: MergeEmployeeTableState) => void;
  state: MergeEmployeeTableState;
}> = ({
  employeeFragment,
  fieldsToDisplay,
  granteeFragment,
  onChange,
  state,
}) => {
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);
  const employee = useFragment(EMPLOYEE_FRAGMENT, employeeFragment);

  const hRISProviderName = useMemo(() => {
    switch (employee.hRISProvider) {
      case "DEEL":
        return "Deel";
      case "REMOTE":
        return "Remote";
    }
  }, [employee.hRISProvider]);

  const handleValueChange = useCallback(
    (field: HRISProviderEmployeeField) => (value: boolean) => {
      onChange({ ...state, [field]: value });
    },
    [onChange, state],
  );

  const selectedTaxResidenceCountryCode = useMemo(() => {
    if (state.TaxResidenceCountryCode) {
      return employee.taxResidenceCountry?.code;
    } else {
      return grantee.taxResidenceCountry?.code;
    }
  }, [state.TaxResidenceCountryCode, employee, grantee]);

  const applicationName = useApplicationName();

  const shouldRowBeDisplayed = useCallback(
    (field: HRISProviderEmployeeField) => {
      if (fieldsToDisplay) {
        return fieldsToDisplay.includes(field);
      }

      return isFieldValueDifferent({
        employee,
        field,
        grantee,
      });
    },
    [fieldsToDisplay, employee, grantee],
  );

  return (
    <RoundedBox className="overflow-hidden" withBorder>
      <Table className="w-full">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Fields</Table.HeaderCell>
            <Table.HeaderCell>From {hRISProviderName}</Table.HeaderCell>
            <Table.HeaderCell>From {applicationName}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {shouldRowBeDisplayed("Name") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.Name}
              field="Name"
              grantee={grantee}
              onChange={handleValueChange("Name")}
            />
          )}
          {shouldRowBeDisplayed("Email") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.Email}
              field="Email"
              grantee={grantee}
              onChange={handleValueChange("Email")}
            />
          )}
          {shouldRowBeDisplayed("TaxResidenceCountryCode") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.TaxResidenceCountryCode}
              field="TaxResidenceCountryCode"
              grantee={grantee}
              onChange={handleValueChange("TaxResidenceCountryCode")}
            />
          )}
          {selectedTaxResidenceCountryCode === "US" &&
            shouldRowBeDisplayed("USStateOfResidenceCode") && (
              <Row
                employee={employee}
                employeeValueIsSelected={state.USStateOfResidenceCode}
                field="USStateOfResidenceCode"
                grantee={grantee}
                onChange={handleValueChange("USStateOfResidenceCode")}
              />
            )}
          {shouldRowBeDisplayed("WorkRelationship") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.WorkRelationship}
              field="WorkRelationship"
              grantee={grantee}
              onChange={handleValueChange("WorkRelationship")}
            />
          )}
          {shouldRowBeDisplayed("StartDate") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.StartDate}
              field="StartDate"
              grantee={grantee}
              onChange={handleValueChange("StartDate")}
            />
          )}
          {shouldRowBeDisplayed("JobTitle") && (
            <Row
              employee={employee}
              employeeValueIsSelected={state.JobTitle}
              field="JobTitle"
              grantee={grantee}
              onChange={handleValueChange("JobTitle")}
            />
          )}
        </Table.Body>
      </Table>
    </RoundedBox>
  );
};
