/**
 * @generated SignedSource<<e1c96818f6f38ddd66b1b184c07607b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useGranteePortalGranteeShares_Grantee$data = {
  readonly totalGrantedSharesBreakdown: {
    readonly real: number;
    readonly virtual: number;
  };
  readonly totalVestedSharesBreakdown: {
    readonly real: number;
    readonly virtual: number;
  };
  readonly " $fragmentType": "useGranteePortalGranteeShares_Grantee";
};
export type useGranteePortalGranteeShares_Grantee$key = {
  readonly " $data"?: useGranteePortalGranteeShares_Grantee$data;
  readonly " $fragmentSpreads": FragmentRefs<"useGranteePortalGranteeShares_Grantee">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "real",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "virtual",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useGranteePortalGranteeShares_Grantee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalGrantedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "totalVestedSharesBreakdown",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Grantee",
  "abstractKey": null
};
})();

(node as any).hash = "e88d03ed5e35dfc4908e5c2adb1049f7";

export default node;
