import React from "react";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../../components/BackButton";
import { Page } from "../../../../components/Page";
import { BreadCrumb } from "../../../../components/ui/BreadCrumb";
import { OneColumnLayout } from "../../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../../components/ui/RoundedBox";
import { Typography } from "../../../../components/ui/Typography";
import { ChangeGrantInstrumentPage_Organization$key } from "./__generated__/ChangeGrantInstrumentPage_Organization.graphql";
import { ChangeGrantInstrumentPage_Query } from "./__generated__/ChangeGrantInstrumentPage_Query.graphql";
import { ChangeGrantInstrumentForm } from "./ChangeGrantInstrumentForm";

const ORGANIZATION_FRAGMENT = graphql`
  fragment ChangeGrantInstrumentPage_Organization on Organization
  @relay(plural: true) {
    ...ChangeGrantInstrumentForm_Organization
  }
`;

const ChangeGrantInstrumentPage_: React.FC<{
  organizationsFragment: ChangeGrantInstrumentPage_Organization$key;
}> = ({ organizationsFragment }) => {
  const organizations = useFragment(
    ORGANIZATION_FRAGMENT,
    organizationsFragment,
  );

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link to="..">Organization admin</BreadCrumb.Link>
          <BreadCrumb.Link to=".">Change grant instrument</BreadCrumb.Link>
        </BreadCrumb>
      }
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Change grant instrument
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Changing an easop grant instrument will re generate its
              documentation and change data in its grantee portal.
              <br />
              Overriding a legacy grant with an Easop Instrument will allow
              access to more taylored information.
            </Typography>
          </div>
        </div>
        <ChangeGrantInstrumentForm organizationsFragment={organizations} />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query ChangeGrantInstrumentPage_Query {
    organizations {
      ...ChangeGrantInstrumentPage_Organization
    }
  }
`;

const ChangeGrantInstrumentPage: React.FC = () => {
  const { organizations } = useLazyLoadQuery<ChangeGrantInstrumentPage_Query>(
    QUERY,
    {},
  );
  return (
    <Page
      analyticsName="Super Admin - ChangeGrantInstrument"
      title={`Super admin | ChangeGrantInstrument`}
    >
      <ChangeGrantInstrumentPage_ organizationsFragment={organizations} />
    </Page>
  );
};

export default ChangeGrantInstrumentPage;
