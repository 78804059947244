export const FolkLogo = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 91 32"
    width="91"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_66_7629)">
      <path
        d="M33.8797 13.7771V27.3327H36.7361V13.7771H41.0206V11.4659H36.7361V9.79005C36.7361 7.95247 37.4676 6.78631 39.0699 6.78631C40.4632 6.78631 41.3341 7.7051 41.7173 8.90659L43.7028 8.19983C43.0061 5.86752 41.5431 4.66602 38.9306 4.66602C35.7259 4.66602 33.8797 6.82165 33.8797 10.1081V11.4659H31.5459V13.7771H33.8797Z"
        fill="#485864"
      />
      <path
        d="M45.8182 19.2061C45.8182 15.743 47.7462 13.34 50.8115 13.34C53.9117 13.34 55.8745 15.743 55.8745 19.2061C55.8745 22.6339 53.9117 25.0722 50.8115 25.0722C47.7462 25.0722 45.8182 22.6339 45.8182 19.2061ZM58.8353 19.2061C58.8353 14.4001 55.305 11.043 50.8115 11.043C46.3529 11.043 42.8574 14.4001 42.8574 19.2061C42.8574 24.0121 46.3529 27.3339 50.8115 27.3339C55.305 27.3339 58.8353 24.0121 58.8353 19.2061Z"
        fill="#485864"
      />
      <path
        d="M61.8281 4.66602V27.3327H64.7192V4.66602H61.8281Z"
        fill="#485864"
      />
      <path
        d="M71.3355 21.3252L73.4604 19.4169L79.5214 27.3327H82.935L75.62 17.7914L82.6215 11.4659H78.9989L71.3355 18.4628V4.66602H68.4443V27.3327H71.3355V21.3252Z"
        fill="#485864"
      />
      <path
        d="M86.6621 27.3542H90.1454V23.8203H86.6621V27.3542Z"
        fill="#485864"
      />
      <path
        d="M13.579 3.24414C20.5526 3.24414 26.2126 8.9264 26.2126 15.9441C26.2126 22.9619 20.5526 28.6441 13.579 28.6441C6.6053 28.6441 0.945312 22.9619 0.945312 15.9441C0.945312 8.9264 6.6053 3.24414 13.579 3.24414Z"
        stroke="#485864"
        strokeMiterlimit="4.13357"
        strokeWidth="1.6"
      />
      <path
        d="M13.4111 24.2222H13.8568C18.1743 24.2222 21.705 20.87 21.8156 16.4464C21.8218 16.2017 21.6299 16 21.3912 16H5.87676C5.638 16 5.44618 16.2017 5.45229 16.4464C5.56296 20.87 9.09362 24.2222 13.4111 24.2222Z"
        fill="#485864"
      />
    </g>
    <defs>
      <clipPath id="clip0_66_7629">
        <rect
          fill="white"
          height="32"
          transform="translate(0.145508)"
          width="90"
        />
      </clipPath>
    </defs>
  </svg>
);
