/**
 * @generated SignedSource<<06dac87c1fae2c69a4195956510102f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ConnectToRemoteButton_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "ConnectToRemoteButton_Organization";
};
export type ConnectToRemoteButton_Organization$key = {
  readonly " $data"?: ConnectToRemoteButton_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ConnectToRemoteButton_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectToRemoteButton_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ec27e2bbca94f52a88a18e4f9190b4a4";

export default node;
