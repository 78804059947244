/**
 * @generated SignedSource<<0da6ec7323b3a3543ed2e9ee52c01e1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type WorkRelationship = "Advisor" | "ContractorManagementCompany" | "ContractorNaturalPerson" | "DirectEmployee" | "EoREmployee";
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestTaxTreatmentSection_CTMSGrant$data = {
  readonly grantee: {
    readonly name: string;
    readonly taxResidenceCountry: {
      readonly emoji: string;
      readonly name: string;
    } | null;
    readonly workRelationship: WorkRelationship | null;
    readonly " $fragmentSpreads": FragmentRefs<"MissingGranteeInformationMessage_Grantee">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_CTMSGrant" | "WantToKnowMoreBox_CTMSGrant">;
  readonly " $fragmentType": "ExerciseRequestTaxTreatmentSection_CTMSGrant";
};
export type ExerciseRequestTaxTreatmentSection_CTMSGrant$key = {
  readonly " $data"?: ExerciseRequestTaxTreatmentSection_CTMSGrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestTaxTreatmentSection_CTMSGrant">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestTaxTreatmentSection_CTMSGrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Grantee",
      "kind": "LinkedField",
      "name": "grantee",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "workRelationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Country",
          "kind": "LinkedField",
          "name": "taxResidenceCountry",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emoji",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MissingGranteeInformationMessage_Grantee"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WantToKnowMoreBox_CTMSGrant"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_CTMSGrant"
    }
  ],
  "type": "CTMSGrant",
  "abstractKey": null
};
})();

(node as any).hash = "c3b71278456f8613078b9a14272ec867";

export default node;
