/**
 * @generated SignedSource<<9f4d93426588dbdd92c0efd3272e8501>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PendingImplementation_Viewer$data = {
  readonly isSuperAdmin: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"GrantsTable_Account">;
  readonly " $fragmentType": "PendingImplementation_Viewer";
};
export type PendingImplementation_Viewer$key = {
  readonly " $data"?: PendingImplementation_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PendingImplementation_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PendingImplementation_Viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSuperAdmin",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GrantsTable_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "dc5393658cc8c3a0efa777d0b3028359";

export default node;
