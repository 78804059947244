import { noop } from "lodash";
import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useSafeMutation } from "../../../../hooks/useSafeMutation";
import {
  Safe,
  SafeWithoutId,
  SimulatedGrant,
} from "../../../../services/SafeCalculatorService";
import { ISafeSimulatorContextProvider } from "../../../SafeSimulator/IContext";
import {
  OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation,
  SafeInput,
} from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation.graphql";
import {
  OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSimulatedGrant_Mutation,
  SafeCalculatorSimulatedGrantInput,
} from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSimulatedGrant_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSafe_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSafe_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation.graphql";
import {
  OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$data,
  OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$key,
} from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_SetSafeAsCurrentValuation_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_SetSafeAsCurrentValuation_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionInitialShares_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionInitialShares_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSafe_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSafe_Mutation.graphql";
import { OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation } from "./__generated__/OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation.graphql";

const SAFE_CALCULATOR_VERSION_FRAGMENT = graphql`
  fragment OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion on SafeCalculatorVersion {
    id
    preConversionFDS
    optionPoolSize
    safes {
      __typename
      ... on SafePostMoney {
        id
        valuationCapInUSD
        totalAmountInvestedInUSD
        lastIssuanceDate
        useAsValuation
      }
      ... on SafePreMoney {
        id
        valuationCapInUSD
        totalAmountInvestedInUSD
        lastIssuanceDate
        useAsValuation
      }
      ... on SafePostMoneyMFN {
        id
        valuationCapInUSD
        totalAmountInvestedInUSD
        lastIssuanceDate
        mfnDate
        useAsValuation
      }
    }
    simulatedGrants {
      id
      # eslint-disable-next-line relay/unused-fields
      type
      # eslint-disable-next-line relay/unused-fields
      grantedSharesInputMode
      # eslint-disable-next-line relay/unused-fields
      shares
    }
  }
`;

const UPDATE_SAFE_CALCULATOR_VERSION_INITIAL_SHARES_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionInitialShares_Mutation(
    $safeCalculatorVersionId: ID!
    $input: SafeCalculatorInitialSharesInput!
  ) {
    updateSafeCalculatorVersionInitialShares(
      input: $input
      safeCalculatorVersionId: $safeCalculatorVersionId
    ) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const ADD_SAFE_CALCULATOR_VERSION_SAFE_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation(
    $safeCalculatorVersionId: ID!
    $input: SafeInput!
  ) {
    addSafeCalculatorVersionSafe(
      input: $input
      safeCalculatorVersionId: $safeCalculatorVersionId
    ) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const UPDATE_SAFE_CALCULATOR_VERSION_SAFE_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSafe_Mutation(
    $safeId: ID!
    $input: SafeInput!
  ) {
    updateSafeCalculatorVersionSafe(input: $input, safeId: $safeId) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const DELETE_SAFE_CALCULATOR_VERSION_SAFE_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSafe_Mutation(
    $safeId: ID!
  ) {
    deleteSafeCalculatorVersionSafe(safeId: $safeId) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const ADD_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSimulatedGrant_Mutation(
    $safeCalculatorVersionId: ID!
    $input: SafeCalculatorSimulatedGrantInput!
  ) {
    addSafeCalculatorVersionSimulatedGrant(
      input: $input
      safeCalculatorVersionId: $safeCalculatorVersionId
    ) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const UPDATE_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation(
    $simulatedGrantId: ID!
    $input: SafeCalculatorSimulatedGrantInput!
  ) {
    updateSafeCalculatorVersionSimulatedGrant(
      input: $input
      simulatedGrantId: $simulatedGrantId
    ) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const DELETE_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation(
    $simulatedGrantId: ID!
  ) {
    deleteSafeCalculatorVersionSimulatedGrant(
      simulatedGrantId: $simulatedGrantId
    ) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const SET_SAFE_AS_CURRENT_VALUATION_MUTATION = graphql`
  mutation OrganizationSettingsSafeCalculatorVersionContext_SetSafeAsCurrentValuation_Mutation(
    $safeId: ID!
  ) {
    setSafeAsCurrentValuation(safeId: $safeId) {
      ...OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion
    }
  }
`;

const mapGqlSafeToSafeSimulatorSafe = (
  safe: OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$data["safes"][number],
): Safe => {
  switch (safe.__typename) {
    case "SafePostMoney":
      return {
        id: safe.id,
        lastIssuanceDate: safe.lastIssuanceDate,
        totalAmountInvestedInUSD: safe.totalAmountInvestedInUSD,
        type: "POST_MONEY",
        useAsValuation: safe.useAsValuation,
        valuationCapInUSD: safe.valuationCapInUSD,
      };
    case "SafePostMoneyMFN":
      return {
        id: safe.id,
        lastIssuanceDate: safe.lastIssuanceDate,
        mfnDate: safe.mfnDate,
        totalAmountInvestedInUSD: safe.totalAmountInvestedInUSD,
        type: "POST_MONEY_MFN",
        useAsValuation: safe.useAsValuation,
        valuationCapInUSD: safe.valuationCapInUSD,
      };
    case "SafePreMoney":
      return {
        id: safe.id,
        lastIssuanceDate: safe.lastIssuanceDate,
        totalAmountInvestedInUSD: safe.totalAmountInvestedInUSD,
        type: "PRE_MONEY",
        useAsValuation: safe.useAsValuation,
        valuationCapInUSD: safe.valuationCapInUSD,
      };
    case "%other":
      throw new Error("Invalid safe type");
  }
};

const mapSafeSimulatorSafeToGqlSafeInput = (safe: SafeWithoutId): SafeInput => {
  return {
    lastIssuanceDate: safe.lastIssuanceDate,
    mfnDate: "mfnDate" in safe ? safe.mfnDate : null,
    totalAmountInvestedInUSD: safe.totalAmountInvestedInUSD,
    type: safe.type,
    valuationCapInUSD: safe.valuationCapInUSD,
  };
};

const mapSafeSimulatorSimulatedGrantToGqlSafeInput = (
  simulatedGrant: Omit<SimulatedGrant, "id">,
): SafeCalculatorSimulatedGrantInput => {
  return {
    grantedSharesInputMode: simulatedGrant.grantedSharesInputMode,
    shares: simulatedGrant.shares,
    type: simulatedGrant.type,
  };
};

export const OrganizationSettingsSafeCalculatorVersionContextProvider: React.FC<{
  children: React.ReactNode;
  safeCalculatorVersionFragment: OrganizationSettingsSafeCalculatorVersionContext_SafeCalculatorVersion$key;
}> = ({ children, safeCalculatorVersionFragment }) => {
  const safeCalculatorVersion = useFragment(
    SAFE_CALCULATOR_VERSION_FRAGMENT,
    safeCalculatorVersionFragment,
  );

  const [_updateSafeCalculatorVersionInitialShares] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionInitialShares_Mutation>(
      UPDATE_SAFE_CALCULATOR_VERSION_INITIAL_SHARES_MUTATION,
    );
  const [_addSafeCalculatorVersionSafe] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSafe_Mutation>(
      ADD_SAFE_CALCULATOR_VERSION_SAFE_MUTATION,
    );
  const [_updateSafeCalculatorVersionSafe] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSafe_Mutation>(
      UPDATE_SAFE_CALCULATOR_VERSION_SAFE_MUTATION,
    );
  const [_deleteSafeCalculatorVersionSafe] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSafe_Mutation>(
      DELETE_SAFE_CALCULATOR_VERSION_SAFE_MUTATION,
    );
  const [_setSafeAsCurrentValuation] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_SetSafeAsCurrentValuation_Mutation>(
      SET_SAFE_AS_CURRENT_VALUATION_MUTATION,
    );
  const [_addSafeCalculatorVersionSimulatedGrant] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_AddSafeCalculatorVersionSimulatedGrant_Mutation>(
      ADD_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION,
    );
  const [_updateSafeCalculatorVersionSimulatedGrant] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_UpdateSafeCalculatorVersionSimulatedGrant_Mutation>(
      UPDATE_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION,
    );
  const [_deleteSafeCalculatorVersionSimulatedGrant] =
    useSafeMutation<OrganizationSettingsSafeCalculatorVersionContext_DeleteSafeCalculatorVersionSimulatedGrant_Mutation>(
      DELETE_SAFE_CALCULATOR_VERSION_SIMULATED_GRANT_MUTATION,
    );

  const state = useMemo(() => {
    return {
      optionPoolShares: safeCalculatorVersion.optionPoolSize,
      preConversionFullyDilutedShares: safeCalculatorVersion.preConversionFDS,
      safes: safeCalculatorVersion.safes.map(mapGqlSafeToSafeSimulatorSafe),
      simulatedGrants: [...safeCalculatorVersion.simulatedGrants],
    };
  }, [
    safeCalculatorVersion.preConversionFDS,
    safeCalculatorVersion.optionPoolSize,
    safeCalculatorVersion.safes,
    safeCalculatorVersion.simulatedGrants,
  ]);

  const onInitialSharesUpdated = async ({
    optionPoolShares,
    preConversionFullyDilutedShares,
  }: {
    optionPoolShares?: number;
    preConversionFullyDilutedShares: number;
  }) => {
    await _updateSafeCalculatorVersionInitialShares({
      variables: {
        input: {
          optionPoolSize: optionPoolShares ?? null,
          preConversionFDS: preConversionFullyDilutedShares,
        },
        safeCalculatorVersionId: safeCalculatorVersion.id,
      },
    });
  };

  const onSafeAdded = async (safe: SafeWithoutId) => {
    await _addSafeCalculatorVersionSafe({
      variables: {
        input: mapSafeSimulatorSafeToGqlSafeInput(safe),
        safeCalculatorVersionId: safeCalculatorVersion.id,
      },
    });
  };

  const onSafeEdited = async (safe: Safe & { id: string }) => {
    await _updateSafeCalculatorVersionSafe({
      variables: {
        input: mapSafeSimulatorSafeToGqlSafeInput(safe),
        safeId: safe.id,
      },
    });
  };

  const onSafeDeleted = async (safe: Safe & { id: string }) => {
    await _deleteSafeCalculatorVersionSafe({
      variables: {
        safeId: safe.id,
      },
    });
  };

  const onSafeSetAsCurrentValuation = async (safe: Safe & { id: string }) => {
    await _setSafeAsCurrentValuation({
      variables: {
        safeId: safe.id,
      },
    });
  };

  const onSimulatedGrantAdded = async (
    simulatedGrant: Omit<SimulatedGrant, "id">,
  ) => {
    await _addSafeCalculatorVersionSimulatedGrant({
      variables: {
        input: mapSafeSimulatorSimulatedGrantToGqlSafeInput(simulatedGrant),
        safeCalculatorVersionId: safeCalculatorVersion.id,
      },
    });
  };

  const onSimulatedGrantEdited = async (simulatedGrant: SimulatedGrant) => {
    await _updateSafeCalculatorVersionSimulatedGrant({
      variables: {
        input: mapSafeSimulatorSimulatedGrantToGqlSafeInput(simulatedGrant),
        simulatedGrantId: simulatedGrant.id,
      },
    });
  };

  const onSimulatedGrantDeleted = async (simulatedGrant: SimulatedGrant) => {
    await _deleteSafeCalculatorVersionSimulatedGrant({
      variables: {
        simulatedGrantId: simulatedGrant.id,
      },
    });
  };

  return (
    <ISafeSimulatorContextProvider
      onInitialSharesUpdated={onInitialSharesUpdated}
      onReset={noop}
      onSafeAdded={onSafeAdded}
      onSafeDeleted={onSafeDeleted}
      onSafeEdited={onSafeEdited}
      onSafeSetAsCurrentValuation={onSafeSetAsCurrentValuation}
      onSimulatedGrantAdded={onSimulatedGrantAdded}
      onSimulatedGrantDeleted={onSimulatedGrantDeleted}
      onSimulatedGrantEdited={onSimulatedGrantEdited}
      state={state}
    >
      {children}
    </ISafeSimulatorContextProvider>
  );
};
