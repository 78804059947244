import React from "react";

import { Typography } from "../../../../components/ui/Typography";

export const GrantReviewRow: React.FC<
  React.PropsWithChildren<{
    title?: React.ReactNode;
  }>
> = ({ children, title }) => {
  return (
    <div className="space-y-4 p-6">
      {title && (
        <Typography as="div" variant="Medium/Extra Small">
          {title}
        </Typography>
      )}
      <div>{children}</div>
    </div>
  );
};
