/**
 * @generated SignedSource<<84c3d9042e47bf65a749d8f550e44d90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GranteesListSlideOver_Organization$data = {
  readonly id: string;
  readonly " $fragmentType": "GranteesListSlideOver_Organization";
};
export type GranteesListSlideOver_Organization$key = {
  readonly " $data"?: GranteesListSlideOver_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"GranteesListSlideOver_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GranteesListSlideOver_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "538f1649646f0973fae1beb1e47f4a3d";

export default node;
