import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { generatePath, Link } from "react-router-dom";

import { CenteredColumnLayout } from "../../components/ui/Layout/CenteredColumnLayout";
import { Picture } from "../../components/ui/Picture";
import { Typography } from "../../components/ui/Typography";
import { APPLICATION_ROUTES } from "../../paths";
import image from "./404-frame.png";

const NotFoundPage = () => {
  useEffect(() => {
    Sentry.captureException(new Error("Page not found"));
  }, []);

  return (
    <CenteredColumnLayout fullHeight showFooter>
      <div className="flex h-full items-center justify-center">
        <div className="mx-auto w-full max-w-lg space-y-6 rounded-lg border-[0.5px] border-gray-04 bg-white px-6 py-10 text-center shadow-100">
          <Typography as="div" variant="Medium/Default">
            <span className="animate-not-found-1">Pag</span>
            <span className="animate-not-found-2">e no</span>
            <span className="animate-not-found-3">t fo</span>
            <span className="animate-not-found-4">und.</span>
          </Typography>
          <div className="space-y-4">
            <Typography as="div" variant="Regular/Small">
              Sorry, we couldn’t find the page you’re looking for.
            </Typography>
            <Typography as="div" variant="Medium/Small">
              <Link
                className="text-primary"
                to={generatePath(APPLICATION_ROUTES["roleSelection"], {})}
              >
                🙏 Take me back!
              </Link>
            </Typography>
          </div>
          <Picture className="mx-auto w-full" image={image} />
        </div>
      </div>
    </CenteredColumnLayout>
  );
};

export default NotFoundPage;
