import React from "react";

import { Page } from "../../components/Page";
import { LogoWithoutText } from "../../components/ui/Logo/Logo";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

const _ForgotPasswordSuccessPage: React.FC = () => (
  <AuthLayout.Card
    logo={<LogoWithoutText />}
    subtitle={
      <>
        Check your emails!
        <br />
        You should have received password reset instructions
      </>
    }
    title="We've got you covered!"
  />
);

const ForgotPasswordSuccessPage: React.FC = () => (
  <Page
    analyticsCategory="Authentication"
    analyticsName="Authentication - Reset password email sent"
    title="Authentication - Reset password email sent"
  >
    <_ForgotPasswordSuccessPage />
  </Page>
);

export default ForgotPasswordSuccessPage;
