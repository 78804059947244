/**
 * @generated SignedSource<<ea5e471b0bce8903d50d8e09c0c854a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type HRISProvider = "DEEL" | "REMOTE";
import { FragmentRefs } from "relay-runtime";
export type CreateNewGranteeItem_HRISProviderEmployee$data = {
  readonly hRISProvider: HRISProvider;
  readonly hRISProviderEmployeeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CleanupGranteesItem_CreateContent_HRISProviderEmployee" | "GranteeForm_DefaultHRISProviderEmployee" | "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee">;
  readonly " $fragmentType": "CreateNewGranteeItem_HRISProviderEmployee";
};
export type CreateNewGranteeItem_HRISProviderEmployee$key = {
  readonly " $data"?: CreateNewGranteeItem_HRISProviderEmployee$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateNewGranteeItem_HRISProviderEmployee">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateNewGranteeItem_HRISProviderEmployee",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProvider",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hRISProviderEmployeeId",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GranteeForm_DefaultHRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MergeEmployeeWithAnotherGranteeModal_HRISProviderEmployee"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CleanupGranteesItem_CreateContent_HRISProviderEmployee"
    }
  ],
  "type": "HRISProviderEmployee",
  "abstractKey": null
};

(node as any).hash = "e1e3c21024bda355d49206c23fe80e95";

export default node;
