import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { graphql, useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath, useNavigate } from "react-router-dom";
import { z } from "zod";

import { BeginnerFlowPostTerminationExercisePeriodSelectionBlock } from "../../../components/BeginnerFlowPostTerminationExercisePeriodSelectionBlock";
import { EarlyExerciseBeneficialNoticeMessage } from "../../../components/EarlyExerciseBeneficialNoticeMessage";
import { GrantWatchouts } from "../../../components/GrantWatchouts";
import { Page } from "../../../components/Page";
import { QuestionCard } from "../../../components/QuestionCard";
import { Button, LinkButton } from "../../../components/ui/Button";
import { Divider } from "../../../components/ui/Divider";
import { FormRow } from "../../../components/ui/Form/FormRow";
import { SelectAutocomplete } from "../../../components/ui/Form/Inputs/Select/SelectAutocomplete";
import { Toggle } from "../../../components/ui/Form/Toggle";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { TextButton } from "../../../components/ui/TextButton";
import { FadeAndScaleTransition } from "../../../components/ui/Transition";
import { Typography } from "../../../components/ui/Typography";
import { zodExtra } from "../../../helpers/zod-extra";
import { useBoolean } from "../../../hooks/useBoolean";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import { AccelerationClauseOptions } from "../../../services/AccelerationClause";
import { CreateSlideOverRemote } from "../Grants/Configure/PostTermination/CreateSlideOver";
import { PTEP_Grantee$key } from "./__generated__/PTEP_Grantee.graphql";
import { PTEP_Instrument$key } from "./__generated__/PTEP_Instrument.graphql";
import { PTEP_Organization$key } from "./__generated__/PTEP_Organization.graphql";
import { PTEP_Query } from "./__generated__/PTEP_Query.graphql";
import { useAssistedGrantContext, useIsAssistedGrantVirtual } from "./Context";
import { AssistedGrantPageGridLayout, AssistedGrantPageTitle } from "./Shared";

const ToggleCard: React.FC<
  React.PropsWithChildren<{
    checked?: boolean;
    label: React.ReactNode;
    onChange: (checked: boolean) => void;
    onQuestionClick: () => void;
    question: React.ReactNode;
    title: React.ReactNode;
  }>
> = ({
  checked = false,
  children,
  label,
  onChange,
  onQuestionClick,
  question,
  title,
}) => (
  <div className="space-y-2 rounded-lg bg-gray-01 p-6">
    <Typography as="div" variant="Medium/Extra Small">
      {title}
    </Typography>
    <Toggle.Group>
      <div className="flex items-center justify-between gap-2">
        <Toggle.Label>
          <Typography
            as="div"
            className="text-black-05"
            variant="Regular/Extra Small"
          >
            {label}
          </Typography>
        </Toggle.Label>
        <Toggle enabled={checked} onChange={onChange} />
      </div>
    </Toggle.Group>
    {checked && children && <div className="py-2">{children}</div>}
    <TextButton onClick={onQuestionClick}>{question}</TextButton>
  </div>
);

const ORGANIZATION_FRAGMENT = graphql`
  fragment PTEP_Organization on Organization {
    id
    allowEarlyExercise
    allowAcceleration
    hasCooleyAsOutsideCounsel
    ...BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Organization
    ...EarlyExerciseBeneficialNoticeMessage_Organization
    postTerminationExercisePeriods {
      id
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_PostTerminationExercisePeriod
    }
  }
`;

const INSTRUMENT_FRAGMENT = graphql`
  fragment PTEP_Instrument on Instrument {
    name
    isEarlyExerciseBeneficial
    equityType {
      ...GrantWatchouts_PtepGreaterThan90DaysWatchout_EquityType
      ...GrantWatchouts_Rule83BWatchout_EquityType
    }
    ...BeginnerFlowPostTerminationExercisePeriodSelectionBlock_Instrument
    ...EarlyExerciseBeneficialNoticeMessage_Instrument
  }
`;

const GRANTEE_FRAGMENT = graphql`
  fragment PTEP_Grantee on Grantee {
    defaultAccelerationClause
    earlyExerciseIsAllowedByDefault
    defaultPostTerminationExercisePeriod {
      id
    }
  }
`;

const SCHEMA = z.object({
  accelerationClause: zodExtra.accelerationClause().nullable(),
  earlyExercise: z.boolean().default(false),
  postTerminationExercisePeriodId: z.string(),
});

export type FieldValues = z.infer<typeof SCHEMA>;

export const usePTEPForm = ({
  defaultValues,
}: {
  defaultValues: Partial<FieldValues>;
}) => {
  return useForm({
    defaultValues,
    resolver: zodResolver(SCHEMA),
  });
};

const AdminAssistedGrantPTEPPage_: React.FC<{
  granteeFragment: PTEP_Grantee$key;
  instrumentFragment: PTEP_Instrument$key;
  organizationFragment: PTEP_Organization$key;
}> = ({ granteeFragment, instrumentFragment, organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const instrument = useFragment(INSTRUMENT_FRAGMENT, instrumentFragment);
  const grantee = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const context = useAssistedGrantContext();
  const navigate = useNavigate();

  const isGrantVirtual = useIsAssistedGrantVirtual();

  const defaultAccelerationClause =
    context.state.accelerationClause ?? grantee.defaultAccelerationClause;

  const form = usePTEPForm({
    defaultValues: {
      accelerationClause: defaultAccelerationClause,
      earlyExercise:
        context.state.earlyExercise ?? grantee.earlyExerciseIsAllowedByDefault,
      postTerminationExercisePeriodId:
        context.state.postTerminationExercisePeriodId ??
        grantee.defaultPostTerminationExercisePeriod?.id,
    },
  });

  const handleSubmit = form.handleSubmit((_fieldValues) => {
    const fieldValues = _fieldValues as FieldValues;
    context.dispatchAction({
      accelerationClause: fieldValues.accelerationClause,
      earlyExercise: fieldValues.earlyExercise,
      postTerminationExercisePeriodId:
        fieldValues.postTerminationExercisePeriodId,
      type: "SET_PTEP",
    });
    void navigate(
      generatePath(APPLICATION_ROUTES.organizationAssistedGrantReview, {
        organizationId: organization.id,
      }),
    );
  });

  const {
    setFalse: hideEarlyExerciseQuestionCard,
    setTrue: showEarlyExerciseQuestionCard,
    value: earlyExerciseQuestionCardIsShown,
  } = useBoolean(false);
  const {
    setFalse: hidePSTPQuestionCard,
    setTrue: showPTSPQuestionCard,
    value: PTSPQuestionCardIsShown,
  } = useBoolean(false);
  const {
    setFalse: hideAccelerationQuestionCard,
    setTrue: showAccelerationQuestionCard,
    value: accelerationQuestionCardIsShown,
  } = useBoolean(false);

  const postTerminationLabel = isGrantVirtual
    ? "post termination settlement period"
    : "post termination exercise period";

  const postTerminationShortLabel = isGrantVirtual ? "PTSP" : "PTEP";

  const earlyExercise = form.watch("earlyExercise");
  const postTerminationExercisePeriodId = form.watch(
    "postTerminationExercisePeriodId",
  );
  const postTerminationExercisePeriod =
    organization.postTerminationExercisePeriods.find(
      (ptep) => ptep.id === postTerminationExercisePeriodId,
    );

  const [showAccelerationSection, _setShowAccelerationSection] = React.useState(
    !!defaultAccelerationClause,
  );

  const setShowAccelerationSection = useCallback(
    (value: boolean) => {
      if (value) {
        form.setValue("accelerationClause", AccelerationClauseOptions[0].value);
      } else {
        form.setValue("accelerationClause", null);
      }
      _setShowAccelerationSection(value);
    },
    [form],
  );

  return (
    <CreateSlideOverRemote.Provider>
      <form onSubmit={handleSubmit}>
        <AssistedGrantPageGridLayout
          aside={
            <div className="space-y-6">
              <FadeAndScaleTransition show={PTSPQuestionCardIsShown}>
                <QuestionCard
                  onCloseButtonClick={hidePSTPQuestionCard}
                  title={`Learn more on ${postTerminationShortLabel}?`}
                >
                  <p>
                    There’s no standard post termination settlement period for
                    SARs. You could have (ranked by employee friendliness,
                    starting with the least employee friendly):
                  </p>

                  <ul className="my-4 list-disc space-y-4 pl-4">
                    <li>
                      <strong>
                        no post termination settlement period →{" "}
                        <u>least employee friendly</u>
                      </strong>
                      , but you can then freely choose if and when the grantee
                      leaves if you want to allow a post termination settlement
                      period
                    </li>
                    <li>
                      <strong>
                        a short post termination settlement period (e.g. of only
                        a few days or months), like the usual 90 days PTEP you
                        would have for employees receiving stock options →{" "}
                        <u>not employee friendly</u>,
                      </strong>{" "}
                      but you can also choose to extend it once grantee leaves
                    </li>
                    <li>
                      <strong>
                        a post termination settlement period that fits a
                        reasonable exit horizon → <u>employee friendly</u>
                      </strong>
                      . Think about your company maturity stage (does an exit
                      seem possible in 2, 5 or 10 years?) and set your post
                      termination settlement period accordingly.
                    </li>
                  </ul>

                  <p>
                    Whatever option you choose, you’ll always be able to pay the
                    bonus at the time the grantee leaves the company (the bonus
                    amount would then be calculated as the difference between
                    FMV of the shares of Common Stock at the time the person
                    leaves, and the FMV of the shares of Common Stock at the
                    time of grant), but it will be completely up to you! The
                    grantee will never be able to force you to pay anything
                    before an exit event.
                  </p>
                </QuestionCard>
              </FadeAndScaleTransition>
              <FadeAndScaleTransition show={earlyExerciseQuestionCardIsShown}>
                <QuestionCard
                  onCloseButtonClick={hideEarlyExerciseQuestionCard}
                  title="Why would I want to allow an early exercise?"
                >
                  This would allow your team member to exercise options before
                  they’ve vested. Based on our customers’ practice, we recommend
                  not opting for early exercise unless:
                  <br />
                  <br />
                  <ul className="list-disc space-y-4 pl-4">
                    <li>
                      your team member requests it (ideally after having been
                      advised by a personal tax advisor)
                    </li>
                    <li>
                      you, as ESOP admin, are aware of the potential admin/legal
                      complexity it could trigger for the company
                    </li>
                  </ul>
                </QuestionCard>
              </FadeAndScaleTransition>
              <FadeAndScaleTransition show={accelerationQuestionCardIsShown}>
                <QuestionCard
                  onCloseButtonClick={hideAccelerationQuestionCard}
                  title="Why would I want to allow acceleration?"
                >
                  On the basis of our customers’ practice, it’s not common to
                  offer acceleration unless it’s expressly negotiated by a
                  candidate.
                  <br />
                  It can also lead to some issues in an M&A process.
                  <br />
                  Acceleration accelerates the vesting of your team member and
                  allows them to vest all their options under certain
                  conditions:
                  <ul className="list-disc py-6 pl-4">
                    <li>
                      <span className="font-medium">Single trigger</span>
                      <br />
                      If the company is acquired (or undergoes a similar event).
                    </li>
                    <li>
                      <span className="font-medium">Double trigger</span>
                      <br />
                      If these 2 events happen together:
                      <ul className="list-disc pl-4">
                        <li>
                          The company is acquired (or undergoes a similar event)
                        </li>
                        <li>
                          Your team member is dismissed or resigns for certain
                          reasons
                        </li>
                      </ul>
                    </li>
                  </ul>
                </QuestionCard>
              </FadeAndScaleTransition>
            </div>
          }
          header={
            <div className="space-y-6">
              <AssistedGrantPageTitle>
                And finally, the {postTerminationLabel}
              </AssistedGrantPageTitle>

              <div className="space-y-2">
                <BeginnerFlowPostTerminationExercisePeriodSelectionBlock
                  control={form.control}
                  disabled={context.isParameterImmutable(
                    "postTerminationExercisePeriodId",
                  )}
                  instrumentFragment={instrument}
                  organizationFragment={organization}
                />
                {postTerminationExercisePeriod && instrument.equityType && (
                  <GrantWatchouts.PtepGreaterThan90DaysWatchout.UsingPtepFragment
                    equityTypeFragment={instrument.equityType}
                    postTerminationExercisePeriodFragment={
                      postTerminationExercisePeriod
                    }
                  />
                )}
                {isGrantVirtual ? (
                  <NoticeMessage size="Small">
                    The {postTerminationLabel} is the time period after your
                    grantee leaves the company, during which your grantee will
                    have the right to have their SAR settled, i.e. receive their
                    cash bonus.
                    <br />
                    <TextButton onClick={showPTSPQuestionCard}>
                      Learn more on {postTerminationShortLabel}
                    </TextButton>
                  </NoticeMessage>
                ) : null}
              </div>
              <Divider />
            </div>
          }
        >
          <div className="space-y-6">
            {!isGrantVirtual ? (
              <>
                {organization.allowEarlyExercise && (
                  <>
                    <div className="space-y-2">
                      <Controller
                        control={form.control}
                        name="earlyExercise"
                        render={({ field }) => (
                          <div className="space-y-2">
                            <ToggleCard
                              checked={field.value}
                              label="Do you want to allow early exercise?"
                              onChange={field.onChange}
                              onQuestionClick={showEarlyExerciseQuestionCard}
                              question="Why would I want to allow an early exercise?"
                              title="Early Exercise"
                            />
                            {instrument.isEarlyExerciseBeneficial && (
                              <EarlyExerciseBeneficialNoticeMessage
                                instrumentFragment={instrument}
                                organizationFragment={organization}
                              />
                            )}
                          </div>
                        )}
                      />
                      {organization.hasCooleyAsOutsideCounsel &&
                        earlyExercise && (
                          <NoticeMessage size="Small" variant="Warning">
                            Early exercise is an important decision that
                            indirectly impacts your investors. If you opt for
                            early exercise, discuss it with your investors and
                            lawyers first.
                          </NoticeMessage>
                        )}
                      {instrument.equityType && (
                        <GrantWatchouts.Rule83BWatchout
                          earlyExerciseAllowed={earlyExercise ?? false}
                          equityTypeFragment={instrument.equityType}
                        />
                      )}
                    </div>
                    <Divider />
                  </>
                )}
                {organization.allowAcceleration && (
                  <>
                    <div className="space-y-2">
                      <Controller
                        control={form.control}
                        name="accelerationClause"
                        render={({ field, fieldState }) => (
                          <ToggleCard
                            checked={showAccelerationSection}
                            label="Do you want to allow acceleration?"
                            onChange={setShowAccelerationSection}
                            onQuestionClick={showAccelerationQuestionCard}
                            question="Why would I want to allow acceleration?"
                            title="Acceleration"
                          >
                            <FormRow
                              error={fieldState.error?.message}
                              label="Select acceleration scenario"
                            >
                              <SelectAutocomplete
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={(option) => {
                                  field.onChange(option?.value);
                                }}
                                options={AccelerationClauseOptions}
                                value={AccelerationClauseOptions.find(
                                  (option) => field.value === option.value,
                                )}
                              />
                            </FormRow>
                          </ToggleCard>
                        )}
                      />
                      <NoticeMessage size="Small" variant="Warning">
                        Acceleration is an important decision that indirectly
                        impacts your investors. If you opt for acceleration,
                        discuss it with your investors and lawyers first.
                      </NoticeMessage>
                    </div>
                  </>
                )}
              </>
            ) : null}

            <div className="mt-10 flex justify-between">
              <LinkButton
                leftIcon={<ArrowLeftIcon />}
                to={generatePath(
                  APPLICATION_ROUTES.organizationAssistedGrantVestingVestingSchedule,
                  {
                    organizationId: organization.id,
                  },
                )}
                variant="Tertiary Link"
              >
                Back
              </LinkButton>
              <Button
                data-cy="ptepNextStepButton"
                rightIcon={<ArrowRightIcon />}
                type="submit"
              >
                Next
              </Button>
            </div>
          </div>
        </AssistedGrantPageGridLayout>
      </form>
    </CreateSlideOverRemote.Provider>
  );
};

const QUERY = graphql`
  query PTEP_Query(
    $organizationId: OrganizationId!
    $instrumentId: UUID!
    $granteeId: GranteeId!
  ) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...PTEP_Organization
    }
    instrument(id: $instrumentId) @required(action: THROW) {
      ...PTEP_Instrument
    }
    grantee(id: $granteeId) @required(action: THROW) {
      ...PTEP_Grantee
    }
  }
`;

const AdminAssistedGrantPTEPPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const context = useAssistedGrantContext();

  if (!context.state.instrumentId) {
    throw new Error("organization instrument id not found in context");
  }
  if (!context.state.granteeId) {
    throw new Error("grantee id not found in context");
  }

  const { grantee, instrument, organization } = useLazyLoadQuery<PTEP_Query>(
    QUERY,
    {
      granteeId: context.state.granteeId,
      instrumentId: context.state.instrumentId,
      organizationId,
    },
  );

  return (
    <Page
      analyticsCategory="Assisted Grant Flow"
      analyticsName="Admin - Assisted Grant Flow - Post Termination Exercise Period"
      organizationId={organization.id}
      title={`Admin | ${organization.name} assisted grant flow - post termination exercise period`}
    >
      <AdminAssistedGrantPTEPPage_
        granteeFragment={grantee}
        instrumentFragment={instrument}
        organizationFragment={organization}
      />
    </Page>
  );
};

export default AdminAssistedGrantPTEPPage;
