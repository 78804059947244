/**
 * @generated SignedSource<<ceb2e4a9a731d312805ba4ad267ba5e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type VestingScheduleGraph_VestingSchedule_Query$variables = {
  quantityGranted: number;
  vestingScheduleId: any;
  vestingStartDate: string;
};
export type VestingScheduleGraph_VestingSchedule_Query$data = {
  readonly vestingDataPointsFromVestingScheduleId: ReadonlyArray<{
    readonly cumulativeVested: number;
    readonly date: string;
  }>;
};
export type VestingScheduleGraph_VestingSchedule_Query = {
  response: VestingScheduleGraph_VestingSchedule_Query$data;
  variables: VestingScheduleGraph_VestingSchedule_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quantityGranted"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingScheduleId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "vestingStartDate"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "quantityGranted",
      "variableName": "quantityGranted"
    },
    {
      "kind": "Variable",
      "name": "vestingScheduleId",
      "variableName": "vestingScheduleId"
    },
    {
      "kind": "Variable",
      "name": "vestingStartDate",
      "variableName": "vestingStartDate"
    }
  ],
  "concreteType": "VestingDataPoint",
  "kind": "LinkedField",
  "name": "vestingDataPointsFromVestingScheduleId",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cumulativeVested",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "date",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VestingScheduleGraph_VestingSchedule_Query",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VestingScheduleGraph_VestingSchedule_Query",
    "selections": [
      (v3/*: any*/)
    ]
  },
  "params": {
    "cacheID": "a574b6dafd00150cb9a16e10c62db22c",
    "id": null,
    "metadata": {},
    "name": "VestingScheduleGraph_VestingSchedule_Query",
    "operationKind": "query",
    "text": "query VestingScheduleGraph_VestingSchedule_Query(\n  $vestingScheduleId: VestingScheduleId!\n  $vestingStartDate: Date!\n  $quantityGranted: Int!\n) {\n  vestingDataPointsFromVestingScheduleId(vestingScheduleId: $vestingScheduleId, vestingStartDate: $vestingStartDate, quantityGranted: $quantityGranted) {\n    cumulativeVested\n    date\n  }\n}\n"
  }
};
})();

(node as any).hash = "37e65967678b19dc07d0bd6970e4d0b5";

export default node;
