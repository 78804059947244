import { useCallback, useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useGranteeGrantIndex_Grantee$key } from "./__generated__/useGranteeGrantIndex_Grantee.graphql";

const GRANTEE_FRAGMENT = graphql`
  fragment useGranteeGrantIndex_Grantee on Grantee {
    grants: ctmsGrants {
      id
    }
    drafts: easopGrantsWithoutCtmsGrant {
      id
    }
  }
`;

export const useGranteeGrantIndex = ({
  granteeFragment,
}: {
  granteeFragment: useGranteeGrantIndex_Grantee$key;
}) => {
  const { drafts, grants } = useFragment(GRANTEE_FRAGMENT, granteeFragment);

  const grantsIdsOlderFirst = useMemo(() => {
    const ctmsGrantsIds = grants.map((grant) => grant.id).reverse();
    const draftsIds = drafts?.map((draft) => draft.id).reverse() ?? [];
    return [...ctmsGrantsIds, ...draftsIds];
  }, [grants, drafts]);

  return useCallback(
    (grantId: string) => grantsIdsOlderFirst.indexOf(grantId),
    [grantsIdsOlderFirst],
  );
};
