/**
 * @generated SignedSource<<a3faf96a76855a092b04e5a50b4cd873>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation$variables = {
  equityGridId: string;
  name: string;
};
export type EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation$data = {
  readonly updateEquityGrid: {
    readonly name: string;
  };
};
export type EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation = {
  response: EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation$data;
  variables: EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityGridId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "attributes"
  },
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityGridId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityGrid",
        "kind": "LinkedField",
        "name": "updateEquityGrid",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityGrid",
        "kind": "LinkedField",
        "name": "updateEquityGrid",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10e140a12622f18a3b7d8038a53726d7",
    "id": null,
    "metadata": {},
    "name": "EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation",
    "operationKind": "mutation",
    "text": "mutation EquityGrid_ConfigureGrants_UpdateEquityGridName_Mutation(\n  $equityGridId: UUID!\n  $name: String!\n) {\n  updateEquityGrid(attributes: {name: $name}, id: $equityGridId) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a549a5d17898fca3f1e8d57f350fa778";

export default node;
