/**
 * @generated SignedSource<<d70190381b3082c96d0b07efdaf2819b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation$variables = {
  safeCalculatorVersionId: string;
};
export type OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation$data = {
  readonly deleteSafeCalculatorVersion: {
    readonly safeCalculatorVersions: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation = {
  response: OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation$data;
  variables: OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "safeCalculatorVersionId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "safeCalculatorVersionId",
    "variableName": "safeCalculatorVersionId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "SafeCalculatorVersion",
  "kind": "LinkedField",
  "name": "safeCalculatorVersions",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteSafeCalculatorVersion",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "deleteSafeCalculatorVersion",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "217cded16d0c97351a501db961fa262d",
    "id": null,
    "metadata": {},
    "name": "OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationSettingsSafeCalculatorLayout_DeleteSafeCalculatorVersion_Mutation(\n  $safeCalculatorVersionId: ID!\n) {\n  deleteSafeCalculatorVersion(safeCalculatorVersionId: $safeCalculatorVersionId) {\n    safeCalculatorVersions {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "49f36c6dd5a2eace4c2b5713b1731c05";

export default node;
