/**
 * @generated SignedSource<<bc91f9eeebc89bcea926989353bdead6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberEquityGrid_Query$variables = {
  boardMemberId: string;
};
export type BoardMemberEquityGrid_Query$data = {
  readonly boardMember: {
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"BoardMemberEquityGrid_Organization">;
    };
  } | null;
};
export type BoardMemberEquityGrid_Query = {
  response: BoardMemberEquityGrid_Query$data;
  variables: BoardMemberEquityGrid_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardMemberId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "boardMemberId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardMemberEquityGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardMember",
        "kind": "LinkedField",
        "name": "boardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "BoardMemberEquityGrid_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardMemberEquityGrid_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardMember",
        "kind": "LinkedField",
        "name": "boardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityGrid",
                "kind": "LinkedField",
                "name": "equityGrid",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EquityGridLevel",
                    "kind": "LinkedField",
                    "name": "levels",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "yearlySalary",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equityInUsd",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "equityInShares",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ownership",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6c030a6a33c33c36832948c22d3ecab5",
    "id": null,
    "metadata": {},
    "name": "BoardMemberEquityGrid_Query",
    "operationKind": "query",
    "text": "query BoardMemberEquityGrid_Query(\n  $boardMemberId: UUID!\n) {\n  boardMember(id: $boardMemberId) {\n    organization {\n      id\n      name\n      ...BoardMemberEquityGrid_Organization\n    }\n    id\n  }\n}\n\nfragment BoardMemberEquityGrid_Organization on Organization {\n  equityGrid {\n    name\n    id\n  }\n  ...BoardMembersEquityGridLevelsTable_Organization\n}\n\nfragment BoardMembersEquityGridLevelsTable_Organization on Organization {\n  equityGrid {\n    levels {\n      name\n      role\n      yearlySalary\n      equityInUsd\n      equityInShares\n      ownership\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "203dc519ce951b523d27d4ac342f131b";

export default node;
