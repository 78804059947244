import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useFragment, useLazyLoadQuery } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { BackButton } from "../../../components/BackButton";
import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { BreadCrumb } from "../../../components/ui/BreadCrumb";
import { LinkButton } from "../../../components/ui/Button";
import { OneColumnLayout } from "../../../components/ui/Layout/OneColumnLayout";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { Table } from "../../../components/ui/Table";
import { Tag } from "../../../components/ui/Tag";
import { Typography } from "../../../components/ui/Typography";
import { useApplicationName } from "../../../hooks/useApplicationTheme";
import { APPLICATION_ROUTES, useOrganizationIdParam } from "../../../paths";
import NotFoundPage from "../../NotFound/NotFound";
import {
  LegalDocumentationPage_Organization$data,
  LegalDocumentationPage_Organization$key,
} from "./__generated__/LegalDocumentationPage_Organization.graphql";
import { LegalDocumentationPage_Query } from "./__generated__/LegalDocumentationPage_Query.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment LegalDocumentationPage_Organization on Organization {
    id
    name
    miscellaneousLegalDocuments {
      displayedName
      document {
        updatedAt
        downloadUrl
      }
    }
  }
`;

type MiscellaneousLegalDocument =
  LegalDocumentationPage_Organization$data["miscellaneousLegalDocuments"][number];

const columnHelper = createColumnHelper<MiscellaneousLegalDocument>();

const DocumentsTable: React.FC<{
  miscellaneousLegalDocuments: ReadonlyArray<MiscellaneousLegalDocument>;
}> = ({ miscellaneousLegalDocuments }) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.displayedName, {
        cell: (context) => (
          <Typography variant="Medium/Extra Small">
            {context.getValue()}
          </Typography>
        ),
        enableSorting: true,
        header: () => "Document",
        id: "document",
      }),
      columnHelper.accessor(
        (row) => new Date(row.document.updatedAt).getTime(),
        {
          cell: (context) => <ShortDate value={context.getValue()} />,
          enableSorting: true,
          header: () => "Uploaded on",
          id: "updated-date",
        },
      ),
      columnHelper.accessor(() => null, {
        cell: (context) => {
          const miscellaneousLegalDocument = context.row.original;
          return (
            <LinkButton
              leftIcon={<ArrowDownTrayIcon />}
              size="extra small"
              target="_blank"
              to={miscellaneousLegalDocument.document.downloadUrl}
              type="button"
              variant="Secondary Full"
            />
          );
        },
        enableSorting: false,
        header: () => "",
        id: "actions",
        meta: { alignRight: true },
      }),
    ],
    [],
  );

  const data = useMemo(
    () => [...miscellaneousLegalDocuments],
    [miscellaneousLegalDocuments],
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "document" }],
    },
  });

  return <Table.Smart table={table} />;
};

const AdminDocumentsLegalDocumentationPage_: React.FC<{
  organizationFragment: LegalDocumentationPage_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const applicationName = useApplicationName();

  return (
    <OneColumnLayout
      Breadcrumb={
        <BreadCrumb>
          <BreadCrumb.Link
            to={generatePath(APPLICATION_ROUTES["organizationHome"], {
              organizationId: organization.id,
            })}
          >
            {organization.name}
          </BreadCrumb.Link>
          <BreadCrumb.Link to="..">Documents</BreadCrumb.Link>
          <BreadCrumb.Link to=".">External legal documentation</BreadCrumb.Link>
        </BreadCrumb>
      }
      className="space-y-6"
      showFooter
    >
      <RoundedBox className="space-y-4 p-6" withBorder withShadow>
        <div className="flex items-center gap-4">
          <BackButton />
          <div className="space-y-2">
            <div className="flex items-center gap-4">
              <Typography variant="Medium/Default">
                External legal documentation
              </Typography>
              <Tag className="uppercase">
                <FormattedMessage
                  defaultMessage="{count, plural, one {# document} other {# documents}}"
                  values={{
                    count: organization.miscellaneousLegalDocuments.length,
                  }}
                />
              </Tag>
            </div>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Access here legal documentation signed outside of{" "}
              {applicationName} and relating indirectly to equity awards (e.g.
              409a valuations, equity incentive plans, custom equity awards,
              etc.)
            </Typography>
          </div>
        </div>
        <DocumentsTable
          miscellaneousLegalDocuments={organization.miscellaneousLegalDocuments}
        />
      </RoundedBox>
    </OneColumnLayout>
  );
};

const QUERY = graphql`
  query LegalDocumentationPage_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) {
      id
      name
      ...LegalDocumentationPage_Organization
    }
  }
`;

const AdminDocumentsLegalDocumentationPage: React.FC = () => {
  const organizationId = useOrganizationIdParam();
  const query = useLazyLoadQuery<LegalDocumentationPage_Query>(
    QUERY,
    {
      organizationId,
    },
    { fetchPolicy: "store-and-network" },
  );

  if (!query.organization) {
    return <NotFoundPage />;
  }

  return (
    <Page
      analyticsName="Admin - Documents - Legal Documentation"
      organizationId={query.organization.id}
      title={`Admin | ${query.organization.name} legal documentation`}
    >
      <AdminDocumentsLegalDocumentationPage_
        organizationFragment={query.organization}
      />
    </Page>
  );
};

export default AdminDocumentsLegalDocumentationPage;
