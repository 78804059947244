import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../../components/Page";
import { useToaster } from "../../../../../../components/Toaster";
import { Toast } from "../../../../../../components/ui/Toast";
import { useQuery } from "../../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../../../paths";
import {
  FORM_SCHEMA,
  FormValues,
  mapFormToGraphQLAccelerationClause,
  mapFormToGraphQLAllowEarlyExercise,
  mapGraphQLToFormAccelerationClause,
  mapGraphQLToFormAllowEarlyExercise,
} from "../FORM_SCHEMA";
import { GrantDefaultsForm } from "../GrantDefaultsForm";
import { Hero } from "../Hero";
import { EmployeesAndContractors_Organization$key } from "./__generated__/EmployeesAndContractors_Organization.graphql";
import { EmployeesAndContractors_Query } from "./__generated__/EmployeesAndContractors_Query.graphql";
import { EmployeesAndContractors_UpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults_Mutation } from "./__generated__/EmployeesAndContractors_UpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults_Mutation.graphql";
import employeesAndContractorsImage from "./EmployeesAndContractors.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment EmployeesAndContractors_Organization on Organization {
    id
    employeesAndContractorsNewHiresGrantDefaults {
      accelerationClause
      allowEarlyExercise
      postTerminationExercisePeriod {
        id
      }
      vestingSchedule {
        id
      }
    }
    ...GrantDefaultsForm_Organization
  }
`;

const MUTATION = graphql`
  mutation EmployeesAndContractors_UpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults_Mutation(
    $input: UpdateOrganizationGrantDefaultsInput!
    $organizationId: OrganizationId!
  ) {
    updateOrganizationEmployeesAndContractorsNewHiresGrantDefaults(
      input: $input
      organizationId: $organizationId
    ) {
      ...EmployeesAndContractors_Organization
    }
  }
`;

const EmployeesAndContractors_: React.FC<{
  organizationFragment: EmployeesAndContractors_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [
    triggerUpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults,
  ] =
    useSafeMutation<EmployeesAndContractors_UpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults_Mutation>(
      MUTATION,
    );

  const form = useForm<FormValues>({
    defaultValues: {
      acceleration: mapGraphQLToFormAccelerationClause(
        organization.employeesAndContractorsNewHiresGrantDefaults
          .accelerationClause,
      ),
      allowEarlyExercise: mapGraphQLToFormAllowEarlyExercise(
        organization.employeesAndContractorsNewHiresGrantDefaults
          .allowEarlyExercise,
      ),
      postTerminationPeriodId:
        organization.employeesAndContractorsNewHiresGrantDefaults
          .postTerminationExercisePeriod?.id,
      vestingScheduleId:
        organization.employeesAndContractorsNewHiresGrantDefaults
          .vestingSchedule?.id,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const toaster = useToaster();

  const handleSubmit = form.handleSubmit(async (formData) => {
    await triggerUpdateOrganizationEmployeesAndContractorsNewHiresGrantDefaults(
      {
        variables: {
          input: {
            accelerationClause: mapFormToGraphQLAccelerationClause(
              formData.acceleration,
            ),
            allowEarlyExercise: mapFormToGraphQLAllowEarlyExercise(
              formData.allowEarlyExercise,
            ),
            postTerminationExercisePeriodId: formData.postTerminationPeriodId,
            vestingScheduleId: formData.vestingScheduleId,
          },
          organizationId: organization.id,
        },
      },
    );

    toaster.push(<Toast variant="success">Changes saved successfully</Toast>);
  });

  return (
    <div className="space-y-10">
      <Hero
        imageSource={employeesAndContractorsImage}
        title="Employees and Contractors"
      >
        Employees often have a 4-year vesting schedule, vesting monthly, with a
        one year cliff. 4-year vesting helps employees&apos; retention in the
        long term, and by having a one year cliff you make sure you don&apos;t
        give up equity to employees who would leave early.
      </Hero>
      <GrantDefaultsForm
        control={form.control}
        description="Define your default parameters for employees and contractors new hire grants"
        formIsDirty={form.formState.isDirty}
        onDiscard={() => {
          form.reset();
        }}
        onSubmit={handleSubmit}
        organizationFragment={organization}
      />
    </div>
  );
};

const QUERY = graphql`
  query EmployeesAndContractors_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...EmployeesAndContractors_Organization
    }
  }
`;

export default function EmployeesAndContractors() {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<EmployeesAndContractors_Query>(QUERY, {
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - New hires grants - Employees and contractors"
      organizationId={organization.id}
      title={`Admin | ${organization.name} organization equity new hires grants settings - Employees and contractors`}
    >
      <EmployeesAndContractors_ organizationFragment={organization} />
    </Page>
  );
}
