/**
 * @generated SignedSource<<00487483abb6ed57939349da1c44d36f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type DocumentTemplateType = "AWARD_AGREEMENT" | "BOARD_CONSENT" | "COUNTRY_SPECIFIC_ADDENDUM" | "FORM_OF_EARLY_EXERCISE_STOCK_PURCHASE_AGREEMENT" | "FORM_OF_EXERCISE_AGREEMENT" | "GRANT_NOTICE";
import { FragmentRefs } from "relay-runtime";
export type DocumentsApprovalPage_DocumentTemplate$data = ReadonlyArray<{
  readonly content: string;
  readonly contentUsedForApproval: string | null;
  readonly id: string;
  readonly name: string;
  readonly needsBoardApproval: boolean;
  readonly type: DocumentTemplateType;
  readonly " $fragmentType": "DocumentsApprovalPage_DocumentTemplate";
}>;
export type DocumentsApprovalPage_DocumentTemplate$key = ReadonlyArray<{
  readonly " $data"?: DocumentsApprovalPage_DocumentTemplate$data;
  readonly " $fragmentSpreads": FragmentRefs<"DocumentsApprovalPage_DocumentTemplate">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "DocumentsApprovalPage_DocumentTemplate",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "needsBoardApproval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentUsedForApproval",
      "storageKey": null
    }
  ],
  "type": "DocumentTemplate",
  "abstractKey": null
};

(node as any).hash = "15c770a0afac5f3a2f7ea7dd4fe3723a";

export default node;
