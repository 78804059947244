import { Transition } from "@headlessui/react";
import React from "react";

export const FadeAndScaleTransition: React.FC<
  React.PropsWithChildren<{
    appear?: boolean;
    className?: string;
    show?: boolean;
  }>
> = ({ appear = true, children, className, show }) => (
  <Transition
    appear={appear}
    as="div"
    className={className}
    enter="transition-all duration-75"
    enterFrom="opacity-0 scale-75"
    enterTo="opacity-100 scale-100"
    leave="transition-all duration-150"
    leaveFrom="opacity-100 scale-100"
    leaveTo="opacity-0 scale-75"
    show={show}
  >
    {children}
  </Transition>
);

export const FadeTransition: React.FC<
  React.PropsWithChildren<{
    appear?: boolean;
    className?: string;
    show?: boolean;
  }>
> = ({ appear = true, children, className, show }) => (
  <Transition
    appear={appear}
    as="div"
    className={className}
    enter="transition-all duration-75"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-all duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
    show={show}
  >
    {children}
  </Transition>
);
