import { addMonths, differenceInDays, differenceInMonths } from "date-fns";

export const roundedDifferenceInMonths = (date1: Date, date2: Date) => {
  const monthsDifference = differenceInMonths(date1, date2);
  const remainingDaysDifference = differenceInDays(
    date1,
    addMonths(date2, monthsDifference),
  );

  return monthsDifference + Math.round(remainingDaysDifference / 30);
};
export const daysSinceDate = (date: string) => {
  return differenceInDays(new Date(), new Date(date));
};
