import { PlusIcon } from "@heroicons/react/24/outline";
import { chain } from "lodash";
import { useMemo, useTransition } from "react";
import {
  Control,
  FieldPath,
  FieldValues,
  useController,
} from "react-hook-form";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { CreateSlideOverRemote } from "../../pages/Admin/Grants/Configure/PostTermination/CreateSlideOver";
import { Button } from "../ui/Button";
import { SelectAutocomplete } from "../ui/Form/Inputs/Select/SelectAutocomplete";
import {
  SelectOrganizationPostTerminationExercisePeriod_Organization$data,
  SelectOrganizationPostTerminationExercisePeriod_Organization$key,
} from "./__generated__/SelectOrganizationPostTerminationExercisePeriod_Organization.graphql";

const ORGANIZATION_FRAGMENT = graphql`
  fragment SelectOrganizationPostTerminationExercisePeriod_Organization on Organization
  @refetchable(
    queryName: "SelectOrganizationPostTerminationExercisePeriod_Organization_RefetchQuery"
  ) {
    id
    postTerminationExercisePeriods {
      __typename
      ... on OrganizationFixedPostTerminationExercisePeriod {
        id
        displayName
      }
      ... on OrganizationVariablePostTerminationExercisePeriod {
        id
        displayName
      }
    }
  }
`;

export type SelectedOrganizationPostTerminationExercisePeriod =
  SelectOrganizationPostTerminationExercisePeriod_Organization$data["postTerminationExercisePeriods"][number];

export const SelectOrganizationPostTerminationExercisePeriod = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>,
>({
  control,
  disabled,
  doNotAllowVariablePTEP,
  isVirtual,
  name,
  organizationFragment,
}: {
  control: Control<TFieldValues>;
  disabled?: boolean;
  doNotAllowVariablePTEP?: boolean;
  isVirtual: boolean;
  name: TName;
  organizationFragment: SelectOrganizationPostTerminationExercisePeriod_Organization$key;
}) => {
  const [organization, refetchOrganization] = useRefetchableFragment(
    ORGANIZATION_FRAGMENT,
    organizationFragment,
  );

  const { field } = useController({
    control,
    name,
  });

  const organizationPostTerminationExercisePeriods = useMemo(
    () =>
      chain(organization.postTerminationExercisePeriods)
        .map((ptep) => {
          switch (ptep.__typename) {
            case "%other":
              return null;
            case "OrganizationFixedPostTerminationExercisePeriod":
              return ptep;
            case "OrganizationVariablePostTerminationExercisePeriod":
              return doNotAllowVariablePTEP ? null : ptep;
          }
        })
        .compact()
        .value(),
    [organization.postTerminationExercisePeriods, doNotAllowVariablePTEP],
  );

  const createSlideOverController = CreateSlideOverRemote.useController();

  const [transitionInProgress, startTransition] = useTransition();
  const onPostTerminationExercisePeriodCreated = (
    organizationPostTerminationExercisePeriodId: string,
  ) => {
    startTransition(() => {
      refetchOrganization(
        {},
        {
          fetchPolicy: "network-only",
        },
      );
      field.onChange(organizationPostTerminationExercisePeriodId);
    });
  };

  return (
    <div className="space-y-2">
      <SelectAutocomplete.Form
        control={control}
        getOptionLabel={(option) => option.displayName}
        getOptionValue={(option) => option.id}
        name={name}
        options={organizationPostTerminationExercisePeriods}
        placeholder="Select a post-termination exercise period"
        usePortal
      />

      {!disabled && (
        <Button
          leftIcon={<PlusIcon />}
          loading={
            createSlideOverController.transitionInProgress ??
            transitionInProgress
          }
          onClick={() =>
            createSlideOverController.open({
              data: { doNotAllowVariablePTEP, organizationId: organization.id },
              onClose: ({
                createdOrganizationPostTerminationExercisePeriod,
              }) => {
                if (createdOrganizationPostTerminationExercisePeriod) {
                  onPostTerminationExercisePeriodCreated(
                    createdOrganizationPostTerminationExercisePeriod.id,
                  );
                }
              },
            })
          }
          size="extra small"
          type="button"
          variant="Tertiary Link"
        >
          {isVirtual
            ? "Add new post-termination settlement period"
            : "Add new post-termination exercise period"}
        </Button>
      )}
    </div>
  );
};
