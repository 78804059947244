/**
 * @generated SignedSource<<1aab5472a63e5e9dba586b244a793198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type GrantWatchouts_Rule83BWatchout_EquityType$data = {
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly code: string;
  };
  readonly " $fragmentType": "GrantWatchouts_Rule83BWatchout_EquityType";
};
export type GrantWatchouts_Rule83BWatchout_EquityType$key = {
  readonly " $data"?: GrantWatchouts_Rule83BWatchout_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"GrantWatchouts_Rule83BWatchout_EquityType">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GrantWatchouts_Rule83BWatchout_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};

(node as any).hash = "1a611b431e4cfdae9b04c53ca740d568";

export default node;
