import { useCallback } from "react";
import { useFragment } from "react-relay";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Step } from "../../../components/ui/Step";
import { Typography } from "../../../components/ui/Typography";
import { useEquityOfferIdParam, useOrganizationIdParam } from "../../../paths";
import { EquityOfferAside_EquityOffer$key } from "./__generated__/EquityOfferAside_EquityOffer.graphql";
import {
  EQUITY_OFFER_STEPS,
  useEquityOfferContext,
} from "./EquityOfferContext";

const EQUITY_OFFER = graphql`
  fragment EquityOfferAside_EquityOffer on EquityOffer {
    visitedSteps
    instrument {
      isVirtual
    }
  }
`;

export const EquityOfferAside: React.FC<{
  equityOfferFragment: EquityOfferAside_EquityOffer$key;
}> = ({ equityOfferFragment }) => {
  const equityOfferContext = useEquityOfferContext();

  const organizationId = useOrganizationIdParam();
  const equityOfferId = useEquityOfferIdParam();
  const equityOffer = useFragment(EQUITY_OFFER, equityOfferFragment);

  const getStepState = useCallback(
    (navItem: (typeof EQUITY_OFFER_STEPS)[number]) => {
      if (navItem.step === equityOfferContext.activeStep) {
        return "active";
      }
      if (equityOffer.visitedSteps.includes(navItem.step)) {
        return "completed";
      }
      return "upcoming";
    },
    [equityOffer.visitedSteps, equityOfferContext.activeStep],
  );

  const isStepVisited = useCallback(
    (navItem: (typeof EQUITY_OFFER_STEPS)[number]) => {
      return equityOffer.visitedSteps.includes(navItem.step);
    },
    [equityOffer.visitedSteps],
  );

  return (
    <aside className="flex flex-col gap-6">
      <Typography variant="Medium/Default">Make a new equity offer</Typography>

      <ul className="block">
        {EQUITY_OFFER_STEPS.map((item, index) => (
          <Step
            dataCy={
              isStepVisited(item)
                ? `${item.step.toLowerCase()}-step-visited`
                : `${item.step.toLowerCase()}-step-not-visited`
            }
            key={item.step}
            linkTo={generatePath(item.route, {
              equityOfferId,
              organizationId,
            })}
            number={index + 1}
            state={getStepState(item)}
          >
            {equityOffer.instrument?.isVirtual ? item.SARLabel : item.label}
          </Step>
        ))}
      </ul>
    </aside>
  );
};
