/**
 * @generated SignedSource<<1898aab826d61a4941ca7250143f368c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplicationSideBarContent_Viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrganizationRoleSwitcher_Account">;
  readonly " $fragmentType": "ApplicationSideBarContent_Viewer";
};
export type ApplicationSideBarContent_Viewer$key = {
  readonly " $data"?: ApplicationSideBarContent_Viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApplicationSideBarContent_Viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApplicationSideBarContent_Viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrganizationRoleSwitcher_Account"
    }
  ],
  "type": "Account",
  "abstractKey": null
};

(node as any).hash = "9ed602109d24fa497e671dabbc82d907";

export default node;
