import { zodResolver } from "@hookform/resolvers/zod";
import { isNil } from "lodash";
import { useForm } from "react-hook-form";
import { FormattedNumber } from "react-intl";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../../components/Page";
import { useToaster } from "../../../../../../components/Toaster";
import { Button } from "../../../../../../components/ui/Button";
import { FormRow } from "../../../../../../components/ui/Form/FormRow";
import { FormRowContainer } from "../../../../../../components/ui/Form/FormRowContainer";
import { RoundedBox } from "../../../../../../components/ui/RoundedBox";
import { Toast } from "../../../../../../components/ui/Toast";
import { Typography } from "../../../../../../components/ui/Typography";
import { useApplicationName } from "../../../../../../hooks/useApplicationTheme";
import { useBoolean } from "../../../../../../hooks/useBoolean";
import { useQuery } from "../../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../../../paths";
import {
  FORM_SCHEMA,
  FormValues,
  mapFormToGraphQLAccelerationClause,
  mapFormToGraphQLAllowEarlyExercise,
  mapGraphQLToFormAccelerationClause,
  mapGraphQLToFormAllowEarlyExercise,
} from "../FORM_SCHEMA";
import { GrantDefaultsForm } from "../GrantDefaultsForm";
import GrantDefaultsLayout from "../GrantDefaultsLayout";
import { Hero } from "../Hero";
import { Refresher_Organization$key } from "./__generated__/Refresher_Organization.graphql";
import { Refresher_Query } from "./__generated__/Refresher_Query.graphql";
import { Refresher_RemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation } from "./__generated__/Refresher_RemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation.graphql";
import { Refresher_UpdateOrganizationRefresherGrantDefaults_Mutation } from "./__generated__/Refresher_UpdateOrganizationRefresherGrantDefaults_Mutation.graphql";
import refresherImage from "./Refresher.png";
import { SetVestingEligibilityThresholdModal } from "./SetVestingEligibilityThresholdModal";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Refresher_Organization on Organization {
    id
    tenureGrantsEligibilityVestedRatioThreshold
    refresherGrantDefaults {
      accelerationClause
      allowEarlyExercise
      postTerminationExercisePeriod {
        id
      }
      vestingSchedule {
        id
      }
    }
    ...GrantDefaultsForm_Organization
    ...SetVestingEligibilityThresholdModal_Organization
  }
`;

const UPDATE_ORGANIZATION_REFRESHER_GRANT_DEFAULTS_MUTATION = graphql`
  mutation Refresher_UpdateOrganizationRefresherGrantDefaults_Mutation(
    $input: UpdateOrganizationGrantDefaultsInput!
    $organizationId: OrganizationId!
  ) {
    updateOrganizationRefresherGrantDefaults(
      input: $input
      organizationId: $organizationId
    ) {
      ...Refresher_Organization
    }
  }
`;

const REMOVE_ORGANIZATION_TENURE_GRANTS_ELIGIBILITY_VESTED_RATIO_THRESHOLD_MUTATION = graphql`
  mutation Refresher_RemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation(
    $organizationId: OrganizationId!
  ) {
    updateOrganizationTenureGrantsEligibilityVestedRatioThreshold(
      tenureGrantsEligibilityVestedRatioThreshold: null
      organizationId: $organizationId
    ) {
      ...Refresher_Organization
    }
  }
`;

const Refresher_: React.FC<{
  organizationFragment: Refresher_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [triggerUpdateOrganizationGrantDefaults] =
    useSafeMutation<Refresher_UpdateOrganizationRefresherGrantDefaults_Mutation>(
      UPDATE_ORGANIZATION_REFRESHER_GRANT_DEFAULTS_MUTATION,
    );

  const [
    triggerRemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold,
    removeOrganizationTenureGrantsEligibilityVestedRatioThresholdMutationIsInFlight,
  ] =
    useSafeMutation<Refresher_RemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold_Mutation>(
      REMOVE_ORGANIZATION_TENURE_GRANTS_ELIGIBILITY_VESTED_RATIO_THRESHOLD_MUTATION,
    );

  const form = useForm<FormValues>({
    defaultValues: {
      acceleration: mapGraphQLToFormAccelerationClause(
        organization.refresherGrantDefaults.accelerationClause,
      ),
      allowEarlyExercise: mapGraphQLToFormAllowEarlyExercise(
        organization.refresherGrantDefaults.allowEarlyExercise,
      ),
      postTerminationPeriodId:
        organization.refresherGrantDefaults.postTerminationExercisePeriod?.id,
      vestingScheduleId:
        organization.refresherGrantDefaults.vestingSchedule?.id,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const toaster = useToaster();

  const handleSubmit = form.handleSubmit(async (formData) => {
    await triggerUpdateOrganizationGrantDefaults({
      variables: {
        input: {
          accelerationClause: mapFormToGraphQLAccelerationClause(
            formData.acceleration,
          ),
          allowEarlyExercise: mapFormToGraphQLAllowEarlyExercise(
            formData.allowEarlyExercise,
          ),
          postTerminationExercisePeriodId: formData.postTerminationPeriodId,
          vestingScheduleId: formData.vestingScheduleId,
        },
        organizationId: organization.id,
      },
    });

    toaster.push(<Toast variant="success">Changes saved successfully</Toast>);
  });

  function removeThreshold() {
    void triggerRemoveOrganizationTenureGrantsEligibilityVestedRatioThreshold({
      variables: {
        organizationId: organization.id,
      },
    });
  }

  const {
    setFalse: closeSetVestingEligibilityThresholdModal,
    setTrue: openSetVestingEligibilityThresholdModal,
    value: setVestingEligibilityThresholdModalIsOpen,
  } = useBoolean(false);

  const applicationName = useApplicationName();

  return (
    <>
      <SetVestingEligibilityThresholdModal
        onClose={closeSetVestingEligibilityThresholdModal}
        organizationFragment={organization}
        show={setVestingEligibilityThresholdModalIsOpen}
      />
      <GrantDefaultsLayout title="Refreshers">
        <div className="space-y-10">
          <Hero imageSource={refresherImage} title="Refresher grants">
            Here you can set default parameters for any grant made to an
            employee after their new hire grant. You can also define a threshold
            for the planning eligibility of a tenure grant below
          </Hero>
          <div className="space-y-10">
            <GrantDefaultsForm
              aboveButtons={
                <RoundedBox
                  background="gray"
                  className="space-y-6 p-6"
                  withBorder
                >
                  <FormRowContainer title="Tenure grant eligibility">
                    <Typography
                      className="text-black-05"
                      variant="Regular/Small"
                    >
                      Define your eligibility parameters here below
                    </Typography>
                  </FormRowContainer>
                  <FormRow
                    help={`Using this threshold will allow ${applicationName} to proactively suggest planning new tenure grants in your Equity planning`}
                    label="Planning eligibility threshold"
                  >
                    <RoundedBox withBorder>
                      <div className="space-y-4 p-6">
                        {isNil(
                          organization.tenureGrantsEligibilityVestedRatioThreshold,
                        ) ? (
                          <>
                            <Typography as="div" variant="Regular/Extra Small">
                              No threshold has been set for any upcoming tenure
                              grant.
                            </Typography>
                            <Button
                              onClick={openSetVestingEligibilityThresholdModal}
                              size="small"
                              type="button"
                              variant="Secondary Outline"
                            >
                              Set a threshold
                            </Button>
                          </>
                        ) : (
                          <>
                            <Typography as="div" variant="Regular/Extra Small">
                              Only employees reaching{" "}
                              <span className="text-primary">
                                <FormattedNumber
                                  style="percent"
                                  value={
                                    organization.tenureGrantsEligibilityVestedRatioThreshold
                                  }
                                />
                              </span>{" "}
                              of their equity vested are eligible for a tenure
                              grant
                            </Typography>
                            <div className="flex gap-4">
                              <Button
                                disabled={
                                  removeOrganizationTenureGrantsEligibilityVestedRatioThresholdMutationIsInFlight
                                }
                                onClick={
                                  openSetVestingEligibilityThresholdModal
                                }
                                size="small"
                                type="button"
                                variant="Secondary Outline"
                              >
                                Edit threshold
                              </Button>
                              <Button
                                loading={
                                  removeOrganizationTenureGrantsEligibilityVestedRatioThresholdMutationIsInFlight
                                }
                                onClick={removeThreshold}
                                size="small"
                                type="button"
                                variant="Danger Outline"
                              >
                                Remove threshold
                              </Button>
                            </div>
                          </>
                        )}
                      </div>
                    </RoundedBox>
                  </FormRow>
                </RoundedBox>
              }
              control={form.control}
              description="Define your default parameters for refresher grants"
              formIsDirty={form.formState.isDirty}
              loading={form.formState.isSubmitting}
              onDiscard={() => {
                form.reset();
              }}
              onSubmit={handleSubmit}
              organizationFragment={organization}
            />
          </div>
        </div>
      </GrantDefaultsLayout>
    </>
  );
};

const QUERY = graphql`
  query Refresher_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...Refresher_Organization
    }
  }
`;

export default function Tenure() {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<Refresher_Query>(QUERY, {
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - Tenure grants"
      organizationId={organization.id}
      title={`Admin | ${organization.name} organization equity tenure grants settings`}
    >
      <Refresher_ organizationFragment={organization} />
    </Page>
  );
}
