import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { zodExtra } from "../helpers/zod-extra";
import { Safe, SafeWithoutId } from "../services/SafeCalculatorService";
import { FormRow } from "./ui/Form/FormRow";
import { DatePicker } from "./ui/Form/Inputs/DatePicker";
import { MaskedInput } from "./ui/Form/Inputs/MaskedInput";
import { SelectAutocomplete } from "./ui/Form/Inputs/Select/SelectAutocomplete";
import { Modal } from "./ui/Modal";

const defaultSafeSchema = z.object({
  lastIssuanceDate: z.string(),
  totalAmountInvestedInUSD: zodExtra.preprocessStringToNumber(
    z.number().positive(),
  ),
  useAsValuation: z.boolean().default(false),
  valuationCapInUSD: zodExtra.preprocessStringToNumber(z.number().positive()),
});

export const safeSchema = z
  .discriminatedUnion("type", [
    defaultSafeSchema.extend({
      type: z.enum(["POST_MONEY", "PRE_MONEY"]),
    }),
    defaultSafeSchema.extend({
      mfnDate: z.string(),
      type: z.enum(["POST_MONEY_MFN"]),
    }),
  ])
  .refine(
    (values) => values.totalAmountInvestedInUSD <= values.valuationCapInUSD,
    {
      message: "Total amount invested must be lower than valuation cap",
      path: ["totalAmountInvestedInUSD"],
    },
  );

export type SafeFormValues = z.infer<typeof safeSchema>;

const getDefaultValues = ({
  editedSafe,
  safeType,
}: {
  editedSafe: null | Safe;
  safeType: Safe["type"];
}) => {
  if (!editedSafe) {
    return { type: safeType } as const;
  }
  switch (safeType) {
    case "POST_MONEY":
    case "PRE_MONEY":
      return {
        lastIssuanceDate: editedSafe.lastIssuanceDate,
        totalAmountInvestedInUSD: editedSafe.totalAmountInvestedInUSD,
        type: safeType,
        valuationCapInUSD:
          "valuationCapInUSD" in editedSafe
            ? editedSafe.valuationCapInUSD
            : undefined,
      } as const;
    case "POST_MONEY_MFN":
      return {
        lastIssuanceDate: editedSafe.lastIssuanceDate,
        mfnDate: "mfnDate" in editedSafe ? editedSafe.mfnDate : undefined,
        totalAmountInvestedInUSD: editedSafe.totalAmountInvestedInUSD,
        type: safeType,
      } as const;
  }
};

const useSafeForm = ({
  editedSafe,
  safeType,
}: {
  editedSafe: null | Safe;
  safeType: Safe["type"];
}) => {
  const defaultValues = useMemo(
    () =>
      getDefaultValues({
        editedSafe,
        safeType,
      }),
    [safeType, editedSafe],
  );
  const { control, formState, handleSubmit } = useForm({
    resolver: zodResolver(safeSchema),
    values: defaultValues,
  });

  return {
    control,
    formState,
    handleSubmit,
  };
};

const DefaultSafeFormContent: React.FC<{
  control: ReturnType<typeof useSafeForm>["control"];
}> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="lastIssuanceDate"
        render={({ field, fieldState }) => (
          <FormRow error={fieldState.error?.message} label="Last issuance date">
            <DatePicker
              onChange={field.onChange}
              placeholder="Date"
              value={field.value}
            />
          </FormRow>
        )}
      />
      <Controller
        control={control}
        name="totalAmountInvestedInUSD"
        render={({ field, fieldState }) => (
          <FormRow
            error={fieldState.error?.message}
            label="Total amount invested"
          >
            <MaskedInput.Currency
              onChange={(event) => field.onChange(event.target.value)}
              placeholder="Amount"
              value={field.value}
            />
          </FormRow>
        )}
      />
      <Controller
        control={control}
        name="valuationCapInUSD"
        render={({ field, fieldState }) => (
          <FormRow error={fieldState.error?.message} label="Valuation cap">
            <MaskedInput.Currency
              onChange={(event) => field.onChange(event.target.value)}
              placeholder="Amount"
              value={field.value}
            />
          </FormRow>
        )}
      />
    </>
  );
};

const SafeMFNFormContent: React.FC<{
  control: ReturnType<typeof useSafeForm>["control"];
}> = ({ control }) => {
  return (
    <>
      <Controller
        control={control}
        name="lastIssuanceDate"
        render={({ field, fieldState }) => (
          <FormRow error={fieldState.error?.message} label="Last issuance date">
            <DatePicker
              onChange={field.onChange}
              placeholder="Date"
              value={field.value}
            />
          </FormRow>
        )}
      />
      <Controller
        control={control}
        name="mfnDate"
        render={({ field, fieldState }) => (
          <FormRow error={fieldState.error?.message} label="MFN date">
            <DatePicker
              onChange={field.onChange}
              placeholder="Date"
              value={field.value}
            />
          </FormRow>
        )}
      />
      <Controller
        control={control}
        name="totalAmountInvestedInUSD"
        render={({ field, fieldState }) => (
          <FormRow
            error={fieldState.error?.message}
            label="Total amount invested"
          >
            <MaskedInput.Currency
              onChange={(event) => field.onChange(event.target.value)}
              placeholder="Amount"
              value={field.value}
            />
          </FormRow>
        )}
      />
      <Controller
        control={control}
        name="valuationCapInUSD"
        render={({ field, fieldState }) => (
          <FormRow
            error={fieldState.error?.message}
            label="Target valuation cap"
            subLabel="Used to simulate conversion if no other SAFE was added post MFN date"
          >
            <MaskedInput.Currency
              onChange={(event) => field.onChange(event.target.value)}
              placeholder="Amount"
              value={field.value}
            />
          </FormRow>
        )}
      />
    </>
  );
};

const SafeFormContent: React.FC<{
  control: ReturnType<typeof useSafeForm>["control"];
  safeType: Safe["type"];
}> = ({ control, safeType }) => {
  switch (safeType) {
    case "POST_MONEY":
    case "PRE_MONEY":
      return <DefaultSafeFormContent control={control} />;
    case "POST_MONEY_MFN":
      return <SafeMFNFormContent control={control} />;
  }
};

type State = {
  editedSafe: null | Safe;
  open: boolean;
};

export const useNewSafeModalState = () => {
  const [state, setState] = useState<State>({
    editedSafe: null,
    open: false,
  });

  const openNewSafeModal = (editedSafe?: Safe) => {
    setState({
      editedSafe: editedSafe ?? null,
      open: true,
    });
  };

  const closeNewSafeModal = () => {
    setState((previousState) => ({
      ...previousState,
      open: false,
    }));
  };

  return {
    closeNewSafeModal,
    newSafeModalState: state,
    openNewSafeModal,
  };
};

const EnterSafeInformationStepContent: React.FC<{
  editedSafe: null | Safe;
  onAddSafe: (safe: SafeWithoutId) => void;
  onBackClicked: () => void;
  onClose: () => void;
  onEditSafe: (safe: Safe) => void;
  safeType: Safe["type"];
}> = ({
  editedSafe,
  onAddSafe,
  onBackClicked,
  onClose,
  onEditSafe,
  safeType,
}) => {
  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useSafeForm({
    editedSafe,
    safeType,
  });

  const onSubmit = handleSubmit((_safe) => {
    const safe = safeSchema.parse(_safe);
    if (editedSafe) {
      onEditSafe({ ...editedSafe, ...safe });
    } else {
      onAddSafe(safe);
    }
    onClose();
  });

  return (
    <Modal.Content
      actionsInHeader={
        <>
          <Modal.BackButton onClick={onBackClicked} />
          <Modal.ActionButton
            form="safe-information-form"
            loading={isSubmitting}
            type="submit"
          >
            {editedSafe ? <>Edit</> : <>Add</>}
          </Modal.ActionButton>
        </>
      }
      onClose={onClose}
      subTitle={
        editedSafe ? (
          <>Update the information below to modify SAFE note</>
        ) : (
          <>Enter the information below to create a SAFE note</>
        )
      }
      title={editedSafe ? <>Edit SAFE note</> : <>Add a SAFE note</>}
    >
      <Modal.Form id="safe-information-form" onSubmit={onSubmit}>
        <SafeFormContent control={control} safeType={safeType} />
      </Modal.Form>
    </Modal.Content>
  );
};

const SafeTypes = [
  { label: "Post-money SAFE", value: "POST_MONEY" },
  { label: "Pre-money SAFE", value: "PRE_MONEY" },
  {
    label: "Post-money MFN SAFE",
    value: "POST_MONEY_MFN",
  },
] as const;

const safeTypeSchema = z.object({
  type: z.enum(["PRE_MONEY", "POST_MONEY", "POST_MONEY_MFN"]),
});

type SafeTypeFormValues = z.infer<typeof safeTypeSchema>;

const SelectSafeTypeStepContent: React.FC<{
  defaultSafeType: null | Safe["type"];
  isEditingSAFE: boolean;
  onClose: () => void;
  onSubmit: (type: Safe["type"]) => void;
}> = ({ defaultSafeType, isEditingSAFE, onClose, onSubmit: _onSubmit }) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      type: defaultSafeType ?? undefined,
    } as SafeTypeFormValues,
    resolver: zodResolver(safeTypeSchema),
  });

  const onSubmit = handleSubmit((safe) => {
    _onSubmit(safe.type);
  });

  return (
    <Modal.Content
      actionsInHeader={
        <Modal.ActionButton
          form="safe-type-form"
          rightIcon={<ArrowRightIcon />}
          type="submit"
        >
          Next
        </Modal.ActionButton>
      }
      onClose={onClose}
      subTitle="Select the type of SAFE"
      title={isEditingSAFE ? <>Edit SAFE note</> : <>Add a SAFE note</>}
    >
      <Modal.Form id="safe-type-form" onSubmit={onSubmit}>
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState }) => (
            <FormRow error={fieldState.error?.message} label="Type">
              <SelectAutocomplete
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                name={field.name}
                onChange={(option) => {
                  field.onChange(option?.value);
                }}
                options={SafeTypes}
                placeholder="Select"
                usePortal
                value={
                  SafeTypes.find((type) => type.value === field.value) ?? null
                }
              />
            </FormRow>
          )}
        />
      </Modal.Form>
    </Modal.Content>
  );
};

type InnerState =
  | {
      activeStep: "ENTER_INFORMATION";
      selectedType: Safe["type"];
    }
  | {
      activeStep: "SELECT_TYPE";
      selectedType: null | Safe["type"];
    };

const ModalContent: React.FC<{
  editedSafe: null | Safe;
  onAddSafe: (safe: SafeWithoutId) => void;
  onClose: () => void;
  onEditSafe: (safe: Safe) => void;
}> = ({ editedSafe, onAddSafe, onClose, onEditSafe }) => {
  const [innerState, setInnerState] = useState<InnerState>(
    editedSafe
      ? { activeStep: "ENTER_INFORMATION", selectedType: editedSafe.type }
      : { activeStep: "SELECT_TYPE", selectedType: null },
  );
  switch (innerState.activeStep) {
    case "ENTER_INFORMATION":
      return (
        <EnterSafeInformationStepContent
          editedSafe={editedSafe}
          onAddSafe={onAddSafe}
          onBackClicked={() =>
            setInnerState({
              activeStep: "SELECT_TYPE",
              selectedType: innerState.selectedType,
            })
          }
          onClose={onClose}
          onEditSafe={onEditSafe}
          safeType={innerState.selectedType}
        />
      );
    case "SELECT_TYPE":
      return (
        <SelectSafeTypeStepContent
          defaultSafeType={innerState.selectedType}
          isEditingSAFE={!!editedSafe}
          onClose={onClose}
          onSubmit={(type) =>
            setInnerState({
              activeStep: "ENTER_INFORMATION",
              selectedType: type,
            })
          }
        />
      );
  }
};

export const SafeModal: React.FC<{
  onAddSafe: (safe: SafeWithoutId) => void;
  onClose: () => void;
  onEditSafe: (safe: Safe) => void;
  state: State;
}> = ({ onAddSafe, onClose, onEditSafe, state }) => {
  return (
    <Modal onClose={onClose} show={state.open}>
      <ModalContent
        editedSafe={state.editedSafe}
        onAddSafe={onAddSafe}
        onClose={onClose}
        onEditSafe={onEditSafe}
      />
    </Modal>
  );
};
