/**
 * @generated SignedSource<<9e91a0c3bd6b4355b2cf0ee0701ee5e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OrganizationRoleSwitcher_resetSeedOrganization_Mutation$variables = Record<PropertyKey, never>;
export type OrganizationRoleSwitcher_resetSeedOrganization_Mutation$data = {
  readonly resetSeedOrganization: boolean;
};
export type OrganizationRoleSwitcher_resetSeedOrganization_Mutation = {
  response: OrganizationRoleSwitcher_resetSeedOrganization_Mutation$data;
  variables: OrganizationRoleSwitcher_resetSeedOrganization_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "resetSeedOrganization",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationRoleSwitcher_resetSeedOrganization_Mutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "OrganizationRoleSwitcher_resetSeedOrganization_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "988dbf93826c6bcf26b7899db0c41a8c",
    "id": null,
    "metadata": {},
    "name": "OrganizationRoleSwitcher_resetSeedOrganization_Mutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationRoleSwitcher_resetSeedOrganization_Mutation {\n  resetSeedOrganization\n}\n"
  }
};
})();

(node as any).hash = "6ba83aca5c4da1a2ad634503e4b3f0d4";

export default node;
