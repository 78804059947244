import { Portal, Transition } from "@headlessui/react";
import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { useCallback, useContext, useMemo, useState } from "react";

export type ToastElement = React.ReactElement<ToastElementProps>;

export type ToastElementProps = {
  className?: string;
  onCloseButtonClick?: React.ComponentProps<"button">["onClick"];
};

interface Toast {
  element: ToastElement;
  id: string;
  isShowing: boolean;
  position: ToastPosition;
}

type ToastPosition = "bottom-right" | "top-center";

type ToastPusher = (
  render: Toast["element"],
  options?: { position?: ToastPosition; timeout?: null | number },
) => void;

const ToasterContext = React.createContext<null | {
  push: ToastPusher;
}>(null);

export const ToasterProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [state, setState] = useState<Toast[]>([]);

  const flush = useCallback(
    (id: string) => () => {
      setState((previousState) =>
        previousState.filter((toast) => toast.id !== id),
      );
    },
    [],
  );

  const hide = useCallback(
    (id: string) => () => {
      setState((previousState) => {
        setTimeout(flush(id), 3000);

        return previousState.map((toast) =>
          toast.id === id ? { ...toast, isShowing: false } : toast,
        );
      });
    },
    [flush],
  );

  const show = useCallback(
    (id: string) => () => {
      setState((previousState) => {
        return previousState.map((toast) =>
          toast.id === id ? { ...toast, isShowing: true } : toast,
        );
      });
    },
    [],
  );

  const push: ToastPusher = useCallback(
    (element, { position = "top-center", timeout = 3000 } = {}) => {
      setState((previousState) => {
        const id = nanoid();

        if (timeout) {
          setTimeout(hide(id), timeout);
        }

        setTimeout(show(id), 50);

        return [...previousState, { element, id, isShowing: false, position }];
      });
    },
    [hide, show],
  );

  const value = useMemo(() => ({ push }), [push]);

  const { bottomRightToasts, topCenterToasts } = useMemo(
    () =>
      state.reduce(
        (
          toasts: {
            bottomRightToasts: Toast[];
            topCenterToasts: Toast[];
          },
          toast,
        ) => {
          switch (toast.position) {
            case "bottom-right":
              return {
                ...toasts,
                bottomRightToasts: [...toasts.bottomRightToasts, toast],
              };
            case "top-center":
              return {
                ...toasts,
                topCenterToasts: [...toasts.topCenterToasts, toast],
              };
          }
        },
        { bottomRightToasts: [], topCenterToasts: [] },
      ),
    [state],
  );

  return (
    <>
      <Portal>
        <div className="pointer-events-none absolute inset-x-4 inset-y-6 z-30 sm:inset-10">
          <div className="relative flex flex-col items-center space-y-3">
            {topCenterToasts.map((toast) => (
              <Transition
                appear
                enter={
                  /* tailwind */ `transition-all duration-200 ease-out transform-gpu`
                }
                enterFrom={/* tailwind */ `opacity-0 -translate-y-44`}
                enterTo={/* tailwind */ `opacity-100 -translate-y-0`}
                key={toast.id}
                leave={
                  /* tailwind */ `transition-all duration-200 ease-in transform-gpu`
                }
                leaveFrom={/* tailwind */ `opacity-100 -translate-y-0`}
                leaveTo={/* tailwind */ `opacity-0 -translate-y-44`}
                show={toast.isShowing}
              >
                {React.cloneElement(toast.element, {
                  ...toast.element.props,
                  className: classNames(
                    toast.element.props.className,
                    "pointer-events-auto",
                  ),
                  onCloseButtonClick: hide(toast.id),
                })}
              </Transition>
            ))}
          </div>
        </div>
        <div className="absolute bottom-10 right-10 z-30 space-y-3">
          {bottomRightToasts.map((toast) => (
            <Transition
              appear
              enter="transition-all duration-300 ease-out transform-gpu"
              enterFrom="translate-x-[calc(100%+1.5rem)]"
              enterTo="translate-x-0"
              key={toast.id}
              leave="transition-all duration-200 ease-in transform-gpu"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-[calc(100%+1.5rem)]"
              show={toast.isShowing}
            >
              {React.cloneElement(toast.element, {
                ...toast.element.props,
                onCloseButtonClick: hide(toast.id),
              })}
            </Transition>
          ))}
        </div>
      </Portal>
      <ToasterContext.Provider value={value}>
        {children}
      </ToasterContext.Provider>
    </>
  );
};

export const useToaster = () => {
  const context = useContext(ToasterContext);

  if (!context) {
    throw new Error("can't get toaster context");
  }

  return context;
};
