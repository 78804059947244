import React, { useMemo } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useBoolean } from "../../hooks/useBoolean";
import {
  getAmendedVestingEndingOn,
  getAmendmentVestingEndOption,
  GrantAmendmentRequest,
} from "../../services/grantAmendmentRequest";
import { LongDate } from "../LongDate";
import { StateUpdateRow } from "../ui/StateUpdateRow";
import { Typography } from "../ui/Typography";
import { GrantAmendmentRequestBoxVestingEnd_CtmsGrant$key } from "./__generated__/GrantAmendmentRequestBoxVestingEnd_CtmsGrant.graphql";
import {
  GrantAmendmentRequestBoxVestingEndSlide,
  VESTING_OPTION_MAP,
} from "./GrantAmendmentRequestBoxVestingEndSlide";

const GRANT_FRAGMENT = graphql`
  fragment GrantAmendmentRequestBoxVestingEnd_CtmsGrant on CTMSGrant {
    id
  }
`;

export const GrantAmendmentRequestBoxVestingEnd: React.FC<{
  ctmsGrantFragment: GrantAmendmentRequestBoxVestingEnd_CtmsGrant$key;
  grantAmendment: GrantAmendmentRequest | null;
  onChange: (grantAmendment: GrantAmendmentRequest) => void;
  readOnly?: boolean;
  terminationDate: string;
  terminationLastDayAtTheCompany: string;
}> = ({
  ctmsGrantFragment,
  grantAmendment,
  onChange,
  readOnly,
  terminationDate,
  terminationLastDayAtTheCompany,
}) => {
  const ctmsGrant = useFragment(GRANT_FRAGMENT, ctmsGrantFragment);

  const {
    setFalse: closeVestingEndSlide,
    setTrue: openVestingEndSlide,
    value: vestingEndSlideIsOpened,
  } = useBoolean(false);

  const vestingEndingOn = useMemo(
    () => new Date(terminationDate),
    [terminationDate],
  );

  const amendedVestingEndingOn = getAmendedVestingEndingOn({
    grantAmendment,
    terminationDate,
    terminationLastDayAtTheCompany,
  });

  const vestingEndOption = getAmendmentVestingEndOption({ grantAmendment });

  return (
    <div className="flex flex-col gap-4 border-t-[0.5px] border-gray-04 p-6">
      <div className="flex items-center justify-between">
        <Typography as="div" variant="Regular/Extra Small">
          Vesting will end on
        </Typography>
        <Typography as="div" variant="Regular/Extra Small">
          {VESTING_OPTION_MAP[vestingEndOption]}
        </Typography>
      </div>

      <StateUpdateRow
        hasChanged={
          !!amendedVestingEndingOn &&
          vestingEndingOn.valueOf() !== amendedVestingEndingOn.valueOf()
        }
        label="The vesting is ending on"
        newValue={
          amendedVestingEndingOn ? (
            <LongDate value={amendedVestingEndingOn} />
          ) : undefined
        }
        onModifyClick={openVestingEndSlide}
        readOnly={readOnly}
        value={<LongDate value={vestingEndingOn} />}
      />

      <GrantAmendmentRequestBoxVestingEndSlide
        currentVesting={{
          vestingEndCustomDate: grantAmendment?.vestingEndCustomDate,
          vestingEndOption,
        }}
        onClose={closeVestingEndSlide}
        onSubmit={(grantVestingEndAmendment) => {
          onChange({
            ctmsGrantId: ctmsGrant.id,
            ...grantVestingEndAmendment,
          });
        }}
        open={vestingEndSlideIsOpened}
      />
    </div>
  );
};
