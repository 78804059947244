/**
 * @generated SignedSource<<1cec57489c01458fe863818a691c5a50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApprovalSlideOverContent_SignatureRequest$data = {
  readonly boardConsent: {
    readonly __typename: string;
    readonly ctmsGrantAmendmentRequestsCount?: number;
    readonly grantCount?: number;
    readonly granteeTerminationCTMSGrantAmendmentRequestCount?: number;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_BoardConsent" | "ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent" | "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent" | "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent" | "ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent">;
  };
  readonly completedAt: string | null;
  readonly id: string;
  readonly " $fragmentType": "ApprovalSlideOverContent_SignatureRequest";
};
export type ApprovalSlideOverContent_SignatureRequest$key = {
  readonly " $data"?: ApprovalSlideOverContent_SignatureRequest$data;
  readonly " $fragmentSpreads": FragmentRefs<"ApprovalSlideOverContent_SignatureRequest">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApprovalSlideOverContent_SignatureRequest",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "boardConsent",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grantCount",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverContent_GrantBoardConsentBlocks_GrantBoardConsent"
            }
          ],
          "type": "GrantBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "granteeTerminationCTMSGrantAmendmentRequestCount",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GranteeTerminationGrantAmendmentBoardConsent"
            }
          ],
          "type": "GranteeTerminationGrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "ctmsGrantAmendmentRequestsCount",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverContent_GrantAmendmentBoardConsentBlocks_GrantAmendmentBoardConsent"
            }
          ],
          "type": "GrantAmendmentBoardConsent",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ApprovalSlideOverContent_FairMarketValueBoardConsentBlocks_FairMarketValueBoardConsent"
            }
          ],
          "type": "FairMarketValueBoardConsent",
          "abstractKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ApprovalSlideOverContent_BoardConsent"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SignatureRequest",
  "abstractKey": null
};
})();

(node as any).hash = "ea7cb4c78d6bffcadf863ae5cc41a681";

export default node;
