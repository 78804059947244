/**
 * @generated SignedSource<<24601ff24bebdf50e9a049da00fc86b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BoardMemberLayout_Organization_Query$variables = {
  boardMemberId: string;
};
export type BoardMemberLayout_Organization_Query$data = {
  readonly boardMember: {
    readonly organization: {
      readonly id: string;
      readonly name: string;
      readonly " $fragmentSpreads": FragmentRefs<"useApplicationTheme_Organization">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"BoardMemberLayout_BoardMember">;
  } | null;
  readonly me: {
    readonly " $fragmentSpreads": FragmentRefs<"BoardMemberLayout_Viewer">;
  };
};
export type BoardMemberLayout_Organization_Query = {
  response: BoardMemberLayout_Organization_Query$data;
  variables: BoardMemberLayout_Organization_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "boardMemberId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "boardMemberId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BoardMemberLayout_Organization_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardMember",
        "kind": "LinkedField",
        "name": "boardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "useApplicationTheme_Organization"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoardMemberLayout_BoardMember"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BoardMemberLayout_Viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BoardMemberLayout_Organization_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "BoardMember",
        "kind": "LinkedField",
        "name": "boardMember",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "useRemoteEquityTheme",
                    "storageKey": null
                  }
                ],
                "type": "IOrganization",
                "abstractKey": "__isIOrganization"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityGrid",
                "kind": "LinkedField",
                "name": "equityGrid",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activated",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Account",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isSuperAdmin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organizationsAsAdmin",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BoardMember",
            "kind": "LinkedField",
            "name": "boardMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9b69942a06546b55d9ff1ca4e3ce899",
    "id": null,
    "metadata": {},
    "name": "BoardMemberLayout_Organization_Query",
    "operationKind": "query",
    "text": "query BoardMemberLayout_Organization_Query(\n  $boardMemberId: UUID!\n) {\n  boardMember(id: $boardMemberId) {\n    organization {\n      id\n      name\n      ...useApplicationTheme_Organization\n    }\n    ...BoardMemberLayout_BoardMember\n    id\n  }\n  me {\n    ...BoardMemberLayout_Viewer\n    id\n  }\n}\n\nfragment ApplicationSideBarContent_Organization on Organization {\n  ...OrganizationRoleSwitcher_Organization\n}\n\nfragment ApplicationSideBarContent_Viewer on Account {\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment ApplicationSideBar_Organization on Organization {\n  ...ApplicationSideBarContent_Organization\n  ...OrganizationRoleSwitcher_Organization\n}\n\nfragment ApplicationSideBar_Viewer on Account {\n  ...ApplicationSideBarContent_Viewer\n  ...OrganizationRoleSwitcher_Account\n}\n\nfragment BoardMemberLayout_BoardMember on BoardMember {\n  id\n  organization {\n    equityGrid {\n      activated\n      id\n    }\n    ...ApplicationSideBar_Organization\n    id\n  }\n}\n\nfragment BoardMemberLayout_Viewer on Account {\n  ...ApplicationSideBar_Viewer\n}\n\nfragment OrganizationRoleSwitcher_Account on Account {\n  isSuperAdmin\n  ...useAccountRoles_Account\n}\n\nfragment OrganizationRoleSwitcher_Organization on Organization {\n  name\n  ...useAccountRoles_Organization\n}\n\nfragment useAccountRoles_Account on Account {\n  organizationsAsAdmin {\n    id\n  }\n  boardMembers {\n    id\n    organization {\n      id\n    }\n  }\n}\n\nfragment useAccountRoles_Organization on Organization {\n  id\n}\n\nfragment useApplicationTheme_Organization on IOrganization {\n  __isIOrganization: __typename\n  useRemoteEquityTheme\n}\n"
  }
};
})();

(node as any).hash = "ea133ff20f360771ac6e9fb3f67e80dd";

export default node;
