/**
 * @generated SignedSource<<6afdddd85122c665dfcf387c93f0edcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type MarkCTMSGrantsAsRepricedItemInput = {
  from: string;
  to: string;
};
export type RepricingForm_RepriceGrantMutation$variables = {
  ctmsGrantsRepricingPairs: ReadonlyArray<MarkCTMSGrantsAsRepricedItemInput>;
};
export type RepricingForm_RepriceGrantMutation$data = {
  readonly markCTMSGrantsAsRepriced: boolean;
};
export type RepricingForm_RepriceGrantMutation = {
  response: RepricingForm_RepriceGrantMutation$data;
  variables: RepricingForm_RepriceGrantMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ctmsGrantsRepricingPairs"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ctmsGrantsRepricingPairs",
        "variableName": "ctmsGrantsRepricingPairs"
      }
    ],
    "kind": "ScalarField",
    "name": "markCTMSGrantsAsRepriced",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RepricingForm_RepriceGrantMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RepricingForm_RepriceGrantMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f95d4515a15ae1bbbc3b5dfef10d4f63",
    "id": null,
    "metadata": {},
    "name": "RepricingForm_RepriceGrantMutation",
    "operationKind": "mutation",
    "text": "mutation RepricingForm_RepriceGrantMutation(\n  $ctmsGrantsRepricingPairs: [MarkCTMSGrantsAsRepricedItemInput!]!\n) {\n  markCTMSGrantsAsRepriced(ctmsGrantsRepricingPairs: $ctmsGrantsRepricingPairs)\n}\n"
  }
};
})();

(node as any).hash = "864aee9e79b6f724e69f351de936be1b";

export default node;
