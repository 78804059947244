/**
 * @generated SignedSource<<cb666c91a96015c9e9ab696895dc6298>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GrantDocumentationDetailsPage_Query$variables = {
  easopGrantId: string;
  organizationId: string;
};
export type GrantDocumentationDetailsPage_Query$data = {
  readonly easopGrant: {
    readonly signedBoardConsentDocument: {
      readonly __typename: "Document";
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationDetailsPage_EasopGrant">;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"GrantDocumentationDetailsPage_Organization">;
  } | null;
};
export type GrantDocumentationDetailsPage_Query = {
  response: GrantDocumentationDetailsPage_Query$data;
  variables: GrantDocumentationDetailsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "easopGrantId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "easopGrantId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "organizationId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GrantDocumentationDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "easopGrant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GrantDocumentationDetailsPage_EasopGrant"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "signedBoardConsentDocument",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GrantDocumentationDetailsPage_Organization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GrantDocumentationDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "EasopGrant",
        "kind": "LinkedField",
        "name": "easopGrant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Grantee",
            "kind": "LinkedField",
            "name": "grantee",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "grantNoticeDocument",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "awardAgreementDocument",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "signedBoardConsentDocument",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "countrySpecificAddendumDocument",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "formOfExerciseAgreementDocument",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "formOfEarlyExerciseStockPurchaseAgreementDocument",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff366c6d6bc40d9fe7f0dff5125ad6e8",
    "id": null,
    "metadata": {},
    "name": "GrantDocumentationDetailsPage_Query",
    "operationKind": "query",
    "text": "query GrantDocumentationDetailsPage_Query(\n  $organizationId: OrganizationId!\n  $easopGrantId: UUID!\n) {\n  easopGrant(id: $easopGrantId) {\n    ...GrantDocumentationDetailsPage_EasopGrant\n    signedBoardConsentDocument {\n      __typename\n      id\n    }\n    id\n  }\n  organization(id: $organizationId) {\n    id\n    name\n    ...GrantDocumentationDetailsPage_Organization\n  }\n}\n\nfragment GrantDocumentationDetailsPage_EasopGrant on EasopGrant {\n  label\n  grantee {\n    name\n    id\n  }\n  ...useGrantDocuments_EasopGrant\n}\n\nfragment GrantDocumentationDetailsPage_Organization on Organization {\n  id\n  name\n}\n\nfragment useGrantDocuments_EasopGrant on EasopGrant {\n  label\n  grantee {\n    name\n    id\n  }\n  grantNoticeDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  awardAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  signedBoardConsentDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  countrySpecificAddendumDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  formOfExerciseAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n  formOfEarlyExerciseStockPurchaseAgreementDocument {\n    downloadUrl\n    updatedAt\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "34d6365844ea498010dd20d6b15e3c7e";

export default node;
