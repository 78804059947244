/**
 * @generated SignedSource<<ee9aa4ff7a5ca1f0d392dc8dbc3f8dd4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestCompletedSection_Organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"WantToKnowMoreBox_Organization">;
  readonly " $fragmentType": "ExerciseRequestCompletedSection_Organization";
};
export type ExerciseRequestCompletedSection_Organization$key = {
  readonly " $data"?: ExerciseRequestCompletedSection_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestCompletedSection_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestCompletedSection_Organization",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "WantToKnowMoreBox_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3838b50f3da5a25295db14297abf1767";

export default node;
