import { ArrowSmallRightIcon } from "@heroicons/react/24/outline";
import React from "react";

import { Button } from "./Button";
import { Typography } from "./Typography";

interface Props extends React.ComponentPropsWithRef<"div"> {
  hasChanged?: boolean;
  label: React.ReactNode;
  newValue?: React.ReactNode;
  onModifyClick?: () => void;
  readOnly?: boolean;
  value?: React.ReactNode;
}

export const StateUpdateRow: React.FC<Props> = ({
  children,
  hasChanged,
  label,
  newValue,
  onModifyClick,
  readOnly,
  value,
}) => {
  return (
    <div className="flex items-center gap-4 rounded bg-primary-01 p-4">
      <Typography as="div" variant="Regular/Small">
        👀
      </Typography>
      <div className="flex flex-col">
        <Typography className="text-black-05" variant="Regular/Extra Small">
          {label}
        </Typography>
        {typeof value !== "undefined" ? (
          <Typography
            className={
              hasChanged ? "text-red-05 line-through" : "text-black-07"
            }
            variant="Medium/Extra Small"
          >
            {value}
          </Typography>
        ) : null}
      </div>
      {hasChanged && (
        <>
          <ArrowSmallRightIcon className="h-6 w-6 text-primary" />
          <div className="flex flex-col">
            <Typography className="text-black-05" variant="Regular/Extra Small">
              {label}
            </Typography>
            <Typography className="text-black-07" variant="Medium/Extra Small">
              {newValue}
            </Typography>
          </div>
        </>
      )}
      {!readOnly ? (
        <div className="ml-auto">
          {children ? (
            children
          ) : (
            <Button
              onClick={(e) => {
                if (!onModifyClick) return;

                e.stopPropagation();
                e.preventDefault();

                onModifyClick();
              }}
              variant="Primary Outline"
            >
              Modify
            </Button>
          )}
        </div>
      ) : null}
    </div>
  );
};
