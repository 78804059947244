/**
 * @generated SignedSource<<5072fa741e773be9c1d07beca2ebd202>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type EquityTypeName = "BSPCE" | "EMI" | "ISO" | "NSO" | "RSA" | "RSU" | "SAR";
import { FragmentRefs } from "relay-runtime";
export type EquityTypesLegalWatchOutsTable_EquityType$data = ReadonlyArray<{
  readonly id: string;
  readonly legalWatchOutsForContractor: {
    readonly lastUpdatedAt: string | null;
  };
  readonly legalWatchOutsForDirectEmployee: {
    readonly lastUpdatedAt: string | null;
  };
  readonly legalWatchOutsForEoREmployee: {
    readonly lastUpdatedAt: string | null;
  };
  readonly name: EquityTypeName;
  readonly taxResidenceCountry: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"CountryFlag_Country">;
  };
  readonly " $fragmentType": "EquityTypesLegalWatchOutsTable_EquityType";
}>;
export type EquityTypesLegalWatchOutsTable_EquityType$key = ReadonlyArray<{
  readonly " $data"?: EquityTypesLegalWatchOutsTable_EquityType$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityTypesLegalWatchOutsTable_EquityType">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastUpdatedAt",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "EquityTypesLegalWatchOutsTable_EquityType",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Country",
      "kind": "LinkedField",
      "name": "taxResidenceCountry",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CountryFlag_Country"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForDirectEmployee",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForEoREmployee",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
      "kind": "LinkedField",
      "name": "legalWatchOutsForContractor",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "EquityType",
  "abstractKey": null
};
})();

(node as any).hash = "bb693b25babfad3a993bf0868e3b7c3c";

export default node;
