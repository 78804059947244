import { Suspense } from "react";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { GrantsFilters } from "../pages/Admin/GrantsFilters";
import {
  OrganizationCTMSGrantsFilters,
  OrganizationCTMSGrantsFiltersOrderBy,
} from "../pages/Admin/Insights/UnderwaterGrants/__generated__/UnderwaterGrantsSlideOver_OrganizationCTMSGrantsPagination_Query.graphql";
import { OrganizationCtmsGrantsTable_CTMSGrants$key } from "./__generated__/OrganizationCtmsGrantsTable_CTMSGrants.graphql";
import {
  OrganizationCtmsGrantsTable_Organization$data,
  OrganizationCtmsGrantsTable_Organization$key,
} from "./__generated__/OrganizationCtmsGrantsTable_Organization.graphql";
import { CtmsGrantsTableRow } from "./CtmsGrantsTableRow";
import { SuspenseWithLoadingFallback } from "./LoadingPlaceholder";
import { LoadMoreButton } from "./ui/LoadMoreButton";
import { NoticeMessage } from "./ui/NoticeMessage";
import { Table } from "./ui/Table";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment OrganizationCtmsGrantsTable_Organization on Organization {
    ...CtmsGrantsTableRow_Organization
    ...GrantsFilters_Organization @defer
  }
`;

const CTMS_GRANTS_FRAGMENT = graphql`
  fragment OrganizationCtmsGrantsTable_CTMSGrants on CTMSGrant
  @relay(plural: true) {
    id
    ...CtmsGrantsTableRow_CTMSGrant
  }
`;

export type OrganizationCtmsGrantsTableOrderBy = {
  direction: "asc" | "desc";
  field:
    | "cumulativeVested"
    | "equityType"
    | "exercisePrice"
    | "grantee"
    | "label"
    | "quantityExercised"
    | "quantityIssued"
    | "remainingDaysToExercise"
    | "settled";
};

const OrganizationCtmsGrantsTable_: React.FC<{
  ctmsGrantsFragment: OrganizationCtmsGrantsTable_CTMSGrants$key;
  filters: OrganizationCTMSGrantsFilters;
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
  onOrderByChange: (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => void;
  organization: OrganizationCtmsGrantsTable_Organization$data;
  totalCount: number;
}> = ({
  ctmsGrantsFragment,
  filters,
  hasNext,
  isLoadingNext,
  loadNext,
  onOrderByChange,
  organization,
  totalCount,
}) => {
  const ctmsGrants = useFragment(CTMS_GRANTS_FRAGMENT, ctmsGrantsFragment);

  const variant = filters.statusIn?.includes("Active")
    ? "Active"
    : "Terminated";

  if (totalCount === 0) {
    return (
      <NoticeMessage size="Small">
        No grants match your current filters.
      </NoticeMessage>
    );
  }

  return (
    <>
      <Table.Containerized>
        <Table.Header className="border-b-[0.5px] border-gray-04">
          <CtmsGrantsTableRow.Header
            onOrderByChange={onOrderByChange}
            orderBy={filters.orderBy ?? null}
            variant={variant}
          />
        </Table.Header>
        <Table.Body>
          {ctmsGrants.map((ctmsGrant) => (
            <CtmsGrantsTableRow
              ctmsGrantFragment={ctmsGrant}
              key={ctmsGrant.id}
              organizationFragment={organization}
              variant={variant}
            />
          ))}
        </Table.Body>
      </Table.Containerized>
      {hasNext && (
        <div className="flex justify-center">
          <LoadMoreButton
            loading={isLoadingNext}
            onLoadMoreRequest={loadNext}
          />
        </div>
      )}
    </>
  );
};

export const OrganizationCTMSGrantsTable: React.FC<{
  ctmsGrantsFragment: OrganizationCtmsGrantsTable_CTMSGrants$key;
  filters: OrganizationCTMSGrantsFilters;
  hasNext: boolean;
  isLoadingNext: boolean;
  loadNext: () => void;
  onFiltersChange: (filters: OrganizationCTMSGrantsFilters) => void;
  organizationFragment: OrganizationCtmsGrantsTable_Organization$key;
  totalCount: number;
}> = ({
  ctmsGrantsFragment,
  filters,
  hasNext,
  isLoadingNext,
  loadNext,
  onFiltersChange,
  organizationFragment,
  totalCount,
}) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const handleOrderByChange = (
    orderBy: null | OrganizationCTMSGrantsFiltersOrderBy,
  ) => {
    onFiltersChange({
      ...filters,
      orderBy,
    });
  };

  return (
    <div className="space-y-10">
      <div className="space-y-4">
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          Overview of all your active grants and vesting schedules
        </Typography>
        <Suspense>
          <GrantsFilters
            filters={filters}
            onFiltersChange={onFiltersChange}
            organizationFragment={organization}
          />
        </Suspense>
      </div>
      <SuspenseWithLoadingFallback>
        <OrganizationCtmsGrantsTable_
          ctmsGrantsFragment={ctmsGrantsFragment}
          filters={filters}
          hasNext={hasNext}
          isLoadingNext={isLoadingNext}
          loadNext={loadNext}
          onOrderByChange={handleOrderByChange}
          organization={organization}
          totalCount={totalCount}
        />
      </SuspenseWithLoadingFallback>
    </div>
  );
};
