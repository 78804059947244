/**
 * @generated SignedSource<<c61bd9d025020cc4beb468865b59eeb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExerciseRequestTaxInformationExport_Organization$data = {
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"TaxDetailsContent_Organization">;
  readonly " $fragmentType": "ExerciseRequestTaxInformationExport_Organization";
};
export type ExerciseRequestTaxInformationExport_Organization$key = {
  readonly " $data"?: ExerciseRequestTaxInformationExport_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"ExerciseRequestTaxInformationExport_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExerciseRequestTaxInformationExport_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaxDetailsContent_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "83bf8feb0076f13a62258a8fc165b48f";

export default node;
