/**
 * @generated SignedSource<<19f177d8abb974ee16443f7d0523a8b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InviteEquityViewerSlideOver_AddEquityViewer_Mutation$variables = {
  accountId: string;
  organizationId: string;
};
export type InviteEquityViewerSlideOver_AddEquityViewer_Mutation$data = {
  readonly addEquityViewer: {
    readonly organization: {
      readonly " $fragmentSpreads": FragmentRefs<"OrganizationSettingsEquityViewers_Organization">;
    };
  };
};
export type InviteEquityViewerSlideOver_AddEquityViewer_Mutation = {
  response: InviteEquityViewerSlideOver_AddEquityViewer_Mutation$data;
  variables: InviteEquityViewerSlideOver_AddEquityViewer_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "Remote Equity Web App"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InviteEquityViewerSlideOver_AddEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddEquityViewerResult",
        "kind": "LinkedField",
        "name": "addEquityViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "OrganizationSettingsEquityViewers_Organization"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "InviteEquityViewerSlideOver_AddEquityViewer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AddEquityViewerResult",
        "kind": "LinkedField",
        "name": "addEquityViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EquityViewer",
                "kind": "LinkedField",
                "name": "equityViewers",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1f7773ef5912192b042a6e490ca9d8ca",
    "id": null,
    "metadata": {},
    "name": "InviteEquityViewerSlideOver_AddEquityViewer_Mutation",
    "operationKind": "mutation",
    "text": "mutation InviteEquityViewerSlideOver_AddEquityViewer_Mutation(\n  $organizationId: OrganizationId!\n  $accountId: String!\n) {\n  addEquityViewer(accountId: $accountId, organizationId: $organizationId, source: \"Remote Equity Web App\") {\n    organization {\n      ...OrganizationSettingsEquityViewers_Organization\n      id\n    }\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Item_EquityViewer on EquityViewer {\n  id\n  account {\n    id\n    firstName\n    lastName\n    email\n  }\n}\n\nfragment OrganizationSettingsEquityViewers_Organization on Organization {\n  id\n  name\n  equityViewers {\n    id\n    ...OrganizationSettingsEquityViewers_Item_EquityViewer\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b9e437a4395dfcc1cc214531d3623f0";

export default node;
