/**
 * @generated SignedSource<<2e69f169f56aa05f0f26b60894c4e71d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EquityOverviewCard_Organization$data = {
  readonly esopPoolShares: number | null;
  readonly poolAvailableShares: number;
  readonly poolDraftedSharesBreakdown: {
    readonly total: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EsopPoolProjectionGraph_Organization" | "SharesInDraftMessage_Organization">;
  readonly " $fragmentType": "EquityOverviewCard_Organization";
};
export type EquityOverviewCard_Organization$key = {
  readonly " $data"?: EquityOverviewCard_Organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"EquityOverviewCard_Organization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EquityOverviewCard_Organization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "esopPoolShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "poolAvailableShares",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SharesBreakdown",
      "kind": "LinkedField",
      "name": "poolDraftedSharesBreakdown",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharesInDraftMessage_Organization"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EsopPoolProjectionGraph_Organization"
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "ece3354bbdd1c26f9ac6c234c02872de";

export default node;
