/**
 * @generated SignedSource<<682f690815c8e796b8ad99c851b15361>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LegalWatchOutsEditionPage_Query$variables = {
  equityTypeId: string;
};
export type LegalWatchOutsEditionPage_Query$data = {
  readonly equityType: {
    readonly " $fragmentSpreads": FragmentRefs<"LegalWatchOutsEditionPage_EquityType">;
  } | null;
};
export type LegalWatchOutsEditionPage_Query = {
  response: LegalWatchOutsEditionPage_Query$data;
  variables: LegalWatchOutsEditionPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "equityTypeId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "equityTypeId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "noWatchOut",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "LegalWatchOut",
    "kind": "LinkedField",
    "name": "watchOuts",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LegalWatchOutsEditionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LegalWatchOutsEditionPage_EquityType"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LegalWatchOutsEditionPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EquityType",
        "kind": "LinkedField",
        "name": "equityType",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "taxResidenceCountry",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emoji",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForDirectEmployee",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForEoREmployee",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EquityTypeWorkRelationshipCategoryLegalWatchOuts",
            "kind": "LinkedField",
            "name": "legalWatchOutsForContractor",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f16969bdb04261888b4ad992401a52b",
    "id": null,
    "metadata": {},
    "name": "LegalWatchOutsEditionPage_Query",
    "operationKind": "query",
    "text": "query LegalWatchOutsEditionPage_Query(\n  $equityTypeId: UUID!\n) {\n  equityType(id: $equityTypeId) {\n    ...LegalWatchOutsEditionPage_EquityType\n    id\n  }\n}\n\nfragment CountryFlag_Country on Country {\n  emoji\n  name\n}\n\nfragment LegalWatchOutsEditionBlock_EquityType on EquityType {\n  id\n  legalWatchOutsForDirectEmployee {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n  legalWatchOutsForEoREmployee {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n  legalWatchOutsForContractor {\n    ...LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts\n  }\n}\n\nfragment LegalWatchOutsEditionBlock_EquityTypeWorkRelationshipCategoryLegalWatchOuts on EquityTypeWorkRelationshipCategoryLegalWatchOuts {\n  noWatchOut\n  watchOuts {\n    id\n    title\n    content\n  }\n}\n\nfragment LegalWatchOutsEditionPage_EquityType on EquityType {\n  name\n  taxResidenceCountry {\n    name\n    ...CountryFlag_Country\n  }\n  ...LegalWatchOutsEditionBlock_EquityType\n}\n"
  }
};
})();

(node as any).hash = "53b355058ead7d2681a0aae0880aa2f0";

export default node;
