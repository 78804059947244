import React from "react";

import { Typography } from "../../../components/ui/Typography";

export const FeatureCheck: React.FC<
  React.PropsWithChildren<{
    icon: React.ElementType<{
      className?: string;
    }>;
  }>
> = ({ children, icon: Icon }) => (
  <div className="flex items-center gap-1">
    <Icon className="w-4 text-primary" />
    <Typography className="text-black-05" variant="Regular/Caption">
      {children}
    </Typography>
  </div>
);
