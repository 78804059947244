import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { Page } from "../../../../../../components/Page";
import { useToaster } from "../../../../../../components/Toaster";
import { Toast } from "../../../../../../components/ui/Toast";
import { useQuery } from "../../../../../../hooks/useQuery";
import { useSafeMutation } from "../../../../../../hooks/useSafeMutation";
import { useOrganizationIdParam } from "../../../../../../paths";
import {
  FORM_SCHEMA,
  FormValues,
  mapFormToGraphQLAccelerationClause,
  mapFormToGraphQLAllowEarlyExercise,
  mapGraphQLToFormAccelerationClause,
  mapGraphQLToFormAllowEarlyExercise,
} from "../FORM_SCHEMA";
import { GrantDefaultsForm } from "../GrantDefaultsForm";
import { Hero } from "../Hero";
import { Advisors_Organization$key } from "./__generated__/Advisors_Organization.graphql";
import { Advisors_Query } from "./__generated__/Advisors_Query.graphql";
import { Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation } from "./__generated__/Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation.graphql";
import advisorsImage from "./Advisors.png";

const ORGANIZATION_FRAGMENT = graphql`
  fragment Advisors_Organization on Organization {
    id
    advisorsNewHiresGrantDefaults {
      accelerationClause
      allowEarlyExercise
      postTerminationExercisePeriod {
        id
      }
      vestingSchedule {
        id
      }
    }
    ...GrantDefaultsForm_Organization
  }
`;

const MUTATION = graphql`
  mutation Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation(
    $input: UpdateOrganizationGrantDefaultsInput!
    $organizationId: OrganizationId!
  ) {
    updateOrganizationAdvisorsNewHiresGrantDefaults(
      input: $input
      organizationId: $organizationId
    ) {
      ...Advisors_Organization
    }
  }
`;

const Advisors_: React.FC<{
  organizationFragment: Advisors_Organization$key;
}> = ({ organizationFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);

  const [triggerUpdateOrganizationAdvisorsNewHiresGrantDefaults] =
    useSafeMutation<Advisors_UpdateOrganizationAdvisorsNewHiresGrantDefaults_Mutation>(
      MUTATION,
    );

  const form = useForm<FormValues>({
    defaultValues: {
      acceleration: mapGraphQLToFormAccelerationClause(
        organization.advisorsNewHiresGrantDefaults.accelerationClause,
      ),
      allowEarlyExercise: mapGraphQLToFormAllowEarlyExercise(
        organization.advisorsNewHiresGrantDefaults.allowEarlyExercise,
      ),
      postTerminationPeriodId:
        organization.advisorsNewHiresGrantDefaults.postTerminationExercisePeriod
          ?.id,
      vestingScheduleId:
        organization.advisorsNewHiresGrantDefaults.vestingSchedule?.id,
    },
    resolver: zodResolver(FORM_SCHEMA),
  });

  const toaster = useToaster();

  const handleSubmit = form.handleSubmit(async (formData) => {
    await triggerUpdateOrganizationAdvisorsNewHiresGrantDefaults({
      variables: {
        input: {
          accelerationClause: mapFormToGraphQLAccelerationClause(
            formData.acceleration,
          ),
          allowEarlyExercise: mapFormToGraphQLAllowEarlyExercise(
            formData.allowEarlyExercise,
          ),
          postTerminationExercisePeriodId: formData.postTerminationPeriodId,
          vestingScheduleId: formData.vestingScheduleId,
        },
        organizationId: organization.id,
      },
    });

    toaster.push(<Toast variant="success">Changes saved successfully</Toast>);
  });

  return (
    <div className="space-y-10">
      <Hero imageSource={advisorsImage} title="Advisors">
        Advisors often have a 2-year vesting schedule, vesting monthly, with no
        cliff. Most companies avoid a 4-year vesting schedule because most
        advisors are going to deliver most of their value upfront.
      </Hero>
      <GrantDefaultsForm
        control={form.control}
        description="Define your default parameters for advisors new hire grants"
        formIsDirty={form.formState.isDirty}
        loading={form.formState.isSubmitting}
        onDiscard={() => {
          form.reset();
        }}
        onSubmit={handleSubmit}
        organizationFragment={organization}
      />
    </div>
  );
};

const QUERY = graphql`
  query Advisors_Query($organizationId: OrganizationId!) {
    organization(id: $organizationId) @required(action: THROW) {
      id
      name
      ...Advisors_Organization
    }
  }
`;

export default function Advisors() {
  const organizationId = useOrganizationIdParam();
  const {
    query: { organization },
  } = useQuery<Advisors_Query>(QUERY, {
    organizationId,
  });

  return (
    <Page
      analyticsCategory="Admin Configure Equity"
      analyticsName="Admin - Equity - Configure - New hires grants - Advisors"
      organizationId={organization.id}
      title={`Admin | ${organization.name} organization equity new hires grants settings - Advisors`}
    >
      <Advisors_ organizationFragment={organization} />
    </Page>
  );
}
