import { useFragment } from "react-relay";
import { graphql } from "relay-runtime";

import { useOrganizationCTMS } from "../hooks/useOrganizationCTMS";
import { InsightNotAvailableBox_Organization$key } from "./__generated__/InsightNotAvailableBox_Organization.graphql";
import { InsightNotAvailableBox_Viewer$key } from "./__generated__/InsightNotAvailableBox_Viewer.graphql";
import { BookADemoSlideOver } from "./BookADemo/BookADemoSlideOver";
import { RoundedBox } from "./ui/RoundedBox";
import { Typography } from "./ui/Typography";

const ORGANIZATION_FRAGMENT = graphql`
  fragment InsightNotAvailableBox_Organization on Organization {
    name
    ...useOrganizationCTMS_Organization
  }
`;

const VIEWER_FRAGMENT = graphql`
  fragment InsightNotAvailableBox_Viewer on Account {
    ...BookADemoSlideOver_Viewer
  }
`;

export const InsightNotAvailableBox: React.FC<{
  organizationFragment: InsightNotAvailableBox_Organization$key;
  viewerFragment: InsightNotAvailableBox_Viewer$key;
}> = ({ organizationFragment, viewerFragment }) => {
  const organization = useFragment(ORGANIZATION_FRAGMENT, organizationFragment);
  const viewer = useFragment(VIEWER_FRAGMENT, viewerFragment);
  const organizationCTMS = useOrganizationCTMS({
    organizationFragment: organization,
  });
  return (
    <>
      <RoundedBox
        className="m-6 max-w-[480px] space-y-4 !bg-gray-01 px-10 py-6 text-center"
        withBorder
      >
        <Typography as="div" variant="Medium/Extra Large">
          😔
        </Typography>
        {organizationCTMS ? (
          <Typography as="div" variant="Medium/Small">
            We cannot retrieve the information from {organizationCTMS.name} at
            the moment.
          </Typography>
        ) : (
          <Typography as="div" variant="Medium/Small">
            We cannot retrieve the information at the moment.
          </Typography>
        )}
        <Typography
          as="div"
          className="text-black-05"
          variant="Regular/Extra Small"
        >
          As soon as the integration is available, we will let you access those
          insights. In the meantime, you can{" "}
          <BookADemoSlideOver
            header="Jump on a call with our team of experts"
            introduction="Why can't I see the insights?"
            organizationName={organization.name}
            renderButton={({ openSlideOver }) => (
              <Typography
                className="cursor-pointer text-primary"
                onClick={openSlideOver}
                variant="Medium/Extra Small"
              >
                contact us
              </Typography>
            )}
            viewerFragment={viewer}
          />{" "}
          for more information.
        </Typography>
      </RoundedBox>
    </>
  );
};
