import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { chain } from "lodash";
import React, { useMemo, useTransition } from "react";
import { generatePath } from "react-router-dom";
import { graphql } from "relay-runtime";

import { Page } from "../../../components/Page";
import { ShortDate } from "../../../components/ShortDate";
import { CenteredColumnLayout } from "../../../components/ui/Layout/CenteredColumnLayout";
import { NoticeMessage } from "../../../components/ui/NoticeMessage";
import { RoundedBox } from "../../../components/ui/RoundedBox";
import { SearchBar } from "../../../components/ui/SearchBar";
import { Table } from "../../../components/ui/Table";
import { Typography } from "../../../components/ui/Typography";
import { useDebounced } from "../../../hooks/useDebounced";
import { useQuery } from "../../../hooks/useQuery";
import { APPLICATION_ROUTES } from "../../../paths";
import {
  DocumentsUpload_Query,
  DocumentsUpload_Query$data,
} from "./__generated__/DocumentsUpload_Query.graphql";

const QUERY = graphql`
  query DocumentsUpload_Query($search: String) {
    organizations(search: $search) {
      id
      name
      miscellaneousLegalDocuments {
        document {
          updatedAt
        }
      }
      activeEquityPlanDocument {
        __typename
      }
      unlockedEquityTypesRequiringActiveSubPlanDocument: unlockedEquityTypes(
        filters: { requiresActiveSubPlanDocument: true }
      ) {
        id
        name
        activeSubPlanDocument {
          __typename
        }
      }
    }
  }
`;

type Organization = DocumentsUpload_Query$data["organizations"][number];

const columnHelper = createColumnHelper<Organization>();

const getLastUpdatedDocumentTimestamp = (organization: Organization) => {
  return chain(organization.miscellaneousLegalDocuments)
    .map(({ document }) => new Date(document.updatedAt).getTime())
    .max()
    .value();
};

const OrganizationsTable: React.FC<{
  organizations: ReadonlyArray<Organization>;
}> = ({ organizations }) => {
  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        cell: (context) => (
          <Typography variant="Medium/Extra Small">
            {context.getValue()}
          </Typography>
        ),
        enableSorting: true,
        header: () => "Organization",
        id: "organization",
      }),
      columnHelper.accessor((row) => row.miscellaneousLegalDocuments.length, {
        cell: (context) => context.getValue() || "-",
        enableSorting: true,
        header: () => "Document uploaded",
        id: "documents-count",
      }),
      columnHelper.accessor((row) => getLastUpdatedDocumentTimestamp(row), {
        cell: (context) => {
          const date = context.getValue();
          return date ? <ShortDate value={context.getValue()} /> : "-";
        },
        enableSorting: true,
        header: () => "Last upload",
        id: "last-updated-date",
      }),
      columnHelper.accessor((row) => !!row.activeEquityPlanDocument, {
        cell: (context) => {
          const organization = context.row.original;
          return (
            <>
              Plan {organization.activeEquityPlanDocument ? "✅" : "❌"}
              <br />
              {organization.unlockedEquityTypesRequiringActiveSubPlanDocument.map(
                (equityType) => (
                  <span key={equityType.id}>
                    {equityType.name} sub-plan{" "}
                    {equityType.activeSubPlanDocument ? "✅" : "❌"}
                    <br />
                  </span>
                ),
              )}
            </>
          );
        },
        enableSorting: false,
        header: () => "Equity plan / sub-plans uploaded",
        id: "equity-plan-uploaded",
      }),
    ],
    [],
  );

  const data = useMemo(() => [...organizations], [organizations]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ desc: false, id: "organization" }],
    },
  });

  if (organizations.length === 0) {
    return (
      <NoticeMessage size="Small">
        No organizations match your current filters.
      </NoticeMessage>
    );
  }

  return (
    <Table.Smart
      rowRenderer={({ cells, rowData }) => (
        <Table.LinkRow
          to={generatePath(
            APPLICATION_ROUTES["superAdminDocumentsUploadOrganizationView"],
            {
              organizationId: rowData.original.id,
            },
          )}
        >
          {cells}
        </Table.LinkRow>
      )}
      table={table}
    />
  );
};

const DocumentsUpload_: React.FC = () => {
  const {
    debouncedState: debouncedSearch,
    isDebouncing: searchIsDebouncing,
    liveState: liveSearch,
    setState: setSearch,
  } = useDebounced({
    initialState: "",
  });

  const [searchTransitionIsInProgress, startSearchTransition] = useTransition();

  function handleSearchChange(value: string) {
    startSearchTransition(() => {
      setSearch(value);
    });
  }

  const { query } = useQuery<DocumentsUpload_Query>(QUERY, {
    search: debouncedSearch,
  });

  return (
    <CenteredColumnLayout maxWidth="800" showFooter>
      <div className="space-y-6">
        <Typography as="div" variant="Medium/Large">
          Documents upload
        </Typography>
        <RoundedBox className="space-y-4 p-6" withBorder withShadow>
          <div className="space-y-2">
            <Typography as="div" variant="Medium/Default">
              Upload legal documents to an organization
            </Typography>
            <Typography
              as="div"
              className="text-black-05"
              variant="Regular/Extra Small"
            >
              Upload anything from 409 approval, plan documentation, ...
            </Typography>
          </div>
          <SearchBar
            className="w-full"
            loading={searchTransitionIsInProgress || searchIsDebouncing}
            onChange={handleSearchChange}
            placeholder="Search organization..."
            value={liveSearch}
          />
          <OrganizationsTable organizations={query.organizations} />
        </RoundedBox>
      </div>
    </CenteredColumnLayout>
  );
};

const DocumentsUpload: React.FC = () => {
  return (
    <Page
      analyticsName="Super Admin - Documents upload"
      title={`Super admin | Documents upload`}
    >
      <DocumentsUpload_ />
    </Page>
  );
};

export default DocumentsUpload;
